import React from 'react';

import Deal from 'components/deal';
import { ModalRouteProps } from 'navigation/types';

const QuickViewDeal: React.FC<ModalRouteProps<{ id: string }>> = ({
  params,
}) => {
  const { id } = params;

  if (!id) {
    return null;
  }

  return <Deal dealId={id} isPanel match={{ params: { dealId: params.id } }} />;
};

export default QuickViewDeal;
