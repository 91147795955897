import classNames from 'classnames';
import { css } from 'emotion';

const container = css`
  width: 840px;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  max-height: 300px;
`;

const container_full = css`
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid var(--bu-gray-500);

  padding-bottom: 10px; /* scrollbar height*/
  overflow-x: hidden;
  &:hover {
    padding-bottom: 0; /* scrollbar height*/
    overflow-x: auto;
  }
`;

const table = css`
  table-layout: fixed;
  min-width: 100%;
  width: auto;
  border-collapse: collapse;
  border-spacing: 0;
`;

const table_header = css`
  display: inline-block;
  background-color: white;
  width: 100%;
  position: sticky;
  top: 0;
  border-bottom: 1px solid var(--bu-table-header-border-color);

  tr {
    display: grid;
    background-color: var(--bu--table-header);

    th {
      border-right: 1px solid var(--bu-table-header-border-color);
      height: 40px;
      display: flex;
      align-items: center;
      color: var(--bu-gray-900);
      font-family: var(--bu-font-medium);
      font-weight: 500;
      line-height: 1.3em;

      &:last-child {
        border-right: none;
      }
    }
  }
`;

const table_header_full = css`
  font-size: 12px;
  text-transform: uppercase;
`;

const table_header_column = css`
  color: #666;
  font-size: 12px;
  position: relative;
  padding: 10px;
  font-family: var(--bu-font-semi-bold);
`;

const table_body = css`
  tr {
    display: grid;
    height: 100%;

    td {
      border-right: 1px solid var(--bu-table-border-color);
      color: var(--bu-gray-900);

      &:last-child {
        border-right: none;
      }
    }
  }
`;

const full_rows = css`
  height: 50px;
`;

const row_text = css`
  word-break: break-all;
`;

const truncate_text = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const table_body_column = css`
  padding: 10px;
  color: #4a4a4a;
  font-size: 12px;
  font-family: var(--bu-font-regular);
`;

const table_body_column_full = css`
  font-size: 14px;
  line-height: 16px;
  color: #666;
  font-family: var(--bu-font-regular);
`;

const table_body_rest_column = css`
  color: #4a4a4a;
  font-family: var(--bu-font-regular);
  font-size: 12px;
  line-height: 14px;
  line-height: 17px;
`;

const table_body_rest_column_full = css`
  padding: 10px;
  color: #666;
  font-family: var(--bu-font-regular);
  font-size: 14px;
  line-height: 17px;
`;

const expandRowText = css`
  color: #4a4a4a;
  line-height: 17px;
`;

const expandRowTextPopup = css`
  font-size: 12px;
`;

const expandRowTextFull = css`
  font-size: 14px;
`;

export const getStyles = (isPopup) => {
  const popupView = {
    container,
    table,
    truncate_text: classNames('configurable-table', truncate_text),
    table_header: classNames(table_header),
    table_header_column,
    table_body: classNames(table_body),
    row_text: classNames('configurable-table', row_text),
    expand_row: classNames(expandRowText, expandRowTextPopup),
    full_rows: '',
    table_body_column: classNames('configurable-table', table_body_column),
    table_body_rest_column: classNames(
      'configurable-table',
      table_body_rest_column,
      table_body_column
    ),
  };

  const fullView = {
    container: container_full,
    table,
    truncate_text,
    table_header: classNames(table_header, table_header_full),
    table_header_column: classNames(table_header_column, table_header_full),
    table_body: classNames(table_body),
    row_text,
    full_rows,
    expand_row: classNames(expandRowText, expandRowTextFull),
    table_body_column: classNames(table_body_column, table_body_column_full),
    table_body_rest_column: table_body_rest_column_full,
  };

  return isPopup ? popupView : fullView;
};
