import classNames from 'classnames';
import { css } from 'emotion';
import * as R from 'ramda';
import { useState } from 'react';
import {
  IColumn,
  IDataCellProps,
  IRow,
  isDisable,
  isEditable,
  TypedTableCellConfig,
  ValueProp,
  ValueType,
} from '../TypedTable';
import DeltaValueCell, { getValueFromDelta } from './common/DeltaValueCell';
import { getCellValue } from './custom/common';

import { useHandleCellStatus } from 'components/hooks/useHandleCellStatus';
import BuSelect from 'components/UI/BuSelect';
import { ISelectOption } from 'components/UI/BuSelect/types';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import * as s from 'components/UI/common/TypedTable/styles';

type GetOptionsParams<T extends IRow> = {
  column: IColumn;
  row: IRow;
  rows: T[];
};
type GetOptions<T extends IRow> = (
  params: GetOptionsParams<T>
) => ISelectOption[];

export function isOptionsFunction<T extends IRow>(
  options: GetOptions<T> | ISelectOption[]
): options is GetOptions<T> {
  return options instanceof Function;
}

export interface SelectCellConfig<T extends IRow = IRow>
  extends TypedTableCellConfig {
  additionalClassName?: string;
  options?: GetOptions<T> | ISelectOption[];
  search?: boolean;
  multiselect?: boolean;
  secondary?: boolean;
}

const editableCellBorder = css`
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: var(--bu-control-border-radius);
  padding: 1px;

  .bu-dropdown {
    border: 1px solid;
    border-color: var(--bu-white) !important;
    width: 100%;
    padding: 2px !important;

    .bu-btn-content {
      .bu-dropdown-label {
        max-width: 88px;
      }

      span {
        line-height: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .bu-down {
      visibility: hidden !important;
    }
  }

  tr:hover &,
  tr.show-controls & {
    background-color: transparent;

    .bu-dropdown {
      border: 1px solid;
      border-color: var(--bu-gray-500) !important;

      .bu-down {
        visibility: visible !important;
      }
    }
  }

  &.loading {
    background-color: #fffaca !important;
  }

  &.success {
    background-color: #cff6d7 !important;
  }

  &.error {
    background-color: #fce3dc !important;
  }
`;

const fluidLabel = css`
  width: 100%;
  padding-left: 7px;
`;

const SelectField = css`
  width: 120px !important;
`;

const SelectCell = ({
  column,
  row,
  onChange,
  status,
  rows,
}: IDataCellProps) => {
  const [isOpen, setOpen] = useState(false);
  const oldValue = getValueFromDelta(
    R.path(column.field.split('.'), row) as ValueType
  ) as String;
  const value = getCellValue<string[]>({ column, row }) ?? '';

  const config = (column.config as SelectCellConfig) || {};
  const options =
    config.options && isOptionsFunction(config.options)
      ? config.options({ column, row, rows })
      : config.options;

  const { showStatus } = useHandleCellStatus(status);

  const getOptionItem = (value: ValueProp) =>
    options && options.find((item) => item.value === value);

  const formatter = (value: ValueProp) => {
    const printOption = getOptionItem(value);

    return `${printOption ? printOption.text : oldValue ? oldValue : ''}`;
  };

  const handleChange = (values: string[]) => {
    if (onChange instanceof Function) {
      const newValue = values?.join(';');
      const currenValue = Array.isArray(value) ? value.join(';') : value;
      if (currenValue !== newValue) {
        onChange(column, row, values as ValueType);
      }
    }
  };

  let CellContent;

  /*
   * This is need it because at the beging value come from BE as an Array,
   * but when is updated it changes to string joined by ';'.
   */
  const selectedValues: string[] = Array.isArray(value)
    ? value
    : value
    ? String(value).split(';')
    : [];

  if (isEditable(column, row, rows)) {
    CellContent = (
      <TooltipWrapper
        tooltip={column.showTooltip && formatter(oldValue)}
        wrap
        disable={isOpen}
      >
        <div
          className={classNames(
            editableCellBorder,
            {
              [(status && status.status) || '']: showStatus,
            },
            config.additionalClassName
          )}
        >
          <BuSelect
            secondary={config.secondary}
            disabled={isDisable(column, row, rows)}
            options={options || []}
            defaults={selectedValues}
            placeholder={'Not Available'}
            onChange={handleChange}
            multiselect
            returnFullList
            className={SelectField}
          />
        </div>
      </TooltipWrapper>
    );
  } else {
    CellContent = (
      <div className={classNames(fluidLabel, s.textOverflow)}>
        {R.isNil(oldValue) ? (
          !!selectedValues.length ? (
            selectedValues.join('; ')
          ) : (
            '-'
          )
        ) : (
          <TooltipWrapper tooltip={column.showTooltip && formatter(oldValue)}>
            <div style={{ width: '100%' }}>{formatter(oldValue)}</div>
          </TooltipWrapper>
        )}
      </div>
    );
  }

  return (
    <DeltaValueCell column={column} row={row} rows={rows} formatter={formatter}>
      {CellContent}
    </DeltaValueCell>
  );
};

export default SelectCell;
