import { combineReducers, Reducer } from 'redux';

import * as t from 'actions/actionTypes';
import {
  PipelineStagesAction,
  PipelineStagesResponse,
} from 'actions/pipelineStagesAction';

export type PipelineStagesState = PipelineStagesResponse;

const initialState: PipelineStagesState = {
  data: [],
  totals: {
    from: {},
    to: {},
  },
  from: '',
  to: '',
};

const pipelineStages: Reducer<PipelineStagesState, PipelineStagesAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case t.PIPELINE_STAGES + t.GET + t.SUCCESS:
      return { ...action.payload! };
    case t.PIPELINE_STAGES + +t.GET + t.ERROR:
      return initialState;
    default:
      return state;
  }
};

export type PipelineStagesStatusState =
  | 'notAsked'
  | 'loading'
  | 'success'
  | 'error';

const pipelineStagesStatus: Reducer<
  PipelineStagesStatusState,
  PipelineStagesAction
> = (state = 'notAsked', action) => {
  switch (action.type) {
    case t.PIPELINE_STAGES + t.GET + t.LOADING:
      return 'loading';
    case t.PIPELINE_STAGES + t.GET + t.SUCCESS:
      return 'success';
    case t.PIPELINE_STAGES + t.GET + t.ERROR:
      return 'error';
    default:
      return state;
  }
};

export default combineReducers({
  pipelineStages,
  pipelineStagesStatus,
});
