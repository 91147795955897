import { ForecastSubmissionSettingHistoryByUserReduxState } from 'common/types/forecastSubmission';

export const isSubmissionUsedToCalculateDelta = (
  submission: NonNullable<
    ForecastSubmissionSettingHistoryByUserReduxState['']
  >[0],
  submissionDateUsedToCalculateDelta: string
) => {
  const createdDate = new Date(submission.created_at);
  const prevDate = new Date(submissionDateUsedToCalculateDelta);
  return createdDate.getTime() === prevDate.getTime();
};
