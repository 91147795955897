import { css, keyframes } from 'emotion';

import { fontDefault } from 'assets/css/common';

export const container = css`
  width: 100%;
  height: 100%;
  background-color: var(--bu-primary-800);
  position: fixed;
  z-index: 980;

  min-width: 50px;
  max-width: 50px;
  overflow: hidden;

  transition: all 0.2s;

  p {
    opacity: 0;
    overflow: hidden;
    white-space: nowrap;
    transition: opacity 0.2s ease;
  }

  &:hover {
    transition-delay: 0.3s;
    z-index: 9000;
    min-width: 200px;
    max-width: 200px;

    p {
      opacity: 1;

      &.bu-icon-logo-wrapper {
        transition-delay: 0.3s;
        overflow: unset;
      }
    }

    .nav-buttons {
      overflow-y: auto;
    }
  }
`;

export const list_buttons = css`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  height: calc(100% - 50px);
  overflow: hidden;
`;

export const list_header_buttons = css`
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden !important;
`;

export const list_buttons_item = css`
  width: 100%;
  height: 44px;

  &:last-of-type {
    height: 54px;

    button {
      padding-bottom: 18px;
    }
  }

  &.border {
    border-top: 1px solid var(--bu-primary-700);
  }
`;

export const list_buttons_item_logo = css`
  height: 60px;
`;

export const list_buttons_spacer = css`
  flex: 1;
`;

const moveLogoArrowAnimation = keyframes`
  100% {
    left: 110px;
    top: -7px;
    font-size: 16px;
    line-height: 16px;
  }
`;

export const logoArrowAnimation = css`
  div:hover > ul > li.${list_buttons_item_logo} & {
    animation: ${moveLogoArrowAnimation} 0s ease 0.3s 1 normal forwards;
  }
`;

export const button_nav = css`
  width: 100%;
  height: 100%;
  border: none;
  background: none;
  display: flex;
  padding: 11px 11px 11px 13px;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  outline: none;
  color: var(--bu-white);

  .bu-icon,
  .bu-icons-group {
    font-size: 24px;
    width: 24px;
    height: 24px;
    line-height: 24px;

    & .bu-icon {
      font-size: inherit;
    }
  }

  p {
    margin-left: 10px;
    transition: 0.2s;
    ${fontDefault};
  }

  &.active,
  &:hover {
    background-color: var(--bu-primary-500);
  }

  &.white {
    background-color: var(--bu-primary-800);
  }
`;
