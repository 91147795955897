import axiosInstance from 'api/axiosInstance';
import {
  DataDescriptor,
  BIMetricsFilter,
} from 'components/dashboard/Metrics/metrics.types';

export type DashboardFiltersGetColumnFieldsResponse = DataDescriptor[];

export type DashboardConsolidatedFiltersResponse = {
  filters: BIMetricsFilter[];
  disabled_filters: DataDescriptor[];
};

export interface DashboardFiltersGetColumnFieldsPayload {
  tables: string[];
  include_changes_since?: boolean;
}

export const getDashboardFiltersColumnsFields = async (
  payload: DashboardFiltersGetColumnFieldsPayload
): Promise<DashboardFiltersGetColumnFieldsResponse> => {
  const res = await axiosInstance.post<DashboardFiltersGetColumnFieldsResponse>(
    `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_column_fields_filter`,
    payload
  );
  return res.data;
};

export const getConsolidatedFilters = async (
  widgetFilters: DataDescriptor[],
  templateFilters: BIMetricsFilter[],
  dashboardFilters: BIMetricsFilter[],
  tables: string[]
): Promise<DashboardConsolidatedFiltersResponse> => {
  const res = await axiosInstance.post<DashboardConsolidatedFiltersResponse>(
    `${process.env.REACT_APP_BACKEND_URL}/rev_bi/dashboards/filters_widget`,
    {
      widget_filters: widgetFilters,
      template_filters: templateFilters,
      dashboard_filters: dashboardFilters,
      tables,
    }
  );
  return res.data;
};
