import * as R from 'ramda';
import { combineReducers } from 'redux';

import * as t from 'actions/actionTypes';

const defaultIds = [];
const ids = (state = defaultIds, action) => {
  switch (action.type) {
    case t.EVENTS + t.GET + t.SUCCESS:
      return R.pluck('id')(action.payload.data.events);
    default:
      return state;
  }
};

const defaultById = {};
const byId = (state = defaultById, action) => {
  switch (action.type) {
    case t.EVENTS + t.GET + t.SUCCESS:
      return R.indexBy(R.prop('id'))(action.payload.data.events);
    default:
      return state;
  }
};

const total = (state = null, action) => {
  switch (action.type) {
    case t.EVENTS + t.GET + t.SUCCESS:
      return action.payload.data.count;
    default:
      return state;
  }
};

const current = (state = null, action) => {
  switch (action.type) {
    case t.EVENT + t.GET + t.SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case t.EVENT + t.GET:
    case t.EVENTS + t.GET:
      return true;
    case t.EVENT + t.GET + t.SUCCESS:
    case t.EVENT + t.GET + t.ERROR:
    case t.EVENTS + t.GET + t.SUCCESS:
    case t.EVENTS + t.GET + t.ERROR:
      return false;
    default:
      return state;
  }
};

const status = (state = 'loaded', action) => {
  switch (action.type) {
    case t.EVENT + t.GET + t.LOADING:
      return 'loading';
    case t.EVENT + t.GET + t.SUCCESS:
      return 'loaded';
    case t.EVENT + t.GET + t.ERROR:
      return 'error';
    default:
      return state;
  }
};

export default combineReducers({
  ids,
  byId,
  total,
  current,
  loading,
  status,
});
