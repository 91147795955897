import isNil from 'lodash/isNil';
import React, { PureComponent } from 'react';

import * as styles from 'components/UI/EmailBody/styles';
import { IProps, IState } from 'components/UI/EmailBody/types';

class EmailBody extends PureComponent<IProps, IState> {
  static shadowRootRef = React.createRef<HTMLDivElement>();
  static shadowEmailRoot: ShadowRoot | null = null;

  constructor(props: IProps) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  componentDidMount() {
    const { body, shadowStyles } = this.props;
    if (
      !isNil(EmailBody.shadowRootRef) &&
      !isNil(EmailBody.shadowRootRef.current)
    ) {
      EmailBody.shadowEmailRoot = EmailBody.shadowRootRef.current.attachShadow({
        mode: 'open',
      });
      EmailBody.shadowEmailRoot.innerHTML = body;
    }

    // add custom styles if present
    if (styles) {
      const style = document.createElement('style');
      if (!isNil(shadowStyles)) {
        style.textContent = shadowStyles;
      }
      if (!isNil(EmailBody.shadowEmailRoot)) {
        EmailBody.shadowEmailRoot.appendChild(style);
      }
    }
  }

  render() {
    return <div className={styles.shadowRoot} ref={EmailBody.shadowRootRef} />;
  }
}

export default EmailBody;
