import { css } from 'emotion';

export const wrapper = css`
  margin: 16px;
`;

export const title = css`
  font-size: 14px;
  margin-bottom: 16px;
`;
