import { css } from 'emotion';
import React from 'react';
import { Loader } from 'semantic-ui-react';

import BuButton from 'components/UI/BuButton';

interface IMoreProps {
  showing: number;
  count: number;
  max?: number;
  loading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const customBuButton = css`
  color: var(--bu-gray-900) !important;
  border-color: var(--bu-gray-400) !important;
`;

const ShowMoreButton: React.FC<IMoreProps> = ({
  showing,
  count,
  max = 5,
  loading,
  onClick = () => {},
}) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!event.isDefaultPrevented()) {
      onClick(event);
    }
  };

  return (
    <BuButton secondary onClick={handleClick} className={customBuButton}>
      {`+ ${count - showing} More `}
      {loading && <Loader size="tiny" active inline />}
    </BuButton>
  );
};

export default ShowMoreButton;
