import { css } from 'emotion';
import React from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import InfoIcon from 'assets/fonts/boostup-icons/badge_info_outline.svg';
import BuButton from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';

const recalculateSection = css`
  display: flex;
  margin-left: 6px;
  spacing: 2px;
`;

const refreshIconStyle = css`
  margin-right: 6px;
`;

const tooltipStyle = css`
  width: 200px;
  text-align: center;
`;

const iconStyle = css`
  width: 20px;
  margin-left: 10px;
  margin-top: -2px;
`;

interface RecalculateSectionProps {
  onRecalculate: () => void;
  tooltipMessage: string;
  recalculateDisabled?: boolean;
}
const RecalculateSection = ({
  onRecalculate,
  tooltipMessage,
  recalculateDisabled = false,
}: RecalculateSectionProps) => (
  <div className={recalculateSection}>
    <BuButton onClick={onRecalculate} outline disabled={recalculateDisabled}>
      <BuIcon className={refreshIconStyle} name={BoostUpIcons.RefreshIcon} />
      Recalculate
    </BuButton>
    <TooltipWrapper
      tooltip={<p className={tooltipStyle}>{tooltipMessage}</p>}
      position="top center"
    >
      <img className={iconStyle} src={InfoIcon} />
    </TooltipWrapper>
  </div>
);

export default RecalculateSection;
