import { css } from 'emotion';

import { fontBody, fontSubheading2 } from 'assets/css/fontStyles';

export const header = css`
  background-color: var(--bu-white);

  height: 49px;
  padding: 14px;

  color: var(--bu-gray-900);

  font-family: var(--bu-font-medium);
  font-size: 18px;
  line-height: 22px;

  border-bottom: 1px solid var(--bu-gray-400);
`;

export const container = css`
  height: 100%;

  display: flex;
  flex-direction: column;

  color: #4a4a4a;
  font-size: 14px;
  line-height: 16px;
`;

export const mainContent = css`
  display: flex;
  flex-direction: column;
  padding: 30px 37px;
  flex: 1;
  & > .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    ${fontBody}
    .lab {
      width: 200px;
    }
    .name {
      ${fontSubheading2}
    }
  }
`;

export const inputWidth = css`
  width: 600px !important;
`;

export const footer = css`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  align-items: center;
  bottom: 0;
  background: var(--bu-white);
  width: 100%;
  height: 52px;
  border-top: 1px solid var(--bu-gray-400);
  box-shadow: 0 0 2px 3px rgb(0 0 0 / 8%), 0 0 2px 1px rgb(0 0 0 / 15%);
  padding: 10px 20px;
`;
