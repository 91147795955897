import { connect } from 'react-redux';

import * as cacheActions from 'actions/cacheActions';
import * as topicActions from 'actions/topicActions';
import MomentsTabs from 'components/UI/MomentsTabs/MomentsTabs';
import { OwnProps } from 'components/UI/MomentsTabs/types';
import { IReduxState } from 'reducers/types';
import {
  getCacheTimestamp,
  getDealTopicCompetitors,
  getObjectionMomentsCached,
} from 'selectors';

const mapStateToProps = (state: IReduxState, { id }: OwnProps) => ({
  competitors: getDealTopicCompetitors(state, id),
  cache: getObjectionMomentsCached(state),
  cacheTimestamp: getCacheTimestamp(state, 'objectionMoment'),
});

const mapDispatchToProps = {
  createCache: cacheActions.addCachedData,
  fetchDealTopics: topicActions.fetchDealTopics,
};

export default connect(mapStateToProps, mapDispatchToProps)(MomentsTabs);
