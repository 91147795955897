import { useEffect, useState } from 'react';

import { BOUNDARY } from 'components/virtualized/VirtualizedItem';

export const useBoundary = (position?: BOUNDARY): boolean => {
  const [isFirstLoad, setFirstLoad] = useState<boolean>(position !== undefined);

  useEffect(() => {
    if (position === BOUNDARY.INSIDE && isFirstLoad) {
      setFirstLoad(false);
    }
  }, [position]);

  return isFirstLoad;
};
