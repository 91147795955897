import { css } from 'emotion';
import { isEmpty } from 'ramda';
import React, { useCallback, useEffect, useContext } from 'react';
import { Loader } from 'semantic-ui-react';

import BusinessTypesPanel from 'components/UI/BusinessTypesPanel';
import OpenFiltersPanel from 'components/UI/OpenFiltersPanel';
import { useHeader } from 'components/UI/Wrapper/Header/header.context';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import PipelineAttainmentWidget from 'components/dashboard/Pipeline/PipelineDashboard/PipelineAttainmentWidget';
import PipelineCreationWidget from 'components/dashboard/Pipeline/PipelineDashboard/PipelineCreationWidget';
import {
  NewPipelineCreatedWidget,
  PipelineByCloseDateWidget,
} from 'components/dashboard/Pipeline/PipelineDashboard/PipelineWidget';
import * as styles from 'components/dashboard/Pipeline/PipelineDashboard/styles';
import { IProps } from 'components/dashboard/Pipeline/PipelineDashboard/types';

export const businessTypesPanelWrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bu-white);
  border-bottom: 1px solid var(--bu-gray-400);
  margin: 0px -20px 0px -20px;
  padding: 0px 20px 0px 20px;
`;

const containerWrapper = css`
  height: 100%;
  background-color: var(--bu-primary-100);
`;

const PipelineDashboard: React.FC<IProps> = ({
  selectedBusinessType,
  filters,
  toggleFilters,
  tabPartition = 'pipeline',
  isRequiredDataReady,
}) => {
  const { setPartition, clearContext } = useHeader();

  useEffect(() => {
    setPartition(tabPartition);

    AnalyticsTracker.event(
      {},
      {
        action: 'Open',
        category: 'Pipeline',
        label: 'Dashboard page',
      }
    );

    return () => {
      clearContext();
    };
  }, []);

  const handleClickWrapper = useCallback(() => {
    toggleFilters('topOpportunities', true);
  }, []);

  if (isEmpty(filters) || !isRequiredDataReady) {
    return <Loader active />;
  }

  return (
    <div onClick={handleClickWrapper}>
      <div className={containerWrapper}>
        <div className="container">
          <div className="container-dashboard">
            <div className={styles.stickyTop}>
              <OpenFiltersPanel tab="pipeline_dashboard" />
            </div>

            <div className={businessTypesPanelWrapper}>
              <BusinessTypesPanel tab="pipeline_dashboard" />
            </div>

            <div className={styles.widgetsContainer}>
              <NewPipelineCreatedWidget
                selectedBusinessType={selectedBusinessType}
                localStorageKeyPrefix="PipelineDashboard"
              />
              <PipelineByCloseDateWidget
                selectedBusinessType={selectedBusinessType}
                localStorageKeyPrefix="PipelineDashboard"
              />
              <PipelineCreationWidget
                selectedBusinessType={selectedBusinessType}
              />
              <PipelineAttainmentWidget
                selectedBusinessType={selectedBusinessType}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PipelineDashboard;
