import { css } from 'emotion';

export const box = css`
  background: var(--bu-white);
  width: 50vw;
  border-radius: 4px;
  overflow: hidden;

  @media only screen and (max-width: 1200px) {
    width: 70vw;
  }
`;

export const box_body = css`
  padding: 20px 30px;
  overflow: auto;
  max-height: 70%;
  width: 100%;
`;

export const header_modal = css`
  font-size: 18px;
  color: #4a4a4a;
  font-family: var(--bu-font-medium);
  border-bottom: 1px solid var(--bu-gray-300);
  overflow: hidden;
  display: grid;
  grid-template-columns: 11fr 60px;
`;

export const header_modal_title = css`
  font-family: var(--bu-font-regular);
  text-align: left;
  padding: 18px 21px;
  display: inline-block;
`;

export const dimmer = css`
  > .content {
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

export const modal = css`
  label: modal;
  margin: 0 2.5% !important;
  color: #000000;
`;

export const modalContent = css`
  border-radius: 4px;
  min-height: 0 !important;
  max-height: calc(80vh) !important;
`;

export const header_modal_close_box = css`
  border-left: 1px solid var(--bu-gray-500);
`;

export const closeButton = css`
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  display: block;

  i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: color 0.4s;
    cursor: pointer;
    color: var(--bu-gray-900);
    font-size: var(--bu-font-size-big);
  }
`;
