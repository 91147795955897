import { useQuery } from '@tanstack/react-query';
import { fetchSubValueList } from 'api/RevBiDropdownConfigs';

import { BIWidget } from 'components/dashboard/Metrics/metrics.types';

export const useGetSubValueList = (widget: BIWidget) => {
  const { data, isError, isLoading } = useQuery({
    queryKey: ['SubValues', widget._id],
    queryFn: () => fetchSubValueList(),
  });

  return {
    data,
    isError,
    isLoading,
  };
};
