import { css } from 'emotion';
import React from 'react';
import { Popup } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuDropdown, {
  BuDropdownItem,
  BuDropdownItemContainer,
} from 'components/UI/BuDropdown';
import BuIcon from 'components/UI/BuIcon';
import Tooltip from 'components/UI/common/Tooltip';
import {
  ActionDropdownText,
  ControlsContainer,
  WidgetTitleAction,
} from 'components/dashboard/Metrics/Widget/Header/Actions/styles';

const popup = css`
  &.ui.popup {
    max-width: 480px;
  }
`;

interface Props {
  isReportViewWidget?: boolean;
  widgetId?: string;
  tooltip?: React.ReactNode;
  onEditWidget?: (widgetId: string) => void;
  onCloneWidget?: (widgetId: string) => void;
  onRemoveWidget?: (widgetId: string) => void;
}

export const WidgetActions: React.FC<Props> = ({
  isReportViewWidget = false,
  widgetId = '',
  tooltip,
  onEditWidget,
  onCloneWidget,
  onRemoveWidget,
}) => (
  <ControlsContainer>
    {isReportViewWidget && (
      <Popup
        basic
        className={popup}
        hoverable
        position={'bottom left'}
        trigger={
          <span>
            {
              <BuIcon
                name={BoostUpIcons.BadgeInfoOutline}
                color="var(--bu-gray-600)"
              />
            }
          </span>
        }
        content={tooltip}
        header="Report Definition"
      />
    )}

    {(onEditWidget || onCloneWidget) && (
      <WidgetTitleAction>
        <Tooltip tooltip="Show Actions" position="top center" hoverable>
          <BuDropdown
            label={<BuIcon name={BoostUpIcons.MenuHoriz} />}
            icon
            noDropdownIcon
            borderless
            placement="bottom-end"
          >
            {(hide) => (
              <BuDropdownItemContainer>
                {onEditWidget && (
                  <BuDropdownItem
                    data-testing="widget-edit"
                    onClick={() => {
                      onEditWidget(widgetId);
                      hide();
                    }}
                  >
                    {<ActionDropdownText>Edit</ActionDropdownText>}
                  </BuDropdownItem>
                )}
                {onCloneWidget && (
                  <BuDropdownItem
                    data-testing="widget-clone"
                    onClick={() => {
                      onCloneWidget(widgetId);
                      hide();
                    }}
                  >
                    {<ActionDropdownText>Clone</ActionDropdownText>}
                  </BuDropdownItem>
                )}
                {onRemoveWidget && (
                  <BuDropdownItem
                    data-testing="widget-remove"
                    onClick={() => {
                      onRemoveWidget(widgetId);
                      hide();
                    }}
                  >
                    {<ActionDropdownText>Remove</ActionDropdownText>}
                  </BuDropdownItem>
                )}
              </BuDropdownItemContainer>
            )}
          </BuDropdown>
        </Tooltip>
      </WidgetTitleAction>
    )}
  </ControlsContainer>
);
