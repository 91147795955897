import styled from '@emotion/styled';

export const ControlsContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  p: {
    fontSize: '18px',
    margin: '0px',
  },
  i: {
    fontSize: '20px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  paddingLeft: '16px',
  justifyContent: 'space-between',
  maxHeight: '70px',
});

export const WidgetTitleAction = styled.div({
  flexShrink: 0,
  'i.bu-icon.bu-icon-more': {
    transform: 'rotate(90deg)',
    fontSize: '16px',
  },
  '> span >Button:hover': {
    border: 0,
  },
});

export const ActionDropdownText = styled.div({ minWidth: '60px' });
