import styled from '@emotion/styled';
import { isNil } from 'ramda';
import React, { FC } from 'react';

import DeltaValue from 'components/UI/DealsFlatTableTS/Table/Row/DeltaValue';
import EditableText from 'components/UI/EditableText';
import { currencyFormatter } from 'components/UI/common/TypedTable/formatters';
import { OppSplitTooltip } from 'components/UI/common/TypedTable/renderers/common/OppSplitTooltip';
import styles from 'components/deal/styles';
import { DispatchProps } from 'components/deal/types';

type Props = {
  id: string;
  field: string;
  value: string | number | Common.DeltaValueNumber | Common.DeltaValueString;

  exchangeRate?: number;
  currency?: string;
  splittedFieldName?: string | null;
  companyCurrency: string;
  isMulticurrencyEnabled?: boolean;
  accountExecutive?: string;
  isEditDisabled: (value: string) => boolean;
  changeDeal: DispatchProps['changeDeal'];
};

const MoneyValueCont = styled.div({
  display: 'flex',
  gap: '4px',
  alignItems: 'center',
});

export const MoneyField: FC<Props> = ({
  id,
  field,
  value = 0,
  exchangeRate,
  currency,
  splittedFieldName,
  companyCurrency,
  isMulticurrencyEnabled,
  accountExecutive = '',
  isEditDisabled,
  changeDeal,
}) => {
  const formatter = currencyFormatter(
    companyCurrency,
    0,
    isMulticurrencyEnabled
  );

  const formattedValue = formatter(+value, currency, exchangeRate);
  const displayValue = typeof value === 'number' ? formattedValue : '-';
  const OppSplitColumnTooltip = splittedFieldName && (
    <OppSplitTooltip
      dealId={id}
      fieldName={splittedFieldName}
      rowUserName={accountExecutive}
    />
  );

  const isEditValue = !isNil(isEditDisabled) && isEditDisabled(field);

  return (
    <article
      id={`deal-container-header-tabs-item-${field.replaceAll(' ', '')}`}
      className="deal-header-tabs_item"
    >
      {field}
      <div className={styles.amountInlineStyles}>
        {isEditValue ? (
          <span className={styles.nonEditableField}>
            {OppSplitColumnTooltip}
            {displayValue}
          </span>
        ) : (
          <DeltaValue
            isForecast={false}
            isCurrency
            fieldName={`deal ${field}`}
            data={value as Common.DeltaValueNumber | Common.DeltaValueString}
            formatter={(value) => formatter(+value, currency, exchangeRate)}
          >
            <MoneyValueCont>
              {OppSplitColumnTooltip}
              <EditableText
                type="currency"
                currencyCode={currency}
                value={value as string}
                onEdit={(value) =>
                  changeDeal(id, { [field.toLowerCase()]: value })
                }
                forceRefresh={false}
                formatter={(value: string) =>
                  value !== 'Not Available'
                    ? formatter(+value, currency, exchangeRate)
                    : value
                }
                showIcon
              />
            </MoneyValueCont>
          </DeltaValue>
        )}
      </div>
    </article>
  );
};
