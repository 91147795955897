import { USER_ROLES } from 'common/constants';
import { Target } from 'reducers/sellerTargetsReducer/types';

export interface IReduxState {
  targets: Target[];
}

export const IMPORT_TARGETS_NOT_ALLOWED_ROLES = [
  USER_ROLES.ACCOUNT_EXECUTIVE,
  USER_ROLES.ACCOUNT_MANGER,
  USER_ROLES.SALES_ENGINEER,
  USER_ROLES.SALES_ENGINEER_MANAGER,
  USER_ROLES.OTHER,
  USER_ROLES.CUSTOMER_SUCCESS_MANAGER,
  USER_ROLES.BDR,
  USER_ROLES.DIRECTOR_OF_ACCOUNTS,
  USER_ROLES.DIRECTOR_OF_CUSTOMER,
];
