import { CACHE, CREATE, READY } from '../actions/actionTypes';
import errorHandler from '../helpers/errorHandler';
import axios, { CancelToken } from 'axios';
import hash from 'object-hash';
import * as R from 'ramda';
import { call, put, delay } from 'redux-saga/effects';

import { cancellationWhiteList } from 'common/constants';

const cancelToken = CancelToken;

const ongoingRequests = {};

export function* createCached(action) {
  yield put(action.loading());
  const source = cancelToken.source();

  try {
    const body = R.compose(R.defaultTo({}), R.propOr({}, 'data'))(action);

    if (
      ongoingRequests[action.type] &&
      cancellationWhiteList.includes(action.type)
    ) {
      // console.warn(`REQUEST TO ${action.url} WAS CANCELLED`);
      // console.warn('BODY:', body);
      ongoingRequests[action.type].cancel();
    }
    ongoingRequests[action.type] = source;

    const hashKey = hash({ url: action.url, body });
    const response = yield call(axios.post, action.url, body, {
      cancelToken: source.token,
    });

    yield put({
      type: CACHE + CREATE,
      key: hashKey,
      data: response.data.data,
    });

    yield put(action.success(response.data));
    ongoingRequests[action.type] = null;

    yield delay(5000);

    yield put({ type: action.type + READY });
  } catch (e) {
    const nextUrl = errorHandler(e);

    if (nextUrl) {
      yield put(nextUrl);
    }

    yield put(action.error(e));

    ongoingRequests[action.type] = null;
  }
}
