import classNames from 'classnames';
import React, { useState } from 'react';

import BuConfirmationPopup from 'components/UI/BuConfirmationPopup';
import CloseButton from 'components/UI/CloseButton';
import UsersAddManuallyTable from 'components/settings/ManageUsers/UsersAddManually/UsersAddManuallyTable';
import * as s from 'components/settings/ManageUsers/UsersAddView/styles';
import { ModalRouteProps } from 'navigation/types';

const UsersAddManually: React.FC<ModalRouteProps> = ({ onClose }) => {
  const [isConfirmationVisible, setConfirmationVisible] = useState(false);

  const closeButtonClickHandler = () => {
    setConfirmationVisible(true);
  };

  const onConfirm = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <div className={classNames(s.container, s.fullscreen)}>
      <div className={s.header}>
        <CloseButton
          onClick={closeButtonClickHandler}
          style={{ marginRight: 10 }}
        />
        Add Users Manually
      </div>

      <UsersAddManuallyTable />

      <BuConfirmationPopup
        cancelText="No"
        confirmText="Yes"
        headerText="Confirmation Required!"
        isOpen={isConfirmationVisible}
        onClose={() => setConfirmationVisible(false)}
        onConfirm={onConfirm}
      >
        <p>Unsaved data will be lost.</p>
      </BuConfirmationPopup>
    </div>
  );
};

export default UsersAddManually;
