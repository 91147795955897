import React, { useMemo } from 'react';

import {
  conditionElement,
  conditionsList,
  forecastConditionElement,
  labelParagraph,
} from 'components/dashboard/Metrics/common/MetricAdvancedOptionsList/styles';
import {
  FORECAST_AMOUNT_ACCURACY,
  FORECAST_SUBMISSION,
} from 'components/dashboard/Metrics/constants';
import { getPeriodPrettyPrint } from 'components/dashboard/Metrics/metrics.helpers';

interface Props {
  readonly metricObject: string;
  readonly linkedDateField: string;
  readonly managerAggregationType?: string;
  readonly isCumulativeSum?: boolean;
  readonly cumulativeSumPeriod?: string;
  readonly forecastSubmissionMetric?: string;
  readonly forecastAmountAccuracyCalculation?: string;
}

export const MetricAdvancedOptionsList: React.FC<Props> = ({
  metricObject,
  managerAggregationType,
  linkedDateField,
  isCumulativeSum = false,
  cumulativeSumPeriod,
  forecastSubmissionMetric,
  forecastAmountAccuracyCalculation,
}) => {
  const isForecastSubmission = metricObject === FORECAST_SUBMISSION;
  const hasCumulativeSum = Boolean(isCumulativeSum && cumulativeSumPeriod);
  const hasForecastAmountAccuracyCalculation = Boolean(
    isForecastSubmission &&
      forecastAmountAccuracyCalculation &&
      forecastSubmissionMetric === FORECAST_AMOUNT_ACCURACY
  );
  const managerAggregationTypeLabel = useMemo(() => {
    const selfReportingLabel = isForecastSubmission
      ? 'Self Forecast Submissions'
      : 'Self Reporting';
    const directReportAggregationLabel = isForecastSubmission
      ? 'Team Forecast Submissions'
      : 'Direct Report Aggregation';

    switch (managerAggregationType) {
      case 'self_revenue':
        return selfReportingLabel;
      case 'total_team_revenue':
        return 'Total Team Aggregation';
      case 'direct_report_revenue':
        return directReportAggregationLabel;
      default:
        return '';
    }
  }, [isForecastSubmission, managerAggregationType]);

  return (
    <>
      {(hasCumulativeSum || hasForecastAmountAccuracyCalculation) && (
        <>
          <p className={labelParagraph}>Show:</p>
          <ul className={conditionsList}>
            {hasCumulativeSum && (
              <li className={conditionElement}>
                Cumulative Sum {getPeriodPrettyPrint(cumulativeSumPeriod || '')}
              </li>
            )}

            {hasForecastAmountAccuracyCalculation && (
              <li className={forecastConditionElement}>
                {forecastAmountAccuracyCalculation} Values
              </li>
            )}
          </ul>
        </>
      )}

      {!!managerAggregationTypeLabel && (
        <>
          <p className={labelParagraph}>Data Aggregation:</p>
          <ul className={conditionsList}>
            <li className={conditionElement}>{managerAggregationTypeLabel}</li>
          </ul>
        </>
      )}

      {!!linkedDateField && (
        <>
          <p className={labelParagraph}>Linked Date Field:</p>
          <ul className={conditionsList}>
            <li className={conditionElement}>{linkedDateField}</li>
          </ul>
        </>
      )}
    </>
  );
};
