import React, { useEffect, useState } from 'react';

import { Conditions } from 'components/dashboard/Metrics/Create/Conditions/Conditions';
import { ConditionsContainer } from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/FunnelDefinition/FunnelConditions/styles';
import { NOT_SAVED_METRIC } from 'components/dashboard/Metrics/Create/constants';
import { isMetricFiltersValid } from 'components/dashboard/Metrics/Create/utils';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import {
  AggregationFunction,
  MetricType,
} from 'components/dashboard/Metrics/enums';
import {
  BIMetricSimpleNewborn,
  BIMetricsQueryFilter,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';

interface OwnProps {
  readonly widget: BIWidget;
  onCompleteOptions: (changes: Partial<BIWidget>) => void;
}

export const FunnelConditions: React.FC<OwnProps> = ({
  widget,
  onCompleteOptions,
}) => {
  const [metric, setMetric] = useState<BIMetricSimpleNewborn>({
    _id: NOT_SAVED_METRIC,
    analysis_type: AnalysisType.FUNNEL,
    aggregation_function: AggregationFunction.Sum,
    filters: widget.funnel_conditions ?? [],
    metadata: { type: MetricType.Simple },
    name: 'Funnel',
    object: 'opportunity',
  });

  const handleChangesCondition = (
    complete: boolean,
    filters: BIMetricsQueryFilter[]
  ) => {
    const newMetric = {
      ...metric,
      filters,
    };

    if (complete) {
      setMetric(newMetric as BIMetricSimpleNewborn);
    }
  };

  useEffect(() => {
    if (metric && isMetricFiltersValid(metric)) {
      onCompleteOptions({
        funnel_conditions: metric.filters,
      });
    }
  }, [metric.filters]);

  if (metric === undefined) {
    return <></>;
  }

  return (
    <ConditionsContainer>
      <Conditions
        title="Entry Conditions"
        description="Set of filters that will be applied always and using the live data values of the objects."
        metric={metric}
        onCompleteConditions={handleChangesCondition}
        columnFields={['opportunity_line_item']}
      />
    </ConditionsContainer>
  );
};
