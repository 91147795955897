export const statusOptions = [
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'disabled',
    label: 'Disabled',
  },
];

export const initialState = {
  id: 1,
  name: '', // required
  email: '', // required
  title: '',
  role: '', // required
  manager: '',
  status: 'active', // required
  isValid: false,
};

export const configTable = [
  {
    id: 'name',
    column: 'name',
    label: 'Name',
    require: true,
  },
  {
    id: 'email',
    column: 'email',
    label: 'Email',
    require: true,
  },
  {
    id: 'title',
    column: 'title',
    label: 'Title',
    require: false,
  },
  {
    id: 'role',
    column: 'role',
    label: 'Role',
    require: true,
  },
  {
    id: 'manager',
    column: 'manager',
    label: 'Manager',
    require: false,
  },
  {
    id: 'status',
    column: 'status',
    label: 'Status',
    require: true,
  },
];
