import { useQuery } from '@tanstack/react-query';
import { GetAccountsParams, getAccounts } from 'api/Accounts';
import { useUpdateAccountMutation } from 'components/hooks/useAccounts';
import { useGetSettingsForTable } from 'components/hooks/useSettingsForTable';
import { useState } from 'react';

const ACCOUNTS_TABLE_SETTINGS = {
  collectionName: 'account',
  tableName: 'Accounts',
};

export type UseManageAccountsModalParams = Omit<
  GetAccountsParams,
  'page_number' | 'sort'
>;
export const useManageAccountsModal = (
  partialParams: UseManageAccountsModalParams
) => {
  const { data: tableConfig, isLoading: isTableSettingsLoading } =
    useGetSettingsForTable(ACCOUNTS_TABLE_SETTINGS);

  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const handlePaginationChange = (page: number) => {
    setPageNumber(page);
  };

  const defaultSortOrder = tableConfig?.order.object_field
    ? `${tableConfig?.order.direction === -1 ? '-' : ''}${
        tableConfig?.order.object_field
      }`
    : '';
  const [userSortOrder, setUserSortOrder] = useState<string>();

  const sortOrder = userSortOrder || defaultSortOrder;

  const handleSort = (sort?: string) => {
    setUserSortOrder(sort);
  };

  const params: GetAccountsParams = {
    ...partialParams,
    page_number: pageNumber - 1,
    sort: sortOrder,
  };
  const { data: accountsData, isLoading: isAccountsLoading } = useQuery({
    queryKey: ['accounts_modal', params],
    queryFn: () => getAccounts(params),
    enabled: !!tableConfig,
    onSuccess: (data) => {
      if (!totalCount) {
        setTotalCount(data.total_count ?? 0);
      }
    },
  });

  const updateAccountMutation = useUpdateAccountMutation([
    'accounts_modal',
    params,
  ]);

  return {
    accountsCount: totalCount,
    accounts: accountsData?.accounts,
    isLoading: isAccountsLoading || isTableSettingsLoading,
    accountsColumns: tableConfig?.columns,
    updateAccount: updateAccountMutation.mutateAsync,
    pageNumber,
    handlePaginationChange,
    sortOrder,
    handleSort,
  };
};
