import styled from '@emotion/styled';
import React from 'react';

import BuRadio from 'components/UI/BuRadio';
import { MetricType } from 'components/dashboard/Metrics/enums';
import { FlexRow } from 'components/dashboard/Metrics/metrics.styles';
import { MetricCreateSubTitle } from 'components/dashboard/Metrics/metrics.styles';

const MetricTypeSelect = styled.div(
  (props: { selected?: boolean; disabled: boolean }) => ({
    border: '1px solid',
    borderColor: props.selected
      ? `var(--bu-primary-500)`
      : `var(--bu-gray-500)`,
    borderRadius: '4px',
    cursor: props.disabled ? 'not-allowed' : 'pointer',
    pointerEvents: props.disabled ? 'none' : 'initial',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: '8px',
    width: '100%',
    backgroundColor: 'var(--bu-white)',
    '>div .ui.radio.checkbox label': {
      fontSize: '16px',
      paddingLeft: '26px',
      marginBottom: '5px',
    },
  })
);

const MetricTypeSelectDescription = styled.div({
  paddingLeft: '26px',
  color: `var(--bu-gray-500)`,
});

interface Props {
  editing: boolean;
  readonly metricType: MetricType;
  readonly updateMetricType: (type: MetricType) => void;
}

export const MetricTypeSelection: React.FC<Props> = ({
  editing = false,
  metricType,
  updateMetricType,
}) => (
  <FlexRow
    cssProps={{
      marginBottom: '24px',
      flexDirection: 'column',
    }}
    data-testing="type-of-metric-section"
  >
    <MetricCreateSubTitle data-testing="Type-of-metric">
      Type of metric
    </MetricCreateSubTitle>
    <FlexRow cssProps={{ gap: '8px' }}>
      <MetricTypeSelect
        selected={metricType === MetricType.Simple}
        onClick={() =>
          metricType === MetricType.Simple
            ? () => {}
            : updateMetricType(MetricType.Simple)
        }
        disabled={editing}
      >
        <div>
          <BuRadio
            label="Simple"
            value={MetricType.Simple}
            checked={metricType === MetricType.Simple}
            disabled={editing}
          />
        </div>
        <MetricTypeSelectDescription>
          Single field aggregation
        </MetricTypeSelectDescription>
      </MetricTypeSelect>
      <MetricTypeSelect
        selected={metricType === MetricType.Formula}
        onClick={() =>
          metricType === MetricType.Formula
            ? () => {}
            : updateMetricType(MetricType.Formula)
        }
        disabled={editing}
      >
        <div>
          <BuRadio
            label="Formula"
            checked={metricType === MetricType.Formula}
            value={MetricType.Formula}
            disabled={editing}
          />
        </div>
        <MetricTypeSelectDescription>
          Formula with 2+ metrics
        </MetricTypeSelectDescription>
      </MetricTypeSelect>
    </FlexRow>
  </FlexRow>
);
