import { css } from 'emotion';

export const toastStyles = (isLarge: boolean = false) => css`
  padding: 0;
  font-size: 14px;
  min-height: 50px;
  border-radius: 4px;
  color: #4a4a4a;
  background-color: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4);
  width: 400px;

  &:before {
    width: 50px;
    font-family: Icons;
    padding: ${isLarge ? '40px 16px' : '16px'};
    color: #fff;
    font-size: 20px;
    margin-right: 18px;
    height: 100%;
    text-align: center;
  }

  &.Toastify__toast--success:before {
    content: '\\f058';
  }
  &.Toastify__toast--warning:before {
    content: '\\f017';
  }
  &.Toastify__toast--error:before {
    content: '\\f06a';
  }

  .Toastify__progress-bar--success,
  &.Toastify__toast--success:before {
    background-color: #00c6ae;
  }

  .Toastify__progress-bar--warning,
  &.Toastify__toast--warning:before {
    background-color: #f7b500;
  }

  .Toastify__progress-bar--error,
  &.Toastify__toast--error:before {
    background-color: #f05a30;
  }
`;
