import styled from '@emotion/styled';

export const MetricOptionsColumn = styled.div({
  width: '520px',
  minWidth: '520px',
  display: 'flex',
  flexDirection: 'column',
  borderRight: 'solid var(--bu-gray-400) 1px',
  overflowY: 'auto',
  maxHeight: 'calc(100vh - 115px)',
});

export const MetricOptionSection = styled.div({
  flexGrow: 1,
  overflowX: 'visible',
  borderTop: 0,
  padding: '16px',
});

export const DefinitionsTitle = styled.p({
  fontSize: '16px',
  fontWeight: 500,
  color: 'var(--bu-gray-900)',
  paddingBottom: '24px',
  marginBottom: '0px',
});
