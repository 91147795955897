import React, { FC } from 'react';

import {
  conditionsList,
  labelParagraph,
} from 'components/dashboard/Metrics/common/ConditionsDescription/styles';
import { ConditionsDescriptionList } from 'components/dashboard/Metrics/common/ConditionsDescriptionList/ConditionsDescriptionList';
import { BIMetricsQueryFilter } from 'components/dashboard/Metrics/metrics.types';

interface Props {
  readonly filters: BIMetricsQueryFilter[];
}

export const ConditionsDescription: FC<Props> = ({ filters }) => (
  <>
    <p className={labelParagraph}>Where:</p>
    <ul className={conditionsList}>
      <ConditionsDescriptionList filters={filters} />
    </ul>
  </>
);
