import isNil from 'lodash/isNil';
import orderBy from 'lodash/orderBy';
import reduce from 'lodash/reduce';
import React, { useState } from 'react';
import { Input, Divider } from 'semantic-ui-react';

import CheckboxInput from 'components/UI/Checkbox';
import TeamCheckbox from 'components/UI/FilterPanelUsers/TeamCheckbox';
import {
  UserCheckbox,
  IAccumulator,
} from 'components/UI/FilterPanelUsers/types';
import {
  getHierarchyDropdownStyle,
  list_item,
  no_result_box,
  panel_dropdown_divider,
  panel_dropdown_input,
} from 'components/dashboard/Metrics/common/HierarchicalUserFilter/PanelUsers/styles';

const reorderUsers = (users: UserCheckbox[]): UserCheckbox[] => {
  if (users.length === 1) {
    users[0].team = reorderUsers(users[0].team);

    return users;
  }

  const output = users.reduce(
    (accumulator: IAccumulator, currentValue: UserCheckbox): IAccumulator => {
      if (!accumulator.leftPart) {
        accumulator.leftPart = [];
      }

      if (!accumulator.rightPart) {
        accumulator.rightPart = [];
      }

      if (currentValue.team && currentValue.team.length > 0) {
        accumulator.leftPart.push(currentValue);
      } else {
        accumulator.rightPart.push(currentValue);
      }

      return accumulator;
    },
    { leftPart: [], rightPart: [] }
  );

  output.leftPart = orderBy(output.leftPart, ['name'], ['asc']);
  output.rightPart = orderBy(output.rightPart, ['name'], ['asc']);

  const sortUsers = new Set([...output.leftPart, ...output.rightPart]);

  sortUsers.forEach((user) => {
    if (user.team.length > 0) user.team = reorderUsers(user.team);
  });

  return Array.from(sortUsers);
};

const prepareUsersToOptions = (
  options: Filters.UserFilterElement[],
  values: Filters.Checkbox[]
): UserCheckbox[] => {
  const checkedValues = values.filter((i) => i.checked).map((i) => i.value);

  const isChecked = (value: string, checkedValues: string[]) =>
    checkedValues.findIndex((v) => v === value) !== -1;

  function iterator(opt: Filters.UserFilterElement): UserCheckbox {
    const result: UserCheckbox = {
      email: opt.email,
      name: opt.name,
      id: opt.id,
      role: opt.role,
      team: isNil(opt.team) ? [] : prepareUsersToOptions(opt.team, values),
      checked: isChecked(opt.email, checkedValues),
    };

    if (!isNil(result.team)) {
      result.team = prepareUsersToOptions(result.team, values);
    }

    return result;
  }

  const checkboxes = options
    .filter((item) => !Array.isArray(item))
    .map(iterator);

  return reorderUsers(checkboxes);
};

const teamFlatter = (team: UserCheckbox[]): UserCheckbox[] =>
  team.reduce((acc: UserCheckbox[], member: UserCheckbox) => {
    let result = acc.concat(member);

    if (member.team !== undefined) {
      result = result.concat(teamFlatter(member.team));
    }

    return result;
  }, []);

type Props = {
  users: Filters.UserFilterElement[];
  config: Filters.Config;
  onChange: (values: Filters.PersistValue[], withReset?: boolean) => void;
};

export const PanelUsers: React.FC<Props> = ({ users, config, onChange }) => {
  const [maxHierarchyLevel, setMaxHierarchyLevel] = useState(0);
  const [searchRequestState, setSearchRequestState] = useState('');
  const { checkedAll: isAllSelected, withShowAll } = config;
  const reorderedUsers = prepareUsersToOptions(users, config.values);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value, checked: originalChecked, name } = e.target;

    const checked = isAllSelected ? true : originalChecked;
    const values: Filters.PersistValue[] = [];

    const changeSingle = (
      items: Filters.UserFilterElement[],
      current: boolean
    ) => {
      items.forEach((item) => {
        const nCurrent =
          current || (item.email === value && item.name === name);

        if (nCurrent) {
          values.push({ id: item.email, checked });
        }

        if (item.team) {
          changeSingle(item.team, nCurrent);
        }
      });
    };

    changeSingle(users, false);
    onChange(values, isAllSelected);
  }

  function handleChangeAll(e: React.ChangeEvent<HTMLInputElement>) {
    onChange(
      config.values.map((item) => ({
        id: item.value,
        checked: e.target.checked,
      }))
    );
  }

  function renderCheckboxes(checkboxes: UserCheckbox[]) {
    const result = checkboxes.map((el: UserCheckbox, i: number) => {
      const { checked, id } = el;
      const flatTeamMembers = teamFlatter(el.team);
      const isAllMembersChecked = !flatTeamMembers.some((el) => !checked);

      return (
        <TeamCheckbox
          checkbox={el}
          defaultExpanded={checkboxes.length > 1}
          handleChange={handleChange}
          isAllChecked={isAllSelected}
          isAllMembersChecked={isAllMembersChecked}
          isFirstLevel
          key={`${i}${id}`}
          maxLevel={maxHierarchyLevel}
          setMaxLevel={setMaxHierarchyLevel}
        />
      );
    });

    if (withShowAll) {
      result.unshift(
        <div className={list_item} key={`${config.title}_select_all`}>
          <CheckboxInput
            strong
            id={`${config.title}_select_all`}
            name="Select All"
            value="select_all"
            onChange={handleChangeAll}
            checked={isAllSelected}
            label="All"
          />
        </div>
      );
    }

    return result;
  }

  function filterCheckBoxes(options: UserCheckbox[], request: string) {
    if (request === '') return options;

    const flatOptions = options.concat(
      reduce(
        options,
        (acc: UserCheckbox[], member: UserCheckbox) =>
          member.team ? acc.concat(teamFlatter(member.team)) : acc,
        []
      )
    );

    return flatOptions.filter(
      (member: UserCheckbox) =>
        (member.name &&
          member.name.toLowerCase().indexOf(request.toLowerCase()) !== -1) ||
        (member.email &&
          member.email.toLowerCase().indexOf(request.toLowerCase()) !== -1)
    );
  }

  const filteredCheckboxes = filterCheckBoxes(
    reorderedUsers,
    searchRequestState
  );

  return (
    <div className="collapse in" id="topics">
      {config.searchable && (
        <>
          <Input
            className={panel_dropdown_input}
            iconPosition="left"
            icon="search"
            placeholder="Search..."
            onChange={(e, { value }) => setSearchRequestState(value)}
            value={searchRequestState}
            data-testing="txt_field"
          />
          <Divider className={panel_dropdown_divider} />
        </>
      )}

      <div className={getHierarchyDropdownStyle(maxHierarchyLevel)}>
        {renderCheckboxes(filteredCheckboxes)}
      </div>

      {searchRequestState !== '' && filteredCheckboxes.length === 0 && (
        <div className={no_result_box}>
          <p>No results found.</p>
          <span onClick={() => setSearchRequestState('')}>reset search</span>
        </div>
      )}
    </div>
  );
};
