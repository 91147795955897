import { Action, combineReducers, Reducer } from 'redux';

import * as t from 'actions/actionTypes';
import { TokenResponse, ServiceResponse } from 'actions/externalApiActions';

export type TokensState = {
  tokens: TokenResponse[];
  errors: {
    message: string | undefined;
    status: boolean;
  };
  success: boolean;
  isDeleted: boolean;
};

export type ServicesState = {
  services: ServiceResponse[];
};

export interface TokensAction<T = string> extends Action {
  type: string;
  payload?: TokensPayload;
  error?: T;
}

export interface ServicesAction<T = string> extends Action {
  type: string;
  payload?: ServicesPayload;
  error?: T;
}

export type TokensPayload = {
  tokens?: TokenResponse[];
  token?: string;
};

export type ServicesPayload = {
  services: ServiceResponse[];
};

const initialTokensState: TokensState = {
  tokens: [],
  errors: {
    message: '',
    status: false,
  },
  success: false,
  isDeleted: false,
};

const initialServicesState: ServicesState = {
  services: [],
};

const apiTokens: Reducer<TokensState, TokensAction> = (
  state = initialTokensState,
  action
) => {
  switch (action.type) {
    case t.SETTINGS + t.API + t.TOKENS + t.GET + t.SUCCESS:
      return {
        ...state,
        tokens: action.payload?.tokens || [],
      };
    case t.SETTINGS + t.API + t.TOKENS + t.GET + t.ERROR:
      return { ...state, ...initialTokensState };
    case t.SETTINGS + t.API + t.TOKENS + t.UPDATE + t.ERROR:
      return { ...state, errors: { message: action.error, status: true } };
    case t.RESET + t.API + t.ERROR:
      return { ...state, errors: { message: '', status: false } };
    case t.SETTINGS + t.API + t.TOKENS + t.UPDATE + t.SUCCESS:
      return { ...state, success: true };
    case t.RESET + t.API + t.SUCCESS:
      return { ...state, success: false };
    case t.SETTINGS + t.API + t.TOKENS + t.REMOVE + t.SUCCESS:
      return { ...state, isDeleted: true };
    case t.RESET + t.API + t.REMOVE:
      return { ...state, isDeleted: false };
    default:
      return state;
  }
};

const apiServices: Reducer<ServicesState, ServicesAction> = (
  state = initialServicesState,
  action
) => {
  switch (action.type) {
    case t.SETTINGS + t.API + t.SERVICES + t.GET + t.SUCCESS:
      return {
        services: action.payload?.services || [],
      };
    default:
      return state;
  }
};

export const externalApiReducer = combineReducers({
  apiTokens,
  apiServices,
});
