import { css } from 'emotion';
import update from 'immutability-helper';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Grid, Menu } from 'semantic-ui-react';

import ColumnPill from 'components/UI/common/ColumnPill/ColumnPill';
import Scrolling from 'components/UI/common/Scrolling/Scrolling';
import ColumnFilterDropdown from 'components/settings/ManageTableColumns/ColumnFilterDropdown';
import ColumnOrderDropdown from 'components/settings/ManageTableColumns/ColumnOrderDropdown';
import {
  AvailableFieldEntry,
  OrderEntry,
  TableColumnData,
  TableColumnEntry,
  TableColumnUpdateData,
} from 'components/settings/ManageTableColumns/ManageTableColumns';

const container = css`
  display: flex;
  flex-direction: row;
  background-color: #edf0f2;
  border: 1px solid #dddddd;
`;

const columnContainer = css`
  padding: 10px;
  min-height: 47px;
`;

const menuCls = css`
  &.ui.menu {
    margin-left: 0;
    margin-right: 0;
    .item {
      text-transform: capitalize;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

interface IProps {
  availableFields: AvailableFieldEntry[];
  data: TableColumnData;
  status: 'success';
  onChange: (table: TableColumnUpdateData) => void;
  order: OrderEntry;
  canChangeDefaultSorting?: boolean;
}

const TableColumnConfiguration: React.FC<IProps> = ({
  availableFields,
  data,
  onChange,
  order,
}: IProps) => {
  const [columns, setColumns] = useState<TableColumnEntry[]>([]);

  useEffect(() => {
    setColumns(data.columns || []);
  }, [data.columns]);

  const removeColumn = (field: AvailableFieldEntry) => {
    onChange({
      ...data,
      columns: columns.filter((column) => column !== field.display_name),
      order:
        data.order.object_field !== field.object_field
          ? `${data.order.direction === -1 ? '-' : ''}${
              data.order.object_field
            }`
          : '',
    });
  };

  const handleChangeCheckbox =
    (field: AvailableFieldEntry) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        onChange({
          ...data,
          columns: [...columns, field.display_name],
          order: `${data.order.direction === -1 ? '-' : ''}${
            data.order.object_field
          }`,
        });
      } else {
        removeColumn(field);
      }
    };

  const handleChangeDefaultOrderColumn = (newSortOrder: string) => {
    onChange({
      ...data,
      order: newSortOrder,
    });
  };

  const handleRemove = (name: TableColumnEntry) => () => {
    const availableField = availableFields.find(
      (field) => field.display_name === name
    );
    removeColumn(
      availableField || {
        display_name: name,
        object_field: '',
        sortable: true,
      }
    );
  };

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = columns[dragIndex];
      const cloneColumns = update(columns, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      });
      setColumns(cloneColumns);
    },
    [columns]
  );

  const onDropEnd = () => {
    onChange({
      ...data,
      columns,
      order: `${data.order.direction === -1 ? '-' : ''}${
        data.order.object_field
      }`,
    });
  };

  const overrideList = [
    {
      name: 'forecast_analytics_change',
      title: 'Trends',
    },
    {
      name: 'forecast_analytics',
      title: 'Dashboard',
    },
    {
      name: 'calls',
      title: 'Completed Calls',
    },
    {
      name: 'deals_insights',
      title: 'Deals Insights',
    },
  ];
  const overrideItem = overrideList.find((item) => item.name === data.name);
  // If more tables are added the names should be updated on the back end so they are reflect was is wanted on the front end
  function getName(name: string) {
    switch (name) {
      case 'DealsDelta':
        return 'Deals Delta Popup';
      case 'ForecastSubmissionSettings':
        return 'Forecast Submission Include Deals Popup';
      default:
        return name.replace(/([A-Z])/g, ' $1').substring(1);
    }
  }

  const columnSelected = useMemo(
    () => `${order.direction == -1 ? '-' : ''}${order.object_field}`,
    [order.object_field, order.direction]
  );

  return (
    <section>
      <Menu borderless secondary size="small" className={menuCls}>
        <Menu.Item header>
          {overrideItem ? overrideItem.title : getName(data.name)}
        </Menu.Item>

        <Menu.Menu position="right">
          <Menu.Item>
            <ColumnOrderDropdown
              columns={availableFields.filter(
                (item) => item.sortable && columns.includes(item.display_name)
              )}
              onChange={handleChangeDefaultOrderColumn}
              selected={columnSelected}
            />
          </Menu.Item>
          <Menu.Item>
            <ColumnFilterDropdown
              onChange={handleChangeCheckbox}
              availableFields={availableFields}
              columns={columns}
            />
          </Menu.Item>
        </Menu.Menu>
      </Menu>

      <Grid>
        <Grid.Row>
          <Grid.Column>
            <DndProvider backend={HTML5Backend}>
              <div className={container}>
                <Scrolling width shadows>
                  <div className={columnContainer}>
                    {columns.map((column, index) => (
                      <ColumnPill
                        key={column}
                        id={column}
                        index={index}
                        text={column}
                        order={order}
                        onRemove={handleRemove(column)}
                        moveCard={moveCard}
                        onDropEnd={onDropEnd}
                      />
                    ))}
                    {columns.length === 0 && (
                      <ColumnFilterDropdown
                        onChange={handleChangeCheckbox}
                        availableFields={availableFields}
                        columns={columns}
                      />
                    )}
                  </div>
                </Scrolling>
              </div>
            </DndProvider>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </section>
  );
};

export default TableColumnConfiguration;
