import { css } from 'emotion';

import {
  fontHeading,
  fontDefault,
  fontDefaultDropdown,
} from 'assets/css/common';

export const fullscreen = css`
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;

  background-color: #fff;
  z-index: 990;
`;

export const container = css`
  height: 100%;

  color: #4a4a4a;
  font-size: 14px;
  line-height: 16px;
`;

export const centerContent = css`
  text-align: center;
  margin-bottom: 32px;
`;

export const exclamationIconStyle = css`
  margin-bottom: 18px;
  width: 40px;
  height: 40px;
`;

export const header = css`
  background-color: var(--bu-gray-100);

  height: 60px;
  padding: 20px;

  color: var(--bu-gray-900);

  font-family: var(--bu-font-medium);
  font-size: 18px;
  line-height: 21px;

  display: flex;
  flex-direction: row;
`;

export const mainContent = css`
  height: calc(
    100% - 60px - 60px - 10px
  ); // header - action buttons - marginTop
  margin-top: 10px;
`;

export const actionButtons = css`
  border-top: 1px solid var(--bu-gray-300);

  height: 60px;
  padding: 5px 22px;

  text-align: right;

  & button {
    margin: 8px;
  }
`;

export const userHeaderContainer = css`
  display: flex;

  flex-direction: row;

  margin: 10px 20px;

  & > .avatar {
    width: 50px;
    margin: 10px;
    text-transform: uppercase;

    color: #fff;
  }

  & > .details {
    margin: 0 10px;
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;

    & > .header {
      font-family: var(--bu-font-medium);
      color: var(--bu-gray-900);
      font-size: 16px;
      line-height: 19px;
    }
  }
`;

export const userDialContainer = css`
  margin: 10px;
  padding-left: 90px;

  & > .header {
    font-family: var(--bu-font-medium);
  }
`;

export const userDetailsContainer = css`
  border-top: 1px solid var(--bu-gray-300);
  padding: 10px;

  padding-left: 20px;

  & > .item {
    margin: 10px 0;

    display: flex;
    flex-direction: row;

    & > .lab {
      width: 100px;
      line-height: 38px;
      font-family: var(--bu-font-medium);
    }

    & > .control {
      flex: 1;
    }
  }
`;

export const modal_alert = css`
  max-width: 440px;
  padding: 20px 50px;
`;

export const modal_alert_btns = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const modal_alert_title = css`
  ${fontHeading};
  margin-bottom: 8px;
`;

export const modal_alert_text = css`
  line-height: 22px;
  ${fontDefault};
  color: var(--bu-gray-700);
`;

export const btn = css`
  padding: 8px 37px;
  margin: 0 10px;
  ${fontDefaultDropdown};
  border-radius: 2px;
  border: 1px solid var(--bu-gray-500);
  color: var(--bu-gray-900);
  background: var(--bu-white);
  font-weight: 800;
  cursor: pointer;

  &.active {
    border-color: transparent;
    background: var(--bu-primary-500);
    color: var(--bu-white);
  }

  &:focus {
    outline: none;
  }
`;
