import * as t from '../actions/actionTypes';

const tst = t.transcriptTypes;

const initialState = {
  callDetail: null,
  callSummary: null,
  isPlaying: false,
  filteredSnippets: [],
  selectedTopics: [],
  activeTopic: '',
  selectedSnippet: { snippet: null, index: null },
  selectedGroupType: null,
  selectedGroup: null,
  searchText: '',
  currentTimeOffset: null,
  searchIndex: null,
  playbackRate: 1,
  apiSuccess: null,
  apiError: null,
  isAddingTag: false,
  isErrorAddingTag: false,
  isTranscriptionOpen: 'Transcript',
  status: 'notAsked',
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  const reducer = {
    [tst.ADD_TAG]: () => ({
      ...state,
      callDetail: {
        ...state.callDetail,
        my_tags: [...state.callDetail.my_tags, action.tag],
      },
      addTagErrorResponse: null,
      isAddingTag: false,
      isErrorAddingTag: false,
    }),
    [tst.UPDATE_STATE]: () => ({ ...state, [action.key]: action.value }),
    [tst.RESET_ACTIVE_TOPIC]: () => ({
      ...state,
      activeTopic: '',
      searchIndex: null,
      selectedTopics: [],
    }),
    [tst.RESET_SEARCH]: () => ({
      ...state,
      searchText: '',
      filteredSnippets: [],
    }),
    [tst.RESET_STATE]: () => ({
      ...initialState,
    }),
    [t.TRANSCRIPT + t.GET + t.LOADING]: () => ({
      ...state,
      status: 'loading',
    }),
    [t.TRANSCRIPT + t.GET + t.SUCCESS]: () => ({
      ...state,
      callDetail: payload.data,
      status: 'success',
    }),
    [tst.RESET_SELECTION]: () => ({
      ...state,
      filteredSnippets: [],
      selectedSnippet: { snippet: null, index: null },
      selectedGroupType: null,
      selectedGroup: null,
      searchText: '',
      searchIndex: null,
    }),
    [tst.REMOVE_TAG]: () => ({
      ...state,
      callDetail: {
        ...state.callDetail,
        my_tags: state.callDetail.my_tags.filter(
          (tag) => tag.text !== action.text
        ),
      },
    }),
    [tst.TOGGLE_ADDING_TAG]: () => ({
      ...state,
      isAddingTag: !state.isAddingTag,
      isErrorAddingTag: false,
    }),
    [tst.CANCEL_ADDING_TAG]: () => ({
      ...state,
      isAddingTag: false,
      isErrorAddingTag: false,
    }),
    [tst.ADD_TAG_ERROR]: () => ({
      ...state,
      addTagErrorResponse: action.addTagErrorResponse,
      isAddingTag: true,
      isErrorAddingTag: true,
    }),
    [tst.RESET_TAG_ERROR]: () => ({
      ...state,
      addTagErrorResponse: null,
      isAddingTag: true,
      isErrorAddingTag: false,
    }),
    [tst.PLAY_TOPIC]: () => ({
      ...state,
      selectedTopics: action.times,
      activeTopic: action.name,
      filteredSnippets: [],
      searchText: '',
    }),
  };
  return Object.prototype.hasOwnProperty.call(reducer, type)
    ? reducer[type](state, action)
    : state;
}
