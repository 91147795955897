import { css } from 'emotion';

export const attendees_field = css`
  display: flex;
  flex-direction: row;
  padding: 3px 0;
`;

export const attendees_icon = css`
  height: 20px;
  width: 20px;
  margin-right: 5px;
`;

export const attendees_name = css`
  height: auto;
  width: fit-content;
  max-width: 250px;
  margin-right: 3px;
  color: #4a4a4a;
  font-family: var(--bu-font-regular);
  font-size: 14px;
  line-height: 19px;
`;

export const attendeeDeclined = css`
  label: attendee-declined;
  color: var(--bu-red-400);
  display: inline-block;
`;

export const attendeeAccepted = css`
  label: attendee-accepted;
  color: var(--bu-green-500);
  display: inline-block;
`;
