import styled from '@emotion/styled';
import { css } from 'emotion';

export const DropdownLeftWrapper = styled.div`
  flex: 1 1 70%;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const dropdownLeftStyle = css`
  flex: 1 1 0;
`;

export const DropdownRightWrapper = styled.div`
  flex: 1 1 0;
`;

export const dropdownStyle = css`
  width: 100%;
`;

export const smallDropdownStyle = css`
  margin: 10px 0;
`;

export const readOnlyInputStyle = css`
  background-color: var(--bu-gray-200) !important;
  opacity: 1 !important;
`;

export const numericInputStyle = css`
  width: 100%;
`;

export const timeSelectorStyle = css`
  width: 150px !important;
`;

export const dateSelectorStyle = css`
  width: 120px !important;
`;

export const datePopUpStyle = css`
  width: 280px;
`;

export const textInputStyle = css`
  width: 100%;
`;

export const PropertyItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ConditionBox = styled.div`
  padding: 0px 30px 10px 10px;
  border: 1px solid var(--bu-gray-500);
  border-radius: 2px;
  background-color: var(--bu-gray-200);
  margin: 10px 0;
  position: relative;
`;

export const ConditionBoxRemoveButton = styled.button`
  position: absolute;
  top: -10px;
  right: -10px;
  height: 20px;
  width: 20px;
  border-radius: 1em;
  border: 1px solid var(--bu-gray-500);
  background-color: var(--bu-gray-200);
  outline: none;
  cursor: pointer;
`;
