import Highcharts from 'highcharts';
import { ActionCreator } from 'typescript-fsa';

import { StageTransfer, getPipelineStages } from 'actions/pipelineStagesAction';
import {
  IColumn,
  IDataCellProps,
  IRow,
  TypedTableCellConfig,
} from 'components/UI/common/TypedTable/TypedTable';
import { StageConfig } from 'components/settings/StageConfiguration/types';
import { PersistQueryParams } from 'navigation/types';
import {
  PipelineStagesState,
  PipelineStagesStatusState,
} from 'reducers/pipelineStagesReducer';

export interface SankeyLink<
  D extends Highcharts.Dictionary<unknown> = Highcharts.Dictionary<any>
> extends Highcharts.Point {
  id: string;
  isNull: boolean;
  from: string;
  fromNode: SankeyNode<D>;
  to: string;
  toNode: SankeyNode<D>;
  weight: null | number;

  custom: D;
  options: Omit<Highcharts.PointOptionsObject, 'custom'> & {
    custom: D;
  };
}

export interface SankeyNode<
  D extends Highcharts.Dictionary<unknown> = Highcharts.Dictionary<any>
> extends Highcharts.Point {
  column: number;
  id: string;
  offset: number;
  top: number;

  custom: D;
  options: Omit<Highcharts.PointOptionsObject, 'custom'> & {
    custom: D;
  };

  sum: number;

  getSum(): number;

  linksFrom: SankeyLink<D>[];
  linksTo: SankeyLink<D>[];
  isNode: true;
  isNull: boolean;
  state: Highcharts.PointStateValue;
}

export const isSankeyNode = <D extends Highcharts.Dictionary<unknown>>(
  point: Highcharts.Point
): point is SankeyNode<D> => (point as SankeyNode<D>).isNode;

export const isSankeyLink = <D extends Highcharts.Dictionary<unknown>>(
  point: Highcharts.Point
): point is SankeyLink<D> =>
  Boolean((point as SankeyLink<D>).from || (point as SankeyLink<D>).to);

export interface CustomDictionary extends Highcharts.Dictionary<unknown> {
  deals: number;
  percentage: number;
  total: number;
  amount: number;
  filters: {
    ids: string[];
  };
}

export type BarPoint = {
  name: string;
  color: string;
};

export type IDispatchProps = {
  persistModalParams: ActionCreator<PersistQueryParams>;
  changeWidgetSinceDate: ActionCreator<{ [key: string]: string[] }>;
  getPipelineStages: typeof getPipelineStages;
};

export type ForecastCategory = {
  value: string;
  sequence: number;
  display_name: string;
};

export type SankeyElement = StageConfig | ForecastCategory;

export const isStageElement = (
  element: SankeyElement
): element is StageConfig => {
  return 'stage' in element;
};

export type IStateProps = {
  stagesConfig: StageConfig[];
  forecastConfiguration: ForecastCategory[];
  pipelineStages: PipelineStagesState;
  pipelineStagesStatus: PipelineStagesStatusState;
  savedPrevValuesDate: [string];
  companyCurrency: string;
};

export type IOwnProps = {
  filters?: object;
  businessType: string | undefined;
  localStorageKeyPrefix?: string;
  includeDisabledUsers: boolean;
};

export type IProps = IStateProps & IDispatchProps & IOwnProps;

export interface SequenceConfig {
  id: string;
  name: string;
  sentiment?: StageTransferSentiment;
}

export type ForecastPipelineColumnType =
  | 'transfer'
  | 'startingValue'
  | 'endingValue';

export interface ForecastPipelineStageColumn extends IColumn {
  onAmountClick?: (title: string, ids: string[]) => void;
  columnType: ForecastPipelineColumnType;
}

export type ForecastPipelineRowType =
  | 'totalRow'
  | 'progressionRow'
  | 'regressionRow'
  | 'sequenceRow';

export interface ForecastPipelineRow extends IRow {
  from: string;
  starting_value: number;
  starting_value_ids: string[];
  ending_value: number;
  ending_value_ids: string[];
  rowType: ForecastPipelineRowType;
}

export interface ForecastPipelineStageCellProps extends IDataCellProps {
  column: ForecastPipelineStageColumn;
  row: ForecastPipelineRow;
}

export interface ForecastPipelineStageCellConfig extends TypedTableCellConfig {
  renderer: React.FC<ForecastPipelineStageCellProps>;
}

export type StageTransferSentiment = 'positive' | 'negative' | 'neutral';

export interface GroupedTransfer {
  from: string;
  transfers: StageTransfer[];
}
