/* eslint-disable guard-for-in */

/* eslint-disable no-restricted-syntax */

/* eslint-disable no-param-reassign */
import produce from 'immer';
import findKey from 'lodash/findKey';
import * as R from 'ramda';

import * as t from 'actions/actionTypes';
import initialState from 'reducers/callsReducer/initialState';

export default function claimsReducer(state = initialState, action) {
  const { type, tab, scroll, payload, data } = action;
  const activeTabKey = findKey(state.tabs, (el) => el.active);
  const reducer = {
    [t.CALLS + t.GET + t.LOADING]: (draft) => {
      draft.getCallsStatus = 'loading';
    },
    [t.CALLS + t.GET + t.SUCCESS]: (draft) => {
      draft.tabs[tab].calls = payload.data.calls;
      draft.tabs[tab].count = payload.data.total_count;
      draft.getCallsStatus = 'success';
    },
    [t.CALLS + t.FILTER]: (draft) => {
      draft.tabs[tab || activeTabKey].filters = R.map((flt) => {
        if (flt.data.key === data.key) {
          const newValue = data.value.length === 0 ? ['__all__'] : data.value;
          return { ...flt, value: newValue };
        }
        return flt;
      }, state.tabs[tab || activeTabKey].filters);
      draft.tabs[tab || activeTabKey].request.page_number = 1;
    },
    [t.CALLS + t.FILTER + t.SUCCESS]: (draft) => {
      draft.tabs[tab || activeTabKey].calls = payload.data.calls;
      draft.paginationStatus = 'success';
      draft.getCallsStatus = 'success';
      draft.tabs[tab || activeTabKey].count = payload.data.total_count;
    },
    [t.CALLS + t.PAGINATION]: (draft) => {
      draft.tabs[tab || activeTabKey].request = action.data;
    },
    [t.CALLS + t.PAGINATION + t.LOADING]: (draft) => {
      draft.paginationStatus = 'loading';
    },
    [t.CALLS + t.PAGINATION + t.SUCCESS]: (draft) => {
      draft.tabs[tab || activeTabKey].calls = payload.data.calls;
      draft.paginationStatus = 'success';
      draft.tabs[tab || activeTabKey].count = payload.data.total_count;
    },
    [t.CALLS + t.EVENTS + t.PAGINATION + t.LOADING]: (draft) => {
      draft.paginationStatus = 'loading';
    },
    [t.CALLS + t.EVENTS + t.PAGINATION + t.SUCCESS]: (draft) => {
      draft.tabs[tab].events = draft.tabs[tab].events.concat(
        payload.data.events
      );
      draft.tabs[tab].count = payload.data.total_count;
      draft.paginationStatus = 'success';
    },
    [t.CALLS + t.SAVE_TAB_SCROLL]: (draft) => {
      draft.tabs[tab].scroll = scroll;
    },
    [t.CALLS + t.CLEAR_FILTERS]: (draft) => {
      draft.tabs[activeTabKey].filters =
        initialState.tabs[activeTabKey].filters;
    },
  };

  return reducer.hasOwnProperty(type) ? produce(state, reducer[type]) : state;
}
