export enum Operator {
  Date = 'date',
  EqualTo = 'equals to',
  NotEqualTo = 'not equal',
  GreaterThan = 'gt',
  GreaterThanOrEqual = 'gte',
  In = 'in',
  NotIn = 'not in',
  Like = 'like',
  NotLike = 'not like',
  LowerThan = 'lt',
  LowerThanOrEqual = 'lte',
}

type BaseCondition = {
  field?: string;
  display_name?: string;
  field_type?: string;
  operator?: undefined;
  value?: undefined;
};

type ConditionNumber = {
  field: string;
  display_name?: string;
  field_type?: string;
  operator:
    | Operator.GreaterThan
    | Operator.GreaterThanOrEqual
    | Operator.LowerThan
    | Operator.LowerThanOrEqual;
  value: number;
};

type ConditionBoolean = {
  field: string;
  display_name?: string;
  field_type?: string;
  operator: Operator.EqualTo | Operator.NotEqualTo;
  value: boolean;
};

type ConditionDate = {
  field: string;
  display_name?: string;
  field_type?: string;
  operator: Operator.Date;
  value: string;
};

type ConditionPicklist = {
  field: string;
  display_name?: string;
  field_type?: string;
  operator: Operator.In | Operator.NotIn;
  value: string;
};

type ConditionMultiPicklist = {
  field: string;
  display_name?: string;
  field_type?: string;
  operator: Operator.In | Operator.NotIn;
  value: string[];
};

type ConditionText = {
  field: string;
  display_name?: string;
  field_type?: string;
  operator?:
    | Operator.EqualTo
    | Operator.NotEqualTo
    | Operator.Like
    | Operator.NotLike;
  value: string;
};

export type ConditionType =
  | BaseCondition
  | ConditionNumber
  | ConditionBoolean
  | ConditionPicklist
  | ConditionMultiPicklist
  | ConditionDate
  | ConditionText;

export type ApiResponse = { data: any };

export type ApiForecastSubmissionsDetailsResponse = {
  data: {
    forecast_options: string[];
    fixed_time_period_options: {
      label: string;
      type: string;
      value: string;
    }[];
  };
};

export type ConditionFieldInfoPickList = {
  field_type: 'picklist' | 'multipicklist';
  field: string;
  display_name: string;
  values: Array<{
    value: string;
    display_name: string;
  }>;
};

type ConditionFieldInfoDate = {
  field_type: 'date';
  field: string;
  display_name: string;
};

type ConditionFieldInfoTime = {
  field_type: 'time';
  field: string;
  display_name: string;
};

type ConditionFieldInfoText = {
  field_type: 'text' | 'note' | 'phone';
  field: string;
  display_name: string;
};

type ConditionFieldInfoBoolean = {
  field_type: 'bool';
  field: string;
  display_name: string;
};

type ConditionFieldInfoNumber = {
  field_type: NumericFieldTypes;
  field: string;
  display_name: string;
};

const NumericFields = ['money', 'number', 'percentage'] as const;

const TextFields = ['text', 'note', 'phone'] as const;

const PicklistFields = ['picklist', 'multipicklist'] as const;

export type NumericFieldTypes = typeof NumericFields[number];

export type TextFieldsTypes = typeof TextFields[number];

export type PicklistFieldsTypes = typeof PicklistFields[number];

export const isNumericField = (
  field?: ConditionFieldInfo
): field is ConditionFieldInfoNumber =>
  NumericFields.includes(field?.field_type as NumericFieldTypes);

export const isTextField = (
  field?: ConditionFieldInfo
): field is ConditionFieldInfoText =>
  TextFields.includes(field?.field_type as TextFieldsTypes);

export const isPicklistField = (
  field?: ConditionFieldInfo
): field is ConditionFieldInfoPickList =>
  PicklistFields.includes(field?.field_type as PicklistFieldsTypes);

export type ConditionFieldInfo =
  | ConditionFieldInfoNumber
  | ConditionFieldInfoBoolean
  | ConditionFieldInfoPickList
  | ConditionFieldInfoDate
  | ConditionFieldInfoTime
  | ConditionFieldInfoText;

export type IProps = {
  companyCurrency: string;
  condition: ConditionType;
  conditionFields: ConditionFieldInfo[];
  onChange(condition: ConditionType): void;
  onRemove(): void;
  isReadOnly?: boolean;
};
