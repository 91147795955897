import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import * as dealsActions from 'actions/dealsActions';
import { defaultOptions } from 'components/chart-dashboards/Dashboard/Dashboard';
import DashboardFilters from 'components/chart-dashboards/Dashboard/DashboardFilters';
import { Chart as IChart } from 'components/chart-dashboards/Dashboard/types';
import { MainIndicatorTitle } from 'components/chart-dashboards/MainIndicators';
import Widget from 'components/chart-dashboards/Widget';
import WidgetTableAccount from 'components/dashboard/ProspectAccountDashboard/WidgetTableAccount';
import WidgetThreePies from 'components/dashboard/ProspectAccountDashboard/WidgetThreePies';
import * as s from 'components/dashboard/ProspectAccountDashboard/styles';
import { VirtualizedItem } from 'components/virtualized/VirtualizedItem';

type ReduxDispatchProps = typeof dispatchToProps;
type Props = ReduxDispatchProps;

const hideFilters = {
  enabled_filters: undefined,
};

const barChartParams: IChart = {
  ...defaultOptions,
  apis: [
    {
      api_url: `${process.env.REACT_APP_BACKEND_URL}/api/data/metrics/number_of_accounts_with_competitors_mentioned/by_competitor`,
      click_api_url: `${process.env.REACT_APP_BACKEND_URL}/api/data/accounts/by_metric/number_of_accounts_with_competitors_mentioned`,
    },
  ],
  chart_options: {
    seriesName: 'Accounts',
    yAxisTitle: '# Accounts',
  },
  chart_type: 'bar',
  data_type: 'accounts',
  label_path: 'label',
  title: 'Accounts with Competitors Mentioned',
  value_path: [
    {
      display_name: '# of accounts',
      path: 'count',
    },
  ],
  ...hideFilters,
};

const barChartLineParams: IChart = {
  ...defaultOptions,
  data_type: 'accounts',
  chart_type: 'line',
  chart_options: {
    seriesName: 'Accounts',
    yAxisTitle: '# Accounts',
  },
  title: 'Accounts with Competitors Mentioned Week by Week',
  label_path: 'label',
  apis: [
    {
      api_params: {
        time_span_group: 'week',
      },
      api_url:
        '/api/data/metrics/number_of_accounts_with_competitors_mentioned/by_competitor_date',
      click_api_url:
        '/api/data/accounts/by_metric/number_of_accounts_with_competitors_mentioned',
    },
  ],
  value_path: [
    {
      display_name: '# of accounts',
      path: 'count',
    },
  ],
  ...hideFilters,
};

const barChartLinesParams: [IChart, IChart] = [
  {
    ...defaultOptions,
    data_type: 'accounts',
    chart_type: 'line',
    chart_options: {
      seriesName: 'Accounts',
      yAxisTitle: '# Accounts',
    },
    title: 'Dis-Engaged in 30 days',
    label_path: 'label',
    apis: [
      {
        api_params: {
          time_span_group: 'week',
          days_since_last_activity: [180, 30],
        },
        api_url:
          '/api/data/metrics/number_of_accounts_contacts_not_engaged/by_date',
        click_api_url:
          '/api/data/accounts/by_metric/number_of_accounts_no_contacts_engaged',
      },
    ],
    value_path: [
      {
        display_name: '# of accounts',
        path: 'count',
      },
    ],
    ...hideFilters,
  },
  {
    ...defaultOptions,
    data_type: 'accounts',
    chart_type: 'line',
    chart_options: {
      seriesName: 'Accounts',
      yAxisTitle: '# Accounts',
    },
    title: 'No Exec. Engagement in 60 days',
    label_path: 'label',
    apis: [
      {
        api_params: {
          time_span_group: 'week',
        },
        api_url:
          '/api/data/metrics/number_of_accounts_contacts_no_exec_engaged_60_days/by_date',
        click_api_url:
          '/api/data/accounts/by_metric/number_of_accounts_no_exec_engaged_60_days',
      },
    ],
    value_path: [
      {
        display_name: '# of accounts',
        path: 'count',
      },
    ],
    ...hideFilters,
  },
];

const ProspectAccountDashboard: React.FC<Props> = ({ toggleAllFilters }) => {
  const tab = 'prospect_account';

  return (
    <div
      onClick={toggleAllFilters as any}
      className={classNames('prospect-account-content', s.content)}
    >
      <DashboardFilters title="Prospect Account Dashboard" tab={tab} />

      <div className={s.indicator}>
        <MainIndicatorTitle title="Current quarter Account Summary" />
      </div>

      <div className={classNames('pie-charts-container', s.pie_charts)}>
        <WidgetThreePies tab={tab} />
      </div>

      <div className={classNames('charts-container', s.charts)}>
        {[
          <Widget
            tab={tab}
            {...barChartLinesParams[0]}
            hideTimeSpanButtons
            mode="lazy"
          />,
          <Widget
            tab={tab}
            {...barChartLinesParams[1]}
            hideTimeSpanButtons
            mode="lazy"
          />,
          <Widget
            tab={tab}
            {...barChartParams}
            hideTimeSpanButtons
            mode="lazy"
          />,
          <Widget
            tab={tab}
            {...barChartLineParams}
            hideTimeSpanButtons
            mode="lazy"
          />,
        ].map((W, index) => (
          <VirtualizedItem height={463} key={index}>
            {W}
          </VirtualizedItem>
        ))}

        {[
          <WidgetTableAccount
            tab={tab}
            defaultFilters={{ disengaged_since: 30, sort: 'account_value' }}
            title="Dis-engaged Accounts in the last 30 days"
            showColumns={[
              'account name',
              'last engaged',
              'user',
              'last meeting',
            ]}
            hideTimeSpanButtons
          />,
          <WidgetTableAccount
            tab={tab}
            defaultFilters={{
              last_activity: 'TQU',
              most_engaged_since: 30,
              sort: 'recent_emails',
            }}
            title="Most Engaged Accounts in the last 30 days"
            showColumns={[
              'account name',
              'user',
              'last meeting',
              'contacts engaged',
            ]}
            hideTimeSpanButtons
          />,
          <WidgetTableAccount
            tab={tab}
            defaultFilters={{
              at_most_contacts_touched: 4,
              contacts_touched_timeframe: 90,
              last_activity: 'TQU',
              sort: 'contacts_touched',
            }}
            title="< 4 contacts touched in the last 90 days"
            showColumns={['account name', 'user', 'contacts touched']}
            hideTimeSpanButtons
          />,
        ].map((W, index) => (
          <VirtualizedItem key={index} withClone>
            {W}
          </VirtualizedItem>
        ))}
      </div>
    </div>
  );
};

const dispatchToProps = {
  toggleAllFilters: dealsActions.toggleAllFilters,
};

export default connect(null, dispatchToProps)(ProspectAccountDashboard);
