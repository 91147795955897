import { combineReducers } from 'redux';

import * as t from 'actions/actionTypes';
import {
  ForecastPaceQuarterlyData,
  ForecastPaceQuarterlyPayload,
  ForecastQuotaAttainmentData,
  ForecastQuotaAttainmentPayload,
} from 'actions/analyticsActions';

export type ForecastPaceQuarterlyAction = {
  type: string;
  payload: ForecastPaceQuarterlyPayload;
  periods: string[];
  cacheDate: string;
};

export type ForecastPaceQuarterlyState = {
  list: ForecastPaceQuarterlyData[];
  periods: string[];
  cacheDate: string;
};

const initialState = {
  list: [],
  cacheDate: '',
};

function forecastPaceQuarterly(
  state = initialState,
  action: ForecastPaceQuarterlyAction
) {
  switch (action.type) {
    case t.FORECAST + t.PACE_QUARTERLY + t.GET + t.SUCCESS:
      // FIXME this is a temporary solution, for fix https://vocalo.atlassian.net/browse/VPD-9461
      const formattedPayload = action.payload.map((item) =>
        item.name === 'target' ? { ...item, display_name: 'Target' } : item
      );
      return {
        ...state,
        list: formattedPayload,
        periods: action.periods,
        cacheDate: action.cacheDate,
      };
    default:
      return state;
  }
}

function forecastPaceQuarterlyLoading(
  state = false,
  action: ForecastPaceQuarterlyAction
) {
  switch (action.type) {
    case t.FORECAST + t.PACE_QUARTERLY + t.GET + t.LOADING:
      return true;
    case t.FORECAST + t.PACE_QUARTERLY + t.GET + t.SUCCESS:
    case t.FORECAST + t.PACE_QUARTERLY + t.GET + t.ERROR:
      return false;
    default:
      return state;
  }
}

export type ForecastQuotaAttainmentAction = {
  type: string;
  payload: ForecastQuotaAttainmentPayload;
};

export type ForecastQuotaAttainmentState = ForecastQuotaAttainmentData[];

function forecastQuotaAttainment(
  state = [],
  action: ForecastQuotaAttainmentAction
) {
  switch (action.type) {
    case t.FORECAST + t.QUOTA_ATTAINMENT + t.GET + t.SUCCESS:
      return [...action.payload];
    default:
      return state;
  }
}

function forecastQuotaAttainmentLoading(
  state = false,
  action: ForecastQuotaAttainmentAction
) {
  switch (action.type) {
    case t.FORECAST + t.QUOTA_ATTAINMENT + t.GET + t.LOADING:
      return true;
    case t.FORECAST + t.QUOTA_ATTAINMENT + t.GET + t.SUCCESS:
    case t.FORECAST + t.QUOTA_ATTAINMENT + t.GET + t.ERROR:
      return false;
    default:
      return state;
  }
}

export default combineReducers({
  forecastPaceQuarterly,
  forecastPaceQuarterlyLoading,
  forecastQuotaAttainment,
  forecastQuotaAttainmentLoading,
});
