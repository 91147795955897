import { combineReducers } from 'redux';

import * as t from 'actions/actionTypes';
import {
  NewPipelineCreatedAction,
  NewPipelineCreatedPayload,
  NewPipelineCreatedFilterAction,
} from 'actions/pipelineActions';

export type NewPipelineCreatedState = NewPipelineCreatedPayload;

const initialState = { data: [], selected_user_target: [], cache: '' };

function newPipelineCreated(
  state: NewPipelineCreatedState = initialState,
  action: NewPipelineCreatedAction
) {
  switch (action.type) {
    case t.NEW_PIPELINE_CREATED + t.GET + t.SUCCESS:
      return { ...action.payload, cache: action.cache };
    case t.NEW_PIPELINE_CREATED + t.GET + t.ERROR:
      return initialState;
    default:
      return state;
  }
}

function newPipelineCreatedLoading(
  state = false,
  action: NewPipelineCreatedAction
) {
  switch (action.type) {
    case t.NEW_PIPELINE_CREATED + t.GET + t.LOADING:
      return true;
    case t.NEW_PIPELINE_CREATED + t.GET + t.SUCCESS:
    case t.NEW_PIPELINE_CREATED + t.GET + t.ERROR:
      return false;
    default:
      return state;
  }
}

function pipelineCreatedByCloseDate(
  state: NewPipelineCreatedState = initialState,
  action: NewPipelineCreatedAction
) {
  switch (action.type) {
    case t.PIPELINE_CREATED_BY_CLOSE_DATE + t.GET + t.SUCCESS:
      return { ...action.payload, cache: action.cache };
    case t.PIPELINE_CREATED_BY_CLOSE_DATE + t.GET + t.ERROR:
      return initialState;
    default:
      return state;
  }
}

function pipelineCreatedByCloseDateLoading(
  state = false,
  action: NewPipelineCreatedAction
) {
  switch (action.type) {
    case t.PIPELINE_CREATED_BY_CLOSE_DATE + t.GET + t.LOADING:
      return true;
    case t.PIPELINE_CREATED_BY_CLOSE_DATE + t.GET + t.SUCCESS:
    case t.PIPELINE_CREATED_BY_CLOSE_DATE + t.GET + t.ERROR:
      return false;
    default:
      return state;
  }
}

export type NewPipelineCreatedFilterState = Record<
  'frequency' | 'paceInterval',
  string
>;

const newPipelineCreatedInitialState: NewPipelineCreatedFilterState = {
  frequency: 'week',
  paceInterval: 'TQU',
};

const newPipelineCreatedFilters = (
  state = newPipelineCreatedInitialState,
  action: NewPipelineCreatedFilterAction
) => {
  switch (action.type) {
    case t.NEW_PIPELINE_CREATED + t.FILTER + t.CHANGE:
      return {
        ...state,
        [action.field]: action.value,
      };
    default:
      return state;
  }
};

const pipelineCreatedByCloseDateFilters = (
  state = newPipelineCreatedInitialState,
  action: NewPipelineCreatedFilterAction
): NewPipelineCreatedFilterState => {
  switch (action.type) {
    case t.PIPELINE_CREATED_BY_CLOSE_DATE + t.FILTER + t.CHANGE:
      return {
        ...state,
        [action.field]: action.value,
      };
    default:
      return state;
  }
};

export default combineReducers({
  newPipelineCreated,
  newPipelineCreatedLoading,
  newPipelineCreatedFilters,
  pipelineCreatedByCloseDate,
  pipelineCreatedByCloseDateLoading,
  pipelineCreatedByCloseDateFilters,
});
