import styled from '@emotion/styled';

export const MetricAnalysisTypeBlock = styled.div({
  paddingBottom: '24px',
  borderBottom: '1px var(--bu-gray-400) solid',
  marginBottom: '24px',
});

export const DataTypeTitle = styled.p({
  fontSize: '16px',
  fontWeight: 500,
  color: 'var(--bu-gray-900)',
  paddingBottom: '16px',
  marginBottom: '0px',
});
