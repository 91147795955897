import { css } from 'emotion';

export const content = css`
  padding: 0 20px 20px 20px;
  width: 100%;
`;

export const pie_charts = css`
  margin-top: 1em;
  margin-bottom: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 19px;
`;

export const charts = css`
  margin: 20px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 19px;

  .widget-segment {
    padding: 0;
  }

  .time-segments {
    margin-top: 5px;
  }
`;

export const chart = css`
  border-radius: 2px;
  border: 1px solid #dddddd;
  padding: 10px;
`;

export const indicator = css`
  margin-top: 5px;
`;
