import styled from '@emotion/styled';
import { css } from 'emotion';

export const mainContent = css`
  display: flex;
  flex-direction: column;
  color: var(--bu-gray-900);
  margin-bottom: 0 !important;
`;

export const section = css`
  font-size: 14px;
  line-height: 22px;
  padding-top: 10px;

  &:first-of-type {
    padding-top: 0;
  }
`;

export const NoteSection = styled.div`
  color: var(--bu-gray-600);
  margin-top: 7px;
`;

export const addConditionButton = css`
  padding: 5px 10px;
  white-space: nowrap;
  border: 1px solid var(--bu-primary-500);
  border-radius: 4px;
  font-size: 14px;
  background-color: var(--bu-gray-100);
  font-family: var(--bu-font-regular);
  cursor: pointer;
  color: var(--bu-primary-500);
  -webkit-transition: 0.4s;
  transition: 0.4s;
`;

export const cancelButton = css`
  margin-right: 10px;
`;

export const FiltersSection = styled.div``;

export const FiltersCounter = styled.div``;

export const AndLabel = styled.div`
  font-size: 14px;
  height: 17px;
  width: 33px;
  color: var(--bu-gray-700);
  font-family: var(--bu-font-regular);
  letter-spacing: 0;
  line-height: 17px;
`;
