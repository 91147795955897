import classNames from 'classnames';
import React from 'react';

import * as styles from 'components/UI/Checkbox/styles';

interface Props {
  strong?: boolean;
  checked: boolean;
  disabled?: boolean;
  id: string;
  label: string;
  name: string;
  onChange: any;
  semichecked?: boolean;
  value: string;
  colorized?: boolean;
  key?: string;
}

const Checkbox: React.FC<Props> = ({
  strong,
  checked,
  disabled,
  id,
  label,
  name,
  onChange,
  semichecked,
  value,
  colorized = false,
}) => {
  const rnd =
    Math.random().toString(36).substring(2, 10) +
    Math.random().toString(36).substring(2, 10);
  const checkboxName = `${rnd}_${id}`;

  return (
    <div
      className={styles.checkboxContainer}
      aria-checked={checked}
      role="menuitemcheckbox"
    >
      <label className={styles.checkboxLabel} htmlFor={checkboxName}>
        <input
          checked={checked}
          className={styles.checkboxInputReal}
          disabled={disabled}
          id={checkboxName}
          name={name}
          onChange={onChange}
          type="checkbox"
          value={value}
        />
        <span className={styles.checkboxCustom(checked, semichecked)} />
        <span
          className={classNames({
            [styles.checkboxContainerChanged]: colorized,
          })}
        >
          {label}
        </span>
      </label>
    </div>
  );
};

export default Checkbox;
