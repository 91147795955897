import { RowStatusType } from 'components/UI/common/TypedTable/TypedTable';

export const someStatusIsLoading = (dealsStatuses: RowStatusType) => {
  if (!dealsStatuses) {
    return false;
  }
  return Object.values(dealsStatuses).some(
    (statusesForDeal) =>
      statusesForDeal.status === 'loading' ||
      Object.values(statusesForDeal).some(
        (statusForCell) => statusForCell?.status === 'loading'
      )
  );
};
