import { Parser } from 'expr-eval';
import { isEmpty, isNil } from 'ramda';

import {
  FormulaColumnMeta,
  TableConfigurationColumn,
} from 'components/UI/TableConfig/types';
import { IRow, ValueProp } from 'components/UI/common/TypedTable/TypedTable';
import { getColumnByFieldName } from 'components/dashboard/ForecastRollUps/helpers';

const EXTRACT_PLACEHOLDERS = /\{(.*?)\}/gm;
const STRING_TEST = /[a-z]+/i;

const parser = new Parser();

const getValuesFor = (row: IRow, keys: string[]): string[] =>
  keys.map((key: string) => {
    const expressionList = key.split('|').map((value: string) => value.trim());
    // looking the first non null
    const valueKey = expressionList.find((item) => {
      const rowItem = STRING_TEST.test(item) ? row[item] : isFinite(+item); // null | undefined | number
      return !isNil(rowItem) && !isEmpty(rowItem);
    });
    return ((valueKey && !isFinite(+valueKey) ? row[valueKey] : valueKey) ??
      0) as string;
  });

const getCellValue = (
  row: IRow,
  keys: string[],
  expression: string = ''
): number => {
  const keyValues: string[] = getValuesFor(row, keys);
  const finalExpression: string = keys.reduce(
    (_expression, key, index) =>
      _expression.replace(key, keyValues[index] || '0'),
    expression.replaceAll('{', '').replaceAll('}', '')
  );
  let cellValue;

  try {
    // Using any to not break the current code
    cellValue = parser.evaluate(finalExpression) as any;
  } catch {
    cellValue = '';
  }

  return cellValue;
};

const fixedTo = function (number: number, n: number) {
  var k = Math.pow(10, n);
  return Math.round(number * k) / k;
};

const calculateColumn = (
  tree: IRow[],
  keys: string[],
  expression: string,
  fieldName: string,
  isDelta: boolean = false
) => {
  tree.forEach((row) => {
    const { children = [] } = row;
    // If it's calculating Deltas, we create a new prop to save the delta value
    const newFieldName = isDelta ? `Delta ${fieldName}` : fieldName;
    const cellValue = isDelta
      ? getFormulaDeltaValue(
          expression,
          keys,
          keys.map((key) => row[key] as number)
        )
      : getCellValue(row, keys, expression);

    row[newFieldName] = isFinite(cellValue) ? fixedTo(cellValue, 2) : '-';

    if (expression && children) {
      calculateColumn(children, keys, expression, fieldName, isDelta);
    }
  });
};

/**
 * Get the expression replaced with column display_name instead of column_name
 *
 * @param expression - String with the expression created on settings (Example: {M1 Booked} - {M2 Booked})
 * @param columns - Array of column configuration
 * @example {Month 1 Booked} - {Month 2 Booked}
 */
export const getExpressionWithDisplayName = (
  expression: string,
  columns: TableConfigurationColumn[]
): string =>
  getFormulaKeys(expression, true).reduce(
    (prev, curr) =>
      prev.replaceAll(
        curr,
        getColumnByFieldName(curr, columns)?.display_name || curr
      ),
    expression
  );

export const getFormulaKeys = (
  expression: string,
  removeSpecialChars: boolean = false
): string[] => {
  const formulaKeys = expression.match(EXTRACT_PLACEHOLDERS) || [];

  if (removeSpecialChars) {
    return formulaKeys.map((key) => key.replace('{', '').replace('}', ''));
  }

  return formulaKeys;
};

export const applyFormulaColumn = (
  tree: IRow[],
  columns: TableConfigurationColumn[]
) => {
  const formulaColumns = columns
    .filter((column) => column.meta?.type === 'formula')
    .sort((a, b) => {
      return (
        (a.meta as FormulaColumnMeta).evaluation_order -
        (b.meta as FormulaColumnMeta).evaluation_order
      );
    });
  formulaColumns.forEach((formulaColumn) => {
    const { meta, field_name } = formulaColumn;
    const { expression = '', sub_value } = meta;
    const keys = getFormulaKeys(expression, true);
    if (expression) {
      calculateColumn(tree, keys, expression, field_name);

      // If sub_value has a badge key, we need to calculate the Deltas to show there
      if (sub_value?.badge && sub_value?.badge.relative_fields) {
        calculateColumn(
          tree,
          sub_value?.badge.relative_fields,
          expression,
          field_name,
          true
        );
      }
    }
  });

  /**
   * We are overriding relative_fields here because on SimpleCell we can get
   * this value easier as badgeValue
   */
  formulaColumns.forEach((formulaColumn) => {
    if (
      formulaColumn.meta.sub_value?.badge &&
      formulaColumn.meta.sub_value?.badge.relative_fields
    ) {
      formulaColumn.meta.sub_value.badge.relative_fields = [
        `Delta ${formulaColumn.field_name}`,
      ];
    }
  });
};

/**
 * Get the values from each key used to calculate the Deltas from a formula expression
 *
 * @param keys - Array with the keys extracted from the expression
 * @param formulaKeysValues - Object with all keys and his values
 * @returns Object with the keys from the expression already calculated with minus Prev value
 * @example
 * {
 *    'Booked': 256897,
 *    'Commit': 356974
 * }
 */
export const getFormulaKeysValues = (
  keys: string[],
  formulaKeysValues: { [key: string]: number | ValueProp }
): { [key: string]: number } =>
  keys.reduce(
    (prev, curr) => ({
      ...prev,
      [curr]:
        ((formulaKeysValues[curr] as number) || 0) -
        ((formulaKeysValues[`Prev ${curr}`] as number) || 0),
    }),
    {}
  );

/**
 * Get the final number of the Delta from a Formula
 *
 * @param expression - String with the expression created on settings
 * @param relativeFieldsNames - Array with the name of the relative fields of this expression
 * @param relativeFieldsValues - Array with the value/number of the relative fields
 * @returns Number with the final calc of the Delta
 */
export const getFormulaDeltaValue = (
  expression: string = '',
  relativeFieldsNames: string[],
  relativeFieldsValues: number[]
): number => {
  const keys = getFormulaKeys(expression, true);
  const formulaKeysValues = relativeFieldsNames.reduce(
    (prev, curr, index) => ({
      ...prev,
      [curr]: relativeFieldsValues[index],
    }),
    {}
  );

  const prevExpression = keys.reduce(
    (_expression, key) => _expression.replace(key, `Prev ${key}`),
    expression
  );
  const prevKeys = keys.map((key) => `Prev ${key}`);

  // For example: {Commit} + {Booked}
  // We need to do this: ({Commit} + {Booked}) - ({Prev Commit} + {Prev Booked}) in order to calculate the Delta value
  return (
    getCellValue(formulaKeysValues as IRow, keys, expression) -
    getCellValue(formulaKeysValues as IRow, prevKeys, prevExpression)
  );
};
