export const METRIC = 'METRIC';
export const METRIC_TIME_SERIES = 'METRIC_TIME_SERIES';
export const OBJECT_LIST = 'OBJECT_LIST';
export const SUCCESS = '_SUCCESS';
export const LOADING = '_LOADING';
export const GET = '_GET';
export const REMOVE = '_REMOVE';
export const ALL = '_ALL';
export const TIME_SERIES = '_TIME_SERIES';
export const TIME_OPTIONS = 'TIME_OPTIONS';
export const WIDGET = 'WIDGET';
export const ADD = '_ADD';
export const UPDATE = '_UPDATE';
export const FORECAST_PERIOD = 'FORECAST_PERIOD';
export const QUARTER = '_QUARTER';
export const MONTH = '_MONTH';
export const WEEK = '_WEEK';
