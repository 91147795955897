import { connect } from 'react-redux';

import { getTableColumnConfiguration } from 'actions/settingsActions';
import TableConfig from 'components/UI/TableConfig/TableConfig';
import {
  DispatchProps,
  StateProps,
  TableConfigOwnProps,
} from 'components/UI/TableConfig/types';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';
import { getSfBaseUrl, tableColumnConfiguration } from 'selectors';

const mapStateToProps = (
  state: IReduxState,
  {
    tableConfigCollection,
    tableConfigName,
    onModifyColumnConfiguration,
    isModal,
  }: TableConfigOwnProps
): StateProps => ({
  companyCurrency: selectors.getUserLocalCurrency(state),
  isMulticurrencyEnabled: selectors.isMulticurrencyEnabled(state),
  sequence: selectors.getAllSequenceTypes(state),
  tableColumnConfiguration: tableColumnConfiguration(
    state,
    tableConfigCollection,
    tableConfigName,
    onModifyColumnConfiguration
  ),
  showOrHideColumns: selectors.getShowOrHideColumns(state, isModal),
  sfBaseUrl: getSfBaseUrl(state),
  oppSplitViewToggle: selectors.getForecastOppSplitViewFlag(state),
});

const mapDispatchToProps = (
  dispatch: Function,
  { tableConfigCollection, tableConfigName }: TableConfigOwnProps
): DispatchProps => ({
  getTableColumnConfiguration: () =>
    dispatch(
      getTableColumnConfiguration(tableConfigCollection, tableConfigName)
    ),
});

/**
 * @deprecated
 */
export default connect(mapStateToProps, mapDispatchToProps)(TableConfig);
