import styled from '@emotion/styled';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as metricActions from 'actions/revbi/metrics';
import { MetricsDashboard } from 'components/dashboard/Metrics/Dashboard/MetricsDashboard';
import { DashboardsSideList } from 'components/dashboard/Metrics/DashboardsSideList/DashboardsSideList';
import { DashboardsList } from 'components/dashboard/Metrics/List/DashboardsList';
import { MetricsList } from 'components/dashboard/Metrics/List/MetricsList';
import { WidgetsList } from 'components/dashboard/Metrics/List/WidgetsList';
import { UsersByActivityProvider } from 'components/dashboard/Metrics/contexts/UsersByActivityContext';
import { RevbiPages } from 'components/dashboard/Metrics/enums';
import { dispatchIfNotAsked } from 'components/dashboard/Metrics/metrics.helpers';
import { BIDashboard } from 'components/dashboard/Metrics/metrics.types';
import * as metricSelectors from 'selectors/revbi/metrics';
import { fetchApi, QueryStatus } from 'utils/network';

const PageContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
});

const getNewDashboard = (): BIDashboard => {
  return {
    name: '',
    properties: {
      // Needed to differentiate between new dashboards using the new dashboard editor
      // and existing dashboards before https://vocalo.atlassian.net/browse/REV-1020
      // and the InteractiveGrid component
      widgetLayout: [],
    },
    widget_list: [],
  };
};

const formatDashboardForSave = (dashboard: BIDashboard) => {
  const { id, _id, created_at, updated_at, ...saveData } = dashboard;
  return saveData;
};

const parseDashboardResponse = (dashboard: BIDashboard): BIDashboard => ({
  ...dashboard,
  id: dashboard.id || dashboard?._id,
});

const parseDashboardList = (dashboardList: BIDashboard[]): BIDashboard[] => {
  if (!Array.isArray(dashboardList)) {
    return [];
  }

  return dashboardList.map((dashboard: BIDashboard) =>
    parseDashboardResponse(dashboard)
  );
};

export const RevBIContainer: React.FC = () => {
  const match = useRouteMatch<{ dashboardId: string }>();
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedDashboardIdRef = useRef(match.params.dashboardId);

  const [dashboardList, setDashboardList] = useState<BIDashboard[]>([]);
  const [selectedDashboard, setSelectedDashboard] = useState<BIDashboard>();
  const [pageShown, setPageShown] = useState<RevbiPages>(RevbiPages.DASHBOARD);
  const [loadDashboardStatus, setLoadDashboardStatus] =
    useState<QueryStatus>('notAsked');
  const [loadDashboardListStatus, setLoadDashboardListStatus] =
    useState<QueryStatus>('notAsked');
  const [includeDisabledUsers, setIncludeDisabledUsers] = useState<boolean>(
    selectedDashboard?.properties?.settings?.userFilter !== 'active'
  );

  const objectListStatus = useSelector(metricSelectors.getObjectListStatus);
  const objectListHistoryStatus = useSelector(
    metricSelectors.getObjectsListHistoryStatus
  );
  const timeOptionsStatus = useSelector(metricSelectors.getTimeOptionsStatus);
  const widgetsListStatus = useSelector(metricSelectors.getWidgetsListStatus);
  const quarterOptionsStatus = useSelector(
    metricSelectors.getQuarterForecastPeriodOptionsStatus
  );
  const monthOptionsStatus = useSelector(
    metricSelectors.getMonthForecastPeriodOptionsStatus
  );
  const weekOptionsStatus = useSelector(
    metricSelectors.getWeekForecastPeriodOptionsStatus
  );

  useEffect(() => {
    setIncludeDisabledUsers(
      selectedDashboard?.properties?.settings?.userFilter !== 'active'
    );
  }, [selectedDashboard?.properties?.settings?.userFilter]);

  useEffect(() => {
    if (match.path.includes('widget')) {
      setPageShown(RevbiPages.WIDGETS);
    } else if (match.path.includes('metric')) {
      setPageShown(RevbiPages.METRICS);
    } else if (match.path.includes('dashboards')) {
      setPageShown(RevbiPages.DASHBOARDS);
    } else {
      setPageShown(RevbiPages.DASHBOARD);
    }
  }, [match.path]);

  useEffect(() => {
    dispatch(metricActions.fetchAllMetrics());
    dispatch(metricActions.fetchAllTSMetrics());

    dispatchIfNotAsked(
      dispatch,
      metricActions.fetchObjectList,
      objectListStatus
    );
    dispatchIfNotAsked(
      dispatch,
      metricActions.fetchTimeSeriesObjectList,
      objectListHistoryStatus
    );
    dispatchIfNotAsked(
      dispatch,
      metricActions.fetchTimeOptions,
      timeOptionsStatus
    );
    dispatchIfNotAsked(
      dispatch,
      metricActions.fetchAllWidgets,
      widgetsListStatus
    );
    dispatchIfNotAsked(
      dispatch,
      metricActions.fetchQuarterForecastPeriodTimeOptions,
      quarterOptionsStatus
    );
    dispatchIfNotAsked(
      dispatch,
      metricActions.fetchMonthForecastPeriodTimeOptions,
      monthOptionsStatus
    );
    dispatchIfNotAsked(
      dispatch,
      metricActions.fetchWeekForecastPeriodTimeOptions,
      weekOptionsStatus
    );

    fetchApi<void, any>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/dashboards`,
      queryMethod: 'get',
      setData: (result) => {
        setDashboardList(parseDashboardList(result ?? []));
        if (match.path === '/revbi' && result.length > 0) {
          history.push(`/revbi/dashboard/${result[0]._id}`);
        }
      },
      setError: (error: string | null) => {
        toast.error(`Failed to load dashboard: ${error}`);
      },
      setStatus: setLoadDashboardListStatus,
    });

    return () => {
      setDashboardList([]);
    };
  }, []);

  useEffect(() => {
    if (
      match.params.dashboardId &&
      selectedDashboardIdRef.current !== match.params.dashboardId
    ) {
      setPageShown(RevbiPages.DASHBOARD);
      fetchApi<void, BIDashboard>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/dashboards/${match.params.dashboardId}/complete`,
        queryMethod: 'get',
        setData: (result) => {
          setSelectedDashboard(parseDashboardResponse(result));
        },
        setError: (error: string | null) => {
          toast.error(`Failed to load dashboard: ${error}`);
        },
        setStatus: setLoadDashboardStatus,
      });
    }
  }, [match.params.dashboardId]);

  const handleAddDashboard = (): void => {
    fetchApi<BIDashboard, BIDashboard>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/dashboards`,
      queryMethod: 'post',
      queryParams: formatDashboardForSave(getNewDashboard()),
      setData: (result) => {
        const savedDashboard = parseDashboardResponse(result);
        setDashboardList((prev) => [savedDashboard, ...prev]);
        setSelectedDashboard(savedDashboard);
        history.replace({
          pathname: `/revbi/dashboard/${
            savedDashboard?.id || savedDashboard?._id
          }`,
        });
        toast.success('Dashboard Created');
      },
      setError: (error: string | null) => {
        toast.error(`Failed to create dashboard: ${error}`);
      },
      setStatus: setLoadDashboardStatus,
    });
  };

  const handleRemoveDashboard = (id: string): void => {
    setDashboardList(dashboardList.filter((dashboard) => dashboard.id !== id));
  };

  const PagesContent = {
    [RevbiPages.WIDGETS]: <WidgetsList />,
    [RevbiPages.METRICS]: <MetricsList />,
    [RevbiPages.DASHBOARDS]: (
      <DashboardsList
        dashboardsList={dashboardList}
        onAdd={handleAddDashboard}
        onRemove={handleRemoveDashboard}
        setDashboardsList={setDashboardList}
      />
    ),
    [RevbiPages.DASHBOARD]: (
      <MetricsDashboard
        key={selectedDashboard?.id}
        loadDashboardStatus={loadDashboardStatus}
        selectedDashboard={selectedDashboard}
        selectedDashboardIdRef={selectedDashboardIdRef}
        setSelectedDashboard={setSelectedDashboard}
        setDashboardList={setDashboardList}
        onRemove={handleRemoveDashboard}
      />
    ),
  };

  return (
    <UsersByActivityProvider includeDisabledUsers={includeDisabledUsers}>
      <PageContainer>
        <DashboardsSideList
          loadDashboardListStatus={loadDashboardListStatus}
          selectedDashboardId={match.params.dashboardId}
          dashboardList={dashboardList}
          onAddDashboard={handleAddDashboard}
        />
        {PagesContent[pageShown]}
      </PageContainer>
    </UsersByActivityProvider>
  );
};
