import userRound from 'assets/fonts/boostup-icons/user-pivot-round.svg';
import oppRound from 'assets/fonts/boostup-icons/opportunity-pivot-round.svg';
import general from 'assets/fonts/boostup-icons/customobject-pivot-round.svg';
import account from 'assets/fonts/boostup-icons/account-pivot.svg';
import event from 'assets/fonts/boostup-icons/event-pivot-round.svg';
import lead from 'assets/fonts/boostup-icons/lead-pivot-round.svg';
import workload from 'assets/fonts/boostup-icons/workload-pivot-round.svg';
import submission from 'assets/fonts/boostup-icons/submission-pivot-round.svg';

/**
 * the new SVGs are not prepared to be fonts.
 *
 * @param name: the entity name
 * @returns SVG Icon
 */

export const getIconByEntityName = (name: string | undefined = 'default') => {
  let table = name.toLocaleLowerCase();
  // this is a hack just to support more than one kind of type.
  if (table.includes('_lead')) {
    table = 'lead';
  } else if (table.includes('_event')) {
    table = 'event';
  } else if (table.includes('workload')) {
    table = 'workload';
  }

  switch (table) {
    case 'opportunity':
      return oppRound;
    case 'user':
      return userRound;
    case 'account':
      return account;
    case 'event':
      return event;
    case 'lead':
      return lead;
    case 'workload':
      return workload;
    case 'forecast_submission':
      return submission;
    default:
      return general;
  }
};
