import { useQuery } from '@tanstack/react-query';
import {
  ForecastSeries,
  GetTrendsPayload,
  TrendItemResponse,
  getTrends,
} from 'api/Trends';
import { TooltipFormatterContextObject } from 'highcharts';
import { isEmpty } from 'ramda';
import React, { useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { useSelector } from 'react-redux';

import { FILTER_NAME } from 'common/constants';
import { DataItemType } from 'components/chart-dashboards/Widget/types';
import {
  TooltipWrapper,
  TooltipDefaultText,
  TooltipAccentText,
} from 'components/dashboard/ForecastDashboard/TrackingDashboard/Chart/styles';
import { useModal } from 'components/modals/ModalContext/modal.context';
import { isBusinessTypesConfigReady } from 'selectors';

const SERIES_THAT_ARE_NEGATIVE: ForecastSeries[] = [
  'pushed',
  'minus_value',
  'won',
  'lost',
];
export const SERIES_THAT_HAVE_CUSTOM_AMOUNT: ForecastSeries[] = [
  'plus_value',
  'minus_value',
];
export const SERIES_THAT_HAVE_TO_SKIP_BUSINESS_TYPE_VALIDATION: ForecastSeries[] =
  ['prior_forecast', 'plus_value', 'minus_value', 'pushed', 'won', 'lost'];

export const SERIES_TO_USE_BEGINNING_OF_PERIOD: ForecastSeries[] = [
  'prior_forecast',
];
export const SERIES_IS_FORECAST: ForecastSeries[] = [
  'new_deals',
  'pulled',
  'plus_value',
  'pushed',
  'minus_value',
  'won',
  'lost',
  'current_forecast',
];

export const SERIES_TO_USE_SNAPSHOT_ENDPOINT: ForecastSeries[] = [
  'prior_forecast',
];
export const FIRST_BAR_INDEX = 0;
export const LAST_BAR_INDEX = 8;
const TOOLTIP_NON_EXPLICIT_FILTER_WARNING = '(only active deals included)';

export const MODAL_HEADER_TOOLTIP: Record<ForecastSeries, string> = {
  prior_forecast:
    'deals matched the current filtering criteria at the starting date AND had open Stage and Forecast Category values. Data displayed corresponds to data at the starting date (snapshot).',
  new_deals:
    'These deals match the current filtering criteria but were created after the starting date selected. Current (live) data is displayed.',
  pulled:
    'These deals did not initially match your filtering criteria by the starting date but now do. Current (live) data is displayed.',
  plus_value:
    'These deals have had their amount value increased since the starting date. Current (live) data is displayed.',
  pushed:
    'These deals initially matched your filtering criteria by the starting date but no longer do. Current (live) data is displayed.',
  minus_value:
    'These deals have had their amount value decreased since the starting date. Current (live) data is displayed.',
  won: 'These deals were closed-won (deal stage) during the selected time period. Current (live) data is displayed.',
  lost: 'These deals were closed-lost (deal stage) during the selected time period. Current (live) data is displayed.',
  current_forecast:
    'These deals match the current filtering criteria date AND have open Stage and Forecast Category values. Current (live) data is displayed.',
};

export const useGetTrends = (filters: GetTrendsPayload) => {
  const isBusinessTypeLoaded = useSelector(isBusinessTypesConfigReady);
  const canFetchWaterfall = !isEmpty(filters) && isBusinessTypeLoaded;

  // TODO - only fetch when filters are ready, required data is not undefined or null
  const { data, isError, isLoading } = useQuery({
    queryKey: ['waterfall', filters],
    queryFn: () => getTrends(filters),
    enabled: canFetchWaterfall,
  });

  const parsedData = useMemo(() => data?.map(parseTrendsResponse), [data]);

  return {
    data: parsedData,
    isError,
    isLoading,
  };
};

export interface TrendItem extends TrendItemResponse {
  isSum: boolean;
  y: number;
}

export const parseTrendsResponse = (
  trend: TrendItemResponse,
  index: number,
  allTrends: TrendItemResponse[]
): TrendItem => {
  const yChartValue = SERIES_THAT_ARE_NEGATIVE.includes(
    trend.filters.forecast_series
  )
    ? trend.total_amount * -1
    : trend.total_amount;

  const isSum = index === allTrends.length - 1;
  return {
    ...trend,
    y: yChartValue,
    isSum,
  };
};

interface CustomHighChartOptions extends Highcharts.PointOptionsObject {
  count: number;
}

export function getTrendWaterfallTooltip(
  context: TooltipFormatterContextObject,
  companyCurrencyCode: string
) {
  // Todo use filter.forecast_Series
  const isFirstBar =
    (context.series.data[FIRST_BAR_INDEX] as DataItemType).label ===
    context.key?.toString();
  const isLastBar =
    (context.series.data[LAST_BAR_INDEX] as DataItemType).label ===
    context.key?.toString();
  const showNonExplicitFiltersWarning = isFirstBar || isLastBar;
  const { count, y } = context.point.options as CustomHighChartOptions;
  const value = `${new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: companyCurrencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(y || 0)} (${count} deals)`;
  return renderToString(
    <TooltipWrapper>
      <TooltipDefaultText>
        {formatLabel(context.point.category as string)}
      </TooltipDefaultText>
      <br />
      <TooltipAccentText>{value}</TooltipAccentText>
      {showNonExplicitFiltersWarning && (
        <>
          <br />
          <TooltipAccentText>
            {TOOLTIP_NON_EXPLICIT_FILTER_WARNING}
          </TooltipAccentText>
        </>
      )}
    </TooltipWrapper>
  );
}

export const formatLabel = (value: string, separator: string = '') => {
  switch (value) {
    case 'Pulled':
      return `Pulled into ${separator} current filtered pipeline`;
    case 'Pushed':
      return `Pushed out of ${separator} current filtered pipeline`;
    default:
      return value;
  }
};

export const getModalParamsForBar = (
  clickedBar: TrendItem,
  filters: GetTrendsPayload
) => {
  const clickedBarForecastSeries = clickedBar.filters.forecast_series;

  const hasToOverrideModalAmount = SERIES_THAT_HAVE_CUSTOM_AMOUNT.includes(
    clickedBarForecastSeries
  );

  const overrideModalAmount = hasToOverrideModalAmount
    ? Math.abs(clickedBar.y || 0)
    : null;

  const hastToSkipBusinessTypeValidation =
    SERIES_THAT_HAVE_TO_SKIP_BUSINESS_TYPE_VALIDATION.includes(
      clickedBarForecastSeries
    );

  const useBeginningOfPeriod = SERIES_TO_USE_BEGINNING_OF_PERIOD.includes(
    clickedBarForecastSeries
  );

  const isForecast = SERIES_IS_FORECAST.includes(clickedBarForecastSeries);

  const useSnapshotEndpoint = SERIES_TO_USE_SNAPSHOT_ENDPOINT.includes(
    clickedBarForecastSeries
  );

  const haveToInheritUsersFilterValues = !!filters.split_view;

  const apiUrl = useSnapshotEndpoint
    ? '/api/data/deals/snapshot/'
    : '/api/data/deals/';

  const modalHeaderTooltip = MODAL_HEADER_TOOLTIP[clickedBarForecastSeries];

  const title = clickedBar.label;

  const filtersForModal = {
    ids: clickedBar.ids,
    use_beginning_of_period: useBeginningOfPeriod,
    is_forecast: isForecast,
    skip_business_validation: hastToSkipBusinessTypeValidation,
    change_interval: filters.change_interval,
    business_type_name: filters.business_type_name,
    split_view: filters.split_view,
    include_disabled: filters.include_disabled,
    // only needed when we need to inherit users filter values
    ...(haveToInheritUsersFilterValues ? { users: filters.users } : {}),
  };

  return {
    apiUrl,
    id: clickedBar.y,
    title,
    showFilters: true,
    filters: JSON.stringify(filtersForModal),
    filterKeyForModal: FILTER_NAME.ForecastAnalyticsModal,
    filtersHaveToInheritFilterValues: false,
    modalHeaderTooltip,
    overrideModalAmount,
    haveToInheritUsersFilterValues,
    openedFromWaterfall: true,
  };
};

export const getDeltaOverPeriod = (trends: TrendItemResponse[]) => {
  if (trends.length < 2) {
    return 0;
  }
  const firstTrend = trends[0];
  const lastTrend = trends[trends.length - 1];

  const firstTrendAmount = firstTrend.total_amount;
  const lastTrendAmount = lastTrend.total_amount;

  const delta = lastTrendAmount - firstTrendAmount;

  return delta;
};
