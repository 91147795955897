import classNames from 'classnames';
import { css } from 'emotion';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleForecastOppSplitView } from 'actions/forecastActions';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import { fontSubtitle } from 'assets/css/common';
import BuIcon from 'components/UI/BuIcon';
import BuToggle from 'components/UI/BuToggle';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import {
  getForecastOppSplitViewFlag,
  getBusinessTypesList,
  getSplitTypesSettings,
} from 'selectors';
import { getSplitsTypesSettings } from 'actions/settingsActions';

const oppSplitViewWrapperStyle = css`
  display: flex;
  align-items: center;
  padding: 0.5em;
  color: var(--bu-gray-700);
  line-height: 11px;
`;

const tooltipWrapperStyle = css`
  width: 300px;
  text-align: center;
`;

const infoIconStyle = css`
  font-size: 18px;
`;

const splitsToggle = css`
  margin-right: 6px;

  span {
    color: var(--bu-gray-900);
  }
`;

type Props = {
  activeBusinessType: string;
};

const OppSplitViewToggle: React.FC<Props> = ({ activeBusinessType }) => {
  const oppSplitViewFlag = useSelector(getForecastOppSplitViewFlag);
  const businessTypesList = useSelector(getBusinessTypesList);
  const dispatch = useDispatch();
  const splitTypesSettings: any = useSelector(getSplitTypesSettings);

  useEffect(() => {
    if (!splitTypesSettings) {
      dispatch(getSplitsTypesSettings());
    }
  }, [splitTypesSettings]);

  const businessTypeConfig = useMemo(
    () =>
      businessTypesList.find(
        (businessType: any) => businessType.name === activeBusinessType
      ),
    [activeBusinessType, businessTypesList]
  );

  const configSplitDisplayFieldName =
    businessTypeConfig?.split_type?.display_field_name || '';

  const configSplitTypeLabel = businessTypeConfig?.split_type?.label || '';
  const btSplitTypes = splitTypesSettings?.splitTypes[
    businessTypeConfig?.amount_field
  ]?.map((split: any) => split.split_field_name);
  const isForced = !btSplitTypes?.includes(
    businessTypeConfig?.split_type?.split_field_name
  );

  return (
    <div className={oppSplitViewWrapperStyle}>
      <BuToggle
        checked={oppSplitViewFlag}
        onChange={() => {
          dispatch(toggleForecastOppSplitView());
        }}
        className={splitsToggle}
      >
        <span>Splits</span>
      </BuToggle>

      <TooltipWrapper
        tooltip={
          <div className={tooltipWrapperStyle}>
            <span className={classNames(fontSubtitle)}>
              {activeBusinessType} BT
            </span>{' '}
            splits data and calculations are based on{' '}
            <span className={classNames(fontSubtitle)}>
              {configSplitTypeLabel}
            </span>{' '}
            split type {isForced ? 'forced from ' : 'from mapped '}
            <span className={classNames(fontSubtitle)}>
              {configSplitDisplayFieldName}
            </span>{' '}
            amount field. You can still see other fields' split records.
          </div>
        }
        position="bottom center"
      >
        {businessTypeConfig?.enable_opportunity_splits && (
          <div>
            <BuIcon
              color="var(--bu-gray-900)"
              name={BoostUpIcons.BadgeInfoOutline}
              className={infoIconStyle}
            />
          </div>
        )}
      </TooltipWrapper>
    </div>
  );
};

export default OppSplitViewToggle;
