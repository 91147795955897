import { css } from 'emotion';

import { fontDefaultDropdownSubtitle } from 'assets/css/common';

export const specific_time_periods = css`
  height: 100%;
  max-height: 400px;
  overflow-y: auto;
  width: 100%;
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 26px 14px;
`;

export const specific_time_periods_single = css`
  height: 100%;
  width: 100%;
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 5px 15px 25px;
`;

export const specific_time_periods_item = css`
  width: 100%;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }
`;

export const specific_time_period_title = css`
  color: var(--bu-gray-700);
  ${fontDefaultDropdownSubtitle};
  margin-bottom: 10px;
  cursor: default;
  padding: 0 6px;
`;

export const specific_time_period_item = css`
  color: var(--bu-gray-900);
  padding: 8px 6px 8px 6px;
  cursor: pointer;
  transition: 0.4s color;
  border: 1px solid transparent;
  border-radius: 4px;
  margin-bottom: 2px;

  &:hover,
  &.selected {
    border-color: var(--bu-primary-500);
    background: rgba(49, 92, 121, 0.12);
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
export const bold_text = css`
  font-weight: 900;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
`;
