import axios from 'axios';
import { put, select, call, all, takeLatest } from 'redux-saga/effects';

import * as t from 'actions/actionTypes';
import {
  activityRemapSuccess,
  activityRemapFailure,
} from 'actions/activityActions';
import { getUser } from 'selectors';

function* remapAllActivity({ body }) {
  try {
    const { api_access_token } = yield select(getUser);
    const response = yield call(
      axios.post,
      `${process.env.REACT_APP_BACKEND_URL}/api/data/deals/remap/`,
      body,
      {
        headers: { Authorization: `Bearer ${api_access_token}` },
      }
    );

    if (response.status === 200) {
      yield put(activityRemapSuccess());
    }
  } catch (e) {
    yield put(activityRemapFailure());
  }
}

function* callMentionsStatsSaga() {
  yield all([takeLatest([t.ACTIVITY + t.REMAP + t.REQUEST], remapAllActivity)]);
}

export default callMentionsStatsSaga;
