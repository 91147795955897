import React, { useMemo } from 'react';

import { WidgetHeader } from 'components/dashboard/Metrics/Widget/Header/WidgetHeader';
import { ReportTable } from 'components/dashboard/Metrics/Widget/Report/ReportTable/ReportTable';
import { TooltipContent } from 'components/dashboard/Metrics/Widget/Report/Tooltip/TooltipContent';
import { TemplateFilters } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilters';
import { WidgetContainer } from 'components/dashboard/Metrics/Widget/widgets.styles';
import {
  BIDashboardSettings,
  BIMetricFormula,
  BIMetricUnion,
  BIMetricsQueryFilter,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';
import { MetricType } from '../../enums';
import { getMetricType } from '../../Create/utils';
import { parseFormulaToMetricsIdArray } from '../helper';
import { useFormulaMetricsList } from '../hooks/useFormulaMetricsList';
import { getWidgetObjects } from '../../metrics.helpers';

interface Props {
  isControlsShown: boolean;
  isDashboardWidget?: boolean;
  isDashboardModal?: boolean;
  dashboardName?: string;
  dashboardSettings?: BIDashboardSettings;
  widget: Partial<BIWidget>;
  onEditWidget?: (metricId: string) => void;
  onChangeWidget: (widget: Partial<BIWidget>) => void;
  onCloneWidget?: (metricId: string) => void;
  onRemoveWidget?: (metricId: string) => void;
  onTitleClicked?: () => void;
  onCloseWidgetModal?: () => void;
}

export const ReportWidget: React.FC<Props> = ({
  isControlsShown = false,
  isDashboardWidget = false,
  isDashboardModal = false,
  dashboardName = '',
  dashboardSettings,
  widget,
  onEditWidget,
  onChangeWidget,
  onCloneWidget,
  onRemoveWidget,
  onTitleClicked,
  onCloseWidgetModal,
}) => {
  const metricFilters: BIMetricsQueryFilter[] =
    widget.metric_list?.[0]?.filters ?? [];
  const metricObject = widget.metric_list?.[0]?.object || 'opportunity';

  const tooltipContent: JSX.Element | undefined = useMemo(
    () => (
      <TooltipContent
        filters={metricFilters || []}
        object={metricObject || ''}
        orderByDirection={widget.order_by?.[0] || ''}
        orderByColumn={widget.order_by_column?.label || ''}
        widgetLimit={widget?.limit || 0}
      />
    ),
    [
      metricObject,
      JSON.stringify(metricFilters),
      widget.order_by_column,
      JSON.stringify(widget.order_by),
      widget?.limit,
    ]
  );
  const usedFormulaMetricIds = useMemo(() => {
    return (widget.metric_list as BIMetricUnion[])
      .filter((m) => getMetricType(m) === MetricType.Formula)
      .flatMap((m) =>
        parseFormulaToMetricsIdArray((m as BIMetricFormula).synthetic_metric)
      );
  }, [widget.metric_list]);

  const { data: usedFormulaMetricsMap } =
    useFormulaMetricsList(usedFormulaMetricIds);
  const tables = getWidgetObjects(widget as BIWidget, usedFormulaMetricsMap);

  return (
    <WidgetContainer
      key={widget._id}
      isDashboard={isDashboardWidget}
      isMetricsPreview={false}
    >
      <WidgetHeader
        isReportViewWidget
        isDashboardWidget={isDashboardWidget}
        id={widget._id}
        name={widget.name}
        dashboardName={dashboardName}
        tooltip={tooltipContent}
        isDashboardModal={isDashboardModal}
        onEditWidget={onEditWidget}
        onCloneWidget={onCloneWidget}
        onRemoveWidget={onRemoveWidget}
        onTitleClicked={onTitleClicked}
        onCloseWidgetModal={onCloseWidgetModal}
        alternativeVisibility={false}
      />

      <TemplateFilters
        showControls={isControlsShown}
        templateFilters={widget.template_filters ?? []}
        widgetFilters={widget.widget_filters ?? []}
        dashboardFilters={widget.dashboard_filters ?? []}
        tables={tables}
        onChangeWidget={onChangeWidget}
      />

      <ReportTable
        dashboardSettings={dashboardSettings}
        widget={widget}
        onChangeWidget={onChangeWidget}
      />
    </WidgetContainer>
  );
};
