import * as styles from './styles';
import { IProps } from './types';
import classNames from 'classnames';
import React, { useMemo } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';

export const FormulaFieldSuggester = (props: IProps) => {
  const getPositioning = useMemo(() => {
    const selection = window.getSelection();
    const range = selection?.getRangeAt(0);
    const selectionRect = range?.getClientRects()[0];
    const fieldRect = props.fieldRef.current?.getClientRects()[0] ?? {
      top: 0,
      left: 0,
    };

    if (selectionRect !== undefined) {
      return {
        top: selectionRect.top + 20,
        left: selectionRect.left + 5,
      };
    }

    return {
      top: fieldRect.top + 30,
      left: fieldRect.left + 15,
    };
  }, [props.items]);

  const suggestions = useMemo(() => {
    if (props.status === 'Loading' || props.status === 'notAsked') {
      return <li>Loading...</li>;
    }
    if (!props.items.length) {
      return 'No matches';
    }
    return props.items.map((el, index) => {
      return (
        <li
          onClick={(e) => {
            props.handleSelect(el);
          }}
          key={el.id}
          className={classNames({
            [styles.activeMetric]: index === props.caretPosition,
          })}
        >
          <BuIcon name={BoostUpIcons.BadgeInfoOutline} />
          {el.name}
        </li>
      );
    });
  }, [props.items, props.caretPosition, props.status]);

  return (
    <section className={styles.suggesterWrapper} style={getPositioning}>
      <h3 className={styles.header}>Saved Simple Metrics</h3>
      <ul className={styles.metricsSuggestionsList}>{suggestions}</ul>
    </section>
  );
};
