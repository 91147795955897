import { AggregationFunction } from 'components/dashboard/Metrics/enums';

export const OPPORTUNITY = 'opportunity';
export const ACCOUNT = 'account';
export const OPPORTUNITY_SPLIT = 'opportunity_split';
export const EMAIL = 'email';
export const CALENDAR_EVENT = 'calendar_event';
export const UNTITLED_DASHBOARD = 'Untitled Dashboard';

export enum AnalysisType {
  LIVE = 'live',
  HISTORICAL = 'historical',
  REPORT = 'report',
  FUNNEL = 'funnel',
}

export const ALL_TIME_VALUE = 'AT';

export const NUMBER_COLUMN_TYPES = new Set(['money', 'number']);
export const PICKLIST_COLUMN_TYPES = new Set(['picklist', 'multipicklist']);
export const TEXT_COLUMN_TYPES = new Set(['text', 'note']);
export const DATE_COLUMN_TYPES = new Set(['date']);
export const BOOL_COLUMN_TYPES = new Set(['bool']);
export const SINGLE_SELECT_COLUMN_TYPES = new Set(['select']);

export const COLUMN_TYPES = new Set([
  ...NUMBER_COLUMN_TYPES,
  ...PICKLIST_COLUMN_TYPES,
  ...TEXT_COLUMN_TYPES,
  ...DATE_COLUMN_TYPES,
  ...BOOL_COLUMN_TYPES,
  ...SINGLE_SELECT_COLUMN_TYPES,
]);

const OPPORTUNITY_BUSINESS_TYPE_FIELD_NAME = 'opportunity.business_type';
const SHARED_BUSINESS_TYPE_FIELD_NAME = 'shared.business_type';
export const BUSINESS_TYPES_FIELD_NAMES = new Set([
  OPPORTUNITY_BUSINESS_TYPE_FIELD_NAME,
  SHARED_BUSINESS_TYPE_FIELD_NAME,
]);

export const MONTHLY = 'Monthly';
export const MONTH = 'month';
export const QUARTERLY = 'Quarterly';
export const QUARTER = 'quarter';
export const WEEK = 'week';
export const YEARLY = 'Yearly';
export const YEAR = 'year';

export const AGGREGATION_OPTIONS: {
  text: string;
  value: AggregationFunction;
}[] = [
  { text: 'Sum', value: AggregationFunction.Sum },
  { text: 'Average', value: AggregationFunction.Average },
  { text: 'Min', value: AggregationFunction.Min },
  { text: 'Max', value: AggregationFunction.Max },
  { text: 'Count', value: AggregationFunction.Count },
];

export const TARGET = 'target';
export const FORECAST = 'forecast';

export const FORECAST_AMOUNT_ACCURACY = 'forecast_amount_accuracy';
export const FORECAST_DEAL_ACCURACY = 'forecast_deal_accuracy';
export const FORECAST_SUBMISSION = 'forecast_submission';

export const FORECAST_SUBMISSION_OBJECTS = [
  FORECAST_AMOUNT_ACCURACY,
  FORECAST_DEAL_ACCURACY,
  FORECAST_SUBMISSION,
];

export const REVBI_TOTALS_DECIMALS_CONFIG = {
  D: 0,
  K: 2,
  M: 2,
  B: 2,
  T: 2,
};

// hard code! alert!
// here will add a new section on the top named Interval Period
// in order to add 3 generic pivots.
export const GENERIC_TIME_INTERVAL_PIVOTS = [
  {
    available_in_any_pivot: true,
    available_in_pivot_nth: false,
    label: 'Week over Week',
    name: 'week',
    table_name: 'time_interval',
    type: 'date',
  },
  {
    available_in_any_pivot: true,
    available_in_pivot_nth: false,
    label: 'Month over Month',
    name: 'month',
    table_name: 'time_interval',
    type: 'date',
  },
  {
    available_in_any_pivot: true,
    available_in_pivot_nth: false,
    label: 'Quarter over Quarter',
    name: 'quarter',
    table_name: 'time_interval',
    type: 'date',
  },
];
