import {
  ForecastSeries,
  GetTrendsPayload,
  TrendItemResponse,
} from 'api/Trends';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';

import { actions } from 'actions';
import { showLiveBadgeOnModal } from 'actions/opportunities';
import { fontSubtitle } from 'assets/css/common';
import { FILTER_NAME } from 'common/constants';
import { formatMoney } from 'common/numbers';
import {
  getModalParamsForBar,
  useGetTrends,
} from 'components/charts/TrendsWaterfall/TrendsWaterfall.helper';
import { Props } from 'components/dashboard/ForecastRollUps/CustomColumnTooltips/MyOrgCustomTooltip';
import styles, {
  SerieRowWrapper,
} from 'components/dashboard/ForecastRollUps/CustomColumnTooltips/styles';
import { ALL_OPTION } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/constants';
import { FullScreenTrendsModal } from 'components/modals/FullscreenTrends/FullscreenTrends';
import { useModal } from 'components/modals/ModalContext/modal.context';
import { PersistQueryParams } from 'navigation/types';
import { openModal } from 'navigation/utils';
import { getInheritedFilterValuesForModal } from 'reducers/filters/helpers';
import { IReduxState } from 'reducers/types';
import { FiltersForAPI, getFiltersState } from 'selectors';

const specialForecastSeries = ['prior_forecast', 'current_forecast'];

type ChangeData = {
  id: ForecastSeries;
  displayLabel?: string;
  totalAmount?: number;
  totalDeals?: number;
  background?: {
    borderColor: string;
    color: string;
  };
};

const SERIES_DISPLAY_LABELS: Record<ForecastSeries, string> = {
  prior_forecast: 'Starting pipeline',
  current_forecast: 'Current pipeline',
  new_deals: 'New Deals created during period',
  pulled: 'Deals Pulled into the pipeline',
  plus_value: 'Deal Value/Amount increased',
  pushed: 'Deals Pushed out of the pipeline',
  minus_value: 'Deal Value/Amount decreased',
  won: 'Deals moved to Closed Won',
  lost: 'Deals moved to Closed Lost',
};

const getSerieLabel = (id: ForecastSeries, serie: TrendItemResponse) =>
  specialForecastSeries.includes(id)
    ? `${SERIES_DISPLAY_LABELS[id]} (${serie.label})`
    : SERIES_DISPLAY_LABELS[id];

const getSerieBackground = (id: ForecastSeries) => {
  switch (id) {
    case 'new_deals':
    case 'pulled':
    case 'plus_value':
      return {
        borderColor: 'var(--bu-green-500)',
        color: 'var(--bu-green-200)',
      };

    case 'pushed':
    case 'minus_value':
    case 'won':
    case 'lost':
      return {
        borderColor: 'var(--bu-red-400)',
        color: 'var(--bu-red-100)',
      };

    default:
      return undefined;
  }
};

const SerieRow: React.FC<{
  serie: ChangeData;
  companyCurrency: string;
  onClick(serie: ChangeData): void;
}> = ({ serie, companyCurrency, onClick }) => {
  // Here we decide if showing deal or deals depending on total deals
  const dealLabel = serie.totalDeals === 1 ? 'Deal' : 'Deals';
  return (
    <SerieRowWrapper
      borderColor={serie.background?.borderColor}
      backgroundColor={serie.background?.color}
      onClick={() => onClick(serie)}
    >
      <span className={classNames(fontSubtitle)}>{serie.displayLabel}:</span>
      &nbsp;
      <span className={classNames(fontSubtitle, 'deals-total-amount')}>
        {specialForecastSeries.includes(serie.id)
          ? `${formatMoney(companyCurrency, serie.totalAmount!)} (${
              serie.totalDeals
            } ${dealLabel})`
          : `${serie.totalDeals} ${dealLabel} (${formatMoney(
              companyCurrency,
              serie.totalAmount!
            )})`}
      </span>
    </SerieRowWrapper>
  );
};

const PipelineSummary: React.FC<Props> = ({
  pipelineSummaryFilters: rawPipelineSummaryFilters,
  rollUpsFilters,
  managerEmail,
  companyCurrency,
  includeDisabledUsers,
  splitType,
  args,
}) => {
  const { openModal: openFullScreenModal } = useModal<FullScreenTrendsModal>();

  const dispatch = useDispatch();

  const globalDefaults = useSelector((state: IReduxState) =>
    getFiltersState(state, null, 'globalDefaults')
  );

  const {
    filtersForAPI: pipelineSummaryFilters,
    filtersForModal: pipelineSummaryModalFilters,
  } = useMemo(() => {
    const filtersForAPI: FiltersForAPI = {};
    filtersForAPI.custom_filters = {};
    filtersForAPI.custom_account_filters = {};

    const filtersForModal: FiltersForAPI = {};
    filtersForModal.custom_filters = {};
    filtersForModal.custom_account_filters = {};

    Object.keys(rawPipelineSummaryFilters || []).forEach((filterName) => {
      const { group, values } = globalDefaults?.[filterName] || {};
      const apiFilters = group ? filtersForAPI[group] : filtersForAPI;
      const modalFilters = group ? filtersForModal[group] : filtersForModal;
      const value = rawPipelineSummaryFilters![filterName];

      /**
       * Since it is for API, we have to send "All" as []
       */
      (apiFilters as any)[filterName] =
        value[0] === ALL_OPTION.value ? [] : value;

      /**
       * If it is configured as "All" we need to add All the values to show it
       * correct on the filter inside the FullscreenTrends
       */
      (modalFilters as any)[filterName] =
        value[0] === ALL_OPTION.value
          ? values?.map(({ value }) => value)
          : value;
    });

    return {
      filtersForAPI,
      filtersForModal,
    };
  }, [rawPipelineSummaryFilters, globalDefaults]);

  const filtersForWaterfall = {
    ...pipelineSummaryFilters,
    include_disabled: includeDisabledUsers,
    change_interval: rollUpsFilters.change_interval[0],
    time_span: rollUpsFilters.change_interval[0],
    // This property is typed as string[] but it's actually a string
    // This is because how FilterForApi is typed
    business_type_name: rollUpsFilters.business_type_name as unknown as string,
    managers: [managerEmail],
    split_view: splitType ? true : undefined,
    close_date_interval: rollUpsFilters.close_date_interval[0],
  } as GetTrendsPayload;

  const { data: changeData = [], isLoading } =
    useGetTrends(filtersForWaterfall);

  const formatedChangeData: ChangeData[] = useMemo(() => {
    if (changeData.length) {
      // fixing the array order putting A bar and B bar at the beginning
      const lastElement = changeData.pop();
      changeData.splice(1, 0, lastElement!);

      return changeData.map((serie) => {
        const id = serie.filters.forecast_series;

        return {
          id,
          displayLabel: getSerieLabel(id, serie),
          totalAmount: serie.y || serie.total_amount,
          totalDeals: serie.count,
          background: getSerieBackground(id),
        };
      });
    }

    return [];
  }, [changeData]);

  const onSerieClick = (serie: ChangeData) => {
    const clickedSerieData = changeData.find(
      (data) => data.filters.forecast_series === serie.id
    )!;

    if (!clickedSerieData) {
      return;
    }

    const showLiveBadge =
      clickedSerieData.filters.forecast_series === 'prior_forecast';

    dispatch(showLiveBadgeOnModal(showLiveBadge));

    const modalParams = getModalParamsForBar(
      clickedSerieData,
      filtersForWaterfall
    );

    // TODO - migrate to useModal hook, this will require changes on DealsModal component
    openModal({
      scheme: '/deals',
      params: {
        localStorageKeyPrefix: 'ForecastTrends',
      },
      persistParams: modalParams,
      persistor: (params: PersistQueryParams) =>
        dispatch(actions.ui.modal.persist(params)),
    });
  };

  const onViewWaterfallClick = () => {
    if (pipelineSummaryFilters) {
      const filtersForModal = getInheritedFilterValuesForModal(
        pipelineSummaryFilters,
        pipelineSummaryModalFilters
      );
      dispatch(
        actions.ui.filters.setForecastModalFilters({
          filters: filtersForModal.map((filter) => ({
            ...filter,
            emptyNotAllowed: true,
          })),
          tab: FILTER_NAME.FullscreenTrendsModal,
          createFromScratch: true,
        })
      );
    }
    openFullScreenModal({
      scheme: '/fullscreen-trends',
      props: {
        filters: filtersForWaterfall,
        user: (args['My Org'] || '') as string,
      },
    });
  };

  return (
    <div className={classNames('bu-font-default', styles.tabContainer)}>
      <div className={classNames(styles.column, styles.myOrgCustomBody)}>
        {isLoading ? (
          <Dimmer.Dimmable dimmed className={styles.loaderStyle}>
            <Dimmer inverted active>
              <Loader />
            </Dimmer>
          </Dimmer.Dimmable>
        ) : (
          formatedChangeData.map((serie: ChangeData) => (
            <SerieRow
              serie={serie}
              companyCurrency={companyCurrency}
              onClick={onSerieClick}
              key={serie.id}
            />
          ))
        )}
      </div>

      <div className={styles.waterfallViewWrapper}>
        <a onClick={onViewWaterfallClick} className={styles.viewWaterfallLink}>
          View Waterfall
        </a>
      </div>
    </div>
  );
};

export default PipelineSummary;
