import { formatMoney } from 'common/numbers';

export const formatAmount = (
  code: string,
  value: number,
  isSign: boolean = true,
  isShort: boolean = true
): string => {
  const absValue = Math.abs(value);
  const isNegative = value < 0;

  const minus = isSign
    ? isNegative
      ? '-'
      : ''
    : isNegative
    ? 'reduced by '
    : 'increased by ';

  return isShort
    ? absValue > 999999
      ? `${minus}${formatMoney(code, absValue / 1000000, 1)}M`
      : absValue > 999
      ? `${minus}${formatMoney(code, absValue / 1000, 0)}K`
      : `${minus}${formatMoney(code, absValue, 0)}`
    : `${minus}${formatMoney(code, absValue, 2)}`;
};
