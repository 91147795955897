import * as styles from './skeletonStyles';
import React, { FC } from 'react';

import BuSkeleton from 'components/UI/BuSkeleton';
import BuLineWithDots from 'components/UI/BuSkeleton/BuLineWithDots';
import BuSkeletonTable from 'components/UI/BuSkeleton/BuSkeletonTable';
import { TopTablePosition } from 'components/UI/BuSkeleton/types';

const AccountSkeleton: FC<{}> = () => {
  return (
    <div className="wrapper">
      <div className="container">
        <div className="container-deal">
          <div className="deal-header">
            <div className="deal-header__top-section">
              <div className="deal-header__top">
                <div className="deal-header__top-left">
                  <BuSkeleton height={35} width={120} />
                </div>

                <div className="deal-header__top-right">
                  <BuSkeleton
                    height={35}
                    width={120}
                    className={styles.marginRight}
                  />
                  <BuSkeleton
                    height={35}
                    width={120}
                    className={styles.marginRight}
                  />
                  <BuSkeleton height={35} width={35} />
                </div>
              </div>

              <>
                <div className={styles.borderWrapper}>
                  <BuSkeleton circle width={30} height={30} />
                  <div className={styles.flexRow}>
                    <BuSkeleton height={10} width={200} />
                    <BuSkeleton height={10} width={200} />
                  </div>
                </div>
              </>
            </div>

            <div className={styles.infoMeetingsContainer}>
              <BuSkeleton width={210} height={30} />
              <BuSkeleton width={210} height={30} />
              <BuSkeleton width={210} height={30} />
              <BuSkeleton width={210} height={30} />
            </div>

            <BuSkeletonTable
              hasTopTable
              topTablePosition={TopTablePosition.INSIDE}
              rowsCount={4}
              rowItem={
                <>
                  <BuSkeleton circle width={30} height={30} />
                  <BuSkeleton height={20} width={130} />
                  <BuLineWithDots dotsCount={4} lineWidth={710} />
                </>
              }
            />

            <BuSkeletonTable
              filledHeader
              hasTopTable
              topTablePosition={TopTablePosition.OUTSIDE}
              rowsCount={3}
              rowItem={
                <>
                  <BuSkeleton height={10} width={160} />
                  <BuSkeleton height={10} width={160} />
                  <BuSkeleton height={10} width={160} />
                  <BuLineWithDots dotsCount={4} lineWidth={400} />
                </>
              }
            />

            <BuSkeletonTable
              borderedHeader
              rowsCount={1}
              rowItem={
                <>
                  <BuSkeleton
                    containerClassName={styles.fullWidthSkeleton}
                    height={10}
                    width="100%"
                  />
                </>
              }
              headerItems={
                <>
                  <BuSkeleton
                    circle
                    width={30}
                    height={30}
                    style={{ marginRight: '10px' }}
                  />
                  <BuSkeleton height={20} width={130} />
                </>
              }
            />

            <BuSkeletonTable
              filledHeader
              hasTopTable
              topTablePosition={TopTablePosition.OUTSIDE}
              rowsCount={3}
              rowItem={
                <>
                  <BuSkeleton height={10} width={100} />
                  <BuSkeleton height={10} width={100} />
                  <BuSkeleton height={10} width={100} />
                  <BuSkeleton height={10} width={580} />
                </>
              }
            />

            <BuSkeletonTable
              filledHeader
              hasTopTable
              topTablePosition={TopTablePosition.OUTSIDE}
              rowsCount={3}
              rowItem={
                <>
                  <BuSkeleton height={10} width={160} />
                  <BuSkeleton height={10} width={160} />
                  <BuSkeleton height={10} width={160} />
                  <BuLineWithDots dotsCount={4} lineWidth={400} />
                </>
              }
            />

            <BuSkeletonTable
              filledHeader
              hasTopTable
              topTablePosition={TopTablePosition.OUTSIDE}
              rowsCount={3}
              rowItem={
                <>
                  <BuSkeleton height={10} width={150} />
                  <BuSkeleton height={10} width={150} />
                  <BuSkeleton height={10} width={150} />
                  <BuSkeleton height={10} width={150} />
                  <BuSkeleton height={10} width={150} />
                </>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSkeleton;
