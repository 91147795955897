import axiosInstance from 'api/axiosInstance';

interface RefreshSalesforceTokenResponse {
  salesforce_service_working: boolean;
}

const REFRESH_TOKEN_URL = '/api/salesforce/test-user-salesforce-services';
export const refreshSalesforceToken = async () => {
  const res = await axiosInstance.get<{
    data: RefreshSalesforceTokenResponse;
  }>(REFRESH_TOKEN_URL);

  return res.data.data;
};
