import React, { FC } from 'react';

import BuIcon from 'components/UI/BuIcon';
import { VisualizationType } from 'components/dashboard/Metrics/enums';
import { VisualizationOption } from '../types';
import { container, icon } from './styles';

interface Props {
  metricId: string;
  option: VisualizationOption;
  onVisualizationChange: (
    metricId: string,
    chartType: VisualizationType
  ) => void;
}

export const DropdownOption: FC<Props> = ({
  metricId,
  option,
  onVisualizationChange,
}) => {
  const handleClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    chartType: VisualizationType
  ) => {
    e.preventDefault();
    e.stopPropagation();
    onVisualizationChange(metricId, chartType);
  };

  return (
    <div
      key={option.chartType + '_' + option.displayName}
      className={container}
      data-testing={`${option.displayName
        .toLowerCase()
        .replace(' ', '-')}_option`}
      onClick={(e) => handleClick(e, option.chartType)}
    >
      <BuIcon className={icon} name={option.icon} />
      {option.displayName}
    </div>
  );
};
