import React from 'react';

import UserDetails from 'components/settings/ManageUsers/UserDetails';
import { ModalRouteProps } from 'navigation/types';

const QuickViewUser: React.FC<ModalRouteProps<{ id: string }>> = ({
  params,
  onClose,
}) => {
  const { id } = params;

  if (!id) {
    return null;
  }

  return <UserDetails id={decodeURIComponent(id)} onClose={onClose} />;
};

export default QuickViewUser;
