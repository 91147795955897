import * as R from 'ramda';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';

const toLowerCaseIsString = <T>(obj: T): T | string =>
  typeof obj === 'string' ? R.toLower(obj) : obj;

const pickDeltaPathIsExist =
  <T>(
    path: R.Path,
    columnConfiguration?: any[],
    isMulticurrencyEnabled?: boolean
  ) =>
  (obj: any): T | string | number | undefined => {
    const columnConfig = columnConfiguration?.find(
      (el) => el.field_name === path[0]
    );
    if (
      !!columnConfiguration &&
      !!columnConfig &&
      !!isMulticurrencyEnabled &&
      columnConfig.type === 'money'
    ) {
      const exchangeRate = R.path(['exchange_rate'], obj) || null;
      const value = R.hasPath([...(path as string[]), 'value'], obj)
        ? R.path<T>([...path, 'value'], obj)
        : R.pathOr<T | string>('', path, obj);
      return Number(value) / Number(exchangeRate);
    }

    return R.hasPath([...(path as string[]), 'value'], obj)
      ? R.path<T>([...path, 'value'], obj)
      : R.pathOr<T | string>('', path, obj);
  };

const useTableSort = <T>(
  initSortByField?: string,
  columnConfiguration?: any[],
  isMulticurrencyEnabled?: boolean
): [
  string | undefined,
  Dispatch<SetStateAction<string | undefined>>,
  (list: readonly T[]) => T[]
] => {
  const [sortByField, setSortByField] =
    useState<string | undefined>(initSortByField);

  const sortBy = useMemo(() => {
    const isDescending = sortByField?.[0] === '-';

    const fieldPath = (sortByField ?? '')
      .slice(isDescending ? 1 : 0)
      .split('.');

    return R.sort<T>(
      (isDescending ? R.descend : R.ascend)(
        R.compose(
          toLowerCaseIsString,
          pickDeltaPathIsExist(
            fieldPath,
            columnConfiguration,
            isMulticurrencyEnabled
          )
        )
      )
    );
  }, [sortByField]);

  return [sortByField, setSortByField, sortBy];
};

export default useTableSort;
