import { AnalyticsTracker } from '../components/common/analyticsUtils';
import { makeModalPath, makeURL } from './scheme.utils';
import omit from 'lodash/omit';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { actions } from 'actions';
import {
  URLScheme,
  ModalURLProps,
  ModalPersistParams,
  Scheme,
} from 'navigation/types';

interface ILinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  url: URLScheme<Scheme>;
  replace?: boolean;
  innerRef?: React.Ref<HTMLAnchorElement>;
}

interface IModalLinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  url: ModalURLProps;
  replace?: boolean;
  innerRef?: React.Ref<HTMLAnchorElement>;
}

interface IModalLinkWithAnalyticsProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  action: string;
  category: string;
  innerRef?: React.Ref<HTMLAnchorElement>;
  label?: string;
  replace?: boolean;
  url: ModalURLProps;
  persistParams?: ModalPersistParams;
}

export const Link: React.FC<ILinkProps> = (props) => {
  const to = makeURL(props.url);

  if (!to) {
    return null;
  }

  const newProps = { ...props, url: undefined };

  return (
    <RouterLink to={to} {...newProps}>
      {newProps.children}
    </RouterLink>
  );
};

type ReduxDispatchProps = typeof dispatchToProps;

const ModalLinkImpl: React.FC<IModalLinkProps & ReduxDispatchProps> = (
  props
) => {
  const { scheme, params, persistParams, modalOptions } = props.url;

  const to = makeURL({
    scheme,
    params,
  });

  if (!to) {
    return null;
  }

  const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (persistParams) {
      props.persist({ scheme, persistParams, modalOptions });
    }

    if (props.onClick) {
      props.onClick(e);
    }
  };

  return (
    <RouterLink
      to={(location) => ({
        ...location,
        pathname: makeModalPath(location.pathname, `~${to}`),
      })}
      {...omit(props, ['url', 'persist'])}
      onClick={onClick}
    >
      {props.children}
    </RouterLink>
  );
};

const dispatchToProps = {
  persist: actions.ui.modal.persist,
};

export const ModalLink = connect(null, dispatchToProps)(ModalLinkImpl);

export const ModalLinkWithAnalytics: React.FC<IModalLinkWithAnalyticsProps> = ({
  action,
  category,
  label = 'Modal opened',
  ...passedProps
}) => {
  const onClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      const { url } = passedProps;
      AnalyticsTracker.event(
        { url },
        {
          action,
          category,
          label,
        }
      );

      if (passedProps.onClick) {
        passedProps.onClick(e);
      }
    },
    []
  );

  return <ModalLink {...passedProps} onClick={onClick} />;
};
