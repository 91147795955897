import styled from '@emotion/styled/macro';
import { css } from 'emotion';

type IProps = { active: boolean };
type PanelProps = { bgColor: string };

export const Panel = styled.div<PanelProps>`
  position: relative;
  height: 100%;
  margin-right: 2px;

  background-color: ${({ bgColor }) => bgColor};
  box-shadow: 4px -2px 4px -4px rgb(0 0 0 / 30%);

  &:hover {
    box-shadow: 4px -2px 4px -4px var(--bu-primary-500);
  }
`;

export const PanelSectionTitle = styled.div`
  color: var(--bu-gray-700);

  padding: 29px 0 16px 19px;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--bu-primary-200);
`;
PanelSectionTitle.defaultProps = {
  className: 'bu-font-sub-heading',
};

export const panelSectionItem = css`
  color: var(--bu-gray-700);
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  width: 100%;
  padding-left: 16px;
  display: block;

  &,
  &:focus {
    text-decoration: none;
  }

  &.active,
  &:hover {
    color: var(--bu-gray-900);
    background-color: var(--bu-primary-200);
    background-color: var(--bu-white);
  }
`;

export const PanelContainer = styled.div<IProps>`
  height: 100%;

  min-width: ${({ active }) => (active ? '210px' : '50px')};
  transition: min-width 0.2s ease-in-out;

  &:hover {
    min-width: 210px;
  }
`;

export const PanelSectionContainer = styled.div<IProps>`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  flex-direction: column;

  ${PanelContainer}:hover > & {
    display: flex;
  }

  & > *:last-child {
    margin-bottom: 80px;
  }

  overflow: hidden;
  height: 100%;

  &:hover {
    overflow: hidden auto;
  }
`;

export const PanelCollapseExpandButton = styled.button<{ visible: boolean }>`
  position: absolute;
  display: ${({ visible }) => (visible ? 'block' : 'none')};

  ${PanelContainer}:hover + & {
    display: none;
  }

  ${PanelContainer}:hover > & {
    display: block;
  }

  height: 24px;
  width: 24px;
  font-size: 16px;
  line-height: 16px;
  border-radius: 12px;

  top: 22px;
  right: 0;
  transform: translateX(50%);

  cursor: pointer;
  outline-style: none;
  background-color: var(--bu-white);
  border-style: none;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  z-index: 988;
  padding: 0;
  color: var(--bu-primary-500);

  &:hover {
    background-color: var(--bu-primary-500);
    color: var(--bu-white);
  }
`;
