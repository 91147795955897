import React, { ComponentClass, SFC } from 'react';
import { useSelector, ConnectedComponent } from 'react-redux';

import Wrapper from 'components/UI/Wrapper';
import * as selectors from 'selectors';

type Props = {
  component: ComponentClass | ConnectedComponent<any, any> | SFC;
};

const WrappedComponent: React.FC<Props> = (props) => {
  const { appDisplayMode, isAppInitiated } = useSelector((state) => ({
    appDisplayMode: selectors.appDisplayMode(state),
    isAppInitiated: selectors.isAppInitiated(state),
  }));

  if (!isAppInitiated) {
    return null;
  }

  if (appDisplayMode === 'compact') {
    return <props.component {...props} />;
  }

  return (
    <Wrapper>
      <props.component {...props} />
    </Wrapper>
  );
};

export default WrappedComponent;
