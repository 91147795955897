import React from 'react';
import { connect } from 'react-redux';

import { EventModal } from 'components/modals/EventModal/EventModal';
import { ModalRouteProps } from 'navigation/types';
import * as selectors from 'selectors';

const CallModal: React.FC<{ event: any }> = ({ event }) => (
  <EventModal event={event} />
);

const stateToProps = (state: any, props: ModalRouteProps<{ id: string }>) => ({
  event: (selectors.getCallOpenedDetails as any)(state, props.params.id),
});

export default connect(stateToProps)(CallModal);
