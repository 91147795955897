//TODO provide extra series parameter for allowing justifying to 'top' also
/**
 * Highcharts mixin for Sankey chart, it is allowing justify nodes to the bottom
 */
export default function (H: any) {
  H.wrap(
    H.seriesTypes.sankey.prototype,
    'createNodeColumn',
    function (this: any, proceeds: any) {
      const column = proceeds.apply(
          this,
          Array.prototype.slice.call(arguments, 1)
        ),
        defaultAlignment = column.top;

      column.top = function (factor: number) {
        const defaultPosition = defaultAlignment.call(this, factor);

        return defaultPosition * 2;
      };

      return column;
    }
  );
}
