import React, { useEffect, useState } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuGroupButton from 'components/UI/BuGroupButton';
import { IOption } from 'components/UI/BuGroupButton/types';
import BuIcon from 'components/UI/BuIcon';
import BuInput from 'components/UI/BuInput';
import BuSelect from 'components/UI/BuSelect';
import { ISelectOption } from 'components/UI/BuSelect/types';
import {
  input,
  infoContainer,
  infoSpan,
  innerContainer,
  outerContainer,
  upperParagraph,
  lowerParagraph,
} from 'components/dashboard/Metrics/common/Calendar/TimeFilter/Tabs/custom.period.styles';

const CUSTOM_OPERATOR_OPTIONS: IOption[] = [
  {
    id: 'next',
    text: 'Next',
  },
  {
    id: 'last',
    text: 'Last',
  },
];

export const CUSTOM_PERIOD_OPTIONS: ISelectOption[] = [
  { text: 'Days', value: 'days' },
  { text: 'Weeks', value: 'weeks' },
  { text: 'Months', value: 'months' },
  { text: 'Quarters', value: 'quarters' },
  { text: 'Years', value: 'years' },
];

export const CUSTOM_PERIOD_LIMITS: {
  [key: string]: number;
} = {
  days: 90,
  weeks: 52,
  months: 24,
  quarters: 8,
  years: 5,
};

type Props = {
  value: string;
  onChange: (value: string) => void;
};

export const CustomPeriodTab: React.FC<Props> = ({ value, onChange }) => {
  const [operator, setOperator] = useState<string>(
    CUSTOM_OPERATOR_OPTIONS[0].id
  );
  const [count, setCount] = useState<string>('1');
  const [period, setPeriod] = useState<string>(CUSTOM_PERIOD_OPTIONS[0].value);
  const [periodLimit, setPeriodLimit] = useState(90);

  useEffect(() => {
    if (!!value && value.length) {
      const split = value.split('_');

      if (split.length === 3 && parseInt(split[1])) {
        setOperator(split[0]);
        setCount(split[1]);
        setPeriod(split[2]);

        const currentPeriodLimit = CUSTOM_PERIOD_LIMITS[split[2]];
        setPeriodLimit(currentPeriodLimit);
      }
    }
  }, []);

  const handleOperatorChange = (value: string): void => {
    setOperator(value);
    onChange(`${value}_${count}_${period}`);
  };

  const handleCountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const thisValue = parseInt(event.currentTarget.value);

    const value =
      !event.currentTarget.value ||
      event.currentTarget.value === '-' ||
      thisValue < 1
        ? '1'
        : thisValue > periodLimit
        ? String(periodLimit)
        : event.currentTarget.value;

    setCount(value);
    onChange(`${operator}_${value}_${period}`);
  };

  const handlePeriodChange = (values: string[]): void => {
    const [value] = values;
    setPeriod(value);

    const currentPeriodLimit = CUSTOM_PERIOD_LIMITS[value];
    setPeriodLimit(currentPeriodLimit);

    if (Number(count) > currentPeriodLimit) {
      setCount(String(currentPeriodLimit));
      onChange(`${operator}_${currentPeriodLimit}_${value}`);
    } else {
      onChange(`${operator}_${count}_${value}`);
    }
  };

  return (
    <div className={outerContainer}>
      <p className={upperParagraph}>Create your own custom option</p>
      <p className={lowerParagraph}>
        Choose custom past or future dates. Ex: Last 6 Months
      </p>
      <div className={innerContainer}>
        <BuGroupButton
          options={CUSTOM_OPERATOR_OPTIONS}
          selectedOption={operator}
          onSelect={(value) => handleOperatorChange(value)}
        />

        <BuInput type="number" value={count} onChange={handleCountChange} />

        <BuSelect
          fullWidth
          secondary
          className={input}
          defaults={[period]}
          options={CUSTOM_PERIOD_OPTIONS}
          onChange={handlePeriodChange}
        />
      </div>

      <div className={infoContainer}>
        <BuIcon
          name={BoostUpIcons.BadgeInfoOutline}
          color={'var(--bu-gray-700)'}
        />
        <span className={infoSpan}>
          Add a number {<br />} between 1 and {periodLimit}
        </span>
      </div>
    </div>
  );
};
