import {
  CustomViewApi,
  ProfileCustomViewRow,
  ViewSection,
} from './ProfileCustomViewsConfigurator.types';

import { IColumn, SortOrder } from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { CheckboxConfig } from 'components/settings/ForecastSettings/ForecastSubmissionTypes/types';

const PROFILE_VIEW_SECTIONS_STRUCTURE = [
  {
    name: 'Accounts',
    views: [
      {
        displayName: 'Accounts',
        value: 'accounts',
      },
    ],
  },
  {
    name: 'Forecasts',
    views: [
      {
        displayName: 'Dashboard',
        value: 'forecast_analytics',
      },
      {
        displayName: 'Trends',
        value: 'forecast_analytics_change',
      },
      {
        displayName: 'Rollups',
        value: 'forecast_roll_ups',
      },
    ],
  },
  {
    name: 'Opportunities',
    views: [
      {
        displayName: 'Opportunities',
        value: 'forecast_opportunities',
      },
    ],
  },
  {
    name: 'Pipeline',
    views: [
      {
        displayName: 'Dashboard',
        value: 'pipeline_dashboard',
      },
      {
        displayName: 'Risk Analytics',
        value: 'pipeline_analytics',
      },
      {
        displayName: 'Deals Progression',
        value: 'pipeline_deals_progression',
      },
    ],
  },
  {
    name: 'Seller',
    views: [
      {
        displayName: 'Seller Performance',
        value: 'seller_dashboard',
      },
    ],
  },
];

const NAME_COLUMN: IColumn = {
  id: 'name',
  label: 'Saved Views',
  field: 'display_name',
  type: ColumnTypes.TEXT,
  sortable: false,
  sort_order: SortOrder.ASCENDING,
  config: {},
};

const ENABLED_COLUMN: IColumn = {
  id: 'enabled',
  label: 'Enable',
  field: 'enabled',
  type: ColumnTypes.CHECKBOX,
  sortable: false,
  editable: true,
  sort_order: SortOrder.ASCENDING,
  config: {
    style: 'toggle',
  } as CheckboxConfig,
};

export const PROFILE_VIEWS_TABLE_COLUMNS = [NAME_COLUMN, ENABLED_COLUMN];

export const alphabeticallySort = (a: CustomViewApi, b: CustomViewApi) =>
  a.display_name.localeCompare(b.display_name, undefined, {
    numeric: true,
    sensitivity: 'base',
  });

export const groupViewsInSections = (
  apiViews: CustomViewApi[]
): ViewSection[] => {
  return PROFILE_VIEW_SECTIONS_STRUCTURE.map((section) => {
    const sectionViews = section.views.map((subSection) => {
      return {
        ...subSection,
        sharedViews: apiViews
          .filter((apiView) => apiView.view_section === subSection.value)
          .sort(alphabeticallySort),
      };
    });

    return {
      ...section,
      views: sectionViews,
    };
  });
};

// Placeholder when no views are available or when loading
export const EMPTY_VIEW_SECTIONS: ViewSection[] = groupViewsInSections([]);
