import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import * as dealsActions from 'actions/dealsActions';
import PipelineDealsProgression from 'components/dashboard/Pipeline/PipelineDealsProgression/PipelineDealsProgression';
import { StateProps } from 'components/dashboard/Pipeline/PipelineDealsProgression/types';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

const stateToProps = (
  state: IReduxState,
  ownProps: RouteComponentProps
): StateProps => ({
  selectedBusinessType: selectors.getSelectedBusinessType(
    state,
    'pipeline_deals_progression'
  ),
  filters: selectors.getFiltersForAPI(state, 'pipeline_deals_progression'),
  tabPartition:
    ownProps.location.pathname === '/dashboard/pipeline' ||
    ownProps.location.pathname === '/dashboard/deals-progression'
      ? 'dashboards'
      : 'pipeline',
  isRequiredDataReady: selectors.isBusinessTypesConfigReady(state),
});

const dispatchToProps = {
  toggleAllFilters: dealsActions.toggleAllFilters,
};

export default connect(stateToProps, dispatchToProps)(PipelineDealsProgression);
