import { css } from 'emotion';

import { fontHeading3, fontSubheading1 } from 'assets/css/fontStyles';

import { cardStyle, tabContentWrapper, notAvilableContent } from '../../styles';

export { tabContentWrapper, notAvilableContent };

export const summaryTabWrapper = css`
  display: flex;
  flex-direction: column;

  .sentiment-section {
    ${cardStyle}
    margin-top: 12px;
    height: 44px;
    display: flex;
    align-items: center;
    line-height: 1.4em;

    .sentiment-label {
      color: var(--bu-color-call-light-gray);
    }

    &.negative {
      background-color: var(
        --bu-color-call-summary-sentiment-negative-background
      );
    }

    &.neutral {
      background-color: var(
        --bu-color-call-summary-sentiment-neutral-background
      );
    }

    &.positive {
      background-color: var(
        --bu-color-call-summary-sentiment-positive-background
      );
    }

    &.NA {
      background-color: var(--bu-color-call-summary-sentiment-na-background);
    }

    .sentiment-circle {
      display: flex;
      align-items: center;
      margin-left: 10px;

      .sentiment-circle-base {
        height: 32px;
        width: 32px;
        margin-right: 10px;
      }

      span {
        ${fontSubheading1}
        text-transform: capitalize;
      }
    }
  }

  .summary-card {
    ${cardStyle}
    display: flex;
    flex-direction: column;
    gap: var(--bu-control-gap-xx-large);

    &.margin-top {
      margin-top: 12px;
    }

    .summary-card-section {
      display: flex;
      flex-direction: column;
      gap: var(--bu-control-gap-large);

      .summary-card-title {
        ${fontHeading3}
      }

      .summary-card-description {
        ul {
          padding-left: 24px;
          display: flex;
          flex-direction: column;
          gap: var(--bu-control-gap-large);
        }
      }
    }
  }
`;
