import { css } from 'emotion';

export const removeMargins = css`
  margin: 0px -20px;
  height: 100%;
`;

export const addButtonColor = css`
  & .menu .ui.button.primary {
    background-color: #0762ec;
    border-radius: 0;
  }
`;

export const tableActiveRowColor = css`
  & .ui.table tr.active {
    &,
    td {
      background-color: #cfeafd !important;
    }
  }
`;

export const cellActiveColor = css`
  color: #00c6ae;

  &.ui.dropdown > .text {
    color: #00c6ae;

    &:after {
      color: #444;
    }
  }
  .ui.dropdown > .menu > &.item {
    color: #00c6ae;
  }
`;

export const defaultCursor = css`
  cursor: default;
`;

export const editButton = css`
  &.ui.basic.icon.button {
    box-shadow: none;

    &:hover {
      background-color: transparent !important;
    }
  }
`;

export const disabledRow = css`
  & {
    .typed-table-cell > div {
      color: var(--bu-gray-500);

      .primary-cell {
        color: var(--bu-gray-500);
        cursor: default;

        .UserAvatar--inner {
          background-color: #ccc !important;
        }
      }
    }
  }
`;

export const tableContainer = css`
  height: calc(100% - 45px - 10px);
  margin-top: 10px;
`;

export const filter = css`
  min-height: 45px;
  padding: 0 20px;
`;

export const resetFilterButton = css`
  & > .ui.button.primary {
    background-color: #0762ec;
  }
`;

export const dropdownOptions = css`
  padding: 5px 0;
`;
