import classNames from 'classnames';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useState, useEffect, useMemo } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import {
  Dimmer,
  Dropdown,
  DropdownProps,
  Form,
  Loader,
  Label,
} from 'semantic-ui-react';

import { ordinalize } from 'common/utils';
import BuButtonRefresh from 'components/UI/BuButtonRefresh';
import { getPlotOptions } from 'components/dashboard/Pipeline/PipelineDashboard/PipelineCreationWidget/helpers';
import * as styles from 'components/dashboard/Pipeline/PipelineDashboard/PipelineCreationWidget/styles';
import {
  IProps,
  apiResponse,
  Stat,
} from 'components/dashboard/Pipeline/PipelineDashboard/PipelineCreationWidget/types';
import { QueryStatus, fetchApi } from 'utils/network';

const apiPoint = `${process.env.REACT_APP_BACKEND_URL}/api/data/pipeline/new/comparison`;
const toastOptions: ToastOptions = { position: 'bottom-left' };

const MAX_NUMBER_OF_DAYS = 92;
const DAYS_OPTIONS = Array.from(Array(MAX_NUMBER_OF_DAYS).keys()).map(
  (_item, index) => {
    const value = index + 1;
    return {
      text: `${ordinalize(value)} day`,
      value,
    };
  }
);

const RGBToColor = {
  'rgb(111,163,255)': 'var(--bu-color-default-chart)',
  'rgb(4,218,178)': 'var(--bu-color-dynamic-bar-green)',
  'rgb(255,114,113)': 'var(--bu-color-dynamic-bar-strong-pink)',
  'rgb(50,124,255)': 'var(--bu-color-default-chart)',
  '#9b9b9b': 'var(--bu-color-dynamic-bar-cyan)',
  '#587eb0': 'var(--bu-color-default-chart)',
  '#6FA3FF': 'var(--bu-color-default-chart)',
  '#04dab2': 'var(--bu-color-dynamic-bar-green)',
  '#6dc7df': 'var(--bu-color-default-chart)',
};

const changeColorsCharts = (array: any[] | undefined) => {
  if (array !== undefined) {
    array.forEach((el: any) => {
      if (el.color) {
        //@ts-ignore
        el.color = RGBToColor[el.color] ? RGBToColor[el.color] : el.color;
      }
    });
  }
};

const PipelineCreationWidget: React.FC<IProps> = ({
  companyCurrency,
  dayInQuarter,
  openFilters,
  selectedBusinessType,
}) => {
  const [selectedDay, setSelectedDay] = useState<number>(dayInQuarter || 1);
  const [apiData, setApiData] = useState<Stat[]>([]);
  const [dataStatus, setDataStatus] = useState<QueryStatus>('notAsked');
  const [showPipelineCreatedValue, setShowPipelineCreatedValue] =
    useState(true);
  const [showPipelineCreationTarget, setShowPipelineCreationTarget] =
    useState(true);
  const [cacheDate, setCacheDate] = useState('');
  const [refreshCache, setRefreshCache] = useState(false);

  const isLoading = ['notAsked', 'loading'].includes(dataStatus);

  const serializedQueryParams = useMemo(
    () =>
      JSON.stringify({
        relative_days: selectedDay,
        business_type_name: selectedBusinessType,
        ...openFilters,
      }),
    [JSON.stringify(openFilters), selectedBusinessType, selectedDay]
  );

  useEffect(() => {
    const abortController = new AbortController();

    fetchApi<string, apiResponse>({
      queryParams: serializedQueryParams,
      setData: ({ data }: apiResponse) => setApiData(data),
      setError: (error: string | null) =>
        toast.error(`Fetching data failed: ${error}`, toastOptions),
      setStatus: setDataStatus,
      setHeaders: (headers) => setCacheDate(headers.get('cache-created') || ''),
      signal: abortController.signal,
      url: apiPoint,
    });

    return () => abortController && abortController.abort();
  }, [serializedQueryParams]);

  useEffect(() => {
    let abortController: AbortController | null = null;

    if (refreshCache) {
      abortController = new AbortController();

      fetchApi<string, apiResponse>({
        queryParams: serializedQueryParams,
        setData: ({ data }: apiResponse) => setApiData(data),
        setError: (error: string | null) =>
          toast.error(`Fetching data failed: ${error}`, toastOptions),
        setStatus: setDataStatus,
        setHeaders: (headers) => {
          setCacheDate(headers.get('cache-created') || '');
          setRefreshCache(false);
        },
        signal: abortController.signal,
        url: refreshCache ? `${apiPoint}?cache_refresh=1` : apiPoint,
      });
    }

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [serializedQueryParams, refreshCache]);

  const options = useMemo(
    () =>
      getPlotOptions(
        apiData,
        companyCurrency,
        showPipelineCreatedValue,
        showPipelineCreationTarget
      ),
    [
      apiData,
      companyCurrency,
      showPipelineCreatedValue,
      showPipelineCreationTarget,
    ]
  );

  changeColorsCharts(options.series);

  return (
    <Dimmer.Dimmable dimmed={isLoading}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.header_title}>
            Total Pipeline Created by
            <Dropdown
              className={styles.dropdownContainer}
              inline
              options={DAYS_OPTIONS}
              onChange={(e: React.SyntheticEvent, option: DropdownProps) =>
                setSelectedDay(option.value as number)
              }
              value={selectedDay}
            />
            of previous quarters
          </div>
          <div>
            <BuButtonRefresh
              status={refreshCache}
              cacheDate={cacheDate}
              onClick={() => setRefreshCache(true)}
            />
          </div>
        </div>

        <div className={styles.widgetActions}>
          <Form>
            <Form.Group
              inline
              className={classNames(styles.resetMargins, styles.fieldSpacing)}
            >
              <Form.Checkbox
                className={styles.checkbox_custom}
                checked={showPipelineCreatedValue}
                onChange={() =>
                  setShowPipelineCreatedValue(!showPipelineCreatedValue)
                }
                label={{
                  children: (
                    <div
                      className={classNames(
                        styles.dot,
                        'pipeline_created_value'
                      )}
                    >
                      Total Pipeline Created Value
                    </div>
                  ),
                }}
              />
              <Form.Checkbox
                className={styles.checkbox_custom}
                checked={showPipelineCreationTarget}
                onChange={() =>
                  setShowPipelineCreationTarget(!showPipelineCreationTarget)
                }
                label={{
                  children: (
                    <div
                      className={classNames(
                        styles.dot,
                        'pipeline_creation_target'
                      )}
                    >
                      Pipeline Creation Target
                    </div>
                  ),
                }}
              />
            </Form.Group>
          </Form>
        </div>

        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          immutable={true}
        />

        <div className={styles.widgetTable}>
          <div className={classNames('grid-column', styles.mainColumn)}>
            <div className="grid-cell grid-header" />
          </div>

          {apiData.map((stat, index) => (
            <div className="grid-column">
              <div className="grid-cell grid-header">
                {apiData.length - 1 === index && (
                  <Label
                    color="blue"
                    size="tiny"
                    className={styles.currentQuarterLabel}
                  >
                    CQ
                  </Label>
                )}
                {stat.name}
              </div>
            </div>
          ))}
        </div>
      </div>

      <Dimmer active={isLoading} inverted>
        <Loader />
      </Dimmer>
    </Dimmer.Dimmable>
  );
};

export default PipelineCreationWidget;
