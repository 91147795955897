import cs from 'classnames';
import { css } from 'emotion';
import React, { HTMLAttributes } from 'react';

export const buttonClose = (
  closeButtonSize = 40,
  closeButtonXSize = 20,
  closeButtonXWeight = 2
) => css`
  width: ${closeButtonSize}px;
  height: ${closeButtonSize}px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  transition: 0.4s;
  border: none;
  display: inline-block;
  color: #000;
  margin-right: 10px;
  margin-top: -10px;
  position: relative;

  &:before,
  &:after {
    content: '';
    width: ${closeButtonXWeight}px;
    border-radius: ${closeButtonXWeight}px;
    height: ${closeButtonXSize - closeButtonXWeight}px;
    background: #4a4a4a;
    position: absolute;
    right: ${closeButtonXSize - closeButtonXWeight / 2}px;
    top: ${(closeButtonSize - closeButtonXSize) / 2 + closeButtonXWeight / 2}px;
  }

  &:after {
    transform: rotate(45deg);
  }

  &:before {
    transform: rotate(-45deg);
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background: rgba(0, 83, 204, 0.3);

    &:after,
    &:before {
      background: #fff;
    }
  }
`;

const CloseButton: React.FC<
  Omit<HTMLAttributes<HTMLButtonElement>, 'type'> & { size?: number }
> = ({ size, className, ...attributes }) => (
  <button
    type="button"
    className={cs(buttonClose(size), className)}
    {...attributes}
  />
);

export default CloseButton;
