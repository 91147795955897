import classNames from 'classnames';
import moment, { MomentInput } from 'moment';
import { pathOr, isNil } from 'ramda';
import React, { FC } from 'react';

import { DealsInsightsColumnConfig } from 'components/UI/TableConfig/types';
import { ValueProp } from 'components/UI/common/TypedTable/TypedTable';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import { DropdownOrText } from 'components/deal/DropdownOrText';
import { MoneyField } from 'components/deal/MoneyField';
import styles from 'components/deal/styles';
import { DealInsights } from 'components/deal/types';
import { DispatchProps } from 'components/deal/types';

type Props = {
  deal: DealInsights;
  hiddenColumns: string[];
  companyCurrency: string;
  isMulticurrencyEnabled: boolean;
  dealsInsightsColumns: DealsInsightsColumnConfig[];
  changeDeal: DispatchProps['changeDeal'];
  isEditDisabled: (value: string) => boolean;
};

export const ExtraFields: FC<Props> = ({
  deal,
  hiddenColumns,
  companyCurrency,
  isMulticurrencyEnabled,
  dealsInsightsColumns,
  changeDeal,
  isEditDisabled,
}) => {
  const NA_LABEL = 'Not Available';

  return (
    <>
      {dealsInsightsColumns.map((column: DealsInsightsColumnConfig) => {
        const field = column.display_name;
        const isDeltaField = column.meta?.delta;
        const pathArr = column.object_field.split('.');
        if (isDeltaField) {
          pathArr.push('value');
        }
        const value = pathOr(NA_LABEL, pathArr, deal);
        const fieldName = column.object_field.split('.').pop() || '';
        const splittedFieldName = (deal?.splitted_fields ?? []).includes(
          fieldName
        )
          ? fieldName
          : null;

        if (hiddenColumns.includes(field.toLowerCase())) {
          return null;
        }

        switch (column.type) {
          case 'money' || 'corporate_currency':
            const exchangeRate = pathOr(1, ['exchange_rate'], deal);
            const currency = pathOr(
              companyCurrency,
              ['crm_metadata', 'currency'],
              deal
            );

            const changeDealCb =
              !!deal?.additional_fields_delta &&
              deal?.additional_fields_delta[fieldName]
                ? (id: string, changes: Record<string, ValueProp>) =>
                    changeDeal(deal?._id, {
                      [`additional_fields_delta.${fieldName}`]:
                        changes[field.toLowerCase()],
                    })
                : changeDeal;

            return (
              <MoneyField
                key={fieldName}
                id={deal?._id}
                field={field}
                value={value}
                splittedFieldName={splittedFieldName}
                currency={currency}
                exchangeRate={exchangeRate}
                companyCurrency={companyCurrency}
                changeDeal={changeDealCb}
                isEditDisabled={isEditDisabled}
                isMulticurrencyEnabled={isMulticurrencyEnabled}
                accountExecutive={deal?.account_executive}
              />
            );

          case 'bool':
            return (
              <article
                id={`deal-container-header-tabs-item-${field.replace(' ', '')}`}
                className="deal-header-tabs_item"
                key={field}
              >
                {field}
                &nbsp;
                <span className="deal-header-tabs_item--black">
                  {!isEditDisabled(field) ? (
                    <DropdownOrText
                      isBool
                      field={field}
                      fieldDropdown={fieldName}
                      valueRaw={value}
                      onChange={(value) =>
                        changeDeal(deal?._id, {
                          [column.object_field]: value,
                        })
                      }
                      multiple={false}
                      isEditDisabled={isEditDisabled}
                    />
                  ) : (
                    <span>
                      {(isNil(value)
                        ? NA_LABEL
                        : Boolean(value)
                        ? 'True'
                        : 'False'
                      ).toString()}
                    </span>
                  )}
                </span>
              </article>
            );
          case 'date':
            return (
              <article
                id={`deal-container-header-tabs-item-${field.replace(' ', '')}`}
                className="deal-header-tabs_item"
                key={field}
              >
                {field}
                &nbsp;
                <span className="deal-header-tabs_item--black">
                  {!isNil(value) && value !== NA_LABEL
                    ? moment
                        .parseZone(value as MomentInput)
                        .utc(false)
                        .format('DD MMM YYYY')
                    : NA_LABEL}
                </span>
              </article>
            );
          case 'multipicklist' || fieldName === 'ML_Task__c':
            return (
              <article
                key={field}
                id={`deal-container-header-tabs-item-${field.replace(' ', '')}`}
                className={classNames(
                  'deal-header-tabs_item',
                  isEditDisabled(fieldName) && styles.disablePointerEvents
                )}
              >
                {field}
                &nbsp;
                <span className="deal-header-tabs_item--black">
                  <DropdownOrText
                    field={fieldName}
                    fieldDropdown={fieldName}
                    valueRaw={value}
                    onChange={(v: string) =>
                      changeDeal(deal?._id, {
                        [field]: v.split(',').filter((item: string) => !!item),
                      })
                    }
                    multiple
                    isEditDisabled={isEditDisabled}
                  />
                </span>
              </article>
            );
          default:
            return (
              <article
                id={`deal-container-header-tabs-item-${field.replace(' ', '')}`}
                className="deal-header-tabs_item"
                key={field}
              >
                {field}
                &nbsp;
                <span className="deal-header-tabs_item--black">
                  <TooltipWrapper
                    tooltip={!isNil(value) ? value : NA_LABEL}
                    showOnTruncatedOnly
                  >
                    {!isNil(value) ? value : NA_LABEL}
                  </TooltipWrapper>
                </span>
              </article>
            );
        }
      })}
    </>
  );
};
