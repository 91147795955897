import styled from '@emotion/styled';
import React from 'react';

const ScrollingContainer = styled.div`
  position: relative;
  width: ${({ width }: { width: number }) => `${width}px`};
  min-width: 100%;
  z-index: 10;
`;

const YAxisChart = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: ${({ width }: { width: number }) => `${width}px`};
`;

const MainChart = styled.div`
  position: absolute;
  overflow: hidden;
  min-width: 100%;
  width: ${({ width }: { width: number }) => `${width}px`};

  & div[data-highcharts-chart] {
    overflow: visible !important;
  }
`;

/*
 * Chart(Highchart) should have hardcoded Highcharts.Options `chart.marginLeft` value
 * as YAxisWidth property for fixing y axis label width
 */
const ScrollableChart: React.FC<{
  YAxisWidth: number;
  width: number;
  disable?: boolean;
}> = ({ YAxisWidth, width, children, disable = false }) =>
  disable ? (
    <>{children}</>
  ) : (
    <ScrollingContainer width={width}>
      <MainChart width={width}>{children}</MainChart>

      <YAxisChart width={YAxisWidth}>{children}</YAxisChart>
    </ScrollingContainer>
  );

export default ScrollableChart;
