import { css } from 'emotion';
import moment from 'moment';
import React from 'react';
import { Button } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import BuToggle from 'components/UI/BuToggle';

const marginRight = css`
  margin-right: 30px;
`;

const syncMessageContainer = css`
  display: flex;
  flex-direction: column;
  .watcherMessage {
    color: var(--bu-gray-700);
    font-size: 12px;
    line-height: 20px;
  }

  .failed {
    color: var(--bu-red-400);
  }
  > span {
    display: flex;
    align-items: center;
    .bu-icon {
      font-size: 18px;
      margin-right: 6px;
    }
  }
`;

const buttonMargins = css`
  &.ui.button {
    margin: 0 10px;
  }
`;

const WARNING_DAYS = 2;

export const switchLabel = (value?: boolean) => (value ? 'ON' : 'OFF');

type ISyncProps = {
  checked: boolean;
  onChange(checked: boolean): void;
  lastSyncDate?: string;
  lastWatcherDate?: string;
  lastWatcherFailedDate?: string;
  lastSyncFailedDate?: string;
  hasWatcherFailed?: boolean;
  hasSyncFailed?: boolean;
  onSync(): void;
  isAdminEnabled: boolean;
  disabled?: boolean;
};

const SyncCheckbox: React.FC<ISyncProps> = ({
  checked,
  onChange,
  onSync,
  lastSyncDate,
  lastWatcherDate,
  lastWatcherFailedDate,
  lastSyncFailedDate,
  hasWatcherFailed,
  hasSyncFailed,
  isAdminEnabled,
  disabled = false,
}: ISyncProps) => {
  return (
    <>
      <BuToggle
        className={marginRight}
        checked={checked}
        onChange={(event) => onChange(event.target.checked)}
        disabled={disabled}
      >
        {switchLabel(checked)}
      </BuToggle>
      <div className={syncMessageContainer}>
        {hasSyncFailed ? (
          <span className="failed">
            <BuIcon
              name={BoostUpIcons.DangerCircle}
              color="var(--bu-red-400)"
            />
            Failed ({moment(lastSyncFailedDate).fromNow()})
          </span>
        ) : (
          <span>
            <BuIcon
              name={BoostUpIcons.CheckCircle}
              color="var(--bu-green-500)"
            />
            {moment(lastSyncDate).fromNow()}
          </span>
        )}

        {hasWatcherFailed ? (
          <div className="watcherMessage failed">
            Watcher failed on{' '}
            {moment(lastWatcherFailedDate).format('Do MMMM, h:mm a')}
          </div>
        ) : (
          <div className="watcherMessage">
            Watcher refreshed on{' '}
            {moment(lastWatcherDate).format('Do MMMM, h:mm a')}
          </div>
        )}
      </div>
      {checked && isAdminEnabled && (
        <Button
          className={buttonMargins}
          icon="redo"
          content="Sync"
          color="green"
          size="mini"
          basic
          onClick={onSync}
        />
      )}
    </>
  );
};

export default SyncCheckbox;
