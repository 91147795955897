/* eslint-disable no-param-reassign */
import produce from 'immer';
import moment from 'moment';
import * as R from 'ramda';
import { toast } from 'react-toastify';

import * as t from 'actions/actionTypes';
import initialState, {
  defaultTab,
} from 'reducers/opportunitiesReducer/initialState';

function addPropStatus(draft, id, prop, status, value = undefined) {
  if (!draft.dealStatus[id]) {
    draft.dealStatus[id] = {};
  }

  if (!draft.dealStatus[id][prop]) {
    draft.dealStatus[id][prop] = {};
  }

  draft.dealStatus[id][prop] = {
    ...draft.dealStatus[id][prop],
    status,
    date: Date.now(),
    value,
  };
}

function addRowStatus(draft, id, status, value = undefined) {
  if (!draft.dealStatus[id]) {
    draft.dealStatus[id] = {};
  }

  draft.dealStatus[id] = {
    status,
    date: Date.now(),
    value,
  };
}

export default function opportunitiesReducer(state = initialState, action) {
  const { type, payload, tab, reportId } = action;
  const reducer = {
    [t.DEALS + t.SET_ACTIVE_TAB]: (draft) => {
      draft.currentTab = tab;
    },
    [t.DEALS + t.SAVE_TAB_SCROLL]: (draft) => {
      draft[tab].scroll = action.scroll;
    },
    [t.DEALS + t.FILTER + t.LOADING]: (draft) => {
      draft[tab].stats.status = 'loading';
    },
    [t.DEALS + t.FILTER + t.SUCCESS]: (draft) => {
      draft[tab].stats = action.stats.data;
      draft[tab].stats.status = 'success';
      if (action.deals) {
        draft[tab].deals.status = 'success';
        draft[tab].deals.data.count = action.deals.data.count;
        draft[tab].deals.list = action.deals.data.deals;
      }
    },
    [t.DEALS + t.GET + t.DETAIL + t.LOADING]: (draft) => {
      draft.dealDetailed[action.id] = {
        id: action.id,
        status: 'loading',
      };
    },
    [t.DEALS + t.GET + t.DETAIL + t.SUCCESS]: (draft) => {
      draft.dealDetailed[action.id] = {
        id: action.payload.data.id,
        data: action.payload.data,
        status: 'success',
        calculated_data: calculateDataForDealDetailed(action.payload.data),
      };
    },
    [t.DEAL + t.DETAIL + t.UPDATE + t.SUCCESS]: (draft) => {
      draft.dealDetailed[action.id] = {
        id: action.payload.data.id,
        data: Object.assign(
          draft.dealDetailed[action.id].data,
          action.payload.data
        ),
        status: 'success',
        calculated_data: calculateDataForDealDetailed(action.payload.data),
      };

      // TODO: Reducers are for data. Drop this code and use the sagas!
      toast.success('CRM update successful.', {
        position: 'bottom-left',
      });
    },
    [t.DEAL + t.DETAIL + t.UPDATE + t.LOADING]: (draft) => {
      // TODO: Reducers are for data. Drop this code and use the sagas!
      toast.warn('CRM update in progress.', {
        position: 'bottom-left',
      });
    },
    [t.DEAL + t.DETAIL + t.UPDATE + t.ERROR]: (draft) => {
      // TODO: Reducers are for data. Drop this code and use the sagas!
      toast.error(
        action.error || "Sorry, we couldn't update the CRM. Please try again.",
        {
          position: 'bottom-left',
        }
      );
    },
    [t.DEALS + t.FILTER + t.TOGGLE]: (draft) => {
      if (tab === 'all') {
        for (const i in draft) {
          if (draft[i] && draft[i].isFiltersOpened !== undefined) {
            draft[i].isFiltersOpened = action.shouldClose
              ? false
              : !draft[tab].isFiltersOpened;
          }
        }
      } else if (!draft[tab]) {
        draft[tab] = defaultTab;
        draft[tab].isFiltersOpened = true;
      } else {
        draft[tab].isFiltersOpened = action.shouldClose
          ? false
          : !draft[tab].isFiltersOpened;
      }
    },
    [t.DEAL_STAGE + t.GET + t.SUCCESS]: (draft) => {
      draft.stages = payload.data;
    },
    [t.DEALS + t.REPORT + t.SORTING + t.SUCCESS]: (draft) => {
      draft.allDeals.customReports[reportId].filters.sort = action.sorting;
      draft.allDeals.customReports[reportId].deals = action.deals;
    },
    [t.DEALS + t.UPDATE + t.SUCCESS]: (draft) => {
      // TODO: Reducers are for data. Drop this code and use the sagas!
      toast.success('CRM update successful.', {
        position: 'bottom-left',
      });

      Object.keys(action.payload.changes).forEach((prop) => {
        addPropStatus(
          draft,
          action.payload.id,
          prop,
          'success',
          action.payload.changes[prop]
        );
      });
      if (action.payload.isARealTimeUpdate) {
        draft.dealStatus.someWasModifiedSinceRecalculation = true;
      }
    },
    [t.DEALS_MODIFIED_SINCE_RECALCULATE_RESET]: (draft) => {
      draft.dealStatus.someWasModifiedSinceRecalculation = false;
    },
    [t.DEALS + t.UPDATE + t.SALES_PROCESS + t.LOADING]: (draft) => {
      addRowStatus(draft, action.payload.id, 'loading');
    },
    [t.DEALS + t.UPDATE + t.SALES_PROCESS + t.SUCCESS]: (draft) => {
      draft.topOpportunities.deals.changes = action.payload.response;
      addRowStatus(draft, action.payload.id, 'success');
      if (action.payload.isARealTimeUpdate) {
        draft.dealStatus.someWasModifiedSinceRecalculation = true;
        addPropStatus(
          draft,
          action.payload.id,
          'sales_process',
          'success',
          action.payload.response.sales_process
        );
      }
    },
    [t.DEALS + t.UPDATE + t.SALES_PROCESS + t.ERROR]: (draft) => {
      addRowStatus(draft, action.payload.id, 'error');
    },
    [t.DEALS + t.UPDATE + t.LOADING]: (draft) => {
      // TODO: Reducers are for data. Drop this code and use the sagas!
      toast.warn('CRM update in progress.', {
        position: 'bottom-left',
      });

      Object.keys(action.payload.changes).forEach((prop) => {
        addPropStatus(
          draft,
          action.payload.id,
          prop,
          'loading',
          action.payload.changes[prop]
        );
      });
    },
    [t.DEALS + t.UPDATE + t.ERROR]: (draft) => {
      Object.keys(action.payload.changes).forEach((prop) => {
        addPropStatus(
          draft,
          action.payload.id,
          prop,
          'error',
          action.payload.changes[prop]
        );
      });
    },
    [t.DEALS + t.REFRESH]: (draft) => {
      draft.forceRefresh = !draft.forceRefresh;
    },
    [t.DEALS + t.DESCRIBE + t.SUCCESS]: (draft) => {
      draft.fieldsDescriptions = action.payload.data;
    },
    [t.DEALS + t.SET + t.COUNT]: (draft) => {
      draft.count[action.key] = action.count;
    },
    [t.DEAL + t.SYNC + t.LOADING]: (draft) => {
      addRowStatus(draft, action.id, 'loading');
    },
    [t.DEAL + t.SYNC + t.SUCCESS]: (draft) => {
      draft.dealDetailed[action.id] = {
        id: action.id,
        data: {
          ...(!!draft.dealDetailed[action.id]
            ? draft.dealDetailed[action.id].data
            : {}),
          ...payload.data,
        },
        status: 'refreshed',
      };
      addRowStatus(draft, action.id, 'success');
    },
    [t.DEAL + t.SYNC + t.ERROR]: (draft) => {
      draft.dealDetailed[action.id] = {
        ...draft.dealDetailed[action.id],
        id: action.id,
        status: 'error',
      };

      addRowStatus(draft, action.id, 'error');

      const errorMessage =
        action.error?.response?.data?.error?.message?.join(', ');

      if (errorMessage) {
        // TODO: Reducers are for data. Drop this code and use the sagas!
        toast.error(errorMessage, {
          position: 'bottom-left',
        });
      }
    },

    [t.SET_SHOW_LIVE_BADGE_ON_MODAL]: (draft) => {
      const { show } = payload;
      draft.showLiveBadgeOnModal = show;
    },
  };

  return Object.prototype.hasOwnProperty.call(reducer, type)
    ? produce(state, reducer[type])
    : state;
}

function calculateDataForDealDetailed(deal) {
  const { calls = [], events = [], contact_engagement = [] } = deal;

  const prospectTouched = contact_engagement.filter((c) => c.is_customer);
  const dateComparator = (a, b) =>
    moment(b.start).format('X') - moment(a.start).format('X');

  const callsAndMeetings = R.compose(
    R.sort(dateComparator),
    R.concat(calls),
    R.filter((e) => !calls.some((c) => c.event_id === e.id))
  )(events);

  const prospectEngagement = prospectTouched.filter((c) => c.is_engaged > 0);
  return {
    no_show_count: R.compose(
      R.length,
      R.filter(R.propEq('no_show', true))
    )(calls),
    last_meeting: R.head(callsAndMeetings),
    calls_and_meetings: callsAndMeetings,
    prospects_touched: prospectTouched,
    prospects_engaged: prospectEngagement,
    prospect_titles_engaged: prospectEngagement
      .map((c) => c.title)
      .filter((title) => !title),
    last_active_prospect: prospectEngagement.sort(
      (a, b) => a.last_engaged.contacted_at < b.last_engaged.contacted_at
    )[0],
  };
}
