import {
  TrendItem,
  formatLabel,
  getDeltaOverPeriod,
  getModalParamsForBar,
  getTrendWaterfallTooltip,
} from './TrendsWaterfall.helper';
import { GetTrendsPayload } from 'api/Trends';
import classNames from 'classnames';
import { TooltipFormatterContextObject } from 'highcharts';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'actions';
import { showLiveBadgeOnModal } from 'actions/opportunities';
import { TABLE_NAME } from 'common/constants';
import OppSplitViewToggle from 'components/UI/TableConfig/OppSplitViewToggle';
import Chart from 'components/chart-dashboards/Widget/Chart';
import ChartTitle from 'components/chart-dashboards/Widget/ChartTitle';
import WidgetHeader from 'components/chart-dashboards/Widget/WidgetHeader';
import { chartWrapper } from 'components/chart-dashboards/Widget/styles';
import { PersistQueryParams } from 'navigation/types';
import { openModal } from 'navigation/utils';
import { getUserLocalCurrency } from 'selectors';

interface Props {
  trends: TrendItem[];
  filters: GetTrendsPayload;
  splitToggleEnabled?: boolean;
  onIntervalChange: (changeInterval: string) => void;
}
const TrendsWaterfall = ({
  trends,
  filters,
  splitToggleEnabled,
  onIntervalChange,
}: Props) => {
  const companyCurrencyCode = useSelector(getUserLocalCurrency);
  const deltaOverPeriod = getDeltaOverPeriod(trends);
  const selectedBusinessType = filters.business_type_name || '';
  const closeDateInterval = filters.close_date_interval;
  const changeInterval = filters.change_interval ?? 'L7D';

  const dispatch = useDispatch();
  const cvsFilters = {
    ...filters,
    change_categories: {
      categories: trends.map((trend) => trend.label),
      ids: trends.reduce(
        (acc, trend) => ({
          ...acc,
          [trend.label]: trend.ids,
        }),
        {}
      ),
    },
    is_waterfall_chart: true,
    table_name: TABLE_NAME.DealsDelta,
  };

  function onBarClick(_: any, clickedBarLabel: string) {
    const clickedBar = trends.find((i) => i.label === clickedBarLabel);

    if (!clickedBar) {
      return;
    }

    const showLiveBadge =
      clickedBar.filters.forecast_series === 'current_forecast';

    dispatch(showLiveBadgeOnModal(showLiveBadge));

    const modalParams = getModalParamsForBar(clickedBar, filters);

    // TODO - migrate to useModal hook, this will require changes on DealsModal component
    openModal({
      scheme: '/deals',
      params: {
        localStorageKeyPrefix: 'ForecastTrends',
      },
      persistParams: modalParams,
      persistor: (params: PersistQueryParams) =>
        dispatch(actions.ui.modal.persist(params)),
    });
  }

  const handleIntervalChange = (filterObject: {
    change_interval: string[];
  }) => {
    const changeIntervalValue = filterObject.change_interval[0];
    onIntervalChange(changeIntervalValue);
  };

  return (
    <div className={classNames('chart-wrapper', chartWrapper)}>
      <WidgetHeader
        children={
          <ChartTitle
            changeInterval={changeInterval}
            chart_type="waterfall"
            title="All changes for deals"
            closeDateInterval={closeDateInterval}
            deltaOverPeriod={deltaOverPeriod}
            extendedTitle
          />
        }
        tab="forecast_analytics_change"
        url="/api/data/deals/csv"
        chart_type="waterfall"
        serializedQueryParams={JSON.stringify(cvsFilters)}
        timeFilterValue={changeInterval}
        setLocalFilters={handleIntervalChange}
        withTimeFilters
        extraBeforeFilters={
          splitToggleEnabled && (
            <OppSplitViewToggle activeBusinessType={selectedBusinessType} />
          )
        }
      />
      {trends && (
        <Chart
          chartType="waterfall"
          data={[{ data: trends }]}
          onClick={onBarClick}
          companyCurrencyCode={companyCurrencyCode}
          title="All changes for deals"
          labelPath="label"
          valuePath="y"
          tooltip={{
            useHTML: true,
            formatter: function (this: TooltipFormatterContextObject) {
              return getTrendWaterfallTooltip(this, companyCurrencyCode);
            },
          }}
          xAxisLabelFormatter={function () {
            return formatLabel((this.value || '') as string, '<br />');
          }}
        />
      )}
    </div>
  );
};

export default TrendsWaterfall;
