import BuSkeleton from '../../UI/BuSkeleton';
import * as styles from './styles';
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { fontCaption } from 'assets/css/common';
import { formatAmount } from 'common/helpers';
import { formatMoney } from 'common/numbers';
import BuIcon from 'components/UI/BuIcon';
import Tooltip from 'components/UI/common/Tooltip';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

interface IProps {
  id?: string;
  title: string;
  value: number;
  count?: number;
  highRisk?: boolean;
  changedValue?: string;
  companyCurrency: string;
  onClick?: () => void;
  selectedItem?: string;
  deltaValue?: any;
  metricNumber?: number;
  isLoading: boolean;
  clickable?: boolean;
}

const getMetricTooltipContent = (metricName: string) => {
  return (
    <div>
      {metricName === 'Booked' ? (
        <>
          <strong>Booked</strong> metric is a sum of all won deals which may
          include deals of disabled users based on the "show active users only"
          toggle. This ignores <strong>Forecast Category</strong> and{' '}
          <strong>Deal Stages</strong> filters and has no dependency on the
          other metrics
        </>
      ) : (
        <>
          <strong>{metricName}</strong> metric includes the{' '}
          <strong>Forecast Category</strong> filter set to <i>'{metricName}'</i>{' '}
          and <strong>Deal Stages</strong> filter set to the default or selected
          options
        </>
      )}
    </div>
  );
};

function TotalPanel(props: IProps) {
  const companyCurrencyCode = useSelector(selectors.getUserLocalCurrency);
  const isSelected = props.selectedItem === props.id;
  const notSelected = props.selectedItem
    ? props.selectedItem !== props.id
    : false;

  const panelClasses = classNames({
    [styles.totalPanel]: true,
    [styles.highRisk]: props.highRisk,
    is_selected: isSelected,
    not_selected: notSelected,
    not_clickable: !props.clickable,
  });

  const currencyValue = formatMoney(props.companyCurrency, props.value, 0);

  return (
    <div
      id={props.id}
      role="button"
      tabIndex={0}
      onClick={props.clickable ? props.onClick : () => {}}
      className={classNames('total-box', panelClasses)}
      data-metric-number={`${props.metricNumber}`}
    >
      <Popup
        content={getMetricTooltipContent(props.title!)}
        position="bottom center"
        mouseEnterDelay={1000}
        trigger={
          <div className={styles.totalBox}>
            {props.isLoading ? (
              <BuSkeleton height={15} width={120} />
            ) : (
              <div
                className={classNames(
                  'total-title',
                  fontCaption,
                  styles.totalTitle
                )}
              >
                <span className={styles.truncateTitle}>
                  {props.highRisk ? `${props.title} At Risk` : `${props.title}`}
                </span>

                {props.count ? (
                  <span>
                    &nbsp;({props.count} {props.count === 1 ? 'Deal' : 'Deals'})
                  </span>
                ) : null}

                <div style={{ display: 'inline-block', marginRight: '3px' }}>
                  <BuIcon
                    name={BoostUpIcons.BadgeInfoOutline}
                    color={'var(--bu-gray-900)'}
                  />
                </div>
              </div>
            )}

            {props.isLoading ? (
              <BuSkeleton height={20} width={100} />
            ) : (
              <div className={classNames('total-footer', styles.totalFooter)}>
                <div
                  className={classNames('total-currency', styles.totalCurrency)}
                >
                  <Tooltip tooltip={currencyValue}>{`${formatAmount(
                    companyCurrencyCode,
                    props.value
                  )}`}</Tooltip>
                </div>
              </div>
            )}
          </div>
        }
      />
    </div>
  );
}

const mapStateToProps = (state: IReduxState) => ({
  companyCurrency: selectors.getUserLocalCurrency(state),
});

export default connect(mapStateToProps)(TotalPanel);
