import { find, isNil, propEq } from 'ramda';
import React, { useCallback } from 'react';
import { Label } from 'semantic-ui-react';

import { ForecastPaceQuarterlyData } from 'actions/analyticsActions';
import { formatAmount } from 'common/helpers';
import { multiplierFormatter } from 'components/UI/TableConfig/column-helper';
import { Progressbar } from 'components/dashboard/ForecastDashboard/Progressbar';
import * as styles from 'components/dashboard/ForecastDashboard/Widget3/styles';

type IProps = {
  quarters: ForecastPaceQuarterlyData[];
  period: string;
  currentQuarter: boolean;
  companyCurrency: string;
  colIndex: number;
};

const QuarterSummary: React.FC<IProps> = ({
  quarters,
  period,
  currentQuarter,
  companyCurrency,
  colIndex,
}) => {
  const currencyFormatter = useCallback(
    (value: number | null) =>
      value !== null ? formatAmount(companyCurrency, value) : '-',
    [companyCurrency]
  );

  const coverageFormatter = (value: number | null) =>
    value !== null ? multiplierFormatter(value) : '-';

  const getValuesByName = useCallback(
    (name: string) => {
      const el = find(propEq('name', name.toLowerCase()))(quarters);

      return (el as ForecastPaceQuarterlyData).values;
    },
    [quarters]
  );

  return (
    <div className="grid-column">
      <div className="grid-cell grid-header">
        {currentQuarter && (
          <Label
            color="blue"
            size="tiny"
            className={styles.currentQuarterLabel}
          >
            CQ
          </Label>
        )}
        {period}
      </div>
      {quarters.map(({ name, values }) => {
        switch (true) {
          case name === 'booked':
            const bookedValue = getValuesByName('booked')[colIndex];
            const targetValue = getValuesByName('target')[colIndex];

            return (
              <div key={name} className="grid-cell">
                <div className={styles.alignRight}>
                  {currencyFormatter(values[colIndex])}
                  {!isNil(bookedValue) && !isNil(targetValue) && (
                    <Progressbar
                      target={Number(targetValue)}
                      value={Number(bookedValue)}
                    />
                  )}
                </div>
              </div>
            );
          case ['pipeline_coverage', 'pipeline_coverage_target'].includes(name):
            return (
              <div key={name} className="grid-cell">
                <div className={styles.alignRight}>
                  {coverageFormatter(values[colIndex])}
                </div>
              </div>
            );
          default:
            return (
              <div key={name} className="grid-cell">
                <div className={styles.alignRight}>
                  {currencyFormatter(values[colIndex])}
                </div>
              </div>
            );
        }
      })}
    </div>
  );
};

export default QuarterSummary;
