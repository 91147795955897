import clx from 'classnames';
import { css } from 'emotion';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import NextStepIcon from 'assets/images/new_icon/nextup_icon.svg';
import { ModalRouteProps } from 'navigation/types';
import { IReduxState } from 'reducers/types';
import { getCustomerNextSteps, getEngagementStatus } from 'selectors';

type NextStep = {
  created_at: string;
  next_step: string;
};

type OwnProps = ModalRouteProps<{ nextStepId: string }>;
type StateProps = { nextStep: NextStep; status: string };
type Props = OwnProps & StateProps;

const emptyModalStyle = css`
  text-align: center;
  font-size: 20px;
  padding: 30px;
`;

const box_email = css`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;
  text-align: left;
`;

const box_email_line = css`
  display: grid;
  grid-template-columns: 1.5fr 10fr;
`;

const box_email_icon = css`
  display: flex;
  justify-content: flex-end;
  font-family: var(--bu-font-medium);
  font-size: 14px;
  color: #777777;
  padding-right: 10px;
  img {
    width: 30px;
    height: 30px;
  }
`;

const margin_bottom = css`
  margin-bottom: 20px;
`;

const align_left = css`
  text-align: left;
  display: flex;
  align-items: center;
`;

const date = css`
  color: #777777;
  font-size: 14px;
  font-family: var(--bu-font-regular);
  display: flex;
  align-items: center;
  height: 100%;
`;

const text = css`
  display: flex;
  flex-direction: column;
  color: #777777;
  font-size: 14px;
  font-family: var(--bu-font-regular);
  text-align: left;
`;

const NextStepModal: React.FC<Props> = ({ params, nextStep, status }) => {
  if (status === 'loading') {
    return <Loader active />;
  }

  if (status === 'error') {
    return <div className={emptyModalStyle}>Something wrong</div>;
  }

  if (!nextStep) {
    return <div className={emptyModalStyle}>Next step not found</div>;
  }

  const { created_at, next_step } = nextStep;

  return (
    <div className={box_email}>
      <div className={clx(box_email_line, margin_bottom)}>
        <div className={box_email_icon}>
          <img src={NextStepIcon} alt="icon" />
        </div>
        <div className={align_left}>
          <div className={date}>
            {moment(created_at).format('dddd, DD MMM YYYY [at] HH:mm:ss')}
          </div>
        </div>
      </div>
      <div className={clx(box_email_line, margin_bottom)}>
        <div className={box_email_icon}>Next step:</div>
        <div className={text}>
          {next_step?.split(/\r?\n/).map((line) => (
            <span>{line}</span>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (
  state: IReduxState,
  { params }: ModalRouteProps<{ nextStepId: string }>
) => {
  const [entityId, createdAt] = params.nextStepId.split('_');

  return {
    nextStep: getCustomerNextSteps(state, entityId).find(
      (record: NextStep) => record.created_at === createdAt
    ),
    status: getEngagementStatus(state),
  };
};

export default connect(mapStateToProps)(NextStepModal);
