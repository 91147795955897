import styled from '@emotion/styled';

interface WidgetTitleRowProps {
  readonly isDashboardModal: boolean;
  readonly isDashboardWidget: boolean;
}

export const WidgetTitleRow = styled.div(
  ({
    isDashboardModal = false,
    isDashboardWidget = false,
  }: WidgetTitleRowProps) => ({
    display: 'flex',
    alignItems: 'center',
    h4: {
      fontSize: '18px',
      fontWeight: 400,
      margin: '0px',
      fontFamily: 'var(--bu-font-medium)',
      color:
        isDashboardModal || !isDashboardWidget
          ? 'inherit'
          : 'var(--bu-primary-700)',
      cursor: isDashboardModal ? 'inherit' : 'pointer',
    },
    h5: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '12px',
      margin: '0px',
      textTransform: 'uppercase',
      color: 'var(--bu-gray-700)',
      fontWeight: 400,
      gap: '4px',

      i: {
        fontSize: '14px',
      },
    },
    i: {
      fontSize: '20px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    padding:
      isDashboardModal || !isDashboardWidget
        ? '16px 16px 12px 16px'
        : '16px 16px 10px 16px',
    justifyContent: 'space-between',
    maxHeight: '70px',
  })
);

export const ActionsWrapper = styled.div({
  display: 'flex',
});

export const TitleWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  section: {
    display: 'flex',
    alignItems: 'center',
  },
});

export const MetricsToggleLabel = styled.span({
  color: 'var(--bu-gray-700)',
});
