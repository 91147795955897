import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { MetricInfoContainer } from 'components/dashboard/Metrics/metrics.styles';
import {
  ActionButtonsContainer,
  CollapseAnimationContainer,
  DragItem,
  MetricTitle,
  PropertiesTitle,
  SavedMetricBlock,
  SavedMetricContent,
  SavedMetricInfo,
  SavedMetricProperties,
} from './styles';
import { MetricInfo } from 'components/dashboard/Metrics/common/MetricInfo/MetricInfo';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import { MetricProperty } from './MetricProperties/MetricProperty';
import {
  BIMetricCreated,
  MetricDisplayInfo,
  MetricsDisplay,
} from 'components/dashboard/Metrics/metrics.types';
import classNames from 'classnames';
import { Identifier, XYCoord } from 'dnd-core';

interface DragItem {
  index: number;
  id: string;
}

interface Props {
  index: number;
  metric: BIMetricCreated;
  metricsDisplayConfig?: MetricsDisplay;
  subValuesData: any;
  isLoadingSubValues: boolean;
  handleEditMetric: (id: string, name: string) => void;
  onRemoveMetric: (metric: BIMetricCreated) => void;
  onChangeAdvanceConfig: (metricId: string, config: MetricDisplayInfo) => void;
  onReorder: (dragIndex: number, hoverIndex: number) => void;
  onDrop: () => void;
}

export const MetricItem: React.FC<Props> = ({
  index,
  metric,
  metricsDisplayConfig,
  subValuesData,
  isLoadingSubValues,
  handleEditMetric,
  onRemoveMetric,
  onChangeAdvanceConfig,
  onReorder,
  onDrop,
}) => {
  const previewRef = useRef<HTMLDivElement>(null);
  const handlerRef = useRef<HTMLDivElement>(null);

  const [panelExpanded, setPanelExpanded] = useState(false);

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'METRIC',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!handlerRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = handlerRef.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (
        (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
        (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
      ) {
        return;
      }

      onReorder(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop: () => {
      return onDrop();
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'METRIC',
    item: () => {
      return { id: metric._id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (_, monitor) => {
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        // Item dropped outside the container, we need to call onDrop to
        // update the list according to the new order
        return onDrop();
      }
    },
  });

  const opacity = isDragging ? 0 : 1;
  drag(handlerRef);
  drop(preview(previewRef));

  return (
    <SavedMetricBlock
      ref={previewRef}
      style={{ opacity, zIndex: 1000 - index }}
      data-testing={`metric-wrapper-${index}`}
    >
      <DragItem ref={handlerRef} data-handler-id={handlerId}>
        <BuIcon name={BoostUpIcons.Drag} />
      </DragItem>
      <SavedMetricContent>
        <SavedMetricInfo>
          <MetricInfoContainer data-testing={`info-metric-${index}`}>
            <MetricTitle>
              {metric?.name ?? '-metric_name_placeholder-'}
            </MetricTitle>
            <MetricInfo hasPopups metric={metric} />
          </MetricInfoContainer>

          <ActionButtonsContainer>
            <BuButton
              borderless
              icon
              size={BuControlSize.SMALL}
              onClick={() => {
                handleEditMetric(metric._id as string, metric.name);
              }}
              testingLabel={`edit-metric-${index}`}
            >
              <BuIcon name={BoostUpIcons.Pencil} />
            </BuButton>
            <BuButton
              borderless
              icon
              size={BuControlSize.SMALL}
              onClick={() => {
                onRemoveMetric(metric);
              }}
              testingLabel={`trash-metric-${index}`}
            >
              <BuIcon name={BoostUpIcons.Trash} />
            </BuButton>
          </ActionButtonsContainer>
        </SavedMetricInfo>
        <SavedMetricProperties>
          <PropertiesTitle onClick={() => setPanelExpanded((prev) => !prev)}>
            Properties{' '}
            <BuIcon
              name={
                panelExpanded
                  ? BoostUpIcons.ChevronDown
                  : BoostUpIcons.ChevronRight
              }
            />
          </PropertiesTitle>
          <CollapseAnimationContainer
            className={classNames({
              closed: !panelExpanded,
            })}
          >
            <MetricProperty
              metric={metric}
              metricsDisplayConfig={metricsDisplayConfig}
              onChange={onChangeAdvanceConfig}
              subValuesData={subValuesData}
              isLoadingSubValueList={isLoadingSubValues}
            />
          </CollapseAnimationContainer>
        </SavedMetricProperties>
      </SavedMetricContent>
    </SavedMetricBlock>
  );
};
