import { css } from 'emotion';

export const downloadButton = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bu-white);
  border-radius: 2px;
  border-style: none;
  cursor: pointer;
  margin: 0 10px;
  outline-style: none;
  height: 28px;
  width: 28px;

  &:hover {
    background-color: #edf0f2;

    & > img {
      filter: invert(33%) sepia(65%) saturate(7495%) hue-rotate(212deg)
        brightness(97%) contrast(95%);
    }
  }
`;
