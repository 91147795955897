import { QueryMethod } from 'utils/network/types';

export const getBody = <TReqParams>(
  queryMethod: QueryMethod,
  queryParams?: TReqParams
): string | undefined => {
  if (queryMethod === 'get' || typeof queryParams === 'undefined') {
    return;
  }

  if (typeof queryParams === 'string') {
    return queryParams;
  }

  return JSON.stringify(queryParams);
};
