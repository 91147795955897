import clx from 'classnames';
import React, {
  FC,
  useState,
  ChangeEvent,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { Loader } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import BuInput from 'components/UI/BuInput';
import BuInputRange from 'components/UI/BuInputRange';
import { RadioControllers } from 'components/settings/FieldConfiguration/CustomFieldsConfiguration/RadioControllers';
import * as styles from 'components/settings/FieldConfiguration/CustomFieldsConfiguration/styles';
import {
  RangeType,
  Props,
  OptionsResponse,
} from 'components/settings/FieldConfiguration/CustomFieldsConfiguration/types';
import { fetchApi, QueryStatus } from 'utils/network';

const initialState = [
  { field: 1, value: 0 },
  { field: 2, value: 0 },
];

export const MoneyConfigCustom: FC<Props> = ({
  fieldInputs,
  setFieldInputs,
  fieldConfigurationTitle,
  stateFilter,
}) => {
  const [isUseCustom, setUseCustom] = useState<boolean>(true);
  const [isLoading, setLoading] = useState<QueryStatus>('notAsked');
  const [sliderRange, setSliderRange] = useState({ min: 0, max: 10000 });
  const [range, setRange] = useState({ min: '<', max: '>' });
  const [values, setValues] = useState(initialState);

  const update = (isAutoGenerate: boolean, ranges?: number[]) => {
    setFieldInputs({
      ...fieldInputs,
      custom_filter_settings: {
        is_automatically_generated: isAutoGenerate,
        values: [],
        ...(ranges && { ranges }),
      },
    });
  };

  const requestOptions = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/settings/fields/${fieldConfigurationTitle}/metadata/${fieldInputs.crm_field}`;
    let abortController: AbortController | null = new AbortController();

    fetchApi<string, OptionsResponse>({
      queryMethod: 'get',
      setData: (response: OptionsResponse) => {
        const options = response.data;

        setSliderRange({
          min: options?.min ?? 0,
          max: options?.max ?? 10000,
        });
      },
      setStatus: (status) => setLoading(status),
      signal: abortController.signal,
      url: url,
    });
  };

  useEffect(() => {
    requestOptions();
  }, [fieldInputs.crm_field]);

  useEffect(() => {
    const { type, custom_filter_settings } = stateFilter;

    if (
      fieldInputs.type === type &&
      custom_filter_settings.ranges?.length &&
      custom_filter_settings.is_automatically_generated === false
    ) {
      const { is_automatically_generated, ranges } = custom_filter_settings;

      setValues(ranges.map((el, idx) => ({ field: idx + 1, value: el })));

      setUseCustom(is_automatically_generated);
      return update(is_automatically_generated, ranges);
    }

    return update(isUseCustom);
  }, [stateFilter]);

  const handleUseCustom = () => {
    setUseCustom(!isUseCustom);
    update(!isUseCustom);
  };

  const handleAddValue = () => {
    const newState = [...values, { field: values.length - 1, value: 0 }];
    setValues(newState);
  };

  const removeElement = (serialNumber: number) => {
    const newState = values.filter((el, idx) => idx !== serialNumber);
    const rangesValues = newState.map((el) => el.value);

    update(isUseCustom, rangesValues);
    setValues(newState);
  };

  const changeRangeValue = (
    e: ChangeEvent<HTMLInputElement>,
    serialNumber: number
  ) => {
    const newState = values.map((el, idx) =>
      idx === serialNumber - 1
        ? { ...el, value: e.currentTarget.valueAsNumber }
        : el
    );
    const ranges = newState.map((i) => i.value);

    setValues(newState);
    update(isUseCustom, ranges);
  };

  const handleChangeRange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      // const priceGap = 1000;
      const priceGap = sliderRange.max / 100;

      if (sliderRange.max - sliderRange.min < priceGap) {
        if (e.target.name === RangeType.min) {
          return setSliderRange({
            ...sliderRange,
            min: sliderRange.max - priceGap,
          });
        }
        if (e.target.name === RangeType.max) {
          return setSliderRange({
            ...sliderRange,
            max: sliderRange.min + priceGap,
          });
        }
        return;
      }

      setSliderRange({
        ...sliderRange,
        [e.target.name]: e.target.valueAsNumber,
      });
    },
    [sliderRange]
  );

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    name: 'min' | 'max'
  ) => {
    setSliderRange({
      ...sliderRange,
      [name]: e.currentTarget.valueAsNumber,
    });
  };

  const sliderProps = useMemo(
    () => ({
      min: 0,
      max: 10000,
      minValue: sliderRange.min,
      maxValue: sliderRange.max,
      onChange: handleChangeRange,
    }),
    [sliderRange]
  );

  return (
    <div className={styles.customBox}>
      <RadioControllers
        labelGenerate="Dynamic Range"
        isChecked={isUseCustom}
        onChange={handleUseCustom}
      />

      {isUseCustom && (
        <div className={styles.generateAutomaticallyLabel}>
          The amount filter will appear as a slider with minimum and maximum
          values defined dynamically based on the data.
        </div>
      )}

      <p className={styles.customList_title}>
        {isUseCustom ? 'Representation of filter in UI' : 'Enter Values:'}
      </p>

      {isUseCustom ? (
        <div className={styles.custom_text_box}>
          {isLoading === 'loading' ? (
            <div className={styles.loader_box}>
              <Loader active inline />
            </div>
          ) : (
            <SliderLayout
              sliderProps={sliderProps}
              handleChange={handleChange}
              sliderRange={sliderRange}
            />
          )}
        </div>
      ) : (
        <>
          <div className={styles.row_price}>
            <div className={styles.customList_item_count}>1.</div>
            <div className={styles.dropdownOptionRange}>&lt;</div>
            <div className={styles.inputBox}>
              <BuInput
                onChange={(e) => changeRangeValue(e, 1)}
                type="number"
                value={values[0].value}
              />
            </div>
          </div>

          {values.map(
            (el, idx) =>
              idx !== 0 &&
              idx !== values.length && (
                <div className={styles.row_price_two} key={idx + 1}>
                  <div className={styles.customList_item_count}>{idx + 1}.</div>
                  <BuInput
                    type="number"
                    value={values[idx - 1].value}
                    disabled
                  />
                  <div className={styles.row_price_separator}>&mdash;</div>
                  <BuInput
                    onChange={(e) => changeRangeValue(e, idx + 1)}
                    type="number"
                    value={el.value}
                  />
                  {idx !== 1 && (
                    <BuButton
                      icon
                      borderless
                      onClick={() => removeElement(idx)}
                      size={BuControlSize.SMALL}
                    >
                      <BuIcon
                        name={BoostUpIcons.Trash}
                        color="var(--bu-text-accent)"
                      />
                    </BuButton>
                  )}
                </div>
              )
          )}

          <div className={styles.box_AddBtn}>
            <BuButton
              onClick={handleAddValue}
              className={styles.customList_addButton}
            >
              + Add Values
            </BuButton>
          </div>

          <div className={styles.row_price}>
            <div className={styles.customList_item_count}>
              {values.length + 1}.
            </div>
            <div className={styles.dropdownOptionRange}>&ge;</div>
            <div className={styles.inputBox}>
              <BuInput
                type="number"
                value={values[values.length - 1].value}
                disabled
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

type PropsSliderLayout = {
  sliderProps: {
    min: number;
    max: number;
    minValue: number;
    maxValue: number;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  };
  handleChange: (
    e: ChangeEvent<HTMLInputElement>,
    name: RangeType.min | RangeType.max
  ) => void;
  sliderRange: {
    min: number;
    max: number;
  };
  error?: RangeType.min | RangeType.max | string;
};

export const SliderLayout: FC<PropsSliderLayout> = ({
  sliderProps,
  handleChange,
  sliderRange,
  error,
}) => (
  <>
    <div className={styles.range_box}>
      <BuInputRange {...sliderProps} />
    </div>
    <div className={styles.price_fields}>
      <div className={styles.price_field}>
        <BuInput
          className={clx(styles.price_input, {
            error: error === RangeType.min && sliderRange.min > sliderRange.max,
          })}
          onChange={(e) => handleChange(e, RangeType.min)}
          type="number"
          value={sliderRange.min}
          min={0}
        />
        <span>$-Min</span>
      </div>
      <div className={styles.separator}>&mdash;</div>
      <div className={styles.price_field}>
        <BuInput
          className={clx(styles.price_input, {
            error: error === RangeType.max && sliderRange.max < sliderRange.min,
          })}
          onChange={(e) => handleChange(e, RangeType.max)}
          type="number"
          value={sliderRange.max}
          min={0}
        />
        <span>$-Max</span>
      </div>
    </div>
  </>
);
