import {
  connect,
  MapDispatchToPropsParam,
  MapStateToPropsParam,
} from 'react-redux';

import { actions } from 'actions';
import { getPipelineStages } from 'actions/pipelineStagesAction';
import ForecastPipelineStage from 'components/dashboard/ForecastPipelineStage/ForecastPipelineStage';
import {
  ForecastCategory,
  IDispatchProps,
  IOwnProps,
  IStateProps,
} from 'components/dashboard/ForecastPipelineStage/types';
import { StageConfig } from 'components/settings/StageConfiguration/types';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

const mapStateToProps: MapStateToPropsParam<
  IStateProps,
  IOwnProps,
  IReduxState
> = (state) => ({
  stagesConfig: selectors.getSettingsStagesConfiguration(
    state
  ) as StageConfig[],
  pipelineStages: selectors.pipelineStages(state),
  pipelineStagesStatus: selectors.pipelineStagesStatus(state),
  forecastConfiguration: selectors.getForecastConfiguration(
    state
  ) as ForecastCategory[],
  savedPrevValuesDate: selectors.getForecastAllChangesDate('sankey')(state),
  companyCurrency: selectors.getUserLocalCurrency(state),
});

const mapDispatchToProps: MapDispatchToPropsParam<IDispatchProps, IOwnProps> = {
  persistModalParams: actions.ui.modal.persist,
  changeWidgetSinceDate:
    actions.ui.forecastWidgetAllChangedDate.changeDateSince,
  getPipelineStages: getPipelineStages,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForecastPipelineStage);
