import { getLocation } from 'connected-react-router';

import { IReduxState } from 'reducers/types';

export const isMetricsExpanded = (state: IReduxState): boolean => {
  const { pathname } = getLocation(state as any);

  const appState = state.appState[pathname.split('/~/')[0]];
  return appState ? appState.metrics.expanded : false;
};

export const getMetricsData = (state: IReduxState) =>
  state.forecast.totals.metricsData;
