import { css } from 'emotion';
import React, { Fragment } from 'react';
import { Dropdown } from 'semantic-ui-react';

import PlayImage from 'assets/fonts/boostup-icons/play.svg';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import {
  IColumn,
  IDataCellProps,
  IRow,
} from 'components/UI/common/TypedTable/TypedTable';
import NotAvailableCell from 'components/UI/common/TypedTable/renderers/NotAvailableCell';
import { history } from 'store/configureStore';

export enum ButtonTypes {
  AUDIO_CALL = 'audioCall',
  BUTTON = 'button',
  DROPDOWN = 'dropdown',
}

type AudioCallButtonParams = {
  className?: string;
  clickHandler: (column: IColumn, row: IRow) => void;
};

type AudioCallButtonConfig = {
  params: AudioCallButtonParams;
  type: ButtonTypes.AUDIO_CALL;
};

type DropdownButtonParams = {
  className: string;
  onChange: (
    value:
      | string
      | number
      | boolean
      | (string | number | boolean)[]
      | undefined,
    column: IColumn,
    row: IRow
  ) => void;
  options: {
    key: number;
    text: string;
    value: number | string;
  }[];
  title: string;
};

type DropdownButtonConfig = {
  params: DropdownButtonParams;
  type: ButtonTypes.DROPDOWN;
};

export type SimpleButtonParams = {
  className?: string;
  clickHandler: (column: IColumn, row: IRow) => void;
  title: string;
};

type SimpleButtonConfig = {
  params: SimpleButtonParams;
  type: ButtonTypes.BUTTON;
};

type ButtonConfig =
  | AudioCallButtonConfig
  | DropdownButtonConfig
  | SimpleButtonConfig;

export type ButtonsConfig = ButtonConfig[];

const audioCallButtonRenderer = (
  b: ButtonConfig,
  { column, row }: IDataCellProps
) => {
  if (b.type === ButtonTypes.AUDIO_CALL) {
    const { className } = b.params as AudioCallButtonParams;
    const { event_call, id } = row;

    return event_call ? (
      <button
        className={className}
        key={`audioCall-${event_call}`}
        onClick={() => history.push(`transcript/${event_call}`)}
      >
        <img src={PlayImage} alt="play" />
      </button>
    ) : (
      <NotAvailableCell />
    );
  }
};

const simpleButtonRenderer = (
  b: ButtonConfig,
  { column, row }: IDataCellProps
) => {
  if (b.type === ButtonTypes.BUTTON) {
    const { className, clickHandler, title } = b.params as SimpleButtonParams;

    return (
      <BuButton
        secondary
        size={BuControlSize.SMALL}
        className={className}
        key={`${title}-${row.id}`}
        onClick={() => clickHandler(column, row)}
      >
        {title}
      </BuButton>
    );
  }
};

const dropDownButtonRenderer = (
  b: ButtonConfig,
  { column, row }: IDataCellProps
) => {
  if (b.type === ButtonTypes.DROPDOWN) {
    const { className, onChange, options, title } =
      b.params as DropdownButtonParams;

    return (
      <Dropdown
        className={css`
          padding: 0 !important;
        `}
        closeOnChange
        data-cypress="action-cell-dropdown"
        direction="left"
        icon={null}
        key={`${title}-${row.id}`}
        onChange={(e, { value }) => onChange(value, column, row)}
        options={options}
        selectOnBlur={false}
        trigger={<button className={className}>{title}</button>}
      />
    );
  }
};

const buttonSelect = {
  [ButtonTypes.AUDIO_CALL]: audioCallButtonRenderer,
  [ButtonTypes.BUTTON]: simpleButtonRenderer,
  [ButtonTypes.DROPDOWN]: dropDownButtonRenderer,
} as const;

const ActionCell: React.FC<IDataCellProps> = ({
  column,
  row,
  rows,
}: IDataCellProps) => {
  if (column.config && column.config.buttons instanceof Array) {
    const buttonsConfig: ButtonsConfig = column.config.buttons;

    return (
      <Fragment>
        {buttonsConfig.map((b) =>
          buttonSelect[b.type](b, { column, row, rows })
        )}
      </Fragment>
    );
  }

  return <Fragment />;
};

export default ActionCell;
