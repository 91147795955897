import { IRow } from 'components/UI/common/TypedTable/TypedTable';

export const findCurrentUserRow = (
  tree: IRow[],
  primaryField: string,
  identifier: string
): IRow | null =>
  tree.reduce((found: IRow | null, item) => {
    if (found) {
      return found;
    }

    if (item[primaryField] === identifier) {
      return item;
    }

    if (Array.isArray(item.children)) {
      return findCurrentUserRow(item.children, primaryField, identifier);
    }

    return null;
  }, null);
