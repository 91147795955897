import { FC } from 'react';

import {
  AnalysisType,
  OPPORTUNITY,
} from 'components/dashboard/Metrics/constants';
import { MetricAnalysisTypes } from 'components/dashboard/Metrics/Create/AnalysisTypes/MetricAnalysisTypes/MetricAnalysisTypes';
import {
  SIMPLE_HISTORICAL_METRIC_NEWBORN_MOCK,
  SIMPLE_METRIC_NEWBORN_MOCK,
} from 'components/dashboard/Metrics/Create/constants';
import {
  DefinitionsTitle,
  MetricOptionsColumn,
  MetricOptionSection,
} from 'components/dashboard/Metrics/Create/MetricCreate/MetricOptions/styles';
import { MetricDefinitionInputs } from 'components/dashboard/Metrics/Create/MetricDefinitionInputs';
import {
  BIMetricSimple,
  BIMetricUnion,
} from 'components/dashboard/Metrics/metrics.types';
import MetricDescriptionInput from '../../MetricDefinitionInputs/MetricDescriptionInput';

interface Props {
  isEditMode: boolean;
  metric: BIMetricUnion;
  updateMetricPerAnalysisType: (newMetric: BIMetricUnion) => void;
  onChangeMetricDefinitionInputs: (
    complete: boolean,
    metricInputs: Partial<BIMetricUnion>
  ) => void;
}

export const MetricOptions: FC<Props> = ({
  isEditMode,
  metric,
  updateMetricPerAnalysisType,
  onChangeMetricDefinitionInputs,
}) => {
  const handleChangeAnalysisType = (type: AnalysisType): void => {
    const newMetric = {
      ...(type === AnalysisType.HISTORICAL
        ? { ...SIMPLE_HISTORICAL_METRIC_NEWBORN_MOCK }
        : { ...SIMPLE_METRIC_NEWBORN_MOCK }),
      // when changing analysis type name is preserved
      name: metric.name,
      // also we preserve aggregation function
      aggregation_function: (metric as BIMetricSimple).aggregation_function,
      // and if object is opportunity we preserve column as well as it is both Live and Historical
      ...((metric as BIMetricSimple)?.object === OPPORTUNITY && {
        column: (metric as BIMetricSimple).column,
      }),
    };

    updateMetricPerAnalysisType(newMetric);
  };

  const handleDescriptionChange = (value: string | undefined): void => {
    onChangeMetricDefinitionInputs(true, { name_identifier: value });
  };

  return (
    <MetricOptionsColumn>
      <MetricOptionSection>
        <MetricAnalysisTypes
          disabled={isEditMode}
          selectedAnalysisType={metric.analysis_type}
          onChangeAnalysisType={handleChangeAnalysisType}
        />

        <DefinitionsTitle>Metric Definition</DefinitionsTitle>

        <MetricDescriptionInput
          onMetricDescriptionChange={handleDescriptionChange}
          metricDescription={metric.name_identifier}
        />
        <MetricDefinitionInputs
          key={metric.analysis_type}
          metric={metric}
          onChangeMetricDefinitionInputs={onChangeMetricDefinitionInputs}
        />
      </MetricOptionSection>
    </MetricOptionsColumn>
  );
};
