import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import clx from 'classnames';
import React, { useState } from 'react';

import { USER_ROLES, Roles } from 'common/constants';
import BuDropdown, {
  BuDropdownItem,
  BuDropdownItemContainer,
} from 'components/UI/BuDropdown';
import BuInput from 'components/UI/BuInput';
import {
  initialState,
  statusOptions,
} from 'components/settings/ManageUsers/UsersAddManually/helpers';
import * as styles from 'components/settings/ManageUsers/UsersAddManually/styles';

type Props = {
  user: typeof initialState;
  domain: string;
  onSelect: (id: number, value: string, field: string) => void;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
    field: string
  ) => void;
  isValidEmailAddress: (email: string, domain: string) => boolean;
  managerOptions: {
    label: string;
    value: string;
  }[];
};

const roleOptions = (Object.keys(USER_ROLES) as Roles).map((roleCode) => ({
  label: USER_ROLES[roleCode],
  value: USER_ROLES[roleCode],
}));

export const UsersAddManuallyTableItem: React.FC<Props> = ({
  user,
  domain,
  onSelect,
  onChange,
  isValidEmailAddress,
  managerOptions,
}) => {
  const [isChangedEmail, setIsChangedEmail] = useState(false);

  return (
    <div key={user.id} className={styles.table_body_row}>
      <div className={styles.table_body_col}>
        <div className={styles.wrapper_field}>
          <BuInput
            type="text"
            value={user.name}
            onChange={(e) => onChange(e, user.id, 'name')}
            placeholder="Full Name"
            className={styles.input}
            style={{ width: '100%' }}
          />
        </div>
      </div>
      <div className={styles.table_body_col}>
        <div className={styles.wrapper_field}>
          <BuInput
            type="text"
            value={user.email}
            onChange={(e) => {
              setIsChangedEmail(true);
              onChange(e, user.id, 'email');
            }}
            placeholder="Enter Email"
            className={clx(styles.input, {
              isValid:
                isChangedEmail && !isValidEmailAddress(user.email, domain),
            })}
            style={{ width: '100%' }}
          />
          {isChangedEmail && !isValidEmailAddress(user.email, domain) && (
            <div className={styles.input_notification}>
              Please enter work/company email.
            </div>
          )}
        </div>
      </div>
      <div className={styles.table_body_col}>
        <div className={styles.wrapper_field}>
          <BuInput
            type="text"
            value={user.title}
            onChange={(e) => onChange(e, user.id, 'title')}
            placeholder="Type Title"
            className={styles.input}
            style={{ width: '100%' }}
          />
        </div>
      </div>
      <div className={styles.table_body_col}>
        <div className={styles.wrapper_field}>
          <BuDropdown
            label={
              roleOptions.find((el) => el.value === user.role)?.label ||
              'Select'
            }
            secondary
            fullWidth
          >
            {(hide) => (
              <BuDropdownItemContainer style={{ maxHeight: 300 }}>
                {roleOptions.map((el) => (
                  <BuDropdownItem
                    key={el.label}
                    value={el.value as string}
                    active={user.role === el.value}
                    onClick={() => {
                      hide();
                      onSelect(user.id, el.value, 'role');
                    }}
                  >
                    {el.label}
                  </BuDropdownItem>
                ))}
              </BuDropdownItemContainer>
            )}
          </BuDropdown>
        </div>
      </div>
      <div className={styles.table_body_col}>
        <div className={styles.wrapper_field}>
          <BuDropdown
            label={
              managerOptions.find((el) => el.value === user.manager)?.label ||
              'Select'
            }
            secondary
            fullWidth
          >
            {(hide) => (
              <BuDropdownItemContainer style={{ maxHeight: 300 }}>
                {managerOptions.map((el) => (
                  <BuDropdownItem
                    key={el.label}
                    value={el.value as string}
                    active={user.manager === el.value}
                    onClick={() => {
                      hide();
                      onSelect(user.id, el.value, 'manager');
                    }}
                  >
                    {el.label}
                  </BuDropdownItem>
                ))}
              </BuDropdownItemContainer>
            )}
          </BuDropdown>
        </div>
      </div>
      <div className={styles.table_body_col}>
        <div className={styles.wrapper_field}>
          <BuDropdown
            label={
              statusOptions.find((el) => el.value === user.status)?.label ||
              'Status'
            }
            secondary
            fullWidth
          >
            {(hide) => (
              <BuDropdownItemContainer style={{ maxHeight: 300 }}>
                {statusOptions.map((el) => (
                  <BuDropdownItem
                    key={el.label}
                    value={el.value as string}
                    active={user.status === el.value}
                    onClick={() => {
                      hide();
                      onSelect(user.id, el.value, 'status');
                    }}
                  >
                    {el.label}
                  </BuDropdownItem>
                ))}
              </BuDropdownItemContainer>
            )}
          </BuDropdown>
        </div>
      </div>
    </div>
  );
};
