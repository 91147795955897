/* eslint-disable no-param-reassign */
import produce from 'immer';
import moment from 'moment';
import * as R from 'ramda';
import { toast } from 'react-toastify';

import * as t from 'actions/actionTypes';
import { parseError } from 'common/helpers';

const initialState = {
  account: {},
  deals: {},
  calculatedData: {},
  status: 'notAsked',
  list: {
    data: [],
    total_count: 0,
  },
};

function updateItemInList(list, id, changes) {
  const foundIndex = list.findIndex((account) => account._id === id);

  if (foundIndex >= 0) {
    list.splice(foundIndex, 1, { ...list[foundIndex], ...changes });
  }
}

function updateAccountsRowStatus(draft, id, changes, status = null) {
  if (draft && draft.list) {
    const { list } = draft;

    if (!list.rowStatus) {
      list.rowStatus = {};
    }
    if (!list.rowStatus[id]) {
      list.rowStatus[id] = {};
    }
    Object.keys(changes).forEach(
      (field) =>
        (list.rowStatus[id][field] = { status, date: Date.now(), value: null })
    );
  }
}

export default function accountReducer(state = initialState, action) {
  const { type, payload } = action;
  const reducer = {
    [t.ACCOUNT + t.GET + t.LOADING]: (draft) => {
      draft.status = 'loading';
    },
    [t.ACCOUNT + t.GET + t.SUCCESS]: (draft) => {
      draft.account = payload.data;
      draft.calculatedData = calculateDataForDealDetailed(payload.data);
      draft.status = 'success';
    },
    [t.ACCOUNT + t.UPDATE + t.SUCCESS]: (draft) => {
      toast.success('Update successful.', {
        position: 'bottom-left',
      });

      if (
        draft &&
        draft.list &&
        draft.list.data &&
        draft.list.data instanceof Array
      ) {
        updateItemInList(draft.list.data, payload.id, payload.data);
      }
      updateAccountsRowStatus(draft, payload.id, payload.changes, 'success');
    },
    [t.ACCOUNT + t.UPDATE + t.LOADING]: (draft) => {
      toast.warn('Update in progress.', {
        position: 'bottom-left',
      });
      updateAccountsRowStatus(draft, payload.id, payload.changes, 'loading');
    },
    [t.ACCOUNT + t.UPDATE + t.ERROR]: (draft) => {
      const error = parseError(action.error);

      toast.error(error || 'Request failed.', {
        position: 'bottom-left',
      });
      updateAccountsRowStatus(draft, payload.id, payload.changes, 'error');
    },

    /* accounts */
    [t.ACCOUNTS + t.GET + t.LOADING]: (draft) => {
      draft.list.status = 'loading';
    },
    [t.ACCOUNTS + t.GET + t.SUCCESS]: (draft) => {
      draft.list.data = payload.accounts;
      draft.list.total_count = payload.total_count;
      draft.list.query = action.data;
      draft.list.status = 'success';
      draft.list.rowStatus = {};
    },
    [t.ACCOUNTS + t.GET + t.ERROR]: (draft) => {
      draft.list.status = 'error';
    },
  };

  return Object.prototype.hasOwnProperty.call(reducer, type)
    ? produce(state, reducer[type])
    : state;
}

function calculateDataForDealDetailed(deal) {
  const { calls = [], events = [], contact_engagement = [] } = deal;

  const prospectTouched = contact_engagement.filter((c) => c.is_customer);
  const dateComparator = (a, b) =>
    moment(b.start).format('X') - moment(a.start).format('X');

  const callsAndMeetings = R.compose(
    R.sort(dateComparator),
    R.concat(calls),
    R.filter((e) => !calls.some((c) => c.event_id === e.id))
  )(events);

  const prospectEngagement = prospectTouched.filter((c) => c.is_engaged > 0);
  return {
    no_show_count: R.compose(
      R.length,
      R.filter(R.propEq('no_show', true))
    )(calls),
    last_meeting: R.head(callsAndMeetings),
    calls_and_meetings: callsAndMeetings,
    prospects_touched: prospectTouched,
    prospects_engaged: prospectEngagement,
    prospect_titles_engaged: prospectEngagement
      .map((c) => c.title)
      .filter((title) => !title),
    last_active_prospect: prospectEngagement.sort(
      (a, b) => a.last_engaged.contacted_at < b.last_engaged.contacted_at
    )[0],
  };
}
