import styled from '@emotion/styled';
import { FlipModifier } from '@popperjs/core/lib/modifiers/flip';
import { OffsetModifier } from '@popperjs/core/lib/modifiers/offset';
import React, { useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import {
  Manager,
  Modifier,
  Popper,
  PopperProps,
  Reference,
} from 'react-popper';

import useStateDelayed from 'components/hooks/useStateDelayed';

const TooltipWrapper = styled.div`
  border: 1px solid var(--bu-gray-500);
  border-radius: var(--bu-control-border-radius);
  background-color: var(--bu-white);
  z-index: 1000;
  box-shadow: 0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%);
`;

const TooltipContent = styled.div`
  padding: 5px;
  overflow: hidden;
  border-radius: var(--bu-control-border-radius);
`;

const TooltipArrow = styled.div`
  *[data-placement^='top'] > & {
    bottom: -4px;
  }

  *[data-placement^='right'] > & {
    left: -4px;

    &::before {
      box-shadow: -1px 1px 0 0 var(--bu-gray-500);
    }
  }

  *[data-placement^='bottom'] > & {
    top: -4px;

    &::before {
      box-shadow: -1px -1px 0 0 var(--bu-gray-500);
    }
  }

  *[data-placement^='left'] > & {
    right: -4px;

    &::before {
      box-shadow: 1px -1px 0 0 var(--bu-gray-500);
    }
  }

  &::before {
    content: '';
    transform: rotate(45deg);
    box-shadow: 1px 1px 0 0 var(--bu-gray-500);
    top: 0;
    left: 0;
    background-color: var(--bu-white);
  }

  &,
  &::before {
    width: 10px;
    height: 10px;
    position: absolute;
    z-index: -1;
  }
`;

const modifiers: ReadonlyArray<Modifier<string>> = [
  {
    name: 'offset',
    enabled: true,
    options: {
      offset: [0, 8],
    },
  } as OffsetModifier,
  {
    name: 'flip',
  } as FlipModifier,
];

export interface IProps
  extends Pick<PopperProps<unknown>, 'placement'>,
    Common.DataTestingType {
  attachTo?: Element;
  mouseLeaveDelay?: number;
  mouseEnterDelay?: number;
  trigger(ref: React.Ref<any>): React.ReactNode;
  isClickable?: boolean;
}

const COMPONENT_TESTING_NAME = 'BuPopup';

const BuPopup: React.FC<IProps> = ({
  children,
  attachTo,
  placement = 'bottom',
  trigger,
  mouseLeaveDelay = 70,
  mouseEnterDelay = 50,
  isClickable = false,
  testingLabel = '',
}) => {
  const [isOpen, setOpen] = useStateDelayed(false);
  const referenceRef = useRef<HTMLElement | null>(null);
  const popperRef = useRef<HTMLDivElement>(null);
  const componentDataTestingKey = testingLabel
    ? `${testingLabel}-${COMPONENT_TESTING_NAME}`
    : COMPONENT_TESTING_NAME;

  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (
        referenceRef?.current?.contains(event.target as HTMLElement) ||
        popperRef?.current?.contains(event.target as HTMLElement)
      ) {
        setOpen(true, mouseEnterDelay);
      } else {
        setOpen(false, mouseLeaveDelay);
      }
    };

    document.addEventListener('mouseover', listener);

    return function () {
      document.removeEventListener('mouseover', listener);
    };
  }, [referenceRef, popperRef, setOpen]);

  const clickHandler = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();

      if (referenceRef?.current?.contains(event.target as HTMLElement)) {
        setOpen((prev) => !prev, 0);
      } else if (popperRef?.current?.contains(event.target as HTMLElement)) {
        setOpen(true, 0);
      } else {
        setOpen(false, 0);
      }
    },
    [referenceRef, popperRef, setOpen]
  );

  useEffect(() => {
    if (!isClickable) {
      return;
    }

    document.addEventListener('click', clickHandler);

    return function () {
      document.removeEventListener('click', clickHandler);
    };
  }, [isClickable, clickHandler]);

  const Popup = useCallback<React.FC>(
    ({ children }) => (
      <Popper placement={placement} modifiers={modifiers} innerRef={popperRef}>
        {({ placement, ref, style, arrowProps }) => (
          <TooltipWrapper
            ref={ref}
            data-placement={placement}
            style={style}
            data-testing={`${componentDataTestingKey}-Wrapper`}
          >
            <TooltipArrow ref={arrowProps.ref} style={arrowProps.style} />
            <TooltipContent data-testing={`${componentDataTestingKey}-Content`}>
              {children}
            </TooltipContent>
          </TooltipWrapper>
        )}
      </Popper>
    ),
    [popperRef, testingLabel]
  );

  return (
    <Manager>
      <Reference>
        {({ ref }) => {
          const innerRef: React.RefCallback<HTMLElement> = (node) => {
            (ref as React.RefCallback<HTMLElement>)(node);
            referenceRef.current = node;
          };
          return trigger(innerRef);
        }}
      </Reference>

      {isOpen &&
        (attachTo ? (
          ReactDOM.createPortal(<Popup>{children}</Popup>, attachTo)
        ) : (
          <Popup data-testing={componentDataTestingKey}>{children}</Popup>
        ))}
    </Manager>
  );
};

export default BuPopup;
