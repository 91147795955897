import { toast, ToastOptions } from 'react-toastify';
import { Action, combineReducers, Reducer } from 'redux';

import * as t from 'actions/actionTypes';
import { RowStatusType } from 'components/UI/common/TypedTable/TypedTable';
import { toastStyles } from 'components/style';

export type USER_STATUS = 'active' | 'disabled';

interface UserBase {
  id: string;
  email: string;
  name?: string;
}

interface Processing {
  calendar: boolean;
  calls: boolean;
  emails: boolean;
}

interface UserDialInfo {
  dial_phone_number?: string;
  dial_meeting_url?: string;
  dial_access_code?: string;
}

interface UserSettings {
  email_call_summary?: boolean;
  email_periodical_manager_report?: boolean; //(only for users who are managers): <bool>
  processing?: Processing; // <user processing settings object>,
  dial_info?: UserDialInfo; // <dial info settings object>
}

interface UserSyncData {
  calendar_watcher_refreshed_at?: string;
  calendar_watcher_last_processed_at?: string;
  calendar_watcher_last_errored_at?: string;
  calendar_watcher_refreshed_errored_at?: string;
  calendar_watcher_refreshed_failed?: boolean;
  calendar_watcher_last_processed_failed?: boolean;

  email_watcher_refreshed_at?: string;
  email_watcher_last_errored_at?: string;
  email_watcher_last_processed_at?: string;
  email_watcher_refreshed_errored_at?: string;

  email_watcher_refreshed_failed?: boolean;
  email_watcher_last_processed_failed?: boolean;

  token_expiry?: string;
  access_token?: {
    status: 'missing' | 'expired' | 'valid';
    expiry: null | string;
  };
}

export type TProfile =
  | {
      id: string;
      name: string;
    }
  | undefined;

export enum Scopes {
  ALL = 'all',
  TEAM = 'team',
  SINLGE_USER = 'single-user',
}

export enum AccessMode {
  VIEWER = 'read-only',
  EDITOR = 'read-write',
}

export type IImpersonation = {
  scope: Scopes;
  access_mode: AccessMode;
  user?: string;
};
export interface UserResponse extends UserBase, UserSyncData {
  manager?: UserBase;
  role: string;
  profile: TProfile;
  status: USER_STATUS;
  user_settings?: UserSettings;
  user_id: string;
  is_user_impersonation_allowed: boolean;
  impersonation_settings: IImpersonation[];
  email_domains?: string[];
  sfdc_user_id: SalesforceId[];
  sync_schedule_failed?: boolean;
  allow_salesforce_update?: boolean;
}

export type SalesforceId = {
  id: string;
  selected: boolean;
};

export type CompanyUsersPayload = {
  data: UserResponse[];
  total?: number;
  profile_names?: string[];
  disable_fields?: string[];
  email_domains?: string[];
};

export interface CompanyUserAction<T = string> extends Action {
  type: string;
  payload?: CompanyUsersPayload;
  error?: T;
}

export interface EmailCalendarSyncPopupVisibleAction extends Action {
  type: string;
  payload?: EmailCalendarSyncPopupState;
}

const companyUsersLoading: Reducer<boolean, CompanyUserAction> = (
  state = false,
  action
) => {
  switch (action.type) {
    case t.COMPANY_SETTINGS + t.USERS + t.GET + t.LOADING:
      return true;
    case t.COMPANY_SETTINGS + t.USERS + t.GET + t.SUCCESS:
    case t.COMPANY_SETTINGS + t.USERS + t.GET + t.ERROR:
      return false;
    default:
      return state;
  }
};

const companyUserLoading: Reducer<boolean, CompanyUserAction> = (
  state = false,
  action
) => {
  switch (action.type) {
    case t.COMPANY_SETTINGS + t.USER + t.GET + t.LOADING:
    case t.COMPANY_SETTINGS + t.USER + t.UPDATE + t.LOADING:
    case t.COMPANY_SETTINGS + t.USER + t.SYNC + t.LOADING:
      return true;
    case t.COMPANY_SETTINGS + t.USER + t.GET + t.SUCCESS:
    case t.COMPANY_SETTINGS + t.USER + t.UPDATE + t.SUCCESS:
    case t.COMPANY_SETTINGS + t.USER + t.SYNC + t.SUCCESS:
    case t.COMPANY_SETTINGS + t.USER + t.GET + t.ERROR:
    case t.COMPANY_SETTINGS + t.USER + t.UPDATE + t.ERROR:
    case t.COMPANY_SETTINGS + t.USER + t.SYNC + t.ERROR:
      return false;
    default:
      return state;
  }
};

export type EmailCalendarSyncPopupState = {
  isCalendarSync: boolean;
  isEmailSync: boolean;
  isVisible: boolean;
};

const initialEmailCalendarSyncPopupState: EmailCalendarSyncPopupState = {
  isCalendarSync: false,
  isEmailSync: false,
  isVisible: false,
};

const emailCalendarSyncPopup: Reducer<
  EmailCalendarSyncPopupState,
  EmailCalendarSyncPopupVisibleAction
> = (state = initialEmailCalendarSyncPopupState, action) => {
  switch (action.type) {
    case t.MODAL + t.USER + t.EMAIL + t.SYNC + t.OPEN:
      return {
        ...state,
        isCalendarSync: action.payload?.isCalendarSync || false,
        isEmailSync: action.payload?.isEmailSync || false,
        isVisible: true,
      };
    case t.MODAL + t.USER + t.EMAIL + t.SYNC + t.CLOSE:
      return { ...state, ...initialEmailCalendarSyncPopupState };
    default:
      return state;
  }
};

const unmappedUserLoading: Reducer<boolean, CompanyUserAction> = (
  state = false,
  action
) => {
  switch (action.type) {
    case t.COMPANY_SETTINGS + t.UNMAPPED_USERS + t.GET + t.LOADING:
    case t.COMPANY_SETTINGS + t.UNMAPPED_USERS + t.UPDATE + t.LOADING:
      return true;
    case t.COMPANY_SETTINGS + t.UNMAPPED_USERS + t.GET + t.SUCCESS:
    case t.COMPANY_SETTINGS + t.UNMAPPED_USERS + t.UPDATE + t.SUCCESS:
    case t.COMPANY_SETTINGS + t.UNMAPPED_USERS + t.GET + t.ERROR:
    case t.COMPANY_SETTINGS + t.UNMAPPED_USERS + t.UPDATE + t.ERROR:
      return false;
    default:
      return state;
  }
};

export type CompanyUsersState = {
  users: UserResponse[];
  total: number;
  profile_names: string[];
};

const initialCompanyUsersState: CompanyUsersState = {
  users: [],
  total: 0,
  profile_names: [],
};

const companyUsers: Reducer<CompanyUsersState, CompanyUserAction> = (
  state = initialCompanyUsersState,
  action
) => {
  switch (action.type) {
    case t.COMPANY_SETTINGS + t.USER + t.UPDATE + t.SUCCESS:
      return {
        ...state,
        users: state.users.map((user) => {
          return (
            (action.payload?.data || []).find(
              (updatedUser) => user.id === updatedUser.id
            ) || user
          );
        }),
      };
    case t.COMPANY_SETTINGS + t.USERS + t.GET + t.SUCCESS:
      return {
        ...state,
        users: action.payload?.data || [],
        total: action.payload?.total || 0,
      };
    case t.COMPANY_SETTINGS + t.USERS + t.GET + t.ERROR:
      return { ...state, ...initialCompanyUsersState };
    case t.COMPANY_SETTINGS + t.USERS + t.GET + t.LOADING:
    default:
      return state;
  }
};

const companyManagers: Reducer<CompanyUsersState, CompanyUserAction> = (
  state = initialCompanyUsersState,
  action
) => {
  switch (action.type) {
    case t.COMPANY_SETTINGS + t.MANAGERS + t.GET + t.SUCCESS:
      return {
        ...state,
        users: action.payload?.data || [],
        total: action.payload?.total || 0,
      };
    case t.COMPANY_SETTINGS + t.MANAGERS + t.GET + t.ERROR:
      return { ...state, ...initialCompanyUsersState };
    case t.COMPANY_SETTINGS + t.MANAGERS + t.GET + t.LOADING:
    default:
      return state;
  }
};

const companyManagerTeams: Reducer<CompanyUsersState, CompanyUserAction> = (
  state = initialCompanyUsersState,
  action
) => {
  switch (action.type) {
    case t.COMPANY_SETTINGS + t.MANAGERS_TEAM + t.GET + t.SUCCESS:
      return {
        ...state,
        users: action.payload?.data || [],
        total: action.payload?.total || 0,
      };
    case t.COMPANY_SETTINGS + t.MANAGERS_TEAM + t.GET + t.ERROR:
      return { ...state, ...initialCompanyUsersState };
    case t.COMPANY_SETTINGS + t.MANAGERS_TEAM + t.GET + t.LOADING:
    default:
      return state;
  }
};

const unmappedUsers: Reducer<CompanyUsersState, CompanyUserAction> = (
  state = initialCompanyUsersState,
  action
) => {
  switch (action.type) {
    case t.COMPANY_SETTINGS + t.UNMAPPED_USERS + t.UPDATE + t.SUCCESS:
      return {
        ...state,
        users: state.users.map((user) => {
          return (
            (action.payload?.data || []).find(
              (updatedUser) => user.email === updatedUser.email
            ) || user
          );
        }),
      };
    case t.COMPANY_SETTINGS + t.UNMAPPED_USERS + t.GET + t.SUCCESS:
      return {
        ...state,
        profile_names: action.payload?.profile_names || [],
        users: action.payload?.data || [],
        total: action.payload?.total || 0,
      };
    case t.COMPANY_SETTINGS + t.UNMAPPED_USERS + t.GET + t.ERROR:
      return { ...state, ...initialCompanyUsersState };
    case t.COMPANY_SETTINGS + t.UNMAPPED_USERS + t.GET + t.LOADING:
    default:
      return state;
  }
};

export type CompanyUserState = UserResponse;

const initialCompanyUserState: UserResponse = {
  id: '',
  email: '',
  role: '',
  profile: undefined,
  user_id: '',
  status: 'disabled',
  is_user_impersonation_allowed: false,
  impersonation_settings: [],
  user_settings: {},
  access_token: {
    status: 'valid',
    expiry: null,
  },
  sfdc_user_id: [],
};

const companyUser: Reducer<CompanyUserState, CompanyUserAction> = (
  state = initialCompanyUserState,
  action
) => {
  switch (action.type) {
    case t.COMPANY_SETTINGS + t.USER + t.UPDATE + t.SUCCESS:
      return {
        ...state,
        ...Object.values(action.payload?.data || [])[0],
      };
    case t.COMPANY_SETTINGS + t.USER + t.GET + t.SUCCESS:
      return {
        ...Object.values(action.payload?.data || [])[0],
        email_domains: action.payload?.email_domains || [],
      };
    case t.COMPANY_SETTINGS + t.USER + t.GET + t.ERROR:
      return { ...state, ...initialCompanyUserState };
    case t.COMPANY_SETTINGS + t.USER + t.GET + t.LOADING:
    default:
      return state;
  }
};

const companyUserDisabledFields: Reducer<string[], CompanyUserAction> = (
  state = [],
  action
) => {
  switch (action.type) {
    case t.COMPANY_SETTINGS + t.USER + t.GET + t.SUCCESS:
      return action.payload?.disable_fields ?? [];
    case t.COMPANY_SETTINGS + t.USERS + t.GET + t.SUCCESS:
      return action.payload?.disable_fields ?? [];
    case t.COMPANY_SETTINGS + t.USER + t.GET + t.LOADING:
    default:
      return state;
  }
};

export type CompanyUserStatusesState = RowStatusType;

const toastOptions: ToastOptions = {
  position: 'bottom-left',
};
const initialCompanyUserStatusesState = {};

const companyUsersStatuses: Reducer<
  CompanyUserStatusesState,
  CompanyUserAction
> = (state = initialCompanyUserStatusesState, action) => {
  switch (action.type) {
    case t.COMPANY_SETTINGS + t.USERS + t.GET + t.SUCCESS:
      return {};
    case t.COMPANY_SETTINGS + t.USER + t.UPDATE + t.SUCCESS:
      toast.success('Update successful.', {
        ...toastOptions,
      });

      const { sync_schedule_failed } = action.payload?.data[0] || {};
      if (sync_schedule_failed) {
        toast.error(
          `New email/calendar sync failed. We have sent an alert to our customer 
          success team and they could contact you shortly. Alternatively, 
          you can contact our support team to get it resolved.`,
          {
            ...toastOptions,
            delay: 2000,
            className: toastStyles(true),
          }
        );
      }

      return {
        ...state,
        ...action.payload?.data.reduce((acc, user) => {
          acc[user.email] = {
            status: 'success',
            date: Date.now(),
            value: null,
          };

          return acc;
        }, {} as RowStatusType),
      };
    case t.COMPANY_SETTINGS + t.USER + t.UPDATE + t.ERROR:
      const errorMessage = action.error ?? 'Unexpected failure.';
      toast.error(errorMessage, toastOptions);

      return {
        ...state,
        ...action.payload?.data.reduce((acc, user) => {
          acc[user.email] = {
            status: 'error',
            date: Date.now(),
            value: null,
          };

          return acc;
        }, {} as RowStatusType),
      };
    case t.COMPANY_SETTINGS + t.USER + t.UPDATE + t.LOADING:
      toast.warn('Update in progress.', toastOptions);

      return {
        ...state,
        ...action.payload?.data.reduce((acc, user) => {
          acc[user.email] = {
            status: 'loading',
            date: Date.now(),
            value: null,
          };

          return acc;
        }, {} as RowStatusType),
      };
    default:
      return state;
  }
};

const companyAddUsersStatuses: Reducer<boolean, CompanyUserAction> = (
  state = false,
  action
) => {
  switch (action.type) {
    case t.COMPANY_SETTINGS + t.UNMAPPED_USERS + t.CREATE + t.SUCCESS:
      toast.success('Creating users successful.', toastOptions);

      return true;
    case t.COMPANY_SETTINGS + t.UNMAPPED_USERS + t.CREATE + t.ERROR: {
      const errorMessage = (action.error as any)?.response.data.error.message;

      const error =
        (typeof errorMessage[0] === 'string'
          ? errorMessage
          : errorMessage.map((msg: Record<string, Record<string, string[]>>) =>
              Object.keys(msg)
                .map((key) =>
                  Object.keys(msg[key])
                    .map((v) => msg[key][v])
                    .join(',\n')
                )
                .join(',\n')
            )
        ).join(',\n') || 'Unexpected failure.';

      toast.error(error, toastOptions);

      return false;
    }
    case t.COMPANY_SETTINGS + t.UNMAPPED_USERS + t.CREATE + t.LOADING:
      toast.warn('Creating users in progress.', toastOptions);

      return false;
    case t.COMPANY_SETTINGS + t.UNMAPPED_USERS + t.GET + t.SUCCESS:
    case t.COMPANY_SETTINGS + t.USERS + t.GET + t.SUCCESS:
      return false;
    default:
      return state;
  }
};

const companySyncUsersStatuses: Reducer<boolean, CompanyUserAction> = (
  state = false,
  action
) => {
  switch (action.type) {
    case t.COMPANY_SETTINGS + t.USER + t.SYNC + t.SUCCESS:
      toast.success('Sync user successful.', toastOptions);

      return true;
    case t.COMPANY_SETTINGS + t.USER + t.SYNC + t.ERROR:
      toast.error('Unexpected failure.', toastOptions);

      return false;
    case t.COMPANY_SETTINGS + t.USER + t.SYNC + t.LOADING:
      toast.warn('Started user sync', toastOptions);

      return false;
    case t.COMPANY_SETTINGS + t.USERS + t.GET + t.SUCCESS:
      return false;
    default:
      return state;
  }
};

export const companySettingsReducer = combineReducers({
  companyUserLoading,
  companyUsersLoading,
  unmappedUserLoading,
  companyManagers,
  companyManagerTeams,
  companyUsers,
  companyUser,
  companyUserDisabledFields,
  companyUsersStatuses,
  companyAddUsersStatuses,
  unmappedUsers,
  companySyncUsersStatuses,
  emailCalendarSyncPopup,
});
