import styled from '@emotion/styled';
import React from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';

const Container = styled.div`
  display: flex;
  align-items: center;

  .bu-icon {
    margin-right: 3px;
  }
`;

const SeriesLabel: React.FC<{ color: string; icon: BoostUpIcons }> = ({
  color,
  icon,
  children,
}) => {
  return (
    <Container>
      <BuIcon name={icon} color={color} /> {children}
    </Container>
  );
};

export default SeriesLabel;
