import { IRow, RowStatusType } from '../common/TypedTable/TypedTable';
import { assocPath } from 'ramda';

export const getDealUpdatesInProgress = (dealsStatuses: RowStatusType) => {
  if (!dealsStatuses) {
    return [];
  }

  return Object.entries(dealsStatuses).flatMap(([dealId, statusesForDeal]) =>
    Object.entries(statusesForDeal)
      // statusesForDeal can be objects containing the field updates (cell updates)
      // or it can be a string or other properties regarding the row status
      // We only want to get the statuses that are objects containing the field updates
      .filter(
        ([_, statusForCell]) =>
          typeof statusForCell === 'object' &&
          statusForCell?.status === 'loading'
      )
      .map(([fieldName, statusForCell]) => ({
        id: dealId,
        fieldName,
        newValue: statusForCell.value,
      }))
  );
};

export const updateDealsWithUpdatesInProgress = (
  deals: IRow[],
  dealsStatuses: RowStatusType
): IRow[] => {
  const dealsUpdatesInProgress = getDealUpdatesInProgress(dealsStatuses);

  return deals.map((deal) => {
    const updatesInProgressForDeal = dealsUpdatesInProgress.filter(
      (field) => field.id === deal.id
    );
    if (updatesInProgressForDeal.length > 0) {
      // For each update in progress, update the deal with the new value
      return updatesInProgressForDeal.reduce((acc, field) => {
        const { fieldName, newValue } = field;
        return assocPath(fieldName.split('.'), newValue, acc);
      }, deal);
    } else {
      return deal;
    }
  });
};
