import React from 'react';
import { Dropdown } from 'semantic-ui-react';

import BuSelect from 'components/UI/BuSelect';
import {
  NULL_OPERATOR_VALUES,
  SET_OPERATION_DROPDOWN_OPTIONS,
} from 'components/dashboard/Metrics/Create/Conditions/Condition/constants';
import {
  FixedWidthContainer,
  FlexGrowContainer,
} from 'components/dashboard/Metrics/Create/Conditions/Condition/styles';
import { FlexRow } from 'components/dashboard/Metrics/metrics.styles';
import {
  BIMetricsFilter,
  BIPicklistOption,
} from 'components/dashboard/Metrics/metrics.types';

type Props = {
  filter: Partial<BIMetricsFilter>;
  picklistValues: BIPicklistOption[];
  onOperatorSelect: (operator: string) => void;
  onChange: (value: string[]) => void;
};

export const PickListTypeCondition: React.FC<Props> = ({
  filter,
  picklistValues,
  onOperatorSelect,
  onChange,
}) => {
  return (
    <FlexRow cssProps={{ gap: '12px' }}>
      <FixedWidthContainer width={100}>
        <BuSelect
          fullWidth
          isLargePlaceholder
          secondary
          placeholder=""
          defaults={[
            filter.operator
              ? filter.operator
              : SET_OPERATION_DROPDOWN_OPTIONS[0].value,
          ]}
          options={SET_OPERATION_DROPDOWN_OPTIONS}
          testingLabel="operator"
          onChange={(values: string[]) => {
            onOperatorSelect(values[0]);
          }}
        />
      </FixedWidthContainer>

      {!NULL_OPERATOR_VALUES.includes(filter.operator ?? '') && (
        <FlexGrowContainer>
          <Dropdown
            data-testing="operator-value"
            style={{
              borderColor: `var(--bu-gray-500)`,
              paddingTop: 0,
              paddingBottom: 0,
              minHeight: '32px',
            }}
            fluid
            multiple
            selection
            value={filter?.value || []}
            onChange={(_, { value }) => {
              onChange(value as string[]);
            }}
            options={(picklistValues ?? []).map((value: BIPicklistOption) => ({
              text: value.display_name,
              value: value.value,
            }))}
            defaults={filter?.value ? ([filter?.value] as string[]) : []}
            placeholder={'Select required values'}
          />
        </FlexGrowContainer>
      )}
    </FlexRow>
  );
};
