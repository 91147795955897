import React, { FC } from 'react';

import BuRadio from 'components/UI/BuRadio';
import * as styles from 'components/settings/FieldConfiguration/CustomFieldsConfiguration/styles';
import { PropsControllers } from 'components/settings/FieldConfiguration/CustomFieldsConfiguration/types';

export const RadioControllers: FC<PropsControllers> = ({
  isChecked,
  onChange,
  labelGenerate = 'Generate Automatically',
  labelCustom = 'Define Custom Values',
}) => (
  <fieldset className={styles.customBox_radio_btns}>
    <BuRadio checked={isChecked} label={labelGenerate} onChange={onChange} />
    <BuRadio checked={!isChecked} label={labelCustom} onChange={onChange} />
  </fieldset>
);
