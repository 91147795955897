import { css } from 'emotion';

import { fontDefaultDropdown, checkedIcon } from 'assets/css/common';

export const checkboxContainer = css``;

export const checkboxContainerChanged = css`
  background-color: #e9fff6;
`;

export const checkboxLabel = css`
  display: flex;
  align-items: center;
  cursor: pointer;
  ${fontDefaultDropdown};
  color: var(--bu-gray-900);
`;

export const checkboxInputReal = css`
  display: none;
`;

export const checkboxCustom = (checked: boolean, semichecked: any) => css`
  position: relative;
  cursor: pointer;
  min-width: 14px;
  height: 14px;
  border: 1px solid var(--bu-gray-500);
  display: inline-block;
  margin-right: 8px;
  background: var(--bu-white);
  border-radius: 3px;

  &:before {
    ${checked ? 'content: ""' : 'content: none'};
    display: block;
    position: absolute;
    left: -1px;
    right: -1px;
    top: -1px;
    bottom: -1px;
    background-color: var(--bu-primary-500);
    border-radius: 3px;
  }

  &:after {
    ${checked ? 'content: ""' : 'content: none'};
    ${checkedIcon}
    width: 12px;
    height: 12px;
    position: absolute;
  }

  ${semichecked && `background: #999999`};
`;
