import { USER_ROLES } from 'common/constants';
import {
  IBusinessType,
  IBusinessTypesSettings,
  ISplitTypes,
} from 'components/settings/DefineBusinessType/types';

export const getNewBusinessType = (
  businessTypesSettings: IBusinessTypesSettings
): IBusinessType => {
  return {
    name: '',
    values: [],
    amount_field:
      businessTypesSettings.availableValues?.amount_field?.[0] || '',
    close_date_field:
      businessTypesSettings.availableValues?.close_date_field?.[0] || '',
    forecast_category_field:
      businessTypesSettings.availableValues?.forecast_category_field?.[0] || '',
    owner_field: businessTypesSettings.availableValues?.owner_field?.[0] || '',
    user_roles: businessTypesSettings?.availableValues?.user_roles?.filter(
      (value) =>
        value === USER_ROLES.ACCOUNT_EXECUTIVE ||
        value === USER_ROLES.SALES_MANAGER ||
        value === USER_ROLES.CLEVEL
    ),
    order:
      (businessTypesSettings?.businessTypes &&
        Object.keys(businessTypesSettings.businessTypes).length + 1) ||
      1,
    enabled: true,
    split_type: null,
    enable_opportunity_splits: false,
    splits_default_enabled: true,
    enable_pipeline_summary: false,
    pipeline_summary_filters: {},
  };
};

export const getSplitTypeLabelById = (
  _splitTypes: ISplitTypes,
  splitType: string,
  amountField?: string,
  forceSplits: boolean = false
) => {
  const splitTypes = forceSplits
    ? Object.values(_splitTypes).reduce((elem, acc) => [...acc, ...elem], [])
    : _splitTypes[amountField || ''] || [];

  return (
    splitTypes.find((_splitType) => _splitType._id === splitType)?.label || null
  );
};
