import { IRow, SortOrder } from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { CustomCellConfig } from 'components/UI/common/TypedTable/renderers/CustomCell';
import { editRemoveButtons } from 'components/UI/common/TypedTable/renderers/custom';

export const getColumns = (
  onRemoveStage: (removedRow: IRow, rows: IRow[]) => void
) => {
  const columns = {
    order: {
      id: 'order',
      label: 'Order',
      field: 'order',
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.TEXT,
      editable: false,
      width: 30,
      config: {},
    },
    name: {
      id: 'name',
      label: 'Name',
      field: 'name',
      type: ColumnTypes.TEXT,
      sortable: false,
      sort_order: SortOrder.ASCENDING,
      config: {},
    },
    action: {
      id: 'actions',
      label: 'Actions',
      field: 'Actions',
      type: ColumnTypes.CUSTOM,
      sort_order: SortOrder.ASCENDING,
      width: 50,
      maxWidth: 60,
      config: {
        renderer: editRemoveButtons({
          canEdit: () => false,
          onRemove: onRemoveStage,
        }),
        className: 'actionColumn',
      } as CustomCellConfig,
    },
  };

  return columns;
};
