import { DropdownItemProps } from 'semantic-ui-react';

import { IColumn, SortOrder } from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { DropDownCellConfig } from 'components/UI/common/TypedTable/renderers/DropDownCell';
import { CategoryRow } from 'components/settings/MapForecastCategories/types';
import { AccessMode } from 'reducers/companyUsersReducer';

export const tableMinWidth = 440;

const defaults = {
  id: '',
  delta: false,
  editable: false,
  resize: false,
  sortable: false,
  sort_order: SortOrder.DESCENDING,
  type: ColumnTypes.TEXT,
  config: {},
};

const CATEGORY_STATUS_OPTIONS: DropdownItemProps[] = [
  { text: 'Read-Only', value: AccessMode.VIEWER, key: AccessMode.VIEWER },
  { text: 'Read-Write', value: AccessMode.EDITOR, key: AccessMode.EDITOR },
  { text: 'Remove Access', value: 'remove_access', key: 'remove_access' },
];

export const columns: IColumn[] = [
  {
    ...defaults,
    field: 'scope',
    label: 'Scope',
    sort_order: SortOrder.ASCENDING,
    type: ColumnTypes.TEXT,
    editable: false,
    width: 100,
  },
  {
    ...defaults,
    field: 'user',
    label: 'User',
    sort_order: SortOrder.ASCENDING,
    type: ColumnTypes.TEXT,
    editable: false,
    width: 200,
  },
  {
    ...defaults,
    field: 'access_mode',
    label: 'Permission',
    sort_order: SortOrder.ASCENDING,
    type: ColumnTypes.DROPDOWN,
    editable: true,
    width: 100,
    config: {
      popupPosition: 'top left',
      options() {
        return CATEGORY_STATUS_OPTIONS;
      },
    } as DropDownCellConfig<CategoryRow>,
  },
].map((col) => ({ ...col, id: col.label }));
