import classNames from 'classnames';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import { downloadButton } from 'components/UI/ExportCSVFromJSON/styles';
import { Props } from 'components/UI/ExportCSVFromJSON/types';
import Tooltip from 'components/UI/common/Tooltip';

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

const ExportCSVFromJSON: React.FC<Props> = ({ buttonClassName, onClick }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDownload = () => {
    if (!isLoading) {
      try {
        setIsLoading(true);
        const { header, cvsData, title } = onClick();
        if (header) {
          cvsData.unshift(header);
        }
        const csv = cvsData
          .map((row) => Object.values(row).join(','))
          .join('\r\n');
        const exportedFilename = `${title || 'export'}.csv`;
        // We need to prepend byte order mark (BOM) in order for excel to set correct encoding
        const blob = new Blob(['\uFEFF' + csv], {
          type: 'text/csv;charset=utf-8;',
        });

        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, exportedFilename);
        } else {
          const link = document.createElement('a');
          if (link.download !== undefined) {
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', exportedFilename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }

        setIsLoading(false);
      } catch (error) {
        toast.error(`Downloading failed: ${error}`, {
          position: 'bottom-left',
        });
        setIsLoading(false);
      }
    }
  };

  return (
    <Tooltip position="bottom right" tooltip="Download CSV">
      <button
        type="button"
        className={classNames(downloadButton, buttonClassName)}
        disabled={isLoading}
        onClick={handleDownload}
      >
        {isLoading ? (
          <Loader size="tiny" active inline />
        ) : (
          <BuIcon name={BoostUpIcons.Download} color="var(--bu-primary-700)" />
        )}
      </button>
    </Tooltip>
  );
};

export default ExportCSVFromJSON;
