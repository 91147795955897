import { css } from 'emotion';

import {
  fontDefaultDropdown,
  fontCaption,
  fontDefault,
} from 'assets/css/common';

export const container = css`
  margin: -15px -15px -10px;
  width: 600px;
  overflow: hidden;
`;

export const header = css`
  width: 100%;
  padding: 13px 20px 8px;
  border-bottom: 1px solid var(--bu-gray-500);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    ${fontDefaultDropdown};
    color: var(--bu-gray-700);
  }

  .diff-currency {
    display: flex;
    gap: 5px;

    i {
      color: var(--bu-orange-500);
    }
  }
`;

export const body = css`
  flex: 1;
  height: 250px;
  max-height: 250px;
  overflow-y: auto;
`;

export const row = css`
  display: flex;
  flex-direction: row;
`;

export const between = css`
  justify-content: space-between;
  align-items: center;
`;

export const item = css`
  border-bottom: 1px solid var(--bu-gray-300);
  width: 100%;
`;

export const column = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const date = css`
  color: var(--bu-gray-900);
  ${fontDefaultDropdown};
  padding: 10px 15px;
  max-width: 120px;
  width: 100%;
  text-align: left;
  border-right: 1px solid var(--bu-gray-300);
`;

export const info = css`
  padding: 10px 15px;
`;

export const historyValue = css`
  color: var(--bu-gray-900);
  ${fontDefaultDropdown};

  .amount-in-user-currency {
    margin-left: 5px;
    color: var(--bu-gray-700);
  }
`;

export const badgeAndHistoryWrapper = css`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

export const badgeWrapper = css`
  margin-left: 4px;
`;

export const badgeWrapperInMulticurrency = css`
  margin-left: 0px;
  display: inline-block;
`;

export const historyDealsButton = css`
  padding-left: 30px;
  cursor: pointer;
  color: var(--bu-gray-900);
  ${fontDefault};
  background-color: var(--bu-gray-300);
  border-radius: 4px;
  padding: 4px 15px;
  text-align: center;

  span {
    ${fontCaption};
    font-size: 14px;
  }
`;

export const notes = css`
  color: var(--bu-gray-700);
  ${fontDefault};
  margin-top: 5px;
  line-height: 22px;
`;

export const notes_none = css`
  color: var(--bu-gray-500);
  margin-top: 5px;
`;

export const content = css`
  color: #777777;
  display: flex;
  height: 250px;
  justify-content: center;
  align-items: center;
`;

export const highlight = css`
  background-color: var(--bu-yellow-300);
`;

export const changesSinceLabel = css`
  font-size: 12px;
  line-height: 16.8px;
  margin-right: 8px;
`;
