import classNames from 'classnames';
import { css } from 'emotion';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Accordion } from 'semantic-ui-react';

import {
  getFieldConfigurationList,
  getTableCollection,
  updateTableCollection,
} from 'actions/settingsActions';
import TableCollection from 'components/UI/common/TableCollection/TableCollection';
import { Status } from 'components/UI/common/TypedTable/TypedTable';
import { FieldConfiguration } from 'components/settings/FieldConfiguration/types';
import { SettingsView } from 'components/settings/Settings/styles';
import { profileBase } from 'components/settings/UserProfiles/UserProfiles';
import { IReduxState } from 'reducers/types';
import {
  fieldConfigurationList,
  tableCollections,
  tableCollectionsStatuses,
} from 'selectors';

const backgroundOnHover = css`
  &.ui.accordion.styled {
    .title {
      &:hover,
      &.active {
        background-color: #f9fcff;
      }
    }

    .content.active {
      padding: 0;

      border-top: 1px solid #ddd;
    }
  }
`;

const contentWithoutPadding = css`
  &.ui.accordion.styled {
    .content.active {
      padding: 0;

      border-top: 1px solid #ddd;
    }
  }
`;

const fixHeadersInAccordion = css`
  &.ui.styled.accordion .ui.header > .content {
    padding: 0 !important;
    padding-left: 0.75em !important;
  }
`;

export type TableColumnEntry = string;

export type AvailableFieldEntry = {
  display_name: string;
  object_field: string;
  sortable: boolean;
};

export type OrderEntry = {
  column: string;
  direction: 1 | -1;
  object_field: string;
};

export type TableColumnData = {
  name: string;
  columns: TableColumnEntry[];
  order: OrderEntry;
  available_fields?: AvailableFieldEntry[];
};

export type TableColumnUpdateData = {
  name: string;
  columns: TableColumnEntry[];
  order: string;
};

export interface TableCollectionData {
  available_fields: AvailableFieldEntry[];
  tables: TableColumnData[];
}

interface TableCollectionDataMap {
  [collectionName: string]: TableCollectionData;
}

interface TableCollectionStatusesMap {
  [collectionName: string]: Status;
}

type TOwnProps = {
  apiBase?: string;
};

interface IStateProps {
  fieldConfigurationList: FieldConfiguration[];
  tableCollections: TableCollectionDataMap;
  tableCollectionsStatuses: TableCollectionStatusesMap;
}

interface IDispatchProps {
  getFieldConfigurationList: () => void;
  getTableCollection: (collectionName: string) => void;
  updateTableCollection: (
    collectionName: string,
    data: TableColumnUpdateData
  ) => void;
}

type IProps = TOwnProps & IStateProps & IDispatchProps;

const ManageTableColumns: React.FC<IProps> = ({
  apiBase = '/api/settings',
  fieldConfigurationList,
  getFieldConfigurationList,
  getTableCollection,
  tableCollections,
  tableCollectionsStatuses,
  updateTableCollection,
}: IProps) => {
  const [activeIndex, setActiveIndex] = useState('');

  useEffect(() => {
    getFieldConfigurationList();
  }, []);

  useEffect(() => {
    if (activeIndex) {
      getTableCollection(activeIndex);
    }
  }, [activeIndex]);

  const handleOpen = (index: string) => () => {
    setActiveIndex(index);
  };

  const handleClose = () => setActiveIndex('');

  const handleChange =
    (collectionName: string) => (table: TableColumnUpdateData) => {
      updateTableCollection(collectionName, table);
    };

  return (
    <>
      {!apiBase.includes(profileBase) && (
        <SettingsView.HeaderContainer>
          <SettingsView.Header>Manage Table Columns</SettingsView.Header>
        </SettingsView.HeaderContainer>
      )}

      <Accordion
        exclusive
        fluid
        styled
        className={classNames(
          backgroundOnHover,
          contentWithoutPadding,
          fixHeadersInAccordion
        )}
      >
        {fieldConfigurationList.map((collection) => (
          <TableCollection
            key={collection.name}
            subTitle="Tables"
            activeIndex={activeIndex}
            collection={collection}
            data={tableCollections[collection.name]}
            status={tableCollectionsStatuses[collection.name]}
            onOpen={handleOpen(collection.name)}
            onClose={handleClose}
            onChange={handleChange(collection.name)}
            canChangeDefaultSorting
          />
        ))}
      </Accordion>
    </>
  );
};

const mapStateToProps = (
  state: IReduxState,
  ownProps: TOwnProps
): IStateProps => {
  const { apiBase = '/api/settings' } = ownProps;

  return {
    fieldConfigurationList: fieldConfigurationList(
      state,
      true,
      apiBase
    ) as FieldConfiguration[],
    tableCollections: tableCollections(state, apiBase),
    tableCollectionsStatuses: tableCollectionsStatuses(state, apiBase),
  };
};

const mapDispatchToProps = (
  dispatch: Function,
  ownProps: TOwnProps
): IDispatchProps => {
  const { apiBase = '/api/settings' } = ownProps;

  return {
    getFieldConfigurationList: () =>
      dispatch(getFieldConfigurationList(apiBase)),
    getTableCollection: (collectionName: string) =>
      dispatch(getTableCollection(apiBase, collectionName)),
    updateTableCollection: (
      collectionName: string,
      data: TableColumnUpdateData
    ) => dispatch(updateTableCollection(apiBase, collectionName, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageTableColumns);
