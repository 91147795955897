export const reportsActionType = {
  UPDATE_STATE: 'REPORTS_UPDATE_STATE',
  RESET_STATE: 'REPORTS_RESET_STATE',
  SELECT_CUSTOM_REPORT: 'SELECT_CUSTOM_REPORT',
  CLEAR_CUSTOM_REPORT: 'CLEAR_CUSTOM_REPORT',
};

export const selectedClassType = {
  ALL_OPPORTUNITIES: 200,
  UPCOMING_EVENT_OPPORTUNITIES: 201, // Opportunities with an upcoming event
  RECENT_EVENT_OPPORTUNITIES: 202, // Opportunities with recent event
  STALLED_OPPORTUNITIES: 203, // Opportunities where customer is not active
  ACTIVE_LEAD_OPPORTUNITIES: 204, // Opportunities with active customer but no upcoming event
  NO_FOLLOWUP_OPPORTUNITIES: 205, // Opportunities where meeting happened and seller has not followed up afterwards
  FEW_CONTACTS_ENGAGED_OPPORTUNITIES: 206, // Opportunities where only few contacts have been engaged
  STRONGLY_QUALIFIED_OPPORTUNITIES: 207, // Opportunities that are strongly qualified
  CUSTOM_REPORT: 500,
};
