import * as R from 'ramda';
import React from 'react';

import {
  IDataCellProps,
  IRow,
  ValueType,
} from 'components/UI/common/TypedTable/TypedTable';

export const getFieldValue = <T extends ValueType>(
  fieldPathName: string,
  row: IRow
) => R.path(fieldPathName.split('.'), row) as T;

export const getCellValue = <T extends ValueType>({
  column,
  row,
}: Pick<IDataCellProps, 'column' | 'row'>) =>
  getFieldValue<T>(column.field, row);

export type CustomComponentFunction<T extends {} = {}> = (
  config: T
) => React.FC<IDataCellProps>;

export const makeComponent =
  <T extends {}>(
    createComponentFunction: CustomComponentFunction<T>
  ): React.FC<T & IDataCellProps> =>
  (props) =>
    createComponentFunction(props)(props);
