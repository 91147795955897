import { TrendItemResponse } from 'api/Trends';
import { ActionCreator } from 'typescript-fsa';

import { FILTER_NAME } from 'common/constants';
import { Chart as IChart } from 'components/chart-dashboards/Dashboard/types';
import { DataItemType } from 'components/chart-dashboards/Widget/types';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import {
  ModalsScheme,
  PersistQueryParams,
  PersistorFunction,
} from 'navigation/types';
import { openModal } from 'navigation/utils';

const dataTypeToScheme: {
  [key: string]: ModalsScheme;
} = {
  accounts: '/accounts',
  deals: '/deals',
  events: '/events',
};

const SERIES_WITH_CUSTOM_AMOUNT = ['+Value', '-Value'];

const SERIES_TO_SKIP_BUSINESS_VALIDATION = [
  '+Value',
  '-Value',
  'Pushed',
  'Won',
  'Lost',
];

export const MAX_ITEMS_TO_DISPLAY = 1000;

export const timeSegments = [
  { text: 'Last 30 days', id: 'L30D' },
  { text: 'Last 60 days', id: 'L60D' },
  { text: 'Last 90 days', id: 'L90D' },
  { text: 'Current Quarter', id: 'TQU' },
];

export const CATEGORY_INITIAL_VALUE = { label: 'All', value: 'all' };

export const getYAxisTitle = (
  value_path: IChart['value_path'],
  value: string
) => {
  if (Array.isArray(value_path)) {
    const item = value_path.find(({ path }) => path === value);

    if (item) {
      return item.display_name;
    }
  }

  return undefined;
};

export const calculateDeltaOverPeriod = (
  isWaterfallChart: boolean,
  chartData?: DataItemType[] | TrendItemResponse[]
): number => {
  if (isWaterfallChart && chartData) {
    const endValue = chartData[chartData.length - 1].total_amount;
    const startValue = chartData[0].total_amount;

    if (typeof endValue === 'number' && typeof startValue === 'number') {
      return endValue - startValue;
    }
  }

  return 0;
};

export const openDealsModal = (
  filters = {},
  title = '',
  context = '',
  chartData: DataItemType[],
  isTrendsWidget = false,
  dataType: string,
  deltaOverPeriod: number,
  clickApiUrl: string = '',
  extraParams: any = {},
  apiParams = {},
  localStorageKeyPrefix = '',
  persistModalParams: ActionCreator<PersistQueryParams> | PersistorFunction
) => {
  const selectedBar =
    chartData.find((i: DataItemType) => i.label === title) ||
    ({
      ids: [],
    } as DataItemType);

  const isTrendsFirstBar = isTrendsWidget && title === chartData[0].label;

  const isLastBar =
    isTrendsWidget && title === chartData[chartData.length - 1].label;
  const hasToOverrideModalAmount = SERIES_WITH_CUSTOM_AMOUNT.includes(title);

  const hasToSendSkipBtValidationParam =
    SERIES_TO_SKIP_BUSINESS_VALIDATION.includes(title) || isTrendsFirstBar;

  const applyBetaFilters = (firstLabel: string) => {
    if (!isTrendsWidget || title === 'How did your pipeline change') {
      return {};
    }

    const betaFilters: {
      [key: string]: string | string[] | boolean | undefined;
    } = {
      ids: selectedBar.ids || [],
      is_forecast: isTrendsFirstBar ? false : true,
      // exclude delta values for first bar
      use_beginning_of_period: firstLabel === selectedBar.label,
    };

    return betaFilters;
  };

  if (['deals', 'events', 'accounts'].includes(dataType)) {
    // If is First Bar (also known as A Bar) we're showing snapshot data, so
    // we need to make filtering on the snapshot data, not the live data
    const apiUrl = isTrendsFirstBar ? '/api/data/deals/snapshot/' : clickApiUrl;

    const params = {
      apiUrl,
      id: selectedBar.y,
      title,
      context,
      isFirstBar: isTrendsFirstBar,
      isLastBar,
      deltaOverPeriod,
      filters: JSON.stringify({
        ...extraParams,
        ...apiParams,
        ...filters,
        ...applyBetaFilters(chartData[0].label!),
        skip_business_validation: hasToSendSkipBtValidationParam,
      }),
      overrideModalAmount: hasToOverrideModalAmount
        ? Math.abs(selectedBar.y || 0)
        : null,
      hideDelta: true,
      filterKeyForModal: FILTER_NAME.ForecastAnalyticsModal,
      filtersHaveToInheritFilterValues: false,
      haveToInheritUsersFilterValues: isTrendsWidget && extraParams.split_view,
      showFilters: isTrendsWidget,
    };

    AnalyticsTracker.event(
      { dataType, params },
      {
        action: 'Click (open modal)',
        category: 'Chart Widget',
        label: `Chart ${title}`,
      }
    );

    openModal({
      scheme: dataTypeToScheme[dataType],
      params: {
        localStorageKeyPrefix,
      },
      persistParams: params,
      persistor: persistModalParams,
    });
  }
};
