import { css } from 'emotion';
import React, { FC, ChangeEvent } from 'react';

const slider = css`
  height: 8px;
  border-radius: 5px;
  background: var(--bu-gray-300);
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const slider_progress = css`
  height: 8px;
  border-radius: 5px;
  background: var(--bu-primary-500);
  position: absolute;
  left: 25%;
  right: 25%;
`;

const range_input = css`
  position: relative;
  width: 100%;

  input {
    position: absolute;
    top: -8px;
    left: 0px;
    height: 8px;
    width: 100%;
    background: none;
    pointer-events: none;
    -webkit-appearance: none;

    &[type='range']::-webkit-slider-thumb {
      height: 20px;
      width: 20px;
      background: var(--bu-white);
      border-radius: 50%;
      pointer-events: auto;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
      -webkit-appearance: none;
      cursor: pointer;
    }

    &[type='range']::-moz-range-thumb {
      height: 20px;
      width: 20px;
      background: var(--bu-white);
      border-radius: 50%;
      pointer-events: auto;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
      -moz-appearance: none;
      border: none;
      cursor: pointer;
    }
  }
`;

type Props = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  minValue: number;
  maxValue: number;
  min: number;
  max: number;
};

const BuInputRange: FC<Props> = ({
  onChange,
  minValue,
  maxValue,
  min,
  max,
}) => {
  const percentLeft = (minValue / max) * 100 + '%';
  const percentRight = 100 - (maxValue / max) * 100 + '%';

  return (
    <section style={{ width: '100%' }}>
      <div className={slider}>
        <div
          className={slider_progress}
          style={{ left: percentLeft, right: percentRight }}
        />
      </div>

      <fieldset className={range_input}>
        <input
          style={{ left: 0 }}
          type="range"
          name="min"
          min={min}
          max={max}
          value={minValue}
          onChange={onChange}
        />
        <input
          type="range"
          name="max"
          min={min}
          max={max}
          value={maxValue}
          onChange={onChange}
        />
      </fieldset>
    </section>
  );
};

export default BuInputRange;
