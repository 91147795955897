import { IColumn, SortOrder } from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { CheckboxConfig } from 'components/settings/ForecastSettings/ForecastSubmissionTypes/types';

export const getColumns = (): IColumn[] => {
  return [
    {
      id: 'metric',
      label: 'Metric',
      field: 'text',
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.TEXT,
      editable: false,
      config: {},
    },
    {
      id: 'enable',
      label: 'Enable',
      field: 'enable',
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.CHECKBOX,
      editable: true,
      disable: (row) => (row.disable as boolean) ?? false,
      width: 100,
      config: {
        style: 'toggle',
      } as CheckboxConfig,
    },
  ];
};
