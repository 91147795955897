import { formatMoney } from 'common/numbers';

export function formatRevenue(number, code = 'USD', precision = 0) {
  return formatMoney(code, +number, precision);
}

export function buildTooltip(companyCurrencyCode) {
  return function () {
    const {
      point: { type, y, y2 },
      series: { name },
      color,
      x,
    } = this;

    const title = ['# of Deals', 'Revenue'].includes(name)
      ? x
      : `${name} – ${x}`;
    const [value, count] =
      type === 'revenue'
        ? [formatRevenue(y, companyCurrencyCode), y2]
        : [formatRevenue(y2, companyCurrencyCode), y];

    return `<b>${title}</b><br/><p><span style="color: ${color}">●</span> ${value} (${count})</p>`;
  };
}
