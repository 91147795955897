import { createSelector } from 'reselect';

import { ISelectOption } from 'components/UI/BuSelect/types';
import { getMetricType } from 'components/dashboard/Metrics/Create/utils';
import {
  AnalysisType,
  FORECAST_SUBMISSION_OBJECTS,
} from 'components/dashboard/Metrics/constants';
import { MetricType } from 'components/dashboard/Metrics/enums';
import { formatMetricObject } from 'components/dashboard/Metrics/metrics.helpers';
import {
  BIMetricCreated,
  BIMetricSimple,
  BIMetricSimplified,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';
import { IReduxState } from 'reducers/types';
import { QueryStatus } from 'utils/network';

export const getMetricsList = (state: IReduxState): BIMetricCreated[] =>
  state.revbi.metrics.metricsList.list;

export const getForecastSubmissionMetrics = (
  state: IReduxState
): BIMetricCreated[] => state.revbi.metrics.forecastSubmissionMetricsList.list;

export const getNonForecastSubmissionMetrics = (
  state: IReduxState
): BIMetricCreated[] =>
  state.revbi.metrics.nonForecastSubmissionMetricsList.list;

export const getMetricsListStatus = (state: IReduxState): string =>
  state.revbi.metrics.metricsList.status;

export const getMetricsTSList = (state: IReduxState): BIMetricCreated[] =>
  state.revbi.metrics.metricsTSList.list;

export const getMetricsTSListStatus = (state: IReduxState): string =>
  state.revbi.metrics.metricsTSList.status;

export const getAllMetricsWithTS = (state: IReduxState): BIMetricCreated[] => {
  if (
    state.revbi.metrics.metricsList.status === 'success' &&
    state.revbi.metrics.metricsTSList.status === 'success'
  ) {
    const allMetrics = [
      ...state.revbi.metrics.metricsList.list,
      ...state.revbi.metrics.metricsTSList.list,
    ];

    return allMetrics.sort(function (a, b) {
      return (
        new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
      );
    });
  }

  return [];
};

export const getAllMetricsWithTSStatus = (state: IReduxState): QueryStatus => {
  const { metricsList, metricsTSList } = state.revbi.metrics;

  if (metricsList.status === 'success' && metricsTSList.status === 'success') {
    return 'success';
  }

  if (metricsList.status === 'loading' || metricsTSList.status === 'loading') {
    return 'loading';
  }

  if (
    metricsList.status === 'notAsked' &&
    metricsTSList.status === 'notAsked'
  ) {
    return 'notAsked';
  }

  return 'notAsked';
};

const getAllSimpleMetrics = (state: IReduxState): BIMetricSimple[] =>
  state.revbi.metrics.metricsList.list.filter(
    (m) => getMetricType(m) === MetricType.Simple
  ) as BIMetricSimple[];

export const getAllSimpleMetricsSimplified = (
  state: IReduxState
): BIMetricSimplified[] =>
  getAllSimpleMetrics(state)
    .filter(
      (metric: BIMetricSimple) =>
        // metric with forecast submission as object are not simple metric
        // don't ask me
        !FORECAST_SUBMISSION_OBJECTS.includes(metric.object)
    )
    .map((metric: BIMetricSimple) => ({
      name: metric.name,
      id: metric._id || '',
      color: '',
    }));

const getAllSimpleTSSMetrics = (state: IReduxState): BIMetricSimple[] =>
  state.revbi.metrics.metricsTSList.list.filter(
    (m) => getMetricType(m) === MetricType.Simple
  ) as BIMetricSimple[];

export const getAllSimpleHistoricalMetricsSimplified = (
  state: IReduxState
): BIMetricSimplified[] =>
  getAllSimpleTSSMetrics(state)
    .filter(
      (metric: BIMetricSimple) =>
        // metric with forecast submission as object are not simple metric
        // don't ask me
        !FORECAST_SUBMISSION_OBJECTS.includes(metric.object)
    )
    .map((metric: BIMetricSimple) => ({
      name: metric.name,
      id: metric._id || '',
      color: '',
    }));

export const getObjectList = (state: IReduxState) =>
  state.revbi.metrics.objectList.list;

export const getObjectListStatus = (state: IReduxState) =>
  state.revbi.metrics.objectList.status;

export const getObjectsListHistoryStatus = (state: IReduxState) =>
  state.revbi.metrics.objectListHistory.status;

const formatObjectsList = (list: string[]): ISelectOption[] =>
  list.map((object: string) => ({
    text: formatMetricObject(object),
    value: object,
  }));

const getObjectListFormatted = (state: IReduxState) =>
  formatObjectsList(state.revbi.metrics.objectList.list);

const getObjectListHistoryFormatted = (state: IReduxState) =>
  formatObjectsList(state.revbi.metrics.objectListHistory.list);

export const getObjectsListFormattedByAnalysisType = (
  state: IReduxState,
  analysisType: AnalysisType
) => {
  if (analysisType === AnalysisType.HISTORICAL) {
    return getObjectListHistoryFormatted(state);
  }
  return getObjectListFormatted(state);
};

export const getTimeOptions = (state: IReduxState) =>
  state.revbi.metrics.timeOptions.options;

export const getTimeOptionsStatus = (state: IReduxState) =>
  state.revbi.metrics.timeOptions.status;

export const getWidgetsList = (state: IReduxState): BIWidget[] =>
  state.revbi.metrics.widgetsList.list;

export const getWidgetsListStatus = (state: IReduxState): string =>
  state.revbi.metrics.widgetsList.status;

export const getQuarterForecastPeriodOptions = (state: IReduxState) =>
  state.revbi.metrics.forecastPeriodTimeOptions.quarter.options;

export const getQuarterForecastPeriodOptionsStatus = (state: IReduxState) =>
  state.revbi.metrics.forecastPeriodTimeOptions.quarter.status;

export const getMonthForecastPeriodOptions = (state: IReduxState) =>
  state.revbi.metrics.forecastPeriodTimeOptions.month.options;

export const getMonthForecastPeriodOptionsStatus = (state: IReduxState) =>
  state.revbi.metrics.forecastPeriodTimeOptions.month.status;

export const getWeekForecastPeriodOptions = (state: IReduxState) =>
  state.revbi.metrics.forecastPeriodTimeOptions.week.options;

export const getWeekForecastPeriodOptionsStatus = (state: IReduxState) =>
  state.revbi.metrics.forecastPeriodTimeOptions.week.status;

export const getAllForecastPeriodOptions = createSelector(
  [
    (state: IReduxState) =>
      state.revbi.metrics.forecastPeriodTimeOptions.quarter.options,
    (state: IReduxState) =>
      state.revbi.metrics.forecastPeriodTimeOptions.month.options,
    (state: IReduxState) =>
      state.revbi.metrics.forecastPeriodTimeOptions.week.options,
  ],
  (quarterOptions, monthOptions, weekOptions) => [
    ...quarterOptions,
    ...monthOptions,
    ...weekOptions,
  ]
);
