import { produce } from 'immer';
import * as R from 'ramda';
import { combineReducers } from 'redux';

import * as t from 'actions/actionTypes';

const stepsDefault = [false, false];
const steps = produce((draft, action) => {
  switch (action.type) {
    case t.SIGN_UP + t.ENTER:
      return stepsDefault;
    case t.SIGN_UP + t.STEP + t.CHANGE:
      draft[action.payload.step] = action.payload.status;
      break;
    default:
  }
}, stepsDefault);

const loading = (state = false, action) => {
  switch (action.type) {
    case t.SIGN_UP + t.OAUTH + t.LOADING:
    case t.SALESFORCE + t.OAUTH + t.LOADING:
      return true;
    case t.SIGN_UP + t.ENTER:
    case t.SIGN_UP + t.OAUTH + t.SUCCESS:
    case t.SIGN_UP + t.OAUTH + t.ERROR:
    case t.SALESFORCE + t.OAUTH + t.SUCCESS:
    case t.SALESFORCE + t.OAUTH + t.ERROR:
      return false;
    default:
      return state;
  }
};

const error = (state = null, action) => {
  switch (action.type) {
    case t.SIGN_UP + t.OAUTH + t.ERROR:
    case t.SALESFORCE + t.OAUTH + t.ERROR:
      return R.pathOr('There was an error during the sign up', [
        'error',
        'response',
        'data',
        'error',
        'message',
        0,
      ])(action);
    case t.SIGN_UP + t.ENTER:
    case t.SIGN_UP + t.OAUTH + t.LOADING:
    case t.SALESFORCE + t.OAUTH + t.LOADING:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  steps,
  loading,
  error,
});
