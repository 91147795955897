import { AccountData } from 'components/dashboard/Accounts/AccountsList';
import axiosInstance from './axiosInstance';
const GET_ACCOUNTS_URL = '/api/data/accounts/';

export interface GetAccountsParams {
  /**
   *  Ids of the accounts to retrieve
   * */
  ids: string[];

  page_number?: number;
  page_size?: number;
  sort?: string;
}

export interface GetAccountsResponse {
  accounts: AccountData[];
  total_count: number;
}

export const getAccounts = async (request: GetAccountsParams) => {
  const response = await axiosInstance.post<GetAccountsResponse>(
    GET_ACCOUNTS_URL,
    request
  );

  return response.data;
};

const getUpdateAccountsUrl = (accountId: string) =>
  `/api/data/accounts/update/${accountId}/`;

export interface UpdateAccountParams {
  editedFields: Partial<AccountData>;
  id: string;
}
export const updateAccount = async (params: UpdateAccountParams) => {
  const response = await axiosInstance.post<AccountData>(
    getUpdateAccountsUrl(params.id),
    params.editedFields
  );

  return response.data;
};
