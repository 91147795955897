import { IDataCellProps, TypedTableCellConfig } from '../TypedTable';
import React from 'react';

export interface CustomCellConfig extends TypedTableCellConfig {
  renderer: React.FC<IDataCellProps>;
}

const CustomCell = ({ column, ...rest }: IDataCellProps) => {
  const config = (column.config as CustomCellConfig) || {};

  const CustomRenderer =
    config.renderer || (() => <div>No renderer set for Custom Cell</div>);

  return <CustomRenderer column={column} {...rest} />;
};

export default CustomCell;
