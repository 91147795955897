import styled from '@emotion/styled';

export const Container = styled.div({
  marginBottom: '15px',
});

export const Title = styled.div({
  fontSize: '17px',
  fontWeight: 'bold',
});

export const Description = styled.div({
  fontSize: '12px',
  color: 'var(--bu-gray-700)',
});
