import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { Dropdown } from 'semantic-ui-react';

import BuDatePicker from 'components/UI/BuDatePicker/BuDatePicker';
import BuInput from 'components/UI/BuInput';
import BuTimePicker from 'components/UI/BuTimePicker';
import {
  ConditionBox,
  ConditionBoxRemoveButton,
  DropdownLeftWrapper,
  DropdownRightWrapper,
  dropdownLeftStyle,
  PropertyItem,
  dropdownStyle,
  smallDropdownStyle,
  readOnlyInputStyle,
  numericInputStyle,
  timeSelectorStyle,
  dateSelectorStyle,
  datePopUpStyle,
  textInputStyle,
} from 'components/settings/CRMSync/CRMTableFiltersModal/TableCondition/styles';
import {
  IProps,
  Operator,
  ConditionType,
  ConditionFieldInfo,
  ConditionFieldInfoPickList,
  isNumericField,
  isTextField,
  isPicklistField,
} from 'components/settings/CRMSync/CRMTableFiltersModal/TableCondition/types';

type AvailableCondition = {
  text: string;
  operator: ConditionType['operator'];
  type: 'numeric' | 'picklist' | ConditionFieldInfo['field_type'];
};

const AVAILABLE_CONDITIONS: Array<AvailableCondition> = [
  { type: 'numeric', operator: Operator.GreaterThan, text: 'Greater than' },
  {
    type: 'numeric',
    operator: Operator.GreaterThanOrEqual,
    text: 'Greater than or Equal',
  },
  { type: 'numeric', operator: Operator.LowerThan, text: 'Lower than' },
  {
    type: 'numeric',
    operator: Operator.LowerThanOrEqual,
    text: 'Lower than or Equal',
  },

  { type: 'bool', operator: Operator.EqualTo, text: 'Equals to' },
  { type: 'bool', operator: Operator.NotEqualTo, text: 'Not equal' },

  { type: 'picklist', operator: Operator.In, text: 'In' },
  { type: 'picklist', operator: Operator.NotIn, text: 'Not In' },

  { type: 'date', operator: Operator.EqualTo, text: 'Equals to' },
  { type: 'date', operator: Operator.NotEqualTo, text: 'Not equal' },

  { type: 'time', operator: Operator.EqualTo, text: 'Equals to' },
  { type: 'time', operator: Operator.NotEqualTo, text: 'Not equal' },

  { type: 'text', operator: Operator.Like, text: 'Like' },
  { type: 'text', operator: Operator.EqualTo, text: 'Equals to' },
];

const INPUT_TYPE = {
  money: 'currency',
  percentage: 'number',
  number: 'number',
  text: 'text',
  phone: 'tel',
  date: '',
  bool: '',
  picklist: '',
  multipicklist: '',
  time: '',
  note: 'string',
};

const TableCondition: React.FC<IProps> = ({
  companyCurrency,
  condition,
  conditionFields,
  onChange,
  onRemove,
  isReadOnly = false,
}) => {
  const currentField = useMemo(
    () => conditionFields.find((field) => field.field === condition?.field),
    [condition, conditionFields]
  );

  const fieldOptions = useMemo(
    () =>
      conditionFields.map((item, index) => ({
        key: `${item.display_name}-${index}`,
        text: item.display_name,
        value: item.field,
      })),
    [conditionFields]
  );

  const currentFieldType = useMemo(() => {
    switch (true) {
      case isNumericField(currentField):
        return 'numeric';
      case isTextField(currentField):
        return 'text';
      case isPicklistField(currentField):
        return 'picklist';
      default:
        return currentField?.field_type;
    }
  }, [currentField]);

  const availableFieldConditions = useMemo(
    () => AVAILABLE_CONDITIONS.filter((item) => item.type === currentFieldType),
    [currentField, currentFieldType]
  );

  const fieldConditionOptions = useMemo(
    () =>
      availableFieldConditions.map((item, index) => ({
        key: `${currentField?.field}-${item.type}-${index}`,
        text: item.text,
        value: item.operator?.toLowerCase(),
      })),
    [availableFieldConditions, currentField]
  );

  const firstAvailableOperator =
    availableFieldConditions.length === 1 &&
    availableFieldConditions[0].operator;

  const operatorName = (
    firstAvailableOperator || condition.operator
  )?.toLowerCase();

  const hasOperator = !!operatorName;

  const defaultValue = (field: ConditionFieldInfo | undefined) => {
    switch (true) {
      case field?.field_type === 'bool':
        return true;
      case field?.field_type === 'multipicklist':
        return [];
      case field?.field_type === 'number':
      case field?.field_type === 'money':
      case field?.field_type === 'percentage':
        return 0;
      default:
        return '';
    }
  };

  const handleFieldChange = useCallback(
    (e, { value }) => {
      const nextField = conditionFields.find((field) => field.field === value);
      onChange({
        display_name: nextField?.display_name,
        field_type: nextField?.field_type,
        field: value as string,
        value: defaultValue(nextField),
        operator: undefined,
      } as ConditionType);
    },
    [onChange, conditionFields]
  );

  const handleConditionChange = useCallback(
    (e, { value }) => {
      onChange({
        ...condition,
        operator: value as ConditionType['operator'],
        value: defaultValue(currentField),
      } as ConditionType);
    },
    [onChange, condition, currentField]
  );

  const notifyValueChange = (
    value: null | string | string[] | boolean | number | number[]
  ) => {
    onChange({ ...condition, value } as ConditionType);
  };

  return (
    <ConditionBox>
      {!isReadOnly && (
        <ConditionBoxRemoveButton onClick={onRemove}>
          x
        </ConditionBoxRemoveButton>
      )}

      <PropertyItem>
        <DropdownLeftWrapper>
          <Dropdown
            className={classNames(dropdownLeftStyle, smallDropdownStyle, {
              [readOnlyInputStyle]: isReadOnly,
            })}
            selection
            options={fieldOptions}
            placeholder="Search..."
            value={condition.field ?? ''}
            onChange={handleFieldChange}
            disabled={isReadOnly}
            search
          />
          <span>is</span>
        </DropdownLeftWrapper>
        <DropdownRightWrapper>
          <Dropdown
            className={classNames(smallDropdownStyle, {
              [readOnlyInputStyle]: isReadOnly,
            })}
            selection
            options={fieldConditionOptions}
            placeholder="Choose an option"
            value={operatorName ?? ''}
            disabled={isReadOnly || !currentField}
            onChange={handleConditionChange}
          />
        </DropdownRightWrapper>
      </PropertyItem>

      {currentField && (
        <PropertyItem>
          {currentFieldType === 'bool' && (
            <Dropdown
              className={classNames(dropdownStyle, {
                [readOnlyInputStyle]: isReadOnly,
              })}
              selection
              disabled={isReadOnly || !hasOperator}
              options={[
                { text: 'True', value: true },
                { text: 'False', value: false },
              ]}
              placeholder="Choose an option"
              value={Boolean(condition.value)}
              onChange={(e, { value }) => notifyValueChange(value as boolean)}
            />
          )}

          {currentFieldType === 'picklist' && (
            <Dropdown
              className={classNames(dropdownStyle, {
                [readOnlyInputStyle]: isReadOnly,
              })}
              fluid
              selection
              multiple={true}
              disabled={isReadOnly || !hasOperator}
              options={(currentField as ConditionFieldInfoPickList).values?.map(
                (item) => ({
                  text: item.display_name,
                  value: item.value,
                })
              )}
              placeholder="Choose an option"
              value={condition.value}
              onChange={(e, { value }) => notifyValueChange(value as string[])}
            />
          )}

          {currentFieldType === 'numeric' && (
            <BuInput
              className={classNames(numericInputStyle, {
                [readOnlyInputStyle]: isReadOnly,
              })}
              code={
                currentField.field_type === 'money'
                  ? companyCurrency
                  : undefined
              }
              postfix={
                currentField.field_type === 'percentage' ? '%' : undefined
              }
              type={INPUT_TYPE[currentField.field_type]}
              disabled={isReadOnly || !hasOperator}
              value={condition.value as number}
              onChange={(e) =>
                notifyValueChange(e.currentTarget.valueAsNumber || 0)
              }
            />
          )}

          {currentFieldType === 'text' && (
            <BuInput
              className={classNames(textInputStyle, {
                [readOnlyInputStyle]: isReadOnly,
              })}
              type={INPUT_TYPE.text}
              disabled={isReadOnly || !hasOperator}
              value={condition.value as string}
              onChange={(e) => notifyValueChange(e.currentTarget.value || '')}
            />
          )}

          {currentFieldType === 'date' && (
            <BuDatePicker
              selectorClassName={classNames(dateSelectorStyle, {
                [readOnlyInputStyle]: isReadOnly,
              })}
              popUpClassName={datePopUpStyle}
              value={(condition.value as string) || ''}
              onChange={notifyValueChange}
              disabled={isReadOnly}
            />
          )}

          {currentFieldType === 'time' && (
            <BuTimePicker
              className={classNames(timeSelectorStyle, {
                [readOnlyInputStyle]: isReadOnly,
              })}
              value={(condition.value as string) || ''}
              onChange={(value) => notifyValueChange(value as string)}
              formats={{
                inputFormat: 'HH:mm',
                displayFormat: 'hh:mm A',
              }}
              disabled={isReadOnly || !hasOperator}
            />
          )}

          {currentFieldType === 'phone' && (
            <BuInput
              className={classNames(textInputStyle, {
                [readOnlyInputStyle]: isReadOnly,
              })}
              type={INPUT_TYPE.phone}
              disabled={isReadOnly || !hasOperator}
              value={condition.value as string}
              onChange={(e) => notifyValueChange(e.currentTarget.value || 0)}
            />
          )}
        </PropertyItem>
      )}
    </ConditionBox>
  );
};

export default TableCondition;
