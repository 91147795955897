export const NEW_PIPELINE_CREATED_PACE_INTERVAL_OPTIONS = [
  {
    text: 'Current Quarter',
    value: 'TQU',
  },
  {
    text: 'Current Year',
    value: 'TYE',
  },
  {
    text: 'Last Quarter',
    value: 'LQU',
  },
  {
    text: 'Last 5 Quarters',
    value: 'L5Q',
  },
];

export const PIPELINE_BY_CLOSED_DATE_PACE_INTERVAL_OPTIONS = [
  {
    text: 'Next Quarter',
    value: 'NQU',
  },
  {
    text: 'Next Month',
    value: 'NMO',
  },
  {
    text: 'Next 3 Months',
    value: 'N3M',
  },
  {
    text: 'Next 6 Months',
    value: 'N6M',
  },
  {
    text: 'Current Quarter',
    value: 'TQU',
  },
  {
    text: 'Current Year',
    value: 'TYE',
  },
  {
    text: 'Last Quarter',
    value: 'LQU',
  },
  {
    text: 'Last 4 Quarters',
    value: 'L4Q',
  },
];

export const NEW_PIPELINE_CREATED_FREQUENCY_OPTIONS = [
  {
    text: 'Week over Week',
    value: 'week',
  },
  {
    text: 'Month over Month',
    value: 'month',
  },
];

export const PIPELINE_CREATED_BY_CLOSE_DATE_FREQUENCY_OPTIONS =
  NEW_PIPELINE_CREATED_FREQUENCY_OPTIONS.concat([
    {
      text: 'Quarter over Quarter',
      value: 'quarter',
    },
  ]);
