import {
  getIncludedDealsByTeamApi,
  useRealTimeForecastDealsModal,
} from './helper';
import { css } from 'emotion';
import { set } from 'lodash';
import * as R from 'ramda';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Loader, Modal } from 'semantic-ui-react';

import { fetchForecastSubmissionsSettingsAvailable } from 'actions/forecastActions';
import * as uiActions from 'actions/ui';
import InfoIcon from 'assets/fonts/boostup-icons/badge_info_outline.svg';
import { mapIncludedSummaryForDeal } from 'common/forecastSubmission';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import { footer } from 'components/UI/BuConfirmationPopup/styles';
import OpenFiltersPanel from 'components/UI/OpenFiltersPanel';
import RecalculateSection from 'components/UI/RecalculateSection';
import {
  IdType,
  RowStatusType,
  ValueType,
  onChangeCallback,
} from 'components/UI/common/TypedTable/TypedTable';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import { getFieldValue } from 'components/UI/common/TypedTable/renderers/custom/common';
import SelectDealsTable from 'components/dashboard/ForecastRollUps/ForecastDealsTable/SelectDealsTable';
import {
  AutoIncludeInfoIcon,
  modalHeader,
  Title,
  modalContent,
  selectDealsTableWrapper,
} from 'components/dashboard/ForecastRollUps/ForecastDealsTable/styles';
import {
  DealsDataReponse,
  ForecastDealsModalProps,
  IncludedByTeamBody,
  IncludedByTeamDataResponse,
  SelectDealsTableDeal,
  SubmissionOverrideDealsResponse,
} from 'components/dashboard/ForecastRollUps/ForecastDealsTable/types';
import { useGetIncludeSummaryForSubmissionAndDeals } from 'components/hooks/useGetIncludeSummaryForSubmission';
import { IReduxState } from 'reducers/types';
import { getFiltersState, isForecastSubmissionLoaded } from 'selectors';
import { fetchApiPromise } from 'utils/network';

const fixSemanticModalCenter = css`
  // https://github.com/Semantic-Org/Semantic-UI-React/issues/3345#issuecomment-452687134
  &.ui.fullscreen.modal {
    left: initial !important;
  }
`;

const buttonTooltip = css`
  text-align: center;
`;

const RECALCULATE_BUTTON_COPY =
  'Changes were made to opportunities and these may affect their eligibility to be included in this forecast type. Closing this popup will recalculate deals to include';

const RECALCULATE_CLOSE_COPY =
  'Changes were made to opportunities and these may affect their eligibility to be included in this forecast type. Closing this popup will recalculate deals to include';

const RECALCULATE_SAVE_COPY =
  'Changes were made to opportunities and these may affect their eligibility to be included in this forecast type. Please recalculate data before saving';

const ForecastDealsModal: React.FC<ForecastDealsModalProps<unknown>> = <T,>({
  url,
  queryParams,
  submissionName,
  isOpen,
  refreshingSubmissionDealsInformation,
  onRefresh,
  onSave,
  onClose,
  dealsIncluded,
  dealsExcluded,
  submissionSettingsId,
  mountNode,
  callOptionType,
  viewMode = false,
  hideIncludeColumn = false,
}: ForecastDealsModalProps<T>) => {
  const dispatch = useDispatch();
  const [selectedDealsIds, setSelectedDealsIds] =
    useState<string[]>(dealsIncluded);

  const [isRawDealsDataLoading, setIsRawDealsDataLoading] =
    useState<boolean>(true);
  const [isIncludedByTeamLoading, setIsIncludedByTeamLoading] =
    useState<boolean>(true);

  const [dealsRawData, setData] = useState<DealsDataReponse>();
  const [firstForecastDealsData, setFirstForecastDealsData] =
    useState<DealsDataReponse>();
  const [filters, setFilters] = useState<Filters.FiltersState | null>(null);

  const [dealsIncludedByTeam, setDealsIncludedByTeam] = useState<
    IncludedByTeamDataResponse['data']['included_deals_ids']
  >([]);
  const [dealsExcludedByTeam, setDealsExcludedByTeam] = useState<
    IncludedByTeamDataResponse['data']['excluded_deals_ids']
  >([]);

  useEffect(() => {
    setSelectedDealsIds((prev) => R.intersection(prev, dealsIncluded));
  }, [dealsIncluded]);

  const [dealsEditionTracker, setDealsEditionTracker] = useState<RowStatusType>(
    {}
  );

  const allDealsIds = useMemo(
    () => [...dealsIncluded, ...dealsExcluded],
    [dealsIncluded, dealsExcluded]
  );

  const {
    realTimeOnClose,
    realTimeOnDealsRefreshed,
    realTimeOnDealUpdated,
    realTimeRecalculate,
    actionButtonsDisabled,
    hasToRecalculate,
    isRecalculating,
    isRealTimeEnabled,
  } = useRealTimeForecastDealsModal({
    dealsEditionTracker,
    onRefresh,
    onClose,
  });

  const internalOnClose = () => {
    if (isRealTimeEnabled) {
      realTimeOnClose();
    }
    onClose();
  };

  const internalOnSave = () => {
    onSave({
      selectedDealsIds,
      allDealsIds,
    });
  };

  const { isDealsIncludedSummaryLoading, dealsIncludedSummary } =
    useGetIncludeSummaryForSubmissionAndDeals(
      { id: submissionSettingsId },
      allDealsIds,
      !viewMode
    );

  const [userHasTeam, setUserHasTeam] = useState<boolean>(false);

  const shouldFetchDealsIdsIncludedByTeam =
    callOptionType === 'SIDE_PANEL_ME_AND_TEAM_CALLS';

  const tabName = useMemo(
    () => `forecast_deals_modal_${submissionSettingsId}`,
    [submissionSettingsId]
  );

  const filterState = useSelector((state: IReduxState) =>
    getFiltersState(state, tabName)
  );

  const isSubmissionAvailableLoaded = useSelector((state: IReduxState) =>
    isForecastSubmissionLoaded(state)
  );

  const updateDealStatusOnTracker = (
    dealId: IdType,
    field: string,
    value: ValueType | null,
    status: string
  ) => {
    setDealsEditionTracker((prev) => {
      const dealToEdit = prev[dealId] || {};
      dealToEdit[field] = {
        value: value?.toString() || '',
        status,
        date: Date.now(),
      };

      return { ...prev, [dealId]: dealToEdit };
    });
  };

  const updateSingleDeal = (
    rowField: IdType,
    columnField: string,
    newValue: ValueType | null
  ) => {
    setData((prev) => {
      if (prev) {
        return {
          ...prev,
          deals: prev.deals.map((deal) => {
            if (deal._id === rowField) {
              const updatedDeal = set(deal, columnField, newValue);
              return {
                ...updatedDeal,
              };
            }
            return deal;
          }),
        };
      }
      return undefined;
    });
  };

  const handleDealChange: onChangeCallback = async (column, row, newValue) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/data/deals/update/${row.id}/`;
    const oldValue = getFieldValue(column.field, row);

    updateDealStatusOnTracker(row.id, column.field, newValue, 'loading');
    updateSingleDeal(row.id, column.field, newValue);

    toast.warn('CRM update in progress.', {
      position: 'bottom-left',
    });
    const { error, result } = await fetchApiPromise<
      { [key: string]: ValueType | null },
      { data: Deals.Deal }
    >({
      url,
      queryParams: {
        [column.field]: newValue,
      },
    });

    if (error || !result) {
      toast.error("Sorry, we couldn't update the CRM. Please try again.", {
        position: 'bottom-left',
      });
      updateDealStatusOnTracker(row.id, column.field, newValue, 'error');

      updateSingleDeal(row.id, column.field, oldValue);
    } else {
      toast.success('CRM updated successfully.', {
        position: 'bottom-left',
      });
      updateDealStatusOnTracker(row.id, column.field, newValue, 'success');
      if (isRealTimeEnabled) {
        realTimeOnDealUpdated();
      } else {
        setIsRawDealsDataLoading(true);
        onRefresh();
      }
    }
  };

  useEffect(() => {
    if (!isSubmissionAvailableLoaded) {
      dispatch(fetchForecastSubmissionsSettingsAvailable());
    }
  }, [submissionSettingsId]);

  useEffect(() => {
    if (isOpen && !R.equals(filters, filterState)) {
      setFilters(filterState);
    }
  }, [isOpen, filterState]);

  useEffect(() => {
    if (!isOpen) {
      dispatch(uiActions.filters.clearAll({ tab: tabName }));
      setData(undefined);
      setFirstForecastDealsData(undefined);
      setFilters(null);
    }
  }, [isOpen]);

  const getDeals = useCallback(() => {
    if (filters) {
      setIsRawDealsDataLoading(true);

      let defaultfilters: Record<string, string[]> = {};
      let customFilters: Record<string, string[]> = {};
      const abortController = new AbortController();

      Object.keys(filters).forEach((filterName) => {
        const filteredValues = filters[filterName].values
          .filter((value) => value.checked)
          .map((value) => value.value);
        const isAll =
          filters[filterName].checkedAll ||
          filteredValues.some((filter) => filter === '__all__') ||
          filteredValues.length === 0;
        const filterValue = isAll ? ['__all__'] : filteredValues;

        if (filters[filterName].group === 'custom_filters') {
          customFilters[filterName] = filterValue;
        } else {
          defaultfilters[filterName] = filterValue;
        }
      });
      fetchApiPromise<T, SubmissionOverrideDealsResponse>({
        url,
        queryParams: {
          ...queryParams,
          filters: {
            ...defaultfilters,
            custom_filters: customFilters,
          },
        },
        signal: abortController.signal,
      }).then(({ result }) => {
        if (!abortController.signal.aborted) {
          if (R.isNil(dealsRawData)) {
            /**
             * We use the first request data to some logics, for show
             * deals change toast or to show the deals includes for example;
             */
            setFirstForecastDealsData(result?.data);
          }

          setData(result?.data);
          setIsRawDealsDataLoading(false);

          if (isRealTimeEnabled) {
            realTimeOnDealsRefreshed();
          }
        }
      });
      return () => {
        abortController.abort();
      };
    }
  }, [filters, queryParams]);

  useEffect(() => {
    if (isOpen && !refreshingSubmissionDealsInformation) {
      const abortDealsCall = getDeals();
      return () => abortDealsCall?.();
    }
  }, [getDeals]);

  useEffect(() => {
    if (allDealsIds.length && shouldFetchDealsIdsIncludedByTeam) {
      setIsIncludedByTeamLoading(true);
      fetchApiPromise<IncludedByTeamBody, IncludedByTeamDataResponse>({
        url: getIncludedDealsByTeamApi(submissionSettingsId),
        queryMethod: 'post',
        queryParams: {
          included_deals: allDealsIds,
          only_team: true,
        },
      }).then((response) => {
        if (response.result?.data.included_deals_ids) {
          setDealsIncludedByTeam(response.result.data.included_deals_ids);
          setDealsExcludedByTeam(response.result.data.excluded_deals_ids);
          setUserHasTeam(response.result.data.has_team);
        }
        setIsIncludedByTeamLoading(false);
      });
    }
  }, [submissionSettingsId, allDealsIds, shouldFetchDealsIdsIncludedByTeam]);

  const parsedDeals = useMemo<SelectDealsTableDeal[]>(
    () =>
      dealsRawData?.deals?.map((rawDeal) => ({
        ...rawDeal,
        includedByTeam: dealsIncludedByTeam.includes(rawDeal._id),
        excludedByTeam: dealsExcludedByTeam.includes(rawDeal._id),
        includedSummaryForDeal: mapIncludedSummaryForDeal(
          rawDeal._id,
          dealsIncludedSummary
        ),
      })) || [],
    [dealsRawData, dealsIncludedByTeam, dealsIncludedSummary]
  );

  useEffect(() => {
    if (firstForecastDealsData) {
      /**
       * show toast message after getting the first deals list when total deals are different than user saw on the main table
       */
      if (
        firstForecastDealsData.count !==
        dealsIncluded.length + dealsExcluded.length
      ) {
        toast.warn('Available deals changed since the last override');
      }
    }
  }, [firstForecastDealsData]);

  const handleSelectDeals: React.ComponentProps<
    typeof SelectDealsTable
  >['onSelectedDeals'] = (selectedDealsIds, allDealsIds, amount) => {
    setSelectedDealsIds(selectedDealsIds);
  };

  const isLoading =
    isRawDealsDataLoading ||
    isDealsIncludedSummaryLoading ||
    (shouldFetchDealsIdsIncludedByTeam && isIncludedByTeamLoading) ||
    refreshingSubmissionDealsInformation ||
    isRecalculating;

  return (
    <Modal
      size="fullscreen"
      open={isOpen}
      onClose={internalOnClose}
      mountNode={mountNode}
      className={fixSemanticModalCenter}
    >
      <Modal.Header className={modalHeader}>
        <Title>{submissionName}</Title>

        {!viewMode && callOptionType === 'SIDE_PANEL_ME_AND_TEAM_CALLS' && (
          <AutoIncludeInfoIcon>
            <TooltipWrapper
              tooltip={
                <div style={{ width: '300px', textAlign: 'center' }}>
                  The deals included/excluded by default are determined by the
                  rules set in your Forecast Settings by your company.
                </div>
              }
              position="right center"
            >
              <img src={InfoIcon} alt="info" />
            </TooltipWrapper>
          </AutoIncludeInfoIcon>
        )}

        <OpenFiltersPanel tab={tabName} isModal />
      </Modal.Header>
      <Modal.Content className={modalContent}>
        {!isRawDealsDataLoading && dealsRawData && firstForecastDealsData && (
          // Sadly a horrible workaround, as we need to load the component subtree as is the one calling
          // the column data configuration, and i need this data to know if i have to call summary endpoint
          // so i need that call being done, but we don't want to show anything to the user
          // until i have all the necessary data. That's why we are rendering the component, but hidding it on the DOM
          <div hidden={isLoading} className={selectDealsTableWrapper}>
            <SelectDealsTable
              submissionSettingsId={submissionSettingsId}
              selectedDealsIds={selectedDealsIds}
              allDealsIds={allDealsIds}
              totalDeals={dealsIncluded.length + dealsExcluded.length}
              onSelectedDeals={handleSelectDeals}
              totalAmount={dealsRawData.total_amount}
              deals={parsedDeals}
              dealsCount={dealsRawData.count}
              firstForecastDealsData={firstForecastDealsData}
              callOptionType={callOptionType}
              onChange={handleDealChange}
              statuses={dealsEditionTracker}
              loading={false}
              viewMode={viewMode}
              hideIncludeColumn={hideIncludeColumn}
              userHasTeam={userHasTeam}
              recalculateButton={
                hasToRecalculate && (
                  <RecalculateSection
                    tooltipMessage={RECALCULATE_BUTTON_COPY}
                    onRecalculate={realTimeRecalculate}
                    recalculateDisabled={actionButtonsDisabled}
                  />
                )
              }
            />
          </div>
        )}
        {isLoading && <Loader active />}
      </Modal.Content>
      <Modal.Actions className={footer}>
        {viewMode ? (
          <TooltipWrapper
            tooltip={<p className={buttonTooltip}>{RECALCULATE_CLOSE_COPY}</p>}
            disable={!hasToRecalculate}
          >
            <BuButton
              secondary
              size={BuControlSize.REGULAR}
              onClick={internalOnClose}
              disabled={actionButtonsDisabled}
            >
              Close
            </BuButton>
          </TooltipWrapper>
        ) : (
          <>
            <TooltipWrapper
              tooltip={
                <p className={buttonTooltip}>{RECALCULATE_SAVE_COPY} </p>
              }
              disable={!hasToRecalculate}
            >
              <BuButton
                size={BuControlSize.REGULAR}
                onClick={internalOnSave}
                disabled={actionButtonsDisabled || hasToRecalculate}
              >
                Save
              </BuButton>
            </TooltipWrapper>
            <TooltipWrapper
              tooltip={
                <p className={buttonTooltip}>{RECALCULATE_CLOSE_COPY}</p>
              }
              disable={!hasToRecalculate}
            >
              <BuButton
                secondary
                size={BuControlSize.REGULAR}
                onClick={internalOnClose}
                disabled={actionButtonsDisabled}
              >
                Cancel
              </BuButton>
            </TooltipWrapper>
          </>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default ForecastDealsModal;
