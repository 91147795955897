import classNames from 'classnames';
import { css } from 'emotion';
import React from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';

const container = css`
  align-items: center;
  display: flex;
  height: 29px;

  img {
    margin: 0 auto;
  }
`;

const justifyCenter = css`
  justify-content: center;
`;

const justifyStart = css`
  justify-content: start;
`;

const justifyEnd = css`
  justify-content: end;
`;

const noDataIcon = css`
  font-size: 16px;
`;

const NotAvailableCell = ({
  placeholder,
  justify = 'center',
}: {
  placeholder?: string | number;
  justify?: 'start' | 'center' | 'end';
}) => (
  <div
    className={classNames(container, {
      [justifyCenter]: justify === 'center',
      [justifyStart]: justify === 'start',
      [justifyEnd]: justify === 'end',
    })}
  >
    {!!placeholder ? (
      placeholder
    ) : (
      <TooltipWrapper tooltip="No Data" position="bottom center">
        <div>
          <BuIcon
            name={BoostUpIcons.NoEventIcon}
            className={noDataIcon}
            color="var(--bu-red-400)"
          />
        </div>
      </TooltipWrapper>
    )}
  </div>
);

export default NotAvailableCell;
