import { css } from 'emotion';

import {
  fontDefaultDropdown,
  fontDefaultDropdownSubtitle,
} from 'assets/css/common';
import { fontBody, fontSubheading1 } from 'assets/css/fontStyles';
import { TimeFilterSizeProp } from 'components/UI/TimeFilter/types';

const sizeToWidth: { [size in TimeFilterSizeProp]: number } = {
  smaller: 140,
  small: 200,
  large: 280,
};

export const time_filter_wrapper = css`
  position: relative;
  margin-left: 10px;

  small {
    visibility: hidden;
  }

  &:hover {
    small {
      visibility: visible;
    }
  }
`;

export const time_filter_label = css`
  font-size: 12px;
  font-family: var(--bu-font-regular);
  color: #9b9b9b;
`;

export const time_filter_btn = (size: TimeFilterSizeProp) => css`
  display: flex;
  align-items: center;
  align-content: space-between;
  height: 100%;
  padding: 3px 10px;
  color: #4a4a4a;
  font-size: 14px;
  font-family: var(--bu-font-regular);
  border: 1px solid var(--bu-gray-300);
  border-radius: 4px;
  width: ${sizeToWidth[size]}px;
  cursor: pointer;
  transition: 0.4s;
  pointer-events: auto;
  background: transparent;
  height: 30px;

  img {
    width: 18px;
    height: 18px;
    margin-right: 9px;
  }

  i {
    margin-left: auto;
    padding: 0 2px;
    font-size: 7px;
    color: var(--bu-gray-800);
  }

  &.margin {
    margin-top: 15px;
  }

  &.active,
  &.open,
  &:hover {
    border-color: #0762ec;
    box-shadow: 0px 0px 0px 7px rgba(238, 247, 254, 1);
  }

  &:focus {
    outline: none;
  }

  &.disabled {
    pointer-events: none;
  }
`;

export const time_filter_dropdown = css`
  position: absolute;
  right: 0;
  top: 55px;
  display: block;
  min-width: 540px;
  border: 1px solid #ccc;
  z-index: 2000;
  background: var(--bu-white);
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
`;

export const time_filter_change_since_dropdown = css`
  position: absolute;
  top: 165px;
  display: block;
  max-width: 280px;
  border: 1px solid #ccc;
  z-index: 2000;
  background: var(--bu-white);
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
`;

export const time_filter_dropdown_wrap = css`
  width: 100%;
  height: 100%;
`;

export const time_filter_dropdown_tabs = css`
  width: 100%;
  height: 31px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: var(--bu-gray-200);
`;

export const time_filter_dropdown_tab = css`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--bu-gray-700);
  ${fontDefaultDropdown};
  border-bottom: 1px solid var(--bu-gray-300);

  &.borderRight {
    color: var(--bu-gray-900);
    border-color: var(--bu-white);
    background: var(--bu-white);
    border-right: 1px solid var(--bu-gray-300);
  }

  &.boderLeft {
    color: var(--bu-gray-900);
    border-color: var(--bu-white);
    background: var(--bu-white);
    border-left: 1px solid var(--bu-gray-300);
  }
`;

export const specific_time_periods = css`
  height: 100%;
  max-height: 400px;
  overflow-y: auto;
  width: 100%;
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
  // padding: 26px 24px; //--------------------------------------
  padding: 26px 14px;
`;

export const specific_time_periods_single = css`
  height: 100%;
  width: 100%;
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 5px 15px 25px;
`;

export const specific_time_periods_item = css`
  width: 100%;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }
`;

export const specific_time_period_title = css`
  color: var(--bu-gray-700);
  ${fontSubheading1}
  margin-bottom: 10px;
  cursor: default;
  padding: 0 6px;
`;

export const specific_time_period = css`
  width: 134px;
  padding: 3px;
`;

export const specific_time_period_item = css`
  ${fontBody}
  color: var(--bu-gray-900);
  padding: 8px 6px 8px 6px;
  cursor: pointer;
  transition: 0.4s color;
  border: 1px solid transparent;
  border-radius: 4px;
  margin-bottom: 2px;

  &:hover,
  &.selected {
    border-color: var(--bu-primary-500);
    background: rgba(49, 92, 121, 0.12);
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const custom_calendar_header = css`
  height: 60px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--bu-gray-500);
  padding: 12px 10px;

  display: grid;
  grid-template-columns: 1fr;
`;

export const custom_calendar_header__first_column = css`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const custom_title_range = css`
  color: var(--bu-gray-700);
  ${fontDefaultDropdownSubtitle};
`;

export const custom_input = css`
  border: 1px solid #ddd;
  padding: 6px 12px;
  ${fontDefaultDropdownSubtitle};
  height: 31px;
  width: 104px;
  margin: 0 8px;
  border-radius: 4px;
  transition: 0.4s;

  &:hover,
  &:focus {
    border-color: var(--bu-primary-500);
  }

  &.isValid {
    border-color: red;
  }

  &::placeholder {
    font-size: 11px;
  }
`;

export const tooltip = css`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -28px;
  display: block;
  padding: 5px 12px;
  color: var(--bu-white);
  font-family: var(--bu-font-regular);
  font-size: 10px;
  width: max-content;
  background: #4a4a4a;
  border-radius: 2px;
`;

export const double_calendar = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1px;
  position: relative;
  background: var(--bu-gray-500);
`;

export const message_warning = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 12px;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  background: #fffaf3;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 100;
  border-radius: 2px;
  color: #573a08;
  width: fit-content;

  p {
    font-size: 13px;
    margin-bottom: 8px;
  }
`;

export const message_warning_title = css`
  margin-bottom: 5px;
  font-size: 15px;
`;

export const message_warning_btn = css`
  cursor: pointer;
  padding: 2px 10px;
  cursor: pointer;
  transition: 0.4s;
  background: var(--bu-white);
  margin-top: 10px;

  &:hover {
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  }
`;

export const time_filter_btn_label = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 130px;
`;

export const time_filter_ft_wrapper = css`
  position: relative;
  margin-left: 10px;

  small {
    visibility: hidden;
  }

  &:hover {
    small {
      visibility: visible;
    }
  }
`;

export const time_filter_ft_btn = css`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 3px 10px;
  color: #4a4a4a;
  font-size: 14px;
  font-family: var(--bu-font-regular);
  border: 1px solid #d1d9db;
  border-radius: 4px;
  max-width: 270px;
  width: 270px;
  cursor: pointer;
  transition: 0.4s;
  pointer-events: auto;
  background: transparent;
  height: 30px;
  box-shadow: rgba(27, 31, 35, 0.05) 0px 1px 0px 0px;

  img {
    width: 18px;
    height: 18px;
    margin-right: 9px;
  }

  i {
    margin-left: auto;
    font-size: 7px;
    color: var(--bu-gray-800);
  }

  &.open,
  &:hover {
    background-color: var(--bu-primary-200);
    border-color: var(--bu-primary-500);
  }

  &:focus {
    outline: none;
  }

  &.disabled {
    pointer-events: none;
  }
`;

export const time_filter_ft_dropdown = css`
  position: absolute;
  right: 0;
  top: 40px;
  display: block;
  min-width: 380px;
  border: 1px solid var(--bu-gray-400);
  border-radius: 4px;
  z-index: 100;
  background: var(--bu-white);
  box-shadow: 0px 22px 24px #00000033;
`;

export const time_filter_ft_dropdown_wrap = css`
  width: 100%;
  height: 100%;
`;

export const tooltip_ft = css`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -28px;
  display: block;
  padding: 5px 12px;
  color: var(--bu-white);
  font-family: var(--bu-font-regular);
  font-size: 10px;
  z-index: 10;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  background: #4a4a4a;
  border-radius: 2px;
`;

export const time_filter_ft_label = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 220px;
`;

export const panel_header = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding: 0 24px;
  ${fontBody}
`;

export const custom_period_tab = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 320px;
  padding: 16px;
`;

export const custom_period_input = css`
  width: 30%;
`;

export const customWrapper = css`
  overflow: auto;
`;
