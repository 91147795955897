import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { actions } from 'actions';
import { createAdminAction, restoreAdmin } from 'actions/adminActions';
import LeftPanel from 'components/UI/Wrapper/LeftPanel/LeftPanel';
import {
  StateProps,
  DispatchProps,
} from 'components/UI/Wrapper/LeftPanel/types';
import { IReduxState } from 'reducers/types';
import {
  isAdmin,
  isImpersonate,
  isAdminEnabled,
  getCrmType,
  getUserRole,
  getAdmin,
  getCompanySettings,
} from 'selectors';

const mapStateToProps = (state: IReduxState): StateProps => {
  const companySettings = getCompanySettings(state);
  return {
    admin: getAdmin(state),
    crmType: getCrmType(state),
    isAdmin: isAdmin(state),
    isAdminEnabled: isAdminEnabled(state),
    isImpersonate: isImpersonate(state),
    userRole: getUserRole(state),
    isDashboardEnabled: companySettings.dashboard_enabled,
    isMeetingsDashboardEnabled:
      companySettings.meetings_dashboard_enabled ?? false,
  };
};

const mapDispatchToProps: DispatchProps = {
  resetTableParams: actions.ui.table.reset,
  restoreAdmin,
  updateAdminState: createAdminAction.updateState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LeftPanel));
