/* eslint-disable import/prefer-default-export */
import moment from 'moment';

import { MONTHS } from 'common/constants';
import { CHANGE_INTERVAL_RULES } from 'common/constants';

const calcFiscalMonthIndex = (
  firstFiscalMonth: number,
  currentFiscalMonth: number
) => {
  if (firstFiscalMonth === 1) {
    return currentFiscalMonth;
  }

  const index = firstFiscalMonth + currentFiscalMonth - 1;
  return index <= 12 ? index : index - 12;
};

/**
 * Calculates the name of the fiscal month by its ordinal number
 *
 * @param {number} firstFiscalMonth Sequential number of the first fiscal month
 * @param {number} currentFiscalMonth Sequential number of the selected fiscal month
 * @param {'full' | 'short'} [mode=full] Result display mode: full month name or abbreviated
 *
 * @returns {string} Name of the month
 */
export const calcFiscalMonthName = (
  firstFiscalMonth: number,
  currentFiscalMonth: number,
  mode: 'full' | 'short' = 'full'
) => {
  const index = calcFiscalMonthIndex(firstFiscalMonth, currentFiscalMonth);
  const result = MONTHS[index];

  return mode === 'full' || result.length < 5 ? result : result.substring(0, 3);
};

/**
 * Evaluates is the date is included into the period
 *
 * @param period abbr of the period
 * @param date date to evaluate
 * @returns true if the date is included on the period
 */
export const isDateInPeriod = (period: string, date: string) => {
  const dateToEvaluate = moment(date);
  const config = CHANGE_INTERVAL_RULES[period];
  const usingType = config[0].type === 'days' ? 'day' : config[0].type;
  const dateInf =
    config[0].type === 'quarter'
      ? moment().quarter(moment().quarter() + config[0].value)
      : moment().add(config[0].value, config[0].type);
  const dateSup =
    config[0].type === 'quarter'
      ? moment().quarter(moment().quarter() + config[1].value)
      : moment().add(config[1].value, config[1].type);

  return dateToEvaluate.isBetween(
    dateInf.startOf(usingType),
    dateSup.endOf(usingType)
  );
};

export const changeDateTimeZone = (
  date: Date,
  timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone
) =>
  new Date(
    date.toLocaleString('en-US', {
      timeZone,
    })
  );
