import classNames from 'classnames';
import React, { useState } from 'react';
import { Dimmer, Modal as SemanticModal } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import * as styles from 'components/UI/Modal/ModalThenaRequestsDetails/styles';
import { useSelector } from 'react-redux';
import * as selectors from 'selectors';
import { IReduxState } from 'reducers/types';
import { ThenaRequest } from 'components/UI/ProspectEngagement/types';
import moment from 'moment';

type Props = {
  onClose: () => void;
  params: { thenaRequestsId: string };
};

const ModalThenaRequestsDetails: React.FC<Props> = ({
  onClose,
  children,
  params = { thenaRequestsId: '' },
}) => {
  const [entityId, requestId] = params.thenaRequestsId.split('_');
  const thenaRequest: ThenaRequest = useSelector(
    (state: IReduxState) =>
      selectors
        .getEngagementAccountThenaRequests(state, entityId)
        .find((record: ThenaRequest) => record.request_id === requestId) || {}
  );
  const [exit, setExit] = useState(false);

  const closeHandler = () => {
    setExit(true);
    onClose();
  };

  if (exit) {
    return null;
  }

  return (
    <Dimmer
      active
      onClickOutside={closeHandler}
      className={classNames('page', 'modals')}
    >
      <div className={styles.box}>
        <div className={classNames(styles.header_modal, 'bu-font-heading')}>
          <div className={styles.header_modal_title}>
            <div>
              <BuIcon name={BoostUpIcons.TimelineCrmActivity} color="#222222" />
            </div>
            <div>
              <h1>
                {thenaRequest.web_link ? (
                  <a href={thenaRequest.web_link} target="_blank">
                    {thenaRequest.title}
                  </a>
                ) : (
                  thenaRequest.title
                )}
              </h1>
              <span>
                {moment(thenaRequest.created_at).format('Do MMM, YYYY, h:mma')}
              </span>
            </div>
          </div>
          <div className={styles.header_modal_close_box}>
            <span className={styles.closeButton} onClick={closeHandler}>
              <BuIcon name={BoostUpIcons.ClosePopup} />
            </span>
          </div>
        </div>
        <div className={styles.box_body}>
          <SemanticModal.Content scrolling className={styles.modalContent}>
            {children}
          </SemanticModal.Content>
        </div>
      </div>
    </Dimmer>
  );
};

export default ModalThenaRequestsDetails;
