import { connect } from 'react-redux';

import { setForecastDashboardFilter } from 'actions/forecastActions';
import TrackingDashboard from 'components/dashboard/ForecastDashboard/TrackingDashboard/TrackingDashboard';
import { StateProps } from 'components/dashboard/ForecastDashboard/TrackingDashboard/types';
import { IReduxState } from 'reducers/types';
import { getFiltersForAPI, getForecastDashboardFilters } from 'selectors';

const mapStateToProps = (state: IReduxState): StateProps => ({
  filters: {
    ...getFiltersForAPI(state, 'forecast_analytics'),
    ...getForecastDashboardFilters(state),
  },
});

const mapDispatchToProps = {
  setForecastDashboardFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackingDashboard);
