import * as t from 'actions/revbi/actionTypes';

export const mappingTakeEveryRevBISaga = [
  t.METRIC + t.GET,
  t.OBJECT_LIST + t.GET,
  t.OBJECT_LIST + t.TIME_SERIES + t.GET,
  t.METRIC + t.GET + t.ALL,
  t.METRIC_TIME_SERIES + t.GET + t.ALL,
  t.TIME_OPTIONS + t.GET,
  t.WIDGET + t.GET + t.ALL,
  t.FORECAST_PERIOD + t.GET + t.QUARTER,
  t.FORECAST_PERIOD + t.GET + t.MONTH,
  t.FORECAST_PERIOD + t.GET + t.WEEK,
];
