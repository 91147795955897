import { css } from 'emotion';

export const container = (width: number) => css`
  display: flex;
  flex: 1;

  input {
    width: ${width}px;
  }
`;

export const text_input = css`
  width: 100%;
  margin-left: 5px;

  input {
    cursor: text;
  }
`;

export const input_error = css`
  border-color: var(--bu-red-400) !important;

  input {
    background-color: var(--bu-red-100);
    color: var(--bu-red-600);
    box-shadow: none !important;
  }
`;
