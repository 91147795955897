import { produce } from 'immer';
import * as R from 'ramda';
import { combineReducers } from 'redux';

import * as t from 'actions/actionTypes';
import { TIME_PERIODS, CALLS_STATS_CHART_IDS } from 'common/constants';

const defaultCallsGraphState = {
  [CALLS_STATS_CHART_IDS.CALLS]: 'loaded',
  [CALLS_STATS_CHART_IDS.EVENTS]: 'loaded',
};
const callsGraphState = produce((draft, action) => {
  switch (action.type) {
    case t.CALLS_STATS + t.GET + t.LOADING:
      draft[action.graphId] = 'loading';
      break;
    case t.CALLS_STATS + t.GET + t.SUCCESS:
      draft[action.graphId] = 'loaded';
      break;
    case t.CALLS_STATS + t.GET + t.ERROR:
      draft[action.graphId] = 'error';
      break;
    default:
  }
}, defaultCallsGraphState);

const defaultCallsGraphData = {};
const callsGraphData = produce((draft, action) => {
  switch (action.type) {
    case t.CALLS_STATS + t.GET + t.SUCCESS:
      draft[action.graphId] = action.payload.data;
      break;
    default:
  }
}, defaultCallsGraphData);

const defaultCallsGraphLocalFilters = {
  [CALLS_STATS_CHART_IDS.CALLS]: {
    type: 'calls',
    status: 'all',
  },
  [CALLS_STATS_CHART_IDS.EVENTS]: {
    type: 'events',
  },
};
const callsGraphLocalFilters = produce((draft, action) => {
  switch (action.type) {
    case t.CALLS_STATS + t.CHANGE + t.LOCAL + t.FILTER:
      draft[action.graphId] = R.merge(draft[action.graphId], action.changes);
      break;
    default:
  }
}, defaultCallsGraphLocalFilters);

const defaultCallsGraphGlobalFilters = {
  period: TIME_PERIODS.LAST_3_MONTHS,
  users: [],
};
const callsGraphGlobalFilters = produce((draft, action) => {
  switch (action.type) {
    case t.CALLS_STATS + t.CHANGE + t.GLOBAL + t.FILTER:
      Object.assign(draft, action.changes);
      break;
    default:
  }
}, defaultCallsGraphGlobalFilters);

const callsMentionsQuery = (state = '', action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const defaultCallMentionsKeywords = [];
const callsMentionsKeywords = (state = defaultCallMentionsKeywords, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const defaultCallsMentionsStats = {};
const callsMentionsStats = (state = defaultCallsMentionsStats, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const defaultCallsMentionsStatsState = 'loaded';
const callsMentionsStatsState = (
  state = defaultCallsMentionsStatsState,
  action
) => {
  switch (action.type) {
    default:
      return state;
  }
};

const defaultCallsMentionsCalls = [];
const callsMentionsCalls = (state = defaultCallsMentionsCalls, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const callsMentionsCallsCount = (state = null, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const defaultCallsMentionsCallsState = 'loaded';
const callsMentionsCallsState = (
  state = defaultCallsMentionsCallsState,
  action
) => {
  switch (action.type) {
    default:
      return state;
  }
};

const defaultCallsMentionsTopCompetitors = [];
const callsMentionsTopCompetitors = (
  state = defaultCallsMentionsTopCompetitors,
  action
) => {
  switch (action.type) {
    default:
      return state;
  }
};

const defaultCallsMentionsTopCompetitorsState = 'loaded';
const callsMentionsTopCompetitorsState = (
  state = defaultCallsMentionsTopCompetitorsState,
  action
) => {
  switch (action.type) {
    default:
      return state;
  }
};

const defaultCallsMentionsTopTopics = [];
const callsMentionsTopTopics = (
  state = defaultCallsMentionsTopTopics,
  action
) => {
  switch (action.type) {
    default:
      return state;
  }
};

const defaultCallsMentionsTopTopicsState = 'loaded';
const callsMentionsTopTopicsState = (
  state = defaultCallsMentionsTopTopicsState,
  action
) => {
  switch (action.type) {
    default:
      return state;
  }
};

const defaultCallsMentionsAllCompetitors = [];
const callsMentionsAllCompetitors = (
  state = defaultCallsMentionsAllCompetitors,
  action
) => {
  switch (action.type) {
    default:
      return state;
  }
};

const callsMentionsAllCompetitorsCount = (state = null, action) => {
  switch (action.type) {
    case t.CALLS_STATS + t.ALL_COMPETITORS + t.CLEAR:
      return null;
    default:
      return state;
  }
};

const defaultCallsMentionsAllCompetitorsState = 'loaded';
const callsMentionsAllCompetitorsState = (
  state = defaultCallsMentionsAllCompetitorsState,
  action
) => {
  switch (action.type) {
    default:
      return state;
  }
};

const defaultCallsMentionsAllTopics = [];
const callsMentionsAllTopics = (
  state = defaultCallsMentionsAllTopics,
  action
) => {
  switch (action.type) {
    default:
      return state;
  }
};

const callsMentionsAllTopicsCount = (state = null, action) => {
  switch (action.type) {
    case t.CALLS_STATS + t.ALL_TOPICS + t.CLEAR:
      return null;
    default:
      return state;
  }
};

const defaultCallsMentionsAllTopicsState = 'loaded';
const callsMentionsAllTopicsState = (
  state = defaultCallsMentionsAllTopicsState,
  action
) => {
  switch (action.type) {
    default:
      return state;
  }
};

const callsMentionsShowFilters = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const defaultCallMentionsFilters = {
  sellers: ['__all__'],
  stages: ['__all__'],
  my_tags: ['__all__'],
  other_tags: ['__all__'],
  topics: ['__all__'],
  competitors: ['__all__'],
  call_duration: ['__all__'],
  number_attendees: ['__all__'],
  completed_dates: ['this_month'],
};
const callsMentionsFilters = (state = defaultCallMentionsFilters, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const callsMentionsScroll = (state = null, action) => {
  switch (action.type) {
    case t.CALLS_STATS + t.MENTIONS + t.SCROLL + t.CHANGE:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  callsGraphState,
  callsGraphData,
  callsGraphLocalFilters,
  callsGraphGlobalFilters,
  callsMentionsQuery,
  callsMentionsKeywords,
  callsMentionsStats,
  callsMentionsStatsState,
  callsMentionsCalls,
  callsMentionsCallsCount,
  callsMentionsCallsState,
  callsMentionsTopCompetitors,
  callsMentionsTopCompetitorsState,
  callsMentionsTopTopics,
  callsMentionsTopTopicsState,
  callsMentionsAllCompetitors,
  callsMentionsAllCompetitorsState,
  callsMentionsAllCompetitorsCount,
  callsMentionsAllTopics,
  callsMentionsAllTopicsState,
  callsMentionsAllTopicsCount,
  callsMentionsShowFilters,
  callsMentionsFilters,
  callsMentionsScroll,
});
