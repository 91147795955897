import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { Action } from 'typescript-fsa';
import { DrilldownTableSettings } from '../contexts/RevBISettingsContext';
import {
  PIVOT_HIERARCHY_SEPARATOR,
  isExpandableRow,
} from './hooks/useExpandableWidgetTable/useExpandableWidgetTable.helper';

import { actions } from 'actions';
import { WidgetColumnConfiguration } from 'api/RevBiWidget';
import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import {
  getMetricType,
  isBIMetricSimple,
} from 'components/dashboard/Metrics/Create/utils';
import {
  CELL_EMPTY_VALUE,
  SUBTOTAL,
} from 'components/dashboard/Metrics/Widget/Table/constants';
import { SUPPORTED_DRILLDOWN_OBJECTS } from 'components/dashboard/Metrics/Widget/constants';
import {
  getChangeIntervalForMetric,
  getMetricsNameAndValueUsedInFormula,
  getSyntheticMetricData,
  getWidgetMetricDisplayNameMap,
  getYValueFromRow,
  openSyntheticMetricDetailsModal,
  parseFormulaToMetricsIdArray,
  removeEmptyProps,
} from 'components/dashboard/Metrics/Widget/helper';
import {
  DataObject,
  SelectedChartValue,
  SyntheticMetricDataWidgetState,
} from 'components/dashboard/Metrics/Widget/types';
import {
  ACCOUNT,
  BUSINESS_TYPES_FIELD_NAMES,
  CALENDAR_EVENT,
  EMAIL,
  FORECAST_SUBMISSION,
  FORECAST_SUBMISSION_OBJECTS,
  OPPORTUNITY_SPLIT,
  TARGET,
} from 'components/dashboard/Metrics/constants';
import {
  MetricType,
  VisualizationType,
} from 'components/dashboard/Metrics/enums';
import {
  BIDashboardSettings,
  BIMetricCreated,
  BIMetricFormula,
  BIMetricSimple,
  BIMetricUnion,
  BIPivotDescriptor,
  BIWidget,
  DrillDownFilter,
  DrillDownParams,
  SelectedValue,
} from 'components/dashboard/Metrics/metrics.types';
import { PersistQueryParams } from 'navigation/types';
import { openModal } from 'navigation/utils';
import { fetchApi } from 'utils/network';
import { HierarchicalWidget } from './hooks/useHierarchicalWidgetFetching/useHierarchicalWidgetFetching.helper';
import {
  CUSTOM_OBJECT_TABLE_MODAL_SCHEME,
  OpenRevBiDrilldownModal,
} from './hooks/useRevBiDrilldownModal';

const DRILL_DOWN_ERROR_MESSAGE = 'Failed to load drill down table data';

type StringUndefined = string | undefined;

const getPivot1ValueFromRow = (
  extraHeader: string | undefined,
  row: IRow,
  pivot1Column: WidgetColumnConfiguration | undefined
): string => {
  if (extraHeader) return extraHeader;

  const value = row[pivot1Column?.field_name ?? ''] as string;

  if (value === CELL_EMPTY_VALUE || value === SUBTOTAL) {
    return (row['pivot1Value'] ?? '') as string;
  }

  return value;
};

// TODO review this function, it seems was only to handle v2 data
// So it can be removed
const getDrillDownFilters = (
  widget: Partial<BIWidget>,
  extraHeader: string | undefined,
  row: IRow,
  clickedMetric: BIMetricCreated
): DrillDownFilter[] => {
  if (!isExpandableRow(row)) {
    console.error('No Expandable Row Error');
  }

  let pivotValuesArray =
    (row?.nodePath as string)?.split(PIVOT_HIERARCHY_SEPARATOR) ?? [];
  if (
    widget.group_by?.length &&
    extraHeader &&
    widget.group_by[0].type === 'date'
  ) {
    pivotValuesArray = [extraHeader as string, ...pivotValuesArray];
  }
  const groupBy = widget.group_by?.slice(0, pivotValuesArray.length) ?? [];
  return groupBy.map((column, idx) => ({
    column:
      // this condition should be removed ones we remove the date pivot supports.
      column.type === 'date' && widget.time_field
        ? widget.time_field
        : column.type === 'date' &&
          isBIMetricSimple(clickedMetric) &&
          clickedMetric.date_field
        ? clickedMetric.date_field
        : column,
    operator: 'eq',
    value: (pivotValuesArray[idx] as string) ?? '',
  }));
};

const getBusinessTypeNameFromMetricFilters = (
  metric: BIMetricCreated
): string => {
  let businessTypeName = '';
  metric?.filters?.forEach((f) => {
    const condition = f.and_condition?.[0].or_condition?.[0];
    if (BUSINESS_TYPES_FIELD_NAMES.has(condition?.column?.name)) {
      businessTypeName = (condition.value as string[])?.[0];
    }
  });

  return businessTypeName;
};

export const createDrillDownParams = (
  clickedMetric: BIMetricCreated,
  drillDownFilters: DrillDownFilter[],
  widgetFiltersBusinessType: string,
  widget: BIWidget,
  dashboardSettings?: BIDashboardSettings
): DrillDownParams => {
  const businessTypeName =
    getBusinessTypeNameFromMetricFilters(clickedMetric) ||
    widgetFiltersBusinessType;
  const dashboardFilters =
    widget.dashboard_filters?.filter(
      (elem) =>
        // Remove closing_in filter as is only supported on deltas
        elem.operator !== 'all' && elem.column.name !== 'shared.__changes_since'
    ) ?? [];

  // add comments
  const { user_alias_for_filtering = '', user_visibility_name = '' } =
    widget.advanced_configurations ?? {};

  const widgetSettings = {
    user_alias_for_filtering,
    user_visibility_name,
  };

  const widgetSettingsIsEmpty = !Object.values(widgetSettings).some(
    (value) => !!value
  );

  const hasUserVisibilityName =
    !!widget.advanced_configurations?.user_visibility_name;
  return {
    drill_down_widget_settings: {
      ...(!widgetSettingsIsEmpty ? removeEmptyProps(widgetSettings) : {}),
    },
    filters: clickedMetric?.filters ?? [],
    template_filters: widget.template_filters ?? [],
    dashboard_filters: dashboardFilters,
    drill_down_filters: drillDownFilters,
    order_by: [],
    offset: 0,
    // backend suppose to return all the rows, this is
    // a tmp fix so that the ui get all the records
    // api will make limit optional later
    limit: 1000000,
    duration:
      widget.group_by[0]?.type === 'date' ? widget.group_by[0].name : '',
    skip_business_validation: !(!!businessTypeName && !hasUserVisibilityName),
    ...(businessTypeName && {
      business_type_name: businessTypeName,
    }),
    manager_aggregation_type: (clickedMetric as BIMetricSimple)
      .manager_aggregation_type,
    is_cumulative_sum: (clickedMetric as BIMetricSimple).is_cumulative_sum,
    ...((clickedMetric as BIMetricSimple).is_cumulative_sum && {
      cumulative_sum_period: (clickedMetric as BIMetricSimple)
        .cumulative_sum_period,
    }),
    include_disabled_users: dashboardSettings?.userFilter === 'all',
  };
};

const fetchRecordsAndOpenDrillDownModal = (
  dashboardSettings: BIDashboardSettings | undefined,
  drilldownParams: DrillDownParams,
  metricObject: string | undefined,
  metricName: string,
  dispatch: Dispatch<Action<PersistQueryParams>>,
  openDrillDownModal: OpenRevBiDrilldownModal,
  dashboardId?: string,
  widgetId?: string,
  hasUserVisibilityName: boolean = false,
  changeInterval?: string
): void => {
  if (metricObject && SUPPORTED_DRILLDOWN_OBJECTS.includes(metricObject)) {
    //this is the first dashboard setting, but in case we add one more, we should rethink this.
    //?user_status=active
    let querySetting = '';
    if (dashboardSettings) {
      querySetting = `?user_status=${dashboardSettings.userFilter}`;
    }

    if (metricObject === OPPORTUNITY_SPLIT) {
      openModal({
        scheme: '/opportunity-split',
        persistParams: {
          apiUrl: `/api/settings/tables/opportunity_split/RevbiOpportunitySplit`,
          filters: [],
          title: metricName,
          showTotalAmount: false,
          metricObject,
          metricName,
          drilldownParams,
          dashboardId,
          widgetId,
        },
        persistor: (params: PersistQueryParams) => {
          dispatch(actions.ui.modal.persist(params));
        },
      });
    } else if ([FORECAST_SUBMISSION].includes(metricObject)) {
      fetchApi<DrillDownParams, { data: string[] }>({
        url: `/rev_bi/drill_down/${metricObject}${querySetting}`,
        queryMethod: 'post',
        queryParams: drilldownParams,
        setData: ({ data = [] }) => {
          if (data.length) {
            const submissionId = data[0];
            let businessType: string = '';
            let forecastName: string = '';
            let submissionCreatedAt: string = '';
            let submissionCreatedBy: string = '';
            drilldownParams.filters.forEach((filter) => {
              filter.and_condition?.forEach((f) => {
                f.or_condition?.forEach((g) => {
                  if (g.column.name === 'shared.business_type') {
                    businessType = g.value as string;
                  }
                  if (g.column.name === 'forecast_submission.forecast_name') {
                    forecastName = g.value as string;
                  }
                });
              });
            });

            drilldownParams.drill_down_filters.forEach((f) => {
              if (f.column.name === 'forecast_submission.created_at') {
                submissionCreatedAt = f.value as string;
              }
              if (f.column.name === 'user.name') {
                submissionCreatedBy = f.value as string;
              }
            });

            const title = `${submissionCreatedBy} included deals - ${businessType} ${forecastName}  - Interval: ${submissionCreatedAt}`;

            openModal({
              scheme: '/deals/included/:submissionId',
              params: {
                submissionId,
                localStorageKeyPrefix: 'RevBiDealsIncluded',
              },
              persistParams: {
                apiUrl: `/api/data/forecast/submission/deals_data_history/${submissionId}`,
                title: title,
                isHistoricalSubmissionsModal: true,
                filterIncludedTogglerInitialState: 'on',
              },
              persistor: (params: PersistQueryParams) =>
                dispatch(actions.ui.modal.persist(params)),
            });
          }
        },
        setError: (_: string | null) => {
          toast.error('Failed to load drill down table data');
        },
      });
    } else {
      fetchApi<DrillDownParams, { data: string[] }>({
        url: `/rev_bi/drill_down/${metricObject}${querySetting}`,
        queryMethod: 'post',
        queryParams: drilldownParams,
        setData: (result) => {
          const data = result.data ?? [];

          const modalSchema = metricObject === ACCOUNT ? '/accounts' : '/deals';

          if (data.length) {
            if (modalSchema === '/accounts') {
              openDrillDownModal({
                scheme: '/revbi-account-modal',
                props: {
                  ids: data.map((id) => id),
                  title: metricName,
                  dashboardId,
                  widgetId,
                },
              });
            } else {
              openDrillDownModal({
                scheme: '/revbi-deals-modal',
                props: {
                  ids: data.map((id) => id),
                  title: metricName,
                  // if the widget has bt filter and no CS dashboard should be false
                  // else true
                  skip_business_validation: !(
                    !!drilldownParams.business_type_name &&
                    !hasUserVisibilityName
                  ),
                  ...(drilldownParams.business_type_name && {
                    business_type_name: drilldownParams.business_type_name,
                  }),
                  dashboardId,
                  widgetId,
                  changeInterval,
                },
              });
            }
          } else {
            toast.error('No data available');
          }
        },
        setError: (_: string | null) => {
          toast.error('Failed to load drill down table data');
        },
      });
    }
  }
};

const fetchEventsAndOpenModal = (
  drilldownParams: DrillDownParams,
  dispatch: Dispatch<Action<PersistQueryParams>>
): void => {
  fetchApi<DrillDownParams, { data: string[] }>({
    url: `/rev_bi/drill_down/calendar_event`,
    queryMethod: 'post',
    queryParams: drilldownParams,
    setData: (result) => {
      if (result.data.length) {
        openModal({
          scheme: '/events',
          persistParams: {
            apiUrl: `/api/data/events/`,
            filters: JSON.stringify({ ids: result.data.map((id) => id) }),
          },
          persistor: (params: PersistQueryParams) => {
            dispatch(actions.ui.modal.persist(params));
          },
        });
      } else {
        toast.error('No data available');
      }
    },
    setError: () => toast.error(DRILL_DOWN_ERROR_MESSAGE),
  });
};

const fetchEmailsAndOpenModal = (
  drilldownParams: DrillDownParams,
  dispatch: Dispatch<Action<PersistQueryParams>>
): void => {
  fetchApi<DrillDownParams, { data: string[] }>({
    url: `/rev_bi/drill_down/email`,
    queryMethod: 'post',
    queryParams: drilldownParams,
    setData: (result) => {
      const data = result.data ?? [];

      if (data.length) {
        openModal({
          scheme: '/emails',
          persistParams: {
            apiUrl: `/api/data/emails/`,
            title: 'Emails',
            filters: JSON.stringify({ ids: data.map((id) => id) }),
          },
          persistor: (params: PersistQueryParams) => {
            dispatch(actions.ui.modal.persist(params));
          },
        });
      } else {
        toast.error('No data available');
      }
    },
    setError: () => toast.error(DRILL_DOWN_ERROR_MESSAGE),
  });
};

export const openDrillDownModal = (
  widget: BIWidget,
  selectedValue: SelectedChartValue,
  clickedMetric: BIMetricCreated,
  widgetFiltersBusinessType: string,
  revbiEmailsDrilldownEnabled: boolean,
  revbiEventsDrilldownEnabled: boolean,
  dashboardSettings: BIDashboardSettings | undefined,
  treeWidget: HierarchicalWidget | undefined,
  availablePivots: number,
  pivot1: BIPivotDescriptor | undefined,
  pivot2: BIPivotDescriptor | undefined,
  metricChartType: VisualizationType,
  dispatch: Dispatch<Action<PersistQueryParams>>,
  openRevBiDrilldownModal: OpenRevBiDrilldownModal,
  hierarchyAlias: string[],
  // TODO remove `?` and send it as a required parameter

  // For now only used in the case of custom object drilldown
  drilldownTableSettings?: Record<string, DrilldownTableSettings>
) => {
  const drillDownFilters = (widget.group_by ?? []).reduce(
    (accumulator: DrillDownFilter[], column, idx) => {
      if (idx === 0 && selectedValue.pivot1Id) {
        accumulator.push({
          column:
            // this condition should be removed ones we remove the date pivot supports.
            column.type === 'date' && widget.time_field
              ? widget.time_field
              : column.type === 'date' &&
                isBIMetricSimple(clickedMetric) &&
                clickedMetric.date_field
              ? clickedMetric.date_field
              : column,
          operator: 'eq',
          value: selectedValue.pivot1Id,
        });
      }

      if (
        idx === 1 &&
        (selectedValue.pivot2Id ||
          (hierarchyAlias.includes(column.name) &&
            selectedValue.name &&
            // Not a greatest solution, but the condition above is not precise
            // as it allows to pass when it is an single pivot visualization
            metricChartType !== VisualizationType.Column &&
            metricChartType !== VisualizationType.Line))
      ) {
        accumulator.push({
          column:
            column.type === 'date' && widget.time_field
              ? widget.time_field
              : column,
          operator: 'eq',
          value: selectedValue.pivot2Id || selectedValue.name || '',
        });
      }

      return accumulator;
    },
    []
  );

  const metricDisplayNames = getWidgetMetricDisplayNameMap(widget);

  const drilldownParams: DrillDownParams = createDrillDownParams(
    clickedMetric,
    drillDownFilters,
    widgetFiltersBusinessType,
    widget,
    dashboardSettings
  );

  if (
    (clickedMetric as BIMetricSimple).object === 'email' &&
    revbiEmailsDrilldownEnabled
  ) {
    fetchEmailsAndOpenModal(drilldownParams, dispatch);
  }

  if (
    (clickedMetric as BIMetricSimple).object === 'calendar_event' &&
    revbiEventsDrilldownEnabled
  ) {
    fetchEventsAndOpenModal(drilldownParams, dispatch);
  }

  if (
    clickedMetric.metadata?.type === 'formula' &&
    (clickedMetric as BIMetricFormula).synthetic_metric
  ) {
    const parsedClickedMetricFormulaAsArray: string[] =
      parseFormulaToMetricsIdArray(
        (clickedMetric as BIMetricFormula).synthetic_metric
      );

    const syntheticMetricData: SyntheticMetricDataWidgetState =
      getSyntheticMetricData(
        parsedClickedMetricFormulaAsArray,
        clickedMetric as BIMetricFormula
      );

    let dataRow: DataObject = {};

    const findDeeperOnePivot = (
      data: any[] | undefined = [],
      field: string,
      value: StringUndefined
    ): any => {
      let rst;
      for (let index = 0; index < data.length && !rst; index++) {
        const element = data[index];

        if (element[field] === value) {
          rst = element;
        } else if (element?.children?.length) {
          rst = findDeeperOnePivot(element.children, field, value);
        }
      }
      return rst;
    };
    const findDeeperTwoPivot = (
      data: any[] | undefined = [],
      field1: string,
      value1: StringUndefined,
      field2: string,
      value2: StringUndefined
    ): any => {
      let rst;
      for (let index = 0; index < data.length && !rst; index++) {
        const element = data[index];
        const pivot2Data = findDeeperOnePivot(element.pivots, field2, value2);
        if (element[field1] === value1 && pivot2Data) {
          rst = pivot2Data;
        } else if (element?.children?.length) {
          rst = findDeeperTwoPivot(
            element.children,
            field1,
            value1,
            field2,
            value2
          );
        }
      }
      return rst;
    };

    // this is a hotfix for 2.36
    const transformTimePeriod = (key: string) => {
      if (key.includes('QoQ')) {
        return 'quarter';
      }

      if (key.includes('MoM')) {
        return 'month';
      }

      return 'week';
    };

    if (availablePivots === 1 && treeWidget?.tree) {
      const pivot1Id = selectedValue.pivot1Id;
      let key = pivot1?.name?.replaceAll('.', '$') || '';

      /**
       * for some reason the BE needs to use the time series and change the attr in the response,
       * that is why we need to translate the name based if the type is timePeriod
       */
      if (pivot1?.type === 'timePeriod') {
        key = transformTimePeriod(key);
      }
      dataRow = findDeeperOnePivot(treeWidget?.tree, key, pivot1Id);
    } else if (availablePivots === 2 && treeWidget?.tree) {
      const pivot1Id = selectedValue.pivot1Id;
      let pivot1Key = pivot1?.name?.replaceAll('.', '$') || '';
      let pivot2Id = selectedValue.pivot2Id;
      const pivot2Key = pivot2?.name?.replaceAll('.', '$') || '';

      /**
       * for some reason the BE needs to use the time series and change the attr in the response,
       * that is why we need to translate the name based if the type is timePeriod
       */
      if (pivot1?.type === 'timePeriod') {
        pivot1Key = transformTimePeriod(pivot1Key);
      }
      /**
       * when the pivot 2 is metric, we can't use the pivot2.name because the data is grouped by user.
       */
      if (pivot2Key === 'user$name' && !pivot2Id) {
        pivot2Id = selectedValue.name as string;
      }

      // Even if it has 2 pivots, if pivot2 has no info, get pivot 1
      dataRow =
        pivot2Id && pivot2Key
          ? findDeeperTwoPivot(
              treeWidget.tree,
              pivot1Key,
              pivot1Id,
              pivot2Key,
              pivot2Id
            )
          : findDeeperOnePivot(treeWidget?.tree, pivot1Key, pivot1Id);
    }

    openSyntheticMetricDetailsModal(
      clickedMetric.name || 'Drill Down',
      drilldownParams,
      syntheticMetricData,
      {
        metricsValues: getMetricsNameAndValueUsedInFormula(
          parsedClickedMetricFormulaAsArray,
          dataRow,
          metricDisplayNames
        ),
        pivot1Id: selectedValue.pivot1Id,
        pivot2Id: selectedValue.pivot2Id,
        y: selectedValue.metricId && dataRow[selectedValue.metricId],
      },
      openRevBiDrilldownModal,
      drilldownTableSettings
    );
  } else {
    if (
      isBIMetricSimple(clickedMetric) &&
      drilldownTableSettings &&
      Object.keys(drilldownTableSettings).includes(clickedMetric.object) &&
      openRevBiDrilldownModal
    ) {
      const tableConfig = drilldownTableSettings[clickedMetric.object];

      handleOpenCustomObjectModal(
        drilldownParams,
        (clickedMetric as BIMetricSimple).object,
        clickedMetric.name,
        tableConfig,
        openRevBiDrilldownModal
      );

      return;
    }
    fetchRecordsAndOpenDrillDownModal(
      dashboardSettings,
      drilldownParams,
      (clickedMetric as BIMetricSimple).object,
      clickedMetric.name,
      dispatch,
      openRevBiDrilldownModal
    );
  }
};

export const openTotalDrilldownModal = (
  clickedMetric: BIMetricCreated,
  drilldownParams: DrillDownParams,
  dispatch: Dispatch<Action<PersistQueryParams>>,
  openRevBiDrilldownModal: OpenRevBiDrilldownModal,
  // For now only used in the case of custom object drilldown
  drilldownTableSettings?: Record<string, DrilldownTableSettings>,
  dashboardSettings?: BIDashboardSettings
) => {
  if (
    isBIMetricSimple(clickedMetric) &&
    drilldownTableSettings &&
    Object.keys(drilldownTableSettings).includes(clickedMetric.object) &&
    openRevBiDrilldownModal
  ) {
    const tableConfig = drilldownTableSettings[clickedMetric.object];

    handleOpenCustomObjectModal(
      drilldownParams,
      (clickedMetric as BIMetricSimple).object,
      clickedMetric.name,
      tableConfig,
      openRevBiDrilldownModal
    );

    return;
  }

  fetchRecordsAndOpenDrillDownModal(
    dashboardSettings,
    drilldownParams,
    (clickedMetric as BIMetricSimple).object,
    clickedMetric.name,
    dispatch,
    openRevBiDrilldownModal
  );
};

export const handleOpenCustomObjectModal = (
  drilldownParams: DrillDownParams,
  metricObject: string | undefined,
  metricName: string,
  tableConfig: DrilldownTableSettings,
  openRevBiDrilldownModal: OpenRevBiDrilldownModal,
  dashboardId?: string,
  widgetId?: string
): void => {
  openRevBiDrilldownModal({
    scheme: CUSTOM_OBJECT_TABLE_MODAL_SCHEME,
    props: {
      apiUrl: tableConfig.url,
      drilldownParams,
      filters: JSON.stringify({
        skip_business_validation: drilldownParams.business_type_name
          ? false
          : true,
        ...(drilldownParams.business_type_name && {
          business_type_name: drilldownParams.business_type_name,
        }),
      }),
      title: tableConfig.table_display_name,
      metricObject: metricObject ?? '',
      metricName,
      dashboardId,
      widgetId,
    },
  });
};

export const openTableDrillDownModal = (
  clickedMetric: BIMetricCreated,
  column: IColumn,
  dashboardSettings: BIDashboardSettings | undefined,
  pivot1FieldName: string,
  revbiEmailsDrilldownEnabled: boolean,
  revbiEventsDrilldownEnabled: boolean,
  row: IRow,
  widget: BIWidget,
  widgetFiltersBusinessType: string,
  dispatch: Dispatch<Action<PersistQueryParams>>,
  openRevBiDrilldownModal: OpenRevBiDrilldownModal,

  // For now only used in the case of custom object drilldown
  drilldownTableSettings?: Record<string, DrilldownTableSettings>,
  dashboardId?: string
): void => {
  const drillDownFilters = getDrillDownFilters(
    widget,
    column.extraHeader,
    row,
    clickedMetric
  );

  const drilldownParams: DrillDownParams = createDrillDownParams(
    clickedMetric as BIMetricCreated,
    // If it is a subtotal row then we send only the first pivot value
    row.isSubtotal ? [drillDownFilters[0]] : drillDownFilters,
    widgetFiltersBusinessType,
    widget,
    dashboardSettings
  );

  const metricDisplayNames = getWidgetMetricDisplayNameMap(widget);
  const changeInterval = getChangeIntervalForMetric(widget, clickedMetric);

  if (
    (clickedMetric as BIMetricSimple).object === EMAIL &&
    revbiEmailsDrilldownEnabled
  ) {
    fetchEmailsAndOpenModal(drilldownParams, dispatch);
  }

  if (
    (clickedMetric as BIMetricSimple).object === CALENDAR_EVENT &&
    revbiEventsDrilldownEnabled
  ) {
    fetchEventsAndOpenModal(drilldownParams, dispatch);
  }

  if (
    clickedMetric.metadata?.type === 'formula' &&
    (clickedMetric as BIMetricFormula).synthetic_metric
  ) {
    const parsedClickedMetricFormulaAsArray: string[] =
      parseFormulaToMetricsIdArray(
        (clickedMetric as BIMetricFormula).synthetic_metric
      );

    const syntheticMetricData: SyntheticMetricDataWidgetState =
      getSyntheticMetricData(
        parsedClickedMetricFormulaAsArray,
        clickedMetric as BIMetricFormula
      );

    const selectedValue: SelectedValue = {
      y: getYValueFromRow(row, clickedMetric, column),
      metricsValues: getMetricsNameAndValueUsedInFormula(
        parsedClickedMetricFormulaAsArray,
        row,
        metricDisplayNames,
        column.extraHeader
      ),
    };

    if (pivot1FieldName) {
      // When date is first pivot, we loose the pivot1 value
      // As we flatten the first 2 levels of the tree
      // TO display first pivot as extra column
      // So correct first pivot label in case of date pivot
      // Will be present on the column extra header field
      // If it is not present, then we can safely get the value from the row
      selectedValue.pivot1Id = column.extraHeader
        ? column.extraHeader
        : (row[pivot1FieldName] as string);
    }

    openSyntheticMetricDetailsModal(
      clickedMetric.name || 'Drill Down',
      drilldownParams,
      syntheticMetricData,
      selectedValue,
      openRevBiDrilldownModal,
      drilldownTableSettings,
      changeInterval
    );
  } else {
    if (
      isBIMetricSimple(clickedMetric) &&
      drilldownTableSettings &&
      Object.keys(drilldownTableSettings).includes(clickedMetric.object) &&
      openRevBiDrilldownModal
    ) {
      const tableConfig = drilldownTableSettings[clickedMetric.object];

      handleOpenCustomObjectModal(
        drilldownParams,
        (clickedMetric as BIMetricSimple).object,
        clickedMetric.name,
        tableConfig,
        openRevBiDrilldownModal,
        dashboardId,
        widget._id
      );

      return;
    }

    fetchRecordsAndOpenDrillDownModal(
      dashboardSettings,
      drilldownParams,
      (clickedMetric as BIMetricSimple).object,
      clickedMetric.name,
      dispatch,
      openRevBiDrilldownModal,
      dashboardId,
      widget._id,
      !!widget.advanced_configurations?.user_visibility_name,
      changeInterval
    );
  }
};

export const checkMetricValidity = (metric: BIMetricUnion): boolean => {
  const metricType = getMetricType(metric);

  if (metricType === MetricType.Formula) {
    // Formulas need synthetic_metric present to be valid
    if ((metric as BIMetricFormula)?.synthetic_metric) {
      return true;
    }

    return false;
  }

  if (metricType === MetricType.Simple) {
    // Targets are valid by default
    if ((metric as BIMetricSimple)?.object === TARGET) {
      return true;
    }

    // Forecast submission objects
    if (
      FORECAST_SUBMISSION_OBJECTS.includes((metric as BIMetricSimple)?.object)
    ) {
      return true;
    }

    // Valid aggregation function is count (no need for column)
    // or it has column and aggregation function
    if (
      (metric as BIMetricSimple)?.aggregation_function === 'count' ||
      ((metric as BIMetricSimple)?.aggregation_function &&
        (metric as BIMetricSimple)?.column)
    ) {
      return true;
    }

    return false;
  }

  return false;
};

export const getDrilldownUrlQueryParams = (
  params?: DrillDownParams
): string => {
  if (!params) {
    return '';
  }

  if (params.include_disabled_users) {
    return `?user_status=all`;
  } else {
    return '?user_status=active';
  }
};
