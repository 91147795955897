import { ResizeHandle } from './InteractiveGrid.types';
import classNames from 'classnames';
import { css } from 'emotion';
import React, { LegacyRef, forwardRef } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';

interface Props {
  resizeHandle: ResizeHandle;
}

const resizeHandleCustomClass = css`
  background-image: none !important;
  font-size: 24px;
  display: flex;
  width: 24px !important;
  height: 24px !important;
  padding: 0 !important;
`;

const resizeHandleIcon = css`
  line-height: 24px;
  color: var(--bu-gray-500);
  &:hover {
    color: var(--bu-primary-500);
  }
`;

export const InteractiveGridResizeHandle = forwardRef<HTMLElement, Props>(
  ({ resizeHandle, ...props }, ref) => (
    <div
      className={classNames(
        resizeHandleCustomClass,
        'react-resizable-handle',
        `react-resizable-handle-${resizeHandle}`
      )}
      ref={ref as LegacyRef<HTMLDivElement>}
      {...props}
    >
      <BuIcon
        name={BoostUpIcons.InteractiveItemResizeHandle}
        className={classNames(resizeHandleIcon)}
      />
    </div>
  )
);
