import React, { FC, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getFilterHumanReadable } from 'components/dashboard/Metrics/common/ConditionsDescriptionList/helpers';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import { BIMetricsQueryFilter } from 'components/dashboard/Metrics/metrics.types';
import {
  getAllForecastPeriodOptions,
  getTimeOptions,
} from 'selectors/revbi/metrics';

interface Props {
  readonly filters: BIMetricsQueryFilter[];
}

export const ConditionsDescriptionList: FC<Props> = ({ filters }) => {
  const { currency: companyCurrencyCode } = useContext(RevBISettingsContext);

  const timeFilterOptions = useSelector(getTimeOptions);
  const forecastPeriodOptions = useSelector(getAllForecastPeriodOptions);

  const conditions = useMemo(
    () =>
      filters.map(
        (filter) =>
          filter?.and_condition?.[0]?.or_condition?.[0]?.column && (
            <li
              key={`${filter.and_condition[0].or_condition[0].column.name}_${filter.and_condition[0].or_condition[0].value}`}
            >
              {getFilterHumanReadable(
                filter.and_condition[0].or_condition[0],
                companyCurrencyCode,
                timeFilterOptions,
                forecastPeriodOptions
              )}
            </li>
          )
      ),
    [JSON.stringify(filters)]
  );

  return <>{conditions}</>;
};
