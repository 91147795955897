import { InteractiveLayoutMinSizeConstraint } from './InteractiveGrid.types';
import { Layout } from 'react-grid-layout';

export const getMinSizeForLayout = (
  layout: Layout,
  sizeConstraints: InteractiveLayoutMinSizeConstraint[]
): InteractiveLayoutMinSizeConstraint | undefined => {
  const { w: itemWidth, h: itemHeight } = layout;

  // The constraint is only applied if the item is resized to a certain minimum height or width.
  // Given that a layout item can have multiple minimum sizes, we need to determine which one is applicable.
  // This is done by comparing the current item size to the minimum sizes, and the one that matches becomes the applicable minimum size.

  const satisfiesSomeConstraint = sizeConstraints.some(
    ({ minH, minW }) => itemHeight >= minH && itemWidth >= minW
  );

  if (satisfiesSomeConstraint) {
    return undefined;
  }

  // This seems expensive to run on every resize. Verify if its worth it
  // and how it affects performance.
  const constraintThatApplies = sizeConstraints
    .filter(({ minH, minW }) => itemHeight <= minH || itemWidth <= minW)
    // Finds the closest constraint to the current item size.
    .sort((a, b) => {
      const aDiff = Math.max(itemHeight - a.minH, itemWidth - a.minW);
      const bDiff = Math.max(itemHeight - b.minH, itemWidth - b.minW);
      return aDiff - bDiff;
    });

  return constraintThatApplies[0] || sizeConstraints[0];
};
