import CustomRangeTab from './Tabs/CustomRangeTab';
import FiscalYearTab from './Tabs/FiscalYearTab';
import PredefinedTab from './Tabs/PredefinedTab';
import classNames from 'classnames';
import React, { Dispatch, FC, SetStateAction, useMemo } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import BuPopup from 'components/UI/BuPopup';
import { CustomPeriodTab } from 'components/dashboard/Metrics/common/Calendar/TimeFilter/Tabs/CustomPeriodTab';
import { RelativeTab } from 'components/dashboard/Metrics/common/Calendar/TimeFilter/Tabs/RelativeTab';
import {
  CUSTOM,
  FISCAL_YEAR,
  PREDEFINED,
  RANGE,
  RELATIVE,
} from 'components/dashboard/Metrics/common/Calendar/TimeFilter/constants';
import * as s from 'components/dashboard/Metrics/common/Calendar/TimeFilter/styles';
import {
  BetweenDate,
  FiltersConfig,
} from 'components/dashboard/Metrics/common/Calendar/types';
import BuCheckbox from 'components/UI/BuCheckbox';

const TABS_FOR_LIVE = [PREDEFINED, CUSTOM, FISCAL_YEAR, RANGE] as const;
const TABS_FOR_HISTORICAL = [RELATIVE, PREDEFINED, FISCAL_YEAR, RANGE] as const;

const getInfoMessage = (tab: string): string => {
  switch (tab) {
    case RELATIVE:
      return 'The options presented are not representing a specific date in time, but a period of time that is relative to each date snapshot.';
    case PREDEFINED:
      return "The options presented are defined by today's date and are the same for each date snapshot.";
    case RANGE:
      return 'The same range selected will be applied to each date snapshot';
    case FISCAL_YEAR:
      return 'Fiscal year filters are set in time and do not change, no matter the snapshot time of the historical data';
    default:
      return '';
  }
};

type TimeFilterContentProps = {
  config: FiltersConfig;
  betweenDate: BetweenDate;
  localTab: string;
  isHistorical: boolean;
  value: string[] | string;
  includeBlanks: boolean;
  showIncludeBlanks: boolean;
  onChange: (value: string) => void;
  onChangeLocalTab: Dispatch<SetStateAction<string>>;
  onClose: Dispatch<SetStateAction<boolean>>;
  setBetweenDate: Dispatch<SetStateAction<BetweenDate>>;
  onIncludeBlank: (value: boolean) => void;
};

export const TimeFilter: FC<TimeFilterContentProps> = ({
  config,
  betweenDate,
  localTab,
  isHistorical = false,
  value,
  includeBlanks = false,
  showIncludeBlanks = true,
  onChange,
  onChangeLocalTab,
  onClose,
  setBetweenDate,
  onIncludeBlank,
}) => {
  const IncludeBlank = useMemo(() => {
    return showIncludeBlanks ? (
      <div className={s.include_black_section}>
        <BuCheckbox
          checked={includeBlanks}
          onChange={onIncludeBlank}
          elementLabel={<label>Include records with a blank value</label>}
        />
      </div>
    ) : null;
  }, [includeBlanks, onIncludeBlank]);

  return (
    <>
      {(config?.customStart || config?.customEnd) && (
        <div className={s.time_filter_dropdown_tabs}>
          {isHistorical &&
            TABS_FOR_HISTORICAL.map((tab) => (
              <div
                key={tab}
                onClick={() => onChangeLocalTab(tab)}
                className={classNames(s.time_filter_historical_dropdown_tab, {
                  selected: tab === localTab,
                })}
              >
                {
                  <>
                    <span style={{ paddingRight: '8px' }}>{tab}</span>
                    <BuPopup
                      trigger={(ref) => (
                        <div ref={ref}>
                          {
                            <BuIcon
                              name={BoostUpIcons.BadgeInfoOutline}
                              color={'var(--bu-primary-500)'}
                            />
                          }
                        </div>
                      )}
                    >
                      <div className={s.info_message_container}>
                        {getInfoMessage(tab)}
                      </div>
                    </BuPopup>
                  </>
                }
              </div>
            ))}

          {!isHistorical &&
            TABS_FOR_LIVE.map((tab) => (
              <div
                key={tab}
                onClick={() => onChangeLocalTab(tab)}
                className={classNames(s.time_filter_dropdown_tab, {
                  selected: tab === localTab,
                  borderRight:
                    localTab === PREDEFINED ||
                    localTab === RELATIVE ||
                    localTab === CUSTOM,
                  borderLeft:
                    localTab === RANGE ||
                    localTab === RELATIVE ||
                    localTab === CUSTOM,
                })}
              >
                {tab}
              </div>
            ))}
        </div>
      )}

      {localTab === RELATIVE && (
        <>
          <RelativeTab
            config={config}
            value={value}
            onChange={onChange}
            onClose={onClose}
          />
          {IncludeBlank}
        </>
      )}

      {localTab === PREDEFINED && (
        <>
          <PredefinedTab
            config={config}
            value={value}
            onChange={onChange}
            onClose={onClose}
          />
          {IncludeBlank}
        </>
      )}

      {localTab === FISCAL_YEAR && (
        <>
          <FiscalYearTab
            config={config}
            value={value}
            onChange={onChange}
            onClose={onClose}
          />
          {IncludeBlank}
        </>
      )}

      {localTab === CUSTOM && (
        <>
          <CustomPeriodTab value={value as string} onChange={onChange} />
          {IncludeBlank}
        </>
      )}

      {localTab === RANGE && (
        <>
          <CustomRangeTab
            betweenDate={betweenDate}
            config={config}
            onChange={onChange}
            setBetweenDate={setBetweenDate}
          />
          {IncludeBlank}
        </>
      )}
    </>
  );
};
