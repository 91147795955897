import { css } from 'emotion';

export const errorContainer = css`
  background-color: var(--bu-red-500);
  color: var(--bu-gray-200);
  font-size: 1em;
  margin-top: 7px;
  border-radius: var(--bu-control-border-radius);
  padding: 8px;
`;
