import { css } from 'emotion';

export const table = {
  container: css`
    height: 100%;
    margin: 10px -20px 0;
  `,
};

export const actionButton = css`
  height: 17px;
  width: 17px;
  margin: 0 5px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  
  &[data-tooltip]:hover:before,
  &[data-tooltip]:hover:after {
    display: none;
  }

  &[data-tooltip]:not([data-tooltip='']) {
    &:after {
      display: block;
      content: attr(data-tooltip);
      visibility: hidden;

      position: absolute;
      word-break: break-all;
      left: 50px;
      bottom: -40px;
      padding: 5px 12px;
      width: max-content;
      border-radius: 2px;
      background: #4a4a4a;
      color: #fff;
      font-size: 10px;
      transform: translateX(-56%) !important;
      font-family: var(--bu-font-regular);
    }

  &:hover::after {
    visibility: visible;
    transition: visibility 0s linear 0.5s;
  }

  &.disabled {
    filter: opacity(0.4);
    cursor: not-allowed;
  }

  > img {
    height: 17px;
    width: 17px;
  }
`;

export const editPanel = {
  container: css`
    display: flex;
    flex-direction: column;
    height: 100vh;
    color: rgba(0, 0, 0, 0.87);
  `,
  header: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--bu-gray-100);
    border-bottom: 1px solid #dddddd;
    height: 50px;
    width: 100%;
    padding: 20px;
  `,
  title: css`
    color: var(--bu-gray-900);
    font-family: var(--bu-font-regular);
    font-size: 18px;
    font-weight: 600;
  `,
  titleComment: css`
    color: #777777;
    font-family: var(--bu-font-regular);
    font-size: 13px;
    margin-top: 5px;
  `,
  description: css`
    display: flex;
    flex-direction: column;
    height: 150px;
    width: 100%;
    padding: 20px 20px 0px 20px;
    color: #4a4a4a;

    & > .row {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 15px;
      width: 800px;

      & > .lbl {
        width: 130px;
        font-weight: 500;
      }

      & > input {
        align-self: flex-start;
        height: 30px;
        width: 400px;
        padding: 12px;
        border: 1px solid var(--bu-gray-500);
        border-radius: var(--bu-control-border-radius);
        background-color: var(--bu-white);

        &:focus {
          border-color: var(--bu-primary-500);
        }

        &.description-input {
          margin-left: 5px;
          width: 700px;
        }

        & + .lbl > .req {
          color: red;
          visibility: hidden;
        }

        &:invalid:not(:focus) {
          & + .lbl > .req {
            visibility: visible;
          }
        }
      }
    }
  `,
  tabs: css`
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 500;
    padding: 15px 20px;
    color: var(--bu-gray-700);
    border-bottom: 1px solid var(--bu-gray-500);
    height: 45px;

    & > .item {
      padding: 0 20px;
      cursor: pointer;
      margin-right: 10px;

      &.active {
        margin-bottom: -15px;
        color: var(--bu-primary-500);
        border-bottom: 2px solid var(--bu-primary-500);
      }
    }
  `,
  body: css`
    height: calc(100vh - 300px);

    & > .scrollable {
      height: 100%;
      overflow: auto;
    }
  `,
  footer: css`
    flex-direction: row;
    justify-content: flex-end;
    align-self: flex-end;
    height: 50px;
    width: 100%;
    display: flex;
    border-top: 1px solid #dddddd;
    padding: 10px 18px;

    & button {
      margin: 0px 8px;
    }
  `,
  button: css`
    height: 40px;
    width: 120px;
    margin: 5px;
    border-radius: 2px;
    font-size: 14px;
    font-family: var(--bu-font-regular);
    text-align: center;
    cursor: pointer;
    outline: none;
    
    &:hover {
      box-shadow: var(--bu-shadow-basic);
    }

    &.cancel {
      background-color: var(--bu-white);
      border: 1px solid #9b9b9b;
      color: color: #4A4A4A;;
    }

    &.save {
      background-color: #0762EC;
      color: var(--bu-white);
      border: none;
    }
    
    &.add {
      height: 30px;
      width: 90px;
      background-color: #0762EC;
      color: var(--bu-white);
      border: none;
    }
  `,
};
