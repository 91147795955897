import { css } from 'emotion';

export const metricsCreateContainer = css`
  background-color: var(--bu-gray-200);
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 4px;
`;

export const breadcrumbs = css`
  margin-bottom: 16px;
  a: {
    cursor: pointer;
  }
`;

export const actionButtonsContainer = css`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
`;
