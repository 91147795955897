import styled from '@emotion/styled';
import { SUPPORTED_COLUMN_COLORS } from 'components/UI/common/TypedTable/styles';

const buVar = (color: string) => `var(--bu-${color})`;

export const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-left: 10px;
`;

export const ColorBox = styled.div((props: { disabled: boolean }) => ({
  display: 'flex',
  border: '1px solid var(--bu-gray-400)',
  borderRadius: '2px',
  background: props.disabled ? 'var(--bu-gray-100)' : 'white',
  padding: '0',
  marginTop: '-5px',
  minWidth: '130px',
}));

export const ColorPicker = styled.div(
  (props: { color?: string; disabled: boolean }) => ({
    width: '40px',
    height: '27px',
    margin: '4px 5px',
    backgroundColor: `${
      props.disabled
        ? 'var(--bu-gray-100)'
        : SUPPORTED_COLUMN_COLORS.includes(props.color ?? '')
        ? buVar(props.color || '')
        : props.color
    }`,
    border: '1px solid var(--bu-gray-400)',
    borderRadius: '4px',
    cursor: 'pointer',
  })
);

export const ColorText = styled.input`
  border: 0;
  border-left: 1px solid var(--bu-gray-400);
  border-radius: 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 8px;
  max-width: 75px;
  font-size: 13px;
`;

export const ColorChooser = styled.div`
  width: 350px;
  padding: 8px 16px;
`;

export const ColorBlock = styled.div((props: { color: string }) => ({
  width: '40px',
  height: '24px',
  background: props.color,
  border: '1px solid var(--bu-gray-400)',
  borderRadius: '4px',
  cursor: 'pointer',
}));

export const ColorInput = styled.input(() => ({
  width: '40px',
  height: '30px',
  background: 'white',
  border: '1px solid var(--bu-gray-400)',
  borderRadius: '4px',
}));

export const CustomColor = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  gap: 4px;
  color: var(--bu-gray-700);
`;
