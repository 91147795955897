import { css } from 'emotion';

export const header = css`
  margin-bottom: 10px;
  padding: 0 30px;
  position: relative;
`;

export const header_title = css`
  color: #4a4a4a;
  text-align: center;
  font-size: 16px;
  font-family: var(--bu-font-semi-bold);
`;

export const header_btns = css`
  position: absolute;
  right: 0;
  top: 0;

  display: flex;
  justify-content: space-between;
  justify-content: flex-end;
  button {
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0;
    margin: 0 4px;
    img {
      width: 18px;
      height: 18px;
    }
    &:focus {
      outline: none;
    }
  }
`;

export const customTitle = css`
  color: #4a4a4a;
  font-family: var(--bu-font-semi-bold);
  text-align: center;
`;
