import { css } from 'emotion';
import {
  box,
  box_body,
  modalContent,
  header_modal_close_box,
  closeButton,
} from 'components/UI/Modal/ModalEmailDetails/styles';

// Reusing same styles
export { box, box_body, modalContent, header_modal_close_box, closeButton };

export const header_modal = css`
  border-bottom: 1px solid var(--bu-gray-300);
  overflow: hidden;
  display: grid;
  grid-template-columns: 11fr 60px;
`;

export const header_modal_title = css`
  text-align: left;
  padding: 18px 21px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--bu-gray-900);
  gap: 8px;

  i {
    font-size: 28px;
  }

  h1 {
    font-family: var(--bu-font-medium);
    font-weight: 500;
    font-size: 14px;
    margin: 0;

    a {
      color: var(--bu-primary-700);
      cursor: pointer;
    }
  }

  span {
    font-family: var(--bu-font-regular);
    font-weight: 400;
    font-size: 12px;
  }
`;
