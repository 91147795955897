import {
  connect,
  MapDispatchToPropsParam,
  MapStateToPropsParam,
} from 'react-redux';

import { getForecastPaceQuarterly } from 'actions/analyticsActions';
import Widget from 'components/dashboard/ForecastDashboard/Widget3/Widget';
import {
  IDispatchProps,
  IOwnProps,
  IStateProps,
} from 'components/dashboard/ForecastDashboard/Widget3/types';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

const mapStateToProps: MapStateToPropsParam<
  IStateProps,
  IOwnProps,
  IReduxState
> = (state) => {
  const data = selectors.forecastPaceQuarterly(state);

  return {
    quarters: data.list,
    periods: data.periods,
    cacheDate: data.cacheDate,
    isLoading: selectors.forecastPaceQuarterlyLoading(state),
    companyCurrency: selectors.getUserLocalCurrency(state),
    filters: selectors.getFiltersForAPI(state, 'forecast_analytics'),
  };
};

const mapDispatchToProps: MapDispatchToPropsParam<IDispatchProps, IOwnProps> = {
  getForecastPaceQuarterly,
};

export default connect(mapStateToProps, mapDispatchToProps)(Widget);
