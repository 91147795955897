import styled from '@emotion/styled';
import { css } from 'emotion';
import moment from 'moment';
import React from 'react';
import { Popup } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import {
  IColumn,
  IDataCellProps,
  SortOrder,
} from 'components/UI/common/TypedTable/TypedTable';

import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { MetricInfo } from 'components/dashboard/Metrics/common/MetricInfo/MetricInfo';
import { UNTITLED_DASHBOARD } from 'components/dashboard/Metrics/constants';
import { formatMetricObject } from 'components/dashboard/Metrics/metrics.helpers';
import { MetricInfoContainer } from 'components/dashboard/Metrics/metrics.styles';
import { BIWidget } from 'components/dashboard/Metrics/metrics.types';
import { Link } from 'navigation/Link';

const NameSpan = styled.span({
  marginRight: '4px',
});

const PrimaryCell = css`
  .typed-table-cell {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const TooltipMetricObject = styled.div({
  color: 'var(--bu-gray-700)',
  paddingTop: '4px',
  fontSize: '12px',
});

const popup = css`
  &.ui.popup {
    max-width: 480px;
  }
`;

const WidgetNameCell: React.FC<IDataCellProps> = ({ row }: IDataCellProps) => (
  <Link
    style={{ color: 'var(--bu-clickable-cell)' }}
    url={{
      scheme: '/revbi/widgets/edit/:widgetId',
      params: { widgetId: (row as any)?._id },
    }}
  >
    <NameSpan>{row.name}</NameSpan>
  </Link>
);

const MetricNameCell: React.FC<IDataCellProps> = ({
  row,
  column,
}: IDataCellProps) => (
  <>
    <Popup
      offset={[0, 10]}
      content={
        <MetricInfoContainer>
          <TooltipMetricObject>
            {formatMetricObject(row.object as string)}
          </TooltipMetricObject>
          <MetricInfo metric={row as any} />
        </MetricInfoContainer>
      }
      key="Details"
      header={row.name}
      trigger={
        <Link
          style={{ color: 'var(--bu-clickable-cell)' }}
          url={{
            scheme:
              column.label === 'widget name'
                ? '/revbi/widgets/edit/:widgetId'
                : '/revbi/metrics/edit/:metricId',
            params:
              column.label === 'widget name'
                ? { widgetId: (row as any)?._id }
                : { metricId: (row as any)?._id },
          }}
        >
          <NameSpan>{row.name}</NameSpan>
        </Link>
      }
      className={popup}
    />
  </>
);

const MetricDateCell: React.FC<IDataCellProps> = ({ row }: IDataCellProps) => {
  const timestamp = (row as any)?.updated_at;
  return (
    <>{timestamp ? moment(timestamp).format("Do MMM 'YY") : 'Date not found'}</>
  );
};

export const getMetricsListTableColumns = (
  handleEdit: (id: string, name: string) => void,
  handleDelete: (id: string, name: string) => void,
  handleClone: (id: string, name: string) => void
): IColumn[] => {
  const MetricActionCell: React.FC<IDataCellProps> = ({
    row,
  }: IDataCellProps) => {
    const id = String(row?._id ?? '');
    const name = String(row?.name ?? '');

    return (
      <>
        <BuButton
          icon
          borderless
          secondary
          onClick={() => handleEdit(id, name)}
          size={BuControlSize.SMALL}
          tooltip={'Edit metric'}
          testingLabel="edit-metric-button"
        >
          <BuIcon name={BoostUpIcons.Pencil} />
        </BuButton>
        <BuButton
          icon
          borderless
          secondary
          onClick={() => handleClone(id, name)}
          size={BuControlSize.SMALL}
          tooltip={'Clone metric'}
          testingLabel="clone-metric-button"
        >
          <BuIcon name={BoostUpIcons.Copy} />
        </BuButton>
        <BuButton
          icon
          borderless
          secondary
          onClick={() => handleDelete(id, name)}
          size={BuControlSize.SMALL}
          tooltip={'Delete metric'}
          testingLabel="delete-metric-button"
        >
          <BuIcon name={BoostUpIcons.Trash} />
        </BuButton>
      </>
    );
  };

  return [
    {
      id: 'name',
      label: 'metric name',
      field: 'name',
      minWidth: 400,
      maxWidth: 400,
      sortable: true,
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.CUSTOM,
      width: 400,
      config: {
        renderer: MetricNameCell,
        className: PrimaryCell,
      } as any,
    },
    {
      id: 'description',
      label: 'description',
      field: 'name_identifier',
      sortable: true,
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.TEXT,
      minWidth: 350,
      maxWidth: 350,
      showTooltip: true,
      config: {
        classNames: PrimaryCell,
      } as any,
    },
    {
      id: 'lastUpdated',
      label: 'last updated',
      field: 'updated_at',
      sortable: true,
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.CUSTOM,
      width: 500,
      config: {
        renderer: MetricDateCell,
      } as any,
    },
    {
      id: 'metricType',
      label: 'Metric Type',
      field: 'synthetic_metric',
      sortable: true,
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.CUSTOM,
      width: 500,
      config: {
        renderer: ({ row }: any) => {
          return <>{row.synthetic_metric ? 'Formula' : 'Simple'}</>;
        },
      } as any,
    },
    {
      id: 'data',
      label: 'Data',
      field: 'analysis_type',
      sortable: true,
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.CUSTOM,
      width: 500,
      config: {
        renderer: ({ row }: any) => {
          return (
            <>
              {row.analysis_type.charAt(0).toUpperCase() +
                row.analysis_type.slice(1)}
            </>
          );
        },
      } as any,
    },
    {
      id: 'actions',
      label: 'actions',
      field: '',
      sortable: false,
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.CUSTOM,
      width: 200,
      config: {
        renderer: MetricActionCell,
      } as any,
    },
  ];
};

type IWidgetCell = {
  row: {
    analysis_type: string;
  };
};

export const getWidgetsListTableColumns = (
  handleEdit: (id: string, name: string) => void,
  handleDelete: (id: string, name: string) => void,
  handleClone: (id: string, name: string) => void
): IColumn[] => {
  const WidgetActionCell: React.FC<IDataCellProps> = ({
    row,
  }: IDataCellProps) => {
    const id = (row as BIWidget)?.id ?? '';
    const name = (row as BIWidget)?.name;

    return (
      <>
        <BuButton
          icon
          borderless
          secondary
          onClick={() => handleEdit(id, name)}
          size={BuControlSize.SMALL}
          tooltip={'Edit widget'}
          testingLabel="edit-widget-button"
        >
          <BuIcon name={BoostUpIcons.Pencil} />
        </BuButton>
        <BuButton
          icon
          borderless
          secondary
          onClick={() => handleClone(id, name)}
          size={BuControlSize.SMALL}
          tooltip={'Clone widget'}
          testingLabel="clone-widget-button"
        >
          <BuIcon name={BoostUpIcons.Copy} />
        </BuButton>
        <BuButton
          icon
          borderless
          secondary
          onClick={() => handleDelete(id, name)}
          size={BuControlSize.SMALL}
          tooltip={'Delete widget'}
          testingLabel="delete-widget-button"
        >
          <BuIcon name={BoostUpIcons.Trash} />
        </BuButton>
      </>
    );
  };

  return [
    {
      id: 'name',
      label: 'widget name',
      field: 'name',
      minWidth: 640,
      sortable: true,
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.CUSTOM,
      width: 1000,
      config: {
        renderer: WidgetNameCell,
      } as any,
    },
    {
      id: 'createdBy',
      label: 'created by',
      field: 'metadata.user_name',
      sortable: true,
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.TEXT,
      width: 500,
      config: {},
    },
    {
      id: 'data',
      label: 'Data',
      field: 'analysis_type',
      sortable: true,
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.CUSTOM,
      width: 500,
      config: {
        renderer: ({ row }: IWidgetCell) => {
          return (
            <>
              {row.analysis_type.charAt(0).toUpperCase() +
                row.analysis_type.slice(1)}
            </>
          );
        },
      },
    },
    {
      id: 'lastUpdated',
      label: 'last updated',
      field: 'updated_at',
      sortable: true,
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.CUSTOM,
      width: 500,
      config: {
        renderer: MetricDateCell,
      } as any,
    },
    {
      id: 'actions',
      label: 'actions',
      field: '',
      sortable: false,
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.CUSTOM,
      width: 200,
      config: {
        renderer: WidgetActionCell,
      } as any,
    },
  ];
};

type IDashboardCell = {
  row: {
    id: string;
    name: string;
    created_at: string;
    updated_at: string;
    metadata: {
      user_name: string;
    };
  };
};

function formatDate(date?: string): string {
  return date ? moment(date).format("Do MMM, 'YY") : 'Date not found';
}

export const getRevBiDashboardsColumns = (
  handleEdit: (id: string) => void,
  handleDelete: (id: string, name: string) => void
): IColumn[] => {
  const ActionCell = ({ row }: IDashboardCell) => (
    <>
      <BuButton
        icon
        borderless
        secondary
        onClick={() => handleEdit(row.id)}
        size={BuControlSize.SMALL}
        tooltip={'Edit dashboard'}
      >
        <BuIcon name={BoostUpIcons.Pencil} />
      </BuButton>
      <BuButton
        icon
        borderless
        secondary
        onClick={() => handleDelete(row.id, row.name)}
        size={BuControlSize.SMALL}
        tooltip={'Delete dashboard'}
      >
        <BuIcon name={BoostUpIcons.Trash} />
      </BuButton>
    </>
  );

  return [
    {
      id: 'name',
      label: 'dashboard name',
      field: 'name',
      minWidth: 400,
      maxWidth: 400,
      sortable: false,
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.CUSTOM,
      width: 400,
      config: {
        renderer: ({ row }: IDashboardCell) => {
          return (
            <Link
              style={{ color: 'var(--bu-clickable-cell)' }}
              url={{
                scheme: `/revbi/dashboard/${row['id']}` as any,
                params: {
                  id: row['id'],
                  name: row['name'],
                },
              }}
            >
              <NameSpan>{row.name || UNTITLED_DASHBOARD}</NameSpan>
            </Link>
          );
        },
        className: PrimaryCell,
      } as any,
    },
    {
      id: 'user_name',
      label: 'Created by',
      field: 'user_name',
      sortable: false,
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.CUSTOM,
      width: 500,
      config: {
        renderer: ({ row }: IDashboardCell) => (
          <div>{row.metadata.user_name}</div>
        ),
      } as any,
    },
    {
      id: 'lastCreated',
      label: 'Created at',
      field: 'created_at',
      sortable: false,
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.CUSTOM,
      width: 500,
      config: {
        renderer: ({ row }: IDashboardCell) => formatDate(row['created_at']),
      } as any,
    },
    {
      id: 'lastUpdated',
      label: 'last updated',
      field: 'updated_at',
      sortable: false,
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.CUSTOM,
      width: 500,
      config: {
        renderer: ({ row }: IDashboardCell) => formatDate(row['updated_at']),
      } as any,
    },
    {
      id: 'actions',
      label: 'actions',
      field: '',
      sortable: false,
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.CUSTOM,
      width: 200,
      config: {
        renderer: ActionCell,
      } as any,
    },
  ];
};
