import styled from '@emotion/styled';

export const ListContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

export const QuickViewContent = styled.div({
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
});

export const SearchInputContainer = styled.div({
  '> div': {
    borderRadius: '25px',
  },
});

export const NumberOfRecordsParagraph = styled.p({
  color: 'var(--bu-gray-900)',
  marginBottom: 0,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '130%',
});

export const AddNewContainer = styled.div({
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
});

export const AddNewParagraph = styled.p({
  color: 'var(--bu-gray-900)',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '140%',
  marginBottom: 0,
});
