import clx from 'classnames';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dimmer, Loader, DropdownItemProps } from 'semantic-ui-react';

import { ForecastQuotaAttainmentData } from 'actions/analyticsActions';
import {
  getPlotOptions,
  sortOptions,
} from 'components/dashboard/ForecastDashboard/SellerQuotaAttainmentWidget/helper';
import * as styles from 'components/dashboard/ForecastDashboard/SellerQuotaAttainmentWidget/styles';
import { IProps } from 'components/dashboard/ForecastDashboard/SellerQuotaAttainmentWidget/types';
import { useBoundary } from 'components/hooks/useBoundary';
import { useClickOutside } from 'components/hooks/useClickOutside';
import { useLocalStorage } from 'components/hooks/useLocalStorage';
import { getUser } from 'selectors';

const SellerQuotaAttainmentWidget: React.FC<IProps> = ({
  companyCurrency,
  filters,
  getForecastQuotaAttainments,
  isLoading,
  position,
  quotaAttainments,
  selectedBusinessType,
}) => {
  const isFirstLoad = useBoundary(position);

  const { email: userEmail } = useSelector(getUser);
  const [sorting, setSorting] = useLocalStorage(
    `SellerQuotaAttainmentWidget.${userEmail}`,
    1
  );

  useEffect(() => {
    if (!isFirstLoad) {
      getForecastQuotaAttainments({
        close_date_interval: ['TQU'],
        business_type_name: selectedBusinessType,
        managers: filters.sales_managers,
        custom_filters: filters.custom_filters,
        opportunity_types: filters.opportunity_types,
        opportunity_stages: filters.opportunity_stages,
      });
    }
  }, [
    isFirstLoad,
    selectedBusinessType,
    JSON.stringify(filters.sales_managers),
    JSON.stringify(filters.custom_filters),
    JSON.stringify(filters.opportunity_types),
    JSON.stringify(filters.opportunity_stages),
  ]);

  const data = useMemo<ForecastQuotaAttainmentData[]>(
    () => quotaAttainments,
    [quotaAttainments]
  );

  const options = useMemo(
    () => getPlotOptions(data, companyCurrency, sorting),
    [data, companyCurrency, sorting]
  );

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.headerRow}>
          How are sellers doing on their Quota Attainment this quarter?
        </div>
        <div>
          <DropdownCustom
            options={sortOptions}
            label="Rank"
            value={sorting}
            onClick={setSorting}
          />
        </div>
      </div>

      <Dimmer.Dimmable dimmed={false}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          immutable={true}
        />
        <Dimmer active={isLoading} inverted>
          <Loader />
        </Dimmer>
      </Dimmer.Dimmable>
    </div>
  );
};

export default SellerQuotaAttainmentWidget;

type IpropsDropdownProps = {
  label: string;
  onClick: any;
  value: any;
  options: any;
};

const DropdownCustom: React.FC<IpropsDropdownProps> = ({
  label,
  onClick,
  value,
  options,
}) => {
  const { isOpen, setIsOpen, refElement } = useClickOutside();

  const toggle = () => setIsOpen(!isOpen);

  const option = options.find((i: DropdownItemProps) => i.value === value);

  return (
    <div
      ref={refElement}
      onClick={toggle}
      className={clx(styles.dropdrown, { active: isOpen })}
    >
      <span className={styles.dropdrown_label}>{label}:</span>
      <span className={styles.dropdrown_value}>{option.text}</span>
      <ul className={clx(styles.dropdrown_options, { open: isOpen })}>
        {options.map((i: DropdownItemProps) => (
          <li
            onClick={() => onClick(i.value)}
            className={clx(styles.dropdrown_options_item, {
              active: i.value === value,
            })}
          >
            {i.text}
          </li>
        ))}
      </ul>
    </div>
  );
};
