import { css } from 'emotion';

import { fontHeading, fontDefault } from 'assets/css/common';

export const confirmation_modal = css`
  &.ui.tiny.modal {
    width: 440px !important;
  }
`;

export const subtitle = css`
  ${fontDefault}
  color: var(--bu-gray-700);
  margin: 0px 25px;

  p {
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const center_content = css`
  text-align: center;
`;

export const title = css`
  ${fontHeading}
  Line height: 19px;
  color: var(--bu-gray-900);
  margin-top: -10px;
  margin-bottom: -10px;
`;

export const no_icon_title = css`
  color: var(--bu-gray-900);
  margin-top: 25px;
  margin-bottom: 20px;
`;

export const footer = css`
  background: #fff !important;
  border: none !important;
  text-align: center !important;

  button {
    margin: 0px 15px 15px;
  }
`;

export const exclamation_icon = css`
  font-size: 40px;
  line-height: 40px;
`;
