import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import { GetDealsResponse, UpdateDealsParams, updateDeal } from 'api/Deals';
import { get, set } from 'lodash';

const MUTATION_KEY = ['updateObjectField', 'deal'];

export interface UseUpdateDealMutationOptions {
  doNotInvalidateOnSettled?: boolean;
}

export const useUpdateDealMutation = (
  keyToUpdate: QueryKey,
  { doNotInvalidateOnSettled }: UseUpdateDealMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: MUTATION_KEY,
    mutationFn: (update: UpdateDealsParams) => updateDeal(update),
    onMutate: async (update) => {
      await queryClient.cancelQueries(keyToUpdate);

      const currentData =
        queryClient.getQueryData<GetDealsResponse>(keyToUpdate);

      if (!currentData) {
        return;
      }

      const itemBeingUpdated = currentData?.deals.find(
        (item) => item._id === update.id
      );

      if (!itemBeingUpdated) {
        return;
      }

      const originalFieldValues: Record<string, any> = Object.fromEntries(
        Object.keys(update.editedFields).map((key) => [
          key,
          get(itemBeingUpdated, key),
        ])
      );

      const optimisticUpdate = {
        ...itemBeingUpdated,
      };

      Object.entries(update.editedFields).forEach(([key, value]) => {
        set(optimisticUpdate, key, value);
      });

      const newData: GetDealsResponse = {
        ...currentData,
        deals: currentData.deals.map((deal) =>
          deal._id === optimisticUpdate._id ? optimisticUpdate : deal
        ),
      };

      queryClient.setQueryData(keyToUpdate, newData);

      return {
        itemIdTryingToUpdate: optimisticUpdate._id,
        originalFieldValues,
      };
    },
    onSettled: () => {
      const noMutationOfSameKeyInProgress =
        queryClient.isMutating({ mutationKey: MUTATION_KEY }) <= 1;

      const canInvalidate = !doNotInvalidateOnSettled;

      if (noMutationOfSameKeyInProgress && canInvalidate) {
        queryClient.invalidateQueries(keyToUpdate);
      }
    },
    onError: (_err, _variables, context) => {
      if (
        !context ||
        !context.itemIdTryingToUpdate ||
        !context.originalFieldValues
      ) {
        return;
      }

      const dataWithOptimisticUpdate =
        queryClient.getQueryData<GetDealsResponse>(keyToUpdate);

      if (!dataWithOptimisticUpdate) {
        return;
      }

      const { itemIdTryingToUpdate, originalFieldValues } = context;

      const optimisticItem = dataWithOptimisticUpdate.deals.find(
        (deal) => deal._id === itemIdTryingToUpdate
      );

      if (!optimisticItem) {
        return;
      }

      const revertedItem = {
        ...optimisticItem,
      };

      Object.entries(originalFieldValues).forEach(([key, value]) => {
        set(revertedItem, key, value);
      });

      const dataWithItemReverted: GetDealsResponse = {
        ...dataWithOptimisticUpdate,
        deals: dataWithOptimisticUpdate.deals.map((deal) =>
          deal._id === revertedItem._id ? revertedItem : deal
        ),
      };

      queryClient.setQueryData(keyToUpdate, dataWithItemReverted);
    },
  });
};
