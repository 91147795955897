import _ from 'lodash';

export const getEmailIds = (state) => state.email.ids;
export const getEmailsCount = (state) => state.email.count;
export const getEmail = (state, id) => {
  const key = _.findKey(
    state.email.byId,
    (m) => m.message_id === id || m.id === id
  );
  if (key in state.email.byId) {
    return state.email.byId[key];
  }
  return null;
};
export const getEmailStatus = (state) => state.email.status;
