import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';

import { TABLE_NAME } from 'common/constants';
import BuGroupButton from 'components/UI/BuGroupButton';
import Chart from 'components/chart-dashboards/Widget/Chart';
import ChartTitle from 'components/chart-dashboards/Widget/ChartTitle';
import WidgetHeader from 'components/chart-dashboards/Widget/WidgetHeader';
import {
  MAX_ITEMS_TO_DISPLAY,
  timeSegments,
  CATEGORY_INITIAL_VALUE,
  getYAxisTitle,
  openDealsModal,
  calculateDeltaOverPeriod,
} from 'components/chart-dashboards/Widget/helper';
import * as styles from 'components/chart-dashboards/Widget/styles';
import { IProps, IData } from 'components/chart-dashboards/Widget/types';
import { useWidgetApi } from 'components/chart-dashboards/Widget/useWidgetApi';
import Select from 'components/modals/PersonalActivity/Select';
import WidgetModal from 'components/modals/WidgetModal';
import * as selectors from 'selectors';
import { history } from 'store/configureStore';

const Widget: React.FC<IProps> = ({
  apis,
  mode = 'default',
  changesSelectorTab,
  chart_options,
  chart_type,
  dashboardId,
  data_type,
  hideFullScreenButton = false,
  withTimeFilters = false,
  withActivitySelect = false,
  extendedTitle,
  extraFilters,
  filters,
  hideTimeSpanButtons,
  label_path,
  persistModalParams,
  series,
  setVisibleSeries,
  tab: tabProp,
  title: titleProp,
  value_path,
  visibleSeries,
  setLocalFilters,
  changeWidgetSinceDate,
  viewBy,
  sortWidget,
  widgetFilterSort,
  isModal = false,
  extraBeforeFilters,
  tooltip = undefined,
  localStorageKeyPrefix = '',
  onGetDefaultData = () => [],
  onSetData = undefined,
  xAxisLabelFormatter = undefined,
}) => {
  const isTrendsWidget =
    history.location.pathname.includes('forecast/analytics') ||
    history.location.pathname.includes('dashboard/trends');
  const companyCurrencyCode = useSelector(selectors.getUserLocalCurrency);
  const [activityPeriod, setActivityPeriod] = useState<string[]>(
    (filters as any).activity_period
  );
  const tab = dashboardId ? `_dashboard_${dashboardId}` : tabProp || titleProp;
  const isWaterfallChart = chart_type === 'waterfall';

  const pathInitialValue = Array.isArray(value_path)
    ? value_path[0].path
    : value_path;

  const changeInterval =
    (filters.change_interval && filters.change_interval[0]) || 'TQU';
  const closeDateInterval =
    (filters.close_date_interval && filters.close_date_interval[0]) || 'TQU';

  const [activeCategory, setActiveCategory] = useState(CATEGORY_INITIAL_VALUE);
  const [fullScreen, setFullScreen] = useState(false);
  const [segmentInterval, setSegmentInterval] = useState([
    tab === 'forecast' ? 'TQU' : closeDateInterval,
  ]);
  const [valuePath, setValuePath] = useState(pathInitialValue);

  const time_span =
    changesSelectorTab === 'forecast'
      ? (filters.change_interval && filters.change_interval[0]) || 'TQU'
      : changesSelectorTab === 'forecastAnalytics'
      ? segmentInterval[0]
      : dashboardId === '2' && filters.time_span
      ? filters.time_span
      : filters.change_interval;

  const fetchParams = {
    ...apis[0].api_params,
    ...extraFilters,
    ...filters,
    activity_period: withActivitySelect
      ? activityPeriod
      : (filters as any).activity_period,
    time_span,
    change_interval: time_span,
    rollup_by: viewBy,
  };

  const serializedQueryParams = JSON.stringify(fetchParams);

  const { data, dataStatus } = useWidgetApi({
    apis,
    changeInterval: fetchParams.change_interval,
    chartType: chart_type,
    dashboardId,
    mode,
    onGetDefaultData,
    pathInitialValue,
    serializedQueryParams,
    setVisibleSeries,
    title: titleProp,
    valuePath,
    onSetData,
  });

  const [serializedCsvParams, setSerializedCsvParams] = useState('');

  const isDealsTable =
    isTrendsWidget && apis[0].click_api_url === '/api/data/deals/';

  useEffect(() => {
    if (Boolean(data) && data.length !== 0) {
      setSerializedCsvParams(
        JSON.stringify({
          change_categories: {
            categories: data[0].data.map((el) => el.label),
            ids: data[0].data.reduce(
              (previous, current) => ({
                ...previous,
                [current.label ?? '']: current.ids,
              }),
              {}
            ),
          },
          ...fetchParams,
          is_waterfall_chart: isWaterfallChart,
          table_name: isDealsTable ? TABLE_NAME.DealsDelta : undefined,
        })
      );
    }
  }, [data]);

  const isLoading = ['notAsked', 'loading'].includes(dataStatus);

  const onChangeDropdown = useCallback((e, { value }) => {
    setValuePath(value);
    sortWidget({ title: titleProp, value });
  }, []);

  const deltaOverPeriod = useMemo(
    () =>
      data[0] && data[0].data.length > 0
        ? calculateDeltaOverPeriod(isWaterfallChart, data[0].data)
        : 0,
    [isWaterfallChart, data]
  );

  const onBarClick = (filters: {}, title = '', context: string) => {
    openDealsModal(
      filters,
      title || titleProp,
      context,
      data[0].data,
      isTrendsWidget,
      data_type,
      deltaOverPeriod,
      apis[0].click_api_url,
      fetchParams,
      apis[0].api_params,
      localStorageKeyPrefix,
      persistModalParams
    );
  };

  const valueTitle = getYAxisTitle(value_path, valuePath);
  const yAxisTitle = valueTitle ? `${valueTitle} →` : undefined;

  const chartTitle = isWaterfallChart
    ? `${activeCategory.label} ${titleProp}`
    : titleProp;

  const renderedChart = (
    <Chart
      chartType={chart_type}
      config={{
        ...chart_options,
        ...{ yAxisTitle },
      }}
      data={data}
      displayName={valueTitle || ''}
      labelPath={label_path}
      onClick={apis[0].click_api_url ? onBarClick : null}
      series={series}
      setVisibleSeries={setVisibleSeries}
      title={chartTitle}
      valuePath={valuePath}
      visibleSeries={visibleSeries}
      companyCurrencyCode={companyCurrencyCode}
      tooltip={tooltip}
      xAxisLabelFormatter={xAxisLabelFormatter}
    />
  );

  const renderedChartTitle = (
    <ChartTitle
      changeInterval={changeInterval}
      chart_type={chart_type}
      closeDateInterval={closeDateInterval}
      deltaOverPeriod={deltaOverPeriod}
      extendedTitle={extendedTitle}
      segmentInterval={segmentInterval[0]}
      title={chartTitle}
    />
  );

  useEffect(() => {
    if (widgetFilterSort) setValuePath(widgetFilterSort);
  }, [widgetFilterSort, titleProp]);

  const emptyLabel = (
    <div className={styles.emptyData}>
      <p>There is no data to display here</p>
    </div>
  );

  const isCheckEmptyData = (data: IData[]) =>
    isEmpty(data)
      ? undefined
      : !data.every((el) =>
          el.data.every((i) => i.count === 0 && i.total_amount === 0)
        );

  const isRenderEmptyLabel = isCheckEmptyData(data);

  /**
   * If it's from trend (analytics page) use 'click_api_url' instead of 'api_url';
   */
  const widgetHeaderUrl =
    isTrendsWidget && apis[0].click_api_url?.includes('/api/data/deals/')
      ? `${apis[0].click_api_url}csv`
      : `${apis[0].api_url}/csv`;

  return (
    <Segment
      basic
      className={classNames(
        'widget-segment',
        isModal ? styles.segment_modal : styles.segment
      )}
      padded={false}
    >
      <Dimmer.Dimmable>
        <div className={classNames('chart-wrapper', styles.chartWrapper)}>
          <WidgetHeader
            chart_type={chart_type}
            onChangeDropdown={onChangeDropdown}
            serializedQueryParams={serializedCsvParams}
            setFullScreen={hideFullScreenButton ? undefined : setFullScreen}
            setLocalFilters={setLocalFilters}
            tab={tab}
            timeFilterValue={filters.change_interval || ''}
            url={widgetHeaderUrl}
            valuePath={value_path}
            valueTitle={valueTitle}
            withTimeFilters={withTimeFilters}
            changeWidgetSinceDate={changeWidgetSinceDate}
            extraFilter={
              withActivitySelect ? (
                <div>
                  <Select onChange={setActivityPeriod} value={activityPeriod} />
                </div>
              ) : undefined
            }
            extraBeforeFilters={extraBeforeFilters}
          >
            {renderedChartTitle}
          </WidgetHeader>

          {data.map((item) => item.data).flat().length >
            MAX_ITEMS_TO_DISPLAY && (
            <div className={styles.toManyItemsWarning}>
              There are more than 1000 entries found so showing only first 1000.
              Use filters to narrowing it down.
            </div>
          )}

          <div>{isRenderEmptyLabel ? renderedChart : emptyLabel}</div>

          {!hideTimeSpanButtons && (
            <BuGroupButton
              className={styles.timeSegments}
              options={timeSegments}
              selectedOption={segmentInterval[0]}
              onSelect={(value) => setSegmentInterval([value])}
            />
          )}
        </div>

        <Dimmer inverted active={isLoading}>
          <Loader content="Loading" />
        </Dimmer>
      </Dimmer.Dimmable>
      {!hideFullScreenButton && (
        <WidgetModal
          chart={renderedChart}
          onClose={setFullScreen}
          open={fullScreen}
          title={renderedChartTitle}
        />
      )}
    </Segment>
  );
};

export default Widget;
