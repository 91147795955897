import classNames from 'classnames';
import { css } from 'emotion';
import React from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import {
  CustomComponentFunction,
  getCellValue,
  makeComponent,
} from 'components/UI/common/TypedTable/renderers/custom/common';

const noWrap = css`
  white-space: nowrap;
`;

const icon = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 0;

  .bu-icon {
    font-size: 1.2em;

    margin-right: 4px;
  }
`;

const yesColor = css`
  color: var(--bu-green-500);
`;

const noColor = css`
  color: var(--bu-gray-500);
`;

const YesImpersonation: React.FC = () => (
  <div className={classNames(noWrap, icon, yesColor)}>
    <BuIcon name={BoostUpIcons.CheckCircle} />
    Yes
  </div>
);

const NoImpersonation: React.FC = () => (
  <div className={classNames(noWrap, icon, noColor)}>
    <BuIcon name={BoostUpIcons.CrossCircle} />
    No
  </div>
);

export const yesNoImpersonationField: CustomComponentFunction =
  () => (props) => {
    const impersonationSettings = getCellValue<string>(props);

    if (impersonationSettings.length === 0) {
      return <NoImpersonation />;
    }

    return <YesImpersonation />;
  };

export const YesNoImpersonationField = makeComponent(yesNoImpersonationField);
