import { css } from 'emotion';

export const wrapper = css`
  display: flex;
  flex-direction: column;

  .container,
  .container-dashboard {
    height: 100%;
    overflow-x: hidden;
  }

  .container {
    padding: 0;
  }

  .container-dashboard {
    display: flex;
    flex-direction: column;
  }
`;

export const header = css`
  margin: 0 20px;
`;

export const content = css`
  flex: 1;
  overflow: hidden;

  .table-body {
    overflow: auto;
    height: calc(100vh - 269px);
  }
`;
