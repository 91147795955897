import { css } from 'emotion';

import { fontSubtitle, fontDefault } from 'assets/css/common';

export const container = css`
  margin-bottom: 20px;
  height: 100%;
  border: 1px solid var(--bu-gray-300);
  border-radius: 2px;
`;

export const headerContainer = css`
  border-bottom: 1px solid var(--bu-gray-300);
  padding: 14px 30px;

  display: flex;
`;

export const headerRow = css`
  flex: 1;
  color: var(--bu-gray-900);
  ${fontSubtitle};
  letter-spacing: 0;
  line-height: 21px;

  display: flex;
  align-items: center;

  .subheader {
    font-size: 14px;
    font-weight: normal;
    line-height: 16px;

    margin-left: 10px;
  }
`;

export const smallDropdown = css`
  margin-left: 10px;
  &.ui.dropdown {
    height: 30px;
    min-height: 30px;
    min-width: 140px;
    padding-top: 6px;
    > .dropdown.icon {
      padding-top: 7px;
    }
  }
`;

export const dropdrown = css`
  display: inline-block;
  border: 1px solid var(--bu-gray-500);
  padding: 5px 8px;
  border-radius: 3px;
  cursor: pointer;
  background: var(--bu-white);
  position: relative;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background: rgba(49, 92, 121, 0.12);
  }

  &.active {
    border-color: var(--bu-primary-500);
  }
`;

export const dropdrown_label = css`
  ${fontSubtitle};
  margin-right: 5px;
`;

export const dropdrown_value = css`
  ${fontDefault};
`;

export const dropdrown_options = css`
  &.open {
    display: block;
  }

  display: none;
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  right: -2px;
  top: 36px;
  z-index: 1000;
  background: var(--bu-white);
  border-radius: 3px;
  width: max-content;
  max-height: 204px;
  overflow: auto;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  border: 1px solid var(--bu-gray-500);
`;

export const dropdrown_options_item = css`
  padding: 7px 15px;

  &.active,
  &:hover {
    background: rgba(48, 176, 209, 0.08);
  }
`;

export const triangle = css`
  border-style: solid;
  height: 0;
  width: 0;
  border-width: 4px;
  border-color: var(--bu-gray-900);
  display: inline-block;
  margin: 0 2px 0 6px;

  &.bu-up {
    border-top-width: 0;
    border-top-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    transform: translateY(-50%);
    border-color: var(--bu-primary-500);
  }

  &.bu-down {
    border-bottom-width: 0;
    border-bottom-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    transform: translateY(-50%);
  }
`;
