import { css } from 'emotion';

export const action_buttons_container = css`
  display: none;
  align-items: center;
  margin-left: auto;
`;

export const action_button = css`
  height: 24px !important;
  width: 24px !important;
  border-radius: 2px;
  background-color: var(--bu-white) !important;

  :hover {
    background-color: var(--bu-gray-300) !important;
  }
`;

export const action_icon = css`
  font-size: 15px !important;
`;
