import { css } from 'emotion';

export const wrapper = css`
  height: 100%;
  padding: 0 20px 0 20px;
  display: flex;
  flex-direction: column;

  .container,
  .container-dashboard {
    height: 100%;
    overflow-x: hidden;
  }

  .container {
    padding: 0;
  }

  .container-dashboard {
    display: flex;
    flex-direction: column;
  }

  #roles,
  #opportunity_stages,
  #opportunity_types {
    display: none;
  }
`;

export const content = css`
  flex: 1;
  margin: 0px -20px;
  overflow: hidden;
  table,
  td,
  th {
    border: 1px solid #ddd;
  }
  th {
    min-width: 160px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
  .ui.table.structured {
    border-collapse: collapse !important;
  }
  .ui.table.structured.compact > thead > tr {
    th {
      z-index: 1 !important;

      .heading_smaller {
        div {
          color: #777777;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 16px;
        }
      }
    }
    th:first-child {
      z-index: 6 !important;
      > div {
        padding: 0px 25px;
      }
    }
  }

  .ui.table.structured.compact > tbody > tr {
    background-color: #fff !important;
    td {
      padding: 0px;
      &.clickeable {
        &:hover {
          background: #f1f6f7 !important;
        }
      }
      .typed-table-cell-type-custom {
        .emerald {
          background-color: #eafaf0;
        }
        .citrine {
          background-color: var(--bu-orange-300);
        }
        .red {
          background-color: #ffebeb;
        }
      }
      .typed-table-cell-type-text {
        padding: 0 10px;
      }
    }
    td:first-child {
      padding: 0px 25px;
      .typed-table-cell {
        font-family: var(--bu-font-regular) !important;
        color: #4a4a4a !important;
        font-size: 14px !important;
        line-height: 15px !important;
      }
      &:hover {
        background: #f1f6f7 !important;
      }
    }
    &:nth-child(2n):hover td {
      background: #fff;
    }
    &:nth-child(2n) > td {
      background-color: #fff;
    }
    &:hover td {
      background: #fff;
    }
  }
`;

export const cellValue = css`
  margin: 7px 0px 5px 0px;
`;

export const cellContainer = css`
  padding: 1px 10px;
`;

export const clickable = css`
  cursor: pointer;
  color: var(--bu-primary-500);

  &:hover {
    background: #f1f6f7 !important;
  }

  &.emerald:hover {
    background: #e2f6cc !important;
  }

  &.citrine:hover {
    background: #fdf0cc !important;
  }

  &.red:hover {
    background: #fcded6 !important;
  }
`;

export const target_seller_main_line = css`
  flex: 1;
  height: 4px;
  border-radius: 2px;
  background: var(--bu-gray-400);
  overflow: hidden;
  position: relative;
`;

export const target_seller_line_fill = css`
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  background: var(--bu-primary-500);
  height: 100%;
  max-width: 100%;
`;

export const target_seller_main_line_percent = css`
  margin-left: 5px;
  color: #777777;
  font-size: 10px;
`;

export const columnFilters = css`
  position: absolute;
  right: 17px;
  top: 158px;
  z-index: 10;
`;

export const dashboardFilters = css`
  min-height: 95px;
`;

const textAlignmentToMarging = {
  left: '-5px 10% 0 0',
  right: '-5px 0 0 10%',
  center: '-5px 5% 0 5%',
};

export const target_seller_main_line_block = (
  textAlignment: 'left' | 'right' | 'center' = 'left'
) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${textAlignmentToMarging[textAlignment]};
`;
