import React from 'react';

import AccountsList from 'components/dashboard/accounts-recommendations/AccountsListWrapper';
import { IProps } from 'components/dashboard/accounts-recommendations/types';

const AccountsRecommendations = ({ filters }: IProps) => {
  return (
    <div>
      <AccountsList
        title="Accounts with prospect not engaged in past 30 days."
        url="/api/data/accounts/recommendations/prospect_not_engaged"
        filters={{
          ...filters,
          time_span: 'L30D',
        }}
      />{' '}
      <br />
      <AccountsList
        title="Accounts with no activity in past 30 days."
        url="/api/data/accounts/recommendations/no_activity"
        filters={{
          ...filters,
          time_span: 'L30D',
        }}
      />{' '}
      <br />
      <AccountsList
        title="Accounts with activity in last 15 days but no opportunities."
        url="/api/data/accounts/recommendations/activity_no_opportunities"
        filters={{
          ...filters,
          time_span: 'L15D',
        }}
      />
    </div>
  );
};

export default AccountsRecommendations;
