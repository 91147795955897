import React, { useContext, useMemo, useRef } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import { FirstPivot } from 'components/dashboard/Metrics/Widget/Controls/Live/FirstPivot';
import { Pivot } from 'components/dashboard/Metrics/Widget/Controls/Live/Pivot';
import {
  container,
  filterBarElement,
  pivotFiltersContainer,
} from 'components/dashboard/Metrics/Widget/Controls/Live/styles';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import {
  DataDescriptor,
  BIPivotDescriptor,
} from 'components/dashboard/Metrics/metrics.types';

interface Props {
  readonly availablePivots: BIPivotDescriptor[];
  readonly hasFSMetric: boolean;
  readonly isPieChart: boolean;
  readonly isTargetMetricOnlyWidget: boolean;
  readonly groups: DataDescriptor[];
  readonly timeField?: DataDescriptor | null;
  readonly isHistorical?: boolean;
  readonly canRemovePivots?: boolean;
  onPivotChange: (values: string[], pivotNro: number) => void;
}

export const PivotControls: React.FC<Props> = ({
  availablePivots,
  hasFSMetric = false,
  isPieChart = false,
  isTargetMetricOnlyWidget = false,
  groups = [],
  timeField,
  isHistorical = false,
  canRemovePivots = true,
  onPivotChange,
}) => {
  const { maxAvailablePivots } = useContext(RevBISettingsContext);

  const parentRef = useRef<HTMLDivElement>(null);

  const maxAvailablePivotsBasedOnMetrics = hasFSMetric ? 2 : maxAvailablePivots;

  const intersectionOptions: BIPivotDescriptor[] = useMemo(
    () => availablePivots.filter((column) => column.available_in_any_pivot),
    [availablePivots]
  );

  const symmetricAnyPivotDiffOptions: BIPivotDescriptor[] = useMemo(
    () =>
      availablePivots.filter(
        (column) =>
          column.type !== 'timePeriod' &&
          column.available_in_pivot_nth &&
          column.available_in_any_pivot
      ),
    [availablePivots]
  );

  const symmetricNthPivotDiffOptionsA: BIPivotDescriptor[] = useMemo(
    () =>
      availablePivots.filter(
        (column) =>
          column.type !== 'timePeriod' &&
          column.available_in_pivot_nth &&
          !column.available_in_any_pivot
      ),
    [availablePivots]
  );

  const getPivotOptions = () => {
    // First pivot insert
    const pivots = [
      <FirstPivot
        key="firstPivot"
        groups={groups}
        options={intersectionOptions}
        timeField={timeField}
        onPivotChange={onPivotChange}
      />,
    ];

    // if the widget has a Pie chart should not show more than 1 pivot.
    if (isPieChart) {
      return pivots;
    }

    // Next Pivots
    const groupByNameList = groups.map((g) => g.name);

    for (
      let index = 1;
      index < groups.length + 2 && index < maxAvailablePivotsBasedOnMetrics;
      index++
    ) {
      const allSectionOptionsCleaned = symmetricAnyPivotDiffOptions.filter(
        (opt) => !groupByNameList.slice(0, index).includes(opt.name)
      );
      const someSectionOptionsCleaned = symmetricNthPivotDiffOptionsA.filter(
        (opt) => !groupByNameList.slice(0, index).includes(opt.name)
      );

      pivots.push(
        <Pivot
          key={index}
          index={index}
          isTargetMetricOnlyWidget={isTargetMetricOnlyWidget}
          groups={groups}
          timeField={timeField}
          union={allSectionOptionsCleaned}
          symmetricDiff={someSectionOptionsCleaned}
          parentRef={parentRef}
          onPivotChange={onPivotChange}
        />
      );
    }

    return pivots;
  };

  // this is only until Historical accepts more pivots
  // and re implements historical with the new changes comming with FS
  const getHistoricalPivotOptions = () => {
    // First pivot insert
    let pivots: React.ReactNode[] = [];

    // if the widget has a Pie chart should not show more than 1 pivot.
    if (isPieChart) {
      return pivots;
    }

    const groupByNameList = groups.map((g) => g.name);
    const someSectionOptionsCleaned = symmetricNthPivotDiffOptionsA.filter(
      (opt) => !groupByNameList.slice(0, 1).includes(opt.name)
    );

    pivots.push(
      <FirstPivot
        key="firstPivot"
        canRemovePivots={canRemovePivots}
        groups={groups}
        options={someSectionOptionsCleaned}
        timeField={timeField}
        onPivotChange={onPivotChange}
      />
    );

    return pivots;
  };
  return (
    <div className={container} ref={parentRef}>
      <div className={filterBarElement}>
        <BuIcon name={BoostUpIcons.Layers} color="var(--bu-gray-700)" />
      </div>
      <div className={pivotFiltersContainer}>
        {!isHistorical ? getPivotOptions() : getHistoricalPivotOptions()}
      </div>
    </div>
  );
};
