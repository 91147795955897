import { RollupsTableConfigurationColumn } from '../types';
import { isNull } from 'lodash';
import * as R from 'ramda';

import { formatAmount } from 'common/helpers';
import { formatMoney } from 'common/numbers';
import { BuMetricsPanelItem } from 'components/UI/BuMetricsPanel';
import { IRow } from 'components/UI/common/TypedTable/TypedTable';

const COLLECTIONS_WITH_AMOUNT_OF_DEALS_ENABLED = ['Deals'];

interface Metric {
  displayName: string;
  amountOfDeals: number | null;
  amount: number;
  delta: number | null;
}

const shouldShowColumnAsMetric = (column: RollupsTableConfigurationColumn) =>
  !!column.show_as_a_metric;

const aggregateFieldFromRow = (fieldName: string) => (acc: number, row: IRow) =>
  (Number(row?.[fieldName]) ?? 0) + acc;

const isAValidNumber = (value: number | null | undefined): value is number =>
  !R.isNil(value) && !isNaN(value!) && typeof value === 'number';

const shouldShowDealsForMetric = (column: RollupsTableConfigurationColumn) =>
  COLLECTIONS_WITH_AMOUNT_OF_DEALS_ENABLED.includes(column.collection);

const mergeSplits = (dealList: string[]) => {
  if (!dealList) {
    return [];
  }
  const deals = new Map<string, number>();
  for (const item of dealList) {
    if (deals.has(item)) {
      const count = deals.get(item) as number;
      deals.set(item, count + 1);
    } else {
      deals.set(item, 1);
    }
  }
  const dealsArray = [];
  for (const entry of deals.entries()) {
    dealsArray.push({ id: entry[0], count: entry[1] });
  }
  return dealsArray;
};

/**
 * Returns deals amount include on the metric, is possible for the field to not have
 * id information, in that case we return null (note null is different than 0 deals)
 */
const getDealAmountOrNullForColumn = (
  data: IRow[],
  column: RollupsTableConfigurationColumn
): number | null => {
  const showDeals = shouldShowDealsForMetric(column);

  if (!showDeals) {
    return null;
  }

  const metricIdsFieldName = `${column.object_field} id`;
  const dealsInvolvedInMetric = data
    .map((row) => row?.[metricIdsFieldName] as unknown[] | undefined | null)
    // In this case undefined is different than null, as backend send null if there are not deals (as 0 deals)
    // but undefined indicates that is not a column where we have deal amount information
    .filter((idList) => idList !== undefined) as (unknown | null)[][];

  const thereIsIdInformation = dealsInvolvedInMetric.length > 0;
  const dealsWithoutDuplicates = [...new Set(dealsInvolvedInMetric)];

  return thereIsIdInformation
    ? dealsWithoutDuplicates.reduce(
        (acc, idList) =>
          acc +
          mergeSplits(idList as string[]).reduce(
            (acc, deal) => acc + deal.count,
            0
          ),
        0
      )
    : 0;
};

const rowHasMetricForField = (fieldName: string) => (row: IRow) =>
  !R.isNil(row?.[fieldName]);

const isMetric = (metricOrNull: Metric | null): metricOrNull is Metric =>
  !R.isNil(metricOrNull);

// We don't have a specific flag to know which column / metric has delta
// But we have the badge metatada, and for now, we only display badges for deltas
// So if it has a badge as delta
const metricHasToShowDelta = (column: RollupsTableConfigurationColumn) =>
  !!column?.meta?.sub_value?.badge?.relative_fields;

const getDeltaAmount = (
  column: RollupsTableConfigurationColumn,
  tree: IRow[],
  currentAmount: number
) => {
  const deltaMetricFieldName = `Prev ${column.object_field}`;
  const showDelta = metricHasToShowDelta(column);

  const previousAmount = showDelta
    ? tree.reduce(aggregateFieldFromRow(deltaMetricFieldName), 0)
    : null;
  // Not using switch as typescript compiler can't smart check
  // previousAmount using isAValidNumber
  if (!isAValidNumber(previousAmount) || !showDelta) {
    return null;
  } else if (column.meta?.sub_value?.badge?.type === 'subtraction') {
    return currentAmount - previousAmount;
  } else if (column.meta?.sub_value?.badge?.type === 'addition') {
    return currentAmount + previousAmount;
  }
  return null;
};

export const generateMetricsFromColumns = (
  columns: RollupsTableConfigurationColumn[],
  tree: IRow[],
  data: IRow[]
): Metric[] =>
  columns
    .filter(shouldShowColumnAsMetric)
    .map((column) => {
      const metricFieldName = column.object_field;

      const rowsWithValidMetric = tree.filter(
        rowHasMetricForField(metricFieldName)
      );

      const thereIsNoRowWithValidMetric = rowsWithValidMetric.length === 0;

      if (thereIsNoRowWithValidMetric) {
        return null;
      }

      const metricAmount = rowsWithValidMetric.reduce(
        aggregateFieldFromRow(metricFieldName),
        0
      );

      const deltaAmount = getDeltaAmount(column, tree, metricAmount);

      const amountOfDeals = getDealAmountOrNullForColumn(data, column);

      return {
        displayName: column.display_name,
        amountOfDeals,
        amount: metricAmount,
        delta: deltaAmount,
      };
    })
    .filter(isMetric) as Metric[];

const formatDealsTitle = (deals: number) =>
  `(${deals} ${deals == 1 ? 'Deal' : 'Deals'})`;

const formatDelta = (currency: string, delta: number): string | null => {
  if (!delta) {
    return null;
  }
  return formatAmount(currency, delta, true);
};

export const mapMetricsToBuMetricsPanelItems = (
  metrics: Metric[],
  currency: string
): BuMetricsPanelItem[] =>
  metrics.map((metric) => ({
    title: metric.displayName,
    dealsCount: isAValidNumber(metric.amountOfDeals)
      ? formatDealsTitle(metric.amountOfDeals)
      : '',
    formattedAmount: formatMoney(currency, metric.amount),
    formattedShortAmount: formatAmount(currency, metric.amount),
    // Do not use format amount directly as delta is not a negative number, its a decrease
    // So $-100.000 (a negative number) is different than -$100.000 (a decrese)
    formattedDeltaAmount: metric.delta
      ? formatDelta(currency, metric.delta)
      : null,
    positiveDelta: metric.delta ? metric.delta > 0 : null,
  }));
