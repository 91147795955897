import {
  PieChartName,
  PiesData,
} from 'components/dashboard/ProspectAccountDashboard/WidgetThreePies/types';

export const apiPointForPies = `${process.env.REACT_APP_BACKEND_URL}/api/data/accounts/account_executive_dashboard_header`;

export const pieConfigs = {
  without_opportunities: {
    title: 'Without Opportunities',
    data: [
      {
        data: [
          { name: 'Without Opportunities' },
          { name: 'With Opportunities' },
        ],
      },
    ],
  },
  disengaged_30_days: {
    title: 'Dis-Engaged in 30 days',
    data: [
      {
        data: [{ name: 'Dis-Engaged' }, { name: 'Engaged' }],
      },
    ],
  },
  no_exec_engaged_60_days: {
    title: 'No Exec. Engagement in 60 days',
    data: [
      {
        data: [{ name: 'No Exec. Engagement' }, { name: 'Exec. Engaged' }],
      },
    ],
  },
  with_competitors_involved: {
    title: 'Competitors involved',
    data: [
      {
        data: [
          { name: 'Competitors Involved' },
          { name: 'Competitors not Involved' },
        ],
      },
    ],
  },
};

export const pieChartsNames: PieChartName[] = [
  'disengaged_30_days',
  'no_exec_engaged_60_days',
  'with_competitors_involved',
  'without_opportunities',
];

export const piesDataInitialState: PiesData = {
  total_accounts: 0,
  without_opportunities: 0,
  disengaged_30_days: 0,
  no_exec_engaged_60_days: 0,
  with_competitors_involved: 0,
};
