import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { ForecastSubmissionSettingItemReduxState } from 'common/types/forecastSubmission';
import { TableConfigurationColumn } from 'components/UI/TableConfig/types';
import { IRow } from 'components/UI/common/TypedTable/TypedTable';
import { ManageForecastModalType } from 'components/modals/ManageForecastModal/types';
import { useModal } from 'components/modals/ModalContext/modal.context';
import { getIsRealTimeEnabled } from 'selectors';

export {
  processTableData,
  isEmptyValue,
  getPipelineGapClassName,
  getMyOrgTooltip,
} from 'components/dashboard/ForecastRollUps/helpers/processTableData';

export { calculatePipelinesTree } from 'components/dashboard/ForecastRollUps/helpers/calculatePipelinesTree';

export { findCurrentUserRow } from 'components/dashboard/ForecastRollUps/helpers/findCurrentUserRow';

export { sortAndAggTree } from 'components/dashboard/ForecastRollUps/helpers/sortAndAggTree';

export {
  getCSVHeaders,
  getCSVData,
  buildFlatList,
} from 'components/dashboard/ForecastRollUps/helpers/csvFunctions';

export const getSubmissionWithColumn = (
  columns: TableConfigurationColumn[],
  submissionSettings: ForecastSubmissionSettingItemReduxState[],
  selectedBusinessType: string
) => {
  const submissionWithColumn: (ForecastSubmissionSettingItemReduxState &
    TableConfigurationColumn)[] = [];
  const submissionColumns = columns.filter(
    (column) => column.meta.forecast_settings_id
  );
  submissionColumns.forEach((column) => {
    const forecastSettingsIds = column.meta.forecast_settings_id || {};
    const forecastSettingsId =
      forecastSettingsIds[selectedBusinessType] || forecastSettingsIds.default;
    const settingsItem = submissionSettings.find(
      (submission) => submission.id === forecastSettingsId
    );

    if (settingsItem) {
      submissionWithColumn.push({
        ...settingsItem,
        ...column,
      });
    }
  });

  return submissionWithColumn;
};

export const getColumnByFieldName = (
  fieldName: string,
  columns: TableConfigurationColumn[]
): TableConfigurationColumn | undefined =>
  columns.find((c) => c.field_name === fieldName);

export const extractIdsFromRow = (row: IRow, fieldName: string) => {
  const dealIds = (row[`${fieldName} id`] as string[]) || [];
  const prevDealsIds = (row[`Prev ${fieldName} id`] as string[]) || [];
  return {
    dealIds,
    prevDealsIds,
  };
};

export const getFlatRows = (rollupRows: IRow[]): IRow[] =>
  rollupRows.flatMap(({ children, ...restOfRow }) => [
    restOfRow,
    ...(children && children.length > 0 ? getFlatRows(children) : []),
  ]);

export const calculateNewDelta = (
  row: IRow,
  column: TableConfigurationColumn
) => {
  const fieldName = column.field_name;

  const hasToShowDelta = column.meta.sub_value?.badge.type === 'subtraction';

  if (!hasToShowDelta) {
    return undefined;
  }
  const currentValue = row[fieldName];
  const prevValue = row[`Prev ${fieldName}`];

  if (typeof currentValue !== 'number' || typeof prevValue !== 'number') {
    return undefined;
  }

  return currentValue - prevValue;
};

/**
 * This hook encapsulates the logic for managing the state and behavior of the "Deals Status" modal
 * and recalculation when real time editing is enabled
 * It will handle the attachment of the callbacks to the modal both on modal opening,
 * or when modal is already open
 * It will also update the modal props when the rollups data is re-fetched
 *  */
export const useRecalculateDealsStatusModal = ({
  isSomeDataLoading,
  rollupsRows,
  rollupsColumns,
  onRecalculate,
}: {
  isSomeDataLoading: boolean;
  rollupsRows: IRow[];
  rollupsColumns: TableConfigurationColumn[];
  onRecalculate: () => void;
}) => {
  const modal = useModal<ManageForecastModalType>();

  const isRealTimeEnabled = useSelector(getIsRealTimeEnabled);

  const dealsStatusProps = modal.getPropsForScheme('/deals-status/:tab');
  const isDealsStatusModalOpen =
    modal.modalsOpened.includes('/deals-status/:tab');

  const rowIdToRecalculate = dealsStatusProps?.rowId;
  const fieldNameToRecalculate = dealsStatusProps?.column_name;

  const hasToRecalculateRef = useRef<boolean>();
  hasToRecalculateRef.current = !!dealsStatusProps?.hasToRecalculate;

  // Set callback for recalculation when modal is opened / page is refresh while modal is already opened
  // Also set callback to recalculate if modal is closed while recalculation is needed
  useEffect(() => {
    if (isDealsStatusModalOpen && isRealTimeEnabled) {
      modal.setCallbacksForScheme('/deals-status/:tab', {
        onRecalculate: onRecalculate,
        onClose: () => {
          if (hasToRecalculateRef.current) {
            onRecalculate();
          }
        },
      });
    }
  }, [isDealsStatusModalOpen, isRealTimeEnabled]);

  // Update modal props to display correct loading status
  useEffect(() => {
    if (isDealsStatusModalOpen && isSomeDataLoading && isRealTimeEnabled) {
      modal.updateModalProps('/deals-status/:tab', {
        hasToRecalculate: true,
        isRecalculating: true,
      });
    }
  }, [isDealsStatusModalOpen, isSomeDataLoading, isRealTimeEnabled]);

  // Update modal props to display up to date data
  useEffect(() => {
    if (
      isDealsStatusModalOpen &&
      dealsStatusProps?.hasToRecalculate &&
      dealsStatusProps?.isRecalculating &&
      !isSomeDataLoading &&
      isRealTimeEnabled
    ) {
      const newRow = getFlatRows(rollupsRows).find(
        (row) => rowIdToRecalculate === row.id
      );
      const column = rollupsColumns.find(
        (column) => column.field_name === fieldNameToRecalculate
      );
      if (newRow && column) {
        const { dealIds, prevDealsIds } = extractIdsFromRow(
          newRow,
          fieldNameToRecalculate
        );
        const newDelta = calculateNewDelta(newRow, column);
        modal.updateModalProps('/deals-status/:tab', {
          isRecalculating: false,
          hasToRecalculate: false,
          dealIds,
          prevDealsIds,
          delta: newDelta,
          now: Date.now(),
        });
      }
    }
  }, [
    rollupsRows,
    isSomeDataLoading,
    rowIdToRecalculate,
    fieldNameToRecalculate,
    isRealTimeEnabled,
    isDealsStatusModalOpen,
    dealsStatusProps?.hasToRecalculate,
    dealsStatusProps?.isRecalculating,
  ]);

  const openDealStatusModal = (
    configuration: Omit<ManageForecastModalType, 'callbacks' | 'scheme'>
  ) => {
    modal.openModal({
      scheme: '/deals-status/:tab',
      ...configuration,
    });
  };

  return {
    openDealStatusModal,
  };
};
