import classNames from 'classnames';
import { useElementSize } from 'components/hooks/useElementSize';
import { useMemo } from 'react';
import {
  multipleTotalCardItem,
  multipleTotalCardsContainer,
  multipleTotalsContainer,
  multipleTotalsInnerContainer,
  totalCardAThirdOfSpace,
  totalCardHalfSpace,
} from './styles';

interface LayoutMultipleTotalsProps {
  children: React.ReactNode;
  metricLength: number;
}

export const LayoutMultipleTotals = ({
  children,
  metricLength,
}: LayoutMultipleTotalsProps) => {
  const [containerRef, { height, width }] = useElementSize();
  const fontSize = useMemo(() => {
    const baseFontSize = 6;

    const heightFactor = 0.07;
    const widthFactor = 0.01;
    const metricsFactor = 0.4;

    return (
      baseFontSize +
      Math.ceil(height * heightFactor) +
      Math.ceil(width * widthFactor) -
      Math.ceil(metricLength * metricsFactor)
    );
  }, [height, width, metricLength]);

  return (
    <div className={multipleTotalsContainer}>
      <div
        className={multipleTotalsInnerContainer}
        data-testing="totals-container"
      >
        <div
          ref={containerRef}
          className={multipleTotalCardsContainer({ fontSize })}
          data-testing="totals-block-section"
        >
          {children}
        </div>
      </div>
    </div>
  );
};

interface LayoutMultipleTotalItemProps {
  children: React.ReactNode;
  isClickable: boolean;
  metricLength: number;
  ['data-testing']: string;
  handleClick: () => void;
}
export const LayoutMultipleTotalItem = ({
  children,
  isClickable,
  metricLength,
  'data-testing': dataTesting,
  handleClick,
}: LayoutMultipleTotalItemProps) => {
  const shouldItemUseAThirdOfSpace = metricLength > 2;
  return (
    <div
      data-testing={dataTesting}
      className={classNames([
        multipleTotalCardItem,
        {
          clickable: isClickable,
        },
        shouldItemUseAThirdOfSpace
          ? totalCardAThirdOfSpace
          : totalCardHalfSpace,
      ])}
      onClick={isClickable ? handleClick : () => {}}
    >
      {children}
    </div>
  );
};
