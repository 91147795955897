import { useRef, useEffect, useState, RefObject } from 'react';
import { useSelector } from 'react-redux';

import { appDisplayMode } from 'selectors';

type Res = [RefObject<HTMLDivElement>, number];

const HEADER_HEIGHT = 50;

const calcOffset = (height: number, isHeaderVisible: boolean) =>
  isHeaderVisible ? height + HEADER_HEIGHT : height;

export const useTableOffset = (): Res => {
  const displayMode = useSelector(appDisplayMode);
  const isHeaderVisible = !['embed', 'compact'].includes(displayMode);
  const [offset, setOffset] = useState<number>(calcOffset(0, isHeaderVisible));

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = ref.current;

    if (!element) return;

    const observer = new ResizeObserver(() => {
      setOffset(calcOffset(element.offsetHeight, isHeaderVisible));
    });

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [ref.current, isHeaderVisible]);

  return [ref, offset];
};
