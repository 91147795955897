import PropTypes from 'prop-types';

export const calls = PropTypes.arrayOf(
  PropTypes.shape({
    summary: PropTypes.string.isRequired,
    user_name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    call_duration: PropTypes.number,
    attendees: PropTypes.array,
    start: PropTypes.string,
  })
).isRequired;

export const call = PropTypes.shape({
  summary: PropTypes.string.isRequired,
  user_name: PropTypes.string.isRequired,
  opportunity_stage: PropTypes.string,
  id: PropTypes.string.isRequired,
  call_duration: PropTypes.number,
  attendees: PropTypes.array,
  start: PropTypes.string,
}).isRequired;

export const loadingStatus = PropTypes.oneOf([
  'notAsked',
  'loading',
  'success',
  'error',
]);

export const deal = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  account_executive: PropTypes.string,
  amount: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  close_date: PropTypes.string.isRequired,
  opportunity_name: PropTypes.string.isRequired,
  positive_indicators: PropTypes.array,
  risk_factors: PropTypes.array.isRequired,
  risk_status: PropTypes.string.isRequired,
  risk_status_display: PropTypes.string.isRequired,
});

const competitorInsight = PropTypes.shape({
  conversation_with: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      role: PropTypes.string,
      name: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    })
  ),
  keywords: PropTypes.arrayOf(PropTypes.string),
  last_mentioned: PropTypes.shape({
    mentioned_at: PropTypes.string,
    touchpoint_type: PropTypes.string,
  }),
  mentions: PropTypes.number,
  text: PropTypes.string,
});

export const topicInsight = PropTypes.shape({
  text: PropTypes.string,
  mentions: PropTypes.number,
  last_mentioned: PropTypes.shape({
    mentioned_at: PropTypes.string,
    touchpoint_type: PropTypes.string,
  }),
  conversation_with: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string,
      role: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  keywords: PropTypes.arrayOf(PropTypes.string),
});

export const dealInsight = PropTypes.shape({
  account: PropTypes.string,
  account_executive: PropTypes.string,
  amount: PropTypes.number,
  close_date: PropTypes.string,
  competitors_insights: competitorInsight,
  contact_engagement: PropTypes.arrayOf(PropTypes.object),
  emails: PropTypes.arrayOf(PropTypes.object),
  last_call: PropTypes.object,
  last_call_sentiment: PropTypes.string,
  name: PropTypes.string,
  next_steps: PropTypes.string,
  positive_indicators: PropTypes.arrayOf(PropTypes.object),
  prev_score: PropTypes.number,
  rep_notes: PropTypes.string,
  risk_factors: PropTypes.arrayOf(PropTypes.object),
  score: PropTypes.number,
  stage: PropTypes.string,
  topic_insights: PropTypes.arrayOf(topicInsight),
  topics_discussed: PropTypes.arrayOf(PropTypes.object),
  win_probability: PropTypes.string,
});

export const statTotal = PropTypes.shape({
  total_count: PropTypes.number,
  count_at_risk: PropTypes.number,
  count_on_track: PropTypes.number,
  total_amount: PropTypes.number,
  total_amount_override: PropTypes.number,
  total_amount_override_count: PropTypes.number,
  amount_at_risk: PropTypes.number,
  amount_on_track: PropTypes.number,
});

export const topic = PropTypes.shape({
  insights: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string).isRequired,
  mentions: PropTypes.number,
  mentions_level: PropTypes.string,
  start_times: PropTypes.arrayOf(PropTypes.number),
  text: PropTypes.string.isRequired,
});

export const contact = PropTypes.shape({
  email: PropTypes.string,
  is_customer: PropTypes.bool,
  role: PropTypes.string,
  touchpoint_stats: PropTypes.shape({
    email: PropTypes.shape({
      num_touchpoints: PropTypes.number,
      num_touchpoints_engaged: PropTypes.number,
      response_rate: PropTypes.number,
      last_touched: PropTypes.string,
    }),
    event: PropTypes.shape({
      num_touchpoints: PropTypes.number,
      num_touchpoints_engaged: PropTypes.number,
      response_rate: PropTypes.number,
      last_touched: PropTypes.string,
      last_engaged: PropTypes.string,
    }),
    call: PropTypes.shape({
      num_touchpoints: PropTypes.number,
      num_touchpoints_engaged: PropTypes.number,
      response_rate: PropTypes.number,
      last_touched: PropTypes.string,
      last_engaged: PropTypes.string,
    }),
    all: PropTypes.shape({
      num_touchpoints: PropTypes.number,
      num_touchpoints_engaged: PropTypes.number,
      response_rate: PropTypes.number,
      last_touched: PropTypes.string,
      last_engaged: PropTypes.string,
    }),
  }),
});

export const callFullDataShape = {
  attendees: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      is_self: PropTypes.bool,
      organizer: PropTypes.bool,
      response_status: PropTypes.string,
    })
  ),
  audio_url: PropTypes.string,
  competitors_discussed: PropTypes.arrayOf(topic),
  description: PropTypes.string,
  duration: PropTypes.number,
  my_tags: PropTypes.array,
  opportunity_name: PropTypes.string,
  other_tags: PropTypes.array,
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.number,
      duration: PropTypes.number,
      speaker_label: PropTypes.string,
      speaker_type: PropTypes.string,
      start_time: PropTypes.number,
      transcript: PropTypes.string,
    })
  ),
  start: PropTypes.string,
  title: PropTypes.string,
  topics_discussed: PropTypes.arrayOf(topic),
};
