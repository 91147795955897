import * as R from 'ramda';

import { TableConfigurationColumn } from 'components/UI/TableConfig/types';
import { IRow } from 'components/UI/common/TypedTable/TypedTable';

type DealList = {
  dealIds: Set<string>;
  deltaIds: Set<string>;
};

export const sortAndAggTree = (
  tree: IRow[],
  columnsToAgg: TableConfigurationColumn[],
  columnsToChildAgg: TableConfigurationColumn[]
) => {
  const sortedTree = R.sortWith<IRow>([R.descend(R.propIs(Array, 'children'))])(
    tree
  );

  return sortedTree.map((item) => {
    if (Array.isArray(item.children)) {
      item.children = sortAndAggTree(
        item.children,
        columnsToAgg,
        columnsToChildAgg
      );

      const accDeals = (base: Set<string>, additionals: string[] = []) =>
        additionals.forEach((newId) => base.add(newId));

      const accDealsList = (
        property: string,
        dealList: DealList,
        element: IRow
      ) => ({
        dealIds: accDeals(
          dealList.dealIds,
          (element[`${property} id`] as string[]) ?? []
        ),
        deltaIds: accDeals(
          dealList.deltaIds,
          (element[`Prev ${property} id`] as string[]) ?? []
        ),
      });

      for (let index = 0; index < columnsToAgg.length; index++) {
        const { field_name: property } = columnsToAgg[index];
        let dealList: DealList = {
          dealIds: new Set((item[`${property} id`] as string[]) ?? []),
          deltaIds: new Set((item[`Prev ${property} id`] as string[]) ?? []),
        };

        item.children.forEach((element) => {
          const prevProperty = `Prev ${property}`;
          const value = element[property] as number;
          const prevValue = element[prevProperty] as number;
          accDealsList(property, dealList, element);

          item[property] = ((item[property] as number) ?? 0) + (value ?? 0);
          if (prevValue) {
            item[prevProperty] =
              ((item[prevProperty] as number) ?? 0) + (prevValue ?? 0);
          }
        });

        item[`${property} id`] = Array.from(dealList.dealIds);
        item[`Prev ${property} id`] = Array.from(dealList.deltaIds);
      }

      for (let index = 0; index < columnsToChildAgg.length; index++) {
        const {
          meta: { sub_value: subValueConfig },
        } = columnsToChildAgg[index];
        if (subValueConfig) {
          const { object: property = '' } = subValueConfig;
          let dealList: DealList = {
            dealIds: new Set((item[`${property} id`] as string[]) ?? []),
            deltaIds: new Set((item[`Prev ${property} id`] as string[]) ?? []),
          };
          item[subValueConfig.relative_field] = item.children.reduce(
            (acc: number, element) => {
              const value = element[property || ''] as number;
              accDealsList(property, dealList, element);
              return value ? acc + value : acc;
            },
            0
          );
          item[`${property} id`] = Array.from(dealList.dealIds);
          item[`Prev ${property} id`] = Array.from(dealList.deltaIds);
        }
      }
    }

    return item;
  });
};
