import MindtickleCellIcon from 'assets/fonts/boostup-icons/mindtickle_call_icon.svg';
import PlayCallIcon from 'assets/fonts/boostup-icons/play.svg';
import PlayCallIconActive from 'assets/images/new_icon/play_call_audio_active.svg';
import TimelineAlertDotBlueIcon from 'assets/fonts/boostup-icons/timeline_alert_dot_blue.svg';
import TimelineAlertDotRedIcon from 'assets/fonts/boostup-icons/timeline_alert_dot_red.svg';

import { ThenaRequest } from './types';

export const crmActivityColors = [
  'rgb(100,202,230, 0.8)',
  'rgb(235,133,163, 0.8)',
  'rgb(97,227,125, 0.8)',
  'rgb(125,132,170, 0.8)',
  'rgb(194,142,215, 0.8)',
  'rgb(205,173,113, 0.8)',
];

export const emailDropdownOptions = [
  { text: 'All Emails', value: 'all_emails' },
  { text: 'Important Emails', value: 'important_emails' },
];

export const timespanToSubtract: { [key: string]: (string | number)[] } = {
  L7D: [7, 'days'],
  L14D: [14, 'days'],
  L30D: [30, 'days'],
  L3M: [3, 'months'],
  L6M: [6, 'months'],
};

export const getCallIcon = (
  source: string,
  eventType?: 'mouseOut' | 'mouseOver'
): string => {
  if (source && source === 'Mindtickle') {
    return MindtickleCellIcon;
  } else {
    if (eventType === 'mouseOver') {
      return PlayCallIconActive;
    } else {
      return PlayCallIcon;
    }
  }
};

export const getThenaDotCustomColor = (thenaRequest: ThenaRequest) =>
  thenaRequest.urgency === 'high' && !thenaRequest.closed_at
    ? '#F05A5A'
    : '#0EB0E3';

export const getThenaDotCustomSymbol = (thenaRequest: ThenaRequest) =>
  !thenaRequest.closed_at
    ? thenaRequest.urgency === 'high'
      ? `url(${TimelineAlertDotRedIcon})`
      : `url(${TimelineAlertDotBlueIcon})`
    : 'circle';
