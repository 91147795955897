import classNames from 'classnames';
import { css } from 'emotion';
import * as R from 'ramda';
import React from 'react';
import { Checkbox, Icon } from 'semantic-ui-react';

import BuCheckbox from 'components/UI/BuCheckbox';
import BuToggle from 'components/UI/BuToggle';
import {
  IDataCellProps,
  isDisable,
  isEditable,
  TypedTableCellConfig,
  ValueType,
} from 'components/UI/common/TypedTable/TypedTable';
import NotAvailableCell from 'components/UI/common/TypedTable/renderers/NotAvailableCell';

export interface CheckboxConfig extends TypedTableCellConfig {
  style?: 'checkbox' | 'toggle' | 'slider';
  checkboxValues?: {
    trueValue: ValueType;
    falseValue: ValueType;
  };
}

export const isCheckboxConfig = (
  config: TypedTableCellConfig
): config is CheckboxConfig => 'style' in config || 'checkboxValues' in config;

const removeMargin = css`
  ó &.ui.checkbox {
    margin-top: 8px;
    margin-bottom: 0;
  }
`;

const removeMarginToggle = css`
  &.ui.checkbox {
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;

const center = css`
  &.ui.checkbox {
    margin-left: auto;
    margin-right: auto;
    display: table;
  }
`;

const CheckboxCell = ({
  column,
  row,
  rows,
  onChange = () => {},
}: IDataCellProps) => {
  const rawValue = R.path<boolean>(column.field.split('.'), row);

  const config = R.pathOr<CheckboxConfig>({}, [], column.config);

  const handleChange = (checked: boolean) => {
    onChange(
      column,
      row,
      config.checkboxValues
        ? !checked
          ? config.checkboxValues?.falseValue
          : config.checkboxValues?.trueValue
        : !!checked
    );
  };

  const isChecked = config.checkboxValues
    ? config.checkboxValues?.trueValue === rawValue
    : rawValue;

  if (!isEditable(column, row, rows)) {
    return (
      <div
        className={classNames('ui', 'fitted', 'checkbox', removeMargin, center)}
      >
        {rawValue === undefined ? (
          <NotAvailableCell />
        ) : (
          <Icon name={isChecked ? 'check' : 'cancel'} color="grey" />
        )}
      </div>
    );
  }

  const classesToAdd =
    config.style === 'toggle'
      ? [removeMarginToggle, center]
      : [removeMargin, center];

  if (config.style === 'toggle') {
    return (
      <BuToggle
        disabled={isDisable(column, row, rows)}
        checked={isChecked}
        className={classNames(classesToAdd)}
        onChange={(e) => handleChange(e.target.checked)}
      />
    );
  }

  if (config.style === 'slider') {
    return (
      <Checkbox
        slider={config.style === 'slider'}
        disabled={isDisable(column, row, rows)}
        checked={isChecked}
        className={classNames(classesToAdd)}
        onChange={(e, { checked }) => handleChange(checked || false)}
      />
    );
  }

  return (
    <BuCheckbox
      disabled={isDisable(column, row, rows)}
      checked={isChecked}
      onChange={handleChange}
    />
  );
};

export default CheckboxCell;
