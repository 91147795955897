import axiosInstance from './axiosInstance';

import { GetSpecificFiltersForAPI } from 'selectors';

const TRENDS_URL = '/api/data/forecast/change';

export type GetTrendsPayload = GetSpecificFiltersForAPI<{
  change_interval: string;
  forecast_category_names: string[] | undefined;
  business_type_name: string;
  split_view: boolean | undefined;
  include_disabled: boolean | undefined;
  close_date_interval: string;
  time_span: string;
  managers?: string[];
}>;

export type GetTrendsApiResponse = {
  data: {
    series: [
      {
        data: TrendItemResponse[];
      }
    ];
  };
};

export type ForecastSeries =
  /** A Bar */
  | 'prior_forecast'

  /** New Bar */
  | 'new_deals'

  /** Pulled Bar */
  | 'pulled'

  /** + Value Bar */
  | 'plus_value'

  /** Pushed Bar */
  | 'pushed'

  /** - Value Bar */
  | 'minus_value'

  /** Won Bar */
  | 'won'

  /** Lost Bar */
  | 'lost'

  /** B Bar */
  | 'current_forecast';

export interface TrendItemResponse {
  ids: string[];
  label: string;
  color: string;
  count: number;
  total_amount: number;
  high: number;
  low: number;
  filters: {
    forecast_series: ForecastSeries;
    now: string;
  };
}

export const getTrends = async (
  payload: GetTrendsPayload
): Promise<TrendItemResponse[]> => {
  const res = await axiosInstance.post<GetTrendsApiResponse>(
    TRENDS_URL,
    payload
  );

  return res.data.data.series[0].data;
};
