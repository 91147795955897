import React, { ChangeEvent, useMemo, useState } from 'react';
import {
  MetricSubValue,
  SubValueConfiguration,
  subValueSelect,
  SubValueWrapper,
} from '../styles';
import BuInput from 'components/UI/BuInput';
import BuSelect from 'components/UI/BuSelect';
import {
  BIMetricCreated,
  MetricDisplayInfo,
  SubValueDisplayInfo,
} from 'components/dashboard/Metrics/metrics.types';
import { SubValuesData } from 'api/RevBiDropdownConfigs';
import BuRadio from 'components/UI/BuRadio';
import { CheckboxProps } from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import { isBIMetricSimple } from 'components/dashboard/Metrics/Create/utils';
import {
  FORECAST_AMOUNT_ACCURACY,
  FORECAST_DEAL_ACCURACY,
} from 'components/dashboard/Metrics/constants';

interface SubValueConfigurationProps {
  metric: BIMetricCreated;
  className: string;
  subValue: SubValueDisplayInfo | undefined;
  subValuesData: SubValuesData | undefined;
  isLoadingSubValueList: boolean;
  metricDisplayConfig: MetricDisplayInfo;
  onChange: (metricId: string, metricDisplayConfig: MetricDisplayInfo) => void;
  triggerDelayedSubValueTemplateChange: (
    metricId: string,
    value: string
  ) => void;
}

export const SubValueConfigurationComponent: React.FC<
  SubValueConfigurationProps
> = ({
  className,
  metric,
  subValue,
  isLoadingSubValueList,
  subValuesData,
  metricDisplayConfig,
  onChange,
  triggerDelayedSubValueTemplateChange,
}) => {
  const isFSAccuracy = useMemo(() => {
    if (isBIMetricSimple(metric)) {
      return [FORECAST_AMOUNT_ACCURACY].includes(
        metric.forecast_submission_properties?.metric_type ?? ''
      );
    }
    return false;
  }, [metric]);
  const [isBookedSubValue, setIsBookedSubValue] = useState<boolean>(
    !!subValue?.field_name.includes('not_saved_booked_amount')
  );

  const [subValueTemplate, setSubValueTemplate] = useState<string>(
    subValue?.template ?? ''
  );

  const handleRadioChange = (
    event: ChangeEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => {
    const value = data.value;

    if (value === 'showBooked') {
      setIsBookedSubValue(true);
      onChange(metric._id, {
        ...metricDisplayConfig,
        subvalues: {
          [`${metric._id}`]: {
            display_name: 'Booked',
            field_name: `not_saved_booked_amount_${metric._id}`,
            type: 'money',
            object: 'forecast',
            template: 'Booked',
          },
        },
      });
    } else {
      setIsBookedSubValue(false);
    }
  };

  return (
    <SubValueConfiguration className={className}>
      {isFSAccuracy && (
        <BuRadio
          label="Add Metric"
          value="addMetric"
          checked={!isBookedSubValue}
          onChange={handleRadioChange}
        />
      )}
      <div className={MetricSubValue}>
        <BuInput
          placeholder=""
          style={{ width: '100px' }}
          maxLength={18}
          disabled={!subValue?.field_name || isBookedSubValue}
          value={subValueTemplate}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.currentTarget.value;
            setSubValueTemplate(value);
            triggerDelayedSubValueTemplateChange(metric._id, value);
          }}
        />
        <SubValueWrapper>
          <BuSelect
            className={subValueSelect}
            placeholder="Select a Metric"
            fullWidth
            disabled={isLoadingSubValueList || isBookedSubValue}
            secondary
            searchable
            options={subValuesData?.subValueOptions ?? []}
            defaults={[subValue?.field_name ?? '']}
            isLargePlaceholder
            onChange={(value: string[]) => {
              const newSubValue = subValuesData?.subValueList.find(
                (item) => item.field_name === value[0]
              );

              if (newSubValue) {
                // as a SubValue we wanna only one value for now.
                onChange(metric._id, {
                  ...metricDisplayConfig,
                  subvalues: {
                    [value[0]]: { ...newSubValue },
                  },
                });
              }

              setSubValueTemplate('');
            }}
          />
        </SubValueWrapper>
      </div>
      {isFSAccuracy && (
        <BuRadio
          label="Show Booked as a sub value"
          value="showBooked"
          checked={isBookedSubValue}
          onChange={handleRadioChange}
        />
      )}
    </SubValueConfiguration>
  );
};
