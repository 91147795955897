import { css } from 'emotion';

export const singleTotalContainer = css`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: stretch;
  padding: 8px 8px 8px 16px;
`;

export const singleTotalItemContainer = ({
  fontSize,
  isClickable,
}: {
  fontSize: number;
  isClickable: boolean;
}) => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  flex: 1 1 min(33%, 200px);

  ${isClickable ? 'cursor: pointer !important;' : 'cursor: auto;'}

  span {
    font-size: ${fontSize * 3}px;
  }
`;

export const multipleTotalsContainer = css`
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 16px;
`;

export const multipleTotalsInnerContainer = css`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const multipleTotalCardsContainer = ({
  fontSize,
}: {
  fontSize: number;
}) => css`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  height: 100%;
  align-items: stretch;

  & > div {
    margin: unset;
    border: solid var(--bu-gray-400) 1px;
  }

  & > div:first-child {
    border-radius: 4px 0 0 4px;
  }

  & > div:last-child {
    border-radius: 0 4px 4px 0;
  }

  span {
    line-height: 100%;
    width: 100%;
    height: 100%;
    font-size: ${fontSize}px;
  }
`;

export const multipleTotalCardItem = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &.clickable {
    cursor: pointer !important;
  }
`;

export const totalCardHalfSpace = css`
  flex: 1 1 min(50%, 200px);
`;

export const totalCardAThirdOfSpace = css`
  flex: 1 1 min(33%, 200px);
`;

export const icon = css`
  margin-right: 8px;
  font-size: 20px;
`;

export const pivotedTotalsContainer = css`
  display: flex;
  align-items: center;
  padding: 0 16px;
  gap: 8px;
  justify-content: flex-end;
`;

export const pivotedTotalsInnerContainer = css`
  display: flex;
  align-items: center;
  padding: 8px 0;
  width: 100%;
`;

export const pivotedTotalsValueContainer = css`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  height: 100%;
  align-items: stretch;
`;

export const pivotedTotalsItemContainer = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 2px;
  border: solid var(--bu-gray-400) 1px;
  border-radius: 4px;

  &.clickable {
    cursor: pointer !important;
  }
`;
