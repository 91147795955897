import { useQuery } from '@tanstack/react-query';
import { refreshSalesforceToken } from 'api/LinkedAccounts';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCompanyHasCRM,
  getCompanySettingsLoaded,
  getCrmType,
  getSalesforceEditPolicy,
  getSalesforceLoginUrl,
  getUser,
  isImpersonate,
} from 'selectors';

import { getSettingsUsers } from 'actions/settingsActions';
import { doGetSalesforceOAuthToken } from 'actions/userActions';
import { REGRESSION_KEY, USER_ROLES } from 'common/constants';
import {
  SALESFORCE_CALLBACK_URL,
  SALESFORCE_CLIENT_ID,
  SALESFORCE_LOGIN_URL,
} from 'components/UI/SalesforceButton/SalesforceButton';
import SalesforceOAuth from 'components/login/salesforce-oauth';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
export const SKIP_CONNECT_SALESFORCE_KEY = 'skipSalesforceConnection';

const NOT_APPROVED_ERROR = 'user is not admin approved to access this app';
const NON_APPROVED_ERROR_USER_MESSAGE =
  'You have successfully logged in, but it appears that you do not have the necessary permissions to access. Please contact your system administrator to request the appropriate permissions.';

const _12_HOURS = 1000 * 60 * 60 * 12;

const getIfUserSkipped = (email: string) => {
  const userSkipKey = getSkipKeyForUserMail(email);
  const skippedConfigRaw = localStorage.getItem(userSkipKey);

  if (!skippedConfigRaw) {
    return false;
  }

  const skippedConfig = JSON.parse(skippedConfigRaw);

  const now = new Date().getTime();

  const skippedOn = new Date(skippedConfig.skippedOn).getTime();

  const diff = now - skippedOn;

  return diff < _12_HOURS;
};

export const clearUserSkipSelection = (email: string) => {
  const userSkipKey = getSkipKeyForUserMail(email);
  localStorage.removeItem(userSkipKey);
};

const getSkipKeyForUserMail = (email: string) =>
  `${SKIP_CONNECT_SALESFORCE_KEY}#${email}`;

const useVerifySalesforceLinking = () => {
  const companySettingsLoaded = useSelector(getCompanySettingsLoaded);
  const salesforceEditPolicy = useSelector(getSalesforceEditPolicy);
  const user = useSelector(getUser);
  const crmType = useSelector(getCrmType);
  const hasCrm = !!useSelector(getCompanyHasCRM);
  const isImpersonatingUser = useSelector(isImpersonate);
  const isRegression = localStorage.getItem(REGRESSION_KEY);

  const isSalesforce = crmType === 'salesforce';
  const isUserPolicy = salesforceEditPolicy === 'user-only';

  const [userSkipped, setUserSkipped] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState<string>(
    NON_APPROVED_ERROR_USER_MESSAGE
  );

  // Needed as a reference to check if the user that skipped the option is the same as the current user
  // As when the user changes (because of impersonation for example), we need to update the skip option
  // but avoid doing the call and showing the modal until we update the skip option for the new user
  const skippedStateIsForUser = useRef<any>();

  useEffect(() => {
    if (user) {
      skippedStateIsForUser.current = user;
      setUserSkipped(getIfUserSkipped(user.email));
      setErrorMessage('');
    }
  }, [user]);

  const dependenciesLoaded =
    companySettingsLoaded &&
    !!user &&
    // Need to wait until the skipped option is updated for the new user
    skippedStateIsForUser.current?.email === user.email;

  const userRequiredToConnect = isUserPolicy && hasCrm && isSalesforce;

  const location = useLocation();
  const isAdminPage = location.pathname.includes('/admin/dashboard');

  const enabled =
    dependenciesLoaded &&
    userRequiredToConnect &&
    !userSkipped &&
    !isAdminPage &&
    !isRegression;

  const { data, isSuccess, refetch } = useQuery({
    queryKey: ['refresh_salesforce_token', user],
    queryFn: () => refreshSalesforceToken(),
    enabled,
  });

  const skipConnection = () => {
    const skippedConfig = {
      skippedOn: new Date(),
    };

    const userSkipKey = getSkipKeyForUserMail(email);

    localStorage.setItem(userSkipKey, JSON.stringify(skippedConfig));
    setUserSkipped(true);
  };

  const salesforceLoginUrl = useSelector(getSalesforceLoginUrl);
  const dispatch = useDispatch();

  const { email, api_access_token } = useSelector(getUser);

  const authSalesforce = async () => {
    let oauth = new SalesforceOAuth(
      SALESFORCE_CLIENT_ID,
      salesforceLoginUrl || SALESFORCE_LOGIN_URL,
      SALESFORCE_CALLBACK_URL
    );

    try {
      const response = await oauth.login();

      const authCode = response.code;
      const salesforceSync = () => {
        dispatch(getSettingsUsers({ user_email: email }));
        refetch();
      };

      dispatch(
        doGetSalesforceOAuthToken(
          email,
          api_access_token,
          authCode,
          salesforceSync
        )
      );
    } catch (error: any) {
      if (
        error?.error_type === 'OAUTH_APP_ACCESS_DENIED' &&
        error?.error_description === NOT_APPROVED_ERROR
      ) {
        setErrorMessage(NON_APPROVED_ERROR_USER_MESSAGE);
      }
    }
  };

  const linkAccountDisabled =
    isImpersonatingUser &&
    user?.originalUser?.role !== USER_ROLES.SALES_OPERATIONS;

  const showConnectSalesforceModal =
    enabled && isSuccess && data && !data.salesforce_service_working;

  const username = user?.name;

  return {
    showConnectSalesforceModal,
    isImpersonatingUser,
    linkAccountDisabled,
    username,
    errorMessage,

    skipConnection,
    authSalesforce,
  };
};

export default useVerifySalesforceLinking;
