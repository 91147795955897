import { css } from 'emotion';

import { fontBody, fontCaption2 } from 'assets/css/fontStyles';

export const linkToWidget = css`
  border: 1px solid var(--bu-gray-400);
  min-width: 200px !important;
  height: 32px;
  border-radius: 4px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;

export const overrideMenuZIndex = css`
  z-index: 135 !important;
`;

export const linkToWidgetContainer = css`
  display: flex;
  flex-direction: row;
  width: 700px;
  height: 220px;
`;

export const linkToWidgetSubjectObjectContainer = css`
  display: flex;
  flex-direction: column;
  width: 130px;
  height: 100%;
  border-right: 1px solid var(--bu-gray-400);
  span {
    ${fontCaption2}
    margin: 15px;
    color: var(--bu-gray-500);
    margin-bottom: 20px;
  }
`;

export const subjectObjectName = css`
  display: flex;
  ${fontCaption2}
  color: var(--bu-black);
  border-radius: 4px;
  height: 28px;
  padding-left: 11px;
  align-items: center;
  margin-left: 6px;
  margin-right: 8px;
  cursor: default;
  &:hover {
    background-color: var(--bu-gray-100);
  }
  &.selected {
    background-color: var(bu-primary-500);
    color: var(--bu-white);
  }
`;

export const linkToWidgetMainContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const optionsContainer = css`
  display: flex;
  flex-direction: column;
  max-height: 310px;
  overflow-y: auto;
  width: 100%;
`;

export const widgetSearch = css`
  width: 100%;
  border-bottom: 1px solid var(--bu-gray-400);
  input {
    border: unset !important;
    border-radius: unset !important;
  }
`;

export const fieldContainer = css`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 6px 17px;
  cursor: default;
  &:hover {
    background-color: var(--bu-gray-100);
  }

  &.selected {
    background-color: var(bu-primary-100);
  }
`;

export const fieldDisplayName = css`
  ${fontBody}
  color: var(--bu-black);
`;

export const objectName = css`
  ${fontBody}
  color: var(--bu-gray-600);
`;
