import { connect, MapStateToPropsParam } from 'react-redux';

import UserReviewList from 'components/settings/ManageUsers/UsersAddView/AddUserSteps/UserReviewList/UserReviewList';
import {
  IOwnProps,
  IStateProps,
} from 'components/settings/ManageUsers/UsersAddView/AddUserSteps/UserReviewList/types';
import { IReduxState } from 'reducers/types';
import { getUser } from 'selectors';
import { getCompanyManagerList } from 'selectors/companyUsers';

export const mapStateToProps: MapStateToPropsParam<
  IStateProps,
  IOwnProps,
  IReduxState
> = (state) => ({
  managers: getCompanyManagerList(state),
  currentUserRole: getUser(state).role,
});

export default connect(mapStateToProps)(UserReviewList);
