import _ from 'lodash';
import * as R from 'ramda';
import { createSelector } from 'reselect';

const getTopicsAndCompetitorsList = R.compose(
  R.values,
  R.path(['topics', 'byName'])
);
export const getTopicsList = createSelector(
  [getTopicsAndCompetitorsList],
  R.reject(R.prop('is_competitor'))
);
export const getSortedTopicsList = createSelector(
  [getTopicsList],
  R.sortBy(R.compose(R.toUpper, R.prop('name')))
);
export const getCompetitorsList = createSelector(
  [getTopicsAndCompetitorsList],
  R.filter(R.prop('is_competitor'))
);
export const getSortedCompetitorsList = createSelector(
  [getCompetitorsList],
  R.sortBy(R.compose(R.toUpper, R.prop('name')))
);
export const isTopicsLoading = (state) => state.topics.loading;

export const getDealTopics = (state, objId) =>
  _.get(state.topics, `byObjId.${objId}.topic_timeline`, []);
export const getDealTopicCompetitors = (state, objId) =>
  _.get(state.topics, `byObjId.${objId}.competitor_timeline`, []);
