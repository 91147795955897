import * as R from 'ramda';
import { createSelector } from 'reselect';

export const getUser = createSelector([(state) => state.user], (user) => user);
export const getUserRole = createSelector([getUser], R.prop('role'));
export const getRegistrationToken = createSelector(
  [getUser],
  R.prop('registrationToken')
);
export const isUserRole = (state, roles) => R.includes(state.user.role, roles);
export const isReadOnlyUser = createSelector([getUser], ({ read_only }) =>
  Boolean(read_only)
);
