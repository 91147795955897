import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { actions } from 'actions';
import { AppStateRecord } from 'navigation/types';

export type IState = {
  [path: string]: AppStateRecord;
};

const initialState: IState = {};

const reducer = reducerWithInitialState<IState>(initialState)
  .case(actions.ui.preferences.user.loaded, (state, { user }): IState => {
    const { value } =
      user.find(({ key_name }) => key_name === 'appState') || {};

    return value ? { ...state, ...value } : { ...state };
  })
  .case(
    actions.ui.appState.persistMetricsStatus,
    (state, { path, expanded }): IState => ({
      ...state,
      [path]: {
        ...(state[path] || {}),
        metrics: { expanded },
      },
    })
  )
  .case(actions.ui.appState.resizeColumn, (state, { path, column }): IState => {
    if (state[path]) {
      return {
        ...state,
        [path]: {
          ...(state[path] || {}),
          sizeColumns: state[path].sizeColumns.some((i) => i.id === column.id)
            ? state[path].sizeColumns.map((el) => {
                if (el.id === column.id) {
                  return { width: column.width, id: column.id };
                } else {
                  return el;
                }
              })
            : [...state[path].sizeColumns, column],
        },
      };
    }

    return {
      ...state,
      [path]: {
        showOrHideColumns: state[path]
          ? [...state[path].showOrHideColumns]
          : [],
        metrics: { expanded: false },
        sizeColumns: [column],
        sequenceColumns: [],
      },
    };
  })
  .case(
    actions.ui.appState.setShowOrHideColumns,
    (state, { path, column }): IState => {
      if (state[path]) {
        const prevShowOrHideColumns = state[path].showOrHideColumns || [];
        const isColumn = prevShowOrHideColumns.some(
          (i) => i.label === column.label
        );

        return {
          ...state,
          [path]: {
            ...state[path],
            showOrHideColumns: isColumn
              ? prevShowOrHideColumns.map((i) => {
                  if (i.label === column.label) {
                    return {
                      label: column.label,
                      show: column.show,
                      id: column.id,
                    };
                  } else {
                    return i;
                  }
                })
              : [...prevShowOrHideColumns, column],
          },
        };
      }

      return {
        ...state,
        [path]: {
          sizeColumns: [],
          metrics: { expanded: false },
          showOrHideColumns: [column],
          sequenceColumns: [],
        },
      };
    }
  )
  .case(
    actions.ui.appState.replaceShowOrHideColumns,
    (state, { path, columns }): IState => {
      if (state[path]) {
        return {
          ...state,
          [path]: {
            ...(state[path] || {}),
            showOrHideColumns: columns,
          },
        };
      }
      return {
        ...state,
        [path]: {
          sizeColumns: [],
          metrics: { expanded: false },
          showOrHideColumns: columns,
          sequenceColumns: [],
        },
      };
    }
  )
  .case(
    actions.ui.appState.setSequenceColumns,
    (state, { path, columns }): IState => {
      if (state[path]) {
        return {
          ...state,
          [path]: {
            ...(state[path] || {}),
            sequenceColumns: columns,
          },
        };
      }

      return {
        ...state,
        [path]: {
          sizeColumns: [],
          metrics: { expanded: false },
          showOrHideColumns: [],
          sequenceColumns: columns,
        },
      };
    }
  );

export const appState = { initialState, reducer };
