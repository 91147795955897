import classnames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { ModalRouteProps } from 'navigation/types';
import { IReduxState } from 'reducers/types';
import { ThenaRequest } from 'components/UI/ProspectEngagement/types';
import BuIcon from 'components/UI/BuIcon';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import {
  INFO_KEY_LIST,
  LIST_NAME_FROM_INFO_KEY,
  SPECIAL_LINK_NAMES,
  getIsSpecialLinkName,
  getListValueFromInfoKey,
} from './helpers';
import { infoListItem, infoListWrapper, modalContainer } from './styles';
import * as selectors from 'selectors';

type Props = ModalRouteProps<{ thenaRequestsId: string }>;

const ThenRequestsModal: React.FC<Props> = ({ params }) => {
  const [entityId, requestId] = params.thenaRequestsId.split('_');
  const thenaRequest: ThenaRequest = useSelector(
    (state: IReduxState) =>
      selectors
        .getEngagementAccountThenaRequests(state, entityId)
        .find((record: ThenaRequest) => record.request_id === requestId) || {}
  );

  const listValueFromInfoKey: Partial<ThenaRequest> =
    getListValueFromInfoKey(thenaRequest);

  return (
    <div className={modalContainer}>
      <div className={infoListWrapper}>
        {INFO_KEY_LIST.map((infoKey) => {
          const itemName = LIST_NAME_FROM_INFO_KEY[infoKey];
          const itemValue = listValueFromInfoKey[infoKey];

          if (!itemValue) return null;

          return (
            <div className={infoListItem}>
              <div
                className={classnames('item-name', {
                  'name-on-top': ['summary'].includes(infoKey),
                })}
              >
                {itemName && `${itemName}:`}
              </div>

              <div
                className={classnames('item-value', {
                  'no-capitalize': ['requestor'].includes(infoKey),
                })}
              >
                {Array.isArray(itemValue) &&
                  infoKey === 'ai_tags' &&
                  itemValue.map((aiTag) => (
                    <div className={classnames('background', 'ai-tags')}>
                      {aiTag}
                    </div>
                  ))}

                {typeof itemValue === 'string' && (
                  <div
                    className={classnames({
                      background: ['sentiment'].includes(infoKey),
                      [itemValue]: ['negative', 'positive'].includes(
                        itemValue.toLowerCase()
                      ),
                    })}
                  >
                    {getIsSpecialLinkName(infoKey) ? (
                      <a href={itemValue} target="_blank">
                        {SPECIAL_LINK_NAMES[infoKey]}{' '}
                        <BuIcon name={BoostUpIcons.ChevronRight} />
                      </a>
                    ) : (
                      <span>{itemValue}</span>
                    )}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ThenRequestsModal;
