import { FC, useMemo } from 'react';
import { Dropdown } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import { VisualizationType } from 'components/dashboard/Metrics/enums';
import { TotalMetricVisualization } from '../helper';
import { DropdownOption } from './Option/DropdownOption';
import * as s from './styles';

export type OnVisualizationChange = (
  metricId: string,
  chartType: VisualizationType
) => void;

interface Props {
  metricId: string;
  visualization: TotalMetricVisualization;
  onVisualizationChange: OnVisualizationChange;
}

export const VisualizationDropdown: FC<Props> = ({
  metricId,
  visualization,
  onVisualizationChange,
}) => {
  const visualizationOptions = visualization.options;
  const visualizationSelected = visualization.selected;
  const iconName = useMemo(
    () =>
      Object.values(visualizationOptions)
        .flatMap((el) => el)
        .filter((el) => !el.disabled)
        .find((el) => el.chartType === visualizationSelected)?.icon ||
      BoostUpIcons.BadgeWarningOutline,
    [visualizationOptions, visualizationSelected]
  );

  const hasPivot1Options = visualizationOptions.pivot1.some(
    (el) => !el.disabled
  );
  const hasPivot2Options = visualizationOptions.pivot2.some(
    (el) => !el.disabled
  );

  return (
    <Dropdown
      fluid
      direction="left"
      data-testing="total-chart-type"
      className={s.totalChartTypeDropdown}
      trigger={<BuIcon className={s.totalChartTypeIcon} name={iconName} />}
      icon={
        <BuIcon
          className={s.totalChartTypeDropdownIcon}
          name={BoostUpIcons.TriangleDown}
        />
      }
      disabled={iconName === BoostUpIcons.BadgeWarningOutline}
    >
      <Dropdown.Menu className={s.totalChartTypeDropdownMenu}>
        {hasPivot1Options && (
          <>
            <div className={s.pivotVisualizationLabel}>
              1st pivot visualization
            </div>

            {visualizationOptions.pivot1
              .filter((el) => !el.disabled)
              .map((el) => (
                <DropdownOption
                  key={el.displayName}
                  metricId={metricId}
                  option={el}
                  onVisualizationChange={onVisualizationChange}
                />
              ))}
          </>
        )}

        {hasPivot2Options && (
          <>
            <div className={s.pivotVisualizationLabel}>
              2nd pivot visualization
            </div>

            {visualizationOptions.pivot2
              .filter((el) => !el.disabled)
              .map((el) => (
                <DropdownOption
                  key={el.displayName}
                  metricId={metricId}
                  option={el}
                  onVisualizationChange={onVisualizationChange}
                />
              ))}
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
