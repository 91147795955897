import { connect } from 'react-redux';

import * as dealsActions from 'actions/dealsActions';
import { MANAGER_ROLES } from 'common/constants';
import Dashboard from 'components/chart-dashboards/Dashboard/Dashboard';
import {
  StateProps,
  OwnProps,
} from 'components/chart-dashboards/Dashboard/types';
import { dashboardToIndex } from 'navigation/routes';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

const mapStateToProps = (
  state: IReduxState,
  ownProps: OwnProps
): StateProps => ({
  personalActivityProps:
    'email' in ownProps
      ? selectors.getModalProps(state, '/activity/personal/:email')
      : undefined,
  dashboard: selectors.getMetricsDashboard(
    state,
    'email' in ownProps ? '2' : dashboardToIndex[ownProps.match.path]
  ) as any,
  isManager: selectors.isUserRole(state, Object.values(MANAGER_ROLES)),
  tabPartition:
    !('email' in ownProps) &&
    (ownProps.match.path === '/dashboard/leaderboard' ||
      ownProps.match.path === '/dashboard/seller')
      ? 'dashboards'
      : 'activity',
});

const dispatchToProps = {
  toggleAllFilters: dealsActions.toggleAllFilters,
};

export default connect(mapStateToProps, dispatchToProps)(Dashboard);
