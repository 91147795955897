import { isNil } from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import BuButton from 'components/UI/BuButton';
import BuSkeleton from 'components/UI/BuSkeleton';
import { Condition } from 'components/dashboard/Metrics/Create/Conditions/Condition/Condition';
import * as s from 'components/dashboard/Metrics/Create/Conditions/styles';
import {
  AnalysisType,
  COLUMN_TYPES,
} from 'components/dashboard/Metrics/constants';
import { parseFilters } from 'components/dashboard/Metrics/metrics.helpers';
import {
  DataDescriptor,
  BIMetricsFilter,
  BIMetricSimple,
  BIMetricsQueryFilter,
  BIMetricUnion,
  ExternalColumnFieldFilter,
} from 'components/dashboard/Metrics/metrics.types';
import { QueryStatus, fetchApi } from 'utils/network';

interface Props {
  readonly title?: string;
  readonly description?: string;
  readonly metric: BIMetricUnion;
  readonly columnFields: string[];
  onCompleteConditions: (
    complete: boolean,
    filter: BIMetricsQueryFilter[]
  ) => void;
}

export const Conditions: React.FC<Props> = ({
  title,
  description,
  metric,
  columnFields,
  onCompleteConditions,
}) => {
  const [status, setStatus] = useState<QueryStatus>('notAsked');
  const [columns, setColumns] = useState<DataDescriptor[]>([]);
  const [conditions, setConditions] = useState<BIMetricsQueryFilter[]>(
    metric?.filters ?? []
  );

  /**
   * We need to update local filters with filters on the metric.
   * This is an anti-pattern and abuse of useEffect. This needs to be rewritten.
   * There should be one state and overuse of useEffect should be avoided.
   */
  useEffect(() => {
    if (JSON.stringify(metric?.filters) !== JSON.stringify(conditions)) {
      setConditions(metric?.filters);
    }
  }, [metric?.filters]);

  /**
   * this function fetches the available conditions for the columns list provided
   * except for historical metric that uses it's own API
   */
  useEffect(() => {
    const abortController = new AbortController();

    if (metric.analysis_type === AnalysisType.HISTORICAL) {
      fetchApi<void, DataDescriptor[]>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_column_fields_filter_history`,
        queryMethod: 'get',
        setData: (fields: DataDescriptor[]) => {
          setColumns(parseFilters(fields));
        },
        setError: (error: string | null) => {
          setColumns([]);
          toast.error(`Failed to load column fields filters: ${error}`);
        },
        setStatus: setStatus,
        signal: abortController.signal,
      });
    } else if (columnFields.length) {
      fetchApi<ExternalColumnFieldFilter, DataDescriptor[]>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_column_fields_filter`,
        queryMethod: 'post',
        queryParams: {
          tables: columnFields,
        },
        setData: (fields: DataDescriptor[]) => {
          setColumns(parseFilters(fields));
        },
        setError: (error: string | null) => {
          setColumns([]);
          toast.error(`Failed to load column fields filters: ${error}`);
        },
        setStatus: setStatus,
        signal: abortController.signal,
      });
    }

    return () => {
      setColumns([]);
      abortController.abort();
    };
  }, [JSON.stringify(columnFields), metric.analysis_type]);

  const handleAddCondition = (): void => {
    if (canAddNewCondition) {
      setConditions([...(conditions ?? []), {}] as BIMetricsQueryFilter[]);
    }
  };

  const handleRemoveCondition = (index: number): void => {
    const newFilters = [
      ...conditions.slice(0, index),
      ...conditions.slice(index + 1),
    ];
    setConditions(newFilters);
  };

  const handleAddFilterCondition = (
    filter: Partial<BIMetricsFilter>,
    idx: number
  ): void => {
    const currentFilters: BIMetricsQueryFilter[] = conditions ?? [];
    const newFilters: BIMetricsQueryFilter[] = [
      ...currentFilters.slice(0, idx),
      { and_condition: [{ or_condition: [filter as BIMetricsFilter] }] },
      ...currentFilters.slice(idx + 1),
    ];
    setConditions(newFilters);
  };

  const selectedFiltersColumnNames: string[] = useMemo(
    () =>
      conditions?.flatMap((filter) =>
        filter?.and_condition?.flatMap((a) =>
          a?.or_condition?.flatMap((b) => b.column.name)
        )
      ),
    [conditions]
  );

  const canAddNewCondition: boolean = useMemo(
    () => (conditions?.length ?? 0) < columns?.length,
    [conditions?.length, columns?.length]
  );

  const forecastPeriodType = useMemo(() => {
    let forecastPeriodTypeData = '';
    conditions.forEach((condition) => {
      const { and_condition = [] } = condition;
      const { or_condition = [] } = and_condition.length
        ? and_condition[0]
        : {};
      const { value = [], column = { name: '' } } = or_condition.length
        ? or_condition[0]
        : {};

      if (column.name === 'forecast_submission.period_type') {
        forecastPeriodTypeData = (value as string[])[0] ?? '';
      }
    });

    return forecastPeriodTypeData;
  }, [conditions]);

  const getOptions = (filter: BIMetricsQueryFilter): DataDescriptor[] => {
    const thisFilterColumnNames = filter?.and_condition?.flatMap((f) =>
      f?.or_condition?.flatMap((g) => g.column.name)
    );
    const usedColumnsInConditions = selectedFiltersColumnNames?.filter(
      (e) => !thisFilterColumnNames?.includes(e)
    );

    return columns.filter(
      (c) =>
        !usedColumnsInConditions?.includes(c.name) && COLUMN_TYPES.has(c.type)
    );
  };

  const skeleton =
    status === 'loading' &&
    conditions.map((_, idx) => (
      <div key={idx} className={s.skeletonWrapper}>
        <BuSkeleton height={25} width="100%" />
      </div>
    ));

  useEffect(() => {
    const complete =
      !!conditions.length &&
      conditions.every((filter: BIMetricsQueryFilter) =>
        filter?.and_condition?.every((orConditions) =>
          orConditions?.or_condition.every((condition) =>
            Boolean(
              condition?.column?.name &&
                condition?.operator &&
                !isNil(condition?.value) &&
                condition?.value !== ''
            )
          )
        )
      );
    onCompleteConditions(complete, conditions);
  }, [conditions]);

  return (
    <div className={s.conditionsContainer} data-testing="conditions-section">
      <div className={s.flexColumn}>
        <div className={s.conditionSubTitle({ isFunnel: !!title })}>
          {title ? title : 'Conditions'}
        </div>

        {!!description && (
          <div className={s.conditionDescription}>{description}</div>
        )}

        {skeleton ||
          conditions?.map((filter, idx) => {
            const condition =
              filter?.and_condition?.[0]?.or_condition?.[0] ?? {};

            return (
              <div
                className={s.optionGroup}
                key={condition?.column?.name ?? `new-${idx}`}
                data-testing={`condition-${idx}`}
              >
                <Condition
                  columns={getOptions(filter)}
                  condition={condition}
                  disabled={status === 'loading'}
                  forecastPeriod={forecastPeriodType}
                  targetPeriod={(metric as BIMetricSimple)?.target_period ?? ''}
                  updateFilter={(newFilter: Partial<BIMetricsFilter>) =>
                    handleAddFilterCondition(newFilter, idx)
                  }
                  onRemove={() => handleRemoveCondition(idx)}
                />
              </div>
            );
          })}

        <div className={s.addButtonContainer}>
          <BuButton
            secondary
            className={canAddNewCondition ? s.addConditionButton : ''}
            disabled={!canAddNewCondition}
            onClick={handleAddCondition}
          >
            + Add condition
          </BuButton>
        </div>
      </div>
    </div>
  );
};
