import Highcharts, { PointerEventObject, XAxisOptions } from 'highcharts';

/**
 * scrolling chart using touchpad
 *
 * need to set :
 * @example Enable scrollbar
 * xAxis.scrollbar.enabled = true; // auto: xAxis.categories.length > xAxis.max
 *
 * @example hides fake x values
 * xAxis.labels.formatter() {
 *   if (typeof this.value !== 'number') {
 *     return this.value;
 *  }
 *  return '';
 * }
 *
 * @example Keep view port bounds after scrolling
 * xAxis.events.afterSetExtremes() {
 *   const xAxis = this,
 *     numberOfPoints = xAxis.series[0].points.length - 1,
 *     minRangeValue = xAxis.getExtremes().min,
 *     maxRangeValue = xAxis.getExtremes().max;
 *   if (minRangeValue < 0) {
 *     xAxis.setExtremes(xAxis.options.min!, xAxis.options.max!);
 *   } else if (maxRangeValue > numberOfPoints) {
 *     xAxis.setExtremes(
 *       numberOfPoints - xAxis.options.max!,
 *       numberOfPoints
 *     );
 *   }
 * },
 */
export default function (H: any) {
  //internal functions
  function stopEvent(e: Event) {
    if (e) {
      if (e.preventDefault) {
        e.preventDefault();
      }
      if (e.stopPropagation) {
        e.stopPropagation();
      }
      e.cancelBubble = true;
    }
  }

  //the wrap
  H.wrap(H.Chart.prototype, 'render', function (proceed: any) {
    //@ts-ignore
    const chart: Highcharts.Chart = this;

    proceed.call(chart);

    H.removeEvent(
      chart.container,
      document.onwheel === undefined ? 'DOMMouseScroll' : 'wheel'
    );

    // Add the mousewheel event
    H.addEvent(
      chart.container,
      document.onwheel === undefined ? 'DOMMouseScroll' : 'wheel',
      function (event: WheelEvent) {
        const xAxis = (chart.options.xAxis as XAxisOptions[])?.[0];
        if (
          xAxis?.scrollbar?.enabled &&
          xAxis?.events?.afterSetExtremes &&
          xAxis.labels?.formatter
        ) {
          let delta, newMin, newMax;

          const e = chart.pointer.normalize(event) as WheelEvent &
            PointerEventObject;
          // Firefox uses e.detail, WebKit and IE uses wheelDelta
          delta = e.detail || e.deltaX / 120;
          delta = delta < 0 ? 1 : -1;

          if (
            chart.isInsidePlot(
              e.chartX - chart.plotLeft,
              e.chartY - chart.plotTop
            )
          ) {
            const axis = chart.xAxis[0];
            const extr = axis.getExtremes();
            const diff = extr.max - extr.min;
            let step = diff / 5; /* move by fifths */
            step =
              step > 1
                ? Math.ceil(step)
                : 1; /* Min step is 1, Move by whole numbers */
            step = step * delta; /* Up/Down */
            // todo some logic for refuse to move ?
            if (step > 0) {
              /* UP */
              if (extr.max + step > extr.dataMax) {
                newMax = extr.dataMax;
                newMin =
                  extr.dataMax -
                  diff; /* Enforce window not getting too small */
              } else {
                newMin = extr.min + step;
                newMax = extr.max + step;
              }
            } else {
              /* DOWN */
              if (extr.min + step < 0) {
                newMin = 0;
                newMax = diff;
              } else {
                newMin = extr.min + step;
                newMax = extr.max + step;
              }
            }

            axis.setExtremes(newMin, newMax, true, false);
          }

          stopEvent(event); // Issue #5011, returning false from non-jQuery event does not prevent default
          return false;
        }
      }
    );
  });
}
