import { connect } from 'react-redux';

import EventsModal from 'components/modals/EventsModal/EventsModal';
import { PersistedProps } from 'components/modals/EventsModal/types';
import { IReduxState } from 'reducers/types';
import { getModalProps } from 'selectors';

const mapStateToProps = (state: IReduxState) => ({
  modalProps: getModalProps<PersistedProps>(state, '/events'),
});

export type ReduxProps = ReturnType<typeof mapStateToProps>;

export default connect<ReduxProps, {}, {}, IReduxState>(mapStateToProps)(
  EventsModal
);
