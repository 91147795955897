import styled from '@emotion/styled';

interface MetricDescriptionProps {
  readonly color: string;
  readonly hasMargin?: boolean;
}

export const MetricDescription = styled.div(
  ({ color, hasMargin }: MetricDescriptionProps) => ({
    padding: '1.5px 8px',
    width: 'fit-content',
    backgroundColor: `var(--bu-${color}-300)`,
    borderRadius: '12px',
    span: {
      fontSize: '12px',
      color: `var(--bu-${color}-700)`,
      whiteSpace: 'nowrap',
    },
    margin: hasMargin ? '14px 0px' : 'revert',
  })
);
