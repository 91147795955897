import { callActionType, selectedClassType } from '../actions/callActions';
import { notNullAndEmpty } from '../common/utils';
import moment from 'moment';
import * as _ from 'ramda';

// callReducer gets state.call as the input state
// Each action makes an appropriate API call
/**

state = {
  "events" : { ... }, // all events
  "calls" : { .... }, // all calls
  "callDetail" : { ... }, // call details for selected call
  "callSummary" : { ... }, // call summary for selected call
  "apiError" : { ... }, // if any api call results in error
  "page_number" : <int> // used in result pagination, specifies the page to fetch
}

**/

function getNewValue(state, key, value) {
  var new_value = null;
  if (key === 'events' || key === 'calls') {
    if (state['page_number'] !== 0) {
      // Append to these keys, replace other keys
      const all_key = 'all_' + key;
      const old_value = state[all_key];
      if (notNullAndEmpty(old_value)) {
        new_value = {
          ...old_value,
          ...value,
          [key]: old_value[key].concat(value[key]),
        };
      } else {
        new_value = value;
      }
    } else {
      new_value = value;
    }
  }
  return new_value;
}

function appendToState(state, key, value) {
  const new_value = getNewValue(state, key, value);
  if (!_.isNil(new_value)) {
    const new_key = 'all_' + key;
    return {
      ...state,
      [new_key]: new_value,
    };
  } else {
    return state;
  }
}

export const callReducer = function (
  state = {
    all_events: [],
    all_calls: [],
    events: [],
    calls: [],
    callDetail: null,
    callSummary: null,
    apiError: null,
    page_number: 0,
    time_min: moment().toISOString(),
    time_max: null,
    selected_class_type: selectedClassType.UPCOMING_CALLS,
  },
  action
) {
  switch (action.type) {
    case callActionType.UPDATE_STATE:
      var new_state;
      if (typeof action.key === 'string') {
        new_state = {
          ...state,
          [action.key]: action.value,
        };
        return appendToState(new_state, action.key, action.value);
      } else {
        new_state = {
          ...state,
          ...action.key,
        };
        const update = { ...action.key };
        for (var key in update) {
          new_state = appendToState(new_state, key, update[key]);
        }
        return new_state;
      }
    case callActionType.RESET_STATE:
      return {
        all_events: [],
        all_calls: [],
        events: [],
        calls: [],
        callDetail: null,
        callSummary: null,
        apiError: null,
        page_number: 0,
        time_min: moment().toISOString(),
        time_max: null,
        selected_class_type: selectedClassType.UPCOMING_CALLS,
      };
    default:
      return state;
  }
};
