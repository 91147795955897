import { css } from 'emotion';
import React from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { fontSubtitle } from 'assets/css/common';
import BuIcon from 'components/UI/BuIcon';

type IProps = {
  visible: boolean;
  title: string;
  onClose(): void;
  width?: number;
};

const container = css`
  border-left: 1px solid var(--bu-gray-400);
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: var(--bu-white);
`;

const header = css`
  background-color: var(--bu-white);
  display: flex;
  flex-direction: row;
  color: var(--bu-gray-900);
  padding: 25px 20px;
  border-bottom: 1px solid var(--bu-gray-400);
  align-items: center;
  height: 40px;

  > h3 {
    flex: 1;
    ${fontSubtitle};
    font-weight: 500;
    font-size: 16px;
    margin: 0;
    text-transform: uppercase;
  }
  > img {
    width: 12px;
    height: 12px;
    cursor: pointer;
  }
  > span {
    cursor: pointer;
  }
`;

const content = css`
  overflow: hidden;
  padding: 15px 0;
  flex: 1;
  background-color: var(--bu-primary-100);
`;

const close_btn = css`
  font-size: 20px;
  color: var(--bu-gray-700);
`;

const SidePanel: React.FC<IProps> = ({
  title,
  width = 'auto',
  visible,
  onClose,
  children,
}) =>
  visible ? (
    <div style={{ width, height: '100%' }} className={container}>
      <div className={header}>
        <h3>{title}</h3>
        <span className={close_btn} onClick={onClose}>
          <BuIcon name={BoostUpIcons.ClosePopup} />
        </span>
      </div>
      <div className={content}>{children}</div>
    </div>
  ) : null;

export default SidePanel;
