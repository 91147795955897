import { connect } from 'react-redux';

import Dashboards from 'components/chart-dashboards/Dashboards';
import { StateProps } from 'components/chart-dashboards/types';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

const mapStateToProps = (state: IReduxState): StateProps => ({
  dashboardsList: selectors.getMetricsDashboardsList(state),
});

export default connect(mapStateToProps)(Dashboards);
