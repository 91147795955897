import { css } from 'emotion';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import ActiveUsersToggle from 'components/UI/ActiveUsersToggle';
import BusinessTypesPanel from 'components/UI/BusinessTypesPanel';
import OpenFiltersPanel from 'components/UI/OpenFiltersPanel';
import { useHeader } from 'components/UI/Wrapper/Header/header.context';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import SankeyWidget from 'components/dashboard/ForecastPipelineStage';
import { IProps } from 'components/dashboard/Pipeline/PipelineDealsProgression/types';
import { getIncludeDisabledUsersByDefaut } from 'selectors';

const stickyTop = css`
  position: sticky;
  background: var(--bu-white);
  z-index: 989;
`;

const btActiveUsers = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bu-white);
  border-bottom: 1px solid var(--bu-gray-400);
  margin: 0 -20px 0px -20px;
  padding: 0 20px 0px 20px;
`;

const contentWrapper = css`
  margin-top: 20px;
  margin-bottom: 100px;
  background-color: var(--bu-white);
`;

const containerWrapper = css`
  height: 100%;
  background-color: var(--bu-primary-100);
`;

const PipelineDealsProgression: React.FC<IProps> = ({
  selectedBusinessType,
  filters,
  toggleAllFilters,
  tabPartition = 'pipeline',
  isRequiredDataReady,
}) => {
  const { setPartition, clearContext } = useHeader();

  const tab = 'pipeline_deals_progression';

  const includeDisabledUsersDefault = useSelector(
    getIncludeDisabledUsersByDefaut
  );
  const [includeDisabledUsers, setIncludeDisabledUsers] = useState(
    includeDisabledUsersDefault
  );

  useEffect(() => {
    setPartition(tabPartition);

    AnalyticsTracker.event(
      { tab },
      {
        category: 'Pipeline',
        action: 'Tab: Deals Progression',
        label: 'Deals Progression page',
      }
    );

    return () => {
      clearContext();
    };
  }, []);

  if (isEmpty(filters) || !isRequiredDataReady) {
    return <Loader active />;
  }

  return (
    <div className={containerWrapper} onClick={() => toggleAllFilters(true)}>
      <div className="container">
        <div className="container-dashboard">
          <div className={stickyTop}>
            <OpenFiltersPanel tab={tab} />
          </div>

          <div className={btActiveUsers}>
            <BusinessTypesPanel tab={tab} />
            <ActiveUsersToggle
              checked={!includeDisabledUsers}
              onChange={setIncludeDisabledUsers}
            />
          </div>

          <div className={contentWrapper}>
            <div style={{ marginTop: 20 }}>
              <SankeyWidget
                filters={filters}
                businessType={selectedBusinessType}
                localStorageKeyPrefix="ForecastTrends"
                includeDisabledUsers={includeDisabledUsers}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PipelineDealsProgression;
