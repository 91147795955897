import { css } from 'emotion';

export const options = css`
  z-index: 150;
  position: absolute;
  width: 222px;
  background: var(--bu-white);
  visibility: hidden;
  opacity: 0;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  border: 1px solid var(--bu-gray-500);
  margin-top: 4px;
  right: 0;

  &.open {
    visibility: visible;
    opacity: 1;
  }
`;

export const option_body = css`
  padding: 8px 0;
`;

export const option = css`
  height: 70px;
  padding: 0 20px 4px 20px;
  cursor: pointer;
  margin-bottom: 4px;

  :hover {
    background-color: var(--bu-primary-200);
  }
`;

export const title = css`
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
  margin-bottom: 0px;

  &.disabled {
    color: var(--bu-gray-500);
  }
`;

export const subheading = css`
  height: 40px;
  color: var(--bu-gray-600);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;

  &.disabled {
    color: var(--bu-gray-400);
  }
`;
