import { connect } from 'react-redux';

import { actions } from 'actions';
import * as widgetsActions from 'actions/widgetsActions';
import { MANAGER_ROLES } from 'common/constants';
import { ViewBy } from 'common/enums/filters';
import Widget from 'components/chart-dashboards/Widget/Widget';
import {
  DispatchProps,
  OwnProps,
  StateProps,
} from 'components/chart-dashboards/Widget/types';
import { indexToDashboard } from 'navigation/routes';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

const mapStateToProps = (state: IReduxState, props: OwnProps): StateProps => {
  const { dashboardId, filters, tab } = props;
  const oldDashboardName = `_dashboard_${dashboardId}`;

  const mappedTab =
    indexToDashboard[dashboardId || '']?.tab || tab || oldDashboardName;

  return {
    widgetFilterSort: selectors.getWidgetFilterSort(state, props.title),
    filters: {
      ...selectors.getFiltersForAPI(state, mappedTab),
      ...filters,
    } as any,
    visibleSeries: selectors.getWidgetVisibleSeries(state, props.title),
    newFilters: selectors.getFiltersState(state, mappedTab) as any,
    viewBy: selectors.isUserRole(state, Object.values(MANAGER_ROLES))
      ? selectors.filtersViewBy(state)
      : ViewBy.SELLER,
  };
};

const dispatchToProps: DispatchProps = {
  persistModalParams: actions.ui.modal.persist,
  setVisibleSeries: widgetsActions.setVisibleSeries,
  changeWidgetSinceDate:
    actions.ui.forecastWidgetAllChangedDate.changeDateSince,
  sortWidget: actions.ui.widget.sortWidget,
};

export default connect(mapStateToProps, dispatchToProps)(Widget);
