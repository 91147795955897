import { css } from 'emotion';
import React from 'react';

type IProgressbarProps = {
  target: number;
  value: number;
};

const progressbarStyle = css`
  font-size: 10px;
  color: #777;

  & > .bar-line {
    display: inline-block;
    height: 4px;
    width: 80px;
    border-radius: 2px;
    background-color: var(--bu-gray-400);
    margin-right: 10px;

    & > .progress-line {
      background-color: var(--bu-primary-500);
      height: 100%;
      border-radius: 2px;
    }
  }
`;

export const Progressbar: React.FC<IProgressbarProps> = ({ value, target }) => {
  const targetAchievePercent = target > 0 ? ~~((value / target) * 100) : 0;

  return (
    <div className={progressbarStyle}>
      <div className="bar-line">
        <div
          className="progress-line"
          style={{
            width: `${Math.min(targetAchievePercent, 100)}%`,
          }}
        />
      </div>
      {targetAchievePercent}%
    </div>
  );
};
