import moment from 'moment';
import React from 'react';

import * as styles from 'components/UI/AttachmentsTable/styles';
import { RowProps } from 'components/UI/AttachmentsTable/types';
import * as momentStyles from 'components/UI/MomentsTable/styles';
import { ModalLink } from 'navigation/Link';

const Row: React.FC<RowProps> = (props: RowProps) => (
  <tr className={styles.body_table_row}>
    <td className={momentStyles.body_table_column}>{props.data.name}</td>
    <td className={momentStyles.body_table_column}>
      {props.data.attachment_type}
    </td>
    <td className={momentStyles.body_table_column}>
      {props.data.summary}{' '}
      <ModalLink
        url={{
          scheme: '/email/:emailId',
          params: { emailId: props.data.message_id },
        }}
        className={momentStyles.show_text}
      >
        More
      </ModalLink>
    </td>
    <td className={momentStyles.body_table_column}>
      <div className={momentStyles.prospect_position}>
        {props.data.sender.name || props.data.sender.email}
      </div>
      <div>{props.data.sender.title}</div>
      <div
        className={momentStyles.date}
        title={moment(props.data.received_at).format('MMM DD, YYYY')}
      >
        {moment(props.data.received_at).fromNow()}
      </div>
    </td>
  </tr>
);

export default Row;
