import { BuDropdownItemContainer } from '../BuDropdown';
import BuDropdown from '../BuDropdown/BuDropdown';
import React from 'react';
import { Loader } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import * as styles from 'components/UI/DownloadDropdown/styles';
import { DownloadDropdownProps } from 'components/UI/DownloadDropdown/types';
import { useClickOutside } from 'components/hooks/useClickOutside';

const DownloadDropdown: React.FC<DownloadDropdownProps> = ({
  isPending,
  triggerDownload,
}) => {
  const { setIsOpen } = useClickOutside();

  const onClick = (
    hide: () => void,
    onlyVisible: boolean,
    addDeltas: boolean,
    addMulticurrency: boolean
  ) => {
    hide();
    triggerDownload(onlyVisible, addDeltas, addMulticurrency);
    setIsOpen(false);
  };

  return (
    <>
      {isPending ? (
        <BuButton disabled>
          <Loader size="tiny" active inline />
        </BuButton>
      ) : (
        <BuDropdown
          secondary
          tooltip="Download CSV"
          label={
            <BuIcon
              color="var(--bu-primary-700)"
              name={BoostUpIcons.Download}
              className={styles.downloadIcon}
            />
          }
          iconDropdown
        >
          {(hide) => (
            <div className={styles.dropdownContent}>
              <BuDropdownItemContainer
                className={styles.option}
                onClick={() => onClick(hide, true, false, true)}
                data-testing="download-visible"
              >
                <p className={styles.title}>Download Visible Columns</p>
                <span className={styles.subheading}>
                  Download only visible columns without historic data.
                </span>
              </BuDropdownItemContainer>
              <BuDropdownItemContainer
                className={styles.option}
                onClick={() => onClick(hide, false, true, true)}
                data-testing="download-all"
              >
                <p className={styles.title}>Download All Columns</p>
                <span className={styles.subheading}>
                  Download all table columns including historical data.
                </span>
              </BuDropdownItemContainer>
            </div>
          )}
        </BuDropdown>
      )}
    </>
  );
};

export default DownloadDropdown;
