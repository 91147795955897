import React, { useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  DefaultModalConfigType,
  ModalConfig,
  ModalParams,
} from './modal.context.types';
import useModalStorageReducer, {
  changeUrlToOpenModal,
  getWhichModalsAreOpen,
} from './modal.context.utils';

import { ModalsScheme } from 'navigation/types';

interface ModalContextValue<T extends DefaultModalConfigType> {
  /**
   * Open modal with given scheme and params
   *
   * Note: Callbacks cannot be passed here, they should be passed in the setCallbacksForScheme function @see {@link setCallbacksForScheme}
   * @example
   * const { openModal } = useModal();
   * openModal({
   *  scheme: 'forecast/:tab', // :tab will be replaced with params.tab
   *  params: { tab: 'changed' },
   *  props: { someFlag: true },
   * })
   * @see {@link ModalConfig}
   *
   */
  openModal: (props: Omit<T, 'callbacks'>) => void;
  closeModal: (scheme: T['scheme'], callback: () => {}) => void;

  /**
   * Updates a modal props for given scheme
   *
   * @example
   * const { updateModalProps } = useModal();
   *
   * openModal({
   *  scheme: 'forecast/:tab',
   *  params: { tab: 'changed' },
   *  props: { someFlag: true },
   * })
   *
   * // Will re render the modal component with new props
   * updateModalProps(
   *  scheme: 'forecast/:tab',
   *  { someFlag: 'false' },
   * })
   */
  updateModalProps: (
    scheme: T['scheme'],
    newProps: Partial<T['props']>
  ) => void;

  /**
   * Registers callbacks for a given modal scheme.
   *
   * This function allows you to set callback functions for a particular modal scheme,
   * making them available to the modal component when it's opened. This is especially
   * useful for handling side effects when a modal is closed or performing any other
   * operations that require a callback.
   *
   * Note: Callbacks are intentionally not allowed to be set via the `openModal` function,
   * because JavaScript functions are not serializable and thus cannot be stored in local storage.
   * This could lead to issues when a user refreshes the page while the modal is open.
   * Therefore, this method should be used within a `useEffect` in the component that triggers the modal,
   * to ensure that the callback persists across page refreshes.
   *
   * @example
   * const { setCallbacksForScheme, modalsOpened } = useModal();
   *
   * const forecastModalIsOpen = modalsOpened.includes('forecast/:tab');
   *
   * useEffect(() => {
   *  if(forecastModalIsOpen){
   *   setCallbacksForScheme('forecast/:tab', {
   *     onClose: () => {}
   *   });
   *  }
   * }, [forecastModalIsOpen]);
   */
  setCallbacksForScheme: (
    scheme: T['scheme'],
    callbacks: T['callbacks']
  ) => void;

  getCallbacksForScheme: (scheme: T['scheme']) => NonNullable<T['callbacks']>;
  getParamsForScheme: (scheme: T['scheme']) => NonNullable<T['params']>;
  getPropsForScheme: (scheme: T['scheme']) => NonNullable<T['props']>;
  closeTooltip: undefined | string;
  /**
   * Will display a tooltip on close button hover, if undefined will not display tooltip
   */
  setCloseTooltip: (tooltip: string | undefined) => void;
  isCloseEnabled: boolean;
  /**
   * If false will prevent modal from closing
   */
  setIsCloseEnabled: (enabled: boolean) => void;
  modalsOpened: T['scheme'][];
}

const ModalContext = React.createContext<ModalContextValue<any>>({
  openModal: (props) => {},
  closeModal: (scheme, callback) => {},
  updateModalProps: (scheme, newProps) => {},
  setCallbacksForScheme: (scheme, callbacks) => {},
  getCallbacksForScheme: (scheme) => ({}),
  getParamsForScheme: (scheme) => ({}),
  getPropsForScheme: (scheme) => ({}),
  closeTooltip: undefined,
  setCloseTooltip: (tooltip) => {},
  isCloseEnabled: true,
  setIsCloseEnabled: (enabled) => {},
  modalsOpened: [],
});

export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const history = useHistory();
  const [closeTooltip, setCloseTooltip] =
    useState<string | undefined>(undefined);
  const [isCloseEnabled, setIsCloseEnabled] = useState(true);

  const location = useLocation();
  const modalsOpened = useMemo(
    () => getWhichModalsAreOpen(location.pathname),
    [location.pathname]
  );

  const {
    storeConfigForScheme,
    removeConfigForScheme,
    updatePropsForScheme,
    setCallbacksForScheme,
    getOnCloseCallbackForScheme,
    getCallbacksForScheme,
    getParamsForScheme,
    getPropsForScheme,
  } = useModalStorageReducer();

  const openModal = useCallback(
    ({ scheme, params, props }: Omit<DefaultModalConfigType, 'callbacks'>) => {
      storeConfigForScheme(scheme, { params, props });
      changeUrlToOpenModal(scheme, params);
    },
    []
  );

  const closeModal = useCallback(
    (scheme: ModalsScheme, callback: () => void) => {
      if (isCloseEnabled) {
        const { location } = history;
        const { pathname } = location;

        const path = pathname.split('/~/').slice(0, -1).join('/~/');

        const onCloseCallback = getOnCloseCallbackForScheme(scheme);
        if (!!onCloseCallback) {
          onCloseCallback();
        }
        history.push({
          ...location,
          pathname: path,
        });

        callback();
        removeConfigForScheme(scheme);
      }
    },
    [getOnCloseCallbackForScheme, removeConfigForScheme]
  );

  const updateModalProps = useCallback(
    (scheme: ModalsScheme, newProps: ModalParams) => {
      updatePropsForScheme(scheme, newProps);
    },
    [updatePropsForScheme]
  );

  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal,
        updateModalProps,
        setCallbacksForScheme,
        getCallbacksForScheme,
        getParamsForScheme,
        getPropsForScheme,
        closeTooltip,
        setCloseTooltip,
        isCloseEnabled,
        setIsCloseEnabled,
        modalsOpened,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

/**
 * Provides a hook to modals functionality.
 * @template T The type of ModalConfig to use. This should always be provided.
 * @example
 * type SomeModalProps = { someFlag: boolean };
 * type SomeModalParams = { tab: string };
 * type SomeModalCallbacks = { onClose: () => void };
 *
 * type SomeModalConfig = ModalConfig<
 *  SomeModalProps,
 *  SomeModalParams,
 *  SomeModalCallbacks,
 * 'A-Modal-Scheme'
 * >
 *
 * const { openModal } = useModal<SomeModalConfig>();
 *
 * // Valid usage
 * openModal({
 *  scheme: 'A-Modal-Scheme',
 *  params: { tab: 'changed' },
 *  props: { someFlag: true },
 * })
 *
 * // Type error
 * openModal({
 * scheme: 'A-Different-Scheme',          // Invalid scheme, different from the one provided in the type
 * params: {  },                          // Missing required params
 * props: { someFlag: "invalid value" },  // Invalid prop type
 * }
 *
 *
 *
 * // If you are going to open more than a type of modal you can use a union type
 * type ComponentModalType = SomeModalConfig | AnotherModalConfig;
 * const { openModal } = useModal<ComponentModalType>();
 *
 */
export const useModal = <T extends DefaultModalConfigType>() =>
  React.useContext<ModalContextValue<T>>(ModalContext);
