import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';

import {
  dangerStype,
  successStyle,
} from 'components/settings/ManageUsers/UserDetails/styles';
import { AccessMode, Scopes, UserResponse } from 'reducers/companyUsersReducer';

export const statusOptions: DropdownItemProps[] = [
  { text: 'Active', value: 'active', key: 'active' },
  { text: 'Disabled', value: 'disabled', key: 'disabled' },
];

export const accessModeOptions: DropdownItemProps[] = [
  { text: 'Read-Only', value: AccessMode.VIEWER, key: 'viewer' },
  { text: 'Read-Write', value: AccessMode.EDITOR, key: 'editor' },
];

export const scopeOptions: DropdownItemProps[] = [
  { text: 'All', value: Scopes.ALL, key: 'all' },
  { text: 'Team', value: Scopes.TEAM, key: 'team' },
  { text: 'User', value: Scopes.SINLGE_USER, key: 'user' },
];

export const scopeLabels = {
  [Scopes.ALL]: 'All',
  [Scopes.TEAM]: 'Team',
  [Scopes.SINLGE_USER]: 'User',
};

export const tokenStatusToConfig: Record<
  NonNullable<UserResponse['access_token']>['status'],
  { text: string; className: string }
> = {
  expired: { text: 'User Token is expired', className: dangerStype },
  missing: { text: 'User Token is missing', className: dangerStype },
  valid: { text: 'User Token is valid', className: successStyle },
};

export const FULL_SCOPES_USER_COUNT = 2;
export const HALF_SCOPES_USER_COUNT = 1;
