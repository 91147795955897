import styled from '@emotion/styled';
import React, { useMemo } from 'react';

import SyntheticFormulaPreview from 'components/UI/BuFormulaTextField/SyntheticFormulaPreview';
import { ConditionsDescription } from 'components/dashboard/Metrics/common/ConditionsDescription/ConditionsDescription';
import { MetricAdvancedOptionsList } from 'components/dashboard/Metrics/common/MetricAdvancedOptionsList/MetricAdvancedOptionsList';
import {
  getAggFunc,
  getMetricDescription,
} from 'components/dashboard/Metrics/common/MetricInfo/helpers';
import { MetricTag } from 'components/dashboard/Metrics/common/MetricTag/MetricTag';
import { MetricType } from 'components/dashboard/Metrics/enums';
import { hasMetricAdvancedOptions } from 'components/dashboard/Metrics/metrics.helpers';
import {
  BIMetricCreated,
  BIMetricFormula,
  BIMetricSimple,
} from 'components/dashboard/Metrics/metrics.types';
import { isBIMetricFormula, isBIMetricSimple } from '../../Create/utils';

const MetricFiltersDescription = styled.div({
  color: 'var(--bu-gray-700)',
  fontSize: '12px',
  ul: {
    marginBottom: '0',
    paddingLeft: '20px',
  },
});

interface Props {
  readonly hasPopups?: boolean;
  readonly metric: BIMetricCreated;
}

export const MetricInfo: React.FC<Props> = ({ hasPopups = false, metric }) => {
  const { aggregationFunction, metricDescription } = useMemo(() => {
    const aggregationFunction = getAggFunc(
      (metric as BIMetricSimple)?.object,
      (metric as BIMetricSimple)?.aggregation_function
    );
    const metricDescription = getMetricDescription(metric as BIMetricSimple);

    return { aggregationFunction, metricDescription };
  }, [metric]);

  return (
    <>
      {isBIMetricFormula(metric) && metric.synthetic_metric && (
        <SyntheticFormulaPreview
          formula={(metric as BIMetricFormula).synthetic_metric}
          hasPopups={hasPopups}
        />
      )}

      {isBIMetricSimple(metric) && (
        <MetricTag
          aggregationFunction={aggregationFunction}
          metricDescription={metricDescription}
        />
      )}

      {metric?.filters && metric.filters.length > 0 && (
        <MetricFiltersDescription>
          <ConditionsDescription filters={metric.filters} />
        </MetricFiltersDescription>
      )}

      {isBIMetricSimple(metric) && hasMetricAdvancedOptions(metric) && (
        <MetricFiltersDescription>
          <MetricAdvancedOptionsList
            metricObject={metric?.object ?? ''}
            managerAggregationType={metric?.manager_aggregation_type}
            isCumulativeSum={metric?.is_cumulative_sum}
            cumulativeSumPeriod={metric?.cumulative_sum_period}
            forecastAmountAccuracyCalculation={
              metric?.forecast_submission_properties?.calculation
            }
            forecastSubmissionMetric={
              metric.forecast_submission_properties?.metric_type
            }
            linkedDateField={metric?.date_field?.label ?? ''}
          />
        </MetricFiltersDescription>
      )}
    </>
  );
};
