import axiosInstance from 'api/axiosInstance';
import { IRow } from 'components/UI/common/TypedTable/TypedTable';
import { GetTableDataApiParams } from 'components/dashboard/Metrics/Widget/Report/ReportTable/ReportTable';

type GetTableDataMethodParams = {
  getTableDataParams: GetTableDataApiParams;
  tableDataUrlApiPath: string;
};

type DealsApiResponse = {
  data: {
    count: number;
    deals: Deals.Deal[];
    total_amount: number;
  };
  accounts: IRow[];
  total_count: number;
};

export const getTableData = async ({
  getTableDataParams,
  tableDataUrlApiPath,
}: GetTableDataMethodParams): Promise<DealsApiResponse> => {
  const res = await axiosInstance.post<DealsApiResponse>(
    `${process.env.REACT_APP_BACKEND_URL}/api/data/${tableDataUrlApiPath}/`,
    getTableDataParams
  );

  const data = res.data ?? {};

  return data;
};
