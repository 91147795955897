import * as t from 'actions/actionTypes';
import {
  DealsForForecastSubmissionSettingPayload,
  DealsForForecastSubmissionSettingResponse,
  ForecastsSubmissionsByPeriodToCopyBody,
  ForecastsSubmissionsByPeriodToCopyResponse,
  ForecastsSubmissionsByPeriodToCopySuccessPayload,
  ForecastsSubmissionsNestedItemLoadingPayload,
  ForecastSubmissionHistoryBody,
  ForecastSubmissionHistoryByUserBody,
  ForecastSubmissionHistoryByUserResponse,
  ForecastSubmissionHistoryByUserSuccessPayload,
  ForecastSubmissionHistoryGetSuccessPayload,
  ForecastSubmissionHistoryResponse,
  ForecastSubmissionsCreateBody,
  ForecastSubmissionsCreateErrorPayload,
  ForecastSubmissionsCreateErrorResponse,
  ForecastSubmissionsCreateLoadingPayload,
  ForecastSubmissionsCreateSuccessPayload,
  ForecastSubmissionsCreateSuccessResponse,
  ForecastSubmissionSettingResponse,
  ForecastSubmissionSettingSuccessPayload,
} from 'common/types/forecastSubmission';
import * as genericSagas from 'sagas/generic';
import { actionCreator } from 'utils/factories';

export const forecastSettingsDealsGetSuccessAction =
  actionCreator<DealsForForecastSubmissionSettingPayload>(
    t.FORECAST_SETTING_DEALS_GET_SUCCESS
  );
export const forecastSettingsDealsGetLoadingAction =
  actionCreator<ForecastsSubmissionsNestedItemLoadingPayload>(
    t.FORECAST_SETTING_DEALS_GET_LOADING
  );

export const fetchDealsForForecastSubmissionSetting = (
  submissionSettingId: string
) => ({
  type: t.FORECAST_SETTING_DEALS_GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/forecast/submission/list_deals/${submissionSettingId}`,
  saga: genericSagas.get,
  success: (payload: DealsForForecastSubmissionSettingResponse) =>
    forecastSettingsDealsGetSuccessAction({
      deals: payload.data,
      submissionSettingId,
    }),
  loading: () => forecastSettingsDealsGetLoadingAction({ submissionSettingId }),
});

export const forecastSettingHistoryGetSuccessAction =
  actionCreator<ForecastSubmissionHistoryGetSuccessPayload>(
    t.FORECAST_SETTING_HISTORY_GET_SUCCESS
  );
export const forecastSettingHistoryGetLoadingAction =
  actionCreator<ForecastsSubmissionsNestedItemLoadingPayload>(
    t.FORECAST_SETTING_HISTORY_GET_LOADING
  );

export const fetchForecastSubmissionHistory = ({
  submissionSettingId,
  forecastName,
  businessTypeName,
  periodInterval,
}: ForecastSubmissionHistoryBody) => ({
  type: t.FORECAST_SETTING_HISTORY_GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/forecast/submission/history/`,
  saga: genericSagas.create,
  data: {
    forecast_name: forecastName,
    business_type_name: businessTypeName,
    close_date_interval: periodInterval,
  },
  success: (payload: ForecastSubmissionHistoryResponse) =>
    forecastSettingHistoryGetSuccessAction({
      historyItems: payload.data,
      submissionSettingId,
    }),
  loading: () =>
    forecastSettingHistoryGetLoadingAction({ submissionSettingId }),
});

export const forecastSubmissionByPeriodToCopySuccessAction =
  actionCreator<ForecastsSubmissionsByPeriodToCopySuccessPayload>(
    t.FORECAST_SUBMISSIONS_BY_PERIOD_TO_COPY_SUCCESS
  );
export const forecastSubmissionByPeriodToCopyLoadingAction =
  actionCreator<ForecastsSubmissionsNestedItemLoadingPayload>(
    t.FORECAST_SUBMISSIONS_BY_PERIOD_TO_COPY_LOADING
  );

export const fetchForecastsSubmissionsByPeriodToCopy = ({
  submissionSettingId,
  periodType,
  includedDeals,
}: ForecastsSubmissionsByPeriodToCopyBody) => ({
  type: t.FORECAST_SUBMISSIONS_BY_PERIOD_TO_COPY_GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/forecast/submission/copy/by_period`,
  saga: genericSagas.create,
  data: {
    period_type: periodType,
    included_deals: includedDeals,
  },
  success: (payload: ForecastsSubmissionsByPeriodToCopyResponse) =>
    forecastSubmissionByPeriodToCopySuccessAction({
      submissionsToCopy: payload.data,
      submissionSettingId,
    }),
  loading: () =>
    forecastSubmissionByPeriodToCopyLoadingAction({ submissionSettingId }),
});
export const forecastSubmissionsSettingsAvailableSuccessAction =
  actionCreator<ForecastSubmissionSettingSuccessPayload>(
    t.FORECAST_SETTINGS_AVAILABLE_SUCCESS
  );

export const forecastSubmissionsSettingsAvailableLoadingAction = actionCreator(
  t.FORECAST_SETTINGS_AVAILABLE_LOADING
);

export const fetchForecastSubmissionsSettingsAvailable = () => ({
  type: t.FORECAST_SETTINGS_AVAILABLE_GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/forecast/submission/available`,
  saga: genericSagas.get,
  success: (payload: ForecastSubmissionSettingResponse) =>
    forecastSubmissionsSettingsAvailableSuccessAction(payload),
  loading: forecastSubmissionsSettingsAvailableLoadingAction,
});

export const forecastSubmissionsCreateSuccessAction =
  actionCreator<ForecastSubmissionsCreateSuccessPayload>(
    t.FORECAST_SUBMISSION_CREATE_SUCCESS
  );

export const forecastSubmissionsCreateErrorAction =
  actionCreator<ForecastSubmissionsCreateErrorPayload>(
    t.FORECAST_SUBMISSION_CREATE_ERROR
  );

export const forecastSubmissionsCreateLoadingsAction =
  actionCreator<ForecastSubmissionsCreateLoadingPayload>(
    t.FORECAST_SUBMISSION_CREATE_LOADING
  );

export const forecastSubmissionCreate = (
  submissionSettingId: string,
  data: ForecastSubmissionsCreateBody
) => ({
  type: t.FORECAST_SUBMISSION_CREATE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/forecast/submission/submit/${submissionSettingId}`,
  saga: genericSagas.create,
  data,
  success: (payload: ForecastSubmissionsCreateSuccessResponse) =>
    forecastSubmissionsCreateSuccessAction({
      submissionCreated: payload.data,
      submissionSettingId,
    }),
  error: (e: ForecastSubmissionsCreateErrorResponse) =>
    forecastSubmissionsCreateErrorAction({
      submissionSettingId,
      error: e?.response?.data?.error || e,
    }),
  loading: () =>
    forecastSubmissionsCreateLoadingsAction({ submissionSettingId }),
});

export const forecastUserSubmissionHistorySuccessAction =
  actionCreator<ForecastSubmissionHistoryByUserSuccessPayload>(
    t.FORECAST_USER_SUBMISSION_HISTORY_SUCCESS
  );
export const forecastUserSubmissionHistoryLoadingAction = actionCreator(
  t.FORECAST_USER_SUBMISSION_HISTORY_LOADING
);
export const forecastUserSubmissionHistoryErrorAction = actionCreator(
  t.FORECAST_USER_SUBMISSION_HISTORY_ERROR
);

export const fetchForecastUserSubmissionHistory = ({
  forecastName,
  userId,
  closeDateInterval,
  businessTypeName,
  periodDelta,
  periodType,
}: ForecastSubmissionHistoryByUserBody) => {
  return {
    type: t.FORECAST_USER_SUBMISSION_HISTORY,
    url: `${process.env.REACT_APP_BACKEND_URL}/api/data/forecast/submission/history/`,
    saga: genericSagas.create,
    data: {
      forecast_name: forecastName,
      user_id: userId,
      business_type_name: businessTypeName,
      close_date_interval: closeDateInterval,
      period_delta: periodDelta,
      period_type: periodType,
    },
    success: (payload: ForecastSubmissionHistoryByUserResponse) =>
      forecastUserSubmissionHistorySuccessAction({
        ...payload,
        userId,
        forecastName,
        closeDateInterval,
        businessTypeName,
        periodDelta,
        periodType,
      }),
    error: () => forecastUserSubmissionHistoryErrorAction(),
    loading: () => forecastUserSubmissionHistoryLoadingAction(),
  };
};

export const forecastSubmissionsSettingsResetData = actionCreator(
  t.FORECAST_SETTING_RESET_DATA
);
