import styled from '@emotion/styled';

import icon from 'assets/images/icons/search.svg';

const BuDropdownSearchItem = styled.input`
  padding: 8px 15px 6px 35px;
  margin: 0 0 2px 0;
  outline: none;
  border: none;
  background: url('${icon}') no-repeat scroll 13px 11px var(--bu-white);
  background-size: 12px;
  text-align: inherit;
  white-space: nowrap;
  height: fit-content;
  font-size: 14px;
  border-bottom: 1px solid var(--bu-gray-400);
  z-index: 1;
  width: 100%;

  position: sticky;
  top: 0;

  &:hover {
    background-color: var(--bu-primary-200);
  }
`;
BuDropdownSearchItem.defaultProps = {
  placeholder: 'Search...',
};

export default BuDropdownSearchItem;
