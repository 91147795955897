import * as R from 'ramda';
import { combineReducers } from 'redux';

import * as t from 'actions/actionTypes';

const loading = (state = false, action) => {
  switch (action.type) {
    case t.LOGIN + t.OAUTH + t.LOADING:
    case t.LOGIN + t.SSO + t.LOADING:
      return true;
    case t.LOGIN + t.ENTER:
    case t.LOGIN + t.OAUTH + t.SUCCESS:
    case t.LOGIN + t.SSO + t.SUCCESS:
    case t.LOGIN + t.OAUTH + t.ERROR:
    case t.LOGIN + t.SSO + t.ERROR:
      return false;
    default:
      return state;
  }
};

const ssoSuccess = (state = null, action) => {
  switch (action.type) {
    case t.LOGIN + t.SSO + t.SUCCESS:
      return R.pathOr('Please check your email', ['message'])(action.payload);
    case t.LOGIN + t.OAUTH + t.LOADING:
    case t.LOGIN + t.SSO + t.LOADING:
    case t.LOGIN + t.ENTER:
    case t.LOGIN + t.OAUTH + t.SUCCESS:
    case t.LOGIN + t.OAUTH + t.ERROR:
    case t.LOGIN + t.SSO + t.ERROR:
      return null;
    default:
      return state;
  }
};

const error = (state = null, action) => {
  switch (action.type) {
    case t.LOGIN + t.OAUTH + t.ERROR:
      return R.pathOr('There was an error during the sign in', [
        'error',
        'response',
        'data',
        'error',
        'message',
        0,
      ])(action);
    case t.LOGIN + t.SSO + t.ERROR:
      return R.pathOr('There was an error during the sign in', [
        'error',
        'response',
        'data',
        'error',
        'message',
        0,
        'email',
        0,
      ])(action);
    case t.LOGIN + t.ENTER:
    case t.LOGIN + t.OAUTH + t.LOADING:
    case t.LOGIN + t.SSO + t.LOADING:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  loading,
  error,
  ssoSuccess,
});
