import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuGroupButton from 'components/UI/BuGroupButton';
import BuIcon from 'components/UI/BuIcon';
import {
  ControlsContainer,
  FilterBarElement,
} from 'components/dashboard/Metrics/Widget/Controls/styles';
import { itemContainerWhiteBg } from 'components/dashboard/Metrics/Widget/FunnelWidget/Controls/styles';
import { DateOperatorOption } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/types';
import { DropdownCalendar } from 'components/dashboard/Metrics/common/Calendar/Calendar';
import {
  DataDescriptor,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';
import { getTimeOptions } from 'selectors/revbi/metrics';
import { fetchApi } from 'utils/network';

interface Props {
  widget: Partial<BIWidget>;
  showControls?: boolean;
  onChangeWidget: (widget: Partial<BIWidget>) => void;
}

export const FunnelControls: React.FC<Props> = ({
  widget,
  showControls,
  onChangeWidget,
}) => {
  const timeFilterOptions = useSelector(getTimeOptions);

  const [timeValueOptions, setTimeValueOptions] = useState<
    Filters.DateFilterElement[]
  >([]);
  const [dateTemplateFilterOptions, setDateTemplateFilterOptions] = useState<
    Filters.DateFilterElement[]
  >([]);

  useEffect(() => {
    if (widget.time_interval) {
      const signalAbortPeriodOptions = new AbortController();
      const signalAbortTemplate = new AbortController();

      fetchApi<void, DataDescriptor[]>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/time_series/get_time_period_options/${widget.time_interval}`,
        queryMethod: 'get',
        setData: (result) => {
          const resultTimePeriodOptions: Filters.DateFilterElement[] = [];

          result.map((element) => {
            resultTimePeriodOptions.push({
              label: element.label,
              value: element.name,
              type: element.type.toUpperCase() as Filters.DateElementType,
            });
          });

          setTimeValueOptions(resultTimePeriodOptions);
        },
        setError: (error: string | null) => {
          toast.error(`Failed to load time period options: ${error}`);
        },
        signal: signalAbortPeriodOptions.signal,
      });

      const getType = (name: string) => {
        if (name === 'all_time') return 'SAME_PERIOD';
        if (name.startsWith('same_')) return 'SAME_PERIOD';
        if (name.startsWith('previous_')) return 'PREVIOUS_PERIOD';
        if (name.startsWith('next_')) return 'NEXT_PERIOD';
      };

      fetchApi<void, any[]>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/time_series/get_date_template_filter_options/${widget.time_interval}`,
        queryMethod: 'get',
        setData: (result) => {
          setDateTemplateFilterOptions(
            result.map((element) => ({
              label: element.label,
              value: element.name,
              type: getType(element.name) as Filters.DateElementType,
            }))
          );
        },
        setError: (error: string | null) => {
          toast.error(`Failed to load date template filter options: ${error}`);
        },
        signal: signalAbortTemplate.signal,
      });

      return () => {
        signalAbortTemplate.abort();
        signalAbortPeriodOptions.abort();
      };
    }
  }, [widget.time_interval]);

  const buildOptions = useCallback(
    (options: Filters.DateFilterElement[]) => {
      return options.map((option) => ({
        ...option,
        checked: option.value === widget.time_period,
      }));
    },
    [widget]
  );

  const handleTimePeriodChange = useCallback(
    (value: DateOperatorOption): void => {
      const selectedValue = value.selected[0].id;
      onChangeWidget({
        time_period: selectedValue,
      });

      if (!timeValueOptions.find((option) => option?.value === selectedValue)) {
        timeValueOptions.push({
          label: selectedValue.split(',').join(' / '),
          value: selectedValue,
        });
      }
    },
    [widget]
  );

  const previewOptions = [
    {
      id: 'funnel',
      text: 'Funnel',
      iconName: BoostUpIcons.Filter,
    },
    {
      id: 'table',
      text: 'Table',
      iconName: BoostUpIcons.Table,
    },
  ];

  return (
    <>
      <ControlsContainer
        isSpaceBetween={false}
        showControls={showControls || false}
      >
        <FilterBarElement>
          <BuIcon name={BoostUpIcons.Calendar} color="var(--bu-gray-700)" />
        </FilterBarElement>

        <DropdownCalendar
          config={{
            title: 'Time Period',
            isLocked: false,
            customEnd: true,
            customStart: true,
            customPeriodValue: widget?.time_period
              ? widget?.time_period
              : undefined,
            values: buildOptions(timeFilterOptions.predefined),
            fiscalYearValues: buildOptions(timeFilterOptions.fiscal_year),
            relativeValues: buildOptions(dateTemplateFilterOptions),
            timeInterval: widget.time_interval,
            timePeriod: widget.time_period,
            includedBlankValues: false,
          }}
          key="Filter"
          name="Filter"
          onChange={handleTimePeriodChange}
        />

        <BuGroupButton
          className={itemContainerWhiteBg}
          options={previewOptions}
          selectedOption={widget.chart_type}
          onSelect={(selection) => {
            onChangeWidget({
              chart_type: selection,
            });
          }}
        />
      </ControlsContainer>
    </>
  );
};
