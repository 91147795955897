import { css } from 'emotion';

export const buSkeleton = css`
  & > span {
    border-radius: 0;
  }
`;

export const tableWrapper = css`
  display: flex;
  flex-direction: column;
  border: 1px solid var(--bu-gray-300);
  align-items: center;
  margin-bottom: 20px;
`;

export const tableWrapperFilled = css`
  padding: 0 0;
`;

export const tableHeader = css`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const tableHeaderContent = css`
  width: 100%;
  padding: 7px 20px;
  display: flex;
  align-items: center;
`;

export const tableHeaderSkeleton = css`
  width: 100%;
`;

export const tableHeaderBordered = css`
  border-bottom: 1px solid var(--bu-gray-300);
`;

export const tableBody = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const tableRow = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 16px;

  border-bottom: 1px solid var(--bu-gray-300);

  &:last-child {
    border-bottom: none;
  }
`;

export const upTable = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
`;

export const upTablePadding = css`
  padding-top: 13px;
`;

export const upTableGridItems = css`
  display: flex;
  margin-right: 10px;
  height: 35px;
  border: 1px solid var(--bu-gray-300);

  & > span {
    height: 100%;
    width: 100px;
    border-right: 1px solid var(--bu-gray-300);

    &:last-child {
      border-right: none;
    }
  }
`;

export const upTableGrid = css`
  display: flex;
`;

export const lineWithDots = css`
  display: block;
`;

export const lineWithDotsLineContainer = css`
  display: flex;
  align-items: center;
`;

export const lineWithDotsContainer = css`
  position: relative;
`;

export const linewithDotsDot = css`
  position: absolute;
  top: 2px;
  bottom: 0;
  margin: auto;
  left: 10px;
`;
