import styled from '@emotion/styled';

export const ItemContainer = styled.div({
  border: '1px solid',
  borderColor: 'var(--bu-gray-400)',
  padding: '16px',
  width: '100%',
  minHeight: '80px',
  color: 'var(--bu-gray-900)',
  borderRadius: '4px',
  display: 'flex',
  gap: '16px',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
});

export const ItemInfo = styled.div({
  paddingTop: '6px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  minWidth: 0,
});

export const ItemName = styled.div({
  fontSize: '16px',
  height: '18px',
  fontWeight: 500,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  minWidth: 0,
  overflow: 'hidden',
  fontStyle: 'normal',
});

export const WidgetSubInfo = styled.div({
  color: 'var(--bu-gray-700)',
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
});

export const WidgetSubInfoItem = styled.div({
  display: 'flex',
  height: '16px',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  gap: '6px',
  fontSize: '14px',
  fontWeight: 400,
});

export const ActionsContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexShrink: 0,
});
