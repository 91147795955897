import MetricTile from './MetricTile';
import {
  MetricPanelSection,
  CollapseAnimationContainer,
  MetricList,
  MetricItem,
} from './StyledComponents';
import { getMetricListItemsPerRow } from './helper';
import { BuMetricsPanelItem } from './types';
import classNames from 'classnames';
import React, { useMemo } from 'react';

import { useElementSize } from 'components/hooks/useElementSize';

export interface BuMetricsPanelProps {
  items: BuMetricsPanelItem[];
  onTileClick?: (item: BuMetricsPanelItem) => void | undefined;
  panelExpanded?: boolean;
}

const BuMetricsPanel = ({
  items,
  onTileClick,
  panelExpanded = false,
}: BuMetricsPanelProps) => {
  const isTileClickable = !!onTileClick;

  const thereAreItems = items.length > 0;

  const [listRef, listSize] = useElementSize<HTMLUListElement>();

  const itemsPerRow = useMemo(
    () => getMetricListItemsPerRow(items.length, listSize.width),
    [items.length, listSize.width]
  );

  if (!thereAreItems) {
    return null;
  }

  return (
    <MetricPanelSection>
      <CollapseAnimationContainer
        className={classNames({
          closed: !panelExpanded,
        })}
      >
        <MetricList
          data-testing="metric-list"
          ref={listRef}
          itemsPerRow={itemsPerRow}
        >
          {items.map((item, index) => (
            <MetricItem
              key={item.title}
              isClickable={isTileClickable}
              itemsPerRow={itemsPerRow}
              onClick={() => onTileClick?.(item)}
              data-testing={`metric-item-${index}`}
            >
              <MetricTile item={item} />
            </MetricItem>
          ))}
        </MetricList>
      </CollapseAnimationContainer>
    </MetricPanelSection>
  );
};

export default BuMetricsPanel;
