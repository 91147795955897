import { debounce } from 'lodash';
import React, { useCallback, useContext, useState } from 'react';

import BuInput from 'components/UI/BuInput';
import BuSelect from 'components/UI/BuSelect';
import {
  NULL_OPERATOR_VALUES,
  NUMBER_OPERATION_DROPDOWN_OPTIONS,
} from 'components/dashboard/Metrics/Create/Conditions/Condition/constants';
import {
  FixedWidthContainer,
  FlexGrowContainer,
} from 'components/dashboard/Metrics/Create/Conditions/Condition/styles';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import { FlexRow } from 'components/dashboard/Metrics/metrics.styles';
import { BIMetricsFilter } from 'components/dashboard/Metrics/metrics.types';

type Props = {
  columnType: string;
  filter: Partial<BIMetricsFilter>;
  setLocalFilter: React.Dispatch<
    React.SetStateAction<Partial<BIMetricsFilter>>
  >;
  onOperatorSelect: (operator: string) => void;
};

export const NumberTypeCondition: React.FC<Props> = ({
  columnType,
  filter,
  setLocalFilter,
  onOperatorSelect,
}) => {
  const { currency } = useContext(RevBISettingsContext);
  const [numberCondition, setNumberCondition] = useState(
    typeof filter.value === 'boolean' || Array.isArray(filter.value)
      ? undefined
      : filter.value
  );

  const triggerChange = useCallback(
    debounce(
      (value: string) =>
        setLocalFilter((prev) => ({
          ...prev,
          value: value,
        })),
      750
    ),
    []
  );

  const handleChangeNumber = (textValue: string): void => {
    setNumberCondition(textValue);
    triggerChange(textValue);
  };

  return (
    <FlexRow cssProps={{ gap: '12px' }}>
      <FixedWidthContainer width={100}>
        <BuSelect
          fullWidth
          secondary
          options={NUMBER_OPERATION_DROPDOWN_OPTIONS}
          onChange={(values: string[]) => {
            onOperatorSelect(values[0]);
          }}
          placeholder=""
          testingLabel="operator"
          defaults={[
            filter.operator
              ? filter.operator
              : NUMBER_OPERATION_DROPDOWN_OPTIONS[0].value,
          ]}
        />
      </FixedWidthContainer>

      {!NULL_OPERATOR_VALUES.includes(filter.operator ?? '') && (
        <FlexGrowContainer>
          <BuInput
            testingLabel="operator-value"
            type={columnType === 'money' ? 'currency' : columnType}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              handleChangeNumber(e.currentTarget.value);
            }}
            value={numberCondition}
            code={currency}
          />
        </FlexGrowContainer>
      )}
    </FlexRow>
  );
};
