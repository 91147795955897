import { InteractiveLayout, ResizeHandle } from './InteractiveGrid.types';
import InteractiveGridItem, {
  INTERACTIVE_GRID_ITEM_DRAG_HANDLE_CLASSNAME,
} from './InteractiveGridItem';
import InteractiveGridPlaceholder from './InteractiveGridPlaceholder';
import { InteractiveGridResizeHandle } from './InteractiveGridResizeHandle';
import { css } from 'emotion';
import React, {
  ReactNode,
  Ref,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import RGL, {
  CoreProps,
  ItemCallback,
  Layout,
  WidthProvider,
} from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import { getMinSizeForLayout } from 'components/dashboard/Metrics/common/InteractiveGrid/InteractiveGrid.helper';

const ReactGridLayout = WidthProvider(RGL);

// display: none !important; used to hide resize handle when resize is disabled
// Issue: https://github.com/react-grid-layout/react-grid-layout/issues/1291
const gridStyle = css`
  .react-grid-item.react-grid-placeholder {
    background-color: var(--bu-primary-500);
    border: 1px dotted var(--bu-primary-500);
    border-radius: 8px;
  }

  .react-resizable-hide .react-resizable-handle {
    display: none !important;
  }
`;

const gridContainerStyle = css`
  position: relative;
  width: 100%;
  height: 100%;
`;

interface Props<T extends InteractiveLayout> {
  renderItem: (item: T) => ReactNode;
  items: T[];
  rowHeight: number;
  columns: number;
  compactType: CoreProps['compactType'];
  isInteractive?: boolean;
  onLayoutChange?: (layout: Layout[]) => void;
}

function InteractiveGrid<T extends InteractiveLayout>({
  renderItem,
  items,
  rowHeight,
  columns,
  compactType,
  isInteractive = true,
  onLayoutChange,
}: Props<T>) {
  const [layout, setLayout] = useState<Layout[]>(items);

  const rowsAvailableToDrop =
    items.reduce((max, item) => Math.max(max, item.y + item.h), 0) + 1;

  useEffect(() => {
    setLayout(items);
  }, [items]);

  const layoutWithPlaceholders = isInteractive
    ? Array(rowsAvailableToDrop * columns)
        .fill(0)
        .map((_, index) => {
          const x = index % columns;
          const y = Math.floor(index / columns);
          const i = `placeholder${index}`;
          return { i, x, y, w: 1, h: 1 };
        })
    : [];

  const internalOnLayoutChange = (layout: Layout[]) => {
    setLayout(layout);
    if (onLayoutChange) {
      onLayoutChange(layout);
    }
  };

  const getCustomResizeHandle = (
    resizeHandle: ResizeHandle,
    ref: Ref<HTMLElement>
  ) => <InteractiveGridResizeHandle resizeHandle={resizeHandle} ref={ref} />;

  const onResize: ItemCallback = (
    layout,
    oldLayoutItem,
    layoutItem,
    placeholder
  ) => {
    const { w: itemWidth, h: itemHeight, i: layoutId } = layoutItem;
    const item = items.find((i) => i.i === layoutId);
    if (item) {
      const minSize = getMinSizeForLayout(layoutItem, item.minSizes);
      if (minSize) {
        // If the current item width is less than the minimum width,
        // we update the layout item and placeholder width to match the minimum width.

        if (itemWidth < minSize.minW) {
          layoutItem.w = minSize.minW;
          placeholder.w = minSize.minW;
        }

        // Similarly, if the current item height is less than the minimum height,
        // we update the layout item and placeholder height to match the minimum height.
        if (itemHeight < minSize.minH) {
          layoutItem.h = minSize.minH;
          placeholder.h = minSize.minH;
        }
      }
    }
  };

  const childItems = useMemo(
    () =>
      layout.map((item, idx) => {
        const itemData = items.find((i) => i.i === item.i);

        return itemData ? (
          <InteractiveGridItem
            key={item.i}
            idx={idx}
            isInteractive={isInteractive}
          >
            {renderItem(itemData as T)}
          </InteractiveGridItem>
        ) : null;
      }),
    [layout, items]
  );

  return (
    <div className={gridContainerStyle}>
      <ReactGridLayout
        className={gridStyle}
        layout={layout}
        onLayoutChange={internalOnLayoutChange}
        onResize={onResize}
        cols={columns}
        maxRows={rowsAvailableToDrop}
        rowHeight={rowHeight}
        compactType={compactType}
        draggableHandle={`.${INTERACTIVE_GRID_ITEM_DRAG_HANDLE_CLASSNAME}`}
        isDraggable={isInteractive}
        isResizable={isInteractive}
        isDroppable={isInteractive}
        resizeHandle={getCustomResizeHandle}
        autoSize
      >
        {childItems}
      </ReactGridLayout>
      {isInteractive && (
        <ReactGridLayout
          className="placeholder"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: -1,
          }}
          layout={layoutWithPlaceholders}
          cols={columns}
          rowHeight={rowHeight}
          compactType={null}
          isResizable={false}
          isDraggable={false}
          isDroppable={false}
          autoSize
        >
          {layoutWithPlaceholders.map((item) => (
            <InteractiveGridPlaceholder key={item.i} />
          ))}
        </ReactGridLayout>
      )}
    </div>
  );
}

export default InteractiveGrid;
