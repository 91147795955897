import { Action } from 'typescript-fsa';

import * as t from 'actions/actionTypes';
import {
  TableConfigurationData,
  TableConfigurationColumn,
} from 'components/UI/TableConfig/types';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';

export type SOQLQueryRow = {
  [key: string]: { value: string } | string;
  id: string;
};

type SOQLQueryRecord = {
  [key: string]: any;
  attributes: any;
};

type SOQLQueryResults = {
  records: SOQLQueryRecord[];
};

type SOQLQueryResponse = {
  results: SOQLQueryResults;
};

export type SOQLQueryState = {
  rows: SOQLQueryRow[];
  tableConfig: TableConfigurationData;
  isLoading: boolean;
  error?: string;
};

function mapSOQLQueryResponseToTable(response: SOQLQueryResponse): {
  rows: SOQLQueryRow[];
  tableConfig: TableConfigurationData;
} {
  const mappedData = response.results.records.reduce(
    (result, record, index) => {
      const row: SOQLQueryRow = { id: `row-${index}` };

      Object.entries(record).forEach(([key, value]) => {
        if (key !== 'attributes') {
          if (!result.columns[key]) {
            result.columns[key] = {
              display_name: key,
              field_name: key,
              sortable: false,
              editable: false,
              meta: { type: 'none' },
              type: ColumnTypes.CUSTOM,
              object_field: key,
              protected: true,
            };
          }

          row[key] = value;
        }
      });

      result.rows.push(row);

      return result;
    },
    {
      rows: [] as SOQLQueryRow[],
      columns: {} as { [key: string]: TableConfigurationColumn },
    }
  );

  return {
    rows: mappedData.rows,
    tableConfig: {
      order: {},
      columns: Object.values(mappedData.columns),
    },
  };
}

const initialState: SOQLQueryState = {
  rows: [],
  tableConfig: { order: {}, columns: [] },
  isLoading: false,
  error: undefined,
};

export const soqlQueryReducer = (
  state = initialState,
  action: Action<any>
): SOQLQueryState => {
  switch (action.type) {
    case t.SETTINGS + t.SOQL_QUERY + t.SUBMIT + t.LOADING:
      return {
        ...initialState,
        isLoading: true,
      };
    case t.SETTINGS + t.SOQL_QUERY + t.SUBMIT + t.SUCCESS:
      return {
        ...initialState,
        ...mapSOQLQueryResponseToTable(action.payload),
        isLoading: false,
      };
    case t.SETTINGS + t.SOQL_QUERY + t.SUBMIT + t.ERROR:
      return {
        ...initialState,
        error: action.payload,
        isLoading: false,
      };
    case t.SETTINGS + t.SOQL_QUERY + t.RESET:
      return initialState;
    default:
      return state;
  }
};
