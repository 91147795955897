import { css } from 'emotion';

export const modalContainer = css`
  display: flex;
  justify-content: flex-start;
  color: var(--bu-gray-900);
`;

export const infoListWrapper = css`
  width: 100%;
`;

export const infoListItem = css`
  display: grid;
  grid-template-columns: 1.5fr 5fr;
  grid-gap: 8px;
  margin-bottom: 12px;

  font-family: var(--bu-font-regular);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  :last-child {
    margin-bottom: 0;
  }

  .item-name,
  .item-value {
    display: flex;
    align-items: center;
  }

  .item-name {
    justify-content: flex-end;
    text-align: right;
    color: var(--bu-gray-700);

    &.name-on-top {
      align-items: flex-start;
    }
  }

  .item-value {
    justify-content: flex-start;
    flex-wrap: wrap;
    text-align: left;
    text-transform: capitalize;
    color: var(--bu-gray-900);
    gap: 8px;

    &.no-capitalize {
      text-transform: none;
    }

    .background {
      background-color: var(--bu-gray-300);
      border-radius: 2px;
      width: fit-content;
      padding: 4px 8px;

      &.negative {
        color: var(--bu-red-400);
        background-color: var(--bu-red-100);
      }

      &.positive {
        color: var(--bu-green-500);
        background-color: var(--bu-green-200);
      }
    }

    .ai-tags {
      width: max-content;
    }

    a,
    i {
      color: var(--bu-primary-700);
    }

    i {
      font-size: 16px;
    }
  }
`;
