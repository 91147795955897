import classNames from 'classnames';
import React, { useState } from 'react';
import { Dimmer, Modal as SemanticModal } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import * as styles from 'components/UI/Modal/ModalEmailDetails/styles';

type Props = {
  size: 'fullscreen' | 'large' | 'small' | 'tiny' | 'mini';
  onClose: () => void;
  type: 'email_window' | 'next_window' | 'event_window' | 'activity_window';
};

const typeToTitle: { [key in Props['type']]: string } = {
  email_window: 'Email Details',
  next_window: 'Next Step Details',
  event_window: 'Meeting Details',
  activity_window: 'Call Details',
};

const ModalEmailDetails: React.FC<Props> = ({
  size,
  onClose,
  children,
  type,
}) => {
  const [exit, setExit] = useState(false);

  const closeHandler = () => {
    setExit(true);
    onClose();
  };

  if (exit) {
    return null;
  }

  const title = typeToTitle[type] || typeToTitle.email_window;

  return (
    <Dimmer
      active
      onClickOutside={closeHandler}
      className={classNames('page', 'modals')}
    >
      <div className={styles.box}>
        <div className={classNames(styles.header_modal, 'bu-font-heading')}>
          <div className={styles.header_modal_title}>{title}</div>
          <div className={styles.header_modal_close_box}>
            <span className={styles.closeButton} onClick={closeHandler}>
              <BuIcon name={BoostUpIcons.ClosePopup} />
            </span>
          </div>
        </div>
        <div className={styles.box_body}>
          <SemanticModal.Content scrolling className={styles.modalContent}>
            {children}
          </SemanticModal.Content>
        </div>
      </div>
    </Dimmer>
  );
};

export default ModalEmailDetails;
