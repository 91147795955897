import React from 'react';
import { connect } from 'react-redux';

import { getEvent } from 'actions/eventActions';
import { EventModal } from 'components/modals/EventModal/EventModal';
import { ModalRouteProps } from 'navigation/types';
import { IReduxState } from 'reducers/types';
import { getCurrentEvent, getLoadingEvent, getEventStatus } from 'selectors';

class EventModalContainer extends React.Component<ContainerProps> {
  componentDidMount() {
    const { fetch, params } = this.props;
    fetch(params.eventId);
  }

  render() {
    const { event, isLoading, status } = this.props;

    return <EventModal event={isLoading ? null : event} status={status} />;
  }
}

const stateToProps = (state: IReduxState) => ({
  status: getEventStatus(state),
  event: getCurrentEvent(state),
  isLoading: getLoadingEvent(state),
});

const dispatchToProps: ReduxDispatchProps = {
  fetch: getEvent,
} as any;

type ReduxStateProps = ReturnType<typeof stateToProps>;
type ReduxDispatchProps = {
  fetch: Function;
};
export type ContainerProps = ReduxStateProps &
  ReduxDispatchProps &
  ModalRouteProps<{ eventId: string }>;

export default connect<ReduxStateProps, ReduxDispatchProps, {}, IReduxState>(
  stateToProps,
  dispatchToProps
)(EventModalContainer);
