import PropTypes from 'prop-types';
import React from 'react';
import { Container } from 'semantic-ui-react';

import * as interfaces from 'common/props-interfaces';
import BuButton from 'components/UI/BuButton';

class StatisticsCore extends React.PureComponent {
  render() {
    const { clearFilters, config, status, isEmpty } = this.props;

    if (isEmpty && status === 'success') {
      return (
        <div className="message-container">
          No deals found for your request. Please change filters and try again.
          <div className="message-container-btn">
            <BuButton onClick={clearFilters}>Clear Filters</BuButton>
          </div>
        </div>
      );
    }

    return (
      <div>
        {config.kpi}
        <div className="statistics__graphics">
          <div className="statistics__graphics-top">
            <Container>
              <div className="statistics__graphics-container">
                {config.charts.map((row) => (
                  <div
                    className={`graphics-container-block ${
                      row.columns === 2 ? 'two' : 'one'
                    }`}
                    key={row.key}
                  >
                    {row.widgets.map((widget) => (
                      <div
                        className="graphics-container-block-box"
                        key={widget.key}
                      >
                        {widget.component}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

StatisticsCore.propTypes = {
  config: PropTypes.shape({
    kpi: PropTypes.element.isRequired,
    charts: PropTypes.arrayOf(
      PropTypes.shape({
        columns: PropTypes.number.isRequired,
        widgets: PropTypes.array.isRequired,
      })
    ),
  }).isRequired,
  status: interfaces.loadingStatus.isRequired,
  clearFilters: PropTypes.func.isRequired,
  isEmpty: PropTypes.bool.isRequired,
};

export default StatisticsCore;
