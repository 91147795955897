/* eslint-disable no-debugger */
import * as R from 'ramda';
import { combineReducers } from 'redux';

import * as t from 'actions/actionTypes';

const ids = (state = [], action) => {
  switch (action.type) {
    case t.EMAIL + t.GET + t.SUCCESS:
      return R.pluck('message_id')(action.payload);
    case t.EMAIL + t.PAGINATION + t.SUCCESS:
      return R.concat(state, R.pluck('message_id', action.payload));
    default:
      return state;
  }
};

const byId = (state = {}, action) => {
  switch (action.type) {
    case t.EMAIL + t.GET + t.SUCCESS:
      return R.indexBy(R.prop('message_id'))(action.payload.data);
    case t.EMAIL + t.PAGINATION + t.SUCCESS:
      return R.merge(
        state,
        R.indexBy(R.prop('message_id'))(action.payload.data)
      );
    case t.EMAIL + t.DETAIL + t.GET + t.SUCCESS:
      return R.assoc(action.payload.message_id, action.payload)(state);
    default:
      return state;
  }
};

const count = (state = null, action) => {
  switch (action.type) {
    case t.EMAIL + t.GET + t.SUCCESS:
    case t.EMAIL + t.PAGINATION + t.SUCCESS:
      return action.payload.total_count;
    default:
      return state;
  }
};

const status = (state = 'loaded', action) => {
  switch (action.type) {
    case t.EMAIL + t.DETAIL + t.GET + t.LOADING:
      return 'loading';
    case t.EMAIL + t.DETAIL + t.GET + t.SUCCESS:
      return 'loaded';
    case t.EMAIL + t.DETAIL + t.GET + t.ERROR:
      return 'error';
    default:
      return state;
  }
};

export default combineReducers({
  ids,
  byId,
  count,
  status,
});
