import { isNil } from 'ramda';

import { formatMoney } from 'common/numbers';

export type UserType = {
  name: string;
  email: string;
};

export const userNameOnlyFormatter = (value?: UserType) =>
  `${value && (value.name || value.email)}`;

export const currencyFormatter =
  (
    companyCurrency: string,
    precision: number,
    isMulticurrencyEnabled: boolean = false
  ) =>
  (value: number, currency?: string, exchangeRate?: number) => {
    if (value === undefined || value === null) return '-';
    const currencyCode = isMulticurrencyEnabled
      ? currency ?? companyCurrency
      : companyCurrency;

    if (exchangeRate && currency !== companyCurrency) {
      return isNil(exchangeRate)
        ? `${formatMoney(currencyCode, value, precision)}`
        : `${formatMoney(companyCurrency, value / exchangeRate, precision)}`;
    } else {
      return formatMoney(currencyCode, value, precision);
    }
  };

export const percentFormatter = (value?: number) => `${value}%`;
