import cf from 'currency-formatter';

const BROWSER_LANGUAGE_CODE = 'en-US'; // TODO start using `window.navigator.language`;

/**
 * @deprecated use below functions
 * @param value
 * @param code
 * @param precision
 */
export const formatCurrency = (
  value: number,
  code: string,
  precision: number
) => cf.format(value, { code, precision });

/**
 * Returning localized number, eg.: (en-US) 1234567.89 -> 1,234,567.89
 * @param number
 * @param precision
 */
export const formatNumber = (number: number, precision?: number) =>
  number.toLocaleString(BROWSER_LANGUAGE_CODE, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });

type DecimalsConfig = {
  D: number;
  K: number;
  M: number;
  B: number;
  T: number;
};

/**
 * Shorting number function
 * Return localized shorten number, e.g.: (en-US) 1234567.89 -> 1.9M
 * @param decimalsConfig
 * @param value
 */
export const shortNumberWithConfig =
  (decimalsConfig: DecimalsConfig, positiveNegativeSign: boolean = true) =>
  (value: number): string => {
    const absValue: number = Math.abs(value);
    const valueToFormat = positiveNegativeSign ? value : absValue;

    switch (true) {
      case absValue < 1e3:
        return formatNumber(valueToFormat, decimalsConfig['D']);
      case absValue < 1e6:
        return formatNumber(valueToFormat / 1e3, decimalsConfig['K']) + 'K';
      case absValue < 1e9:
        return formatNumber(valueToFormat / 1e6, decimalsConfig['M']) + 'M';
      case absValue < 1e12:
        return formatNumber(valueToFormat / 1e9, decimalsConfig['B']) + 'B';
      default:
        return formatNumber(valueToFormat / 1e12, decimalsConfig['T']) + 'T';
    }
  };

const PRECISION = 2;
const NUMBER_TO_REPLACE = 0;
const FORMATTED_NUMBER_TO_REPLACE = formatNumber(NUMBER_TO_REPLACE, PRECISION);
/**
 * Return localized money value, e.g. ('eb-US' + currency 'USD') 1234567.89 -> $1,234,567.89
 * @param companyCurrency
 * @param number
 * @param precision
 */
export const formatMoney = (
  companyCurrency: string,
  number: number,
  precision: number = 0
) =>
  number?.toLocaleString
    ? number.toLocaleString(BROWSER_LANGUAGE_CODE, {
        style: 'currency',
        currency: companyCurrency,
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
      })
    : '-';

/**
 * Return localized money shorten value, e.g. ('eb-US' + currency 'USD') 1234567.89 -> $1.9M
 * @param companyCurrency
 * @param number
 * @param shortNumberFn
 * @param positiveSign
 */
export const formatMoneyAndShort = (
  companyCurrency: string,
  number: number,
  shortNumberFn: (value: number) => string,
  positiveSign: boolean = false,
  negativeSign: boolean = true
) => {
  const formattedMoney = formatMoney(
    companyCurrency,
    NUMBER_TO_REPLACE,
    PRECISION
  ).replace(FORMATTED_NUMBER_TO_REPLACE, shortNumberFn(Math.abs(number)));
  let signal = '';

  if (number > 0 && positiveSign) {
    signal = '+ ';
  } else if (number < 0 && negativeSign) {
    signal = '- ';
  }

  return `${signal}${formattedMoney}`;
};

const DECIMALS_CONFIG = {
  D: 0,
  K: 0,
  M: 1,
  B: 1,
  T: 1,
};

/**
 * Short for `formatMoneyAndShort` function with predefined shorten number function
 * @param number
 * @param companyCurrency
 * @param positiveSign
 */
export const formatAmount = (
  number: number,
  companyCurrency: string,
  positiveSign: boolean = false,
  negativeSign: boolean = true
): string =>
  formatMoneyAndShort(
    companyCurrency,
    number,
    shortNumberWithConfig(DECIMALS_CONFIG),
    positiveSign,
    negativeSign
  );

/**
 * Return currency symbol, e.g. USD -> $
 * @param companyCurrency
 */
export const getCurrencySymbol = (companyCurrency: string) =>
  formatMoney(companyCurrency, NUMBER_TO_REPLACE, PRECISION)
    .replace(FORMATTED_NUMBER_TO_REPLACE, '')
    .trim();

/**
 * Return percentage string value
 * @param number
 * @param precision
 */
export const formatPercentage = (
  value: number,
  precision: number = PRECISION
) => {
  return isNaN(value) ? '-' : `${formatNumber(value as number, precision)}%`;
};
