import classNames from 'classnames';
import { css } from 'emotion';
import React from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import {
  CustomComponentFunction,
  getCellValue,
  makeComponent,
} from 'components/UI/common/TypedTable/renderers/custom/common';

const noWrap = css`
  white-space: nowrap;
`;

const icon = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 0;

  .bu-icon {
    font-size: 1.2em;

    margin-right: 4px;
  }
`;

const enabledColor = css`
  color: var(--bu-green-500);
`;

const notEnabledColor = css`
  color: var(--bu-gray-500);
`;

const Enabled: React.FC = () => (
  <div className={classNames(noWrap, icon, enabledColor)}>
    <BuIcon name={BoostUpIcons.CheckCircle} />
    Enabled
  </div>
);

const NotEnabled: React.FC = () => (
  <div className={classNames(noWrap, icon, notEnabledColor)}>
    <BuIcon name={BoostUpIcons.CrossCircle} />
    Not Enabled
  </div>
);

export const enabledField: CustomComponentFunction = () => (props) => {
  const enabled = getCellValue<string>(props);

  if (!enabled) {
    return <NotEnabled />;
  }

  return <Enabled />;
};

export const EnabledField = makeComponent(enabledField);
