import { css } from 'emotion';

export const mainContent = css`
  display: flex;
  flex-direction: column;
`;

export const fieldRow = css`
  display: flex;
  align-items: flex-start !important;
  margin-bottom: 14px !important;
`;

export const flexField = css`
  display: flex !important;
  gap: 5px;
`;

export const label = css`
  color: #4a4a4a;
  flex: 1;
  font-size: 12px !important;
  font-weight: 600;
  line-height: 18px !important;
  margin-right: 10px;
  padding-top: 8px;
`;

export const toggleWithLabel = css`
  display: flex !important;
`;

export const toggleLabel = css`
  font-weight: 500;
  font-size: 11px;
`;

export const inputWrapper = css`
  display: inline-block;
  flex: 3;
  color: var(--bu-gray-900);
`;

export const smallDropdown = css`
  height: 30px !important;
  width: 170px !important;
`;

export const actionButtons = css`
  align-items: center;
  border-top: 1px solid var(--bu-gray-300);
  bottom: 0;
  display: flex;
  height: 60px;
  justify-content: flex-end;
  padding: 1px 22px;
  position: absolute;
  width: 100%;
`;

export const filterWrapper = css`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

export const buttons_container = css`
  display: flex;
  flex-direction: row;
  align-items: center;

  .ui.selection.dropdown {
    height: 32px !important;
    min-height: 32px !important;
  }

  .ui.selection.visible.dropdown > .text {
    color: var(--bu-primary-500) !important;
  }
`;

export const addPointer = css`
  cursor: pointer;
`;

export const dropdownStyle = css`
  height: 32px !important;
  margin-right: 20px !important;
  border: none !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  color: var(--bu-primary-500) !important;
  padding-top: 2px;
  cursor: pointer;
  background: transparent !important;
  transition: 0.4s;

  &:hover {
    background: var(--bu-gray-200) !important;
  }

  &.active {
    color: var(--bu-primary-500) !important;
    background: var(--bu-gray-200) !important;
  }
`;

export const dropdownStubStyle = css`
  height: 29px !important;
  width: 84px;
  padding-top: 8px;
  background: transparent;
  text-align: center;
  cursor: not-allowed;

  &:hover {
    background: var(--bu-gray-200) !important;
  }

  &.active {
    color: var(--bu-primary-500) !important;
    background: var(--bu-gray-200) !important;
  }
`;

export const emptyOptions = css`
  .menu {
    display: none !important;
  }
`;

export const buttonResetStyle = css`
  margin-right: 20px;
`;

export const actionIconContainerStyle = css`
  width: 20px;
`;

export const actionTrashIconContainerStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 32px;
  width: 32px;
  padding: 2px 0px 0px 5px;
  margin-left: -3px;
`;

export const withBorder = css`
  border: 1px solid var(--bu-gray-300);
  border-radius: 4px;
  border-left: none;
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
`;
export const addFieldDropdown = css`
  .bu-dropdown {
    border-color: transparent;
    color: var(--bu-primary-500);
  }
`;

export const subText = css`
  color: var(--bu-gray-700);
  margin-top: 7px;
  font-size: 1em;
  font-weight: normal;
`;

export const noMargin = css`
  margin: 0 !important;
`;

export const checkboxSubText = css`
  margin-left: 1.5em;
`;

export const submissionLockTextWrapper = css`
  margin-top: 10px;

  .title {
    font-size: 1.2em;
  }
`;
