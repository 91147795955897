import {
  connect,
  MapDispatchToPropsParam,
  MapStateToPropsParam,
} from 'react-redux';

import { getForecastQuotaAttainments } from 'actions/analyticsActions';
import SellerQuotaAttainmentWidget from 'components/dashboard/ForecastDashboard/SellerQuotaAttainmentWidget/SellerQuotaAttainmentWidget';
import {
  IDispatchProps,
  IOwnProps,
  IStateProps,
} from 'components/dashboard/ForecastDashboard/SellerQuotaAttainmentWidget/types';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

const mapStateToProps: MapStateToPropsParam<
  IStateProps,
  IOwnProps,
  IReduxState
> = (state) => ({
  quotaAttainments: selectors.forecastQuotaAttainments(state),
  isLoading: selectors.forecastQuotaAttainmentsLoading(state),
  companyCurrency: selectors.getUserLocalCurrency(state),
  filters: selectors.getFiltersForAPI(state, 'forecast_analytics'),
});

const mapDispatchToProps: MapDispatchToPropsParam<IDispatchProps, IOwnProps> = {
  getForecastQuotaAttainments,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SellerQuotaAttainmentWidget);
