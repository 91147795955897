import {
  connect,
  MapDispatchToPropsParam,
  MapStateToPropsParam,
} from 'react-redux';

import { actions } from 'actions';
import {
  getNewPipelineCreated,
  setNewPipelineCreatedFilter,
} from 'actions/pipelineActions';
import { USER_ROLES } from 'common/constants';
import PipelineWidget from 'components/dashboard/Pipeline/PipelineDashboard/PipelineWidget/PipelineWidget';
import {
  IDispatchProps,
  IOwnProps,
  IStateProps,
} from 'components/dashboard/Pipeline/PipelineDashboard/PipelineWidget/types';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

const mapStateToProps: MapStateToPropsParam<
  IStateProps,
  IOwnProps,
  IReduxState
> = (state) => ({
  pipelineData: selectors.newPipelineCreated(state),
  isLoading: selectors.newPipelineCreatedLoading(state),
  companyCurrency: selectors.getUserLocalCurrency(state),
  filters: selectors.getNewPipelineCreatedFilters(state),
  openFilters: selectors.getFiltersForAPI(state, 'pipeline_dashboard'),
  user: selectors.getUser(state),
  isCloudOps: selectors.isUserRole(state, USER_ROLES.SALES_OPERATIONS),
  isNewPipelineCreatedWidget: true,
});

const mapDispatchToProps: MapDispatchToPropsParam<IDispatchProps, IOwnProps> = {
  fetch: getNewPipelineCreated,
  setFilter: setNewPipelineCreatedFilter,
  persistModalParams: actions.ui.modal.persist,
};

export default connect(mapStateToProps, mapDispatchToProps)(PipelineWidget);
