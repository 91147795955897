import { css } from 'emotion';

export const fullscreen = css`
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;

  background-color: #fff;
  z-index: 1000;
`;

export const container = css`
  height: 100%;

  display: flex;
  flex-direction: column;

  color: #4a4a4a;
  font-size: 14px;
  line-height: 16px;
`;

export const header = css`
  background-color: var(--bu-gray-100);

  height: 60px;
  padding: 20px;

  color: var(--bu-gray-900);

  font-family: var(--bu-font-medium);
  font-size: 18px;
  line-height: 21px;

  display: flex;
  flex-direction: row;
`;

export const mainContent = css`
  flex: 1;
`;

export const actionButtons = css`
  border-top: 1px solid var(--bu-gray-300);

  height: 60px;
  padding: 13px 22px;

  text-align: right;

  & .ui.button {
    margin: 8px;

    &.primary {
      background-color: #0762ec;
    }
  }
`;

export const userHeaderContainer = css`
  display: flex;

  flex-direction: row;

  margin: 10px 30px 20px;

  & > .header {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const userDetailsContainer = css`
  padding: 10px 30px;

  & > .header {
    font-family: var(--bu-font-medium);
  }

  & > .item {
    border-top: 1px solid var(--bu-gray-300);

    &:last-child {
      border-bottom: 1px solid var(--bu-gray-300);
    }

    margin: 10px 0;
    padding: 10px 10px 0 0;

    display: flex;
    flex-direction: row;

    & > .lab {
      width: 200px;
      line-height: 38px;
    }

    & > .control {
      flex: 1;
      display: flex;
      align-items: center;

      .ui.dropdown {
        width: 440px;
      }
    }
  }
`;
