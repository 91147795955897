import classNames from 'classnames';
import React, { useState } from 'react';

import BuGroupButton from 'components/UI/BuGroupButton';
import DealsTable from 'components/UI/DealsFlatTableConfig/DealsTable.container';
import styles from 'components/account-insights/styles';

interface OwnProps {
  noResave?: boolean;
  accountId: string;
  openDealsCount: string;
  closedDealsCount: string;
}

type Tabs = 'open' | 'close';

const DealsAccount: React.FC<OwnProps> = ({
  noResave,
  accountId,
  openDealsCount,
  closedDealsCount,
}) => {
  const [openTab, setOpenTab] = useState('Open Opportunities');

  const baseRequest = {
    close_date_interval: ['ALT'],
    flat: true,
    page_size: 5,
    account_id: accountId,
    include_disabled: true,
  };

  const openDealsRequest = {
    ...baseRequest,
    deal_status: 'Open',
  };

  const closedDealsRequest = {
    ...baseRequest,
    deal_status: 'Closed',
  };

  const tabs: { [tab in Tabs]: { text: string; handlerText: string } } = {
    open: {
      text: `Open (${openDealsCount})`,
      handlerText: 'Open Opportunities',
    },
    close: {
      text: `Closed (${closedDealsCount})`,
      handlerText: 'Close Opportunities',
    },
  };

  const handleChangeTab = (tab: Tabs) => setOpenTab(tabs[tab].handlerText);
  const options = Object.keys(tabs).map((tab) => ({
    id: tab,
    text: tabs[tab as Tabs].text,
  }));

  const getOption = (value: any) =>
    value === 'Open Opportunities' ? 'open' : 'close';

  return (
    <>
      <div className={styles.tabs}>
        <BuGroupButton
          options={options}
          selectedOption={getOption(openTab)}
          onSelect={(value) => handleChangeTab(value as Tabs)}
        />
      </div>
      <div
        className={classNames({
          [styles.table]: true,
          open: openTab === 'Open Opportunities',
        })}
      >
        <DealsTable
          title="Open"
          tableConfigCollection="opportunity"
          tableConfigName="Opportunities"
          initialRequest={openDealsRequest}
          persistName={`account_open_${accountId}`}
          noResave={noResave}
        />
      </div>
      <div
        className={classNames(styles.table, {
          open: openTab === 'Close Opportunities',
        })}
      >
        <DealsTable
          title="Closed"
          tableConfigCollection="opportunity"
          tableConfigName="Opportunities"
          initialRequest={closedDealsRequest}
          persistName={`account_closed_${accountId}`}
          noResave={noResave}
        />
      </div>
    </>
  );
};

export default DealsAccount;
