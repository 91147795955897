import { IColumn, SortOrder } from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import ContactNameCell from 'components/dashboard/RepsRecommendations/customCells/ContactNameCell';
import DealCell from 'components/dashboard/RepsRecommendations/customCells/DealCell';
import EmailCell from 'components/dashboard/RepsRecommendations/customCells/EmailCell';
import {
  IGetColumnsConfig,
  RTableData,
  TableName,
} from 'components/dashboard/RepsRecommendations/types';
import { getComposeDismissButtonsConfig } from 'components/dashboard/RepsRecommendations/utils';

const apiPoint = `${process.env.REACT_APP_BACKEND_URL}/api/data/recommendations/type/contact_follow_up`;
const tableName: TableName = 'contact-follow-up';
const tableMinWidth = 1220;

const defaultQueryParams = {
  page_number: 1,
  page_size: 50,
  sort: '-contact_last_touched_at',
};

const initialState: RTableData = {
  total_count: 0,
  recommendations: [
    {
      account_id: '',
      account_name: '',
      contact_email: '',
      contact_last_engaged_at: null,
      contact_last_touched_at: null,
      contact_name: '',
      contact_role: 'L0',
      contact_title: '',
      created_at: '',
      description: null,
      id: '',
      last_email_message_id: null,
      last_email_received_at: null,
      last_email_subject: null,
      opportunity_name: null,
      type: 'CONTACT_FOLLOW_UP',
    },
  ],
};

const defaults = {
  id: '',
  delta: false,
  editable: false,
  resize: false,
  sort_field: null,
  sort_order: SortOrder.DESCENDING,
  sortable: true,
  type: ColumnTypes.TEXT,
  width: Math.floor(tableMinWidth / 5),
  config: {},
};

const getColumnsConfig = ({ compose, dismiss }: IGetColumnsConfig): IColumn[] =>
  [
    {
      ...defaults,
      field: 'contact_name',
      label: 'Contact Name',
      type: ColumnTypes.CUSTOM,
      width: 230,
      config: {
        renderer: ContactNameCell,
        tableName,
        truncateTextFrom: 32,
      },
    },
    {
      ...defaults,
      field: 'contact_last_touched_at',
      label: 'Last Touched',
      type: ColumnTypes.DATE,
      width: 120,
      config: {
        format: 'ago',
        withoutTooltip: true,
      },
    },
    {
      ...defaults,
      field: 'opportunity_name',
      label: 'Deal',
      type: ColumnTypes.CUSTOM,
      width: 230,
      config: {
        renderer: DealCell,
        tableName,
        truncateTextFrom: 32,
      },
    },
    {
      ...defaults,
      field: 'opportunity_close_date',
      label: 'Close date',
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.DATE,
      width: 120,
      config: {
        withoutTooltip: true,
      },
    },
    {
      ...defaults,
      field: 'last_email_subject',
      label: 'Last Email',
      sortable: false,
      type: ColumnTypes.CUSTOM,
      width: 470,
      config: {
        renderer: EmailCell,
        truncateTextFrom: 80,
      },
    },
    {
      ...defaults,
      field: '',
      label: 'Actions',
      sortable: false,
      type: ColumnTypes.ACTION,
      width: 120,
      config: {
        buttons: getComposeDismissButtonsConfig({ compose, dismiss }),
      },
    },
  ].map((col) => ({ ...col, id: col.label }));

export default {
  apiPoint,
  defaultQueryParams,
  getColumnsConfig,
  initialState,
  tableMinWidth,
  tableName,
};
