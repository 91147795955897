import { MetricFiltersDescription, Title } from './styles';
import React, { FC } from 'react';

import { ConditionsDescriptionList } from 'components/dashboard/Metrics/common/ConditionsDescriptionList/ConditionsDescriptionList';
import { BIMetricsQueryFilter } from 'components/dashboard/Metrics/metrics.types';

interface IProps {
  readonly filters: BIMetricsQueryFilter[];
}

export const BuMetricFiltersDescription: FC<IProps> = ({ filters }) => {
  if (filters.length > 0) {
    return (
      <MetricFiltersDescription>
        <Title>{'Conditions:'}</Title>
        <ul>
          <ConditionsDescriptionList filters={filters} />
        </ul>
      </MetricFiltersDescription>
    );
  }

  return <></>;
};
