import clx from 'classnames';
import { orderBy, uniqBy, isEmpty } from 'lodash';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { DropdownItemProps } from 'semantic-ui-react';

import { actions } from 'actions';
import { MANAGER_ROLES } from 'common/constants';
import * as styles from 'components/dashboard/ForecastDashboard/SellerQuotaAttainmentWidget/styles';
import { useClickOutside } from 'components/hooks/useClickOutside';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

type ReduxStateProps = ReturnType<typeof stateToProps>;
type ReduxDispatchProps = typeof dispatchToProps;
type Props = ReduxStateProps & ReduxDispatchProps;

const getAllUsers = (input: Filters.UserFilterElement[]) => {
  let output: Filters.UserFilterElement[] = [];

  input.forEach((i) => {
    i.team && i.team.length > 0
      ? (output = [...output, ...getAllUsers(i.team)])
      : output.push(i);
  });
  return output;
};

const UsersImpl: React.FC<Props> = ({ users, filters, filterPersist }) => {
  const persist = (value: string) => {
    filterPersist({
      tab: 'seller_dashboard',
      name: 'users_seller',
      values: [{ id: value, checked: true }],
      withReset: true,
    });
  };

  const onChange = useCallback(
    (e: React.SyntheticEvent<HTMLElement>, value: string) => {
      e.preventDefault();
      persist(value);
    },
    []
  );

  if (!filters.users_seller) {
    return null;
  }

  const values = getAllUsers(users!)
    .map((i) =>
      !Object.values(MANAGER_ROLES).includes(i.role!)
        ? { text: i.name || i.email, value: i.email, key: i.email }
        : {}
    )
    .filter((i) => i.text && i.value);

  const dropdownOptions = orderBy(
    uniqBy(values, 'value'),
    ['text'],
    ['asc']
  ) as DropdownItemProps[];

  const value =
    (filters.users_seller && filters.users_seller[0]) ||
    (dropdownOptions && dropdownOptions[0] && dropdownOptions[0]!.value);

  if (value !== (filters.users_seller && filters.users_seller[0])) {
    persist(value as string);
  }

  return (
    <DropdownCustom
      options={dropdownOptions}
      value={value}
      onClick={(e: React.SyntheticEvent<HTMLElement>, data: string) =>
        onChange(e, data)
      }
    />
  );
};

const stateToProps = (state: IReduxState) => ({
  users: selectors.getUsersForFilter(state),
  filters: selectors.getFiltersForAPI(state, 'seller_dashboard'),
});

const dispatchToProps = {
  filterPersist: actions.ui.filters.persist,
};

export const Users = connect(stateToProps, dispatchToProps)(UsersImpl);

type IpropsDropdownProps = {
  onClick: any;
  value: any;
  options: DropdownItemProps[];
};

const DropdownCustom: React.FC<IpropsDropdownProps> = ({
  onClick,
  value,
  options,
}) => {
  const { isOpen, setIsOpen, refElement } = useClickOutside();

  const toggle = () => setIsOpen(!isOpen);

  const option = options.find((i: DropdownItemProps) => i.value === value);

  const activeValue = option !== undefined ? option.text : '';

  return (
    <div
      ref={refElement}
      onClick={toggle}
      className={clx(styles.dropdrown, { active: isOpen })}
    >
      <span className={styles.dropdrown_value}>
        {activeValue}
        <i
          className={clx(styles.triangle, {
            'bu-up': isOpen,
            'bu-down': !isOpen,
          })}
        />
      </span>
      {!isEmpty(options) && (
        <ul className={clx(styles.dropdrown_options, { open: isOpen })}>
          {options.map((i: DropdownItemProps) => (
            <li
              onClick={(e) => onClick(e, i.value)}
              className={clx(styles.dropdrown_options_item, {
                active: i.value === value,
              })}
            >
              {i.text}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
