import classNames from 'classnames';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import * as s from 'components/UI/NavPanel/styles';
import {
  Panel,
  PanelCollapseExpandButton,
  PanelContainer,
  PanelSectionContainer,
  PanelSectionTitle,
} from 'components/UI/NavPanel/styles';
import {
  NavPanelGroup,
  NavPanelItem,
  NavPanelLinkItem,
  NavPanelProps,
  onClickHandler,
} from 'components/UI/NavPanel/types';
import Tooltip from 'components/UI/common/Tooltip';

const isNavLink = (item: NavPanelItem): item is NavPanelLinkItem =>
  Boolean(item && (item as NavPanelLinkItem).path);

const SectionItem: React.FC<{
  item: NavPanelItem;
  activeItem?: NavPanelItem['key'];
  onClick: onClickHandler;
}> = <T extends NavPanelItem = NavPanelItem>({
  item,
  activeItem,
  onClick,
}: {
  item: T;
  activeItem?: T['key'];
  onClick: onClickHandler<T>;
}) =>
  isNavLink(item) ? (
    <NavLink
      className={classNames(s.panelSectionItem, {
        active: item.key === activeItem,
      })}
      to={item.path}
      activeClassName="active"
      key={item.key}
    >
      {item.label}
    </NavLink>
  ) : (
    <div
      className={classNames(s.panelSectionItem, {
        active: item.key === activeItem,
      })}
      onClick={() => onClick(item)}
      key={item.key}
    >
      {item.label}
    </div>
  );

const SectionGroup: React.FC<{
  group: NavPanelGroup;
  activeItem?: NavPanelItem['key'];
  onClick: onClickHandler;
}> = <T extends NavPanelItem = NavPanelItem>({
  group,
  activeItem,
  onClick,
}: {
  group: NavPanelGroup<T>;
  activeItem?: T['key'];
  onClick: onClickHandler;
}) => (
  <>
    <PanelSectionTitle>{group.label}</PanelSectionTitle>

    {group.items
      .filter((item) => item.visible)
      .map((item) => (
        <SectionItem
          key={`${group.key}-${item.key}`}
          activeItem={activeItem}
          item={item}
          onClick={onClick}
        />
      ))}
  </>
);

const CollapseExpandButton: React.FC<{
  active: boolean;
  visible: boolean;
  onClick(): void;
}> = ({ active, onClick, visible }) => (
  <PanelCollapseExpandButton
    onClick={onClick}
    visible={visible}
    className={classNames({ visible })}
  >
    <Tooltip tooltip={active ? 'collapse' : 'fix opened'} position="top center">
      <BuIcon
        name={active ? BoostUpIcons.ChevronLeft : BoostUpIcons.ChevronRight}
      />
    </Tooltip>
  </PanelCollapseExpandButton>
);

const NavPanel: React.FC<NavPanelProps> = <T extends NavPanelItem>({
  activeItem,
  onClick = () => {},
  groups,
}: NavPanelProps<T>) => {
  const [active, setActive] = useState(true);

  const handleSwitchOpen = () => setActive((prev) => !prev);

  return (
    <Panel bgColor="var(--bu-primary-200)">
      <PanelContainer
        active={active}
        data-se="settings-sections"
        className={classNames({ active })}
      >
        <PanelSectionContainer active={active}>
          {groups
            .filter((item) => item.visible)
            .map((group) => (
              <SectionGroup
                key={group.key}
                activeItem={activeItem}
                group={group}
                onClick={onClick}
              />
            ))}
        </PanelSectionContainer>

        <CollapseExpandButton
          active={active}
          onClick={handleSwitchOpen}
          visible={active}
        />
      </PanelContainer>

      <CollapseExpandButton
        active={active}
        onClick={handleSwitchOpen}
        visible={!active}
      />
    </Panel>
  );
};

export default NavPanel;
