import moment from 'moment';
import { ThenaRequest } from 'components/UI/ProspectEngagement/types';

export const INFO_KEY_LIST: (keyof ThenaRequest)[] = [
  'urgency',
  'sentiment',
  'status',
  'assigned_to',
  'requestor',
  'updated_at',
  'closed_at',
  'closed_by',
  'ai_tags',
  'summary',
  'message_permalink',
  'internal_notification_link',
];

export const LIST_NAME_FROM_INFO_KEY: Partial<
  Record<keyof ThenaRequest, string>
> = {
  urgency: 'Urgency',
  sentiment: 'Sentiment',
  status: 'Status',
  assigned_to: 'Assigned to',
  requestor: 'Requestor',
  updated_at: 'Updated on',
  closed_at: 'Closed on',
  closed_by: 'Closed by',
  ai_tags: 'Tags',
  summary: 'Summary',
};

export const SPECIAL_LINK_NAMES: Partial<Record<keyof ThenaRequest, string>> = {
  message_permalink: 'Open in Slack',
  internal_notification_link: 'Open Internal Message Thread',
};

export const getRequestorValue = (thenaRequest: ThenaRequest) => {
  const {
    requestor_name: name,
    requestor_email: email,
    requestor: id,
  } = thenaRequest;

  if (name && email) {
    return `${thenaRequest.requestor_name} (${thenaRequest.requestor_email})`;
  } else if (name) {
    return name;
  } else if (email) {
    return email;
  }

  return id;
};

export const getIsSpecialLinkName = (infoKey: keyof ThenaRequest) =>
  Object.keys(SPECIAL_LINK_NAMES).includes(infoKey);

export const getListValueFromInfoKey = (thenaRequest: ThenaRequest) => ({
  ...thenaRequest,
  requestor: getRequestorValue(thenaRequest),
  updated_at: thenaRequest.updated_at
    ? moment(thenaRequest.updated_at).utc().format('Do MMM, YYYY, h:mma')
    : '',
  closed_at: thenaRequest.closed_at
    ? moment(thenaRequest.closed_at).utc().format('Do MMM, YYYY, h:mma')
    : '',
  closed_by:
    thenaRequest.closed_at && thenaRequest.closed_by
      ? thenaRequest.closed_by
      : '',
});
