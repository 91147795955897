import moment from 'moment';

export const fakeValuesDate = {
  startDate: moment().format('MM/DD/YYYY'),
  endDate: moment().add(7, 'days').format('MM/DD/YYYY'),
};

export const valuesConfigDate = [
  {
    checked: true,
    label: 'Current Week',
    type: 'PRESENT',
    value: 'TWE',
  },
  {
    checked: false,
    label: 'Current Week + 1',
    type: 'PRESENT',
    value: '2WN',
  },
  {
    checked: true,
    label: 'Current Month',
    type: 'PRESENT',
    value: 'TMO',
  },
  {
    checked: false,
    label: 'Current Month + 1',
    type: 'PRESENT',
    value: '2MN',
  },
  {
    checked: true,
    label: 'Current Quarter',
    type: 'PRESENT',
    value: 'TQU',
  },
  {
    checked: false,
    label: 'Current Quarter + 1',
    type: 'PRESENT',
    value: '2QN',
  },
  {
    checked: true,
    label: 'Current Year',
    type: 'PRESENT',
    value: 'TYE',
  },
  {
    checked: true,
    label: 'All Time',
    type: 'PRESENT',
    value: 'ALT',
  },
  //----------------------------------------------------------------
  {
    checked: true,
    label: 'Last Week',
    type: 'PAST',
    value: 'LWE',
  },
  {
    checked: false,
    label: 'Last Week - 1',
    type: 'PAST',
    value: 'L2W',
  },
  {
    checked: true,
    label: 'Last Quarter',
    type: 'PAST',
    value: 'LQU',
  },
  {
    checked: false,
    label: 'Last Quarter - 1',
    type: 'PAST',
    value: 'L6M',
  },
  {
    checked: true,
    label: 'Last Month',
    type: 'PAST',
    value: 'LMO',
  },
  {
    checked: false,
    label: 'Last Month - 1',
    type: 'PAST',
    value: 'L3M',
  },
  {
    checked: false,
    label: 'Last Year',
    type: 'PAST',
    value: 'LYE',
  },
  //----------------------------------------------------------------
  {
    checked: false,
    label: 'Next Week',
    type: 'FUTURE',
    value: 'NWE',
  },
  {
    checked: false,
    label: 'Next Week + 1',
    type: 'FUTURE',
    value: 'N2W',
  },
  {
    checked: true,
    label: 'Next Month',
    type: 'FUTURE',
    value: 'NMO',
  },
  {
    checked: false,
    label: 'Next Month + 1',
    type: 'FUTURE',
    value: 'N2M',
  },
  {
    checked: true,
    label: 'Next Quarter',
    type: 'FUTURE',
    value: 'NQU',
  },
  {
    checked: true,
    label: 'Next 3 Months',
    type: 'FUTURE',
    value: 'N3M',
  },
  {
    checked: true,
    label: 'Next 6 Months',
    type: 'FUTURE',
    value: 'N6M',
  },
  {
    checked: false,
    label: 'Closing in the future',
    type: 'FUTURE',
    value: 'FUT',
  },
];

export const config: Filters.Config = {
  checkedAll: false,
  checkedTotal: 0,
  title: 'Date Period',
  type: 'date',
  withShowAll: true,
  isLocked: false,
  values: valuesConfigDate,
  allowFuture: true,
};
