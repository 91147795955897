import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import BuRadio from 'components/UI/BuRadio';
import {
  container,
  infoParagraphRadio,
  optionContainer,
  radiosContainer,
} from 'components/dashboard/Metrics/Create/MetricDefinitionInputs/SimpleMetricsInputs/ForecastSubmissionOptions/styles';
import { MetricCreateSubTitle } from 'components/dashboard/Metrics/metrics.styles';
import {
  BIMetricSimple,
  BIMetricSimpleNewborn,
  DataDescriptor,
} from 'components/dashboard/Metrics/metrics.types';
import { MetricDateField } from '../MetricDateField';

export interface ForecastSubmissionOptionsProps {
  calculation: string | undefined;
  manager_aggregation_type: string | undefined;
  date_field?: DataDescriptor | null;
}
interface Props {
  metric: BIMetricSimple | BIMetricSimpleNewborn;
  isForecastAmountAccuracy: boolean;
  forecastSubmissionOptions: ForecastSubmissionOptionsProps;
  setForecastSubmissionOptions: Dispatch<
    SetStateAction<ForecastSubmissionOptionsProps>
  >;
}

export const ForecastSubmissionOptions: FC<Props> = ({
  metric,
  isForecastAmountAccuracy,
  forecastSubmissionOptions,
  setForecastSubmissionOptions,
}) => {
  const [dataAggregation, setDataAggregation] = useState<string>(
    forecastSubmissionOptions.manager_aggregation_type || 'self_revenue'
  );
  const [showForecastValues, setShowForecastValues] = useState<string>(
    forecastSubmissionOptions.calculation || 'absolute'
  );
  const [dateField, setDateField] = useState<DataDescriptor | undefined | null>(
    metric.date_field
  );

  const handleChangeHierarchyRollupSum = (value: string): void => {
    setDataAggregation(value);
  };

  const handleChangeDateField = ({
    field,
    complete,
  }: {
    field: DataDescriptor | null | undefined;
    complete: boolean;
  }): void => {
    setDateField(field);
  };

  useEffect(() => {
    setForecastSubmissionOptions({
      manager_aggregation_type: dataAggregation,
      calculation: showForecastValues,
      date_field: dateField,
    });
  }, [dataAggregation, showForecastValues, dateField]);

  return (
    <div className={container} data-testing="advanced-options-container">
      {isForecastAmountAccuracy && (
        <div className={radiosContainer}>
          <MetricCreateSubTitle>Show</MetricCreateSubTitle>
          <BuRadio
            onChange={() => setShowForecastValues('absolute')}
            checked={showForecastValues === 'absolute'}
            label="Absolute Values"
          ></BuRadio>
          <p className={infoParagraphRadio}>
            Will show absolute accuracy % e.g. 105%, 84% etc.
          </p>

          <BuRadio
            onChange={() => setShowForecastValues('deviation')}
            checked={showForecastValues === 'deviation'}
            label="Deviation Values"
          ></BuRadio>
          <p className={infoParagraphRadio}>
            Will show variation from the actual booked e.g. +5%, -5% etc.
          </p>
        </div>
      )}

      <MetricCreateSubTitle>Data Aggregation</MetricCreateSubTitle>
      <div className={optionContainer}>
        <BuRadio
          onChange={(_, { value }: { value: string }) =>
            handleChangeHierarchyRollupSum(value)
          }
          checked={['self_revenue', '', undefined].includes(dataAggregation)}
          value={'self_revenue'}
          label="Self Forecast Submissions"
        ></BuRadio>
        <p className={infoParagraphRadio}>
          Considers only the individual's submissions
        </p>
      </div>
      <div className={optionContainer} data-testing="manager-level-aggregation">
        <BuRadio
          onChange={(_, { value }: { value: string }) =>
            handleChangeHierarchyRollupSum(value)
          }
          checked={dataAggregation === 'direct_report_revenue'}
          value={'direct_report_revenue'}
          label="Team Forecast Submissions"
        ></BuRadio>
        <p className={infoParagraphRadio}>
          Aggregates only direct reports submissions
        </p>
      </div>
      <MetricCreateSubTitle>Advanced Options</MetricCreateSubTitle>
      <MetricDateField
        metric={metric}
        dateField={metric.date_field}
        onDateFieldChange={handleChangeDateField}
      />
    </div>
  );
};
