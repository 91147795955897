import { css } from 'emotion';
import React from 'react';

import TruncatedText from 'components/UI/TruncatedText/TruncatedText';
import { IDataCellProps } from 'components/UI/common/TypedTable/TypedTable';
import NotAvailableCell from 'components/UI/common/TypedTable/renderers/NotAvailableCell';
import { ModalLinkWithAnalytics } from 'navigation/Link';

const cellContainerStyle = css`
  vertical-align: middle !important;
  white-space: normal;
  word-break: normal;
`;

const blueLinkMoreStyle = css`
  cursor: pointer;
  margin-left: 4px;
  text-decoration: underline;

  color: var(--bu-clickable-cell);

  tr:hover & {
    color: var(--bu-clickable-cell__hover);
  }
`;

const EmailCell: React.FC<IDataCellProps> = ({
  column,
  row,
}: IDataCellProps) => {
  const { last_email_subject, last_email_message_id } = row;
  const { truncateTextFrom } = column.config;
  const text = (last_email_subject || 'none').toString();
  const length = truncateTextFrom || 30;

  return (
    <>
      {last_email_message_id ? (
        <div className={cellContainerStyle}>
          <TruncatedText inverted text={text} length={length} />
          <ModalLinkWithAnalytics
            className={blueLinkMoreStyle}
            action={`Open emailId:${last_email_message_id}`}
            category="Reps Recommendation"
            url={{
              scheme: '/email/:emailId',
              params: { emailId: last_email_message_id.toString() },
            }}
          >
            More
          </ModalLinkWithAnalytics>
        </div>
      ) : (
        <NotAvailableCell />
      )}
    </>
  );
};

export default EmailCell;
