import { css } from 'emotion';

export const inputsContainer = css`
  margin-bottom: 24px;
`;

export const radiosContainer = css`
  margin-bottom: 12px;
`;

export const tooltipSize = css`
  width: 300px;
`;
