import { connect } from 'react-redux';

import { actions } from 'actions';
import AccountsModal from 'components/modals/AccountsModal/AccountsModal';
import { PersistedProps } from 'components/modals/AccountsModal/types';
import { PersistQueryParams } from 'navigation/types';
import { IReduxState } from 'reducers/types';
import { getModalProps, getAccountHiddenColumns } from 'selectors';

const stateToProps = (state: IReduxState) => ({
  modalProps: getModalProps<PersistedProps>(state, '/accounts'),
  accountHiddenColumns: getAccountHiddenColumns(state),
});

type ReduxStateProps = ReturnType<typeof stateToProps>;
type ReduxDispatchProps = {
  persistModalParams: (params: PersistQueryParams) => void;
};

export type ReduxProps = ReduxStateProps & ReduxDispatchProps;

const dispatchToProps: ReduxDispatchProps = {
  persistModalParams: actions.ui.modal.persist,
};

export default connect(stateToProps, dispatchToProps)(AccountsModal);
