import Cookies from 'js-cookie';
import React from 'react';

import BoostUpLogo from 'assets/images/new_icon/boostup_logo.svg';
import ErrorIcon from 'assets/images/new_icon/error_icon.svg';
import * as styles from 'components/ErrorPage/styles';

const getErrorMessage = () => {
  const sfdcError = Cookies.get('sfdc-canvas-app-info');

  if (sfdcError === 'PROVISIONING_ERROR_COMPANY') {
    return (
      <>
        It seems that your organization is not on BoostUp yet.
        <br />
        Contact{' '}
        <a className={styles.link} href="mailto:sales@boostup.ai">
          sales@boostup.ai
        </a>{' '}
        for Signing up with BoostUp.
      </>
    );
  }

  if (sfdcError === 'PROVISIONING_ERROR_USER') {
    return (
      <>
        It appears that your account is not registered with BoostUp at this
        time.
        <br />
        Please contact your Sales Operations to get you an access to BoostUp.
      </>
    );
  }

  return (
    <>
      Something went wrong.
      <br />
      Contact{' '}
      <a className={styles.link} href="mailto:success@boostup.ai">
        success@boostup.ai
      </a>{' '}
      for help with BoostUp.
    </>
  );
};

const ErrorPage: React.FC = () => (
  <div className={styles.wrapper}>
    <img className={styles.boostUpLogo} src={BoostUpLogo} alt="Boostup Logo" />
    <img src={ErrorIcon} alt="Boostup Logo" />
    <div className={styles.title}>Oops!</div>
    <div className={styles.text}>{getErrorMessage()}</div>
  </div>
);

export default ErrorPage;
