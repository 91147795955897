import { css } from 'emotion';

import { fontSubtitle } from 'assets/css/common';

export const clear_message = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;

  p {
    ${fontSubtitle};
    font-size: 16px;
    color: #a4a4a4;
  }
`;

export const clear_btn = css`
  background-color: var(--bu-primary-500);
  color: var(--bu-white);
  ${fontSubtitle};
  padding: 7px 15px;
  border: 0;
  font-size: 14px;
  line-height: 19px;
  border-radius: 4px;
  margin-top: 15px;
  cursor: pointer;
  transition: 0.4s;

  &:hover {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  }
`;

export const wrapper = css`
  height: 100%;
  display: flex;
  flex-direction: column;

  .container,
  .container-dashboard {
    height: 100%;
    overflow-x: hidden;
  }

  .container {
    padding: 0;
  }

  .container-dashboard {
    display: flex;
    flex-direction: column;
  }
`;
