import { css } from 'emotion';
import * as R from 'ramda';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { createSelector } from 'reselect';

import { actions } from 'actions';
import * as dealsActions from 'actions/dealsActions';
import * as searchActions from 'actions/searchActions';
import BuToggle from 'components/UI/BuToggle';
import BusinessTypesPanel from 'components/UI/BusinessTypesPanel';
import OpenFiltersPanel from 'components/UI/OpenFiltersPanel';
import { useHeader } from 'components/UI/Wrapper/Header/header.context';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import Funnel from 'components/dashboard/Charts/Funnel';
import KPI from 'components/dashboard/Charts/KPI';
import RevenueStacked from 'components/dashboard/Charts/RevenueStacked';
import Unstacked from 'components/dashboard/Charts/Unstacked';
import ForecastTotalsPanel from 'components/dashboard/Forecast/ForecastTotalsPanel';
import StatisticsCore from 'components/dashboard/StatisticsCore';
import 'components/dashboard/styles.css';
import { Partition } from 'navigation/tabs';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';
import { FiltersForAPI } from 'selectors/openFilters';

const stickyTop = css`
  position: sticky;
  background: var(--bu-white);
  z-index: 989;
`;

export const businessTypesMetricsButtoWrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bu-white);
  border-bottom: 1px solid var(--bu-gray-400);
  margin: 0px -20px 0px -20px;
  padding: 9px 20px 4px 20px;

  > div {
    margin: 0;
  }
`;

const containerWrapper = css`
  height: 100%;
  background-color: var(--bu-primary-100);
`;

type ReduxStateProps = ReturnType<typeof stateToProps>;
type ReduxDispatchProps = typeof dispatchToProps;
interface OwnProps {
  readonly tabPartition: Partition;
}
type Props = ReduxStateProps & ReduxDispatchProps & OwnProps;

type Stage = {
  opportunity_stage: string;
};

const PipelineAnalytics: React.FC<Props> = ({
  clearFilters,
  filters,
  stagesFunnel,
  stats,
  statsStatus,
  totalCount,
  users,
  tabPartition = 'pipeline',
  selectedBusinessType,
  isOverallEnabled,
  changeTab,
  clearSearch,
  toggleFilters,
}) => {
  const { setPartition, clearContext } = useHeader();

  const { isRiskAnalyticsEnabled } = useSelector((state) => ({
    isRiskAnalyticsEnabled: selectors.getFeatureFlag(
      state,
      'risk_analytics_metrics_bar_enabled'
    ),
  }));
  const isMetricsExpanded = useSelector(selectors.isMetricsExpanded);

  const [showingMetrics, setShowingMetrics] = useState(isMetricsExpanded);

  const orderedStages = useMemo(() => {
    const sortOrder = stagesFunnel.map((item: String[]) => item[0]);

    return stats.stages.sort(
      (a: Stage, b: Stage) =>
        sortOrder.indexOf(a.opportunity_stage) -
        sortOrder.indexOf(b.opportunity_stage)
    );
  }, [JSON.stringify(stats.stages), JSON.stringify(stagesFunnel)]);

  const filtersAndBusinessType = {
    ...filters,
    business_type_name: selectedBusinessType,
  } as FiltersForAPI & {
    business_type_name: string;
  };

  const statsConfig = getStatConfig(
    stats,
    statsStatus,
    filtersAndBusinessType,
    users,
    stagesFunnel,
    orderedStages,
    'pipeline_analytics'
  );

  useEffect(() => {
    setPartition(tabPartition);

    AnalyticsTracker.event(
      {
        filters,
        stagesFunnel,
        stats,
        totalCount,
        users,
      },
      {
        category: 'Opportunities Dashboard',
        action: 'Tab: Pipeline Analytics',
        label: 'Tab Opened',
      }
    );

    clearSearch();
    changeTab('analytics');

    return () => {
      clearContext();
      changeTab(null);
    };
  }, []);

  const handleClickWrapper = useCallback(() => {
    toggleFilters('topOpportunities', true);
  }, []);

  const handleClearFilters = useCallback(() => {
    clearFilters({ tab: 'topOpportunities' });
  }, [clearFilters]);

  return (
    <div onClick={handleClickWrapper}>
      <div className={containerWrapper}>
        <div className="container">
          <div className="container-dashboard">
            <div className={stickyTop}>
              <OpenFiltersPanel tab="pipeline_analytics" />
            </div>

            <div className={businessTypesMetricsButtoWrapper}>
              <BusinessTypesPanel
                tab="pipeline_analytics"
                isOverallEnabled={isOverallEnabled}
              />
              <BuToggle
                checked={showingMetrics}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setShowingMetrics(e.target.checked)
                }
                testingLabel="roll-ups-metrics-toggle"
                leftLabel
              >
                Metrics
              </BuToggle>
            </div>

            {isRiskAnalyticsEnabled ? (
              <ForecastTotalsPanel
                filters={filtersAndBusinessType}
                tab="pipeline_analytics"
                showingMetrics={showingMetrics}
              />
            ) : null}

            <StatisticsCore
              clearFilters={handleClearFilters}
              config={statsConfig}
              isEmpty={totalCount === 0}
              status={statsStatus}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const getStatConfig = (
  stats: any,
  statsStatus: any,
  filters: FiltersForAPI,
  users: any,
  stagesFunnel: any,
  orderedStages: string[],
  tab: string
) => ({
  kpi: <KPI data={stats.totals} status={statsStatus} />,
  charts: [
    {
      columns: 1,
      key: 'row_1',
      widgets: [
        {
          key: 'funnel',
          component: (
            <Funnel
              data={stats.stages}
              status={statsStatus}
              funnel={stagesFunnel}
              title="Stage"
              filters={filters}
              tab={tab}
              localStorageKeyPrefix="RiskAnalytics"
            />
          ),
        },
      ],
    },
    {
      columns: 2,
      key: 'row_4',
      widgets: [
        {
          key: 'revenueByRisk',
          component: (
            <Unstacked
              data={stats.risk_factors}
              status={statsStatus}
              title="Deals by Risk Factors"
              color="var(--bu-color-default-chart-red)"
              hoverColor="var(--bu-color-default-chart-red-hover)"
              valueName="total_count"
              revenueValueName="total_amount"
              category="category_name"
              childFilterValue="risk_categories"
              childValueFormatter={(value: string) => {
                const riskCode = R.compose(
                  R.path(['category']),
                  R.find((el) => el.category_name === value)
                )(stats.risk_factors || []);
                return [riskCode];
              }}
              filters={filters}
              tab={tab}
              localStorageKeyPrefix="RiskAnalytics"
            />
          ),
        },
        {
          key: 'revenueByPositiveIndicators',
          component: (
            <Unstacked
              data={stats.positive_indicators}
              status={statsStatus}
              title="Deals by Positive Indicators"
              valueName="total_count"
              revenueValueName="total_amount"
              category="category_name"
              color="var(--bu-color-default-chart)"
              hoverColor="var(--bu-color-default-chart-hover)"
              childFilterValue="positive_indicators"
              childValueFormatter={(value: string) => {
                const positiveIndicatorCode = R.compose(
                  R.path(['category']),
                  R.find((el) => el.category_name === value)
                )(stats.positive_indicators);
                return [positiveIndicatorCode];
              }}
              filters={filters}
              tab={tab}
              localStorageKeyPrefix="RiskAnalytics"
            />
          ),
        },
      ],
    },
    {
      columns: 2,
      key: 'row_2',
      widgets: [
        {
          key: 'revenueByStage',
          component: (
            <RevenueStacked
              data={orderedStages}
              status={statsStatus}
              title="Stage"
              category="opportunity_stage"
              childFilterValue="opportunity_stages"
              childValueFormatter={(value: string) => [value]}
              filters={filters}
              tab={tab}
              localStorageKeyPrefix="RiskAnalytics"
            />
          ),
        },
        {
          key: 'revenueByTime',
          component: (
            <RevenueStacked
              data={stats.by_date}
              status={statsStatus}
              title="Close Date"
              category="date_group"
              childFilterValue="close_date_interval"
              childValueFormatter={(_value: string) => {
                // If it is being filter weekly, send weekday_date instead of date_group
                const value =
                  filters.close_date_interval.indexOf('TWE') !== -1
                    ? stats.by_date?.find(
                        (date: any) => date.date_group === _value
                      )?.weekday_date
                    : _value;

                return [...(filters.close_date_interval || []), value];
              }}
              filters={filters}
              tab={tab}
              localStorageKeyPrefix="RiskAnalytics"
            />
          ),
        },
      ],
    },
    {
      columns: 1,
      key: 'row_3',
      widgets: [
        {
          key: 'revenueBySeller',
          component: (
            <RevenueStacked
              data={stats.sellers}
              status={statsStatus}
              title="Seller"
              category="name"
              childFilterValue="users"
              hideFullScreenButton
              sort
              childValueFormatter={(value: string) => {
                const userEmail = R.compose(
                  R.path(['email']),
                  R.find((el) => el.name === value)
                )(users);
                return [userEmail];
              }}
              filters={filters}
              tab={tab}
              localStorageKeyPrefix="RiskAnalytics"
            />
          ),
        },
      ],
    },
  ],
});

const getTotalCount = createSelector(
  [(state) => selectors.getStatsTotals(state, 'analytics')],
  (stats) => R.pathOr(0, ['total_count'], stats)
);

const stateToProps = (state: IReduxState, ownProps: RouteComponentProps) => {
  const { deals_overall_view_enabled } = selectors.getFeatureFlags(state);

  const tabPartition: Partition =
    ownProps.location.pathname === '/dashboard/risk_analytics'
      ? 'dashboards'
      : 'pipeline';
  return {
    filters: selectors.getFiltersForAPI(state, 'pipeline_analytics'),
    stagesFunnel: selectors.getSettingsStagesFunnel(state),
    stats: selectors.getAllStats(state, 'analytics'),
    statsStatus: selectors.getStatsStatus(state, 'analytics'),
    totalCount: getTotalCount(state),
    users: selectors.getFlatFilterUsers(state),
    tabPartition,
    selectedBusinessType: selectors.getBusinessTypeForTab(
      state,
      'pipeline_analytics'
    ),
    isOverallEnabled: deals_overall_view_enabled,
  };
};

const dispatchToProps = {
  changeTab: dealsActions.changeDealActiveTab,
  clearFilters: actions.ui.filters.clearAll,
  clearSearch: () => searchActions.changeSearchQuery(''),
  toggleFilters: dealsActions.toogleFiltersBlock,
};

export default connect(stateToProps, dispatchToProps)(PipelineAnalytics);
