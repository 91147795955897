import clx from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import { actions } from 'actions';
import * as styles from 'components/dashboard/ForecastDashboard/SellerQuotaAttainmentWidget/styles';
import { useClickOutside } from 'components/hooks/useClickOutside';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

type ReduxStateProps = ReturnType<typeof stateToProps>;
type ReduxDispatchProps = typeof dispatchToProps;
type Props = ReduxStateProps & ReduxDispatchProps;

const SortByImpl: React.FC<Props> = ({ sortingField, updateSortBy }) => (
  <DropdownCustom
    options={[
      { text: 'Count', value: 'count' },
      { text: 'User name', value: 'username' },
    ]}
    value={sortingField}
    onClick={(value: 'count' | 'username') => updateSortBy({ field: value })}
    label="Sort By"
  />
);

const stateToProps = (state: IReduxState) => ({
  sortingField: selectors.getInternalActivitySortByFilter(state).field,
});

const dispatchToProps = {
  updateSortBy: actions.ui.filters.updateSortBy,
};

export const SortBy = connect(stateToProps, dispatchToProps)(SortByImpl);

type IpropsDropdownProps = {
  onClick: any;
  value: string;
  options: {
    text: string;
    value: string;
  }[];
  label: string;
};

const DropdownCustom: React.FC<IpropsDropdownProps> = ({
  onClick,
  value,
  options,
  label,
}) => {
  const { isOpen, setIsOpen, refElement } = useClickOutside();

  const toggle = () => setIsOpen(!isOpen);

  const option = options.find((i) => i.value === value);

  const activeValue = option !== undefined ? option.text : '';

  return (
    <div
      ref={refElement}
      onClick={toggle}
      className={clx(styles.dropdrown, { active: isOpen })}
    >
      <span className={styles.dropdrown_label}>{label}:</span>
      <span className={styles.dropdrown_value}>
        {activeValue}
        <i
          className={clx(styles.triangle, {
            'bu-up': isOpen,
            'bu-down': !isOpen,
          })}
        />
      </span>
      <ul className={clx(styles.dropdrown_options, { open: isOpen })}>
        {options.map((item) => (
          <li
            onClick={() => onClick(item.value)}
            className={clx(styles.dropdrown_options_item, {
              active: item.value === value,
            })}
          >
            {item.text}
          </li>
        ))}
      </ul>
    </div>
  );
};
