import Promise from 'promise';
import { dissoc, merge } from 'ramda';

import { createQs } from 'common/utils';
import errorHandler from 'helpers/errorHandler';

let fetcher = fetch;

const parseJSON = (response) => response.json();

function extractAuthToken(data) {
  return dissoc('api_access_token', data);
}

const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  return response.json().then((json) => {
    const { statusText } = response;
    const message = { ...json, statusText };
    const error = new Error();

    error.message = message;
    error.response = response;

    throw error;
  });
};

const getAjax = (uri, data) => {
  if (data) {
    uri += createQs(data);
  }
  return fetcher(uri, {
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(parseJSON);
};

export const getAction =
  (endPoint, reqData, successCallback, errorCallback, errorMessage) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      const data = extractAuthToken(reqData);

      getAjax(endPoint, data).then(
        (res) => resolve(res),
        (error) => reject(error)
      );
    }).then(
      (res) => dispatch(successCallback(res)),
      (error) => {
        const message = errorMessage || error.message;
        const nextUrl = errorHandler(error);
        if (nextUrl) {
          return dispatch(nextUrl);
        }
        return dispatch(errorCallback(message));
      }
    );

const postAjax = (uri, data) => {
  let opts = {
    method: 'post',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (data) {
    opts = merge(opts, {
      body: JSON.stringify(data),
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  return fetcher(uri, opts).then(checkStatus).then(parseJSON);
};

export const postAction =
  (endPoint, reqData, successCallback, errorCallback, errorMessage) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      const data = extractAuthToken(reqData);
      postAjax(endPoint, data).then(
        (res) => resolve(res),
        (error) => reject(error)
      );
    }).then(
      (res) => dispatch(successCallback(res)),
      (error) => {
        const nextUrl = errorHandler(error);
        if (nextUrl) {
          return dispatch(nextUrl);
        }
        const message = errorMessage || error.message;
        return dispatch(errorCallback(message));
      }
    );

const deleteAjax = (uri, data) => {
  if (data) {
    uri += createQs(data);
  }
  return fetcher(uri, {
    method: 'DELETE',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
    },
  }).then(checkStatus);
};

export const deleteAction =
  (endPoint, reqData, successCallback, errorCallback, errorMessage) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      const data = extractAuthToken(reqData);
      deleteAjax(endPoint, data).then(
        (response) => resolve(response),
        (error) => reject(error)
      );
    }).then(
      (response) => dispatch(successCallback(response)),
      (error) => {
        const nextUrl = errorHandler(error);
        if (nextUrl) {
          return dispatch(nextUrl);
        }
        if (error.response.status === 404) {
          return dispatch(successCallback(error.response));
        }
        const message = errorMessage || error.message;
        return dispatch(errorCallback(message));
      }
    );
