import { css } from 'emotion';

import { fontDefaultDropdown } from 'assets/css/common';

export const time_filter_dropdown_tabs = css`
  width: 100%;
  height: 31px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  background: var(--bu-gray-200);
`;

export const time_filter_historical_dropdown_tab = css`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--bu-gray-700);
  ${fontDefaultDropdown};
  border-bottom: 1px solid var(--bu-gray-300);

  &.selected {
    color: var(--bu-gray-900);
    border-color: var(--bu-white);
    background: var(--bu-white);
    border-left: 1px solid var(--bu-gray-300);
    border-right: 1px solid var(--bu-gray-300);
  }
`;

export const time_filter_dropdown_tab = css`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--bu-gray-700);
  ${fontDefaultDropdown};
  border-bottom: 1px solid var(--bu-gray-300);
  height: 31px;

  &.selected {
    color: var(--bu-gray-900);
    border-color: var(--bu-white);
    background: var(--bu-white);
    border-left: 1px solid var(--bu-gray-300);
    border-right: 1px solid var(--bu-gray-300);
  }

  &.borderRight {
    border-right: 1px solid var(--bu-gray-300);
  }

  &.borderLeft {
    border-left: 1px solid var(--bu-gray-300);
  }
`;

export const info_message_container = css`
  max-width: 194px;
  font-size: 14px;
  text-align: center;
  color: 'var(--bu-gray-900)';
  padding: 12px;
`;

export const include_black_section = css`
  border-top: 1px solid var(--bu-gray-400);
  background-color: var(--bu-gray-100);
  padding: 10px;
`;
