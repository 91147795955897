import BuInput from 'components/UI/BuInput';
import { MetricCreateSubTitle } from 'components/dashboard/Metrics/metrics.styles';
import { inputsContainer } from '../styles';

interface MetricDescriptionInputProps {
  metricDescription?: string;
  onMetricDescriptionChange: (value?: string) => void;
}

const MetricDescriptionInput = ({
  metricDescription,
  onMetricDescriptionChange,
}: MetricDescriptionInputProps) => (
  <div className={inputsContainer} data-testing="object-section">
    <MetricCreateSubTitle>Description</MetricCreateSubTitle>
    <BuInput
      placeholder={`Enter a description`}
      type="text"
      value={metricDescription}
      onChange={(e) => {
        onMetricDescriptionChange(e.target.value);
      }}
      testingLabel={`metric-description`}
    />
  </div>
);

export default MetricDescriptionInput;
