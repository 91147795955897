import classNames from 'classnames';
import React, { useState } from 'react';
import { Dropdown } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import {
  pivotOptionsBuilder,
  removePivotBuilder,
  selectedPivotLabel,
} from 'components/dashboard/Metrics/Widget/Controls/Live/helpers';
import {
  pivotWidget,
  pivotZIndex,
  pivotWidgetMenu,
  pivotWidgetSearch,
} from 'components/dashboard/Metrics/Widget/Controls/Live/styles';
import {
  DataDescriptor,
  BIPivotDescriptor,
} from 'components/dashboard/Metrics/metrics.types';

interface Props {
  readonly groups: DataDescriptor[];
  readonly options: BIPivotDescriptor[];
  readonly timeField?: DataDescriptor | null;
  readonly canRemovePivots?: boolean;
  onPivotChange: (values: string[], pivotNro: number) => void;
}

export const FirstPivot: React.FC<Props> = ({
  groups,
  options,
  timeField,
  canRemovePivots = true,
  onPivotChange,
}) => {
  const [searchValue, setSearchValue] = useState<string>('');

  return (
    <Dropdown
      fluid
      data-testing={`pivot-0`}
      direction="right"
      className={classNames(pivotWidget, pivotZIndex(0 + 1))}
      text={selectedPivotLabel(groups[0], timeField)}
      onClose={() => setSearchValue('')}
    >
      <Dropdown.Menu className={pivotWidgetMenu}>
        <div className={pivotWidgetSearch}>
          <BuIcon name={BoostUpIcons.Search} />
          <input
            value={searchValue}
            type="text"
            placeholder="Search..."
            data-testing="search_txt_field"
            onChange={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setSearchValue(e.target.value);
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onKeyDown={(e) => {
              if (e.key === ' ') {
                e.preventDefault();
                e.stopPropagation();
                setSearchValue((prev) => {
                  if (prev && prev !== ' ') {
                    return prev + ' ';
                  }

                  return prev;
                });
              }
            }}
          />
        </div>

        {canRemovePivots &&
          groups[0] &&
          removePivotBuilder(`pivot-remove-option`, 0, onPivotChange)}

        {pivotOptionsBuilder(
          options,
          onPivotChange,
          `pivot-0-option`,
          searchValue,
          0,
          groups
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
