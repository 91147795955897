import { css } from 'emotion';

export const container = css`
  margin-left: -20px;
  margin-right: -20px;
  height: 80vh;

  .container .container-dashboard {
    height: 80vh;
  }
`;
