import { css } from 'emotion';

import { fontDefault } from 'assets/css/common';

export const wrapper = css`
  height: 100%;
  overflow: hidden;
`;

export const header = css`
  display: flex;
  margin: 20px 0 10px;
`;

export const title = css`
  font-family: var(--bu-font-semi-bold);
  font-size: 18px;
  color: #4a4a4a;
  display: flex;
  align-items: center;
`;

export const filters = css`
  margin-left: auto;
  display: flex;
  align-items: center;

  > div {
    margin-left: 10px;
  }
`;

export const dropdownContainer = css`
  &.ui.selection.dropdown.active,
  &.ui.selection.dropdown.visible {
    z-index: 1000;
  }
`;

export const tableContainer = (checkedColumnsCount: number) => css`
  .ui.table.structured {
    & thead {
      tr:first-child {
        > th:not(:last-child) {
          border-right: 2px solid #ddd;
        }

        > th:last-child {
          background-color: var(--bu-white) !important;
        }
      }

      tr:last-child {
        > th:nth-child(${checkedColumnsCount}n + 1):not(:last-child) {
          border-right: 2px solid #ddd;
        }

        > th:nth-last-child(-n + ${checkedColumnsCount}) {
          background-color: var(--bu-white) !important;
        }
      }
    }

    & tbody tr {
      > td:nth-child(${checkedColumnsCount}n + 1):not(:last-child) {
        border-right: 2px solid #ddd;
      }

      > td:nth-last-child(-n + ${checkedColumnsCount}):not(:only-child) {
        background-color: var(--bu-white) !important;
      }
    }
  }
`;

export const buttonGroupContainer = css`
  margin: 10px 0 10px;
`;

//------------------------

export const dropdrown = css`
  border: 1px solid var(--bu-gray-500);
  padding: 5px 8px;
  border-radius: 3px;
  cursor: pointer;
  background: var(--bu-white);
  position: relative;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background: rgba(49, 92, 121, 0.12);
  }

  &.active {
    border-color: var(--bu-primary-500);
  }
`;

export const dropdrown_options = css`
  &.open {
    display: block;
  }

  display: none;
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  right: -2px;
  top: 36px;
  z-index: 1000;
  background: var(--bu-white);
  border-radius: 4px;
  width: max-content;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  border: 1px solid var(--bu-gray-500);
`;

export const dropdrown_options_list = css`
  max-height: 204px;
  min-width: 180px;
  overflow: auto;
`;

export const dropdrown_options_item = css`
  padding: 8px 15px;
  ${fontDefault};

  &.active {
    background: rgba(48, 176, 209, 0.08);
  }
  &:hover {
    background: rgba(48, 176, 209, 0.08);
  }
`;

export const triangle = css`
  border-style: solid;
  height: 0;
  width: 0;
  border-width: 4px;
  border-color: var(--bu-gray-900);
  display: inline-block;
  margin: 0 2px 0 6px;

  &.bu-up {
    border-top-width: 0;
    border-top-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    transform: translateY(-50%);
    border-color: var(--bu-primary-500);
  }

  &.bu-down {
    border-bottom-width: 0;
    border-bottom-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    transform: translateY(-50%);
  }
`;

export const dropdown_search_icon = css`
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 7px;
  font-size: 10px;

  i {
    color: var(--bu-gray-700);
  }
`;

export const dropdown_search = css`
  position: relative;
  width: 100%;
  height: 32px;
  font-size: 14px;

  input {
    position: absolute;
    left: 0px;
    top: 0;
    margin-left: -1px;
    bottom: 0;
    border: 1px solid var(--bu-gray-500);
    width: 101%;
    border-radius: 4px 4px 0 0;
    padding: 0 25px 0 25px;
    transition: 0.4s;

    &:focus {
      border-color: var(--bu-gray-700);
    }
  }
`;

export const btn_reset_input = css`
  position: absolute;
  right: 8px;
  top: 50%;
  font-size: 10px;
  transform: translateY(-50%);
`;

export const no_result = css`
  display: flex;
  justify-content: center;
  flex-direction: column;

  p {
    font-size: 14px;
    text-align: center;
    margin: 10px 0;
  }

  button {
    width: 120px;
    margin: 0 auto;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 5px;
    border-radius: 3px;
    background: var(--bu-white);
    border: 1px solid var(--bu-gray-700);
    transition: 0.4s;

    &:hover {
      background: var(--bu-gray-400);
      border-color: var(--bu-primary-500);
    }
  }
`;
