import React from 'react';
import { Dimmer } from 'semantic-ui-react';

import * as s from 'components/UI/Modal/QuickView/styles';
import {
  QuickViewContainer,
  QuickViewCloseButton,
} from 'components/UI/Modal/QuickView/styles';

type Props = {
  onClose: () => void;
  size?: 'large' | 'small' | 'tiny' | 'mini';
  enabled?: boolean;
};

const QuickView: React.FC<Props> = ({
  onClose,
  size,
  children,
  enabled = true,
}) => {
  const closeHandler = () => {
    onClose();
  };

  if (!enabled) {
    return null;
  }

  return (
    <Dimmer active onClickOutside={closeHandler} className={s.wrapper}>
      <QuickViewCloseButton
        type="button"
        onClick={closeHandler}
        className={size}
        data-testing="Btn_close-panel"
      />
      <QuickViewContainer className={size}>{children}</QuickViewContainer>
    </Dimmer>
  );
};

export default QuickView;
