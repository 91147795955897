import { connect } from 'react-redux';

import { fetchEmail } from 'actions/emailActions';
import EmailDetailModal from 'components/modals/EmailDetailModal/EmailDetailModal';
import { OwnProps } from 'components/modals/EmailDetailModal/types';
import { IReduxState } from 'reducers/types';
import {
  getEmail,
  getEmailStatus,
  getModalProps,
  getFeatureFlags,
} from 'selectors';

const stateToProps = (state: IReduxState, props: OwnProps) => {
  const { email_convert_newline_enabled } = getFeatureFlags(state);
  return {
    status: getEmailStatus(state),
    email: getEmail(state, props.params.emailId),
    modalProps: getModalProps<{ topic?: string }>(state, '/email/:emailId'),
    isEmailConvertNewlineEnabled: email_convert_newline_enabled,
  };
};

const dispatchToProps = {
  fetch: fetchEmail,
};

export type ReduxStateProps = ReturnType<typeof stateToProps>;
export type ReduxDispatchProps = typeof dispatchToProps;

export default connect(stateToProps, dispatchToProps)(EmailDetailModal);
