import { FC } from 'react';
import { useSelector } from 'react-redux';

import BuSelect from 'components/UI/BuSelect';
import { inputsContainer } from 'components/dashboard/Metrics/Create/MetricDefinitionInputs/styles';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import { MetricCreateSubTitle } from 'components/dashboard/Metrics/metrics.styles';
import { IReduxState } from 'reducers/types';
import { getObjectsListFormattedByAnalysisType } from 'selectors/revbi/metrics';

interface Props {
  readonly analysisType: AnalysisType;
  readonly metricObject: string;
  onChangeMetricObject: (value: string) => void;
}

export const MetricObjectSelector: FC<Props> = ({
  analysisType,
  metricObject,
  onChangeMetricObject,
}) => {
  const objectSelectorOptions = useSelector((state: IReduxState) =>
    getObjectsListFormattedByAnalysisType(state, analysisType)
  );

  const handleChange = (values: string[]) => {
    const [value] = values;
    onChangeMetricObject(value);
  };

  return (
    <div className={inputsContainer} data-testing="object-section">
      <MetricCreateSubTitle>Object</MetricCreateSubTitle>
      <BuSelect
        fullWidth
        isLargePlaceholder
        secondary
        placeholder="Select a table"
        defaults={[metricObject]}
        options={objectSelectorOptions}
        onChange={handleChange}
        testingLabel="object"
      />
    </div>
  );
};
