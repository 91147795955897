import { css } from 'emotion';

export const rContainer = css`
  display: flex;
  flex-direction: row;
  height: 100%;

  & > div:first-child {
    flex-shrink: 0;
  }

  & > div:last-child {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 20px 30px;
  }
`;

export const rTableContainer = css`
  overflow: auto;
  width: 100%;
  margin: 10px -30px 0 -20px;
`;
