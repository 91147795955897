import classNames from 'classnames';
import { css } from 'emotion';
import React, { useCallback } from 'react';

import TruncatedText from 'components/UI/TruncatedText/TruncatedText';
import { IDataCellProps } from 'components/UI/common/TypedTable/TypedTable';
import NotAvailableCell from 'components/UI/common/TypedTable/renderers/NotAvailableCell';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import { openModal } from 'navigation/utils';

const cellContainerStyle = css`
  display: flex;
  flex-direction: column;
`;

const dealNameStyle = css`
  color: var(--bu-clickable-cell);

  tr:hover & {
    color: var(--bu-clickable-cell__hover);
  }
  margin-bottom: 3px;
`;

const cursorPointerStyle = css`
  cursor: pointer;
`;

const DealCell: React.FC<IDataCellProps> = ({
  column,
  row,
}: IDataCellProps) => {
  const { truncateTextFrom = 25 } = column.config;
  const { opportunity_id, opportunity_name, _id, amount } = row;

  const opportunityId = opportunity_id || _id;

  const handleClick = useCallback(() => {
    if (opportunityId) {
      openModal({
        scheme: '/deal/:id',
        params: { id: `${opportunityId}` },
      });

      AnalyticsTracker.event(
        {
          row,
        },
        {
          action: `Open details for deal id:${opportunityId} `,
          category: 'Reps Recommendation',
          label: 'Deal details modal opened',
        }
      );
    }
  }, [opportunityId, row.id]);

  return !opportunity_id && !opportunity_name ? (
    <NotAvailableCell />
  ) : (
    <div className={cellContainerStyle}>
      {opportunity_name ? (
        <TruncatedText
          inverted
          length={truncateTextFrom}
          onClick={handleClick}
          text={`${opportunity_name}`}
          textClassName={classNames(dealNameStyle, {
            [cursorPointerStyle]: opportunityId,
          })}
        />
      ) : null}
    </div>
  );
};

export default DealCell;
