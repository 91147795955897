import classNames from 'classnames';
import debounce from 'lodash/debounce';
import React, {
  FC,
  useRef,
  useState,
  useEffect,
  ChangeEvent,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { formatAmount } from 'common/helpers';
import BuIcon from 'components/UI/BuIcon';
import * as s from 'components/UI/OpenFiltersPanel/styles';
import { Props } from 'components/UI/OpenFiltersPanel/types';
import { useClickOutside } from 'components/hooks/useClickOutside';
import { SliderLayout } from 'components/settings/FieldConfiguration/CustomFieldsConfiguration/MoneyConfigCustom';
import { RangeType } from 'components/settings/FieldConfiguration/CustomFieldsConfiguration/types';
import { getUserLocalCurrency } from 'selectors/settings';

type Range = {
  min: number;
  max: number;
  minValue: number;
  maxValue: number;
};

const warningMessages = {
  min: 'The minimum value cannot be greater than the maximum',
  max: 'The maximum value cannot be less than the minimum',
};

export const DropdownMoneySlider: FC<Props> = ({
  config,
  name,
  onChange,
  className,
}) => {
  const [error, setError] =
    useState<RangeType.max | RangeType.min | string>('');
  const [state, setState] = useState<Range>({
    min: 0,
    max: 100,
    minValue: 0,
    maxValue: 100,
  });
  const { isOpen, setIsOpen, refElement } = useClickOutside();
  const { isChanged, isLocked, title, values } = config;

  const companyCurrencyCode = useSelector(getUserLocalCurrency);

  const panelRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLDivElement>(null);

  const handleUpdate = ({ min, max, minValue, maxValue }: Range) => {
    setState({ min, max, minValue, maxValue });
  };

  const debounceSetValue = useCallback(
    debounce((min: number, max: number, error: string) => {
      if (error) {
        return;
      }
      onChange([{ id: `[${min}:${max}]`, checked: true }], true);
    }, 800),
    []
  );

  const rangeValues = values
    .filter((el) => el.label === RangeType.min || el.label === RangeType.max)
    .reduce<Record<string, number>>((acc, el) => {
      acc[el.label] = +el.value;
      return acc;
    }, {});

  useEffect(() => {
    return () => {
      if (Boolean(error)) {
        setError('');
        setState({ ...state, min: rangeValues.min, max: rangeValues.max });
      }
    };
  }, [setIsOpen, isOpen, error]);

  useEffect(() => {
    setState({
      min: rangeValues.min,
      max: rangeValues.max,
      minValue: rangeValues.min,
      maxValue: rangeValues.max,
    });
  }, []);

  useEffect(() => {
    debounceSetValue(state.min, state.max, error);
  }, [state.min, state.max]);

  const handleChangeRange = (e: ChangeEvent<HTMLInputElement>) => {
    const priceGap = state.max / 100;
    const name = e.target.name;
    const value = e.target.valueAsNumber;

    if (
      (name === RangeType.min && value >= state.max - priceGap) ||
      (name === RangeType.max && value <= state.min + priceGap)
    ) {
      return;
    }

    if (state.max - state.min < priceGap) {
      handleUpdate({
        ...state,
        ...(name === RangeType.min
          ? { minValue: state.max - priceGap }
          : { maxValue: state.min + priceGap }),
      });
    } else {
      handleUpdate({ ...state, [name]: value });
    }
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    name: RangeType.min | RangeType.max
  ) => {
    const value = e.currentTarget.valueAsNumber;
    setError('');

    const update = (value: number) => {
      handleUpdate({
        ...state,
        [name]: value,
      });
    };

    if (
      (name === RangeType.min && value > state.max) ||
      (name === RangeType.max && value < state.min)
    ) {
      setError(name);
    }

    update(value);
  };

  const label = `${formatAmount(
    companyCurrencyCode,
    state.min
  )} - ${formatAmount(companyCurrencyCode, state.max)}`;

  return (
    <div
      id={`${name}_dropdown`}
      ref={refElement}
      className={classNames(s.panel_wrapper, className)}
    >
      <div
        ref={labelRef}
        className={classNames(s.panel_dropdown_button, {
          isChanged,
          isOpen,
        })}
        onClick={() => !isLocked && setIsOpen(!isOpen)}
      >
        <span className={s.panel_dropdown_name}>{title}:</span>
        <span className={s.panel_dropdown_value}>{label}</span>

        <BuIcon
          name={isOpen ? BoostUpIcons.TriangleUp : BoostUpIcons.TriangleDown}
          color="var(--bu-gray-900)"
          className={s.icon}
        />
      </div>

      {isOpen && (
        <div
          ref={panelRef}
          className={s.panel_dropdown}
          style={{ width: 380, padding: '20px 20px' }}
        >
          <SliderLayout
            sliderProps={{
              min: state.minValue,
              max: state.maxValue,
              minValue: state.min,
              maxValue: state.max,
              onChange: handleChangeRange,
            }}
            handleChange={handleChange}
            sliderRange={{ min: state.min, max: state.max }}
            error={error}
          />

          {Boolean(error) && (
            <div className={s.warning_box}>
              {warningMessages[error as RangeType.max | RangeType.min]}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
