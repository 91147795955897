import classNames from 'classnames';
import React from 'react';

import { fontDefault, fontDisplay, fontSubtitle } from 'assets/css/common';
import UserIcon from 'assets/fonts/boostup-icons/user.svg';
import { formatAmount } from 'common/numbers';
import { ValueProp } from 'components/UI/common/TypedTable/TypedTable';
import Badge from 'components/dashboard/Forecast/Dashboard/Badge';
import styles, {
  MainValueWrapper,
  MainValue,
  FormulaTitle,
  VariableValuesTable,
  TableBody,
} from 'components/dashboard/ForecastRollUps/CustomColumnTooltips/styles';

type Props = {
  title: string;
  selectedBusinessType: string;
  expression: string;
  expressionValues: {
    variable: string;
    fieldName: string;
    value: number;
  }[];
  cellValue: number;
  companyCurrency: string;
  myOrg: string;
  currencyValueFormatter: (value: number) => string;
  deltaValue?: number;
  formulaKeysValues: { [key: string]: number | ValueProp };
};

const CellTooltip: React.FC<Props> = ({
  title,
  selectedBusinessType,
  expression,
  expressionValues = [],
  cellValue,
  companyCurrency,
  myOrg,
  currencyValueFormatter,
  deltaValue = 0,
  formulaKeysValues,
}) => (
  <div className={classNames('bu-font-default', styles.container)}>
    <div className={classNames(styles.row, styles.header)}>
      <div className="title">{title}</div>

      <div className="details">
        <img src={UserIcon} alt="User" />
        <div>
          {myOrg} ({selectedBusinessType})
        </div>
      </div>
    </div>
    <div className={classNames(styles.column, styles.body)}>
      <MainValueWrapper>
        <MainValue className={classNames(fontDisplay)}>
          {formatAmount(cellValue, companyCurrency)}
        </MainValue>
        {isFinite(deltaValue) && (
          <span className={styles.badgeWrapper}>
            <Badge value={deltaValue} />
          </span>
        )}
      </MainValueWrapper>

      <FormulaTitle className={classNames(fontDefault)}>Formula</FormulaTitle>
      <span className={classNames(fontSubtitle)}>{expression}</span>

      <VariableValuesTable>
        <TableBody>
          <div className={classNames(fontSubtitle, 'table-header')}>
            Variable
          </div>
          <div className={classNames(fontSubtitle, 'table-header')}>Values</div>

          {expressionValues.map(({ variable, fieldName, value }) => (
            <React.Fragment key={variable}>
              <div>{variable}</div>
              <div className="values-container">
                {currencyValueFormatter(value)}
                {isFinite(formulaKeysValues[fieldName] as number) && (
                  <Badge value={formulaKeysValues[fieldName] as number} />
                )}
              </div>
            </React.Fragment>
          ))}
        </TableBody>
      </VariableValuesTable>
    </div>
  </div>
);

export default CellTooltip;
