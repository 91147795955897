export enum BoostUpIcons {
  AccountPivot = 'account-pivot',
  ArrowLeft = 'arrow_left',
  ArrowRight = 'arrow_right',
  Attachment = 'attachment',
  Audio = 'audio',
  BadgeCheckSolid = 'badge_check_solid',
  BadgeClockSolid = 'badge_clock_solid',
  BadgeErrorSolid = 'badge_error_solid',
  BadgeInfoOutline = 'badge_info_outline',
  BadgeInfoSolid = 'badge_info_solid',
  BadgeQuestionOutline = 'badge_question_outline',
  BadgeQuestionSolid = 'badge_question_solid',
  BadgeWarningOutline = 'badge_warning_outline',
  BadgeWarningSolid = 'badge_warning_solid',
  BarGraph = 'bar_graph',
  Bookmark = 'bookmark',
  BoostupLogoPart_1 = 'boostup_logo_part_1',
  BoostupLogoPart_2 = 'boostup_logo_part_2',
  BoostupLogo = 'boostup_logo',
  BoostupPart_1 = 'boostup_part_1',
  BoostupPart_2 = 'boostup_part_2',
  Boostup = 'boostup',
  Calendar = 'calendar',
  Call = 'call',
  ChangeDown = 'change_down',
  ChangeUp = 'change_up',
  ChartLabelDot = 'chart_label_dot',
  ChartLabelLineDash = 'chart_label_line_dash',
  ChartLabelLineDot = 'chart_label_line_dot',
  ChartLabelLineSolid = 'chart_label_line_solid',
  CheckCircle = 'check_circle',
  CheckboxCheckedFull = 'checkbox_checked_full',
  CheckboxChecked = 'checkbox_checked',
  CheckboxEmpty = 'checkbox_empty',
  CheckboxIndeterminateFull = 'checkbox_indeterminate_full',
  CheckboxIndeterminate = 'checkbox_indeterminate',
  Checked = 'checked',
  ChevronDown = 'chevron_down',
  ChevronLeft = 'chevron_left',
  ChevronRight = 'chevron_right',
  ChevronUp = 'chevron_up',
  Clipboard = 'clipboard',
  Clock = 'clock',
  CloseBig = 'close_big',
  ClosePopup = 'close_popup',
  CollapseIcon = 'collapse_icon',
  Collapse = 'collapse',
  ColumnGraphBox = 'column_graph_box',
  ColumnGraph = 'column_graph',
  CombinedGraphs = 'combined_graphs',
  Configuration = 'configuration',
  ContactPivotRound = 'contact-pivot-round',
  ContactPivot = 'contact-pivot',
  ContactsLinked = 'contacts_linked',
  CopyIcon = 'copy_icon',
  Copy = 'copy',
  CrossCircle = 'cross_circle',
  CustomobjectPivotRound = 'customobject-pivot-round',
  CustomobjectPivot = 'customobject-pivot',
  DangerCircle = 'danger_circle',
  Dialogue = 'dialogue',
  Download = 'download',
  Drag = 'drag',
  Error = 'error',
  EventPivotRound = 'event-pivot-round',
  EventPivot = 'event-pivot',
  FileBlank = 'file_blank',
  FileDocument = 'file_document',
  Filter = 'filter',
  FullScreen = 'full_screen',
  GroupedBarGraph = 'grouped_bar_graph',
  GroupedColumnGraph = 'grouped_column_graph',
  HorizontalResize = 'horizontal_resize',
  Image = 'image',
  InteractiveItemResizeHandle = 'interactive_item_resize_handle',
  JsonFile = 'json-file',
  Layers = 'layers',
  LeadPivotRound = 'lead-pivot-round',
  LeadPivot = 'lead-pivot',
  LineGraph = 'line_graph',
  Link = 'link',
  Location = 'location',
  Lock = 'lock',
  LogOut = 'log_out',
  LogoDatadog = 'logo_datadog',
  LogoGoogle = 'logo_google',
  LogoHubspot = 'logo_hubspot',
  LogoLinkedin = 'logo_linkedin',
  LogoOutlook = 'logo_outlook',
  LogoSalesforce = 'logo_salesforce',
  Mail = 'mail',
  MediaFastForward = 'media_fast_forward',
  MediaPause = 'media_pause',
  MediaPlay = 'media_play',
  MediaRewind = 'media_rewind',
  MediaSkipBack = 'media_skip_back',
  MediaSkipForward = 'media_skip_forward',
  MediaStop = 'media_stop',
  MeetingTimelineIcon = 'meeting_timeline_icon',
  MenuDrawer = 'menu_drawer',
  MenuHoriz = 'menu_horiz',
  MenuVert = 'menu_vert',
  Microphone = 'microphone',
  MindtickleCallIcon = 'mindtickle_call_icon',
  MindtickleCallTransparent = 'mindtickle_call_transparent',
  Minus = 'minus',
  Money = 'money',
  MultiCurrency = 'multi_currency',
  NavAccounts = 'nav_accounts',
  NavActivity = 'nav_activity',
  NavAdmin = 'nav_admin',
  NavCalls = 'nav_calls',
  NavDashboard = 'nav_dashboard',
  NavForecast = 'nav_forecast',
  NavMeetings = 'nav_meetings',
  NavMetric = 'nav_metric',
  NavOpportunities = 'nav_opportunities',
  NavPipeline = 'nav_pipeline',
  NavProspect = 'nav_prospect',
  NavSeller = 'nav_seller',
  NavSettings = 'nav_settings',
  NavTargets = 'nav_targets',
  NavTodos = 'nav_todos',
  NoEventIcon = 'No-event-icon',
  Notes = 'notes',
  OppSplitIcon = 'opp_split_icon',
  OpportunityPivotRound = 'opportunity-pivot-round',
  OpportunityPivot = 'opportunity-pivot',
  Pencil = 'pencil',
  PieChart = 'pie_chart',
  Pin = 'pin',
  Play = 'play',
  Plus = 'plus',
  Print = 'print',
  QuickHelp = 'quick_help',
  ReadOnly = 'read_only',
  RefreshIcon = 'refresh_icon',
  Reload = 'reload',
  RiskDot = 'risk_dot',
  RotatedPin = 'rotated_pin',
  RotatedPinned = 'rotated_pinned',
  Search = 'search',
  SentimentFaceNegative = 'sentiment_face_negative',
  SentimentFaceNeutral = 'sentiment_face_neutral',
  SentimentFacePositive = 'sentiment_face_positive',
  Settings = 'settings',
  Share = 'share',
  SortingAscending = 'sorting_ascending',
  SortingDescending = 'sorting_descending',
  Splits = 'splits',
  SquareMinus = 'square_minus',
  SquarePlus = 'square_plus',
  StackedBarGraph = 'stacked_bar_graph',
  StackedColumnGraph = 'stacked_column_graph',
  SubmissionPivotRound = 'submission-pivot-round',
  SubmissionPivot = 'submission-pivot',
  Submit = 'submit',
  Sync = 'sync',
  TableColumnsSelection = 'table_columns_selection',
  Table = 'table',
  TimelineAlertDotBlue = 'timeline_alert_dot_blue',
  TimelineAlertDotRed = 'timeline_alert_dot_red',
  TimelineBuyingTeamEmail = 'timeline_buying_team_email',
  TimelineCrmActivity = 'timeline_crm_activity',
  TimelineMeeting = 'timeline_meeting',
  TimelineNextSteps = 'timeline_next_steps',
  TimelineSellingTeamEmail = 'timeline_selling_team_email',
  ToDo = 'to_do',
  Trash = 'trash',
  TriangleDown = 'triangleDown',
  TriangleLeft = 'triangleLeft',
  TriangleRight = 'triangleRight',
  TriangleUp = 'triangleUp',
  Unlock = 'unlock',
  Upload = 'upload',
  UserGroup = 'user_group',
  UserPivotRound = 'user-pivot-round',
  UserPivot = 'user-pivot',
  User = 'user',
  VerticalResize = 'vertical_resize',
  WorkloadPivotRound = 'workload-pivot-round',
  WorkloadPivot = 'workload-pivot',
}

export const BOOST_UP_ICONS_CODEPOINTS: { [key in BoostUpIcons]: string } = {
  [BoostUpIcons.AccountPivot]: '61697',
  [BoostUpIcons.ArrowLeft]: '61698',
  [BoostUpIcons.ArrowRight]: '61699',
  [BoostUpIcons.Attachment]: '61700',
  [BoostUpIcons.Audio]: '61701',
  [BoostUpIcons.BadgeCheckSolid]: '61702',
  [BoostUpIcons.BadgeClockSolid]: '61703',
  [BoostUpIcons.BadgeErrorSolid]: '61704',
  [BoostUpIcons.BadgeInfoOutline]: '61705',
  [BoostUpIcons.BadgeInfoSolid]: '61706',
  [BoostUpIcons.BadgeQuestionOutline]: '61707',
  [BoostUpIcons.BadgeQuestionSolid]: '61708',
  [BoostUpIcons.BadgeWarningOutline]: '61709',
  [BoostUpIcons.BadgeWarningSolid]: '61710',
  [BoostUpIcons.BarGraph]: '61711',
  [BoostUpIcons.Bookmark]: '61712',
  [BoostUpIcons.BoostupLogoPart_1]: '61713',
  [BoostUpIcons.BoostupLogoPart_2]: '61714',
  [BoostUpIcons.BoostupLogo]: '61715',
  [BoostUpIcons.BoostupPart_1]: '61716',
  [BoostUpIcons.BoostupPart_2]: '61717',
  [BoostUpIcons.Boostup]: '61718',
  [BoostUpIcons.Calendar]: '61719',
  [BoostUpIcons.Call]: '61720',
  [BoostUpIcons.ChangeDown]: '61721',
  [BoostUpIcons.ChangeUp]: '61722',
  [BoostUpIcons.ChartLabelDot]: '61723',
  [BoostUpIcons.ChartLabelLineDash]: '61724',
  [BoostUpIcons.ChartLabelLineDot]: '61725',
  [BoostUpIcons.ChartLabelLineSolid]: '61726',
  [BoostUpIcons.CheckCircle]: '61727',
  [BoostUpIcons.CheckboxCheckedFull]: '61728',
  [BoostUpIcons.CheckboxChecked]: '61729',
  [BoostUpIcons.CheckboxEmpty]: '61730',
  [BoostUpIcons.CheckboxIndeterminateFull]: '61731',
  [BoostUpIcons.CheckboxIndeterminate]: '61732',
  [BoostUpIcons.Checked]: '61733',
  [BoostUpIcons.ChevronDown]: '61734',
  [BoostUpIcons.ChevronLeft]: '61735',
  [BoostUpIcons.ChevronRight]: '61736',
  [BoostUpIcons.ChevronUp]: '61737',
  [BoostUpIcons.Clipboard]: '61738',
  [BoostUpIcons.Clock]: '61739',
  [BoostUpIcons.CloseBig]: '61740',
  [BoostUpIcons.ClosePopup]: '61741',
  [BoostUpIcons.CollapseIcon]: '61742',
  [BoostUpIcons.Collapse]: '61743',
  [BoostUpIcons.ColumnGraphBox]: '61744',
  [BoostUpIcons.ColumnGraph]: '61745',
  [BoostUpIcons.CombinedGraphs]: '61746',
  [BoostUpIcons.Configuration]: '61747',
  [BoostUpIcons.ContactPivotRound]: '61748',
  [BoostUpIcons.ContactPivot]: '61749',
  [BoostUpIcons.ContactsLinked]: '61750',
  [BoostUpIcons.CopyIcon]: '61751',
  [BoostUpIcons.Copy]: '61752',
  [BoostUpIcons.CrossCircle]: '61753',
  [BoostUpIcons.CustomobjectPivotRound]: '61754',
  [BoostUpIcons.CustomobjectPivot]: '61755',
  [BoostUpIcons.DangerCircle]: '61756',
  [BoostUpIcons.Dialogue]: '61757',
  [BoostUpIcons.Download]: '61758',
  [BoostUpIcons.Drag]: '61759',
  [BoostUpIcons.Error]: '61760',
  [BoostUpIcons.EventPivotRound]: '61761',
  [BoostUpIcons.EventPivot]: '61762',
  [BoostUpIcons.FileBlank]: '61763',
  [BoostUpIcons.FileDocument]: '61764',
  [BoostUpIcons.Filter]: '61765',
  [BoostUpIcons.FullScreen]: '61766',
  [BoostUpIcons.GroupedBarGraph]: '61767',
  [BoostUpIcons.GroupedColumnGraph]: '61768',
  [BoostUpIcons.HorizontalResize]: '61769',
  [BoostUpIcons.Image]: '61770',
  [BoostUpIcons.InteractiveItemResizeHandle]: '61771',
  [BoostUpIcons.JsonFile]: '61772',
  [BoostUpIcons.Layers]: '61773',
  [BoostUpIcons.LeadPivotRound]: '61774',
  [BoostUpIcons.LeadPivot]: '61775',
  [BoostUpIcons.LineGraph]: '61776',
  [BoostUpIcons.Link]: '61777',
  [BoostUpIcons.Location]: '61778',
  [BoostUpIcons.Lock]: '61779',
  [BoostUpIcons.LogOut]: '61780',
  [BoostUpIcons.LogoDatadog]: '61781',
  [BoostUpIcons.LogoGoogle]: '61782',
  [BoostUpIcons.LogoHubspot]: '61783',
  [BoostUpIcons.LogoLinkedin]: '61784',
  [BoostUpIcons.LogoOutlook]: '61785',
  [BoostUpIcons.LogoSalesforce]: '61786',
  [BoostUpIcons.Mail]: '61787',
  [BoostUpIcons.MediaFastForward]: '61788',
  [BoostUpIcons.MediaPause]: '61789',
  [BoostUpIcons.MediaPlay]: '61790',
  [BoostUpIcons.MediaRewind]: '61791',
  [BoostUpIcons.MediaSkipBack]: '61792',
  [BoostUpIcons.MediaSkipForward]: '61793',
  [BoostUpIcons.MediaStop]: '61794',
  [BoostUpIcons.MeetingTimelineIcon]: '61795',
  [BoostUpIcons.MenuDrawer]: '61796',
  [BoostUpIcons.MenuHoriz]: '61797',
  [BoostUpIcons.MenuVert]: '61798',
  [BoostUpIcons.Microphone]: '61799',
  [BoostUpIcons.MindtickleCallIcon]: '61800',
  [BoostUpIcons.MindtickleCallTransparent]: '61801',
  [BoostUpIcons.Minus]: '61802',
  [BoostUpIcons.Money]: '61803',
  [BoostUpIcons.MultiCurrency]: '61804',
  [BoostUpIcons.NavAccounts]: '61805',
  [BoostUpIcons.NavActivity]: '61806',
  [BoostUpIcons.NavAdmin]: '61807',
  [BoostUpIcons.NavCalls]: '61808',
  [BoostUpIcons.NavDashboard]: '61809',
  [BoostUpIcons.NavForecast]: '61810',
  [BoostUpIcons.NavMeetings]: '61811',
  [BoostUpIcons.NavMetric]: '61812',
  [BoostUpIcons.NavOpportunities]: '61813',
  [BoostUpIcons.NavPipeline]: '61814',
  [BoostUpIcons.NavProspect]: '61815',
  [BoostUpIcons.NavSeller]: '61816',
  [BoostUpIcons.NavSettings]: '61817',
  [BoostUpIcons.NavTargets]: '61818',
  [BoostUpIcons.NavTodos]: '61819',
  [BoostUpIcons.NoEventIcon]: '61820',
  [BoostUpIcons.Notes]: '61821',
  [BoostUpIcons.OppSplitIcon]: '61822',
  [BoostUpIcons.OpportunityPivotRound]: '61823',
  [BoostUpIcons.OpportunityPivot]: '61824',
  [BoostUpIcons.Pencil]: '61825',
  [BoostUpIcons.PieChart]: '61826',
  [BoostUpIcons.Pin]: '61827',
  [BoostUpIcons.Play]: '61828',
  [BoostUpIcons.Plus]: '61829',
  [BoostUpIcons.Print]: '61830',
  [BoostUpIcons.QuickHelp]: '61831',
  [BoostUpIcons.ReadOnly]: '61832',
  [BoostUpIcons.RefreshIcon]: '61833',
  [BoostUpIcons.Reload]: '61834',
  [BoostUpIcons.RiskDot]: '61835',
  [BoostUpIcons.RotatedPin]: '61836',
  [BoostUpIcons.RotatedPinned]: '61837',
  [BoostUpIcons.Search]: '61838',
  [BoostUpIcons.SentimentFaceNegative]: '61839',
  [BoostUpIcons.SentimentFaceNeutral]: '61840',
  [BoostUpIcons.SentimentFacePositive]: '61841',
  [BoostUpIcons.Settings]: '61842',
  [BoostUpIcons.Share]: '61843',
  [BoostUpIcons.SortingAscending]: '61844',
  [BoostUpIcons.SortingDescending]: '61845',
  [BoostUpIcons.Splits]: '61846',
  [BoostUpIcons.SquareMinus]: '61847',
  [BoostUpIcons.SquarePlus]: '61848',
  [BoostUpIcons.StackedBarGraph]: '61849',
  [BoostUpIcons.StackedColumnGraph]: '61850',
  [BoostUpIcons.SubmissionPivotRound]: '61851',
  [BoostUpIcons.SubmissionPivot]: '61852',
  [BoostUpIcons.Submit]: '61853',
  [BoostUpIcons.Sync]: '61854',
  [BoostUpIcons.TableColumnsSelection]: '61855',
  [BoostUpIcons.Table]: '61856',
  [BoostUpIcons.TimelineAlertDotBlue]: '61857',
  [BoostUpIcons.TimelineAlertDotRed]: '61858',
  [BoostUpIcons.TimelineBuyingTeamEmail]: '61859',
  [BoostUpIcons.TimelineCrmActivity]: '61860',
  [BoostUpIcons.TimelineMeeting]: '61861',
  [BoostUpIcons.TimelineNextSteps]: '61862',
  [BoostUpIcons.TimelineSellingTeamEmail]: '61863',
  [BoostUpIcons.ToDo]: '61864',
  [BoostUpIcons.Trash]: '61865',
  [BoostUpIcons.TriangleDown]: '61866',
  [BoostUpIcons.TriangleLeft]: '61867',
  [BoostUpIcons.TriangleRight]: '61868',
  [BoostUpIcons.TriangleUp]: '61869',
  [BoostUpIcons.Unlock]: '61870',
  [BoostUpIcons.Upload]: '61871',
  [BoostUpIcons.UserGroup]: '61872',
  [BoostUpIcons.UserPivotRound]: '61873',
  [BoostUpIcons.UserPivot]: '61874',
  [BoostUpIcons.User]: '61875',
  [BoostUpIcons.VerticalResize]: '61876',
  [BoostUpIcons.WorkloadPivotRound]: '61877',
  [BoostUpIcons.WorkloadPivot]: '61878',
};
