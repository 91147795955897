import { toast } from 'react-toastify';
import { combineReducers } from 'redux';

import * as t from 'actions/actionTypes';

const totals = (state = {}, action) => {
  switch (action.type) {
    case t.FORECAST + t.GET + t.SUCCESS:
      return {
        ...state,
        metricsData: action.payload,
      };
    case t.FORECAST + t.SET + t.ACTIVE:
      return {
        ...state,
        selectedItem: action.itemName,
      };
    default:
      return state;
  }
};

const overideInitialState = {
  forecastManager: 0,
  forecastSeller: 0,
  forecastOverrideManager: 0,
  forecastOverrideSeller: 0,
};

const overide = (state = overideInitialState, action) => {
  switch (action.type) {
    case t.FORECAST + t.OVERIDE + t.SUCCESS:
      return {
        ...state,
        [action.field]: action.value,
      };
    default:
      return state;
  }
};

const isLoading = (state = true, action) => {
  switch (action.type) {
    case t.FORECAST + t.GET + t.LOADING:
      return true;
    case t.FORECAST + t.GET + t.SUCCESS:
    case t.FORECAST + t.GET + t.ERROR:
      return false;
    default:
      return state;
  }
};

const defaultToasts = {
  message: '',
  status: '',
};

const setForecastOverride = (state = defaultToasts, action) => {
  switch (action.type) {
    case t.FORECAST + t.SET + t.OVERIDE + t.REQUEST:
      return toast.warn('Submitting forecast override', {
        position: 'bottom-left',
      });
    case t.FORECAST + t.SET + t.OVERIDE + t.SUCCESS:
      return toast.success('Forecast override submitted successfully', {
        position: 'bottom-left',
      });
    case t.FORECAST + t.SET + t.OVERIDE + t.ERROR:
      return toast.error(action.message, {
        position: 'bottom-left',
      });
    default:
      return state;
  }
};

const customFieldsTotals = (state = {}, action) => {
  switch (action.type) {
    case t.FORECAST + t.SET + t.CUSTOM_FIELD + t.TOTALS:
      return {
        ...state,
        ...action.totalValues,
      };
    default:
      return state;
  }
};

const setForecastCustomField = (state = defaultToasts, action) => {
  switch (action.type) {
    case t.FORECAST + t.SET + t.CUSTOM_FIELD + t.REQUEST:
      return toast.warn(`Submitting ${action.customFieldLabel}`, {
        position: 'bottom-left',
      });
    case t.FORECAST + t.SET + t.CUSTOM_FIELD + t.SUCCESS:
      return toast.success(
        `${action.customFieldLabel} submitted successfully`,
        {
          position: 'bottom-left',
        }
      );
    case t.FORECAST + t.SET + t.CUSTOM_FIELD + t.ERROR:
      return toast.error(
        `${action.customFieldLabel} failed:\n${action.message}`,
        {
          position: 'bottom-left',
        }
      );
    default:
      return state;
  }
};

const isTotalsUpToDate = (state = false, action) => {
  switch (action.type) {
    case t.FORECAST + t.REFRESH + t.START:
      return false;
    case t.FORECAST + t.REFRESH + t.SUCCESS:
      return true;
    default:
      return state;
  }
};

const progressionInitialState = {
  frequency: 'week',
  paceInterval: 'TQU',
  checkedColumns: [
    'overall_target',
    'booked',
    'pace',
    'total_pipe',
    'pipeline_gap',
  ],
};

const progressionFilters = (state = progressionInitialState, action) => {
  switch (action.type) {
    case t.FORECAST + t.PROGRESSION + t.FILTER + t.CHANGE:
      return {
        ...state,
        [action.field]: action.value,
      };
    default:
      return state;
  }
};

const dashboardInitialState = {
  closeDateInterval: 'TQU',
  paceInterval: 'TQU',
  frequency: 'week',
};

const dashboardFilters = (state = dashboardInitialState, action) => {
  switch (action.type) {
    case t.FORECAST + t.DASHBOARD + t.FILTER + t.CHANGE:
      return {
        ...state,
        [action.field]: action.value,
      };
    default:
      return state;
  }
};

const oppSplitView = (state = false, action) => {
  switch (action.type) {
    case t.FORECAST + t.TOGGLE + t.OPP_SPLIT_VIEW:
      return !state;
    case t.FORECAST + t.SET + t.OPP_SPLIT_VIEW:
      return Boolean(action.value);
    default:
      return state;
  }
};

export default combineReducers({
  setForecastOverride,
  setForecastCustomField,
  customFieldsTotals,
  isTotalsUpToDate,
  totals,
  isLoading,
  overide,
  progressionFilters,
  dashboardFilters,
  oppSplitView,
});
