import classNames from 'classnames';
import { css } from 'emotion';
import React from 'react';
import { Popup } from 'semantic-ui-react';

import failureIcon from 'assets/images/failure.svg';
import failureGrayIcon from 'assets/images/failure_gray.svg';
import successIcon from 'assets/images/success.svg';
import successGrayIcon from 'assets/images/success_gray.svg';
import { IncludedSummaryForDeal } from 'common/types/forecastSubmission';
import { IDataCellProps } from 'components/UI/common/TypedTable/TypedTable';
import { getFieldValue } from 'components/UI/common/TypedTable/renderers/custom/common';

const header = css`
  padding: 10px 20px;
  flex: 1 100%;
  border-bottom: 1px solid #cccc;
  color: #8c8b8b;
`;

const aside1 = css`
  flex: 0.5 0 0;
  border-right: 1px solid #dddddd;
`;

const aside2 = css`
  flex: 4 0 0;
  padding: 10px;
  margin-left: -19px;
`;

const container = css`
  display: flex;
  justify-content: space-between;
  width: 75px;
  color: #777777;
  cursor: pointer;
  margin: 0 auto;
`;

const popUpWrapper = css`
  display: flex;
  flex-flow: row wrap;
`;

const popupContainer = css`
  padding: 0 !important;
`;

const tripActionsSuccessIconsPopUp = css`
  margin: 50% 0px;
  background: #fff;
  width: 18px;
  height: 18px;
`;

const tripActionsFailureIconsPopUp = css`
  margin: 50% 0px;
  background: #fff;
  width: 21px;
  height: 18px;
  margin-left: -2px;
`;

const tripActionsFailureIcon = css`
  width: 19px;
  height: 17px;
  margin-top: -3px;
`;

const tripActionsSuccessIcon = css`
  width: 17px;
  height: 17px;
  margin-top: -3px;
`;

const divider = css`
  border: 1px solid #dddddd;
  height: 16px;
`;

const rolSection = css`
  color: #cbcbcb;
  font-size: 12px;
`;

const asideContainer = css`
  display: flex;
  margin-bottom: 10px;
`;

const marginLeft = (marginSize: number) => css`
  margin-left: ${marginSize}px;
`;

const SubmissionSummaryCell: React.FC<IDataCellProps> = ({ row }) => {
  const includedSummaryForDeal =
    getFieldValue<IncludedSummaryForDeal[] | undefined>(
      'includedSummaryForDeal',
      row
    ) || [];

  const usersThatIncludedDeal = includedSummaryForDeal.filter(
    (summary) => summary.wasDealIncluded
  );

  const numberOfUsersThatIncludedDeal = usersThatIncludedDeal.length;
  const numberOfUsersThatExcludedDeal =
    includedSummaryForDeal.length - usersThatIncludedDeal.length;

  const trigger = () => {
    const sucIcon =
      numberOfUsersThatIncludedDeal > 0 ? successIcon : successGrayIcon;
    const failIcon =
      numberOfUsersThatExcludedDeal > 0 ? failureIcon : failureGrayIcon;

    return (
      <div className={container}>
        <div>
          <img alt="success" className={tripActionsSuccessIcon} src={sucIcon} />
          <span className={marginLeft(5)}>{numberOfUsersThatIncludedDeal}</span>
        </div>
        <div className={divider} />
        <div>
          <img
            alt="failure"
            className={tripActionsFailureIcon}
            src={failIcon}
          />
          <span className={marginLeft(5)}>{numberOfUsersThatExcludedDeal}</span>
        </div>
      </div>
    );
  };

  const thereIsIncludedInformation =
    includedSummaryForDeal && includedSummaryForDeal.length > 0;

  return (
    <Popup
      basic
      hoverable
      className={popupContainer}
      position="bottom center"
      trigger={trigger()}
    >
      <div className={popUpWrapper}>
        <header className={header}>Summary</header>
        <aside className={aside1} />
        <aside className={aside2}>
          {thereIsIncludedInformation ? (
            includedSummaryForDeal.map((item) => (
              <div key={item.userId} className={asideContainer}>
                <div>
                  <img
                    alt={item.wasDealIncluded ? 'success' : 'failure'}
                    className={classNames({
                      [tripActionsSuccessIconsPopUp]: item.wasDealIncluded,
                      [tripActionsFailureIconsPopUp]: !item.wasDealIncluded,
                    })}
                    src={item.wasDealIncluded ? successIcon : failureIcon}
                  />
                </div>
                <div className={marginLeft(10)}>
                  <div>{item.userName}</div>
                  <div className={rolSection}>{item.userRole}</div>
                </div>
              </div>
            ))
          ) : (
            <div className={marginLeft(20)}>There is nothing to display</div>
          )}
        </aside>
      </div>
    </Popup>
  );
};

export default SubmissionSummaryCell;
