import { css } from 'emotion';

import { fontDefault, fontHeading, fontSubtitle } from 'assets/css/common';
import { fontSubheading2 } from 'assets/css/fontStyles';

export const header = css`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 48px;
  align-items: center;
  ${fontHeading}
  padding-left: 24px;
  border-bottom: 1px solid var(--bu-gray-300);
`;

export const addThirdPartyContainer = css`
  height: calc(100% - 50px);
`;

export const mainContainer = css`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

export const stageContainer = css`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  height: 100%;
  background-color: var(bu-primary-200);
`;

export const pageContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
  padding: 30px 30px 0 40px;
`;

export const checkModalIcon = css`
  &.icon.green {
    font-size: 40px;
    line-height: 40px;
    color: var(--bu-green-500) !important;
  }
`;

export const stagesLine = css`
  width: 1px;
  margin-left: 24px;
  position: absolute;
  border-left: 1px dashed var(--bu-gray-400);
  height: calc(100% - 100px);
`;

export const stageMark = css`
  display: flex;
  min-width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--bu-gray-600);
  margin-right: 10px;
  z-index: 2;
  &.selected {
    background-color: var(bu-primary-500);
  }
  &.completed {
    background-color: var(--bu-green-500);
  }
  &.exclamation {
    background-color: var(--bu-orange-500);
  }
`;

export const exclamation = css`
  font-size: 10px !important;
  width: unset !important;
  color: var(--bu-white) !important;
  margin-left: 6px !important;
  margin-bottom: 8px !important;
`;

export const stage = css`
  height: 40px;
  display: flex;
  flex-direction: row;
  padding-left: 16px;
  margin-top: 17px;
  align-items: center;
  &.selected {
    background-color: var(bu-primary-200);
    color: var(--bu-black);
  }
  ${fontSubtitle}
`;

export const dropdownSelector = css`
  margin: 10px 0;
`;

export const label = css`
  width: 100%;
  ${fontDefault}
`;

export const databaseIdInput = css`
  width: 80%;
`;

export const databaseIdLabel = css`
  ${fontDefault}
  margin-top: 30px;
`;

export const databaseInputContainer = css`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const addDatabasesContainer = css`
  display: flex;
  flex-direction: column;
  width: calc(100% - 200px);
`;

export const footerContainer = css`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  margin-top: auto;
  align-items: center;
  background: var(--bu-white);
  width: 100%;
  min-height: 52px;
  border-top: 1px solid var(--bu-gray-400);
  box-shadow: 0 0 2px 3px rgb(0 0 0 / 8%), 0 0 2px 1px rgb(0 0 0 / 15%);
  padding-right: 30px;
`;

export const nextButton = css`
  width: 90px;
`;

export const controlsContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 40px;
  span {
    ${fontDefault}
    color: var(--bu-gray-600);
  }
`;

export const tableRow = css`
  ${fontDefault}
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid var(--bu-gray-200);
  min-height: 40px;
`;

export const tableReviewContainer = css`
  display: flex;
  flex-direction: column;
  height: 160px;
  max-height: 160px;
  overflow-y: auto;
`;

export const existingDatabaseContainer = css`
  margin-top: 30px;
`;
