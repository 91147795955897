import { css } from 'emotion';

export const search_icon = css`
  height: 18px;
  width: 18px;
  position: absolute;
  left: 18px;
  top: 50%;
  transform: translateY(-50%);
`;

export const clear_icon = css`
  height: 8px;
  width: 8px;
  margin: 9px 5px;
  /* position: absolute; */
  /* right: 18px; */
  /* top: 50%; */
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 100;
`;

export const searchContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const clear_icon__container = css`
  height: 18px;
  width: 18px;
  background-color: #d8d8d8;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  right: 18px;
  top: 30%;
`;
