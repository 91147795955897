import { css } from 'emotion';

export const container = css`
  position: relative;
  height: 24px;
  width: 250px;
  min-width: 200px;
  margin-right: 10px;
`;

export const icon_search = css`
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0;
  top: calc(50% - 2px);
  transform: translateY(-50%);
`;

export const input_search = css`
  height: 100%;
  width: 100%;
  padding-left: 20px;
  padding-right: 30px;
  padding-bottom: 4px;
  border: none;
  border-bottom: 1px solid var(--bu-gray-300);
  font-family: var(--bu-font-regular);
  font-size: 14px;
  background: #fff;
  color: #4a4a4a;
  transition: 0.4s;

  &::placeholder {
    color: #9b9b9b;
  }

  &:focus {
    border-bottom: 1px solid #0762ec;
    background: #fff;
  }
`;

export const reset_btn = css`
  width: 14px;
  height: 14px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  box-sizing: border-box;
  border: none;
  cursor: pointer;

  &:after {
    content: '';
    display: block;
    width: 1px;
    height: 16px;
    transform: rotate(135deg);
    background: #000;
    position: absolute;
    left: 7px;
    top: -1px;
  }

  &:before {
    content: '';
    display: block;
    width: 1px;
    height: 16px;
    transform: rotate(45deg);
    background: #000;
    position: absolute;
    left: 7px;
    top: -1px;
  }

  &:focus {
    outline: none;
  }
`;
