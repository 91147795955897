import { TableConfigurationColumn } from 'components/UI/TableConfig/types';
import { IRow } from 'components/UI/common/TypedTable/TypedTable';

export const calculatePipelinesTree = (
  tree: IRow[],
  columnsWithPipelineGap: TableConfigurationColumn[],
  columnsWithPipelineCoverage: TableConfigurationColumn[],
  notAvailableValue: string = '-'
) =>
  tree.map((item) => {
    if (item.isEmpty) {
      return item;
    }

    if (Array.isArray(item.children)) {
      item.children = calculatePipelinesTree(
        item.children,
        columnsWithPipelineGap,
        columnsWithPipelineCoverage
      );
    }

    columnsWithPipelineGap.forEach((column) => {
      const config = column.meta.pipeline_gap || {},
        target = item[config.target] as number,
        booked = item[config.booked] as number,
        multiplier = item[config.multiplier] as number,
        totalPipe = item[config.total_pipe] as number,
        remainingTarget = Math.max(target - booked, 0),
        idealPipeline = remainingTarget * multiplier;

      item[column.field_name] =
        target && idealPipeline
          ? Math.round(
              (Math.floor(totalPipe) / Math.floor(idealPipeline)) * 100
            )
          : notAvailableValue;
    });

    columnsWithPipelineCoverage.forEach((column) => {
      const config = column.meta.pipeline_coverage || {},
        target = item[config.target] as number,
        booked = item[config.booked] as number,
        totalPipe = item[config.total_pipe] as number,
        remainingTarget = Math.max(target - booked, 0),
        oneDecimal = 10;

      item[column.field_name] =
        target && remainingTarget
          ? (
              Math.round(
                (Math.floor(totalPipe) / Math.floor(remainingTarget)) *
                  oneDecimal
              ) / oneDecimal
            ).toFixed(1)
          : notAvailableValue;
    });

    return item;
  });
