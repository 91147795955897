import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Features, partitions } from 'navigation/tabs';
import { Scheme } from 'navigation/types';
import {
  getCompanySettings,
  getUserRole,
  isAdminEnabled,
  isPagesAvailable,
} from 'selectors';

interface tabScheme {
  scheme: Scheme;
  title: string;
  isVisible: (userRole: string, features: Features) => boolean | boolean;
}

export const useEnabledDashboard = () => {
  const { userRole, features } = useSelector((state) => {
    const companySettings = getCompanySettings(state);

    return {
      userRole: getUserRole(state) as string,
      features: {
        isAdminEnabled: isAdminEnabled(state),
        isForecastDashboardEnabled: companySettings?.forecast_dashboard_enabled,
        isRepPaceEnabled: companySettings?.rep_pace_table_enabled,
        isScorecardEnabled: companySettings?.scorecard_enabled,
        isMeetingsDashboardEnabled: companySettings?.meetings_dashboard_enabled,
        isSankeyChartEnabled: companySettings?.sankey_chart_enabled,
      },
    };
  });

  const tabs: tabScheme[] = useSelector((state) =>
    isPagesAvailable(state, partitions.dashboards)
  );

  return tabs.filter((tab) => {
    if (
      (typeof tab.isVisible === 'function' &&
        tab.isVisible(userRole, features)) ||
      (typeof tab.isVisible === 'boolean' && tab.isVisible !== false) ||
      typeof tab.isVisible === 'undefined'
    ) {
      return true;
    }
    return false;
  });
};

export const DashboardPage: React.FC = () => {
  // this component just redirect to the first enabled dashboard
  // base on user profile navigation setting
  const history = useHistory();

  const enabledTab = useEnabledDashboard();

  if (enabledTab.length > 0) {
    history.push(enabledTab[0].scheme);
  }

  return null;
};
