import { customReportActionType as t } from '../actions/customReportActions';
import produce from 'immer';
import moment from 'moment';

export const customReportsReducer = (state = getInitialState(), action) => {
  const { key, value, type } = action;
  const reducer = {
    [t.UPDATE_STATE]: (draft) => {
      if (typeof key === 'string') {
        draft[key] = value;
      } else {
        for (const i in key) {
          draft[i] = key[i];
        }
      }
    },
    [t.RESET_STATE]: (draft) => {
      draft.report_spec = {};
      draft.report_specs = [];
      draft.apiError = null;
      draft.apiSuccess = null;
      draft.redirectToReports = false;
    },
    [t.REMOVE_CUSTOM_REPORT]: (draft) => {
      draft['report_specs'] = state.report_specs.filter(
        (r) => r.id !== action.id
      );
    },
    [t.ADD_CUSTOM_REPORT]: (draft) => {
      draft['report_specs'] = state.report_specs
        .filter((r) => r.id !== action.report.id)
        .concat(action.report)
        .sort((a, b) =>
          moment(a.last_modified).format('X') >
          moment(b.last_modified).format('X')
            ? -1
            : 1
        );
      draft['redirectToReports'] = true;
    },
    [t.CANCEL_EDIT_CUSTOM_REPORT]: (draft) => {
      draft['report_spec'] = {};
      draft['redirectToReports'] = true;
    },
  };

  return reducer.hasOwnProperty(type) ? produce(state, reducer[type]) : state;
};

function getInitialState() {
  return {
    report_spec: {},
    report_specs: [],
    apiError: null,
    apiSuccess: null,
    redirectToReports: false,
  };
}
