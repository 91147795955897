import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import Panel from 'components/UI/FilterPanels/Panel';
import PanelColumnList from 'components/UI/FilterPanels/PanelColumnList';
import PanelStages from 'components/UI/FilterPanels/PanelStages';
import PanelUserRole from 'components/UI/FilterPanels/PanelUserRole';
import PanelUsers from 'components/UI/FilterPanels/PanelUsers';
import { IPropsNew } from 'components/UI/FilterPanels/types';
import setElementInViewport from 'components/UI/OpenFiltersPanel/Dropdowns/setElementInViewport';
import * as s from 'components/UI/OpenFiltersPanel/styles';
import { Props } from 'components/UI/OpenFiltersPanel/types';
import { useClickOutside } from 'components/hooks/useClickOutside';

const filterNameToPanel: {
  [key: string]: React.FC<IPropsNew>;
} = {
  opportunity_stages: PanelStages,
  user_role: PanelUserRole,
  users: PanelUsers,
  sales_managers: PanelColumnList,
} as const;

export const DropdownNormal: React.FC<Props> = ({
  config,
  isProfileSettings,
  name,
  onChange,
  onClose,
  setNormalPanel,
}) => {
  const { isOpen, setIsOpen, refElement } = useClickOutside();
  const panelRef = useRef<HTMLDivElement>(null);

  const isSalesManagerFilter = 'sales_managers' === name;

  const PanelProvider = setNormalPanel
    ? Panel
    : filterNameToPanel[name] || Panel;
  const { checkedAll, isChanged, isLocked, title, values } = config;
  const checkedItems = values.filter((item) => item.checked);
  const checkedTotal = checkedItems.length;

  useEffect(() => {
    if (!isProfileSettings) {
      setElementInViewport(panelRef);
    }

    if (!isOpen && onClose) {
      onClose();
    }
  }, [isOpen]);

  const currentValueText = checkedAll
    ? 'All'
    : `${checkedItems[0]?.label || ''} ${
        checkedTotal > 1 ? `+ ${checkedTotal - 1}` : ''
      }`;

  return (
    <div id={name} ref={refElement} className={s.panel_wrapper}>
      <div
        className={classNames(s.panel_dropdown_button, {
          isChanged,
          isLocked: !isProfileSettings && isLocked,
          isOpen,
        })}
        onClick={() => !isLocked && setIsOpen(!isOpen)}
      >
        <span className={s.panel_dropdown_name}>{title}:</span>
        <span className={s.panel_span}>{currentValueText}</span>

        <BuIcon
          name={isOpen ? BoostUpIcons.TriangleUp : BoostUpIcons.TriangleDown}
          color="var(--bu-gray-900)"
          className={s.icon}
        />
      </div>

      {isOpen && (
        <div
          className={classNames({
            [s.panel_dropdown]: !isSalesManagerFilter,
            [s.tree_column]: isSalesManagerFilter,
          })}
          ref={panelRef}
        >
          <PanelProvider
            config={config}
            onChange={onChange}
            onClose={setIsOpen}
          />
        </div>
      )}
    </div>
  );
};
