// import * as api from 'src/actions/api'; // TODO
import { Effect } from '@redux-saga/types';
import { Action } from 'redux';

import * as ui from 'actions/ui';

export interface SagaAction<
  SuccessPayload,
  ErrorPayload,
  ResponseAction,
  Data = never
> extends Action<string> {
  url: string;
  data?: Data;
  saga(
    action: SagaAction<SuccessPayload, ErrorPayload, ResponseAction, Data>
  ): Generator<Effect, void, never>;
  loading(): ResponseAction;
  success(payload: SuccessPayload): ResponseAction;
  error(e: ErrorPayload): ResponseAction;
}

export type SagaActionFunction<
  SuccessPayload,
  ErrorPayload,
  ResponseAction,
  Data = never
> = (
  ...args: Data[]
) => SagaAction<SuccessPayload, ErrorPayload, ResponseAction, Data>;

export const actions = {
  // api,
  ui,
};
