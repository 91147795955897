import {
  EMPTY_VIEW_SECTIONS,
  groupViewsInSections,
} from './CustomViewsConfigurator.helper';
import {
  CustomViewResponse,
  CustomViewUpdateBody,
  ExpandedAccordionConfig,
  ViewSection,
} from './ProfileCustomViewsConfigurator.types';
import SubSectionViewAccordion from './SectionViewAccordion';
import classNames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Accordion } from 'semantic-ui-react';

import BuSkeleton from 'components/UI/BuSkeleton';
import * as sharedStyles from 'components/settings/FieldConfiguration/styles';
import * as s from 'components/settings/UserProfiles/Views/CustomViewsConfigurator.styles';
import { QueryStatus, fetchApiWithoutCb } from 'utils/network';

interface Props {
  profileId: string;
}

const MyComponent: React.FC<Props> = ({ profileId }) => {
  const [expandedAccordion, setExpandedAccordion] =
    React.useState<ExpandedAccordionConfig | undefined>();

  const [viewSections, setViewSections] =
    useState<ViewSection[]>(EMPTY_VIEW_SECTIONS);

  const [queryStatus, setQueryStatus] = useState<QueryStatus>('notAsked');

  useEffect(() => {
    const abortController = new AbortController();
    const fetchViews = async () => {
      setQueryStatus('loading');
      const res = await fetchApiWithoutCb<{}, CustomViewResponse>({
        queryMethod: 'get',
        url: `/api/profile/profile/${profileId}/custom_views`,
        signal: abortController.signal,
      });

      if (res.result?.saved_views) {
        setQueryStatus('success');
        const groupedViews = groupViewsInSections(res.result.saved_views);
        setViewSections(groupedViews);
      } else if (res.error) {
        toast.error('Error Fetching Views');
      }
    };

    fetchViews();

    return () => {
      abortController.abort();
    };
  }, [profileId]);

  const updateViewEnabled = async (id: string, newValue: boolean) => {
    const prevValue = !newValue;
    setViewSections((prev) =>
      prev.map((section) => ({
        ...section,
        views: section.views.map((subSection) => ({
          ...subSection,
          sharedViews: subSection.sharedViews.map((view) =>
            view.id === id ? { ...view, enabled: newValue } : view
          ),
        })),
      }))
    );

    toast.warn('Updating View');
    const res = await fetchApiWithoutCb<CustomViewUpdateBody, {}>({
      url: `/api/profile/profile/${profileId}/custom_views`,
      queryMethod: 'put',
      queryParams: {
        id: id,
        enabled: newValue,
      },
    });

    if (res.error) {
      toast.error('Error Updating View');
      setViewSections((prev) =>
        prev.map((section) => ({
          ...section,
          views: section.views.map((subSection) => ({
            ...subSection,
            sharedViews: subSection.sharedViews.map((view) =>
              view.id === id ? { ...view, enabled: prevValue } : view
            ),
          })),
        }))
      );
    } else {
      toast.success('View Updated');
    }
  };

  const closeExpandedAccordion = () => setExpandedAccordion(undefined);

  const viewsLoading = queryStatus === 'loading' || queryStatus === 'notAsked';

  return (
    <div className={s.viewSection}>
      {viewSections.map(({ name: sectionName, views: sectionViews }) => (
        <Fragment key={sectionName}>
          <h2 className={classNames('bu-font-sub-heading')}>{sectionName}</h2>
          <Accordion
            exclusive
            fluid
            styled
            className={classNames(
              sharedStyles.backgroundOnHover,
              sharedStyles.contentWithoutPadding,
              sharedStyles.fixHeadersInAccordion
            )}
          >
            {sectionViews.map((subSection, viewIndex) => {
              const isAccordionExpanded =
                expandedAccordion?.sectionName === sectionName &&
                expandedAccordion?.viewIndex === viewIndex;

              return !viewsLoading ? (
                <SubSectionViewAccordion
                  key={subSection.value}
                  viewSubSection={subSection}
                  isAccordionExpanded={isAccordionExpanded}
                  onTitleClick={() =>
                    isAccordionExpanded
                      ? closeExpandedAccordion()
                      : setExpandedAccordion({ sectionName, viewIndex })
                  }
                  updateViewEnabled={updateViewEnabled}
                />
              ) : (
                <BuSkeleton
                  key={subSection.value}
                  width="99%"
                  height={25}
                  className={s.subSectionSkeleton}
                />
              );
            })}
          </Accordion>
        </Fragment>
      ))}
    </div>
  );
};

export default MyComponent;
