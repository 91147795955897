import styled from '@emotion/styled';
import { css } from 'emotion';

export const chartContainer = css`
  & .highcharts-container {
    position: inherit !important;
  }
`;

export const ChartTooltip = {
  Header: styled.div`
    color: var(--bu-primary-500);
  `,
  Container: styled.div``,
  Series: styled.div`
    align-items: center;
    display: flex;
    margin-top: 8px;
  `,
};
ChartTooltip.Header.defaultProps = {
  className: 'bu-font-heading',
};

export const chartTooltip = css`
  &.highcharts-tooltip {
    z-index: 9998;
  }
  &.highcharts-tooltip > span {
    padding: 15px !important;
    left: 1px !important;
    top: 1px !important;
    max-height: 300px !important;
    overflow-y: scroll !important;
    background-color: #fff !important;
    pointer-events: auto !important;
    z-index: 9999 !important;
    border: 1px solid var(--bu-gray-500) !important;
  }
`;

export const totalValue = css`
  & > span {
    color: #fff;
    background-color: var(--bu-color-constant-pipeline-coverage);
    border-radius: 1em;
    border-width: 0;
    padding: 0 10px;
  }
`;

export const Dot = styled.div`
  background-color: ${({ color }: { color: string }) => color};
  border-radius: 50%;
  height: 8px;
  margin-right: 7px;
  width: 8px;
`;

export const TooltipWrapper = styled.div`
  padding: 5px;
`;

export const TooltipDefaultText = styled.span`
  color: var(--bu-gray-900);
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
`;

export const TooltipAccentText = styled(TooltipDefaultText)`
  color: var(--bu-gray-700);
  font-weight: normal;
  font-family: var(--bu-font-regular);
`;

export const chartLinesStyles = css`
  & {
    .highcharts-plot-line,
    .highcharts-line-series > .highcharts-graph {
      stroke-linecap: round;

      &[stroke-dasharray='3,9'] {
        // Dot lineWidth=3
        stroke-dasharray: 1, 7;
      }

      &[stroke-dasharray='12,9'] {
        // Dash lineWidth=3
        stroke-dasharray: 5, 8;
      }
    }
  }
`;

export const chartTargetLabelPill = css`
  & .highcharts-plot-line-label {
    padding: 2px 9px;
    border-radius: 1em;
    background-color: var(--bu-color-constant-forecast-target);
    color: #fff !important;
  }
`;
