import { css } from 'emotion';

export const core_data = css`
  margin-bottom: 20px !important;
`;

export const evaluation = css`
  padding-top: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const main_assessment = css`
  font-family: var(--bu-font-semi-bold);
  font-size: 18px;
  color: #4a4a4a;
`;

export const assessment_up = css`
  font-size: 10px;
  font-family: var(--bu-font-regular);
  color: #0aa5a2;
`;

export const assessment_down = css`
  font-size: 13px;
  color: #f74544;
`;

export const icon_sort = css`
  padding: 0 5px;
`;

export const indicator_label = css`
  font-size: 14px;
  font-family: var(--bu-font-regular);
  color: #4a4a4a;
`;

export const information_span = css`
  font-size: 10px;
  font-family: var(--bu-font-regular);
  color: #9b9b9b;

  display: flex;
  align-items: center;

  img {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
`;

export const information_block = css`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;
