import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import { css } from 'emotion';
import * as R from 'ramda';
import { Link } from 'react-router-dom';

import {
  IDataCellProps,
  TypedTableCellConfig,
} from 'components/UI/common/TypedTable/TypedTable';

export interface LinkCellConfig extends TypedTableCellConfig {
  link_pattern?: string;
  isNewWindow?: boolean;
}

const container = css`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const linkColor = css`
  width: 100%;

  color: var(--bu-clickable-cell);

  tr:hover & {
    color: var(--bu-clickable-cell__hover);
  }
`;

const LinkCell = ({ column, row }: IDataCellProps) => {
  const config = (column.config as LinkCellConfig) || {};
  const value = R.path(column.field.split('.'), row) as string;
  const link = Function.constructor(
    `return (row) => \`${config.link_pattern || ''}\``
  )()(row);

  const showTooltip = column.showTooltip;

  if (!link) {
    return <div className={container}>{value}</div>;
  }

  return (
    <TooltipWrapper
      tooltip={value}
      disable={!showTooltip}
      position="bottom center"
    >
      <div className={container}>
        {config.isNewWindow ? (
          <a className={linkColor} href={link} target="_blank">
            {value}
          </a>
        ) : (
          <Link to={link} className={linkColor}>
            {value}
          </Link>
        )}
      </div>
    </TooltipWrapper>
  );
};

export default LinkCell;
