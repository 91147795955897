import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { setForecastProgressionFilter } from 'actions/forecastActions';
import Progression from 'components/dashboard/Progression/Progression';
import { StateProps } from 'components/dashboard/Progression/types';
import { Partition } from 'navigation/tabs';
import { IReduxState } from 'reducers/types';
import {
  getBusinessTypes,
  getUserLocalCurrency,
  getCompanySettings,
  getForecastConfiguration,
  getForecastProgressionFilters,
  isBusinessTypesConfigReady as getIsBusinessTypesConfigReady,
} from 'selectors';

const PROGRESSION_COLUMNS_OPTIONS = [
  {
    text: 'Target',
    value: 'target',
  },
  {
    text: 'Expected Booked',
    value: 'expected_booked',
  },
  {
    text: 'Actual Booked',
    value: 'booked',
  },
  {
    text: 'Booked Pace',
    value: 'pace',
  },
  {
    text: 'Total Pipeline',
    value: 'total_pipe',
  },
  {
    text: 'Pipeline Gap',
    value: 'pipeline_gap',
  },
];

const mapStateToProps = (
  state: IReduxState,
  ownProps: RouteComponentProps
): StateProps => {
  const tabPartition: Partition =
    ownProps.location.pathname === '/dashboard/pacing'
      ? 'dashboards'
      : 'forecast';
  const isPipelineGapEnabled =
    getCompanySettings(state)?.pipeline_gap_settings?.enabled;

  const columnsOptions = [...PROGRESSION_COLUMNS_OPTIONS];
  // @ts-ignore
  getForecastConfiguration(state).forEach((category) => {
    columnsOptions.push({ value: category.value, text: category.display_name });
  });

  return {
    businessTypes: getBusinessTypes(state),
    isBusinessTypesConfigReady: getIsBusinessTypesConfigReady(state),
    columnsOptions: columnsOptions
      // Remove Pipeline Gap if it is not enabled.
      .filter(
        (column) => isPipelineGapEnabled || column.value !== 'pipeline_gap'
      ),
    companyCurrency: getUserLocalCurrency(state),
    filters: getForecastProgressionFilters(state),
    tabPartition,
  };
};

const mapDispatchToProps = {
  setForecastProgressionFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(Progression);
