import clx from 'classnames';
import { css } from 'emotion';
import React from 'react';
import { DropdownItemProps } from 'semantic-ui-react';

import {
  fontDefault,
  fontDefaultDropdown,
  fontSubtitle,
} from 'assets/css/common';
import { useClickOutside } from 'components/hooks/useClickOutside';

const dropdrown = css`
  border: 1px solid var(--bu-gray-500);
  padding: 5px 8px;
  border-radius: 4px;
  cursor: pointer;
  background: var(--bu-white);
  position: relative;
  margin-left: 10px;

  &:hover {
    background: rgba(49, 92, 121, 0.12);
  }

  &.active {
    border-color: var(--bu-primary-500);
  }
`;

const dropdrown_label = css`
  ${fontSubtitle};
  margin-right: 5px;
`;

const dropdrown_value = css`
  ${fontDefault};
`;

const dropdrown_options = css`
  &.open {
    display: block;
  }

  display: none;
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  left: 0;
  top: 33px;
  z-index: 15;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: max-content;
`;

const dropdrown_options_item = css`
  padding: 7px 15px;
  ${fontDefaultDropdown}
  &.active,
  &:hover {
    background: rgba(48, 176, 209, 0.08);
  }
`;

type IpropsDropdownProps = {
  label: string;
  onClick: any;
  value: any;
  options: any;
  interval: string;
};
export const DropdownCustom: React.FC<IpropsDropdownProps> = ({
  label,
  onClick,
  value,
  options,
  interval,
}) => {
  const { isOpen, setIsOpen, refElement } = useClickOutside();

  const toggle = () => setIsOpen(!isOpen);

  const option = options.find((i: DropdownItemProps) => i.value === value);

  return (
    <div
      ref={refElement}
      onClick={toggle}
      className={clx(dropdrown, { active: isOpen })}
    >
      <span className={dropdrown_label}>{label}:</span>
      <span className={dropdrown_value}>{option.text}</span>
      <ul className={clx(dropdrown_options, { open: isOpen })}>
        {options.map((i: DropdownItemProps) => (
          <li
            onClick={() => onClick(interval, i.value)}
            className={clx(dropdrown_options_item, {
              active: i.value === value,
            })}
          >
            {i.text}
          </li>
        ))}
      </ul>
    </div>
  );
};
