import React from 'react';

import * as styles from 'components/chart-dashboards/Dashboard/styles';
import Widget from 'components/chart-dashboards/Widget';
import { IProps } from 'components/dashboard/accounts-analytics/types';

const AccountsAnalytics = (props: IProps) => {
  if (!props.dashboard) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.charts}>
        {props.dashboard.rows.map((row, i) => (
          <div key={i} className={styles.chartRow}>
            {row.charts.map((chart) => (
              <div
                className={styles.chartWrapper(
                  row.charts.length > 1 ? 49 : 100
                )}
                key={chart.title}
              >
                <Widget {...chart} />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccountsAnalytics;
