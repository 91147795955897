import { css } from 'emotion';

export const fontHeading1 = css`
  font-family: var(--bu-font-semi-bold);
  font-size: 24px;
  line-height: 1.2em;
  letter-spacing: normal;
`;

export const fontHeading2 = css`
  font-family: var(--bu-font-semi-bold);
  font-size: 18px;
  line-height: 1.2em;
  letter-spacing: normal;
`;

export const fontHeading3 = css`
  font-family: var(--bu-font-medium);
  font-size: 16px;
  line-height: 1.4em;
  letter-spacing: normal;
`;

export const fontSubheading1 = css`
  font-family: var(--bu-font-medium);
  font-size: 14px;
  line-height: 1.4em;
  letter-spacing: normal;
`;

export const fontSubheading2 = css`
  font-family: var(--bu-font-semi-bold);
  font-size: 14px;
  line-height: 1.4em;
  letter-spacing: normal;
`;

export const fontBody = css`
  font-family: var(--bu-font-regular);
  font-size: 14px;
  line-height: 1.4em;
`;

export const fontCaption1 = css`
  font-family: var(--bu-font-regular);
  font-size: 12px;
  line-height: 1.3em;
  letter-spacing: normal;
`;

export const fontCaption2 = css`
  font-family: var(--bu-font-semi-bold);
  font-size: 12px;
  line-height: 1.3em;
  letter-spacing: normal;
`;

export const fontCaption3 = css`
  font-family: var(--bu-font-reqular);
  font-size: 10px;
  line-height: 1.4em;
  letter-spacing: normal;
`;
