import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  IncludeSummaryDataResponse,
  IncludeSummaryDealResponse,
  IncludeSummaryBody,
  IncludeSummarySubmission,
  SubmissionWithId,
  SubmissionWithNoId,
} from 'common/types/forecastSubmission';
import { TableConfigurationData } from 'components/UI/TableConfig/types';
import {
  getIsForecastIncludeSummaryEnabled,
  tableColumnConfiguration,
} from 'selectors';
import { fetchApiPromise } from 'utils/network';

export function isIncludeSummarySubmissionWithId(
  submission: IncludeSummarySubmission
): submission is SubmissionWithId {
  return (submission as SubmissionWithId).id !== undefined;
}

export const useGetIncludeSummaryForSubmissionAndDeals = (
  submissionSetting: IncludeSummarySubmission | undefined,
  deals: string[],
  shouldFetch: boolean = true
) => {
  const [isFetching, setIsFetching] = useState(false);
  const [dealsIncludedSummary, setDealsIncludedSummary] = useState<
    IncludeSummaryDealResponse[]
  >([]);

  const { columns = [] }: TableConfigurationData =
    useSelector((state) =>
      tableColumnConfiguration(
        state,
        'opportunity',
        'ForecastSubmissionSettings'
      )
    ) || {};

  const columnsLoaded = columns.length > 0;

  const includeSummaryColumnEnabled = !!columns.find(
    (c) => c.meta?.type === 'include_summary'
  );
  const companyIncludeSummaryEnabled = useSelector(
    getIsForecastIncludeSummaryEnabled
  );

  const shouldFetchIncludedSummary =
    companyIncludeSummaryEnabled && includeSummaryColumnEnabled && shouldFetch;

  useEffect(() => {
    setIsFetching(false);
    if (deals.length && shouldFetchIncludedSummary && submissionSetting) {
      setIsFetching(true);

      const queryParams = isIncludeSummarySubmissionWithId(submissionSetting)
        ? {
            deals_ids: deals,
            forecast_submission_setting_id: submissionSetting.id,
          }
        : {
            deals_ids: deals,
            forecast_name: submissionSetting.forecastName,
            business_type_name: submissionSetting.businessTypeName,
            close_date_interval: submissionSetting.closeDateInterval,
          };

      fetchApiPromise<IncludeSummaryBody, IncludeSummaryDataResponse>({
        url: `${process.env.REACT_APP_BACKEND_URL}/api/data/forecast/submission/include_summary/`,
        queryMethod: 'post',
        queryParams,
      }).then((response) => {
        if (response.result?.deals) {
          setDealsIncludedSummary(response.result?.deals);
        }
        setIsFetching(false);
      });
    }
  }, [
    deals,
    shouldFetchIncludedSummary,
    (submissionSetting as SubmissionWithId)?.id,
    (submissionSetting as SubmissionWithNoId)?.forecastName,
    (submissionSetting as SubmissionWithNoId)?.closeDateInterval,
    (submissionSetting as SubmissionWithNoId)?.businessTypeName,
  ]);

  return {
    isDealsIncludedSummaryLoading:
      shouldFetchIncludedSummary && (isFetching || !columnsLoaded),
    dealsIncludedSummary,
  };
};
