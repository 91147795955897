import { DEFAULT_QUERY_METHOD } from 'utils/network/constants';
import { fetchApiWithoutCb } from 'utils/network/fetchApiWithoutCb';
import { IFetchApiWithCd } from 'utils/network/types';

/**
 * @deprecated Please use useQuery instead of this function.
 * @see https://vocalo.atlassian.net/wiki/spaces/EN/pages/3210215429/Proposal+for+Standardizing+Data+Fetching+and+Caching+using+React+Query
 * @see https://tanstack.com/query/v4
 */
export const fetchApiWithCb = async <TReqParams, TResponse>({
  queryMethod = DEFAULT_QUERY_METHOD,
  queryParams,
  setData = () => {},
  setError = () => {},
  setStatus = () => {},
  setHeaders = () => {},
  signal,
  url,
}: IFetchApiWithCd<TReqParams, TResponse>) => {
  setStatus('loading');

  const { error, result, headers } = await fetchApiWithoutCb<
    TReqParams,
    TResponse
  >({
    queryMethod,
    queryParams,
    signal,
    url,
  });

  if (error) {
    setError(error);
    setStatus('error');
  }

  if (result || result === null) {
    setData(result);
    setStatus('success');
  }

  if (headers) {
    setHeaders(headers);
  }
};
