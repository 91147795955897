import axios from 'axios';
import { put, call, all, takeLatest } from 'redux-saga/effects';

import * as t from 'actions/actionTypes';
import {
  getTargetsSuccess,
  getTargetsFailure,
  setTargetSuccess,
  setTargetFailure,
  ITargetsPayload,
} from 'actions/targetsActions';

const getIntervalUrl = (interval: string): string =>
  interval ? `/${interval}` : '/quarterly';
const getAttributeUrl = (attribute?: string): string =>
  attribute ? `/${attribute}` : '';

function* getTargets({
  interval,
  year,
  attribute,
  selectedBusinessType,
}: ITargetsPayload) {
  try {
    const itervalUrl = getIntervalUrl(interval);
    const attributeUrl = getAttributeUrl(attribute);
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/data/target${itervalUrl}${attributeUrl}`;
    const { data, status } = yield call(axios.get, url, {
      params: {
        fiscal_year: year,
        business_type: selectedBusinessType,
      },
    });

    if (status === 200) {
      yield put(
        getTargetsSuccess({
          overall_targets: data.overall_targets,
          user_targets_by_role: data.user_targets_by_role,
        })
      );
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      let error = e.response?.data.error.message.join(',\n');
      yield put(getTargetsFailure(error));
    }
  }
}

function* setSellerTarget({ interval, targetsBy, body }: ITargetsPayload) {
  try {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/data/target/${interval}/${targetsBy}`;

    const { status } = yield call(axios.post, url, body);

    if (status === 200) {
      yield put(setTargetSuccess());
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      let error = e.response?.data.error.message.join(',\n');
      yield put(setTargetFailure(error));
    }
  }
}

function* sellerTargetsWatch() {
  yield all([
    // @ts-ignore
    takeLatest(t.TARGETS + t.GET + t.REQUEST, getTargets),
    // @ts-ignore
    takeLatest([t.TARGETS + t.SET + t.REQUEST], setSellerTarget),
  ]);
}

export default sellerTargetsWatch;
