/* eslint-disable no-debugger */
import { css } from 'emotion';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import flow from 'lodash/flow';
import filter from 'lodash/fp/filter';
import map from 'lodash/fp/map';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { renderToString } from 'react-dom/server';
import { connect } from 'react-redux';

import { actions } from 'actions';
import MindtickleCellIcon from 'assets/fonts/boostup-icons/mindtickle_call_icon.svg';
import PlayCallIcon from 'assets/fonts/boostup-icons/play.svg';
import { addUrlParams } from 'common/helpers';
import { openModal } from 'navigation/utils';
import { history } from 'store/configureStore';
import { fetchApi } from 'utils/network';

const chartStyle = css`
  display: block;
  height: 35px;
  overflow: visible;
`;

const tooltipContainer = css`
  display: flex;
  flex-direction: column;
  max-height: 40px;
  height: 100%;
  max-width: 600px;
  width: 100%;
  z-index: 3;
`;

const descriptionStyle = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const chartContainer = css`
  overflow: visible !important;
`;

class TopicTimelineChart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isCallsActive: true,
      isEventsActive: true,
      isProspectEmailsActive: true,
      isRepEmailsActive: true,
    };
  }

  isAllActive = () => {
    const {
      isCallsActive,
      isEventsActive,
      isProspectEmailsActive,
      isRepEmailsActive,
    } = this.state;
    return (
      isCallsActive &&
      isEventsActive &&
      isProspectEmailsActive &&
      isRepEmailsActive
    );
  };

  handleLegendClick = (field) => {
    const falsyValues = {
      isCallsActive: false,
      isEventsActive: false,
      isProspectEmailsActive: false,
      isRepEmailsActive: false,
    };

    this.setState((prevState) => ({
      ...prevState,
      ...falsyValues,
      [field]: true,
    }));
  };

  handleClickAll = () => {
    const falsyValues = {
      isCallsActive: false,
      isEventsActive: false,
      isProspectEmailsActive: false,
      isRepEmailsActive: false,
    };

    const trullyValues = {
      isCallsActive: true,
      isEventsActive: true,
      isProspectEmailsActive: true,
      isRepEmailsActive: true,
    };

    this.setState(this.isAllActive() ? falsyValues : trullyValues);
  };

  isActiveBtn(name) {
    return this.isAllActive() !== true && this.state[name] === true;
  }

  render() {
    const {
      data,
      timeLineWidth = 399,
      topic,
      timeSpan,
      minMaxRange,
      persistModalParams,
      entityId,
    } = this.props;

    const {
      isCallsActive,
      isEventsActive,
      isProspectEmailsActive,
      isRepEmailsActive,
    } = this.state;
    function getFormattedLabel(value) {
      const dateTime = moment(value);

      return dateTime.utc().hour() ||
        dateTime.utc().minutes() ||
        dateTime.utc().second()
        ? ''
        : dateTime.format('M/D');
    }
    const config = {
      chart: {
        type: 'scatter',
        height: '35px',
        width: timeLineWidth,
        backgroundColor: 'rgba(255, 255, 255, 0.0)',
      },
      title: false,
      xAxis: {
        type: 'datetime',
        title: {
          enabled: false,
        },
        showFirstLabel: true,
        reversed: false,
        min: minMaxRange.min,
        max: minMaxRange.max,
        labels: {
          formatter() {
            return getFormattedLabel(this.value);
          },
          y: 33,
        },
        endOnTick: true,
        lineWidth: 2,
        minRange: 1,
        startOnTick: true,
        tickLength: 5,
        lineColor: '#CCD8EE',
        tickColor: '#CCD8EE',
      },
      yAxis: [
        {
          showEmpty: true,
          min: 0,
          max: 100,
          visible: false,
        },
      ],
      legend: false,
      tooltip: {
        outside: true,
        enabled: true,
        useHTML: true,
        formatter() {
          //@ts-ignore
          const { category, subject } = this.point;
          return renderToString(
            <div className={tooltipContainer}>
              <span className={descriptionStyle}>{subject}</span>
              <span>
                {moment
                  .utc(category, 'x')
                  .local()
                  .format('Do MMMM YYYY, h:mm a')}
              </span>
            </div>
          );
        },
      },
      plotOptions: {
        scatter: {
          marker: {
            radius: 5,
            states: {
              hover: {
                enabled: true,
                lineColor: 'rgb(100,100,100)',
              },
            },
          },
          series: {
            label: {
              connectorAllowed: false,
            },
          },
          states: {
            hover: {
              marker: {
                enabled: false,
              },
            },
          },
          point: {
            events: {
              click() {
                switch (this.type) {
                  case 'email':
                    openModal({
                      scheme: '/email/:emailId',
                      params: { emailId: this.id },
                      persistParams: { topic },
                      persistor: persistModalParams,
                    });
                    break;
                  case 'event':
                    openModal({
                      scheme: '/event/:eventId',
                      params: { eventId: this.id },
                      persistParams: { topic },
                      persistor: persistModalParams,
                    });
                    break;
                  case 'call':
                    history.push({
                      pathname: `/transcript/${this.id}?topic=${topic}`,
                    });
                    break;
                  case 'mindtickle':
                    fetchApi({
                      url: `${process.env.REACT_APP_BACKEND_URL}/api/data/events/call_ai/${this.id}`,
                      queryMethod: 'get',
                      setData(response) {
                        if (response) {
                          const activity = response.activities[0];

                          openModal({
                            scheme: '/activity/:activityId',
                            params: {
                              activityId: `${entityId}_${activity.record_id}`,
                            },
                            persistParams: {
                              activity: response,
                            },
                            persistor: persistModalParams,
                          });
                        }
                      },
                      setError: () => {
                        if (this.recording_url) {
                          const win = window.open(this.recording_url, '_blank');
                          win.focus();
                        }
                        return null;
                      },
                    });
                    break;
                }
              },
            },
          },
        },
        series: {
          stickyTracking: false,
        },
      },
      series: [
        {
          name: 'Calls',
          data: flow(
            filter((el) => el.source === 'call'),
            map((el) => ({
              x: +moment(el.mentioned_at).format('x'),
              y: 0,
              type: 'call',
              id: el.object_id,
              subject: el.summary,
            }))
          )(data),
          color: '#0AB3B0',
          cursor: 'pointer',
          visible: isCallsActive,
          marker: {
            symbol: `url(${PlayCallIcon})`,
          },
        },
        {
          name: 'Events',
          data: flow(
            filter((el) => el.source === 'event'),
            map((el) => ({
              x: +moment(el.mentioned_at).format('x'),
              y: 0,
              type: 'event',
              id: el.object_id,
              subject: el.summary,
            }))
          )(data),
          color: 'rgb(232 147 12 / 80%)',
          cursor: 'pointer',
          visible: isEventsActive,
          marker: {
            symbol: 'circle',
          },
        },
        {
          name: 'Prospect Emails',
          data: flow(
            filter(
              (el) => el.source === 'email' && el.email_type === 'CUSTOMER'
            ),
            map((el) => ({
              x: +moment(el.mentioned_at).format('x'),
              y: 0,
              type: 'email',
              id: el.object_id,
              subject: el.email_subject,
            }))
          )(data),
          color: 'var(--bu-primary-500)',
          visible: isProspectEmailsActive,
          cursor: 'pointer',
          marker: {
            symbol: 'circle',
          },
        },
        {
          name: 'Rep emails',
          data: flow(
            filter(
              (el) => el.source === 'email' && el.email_type === 'COMPANY'
            ),
            map((el) => ({
              x: +moment(el.mentioned_at).format('x'),
              y: 0,
              type: 'email',
              id: el.object_id,
              subject: el.email_subject,
            }))
          )(data),
          cursor: 'pointer',
          color: 'var(--bu-red-400)',
          visible: isRepEmailsActive,
          marker: {
            symbol: 'circle',
          },
        },
        {
          name: 'Mindtickle source',
          data: flow(
            filter((el) => 'Mindtickle' === el.source),
            map((el) => ({
              x: +moment(el.mentioned_at).format('x'),
              y: 0,
              type: 'mindtickle',
              id: el.object_id,
              subject: el.summary,
              source: el.source,
              recording_url: addUrlParams(el.recording_url, {
                activeMenuKey: 'themes',
              }),
            }))
          )(data),
          cursor: 'pointer',
          color: 'var(--bu-red-400)',
          visible: true,
          marker: {
            symbol: `url(${MindtickleCellIcon})`,
          },
        },
      ],
    };
    return (
      <div className={chartStyle}>
        <HighchartsReact
          highcharts={Highcharts}
          options={config}
          immutable={true}
          containerProps={{ className: chartContainer }}
        />
      </div>
    );
  }
}

TopicTimelineChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const dispatchToProps = {
  persistModalParams: actions.ui.modal.persist,
};

export default connect(null, dispatchToProps)(TopicTimelineChart);
