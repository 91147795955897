import classNames from 'classnames';
import { css } from 'emotion';
import React, { useState } from 'react';
import { Radio, Input, Divider } from 'semantic-ui-react';
import { CheckboxProps } from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { fontDefaultDropdown } from 'assets/css/common';
import BuIcon from 'components/UI/BuIcon';
import CheckboxInput from 'components/UI/Checkbox';
import { IPropsNew } from 'components/UI/FilterPanels/types';
import * as s from 'components/UI/OpenFiltersPanel/styles';
import { no_result_box } from 'components/UI/OpenFiltersPanel/styles';

export const radioButtonOptionStyle = css`
  ${fontDefaultDropdown};
  color: var(--bu-gray-900) !important;
  margin-top: 0 !important;
  max-width: 100% !important;
  margin-bottom: 0px !important;

  label:after {
    width: 14px !important;
    height: 14px !important;
    top: 3px !important;
  }

  label:before {
    width: 14px !important;
    height: 14px !important;
    top: 3px !important;
  }

  input:checked ~ label:before {
    background-color: var(--bu-primary-500) !important;
    border-color: var(--bu-primary-500) !important;
  }

  input:checked ~ label:after {
    background-color: var(--bu-white) !important;
  }
`;

const listItem = css`
  padding: 7px 15px 7px 15px;
  transition: 0.4s all;

  &:hover {
    background: rgba(48, 176, 209, 0.08);
  }

  &.checked {
    background: rgba(48, 176, 209, 0.08);
  }
`;

const Panel: React.FC<IPropsNew> = ({ config, onChange, onClose }) => {
  const [searchRequestState, setSearchRequestState] = useState('');
  const isAllSelected = config && config.checkedAll;
  const isRadioButton = config.type === 'radio';

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value: id, checked } = e.target;

    onChange([{ id, checked: isAllSelected ? true : checked }], isAllSelected);
  }

  function handleChangeAll(e: React.ChangeEvent<HTMLInputElement>) {
    onChange(
      config.values.map((item) => ({
        id: item.value,
        checked: e.target.checked,
      })),
      true
    );
  }

  function handleChangeRadio(
    e: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) {
    const { checked, value } = data;

    if (checked && value) {
      onChange(
        config.values.map((item) => ({
          id: item.value,
          checked: item.value === value,
        }))
      );
    }
    onClose(false);
  }

  function renderCheckboxes(checkboxes: Filters.Checkbox[]) {
    return checkboxes.map((checkbox: Filters.Checkbox) => {
      const { checked, label, value } = checkbox;
      const { title, key } = config;
      const isAllOption = value === '__all__';

      const commonParams = {
        checked: isAllSelected && !isAllOption ? false : checked,
        id: `${title}_${value}`,
        key: `${key}_${title}_${value}`,
        label,
        name: title,
        value,
        strong: isAllOption,
      };

      const typeButtons = () => {
        if (config.type === 'radio') {
          return (
            <Radio
              {...commonParams}
              className={radioButtonOptionStyle}
              onChange={handleChangeRadio}
              role="option"
              aria-selected={commonParams.checked}
            />
          );
        } else if (config.type === 'text_field') {
          return <TextField {...commonParams} onChange={handleChangeRadio} />;
        } else {
          return (
            <CheckboxInput
              {...commonParams}
              onChange={isAllOption ? handleChangeAll : handleChange}
            />
          );
        }
      };

      const renderButton = typeButtons();

      return (
        <div
          className={classNames(listItem, {
            checked: commonParams.checked,
            hasMore: checkbox.group,
          })}
          key={`${title}_${value}`}
        >
          {renderButton}
        </div>
      );
    });
  }

  function filterCheckBoxes(options: Filters.Checkbox[], request: string) {
    if (request === '') {
      return options;
    }

    return options.filter(
      (opt) =>
        opt.label.toLowerCase().indexOf(request.toLowerCase()) !== -1 ||
        opt.value.toLowerCase().indexOf(request.toLowerCase()) !== -1
    );
  }

  const filteredCheckboxes =
    config && filterCheckBoxes(config.values, searchRequestState);

  return (
    <>
      {config && (
        <div className="collapse in" id="topics">
          {config.searchable && (
            <>
              <Input
                className={s.panel_dropdown_input}
                iconPosition="left"
                icon="search"
                placeholder="Search..."
                onChange={(e, { value }) => setSearchRequestState(value)}
                value={searchRequestState}
              />
              <Divider className={s.panel_dropdown_divider} />
            </>
          )}

          <div className={s.panel_dropdown_checkboxes}>
            {renderCheckboxes(filteredCheckboxes)}
          </div>

          {searchRequestState !== '' && filteredCheckboxes.length === 0 && (
            <div className={no_result_box}>
              <p>No results found.</p>
              <span onClick={() => setSearchRequestState('')}>
                reset search
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Panel;

interface TextFieldProps {
  onChange: any;
  checked: boolean;
  id: string;
  value: string;
  strong: boolean;
  name: string;
  label: string;
}

const TextField = ({ onChange, id, value, label, checked }: TextFieldProps) => (
  <div
    className={classNames(s.textField, { active: checked })}
    onClick={(e) => onChange(e, { checked: true, value })}
    aria-selected={checked}
    role="option"
    key={id}
  >
    {label}
    {checked && <BuIcon name={BoostUpIcons.ChevronRight} />}
  </div>
);
