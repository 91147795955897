import * as R from 'ramda';

export const getDealsCurrentTab = (state) => state.deals.currentTab;

export const getDealsTabScroll = (state, tab) => state.deals[tab].scroll;

export const getDealsStatus = (state, tab) => state.deals[tab].deals.status;

export const getDealDataLastCall = (state) =>
  R.pathOr('', ['deals', 'dealDetailed', 'data', 'last_call'], state);

export const getDealDetailed = (state, id) => {
  return R.pathOr(
    state.deals.dealDetailed.dumb,
    ['deals', 'dealDetailed', id],
    state
  );
};

export const getAllDealDetailed = (state) => {
  return R.pathOr({}, ['deals', 'dealDetailed'], state);
};

export const getAllStats = (state, tab) => state.deals[tab].stats;

export const getStatsStatus = (state, tab) => state.deals[tab].stats.status;

export const getStatsTotals = (state, tab) => state.deals[tab].stats.totals;

export const getForceRefresh = (state) => state.deals.forceRefresh;

export const getFieldOptions = (state, field) =>
  R.pathOr([], ['deals', 'fieldsDescriptions', field, 'values'], state);

export const getFieldDescriptions = (state) =>
  R.pathOr({}, ['deals', 'fieldsDescriptions'], state);

export const getDealsCount = (state, type) => state.deals.count[type];
export const getDealStatuses = (state) => state.deals.dealStatus;
export const getIfSomeDealWasModifiedSinceRecalculation = (state) =>
  state.deals.dealStatus.someWasModifiedSinceRecalculation;

export const getChangedOpportunityDeal = R.pathOr(null, [
  'deals',
  'topOpportunities',
  'deals',
  'changes',
]);
