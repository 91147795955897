import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import * as R from 'ramda';

const Input = styled.input`
  label: input;
  // height: ${(props) => props.scale * 34}px;
  padding: 8px;
  border: 1px solid #cccccc;
  border-radius: ${(props) => (props.open ? '20px 20px 0 0' : '20px')};
  color: #4a4a4a;
  font-family: var(--bu-font-regular);
  font-size: ${(props) => R.max(10, 14 * props.scale)}px;
  background: #f7f7f7;
  ${(props) => (props.fluid ? 'min-width: 0;' : '')} &::placeholder {
    color: #4a4a4a;
    font-family: var(--bu-font-regular);
    font-size: 14px;
  }

  &:focus {
    box-shadow: none;
    background: #ffffff;
  }

  &:disabled {
    background-color: #f9f9f9;
    cursor: not-allowed;
  }
`;

Input.propTypes = {
  scale: PropTypes.number,
};

Input.defaultProps = {
  scale: 1,
};

export default Input;
