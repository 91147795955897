import styled from '@emotion/styled';

export const ConditionsContainer = styled.div({
  marginBottom: '15px',
});

export const ConditionsTitle = styled.div({
  fontWeight: 'bold',
  fontSize: '17px',
  paddingBottom: '5px',
});

export const Description = styled.div({
  fontSize: '12px',
  color: 'var(--bu-gray-700)',
});

export const ConditionsList = styled.div({
  paddingBottom: '5px',
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
});

export const ConditionBlock = styled.div({
  padding: '10px',
  backgroundColor: 'var(--bu-gray-100)',
  backgroundRadius: '5px',
});
