import { DEFAULT_QUERY_METHOD } from 'utils/network/constants';
import { IUploadFile } from 'utils/network/types';

export const uploadFile = async <TReqParams>({
  file,
  queryMethod = DEFAULT_QUERY_METHOD,
  params,
  setError = () => {},
  setStatus = () => {},
  url,
}: IUploadFile<TReqParams>): Promise<Response> => {
  var formData = new FormData();
  if (!!file) {
    formData.append('file', file);
  }

  if (!!params) {
    for (const [key, value] of Object.entries(params)) {
      // @ts-ignore
      formData.append(key, value);
    }
  }

  return await fetch(url, {
    body: formData,
    credentials: 'include',
    method: queryMethod,
  });
};
