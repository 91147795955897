export const timeOptions = {
  firstCol: [
    { value: 'YDY', text: 'Yesterday' },
    { value: 'L30D', text: 'Last 30 days' },
    { value: 'SQS', text: 'Quarter Start' },
  ],
  secondCol: [
    { value: 'L7D', text: 'Last 7 days' },
    { value: 'L60D', text: 'Last 60 days' },
  ],
  thirdCol: [
    { value: 'L14D', text: 'Last 14 days' },
    { value: 'L3M', text: 'Last 3 months' },
  ],
};
