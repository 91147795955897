import styled from '@emotion/styled/macro';
import { XYCoord } from 'dnd-core';
import { css } from 'emotion';
import React, { FC, useRef } from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import { OrderEntry } from 'components/settings/ManageTableColumns/ManageTableColumns';

const column = css`
  display: inline-block;
  font-family: var(--bu-font-regular);
  border: 1px solid var(--bu-gray-500);
  border-radius: 4px;
  padding: 5px 8px;
  margin: 0px 7px 7px 0px;
  height: 30px;
  line-height: 17px;
  background-color: var(--bu-gray-200);
  color: var(--bu-gray-900);
  cursor: move;
  transition: 0.4s;

  &:hover {
    background: rgba(49, 92, 121, 0.12);
  }
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: -2px;
  gap: 3px;

  .bu-icon-sorting_descending,
  .bu-icon-sorting_ascending {
    color: var(--bu-primary-500);
  }
`;

export const column_icon = css`
  font-size: 18px;
`;

interface IProps {
  id: string;
  text: string;
  index: number;
  onRemove: () => void;
  order?: OrderEntry;
  moveCard?: (dragIndex: number, hoverIndex: number) => void;
  onDropEnd?: () => void;
}

const ColumnPill: FC<IProps> = ({
  id,
  text,
  index,
  onRemove,
  order,
  moveCard,
  onDropEnd,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: IProps, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (
        (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
        (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
      ) {
        return;
      }

      if (moveCard) {
        moveCard(dragIndex, hoverIndex);
      }

      item.index = hoverIndex;
    },
    drop: () => {
      if (onDropEnd) {
        return onDropEnd();
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  return (
    <div
      ref={ref}
      style={{ opacity }}
      className={column}
      data-handler-id={handlerId}
    >
      <LabelContainer>
        <span>{text}</span>

        {order?.column === id && (
          <BuIcon
            name={
              order.direction === 1
                ? BoostUpIcons.SortingAscending
                : BoostUpIcons.SortingDescending
            }
          />
        )}
        <span
          style={{ cursor: 'pointer' }}
          className={column_icon}
          onClick={onRemove}
        >
          <BuIcon name={BoostUpIcons.Trash} />
        </span>
      </LabelContainer>
    </div>
  );
};

export default ColumnPill;
