import React, { Fragment } from 'react';
import { Grid } from 'semantic-ui-react';

import InfoIcon from 'assets/images/new_icon/info_icon.svg';
import IndicatorItem from 'components/chart-dashboards/MainIndicators/IndicatorItem';
import * as s from 'components/chart-dashboards/MainIndicators/styles';
import { IProps } from 'components/chart-dashboards/MainIndicators/types';

interface ITitleProps {
  title?: string;
}

export const MainIndicatorTitle = ({
  title = 'Closing in current quarter',
}: ITitleProps) => (
  <div className={s.information_block}>
    <span className={s.information_span}>
      <img src={InfoIcon} alt="Info icon" />
      {title}
    </span>
  </div>
);

const MainIndicators: React.FC<IProps> = ({ dashboardsHeader }) => {
  if (!dashboardsHeader) {
    return null;
  }

  return (
    <Fragment>
      <MainIndicatorTitle />
      <Grid className={s.core_data} celled>
        <Grid.Row columns={6}>
          {dashboardsHeader.metrics.map((el) => (
            <IndicatorItem
              key={el.title}
              apiUrls={el.apis}
              dataType={el.data_type}
              title={el.title}
              chartType={el.chart_type}
              valuePath={el.value_path}
            />
          ))}
        </Grid.Row>
      </Grid>
    </Fragment>
  );
};

export default MainIndicators;
