import classNames from 'classnames';
import { Loader } from 'semantic-ui-react';
import {
  CellMainContent,
  ExpandCollapseIcon,
  ExpandPosition,
  ExpandRotate,
  ExpandableHierarchyCellWrapper,
  LoadingNodeLoader,
  PivotIconContainer,
  TextWrapper,
  TitleValue,
  TooltipNextPivotName,
} from './ExpandHierarchyCell.styles';
import {
  ExpandableRow,
  OnExpandClick,
  PIVOT_HIERARCHY_SEPARATOR,
} from './useExpandableWidgetTable.helper';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import { IDataCellProps } from 'components/UI/common/TypedTable/TypedTable';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';

export interface IDataExpandableCellProps extends IDataCellProps {
  row: ExpandableRow;
  onExpandClick: OnExpandClick;
  getColumnNameAndIconTable: (depthLevel: number) => { [key: string]: string };
}

const ExpandableHierarchyCell = ({
  row,
  column,
  onExpandClick,
  getColumnNameAndIconTable,
}: IDataExpandableCellProps) => {
  const currentValue = row.expandValue;
  const currentTitleValue = row.nodePivotTitleValue;
  const isExpanded = row.isExpanded;
  const canExpand = row.canExpand;
  const nodePath = row.nodePath;
  const haveToFetchPivotsIfExpanded = row.haveToFetchPivotsIfExpanded;
  const nextPivotName = row.nextPivotName;
  const pivotHierarchyDepth = row.nodePath.split(
    PIVOT_HIERARCHY_SEPARATOR
  ).length;
  const isLoadingNode = row.isLoadingRow;
  const { icon: Icon, columnName } =
    getColumnNameAndIconTable(pivotHierarchyDepth);

  if (isLoadingNode) {
    return (
      <div
        key={`${row.id}-${column.id}`}
        className={ExpandableHierarchyCellWrapper}
      >
        <span className={CellMainContent(pivotHierarchyDepth)}>
          Loading
          <Loader className={LoadingNodeLoader} active size="tiny" inline />
        </span>
      </div>
    );
  }

  const toggleCollapseIcon = isExpanded
    ? BoostUpIcons.CollapseIcon
    : BoostUpIcons.CrossCircle;

  return (
    <div
      key={`${row.id}-${column.id}`}
      className={ExpandableHierarchyCellWrapper}
    >
      <div className={CellMainContent(pivotHierarchyDepth)}>
        {columnName && row.id !== 'Subtotal' && (
          <TooltipWrapper
            tooltip={<div className={TooltipNextPivotName}>{columnName}</div>}
            position="top center"
          >
            <div>
              <img
                src={Icon}
                alt={nextPivotName}
                className={PivotIconContainer(pivotHierarchyDepth)}
              />
            </div>
          </TooltipWrapper>
        )}

        <TooltipWrapper
          tooltip={<div className={TooltipNextPivotName}>{currentValue}</div>}
          position="top center"
        >
          <div className={TextWrapper(row.id === 'Subtotal')}>
            {currentValue}
            {!!currentTitleValue && (
              <span className={TitleValue}>{currentTitleValue}</span>
            )}
          </div>
        </TooltipWrapper>
      </div>

      {canExpand && (
        <TooltipWrapper
          tooltip={<div className={TooltipNextPivotName}>{nextPivotName}</div>}
          position="right center"
        >
          <div
            onClick={() =>
              onExpandClick(nodePath, !isExpanded, haveToFetchPivotsIfExpanded)
            }
            className={classNames(
              {
                // We are using the cross icon for the expand icon as well, so we need to rotate it
                // to make it look like a + icon
                [ExpandRotate]: !isExpanded,
              },
              ExpandPosition
            )}
          >
            <BuIcon
              color="var(--bu-primary-500)"
              name={toggleCollapseIcon}
              className={ExpandCollapseIcon}
            />
          </div>
        </TooltipWrapper>
      )}
    </div>
  );
};

export default ExpandableHierarchyCell;
