import { css } from 'emotion';

export const header = css`
  label: header;
  color: #1e306e;
  letter-spacing: 0;
  line-height: 21px;
  padding: 25px 0 10px 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const headerTitle = css`
  label: header-title;
  flex-grow: 1;
  color: #1e306e;
  display: flex;
  flex-direction: column;
`;

export const headerSubtitle = css`
  label: header-subtitle;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const loaderContainer = css`
  label: loader-container;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const privacy_policy = css`
  margin: 30px 0;

  h1 {
    color: #4a4a4a;
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 20px;
    font-family: var(--bu-font-semi-bold);
  }

  p {
    color: #4a4a4a;
    font-size: 18px;
    line-height: 26px;
    font-family: var(--bu-font-regular);
    margin-bottom: 20px;
  }

  ol {
    li {
      color: #4a4a4a;
      font-size: 18px;
      line-height: 26px;
      font-family: var(--bu-font-regular);
      margin-bottom: 20px;
    }
  }
`;

export const weight_span = css`
  font-family: var(--bu-font-semi-bold);
`;

export const link = css`
  color: #0a386c;
  font-family: var(--bu-font-semi-bold);
  text-decoration: underline;
`;

export const checkboxContainer = css`
  line-height: 3;
`;

export const content = css`
  margin: 30px 0;

  h1 {
    color: #4a4a4a;
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 20px;
    font-family: var(--bu-font-semi-bold);
  }

  p {
    color: #4a4a4a;
    font-size: 18px;
    line-height: 26px;
    font-family: var(--bu-font-regular);
    margin-bottom: 20px;
  }

  ol {
    list-style: none;
    counter-reset: li;
    margin-bottom: 20px;

    li {
      color: #4a4a4a;
      font-size: 18px;
      line-height: 26px;
      font-family: var(--bu-font-regular);
      margin-bottom: 20px;
    }

    li:before {
      counter-increment: li;
      content: counters(li, '.') '. ';
    }
  }
`;

export const avatarColors = [
  '#F2A35A',
  '#8084E9',
  '#BC6BDD',
  '#00C6AE',
  '#FF7271',
  '#61E37D',
  '#7D84AA',
  '#28BEDF',
];

export const backgroundOnHover = css`
  &.ui.accordion.styled {
    .title {
      &:hover,
      &.active {
        background-color: #f9fcff;
      }
    }
    .content.active {
      padding: 0;
      border-top: 1px solid #ddd;
    }
  }
`;

export const contentWithoutPadding = css`
  &.ui.accordion.styled {
    .content.active {
      padding: 0;
      border-top: 1px solid #ddd;
    }
  }
`;

export const fixHeadersInAccordion = css`
  &.ui.styled.accordion .ui.header > .content {
    padding: 0 !important;
    padding-left: 0.75em !important;
  }
`;

export const headerContainer = css`
  display: flex;
  margin: 27px 0 10px 0;
  &:nth-child(n + 2) {
    margin-top: 40px;
  }
  & > div:first-child {
    flex: 1;
  }
`;

export const headerRow = css`
  color: #1e306e;
  font-size: 18px;
  font-family: var(--bu-font-medium);
  letter-spacing: 0;
  line-height: 21px;
  .subheader {
    font-family: var(--bu-font-regular);
    font-size: 14px;
    line-height: 21px;
    color: #777777;
    margin-top: 7px;
  }
`;

export const headerActions = css`
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
  align-items: flex-end;
  flex-direction: row;
`;

export const removeContainerPadding = css`
  margin: 0px -30px;
  height: 100%;
`;

export const colorOnOpen = css`
  &.ui.header > {
    .icon,
    .content {
      color: var(--bu-primary-500);
    }
  }
`;

export const colorOnHoverParent = css`
  *:hover > & {
    ${colorOnOpen}
  }
`;
