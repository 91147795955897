import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import localforage from 'localforage';
import { applyMiddleware, compose, createStore } from 'redux';
import { createTransform, persistReducer, persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import { createBlacklistFilter } from 'common/createBlackListFilter';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import { analyticsMiddleware } from 'middlewares/analytics';
import rootReducer from 'reducers';
import { initialState as openFiltersInitialState } from 'reducers/filters/openFilters';
import indexSaga from 'sagas';

localforage.config({
  name: 'boostup',
  version: 1,
  storeName: 'keyvaluepairs',
  driver:
    process.env.NODE_ENV === 'test'
      ? [localforage.LOCALSTORAGE]
      : [localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE],
});

const settingsLoadedTransform = createTransform(
  (inboundState) => inboundState,
  (outboundState) => ({
    ...outboundState,
    companySettings: {
      ...outboundState.companySettings,
      loaded: false,
    },
  }),
  { whitelist: ['settings'] }
);

const openFiltersTransform = createTransform(
  (inboundState) => ({ selectedProfiles: inboundState.selectedProfiles }),
  (outboundState) => ({ ...openFiltersInitialState, ...outboundState }),
  { whitelist: ['openFilters'] }
);

const nestedBlacklist = createBlacklistFilter('settings', ['usersFilters']);

const config = {
  key: 'root:v32', // key is required
  storage: localforage,
  blacklist: [
    'accounts',
    'app',
    'appState',
    'buyerEngagement',
    'calls',
    'deals',
    'forecastSubmission',
    'router',
    'tables',
    'transcript',
    'widget',
    'widgets',
    'search',
    'revbi',
    'openFilters',
  ],
  transforms: [settingsLoadedTransform, nestedBlacklist, openFiltersTransform],
};

export const conf = process.env.REACT_APP_SUBDIRECTORY
  ? { basename: process.env.REACT_APP_SUBDIRECTORY }
  : undefined;
export const history = createBrowserHistory(conf);

const reducer = persistReducer(config, rootReducer(history));
const sagaMiddleware = createSagaMiddleware();
const isDevelopment = process.env.NODE_ENV !== 'production';
const middleware = isDevelopment
  ? [analyticsMiddleware, routerMiddleware(history), thunk, sagaMiddleware]
  : [analyticsMiddleware, routerMiddleware(history), thunk, sagaMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const appliedMiddlewares = applyMiddleware(...middleware);
const store = createStore(
  reducer,
  isDevelopment ? composeEnhancers(appliedMiddlewares) : appliedMiddlewares
);

export const persistor = persistStore(store, {});

sagaMiddleware.run(indexSaga);
AnalyticsTracker.init();

export default store;
