/* eslint-disable no-param-reassign */
import produce from 'immer';

import * as t from 'actions/actionTypes';

const initialState = {
  objectionMoment: {},
  cachedItems: [],
  dealsIncluded: [],
};

export function cacheReducer(state = initialState, action) {
  const { type, key, data, entity, entity_id } = action;
  const reducer = {
    [t.CACHE + t.CREATE]: (draft) => {
      if (entity) {
        draft.objectionMoment[key] = data;
        draft[entity].cacheTimestamp = Date.now();
      } else if (entity_id) {
        draft[key] = data;
        draft.cachedItems = [];
        draft.cachedItems.push({ entity_id, value: Date.now() });
      } else {
        draft[key] = data;
      }
    },
  };

  return Object.prototype.hasOwnProperty.call(reducer, type)
    ? produce(state, reducer[type])
    : state;
}

export function draftTableRows(state = {}, action) {
  switch (action.type) {
    case t.CACHE + t.TABLE_DRAFT:
      return {
        ...state,
        [action.tableName]: action.draftRows,
      };
    default:
      return state;
  }
}

const dealsIncludedInitialState = {
  deals: {},
};

export function dealsIncluded(state = dealsIncludedInitialState, action) {
  switch (action.type) {
    case t.CACHE + t.INCLUDED_DEALS + t.CHANGE:
      if (action.submissionId) {
        return {
          deals: {
            [`${action.submissionId}`]: action.value,
          },
        };
      }
      return {
        deals: {},
      };
    default:
      return state;
  }
}
