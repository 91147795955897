import { combineReducers } from 'redux';

import * as t from 'actions/actionTypes';

const remapActivity = (state = {}, action) => {
  switch (action.type) {
    case t.ACTIVITY + t.REMAP + t.REQUEST:
      return {
        message: 'Remap activity in progress',
        status: 'warn',
      };
    case t.ACTIVITY + t.REMAP + t.SUCCESS:
      return {
        message: 'Remap activity successful',
        status: 'success',
      };
    case t.ACTIVITY + t.REMAP + t.ERROR:
      return {
        message: 'Remap activity failed, please try again',
        status: 'error',
      };
    default:
      return {
        message: null,
        status: null,
      };
  }
};

export default combineReducers({
  remapActivity,
});
