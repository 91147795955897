import './assets/css/site.css';
import 'react-loading-skeleton/dist/skeleton.css';
import React from 'react';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom';
import 'whatwg-fetch';

import 'react-loading-skeleton/dist/skeleton.css';
import 'assets/css/boostup-icons.css';
import App from 'components/app';
import { importPolyfills } from 'utils/polyfills';

// Initialize Sentry
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
});

async function initApp() {
  await importPolyfills();
  ReactDOM.render(<App />, document.getElementById('root'));
}

if (process.env.NODE_ENV !== 'test') {
  initApp();
}
