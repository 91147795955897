import classNames from 'classnames';
import { css } from 'emotion';
import React, { forwardRef } from 'react';

const placeholderStyle = css`
  text-align: center;
  display: flex;
  justify-content: center;
  alignitems: center;
  box-sizing: border-box;
  background: var(--bu-gray-300);
  border-radius: 8px;
`;

interface InteractiveGridPlaceholderProps {
  key: string;
  style?: React.CSSProperties;
  className?: string;
  onMouseDown?: React.MouseEventHandler<HTMLDivElement>;
  onMouseUp?: React.MouseEventHandler<HTMLDivElement>;
  onTouchEnd?: React.TouchEventHandler<HTMLDivElement>;
}

const InteractiveGridPlaceholder = forwardRef(
  (
    {
      key,
      style,
      className,
      onMouseDown,
      onMouseUp,
      onTouchEnd,
    }: InteractiveGridPlaceholderProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    return (
      <div
        key={key}
        style={{
          ...style,
        }}
        className={classNames(placeholderStyle, className)}
        ref={ref}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchEnd={onTouchEnd}
      />
    );
  }
);

export default InteractiveGridPlaceholder;
