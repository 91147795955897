import * as R from 'ramda';

import { AnalyticsTracker } from 'components/common/analyticsUtils';
import { isAdmin } from 'selectors';

export const analyticsMiddleware = (store) => (next) => (action) => {
  // process the analytics meta data of the action if present
  const analyticsData = R.path(['meta', 'analytics'])(action);

  if (R.not(R.isNil(analyticsData))) {
    const { category, action, label } = analyticsData;
    const isAdminUser = isAdmin(store.getState());

    AnalyticsTracker.event(
      { isAdmin: isAdminUser },
      {
        category,
        action,
        label,
      }
    );
  }
  return next(action);
};
