import { css } from 'emotion';

export const modal_text_input = css`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 16px;
`;

export const header = css`
  border-bottom: 1px solid var(--bu-gray-400);
  display: flex;
`;

export const title_container = css`
  height: 42px;
  padding: 8px 0px 8px 16px;
  display: flex;
`;

export const title_paragraph = css`
  font-size: 16px;
  letter-spacing: 0;
  line-height: 30px;
  font-weight: 600;
`;

export const close_icon_button = css`
  height: 42px;
  margin-left: auto;
  padding: 12px;
  border-left: 1px solid var(--bu-gray-400);
  cursor: pointer;

  i {
    font-size: 17px !important;
  }
`;

export const name_span = css`
  color: var(--bu-gray-600);

  span {
    color: var(--bu-red-400);
    font-weight: 600;
    padding-left: 0.5em;
  }
`;

export const input_error = css`
  border-color: var(--bu-red-400) !important;

  input {
    background-color: var(--bu-red-100);
    color: var(--bu-red-600);
    box-shadow: none !important;
  }
`;

export const buttons_container = css`
  padding-top: 32px;
`;

export const cancel_button = css`
  margin-left: 16px;
`;
