import React from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import BuPopup from 'components/UI/BuPopup';
import {
  action_buttons_container,
  action_button,
  action_icon,
} from 'components/UI/OpenFiltersPanel/CustomViewsDropdown/CustomViewItem/ViewActionButtons/styles';
import { popup } from 'components/UI/OpenFiltersPanel/styles';

type Props = {
  id: string | undefined;
  name: string;
  onEditName: () => void;
  onRemoveView: (id: string, name: string) => void;
};

const ViewActionButtons: React.FC<Props> = ({
  id,
  name,
  onEditName,
  onRemoveView,
}) => {
  const handleEditName = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onEditName();
  };

  const handleRemoveView = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onRemoveView(id as string, name);
  };

  return (
    <div className={action_buttons_container}>
      <BuButton
        icon
        secondary
        size={BuControlSize.SMALL}
        className={action_button}
        onClick={handleEditName}
      >
        <BuPopup
          trigger={(ref) => (
            <div ref={ref}>
              {
                <BuIcon
                  name={BoostUpIcons.Pencil}
                  color="var(--bu-gray-700)"
                  className={action_icon}
                />
              }
            </div>
          )}
        >
          <div className={popup}>Edit View name</div>
        </BuPopup>
      </BuButton>
      <BuButton
        icon
        secondary
        size={BuControlSize.SMALL}
        className={action_button}
        onClick={handleRemoveView}
      >
        <BuPopup
          trigger={(ref) => (
            <div ref={ref}>
              {
                <BuIcon
                  name={BoostUpIcons.Trash}
                  color="var(--bu-gray-800)"
                  className={action_icon}
                />
              }
            </div>
          )}
        >
          <div className={popup}>Delete View</div>
        </BuPopup>
      </BuButton>
    </div>
  );
};

export default ViewActionButtons;
