import styled from '@emotion/styled';

export const metricHighlighted = styled.span({
  display: 'inline-block',
  padding: '2px 8px',
  borderRadius: '12px',
  fontFamily: 'var(--bu-font-medium)',
  border: 0,
  margin: '2px 0',
  fontSize: '12px',
});

export const symbolElement = styled.span({
  padding: '0 3px',
});
