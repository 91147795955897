import TopicsDiscussedlastCallRow from './LastCallRow';
import { IProps, IState } from './types';
import classNames from 'classnames';
import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';

const R = require('ramda');

class TopicsDiscussedlastCall extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      rowsCount: 10,
      page: 1,
      sortField: 'mentions',
      sortOrder: 'desc',
    };

    this.handleSort = this.handleSort.bind(this);
  }

  handleSort(sortField: string, sortOrder: string) {
    this.setState({ sortField, sortOrder });
  }

  render() {
    const { lastCall } = this.props;

    if (!lastCall) return null;

    const topicInsightsData = lastCall.topic_insights;
    if (!topicInsightsData) return null;
    const { rowsCount, page, sortField, sortOrder } = this.state;
    const topicsForRendering = R.compose(
      R.dropLast(
        topicInsightsData.length - (page === 1 ? 5 : page * rowsCount)
      ),
      R.sortWith([
        sortOrder === 'asc'
          ? R.ascend(R.prop(sortField))
          : R.descend(R.prop(sortField)),
      ])
    )(topicInsightsData);

    const sortByNameUpClasses = classNames({
      'header-icon--rotate': true,
      active: sortField === 'text' && sortOrder === 'asc',
    });

    const sortByNameDownClasses = classNames({
      active: sortField === 'text' && sortOrder === 'desc',
    });

    const sortByMentionsUpClasses = classNames({
      'header-icon--rotate': true,
      active: sortField === 'mentions' && sortOrder === 'asc',
    });

    const sortByMentionsDownClasses = classNames({
      active: sortField === 'mentions' && sortOrder === 'desc',
    });

    return (
      <div className="deal-body-table">
        <div className="deal-body-table--header">
          <div className="deal-body-table--header_column one">
            TOPIC
            <a className="deal-body-table--header-btn">
              <Icon
                className={sortByNameUpClasses}
                name="caret up"
                onClick={() => this.handleSort('text', 'asc')}
              />
              <Icon
                name="caret down"
                className={sortByNameDownClasses}
                onClick={() => this.handleSort('text', 'desc')}
              />
            </a>
          </div>
          <div className="deal-body-table--header_column equal">
            # OF MENTIONS
            <a className="deal-body-table--header-btn">
              <Icon
                className={sortByMentionsUpClasses}
                name="caret up"
                onClick={() => this.handleSort('mentions', 'asc')}
              />
              <Icon
                name="caret down"
                className={sortByMentionsDownClasses}
                onClick={() => this.handleSort('mentions', 'desc')}
              />
            </a>
          </div>
          {/* <div className='deal-body-table--header_column two'>
            LAST MENTIONED
          </div> */}
          <div className="deal-body-table--header_column equal">
            IN CONVERSATION WITH
          </div>
          <div className="deal-body-table--header_column three">
            ASSOCIATED KEYWORDS
          </div>
        </div>
        {topicsForRendering.map((el: Topics.Insight) => (
          <TopicsDiscussedlastCallRow row={el} key={el.text} />
        ))}
        {rowsCount * page < topicInsightsData.length && (
          <div
            className="table-body-btn no--margin"
            onClick={() => this.setState({ page: page + 1 })}
          >
            <button className="table-body-viewRest">
              {`+ ${topicInsightsData.length - rowsCount * page}`} More
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default TopicsDiscussedlastCall;
