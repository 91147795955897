import classNames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';

import BuGroupButton from 'components/UI/BuGroupButton';
import LastCall from 'components/UI/TopicsDiscussedTabs/LastCall';
import Table from 'components/UI/TopicsDiscussedTabs/Table';
import OptionsSelector from 'components/UI/TopicsDiscussedTabs/OptionsSelector';
import * as s from 'components/UI/TopicsDiscussedTabs/styles';
import { IProps } from 'components/UI/TopicsDiscussedTabs/types';
import { useBoundary } from 'components/hooks/useBoundary';

const timeInterval = [
  { text: 'Last 7 days', value: 'L7D' },
  { text: 'Last 14 days', value: 'L14D' },
  { text: 'Last 30 days', value: 'L30D' },
  { text: 'Last 3 months', value: 'L3M' },
  { text: 'Last 6 months', value: 'L6M' },
  { text: 'All Time', value: 'ALT' },
];

function TabsWrapper(props: IProps) {
  const { fetch, topics, loading, lastCall, position, entityId } = props;

  const isFirstLoad = useBoundary(props.position);
  const [timespanState, setTimespanState] = useState(props.timespan);

  useEffect(() => {
    if (!isFirstLoad) {
      fetch(timespanState);
    }
  }, [isFirstLoad, timespanState]);

  const buildOptionGroupData = (isLastCallPassed: boolean) => {
    const options = [
      {
        id: 'TopicsDiscussed',
        text: `Topics Discussed (${get(topics, 'length', 0)})`,
      },
    ];
    if (isLastCallPassed) {
      options.push({
        id: 'TopicsDiscussedLastCall',
        text: `Last Call (${
          lastCall !== undefined && get(lastCall, 'topic_insights', []).length
        })`,
      });
    }
    return options;
  };

  const isLastCallPassed: boolean =
    !isNil(lastCall) && !isEmpty(lastCall) && !isNil(lastCall.topic_insights);
  const [tabsState, setTabsState] = useState('TopicsDiscussed');

  const isTopicsShown: boolean = tabsState === 'TopicsDiscussed';
  const isLastCallShown: boolean = tabsState === 'TopicsDiscussedLastCall';

  const topicDiscussedClasses = classNames(s.btn_tab, {
    active: isTopicsShown,
  });

  const topicsDiscussedInLastCall = classNames(s.btn_tab, {
    active: isLastCallShown,
  });

  return (
    <>
      <div className={s.filters_container}>
        <div className={classNames(s.section_header, 'bu-font-heading')}>
          Topics
        </div>
        <div className={s.tabs}>
          <BuGroupButton
            options={buildOptionGroupData(isLastCallPassed)}
            selectedOption={tabsState}
            onSelect={(value: string) => {
              setTabsState(value);
            }}
            useRevampStyle
          />
        </div>
        <div className={s.filters}>
          <OptionsSelector
            value={timespanState}
            onChange={setTimespanState}
            options={timeInterval}
          />
        </div>
      </div>
      {tabsState === 'TopicsDiscussed' && (
        <Table
          loading={loading}
          data={topics || []}
          timeSpan={timespanState}
          entityId={entityId}
        />
      )}
      {tabsState === 'TopicsDiscussedLastCall' && lastCall && (
        <LastCall lastCall={lastCall} />
      )}
    </>
  );
}

export default TabsWrapper;
