import styled from '@emotion/styled';

export const FilterColumnDropdownContainer = styled.div({
  flexGrow: 1,
  marginRight: '12px',
  '>.select': {
    width: '100%',
  },
});

export const RemoveIconContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '32px',
  height: '32px',
  flexShrink: 0,
  cursor: 'pointer',
});
