import { actions } from '../../../actions';
import { connect, MapStateToPropsParam } from 'react-redux';

import * as dealsActions from 'actions/dealsActions';
import ProspectEngagement from 'components/UI/ProspectEngagement/ProspectEngagement';
import { IOwnProps, IStateProps } from 'components/UI/ProspectEngagement/types';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

const mapStateToProps: MapStateToPropsParam<
  IStateProps,
  IOwnProps,
  IReduxState
> = (state, { dealId, accountId }) => ({
  calls: selectors.getEngagementCalls(
    state,
    dealId || accountId
  ) as IStateProps['calls'],
  events: selectors.getEngagementEvents(
    state,
    dealId || accountId
  ) as IStateProps['events'],
  repEmails: selectors.getRepEngagementEmails(
    state,
    dealId || accountId
  ) as IStateProps['repEmails'],
  nextSteps: selectors.getCustomerNextSteps(
    state,
    dealId || accountId
  ) as IStateProps['nextSteps'],
  customerEmails: selectors.getCustomerEngagementEmails(
    state,
    dealId || accountId
  ) as IStateProps['customerEmails'],
  sfdcActivities: selectors.getSFDCActivityGroups(
    state,
    dealId || accountId
  ) as IStateProps['sfdcActivities'],
  isEngagementCached: selectors.isEngagementCached(
    state,
    dealId || accountId
  ) as IStateProps['isEngagementCached'],
  stageHistory: selectors.getEngagementStageHistory(
    state,
    dealId || accountId
  ) as IStateProps['stageHistory'],
  thenaRequests: selectors.getEngagementAccountThenaRequests(
    state,
    dealId || accountId
  ) as IStateProps['thenaRequests'],
  frozenTime: selectors.getFrozenTime(state),
  thenaRequestsEnabled:
    selectors.getCompanySettings(state)?.thena_requests_enabled,
});

const mapDispatchToProps = (
  dispatch: any,
  { dealId, accountId }: IOwnProps
) => ({
  fetch: (timespan: string, onlyImportant: boolean) =>
    dispatch(
      dealsActions.fetchEngagement(timespan, onlyImportant, dealId, accountId)
    ),
  persistModalParams: (params: any) =>
    dispatch(actions.ui.modal.persist(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProspectEngagement);
