import Row from './Row';
import { getStyles } from './styles';
import { IProps } from './types';
import classNames from 'classnames';
import { css } from 'emotion';
import React from 'react';

const ColumnPopupTable = ({ rows, columns, isPopup }: IProps) => {
  const s = getStyles(isPopup);

  const columns_row = (numberColumns: number) => css`
    grid-template-columns: repeat(${numberColumns}, 208px);
  `;

  return (
    <div className={s.container}>
      <table className={s.table}>
        <thead className={s.table_header}>
          <tr className={columns_row(columns.length)}>
            {columns.map((c) => (
              <th
                key={c.field}
                className={classNames(
                  'configurable-table',
                  s.table_header_column
                )}
              >
                {c.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={s.table_body}>
          {rows.map((r, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Row row={r} columns={columns} styles={s} key={index} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ColumnPopupTable;
