import classNames from 'classnames';
import * as R from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dimmer, Dropdown, Loader } from 'semantic-ui-react';

import {
  clearBusinessTypesConfig,
  setBusinessTypesSettings,
} from 'actions/settingsActions';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import BuInput from 'components/UI/BuInput';
import BuRadio from 'components/UI/BuRadio';
import BuSelect from 'components/UI/BuSelect';
import BuToggle from 'components/UI/BuToggle';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import { getNewBusinessType } from 'components/modals/BusinessTypeModal/BusinessTypeDetails/helpers';
import * as s from 'components/modals/BusinessTypeModal/BusinessTypeDetails/styles';
import { getOptions } from 'components/settings/DefineBusinessType/helpers';
import {
  IBusinessType,
  IBusinessTypesSettings,
  ISplitTypesSettings,
} from 'components/settings/DefineBusinessType/types';
import { FiltersFieldSettings } from 'components/settings/ForecastSettings/AddForecastSubmission/FiltersFieldSettings';
import {
  extractSelectedOptionsReduce,
  formatFilterProps,
  generateAvailableFilters,
  getNewFiltersList,
} from 'components/settings/ForecastSettings/AddForecastSubmission/helper';
import {
  FilterList,
  FilterProps,
} from 'components/settings/ForecastSettings/AddForecastSubmission/types';
import * as selectors from 'selectors';

const BusinessTypeDetails = ({
  isEditMode = false,
  businessTypeForEditName,
  onClose = () => {},
}: {
  isEditMode?: boolean;
  businessTypeForEditName?: string;
  onClose?(): void;
}) => {
  const dispatch = useDispatch();

  const ALL = 'ALL';
  const ENABLE_PIPELINE_SUMMARY_BASE_FILTERS = [
    'opportunity_stages',
    'forecast_category_names',
  ];

  const businessTypesSettings = useSelector(
    selectors.getBusinessTypesSettings
  ) as IBusinessTypesSettings;
  const splitTypesSettings = useSelector(
    selectors.getSplitTypesSettings
  ) as ISplitTypesSettings;
  const filterList = useSelector(selectors.getSubmissionFilters);
  const isLoading = useSelector(selectors.getBusinessTypesLoading) as boolean;
  const isOppSplitEnabled = useSelector(selectors.isOppSplitEnabled);
  const { enable_pipeline_summary, enable_opportunity_forced_splits } =
    useSelector((state) => selectors.getFeatureFlags(state));

  const businessTypesNames = useMemo(
    () =>
      businessTypesSettings?.businessTypes
        ? Object.values(businessTypesSettings.businessTypes).map(
            (bt) => bt.name
          )
        : [],
    [JSON.stringify(businessTypesSettings)]
  );
  const initialBusinessType: IBusinessType = useMemo(
    () =>
      isEditMode && businessTypeForEditName
        ? businessTypesSettings.businessTypes?.[businessTypeForEditName]
        : getNewBusinessType(businessTypesSettings),
    [isEditMode, businessTypeForEditName, businessTypesSettings]
  );

  const [businessType, setBusinessType] = useState<IBusinessType>(
    initialBusinessType || {}
  );
  const [isBtEnabled, setBtEnabled] = useState<boolean>(
    initialBusinessType?.enabled || true
  );
  const [isValid, setValid] = useState<boolean>(isEditMode);
  const [isTouched, setTouched] = useState<boolean>(false);
  const [isValidName, setValidName] = useState<boolean>(true);
  const [pipelineSummaryFilters, setPipelineSummaryFilters] = useState<
    Record<string, any>
  >({});
  const [pipelineSummaryFiltersList, setPipelineSummaryFiltersList] = useState<
    FilterProps[]
  >([]);
  const [
    hasOwnerChangeAfterSelectAmountSplitType,
    setHasOwnerChangeAfterSelectAmountSplitType,
  ] = useState(false);

  useEffect(() => {
    setBusinessType(
      businessTypeForEditName
        ? businessTypesSettings.businessTypes?.[businessTypeForEditName]
        : initialBusinessType
    );
  }, [JSON.stringify(businessTypesSettings), enable_opportunity_forced_splits]);

  useEffect(() => {
    if (
      filterList.loaded &&
      businessTypesSettings &&
      businessTypesSettings.availableValues
    ) {
      const oppStageValueToLabel: Record<string, string> =
        filterList.opportunity_stages.fields.reduce(
          (acc: object, val: string[]) => ({ ...acc, [val[0]]: val[1] }),
          {}
        );
      const forceFilterValues = {
        opportunity_stages:
          businessTypesSettings.availableValues.active_opportunity_stages.map(
            (label: string) => oppStageValueToLabel[label]
          ),
        forecast_category_names:
          businessTypesSettings.availableValues.active_forecast_category_names,
      };
      const defaultFilters = !R.isEmpty(businessType.pipeline_summary_filters)
        ? businessType.pipeline_summary_filters
        : forceFilterValues;

      const pipelineSummaryFiltersKeys = Object.keys(defaultFilters || {});
      if (pipelineSummaryFiltersKeys.length > 0) {
        setPipelineSummaryFilters(
          generateAvailableFilters(
            pipelineSummaryFiltersKeys,
            filterList,
            defaultFilters,
            false,
            forceFilterValues
          )
        );
      }
    }
  }, [
    businessTypesSettings,
    businessType.pipeline_summary_filters,
    filterList,
  ]);

  const handleRemoveFilterOptions = (name: string) => {
    const newPipelineSummaryFiltersList = pipelineSummaryFiltersList.filter(
      (item) => item.name !== name
    );
    const newPipelineSummaryFilters = formatFilterProps(
      newPipelineSummaryFiltersList
    );
    setPipelineSummaryFilters(newPipelineSummaryFilters);
    setPipelineSummaryFiltersList(newPipelineSummaryFiltersList);
    setTouched(true);
  };

  const handleResetFiltersToDefaults = () => {
    const newPipelineSummaryFiltersList = pipelineSummaryFiltersList.filter(
      (item) => ENABLE_PIPELINE_SUMMARY_BASE_FILTERS.includes(item.name)
    );
    setPipelineSummaryFiltersList(newPipelineSummaryFiltersList);
    setPipelineSummaryFilters((prev) => {
      const resetedFilters = R.pick(ENABLE_PIPELINE_SUMMARY_BASE_FILTERS, prev);

      ENABLE_PIPELINE_SUMMARY_BASE_FILTERS.forEach((filterName) => {
        resetedFilters[filterName] = {
          ...resetedFilters[filterName],
          values: prev[filterName].values.map((value: any) => ({
            ...value,
            checked: true,
          })),
        };
      });

      return resetedFilters;
    });
    setTouched(true);
  };

  useEffect(() => {
    if (pipelineSummaryFilters) {
      setPipelineSummaryFiltersList(getNewFiltersList(pipelineSummaryFilters));
    }
  }, [pipelineSummaryFilters]);

  const handlePersistPipelineSummaryFilter = (
    name: string,
    filters: Record<string, any>
  ) => {
    setPipelineSummaryFilters((prev) => ({
      ...prev,
      [name]: filters,
    }));

    setTouched(true);
  };

  const handleFilterDropdownChange = (filters: Record<string, any>) => {
    setPipelineSummaryFilters((prev) => ({ ...prev, ...filters }));
    setTouched(true);
  };

  const amountSplitTypes = useMemo(() => {
    if (enable_opportunity_forced_splits) {
      return Object.values(splitTypesSettings.splitTypes).reduce(
        (splits, acc) => [...acc, ...splits],
        []
      );
    } else {
      return (
        splitTypesSettings.splitTypes[businessType.amount_field || ''] || []
      );
    }
  }, [
    businessType.amount_field,
    splitTypesSettings,
    enable_opportunity_forced_splits,
  ]);

  const validate = (businessType: IBusinessType): void => {
    const fieldsToValidate = businessType.enable_opportunity_splits
      ? businessType
      : R.omit(['enable_opportunity_splits', 'split_type'], businessType);
    const hasEmptyField = Object.values(fieldsToValidate).some(
      (x) => x === null || x === '' || (x instanceof Array && !x.length)
    );
    setValid(!hasEmptyField);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newName = e.target.value;
    const newBusinessType = {
      ...businessType,
      name: newName,
    };
    setBusinessType(newBusinessType);
    setTouched(true);

    const isDuplicate = businessTypesNames.includes(newName);
    setValidName(!isDuplicate);
    isDuplicate ? setValid(false) : validate(newBusinessType);
  };

  const updateBusinessTypeObject = (newBusinessType: IBusinessType) => {
    setBusinessType(newBusinessType);
    setTouched(true);
    validate(newBusinessType);
  };

  const handleMultiDropdownValueChange = (
    values: string[],
    field: keyof IBusinessType
  ): void => {
    const currentValues = (businessType[field] as string[]) || [ALL];
    const difference = values.filter((x) => !currentValues.includes(x));
    const hasAll = difference.includes(ALL);

    const newBusinessType = {
      ...businessType,
      [field]: hasAll ? difference : values.filter((x) => x !== ALL),
    };

    updateBusinessTypeObject(newBusinessType);
  };

  const handleChangeDropdownValue = (values: string[], field: string): void => {
    const newBusinessType = {
      ...businessType,
      [field]: values[0],
    };

    updateBusinessTypeObject(newBusinessType);
  };

  const handleChangeToggleValue = (value: boolean, field: string): void => {
    const newBusinessType = {
      ...businessType,
      [field]: value,
    };

    updateBusinessTypeObject(newBusinessType);
  };

  const handleChangeEnableOppSplits = (enableOppSplit: boolean): void => {
    const newBusinessType = {
      ...businessType,
      enable_opportunity_splits: enableOppSplit,
      splits_default_enabled: enableOppSplit, // Also true whenever we enable Opp Splits
      split_type: '',
    };

    updateBusinessTypeObject(newBusinessType);
  };

  const handleChangeDefaultToggle = (splitsDefaultEnabled: boolean): void => {
    const newBusinessType = {
      ...businessType,
      splits_default_enabled: splitsDefaultEnabled,
    };

    updateBusinessTypeObject(newBusinessType);
  };

  const handleChangeAmountField = (values: string[]): void => {
    const newBusinessType = {
      ...businessType,
      amount_field: values[0],
      enable_opportunity_splits: false,
      split_type: '',
    };

    updateBusinessTypeObject(newBusinessType);
  };

  const handleCancel = (): void => {
    onClose();
  };

  const handleSave = (): void => {
    const newBusinessType = {
      ...businessType,
      enabled: isBtEnabled,
      split_type: isOppSplitEnabled
        ? businessType.split_type || null
        : undefined,
      user_roles: businessType.user_roles || [],
      enable_opportunity_splits: isOppSplitEnabled
        ? businessType.enable_opportunity_splits
        : undefined,
      pipeline_summary_filters: pipelineSummaryFiltersList.reduce(
        extractSelectedOptionsReduce,
        {}
      ),
    };
    dispatch(setBusinessTypesSettings(newBusinessType));
    dispatch(clearBusinessTypesConfig());
    onClose();
  };

  const handleEnableBusinessType = (): void => {
    setBtEnabled(!isBtEnabled);
    setTouched(true);
  };

  return (
    <div className={s.container}>
      <div className={s.header}>
        {isEditMode ? 'Edit' : 'Add'} Business Type
      </div>
      <div className={s.mainContent}>
        <Dimmer.Dimmable dimmed={isLoading}>
          <div className={s.detailsContainer}>
            <div className="item">
              <div className="lab">Primary CRM Field:</div>
              <div className="control">
                {businessTypesSettings.primaryField}
              </div>
            </div>
            <div className="item">
              <div className="lab">Name:</div>
              <div className="control">
                <BuInput
                  className={classNames(
                    s.textInput,
                    !isValidName && s.inputError
                  )}
                  placeholder="Set Name"
                  type="text"
                  value={(businessType?.name as string) || ''}
                  onChange={handleNameChange}
                />
                {!isValidName && (
                  <span className={s.errorInfo}>
                    <BuIcon name={BoostUpIcons.DangerCircle} /> Name already
                    exists!
                  </span>
                )}
              </div>
            </div>
            <div className="item">
              <div className="lab lab-multipicker">Values:</div>
              <div className="control">
                <Dropdown
                  fluid
                  multiple
                  placeholder="Set Values"
                  selection
                  options={getOptions(
                    businessTypesSettings?.availableValues?.values
                  )}
                  value={businessType?.values || []}
                  onChange={(_, { value }) => {
                    handleMultiDropdownValueChange(value as string[], 'values');
                  }}
                />
              </div>
            </div>
            <div className="item">
              <div className="lab">Amount Field:</div>
              <div className="control">
                <BuSelect
                  isLargePlaceholder
                  placeholder="Set Amount"
                  className={s.fullWidth}
                  defaults={[businessType?.amount_field || '']}
                  options={getOptions(
                    businessTypesSettings?.availableValues?.amount_field
                  )}
                  onChange={(values: string[]) => {
                    handleChangeAmountField(values);
                    setHasOwnerChangeAfterSelectAmountSplitType(false);
                  }}
                  secondary
                  fullWidth
                />
              </div>
            </div>
            <div className="item">
              <div className="lab">Close Date Field:</div>
              <div className="control">
                <BuSelect
                  isLargePlaceholder
                  placeholder="Set Close Date"
                  className={s.fullWidth}
                  defaults={[businessType?.close_date_field || '']}
                  options={getOptions(
                    businessTypesSettings?.availableValues?.close_date_field
                  )}
                  onChange={(values: string[]) => {
                    handleChangeDropdownValue(values, 'close_date_field');
                  }}
                  secondary
                  fullWidth
                />
              </div>
            </div>
            <div className="item">
              <div className="lab">Deal Owner Field:</div>
              <div className="control">
                <BuSelect
                  isLargePlaceholder
                  placeholder="Set Deal Owner"
                  className={s.fullWidth}
                  defaults={[
                    businessType?.owner_field ||
                      businessTypesSettings?.availableValues
                        ?.owner_field?.[0] ||
                      '',
                  ]}
                  options={getOptions(
                    businessTypesSettings?.availableValues?.owner_field
                  )}
                  onChange={(values: string[]) => {
                    handleChangeDropdownValue(values, 'owner_field');
                    setHasOwnerChangeAfterSelectAmountSplitType(
                      businessType?.enable_opportunity_splits &&
                        !!amountSplitTypes.length
                    );
                  }}
                  secondary
                  fullWidth
                />
              </div>
            </div>
            <div className="item">
              <div className="lab-long lab-multipicker">
                Show these BoostUp Roles in Hierarchy:
              </div>
              <div className="control">
                <Dropdown
                  fluid
                  multiple
                  placeholder="Set Roles"
                  selection
                  options={getOptions(
                    businessTypesSettings?.availableValues?.user_roles
                  )}
                  value={businessType?.user_roles || []}
                  onChange={(_, { value }) => {
                    handleMultiDropdownValueChange(
                      value as string[],
                      'user_roles'
                    );
                  }}
                />
              </div>
            </div>
            <div className="item">
              <div className="lab">Forecast Category Field:</div>
              <div className="control">
                <BuSelect
                  isLargePlaceholder
                  placeholder="Set Forecast Category"
                  className={s.fullWidth}
                  defaults={[
                    businessType?.forecast_category_field ||
                      businessTypesSettings?.availableValues
                        ?.forecast_category_field?.[0] ||
                      '',
                  ]}
                  options={getOptions(
                    businessTypesSettings?.availableValues
                      ?.forecast_category_field
                  )}
                  onChange={(values: string[]) => {
                    handleChangeDropdownValue(
                      values,
                      'forecast_category_field'
                    );
                  }}
                  secondary
                  fullWidth
                />
              </div>
            </div>

            {enable_pipeline_summary && (
              <>
                <div className="item">
                  <div className="lab">Properties:</div>
                  <div className="control">
                    <div>
                      <BuToggle
                        checked={businessType.enable_pipeline_summary}
                        onChange={() => {
                          handleChangeToggleValue(
                            !businessType.enable_pipeline_summary,
                            'enable_pipeline_summary'
                          );
                        }}
                      >
                        <>
                          <label>Enable Pipeline Summary in RollUps</label>

                          <TooltipWrapper
                            tooltip={
                              <div className={s.tooltipContentStyle}>
                                Pipeline Change Summary information when
                                hovering over a user within RollUps
                              </div>
                            }
                            position="bottom center"
                          >
                            <div className={s.infoIconStyleWrapper}>
                              <BuIcon
                                color="var(--bu-gray-700)"
                                name={BoostUpIcons.BadgeInfoOutline}
                                className={s.infoIconStyle}
                              />
                            </div>
                          </TooltipWrapper>
                        </>
                      </BuToggle>
                    </div>

                    {businessType.enable_pipeline_summary && (
                      <>
                        <div className={classNames('info', s.info)}>
                          Define the filters you want to apply to configure your
                          active pipeline for this Business Type. Only active
                          and open Opportunity Stages and Forecast categories
                          are allowed.
                        </div>

                        <div className={s.filterWrapper}>
                          <FiltersFieldSettings
                            filtersList={pipelineSummaryFiltersList}
                            filters={pipelineSummaryFilters}
                            onPersist={handlePersistPipelineSummaryFilter}
                            onRemoveFilterOptions={handleRemoveFilterOptions}
                            availableFilters={
                              businessTypesSettings?.availableValues
                                ?.available_pipeline_summary_filters
                            }
                            onDropdownChange={handleFilterDropdownChange}
                            onResetToDefaults={handleResetFiltersToDefaults}
                            hasAddFiltersButton
                            hasRemoveFilterButton
                            disableRemoveButtonList={
                              ENABLE_PIPELINE_SUMMARY_BASE_FILTERS
                            }
                            dropdownTab="pipeline_summary_options_business_type"
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}

            {isOppSplitEnabled && (
              <>
                <div className="item">
                  <div className="lab">Enable Amount Split</div>
                  <div className="control row">
                    <BuToggle
                      checked={businessType.enable_opportunity_splits}
                      disabled={!amountSplitTypes.length}
                      onChange={() =>
                        handleChangeEnableOppSplits(
                          !businessType.enable_opportunity_splits
                        )
                      }
                    />
                    <div className={s.subText}>
                      {!amountSplitTypes.length &&
                        'Mapped amount field is not configured for splits'}
                      {!!amountSplitTypes.length &&
                        hasOwnerChangeAfterSelectAmountSplitType &&
                        'BT Owner mapping config will override Split Owner data'}
                    </div>
                  </div>
                </div>

                {businessType.enable_opportunity_splits &&
                  !!amountSplitTypes.length && (
                    <div className="item">
                      <div className="lab">Amount Split Type</div>
                      <div className="control">
                        <BuSelect
                          isLargePlaceholder
                          placeholder="Set Amount Split Type"
                          className={s.fullWidth}
                          defaults={[businessType.split_type || '']}
                          options={amountSplitTypes.map(
                            ({ label, split_field_name, _id }) => ({
                              text: `${label} ${
                                enable_opportunity_forced_splits
                                  ? `(${split_field_name})`
                                  : ''
                              }`,
                              key: label,
                              value: _id,
                            })
                          )}
                          onChange={(values: string[]) => {
                            handleChangeDropdownValue(values, 'split_type');
                          }}
                          secondary
                          fullWidth
                        />
                      </div>
                    </div>
                  )}

                {businessType.enable_opportunity_splits && (
                  <div className="item">
                    <div className="lab">Split view default status</div>
                    <div className="row">
                      <div className={s.splitToggles}>
                        <BuRadio
                          value="on"
                          label="On"
                          checked={businessType.splits_default_enabled}
                          onChange={() => handleChangeDefaultToggle(true)}
                        />
                        <BuRadio
                          value="off"
                          label="Off"
                          checked={!businessType.splits_default_enabled}
                          onChange={() => handleChangeDefaultToggle(false)}
                        />
                      </div>
                    </div>
                    <div className={s.smallText}>
                      This will determine the "Splits View" toggle default
                      status in Opportunities and Trends Dashboard
                    </div>
                  </div>
                )}
              </>
            )}

            {!isEditMode && (
              <div className="item">
                <div className="lab">User profile visibility:</div>
                <div className="control">
                  <div className="info">
                    Note: This will be added on all User profiles automatically.
                    You can only change the visibility of it. If you want to
                    keep it enabled for few profiles and disabled it for others
                    then chose Disabled and change it by visiting User Profile
                    settings.
                  </div>
                  <div className="sub-header">
                    How do you want to keep it by default for all User profiles?
                  </div>
                  <div className="radio-container">
                    <BuRadio
                      value="enabled"
                      label="Enable"
                      checked={isBtEnabled}
                      onChange={handleEnableBusinessType}
                    />
                    <div className="label-title">
                      (Users will have it available immediately)
                    </div>
                    <BuRadio
                      value="disabled"
                      label="Disable"
                      checked={!isBtEnabled}
                      onChange={handleEnableBusinessType}
                    />
                    <div className="label-title">
                      (Users will not see it until you enable it)
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <Dimmer active={isLoading} inverted>
            <Loader />
          </Dimmer>
        </Dimmer.Dimmable>
      </div>

      <div className={s.actionButtons}>
        <BuButton
          className={s.wideButtons}
          secondary
          size={BuControlSize.BIG}
          onClick={handleCancel}
        >
          Cancel
        </BuButton>
        <BuButton
          className={s.wideButtons}
          size={BuControlSize.BIG}
          disabled={!isTouched || !isValid || !isValidName}
          onClick={handleSave}
        >
          Save
        </BuButton>
      </div>
    </div>
  );
};

export default BusinessTypeDetails;
