import { isNil } from 'ramda';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import BuSelect from 'components/UI/BuSelect';
import { BoolTypeCondition } from 'components/dashboard/Metrics/Create/Conditions/Condition/BoolTypeCondition/BoolTypeCondition';
import { ColumnSelector } from 'components/dashboard/Metrics/Create/Conditions/Condition/ColumnSelector/ColumnSelector';
import { DateTypeCondition } from 'components/dashboard/Metrics/Create/Conditions/Condition/DateTypeCondition/DateTypeCondition';
import { ForecastPeriodTypeCondition } from 'components/dashboard/Metrics/Create/Conditions/Condition/ForecastPeriodTypeCondition/ForecastPeriodTypeCondition';
import { NumberTypeCondition } from 'components/dashboard/Metrics/Create/Conditions/Condition/NumberTypeCondition/NumberTypeCondition';
import { PickListTypeCondition } from 'components/dashboard/Metrics/Create/Conditions/Condition/PickListTypeCondition/PickListTypeCondition';
import { TargetPeriodTypeCondition } from 'components/dashboard/Metrics/Create/Conditions/Condition/TargetPeriodTypeCondition/TargetPeriodTypeCondition';
import { UserTypeCondition } from 'components/dashboard/Metrics/Create/Conditions/Condition/UserTypeCondition/UserTypeCondition';
import {
  NULL_OPERATOR_OPTIONS,
  NULL_OPERATOR_VALUES,
  NUMBER_OPERATION_DROPDOWN_OPTIONS,
} from 'components/dashboard/Metrics/Create/Conditions/Condition/constants';
import {
  ConditionRow,
  FixedWidthContainer,
  FlexColumn,
  FlexGrowContainer,
} from 'components/dashboard/Metrics/Create/Conditions/Condition/styles';
import {
  BOOL_COLUMN_TYPES,
  DATE_COLUMN_TYPES,
  NUMBER_COLUMN_TYPES,
  PICKLIST_COLUMN_TYPES,
  SINGLE_SELECT_COLUMN_TYPES,
  TEXT_COLUMN_TYPES,
} from 'components/dashboard/Metrics/constants';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import { FlexRow } from 'components/dashboard/Metrics/metrics.styles';
import {
  DataDescriptor,
  BIMetricsFilter,
  BIPicklistOption,
} from 'components/dashboard/Metrics/metrics.types';
import { QueryStatus, fetchApi } from 'utils/network';

type Props = {
  columns: DataDescriptor[];
  condition: Partial<BIMetricsFilter>;
  disabled: boolean;
  forecastPeriod: string;
  targetPeriod: string;
  updateFilter: (newFilter: Partial<BIMetricsFilter>) => void;
  onRemove: () => void;
};

export const Condition: React.FC<Props> = ({
  columns,
  condition,
  disabled = false,
  forecastPeriod,
  targetPeriod,
  updateFilter,
  onRemove,
}) => {
  const { hierarchyAlias } = useContext(RevBISettingsContext);

  const [localFilter, setLocalFilter] =
    useState<Partial<BIMetricsFilter>>(condition);

  const [picklistValues, setPicklistValues] = useState<BIPicklistOption[]>([]);
  const [status, setStatus] = useState<QueryStatus>('notAsked');

  useEffect(() => {
    const { label, name, type } = localFilter.column ?? {};
    if (
      label &&
      name &&
      type &&
      (PICKLIST_COLUMN_TYPES.has(type) || SINGLE_SELECT_COLUMN_TYPES.has(type))
    ) {
      fetchApi<DataDescriptor, BIPicklistOption[]>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/company_settings/get_column_values`,
        queryMethod: 'post',
        queryParams: { label, name, type },
        setData: (result) => {
          if (SINGLE_SELECT_COLUMN_TYPES.has(type ?? '')) {
            // Special case for companies like Redsift which have 'All' business type
            const allIndex = result.findIndex(
              (v) => v.value.toLocaleLowerCase() === 'all'
            );
            if (allIndex !== -1) {
              result[allIndex] = {
                ...result[allIndex],
                value: '-ALL-',
                display_name: '-ALL-',
              };
            }
          }

          setPicklistValues(
            // Filter to remove duplicates which are causing issues
            result.filter(
              (v, i, a) => a.findIndex((v2) => v2.value === v.value) === i
            )
          );
        },
        setError: (error: string | null) => {
          toast.error(`Failed to load condition values: ${error}`);
        },
        setStatus: setStatus,
      });
    }
  }, [localFilter?.column?.name]);

  const getDefaultOperator = (column: DataDescriptor): string => {
    const columnType = column?.type ?? '';

    if (BOOL_COLUMN_TYPES.has(columnType)) {
      return 'eq';
    }

    if (
      PICKLIST_COLUMN_TYPES.has(columnType) ||
      SINGLE_SELECT_COLUMN_TYPES.has(columnType)
    ) {
      return 'in';
    }

    if (NUMBER_COLUMN_TYPES.has(columnType)) {
      return NUMBER_OPERATION_DROPDOWN_OPTIONS[0].value;
    }

    return '';
  };

  const handleColumnSelect = (values: string[]): void => {
    const selectedColumn: string = values[0];
    const column = columns.find((column) => column.name === selectedColumn);
    const operator = column ? getDefaultOperator(column) : '';
    const value = BOOL_COLUMN_TYPES.has(column?.type ?? '') ? true : '';
    setLocalFilter({ column, operator, value });
  };

  const handleOperatorSelect = (operator: string): void => {
    if (!NULL_OPERATOR_VALUES.includes(operator)) {
      // operator is not in nullOperatorValues
      // check if the localFilter.value is 'is_null' or 'is_not_null'
      // if it is, remove the value. otherwise preserve it.
      const value =
        typeof localFilter?.value === 'string' &&
        NULL_OPERATOR_VALUES.includes(localFilter?.value)
          ? ''
          : localFilter?.value;
      setLocalFilter({
        column: localFilter.column,
        operator,
        value,
      });
    } else {
      // operator in nullOperatorValues
      // backend expect value is same as the operator
      setLocalFilter((prev) => ({
        ...prev,
        operator,
        value: operator,
      }));
    }
  };

  const handleSingleSelectValueChange = (values: string[]): void => {
    // Special case for companies like Redsift which have 'All' business type
    setLocalFilter((prev) => ({
      ...prev,
      value: values[0] === '-ALL-' ? ['All'] : values,
    }));
  };

  const handlePicklistChange = (values: string[]): void => {
    if (values.some((el) => el === '__all_active__')) {
      setLocalFilter((prev) => ({
        ...prev,
        value: picklistValues
          .filter(
            (el) =>
              el.is_active &&
              el.value !== '__all__' &&
              el.value !== '__all_active__'
          )
          .map((el) => el.value),
      }));
      return;
    }

    if (values.some((el) => el === '__all__')) {
      setLocalFilter((prev) => ({
        ...prev,
        value: picklistValues
          .filter(
            (el) => el.value !== '__all__' && el.value !== '__all_active__'
          )
          .map((el) => el.value),
      }));
      return;
    }

    setLocalFilter((prev) => ({ ...prev, value: values }));
  };

  const columnType: string = useMemo(
    () =>
      columns.find((column) => column.name === localFilter?.column?.name)
        ?.type || '',
    [localFilter?.column?.name, columns]
  );

  useEffect(() => {
    const areInputsReady =
      localFilter.column &&
      !isNil(localFilter.value) &&
      localFilter.value !== '';
    if (areInputsReady) {
      updateFilter(localFilter);
    }
  }, [localFilter]);

  const isUserFilter = hierarchyAlias.includes(localFilter.column?.name ?? '');
  const isForecastPeriodFilter =
    localFilter.column?.name === 'forecast_submission.forecast_period';
  const isTargetPeriodFilter =
    localFilter.column?.name === 'target.target_date';

  return (
    <FlexColumn>
      <ColumnSelector
        column={localFilter.column}
        filterColumns={columns}
        onChangeColumn={handleColumnSelect}
        onRemove={onRemove}
      />

      {NUMBER_COLUMN_TYPES.has(columnType) && (
        <NumberTypeCondition
          columnType={columnType}
          filter={localFilter}
          setLocalFilter={setLocalFilter}
          onOperatorSelect={handleOperatorSelect}
        />
      )}

      {SINGLE_SELECT_COLUMN_TYPES.has(columnType) && (
        <div className={ConditionRow}>
          <FixedWidthContainer width={20}>is</FixedWidthContainer>
          <FlexGrowContainer data-testing="operator-value">
            <BuSelect
              fullWidth
              isLargePlaceholder
              secondary
              defaults={
                // Special case for companies like Redsift which have 'All' business type
                (localFilter?.value as string[])?.[0] === 'All'
                  ? ['-ALL-']
                  : ((localFilter?.value ?? []) as string[])
              }
              disabled={status !== 'success'}
              options={(picklistValues ?? []).map(
                (value: BIPicklistOption) => ({
                  text: value.display_name,
                  value: value.value,
                })
              )}
              testingLabel="is-values"
              onChange={handleSingleSelectValueChange}
            />
          </FlexGrowContainer>
        </div>
      )}

      {PICKLIST_COLUMN_TYPES.has(columnType) && (
        <>
          {isUserFilter ? (
            <UserTypeCondition
              filter={localFilter}
              onOperatorSelect={handleOperatorSelect}
              onChange={handlePicklistChange}
            />
          ) : (
            <PickListTypeCondition
              filter={localFilter}
              picklistValues={picklistValues}
              onOperatorSelect={handleOperatorSelect}
              onChange={handlePicklistChange}
            />
          )}
        </>
      )}

      {/*
      free text field may not be support for filter
       */}
      {TEXT_COLUMN_TYPES.has(columnType) && (
        <FlexRow>
          <FlexGrowContainer>
            <BuSelect
              fullWidth
              isLargePlaceholder
              secondary
              placeholder=""
              defaults={[condition.operator ? condition.operator : '']}
              options={NULL_OPERATOR_OPTIONS}
              onChange={(values: string[]) => {
                handleOperatorSelect(values[0]);
              }}
            />
          </FlexGrowContainer>
        </FlexRow>
      )}

      {DATE_COLUMN_TYPES.has(columnType) && (
        <>
          {isTargetPeriodFilter ? (
            <TargetPeriodTypeCondition
              selectedValue={localFilter.value as string}
              targetPeriod={targetPeriod}
              onChangeTargetPeriod={(targetPeriod: Partial<BIMetricsFilter>) =>
                setLocalFilter((prev) => ({ ...prev, ...targetPeriod }))
              }
            />
          ) : (
            <>
              {isForecastPeriodFilter ? (
                <ForecastPeriodTypeCondition
                  selectedValue={localFilter.value as string}
                  timePeriod={forecastPeriod}
                  onValueChange={(forecastPeriod: Partial<BIMetricsFilter>) =>
                    setLocalFilter((prev) => ({ ...prev, ...forecastPeriod }))
                  }
                />
              ) : (
                <DateTypeCondition
                  filter={localFilter}
                  onOperatorSelect={handleOperatorSelect}
                  onChangeDateType={(value: Partial<BIMetricsFilter>) =>
                    setLocalFilter((prev) => ({ ...prev, ...value }))
                  }
                />
              )}
            </>
          )}
        </>
      )}

      {BOOL_COLUMN_TYPES.has(columnType) && (
        <BoolTypeCondition
          disabled={disabled}
          localFilter={localFilter}
          setLocalFilter={setLocalFilter}
          onOperatorSelect={handleOperatorSelect}
        />
      )}
    </FlexColumn>
  );
};
