import { getSubmissionsToCopy } from './helper';
import classNames from 'classnames';
import { debounce } from 'lodash';
import moment from 'moment';
import { isNil, difference } from 'ramda';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Loader, Radio } from 'semantic-ui-react';

import { actions } from 'actions';
import { submitForecast } from 'actions/forecastActions';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import failureIcon from 'assets/images/failure.svg';
import successIcon from 'assets/images/success.svg';
import { getCurrencySymbol } from 'common/numbers';
import {
  ForecastsSubmissionsByPeriodToCopyReduxState,
  ForecastSubmissionSettingsDealsItemReduxState,
  ForecastSubmissionSettingsDealsReduxState,
  Persisted,
} from 'common/types/forecastSubmission';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import { IdType } from 'components/UI/common/TypedTable/TypedTable';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import * as styles from 'components/dashboard/Forecast/Dashboard/SubmitForecast/styles';
import { Formatter } from 'components/dashboard/Forecast/Dashboard/SubmitForecast/types';
import * as selectors from 'selectors';
import { getHideViewIncludeExcludeSubmissions } from 'selectors';

type Props = {
  formatter: Formatter;
  included: string[];
  onSubmit: (
    amount: number,
    notes: string,
    fromCopy: boolean,
    includedValue: number,
    includedCount: number,
    dealsCount?: number
  ) => void;
  onIncludedDealsChange: (
    includedDealsIds: IdType[],
    excludedDealsIds: IdType[],
    activesubmissionSettingId: string,
    includeWithReplace?: boolean
  ) => void;
  resetIncludedAndExcludedDeals: (
    activesubmissionSettingId: string,
    deals: ForecastSubmissionSettingsDealsItemReduxState[]
  ) => void;
  openDealsModal: () => void;
  deals: ForecastSubmissionSettingsDealsReduxState;
  submissionSettingId: string;
  includeExcludeSupported: boolean;
  persist: Persisted | null;
  submissionsToCopy: ForecastsSubmissionsByPeriodToCopyReduxState;
  submissionLockingType: string;
  isModalOpenForThisSubmission: boolean;
};

const Form: React.FC<Props> = ({
  formatter,
  included,
  onIncludedDealsChange,
  resetIncludedAndExcludedDeals,
  onSubmit,
  openDealsModal,
  deals,
  submissionSettingId,
  includeExcludeSupported,
  persist,
  submissionsToCopy,
  submissionLockingType,
  isModalOpenForThisSubmission,
}) => {
  const dispatch = useDispatch();

  const companyCurrencyCode = useSelector(selectors.getUserLocalCurrency);
  const hideViewIncludeExcludeSubmissions = useSelector(
    getHideViewIncludeExcludeSubmissions
  );

  const companyCurrencySymbol = useMemo(
    () => getCurrencySymbol(companyCurrencyCode),
    [companyCurrencyCode]
  );

  const mappedSubmissionsToCopy = useMemo(
    () => getSubmissionsToCopy(submissionsToCopy, deals) || [],
    [submissionsToCopy, deals]
  );
  const formatterAmmount = (amount: number | string | undefined) =>
    isFinite(amount as number)
      ? amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      : '';

  const [copyBoard, setCopyBoard] = useState(false);
  const [copyFrom, setCopyFrom] = useState('');
  const [notes, setNotes] = useState(persist?.notes || '');
  const [value, setValue] = useState(persist?.amount || '');
  const [starOverwritingAmount, setStarOverwritingAmount] = useState(
    isModalOpenForThisSubmission
  );
  const [valueFormatted, setValueFormatted] = useState(
    formatterAmmount(persist?.amount)
  );
  const [submissionError, setSubmissionError] = useState({
    status: '',
    message: '',
  });

  // CODE FOR POC ONLY - RE DESIGN SOLUTION
  const [submissionMadeFromCopy, setSubmissionMadeFromCopy] = useState(true);
  const count = deals ? deals.count : 0;

  const includedText = includeExcludeSupported
    ? `${included.length} of ${count}`
    : `${count}`;

  const includedValue = useMemo(
    () =>
      includeExcludeSupported
        ? (deals &&
            deals.deals.reduce<number>(
              (acc, deal) =>
                acc +
                (deal.amount && included.includes(deal._id)
                  ? deal.amount / (deal.exchange_rate || 1)
                  : 0),
              0
            )) ||
          0
        : (deals && deals.total_amount) || 0,
    [includeExcludeSupported, deals, included]
  );

  const persistDebounced = useCallback(
    debounce((value, notes) => {
      dispatch(
        actions.ui.manageForecast.persist({
          settingsId: submissionSettingId,
          amount: +value,
          notes,
        })
      );
    }, 1000),
    [setValue, setNotes]
  );

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueAmount = e.target.value.replace(/[A-Za-z!@#$%^&*,()]/g, '');
    const valueAmountFormatted = formatterAmmount(valueAmount);

    setValue(valueAmount);
    setValueFormatted(valueAmountFormatted);
    persistDebounced(valueAmount, notes);
  };

  const handleNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(e.target.value);
    persistDebounced(value, e.target.value);
  };

  const handleSubmit = () => {
    onSubmit(
      +value,
      notes,
      submissionMadeFromCopy,
      includedValue,
      included.length,
      deals?.count
    );
    dispatch(submitForecast());

    AnalyticsTracker.event(
      {
        tab: 'forecast',
      },
      {
        category: 'Submit Forecast',
        action: 'Submited forecast',
      }
    );
  };

  useEffect(() => {
    if (persist?.status === 'success') {
      setNotes(persist?.notes || '');
      setValue(persist?.amount || '');
      setValueFormatted(formatterAmmount(persist?.amount));
      setStarOverwritingAmount(false);
      resetIncludedAndExcludedDeals(submissionSettingId, deals?.deals || []);
      setSubmissionError({
        status: '',
        message: '',
      });
    }

    if (persist?.status === 'error') {
      if (persist?.message === 'Submission is locked') {
        toast.error(
          'Forecast could not be submitted. You may have been locked. Please refresh the page and retry or contact your administrator',
          {
            position: 'bottom-left',
          }
        );

        return;
      }

      setSubmissionError({
        status: persist?.status,
        message: persist?.message,
      });
    }
  }, [persist?.status]);

  const handleCopy = () => {
    if (copyFrom) {
      const toCopy = mappedSubmissionsToCopy.find(({ id }) => id === copyFrom);

      if (toCopy) {
        const intersectedDeals = toCopy.intersectedDeals;
        const allDealsId = deals?.deals.map((d) => d._id) || [];
        const excludedDeals = difference(allDealsId, intersectedDeals);
        // CODE FOR POC ONLY - RE DESIGN SOLUTION
        setSubmissionMadeFromCopy(false);
        onIncludedDealsChange(
          intersectedDeals,
          excludedDeals,
          submissionSettingId,
          true
        );
      }
    }

    setCopyBoard(false);
    setCopyFrom('');
  };

  const startOverwritingAndtrigger = (panel: string) => {
    setStarOverwritingAmount(true);
    if (panel === 'modal') {
      openDealsModal();
    } else if (panel === 'copyBoard') {
      setCopyBoard(!copyBoard);
    }
  };

  useEffect(() => {
    const { deals: dealsList = [] } = deals || {};
    if (starOverwritingAmount && dealsList.length) {
      let amount: number | string = '';
      const amountList = dealsList.reduce((acc, currentDeal) => {
        const { amount, _id, exchange_rate } = currentDeal;
        acc.push(
          amount && included.includes(_id) ? amount / (exchange_rate || 1) : 0
        );

        return acc;
      }, [] as number[]);
      const hasOneAmount = amountList.find((v) => v >= 0) ?? undefined;

      if (!isNil(hasOneAmount)) {
        amount = amountList
          .reduce((acc, amount) => acc + (amount ?? 0), 0)
          .toFixed(0);
      }

      setValue(amount);
      setValueFormatted(formatterAmmount(amount));
    }
  }, [included]);

  return (
    <div className={styles.form}>
      <span className={styles.formSubTitle}>
        Amount ({companyCurrencySymbol})
      </span>

      <input
        className={styles.forecastHistoryInput}
        id="dashboard-header-input"
        onChange={handleValueChange}
        placeholder="Enter forecast"
        type="text"
        value={value === '-' ? '-' : valueFormatted}
        data-testing="txt_field"
      />

      {!!count &&
        (!hideViewIncludeExcludeSubmissions || includeExcludeSupported) && (
          <div className={styles.dealsIncluded}>
            <div>
              Deals included:{' '}
              <span>
                {includedText}
                {` (${formatter(includedValue)})`}
              </span>
            </div>

            <div className="copy-submit-wrapper">
              {!!mappedSubmissionsToCopy.length && (
                <div className={classNames('btn', styles.copyButton)}>
                  <button
                    onClick={() => startOverwritingAndtrigger('copyBoard')}
                  >
                    <BuIcon name={BoostUpIcons.CopyIcon} />
                  </button>
                </div>
              )}
              <div
                className={styles.copyBoard}
                style={{ display: copyBoard ? 'block' : 'none' }}
              >
                <div className="cpb_header">Latest from the period</div>

                <div className="cpd_body">
                  {!!mappedSubmissionsToCopy.length && (
                    <>
                      <div className={styles.cpdBodyColumn}>
                        <Radio
                          checked={copyFrom === ''}
                          className={styles.radioButtonOptionStyle}
                          label="None"
                          onChange={() => setCopyFrom('')}
                          value=""
                        />
                      </div>

                      {mappedSubmissionsToCopy.map(
                        ({
                          id,
                          displayName,
                          intersectedDealsCount,
                          totalDeals,
                          intersectedDealsAmount,
                          createdAt,
                        }) => (
                          <div className={styles.cpdBodyColumn} key={id}>
                            <Radio
                              checked={copyFrom === id}
                              className={styles.radioButtonOptionStyle}
                              label={displayName}
                              onChange={() => setCopyFrom(id)}
                              value={id}
                            />

                            <span className="cpd_copied-count">{`last submitted ${moment(
                              createdAt
                            ).format('MMM, D, HH:mm')}`}</span>

                            <span className="cpd_copied-count">
                              {`${intersectedDealsCount} of ${totalDeals} (${formatter(
                                intersectedDealsAmount
                              )})`}
                            </span>
                          </div>
                        )
                      )}
                    </>
                  )}
                </div>

                <div className="cpb_button_container">
                  <BuButton
                    disabled={!mappedSubmissionsToCopy.length}
                    onClick={handleCopy}
                    size={BuControlSize.REGULAR}
                  >
                    Apply
                  </BuButton>
                  <BuButton
                    secondary
                    size={BuControlSize.REGULAR}
                    onClick={() => setCopyBoard(!copyBoard)}
                  >
                    Cancel
                  </BuButton>
                </div>
              </div>

              <div
                onClick={() => startOverwritingAndtrigger('modal')}
                className="btn"
              >
                {includeExcludeSupported ? 'Edit' : 'View'}
              </div>
            </div>
          </div>
        )}

      <span className={styles.formSubTitle}>Note (Optional)</span>

      <textarea
        onChange={handleNotesChange}
        value={notes}
        placeholder="Type..."
        className={styles.forecastHistoryTextarea}
        rows={5}
        cols={35}
        name="text"
        data-testing="txt_field"
      />

      <div className={styles.btnLine}>
        <TooltipWrapper
          tooltip={
            !!submissionLockingType && (
              <div style={{ width: '200px', textAlign: 'center' }}>
                Submission is locked because a manager above you has already
                submitted this week
              </div>
            )
          }
          position="top center"
        >
          <div>
            <BuButton
              disabled={!value || !!submissionLockingType}
              size={BuControlSize.REGULAR}
              onClick={handleSubmit}
              className={styles.submitForecastButton}
            >
              {!!submissionLockingType && (
                <BuIcon name={BoostUpIcons.Lock} color="var(--bu-white)" />
              )}
              Submit
            </BuButton>
          </div>
        </TooltipWrapper>

        {persist?.status === 'loading' && <Loader active inline size="small" />}

        {persist?.status === 'success' && (
          <>
            <img src={successIcon} alt="" />
            <span className={styles.success}>Submitted successfully!</span>
          </>
        )}

        {submissionError.status === 'error' && (
          <>
            <img src={failureIcon} alt="" />
            <span className={styles.failure}>{submissionError.message}</span>
          </>
        )}
      </div>
    </div>
  );
};

export default Form;
