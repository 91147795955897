import React from 'react';
import { Loader } from 'semantic-ui-react';

import { IProps } from 'components/UI/GlobalAppLoader/types';

const blackListRoutes = [
  '/login',
  '/login/it-admin-oauth',
  '/register',
  '/admin/dashboard',
  '/sign-up',
  '/sign-up/salesforce',
  '/error',
];

export const GlobalAppLoader: React.FC<IProps> = ({
  location,
  isAppInitiated,
}) => {
  if (!isAppInitiated && !blackListRoutes.includes(location.pathname)) {
    return <Loader active />;
  }

  return null;
};

export default GlobalAppLoader;
