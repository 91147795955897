import { connect, MapDispatchToPropsParam } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import * as dealsActions from 'actions/dealsActions';
import PipelineDashboard from 'components/dashboard/Pipeline/PipelineDashboard/PipelineDashboard';
import {
  IDispatchProps,
  IOwnProps,
  IStateProps,
} from 'components/dashboard/Pipeline/PipelineDashboard/types';
import { IReduxState } from 'reducers/types';
import {
  getFiltersForAPI,
  getSelectedBusinessType,
  isBusinessTypesConfigReady,
} from 'selectors';

const mapStateToProps = (
  state: IReduxState,
  ownProps: RouteComponentProps
): IStateProps => ({
  selectedBusinessType: getSelectedBusinessType(state, 'pipeline_dashboard'),
  filters: getFiltersForAPI(state, 'pipeline_dashboard'),
  tabPartition:
    ownProps.location.pathname === '/dashboard/pipeline'
      ? 'dashboards'
      : 'pipeline',
  isRequiredDataReady: isBusinessTypesConfigReady(state),
});

const mapDispatchToProps: MapDispatchToPropsParam<IDispatchProps, IOwnProps> = {
  toggleFilters: dealsActions.toogleFiltersBlock,
};

export default connect(mapStateToProps, mapDispatchToProps)(PipelineDashboard);
