import * as t from 'actions/actionTypes';
import * as accountsSagas from 'sagas/accounts';
import * as genericSagas from 'sagas/generic';

export const getAccountData = (id) => ({
  type: t.ACCOUNT + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/deals/account/${id}/`,
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.ACCOUNT + t.GET + t.SUCCESS,
    payload,
    id,
  }),
  error: (e) => ({
    type: t.ACCOUNT + t.GET + t.ERROR,
    error: e,
    id,
  }),
  loading: () => ({
    type: t.ACCOUNT + t.GET + t.LOADING,
    id,
  }),
});

export const getAccounts = (data) => ({
  type: t.ACCOUNTS + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/accounts/`,
  saga: accountsSagas.createCached,
  data: {
    ...data,
    page_size: (data && data.page_size) || 30,
    page_number: (data && data.page_number) || 0,
  },
  success: (payload) => ({
    type: t.ACCOUNTS + t.GET + t.SUCCESS,
    payload,
    data,
  }),
  error: (e) => ({
    type: t.ACCOUNTS + t.GET + t.ERROR,
    error: e,
    data,
  }),
  loading: () => ({
    type: t.ACCOUNTS + t.GET + t.LOADING,
    data,
  }),
});

export const updateAccount = (id, changes) => ({
  type: t.ACCOUNT + t.UPDATE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/accounts/update/${id}/`,
  saga: genericSagas.create,
  data: changes,
  success: (payload) => ({
    type: t.ACCOUNT + t.UPDATE + t.SUCCESS,
    payload: {
      id,
      changes,
      data: {
        ...payload,
      },
    },
  }),
  error: (e) => ({
    type: t.ACCOUNT + t.UPDATE + t.ERROR,
    error: e?.response?.data?.error?.message?.[0] || e,
    payload: {
      id,
      changes,
    },
  }),
  loading: () => ({
    type: t.ACCOUNT + t.UPDATE + t.LOADING,
    payload: {
      id,
      changes,
    },
  }),
});
