import { connect } from 'react-redux';

import DealsModal from 'components/modals/DealsModal/DealsModal';
import { PersistedProps, StateProps } from 'components/modals/DealsModal/types';
import { IReduxState } from 'reducers/types';
import { getModalProps, getModalOptions } from 'selectors';

const DEALS_INCLUDED_PATH = '/deals/included/:submissionId';

const mapStateToProps = (state: IReduxState) => ({
  modalProps: getModalProps<PersistedProps>(state, DEALS_INCLUDED_PATH),
  modalOptions: getModalOptions<PersistedProps>(state, DEALS_INCLUDED_PATH),
  isIncludedDeals: true,
});

export default connect<StateProps, {}, {}, IReduxState>(mapStateToProps)(
  DealsModal
);
