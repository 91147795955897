import styled from '@emotion/styled';
import classNames from 'classnames';
import { css } from 'emotion';
import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { fontSubtitle } from 'assets/css/common';
import { formatMoney } from 'common/numbers';
import BuIcon from 'components/UI/BuIcon';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import { IReduxState } from 'reducers/types';
import {
  getBusinessTypes,
  getUserLocalCurrency,
  getFeatureFlags,
  getForecastActiveBusinessType,
  getSelectedBusinessType,
  isOppSplitEnabled,
  getBusinessTypesList,
  OVERALL_BUSINESS_TYPE,
} from 'selectors';
import { history } from 'store/configureStore';
import { fetchApi, QueryStatus } from 'utils/network';

interface SplitFieldResp {
  original_amount: number;
  splits: {
    highlighted_split: boolean;
    split_amount: number;
    split_percentage: number;
    split_type_name?: string;
    user: { name: string };
  }[];
}
interface SplitDataResp extends Record<string, SplitFieldResp> {}

interface Props {
  readonly dealId: string;
  readonly fieldName: string;
  readonly crmField?: string;
  readonly rowUserName: string;
}

const IconContainer = styled.div({
  position: 'relative',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '> i': {
    fontSize: '14px',
  },
});

const TooltipContainer = styled.div({
  minWidth: '470px',
  minHeight: '100px',
  position: 'relative',
});

const TooltipBodyContainer = styled.div({
  padding: '4px 0',
});

const HeaderDiv = styled.div({
  borderBottom: `1px solid var(--bu-gray-400)`,
  paddingBottom: '10px',
});

const HeaderTitle = styled.div({
  color: `var(--bu-gray-900)`,
  fontWeight: 'bold',
  padding: '0 10px 5px',
  fontSize: '16px',
});

const HeaderSubTitle = styled.div({
  color: `var(--bu-gray-700)`,
  padding: '0 10px',
});

const RowGrid = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridColumnGap: '12px',
  padding: '4px 18px',
  '&.total': {
    fontWeight: 'bold',
    margin: '4px 0px',
    gridTemplateColumns: '1fr 1fr',
  },
  '&.highlight': {
    fontWeight: 'bold',
  },
});

const SplitTypeDiv = styled.div({
  textAlign: 'right',
  alignSelf: 'center',
});

const AmountDiv = styled.div({
  textAlign: 'right',
  alignSelf: 'center',
});

const PercentageDiv = styled.div({
  color: `var(--bu-gray-700)`,
  display: 'inline',
  fontSize: '12px',
});

const iconClassName = css``;

type ApiResponse = { data: SplitDataResp };

export const orderSplitsByUserName = (
  arr: SplitFieldResp['splits'],
  name: string
) => {
  if (!name) return arr;

  const sortedArr = [...arr];
  sortedArr.sort((a, b) => {
    if (a.user.name === name && b.user.name !== name) {
      return -1;
    } else if (a.user.name !== name && b.user.name === name) {
      return 1;
    } else {
      return 0;
    }
  });
  return sortedArr;
};

const TooltipContent: React.FC<Props> = ({
  dealId,
  fieldName,
  crmField = '',
  rowUserName = '',
}) => {
  const { deals_overall_view_enabled, enable_opportunity_forced_splits } =
    useSelector(getFeatureFlags);
  const [
    businessTypes,
    forecastActiveBusinessType,
    trendsSelectedBusinessType,
    currency,
    businessTypesList,
  ] = useSelector((state: IReduxState) => {
    return [
      getBusinessTypes(state, deals_overall_view_enabled),
      getForecastActiveBusinessType(state),
      getSelectedBusinessType(state, 'forecast_analytics_change'),
      getUserLocalCurrency(state),
      getBusinessTypesList(state),
    ];
  });
  const isTrendsPage =
    history.location.pathname.includes('forecast/analytics') ||
    history.location.pathname.includes('dashboard/trends');
  const activeBusinessType = isTrendsPage
    ? trendsSelectedBusinessType
    : forecastActiveBusinessType;
  const selectedBusinessType = activeBusinessType || businessTypes[0]; // selectedBusinessType can be undefined if no business type is being setup
  const businessTypesForFilters =
    selectedBusinessType === OVERALL_BUSINESS_TYPE
      ? ''
      : selectedBusinessType ?? '';
  const [status, setStatus] = useState<QueryStatus>('notAsked');
  const [splitData, setSplitData] = useState<SplitFieldResp>();
  const [errorMessage, setErrorMessage] = useState<string | null>('');
  const apiPoint = `${
    process.env.REACT_APP_BACKEND_URL
  }/api/data/deals/${dealId}/splits?business_type=${encodeURIComponent(
    businessTypesForFilters
  )}`;
  const setData = ({ data }: ApiResponse) => {
    setSplitData({
      ...data[fieldName],
      splits: orderSplitsByUserName(data[fieldName].splits, rowUserName),
    });
  };

  const businessTypeConfig = useMemo(
    () =>
      businessTypesList.find(
        (businessType: any) => businessType.name === activeBusinessType
      ),
    [activeBusinessType, businessTypesList]
  );

  const splitBTConfig = businessTypesList.find(
    (businessType: any) =>
      businessType.amount_field ===
      businessTypeConfig?.split_type?.split_field_name
  );

  const amountFieldName = businessTypeConfig?.amount_field || '';
  const mustShowSplitType = amountFieldName === fieldName;

  const isHighlightRow = (split: any) =>
    split.highlighted_split && split.user.name === rowUserName;

  const splitDetail = (
    <TooltipContainer>
      <HeaderDiv>
        <HeaderTitle>Opportunity Split Amount Detail</HeaderTitle>
        {!!(
          businessTypeConfig?.enable_opportunity_splits && mustShowSplitType
        ) && (
          <HeaderSubTitle>
            {activeBusinessType} BT split type:{' '}
            {businessTypeConfig?.split_type.label || ''}{' '}
            {enable_opportunity_forced_splits &&
            businessTypeConfig?.split_type?.split_field_name
              ? `(${businessTypeConfig?.split_type?.split_field_name})`
              : ''}
          </HeaderSubTitle>
        )}
      </HeaderDiv>

      <TooltipBodyContainer>
        <RowGrid className="total">
          <div>Amount</div>
          <AmountDiv>
            {formatMoney(currency, splitData?.original_amount ?? 0, 0)}
          </AmountDiv>
        </RowGrid>
        {splitData?.splits.map((split, idx) => {
          const name = split?.user?.name;
          return (
            <RowGrid
              key={`${name}_${idx}`}
              className={classNames({
                highlight: isHighlightRow(split),
                [fontSubtitle]: isHighlightRow(split),
              })}
            >
              <div>{name}</div>
              <SplitTypeDiv>{split?.split_type_name ?? ''}</SplitTypeDiv>
              <AmountDiv>
                {formatMoney(currency, split.split_amount ?? 0, 0)}
                <PercentageDiv>{` (${split.split_percentage}%)`}</PercentageDiv>
              </AmountDiv>
            </RowGrid>
          );
        })}
      </TooltipBodyContainer>
    </TooltipContainer>
  );

  useEffect(() => {
    fetchApi<string, ApiResponse>({
      queryMethod: 'get',
      setData,
      setError: setErrorMessage,
      setStatus,
      url: apiPoint,
    });
  }, []);

  return (
    <TooltipContainer>
      {status === 'loading' && <Loader active />}
      {status === 'error' && <div>{errorMessage}</div>}
      {status === 'success' && splitDetail}
    </TooltipContainer>
  );
};

export const OppSplitTooltip: React.FC<Props> = ({
  dealId,
  fieldName,
  crmField,
  rowUserName,
  children,
}) => {
  const isOppSplitFlagEnabled = useSelector(isOppSplitEnabled);
  return isOppSplitFlagEnabled ? (
    <TooltipWrapper
      tooltip={
        <TooltipContent
          dealId={dealId}
          fieldName={fieldName}
          crmField={crmField}
          rowUserName={rowUserName}
        />
      }
      noPadding={true}
    >
      {children ?? (
        <IconContainer
          className={'opp-split-icon'}
          data-bu-test="opp-split-icon"
        >
          <BuIcon
            name={BoostUpIcons.Splits}
            color="var(--bu-primary-500)"
            className={iconClassName}
          />
        </IconContainer>
      )}
    </TooltipWrapper>
  ) : null;
};
