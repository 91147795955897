import classNames from 'classnames';
import React from 'react';
import { timeSpanOptions } from 'sharedLogicMainAppAndRevBi/timeSpanOptions';

import { fontSubtitle, fontDefaultDropdown } from 'assets/css/common';
import * as s from 'components/dashboard/Metrics/common/Calendar/TimeFilter/Tabs/styles';
import { NormalizedData } from 'components/dashboard/Metrics/common/Calendar/TimeFilter/Tabs/types';
import { FiltersConfig } from 'components/dashboard/Metrics/common/Calendar/types';

const titlePredefined: Record<string, { title: string; order: number }> = {
  SAME_PERIOD: {
    title: 'Same period',
    order: 1,
  },
  PREVIOUS_PERIOD: {
    title: 'Previous period',
    order: 2,
  },
  NEXT_PERIOD: {
    title: 'Next period',
    order: 3,
  },
};

const getKeys = (config: FiltersConfig): (Filters.DateElementType | '')[] => {
  const keys: Filters.DateElementType[] = [];

  config.relativeValues?.forEach((item) => {
    if (item.type && !keys.includes(item.type as any)) {
      keys.push(item.type as any);
    }
  });

  return keys.length ? keys : [''];
};

const getNormalizedData = (
  config: FiltersConfig | undefined
): NormalizedData => {
  if (config) {
    return getKeys(config)
      .map((title) => ({
        title,
        fields: (config.relativeValues || [])
          .filter((item) => item.type === (title || undefined))
          .map(({ label, value }) => ({
            value,
            text: label,
          })),
      }))
      .sort(
        (l, r) =>
          titlePredefined[l.title].order - titlePredefined[r.title].order
      );
  }

  return [
    {
      title: 'SAME_PERIOD',
      fields: timeSpanOptions.timeSpanPresent,
    },
    {
      title: 'PREVIOUS_PERIOD',
      fields: timeSpanOptions.timeSpanPast,
    },
    {
      title: 'NEXT_PERIOD',
      fields: timeSpanOptions.timeSpanFuture,
    },
  ];
};

type Props = {
  config?: FiltersConfig;
  value: string[] | string;
  onChange: (value: string) => void;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
};

export const RelativeTab: React.FC<Props> = ({
  config,
  value,
  onChange,
  onClose,
}) => {
  const data = getNormalizedData(config || undefined).filter(
    (item) => !!item.fields.length
  );

  const isMultiple = data.length > 1;

  return (
    <>
      <span
        style={{
          padding: '16px 16px 0px 16px',
        }}
      >
        The option will be applied to each{' '}
        <span className={s.bold_text}>{config?.timeInterval}</span> in the{' '}
        <span className={s.bold_text}>{config?.timePeriod}</span>
      </span>
      <div
        className={classNames(s.specific_time_periods, {
          [s.specific_time_periods_single]: !isMultiple,
        })}
      >
        {data.map(({ title, fields }) => (
          <div
            key={title}
            className={classNames({
              [s.specific_time_periods_item]: isMultiple,
            })}
          >
            {title && (
              <p className={s.specific_time_period_title}>
                {titlePredefined[title].title}
              </p>
            )}

            {fields.map((el) => (
              <div
                key={el.value}
                onClick={() => {
                  onChange(el.value);
                  onClose(false);
                }}
                className={classNames(s.specific_time_period_item, {
                  selected:
                    value && Array.isArray(value)
                      ? value.includes(el.value)
                      : value === el.value,
                  [fontSubtitle]: el.boldText,
                  [fontDefaultDropdown]: !el.boldText,
                })}
              >
                {el.text}
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};
