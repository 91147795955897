import { useEffect } from 'react';

export const useHideBlockingScreen = () => {
  useEffect(() => {
    const blockingBlock = document.querySelector<HTMLElement>(
      'div#widthLimitation'
    );

    if (blockingBlock) {
      blockingBlock.style.display = 'none';
    }
  }, []);
};
