import classNames from 'classnames';
import React from 'react';
import { Radio } from 'semantic-ui-react';

import {
  panelRadioGroup,
  panelRadioGroupItem,
} from 'components/UI/common/Filter/styles';
import {
  ICheckboxItemNode,
  IRadioGroupForFilters,
} from 'components/UI/common/Filter/types';

const RadioGroupForFilters: React.FC<IRadioGroupForFilters> = ({
  checked,
  nodes,
  onCheck,
}) => {
  const [checkedVal] = checked;

  return (
    <div className={panelRadioGroup}>
      {nodes.map((node) => {
        const { label, special, value } = node as ICheckboxItemNode;

        return (
          <Radio
            checked={checkedVal === value}
            className={classNames(panelRadioGroupItem, { special })}
            key={value}
            label={label}
            name="radioGroup"
            onChange={() => onCheck([value], node)}
            value={value}
          />
        );
      })}
    </div>
  );
};

export default RadioGroupForFilters;
