import React, { Component, Fragment } from 'react';

import {
  IProps,
  IState,
} from 'components/UI/TopicsDiscussedTabs/LastCall/LastCallRow/types';
import { history } from 'store/configureStore';

const R = require('ramda');
class TopicsDiscussedLastCallRow extends Component<IProps, IState> {
  state = {
    isKeywordsCollapsed: true,
  };

  render() {
    const { row } = this.props;
    const { isKeywordsCollapsed } = this.state;

    const keywordsForRendering = isKeywordsCollapsed
      ? R.compose(
          R.map((el: string) => (
            <span className="table-body-item-keywords" key={el}>
              {el}
            </span>
          )),
          R.dropLast(row.keywords.length - 2)
        )(row.keywords)
      : R.map(
          (el: string) => (
            <span className="table-body-item-keywords" key={el}>
              {el}
            </span>
          ),
          row.keywords
        );

    return (
      <ul className="deal-body-table--body" key={row.text}>
        <li className="deal-body-table--body-column one">
          <span
            onClick={() =>
              history.push(
                `/transcript/${row.last_mentioned.id}?topic=${row.text}`
              )
            }
            className="callTranscript__table-body-item-name"
          >
            {row.text}
          </span>
        </li>
        <li className="deal-body-table--body-column equal">
          <span className="table-body-item-indicator-title">
            {row.mentions} mentions
          </span>
        </li>
        <li className="deal-body-table--body-column equal">
          {row.conversation_with &&
            row.conversation_with.map((el, i) => (
              <Fragment key={i}>
                {el.name || el.email}
                {el.title && <span className="text--blue">({el.title})</span>}
                {i !== row.conversation_with.length - 1 && ', '}
              </Fragment>
            ))}
        </li>
        <li className="deal-body-table--body-column three">
          {keywordsForRendering}
          {row.keywords.length > 2 &&
            (isKeywordsCollapsed ? (
              <button
                className="table-body-item-keywords-btn"
                onClick={() => this.setState({ isKeywordsCollapsed: false })}
              >
                {`+ ${row.keywords.length - 2}`}
              </button>
            ) : (
              <button
                className="table-body-item-keywords-btn"
                onClick={() => this.setState({ isKeywordsCollapsed: true })}
              >
                Collapse
              </button>
            ))}
        </li>
      </ul>
    );
  }
}

export default TopicsDiscussedLastCallRow;
