import * as R from 'ramda';
import Table from 'components/UI/TableConfig/Table';
import {
  IRow,
  onChangeCallback,
} from 'components/UI/common/TypedTable/TypedTable';
import { useManageRowStatuses } from 'components/hooks/useManageRowStatuses';
import { css } from 'emotion';
import { useSelector, useDispatch } from 'react-redux';
import { getDraftTableRows, getShowOrHideColumns } from 'selectors';
import {
  UseManageRevBiDealsModalParams,
  getDownloadButtonProps,
  useManageRevBiDealsModal,
} from './RevBiDealsModal.helper';

import { isAxiosError } from 'axios';
import { TABLE_ID } from 'common/constants';
import { DownloadButtonProps } from 'components/UI/DownloadButton/types';
import { useRevBiDrilldownModalWithScheme } from 'components/dashboard/Metrics/Widget/hooks/useRevBiDrilldownModal';
import { usePageSize } from 'components/hooks/usePageSize';
import { useManageTableConfigurationColumns } from 'components/hooks/useSettingsForTable';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { updateDraftTableData } from 'actions/cacheActions';
import { getModalId } from 'components/modals/helper';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import { mapChangesSinceDataForDeals } from 'common/forecastSubmission';
import { getStartDateForChangeInterval } from 'common/dates';

const modalHeight = css`
  height: 80vh;
`;

const RevBiDealsModal = () => {
  const dispatch = useDispatch();
  const { getProps, markShouldReFetchOnClose } =
    useRevBiDrilldownModalWithScheme('/revbi-deals-modal');

  const {
    ids,
    skip_business_validation,
    business_type_name,
    title,
    dashboardId,
    widgetId,
    changeInterval,
  } = getProps();

  const [dealsPageSize] = usePageSize('RevbiOpportunityTable');

  const modalId = getModalId(
    TABLE_ID.REVBI_DEALS_MODAL,
    title,
    dashboardId,
    widgetId
  );

  const [userUpdatedField, setUserUpdatedField] = useState<boolean>();
  const showOrHideColumns = useSelector(getShowOrHideColumns);
  const draftData: IRow[] = useSelector((state) =>
    getDraftTableRows(modalId, state)
  );

  const {
    rowStatuses,
    setLoadingRowField,
    setSuccessRowField,
    setErrorRowField,
  } = useManageRowStatuses();

  const { parseColumns, sortColumns } = useManageTableConfigurationColumns();

  const context = useContext(RevBISettingsContext);

  const changeIntervalValue = changeInterval
    ? context.changeIntervalMapToCore[changeInterval]
    : undefined;

  const deltaParams = changeIntervalValue
    ? {
        change_interval: [changeIntervalValue],
        is_forecast: true,
      }
    : {};

  const params: UseManageRevBiDealsModalParams = {
    page_size: dealsPageSize,
    only_included_deals: false,
    skip_business_validation,
    ids,
    business_type_name,
    ...deltaParams,
  };

  const {
    deals,
    dealsColumns,
    isLoading,
    dealsCount,
    changesSinceBasedDate,
    updateDeal,
    pageNumber,
    handlePaginationChange,
    sortOrder,
    handleSort,
    totalRow,
    invalidateData,
  } = useManageRevBiDealsModal(params, {
    doNotInvalidateOnSettled: true,
  });

  const columns =
    dealsColumns &&
    parseColumns({
      tableConfigurationColumns: dealsColumns,
      hiddenColumns: [],
      oppSplitViewToggleEnabled: false,
      onSpecialClick: () => {},
    });

  const sortedColumns = columns && sortColumns(columns);

  const rows: IRow[] =
    deals?.map((d) => ({
      id: d._id,
      ...d,
    })) ?? [];

  const rowsWithChangeDate =
    changeIntervalValue && changesSinceBasedDate
      ? mapChangesSinceDataForDeals(
          rows,
          changesSinceBasedDate,
          changeIntervalValue
        )
      : rows;

  const handleChange: onChangeCallback = async (column, row, newValue) => {
    const dealId = row.id.toString();
    const fieldToUpdate = column.field;
    const updateOperation = {
      [fieldToUpdate]: newValue,
    };

    setLoadingRowField(dealId, fieldToUpdate);

    try {
      await updateDeal({
        id: dealId,
        editedFields: updateOperation,
        origin: 'revbi',
      });

      toast.success('Deal updated successfully');
      setUserUpdatedField(true);
      setSuccessRowField(dealId, fieldToUpdate);
      markShouldReFetchOnClose();
    } catch (e) {
      const errorMessage =
        isAxiosError(e) && e.response?.data?.error?.message?.[0];
      toast.error(errorMessage || 'Failed to update deal');
      setErrorRowField(dealId, fieldToUpdate);
    }
  };

  const downloadButton: DownloadButtonProps = getDownloadButtonProps(params);

  const onRefreshClick = () => {
    setUserUpdatedField(false);
    invalidateData();
  };

  const handleDraftChange: onChangeCallback = (column, row, newValue) => {
    const draftItem = draftData?.find((item) => item.id === row.id) || {
      id: row.id,
    };
    const draftListWithoutItem =
      draftData?.filter((item) => item.id !== row.id) || [];

    dispatch(
      updateDraftTableData(modalId, [
        ...draftListWithoutItem,
        R.assocPath(column.field.split('.'), newValue, draftItem),
      ])
    );
  };

  // If you modify the layout remember to leave the data-testing attribute for automation
  return (
    <div className={modalHeight} data-testing="deals-modal">
      <Table
        tableId={TABLE_ID.REVBI_DEALS_MODAL}
        pinnableColumns
        title={title}
        totalRow={totalRow}
        totalCount={dealsCount}
        columns={sortedColumns || []}
        data={rowsWithChangeDate}
        loading={isLoading}
        showOrHideColumns={showOrHideColumns}
        onRefresh={onRefreshClick}
        hasRefreshButton={userUpdatedField}
        onChange={handleChange}
        statuses={rowStatuses}
        rowsPerPage={dealsPageSize}
        currentPage={pageNumber}
        onPaginationChange={handlePaginationChange}
        onSort={handleSort}
        sortOrder={sortOrder}
        downloadButton={downloadButton}
        showTotalAmount={false}
        dataType="deals"
        showColumnsVisibilityToggle
        hidePaginationEnd
        hideSearch
        fullscreen
        isModal
        onDraftChange={handleDraftChange}
        draftData={draftData}
      />
    </div>
  );
};

export default RevBiDealsModal;
