import React from 'react';
import classNames from 'classnames';

import * as styles from './styles';
import SummaryInsightHeader from '../SummaryInsightHeader';
import { CallInsightsResponse, CallSummarySentiment } from '../../types';

type Props = {
  accountName?: string;
  accountId?: string;
  opportunityName?: string;
  accountExecutive?: string;
  opportunityId?: string;
  summary: CallInsightsResponse['call_summary_data'];
  hideOpportunityAndAE?: boolean;
  hideSentiment?: boolean;
};

const SummaryTab: React.FC<Props> = ({
  accountName,
  accountId,
  opportunityName,
  accountExecutive,
  opportunityId,
  summary,
  hideOpportunityAndAE = false,
  hideSentiment = false,
}) => {
  const isSentimentNotAvailable = summary?.sentiment.sentiment === 'NA';

  return (
    <div className={styles.tabContentWrapper}>
      <div className={styles.summaryTabWrapper}>
        {!hideOpportunityAndAE && (
          <SummaryInsightHeader
            accountName={accountName}
            accountId={accountId}
            opportunityName={opportunityName}
            accountExecutive={accountExecutive}
            opportunityId={opportunityId}
          />
        )}

        {!hideSentiment && !!summary?.sentiment && (
          <div
            className={classNames(
              'sentiment-section',
              summary.sentiment.sentiment
            )}
          >
            <div className="sentiment-label">SENTIMENT:</div>
            <div className="sentiment-circle">
              {/* TODO: Will have this in the future */}
              {/* {!isSentimentNotAvailable && (
                <div className="sentiment-circle-base">
                  <SentimentCircle
                    percentage={sentiment.confidence}
                    color={
                      SENTIMENT_STATUS_COLOR[
                        sentiment.sentiment as CallInsightsResponse['sentiment']['sentiment']
                      ]
                    }
                  />
                </div>
              )} */}

              <span>
                {!isSentimentNotAvailable
                  ? summary.sentiment.sentiment
                  : 'Not available'}
              </span>
            </div>
          </div>
        )}

        {!!summary && !!summary.sentiment ? (
          <div
            className={classNames('summary-card', {
              'margin-top': !summary?.sentiment,
            })}
          >
            {!!summary?.sentiment &&
              !!summary.sentiment.description &&
              !isSentimentNotAvailable && (
                <div className="summary-card-section">
                  <div className="summary-card-title">Sentiment</div>
                  <div className="summary-card-description">
                    {summary.sentiment.description}
                  </div>
                </div>
              )}

            {!!summary && !!summary.summary && (
              <div className="summary-card-section">
                <div className="summary-card-title">Call Summary</div>
                <div className="summary-card-description">
                  {summary.summary}
                </div>
              </div>
            )}

            {!!summary?.next_steps && !!summary.next_steps.length && (
              <div className="summary-card-section">
                <div className="summary-card-title">Next Steps</div>

                <div className="summary-card-description">
                  <ul>
                    {summary.next_steps.map((nextStep) => (
                      <li key={nextStep}>{nextStep}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className={styles.notAvilableContent}>No summary available</div>
        )}
      </div>
    </div>
  );
};

export default SummaryTab;
