import { css } from 'emotion';

export const totalCardHalfSpace = css`
  flex: 1 1 min(50%, 200px);
`;

export const totalCardAThirdOfSpace = css`
  flex: 1 1 min(33%, 200px);
`;

export const totalItemContainer = css`
  width: 100%;
`;

export const totalItemPopup = css`
  &.ui.popup {
    max-width: 480px;
  }
`;

export const containerTileWithoutValue = css`
  display: flex;
  align-items: center;
  padding: 8px 8px 8px 0;
`;

export const singleTitleStyle = css`
  color: var(--bu-gray-700);
  font-size: 14px;
  margin: 0px;
`;

export const pivotedTitleStyle = css`
  color: var(--bu-gray-700);
  font-size: 12px;
  margin: 0px;
`;

export const multipleTotalsTitleStyle = css`
  color: var(--bu-gray-700);
  font-size: 12px;
  margin: 0px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
  max-width: 100%;
`;

export const singleTotalValueContainer = css`
  display: flex;
  flex-direction: column;

  width: 100%;
  align-items: center;
`;

export const multipleTotalValueContainer = css`
  display: flex;
  flex-direction: column;

  width: 100%;
  margin: 4px 8px;
  gap: 4px;
`;

export const multipleWithChartSelectionTotalValueContainer = css`
  display: flex;
  flex-direction: column;

  width: 90%;
  margin: 4px 8px;
`;

export const metricNameParagraph = css`
  padding-left: 8px;
  font-size: 16px;
`;
