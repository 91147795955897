import {
  QueryStatus,
  UseQueryResult,
  useQueries,
  useQueryClient,
} from '@tanstack/react-query';
import {
  GetWidgetResponse,
  getWidgetData,
  WidgetType,
  WidgetAction,
  getWidgetDelta,
  GetDeltasResponse,
} from 'api/RevBiWidget';
import { useEffect, useMemo, useRef, useState } from 'react';

import {
  BATCH_WIDGET,
  HierarchicalWidget,
  buildWidgetTreeFromBatchFetching,
  getMetricConfigurations,
  getPayloadForWidgetBatchFetching,
  getPivotConfigurations,
  getWidgetDataThatShouldResetTreesIfChanged,
  remapDeltasToPathDictionary,
} from 'components/dashboard/Metrics/Widget/hooks/useHierarchicalWidgetFetching/useHierarchicalWidgetFetching.helper';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import { VisualizationType } from 'components/dashboard/Metrics/enums';
import {
  BIWidget,
  MetricDisplayInfo,
} from 'components/dashboard/Metrics/metrics.types';
import { HierarchicalWidgetNodeSubtree } from 'components/dashboard/Metrics/Widget/hooks/useHierarchicalWidgetFetching/useHierarchicalWidgetFetching.helper';

interface UseHierarchicalWidgetFetchingParams {
  widgetConfiguration: BIWidget;
  hookEnabled?: boolean;
  urlQuery?: Record<string, string | undefined>;
  widgetType: WidgetType;
  widgetAction: WidgetAction;
}

interface UseHierarchicalWidgetFetchingResult {
  treeWidget: HierarchicalWidget;

  /**
   * Status of the root node
   * That lets us now if we have something to show
   */
  status: QueryStatus;

  /**
   * Used when the data was already loaded
   * But for some reason the same table (data loaded)
   * Has to be refetch and we don't want to loose the
   * whole previous state
   */
  isTableRefetching: boolean;

  /**
   * Adds a subtree to the fetch list
   * Usually called when a node is expanded
   */
  addSubTreeToFetch: (path: string) => void;

  /**
   * Invalidates the queries loaded to force a refresh
   */
  refetchLoadedSubtrees: (byLevel?: string | undefined) => void;
}

const useHierarchicalWidgetFetching = ({
  widgetConfiguration,
  hookEnabled = true,
  urlQuery,
  widgetType,
  widgetAction,
}: UseHierarchicalWidgetFetchingParams): UseHierarchicalWidgetFetchingResult => {
  const queryClient = useQueryClient();

  const pivots = widgetConfiguration.group_by ?? [];

  const [subtreesToFetch, setSubtreesToFetch] = useState<string[]>([]);

  const hasDatePivot = pivots.some((groupBy) => groupBy.type === 'date');

  const isHistorical =
    widgetConfiguration.analysis_type === AnalysisType.HISTORICAL;

  const isTable = widgetConfiguration.properties?.metricToChartType?.some(
    (mc) => mc.chartType == VisualizationType.Table
  );

  const shouldBatchFetch = isTable && !isHistorical;

  const addSubTreeToFetch = (path: string) => {
    // No need to do anything if we are not batch fetching
    if (shouldBatchFetch) {
      setSubtreesToFetch((prev) => {
        if (prev.includes(path)) {
          return prev;
        }
        return [...prev, path];
      });
    }
  };

  const refetchLoadedSubtrees = (byKey: string = '') => {
    if (byKey) {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.includes(byKey) &&
          query.queryKey.includes(`widget-${widgetConfiguration._id}`),
      });
      return;
    }

    queryClient.invalidateQueries({
      queryKey: [BATCH_WIDGET],
    });
  };

  const widgetDependencies = getWidgetDataThatShouldResetTreesIfChanged(
    widgetConfiguration,
    widgetType
  );

  const previousWidgetDependenciesRef = useRef(widgetDependencies);

  const widgetDependenciesChanged =
    JSON.stringify(widgetDependencies) !==
    JSON.stringify(previousWidgetDependenciesRef.current);

  /**
   * This is used to reset the subtrees to fetch when the widget configuration changes
   * So when the widget configuration changes we want to reset the subtrees to fetch
   * As we need to discard all the previous table expanded nodes
   */
  useEffect(() => {
    if (widgetDependenciesChanged) {
      setSubtreesToFetch([]);
      previousWidgetDependenciesRef.current = widgetDependencies;
    }
  }, [widgetDependencies]);

  const payloads = useMemo(() => {
    const dashboardFilters =
      widgetConfiguration.dashboard_filters?.filter(
        (elem) =>
          // Don't send the filters with operator 'all' because those are the filters that we
          // store to show to the user even though it doesn't filter the data
          elem.operator !== 'all' &&
          // Remove closing_in filter as is only supported on deltas
          elem.column.name !== 'shared.__changes_since'
      ) ?? [];

    const widgetConfigurationPayload = {
      ...widgetConfiguration,
      dashboard_filters: dashboardFilters,
    };

    if (shouldBatchFetch) {
      return getPayloadForWidgetBatchFetching(
        subtreesToFetch,
        pivots,
        widgetConfigurationPayload
      );
    }

    return [
      {
        queryKey: [BATCH_WIDGET],
        payload: {
          ...widgetConfigurationPayload,
          group_by: pivots,
        },
      },
    ];
  }, [subtreesToFetch, pivots, widgetConfiguration, shouldBatchFetch]);

  const shouldFetch = hookEnabled && !widgetDependenciesChanged;

  const queries = payloads.map((payload) => ({
    // TODO refine this query key
    // Specially defining what is the payload that is changing
    // and affects its response
    queryKey: [
      ...payload.queryKey,
      pivots,
      widgetDependencies,
      urlQuery,
      `level-${payload.queryKey.length}`,
      `widget-${widgetConfiguration._id}`,
    ],
    queryFn: () =>
      getWidgetData(payload.payload, urlQuery, widgetType, widgetAction),
    enabled: shouldFetch,
    // Prevent data to be cached, stale data is deleted immediately and not used
    // when fetching again
    cacheTime: 1,
  }));

  // For some reason typescript complains that result is implicit any type
  // When its typed by the useQueries return type
  // But adding the type explicitly makes it work
  const queriesResults = useQueries({
    queries: queries,
  }) as UseQueryResult<GetWidgetResponse, unknown>[];

  const keyedResults = queriesResults.map(
    (result: UseQueryResult<GetWidgetResponse, unknown>, index: number) => ({
      // Removing the BATCH_WIDGET keykey
      key: payloads[index].queryKey.slice(1),
      response: result,
    })
  ) as {
    key: string[];
    response: UseQueryResult<GetWidgetResponse, unknown>;
  }[];

  const isPublishedDashboard = widgetAction === 'published';
  const closinginFilter = widgetConfiguration.dashboard_filters?.find(
    (filter) => filter.column.name === 'shared.__changes_since'
  );

  const metricDisplayConfigValues = Object.values(
    widgetConfiguration.advanced_configurations?.display?.metrics ?? {}
  );
  const hasDeltas = metricDisplayConfigValues.some(
    (displayMetricData: MetricDisplayInfo) => displayMetricData.show_deltas
  );

  const deltasQueries = payloads.map((payload, index) => ({
    queryKey: [
      ...payload.queryKey,
      pivots,
      widgetDependencies,
      `level-${payload.queryKey.length}`,
      `widget-${widgetConfiguration._id}`,
      'delta',
      closinginFilter,
    ],
    queryFn: () =>
      getWidgetDelta(
        {
          ...payload.payload,
          dashboard_filters: [
            ...(payload.payload.dashboard_filters ?? []),
            ...(closinginFilter ? [closinginFilter] : []),
          ],
        },
        urlQuery
      ),
    enabled:
      isPublishedDashboard &&
      isTable &&
      hasDeltas &&
      !!closinginFilter &&
      !!closinginFilter.value &&
      // When the corresponding query is success and has data
      keyedResults[index].response.isSuccess &&
      !!keyedResults[index].response.data,
  }));

  const deltasResults = useQueries({
    queries: deltasQueries,
  }) as UseQueryResult<GetDeltasResponse, unknown>[];

  const keyedDeltasResults = deltasResults.map(
    (result: UseQueryResult<GetDeltasResponse, unknown>, index: number) => ({
      key: payloads[index].queryKey.slice(1),
      response: result,
    })
  ) as {
    key: string[];
    response: UseQueryResult<GetDeltasResponse, unknown>;
  }[];

  // TODO solve this other way
  if (!hookEnabled) {
    return {
      treeWidget: {
        tree: [],
        totals: {
          nodePath: '',
          pivot_is_expandible: false,
          pivots: [],
        },
        metricConfigurations: [],
        pivotConfigurations: [],
        errors: {},
        updatedAt: '',
      },
      status: 'loading',
      isTableRefetching: false,
      addSubTreeToFetch,
      refetchLoadedSubtrees: refetchLoadedSubtrees,
    };
  }

  const firstResult = keyedResults[0];
  const isRootRefetching =
    firstResult.response.isFetching && !!firstResult.response.data;

  let loadedSegments = keyedResults
    .filter((result) => {
      const isSuccessWithData =
        result.response.isSuccess && !!result.response.data;

      /**
       * If the root is refetching
       * we are going to consider a segment loaded if it has data and is not loading
       * So if its stale but already loaded we are going to consider it loaded
       * this is to keep the tree state with the data that was already loaded
       * but we provide a flag to let know that the tree is being re fetched
       *
       * If the root is not refetching
       * we are going to consider a loaded segments if it has data and is not fetching
       *
       */
      const isLoadingOrFetching = isRootRefetching
        ? result.response.isLoading
        : result.response.isFetching;

      return isSuccessWithData && !isLoadingOrFetching;
    })
    .map((result) => ({
      parentPath: result.key,
      // added ! as we are filtering out the nulls above
      nodes: result.response.data!.data,
    }));

  let loadingSegments: HierarchicalWidgetNodeSubtree[] = keyedResults
    .filter((result) =>
      /**
       * Similar to above condition but for the loaded segments
       */
      isRootRefetching ? result.response.isLoading : result.response.isFetching
    )
    .map((result) => ({
      parentPath: result.key,
      nodes: [
        {
          isLoadingNode: true,
        },
      ],
    }));

  const someSegmentLoaded = loadedSegments.length > 0;

  if (!someSegmentLoaded) {
    return {
      treeWidget: {
        tree: [],
        totals: {
          nodePath: '',
          pivot_is_expandible: false,
          pivots: [],
        },
        metricConfigurations: [],
        pivotConfigurations: [],
        errors: {},
        updatedAt: '',
      },
      status: 'loading',
      isTableRefetching: false,
      addSubTreeToFetch,
      refetchLoadedSubtrees,
    };
  }

  const firstResultData = firstResult.response.data;

  const metricConfigurations = getMetricConfigurations(
    firstResultData,
    widgetConfiguration
  );

  const updatedAt = firstResultData?.updated_at ?? '';

  const errors = firstResultData?.errors ?? {};

  const pivotConfigurations = getPivotConfigurations(
    firstResultData,
    widgetConfiguration,
    {
      usePivotDataFromResponse: !shouldBatchFetch,
    }
  );

  const status = firstResult.response.status;

  if (hasDatePivot) {
    // When using date as pivot, the data structure is different
    // As when expanding a pivot from the second level on
    // we have to display information for all the dates (the first pivot)
    // so the BE has to send values for all the dates
    // we need to generate a new segment for each date
    // so its correctly appended to the tree on buildWidgetTreeFromBatchFetching

    const dateAndSecondPivotSegment = loadedSegments[0];
    const nonFirstSegments = loadedSegments.slice(1);

    const flattenDateSegments = nonFirstSegments.flatMap((segment) =>
      segment.nodes.map((node) => ({
        // The original parent path (the expanded node) is missing the date pivot
        // we have this information in the node
        parentPath: [node[pivots[0].name], ...segment.parentPath],
        nodes: node.pivots ?? [],
      }))
    );

    loadedSegments = [dateAndSecondPivotSegment, ...flattenDateSegments];

    // the same thing with loading segments, we need to generate a similar structure
    // in order to append the correct loading row in the table.
    // so we will take all loading segments and generate each loading
    // for each period in the pivot 1
    if (loadingSegments.length && dateAndSecondPivotSegment.nodes[0].pivots) {
      const pivotKey = pivots[0].name;
      const pivotsData = dateAndSecondPivotSegment.nodes[0].pivots;
      let newLoadingSegments: HierarchicalWidgetNodeSubtree[] = [];

      loadingSegments.forEach((segment) => {
        const multiSegmentsByPivotDate = pivotsData.map((pivot) => ({
          ...segment,
          parentPath: [pivot[pivotKey], ...segment.parentPath],
        }));

        newLoadingSegments = newLoadingSegments.concat(
          multiSegmentsByPivotDate
        );
      });

      loadingSegments = newLoadingSegments;
    }
  }

  const allSegments = loadedSegments.concat(loadingSegments);
  const treeWidgetDataWithTotals = buildWidgetTreeFromBatchFetching(
    allSegments,
    pivotConfigurations
  );

  if (errors && !treeWidgetDataWithTotals[0] && status === 'success') {
    return {
      treeWidget: {
        tree: [],
        totals: {
          nodePath: '',
          pivot_is_expandible: false,
          pivots: [],
        },
        metricConfigurations,
        pivotConfigurations,
        errors: errors,
        updatedAt: '',
      },
      status,
      isTableRefetching: false,
      addSubTreeToFetch,
      refetchLoadedSubtrees,
    };
  }

  if (!treeWidgetDataWithTotals[0]) {
    return {
      treeWidget: {
        tree: [],
        totals: {
          nodePath: '',
          pivot_is_expandible: false,
          pivots: [],
        },
        metricConfigurations: [],
        pivotConfigurations: [],
        errors: {},
        updatedAt: '',
      },
      status: 'loading',
      isTableRefetching: false,
      addSubTreeToFetch,
      refetchLoadedSubtrees,
    };
  }

  const totals = treeWidgetDataWithTotals[0];
  const treeWidgetData = treeWidgetDataWithTotals[0].pivots ?? [];

  const deltasByPath = remapDeltasToPathDictionary(
    keyedDeltasResults,
    pivotConfigurations
  );

  const changesSinceSelected = Array.isArray(closinginFilter?.value)
    ? (closinginFilter?.value[0] as string)
    : '';

  const treeWidget: HierarchicalWidget = {
    tree: treeWidgetData,
    deltas: {
      deltasByPath,
      fromChangesSince: changesSinceSelected,
    },
    totals,
    metricConfigurations,
    pivotConfigurations,
    errors,
    updatedAt,
  };

  return {
    treeWidget,
    status,
    isTableRefetching: isRootRefetching,
    addSubTreeToFetch,
    refetchLoadedSubtrees,
  };
};

export default useHierarchicalWidgetFetching;
