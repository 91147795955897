import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as userProfileActions from 'actions/userProfilesActions';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuToggle from 'components/UI/BuToggle';
import Scrolling from 'components/UI/common/Scrolling/Scrolling';
import ManagePageDefaults from 'components/settings/ManageFilters/ManagePageDefaults/ManagePageDefaults';
import ManageTableColumns from 'components/settings/ManageTableColumns';
import ManageBusinessTypes from 'components/settings/UserProfiles/BusinessTypes';
import NavigationProfile from 'components/settings/UserProfiles/NavigationProfile';
import ProfileCustomViewsConfigurator from 'components/settings/UserProfiles/Views/CustomViewsConfigurator';
import { profileBase } from 'components/settings/UserProfiles/UserProfiles';
import { editPanel as s } from 'components/settings/UserProfiles/styles';
import { IModalProps } from 'components/settings/UserProfiles/types';
import { IReduxState } from 'reducers/types';
import { getFeatureFlags, getModalProps } from 'selectors';
import ForecastSummary from 'components/settings/UserProfiles/Views/ForecastSummary/ForecastSummary';

const featureFlagsApi = `${process.env.REACT_APP_BACKEND_URL}/api/settings/company/flags`;

const defaultTabs = [
  { key: 'navigation', label: 'Navigation', enabled: true },
  { key: 'filters', label: 'Filters', enabled: true },
  { key: 'columns', label: 'Columns', enabled: true },
  { key: 'views', label: 'Views', enabled: true },
];

const mapModeToTitle: { [key: string]: string } = {
  edit: 'Edit Profile',
  new: 'Add New Profile',
};

type IFeaturesResponse = {
  data: {
    business_type: boolean;
  };
};

// onClose comes from the mount point @Switch
const EditProfile: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const dispatch = useDispatch();

  const [businessTypeEnabled, setBusinessTypeEnabled] =
    useState<boolean>(false);
  const { mode = 'edit', row }: IModalProps = useSelector(
    (state: IReduxState) => getModalProps(state, `/edit-profile/:profileId`)
  );

  useEffect(() => {
    /**
     * TODO: fetch feature flags and populate them on the same level we're fetching company settings
     * TechDept - https://vocalo.atlassian.net/browse/VPD-10374 [myk.hub]
     */
    const fetchFeatureFlags = async () => {
      const response = await fetch(featureFlagsApi);
      const { data }: IFeaturesResponse = await response.json();

      setBusinessTypeEnabled(data['business_type']);
    };

    fetchFeatureFlags();
  }, []);

  const { forecast_summary_enabled } = useSelector((state) =>
    getFeatureFlags(state)
  );

  const tabs = [
    ...defaultTabs,
    {
      key: 'businessType',
      label: 'Business Type',
      enabled: businessTypeEnabled,
    },
    {
      key: 'forecastSummary',
      label: 'Forecast Summary',
      enabled: forecast_summary_enabled,
    },
  ];

  const {
    id = '',
    is_draft,
    description,
    name,
    last_updated,
    read_only = false,
  } = row || {};
  const apiBase = `${profileBase}/${id}`;

  const [activeTab, setActiveTab] = useState<string>(tabs[0].key);
  const [nameProfile, setNameProfile] = useState(is_draft ? '' : name);
  const [descriptionProfile, setDescriptionProfile] = useState(
    is_draft ? '' : description
  );
  const [readOnlyProfile, setReadOnlyProfile] = useState<boolean>(
    is_draft ? false : read_only
  );

  const handleSave = (): void => {
    dispatch(
      userProfileActions.updateUserProfile({
        id,
        name: nameProfile,
        description: descriptionProfile,
        read_only: readOnlyProfile,
        is_draft: false,
      })
    );
  };

  const handleCancel = () => {
    onClose();
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFunction: Function
  ) => {
    setFunction(event.target.value);
  };

  const handleTabClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    const key = e.currentTarget.getAttribute('data-key');
    key && setActiveTab(key);
  }, []);

  const handleToggleChange =
    (field: boolean, setFunction: (value: boolean) => void) => () => {
      setFunction(!field);
    };

  return (
    <div className={s.container}>
      <div className={s.header}>
        <span className={s.title}>{mapModeToTitle[mode]}</span>
        {!is_draft && last_updated && (
          <span className={s.titleComment}>{last_updated}</span>
        )}
      </div>

      <div className={s.description}>
        <div className="row">
          <input
            id="name"
            required
            type="text"
            onChange={(event) => handleChange(event, setNameProfile)}
            placeholder="Type a unique name"
            value={nameProfile}
            data-testing="txt_field"
          />
          <span className="lbl">
            Profile Name:<span className="req">*</span>
          </span>
        </div>
        <div className="row">
          <input
            className="description-input"
            id="desc"
            type="text"
            onChange={(event) => handleChange(event, setDescriptionProfile)}
            placeholder="Description will help you understand the user profile"
            value={descriptionProfile}
            data-testing="txt_field"
          />
          <span className="lbl">Description:</span>
        </div>
        <div className="row">
          <BuToggle
            checked={readOnlyProfile}
            onChange={handleToggleChange(readOnlyProfile, setReadOnlyProfile)}
          />
          <span className="lbl">Mark this as read-only profile:</span>
        </div>
      </div>

      <div className={s.tabs}>
        {tabs.map(
          ({ key, label, enabled }) =>
            enabled && (
              <div
                className={classNames('item', { active: key === activeTab })}
                data-key={key}
                key={key}
                onClick={handleTabClick}
              >
                {label}
              </div>
            )
        )}
      </div>

      <div className={s.body}>
        <Scrolling height="100%" shadows alwaysShow>
          {activeTab === 'navigation' && <NavigationProfile profileId={id} />}

          {activeTab === 'filters' && <ManagePageDefaults profileId={id} />}

          {activeTab === 'columns' && <ManageTableColumns apiBase={apiBase} />}

          {activeTab === 'businessType' && businessTypeEnabled && (
            <ManageBusinessTypes profileId={id} />
          )}

          {activeTab === 'views' && businessTypeEnabled && (
            <ProfileCustomViewsConfigurator profileId={id} />
          )}

          {activeTab === 'forecastSummary' && forecast_summary_enabled && (
            <ForecastSummary profileId={id} />
          )}
        </Scrolling>
      </div>

      <div className={s.footer}>
        <BuButton size={BuControlSize.REGULAR} onClick={handleSave}>
          Save
        </BuButton>
        <BuButton secondary size={BuControlSize.REGULAR} onClick={handleCancel}>
          Cancel
        </BuButton>
      </div>
    </div>
  );
};

export default EditProfile;
