import classNames from 'classnames';
import React, { FC } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import { debugInfoStyles } from 'components/account-insights/styles';

const {
  column,
  container,
  row,
  title,
  subtitle,
  paddingRow,
  lineHeight22,
  marginBottom10,
  toggle_box,
  content,
  iconContainer,
  toggle_box_btn,
} = debugInfoStyles;

const contactsLength = (linkedContactsLength: number[] | number) => {
  if (linkedContactsLength <= 0) {
    return '';
  }
  if (linkedContactsLength === 1) {
    return ' Contact';
  }
  return ' Contacts';
};

type Props = {
  isPanelOpen: boolean;
  setPanelOpen: (value: boolean) => void;
  contacts: { email: string; name: string; id: string }[];
};

export const DebugPanel: FC<Props> = ({
  isPanelOpen,
  setPanelOpen,
  contacts,
}) => {
  if (!contacts) {
    return null;
  }

  const contactsLinkedToAccount = contacts
    ? contacts
        .map((c) => (c.name ? `${c.name} (${c.email})` : c.email))
        .join(', ')
    : null;

  const linkedContactsLength = contacts.length;

  const wordLength = contactsLength(linkedContactsLength);

  return (
    <div className={container}>
      <div className={iconContainer}>
        <BuIcon name={BoostUpIcons.ContactsLinked} />
      </div>
      <div className={content}>
        <div className={classNames(row, paddingRow)}>
          <div className={row}>
            <span className={title}>
              {wordLength} linked to this opportunity:
            </span>
            <span className={subtitle}>
              ({linkedContactsLength}
              {wordLength})
            </span>
          </div>
          <div className={column} />
        </div>
        {isPanelOpen && (
          <div className={classNames(row, paddingRow, marginBottom10)}>
            <span className={classNames(subtitle, lineHeight22)}>
              {contactsLinkedToAccount}
            </span>
          </div>
        )}
        {!!linkedContactsLength && (
          <span
            onClick={() => setPanelOpen(!isPanelOpen)}
            className={classNames(toggle_box, {
              hidden_panel: !isPanelOpen,
            })}
          >
            <BuButton className={toggle_box_btn}>
              <BuIcon
                className={classNames({
                  down: !isPanelOpen,
                })}
                name={BoostUpIcons.ChevronLeft}
              />
              {isPanelOpen ? 'show less' : 'show more'}
            </BuButton>
          </span>
        )}
      </div>
    </div>
  );
};
