import { css } from 'emotion';

import { fontBody } from 'assets/css/fontStyles';

export const container = css`
  width: 200px;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 10px 12px;
  ${fontBody}

  & > i {
    color: var(--bu-gray-700);
  }

  &:hover {
    background-color: var(--bu-primary-200);
    cursor: pointer;
  }
`;

export const icon = css`
  margin-right: 8px;
`;
