import PropTypes from 'prop-types';
import React from 'react';
import { Input, Divider } from 'semantic-ui-react';

import * as s from 'components/UI/OpenFiltersPanel/styles';

function SearchInput(props) {
  return (
    <div>
      <Input
        className={s.panel_dropdown_input}
        iconPosition="left"
        icon="search"
        name={props.name}
        placeholder="Search..."
        onChange={props.onChange}
        value={props.value}
        data-testing="txt_field"
      />
      <Divider className={s.panel_dropdown_divider} />
    </div>
  );
}

SearchInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default SearchInput;
