import { FORECAST_SUBMISSION } from 'components/dashboard/Metrics/constants';
import { DataDescriptor } from 'components/dashboard/Metrics/metrics.types';
import axiosInstance from './axiosInstance';

export const getMetricProperties = async (
  metricObject: string,
  type: string
) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_column_fields/${metricObject}`;

  if (type === 'historical') {
    url = `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_column_fields_filter_history`;
  }

  if (metricObject === FORECAST_SUBMISSION) {
    url = `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/table_properties/${metricObject}`;
  }

  const res = await axiosInstance.get<string, { data: DataDescriptor[] }>(url);

  return res.data;
};

export const getDateColumnsFor = async (object: string) => {
  const rest = await axiosInstance.get<string, { data: DataDescriptor[] }>(
    `/rev_bi/external/columns/date_pivots?table_names=${object}`
  );
  return rest.data;
};
