import {
  getLocation,
  getAction,
  LOCATION_CHANGE,
} from 'connected-react-router';
import * as R from 'ramda';
import { select, take, put } from 'redux-saga/effects';

import { LOGIN_ROUTE, changePreviousRoute } from 'actions/routeActions';

const lastOrNull = R.compose(R.defaultTo(null), R.last);

function* previousRouteSaga() {
  // save the previous route in a variable
  let previousRoutes = [];

  while (true) {
    // wait for changes on the location
    yield take(LOCATION_CHANGE);

    const action = yield select(getAction);
    const location = yield select(getLocation);
    const currentRoute = location.pathname + location.search;
    const lastRoutes = R.takeLast(2, previousRoutes);

    if (R.not(R.contains(currentRoute, lastRoutes))) {
      // if the route is different than the previous 2 last routes, we're moving forward
      // update previous route and add current to the stack
      yield put(changePreviousRoute(lastOrNull(lastRoutes)));

      previousRoutes = R.append(currentRoute, previousRoutes);
    } else {
      // if the routes is within the last 2, we're moving backwards or within the same route,
      if (action === 'POP' || action === 'PUSH') {
        // if the action is a POP or a PUSH, we're going back
        // we have to remove the last route from our stack
        previousRoutes = R.dropLast(1, previousRoutes);

        // and reset the previous route to the previous one to the current
        // we only consider POP as a back navigation (PUSH is just a circular navigation)
        yield put(
          changePreviousRoute(
            lastOrNull(R.init(previousRoutes)),
            action === 'POP'
          )
        );
      } else {
        // otherwise, on a REPLACE action, just update the stack
        previousRoutes = R.compose(
          R.append(currentRoute),
          R.dropLast(1)
        )(previousRoutes);
      }
    }

    // only keep the last 10 routes and ignore login routes
    previousRoutes = R.compose(
      R.when(R.compose(R.lte(10), R.length), R.dropLast(1)),
      R.reject(R.startsWith(LOGIN_ROUTE))
    )(previousRoutes);
  }
}

export default previousRouteSaga;
