export function importPolyfills() {
  const polyfillPromises: Promise<any>[] = [];

  if (!('ResizeObserver' in window)) {
    polyfillPromises.push(
      import(
        /* webpackChunkName: "resize-observer-polyfill" */ 'resize-observer-polyfill'
      ).then((ResizeObserverPolyfill) => {
        window.ResizeObserver = ResizeObserverPolyfill.default;
      })
    );
  }

  return Promise.all(polyfillPromises);
}
