import isNil from 'lodash/isNil';
import { RefObject } from 'react';

const setElementInViewport = (ref: RefObject<HTMLDivElement>) => {
  if (!isNil(ref) && !isNil(ref.current)) {
    const { current } = ref;

    const refBounding = current?.getBoundingClientRect();
    const screenWidth = current?.ownerDocument?.body?.clientWidth || 0;

    if (screenWidth && refBounding && screenWidth < refBounding?.right) {
      current.style.right = '0px';
    }
  }
};

export default setElementInViewport;
