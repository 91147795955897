import * as R from 'ramda';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';

const useTableSearch = <T>(
  searchInPaths: MapPaths.Paths<T>[]
): [string, Dispatch<SetStateAction<string>>, (obj: T) => boolean] => {
  const [searchText, setSearchText] = useState<string>('');

  const searchFilter = useCallback(
    (obj: T) => {
      const str = searchText.toLocaleLowerCase();

      const stringCompare = (a: unknown) =>
        String(a).toLocaleLowerCase().includes(str);

      return (
        !str ||
        searchInPaths.some((path) =>
          R.pathSatisfies(stringCompare, path as string[], obj)
        )
      );
    },
    [searchText]
  );

  return [searchText, setSearchText, searchFilter];
};

export default useTableSearch;
