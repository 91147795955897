import classNames from 'classnames';
import { css } from 'emotion';
import React from 'react';
import { useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';

import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

const styles_dropdown = css`
  padding: 7px !important;
  border: 1px solid var(--bu-gray-700) !important;
  border-radius: 2px !important;
  font-size: 14px !important;
  font-family: var(--bu-font-regular);
  color: var(--bu-gray-900);

  .menu {
    z-index: 1000 !important;
    max-height: 130px;
    overflow-y: auto !important;
  }
`;

const dropdownWithScroll = css`
  padding-left: 16px !important;
`;

type Option = {
  text: string;
  key: string;
  value: string;
};

type Props = {
  onChange: (value: string[]) => void;
  value?: string[];
};

const options = [
  {
    text: 'Current Quarter',
    key: 'TQU',
    value: 'TQU',
  },
  {
    text: 'Next Quarter',
    key: 'NQU',
    value: 'NQU',
  },
  {
    text: 'Last Quarter',
    key: 'LQU',
    value: 'LQU',
  },
];

const sort = (a: Option, b: Option) => {
  if (a.value > b.value) {
    return 1;
  }

  if (a.value < b.value) {
    return -1;
  }

  return 0;
};

const Select: React.FC<Props> = ({ onChange, value = ['TQU'] }) => {
  const filters = useSelector((state: IReduxState) =>
    selectors.getFiltersState(state, 'forecast_roll_ups')
  );

  const config = (filters && filters.close_date_interval) || undefined;
  const isDate = !!config?.values[0]?.type;
  const values =
    config?.values.map(({ label, value }) => ({
      text: label,
      key: value,
      value,
    })) || [];

  const opts = isDate ? values.sort(sort) : options;

  return (
    <Dropdown
      options={opts}
      simple
      item
      onChange={(_, { value: v }) => onChange([v] as string[])}
      value={value[0]}
      direction="left"
      className={classNames(styles_dropdown, {
        [dropdownWithScroll]: opts.length > 3,
      })}
    />
  );
};

export default Select;
