import classNames from 'classnames';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Loader, Segment, Grid, Dropdown } from 'semantic-ui-react';

import { actions } from 'actions';
import * as interfaces from 'common/props-interfaces';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import {
  formatRevenue,
  buildTooltip,
} from 'components/dashboard/Charts/helpers';
import * as layStyle from 'components/dashboard/styles';
import RevenueStackedModal from 'components/modals/RevenueStackedModal';
import {
  REVENUE_DROPDOWN_OPTIONS,
  RevenueTypes,
  formatRevenueTitle,
} from 'components/modals/types';
import { openModal } from 'navigation/utils';
import * as selectors from 'selectors';
import FullScreenIcon from 'assets/images/new_icon/full_screen_icon.svg';

class RevenueStacked extends React.Component {
  constructor(props) {
    super(props);

    this.getConfig = this.getConfig.bind(this);
    this.handleChartClick = this.handleChartClick.bind(this);

    this.state = {
      fullScreen: false,
      type: RevenueTypes.COUNT,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { data, status } = this.props;
    const { type, fullScreen } = this.state;
    return (
      data !== nextProps.data ||
      status !== nextProps.status ||
      type !== nextState.type ||
      fullScreen !== nextState.fullScreen
    );
  }

  getConfig() {
    const { data, sort = false, companyCurrency } = this.props;
    const { type } = this.state;
    const self = this;

    if (!data) return {};

    const sumValues = (item) =>
      type === RevenueTypes.REVENUE
        ? item.amount_high_risk + item.amount_medium_risk + item.amount_low_risk
        : item.count_high_risk + item.count_medium_risk + item.count_low_risk;

    const dataSorted = sort
      ? data.sort((a, b) => sumValues(b) - sumValues(a))
      : data;

    const revenueAxis = {
      min: 0,
      title: {
        text: 'Amount',
      },
      labels: {
        formatter() {
          return formatRevenue(this.value, companyCurrency, 0);
        },
      },
    };
    const countAxis = {
      min: 0,
      title: {
        text: '# of Deals',
        x: 0,
      },
      allowDecimals: false,
    };
    const config = {
      chart: {
        type: 'column',
      },
      title: {
        text: null,
      },
      xAxis: {
        categories: dataSorted.map((el) => el[this.props.category]),
      },
      tooltip: {
        enabled: true,
        formatter: buildTooltip(companyCurrency),
      },
      yAxis: type === RevenueTypes.REVENUE ? revenueAxis : countAxis,
      legend: {
        align: 'right',
        verticalAlign: 'top',
        borderWidth: 0,
        y: -50,
        x: -40,
        margin: 70,
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          cursor: 'pointer',
          events: {
            click(e) {
              self.handleChartClick(e.point.category, this.name);
            },
          },
        },
      },
      series: dataSorted.reduce(
        (acc, stage) => {
          acc[0].data.push({
            type,
            y:
              type === RevenueTypes.REVENUE
                ? stage.amount_high_risk
                : stage.count_high_risk,
            y2:
              type === RevenueTypes.REVENUE
                ? stage.count_high_risk
                : stage.amount_high_risk,
          });
          acc[1].data.push({
            type,
            y:
              type === RevenueTypes.REVENUE
                ? stage.amount_medium_risk
                : stage.count_medium_risk,
            y2:
              type === RevenueTypes.REVENUE
                ? stage.count_medium_risk
                : stage.amount_medium_risk,
          });
          acc[2].data.push({
            type,
            y:
              type === RevenueTypes.REVENUE
                ? stage.amount_low_risk
                : stage.count_low_risk,
            y2:
              type === RevenueTypes.REVENUE
                ? stage.count_low_risk
                : stage.amount_low_risk,
          });
          return acc;
        },
        [
          {
            name: 'High Risk',
            color: 'var(--bu-color-negative-bar)',
            data: [],
          },
          {
            name: 'Medium Risk',
            color: 'var(--bu-color-caution-chart)',
            data: [],
          },
          {
            name: 'Low Risk',
            color: 'var(--bu-color-positive-bar)',
            data: [],
          },
        ]
      ),
    };
    if (this.state.fullScreen) {
      config.chart.options3d = {
        enabled: false,
        alpha: 15,
        beta: 15,
        depth: 50,
        viewDistance: 25,
      };
    }
    return config;
  }

  handleChartClick(value, type) {
    const {
      title,
      childFilterValue,
      childValueFormatter,
      filters,
      isModal,
      persistModalParams,
      tab,
    } = this.props;
    if (childFilterValue) {
      let riskScoreCategory;

      switch (type) {
        case 'High Risk':
          riskScoreCategory = 'high';
          break;
        case 'Medium Risk':
          riskScoreCategory = 'medium';
          break;
        default:
          riskScoreCategory = 'low';
      }

      openModal({
        scheme: '/deals/:tab',
        params: {
          tab: isModal ? 'accounts-deals' : 'default',
          localStorageKeyPrefix: this.props.localStorageKeyPrefix,
        },
        persistParams: {
          ...filters,
          title,
          [childFilterValue]: childValueFormatter
            ? childValueFormatter(value)
            : value,
          time_span_combination: 'and',
          filter_activity_sync_info: true,
          risk_score: isEmpty(filters.risk_score)
            ? [riskScoreCategory]
            : filters.risk_score,
        },
        persistor: persistModalParams,
      });

      AnalyticsTracker.event(
        { tab },
        {
          category: 'Pipeline Analytics',
          action: 'Tab: Risk Analytics',
          label: `Opened ${title}`,
        }
      );
    }
  }

  render() {
    const { status, title, isModal, extraFilter } = this.props;
    const { type, fullScreen } = this.state;

    if (status === 'loading') {
      return (
        <div className="block-loader">
          <Loader active inline="centered" />
        </div>
      );
    }

    const config = this.getConfig();

    const { hideFullScreenButton } = this.props;

    return (
      <Segment className={classNames(isModal && layStyle.removePadding_box)}>
        <Grid>
          <Grid.Row>
            <div className={layStyle.chartHeader}>
              <span className={layStyle.chartTitle}>
                {formatRevenueTitle(type, title)}
              </span>
              <div className={layStyle.alignRight}>
                {extraFilter}

                <Dropdown
                  className={layStyle.dropdown}
                  options={REVENUE_DROPDOWN_OPTIONS}
                  onChange={(e, { value }) => this.setState({ type: value })}
                  value={type}
                />
                {!hideFullScreenButton && (
                  <button
                    type="button"
                    onClick={() =>
                      this.setState({
                        fullScreen: true,
                      })
                    }
                    className={layStyle.fullScreen}
                  >
                    <img src={FullScreenIcon} alt="full screen" />
                  </button>
                )}
              </div>
            </div>
          </Grid.Row>
          <Grid.Row columns={1} stretched>
            <Grid.Column className={layStyle.chartContainer} stretched>
              <HighchartsReact
                highcharts={Highcharts}
                options={config}
                immutable
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <RevenueStackedModal
          open={fullScreen}
          type={type}
          config={config}
          title={title}
          onClose={() => this.setState({ fullScreen: false })}
          onChange={(e, { value }) => this.setState({ type: value })}
        />
      </Segment>
    );
  }
}

const mapStateToProps = (state) => ({
  companyCurrency: selectors.getUserLocalCurrency(state),
});

const dispatchToProps = {
  persistModalParams: actions.ui.modal.persist,
};

RevenueStacked.defaultProps = {
  hideFullScreenButton: false,
};

RevenueStacked.propTypes = {
  hideFullScreenButton: PropTypes.bool,
  sort: PropTypes.bool,
  status: interfaces.loadingStatus,
  title: PropTypes.string,
  childFilterValue: PropTypes.string,
  category: PropTypes.string.isRequired,
  data: PropTypes.array,
  childValueFormatter: PropTypes.func,
  persistModalParams: PropTypes.func.isRequired,
  companyCurrency: PropTypes.string.isRequired,
  localStorageKeyPrefix: PropTypes.string,
};

export default connect(mapStateToProps, dispatchToProps)(RevenueStacked);
