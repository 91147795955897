import { css } from 'emotion';

export const outerContainer = css`
  display: flex;
  flex-direction: column;
  height: 320px;
  padding: 16px;
  gap: 8px;
`;

export const innerContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const input = css`
  width: 30%;
`;

export const infoContainer = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  i {
    font-size: 17px;
  }
`;

export const infoSpan = css`
  font-size: 12px;
  color: var(--bu-gray-600);
`;

export const upperParagraph = css`
  color: var(--bu-gray-900);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 0;
`;

export const lowerParagraph = css`
  color: var(--bu-gray-900);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
`;
