import styled from '@emotion/styled';

export const TopicTitle = styled.span`
  line-height: 22px;
  color: var(--bu-gray-900);
  font-weight: 600;

  &.green {
    color: #0ba5a3;
  }

  &.red {
    color: #f74544;
  }
`;

export const TitlesRow = styled.span`
  line-height: 20px;
`;

export const mentionedByTimeSpan = styled.span`
  font-size: 12px;
`;
