import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import { Loader } from 'semantic-ui-react';

import DownloadIcon from 'assets/images/new_icon/download2.svg';
import { downloadButton } from 'components/UI/DownloadButton/styles';
import { DownloadButtonProps } from 'components/UI/DownloadButton/types';
import Tooltip from 'components/UI/common/Tooltip';
import { downloadFile, QueryStatus } from 'utils/network';

const toastOptions: ToastOptions = { position: 'bottom-left' };

const DownloadButton: React.FC<DownloadButtonProps> = ({
  buttonClassName,
  queryMethod,
  serializedQueryParams,
  url,
}) => {
  const [error, setError] = useState<null | string>(null);
  const [status, setStatus] = useState<QueryStatus>('notAsked');

  const isLoading = status === 'loading';

  const handleDownload = useCallback(() => {
    if (!isLoading) {
      setStatus('loading');

      downloadFile<string>({
        queryMethod,
        queryParams: serializedQueryParams,
        setError,
        setStatus,
        url,
      });
    }
  }, [queryMethod, serializedQueryParams, status, url]);

  useEffect(() => {
    if (status === 'error') {
      toast.error(`Downloading failed: ${error}`, toastOptions);
    }
  }, [status]);

  return (
    <Tooltip tooltip="Download CSV">
      <button
        className={classNames(downloadButton, buttonClassName)}
        disabled={isLoading}
        onClick={handleDownload}
      >
        {isLoading ? (
          <Loader size="tiny" active inline />
        ) : (
          <img alt="download" src={DownloadIcon} />
        )}
      </button>
    </Tooltip>
  );
};

export default DownloadButton;
