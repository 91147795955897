import styled from '@emotion/styled';

export const WidgetPreviewColumn = styled.div({
  flexGrow: 1,
  background: 'var(--bu-gray-200)',
  alignItems: 'center',
  padding: '24px 48px',
  overflow: 'auto',
});

export const WidgetPreviewContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  marginBottom: '48px',
  // Workaround for report table , as table can't handle small screens
  '.bu-search': {
    '@media (max-width: 1400px)': {
      width: '111px',
    },
  },
  '.table-controls > .fluid': {
    '@media (max-width: 1300px)': {
      flexDirection: 'column',

      'div.fitted:first-of-type': {
        alignSelf: 'start',
        marginBottom: '4px',
      },
    },
  },
});
