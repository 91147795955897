import { css } from 'emotion';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Input, Divider } from 'semantic-ui-react';

import CheckboxInput from 'components/UI/Checkbox';
import { IPropsNew } from 'components/UI/FilterPanels/types';
import * as s from 'components/UI/OpenFiltersPanel/styles';
import { no_result_box } from 'components/UI/OpenFiltersPanel/styles';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

type ReduxStateProps = ReturnType<typeof stateToProps>;
type Props = ReduxStateProps & IPropsNew;

const listItem = css`
  padding: 7px 15px 7px 15px;
  transition: 0.4s all;

  &:hover {
    background: rgba(48, 176, 209, 0.08);
  }
`;

const PanelStages: React.FC<Props> = ({
  config,
  onChange,
  activeStages,
  onClose,
}) => {
  const [searchRequestState, setSearchRequestState] = useState('');

  const isAllSelected = config.checkedAll;

  const checkedValues = config.values
    .filter((c) => c.checked && c.value !== '__all__')
    .map((c) => c.value);

  const allActiveChecked = isEqual(sortBy(checkedValues), sortBy(activeStages));

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value: id, checked: originalChecked } = event.target;

    const withReset =
      isAllSelected ||
      (activeStages.includes(id) && allActiveChecked && originalChecked);
    const checked = withReset ? true : originalChecked;

    onChange([{ id, checked }], withReset);
  }

  function handleChangeAll(event: React.ChangeEvent<HTMLInputElement>) {
    const { checked } = event.target;
    onChange(config.values.map((item) => ({ id: item.value, checked })));
  }

  function handleChangeAllActive(event: React.ChangeEvent<HTMLInputElement>) {
    const { checked } = event.target;
    onChange(
      config.values.map((item) => ({
        id: item.value,
        checked: activeStages.includes(item.value) ? checked : false,
      })),
      true
    );
  }

  function renderCheckboxes(checkboxes: Filters.Checkbox[]) {
    const result = checkboxes.map((checkbox: Filters.Checkbox) => {
      const { checked, label, value } = checkbox;
      const { title } = config;
      const isAllOption = value === '__all__';

      return (
        <div className={listItem} key={`${title}_${value}`}>
          <CheckboxInput
            strong={isAllOption}
            id={`${title}_${value}`}
            name={title}
            value={value}
            onChange={isAllOption ? handleChangeAll : handleChange}
            checked={isAllSelected && !isAllOption ? false : checked}
            label={label}
            key={`${title}_${value}`}
            colorized={activeStages.includes(value) && allActiveChecked}
          />
        </div>
      );
    });

    result.splice(
      1,
      0,
      <div className={listItem} key={`${config.title}_select_open`}>
        <CheckboxInput
          strong
          id={`${config.title}_select_open`}
          name="All Active"
          value="__active__"
          onChange={handleChangeAllActive}
          checked={allActiveChecked}
          label="All Active"
          colorized={allActiveChecked}
        />
      </div>
    );

    return result;
  }

  function filterCheckBoxes(options: Filters.Checkbox[], request: string) {
    if (request === '') return options;
    return options.filter(
      (opt) =>
        opt.label.toLowerCase().indexOf(request.toLowerCase()) !== -1 ||
        opt.value.toLowerCase().indexOf(request.toLowerCase()) !== -1
    );
  }

  const filteredCheckboxes =
    config && filterCheckBoxes(config.values, searchRequestState);

  return (
    <>
      {config && (
        <div className="collapse in" id="topics">
          {config.searchable && (
            <>
              <Input
                className={s.panel_dropdown_input}
                iconPosition="left"
                icon="search"
                placeholder="Search..."
                onChange={(e, { value }) => setSearchRequestState(value)}
                value={searchRequestState}
                data-testing="txt_field"
              />
              <Divider className={s.panel_dropdown_divider} />
            </>
          )}

          <div className={s.panel_dropdown_checkboxes}>
            {renderCheckboxes(filteredCheckboxes)}
          </div>

          {searchRequestState !== '' && filteredCheckboxes.length === 0 && (
            <div className={no_result_box}>
              <p>No results found.</p>
              <span onClick={() => setSearchRequestState('')}>
                reset search
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const stateToProps = (state: IReduxState) => ({
  activeStages: selectors.getSettingsActiveStages(state),
});

export default connect(stateToProps)(PanelStages);
