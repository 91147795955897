import { useState } from 'react';

import {
  RowStatusType,
  Status,
} from 'components/UI/common/TypedTable/TypedTable';

/**
 * This hooks is used to manage the status of a row in a table.
 * The status will be used to display color for different status
 * when used along with Table
 *
 * @see Table - statuses property
 *
 */
export const useManageRowStatuses = () => {
  const [rowStatuses, setRowStatuses] = useState<RowStatusType>({});

  const setRowStatus = (rowId: string, field: string, status: Status) => {
    const statusConfig = {
      [field]: {
        status,
        date: Date.now(),
        value: 1000,
      },
    };

    setRowStatuses((prevRowStatuses) => ({
      ...prevRowStatuses,
      [rowId]: {
        ...prevRowStatuses[rowId],
        ...statusConfig,
      },
    }));
  };

  const setLoadingRowField = (rowId: string, field: string) =>
    setRowStatus(rowId, field, 'loading');

  const setSuccessRowField = (rowId: string, field: string) =>
    setRowStatus(rowId, field, 'success');

  const setErrorRowField = (rowId: string, field: string) =>
    setRowStatus(rowId, field, 'error');

  return {
    rowStatuses,
    setLoadingRowField,
    setSuccessRowField,
    setErrorRowField,
  };
};
