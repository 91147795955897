import BuIcon from '../BuIcon';
import styled from '@emotion/styled';
import classNames from 'classnames';
import { css } from 'emotion';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';
import { changeDateTimeZone } from 'utils/dates';

const DeadLineWrapper = styled.div`
  padding: 7px 5px;
`;

const DeadLine = styled.span`
  margin-left: 4px;
  font-size: 12px;
  color: var(--bu-gray-700);
`;

const alert = css`
  color: var(--bu-orange-500) !important;
`;

const error = css`
  color: var(--bu-red-400) !important;
`;

export const ScheduleSubmissionAlert: React.FC = () => {
  const { deadlineConfig, lastSubmissionDate } = useSelector(
    (state: IReduxState) => ({
      deadlineConfig: selectors.forecastSubmissionDeadlineConfig(state),
      lastSubmissionDate: selectors.forecastLastSubmissionDate(state),
    })
  );

  const { deadline, forecast_submission_reminder } = deadlineConfig;

  /**
   * in case of the User doesn't have a deadline this should not return a component.
   */
  if (!deadline) {
    return null;
  }

  const deadlineHour =
    forecast_submission_reminder?.forecast_submission_reminder_deadline_hour;
  const reminderTimeZone =
    forecast_submission_reminder?.forecast_submission_reminder_timezone;
  const localDeadline = changeDateTimeZone(deadline);
  const today = moment();
  const deadlineMoment = moment(localDeadline);
  const lastSubmissionMoment = moment.utc(lastSubmissionDate);
  const isDeadlineToday = deadlineMoment.isSame(today, 'day');
  const isDeadlineIn24Hours = today.isBetween(
    moment.utc(deadlineMoment).subtract(24, 'hours'),
    deadlineMoment
  );
  const configuredTimeZoneDeadlineMessage = `${moment(
    deadlineHour,
    'ha'
  ).format('ha')} ${reminderTimeZone}`;
  const currentTimeZoneDeadlineMessage = isDeadlineToday
    ? `${deadlineMoment.format('h:mma')}`
    : `${deadlineMoment.format('MMM DD, h:mma')}`;

  const message = isDeadlineToday
    ? `Due today, ${currentTimeZoneDeadlineMessage} local (${configuredTimeZoneDeadlineMessage})`
    : `Due ${currentTimeZoneDeadlineMessage} local (${configuredTimeZoneDeadlineMessage})`;

  return (
    <DeadLineWrapper>
      <TooltipWrapper
        tooltip={
          lastSubmissionDate !== null
            ? `Last submitted on ${lastSubmissionMoment.format('MMM DD, `YY')}`
            : 'No submissions found'
        }
      >
        <div>
          <BuIcon
            name={BoostUpIcons.Clock}
            className={classNames({
              [alert]: !isDeadlineIn24Hours,
              [error]: isDeadlineIn24Hours || isDeadlineToday,
            })}
          />
          <DeadLine
            className={classNames({
              [alert]: !isDeadlineIn24Hours,
              [error]: isDeadlineIn24Hours || isDeadlineToday,
            })}
          >
            {message}
          </DeadLine>
        </div>
      </TooltipWrapper>
    </DeadLineWrapper>
  );
};
