import styled from '@emotion/styled';

export const AnalysisTypeTitle = styled.div({
  color: `var(--bu-gray-900)`,
  '>span': {
    paddingLeft: '8px',
  },
});

export const AnalysisTypeContent = styled.div({
  paddingLeft: '24px',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});
