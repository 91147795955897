import React from 'react';

import BuSelect from 'components/UI/BuSelect';
import {
  Container,
  Title,
  Description,
} from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/FunnelDefinition/FunnelObjectSelector/styles';

// This is a placeholder component for when we have to implement multiple
// objects for the funnel

export const FunnelObjectSelector: React.FC = () => {
  const selectedField = 'opportunity';
  const fieldOptions = [{ value: 'opportunity', text: 'Opportunity' }];

  return (
    <Container>
      <Title>Object</Title>
      <Description>Type of object used to create the funnel</Description>

      <BuSelect
        fullWidth
        isLargePlaceholder
        secondary
        placeholder="Select a field"
        defaults={[selectedField]}
        options={fieldOptions}
        onChange={() => {}}
        testingLabel="object"
      />
    </Container>
  );
};
