import * as Sentry from '@sentry/react';
import { push } from 'connected-react-router';

export default function errorHandler(e) {
  Sentry.captureException(e);
  let nextUrl = '';

  if (
    e &&
    e.response &&
    e.response.status === 401 &&
    !window.location.pathname.includes('/login')
  ) {
    if (window.location.pathname !== '/') {
      nextUrl = `/login?next=${window.location.pathname}${window.location.search}`;
    } else {
      nextUrl = '/login';
    }
  }

  if (nextUrl !== '') {
    return push(nextUrl);
  } else {
    return null;
  }
}
