import * as R from 'ramda';

import * as t from 'actions/actionTypes';
import {
  goToDealInsights,
  goToCallInsights,
  goToMeeting,
  goToAccount,
} from 'actions/routeActions';
import * as genericSagas from 'sagas/generic';

export const changeSearchQuery = (query) => ({
  type: t.SEARCH + t.QUERY + t.CHANGE,
  payload: query,
});

export const clearSearchQuery = R.partial(changeSearchQuery, ['']);

const SUGGESTIONS_LIMIT_BY_TYPE = 3;
export const getSearchSuggestions = (query) => ({
  type: t.SEARCH + t.SUGGESTION + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/search/`,
  data: {
    q: query,
    page_size: SUGGESTIONS_LIMIT_BY_TYPE,
  },
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.SEARCH + t.SUGGESTION + t.GET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.SEARCH + t.SUGGESTION + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.SEARCH + t.SUGGESTION + t.GET + t.LOADING,
  }),
  meta: {
    analytics: {
      category: 'Search Dashboard',
      action: 'Search',
      label: query,
    },
  },
});

export const SEARCH_RESULTS_PAGE_SIZE = 5;

export const getSearchResults = (query) => ({
  type: t.SEARCH + t.RESULT + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/search/`,
  data: {
    q: query,
    page_size: SEARCH_RESULTS_PAGE_SIZE,
  },
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.SEARCH + t.RESULT + t.GET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.SEARCH + t.RESULT + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.SEARCH + t.RESULT + t.GET + t.LOADING,
  }),
  meta: {
    analytics: {
      category: 'Search Dashboard',
      action: 'Search',
      label: query,
    },
  },
});

export const paginateSearchResults = (query, page, types = null) => ({
  type: t.SEARCH + t.RESULT + t.PAGINATION,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/search/`,
  data: {
    q: query,
    page_size: SEARCH_RESULTS_PAGE_SIZE,
    page_number: page,
    types,
  },
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.SEARCH + t.RESULT + t.PAGINATION + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.SEARCH + t.RESULT + t.PAGINATION + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.SEARCH + t.RESULT + t.PAGINATION + t.LOADING,
  }),
  meta: {
    analytics: {
      category: 'Search Dashboard',
      action: `Load More - ${R.head(types)}`,
      label: query,
    },
  },
});

const resourceActionCreators = {
  account: goToAccount,
  opportunity: goToDealInsights,
  call: goToCallInsights,
  meeting: goToMeeting,
};
export const goToResource = (type, id, query) => ({
  ...resourceActionCreators[type](id),
  meta: {
    analytics: {
      category: 'Search Dashboard',
      action: `Search Result - ${type}`,
      label: query,
    },
  },
});
