export const getApiPathAndSearchParam = (
  metricObject: string,
  isMetricObjectEqualToOpportunity: boolean
) => {
  if (metricObject && isMetricObjectEqualToOpportunity) {
    return ['deals', 'opportunity_name'];
  } else if (metricObject) {
    return ['accounts', 'account_name'];
  }

  return [];
};
