import * as t from 'actions/actionTypes';
import * as genericSagas from 'sagas/generic';

export const DEFAULT_PAGE_SIZE = 10;

export const getEvent = (id) => ({
  type: t.EVENT + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/events/${id}`,
  saga: genericSagas.get,
  success: (payload) => ({
    type: t.EVENT + t.GET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.EVENT + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.EVENT + t.GET + t.LOADING,
  }),
});
