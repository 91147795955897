import styled from '@emotion/styled';
import classNames from 'classnames';
import React from 'react';
import {
  Accordion,
  Dimmer,
  Grid,
  Loader,
  Placeholder,
  Segment,
} from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import { Status } from 'components/UI/common/TypedTable/TypedTable';
import { FieldConfiguration } from 'components/settings/FieldConfiguration/types';
import {
  TableCollectionData,
  TableColumnUpdateData,
} from 'components/settings/ManageTableColumns/ManageTableColumns';
import TableColumnConfiguration from 'components/settings/ManageTableColumns/TableColumnConfiguration';

const getCountWithPlural = (count: number, title: string) => {
  if (count === 1 && title) {
    return `(1 ${title.slice(0, -1)})`;
  }
  if (count > 1 && title) {
    return `(${count} ${title})`;
  }
  return '';
};

interface IProps {
  activeIndex: string;
  collection: FieldConfiguration;
  data: TableCollectionData;
  status: Status;
  subTitle?: string;
  canChangeDefaultSorting?: boolean;
  onOpen: () => void;
  onClose: () => void;
  onChange: (table: TableColumnUpdateData) => void;
}

export const AccordionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: normal;
  color: var(--bu-gray-900);
  flex: 1;
  height: 30px;

  *:hover > &,
  &.active {
    color: var(--bu-primary-500);
  }

  .bu-icon {
    font-size: 1.2em;
    margin-right: 5px;
    line-height: 1.2em;
  }
`;

const TableCollection: React.FC<IProps> = ({
  activeIndex,
  collection,
  onOpen,
  onClose,
  data: { tables, available_fields: availableFields } = {
    tables: [],
    available_fields: [],
  },
  status,
  onChange,
  subTitle,
  canChangeDefaultSorting = false,
}: IProps) => {
  const isOpen = collection.name === activeIndex;

  const noAction = () => {};

  const handleHeaderClick = () => (isOpen ? onClose() : onOpen());

  return (
    <>
      <Accordion.Title
        active={activeIndex === collection.name}
        index={collection.name}
        onClick={noAction}
      >
        <Grid columns={2}>
          <Grid.Column width={14}>
            <AccordionHeader
              className={classNames('bu-font-section-title', {
                active: isOpen,
              })}
              onClick={handleHeaderClick}
            >
              <BuIcon
                name={
                  isOpen ? BoostUpIcons.SquareMinus : BoostUpIcons.SquarePlus
                }
              />

              {collection.label}
              {subTitle &&
                `${subTitle} ${getCountWithPlural(tables.length, subTitle)}`}
            </AccordionHeader>
          </Grid.Column>
        </Grid>
      </Accordion.Title>

      <Accordion.Content active={activeIndex === collection.name}>
        <Dimmer.Dimmable dimmed={status === 'loading'}>
          <Segment basic>
            {tables.map((table) => (
              <TableColumnConfiguration
                key={table.name}
                availableFields={table.available_fields || availableFields}
                data={table}
                status="success"
                onChange={onChange}
                order={table.order}
                canChangeDefaultSorting={canChangeDefaultSorting}
              />
            ))}

            {tables.length === 0 && status === 'success' && (
              <>
                No tables available to be configured. Contact your administrator
                for help.
              </>
            )}

            {tables.length === 0 && (status === 'loading' || !status) && (
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line />
                </Placeholder.Header>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder>
            )}
          </Segment>

          <Dimmer active={status === 'loading'} inverted>
            <Loader />
          </Dimmer>
        </Dimmer.Dimmable>
      </Accordion.Content>
    </>
  );
};

export default TableCollection;
