import { css } from 'emotion';

export const eventContainer = css`
  width: 100%;
  height: 100%;
  label: event-container;
  font-family: var(--bu-font-regular);
`;

export const container = css`
  width: 100%;
  padding: 20px 10px;
  margin: 0 auto;
  max-width: 980px;
`;

export const breadcrumbsBlock = css`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
`;

export const breadcrumbs = css`
  margin-left: 20px;
  font-family: var(--bu-font-regular);
  font-size: 14px;
  color: #9b9b9b;
  font-weight: 600;

  span {
    color: #4a4a4a;
    font-weight: 400;
  }
`;

export const row = css`
  display: flex;
  margin-bottom: 20px;
`;

export const iconBlock = css`
  width: 30px;
  margin-right: 10px;

  img {
    width: 31px;
    height: 32px;
  }
`;

export const rowContent = css`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  max-width: 100%;
`;

export const name = css`
  color: #4a4a4a;
  font-size: 24px;
  font-family: var(--bu-font-regular);
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 5px;
`;

export const date = css`
  color: #666666;
  font-size: 16px;
  font-family: var(--bu-font-regular);
  font-weight: 600;
`;

export const label = css`
  color: #666666;
  font-size: 16px;
  font-family: var(--bu-font-regular);
  font-weight: 600;

  span {
    color: #666666;
    font-size: 16px;
    font-family: var(--bu-font-regular);
    font-weight: 600;
  }
`;

export const descriptionText = css`
  color: #666666;
  font-size: 16px;
  font-family: var(--bu-font-regular);
  font-weight: 600;
`;
