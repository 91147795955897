import { NumberDropdownOption } from '../types';
import { css } from 'emotion';
import React, { useState, useMemo, useContext, useEffect } from 'react';

import { formatMoney, formatNumber } from 'common/numbers';
import BuDropdown, {
  BuDropdownItem,
  BuDropdownItemContainer,
} from 'components/UI/BuDropdown';
import BuInput from 'components/UI/BuInput';
import BuRadio from 'components/UI/BuRadio';
import { NUMBER_OPERATION_DROPDOWN_OPTIONS } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/constants';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import { BIMetricsFilter } from 'components/dashboard/Metrics/metrics.types';

const whiteBackground = css`
  &.bu-secondary {
    background-color: var(--bu-white) !important;
  }
`;

const ALL_LABEL = 'All';

interface Props {
  templateFilter?: BIMetricsFilter;
  widgetFilterLabel: string;
  widgetFilterType: string;
  disabled?: boolean;
  updateFilters: (
    emptyFilters: boolean,
    operator: string,
    value: string | string[] | number
  ) => void;
}

export const NumberTypeFilter: React.FC<Props> = ({
  templateFilter,
  widgetFilterLabel,
  widgetFilterType,
  disabled = false,
  updateFilters,
}) => {
  const { currency } = useContext(RevBISettingsContext);

  const [numberOperatorChecked, setNumberOperatorChecked] = useState(
    NUMBER_OPERATION_DROPDOWN_OPTIONS.find(
      (el) => el.value === templateFilter?.operator
    ) ?? NUMBER_OPERATION_DROPDOWN_OPTIONS[3]
  );
  const [numberValue, setNumberValue] = useState<number | undefined>(
    (templateFilter?.value as number) ?? undefined
  );

  const numberOptionLabel = useMemo(() => {
    if (numberOperatorChecked.text === ALL_LABEL) {
      return numberOperatorChecked.text;
    }

    return (
      numberOperatorChecked &&
      numberValue &&
      `${numberOperatorChecked.symbol} ${
        widgetFilterType === 'money'
          ? formatMoney(currency, numberValue)
          : formatNumber(numberValue)
      }`
    );
  }, [numberOperatorChecked, numberValue]);

  useEffect(() => {
    const value = (templateFilter?.value as number) ?? undefined;
    const operator = templateFilter?.operator;
    if (value !== undefined) {
      setNumberValue(value);
    }
    if (
      operator &&
      NUMBER_OPERATION_DROPDOWN_OPTIONS.map((x) => x.value).includes(operator)
    ) {
      const option = NUMBER_OPERATION_DROPDOWN_OPTIONS.find(
        (el) => el.value === templateFilter.operator
      );
      if (option) {
        setNumberOperatorChecked(option);
      }
    }
  }, [templateFilter]);

  const handleNumberValueChanged = (
    numberOperator: NumberDropdownOption
  ): void => {
    updateFilters(
      !numberValue,
      numberOperator.value,
      numberOperator.text === ALL_LABEL ? 'all' : numberValue || ''
    );
  };

  const handleChangeNumberOperatorChecked = (
    option: NumberDropdownOption
  ): void => {
    setNumberOperatorChecked(option);
    if (option.text === ALL_LABEL) {
      setNumberValue(undefined);
    }
  };

  return (
    <BuDropdown
      secondary
      disabled={disabled}
      label={numberOptionLabel}
      inlineLabel={widgetFilterLabel}
      className={whiteBackground}
      onClose={() => handleNumberValueChanged(numberOperatorChecked)}
      testingLabel={`number-type-filter`}
    >
      <BuDropdownItemContainer>
        {NUMBER_OPERATION_DROPDOWN_OPTIONS.map((element) => (
          <BuDropdownItem style={{ marginBottom: '10px' }} key={element.text}>
            <BuRadio
              label={element.text}
              checked={numberOperatorChecked === element}
              onChange={() => handleChangeNumberOperatorChecked(element)}
            />
            {numberOperatorChecked === element &&
              numberOperatorChecked !==
                NUMBER_OPERATION_DROPDOWN_OPTIONS[0] && (
                <BuInput
                  code={currency}
                  onChange={(e) => {
                    if (!isNaN(e.currentTarget.valueAsNumber)) {
                      setNumberValue(e.currentTarget.valueAsNumber);
                    } else {
                      setNumberValue(undefined);
                    }
                  }}
                  value={numberValue}
                  type={widgetFilterType === 'money' ? 'currency' : 'number'}
                  style={{ margin: '6px 0 0 0' }}
                />
              )}
          </BuDropdownItem>
        ))}
      </BuDropdownItemContainer>
    </BuDropdown>
  );
};
