import styled from '@emotion/styled';
import CSS from 'csstype';

// @ts-ignore
export const FlexRow = styled.div((props: { cssProps?: CSS.Properties }) => ({
  display: 'flex',
  ...props.cssProps,
}));

export const MetricCreateSubTitle = styled.div({
  fontSize: '14px',
  fontWeight: 600,
  color: 'var(--bu-gray-900)',
  marginBottom: '8px',
  span: {
    marginRight: '5px',
  },
});

export const ExclamationIcon = styled.div({
  display: 'inline-block',
});

export const MetricInfoContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  minWidth: '232px',
});
