import { TableConfigurationColumn } from 'components/UI/TableConfig/types';
import axiosInstance from './axiosInstance';

export interface GetSettingsForTableParams {
  collectionName: string;
  tableName: string;
}

export interface TableOrder {
  column: string;
  direction: 1 | -1;
  object_field: string;
}

export interface GetSettingsForTableResponse {
  columns: TableConfigurationColumn[];
  order: TableOrder;
}

export interface GetSettingsForTableResponseData {
  data: GetSettingsForTableResponse;
}

const getSettingsUrl = (collectionName: string, tableName: string) =>
  `/api/settings/tables/${collectionName}/${tableName}`;

export const getSettingsForTable = async (
  params: GetSettingsForTableParams
) => {
  const response = await axiosInstance.get<GetSettingsForTableResponseData>(
    getSettingsUrl(params.collectionName, params.tableName)
  );

  return response.data.data;
};
