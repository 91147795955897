import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as accountActions from 'actions/accountsActions';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import AccountsDashboard from 'components/dashboard/Accounts/AccountsDashboard';
import * as selectors from 'selectors';

class AccountsScreen extends React.Component {
  componentDidMount() {
    AnalyticsTracker.event(this.props, {
      category: 'Accounts Dashboard',
      action: 'Tab: Accounts',
      label: 'Tab Opened',
    });
    AnalyticsTracker.setUserProperties(this.props);
  }

  render() {
    return <AccountsDashboard {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  user: selectors.getUser(state),
  isAdmin: selectors.isAdmin(state),
  settingsLoaded: selectors.getCompanySettingsLoaded(state),
  settings: selectors.getCompanySettings(state),
  accounts: selectors.getAccounts(state),
  status: selectors.getAccountsStatus(state),
  companyCurrency: selectors.getUserLocalCurrency(state),
});

const mapDispatchToProps = {
  fetchAccounts: accountActions.getAccounts,
  updateAccount: accountActions.updateAccount,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountsScreen));
