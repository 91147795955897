import { css } from 'emotion';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { actions } from 'actions';
import * as accountActions from 'actions/accountsActions';
import { updateViewConfig } from 'common/helpers';
import { PersistedTableItem } from 'components/UI/DealsFlatTableTS/Table/types';
import {
  IProps as AccountsListProps,
  AccountsList,
  AccountData,
} from 'components/dashboard/Accounts/AccountsList';
import viewConfig from 'components/dashboard/Accounts/getConfig';
import { Filters } from 'reducers/opportunitiesReducer/types';
import { IReduxState } from 'reducers/types';
import { getAccountHiddenColumns } from 'selectors';
import { getTablePrevState } from 'selectors/table';

type OwnProps = {
  url: string;
  title: string;
  filters: Partial<Filters>;
};

type StateProps = {
  sortAccountsPrevState: PersistedTableItem | {};
  accountHiddenColumns: string[];
};

type DispatchProps = {
  updateAccount: Function;
  persistTableParams: Function;
};

type IProps = OwnProps & StateProps & DispatchProps;

const AccountsListWrapper = (props: IProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [count, setCount] = useState<number>(0);
  const [data, setData] = useState<AccountData[]>([]);
  const [rowsPerPage, setPageSize] = useState<number>(5);
  const [currentPage, setPageNumber] = useState<number>(1);
  const [sortOrder, setSortOrder] = useState(viewConfig.defaultSortOrder);
  const [searchText, setSearchText] = useState<string>('');
  const [firstInitialisation, setFirstInitialisation] = useState<boolean>(true);

  const fetchData = async (request: any, controller: AbortController) => {
    setLoading(true);

    try {
      const response = await fetch(props.url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({ ...props.filters, ...request }),
        signal: controller.signal,
      });

      const result = await response.json();

      setData(result.accounts);
      setCount(result.total_count);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if ((err as Error).name === 'AbortError') {
        console.warn('ABORTED');
      }
    }
  };

  useEffect(() => {
    const isEmptyPrevState = !isEmpty(props.sortAccountsPrevState);

    if (
      isEmptyPrevState &&
      firstInitialisation &&
      'filter' in props.sortAccountsPrevState
    ) {
      setPageSize(props.sortAccountsPrevState.filter.page_size!);
      setPageNumber(props.sortAccountsPrevState.filter.page_number!);
      setSortOrder(props.sortAccountsPrevState.filter.sort);
      setFirstInitialisation(false);
    }
  }, [
    firstInitialisation,
    setFirstInitialisation,
    setPageSize,
    setPageNumber,
    setSortOrder,
  ]);

  useEffect(() => {
    const lastState = {
      name: props.title,
      type: 'accounts',
      filter: {
        sort: sortOrder,
        page_size: rowsPerPage,
        page_number: currentPage,
      },
    };

    return () => {
      props.persistTableParams(lastState);
    };
  }, [sortOrder, currentPage, rowsPerPage, props.title]);

  useEffect(() => {
    const controller = new AbortController();
    const query = {
      account_name: searchText || undefined,
      page_size: rowsPerPage,
      page_number: currentPage - 1,
      sort: sortOrder || undefined,
    };

    fetchData(query, controller);

    return () => {
      controller.abort();
    };
  }, [
    searchText,
    rowsPerPage,
    currentPage,
    sortOrder,
    JSON.stringify(props.filters),
    props.url,
  ]);

  const handleSort = useCallback(
    (sort?: string) => {
      setPageNumber(1);
      setSortOrder(sort);
    },
    [setPageNumber, setSortOrder]
  );

  const handleSearchChange = useCallback(
    (text: string) => {
      setSearchText(text);
      setPageNumber(1);
    },
    [setPageNumber, setSearchText]
  );

  const handlePaginationChange = useCallback(
    (page: number, pageSize: number) => {
      setPageNumber(page);
      setPageSize(pageSize);
    },
    [setPageSize, setPageNumber]
  );

  const handleChange: AccountsListProps['onChange'] = (col, row, value) => {
    props.updateAccount(row._id, {
      [col.field]: value,
    });
    setTimeout(() => {
      const controller = new AbortController();
      const query = {
        account_name: searchText || undefined,
        page_size: rowsPerPage,
        page_number: currentPage - 1,
        sort: sortOrder || undefined,
      };
      fetchData(query, controller);
    }, 2000);
  };

  const accountNameCellColor = css`
    color: #0762ec;
    font-family: var(--bu-font-regular);
    font-weight: 600;
  `;

  viewConfig.columns['account name'].config.className = accountNameCellColor;

  updateViewConfig(viewConfig, props.accountHiddenColumns, 'columns', 'hidden');

  return (
    <AccountsList
      loading={loading}
      title={props.title}
      config={viewConfig.columns}
      accounts={{
        data,
        total_count: count,
      }}
      sortOrder={sortOrder}
      onSort={handleSort}
      searchText={searchText}
      onSearchChange={handleSearchChange}
      onChange={handleChange}
      rowsPerPage={rowsPerPage}
      currentPage={currentPage}
      onPaginationChange={handlePaginationChange}
      hidePaginationEnd
      hideSearch
    />
  );
};

const mapDispatchToProps: DispatchProps = {
  updateAccount: accountActions.updateAccount,
  persistTableParams: actions.ui.table.persist,
};

const mapStateToProps = (state: IReduxState, props: OwnProps) => ({
  sortAccountsPrevState: getTablePrevState(state, 'accounts', props.title),
  accountHiddenColumns: getAccountHiddenColumns(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountsListWrapper);
