import { css } from 'emotion';

export const container = css`
  margin-bottom: 24px;
`;

export const optionContainer = css`
  margin-bottom: 12px;
`;

export const radiosContainer = css`
  margin-bottom: 24px;
`;

export const infoParagraphRadio = css`
  font-size: 12px;
  color: var(--bu-gray-600);
  padding-left: 26px;
`;
