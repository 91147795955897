import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getChartOptions } from 'components/dashboard/ForecastDashboard/TrackingDashboard/Chart/helper';
import { chartContainer } from 'components/dashboard/ForecastDashboard/TrackingDashboard/Chart/styles';
import { IProps } from 'components/dashboard/ForecastDashboard/TrackingDashboard/Chart/types';
import * as selectors from 'selectors';

const Chart: React.FC<IProps> = ({
  series,
  data,
  yAxisWidth,
  pointWidth,
  fluid = false,
}) => {
  const companyCurrencyCode = useSelector(selectors.getUserLocalCurrency);
  const options = useMemo(
    () =>
      getChartOptions({
        series,
        stat: data,
        yAxisWidth,
        pointWidth,
        fluid,
        companyCurrencyCode,
      }),
    [
      JSON.stringify(series),
      JSON.stringify(data),
      yAxisWidth,
      pointWidth,
      fluid,
    ]
  );

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      immutable
      containerProps={{ className: chartContainer }}
    />
  );
};

export default Chart;
