import stage_down_arrow from '../../../../../assets/images/stage_down_arrow.svg';
import stage_up_arrow from '../../../../../assets/images/stage_up_arrow.svg';
import styles from '../styles';
import { Formatter } from './types';
import classNames from 'classnames';
import moment from 'moment';
import { isNil } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { Popup } from 'semantic-ui-react';

import { ValueProp } from 'components/UI/common/TypedTable/TypedTable';
import { IDeltaValueType } from 'components/UI/common/TypedTable/renderers/common/DeltaValueCell';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

const isDelta = (
  value: ValueProp | IDeltaValueType
): value is IDeltaValueType =>
  value instanceof Object && 'value' in (value as IDeltaValueType);

export const getDeltaValue = (value: ValueProp | IDeltaValueType): ValueProp =>
  isDelta(value) ? (value.value as ValueProp) : value;

interface IProps {
  isForecast: boolean;
  isCurrency?: boolean;
  fieldName: string;
  options?: string[];
  data?: Common.DeltaValueNumber | Common.DeltaValueString;
  formatter?: Formatter;
  dotted?: boolean;
  children?: any;
}

const DeltaValue = ({
  data,
  isCurrency = false,
  children,
  formatter,
  isForecast,
  options,
  dotted,
}: IProps) => {
  if (isNil(data)) {
    return (
      <div
        className={classNames({
          'allDeals-table-body-column-other': !isCurrency,
          [styles.columnLabel]: true,
        })}
      >
        {children}
      </div>
    );
  }

  const value = formatter === undefined ? data.value : formatter(data.value);
  const prev_value =
    data.prev_value === undefined
      ? null
      : data.prev_value !== null
      ? formatter === undefined
        ? data.prev_value
        : formatter(data.prev_value)
      : null;

  if (
    !isForecast ||
    (prev_value === null && value !== null && value.toString().length < 25)
  ) {
    return (
      <div
        className={classNames({
          'allDeals-table-body-column-other': !isCurrency,
          [styles.columnLabel]: true,
        })}
      >
        {children}
      </div>
    );
  }

  const dateToNumber = (s: string): number => new Date(s).getTime();
  const getArrIndex = (c: string, arr: string[] = options || []): number =>
    arr.indexOf(c);

  const trigger =
    data.prev_value !== null ? (
      <div
        className={classNames({
          [styles.pointer]: true,
          [styles.arrowWrapper]: !dotted,
          [styles.dotWrapper]: dotted,
        })}
      >
        {!dotted && data.value > data.prev_value && (
          <img alt="up-arrow" src={stage_up_arrow} />
        )}
        {!dotted && data.value < data.prev_value && (
          <img alt="down-arrow" src={stage_down_arrow} />
        )}
        {dotted && (
          <>
            {dateToNumber(data.value as string) >
              dateToNumber(data.prev_value as string) && (
              <img alt="up-arrow" src={stage_down_arrow} />
            )}
            {dateToNumber(data.value as string) <
              dateToNumber(data.prev_value as string) && (
              <img alt="down-arrow" src={stage_up_arrow} />
            )}
            {getArrIndex(data.value as string) >
              getArrIndex(data.prev_value as string) && (
              <img alt="down-arrow" src={stage_up_arrow} />
            )}
            {getArrIndex(data.value as string) <
              getArrIndex(data.prev_value as string) && (
              <img alt="up-arrow" src={stage_down_arrow} />
            )}
          </>
        )}
      </div>
    ) : null;

  const content = (
    <div className="allDeals-indicator-popup-window">
      {prev_value !== null ? (
        <div className={styles.popupText}>
          <div>
            Changed from{' '}
            <span className={styles.popupTextBold}>{prev_value}</span> to{' '}
            <span className={styles.popupTextBold}>{value}</span>
          </div>
          <div>
            changed on{' '}
            <span className={styles.popupTextBold}>
              {moment(data.updated_at, 'YYYY-MM-DD').format(" Do MMM 'YY")}
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );

  return (
    <div className={styles.deltaValueWrapper}>
      <div
        className={classNames({
          'allDeals-table-body-column-other': !isCurrency,
          [styles.columnLabel]: true,
          [styles.pointer]: true,
        })}
      >
        {children}
      </div>
      <Popup
        hoverable
        position="bottom center"
        trigger={trigger}
        content={content}
        offset={[0, 0]}
      />
    </div>
  );
};

const mapStateToProps = (state: IReduxState): Object => ({
  options: selectors.getFieldOptions(state, 'forecast_category'),
});

export default connect(mapStateToProps)(DeltaValue);
