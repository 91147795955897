import React from 'react';

import {
  FixedWidthContainer,
  FlexGrowContainer,
} from 'components/dashboard/Metrics/Create/Conditions/Condition/styles';
import { DateOperatorOption } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/types';
import { ForecastPeriodCalendar } from 'components/dashboard/Metrics/common/ForecastPeriodCalendar/ForecastPeriodCalendar';
import { FlexRow } from 'components/dashboard/Metrics/metrics.styles';
import { BIMetricsFilter } from 'components/dashboard/Metrics/metrics.types';

interface Props {
  selectedValue?: string;
  timePeriod: string;
  onValueChange: (newFilter: Partial<BIMetricsFilter>) => void;
}

export const ForecastPeriodTypeCondition: React.FC<Props> = ({
  selectedValue,
  timePeriod,
  onValueChange,
}) => {
  const handleDateValueChange = (change: DateOperatorOption) => {
    const selectedValue = change.selected[0].id;
    onValueChange({
      operator: change.operator,
      value: selectedValue,
    });
  };

  return (
    <FlexRow cssProps={{ alignItems: 'center', marginRight: '42px' }}>
      <FixedWidthContainer width={20}>In</FixedWidthContainer>
      <FlexGrowContainer data-testing="forecast-period-container">
        <ForecastPeriodCalendar
          forecastPeriod={timePeriod}
          selectedValue={selectedValue}
          title="Forecast Period"
          onValueChange={handleDateValueChange}
        />
      </FlexGrowContainer>
    </FlexRow>
  );
};
