import { INavigationSchemes } from 'navigation/types';

export const navigationSchemes: INavigationSchemes = {
  forecast: {
    forecast__dashboard: {
      scheme: '/forecast/dashboard',
      title: 'Dashboard',
    },
    forecast__trends: {
      scheme: '/forecast/analytics',
      title: 'Trends',
    },
    forecast__pacing: {
      scheme: '/forecast/pacing',
      title: 'Pacing',
    },
    forecast__rollups: {
      scheme: '/forecast/roll-ups',
      title: 'Roll Ups',
    },
  },
  opportunities: {
    opportunities__dashboard: {
      scheme: '/opportunities',
      title: 'Opportunities',
    },
  },
  accounts: {
    accounts__dashboard: {
      scheme: '/accounts/all',
      title: 'Accounts',
    },
  },
  calls: {
    calls__upcoming_calls: {
      scheme: '/calls/upcoming',
      title: 'Upcoming Calls',
    },
    calls__completed_calls: {
      scheme: '/calls',
      title: 'Completed Calls',
    },
  },
  pipeline: {
    pipeline__dashboard: {
      scheme: '/pipeline/dashboard',
      title: 'Dashboard',
    },
    pipeline__risk_analytics: {
      scheme: '/pipeline/analytics',
      title: 'Risk Analytics',
    },
    pipeline__deals_progression: {
      scheme: '/pipeline/deals-progression',
      title: 'Deals Progression',
    },
  },
  targets: {
    targets__forecast: {
      scheme: '/targets/forecast',
      title: 'Forecast Targets',
    },
    targets__pipeline_creation: {
      scheme: '/targets/pipeline-creation',
      title: 'Pipeline Creation Targets',
    },
    targets__pipeline_coverage: {
      scheme: '/targets/pipeline-coverage',
      title: 'Pipeline Coverage Targets',
    },
  },
  activity: {
    activity__dashboard: {
      scheme: '/dashboards/0',
      title: 'Leaderboard',
    },
    activity__scorecard: {
      scheme: '/scorecard',
      title: 'Scorecard',
    },
  },
  meetings: {
    meetings__upcoming: {
      scheme: '/meetings',
      title: 'Upcoming meetings',
    },
    meetings__completed: {
      scheme: '/meetings/completed',
      title: 'Completed Meetings',
    },
  },
  dashboards: {
    dashboards__forecast: {
      scheme: '/dashboard/forecast',
      title: 'Forecast',
    },
    dashboards__trends: {
      scheme: '/dashboard/trends',
      title: 'Trends',
    },
    dashboards__deals_progression: {
      scheme: '/dashboard/deals-progression',
      title: 'Deals Progression',
    },
    dashboards__pacing: {
      scheme: '/dashboard/pacing',
      title: 'Pacing',
    },
    dashboards__pipeline: {
      scheme: '/dashboard/pipeline',
      title: 'Pipeline',
    },
    dashboards__risk_analytics: {
      scheme: '/dashboard/risk_analytics',
      title: 'Risk Analytics',
    },
    dashboards__leaderboard: {
      scheme: '/dashboard/leaderboard',
      title: 'Leaderboard',
    },
    dashboards__scorecard: {
      scheme: '/dashboard/scorecard',
      title: 'Scorecard',
    },
    dashboards__seller: {
      scheme: '/dashboard/seller',
      title: 'Seller',
    },
  },
  RevBI: {
    scheme: '/revbi',
    title: 'Revbi',
  },
  prospect_account: {
    scheme: '/prospect-account-dashboard',
    title: 'Prospect Account',
  },
  todos: {
    scheme: '/reps-recommendations',
    title: "To do's",
  },
  sellers: {
    scheme: '/dashboards/2',
    title: 'Seller',
  },
  settings: {
    scheme: '/settings',
    title: 'Seller',
  },
};
