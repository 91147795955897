import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';

import { formatMoney } from 'common/numbers';
import { headers } from 'components/activity/base';
import * as s from 'components/chart-dashboards/MainIndicators/styles';
import { IndicatorComponentProps } from 'components/chart-dashboards/MainIndicators/types';
import * as selectors from 'selectors';

const IndicatorItem: React.FC<IndicatorComponentProps> = ({
  apiUrls,
  title,
}) => {
  const companyCurrencyCode = useSelector(selectors.getUserLocalCurrency);

  const [data, setData] = useState<{
    count?: number;
    total_amount?: number;
  }>({});

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const url = apiUrls[0];

    const response = await fetch(url.api_url, {
      headers: headers,
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify(url.api_params || {}),
    });

    const result = await response.json();
    setData(result.data);
  }

  return (
    <Grid.Column textAlign="center">
      <span className={s.indicator_label}>{title}</span>
      <div className={s.evaluation}>
        <div className={s.main_assessment}>
          {data.total_amount !== 0 && data.total_amount
            ? `${formatMoney(companyCurrencyCode, data.total_amount, 0)} (${
                data.count
              })`
            : data.count}
        </div>
      </div>
    </Grid.Column>
  );
};

export default IndicatorItem;
