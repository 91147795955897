import styled from '@emotion/styled';
import { FlipModifier } from '@popperjs/core/lib/modifiers/flip';
import { OffsetModifier } from '@popperjs/core/lib/modifiers/offset';
import classNames from 'classnames';
import { css } from 'emotion';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import {
  Manager,
  Modifier,
  Popper,
  Reference,
  PopperProps,
} from 'react-popper';

import BuButton, { BuControlSize, IButtonProps } from 'components/UI/BuButton';

const DropdownContentWrapper = styled.div`
  z-index: 1000;
  width: fit-content;
`;

const DropdownContentBox = styled.div`
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  background-color: white;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`;

const InlineLabel = styled.span`
  font-weight: 600;
  margin-right: 5px;
`;

type ChildrenDropdownCallbackFunction = (close: () => void) => React.ReactNode;

type IDropdownChildren = {
  children: React.ReactNode | ChildrenDropdownCallbackFunction;
};

export const DropdownItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: fit-content;
  max-height: 200px;
  overflow-y: auto;
`;

export const DropdownItem = styled.button<{ active?: boolean }>`
  padding: 6px 15px;
  outline: none;
  border: none;
  background-color: transparent;
  text-align: inherit;
  white-space: nowrap;
  height: fit-content;
  font-size: 14px;

  ${({ active }) =>
    active &&
    `
    background-color: var(--bu-primary-200);
    font-weight: bold;

  `};

  &.multiselect {
    background-color: transparent;
    font-weight: normal;
  }

  &:hover {
    background-color: var(--bu-primary-200);
  }
`;

const fullWidthStyle = css`
  min-width: 100%;
`;

export const customIconDropdown = css`
  border: none;
  padding: 0 !important;

  i {
    font-size: 18px !important;
  }

  &:hover {
    background-color: var(--bu-white) !important;
  }

  .bu-icon-triangleDown,
  .bu-icon-triangleUp {
    display: none;
  }
`;

const secondaryStyle = css`
  &.bu-secondary {
    color: var(--bu-gray-900) !important;
    border-color: var(--bu-table-border-color) !important;
    font-weight: 600;

    &:not(.bu-dropdown) {
      .bu-btn-content {
        justify-content: center;
      }
    }

    .bu-btn-content {
      display: flex;
      justify-content: space-between;
      width: auto;

      label {
        width: max-content;
        cursor: pointer;
      }

      .dropdown-icon {
        margin-left: 8px;
      }
    }

    span,
    .bu-dropdown-label {
      font-weight: 400;
    }
  }
`;

const modifiers: ReadonlyArray<Modifier<string>> = [
  {
    name: 'offset',
    enabled: true,
    options: {
      offset: [0, 3],
    },
  } as OffsetModifier,
  {
    name: 'flip',
  } as FlipModifier,
];

export type DropdownButtonProps = {
  icon?: boolean;
  inlineLabel?: string;
  className?: string;
  label: React.ReactNode;
  secondary?: boolean;
  borderless?: boolean;
  tooltip?: string;
  disabled?: boolean;
  readOnly?: boolean;
  size?: BuControlSize;
  attachTo?: Element;
  fullWidth?: boolean;
  onClose?(): void;
  onOpen?(): void;
  placement?: PopperProps<any>['placement'];
  noDropdownIcon?: IButtonProps['noDropdownIcon'];
  iconDropdown?: boolean;
};

export type BuDropdownProps = Omit<DropdownButtonProps, 'children'> &
  IDropdownChildren &
  Common.DataTestingType;

type TriggerProps = {
  isOpen: boolean;
  ref: React.RefCallback<HTMLElement>;
  open: () => void;
  close: () => void;
};

type InlineDropdownProps = {
  attachTo?: Element;
  fullWidth?: boolean;
  placement?: PopperProps<any>['placement'];
  trigger: (props: TriggerProps) => React.ReactNode;
  children: React.ReactNode | ChildrenDropdownCallbackFunction;
  onOpen?(): void;
  onClose?(): void;
} & Common.DataTestingType;

/**
 * Dropdown for any Html element
 * @todo consider to move that to separate component
 */
export const BuDropdownWrapper: React.FC<InlineDropdownProps> = ({
  trigger,
  children,
  attachTo,
  fullWidth,
  placement = 'bottom-start',
  onClose,
  onOpen,
  testingLabel,
}) => {
  const referenceRef = useRef<HTMLElement | null>(null);
  const popperRef = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpenPopup = useCallback(() => {
    if (isOpen) {
      onClose?.();
    } else {
      onOpen?.();
    }
    setOpen(!isOpen);
  }, [setOpen, isOpen, onOpen, onClose]);

  const handleClosePopup = useCallback(() => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  }, [setOpen, onClose]);

  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (
        referenceRef?.current?.contains(event.target as HTMLElement) ||
        popperRef?.current?.contains(event.target as HTMLElement)
      ) {
        return;
      }

      isOpen && handleClosePopup();
    };

    document.addEventListener('mousedown', listener);

    return function () {
      document.removeEventListener('mousedown', listener);
    };
  }, [handleClosePopup, referenceRef, popperRef, isOpen]);

  const DropdownContent = useCallback<React.FC<IDropdownChildren>>(
    ({ children }) => (
      <Popper placement={placement} modifiers={modifiers} innerRef={popperRef}>
        {({ placement, ref, style }) => (
          <DropdownContentWrapper
            ref={ref}
            data-placement={placement}
            style={style}
            data-testing={`${testingLabel}-Wrapper`}
            className={classNames('bu-dropdown-content-wrapper', {
              [fullWidthStyle]: fullWidth,
            })}
          >
            <DropdownContentBox
              data-testing={`${testingLabel}-Content`}
              className="bu-dropdown-content-box"
            >
              {typeof children === 'function'
                ? (children as ChildrenDropdownCallbackFunction)(
                    handleClosePopup
                  )
                : children}
            </DropdownContentBox>
          </DropdownContentWrapper>
        )}
      </Popper>
    ),
    [popperRef, testingLabel]
  );

  return (
    <Manager>
      <Reference>
        {({ ref }) =>
          trigger({
            ref(el) {
              referenceRef.current = el;
              (ref as React.RefCallback<HTMLElement>)(el);
            },
            open: handleOpenPopup,
            close: handleClosePopup,
            isOpen,
          })
        }
      </Reference>
      {isOpen &&
        (attachTo ? (
          ReactDOM.createPortal(
            <DropdownContent>{children}</DropdownContent>,
            attachTo
          )
        ) : (
          <DropdownContent>{children}</DropdownContent>
        ))}
    </Manager>
  );
};
const COMPONENT_TESTING_NAME = 'BuDropDown';
/**
 * It is a sugar now for BuButton and BuDropDownWrapper
 */
const BuDropdown: React.FC<BuDropdownProps> = ({
  label,
  secondary,
  borderless,
  tooltip,
  size = BuControlSize.REGULAR,
  disabled,
  readOnly,
  children,
  attachTo,
  fullWidth,
  inlineLabel,
  onClose,
  onOpen,
  icon,
  placement = 'bottom-start',
  noDropdownIcon,
  className,
  testingLabel = '',
  iconDropdown = false,
}) => {
  const componentDataTestingKey = testingLabel
    ? `${testingLabel}-${COMPONENT_TESTING_NAME}`
    : COMPONENT_TESTING_NAME;
  return (
    <BuDropdownWrapper
      placement={placement}
      onClose={onClose}
      onOpen={onOpen}
      attachTo={attachTo}
      testingLabel={componentDataTestingKey}
      fullWidth={fullWidth}
      trigger={({ ref, open, isOpen }) => (
        <BuButton
          innerRef={ref as React.RefCallback<HTMLButtonElement>}
          secondary={secondary}
          borderless={borderless}
          size={size}
          tooltip={tooltip}
          dropdown={isOpen ? 'open' : 'close'}
          noDropdownIcon={noDropdownIcon}
          onMouseDown={open}
          disabled={disabled}
          readOnly={readOnly}
          className={classNames(className, secondaryStyle, {
            [fullWidthStyle]: fullWidth,
            [customIconDropdown]: iconDropdown,
          })}
          icon={icon}
          testingLabel={componentDataTestingKey}
        >
          {inlineLabel && <InlineLabel>{inlineLabel}:</InlineLabel>}
          <label>{label}</label>
        </BuButton>
      )}
    >
      {children}
    </BuDropdownWrapper>
  );
};
export default BuDropdown;
