import styled from '@emotion/styled/macro';
import React, { useState } from 'react';

import BuDropdown, {
  BuDropdownItemContainer,
  BuDropdownSearchItem,
  BuDropdownItemGroup,
} from 'components/UI/BuDropdown';
import Checkbox from 'components/UI/Checkbox';
import Scrolling from 'components/UI/common/Scrolling/Scrolling';
import { AvailableFieldEntry } from 'components/settings/ManageTableColumns/ManageTableColumns';

const DropdownItem = styled.div`
  padding: 10px 15px 5px;
  background-color: transparent;
  word-break: break-all;
`;

interface IProps {
  availableFields: AvailableFieldEntry[];
  columns: string[];
  onChange(field: AvailableFieldEntry): void;
}

const ColumnFilterDropdown: React.FC<IProps> = ({
  availableFields,
  onChange,
  columns,
}: IProps) => {
  const [fieldsDropdownFilter, setFieldsDropdownFilter] = useState('');

  const sortedAndFilteredFields: AvailableFieldEntry[] = availableFields
    .sort((l, r) => l.display_name.localeCompare(r.display_name))
    .filter((field) =>
      field.display_name
        .toLocaleLowerCase()
        .includes(fieldsDropdownFilter.toLocaleLowerCase())
    );

  return (
    <BuDropdown
      onClose={() => setFieldsDropdownFilter('')}
      label="Add"
      placement="bottom-start"
    >
      <BuDropdownItemContainer>
        <BuDropdownItemGroup>
          <Scrolling
            alwaysShow
            shadows
            maxHeight={400}
            width={300}
            height
            shadowTopOffset={32}
          >
            <BuDropdownSearchItem
              value={fieldsDropdownFilter}
              onChange={(e) => setFieldsDropdownFilter(e.target.value)}
              data-testing="txt_field"
            />

            {sortedAndFilteredFields.map((field: AvailableFieldEntry) => {
              const checked = !!columns.find(
                (column) => column === field.display_name
              );

              return (
                <DropdownItem
                  key={`${field.display_name}-${field.object_field}`}
                >
                  <Checkbox
                    label={field.display_name}
                    name={field.display_name}
                    checked={checked}
                    value={field.object_field}
                    id={`${field.display_name}-${field.object_field}`}
                    onChange={onChange(field)}
                  />
                </DropdownItem>
              );
            })}
          </Scrolling>
        </BuDropdownItemGroup>
      </BuDropdownItemContainer>
    </BuDropdown>
  );
};

export default ColumnFilterDropdown;
