import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'actions';
import {
  ForecastSubmissionSettingItemReduxState,
  ForecastSubmissionSettingsDealsItemReduxState,
} from 'common/types/forecastSubmission';
import { IdType } from 'components/UI/common/TypedTable/TypedTable';
import BasePanel from 'components/dashboard/Forecast/Dashboard/SubmitForecast/BasePanel';
import History from 'components/dashboard/Forecast/Dashboard/SubmitForecast/History';
import {
  getIncludedDeals,
  getExcludedDeals,
} from 'components/dashboard/Forecast/Dashboard/SubmitForecast/helper';
import {
  AllPersisted,
  Formatter,
  HistoryPanelType,
} from 'components/dashboard/Forecast/Dashboard/SubmitForecast/types';
import {
  getForecastHistoryPanel,
  getForecastPersisted,
  getForecastSubmissionSettings,
} from 'selectors';

type Props = {
  onClose: () => void;
  formatter: Formatter;
  onRecalculate?: () => void;
};

const SubmitForecast: React.FC<Props> = ({
  onClose,
  formatter,
  onRecalculate,
}) => {
  const dispatch = useDispatch();

  const [includedDeals, setIncludedDeals] = useState<{
    [key: string]: string[];
  }>({});

  const [excludedDeals, setExcludedDeals] = useState<{
    [key: string]: string[];
  }>({});

  const historyPanel: HistoryPanelType = useSelector(getForecastHistoryPanel);
  const persisted: AllPersisted = useSelector(getForecastPersisted);

  const forecastSettings: ForecastSubmissionSettingItemReduxState[] =
    useSelector(getForecastSubmissionSettings);

  const resetIncludedAndExcludedDeals = (
    submissionSettingId: string,
    deals: ForecastSubmissionSettingsDealsItemReduxState[]
  ) => {
    const activeSettings = forecastSettings.find(
      (setting) => setting.id === submissionSettingId
    );

    if (activeSettings) {
      const { include_exclude_supported } = activeSettings;
      setIncludedDeals((prevState) => ({
        ...prevState,
        [submissionSettingId]: getIncludedDeals(
          deals,
          include_exclude_supported
        ),
      }));

      setExcludedDeals((prevState) => ({
        ...prevState,
        [submissionSettingId]: getExcludedDeals(
          deals,
          include_exclude_supported
        ),
      }));
    }
  };

  const onIncludedDealsChange = useCallback(
    (
      includedDealsIds: IdType[],
      excludedDealsIds: IdType[],
      activeSettingsId: string
    ) => {
      let tempExcludedDealsIds = excludedDealsIds;

      // check if is not exclude deals.
      if (!tempExcludedDealsIds.length) {
        tempExcludedDealsIds = includedDeals[activeSettingsId].filter(
          (deal) => !includedDealsIds.includes(deal)
        );
      }

      const _dealsToExclude = [
        ...(excludedDeals[activeSettingsId] || []),
        ...tempExcludedDealsIds.filter(
          (id) => !excludedDeals[activeSettingsId]?.includes(id as string)
        ),
      ].filter((id) => !includedDealsIds.includes(id));

      const _excludedDeals = {
        ...excludedDeals,
        [activeSettingsId]: _dealsToExclude as string[],
      };

      setExcludedDeals(_excludedDeals);

      const submissionIncludedDeals = forecastSettings
        .find((setting) => setting.id === activeSettingsId)
        ?.deals?.deals?.map((deal) => deal._id);

      const _includedDeals = [
        ...(submissionIncludedDeals?.filter(
          (item) =>
            !(
              _excludedDeals[activeSettingsId].includes(item) ||
              includedDealsIds.includes(item)
            )
        ) || []),
        ...includedDealsIds,
      ];

      setIncludedDeals({
        ...includedDeals,
        [activeSettingsId]: _includedDeals as string[],
      });
    },
    [forecastSettings, excludedDeals, includedDeals]
  );

  if (historyPanel) {
    return (
      <History
        formatter={formatter}
        onBack={() => dispatch(actions.ui.manageForecast.setHistoryPanel(null))}
        onClose={onClose}
        panel
        settingId={historyPanel.settingsId}
      />
    );
  }

  return (
    <BasePanel
      formatter={formatter}
      includedDeals={includedDeals}
      excludedDeals={excludedDeals}
      onClose={onClose}
      onIncludedDealsChange={onIncludedDealsChange}
      resetIncludedAndExcludedDeals={resetIncludedAndExcludedDeals}
      persisted={persisted}
      onRecalculate={onRecalculate}
    />
  );
};

export default SubmitForecast;
