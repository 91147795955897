import { css } from 'emotion';

export const options_box_element = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding: 4px 8px;
  margin: 1px 0;
  cursor: pointer;
  color: var(--bu-gray-900);
  font-family: var(--bu-font-regular);
  position: relative;
  height: 32px;
  border-left: 3px solid white;

  &:hover {
    background: var(--bu-gray-200);
    border-color: var(--bu-gray-200);

    div {
      display: flex;
    }

    span {
      margin-right: 0px !important;
    }
  }

  &.active {
    background: var(--bu-primary-200);
    border-color: var(--bu-primary-500);
  }

  &:last-child {
    margin-bottom: 14px;
  }
`;

export const element_container = css`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const view_name = css`
  padding: 0 16px;
  margin-right: 56px;
  flex: 1;

  &.active {
    font-family: var(--bu-font-medium);
  }
`;

export const sharedIcon = css`
  margin-left: 3px;
`;
