import { css } from 'emotion';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import truncate from 'lodash/truncate';
import React from 'react';
import { Popup } from 'semantic-ui-react';

import { IProps } from 'components/UI/TruncatedText/types';

const customPopup = css`
  top: 10px !important;
  padding: 5px 12px !important;
  background: #4a4a4a !important;
  border-radius: 2px !important;

  &:before {
    display: none;
  }
`;

const Truncate: React.FC<IProps> = ({
  inverted,
  length,
  omission,
  onClick,
  text,
  textClassName,
}) =>
  !isString(text) || !isNumber(length) || get(text, 'length', 0) <= length ? (
    <span className={textClassName} onClick={onClick}>
      {text}
    </span>
  ) : (
    <Popup
      className={customPopup}
      content={<span style={{ color: 'white' }}>{text}</span>}
      inverted={inverted}
      position="bottom center"
      trigger={
        <span className={textClassName} onClick={onClick}>
          {truncate(text, { length, omission })}
        </span>
      }
    />
  );

Truncate.defaultProps = {
  length: 40,
  omission: '...',
};

export default Truncate;
