import { css } from 'emotion';

export const ofPages = css`
  font-family: var(--bu-font-regular);
  font-size: 14px;
  line-height: 32px;
  margin: 0 8px;
`;

export const container = css`
  display: flex;
  align-items: center;
`;

export const dropdown_box = css`
  display: flex;
  align-items: center;

  font-family: var(--bu-font-regular);
  font-size: 14px;
  color: #9b9b9b;

  &:focus {
    border: 1px solid #0762ec;
  }
`;

export const dropdown = css`
  &.ui.dropdown {
    color: #4a4a4a;
    padding: 0 5px; /* reset boostrap */
    > .dropdown.icon {
      height: auto;
      padding: 0; /* reset boostrap */
    }
  }
`;

export const wrapper = css`
  display: flex;
  align-items: center;

  font-family: var(--bu-font-regular);
  font-size: 14px;
  color: #9b9b9b;
`;

export const activePage = css`
  width: 24px;
  text-align: center;
  border: none;
  border-bottom: 1px solid var(--bu-gray-300);
  color: #4a4a4a;

  &:focus {
    border-bottom-color: #0762ec;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;
