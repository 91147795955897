import { Series } from '../TrackingDashboard/types';
import { getSeriesColor } from './constants';
import classNames from 'classnames';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Dimmer, Form, Loader } from 'semantic-ui-react';

import BuButtonRefresh from 'components/UI/BuButtonRefresh';
import BuCheckbox from 'components/UI/BuCheckbox';
import SeriesLabel from 'components/dashboard/ForecastDashboard/TrackingDashboard/SeriesLabel';
import { getLabelIcon } from 'components/dashboard/ForecastDashboard/TrackingDashboard/helpers';
import QuarterSummary from 'components/dashboard/ForecastDashboard/Widget3/QuarterSummary';
import { getPlotOptions } from 'components/dashboard/ForecastDashboard/Widget3/helper';
import * as styles from 'components/dashboard/ForecastDashboard/Widget3/styles';
import { IProps } from 'components/dashboard/ForecastDashboard/Widget3/types';
import { useBoundary } from 'components/hooks/useBoundary';
import { useLocalStorage } from 'components/hooks/useLocalStorage';
import { getUser } from 'selectors';

type ICheckboxProps = {
  checked: boolean;
  onCheck: (name: string) => void;
  name: string;
};

const Checkbox: React.FC<ICheckboxProps> = ({
  checked,
  onCheck,
  children,
  name,
}) => (
  <Form.Checkbox
    className={styles.customCheckbox}
    checked={checked}
    onClick={() => onCheck(name)}
    label={{ children }}
  />
);

const Widget: React.FC<IProps> = ({
  companyCurrency,
  filters,
  getForecastPaceQuarterly,
  isLoading,
  position,
  quarters,
  periods,
  cacheDate,
  selectedBusinessType,
  isModal = false,
}) => {
  const isFirstLoad = useBoundary(position);
  const usedParams = useRef<string>('');
  const [refreshCache, setRefreshCache] = useState(false);
  const { email: userEmail } = useSelector(getUser);
  const [visibleCheckboxes, setVisibleCheckboxes] = useLocalStorage<string[]>(
    `Widget.${userEmail}`,
    ['target', 'commit', 'best_case', 'pipeline', 'booked', 'lost']
  );

  const serializedQueryParams = useMemo(
    () => ({
      business_type_name: selectedBusinessType,
      managers: filters.sales_managers,
      custom_filters: filters.custom_filters,
      opportunity_types: filters.opportunity_types,
      opportunity_stages: filters.opportunity_stages,
    }),
    [
      JSON.stringify(filters.sales_managers),
      JSON.stringify(filters.custom_filters),
      JSON.stringify(filters.opportunity_types),
      JSON.stringify(filters.opportunity_stages),
      selectedBusinessType,
    ]
  );

  useEffect(() => {
    if (
      !isFirstLoad &&
      (refreshCache ||
        usedParams.current !== JSON.stringify(serializedQueryParams))
    ) {
      usedParams.current = JSON.stringify(serializedQueryParams);
      getForecastPaceQuarterly(serializedQueryParams, refreshCache);
      setRefreshCache(false);
    }
  }, [isFirstLoad, serializedQueryParams, refreshCache]);

  const updatedQuarters = useMemo(
    () => quarters.filter((q) => visibleCheckboxes.includes(q.name)),
    [quarters, visibleCheckboxes]
  );

  const options = useMemo(
    () => getPlotOptions(updatedQuarters, companyCurrency, periods),
    [updatedQuarters, companyCurrency, periods]
  );

  const handleVisibleCheckboxes = (name: string) => {
    if (visibleCheckboxes.includes(name)) {
      const newCheckboxes = visibleCheckboxes.filter((c) => c !== name);
      setVisibleCheckboxes(newCheckboxes);
    } else {
      setVisibleCheckboxes([...visibleCheckboxes, name]);
    }
  };

  const renderGrid = (
    <div className={classNames(styles.widgetTable, { widget: isModal })}>
      <div className={classNames('grid-column', styles.mainColumn)}>
        <div className="grid-cell grid-header" />
        {quarters.map((q, i) => {
          const customOptions =
            q.name === 'target'
              ? { dashStyle: 'Dash', type: 'line' }
              : { type: 'column' };

          return (
            <div key={q.name} className="grid-cell">
              <label>
                <SeriesLabel
                  color={getSeriesColor(q.name, i)}
                  icon={getLabelIcon({
                    ...customOptions,
                    color: getSeriesColor(q.name, i),
                    visible: true,
                    field: q.name,
                    filterable: true,
                    name: q.name,
                  } as Series)}
                >
                  {q.display_name || q.name}
                </SeriesLabel>
              </label>
            </div>
          );
        })}
      </div>

      {periods &&
        periods.map((period, index) => (
          <QuarterSummary
            key={period}
            colIndex={index}
            quarters={quarters}
            period={period}
            companyCurrency={companyCurrency}
            currentQuarter={periods.length - 1 === index}
          />
        ))}
    </div>
  );

  /* FIXME: `isModal` prop is probably redundant here, not sure we're using it somewhere
   * I'll keep it here in case we need it
   */
  if (isModal) {
    return (
      <div className={styles.modal_container}>
        <div className={styles.modal_title}>
          How are we doing today vs same day in the previous quarters?
        </div>

        <Dimmer.Dimmable dimmed={isLoading}>
          <div
            className={classNames(styles.modal_widget_container, {
              widget: isModal,
            })}
          >
            <div className={styles.modal_checkboxes}>
              {quarters.map((q) => (
                <BuCheckbox
                  key={q.name}
                  checked={visibleCheckboxes.includes(q.name)}
                  onChange={() => handleVisibleCheckboxes(q.name)}
                  elementLabel={q.display_name || q.name}
                />
              ))}
            </div>

            <div className={styles.chartContainerWidget}>
              <HighchartsReact
                highcharts={Highcharts}
                options={options}
                immutable
              />
            </div>
            {renderGrid}
          </div>

          <Dimmer active={isLoading} inverted>
            <Loader />
          </Dimmer>
        </Dimmer.Dimmable>
      </div>
    );
  }

  return (
    <div className={styles.marginTop}>
      <Dimmer.Dimmable dimmed={isLoading}>
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            <div className={classNames(styles.headerRow, 'bu-font-subtitle')}>
              How are we doing today vs same day in the previous quarters?
            </div>
            <div>
              <BuButtonRefresh
                cacheDate={cacheDate}
                status={refreshCache}
                onClick={() => setRefreshCache(true)}
              />
            </div>
          </div>
          <div className={styles.widgetContainer}>
            <div className={styles.widgetActions}>
              <Form>
                <Form.Group
                  inline
                  className={classNames(
                    styles.resetMargins,
                    styles.fieldSpacing
                  )}
                >
                  {quarters.map((q, i) => {
                    const customOptions =
                      q.name === 'target'
                        ? { dashStyle: 'Dash', type: 'line' }
                        : { type: 'column' };

                    return (
                      <Checkbox
                        key={q.name}
                        name={q.name}
                        checked={visibleCheckboxes.includes(q.name)}
                        onCheck={handleVisibleCheckboxes}
                      >
                        <SeriesLabel
                          color={getSeriesColor(q.name, i)}
                          icon={getLabelIcon({
                            ...customOptions,
                            color: getSeriesColor(q.name, i),
                            visible: true,
                            field: q.name,
                            filterable: true,
                            name: q.name,
                          } as Series)}
                        >
                          {q.display_name || q.name}
                        </SeriesLabel>
                      </Checkbox>
                    );
                  })}
                </Form.Group>
              </Form>
            </div>
          </div>

          <div className={styles.chartContainer}>
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              immutable
            />
          </div>
          {renderGrid}
        </div>
        <Dimmer active={isLoading} inverted>
          <Loader />
        </Dimmer>
      </Dimmer.Dimmable>
    </div>
  );
};

export default Widget;
