import styled from '@emotion/styled';
import { css } from 'emotion';

export const StagesSection = styled.div({
  background: 'var(--bu-gray-100)',
  padding: '10px',
  borderRadius: '5px',
  display: 'flex',
  flexDirection: 'column',
});

export const StagesTitle = styled.div({
  fontWeight: 'bold',
  fontSize: '17px',
  paddingBottom: '5px',
});

export const StagesDescription = styled.div({
  color: 'var(--bu-gray-700)',
  fontSize: '12px',
  marginBottom: '10px',
});

export const StageValues = styled.div({
  marginTop: '15px',
});

export const dimmable = css`
  height: 100%;
`;

export const stageRow = css`
  background: #ff0;
`;

export const ContinueButtonWrapper = styled.div({
  padding: '15px 0',
  display: 'flex',
  justifyContent: 'flex-end',
});
