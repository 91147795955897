import {
  IColumn,
  IDataCellProps,
  IRow,
  TypedTableCellConfig,
  ValueProp,
} from '../TypedTable';
import * as s from '../styles';
import classNames from 'classnames';
import { css } from 'emotion';
import moment from 'moment';
import * as R from 'ramda';
import React, { ReactNode } from 'react';

import NotAvailableCell from 'components/UI/common/TypedTable/renderers/NotAvailableCell';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import Badge from 'components/dashboard/Forecast/Dashboard/Badge';
import { getRawValue } from 'components/dashboard/ForecastRollUps/helpers/rowValueExtractors';

export interface ClickableCellConfig extends TypedTableCellConfig {
  click: (column?: IColumn, row?: IRow) => void;
}

const subValue_block = css`
  color: var(--bu-gray-700);
  font-size: 0.85em;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const badge_block = css`
  margin-top: -5px;
`;

const pointerCursor = css`
  cursor: pointer;

  color: var(--bu-clickable-cell);

  font-weight: bold;

  tr:hover & {
    color: var(--bu-clickable-cell__hover);
  }
`;

const defaultFormatter = (value: ValueProp) => `${value}`;
const dateFormatter = (value: ValueProp) =>
  value
    ? moment(value as string)
        .local()
        .format('DD MMM YYYY, h:mm A')
    : '';

const ClickableCell = ({ column, row }: IDataCellProps) => {
  const columnConfig: ClickableCellConfig =
    (column.config as ClickableCellConfig) || {};

  const config: ClickableCellConfig = {
    formatter:
      columnConfig.subValue?.badge?.type === 'date'
        ? dateFormatter
        : defaultFormatter,
    ...columnConfig,
  };

  const text = `${R.path(column.field.split('.'), row)}` as ValueProp;
  const isNew = column.config.new && row.new;

  const { subValue } = config;
  let relativeFieldSubValue: ReactNode;
  let badgeValue: number | undefined = undefined;

  if (subValue) {
    if (subValue.relativeField) {
      const relativeFieldSubRawValue = getRawValue(
        subValue.relativeField,
        row
      ) as ValueProp;

      if (relativeFieldSubRawValue) {
        relativeFieldSubValue =
          subValue.template &&
          typeof config.formatter!(relativeFieldSubRawValue) === 'string'
            ? subValue.template.replace(
                '{value}',
                config.formatter!(relativeFieldSubRawValue) as string
              )
            : config.formatter!(relativeFieldSubRawValue);
      }
    } else if (subValue.badge) {
      const relativeFieldsSubRawValue = subValue.badge.relative_fields.map(
        (relativeField) => getRawValue(relativeField, row) as number
      );
      const firstItem = relativeFieldsSubRawValue.shift();
      badgeValue = relativeFieldsSubRawValue.reduce((acc, item) => {
        switch (subValue.badge?.type) {
          case 'addition':
            return acc + item;
          case 'subtraction':
            return acc - item;
          default:
            return acc;
        }
      }, firstItem || 0);
      relativeFieldSubValue = (
        <div className={badge_block}>
          {config.click ? (
            <Badge
              onClick={() => config.click!(column, row)}
              value={badgeValue}
            />
          ) : (
            <Badge value={badgeValue} />
          )}
        </div>
      );
    }
  }

  const handleClick = () => {
    if (config.click) {
      config.click(column, row);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.altKey || event.ctrlKey) {
      return;
    }

    switch (event.key) {
      case 'Enter':
      case 'Space':
      case ' ':
        handleClick();
        break;
      default:
    }
  };

  const isNotAvailable =
    (R.isNil(text) || R.isEmpty(text)) && typeof text !== 'number';
  const isCeroValue = Number(text) === 0;

  return isNotAvailable ? (
    <NotAvailableCell />
  ) : (
    <TooltipWrapper tooltip={column.showTooltip && text}>
      <div className={classNames(isNew && s.badgeWrapper)}>
        <div className={s.textOverflow}>
          {isCeroValue ? (
            <div className={classNames(config.className)}>
              <span>{text}</span>
            </div>
          ) : (
            <div
              onClick={handleClick}
              onKeyDown={handleKeyDown}
              className={classNames(pointerCursor, config.className)}
            >
              <span>{text}</span>
            </div>
          )}
        </div>
        {isNew ? <div className={s.newBadge}>New</div> : null}

        {subValue && !!relativeFieldSubValue && (
          <div className={classNames(subValue_block, subValue.className)}>
            {relativeFieldSubValue}
          </div>
        )}
      </div>
    </TooltipWrapper>
  );
};

export default ClickableCell;
