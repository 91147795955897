// eslint-disable-next-line import/prefer-default-export
export const getObjectionMomentsCached = (state) => state.cache.objectionMoment;

export const getCacheTimestamp = (state, entity) => {
  return state.cache[entity] ? state.cache[entity].cacheTimestamp : null;
};

export const getDraftTableRows = (tableName, state) =>
  state.draftTableRows[tableName];

export const getDealsIncluded = (state) => {
  return state.dealsIncluded.deals;
};
