import { cloneDeep, get, unset } from 'lodash';
import { createTransform } from 'redux-persist';

/**
 * Function created for https://vocalo.atlassian.net/browse/VPD-13977
 * original dependency that manage to create a blacklist filter for redux-persist was redux-persist-transform-filter
 * but was no longer maintained and contained a security vulnerability (https://github.com/Vocalo/Frontend/security/dependabot/127)
 *
 * Dependency was really simple and was not even worth it in the first place, as considered many use cases that were not needed
 * and size was pretty big for a simple blacklist filter.
 *
 * To fix vulnerability and reduce size, we created this function that does the same as the function used in the dependency
 * Managed to remove most of the unused code and keep only the blacklist filter function.
 *
 * What this function does is to not persist keys from a reducer that are not needed to be persisted.
 * Check original code here https://github.com/edy/redux-persist-transform-filter/blob/master/index.js
 */
export function createBlacklistFilter(
  reducerName: string,
  inboundPaths: string[]
) {
  return createTransform(
    // inbound
    (inboundState, key) => {
      return blacklistFilter(inboundState, inboundPaths);
    },

    // outbound
    (outboundState, key) => outboundState,

    { whitelist: [reducerName] }
  );
}

export function blacklistFilter(state: unknown, paths: string[] = []) {
  const subset = cloneDeep(state);
  paths.forEach((path) => {
    const value = get(state, path);

    if (typeof value !== 'undefined') {
      unset(subset, path);
    }
  });

  return subset;
}
