import { css } from 'emotion';

import { fontDefault, fontSubtitle } from 'assets/css/common';

export const QUARTER_DELIMITER_COLUMN = 'q-delimiter-col';

export const wrapper = css`
  height: 100%;
  overflow: hidden;
`;

export const header = css`
  display: flex;
  justify-content: space-between;
  margin: 20px 0 10px;
`;

export const title = css`
  font-family: var(--bu-font-regular);
  font-size: 18px;
  color: #4a4a4a;
  text-transform: capitalize;
`;

export const filters = css`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const dropdownContainer = css`
  margin-left: 20px;

  &.ui.selection.dropdown.active,
  &.ui.selection.dropdown.visible {
    z-index: 1000;
  }
`;

export const tableContainer = css`
  .ui.table.structured.compact > tbody > tr {
    &.topRow {
      background-color: #f7f7f7;

      &:nth-child(2n) {
        background-color: #f7f7f7;

        td:first-child {
          background-color: #f7f7f7;
        }

        &:hover td {
          background: #eef7fe;
        }
      }

      td:first-child {
        background-color: #f7f7f7;
      }

      &:hover td {
        background: #eef7fe;
      }

      .typed-table-cell {
        font-family: var(--bu-font-medium);
        font-size: 14px;
      }
    }

    &.empty {
      .typed-table-cell {
        opacity: 0;
        pointer-events: none;

        .hoverable {
          color: white;
        }
      }
    }

    &:nth-child(2n) {
      background-color: #fff;

      td:first-child {
        background-color: #fff;
      }

      &:hover td {
        background: #eef7fe;
      }
    }

    &.noEdit {
      pointer-events: none;

      td:first-child {
        pointer-events: auto;
      }
    }
  }

  .ui.table.structured.compact .current {
    background-color: var(--bu-white) !important;
  }

  .ui.table {
    &
      > tbody
      > tr
      > td.${QUARTER_DELIMITER_COLUMN},
      &
      > thead
      > tr
      > th.${QUARTER_DELIMITER_COLUMN} {
      border-left: 1px solid var(--bu-gray-500);
    }
  }

  .hierarchy-toggle button {
    margin-top: 2px;
  }
`;

export const checkboxContainer = css`
  margin: 10px !important;
`;

export const dropdrown = css`
  display: inline-block;
  border: 1px solid var(--bu-gray-500);
  padding: 5px 8px;
  border-radius: 3px;
  cursor: pointer;
  background: var(--bu-white);
  position: relative;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background: rgba(49, 92, 121, 0.12);
  }

  &.active {
    border-color: var(--bu-primary-500);
  }
`;

export const dropdrown_label = css`
  ${fontSubtitle};
  margin-right: 5px;
`;

export const dropdrown_value = css`
  ${fontDefault};
`;

export const dropdrown_options = css`
  &.open {
    display: block;
  }

  display: none;
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  right: -2px;
  top: 36px;
  z-index: 1000;
  background: #fff;
  border-radius: 4px;
  width: max-content;
  max-height: 204px;
  overflow: auto;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
`;

export const dropdrown_options_item = css`
  padding: 7px 15px;

  &.active,
  &:hover {
    background: rgba(48, 176, 209, 0.08);
  }
`;

export const triangle = css`
  border-style: solid;
  height: 0;
  width: 0;
  border-width: 4px;
  border-color: var(--bu-gray-900);
  display: inline-block;
  margin: 0 2px 0 6px;

  &.bu-up {
    border-top-width: 0;
    border-top-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    transform: translateY(-50%);
    border-color: var(--bu-primary-500);
  }

  &.bu-down {
    border-bottom-width: 0;
    border-bottom-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    transform: translateY(-50%);
  }
`;

export const panel_button = (isPending: boolean) => css`
  border-radius: 4px;
  cursor: pointer;
  ${fontSubtitle}
  text-transform: inherit;
  color: ${isPending ? '#737376' : 'var(--bu-primary-500)'};
  background: transparent;
  border: none;
  transition: 0.4s;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:hover,
  &:focus {
    background: var(--bu-gray-200);
    outline: none;
  }
`;

export const targets_bt_container = (isTargetsImportEnabled: boolean) => css`
  max-width: ${isTargetsImportEnabled ? 'calc(100% - 300px)' : '100%'};
`;

export const button = css`
  padding: 5px 10px;
  white-space: nowrap;
  background: var(--bu-primary-500);
  border: 1px solid var(--bu-primary-500);
  border-radius: 4px;
  ${fontSubtitle}
  cursor: pointer;
  color: var(--bu-white);
  transition: 0.4s;
  margin-left: 20px;
`;

export const headerButtonGroup = css`
  display: flex;
`;
