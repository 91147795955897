import React, { useMemo } from 'react';

import BuButton from 'components/UI/BuButton';
import { ISelectOption } from 'components/UI/BuSelect/types';
import {
  AddFilterButtonContainer,
  FiltersContainer,
  MetricsFilterDescriptionLong,
  MetricsFilterDescription,
} from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/WidgetFilters/styles';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import {
  DataDescriptor,
  BIMetricsFilter,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';
import { getDropdownFriendlyName } from 'components/dashboard/Metrics/metrics.helpers';
import { ColumnSelector } from 'components/dashboard/Metrics/Create/Conditions/Condition/ColumnSelector/ColumnSelector';

interface Props {
  readonly analysisType: AnalysisType;
  readonly columns: DataDescriptor[];
  readonly templateFilters: BIMetricsFilter[];
  readonly widgetFilters: DataDescriptor[];
  updateWidget: (widget: Partial<BIWidget>) => void;
}

export const WidgetFilters: React.FC<Props> = ({
  analysisType,
  columns = [],
  templateFilters = [],
  widgetFilters = [],
  updateWidget,
}) => {
  const DISABLED_FILTER_TYPES = ['CRM Lookup', 'custom', 'formula', 'user'];

  const filterColumns: DataDescriptor[] = useMemo(
    () =>
      columns
        .filter((element) => !DISABLED_FILTER_TYPES.includes(element.type))
        // To remove duplicates which are causing issues
        .filter((v, i, a) => a.findIndex((v2) => v2.name === v.name) === i),

    [columns]
  );

  const getOptions = (index: number): ISelectOption[] =>
    filterColumns
      // removes the option selected
      .filter((column) => {
        const idx = widgetFilters.findIndex(
          (filter) => filter.name === column.name
        );

        return idx === -1 || idx === index;
      })
      .map((column) => ({
        text: column.label,
        value: column.name,
      }));

  const handleRemove = (index: number): void => {
    const widgetFilter = widgetFilters?.[index];

    const newWidgetFilters = [
      ...widgetFilters.slice(0, index),
      ...widgetFilters.slice(index + 1),
    ];

    const templateFiltersIndex = templateFilters.findIndex(
      (f) => f.column.name === widgetFilter.name
    );
    const newTemplateFilters =
      templateFilters && templateFiltersIndex
        ? [
            ...templateFilters.slice(0, templateFiltersIndex),
            ...templateFilters.slice(templateFiltersIndex + 1),
          ]
        : [];

    updateWidget({
      widget_filters: newWidgetFilters,
      template_filters: newTemplateFilters,
    });
  };

  const handleChange = (values: string[], index: number): void => {
    const selectedColumn = filterColumns.find((f) => values[0] === f.name);

    if (selectedColumn) {
      const newFilters = widgetFilters ?? [];
      newFilters[index] = selectedColumn;

      const newTemplateFilters = templateFilters
        ? templateFilters.filter((filter) => {
            const index = newFilters.findIndex(
              (newFilter) => filter.column.name === newFilter.name
            );
            return index != -1;
          })
        : [];

      updateWidget({
        widget_filters: newFilters,
        template_filters: newTemplateFilters,
      });
    }
  };

  const handleAdd = (): void => {
    const newFilter = filterColumns.find(
      (f) => !widgetFilters.some((wf) => wf.name === f.name)
    );

    if (newFilter) {
      updateWidget({
        widget_filters: [...widgetFilters, newFilter],
      });
    }
  };

  const canAddNewFilter: boolean = widgetFilters.length < filterColumns?.length;

  return (
    <>
      <MetricsFilterDescription>
        Add a field as a selectable filter to be able to set its value on
        runtime when analysing the widget in a dashboard.
        {analysisType === AnalysisType.FUNNEL && (
          <MetricsFilterDescriptionLong>
            Filters will be applied on <strong>live</strong> data of the funnel
            objects.
          </MetricsFilterDescriptionLong>
        )}
      </MetricsFilterDescription>

      <FiltersContainer>
        {widgetFilters.map((widgetFilter, index) => (
          <div data-testing={`filter-Container-${index}`}>
            <ColumnSelector
              key={widgetFilter.name}
              column={widgetFilter}
              dataTesting={`Filter-${index}`}
              filterColumns={filterColumns.filter((column) => {
                const idx = widgetFilters.findIndex(
                  (filter) => filter.name === column.name
                );

                return idx === -1 || idx === index;
              })}
              onChangeColumn={(values: string[]) => {
                handleChange(values, index);
              }}
              onRemove={() => {
                handleRemove(index);
              }}
            />
          </div>
        ))}

        <AddFilterButtonContainer>
          <BuButton secondary disabled={!canAddNewFilter} onClick={handleAdd}>
            + Add Filter
          </BuButton>
        </AddFilterButtonContainer>
      </FiltersContainer>
    </>
  );
};
