import moment from 'moment';

import { getAction } from 'actions/baseActions';

export const customReportActionType = {
  UPDATE_STATE: 'CUSTOM_REPORT_UPDATE_STATE',
  RESET_STATE: 'CUSTOM_REPORT_RESET_STATE',
  REMOVE_CUSTOM_REPORT: 'REMOVE_CUSTOM_REPORT',
  UPDATE_CUSTOM_REPORT_FIELD: 'UPDATE_CUSTOM_REPORT_FIELD',
  CANCEL_EDIT_CUSTOM_REPORT: 'CANCEL_EDIT_CUSTOM_REPORT',
  ADD_CUSTOM_REPORT: 'ADD_CUSTOM_REPORT',
};

const createCustomReportAction = {
  updateState: (key, value) => {
    return {
      type: customReportActionType.UPDATE_STATE,
      key,
      value,
    };
  },
  resetState: () => {
    return {
      type: customReportActionType.RESET_STATE,
    };
  },
  removeCustomReport: (id) => {
    return {
      type: customReportActionType.REMOVE_CUSTOM_REPORT,
      id,
    };
  },
  cancelEditCustomReport: () => {
    return {
      type: customReportActionType.CANCEL_EDIT_CUSTOM_REPORT,
    };
  },
  processApiSuccess: (response) => {
    return createCustomReportAction.updateState({
      apiSuccess: true,
      report_specs: response.data.report_specs.sort((a, b) =>
        moment(a.last_modified).format('x') >
        moment(b.last_modified).format('x')
          ? -1
          : 1
      ),
    });
  },
  processApiError: (response) => {
    return createCustomReportAction.updateState('apiError', response);
  },
  processRemoveCustomReportSuccess: (response, id) => {
    return createCustomReportAction.removeCustomReport(id);
  },
  processSaveSuccess: (response) => {
    return {
      type: customReportActionType.ADD_CUSTOM_REPORT,
      report: response.data,
    };
  },
  processSaveFailure: (response) => {
    return createCustomReportAction.updateState('apiError', response);
  },
  processGetCustomReportSuccess: (response) => {
    return createCustomReportAction.updateState({
      apiSuccess: true,
      report_spec: response.data,
    });
  },
  processGetCustomReportFailure: (response) => {
    return createCustomReportAction.updateState({
      apiError: response,
      report_spec: {},
    });
  },
};

export const doGetCustomReports = (email, api_access_token) => {
  return getAction(
    `${process.env.REACT_APP_BACKEND_URL}/api/data/custom_reports/`,
    {
      email,
      api_access_token,
    },
    createCustomReportAction.processApiSuccess,
    createCustomReportAction.processApiError
  );
};
