import {
  ACCOUNT,
  EMAIL,
  CALENDAR_EVENT,
  FORECAST_SUBMISSION,
  OPPORTUNITY,
  OPPORTUNITY_SPLIT,
} from 'components/dashboard/Metrics/constants';

// this is the list of objects that are supported for drilldown in RevBI API
export const SUPPORTED_DRILLDOWN_OBJECTS = [
  OPPORTUNITY,
  ACCOUNT,
  OPPORTUNITY_SPLIT,
  FORECAST_SUBMISSION,
];

export const SUPPORTED_DRILLDOWN_OBJECTS_WITH_CLICK = [
  ...SUPPORTED_DRILLDOWN_OBJECTS,
  EMAIL,
  CALENDAR_EVENT,
];
