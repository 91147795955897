import clx from 'classnames';
import { css } from 'emotion';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import { actions } from 'actions';
import { BuControlSize } from 'components/UI/BuButton';
import BuDropdown, {
  BuDropdownItem,
  BuDropdownItemContainer,
} from 'components/UI/BuDropdown';
import * as s from 'components/UI/common/ColumnsControl/styles';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';
import tableColumnsSelectionIcon from '../../../../assets/fonts/boostup-icons/table_columns_selection.svg';

const tableColumnsSelectionStyle = css`
  width: 18px;
`;

type OwnProps = {
  displayedColumns: {
    label: string;
    id: string;
    show: boolean;
  }[];
  isModal?: boolean;
  onChange?: ({ id, show }: { id: string; show: boolean }) => void;
};

type StateProps = {
  persistName: string;
};

type DispatchProps = {
  setShowOrHideColumns: Function;
};

type IProps = OwnProps & StateProps & DispatchProps;

const ColumnsControl: React.FC<IProps> = React.memo(
  ({ displayedColumns, persistName, onChange, setShowOrHideColumns }) => {
    const _onChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const id = e.currentTarget.getAttribute('data-id');
        const column = displayedColumns.find((i) => i.id === id);

        if (column && id) {
          if (onChange) {
            onChange({ id, show: !column.show });
          } else {
            setShowOrHideColumns({
              path: persistName,
              column: { ...column, show: !column.show },
            });
          }
        }
      },
      [JSON.stringify(displayedColumns), persistName]
    );

    return (
      <BuDropdown
        label={
          <img
            className={tableColumnsSelectionStyle}
            src={tableColumnsSelectionIcon}
            alt="table columns selection"
          />
        }
        tooltip="Columns"
        secondary
        size={BuControlSize.SMALL}
        iconDropdown
      >
        {() => (
          <BuDropdownItemContainer className={s.menu}>
            {displayedColumns.map((el) => (
              <BuDropdownItem key={el.id}>
                <label
                  className={clx(s.custom_checkbox, { isChecked: el.show })}
                >
                  <input
                    checked={el.show}
                    data-id={el.id}
                    className={s.custom_checkbox__input}
                    onChange={_onChange}
                    type="checkbox"
                  />
                  <span className={s.check_box} />
                  {el.label}
                </label>
              </BuDropdownItem>
            ))}
          </BuDropdownItemContainer>
        )}
      </BuDropdown>
    );
  },
  (prev, next) =>
    JSON.stringify(prev.displayedColumns) ===
    JSON.stringify(next.displayedColumns)
);

const mapStateToProps = (
  state: IReduxState,
  ownProps: OwnProps
): StateProps => ({
  persistName: selectors.getPersistName(state, ownProps.isModal),
});

const dispatchToProps = {
  setShowOrHideColumns: actions.ui.appState.setShowOrHideColumns,
};

export default connect(mapStateToProps, dispatchToProps)(ColumnsControl);
