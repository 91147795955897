import { IDataCellProps, ValueProp, IRow } from '../../TypedTable';
import classNames from 'classnames';
import { css } from 'emotion';
import isNumber from 'lodash/isNumber';
import * as R from 'ramda';
import React, { PropsWithChildren, ReactNode } from 'react';

import stage_down_arrow from 'assets/images/stage_down_arrow.svg';
import stage_up_arrow from 'assets/images/stage_up_arrow.svg';
import styles from 'components/UI/DealsFlatTableTS/Table/styles';

const arrowContainer = css`
  display: flex;
  justify-content: space-between;

  &.icon {
    font-size: 0;
  }
`;

const arrowIconSpaceHolder = css`
  display: inline-block;
  width: 19px;
`;

const childrenWidth = css`
  width: 90%;
`;

const noPointer = css`
  cursor: auto;
`;

const getRawValue = (field: string, row: IRow): ValueProp => {
  if (R.path(field.split('.'), row) !== undefined)
    return R.path(field.split('.'), row) as ValueProp;

  return R.pathOr(undefined, ['extra', field, 'value'], row);
};

const ArrowValueCell = ({
  column,
  row,
  children,
}: PropsWithChildren<IDataCellProps>) => {
  const relativeFieldsSubRawValue =
    column.config.subValue?.arrow?.relative_fields.map(
      (relativeField: string) => getRawValue(relativeField, row) as number
    ) || [];

  const currentValue = relativeFieldsSubRawValue
    ? relativeFieldsSubRawValue[0]
    : 0;
  const currentPrevValue = relativeFieldsSubRawValue
    ? relativeFieldsSubRawValue[1]
    : 0;

  let indicator: ReactNode = null;

  if (!R.isNil(currentValue) && !R.isNil(currentPrevValue)) {
    if (isNumber(currentValue)) {
      if (currentValue < currentPrevValue) {
        indicator = (
          <img className={noPointer} alt="down-arrow" src={stage_down_arrow} />
        );
      } else if (currentValue > currentPrevValue) {
        indicator = (
          <img className={noPointer} alt="up-arrow" src={stage_up_arrow} />
        );
      }
    } else {
      indicator = (
        <img className={noPointer} alt="up-arrow" src={stage_up_arrow} />
      );
    }
  }
  const trigger =
    currentPrevValue !== null ? (
      <div className={classNames(styles.pointer, styles.arrowWrapper)}>
        {indicator}
      </div>
    ) : (
      <div className={arrowIconSpaceHolder} />
    );

  return (
    <div
      className={classNames({
        [arrowContainer]: indicator,
      })}
    >
      {/* free space for arrow indicator  */}
      <div
        className={classNames({
          [childrenWidth]: indicator,
        })}
      >
        {children}
      </div>

      {indicator ? trigger : <div className={arrowIconSpaceHolder} />}
    </div>
  );
};

export default ArrowValueCell;
