import styled from '@emotion/styled';

export const MetricFiltersDescription = styled.div({
  color: '#222',
  fontSize: '12px',
  ul: {
    marginBottom: '0px',
  },
});

export const Title = styled.div({
  color: 'var(--bu-gray-700)',
});
