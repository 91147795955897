import { css } from 'emotion';
import { isEmpty } from 'ramda';
import React, { useEffect, useContext } from 'react';

import BusinessTypesPanel from 'components/UI/BusinessTypesPanel';
import OpenFiltersPanel from 'components/UI/OpenFiltersPanel';
import { useHeader } from 'components/UI/Wrapper/Header/header.context';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import SellerQuotaAttainmentWidget from 'components/dashboard/ForecastDashboard/SellerQuotaAttainmentWidget';
import TrackingDashboard from 'components/dashboard/ForecastDashboard/TrackingDashboard';
import Widget3 from 'components/dashboard/ForecastDashboard/Widget3';
import * as styles from 'components/dashboard/ForecastDashboard/styles';
import { IProps } from 'components/dashboard/ForecastDashboard/types';
import { VirtualizedItem } from 'components/virtualized/VirtualizedItem';
import classNames from 'classnames';

const stickyTop = css`
  position: sticky;
  background: var(--bu-white);
  z-index: 989;
`;

export const businessTypesPanelWrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bu-white);
  border-bottom: 1px solid var(--bu-gray-400);
  margin: 0px -20px 0px -20px;
  padding: 0px 20px 0px 20px;
`;

const containerWrapper = css`
  background-color: var(--bu-primary-100);
`;

const ForecastDashboard: React.FC<IProps> = ({
  selectedBusinessType,
  filters,
  tabPartition = 'forecast',
  isQuotaAttainmentChartEnabled,
  isRequiredDataReady,
}) => {
  const { setPartition, clearContext } = useHeader();

  useEffect(() => {
    setPartition(tabPartition);

    AnalyticsTracker.event(
      {},
      {
        action: 'Open',
        category: 'Forecast',
        label: 'Dashboard page',
      }
    );

    return () => {
      clearContext();
    };
  }, []);

  return (
    <div className={classNames('container', containerWrapper)}>
      <div className="container-dashboard">
        <div className={stickyTop}>
          <OpenFiltersPanel tab="forecast_analytics" />
        </div>

        <div className={businessTypesPanelWrapper}>
          <BusinessTypesPanel tab="forecast_analytics" />
        </div>

        <div className={styles.wrapper}>
          {isRequiredDataReady &&
            !isEmpty(filters) &&
            [
              <TrackingDashboard selectedBusinessType={selectedBusinessType} />,
              <Widget3 selectedBusinessType={selectedBusinessType} />,
              isQuotaAttainmentChartEnabled && (
                <SellerQuotaAttainmentWidget
                  selectedBusinessType={selectedBusinessType}
                />
              ),
            ].map((El, index) => (
              <VirtualizedItem key={index} withClone>
                {El}
              </VirtualizedItem>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ForecastDashboard;
