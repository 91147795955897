import { DealOutcomePrediction } from 'components/deal/types';

export const getOutcomeMessage = (
  prediction: DealOutcomePrediction['prediction'] | undefined
) => {
  let predictionVerb;

  if (!prediction) {
    return 'Prediction for this opportunity is not available!';
  }

  switch (prediction) {
    case 'WIN':
      predictionVerb = 'winning';
      break;
    case 'SLIP':
      predictionVerb = 'slipping';
      break;
    case 'LOSE':
      predictionVerb = 'losing';
      break;
  }

  return `BoostUp predicts that there are high chances of ${predictionVerb} this deal based on the current factors.`;
};
