import React from 'react';

import { ModalRouteProps } from 'navigation/types';
import Account from 'screens/Account';

const QuickViewAccount: React.FC<ModalRouteProps<{ id: string }>> = ({
  params,
}) => {
  const { id } = params;

  if (!id) {
    return null;
  }

  return <Account id={id} isPanel noResave />;
};

export default QuickViewAccount;
