import * as t from 'actions/actionTypes';
import * as genericSagas from 'sagas/generic';

export const getTopics = () => ({
  type: t.TOPICS + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/topics/get`,
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.TOPICS + t.GET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.TOPICS + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.TOPICS + t.GET + t.LOADING,
  }),
});

export const updateTopic = (
  name,
  keywords,
  sentiment,
  is_competitor = false
) => ({
  type: t.TOPICS + t.UPDATE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/topics/update`,
  data: {
    name,
    keywords,
    sentiment,
    is_competitor,
  },
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.TOPICS + t.UPDATE + t.SUCCESS,
    payload,
    is_competitor,
  }),
  error: (e) => ({
    type: t.TOPICS + t.UPDATE + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.TOPICS + t.UPDATE + t.LOADING,
  }),
});

export const deleteTopic = (name, is_competitor = false) => ({
  type: t.TOPICS + t.REMOVE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/topics/delete`,
  data: {
    name,
  },
  saga: genericSagas.create,
  success: () => ({
    type: t.TOPICS + t.REMOVE + t.SUCCESS,
    payload: {
      name,
    },
    is_competitor,
  }),
  error: (e) => ({
    type: t.TOPICS + t.REMOVE + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.TOPICS + t.REMOVE + t.LOADING,
  }),
});

export const fetchDealTopics = (dealId, accountId, timespan) => {
  const objId = dealId || accountId;
  const objType = dealId ? 'deal' : 'account';

  return {
    type: t.DEAL + t.DEALTOPICS + t.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/api/data/deals/${objId}/topic_timeline/${objType}`,
    data: {
      time_period: timespan,
    },
    saga: genericSagas.get,
    success: (payload) => ({
      type: t.DEAL + t.DEALTOPICS + t.GET + t.SUCCESS,
      payload: {
        ...payload,
        objId,
      },
    }),
    error: (e) => ({
      type: t.DEAL + t.DEALTOPICS + t.GET + t.ERROR,
      error: e,
    }),
    loading: () => ({
      type: t.DEAL + t.DEALTOPICS + t.GET + t.LOADING,
    }),
  };
};
