import BusinessTypeDetails from './BusinessTypeDetails';
import React from 'react';

import { ModalRouteProps } from 'navigation/types';

const EditBusinessTypeModal: React.FC<
  ModalRouteProps<{
    businessType: string;
  }>
> = ({ params, onClose }) => {
  const { businessType } = params;

  return (
    <BusinessTypeDetails
      isEditMode
      businessTypeForEditName={businessType}
      onClose={onClose}
    />
  );
};

export default EditBusinessTypeModal;
