import { css } from 'emotion';

export const container = css`
  position: relative;
  align-self: center;
`;

export const toggles_container = css`
  z-index: 988;
  position: absolute;
  background: var(--bu-white);
  visibility: hidden;
  opacity: 0;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  border: 1px solid var(--bu-gray-500);
  padding: 12px;
  right: 0;
  width: max-content;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &.open {
    visibility: visible;
    opacity: 1;
  }
`;

export const icon = css`
  font-size: 24px;
`;
