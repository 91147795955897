import { connect } from 'react-redux';

import AccountsAnalytics from 'components/dashboard/accounts-analytics/AccountsAnalytics';
import { StateProps } from 'components/dashboard/accounts-analytics/types';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

const mapStateToProps = (state: IReduxState): StateProps => ({
  dashboard: selectors.getMetricsDashboardByTitle(state, 'Accounts Analytics'),
});

export default connect(mapStateToProps)(AccountsAnalytics);
