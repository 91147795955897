import {
  CONSTANT_COLORS,
  DYNAMIC_BAR_COLORS,
  DYNAMIC_LINE_COLORS,
} from 'components/dashboard/ForecastDashboard/TrackingDashboard/constants';
import {
  StatItem,
  PipelineType,
  OptionsKeys,
} from 'components/dashboard/ForecastDashboard/TrackingDashboard/types';

export class StyleResolver {
  private indexes: {
    [K in OptionsKeys]: number;
  } = {
    pipeline: 0,
    Projections: 0,
    Q: 0,
    M: 0,
    W: 0,
    target: 0,
  };

  public getColor({ forecasttype, name }: StatItem): string {
    if (forecasttype === 'pipeline') {
      if (name in CONSTANT_COLORS) {
        return CONSTANT_COLORS[name as PipelineType];
      }

      const index = this.indexes.pipeline++;
      return DYNAMIC_BAR_COLORS[index];
    }

    const index = this.indexes[forecasttype as OptionsKeys]++;
    return DYNAMIC_LINE_COLORS[index];
  }

  public getDashStyle(forecasttype?: string): Highcharts.DashStyleValue {
    switch (forecasttype) {
      case 'Projections':
        return 'Solid';
      case 'Q':
        return 'Dot';
      case 'W':
      case 'M':
        return 'Dash';
      default:
        return 'Solid';
    }
  }
}
