export const insightsActionType = {
  UPDATE_STATE: 'INSIGHTS_UPDATE_STATE',
  RESET_STATE: 'INSIGHTS_RESET_STATE',
  UPDATE_CALL_STATE: 'INSIGHTS_UPDATE_CALL_STATE',
  UPDATE_CALLS: 'INSIGHTS_UPDATE_CALLS',
};

export const selectedClassType = {
  // The values should not overlap with selectedClassType defined in
  // callActions
  CUSTOMER_INSIGHTS: 100,
  COMPANY_INSIGHTS: 101,
};

const createInsightsAction = {
  updateState: (key, value) => {
    return {
      type: insightsActionType.UPDATE_STATE,
      key,
      value,
    };
  },
  resetState: () => {
    return {
      type: insightsActionType.RESET_STATE,
    };
  },
  updateCalls: (value) => {
    return {
      type: insightsActionType.UPDATE_CALLS,
      value,
    };
  },

  updateCallState: (call_id, key, value) => {
    return {
      type: insightsActionType.UPDATE_CALL_STATE,
      call_id: call_id,
      key,
      value,
    };
  },
  processCalls: (page_number) => (response) => {
    return createInsightsAction.updateCalls({
      calls: response.data,
      page_number,
    });
  },
  processApiError: (response) => {
    return createInsightsAction.updateState('apiError', response);
  },
  setSearchIndex: (call_id, searchIndex) => {
    return createInsightsAction.updateCallState(
      call_id,
      'searchIndex',
      searchIndex
    );
  },
  playAudio: (call_id) => {
    return createInsightsAction.updateCallState(call_id, 'isPlaying', true);
  },
  pauseAudio: (call_id) => {
    return createInsightsAction.updateCallState(call_id, 'isPlaying', false);
  },
};
