import * as Highcharts from 'highcharts';
import { DropdownItemProps } from 'semantic-ui-react';

import { formatAmount } from 'common/numbers';
import { AttainmentData } from 'components/dashboard/Pipeline/PipelineDashboard/PipelineAttainmentWidget/types';

export const getPlotOptions = (
  attainmentData: AttainmentData[],
  currencyCode: string,
  sorting: number
): Highcharts.Options => {
  const sortedData = attainmentData.sort(
    ({ quota_attainment: left }, { quota_attainment: right }) =>
      sorting === 1 ? right - left : left - right
  );

  const avgTarget =
    sortedData.length > 0
      ? sortedData.reduce((acc, item) => acc + item.quota_attainment, 0) /
        sortedData.length
      : 0;

  return {
    chart: {
      type: 'column',
      height: 420,
      marginTop: 40,
      scrollablePlotArea: {
        minWidth: 1024,
        opacity: 1,
      },
    },
    title: {
      text: '',
    },
    xAxis: [
      {
        type: 'category',
        categories: sortedData.map((data) => data.user.name),
        labels: {
          enabled: true,
          rotation: -30,
        },
        visible: true,
      },
    ],
    yAxis: {
      allowDecimals: false,
      min: 0,
      softMax: 100,
      title: {
        text: 'Quota Attainment',
      },
      labels: {
        enabled: true,
        format: '{value}%',
      },
      gridLineDashStyle: 'Dash',
      stackLabels: {
        enabled: true,
        format: '{total:.0f}%',
      },
      plotLines: [
        {
          value: avgTarget,
          label: {
            text: `Avg: ${~~avgTarget}%`,
            align: 'right',
            verticalAlign: 'middle',
            style: {
              color: '#F05A30',
            },
            x: -5,
          },
          dashStyle: 'ShortDot',
          color: '#F05A30',
          width: 2,
          zIndex: 10,
        },
      ],
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      formatter: function () {
        const quotaAttainment = this.point.options.custom as AttainmentData;

        return [
          `<b>${this.x}</b>`,
          `Target: ${formatAmount(quotaAttainment.target, currencyCode)}`,
          `Total: ${formatAmount(quotaAttainment.total_amount, currencyCode)}`,
        ].join('<br />');
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        grouping: true,
        pointRange: 1,
      },
    },
    series: [
      {
        type: 'column',
        color: '#04DAB2',
        name: 'Booked',
        data: sortedData.map((data) => ({
          y: data.quota_attainment,
          custom: data,
        })),
        pointWidth: 20,
        xAxis: 0,
      } as Highcharts.SeriesColumnOptions,
    ],
  };
};

export const PIPELINE_ATTAINMENT_TIME_INTERVAL_OPTIONS_DEFAULT = 'TQU';

export const PIPELINE_ATTAINMENT_TIME_INTERVAL_OPTIONS: DropdownItemProps[] = [
  {
    key: PIPELINE_ATTAINMENT_TIME_INTERVAL_OPTIONS_DEFAULT,
    text: 'Current Quarter',
    value: PIPELINE_ATTAINMENT_TIME_INTERVAL_OPTIONS_DEFAULT,
  },
  {
    key: 'TYE',
    text: 'Current Year',
    value: 'TYE',
  },
  {
    key: 'LQU',
    text: 'Last Quarter',
    value: 'LQU',
  },
  {
    key: 'L5Q',
    text: 'Last 5 Quarters',
    value: 'L5Q',
  },
];

export const PIPELINE_ATTAINMENT_SORT_OPTIONS: DropdownItemProps[] = [
  {
    key: 'asc',
    value: -1,
    text: 'Ascending',
  },
  {
    key: 'desc',
    value: 1,
    text: 'Descending',
  },
];
