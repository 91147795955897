import classNames from 'classnames';
import React from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';

export type IIconProps = {
  name: BoostUpIcons;
  className?: string;
  color?: string;
  forwardedRef?: React.Ref<HTMLElement>;
};

type IIconGroupProps = {
  className?: string;
  forwardedRef?: React.Ref<HTMLElement>;
};

const BuIcon: React.FC<IIconProps> & { Group: React.FC<IIconGroupProps> } = ({
  forwardedRef,
  name,
  className,
  color,
}) => (
  <i
    ref={forwardedRef}
    aria-hidden="true"
    className={classNames(`bu-icon bu-icon-${name}`, className)}
    style={{ color }}
  />
);
BuIcon.Group = ({ forwardedRef, children, className }) => (
  <i ref={forwardedRef} className={classNames('bu-icons-group', className)}>
    {children}
  </i>
);

export const BuLogo: React.FC<IIconGroupProps> = ({
  forwardedRef,
  className,
}) => (
  <BuIcon.Group forwardedRef={forwardedRef} className={className}>
    <BuIcon
      name={BoostUpIcons.BoostupLogoPart_1}
      color="var(--bu-color-left-panel-logo-part-2)"
    />
    <BuIcon
      name={BoostUpIcons.BoostupLogoPart_2}
      color="var(--bu-color-left-panel-logo-part-1)"
    />
  </BuIcon.Group>
);

export default BuIcon;
