import { css } from 'emotion';

import { fontSubtitle } from 'assets/css/common';

export const tabs = css`
  display: flex;
  align-items: center;
  min-height: 30px;
  padding-top: 0;
  margin: 0px -30px 0px -20px;
  min-height: 42px;
`;

export const tab_icon = css`
  width: 16px;
  margin-left: 5px;
`;

export const tab_content_wrapper = css`
  display: flex;
  align-items: center;
`;

export const tab_link = css`
  margin-right: 16px;
  top: 12px;
  position: relative;

  &:last-child {
    margin-right: 0;
  }
`;

export const tabs_item_wpar = css`
  display: -ms-inline-grid;
  display: inline-grid;

  p {
    visibility: hidden;
    position: absolute;
    left: 50%;
    transform: translateX(-56%);
    bottom: -35px;
    padding: 5px 12px;
    width: fit-content;
    border-radius: 2px;
    font-size: 10px;
    font-family: var(--bu-font-regular);
  }
`;

export const tabs_item = css`
  border: none;
  background-color: var(--bu-primary-800);
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${fontSubtitle};
  text-transform: capitalize;
  color: var(--bu-white);
  position: relative;
  min-height: 36px;
  font-size: 14px;
  font-family: var(--bu-font-medium);
  padding: 6px 16px;
  border-radius: 6px 6px 0 0;

  &:hover {
    color: var(--bu-gray-900);
    background-color: var(--bu-gray-200);
  }

  &.active {
    background-color: var(--bu-gray-200);
    display: block;
    min-height: 36px;
    color: var(--bu-gray-900);
    ${fontSubtitle};
    text-transform: capitalize;
    -webkit-letter-spacing: 0;
    -moz-letter-spacing: 0;
    -ms-letter-spacing: 0;
    letter-spacing: 0;
    line-height: 0;
    text-align: center;
  }

  &:focus {
    outline: none;
  }
`;

export const oversizing_tab_item = css`
  &.active,
  &:hover {
    background-color: var(--bu-gray-200);
  }
`;

export const oversizing_tab_tooltip_wrapper = css`
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  ${fontSubtitle};
`;

export const oversizing_tab_tooltip_item = css`
  color: var(--bu-gray-600);
  padding: 6px 16px;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;

  &:hover {
    color: var(--bu-gray-900);
  }

  &.active {
    background-color: var(--bu-gray-300);
    color: var(--bu-gray-900);
  }
`;
