import * as R from 'ramda';

import { IRow } from 'components/UI/common/TypedTable/TypedTable';

export const treeify = (
  list: IRow[],
  groupByProperty: string,
  parentProperty: string
) => {
  const tree: IRow[] = [];
  const lookup = list.reduce(
    (acc: { [key: string]: IRow }, row, index: number) => ({
      ...acc,
      [R.prop(groupByProperty)(row) as string]: {
        ...row,
        id: index,
      },
    }),
    {}
  );

  list.forEach((item) => {
    const id = R.prop(groupByProperty)(item);
    const parent = R.prop(parentProperty)(item);
    const parentLookup = parent && lookup[parent as string];
    const itemLookup = lookup[id as string];

    if (parentLookup) {
      parentLookup.children = [...(parentLookup.children || []), itemLookup];
    } else {
      tree.push(itemLookup);
    }
  });

  return tree;
};
