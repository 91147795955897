import { css } from 'emotion';
import React, { useState } from 'react';

import pageBrokenIcon from 'assets/images/page_broken_icon.svg';
import BuButton, { BuControlSize } from 'components/UI/BuButton';

const messageContainer = css`
  width: 100%;
  flex-direction: column;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
const messageText = css`
  color: var(--bu-gray-700);
  max-width: 550px;
  text-align: center;
`;
const title = css`
  font-size: 16px;
  text-align: center;
`;

const refresh = css`
  margin-top: 10px;
`;

const MAX_REFRESH = 3;

interface Props {
  onRefresh: () => void;
}

const ErrorMessage: React.FC<Props> = ({ onRefresh }) => {
  const [timesRefreshed, setTimesRefreshed] = useState<number>(0);

  const onRefreshClicked = () => {
    if (timesRefreshed < MAX_REFRESH) {
      onRefresh();
    }
    setTimesRefreshed(timesRefreshed + 1);
  };

  return (
    <div className={messageContainer}>
      <img src={pageBrokenIcon}></img>
      <h4 className={title}>Oops, Something Went Wrong</h4>
      <p className={messageText}>
        This page didn’t load due to the configuration error. Please try
        reloading. If this problem persists, contact your admin or Sales Ops.
      </p>
      {timesRefreshed < MAX_REFRESH && (
        <BuButton
          className={refresh}
          size={BuControlSize.REGULAR}
          onClick={() => onRefreshClicked()}
        >
          Reload
        </BuButton>
      )}
    </div>
  );
};

export default ErrorMessage;
