import clx from 'classnames';
import React, { FC, useState, ChangeEvent, useEffect } from 'react';

import BuButton from 'components/UI/BuButton';
import BuInput from 'components/UI/BuInput';
import BuRadio from 'components/UI/BuRadio';
import * as styles from 'components/settings/FieldConfiguration/CustomFieldsConfiguration/styles';
import { Props } from 'components/settings/FieldConfiguration/CustomFieldsConfiguration/types';

const initialOptions: Filters.Checkbox[] = [
  { checked: false, label: '__internal_value__', value: '' },
  { checked: true, label: 'All', value: 'all' },
  { checked: false, label: 'Exclude Null', value: 'exclude_null' },
  { checked: false, label: 'Only Null', value: 'only_null' },
];

type LayoutProps = {
  isFilter: boolean;
  onChange: (values: Filters.PersistValue[], withReset?: boolean) => void;
  onClose?: () => void;
  options: Filters.Checkbox[];
};

export const LayoutTextFilter: FC<LayoutProps> = ({
  isFilter,
  onChange,
  onClose,
  options,
}) => {
  const initialText = options[0].value ?? '';
  const initialRadio = options.filter(
    (item) => item.label !== '__internal_value__'
  );

  const [text, setText] = useState<string>(initialText);
  const [radio, setRadio] = useState(initialRadio);

  const checkedRadio = radio.find(({ checked }) => checked);
  const isTextDisabled = checkedRadio?.value !== '__all__';

  const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  const onChangeRadio = (value: Filters.Checkbox['value']) => {
    setRadio((prev) =>
      prev.map((el) => ({
        ...el,
        checked: el.value === value,
      }))
    );
  };

  const onSubmit = () => {
    onChange(
      [
        { id: text.trim(), checked: !!text && !isTextDisabled },
        ...radio.map(({ checked, value: id }) => ({ id, checked })),
      ],
      true
    );

    if (onClose) {
      onClose();
    }
  };

  const onReset = () => {
    setText(initialText);
    setRadio(initialRadio);

    if (onClose) {
      onClose();
    }
  };

  return (
    <section className={clx(styles.customBox, { isFilter })}>
      {!isFilter && (
        <p style={{ marginTop: 10 }} className={styles.customList_title}>
          Representation of filter in UI
        </p>
      )}

      <div className={clx(styles.custom_text_box, { isFilter })}>
        <div>
          <BuInput
            type="text"
            value={text}
            placeholder="Type to search"
            onChange={onChangeValue}
            className={styles.customList_item_input}
            disabled={isTextDisabled}
          />
        </div>

        <fieldset
          style={{ marginTop: 10 }}
          className={styles.customBox_radio_btns}
        >
          {radio.map((btn, index) => (
            <BuRadio
              key={`${btn.label}_${index}`}
              checked={btn.checked}
              label={btn.label}
              onChange={() => onChangeRadio(btn.value)}
            />
          ))}
        </fieldset>

        <div className={styles.custom_btns_controlls}>
          <BuButton onClick={onSubmit}>Apply</BuButton>
          <BuButton onClick={onReset} secondary>
            Cancel
          </BuButton>
        </div>
      </div>
    </section>
  );
};

export const TextConfigCustom: FC<Props> = ({
  fieldInputs,
  setFieldInputs,
}) => {
  useEffect(() => {
    setFieldInputs({
      ...fieldInputs,
      custom_filter_settings: {
        values: [],
        is_automatically_generated: true,
      },
    });
  }, []);

  return (
    <LayoutTextFilter
      isFilter={false}
      options={initialOptions}
      onChange={() => {}}
    />
  );
};
