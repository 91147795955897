import classNames from 'classnames';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { Dropdown, Popup } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import {
  TOOLTIP_CONTENT_ALL,
  TOOLTIP_CONTENT_SOME,
} from 'components/dashboard/Metrics/Widget/Controls/Live/constants';
import {
  pivotOptionsBuilder,
  removePivotBuilder,
  selectedPivotLabel,
} from 'components/dashboard/Metrics/Widget/Controls/Live/helpers';
import {
  pivotWidget,
  pivotWidgetMenu,
  pivotWidgetSearch,
  pivot2WidgetSeparator,
  pivotZIndex,
} from 'components/dashboard/Metrics/Widget/Controls/Live/styles';
import {
  DataDescriptor,
  BIPivotDescriptor,
} from 'components/dashboard/Metrics/metrics.types';

interface Props {
  readonly index: number;
  readonly isTargetMetricOnlyWidget: boolean;
  readonly groups: DataDescriptor[];
  readonly timeField?: DataDescriptor | null;
  readonly union: BIPivotDescriptor[];
  readonly symmetricDiff: BIPivotDescriptor[];
  readonly parentRef: React.RefObject<HTMLDivElement>;
  readonly canRemovePivots?: boolean;
  onPivotChange: (values: string[], pivotNro: number) => void;
}

export const Pivot: React.FC<Props> = ({
  index,
  canRemovePivots = true,
  isTargetMetricOnlyWidget,
  groups,
  timeField,
  union,
  symmetricDiff,
  parentRef,
  onPivotChange,
}) => {
  const refElement = useRef<HTMLDivElement>(null);

  const [searchValue, setSearchValue] = useState<string>('');

  const [direction, setDirection] = useState<'left' | 'right'>('right');

  useLayoutEffect(() => {
    const pivotContainerWidth =
      parentRef.current?.getBoundingClientRect().width;

    const pivotContainerX = parentRef?.current?.offsetLeft;

    const pivotX = refElement?.current?.offsetLeft;

    if (pivotContainerWidth && pivotContainerX && pivotX) {
      const halfOfPivotContainer = pivotContainerWidth / 2;

      const middlePointX = pivotContainerX + halfOfPivotContainer;

      if (pivotX > middlePointX) {
        setDirection('left');
      } else {
        setDirection('right');
      }
    }
  });

  return (
    <div ref={refElement}>
      <Dropdown
        fluid
        data-testing={`pivot-${index}`}
        direction={direction}
        className={classNames(pivotWidget, pivotZIndex(index + 1))}
        disabled={
          !groups[index - 1] ||
          groups[index - 1].name.toLocaleLowerCase() === 'none' ||
          isTargetMetricOnlyWidget ||
          union.length + symmetricDiff.length === 0
        }
        text={selectedPivotLabel(groups[index], timeField)}
        onClose={() => setSearchValue('')}
      >
        <Dropdown.Menu className={pivotWidgetMenu}>
          <div className={pivotWidgetSearch}>
            <BuIcon name={BoostUpIcons.Search} />
            <input
              value={searchValue}
              type="text"
              placeholder="Search..."
              data-testing="search_txt_field"
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSearchValue(e.target.value);
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onKeyDown={(e) => {
                if (e.key === ' ') {
                  e.preventDefault();
                  e.stopPropagation();
                  setSearchValue((prev) => {
                    if (prev && prev !== ' ') {
                      return prev + ' ';
                    }

                    return prev;
                  });
                }
              }}
            />
          </div>

          {canRemovePivots &&
            groups[index] &&
            removePivotBuilder(`pivot-remove-option`, index, onPivotChange)}

          <div className={pivot2WidgetSeparator}>
            Applies to all metrics
            <Popup
              position="top center"
              content={TOOLTIP_CONTENT_ALL}
              trigger={
                <div>
                  <BuIcon name={BoostUpIcons.Table} />
                  <BuIcon name={BoostUpIcons.GroupedColumnGraph} />
                  <BuIcon name={BoostUpIcons.GroupedBarGraph} />
                  <BuIcon name={BoostUpIcons.CombinedGraphs} />
                </div>
              }
              hoverable
            ></Popup>
          </div>

          {pivotOptionsBuilder(
            union,
            onPivotChange,
            `pivot-${index}-option`,
            searchValue,
            index,
            groups
          )}

          {!!symmetricDiff.length && (
            <div className={pivot2WidgetSeparator}>
              Applies to some metrics
              <Popup
                position="top center"
                content={TOOLTIP_CONTENT_SOME}
                trigger={
                  <div>
                    <BuIcon name={BoostUpIcons.CombinedGraphs} />
                  </div>
                }
                hoverable
              />
            </div>
          )}

          {pivotOptionsBuilder(
            symmetricDiff,
            onPivotChange,
            `pivot-${index}-all-option`,
            searchValue,
            index,
            groups
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
