import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { getLocation, push, replace } from 'connected-react-router';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { actions } from 'actions';
import * as t from 'actions/actionTypes';
import * as adminActions from 'actions/adminActions';
import { initApp } from 'actions/initActions';
import * as routeActions from 'actions/routeActions';
import * as settingsActions from 'actions/settingsActions';
import * as usersActions from 'actions/userActions';
import errorHandler from 'helpers/errorHandler';
import * as selectors from 'selectors';

const PUBLIC_ROUTES = ['/external/call'];

const isPublicRoute = (pathname) =>
  PUBLIC_ROUTES.some((route) => pathname.includes(route));

const isDevelopment = process.env.NODE_ENV !== 'production';
const isDatadogRumProduction = [
  'production',
  'production-emea',
  'mongodb',
].includes(process.env.REACT_APP_ENV);

export default function* init() {
  try {
    const sfdcError = Cookies.get('sfdc-canvas-app-info');

    if (sfdcError) {
      return yield put(replace('/error'));
    }

    const params = queryString.parse(window.location.search);
    const { needPersonate, auth_token: accessToken } = params;

    if (accessToken) {
      const headers = {
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const meResponse = yield call(
        axios.get,
        `${process.env.REACT_APP_BACKEND_URL}/api/user/me`,
        headers
      );

      yield put(
        usersActions.createUserAction.setOriginalUser(meResponse.data.data)
      );
      yield put(
        usersActions.createUserAction.updateState({
          apiSuccess: meResponse,
          role: meResponse.data.data.role,
          user_id: meResponse.data.data.user_id,
          name: meResponse.data.data.name,
          email: meResponse.data.data.email,
        })
      );
      yield put(
        adminActions.createAdminAction.updateState({
          email_impersonation: meResponse.data.data.email,
          email_domain: meResponse.data.data.email_domain,
          is_admin: meResponse.data.data.is_admin,
          is_user_impersonation_allowed:
            meResponse.data.data.is_user_impersonation_allowed,
        })
      );
    }

    if (needPersonate) {
      const meResponse = yield call(
        axios.get,
        `${process.env.REACT_APP_BACKEND_URL}/api/user/me`,
        { withCredentials: true }
      );

      yield put(
        usersActions.createUserAction.setOriginalUser(meResponse.data.data)
      );
      yield put(
        usersActions.createUserAction.updateState({
          apiSuccess: meResponse,
          role: meResponse.data.data.role,
          user_id: meResponse.data.data.user_id,
          name: meResponse.data.data.name,
          email: meResponse.data.data.email,
        })
      );
      yield put(
        adminActions.createAdminAction.updateState({
          email_impersonation: meResponse.data.data.email,
          email_domain: meResponse.data.data.email_domain,
          is_admin: meResponse.data.data.is_admin,
          is_user_impersonation_allowed:
            meResponse.data.data.is_user_impersonation_allowed,
        })
      );
    }

    const { user, pathname } = yield select((state) => ({
      user: selectors.getUser(state),
      pathname: getLocation(state).pathname,
    }));

    if (user.email && !user.apiError) {
      Sentry.getCurrentScope().setUser({ email: user.email });

      if (isDatadogRumProduction) {
        datadogRum.setUser({
          role: user.originalUser.role,
          email: user.originalUser.email,
          company: user.originalUser.email_domain,
          impersonation_role: user.role,
          impersonation_email: user.email,
        });

        datadogRum.init({
          applicationId: process.env.REACT_APP_DATADOG_RUM_APPLICATION_ID,
          clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
          site: 'datadoghq.com',
          service: `${process.env.REACT_APP_ENV}---frontend-app`,
          env: process.env.REACT_APP_ENV,
          // Specify a version number to identify the deployed version of your application in Datadog
          // version: '1.0.0',
          sessionSampleRate: parseInt(
            process.env.REACT_APP_DATADOG_RUM_SESSION_SAMPLE_RATE
          ),
          sessionReplaySampleRate: parseInt(
            process.env.REACT_APP_DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE
          ),
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel:
            process.env.REACT_APP_DATADOG_RUM_DEFAULT_PRIVACY_LEVEL,
        });
      }

      const { isImpersonate, isAdmin } = yield select((state) => ({
        isImpersonate: selectors.isImpersonate(state),
        isAdmin: selectors.isAdmin(state),
      }));

      const visitorId = user.email;
      const accountId = user.email ? user.email.split('@')[1] : undefined;

      if (!isAdmin && !isImpersonate && !!window.pendo) {
        window.pendo.initialize({
          visitor: {
            company: accountId,
            email: user.email,
            full_name: user.name,
            id: isDevelopment ? `development_${visitorId}` : visitorId,
            isAdmin,
            role: user.role,
          },
          account: {
            id: isDevelopment ? `development_${accountId}` : accountId,
          },
        });
        window.ChurnZero.push([
          'setAppKey',
          process.env.REACT_APP_CHURN_ZERO_APP_KEY,
        ]);
        window.ChurnZero.push([
          'setContact',
          isDevelopment ? `development_${accountId}` : accountId,
          isDevelopment ? `development_${visitorId}` : visitorId,
        ]);
      }

      yield all([
        put(routeActions.getRouterConfig({ read_only: user.read_only })),
        put(actions.ui.filters.init({ role: user.role, email: user.email })),
        put(actions.ui.preferences.user.get()),
      ]);

      yield put(settingsActions.getCompanySettings());
      yield put(settingsActions.getBusinessTypesConfig());
      yield put(settingsActions.getCompanyIsActiveSettings());
    } else if (
      !pathname.includes('login') &&
      !pathname.includes('admin') &&
      !pathname.includes('sign-up') &&
      !isPublicRoute(pathname)
    ) {
      yield put(replace('/login'));
    }

    yield put(usersActions.appInitiated());
  } catch (e) {
    const nextUrl = errorHandler(e);
    if (nextUrl) {
      yield put(nextUrl);
    }
  }
}

function* initAdmin() {
  // notice we are cleaning some specific things in order to reloaded...
  // we need to review this in order to clean all the configuration based on the user.
  yield put(actions.ui.manageForecast.resetSubmissionSettings());
  yield put(actions.ui.routes.clear());
  yield put(initApp());
  yield put(push('/'));
}

export function* initSaga() {
  yield takeEvery([t.ADMIN + t.IMPERSONATE + t.SUCCESS + t.INIT], initAdmin);
}
