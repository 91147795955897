import styled from '@emotion/styled';

export const MetricsFilterDescription = styled.div({
  margin: '4px 0px 24px 0px',
});

export const MetricsFilterDescriptionLong = styled.div({
  paddingTop: '8px',
});

export const FiltersContainer = styled.div({
  overflowY: 'auto',
  height: 'calc(100vh - 365px)',
  maxHeight: 'calc(100vh - 365px)',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});

export const AddFilterButtonContainer = styled.div({
  margin: '16px 0',
});
