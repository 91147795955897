import { AxiosError } from 'axios';

import * as t from 'actions/actionTypes';
import * as genericSagas from 'sagas/generic';

export type NewPipelineCreatedSpanUser = {
  email: string;
  name: string;
  role?: string;
};

export type NewPipelineCreatedSpanData = {
  name: string;
  total_amount: number | null;
  deals: string[];
  target?: number;
};

export type NewPipelineCreatedData = {
  user: NewPipelineCreatedSpanUser;
  span: NewPipelineCreatedSpanData[];
};

export type NewPipelineCreatedCurrentUserData = {
  name: string;
  target?: number;
};

export type NewPipelineCreatedPayload = {
  data: NewPipelineCreatedData[];
  selected_user_target: NewPipelineCreatedCurrentUserData[];
  cache: string;
};

export type NewPipelineCreatedAction = {
  type: string;
  payload: NewPipelineCreatedPayload;
  cache: string;
};

export type GetForecastPaceQuarterlyArgs = {
  manager?: string;
  pace_freq?: string;
  pace_interval?: string;
  close_date_interval?: string[];
  business_type_name?: string;
};

export type Response = {
  config: any;
  data: NewPipelineCreatedPayload;
  headers: {
    'cache-created': string;
  };
  request: any;
  status: number;
  statusText: string;
};

export const getNewPipelineCreated = (
  data: GetForecastPaceQuarterlyArgs,
  refreshCache = false
) => ({
  type: t.NEW_PIPELINE_CREATED + t.GET,
  url: refreshCache
    ? `${process.env.REACT_APP_BACKEND_URL}/api/data/pipeline/new?cache_refresh=1`
    : `${process.env.REACT_APP_BACKEND_URL}/api/data/pipeline/new`,
  data,
  saga: genericSagas.create,
  success: (
    payload: NewPipelineCreatedPayload,
    headers: Response
  ): NewPipelineCreatedAction => ({
    type: t.NEW_PIPELINE_CREATED + t.GET + t.SUCCESS,
    payload,
    cache: headers['headers']['cache-created'] || '',
  }),
  error: (e: AxiosError<NewPipelineCreatedPayload>) => ({
    type: t.NEW_PIPELINE_CREATED + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.NEW_PIPELINE_CREATED + t.GET + t.LOADING,
  }),
});

export const getPipelineCreatedByCloseDate = (
  data: GetForecastPaceQuarterlyArgs,
  refreshCache = false
) => ({
  type: t.PIPELINE_CREATED_BY_CLOSE_DATE + t.GET,
  url: refreshCache
    ? `${process.env.REACT_APP_BACKEND_URL}/api/data/pipeline/close_date_pipeline?cache_refresh=1`
    : `${process.env.REACT_APP_BACKEND_URL}/api/data/pipeline/close_date_pipeline`,
  data,
  saga: genericSagas.create,
  success: (
    payload: NewPipelineCreatedPayload,
    headers: Response
  ): NewPipelineCreatedAction => ({
    type: t.PIPELINE_CREATED_BY_CLOSE_DATE + t.GET + t.SUCCESS,
    payload,
    cache: headers['headers']['cache-created'] || '',
  }),
  error: (e: AxiosError<NewPipelineCreatedPayload>) => ({
    type: t.PIPELINE_CREATED_BY_CLOSE_DATE + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.PIPELINE_CREATED_BY_CLOSE_DATE + t.GET + t.LOADING,
  }),
});

export type NewPipelineCreatedFilterAction = {
  type: string;
  field: string;
  value: string;
};

export const setNewPipelineCreatedFilter = (
  field: string,
  value: string
): NewPipelineCreatedFilterAction => ({
  type: t.NEW_PIPELINE_CREATED + t.FILTER + t.CHANGE,
  field,
  value,
});

export const setPipelineByCloseDateFilter = (
  field: string,
  value: string
): NewPipelineCreatedFilterAction => ({
  type: t.PIPELINE_CREATED_BY_CLOSE_DATE + t.FILTER + t.CHANGE,
  field,
  value,
});
