import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Grid, GridColumn, GridRow, Popup } from 'semantic-ui-react';
import {
  ColorBlock,
  ColorBox,
  ColorChooser,
  ColorInput,
  ColorPicker,
  ColorText,
  CustomColor,
  Wrapper,
} from './styles';

interface IProps {
  color?: string;
  onChange: (color: string) => void;
  disabled?: boolean;
}

const COLOR_ROWS = [
  ['#F2F9FD', '#EDFBFD', '#F0FAFF', '#EFF0FA', '#F6F0FA', '#FBECFE', '#FFF0F0'],
  ['#F2F7ED', '#F4FDE7', '#F8FEE6', '#F4FDE7', '#FFF9DB', '#FFF7D1', '#FFF2E0'],
];

const MetricColorPicker: React.FC<IProps> = ({ color, onChange, disabled }) => {
  const [inputColor, setInputColor] = useState<string>(color ?? '#FFFFFF');
  const onChangeDebounce = useCallback(
    debounce((value) => {
      onChange(value);
    }, 1000),
    [onChange]
  );

  useEffect(() => {
    if (color) {
      setInputColor(color);
    }
  }, [color]);

  const colorChooser = (
    <ColorChooser>
      <Grid columns={7}>
        {COLOR_ROWS.map((colors, i) => (
          <GridRow key={i}>
            {colors.map((color, j) => (
              <GridColumn key={j}>
                <ColorBlock
                  color={color}
                  onClick={() => {
                    setInputColor(color);
                    onChange(color);
                  }}
                />
              </GridColumn>
            ))}
          </GridRow>
        ))}
      </Grid>
      <CustomColor>
        Custom:{' '}
        <ColorInput
          type="color"
          value={inputColor}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setInputColor(event.target.value);
            onChangeDebounce(event.target.value);
          }}
        />
      </CustomColor>
    </ColorChooser>
  );

  return (
    <Wrapper>
      <ColorBox disabled={!!disabled}>
        <Popup
          disabled={disabled}
          content={colorChooser}
          on="click"
          position="bottom center"
          pinned
          trigger={<ColorPicker disabled={!!disabled} color={color} />}
        />

        <ColorText
          type="text"
          disabled={!!disabled}
          value={inputColor?.toUpperCase()}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setInputColor(event.target.value);
            onChangeDebounce(event.target.value);
          }}
        />
      </ColorBox>
    </Wrapper>
  );
};

export default MetricColorPicker;
