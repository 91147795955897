import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  CustomObjectDrilldownItem,
  GetDrilldownDataForMetricObjectReturn,
  GetDrilldownDataForTablePayload,
  GetDrilldownDataParams,
  getDrilldownColumns,
  getDrilldownDataForMetricObject,
  getDrilldownTotals,
  updateObjectField,
} from 'api/RevBiDrilldown';
import { useEffect, useState } from 'react';
import { CsvColumns } from '../types';

import {
  columnTypes,
  styleFirstVisibleColumn,
} from 'components/UI/TableConfig/column-helper';
import { TableConfigurationColumn } from 'components/UI/TableConfig/types';
import {
  IColumn,
  IRow,
  SortOrder,
} from 'components/UI/common/TypedTable/TypedTable';
import { getTotalsRow } from 'components/UI/common/TypedTable/helper';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { LinkCellConfig } from 'components/UI/common/TypedTable/renderers/LinkCell';
import { get, set } from 'lodash';
import { useSelector } from 'react-redux';
import { getIsTotalsRowEnabled } from 'selectors';
import { getColumnAlignmentByType } from 'utils/aligns';

const getSalesforceLink = (salesforceUrl: string, id: string) =>
  `${salesforceUrl}/lightning/r/object/${id}/view`;

export const parseDrilldownItems = (
  items: CustomObjectDrilldownItem[],
  salesforceUrl: string | undefined
): IRow[] =>
  items.map((item) => {
    const hasCrmAndNextStepIsNull =
      item.crm_metadata && item.crm_metadata.next_step === null;

    return {
      ...item,
      // IMO this should be sent directly by backend
      // Instead of FE building the url through salesforceUrl
      // exposed on settings. But they decided to do it this way
      salesforceLink:
        salesforceUrl && getSalesforceLink(salesforceUrl, item.id),
      crm_metadata: hasCrmAndNextStepIsNull
        ? { ...item.crm_metadata, next_step: '' }
        : item.crm_metadata,
    };
  });

export const parseCsvColumns = (
  columns: TableConfigurationColumn[]
): CsvColumns[] =>
  columns.map((c) => ({
    display_name: c.display_name,
    object_field:
      c.object_field === 'crm_metadata.opportunity_name'
        ? 'opportunity_name'
        : c.object_field,
  }));

export const parseDrilldownColumns = (
  columns: TableConfigurationColumn[],
  getColumnType: ReturnType<typeof columnTypes>
) => {
  const parsedColumns = (columns || []).map<IColumn>((column, index) => {
    const specificColumnTypeConfig = getColumnType(column, columns);

    const showTooltip = ![
      ColumnTypes.NUMBER,
      ColumnTypes.PERCENT,
      ColumnTypes.MONEY,
    ].includes(specificColumnTypeConfig.type);

    // RC-337: Force money type for number type on RevBI
    const getColumnTypeAlignment = (type: string) =>
      type === 'number' ? 'money' : type;

    const baseColumn = {
      field: column.object_field,
      sort_order: SortOrder.DESCENDING,
      sortable: column.sortable,
      id: `${column.field_name}-${index}`,
      label: column.display_name,
      fieldHeaderHighlight: !!column.fieldHeaderHighlight,
      ...specificColumnTypeConfig,
      showTooltip,
      editable: column.editable,
      align: getColumnAlignmentByType(
        column.type == 'custom'
          ? column.meta?.type
          : getColumnTypeAlignment(column.type)
      ),
    };
    const hasLinkToSalesforce =
      column.object_field.toLocaleLowerCase() === 'name';

    if (hasLinkToSalesforce) {
      baseColumn.type = ColumnTypes.LINK;
      baseColumn.config = {
        link_pattern: '${row.salesforceLink}',
        isNewWindow: true,
      } as LinkCellConfig;
    }

    return baseColumn;
  });

  styleFirstVisibleColumn(parsedColumns, 400);

  return parsedColumns;
};

const MUTATION_KEY = ['updateObjectField', 'Custom Table'];

interface UseUpdateFieldMutationOptions {
  doNotInvalidateOnSettled?: boolean;
}

const useUpdateFieldMutation = (
  metricObject: string,
  params: GetDrilldownDataParams,
  { doNotInvalidateOnSettled }: UseUpdateFieldMutationOptions
) => {
  const keyToUpdate = ['getDrilldownDataForMetricObject', metricObject, params];
  const queryClient = useQueryClient();
  const updateFieldMutation = useMutation({
    mutationKey: [MUTATION_KEY],
    mutationFn: ({
      id,
      editedFields,
    }: {
      id: string;
      editedFields: GetDrilldownDataForTablePayload;
    }) => {
      return updateObjectField(metricObject, id, editedFields);
    },
    onMutate: async (update) => {
      await queryClient.cancelQueries(keyToUpdate);

      const currentData =
        queryClient.getQueryData<GetDrilldownDataForMetricObjectReturn>(
          keyToUpdate
        );

      if (!currentData) {
        return;
      }

      const itemBeingUpdated = currentData?.items.find(
        (item) => item.id === update.id
      );

      if (!itemBeingUpdated) {
        return;
      }

      const originalFieldValues: Record<string, any> = Object.fromEntries(
        Object.keys(update.editedFields).map((key) => [
          key,
          get(itemBeingUpdated, key),
        ])
      );

      const optimisticUpdate = {
        ...itemBeingUpdated,
      };

      Object.entries(update.editedFields).forEach(([key, value]) => {
        set(optimisticUpdate, key, value);
      });

      const newData = {
        ...currentData,
        items: currentData.items.map((item) =>
          item.id === optimisticUpdate.id ? optimisticUpdate : item
        ),
      };

      queryClient.setQueryData(keyToUpdate, newData);

      return {
        itemIdTryingToUpdate: optimisticUpdate.id,
        originalFieldValues,
      };
    },
    onSettled: () => {
      const noMutationOfSameKeyInProgress =
        queryClient.isMutating({ mutationKey: MUTATION_KEY }) <= 1;

      const canInvalidate = !doNotInvalidateOnSettled;

      if (noMutationOfSameKeyInProgress && canInvalidate) {
        queryClient.invalidateQueries(keyToUpdate);
      }
    },
    onError: (_err, _variables, context) => {
      if (
        !context ||
        !context.itemIdTryingToUpdate ||
        !context.originalFieldValues
      ) {
        return;
      }

      const dataWithOptimisticUpdate =
        queryClient.getQueryData<GetDrilldownDataForMetricObjectReturn>(
          keyToUpdate
        );

      if (!dataWithOptimisticUpdate) {
        return;
      }

      const { itemIdTryingToUpdate, originalFieldValues } = context;

      const optimisticItem = dataWithOptimisticUpdate.items.find(
        (item) => item.id === itemIdTryingToUpdate
      );

      if (!optimisticItem) {
        return;
      }

      const revertedItem = {
        ...optimisticItem,
      };

      Object.entries(originalFieldValues).forEach(([key, value]) => {
        set(revertedItem, key, value);
      });

      const dataWithItemReverted: GetDrilldownDataForMetricObjectReturn = {
        ...dataWithOptimisticUpdate,
        items: dataWithOptimisticUpdate.items.map((item) =>
          item.id === revertedItem.id ? revertedItem : item
        ),
      };

      queryClient.setQueryData(keyToUpdate, dataWithItemReverted);
    },
  });

  return updateFieldMutation;
};

const useGetDrilldownColumns = (apiUrl: string) => {
  const { data: drilldownColumnsData, isLoading: areColumnsLoading } = useQuery(
    {
      queryKey: ['getDrilldownColumns', apiUrl],
      queryFn: () => getDrilldownColumns(apiUrl),
    }
  );

  const drilldownColumns = drilldownColumnsData?.columns || [];
  const order = drilldownColumnsData?.order.object_field;

  return { drilldownColumns, order, areColumnsLoading };
};

const useGetDrilldownData = (
  metricObject: string,
  params: GetDrilldownDataParams,
  canFetch: boolean
) => {
  const [totalCount, setTotalCount] = useState(0);

  const getDrilldownDataQueryKey = [
    'getDrilldownDataForMetricObject',
    metricObject,
    params,
  ];
  const { data: drilldownData, isLoading: isDrilldownDataLoading } = useQuery({
    queryKey: getDrilldownDataQueryKey,
    queryFn: () => getDrilldownDataForMetricObject(metricObject, params as any),
    enabled: canFetch,
    cacheTime: 1,
    onSuccess: (data) => {
      if (!totalCount) {
        setTotalCount(data.count ?? 0);
      }
    },
  });

  return {
    drilldownData,
    isDrilldownDataLoading,
    totalCount,
  };
};

interface UseManageCustomObjectDataOptions {
  doNotInvalidateOnSettled?: boolean;
}

export const useManageCustomObjectData = (
  apiUrl: string,
  metricObject: string,
  params: GetDrilldownDataParams,
  { doNotInvalidateOnSettled }: UseManageCustomObjectDataOptions
) => {
  const { drilldownColumns, order, areColumnsLoading } =
    useGetDrilldownColumns(apiUrl);

  const isTotalsRowEnabled = useSelector(getIsTotalsRowEnabled);
  const queryClient = useQueryClient();

  const totalParams = {
    ...params,
    columns: drilldownColumns
      .map((c) => c.object_field)
      .filter(Boolean) as string[],
  };

  const { data: customObjectTotals, isLoading: areTotalsLoading } = useQuery({
    queryKey: ['getDrilldownDataForMetricObject', 'totals', params],
    queryFn: () => getDrilldownTotals(metricObject, totalParams),
    enabled: !areColumnsLoading && isTotalsRowEnabled,
  });

  const drilldownDataParams = {
    ...params,
    columns: drilldownColumns.map((c) => c.object_field),
    order_by_expression: params.order_by_expression
      ? params.order_by_expression
      : order,
  };

  const { drilldownData, isDrilldownDataLoading, totalCount } =
    useGetDrilldownData(metricObject, drilldownDataParams, !areColumnsLoading);

  const mutation = useUpdateFieldMutation(metricObject, drilldownDataParams, {
    doNotInvalidateOnSettled,
  });

  const isLoading = areColumnsLoading || isDrilldownDataLoading;

  const invalidateData = () => {
    queryClient.invalidateQueries(['getDrilldownDataForMetricObject']);
  };

  // On unmount invalidate the query to avoid stale data
  // if the user opens the modal again
  useEffect(() => {
    return () => {
      invalidateData();
    };
  }, []);

  const totalRow =
    !areColumnsLoading && isTotalsRowEnabled
      ? getTotalsRow(customObjectTotals, areTotalsLoading)
      : undefined;

  return {
    drilldownItems: drilldownData?.items || [],
    totalCount,
    drilldownColumns: drilldownColumns,
    order,
    updateObjectField: mutation.mutateAsync,
    isLoading,
    invalidateData,
    totalRow,
  };
};
