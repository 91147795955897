import { css } from 'emotion';

export const shadowRoot = css`
  label: email-body-shadow-root;
`;

export const showMore = css`
  label: email-body-show-more;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
