import { connect } from 'react-redux';

import * as topicActions from 'actions/topicActions';
import Tabs from 'components/UI/TopicsDiscussedTabs/TabsWrapper';
import * as selectors from 'selectors';

const mapStateToProps = (state, { dealId, accountId }) => ({
  topics: selectors.getDealTopics(state, dealId || accountId),
  lastCall: selectors.getDealDataLastCall(state),
  loading: selectors.isTopicsLoading(state),
  entityId: dealId || accountId,
});

const mapDispatchToProps = (dispatch, { dealId, accountId }) => ({
  fetch: (timespan) =>
    dispatch(topicActions.fetchDealTopics(dealId, accountId, timespan)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
