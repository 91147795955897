import * as R from 'ramda';

import { IRow } from 'components/UI/common/TypedTable/TypedTable';

export function getRawValue(field: string, row: IRow): any {
  if (R.path(field.split('.'), row) !== undefined)
    return R.path(field.split('.'), row);

  return R.pathOr(undefined, ['extra', field, 'value'], row);
}
