import { XYCoord } from 'dnd-core';
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import FilterDropdown, {
  FilterDropdownOwnProps,
} from 'components/settings/ManageFilters/shared/FilterDropdown';

interface FilterDropdownWithDnDOwnProps extends FilterDropdownOwnProps {
  index: number;
  onDrop: () => void;
  onMove: (dragIndex: number, hoverIndex: number) => void;
}

type DragItem = {
  index: number;
  id: string;
  type: string;
};

const DRAG_ITEM_TYPE = 'filterDropdown';

const WrappedFilterDropdown: React.FC<FilterDropdownWithDnDOwnProps> = ({
  index,
  onDrop,
  onMove,
  ...passedProps
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop(
    {
      accept: DRAG_ITEM_TYPE,
      hover: (item: DragItem, monitor) => {
        if (!ref.current || index === item.index) {
          return;
        }

        const dragIndex = item.index;
        const hoverIndex = index;
        const { left, right } = ref.current.getBoundingClientRect();
        const clientOffset: XYCoord = monitor.getClientOffset() as XYCoord;
        const hoverClientX = clientOffset.x - left;
        const hoverMiddleX = (right - left) / 2;

        if (
          (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) ||
          (dragIndex > hoverIndex && hoverClientX > hoverMiddleX)
        ) {
          return;
        }

        onMove(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },

      drop: onDrop,
    },
    [onMove]
  );

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: DRAG_ITEM_TYPE,
      item: { id: passedProps.filter.name, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [passedProps]
  );

  drag(drop(ref));

  return (
    <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1, paddingBottom: 5 }}>
      <FilterDropdown {...passedProps} />
    </div>
  );
};

export default WrappedFilterDropdown;
