import styled from '@emotion/styled';
import React, { ReactNode, useMemo } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import { WidgetColumnErrorConfiguration } from 'api/RevBiWidget';
import { BIWidget } from '../../metrics.types';
import { isBIMetricSimple } from '../../Create/utils';
import { FORECAST_SUBMISSION } from '../../constants';

const NoDataContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  height: '100%',
  '>.bar-graph': {
    fontSize: '12em',
    color: 'var(--bu-gray-400)',
  },
  '>div': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '32px',
    '>.noDataTitle': {
      fontWeight: 315,
      fontSize: '18px',
    },
  },
  '.fs-body-error': {
    p: {
      textAlign: 'left',
      margin: '0',
    },
    ul: {
      padding: '0',
      li: {
        listStyle: 'disc',
        marginLeft: '20px',
      },
    },
  },
});

export const NoDataMessage: React.FC<{
  errors: WidgetColumnErrorConfiguration;
  widget: BIWidget | undefined;
}> = ({ errors, widget }) => {
  const widgetContainsAnyFSMetric = useMemo(
    () =>
      widget?.metric_list.some(
        (metric) =>
          isBIMetricSimple(metric) && metric.object === FORECAST_SUBMISSION
      ),
    [widget]
  );

  const messages: ReactNode[] = useMemo(() => {
    // Extracting messages from dynamic keys
    let messages: ReactNode[] = [];

    for (const key in errors) {
      if (Array.isArray(errors[key])) {
        errors[key].forEach((item) => {
          if (item.message) {
            messages.push(<li>{item.message}</li>);
          }
        });
      }
    }

    return messages;
  }, [errors]);

  return (
    <NoDataContainer>
      <div>
        <p className="noDataTitle">No data found for this metric</p>
        <p className="noDataBody">
          Try changing the metric property or conditions.
        </p>
        {widgetContainsAnyFSMetric ? (
          <div className="fs-body-error">
            <p>Forecast based metrics require to display data:</p>
            <ul>
              <li>"Name", "Type" and "Period type" as conditions</li>
              <li>Time Interval and User Pivots</li>
            </ul>
          </div>
        ) : (
          ''
        )}
      </div>
      <BuIcon name={BoostUpIcons.ColumnGraph} className="bar-graph" />
    </NoDataContainer>
  );
};
