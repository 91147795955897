import classNames from 'classnames';
import React from 'react';
import { ActionCreator } from 'typescript-fsa';

import { ViewBy } from 'common/enums/filters';
import {
  IColumn,
  IRow,
  SortOrder,
  ValueProp,
  TypedTableCellConfig,
} from 'components/UI/common/TypedTable/TypedTable';
import { currencyFormatter } from 'components/UI/common/TypedTable/formatters';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { CustomCellConfig } from 'components/UI/common/TypedTable/renderers/CustomCell';
import { getCellValue } from 'components/UI/common/TypedTable/renderers/custom/common';
import { ApiParams } from 'components/chart-dashboards/Dashboard/types';
import {
  target_seller_main_line,
  target_seller_line_fill,
  target_seller_main_line_percent,
  target_seller_main_line_block,
  cellValue,
  cellContainer,
  clickable,
} from 'components/dashboard/Scorecard/styles';
import { Column, Data, User } from 'components/dashboard/Scorecard/types';
import { ModalsScheme, PersistQueryParams } from 'navigation/types';
import { openModal } from 'navigation/utils';
import { FiltersForAPI } from 'selectors';

const getColorValue = (value: number) => {
  switch (true) {
    case value > 81:
      return 'emerald';
    case value > 50:
      return 'citrine';
    default:
      return 'red';
  }
};

const dataTypeToScheme: {
  [key: string]: ModalsScheme;
} = {
  accounts: '/accounts',
  deals: '/deals',
  events: '/events',
};

const onClickOpenModal = (
  field_name: string,
  user_email: ValueProp,
  data_type: string,
  click_api_url: string,
  filters: FiltersForAPI | undefined,
  api_params: ApiParams,
  title_graph: string,
  default_filters: FiltersForAPI | undefined,
  persistModalParams: ActionCreator<PersistQueryParams>,
  viewBy: ViewBy
) => {
  const filtersUpdated = { ...filters, ...default_filters };
  const time_span = filters ? filters['activity_period'] : '';

  if (field_name === 'user' && typeof user_email === 'string') {
    const modalParams = {
      email: user_email,
      filters: {
        ...api_params,
        time_span: filters ? filters['activity_period'] : '',
        sort_by: 'start',
        ...filters,
        rollup_by: viewBy,
        managers: [user_email],
        users: [user_email],
      },
    };

    openModal({
      scheme: '/activity/personal/:email',
      params: { email: user_email },
      persistParams: modalParams,
      persistor: persistModalParams,
    });
  } else if (data_type) {
    const params = {
      apiUrl: click_api_url,
      id: undefined,
      title: title_graph,
      context: '',
      deltaOverPeriod: 0,
      filters: JSON.stringify({
        ...api_params,
        time_span,
        sort_by: 'start',
        ...filtersUpdated,
        rollup_by: viewBy,
        users: [user_email],
      }),
    };

    openModal({
      scheme: dataTypeToScheme[data_type],
      persistParams: params,
      persistor: persistModalParams,
    });
  }
};

const getColumn = (
  column: Column,
  apifilters: FiltersForAPI | undefined,
  persistModalParams: ActionCreator<PersistQueryParams>,
  showManager: boolean,
  viewBy: ViewBy,
  companyCurrency: string
): IColumn => {
  const { field_name, display_name, data_type, click_api_url, var_type } =
    column;
  const formatter = currencyFormatter(
    companyCurrency,
    0
  ) as TypedTableCellConfig['formatter'];
  return {
    field: field_name,
    id: field_name,
    label: display_name,
    type:
      data_type || field_name === 'user'
        ? ColumnTypes.CUSTOM
        : ColumnTypes.TEXT,
    align: data_type ? (var_type !== 'money' ? 'center' : 'right') : 'left',
    hidden: field_name === 'manager' && !showManager,
    sortable: true,
    apifilters,
    config: {
      className: data_type && click_api_url ? 'clickeable' : '',
      renderer({ column, row }) {
        const {
          title_graph,
          api_params,
          apifilters,
          click_api_url,
          data_type,
          default_filters,
        } = column;
        const { email } = row;
        const value =
          column.var_type !== 'money'
            ? getCellValue({ column, row })
            : formatter!(getCellValue({ column, row }));

        const target = column.target;
        const showPercentage = [
          'user',
          'number_of_opportunities_closed',
        ].includes(column.field_name);

        const progressBarPercentValue =
          !showPercentage && row[`${column.field_name}_percentage`]
            ? Number(row[`${column.field_name}_percentage`])
            : 0;

        const cellColor =
          !showPercentage && target
            ? getColorValue(progressBarPercentValue)
            : '';

        const isClickable = !!(
          (column.data_type && column.click_api_url) ||
          (field_name === 'user' && typeof email === 'string')
        );

        const showProgressBar = target > 0;

        return (
          <div
            onClick={() =>
              onClickOpenModal(
                field_name,
                email,
                data_type,
                click_api_url,
                apifilters,
                api_params,
                title_graph,
                default_filters,
                persistModalParams,
                viewBy
              )
            }
            className={classNames(cellContainer, cellColor, {
              [clickable]: isClickable,
            })}
          >
            <div className={cellValue}>{value}</div>
            {showProgressBar && (
              <div className={target_seller_main_line_block(column.align)}>
                <div className={target_seller_main_line}>
                  <div
                    style={{
                      width: progressBarPercentValue,
                    }}
                    className={target_seller_line_fill}
                  />
                </div>
                <div className={target_seller_main_line_percent}>
                  {`${progressBarPercentValue}%`}
                </div>
              </div>
            )}
          </div>
        );
      },
    } as CustomCellConfig,
    sort_order: SortOrder.ASCENDING,
    ...column,
  };
};

export const getColumns = (
  columns: Column[],
  filters: FiltersForAPI | undefined,
  persistModalParams: ActionCreator<PersistQueryParams>,
  showManager: boolean,
  viewBy: ViewBy,
  companyCurrency: string
): IColumn[] =>
  columns.map((column) =>
    getColumn(
      column,
      filters,
      persistModalParams,
      showManager,
      viewBy,
      companyCurrency
    )
  );

export const getRows = (rows: Data, users: User[], columns: Column[]): IRow[] =>
  users.map((user, index) => {
    return {
      ...columns.reduce((acc, column) => {
        acc[column.field_name] = rows[column.field_name]
          ? rows[column.field_name][index].value
          : '';
        acc[`${column.field_name}_percentage`] = rows[column.field_name]
          ? rows[column.field_name][index].percentage
          : 0;
        if (column.field_name === 'user') {
          acc['email'] = rows[column.field_name][index].email;
        }
        return acc;
      }, {} as IRow),
    };
  });
