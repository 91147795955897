import { MANAGER_ROLES, USER_ROLES } from 'common/constants';
import { IColumn, SortOrder } from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { ClickableCellConfig } from 'components/UI/common/TypedTable/renderers/ClickableCell';
import {
  DropDownCellConfig,
  IDropDownOptions,
} from 'components/UI/common/TypedTable/renderers/DropDownCell';
import { cellActiveColor } from 'components/settings/ManageUsers/styles';

type GetColumnsParams = {
  currentUserRole?: string;
  managersOptions: IDropDownOptions[];
};

type getColumnsFunction = (params: GetColumnsParams) => {
  [id: string]: IColumn;
};

export const getColumns: getColumnsFunction = ({
  currentUserRole,
  managersOptions,
}) => ({
  name: {
    id: 'name',
    label: 'Name',
    field: 'name',
    type: ColumnTypes.USER,
    sortable: false,
    sort_order: SortOrder.ASCENDING,
    width: 250,
    config: {
      className: 'primary-cell',
    } as ClickableCellConfig,
  },
  email: {
    id: 'email',
    label: 'Email',
    field: 'email',
    type: ColumnTypes.TEXT,
    sortable: false,
    sort_order: SortOrder.ASCENDING,
    config: {},
  },
  title: {
    id: 'title',
    label: 'Title',
    field: 'title',
    type: ColumnTypes.TEXT,
    sortable: false,
    width: 200,
    sort_order: SortOrder.ASCENDING,
    config: {},
  },
  numOpenOps: {
    id: 'numOpenOps',
    label: 'Open Opportunities',
    field: 'num_open_opportunities',
    type: ColumnTypes.NUMBER,
    align: 'center',
    sortable: false,
    width: 200,
    sort_order: SortOrder.ASCENDING,
    config: {},
  },
  role: {
    id: 'role',
    label: 'Role',
    field: 'role',
    sortable: false,
    type: ColumnTypes.DROPDOWN,
    editable: true,
    sort_order: SortOrder.ASCENDING,
    width: 300,
    config: {
      options: Object.values(USER_ROLES).map((role) => ({
        key: role,
        text: role,
        value: role,
      })),
    } as DropDownCellConfig,
  },
  manager: {
    id: 'manager',
    label: 'Manager',
    field: 'manager',
    type: ColumnTypes.DROPDOWN,
    editable: true,
    hidden: !Object.values(MANAGER_ROLES).includes(currentUserRole || ''),
    sortable: false,
    sort_order: SortOrder.ASCENDING,
    width: 250,
    config: {
      options: managersOptions,
    } as DropDownCellConfig,
  },
  status: {
    id: 'status',
    label: 'Status',
    field: 'status',
    type: ColumnTypes.DROPDOWN,
    editable: true,
    sortable: false,
    sort_order: SortOrder.ASCENDING,
    width: 125,
    config: {
      options: [
        {
          text: 'Active',
          value: 'active',
          key: 'active',
          className: cellActiveColor,
        },
        { text: 'Disabled', value: 'disabled', key: 'disabled' },
      ],
    } as DropDownCellConfig,
  },
  id: {
    id: 'id',
    label: 'CRM ID',
    field: 'sfdc_user_id',
    type: ColumnTypes.TEXT,
    sortable: false,
    width: 200,
    sort_order: SortOrder.ASCENDING,
    config: {},
  },
});
