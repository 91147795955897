import styles from './styles';
import { IProps } from './types';
import classNames from 'classnames';
import React from 'react';

import { RiskScore } from 'components/UI/common/TypedTable/renderers/RiskScoreCell';

const RiskScoreBlock: React.FC<IProps> = ({
  name,
  riskFactors,
  positiveIndicators,
  score,
  activitySyncInfo,
  isProgression,
}) => {
  return (
    <div
      id={`deal-container-header-info-meeting-${name.replaceAll(' ', '')}`}
      className={classNames(styles.header, 'deal-header-info-meeting-item')}
      onClick={(e) => e.stopPropagation()}
    >
      <span
        className={styles.header_title}
        onClick={(e) => e.stopPropagation()}
      >
        {name}:&nbsp;
      </span>
      <RiskScore
        isProgression={isProgression}
        value={score}
        riskFactors={riskFactors}
        positiveIndicators={positiveIndicators}
        activitySyncInfo={activitySyncInfo}
      />
    </div>
  );
};

export default RiskScoreBlock;
