import axios, { CancelToken } from 'axios';
import isFunction from 'lodash/isFunction';
import hash from 'object-hash';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import { call, put, delay } from 'redux-saga/effects';

import { READY, CACHE, CREATE } from 'actions/actionTypes';
import { cancellationWhiteList } from 'common/constants';
import errorHandler from 'helpers/errorHandler';

const cancelToken = CancelToken;

const ongoingRequests = {};

const showErrorToast = (options, error) => {
  if (options?.toast?.error) {
    const response = error.response.data;
    let text = options.toast.defaultErrorMessage || 'Unexpected failure';

    if (typeof response === 'string') {
      text = response;
    } else if (Array.isArray(response)) {
      text = response.find((item) => item?.message)?.message ?? text;
    } else if (response?.error?.message?.[0]) {
      text = response.error.message[0];
    }

    toast.error(text, { position: 'bottom-left' });
  }
};
/**
 * @deprecated Please use useQuery instead of this function.
 * @see https://vocalo.atlassian.net/wiki/spaces/EN/pages/3210215429/Proposal+for+Standardizing+Data+Fetching+and+Caching+using+React+Query
 * @see https://tanstack.com/query/v4
 */
export function* create(action) {
  yield put(action.loading());
  const source = cancelToken.source();

  try {
    const body = R.compose(R.defaultTo({}), R.propOr({}, 'data'))(action);
    if (
      ongoingRequests[action.type] &&
      cancellationWhiteList.includes(action.type)
    ) {
      // console.warn(`REQUEST TO ${action.url} WAS CANCELLED`);
      // console.warn('BODY:', body);
      source.cancel();
    }
    ongoingRequests[action.type] = true;
    const response = yield call(axios.post, action.url, body, {
      cancelToken: source.token,
    });
    yield put(action.success(response.data, response));
    ongoingRequests[action.type] = false;
    yield delay(5000);
    yield put({ type: action.type + READY });
  } catch (e) {
    const nextUrl = errorHandler(e);
    if (nextUrl) {
      yield put(nextUrl);
    }
    if (action.error) {
      yield put(action.error(e));
    }
    ongoingRequests[action.type] = false;

    showErrorToast(action.options, e);
  }
}

export function* createCached(action) {
  yield put(action.loading());
  try {
    const body = R.compose(R.defaultTo({}), R.propOr({}, 'data'))(action);
    const hashKey = hash({ url: action.url, body });
    const response = yield call(axios.post, action.url, body);
    yield put({
      type: CACHE + CREATE,
      key: hashKey,
      data: response.data.data,
      entity_id: response.data.data.id,
    });
    yield put(action.success(response.data));
    yield delay(5000);
    yield put({ type: action.type + READY });
  } catch (e) {
    const nextUrl = errorHandler(e);
    if (nextUrl) {
      yield put(nextUrl);
    }
    if (action.error) {
      yield put(action.error(e));
    }

    showErrorToast(action.options, e);
  }
}

/**
 * @deprecated Please use useQuery instead of this function.
 * @see https://vocalo.atlassian.net/wiki/spaces/EN/pages/3210215429/Proposal+for+Standardizing+Data+Fetching+and+Caching+using+React+Query
 * @see https://tanstack.com/query/v4
 */
export function* update(action) {
  yield put(action.loading());
  try {
    const response = yield call(axios.put, action.url, action.data);
    yield put(action.success(response.data));
    yield delay(7000);
    yield put({ type: action.type + READY });
  } catch (e) {
    const nextUrl = errorHandler(e);
    if (nextUrl) {
      yield put(nextUrl);
    }
    if (action.error) {
      yield put(action.error(e));
    }

    showErrorToast(action.options, e);
  }
}

/**
 * @deprecated Please use useQuery instead of this function.
 * @see https://vocalo.atlassian.net/wiki/spaces/EN/pages/3210215429/Proposal+for+Standardizing+Data+Fetching+and+Caching+using+React+Query
 * @see https://tanstack.com/query/v4
 */
export function* patch(action) {
  yield put(action.loading());
  try {
    const response = yield call(axios.patch, action.url, action.data);
    yield put(action.success(response.data));
    yield put({ type: action.type + READY });
  } catch (e) {
    const nextUrl = errorHandler(e);
    if (nextUrl) {
      yield put(nextUrl);
    }
    if (action.error) {
      yield put(action.error(e));
    }

    showErrorToast(action.options, e);
  }
}

/**
 * @deprecated Please use useQuery instead of this function.
 * @see https://vocalo.atlassian.net/wiki/spaces/EN/pages/3210215429/Proposal+for+Standardizing+Data+Fetching+and+Caching+using+React+Query
 * @see https://tanstack.com/query/v4
 */
export function* get(action) {
  yield put(action.loading());
  try {
    const config = {
      params: action.data,
    };
    const response = yield call(axios.get, action.url, config);
    yield put(action.success(response.data));
  } catch (e) {
    const nextUrl = errorHandler(e);
    if (nextUrl) {
      yield put(nextUrl);
    }
    if (action.error) {
      yield put(action.error(e));
    }
    showErrorToast(action.options, e);
  }
}

export function* getCached(action) {
  const { dataTransformer } = action;
  yield put(action.loading());
  try {
    const config = {
      params: action.data,
    };
    const hashKey = hash({ url: action.url, config });
    const response = yield call(axios.get, action.url, config);
    yield put(action.success(response.data));
    yield put({
      type: CACHE + CREATE,
      key: hashKey,
      data: isFunction(dataTransformer)
        ? dataTransformer(response.data)
        : response.data,
    });
  } catch (e) {
    const nextUrl = errorHandler(e);
    if (nextUrl) {
      yield put(nextUrl);
    }
    if (action.error) {
      yield put(action.error(e));
    }

    showErrorToast(action.options, e);
  }
}

/**
 * @deprecated Please use useQuery instead of this function.
 * @see https://vocalo.atlassian.net/wiki/spaces/EN/pages/3210215429/Proposal+for+Standardizing+Data+Fetching+and+Caching+using+React+Query
 * @see https://tanstack.com/query/v4
 */
export function* remove(action) {
  yield put(action.loading());
  try {
    const config = action.data ? { data: action.data } : null;

    const response = yield call(axios.delete, action.url, config);
    yield put(action.success(response.data));
  } catch (e) {
    const nextUrl = errorHandler(e);
    if (nextUrl) {
      yield put(nextUrl);
    }
    if (action.error) {
      yield put(action.error(e));
    }

    showErrorToast(action.options, e);
  }
}
