import * as R from 'ramda';

import { formatMoney } from 'common/numbers';
import { TableConfigurationColumn } from 'components/UI/TableConfig/types';
import { IRow, ValueProp } from 'components/UI/common/TypedTable/TypedTable';
import { isEmptyValue } from 'components/dashboard/ForecastRollUps/helpers/processTableData';
import {
  CSVHeaders,
  CSVHeaderType,
} from 'components/dashboard/ForecastRollUps/types';

const valueFormatter = (
  companyCurrencyCode: string,
  value: ValueProp,
  header: CSVHeaderType,
  row: IRow,
  key: string
) => {
  if (isEmptyValue(value) && header.type !== 'progressBar') {
    return '-';
  }

  switch (header.type) {
    case 'text':
      return String(value).replace(/,/g, '');
    case 'custom': {
      if (['Title', 'My Org'].includes(key)) {
        return String(value).replace(/,/g, '');
      }
      return '';
    }
    case 'money':
      return `"${formatMoney(
        companyCurrencyCode,
        Math.round(Number(value)),
        0
      )}"`;
    case 'percentage':
      return `${Number(value).toFixed()}%`;
    case 'multiplier':
      return `${Number(value)}x`;
    case 'progressBar':
      const relativeFieldValue = row[header.relativeField || ''];
      return relativeFieldValue
        ? `${Math.round(
            (Number(row[header.value]) / Number(relativeFieldValue)) * 100
          )}%`
        : '-';
    default:
      return '';
  }
};

export const buildFlatList = (
  tree: IRow[],
  level: number,
  primaryField: string,
  parent?: IRow
): IRow[] =>
  tree.reduce((acc: IRow[], item) => {
    let cloneItem = R.omit(['children'], item) as IRow;
    cloneItem.isManager = Array.isArray(item.children) && item.children.length;
    cloneItem.level = level;
    cloneItem.manager = parent ? parent[primaryField] || parent.email : null;
    cloneItem.managerTitle = parent?.Title || null;

    const children = item.children || [];

    return [
      ...acc,
      cloneItem,
      ...buildFlatList(children, level + 1, primaryField, item),
    ];
  }, []);

export const getCSVHeaders = (
  columns: TableConfigurationColumn[],
  primaryField: string
) =>
  columns.reduce(
    (acc: CSVHeaders, column) => {
      if (!column.hidden) {
        acc.header[column.object_field] = column.display_name;
        acc.headerType[column.object_field] = {
          value: column.display_name,
          type: column.type,
        };

        if (column.meta.sub_value) {
          acc.header[column.meta.sub_value.relative_field] = !column.meta
            .sub_value.aggregation
            ? column.meta.sub_value.relative_field
            : `${column.display_name} Team`;
          acc.headerType[column.meta.sub_value.relative_field] = {
            value: column.meta.sub_value.relative_field,
            type: column.type,
          };
        }

        if (column.meta.progress_bar) {
          acc.header[
            `${column.object_field} (%)`
          ] = `${column.display_name} (%)`;
          acc.headerType[`${column.object_field} (%)`] = {
            value: column.object_field,
            type: 'progressBar',
            relativeField: column.meta.progress_bar.relative_field,
          };
        }

        if (column.object_field === primaryField) {
          acc.header['manager'] = 'Manager';
          acc.headerType['manager'] = { value: 'Manager', type: 'text' };
          acc.header['managerTitle'] = 'Manager Title';
          acc.headerType['managerTitle'] = {
            value: 'Manager Title',
            type: 'text',
          };
        }
      }

      return acc;
    },
    {
      header: {},
      headerType: {},
    }
  );

export const getCSVData = (
  companyCurrencyCode: string,
  tree: IRow[],
  primaryField: string,
  headerType: CSVHeaders['headerType']
) => {
  const list = buildFlatList(tree, 0, primaryField);
  const headerKeys = Object.keys(headerType);

  return list.map((row) =>
    headerKeys.reduce(
      (acc, key) => ({
        ...acc,
        [key]: valueFormatter(
          companyCurrencyCode,
          row[key],
          headerType[key],
          row,
          key
        ),
      }),
      {}
    )
  );
};
