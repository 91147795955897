import {
  tooltipLabel,
  tooltipContainer,
  tooltipCaption,
} from 'components/dashboard/Metrics/Widget/Table/helpers/DeltaTooltip.styles';
import React from 'react';

interface DeltaTooltipProps {
  currentValue: string;
  previousValue: string;
  changesSince: string;
}

const MAP_CHANGES_SINCE_TO_LABEL: Record<string, string> = {
  last_1_days: 'Yesterday',
  last_7_days: 'Last 7 days',
  last_14_days: 'Last 14 days',
  last_30_days: 'Last 30 days',
  last_60_days: 'Last 60 days',
  last_3_months: 'Last 3 months',
  current_quarter: 'Quarter Start',
};

const DeltaTooltip: React.FC<DeltaTooltipProps> = ({
  currentValue,
  previousValue,
  changesSince,
}) => {
  return (
    <div className={tooltipContainer}>
      <div>
        <span className={tooltipLabel}>Changed From: </span>
        {previousValue} to {currentValue}
      </div>
      <div className={tooltipCaption}>
        Delta Based on Changes since {MAP_CHANGES_SINCE_TO_LABEL[changesSince]}
      </div>
    </div>
  );
};

export default DeltaTooltip;
