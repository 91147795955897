import * as styles from './styles';
import classNames from 'classnames';
import { css } from 'emotion';
import React from 'react';

import { multiplierFormatter } from 'components/UI/TableConfig/column-helper';
import {
  IColumn,
  IRow,
  SortOrder,
  TypedTableCellConfig,
} from 'components/UI/common/TypedTable/TypedTable';
import { currencyFormatter } from 'components/UI/common/TypedTable/formatters';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { CustomCellConfig } from 'components/UI/common/TypedTable/renderers/CustomCell';
import { getTargetKey } from 'components/dashboard/SellerTargets/helper';
import {
  Target,
  Targets,
  UserTargetsByRole,
} from 'reducers/sellerTargetsReducer/types';
import { calcFiscalMonthName } from 'utils/dates';

type GetColumnsFunction = (
  isQuarterly: boolean,
  targets: Targets,
  fiscalYear: string,
  selectedBusinessType: string,
  currentFiscalYear: number,
  companyCurrency: string,
  isCoverage: boolean
) => {
  columns: IColumn[];
  extraHeader: IColumn[];
};

const getTargetColumn = (
  isQuarterly: boolean,
  isCoverage: boolean,
  companyCurrency: string,
  period: number | undefined,
  label: string,
  fiscalYear: string,
  currentYear: string,
  businessType?: string
): IColumn => ({
  align: 'right',
  config: {
    isCoverage,
    isMoney: true,
    formatter: (isCoverage
      ? multiplierFormatter
      : currencyFormatter(
          companyCurrency,
          0
        )) as TypedTableCellConfig['formatter'],
    className: (row: IRow, rows: IRow[], column: IColumn) => {
      const targets = row[column.field]?.valueOf() as {
        target: number;
        new_target: number;
      };
      if (!targets || targets.new_target === targets.target) {
        return '';
      }
      return targets.target < targets.new_target
        ? styles.cellGreen
        : styles.cellRed;
    },
    renderer: (params) => {
      let targets = params.row[params.column.field]?.valueOf() as {
        target: number;
        new_target: number;
      };
      let value = undefined;
      let subValue = undefined;
      if (targets && params.column.config.formatter) {
        value = !isNaN(targets.new_target)
          ? params.column.config.formatter(targets.new_target)
          : params.column.config.formatter(targets.target);
        subValue = !isNaN(targets.target)
          ? params.column.config.formatter(targets.target)
          : undefined;
      }
      return (
        <div className={styles.targetCellContainer}>
          <span className={styles.targetCellValue}>{value}</span>
          {!!subValue && subValue !== value && (
            <span className={styles.targetCellSubValue}>{subValue}</span>
          )}
        </div>
      );
    },
  } as CustomCellConfig,
  field: getTargetKey(isQuarterly, period, fiscalYear, businessType),
  id: getTargetKey(isQuarterly, period, fiscalYear, businessType),
  [isQuarterly ? 'quarter' : 'month']: period,
  editable: false,
  sort_order: SortOrder.ASCENDING,
  sortable: false,
  type: ColumnTypes.CUSTOM,
  label: `${label} ${currentYear} ${fiscalYear}`,
  ...(businessType ? { business_type: businessType } : {}),
});

export const getColumns: GetColumnsFunction = (
  isQuarterly,
  targets,
  fiscalYear,
  selectedBusinessType,
  currentFiscalYear,
  companyCurrency,
  isCoverage
) => {
  let columns: IColumn[] = [];
  const currentYear = String(currentFiscalYear) === fiscalYear ? 'CFY' : 'FY';
  const overallTargets = targets.overall_targets || [];

  if (overallTargets.length) {
    columns.push({
      id: 'user',
      label: 'Users',
      field: 'user',
      type: ColumnTypes.CUSTOM,
      sortable: false,
      sort_order: SortOrder.ASCENDING,
      config: {
        className: (row: IRow, rows: IRow[]) => css`
          background-color: ${row.isChanged
            ? 'var(--bu-orange-300) !important'
            : 'var(--bu-white)'};
        `,
        renderer: (params) => <>{params.row[params.column.field]}</>,
      } as CustomCellConfig,
    });

    overallTargets
      // The next two lines ensure the uniqueness of the columns
      .map((item) => (isQuarterly ? item.quarter : item.month))
      .filter((value, index, self) => self.indexOf(value) === index)
      .forEach((period: number | undefined) => {
        const label = isQuarterly
          ? `Q${period}`
          : calcFiscalMonthName(1, period || 1, 'short');

        columns.push(
          getTargetColumn(
            isQuarterly,
            isCoverage,
            companyCurrency,
            period,
            label,
            fiscalYear,
            currentYear,
            selectedBusinessType
          )
        );
      });
  }

  // Adding QUARTER_DELIMITER_COLUMN class to separate vertically the quarters
  if (isQuarterly) {
    let currentQuarter: number;
    columns = columns.map((col) => {
      const { config, quarter } = col;
      let delimiterCls: string;
      if (currentQuarter !== quarter) {
        currentQuarter = quarter;
        delimiterCls = styles.QUARTER_DELIMITER_COLUMN;
      }

      return {
        ...col,
        config: {
          ...config,
          className: (row: IRow, rows: IRow[], clmn?: IColumn) =>
            classNames(
              config.className && typeof config.className === 'function'
                ? config.className(row, rows, clmn)
                : config.className,
              delimiterCls
            ),
        },
      };
    });
  }

  return {
    columns,
    extraHeader: [],
  };
};

const getTargetRows = (
  isQuarterly: boolean,
  targetList: Target[],
  fiscalYear: string,
  selectedBusinessType: string,
  targetType: string
): IRow[] =>
  targetList.map((target: Target, i: number) => ({
    id: `${targetType}-${i}`,
    targetType,
    user: target.user.name || target.user.email,
    email: target.user.email,
    fiscal_year: fiscalYear,
    isChanged: target.targets?.some((el) => el.target !== el.new_target),
    ...target.targets?.reduce(
      (acc, item: Target) => ({
        ...acc,
        [getTargetKey(
          isQuarterly,
          isQuarterly ? item.quarter : item.month,
          fiscalYear,
          selectedBusinessType
        )]: { new_target: item.new_target, target: item.target },
        ...(isQuarterly
          ? { quarter: target.quarter }
          : { month: target.month }),
      }),
      {}
    ),
  }));

const getParentRow = (
  isQuarterly: boolean,
  targetList: Target[],
  fiscalYear: string,
  rows: IRow[],
  selectedBusinessType: string,
  targetType: string
): IRow => {
  const calculatedCustomTotals: {
    [key: string]: { target: number; new_target: number };
  } = {};

  targetList.forEach((target: Target) => {
    if (target.targets?.length) {
      target.targets.forEach((innerTarget: Target) => {
        const key = getTargetKey(
          isQuarterly,
          isQuarterly ? innerTarget.quarter : innerTarget.month,
          fiscalYear,
          selectedBusinessType
        );

        calculatedCustomTotals[key] = rows.reduce(
          (agg, item) => {
            const value = item[key] as { target: number; new_target: number };
            return {
              target: isNaN(value.target)
                ? agg.target
                : agg.target + value.target,
              new_target: isNaN(value.new_target)
                ? agg.new_target
                : agg.new_target + value.new_target,
            };
          },
          { target: 0, new_target: 0 }
        );
      });
    }
  });

  return {
    id: targetType,
    user: targetType,
    children: rows,
    isTopRow: true,
    disableEdit: true,
    ...calculatedCustomTotals,
  };
};

export const getRows = (
  isQuarterly: boolean,
  targets: Targets,
  importedTargets: UserTargetsByRole[],
  fiscalYear: string,
  selectedBusinessType: string
): IRow[] => {
  const overallTargets = targets.overall_targets || [];
  const userTargetsByRole = importedTargets || [];
  const rowOverall: IRow = {
    id: 'overall',
    user: 'Overall',
    isTopRow: true,
    fiscal_year: fiscalYear,
    ...overallTargets.reduce(
      (acc, target: Target) => ({
        ...acc,
        [getTargetKey(
          isQuarterly,
          isQuarterly ? target.quarter : target.month,
          fiscalYear,
          selectedBusinessType
        )]: { target: target.target, new_target: target.target },
      }),
      {}
    ),
  };

  const allTargets: IRow[] = [];

  userTargetsByRole.forEach((target, index) => {
    const roleUsers = getTargetRows(
      isQuarterly,
      target.user_targets,
      fiscalYear,
      selectedBusinessType,
      'user'
    );

    const rowTopUsers = getParentRow(
      isQuarterly,
      target.user_targets,
      fiscalYear,
      roleUsers,
      selectedBusinessType,
      target.role_display_name
    );

    allTargets.push(rowTopUsers);

    if (userTargetsByRole.length - 1 !== index) {
      allTargets.push({
        id: `empty-${target.role_display_name}`,
        isEmpty: true,
      });
    }
  });

  return [rowOverall, { id: 'empty-overall', isEmpty: true }, ...allTargets];
};
