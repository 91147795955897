import React, { useState, useEffect } from 'react';
import { DropdownProps } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import * as s from 'components/UI/DealsFlatTableTS/Table/Pagination/styles';
import { IProps } from 'components/UI/DealsFlatTableTS/Table/Pagination/types';
import MiniSearchInput from 'components/UI/MiniSearchInput';

const Pagination: React.FC<IProps> = (props) => {
  const [inputPageValue, setInputPageNumber] = useState(props.currentPage);

  useEffect(() => setInputPageNumber(props.currentPage), [props.currentPage]);

  const options = (props.options || [5, 10, 25, 50]).map((option) => ({
    key: option,
    text: option,
    value: option,
  }));

  return (
    <div className={s.container}>
      {props.isVisibleSearch && (
        <MiniSearchInput
          inputSearchValue={props.inputSearchValue}
          onChangeInputSearchValue={props.onChangeInputSearchValue}
          onEnterPressSearch={props.onEnterPressSearch}
          resetInputSearch={props.resetInputSearch}
        />
      )}

      <div className={s.ofPages}>
        {props.currentPage} of {props.totalPages}
      </div>

      <div className={s.wrapper}>
        <BuButton
          icon
          borderless
          size={BuControlSize.SMALL}
          disabled={props.currentPage === 1}
          onClick={props.onClickPrev}
        >
          <BuIcon name={BoostUpIcons.ChevronLeft} />
        </BuButton>
        <BuButton
          icon
          borderless
          size={BuControlSize.SMALL}
          disabled={
            props.currentPage === props.totalPages || props.totalPages === 0
          }
          onClick={props.onClickNext}
        >
          <BuIcon name={BoostUpIcons.ChevronRight} />
        </BuButton>
      </div>
    </div>
  );
};

export default Pagination;
