import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import DealInsights from 'components/deal';
import { useHideBlockingScreen } from 'components/hooks/useHideBlockingScreen';

const FullScreenOpportunities: FC = () => {
  const { dealId } = useParams<{ dealId: string }>();

  useHideBlockingScreen();

  if (!dealId) {
    return null;
  }

  return (
    <DealInsights
      dealId={dealId}
      isPanel={false}
      match={{ params: { dealId } }}
      isFullScreen
    />
  );
};

export default FullScreenOpportunities;
