import styled from '@emotion/styled';
import React from 'react';

const P = styled.p`
  margin-bottom: 1.5em;
`;

const Wrapper = styled.div`
  color: #064fb8;
  background: white;
  position: fixed;
  left: 0;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  flex-direction: column;
  padding: 0 2em;

  @media screen and (min-width: 992px), print {
    display: none;
    visibility: hidden;
  }
`;

export default () => (
  <Wrapper id="widthLimitation">
    <P>
      Given screen resolution is not supported, please view this from a desktop
      browser. Please make sure that it is at least 1000px wide. If you are on a
      desktop browser, try widening the browser window.
    </P>
  </Wrapper>
);
