import { useGetSubValueList } from './DefinitionsMetricList.helper';
import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';
import { toast } from 'react-toastify';

import BuButton from 'components/UI/BuButton';
import { MetricsButtonsContainer } from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/DefinitionsMetricsList/styles';
import { MetricEditConfirmationPopup } from 'components/dashboard/Metrics/common/MetricEditConfirmationPopup/MetricEditConfirmationPopup';
import {
  BIMetricCreated,
  BIWidget,
  MetricDeleteInfo,
  MetricDisplayInfo,
  MetricsDisplay,
  SelectedItem,
} from 'components/dashboard/Metrics/metrics.types';
import { fetchApi } from 'utils/network';
import { MetricItem } from './MetricItem';
import update from 'immutability-helper';

interface Props {
  readonly widget: BIWidget;
  setIsCreateOrEditMetricMode: Dispatch<SetStateAction<boolean>>;
  setIsSideBarOpen: Dispatch<SetStateAction<boolean>>;
  onReorder: (metrics: BIMetricCreated[]) => void;
  onRemoveMetric: (metric: BIMetricCreated) => void;
  setMetricIdToEdit: (id: string) => void;
  onChangeAdvanceConfig: (metricId: string, config: MetricDisplayInfo) => void;
}

export const DefinitionsMetricsList: React.FC<Props> = ({
  widget,
  setIsCreateOrEditMetricMode,
  setIsSideBarOpen,
  onReorder,
  onRemoveMetric,
  setMetricIdToEdit,
  onChangeAdvanceConfig,
}) => {
  const metrics: BIMetricCreated[] = widget.metric_list || [];
  const metricsDisplayConfig: MetricsDisplay | undefined =
    widget.advanced_configurations?.display?.metrics;

  const [metricDeleteInfo, setMetricDeleteInfo] = useState<MetricDeleteInfo>();
  const [isEditConfirmationOpen, setIsEditConfirmationOpen] =
    useState<boolean>(false);
  const [selectedElement, setSelectedElement] = useState<SelectedItem>();
  const [metricList, setMetricList] = useState<BIMetricCreated[]>(metrics);

  useEffect(() => {
    setMetricList(metrics);
  }, [metrics]);

  const { data: subValuesData, isLoading: isLoadingSubValues } =
    useGetSubValueList(widget);

  const handleEditConfirm = (id: string) => {
    if (id) {
      setMetricIdToEdit(id);
      setIsCreateOrEditMetricMode(true);
    }
  };

  const handleEditMetric = (id: string, name: string): void => {
    fetchApi<void, MetricDeleteInfo>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics_delete_info/${id}`,
      queryMethod: 'get',
      setData: (result: MetricDeleteInfo) => {
        setMetricDeleteInfo(result);
        if (
          result.widget_deleted_list.length > 0 ||
          result?.widget_edited_list.length > 0 ||
          result?.synthetic_metric_list.length > 0
        ) {
          setIsEditConfirmationOpen(true);
          setSelectedElement({ id, name });
        } else {
          handleEditConfirm(id);
        }
      },
      setError: (_: string | null) => {
        toast.error(`Failed to check if safe to edit metric`);
      },
    });
  };

  const handleCreateNewMetric = () => {
    setMetricIdToEdit('');
    setIsCreateOrEditMetricMode(true);
  };

  return (
    <>
      {metricList?.map((metric: BIMetricCreated, index: number) => (
        <MetricItem
          key={metric._id}
          index={index}
          metric={metric}
          metricsDisplayConfig={metricsDisplayConfig}
          subValuesData={subValuesData}
          isLoadingSubValues={isLoadingSubValues}
          handleEditMetric={handleEditMetric}
          onRemoveMetric={onRemoveMetric}
          onChangeAdvanceConfig={onChangeAdvanceConfig}
          onReorder={(dragIndex, hoverIndex) => {
            setMetricList((prevMetrics) =>
              update(prevMetrics, {
                $splice: [
                  [dragIndex, 1],
                  [hoverIndex, 0, prevMetrics[dragIndex]],
                ],
              })
            );
          }}
          onDrop={() => {
            onReorder(metricList);
          }}
        />
      ))}

      <div>
        <span>Add an existing metric or create a new metric</span>
      </div>

      <MetricsButtonsContainer>
        <BuButton secondary onClick={() => setIsSideBarOpen(true)}>
          + Existing Metric
        </BuButton>
        <BuButton secondary onClick={handleCreateNewMetric}>
          + New Metric
        </BuButton>
      </MetricsButtonsContainer>

      <MetricEditConfirmationPopup
        isEditConfirmationOpen={isEditConfirmationOpen}
        metricDeleteInfo={metricDeleteInfo}
        selectedElement={selectedElement}
        onEditConfirm={handleEditConfirm}
        setIsEditConfirmationOpen={setIsEditConfirmationOpen}
      />
    </>
  );
};
