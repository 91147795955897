import React from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import BuPopup from 'components/UI/BuPopup';
import * as s from 'components/settings/ManageUsers/UserDetails/EmailValidationIcon/styles';

const EMAIL_USED_MESSAGE = 'The email is already being used.';
const EMAIL_INVALID_MESSAGE =
  'Invalid email username, please use only valid characters.';

const EmailValidationIcon: React.FC<{
  isValid: boolean | null;
  isAvailable: boolean;
}> = ({ isValid, isAvailable }) => {
  if (isValid === null) {
    return (
      <div className={s.icon}>
        <BuIcon name={BoostUpIcons.CheckCircle} color="var(--bu-gray-400)" />
      </div>
    );
  }

  const buIcon = (
    <BuIcon
      name={isValid ? BoostUpIcons.CheckCircle : BoostUpIcons.DangerCircle}
      color={isValid ? 'var(--bu-green-500)' : 'var(--bu-red-400)'}
    />
  );

  return (
    <div className={s.icon}>
      {isAvailable && isValid ? (
        buIcon
      ) : (
        <BuPopup trigger={(ref) => <div ref={ref}>{buIcon}</div>}>
          <div className={s.popup}>
            {isAvailable ? EMAIL_INVALID_MESSAGE : EMAIL_USED_MESSAGE}
          </div>
        </BuPopup>
      )}
    </div>
  );
};

export default EmailValidationIcon;
