import React from 'react';
import { Loader } from 'semantic-ui-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  ForecastDashboardMetric,
  ForecastDashboardMetricId,
  ForecastProfileData,
  getForecastSummaryProfile,
  setForecastSummaryProfile,
} from 'api/ForecastSummary';
import TypedTable, {
  IRow,
  onChangeCallback,
} from 'components/UI/common/TypedTable/TypedTable';
import * as styles from 'components/settings/UserProfiles/Views/ForecastSummary/styles';
import { getColumns } from 'components/settings/UserProfiles/Views/ForecastSummary/utils';
import { toast } from 'react-toastify';

interface Props {
  profileId: string;
}

interface ForecastRow extends IRow {
  id: ForecastDashboardMetricId;
  text: string;
  disable?: boolean;
  enable: boolean;
}

const metrics: ForecastDashboardMetric[] = [
  { id: 'show_forecasted_amount', text: 'Forecasted Amount', disable: true },
  {
    id: 'show_forecasted_deals_list',
    text: 'Forecasted Deal List',
    disable: true,
  },
  {
    id: 'show_forecasted_deals_amount',
    text: 'Forecast included deals total Amount',
    disable: true,
  },
  {
    id: 'show_forecasted_amount_delta',
    text: 'Forecast Amount Delta between previous forecast submission and current one',
  },
  {
    id: 'show_forecasted_count_delta',
    text: 'Forecast Deal Delta between previous forecast submission and current one (included deal count then vs now)',
  },
];

const ForecastSummary: React.FC<Props> = ({ profileId }) => {
  if (!profileId) {
    return <></>;
  }

  const queryClient = useQueryClient();
  // Request the data
  const {
    data: requestData,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ['forecastSummaryProfile', profileId],
    queryFn: () => getForecastSummaryProfile(profileId),
  });

  const dataMutation = useMutation({
    mutationFn: (payload: ForecastProfileData) =>
      setForecastSummaryProfile(profileId, payload),
    onMutate: async (newState) => {
      // Snapshot the previous value
      const previousState = queryClient.getQueryData([
        'forecastSummaryProfile',
        profileId,
      ]);

      // Optimistically set new toggle state
      queryClient.setQueryData(['forecastSummaryProfile', profileId], {
        data: newState,
      });

      // Save previous snapshots in context object in case we need to rollback
      return { previousState };
    },
  });

  const data: ForecastRow[] = requestData
    ? metrics.map((row) => ({
        ...row,
        enable: requestData.data[row.id] ?? false,
      }))
    : metrics.map((row) => ({ ...row, enable: false }));

  const columns = getColumns();

  const handleChange: onChangeCallback = (column, editedRow, newValue) => {
    const payload: ForecastProfileData = data.reduce(
      (acc, row) => ({
        ...acc,
        [row.id]: row.id === editedRow.id ? newValue : row.enable,
      }),
      {}
    );
    dataMutation.mutate(payload, {
      onSuccess: () => {
        toast.success('Forecast summary saved successfully.', {
          position: 'bottom-left',
        });
      },
      onError: () => {
        toast.error('Error saving forecast summary.', {
          position: 'bottom-left',
        });
      },
    });
  };

  return (
    <div className={styles.wrapper}>
      {isLoading ? (
        <Loader content="Loading" />
      ) : isError ? (
        <div> Error loading data </div>
      ) : (
        <>
          <div className={styles.title}>
            Configure which metrics you want to see on the Forecast summary
            hover in Rollups.
          </div>
          <div>
            <TypedTable.Border>
              <TypedTable
                columns={columns}
                data={data}
                onChange={handleChange}
                width="100%"
                rowClassName={() => 'show-controls'}
                stickyHeader
              />
            </TypedTable.Border>
          </div>
        </>
      )}
    </div>
  );
};

export default ForecastSummary;
