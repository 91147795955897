import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { actions } from 'actions';

export type IState = {};

const initialState: IState = {};

const reducer = reducerWithInitialState<IState>(initialState).case(
  actions.ui.forecastWidgetAllChangedDate.changeDateSince,
  (state, payload): IState => payload
);

export const forecastWidgetDate = { initialState, reducer };
