import * as t from 'actions/actionTypes';
import * as genericSagas from 'sagas/generic';

export const enterCallsStats = () => ({
  type: t.CALLS_STATS + t.ENTER,
});

export const exitCallsStats = () => ({
  type: t.CALLS_STATS + t.EXIT,
});

export const getCallsStats = (graphId, type, data) => ({
  type: t.CALLS_STATS + t.GET,
  data,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/${type}/stats`,
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.CALLS_STATS + t.GET + t.SUCCESS,
    graphId,
    payload,
  }),
  error: (e) => ({
    type: t.CALLS_STATS + t.GET + t.ERROR,
    graphId,
    error: e,
  }),
  loading: () => ({
    type: t.CALLS_STATS + t.GET + t.LOADING,
    graphId,
  }),
});

export const changeCallsGraphGlobalFilter = (changes) => ({
  type: t.CALLS_STATS + t.CHANGE + t.GLOBAL + t.FILTER,
  changes,
});
