import { css } from 'emotion';

import { fontDefaultDropdown } from 'assets/css/common';

export const panelContainer = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  height: 100%;
`;

export const loaderSidePanelContainer = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const panelHeader = css`
  display: flex;
  flex-direction: column;
  background-color: var(--bu-white);
  padding: 10px 15px;
  border-bottom: 1px solid var(--bu-gray-400);
  height: 70px;
  justify-content: center;

  width: 100%;
  z-index: 2;

  .row {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  .title {
    flex: 1;
  }

  .locking-period-message {
    color: var(--bu-gray-700);
    font-size: 12px;
    margin-top: 4px;
  }

  .schedule-submission-alert {
    width: 150px;
    margin-right: 20px;
  }

  .back {
    position: absolute;
    height: 16px;
    color: #777777;
    font-size: 14px;
    cursor: pointer;
  }

  .main-text {
    font-size: 16px;
    font-weight: 500;
    font-family: var(--bu-font-medium);
    line-height: 19px;
    margin-left: 20px;
    color: var(--bu-gray-900);
    text-transform: capitalize;

    &.one-row {
      margin: 0;
    }
  }

  .main-text-extended {
    font-size: 12px;
    line-height: 20px;
    color: var(--bu-gray-700);
    margin: -5px 0 -5px 20px;

    > span {
      font-family: var(--bu-font-medium);
      font-size: 16px;
      font-weight: 500;
      text-transform: capitalize;
      color: var(--bu-gray-900);
    }
  }
`;

export const recordTitle = css`
  padding: 12px 15px 0 15px;
  font-size: 12px;
  font-weight: 600;
  color: #939393;
  text-transform: uppercase;
`;

export const backIcon = css`
  width: 7px;
  height: 10px;
  margin-right: 5px;
  margin-bottom: 3px;
`;

export const historyIcon = css`
  width: 18px;
  height: 18px;
  margin-right: 10px;
  filter: invert(29%) sepia(9%) saturate(0%) hue-rotate(135deg) brightness(97%)
    contrast(96%);
`;

export const closeButton = css`
  position: absolute;
  right: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const form = css`
  padding: 15px;
`;

export const formSubTitle = css`
  color: var(--bu-gray-900);
  font-family: var(--bu-font-regular);
  font-size: 13px;
  line-height: 17px;
  display: block;
  margin-top: 5px;
`;

export const copyButton = css`
  height: 20px;
  width: 20px;

  button {
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: var(--bu-primary-500);
  }

  i {
    font-size: 16px;
  }
`;

export const copyBoard = css`
  position: absolute;
  display: flex;
  top: 50px;
  left: 0px;
  height: 340px;
  width: 100%;
  box-shadow: 0px 10px 11px 5px rgb(0 0 0 / 19%);
  background-color: var(--bu-white);
  z-index: 99;

  > .cpb_header {
    display: flex;
    align-items: center;
    padding: 10px;
    width: 100%;
    border-bottom: 1px solid var(--bu-gray-400);
    font-family: var(--bu-font-regular);
    font-size: 14px;
    color: var(--bu-gray-900);
  }

  > .cpd_body {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: calc(100% - 40px - 40px);
    width: 100%;
    padding: 16px 10px;
    position: relative;
    z-index: 1;
  }

  > .cpb_button_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--bu-white);
    border-top: 1px solid var(--bu-gray-400);
    padding: 10px;
    width: 100%;
  }
`;

export const cpdBodyColumn = css`
  font-family: var(--bu-font-regular);
  font-size: 14px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;

  > .cpd_copied-count {
    margin-top: 3px;
    margin-left: 25px;
    font-size: 13px;
    color: var(--bu-gray-700);
    font-family: var(--bu-font-regular);
  }
`;

export const dealsIncluded = css`
  font-size: 12px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--bu--limegreen-100);
  border-radius: 4px;
  color: var(--bu-gray-900);
  padding: 8px 12px;
  margin-bottom: 10px;

  .copy-submit-wrapper {
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 6px;
    color: var(--bu-gray-900);
    font-weight: bold;
  }

  div > span {
    color: var(--bu-gray-900);
    font-weight: bold;
  }

  div.btn {
    user-select: none;
    font-weight: 600;
    color: var(--bu-primary-700);
    cursor: pointer;
  }
`;

export const radioButtonOptionStyle = css`
  ${fontDefaultDropdown};
  color: var(--bu-gray-900) !important;
  margin-top: 0 !important;
  max-width: 100% !important;
  margin-bottom: 0px !important;

  input:checked ~ label:before {
    background-color: var(--bu-primary-500) !important;
    border-color: var(--bu-primary-500) !important;
  }

  input:checked ~ label:after {
    background-color: var(--bu-white) !important;
  }
`;

export const forecastHistoryInput = css`
  border: 1px solid var(--bu-gray-500);
  border-radius: 4px;
  color: #383838;
  font-family: var(--bu-font-regular);
  height: 30px;
  line-height: 24px;
  margin: 5px 0 10px 0;
  padding: 5px 10px;
  transition: 0.4s;
  width: 100%;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='text'] {
    font-size: 14px;
    -moz-appearance: textfield;
    padding-left: 10px;

    &:focus {
      border-color: var(--bu-primary-500);
    }

    &::placeholder {
      color: var(--bu-gray-900);
    }
  }
`;

export const forecastHistoryTextarea = css`
  display: block;
  min-height: 60px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 20px;
  border: 1px solid var(--bu-gray-500);
  border-radius: 4px;
  transition: 0.4s;
  resize: vertical;
  padding: 5px 10px;
  color: #383838;
  font-family: var(--bu-font-regular);
  font-size: 14px;
  line-height: 19px;

  &:focus {
    border-color: var(--bu-primary-500);
  }

  &::placeholder {
    color: var(--bu-gray-900);
  }
`;

export const submitForecastButton = css`
  i {
    font-size: 18px;
    margin-right: 5px;
  }
`;

export const btnLine = css`
  display: flex;
  flex-direction: row;
  align-items: center;

  > button {
    display: block;
    margin-right: 10px;
    min-width: 145px;

    &:disabled {
      cursor: default;
    }
  }
`;

export const loaderArea = css`
  padding: 10px 0;
`;

export const recordHeader = css`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-left: 12px;
  cursor: pointer;
  user-select: none;
  height: 24px;
  color: #777777;
  font-size: 14px;
  font-weight: 500;
  padding-right: 10px;
`;

export const recordIconsWrapper = css`
  display: flex;
  align-items: center;
`;

export const recordSignIcon = css`
  &.active {
    color: var(--bu-primary-500);
  }
`;

export const recordLockIcon = css`
  width: 20px;
  font-size: 22px;
  margin-right: 5px;
`;

export const recordTHeader = css`
  display: flex;
  flex-direction: column;
  border: none;
  color: var(--bu-gray-900);
  font-size: 14px;
  font-weight: 600;
  font-family: var(--bu-font-medium);
`;

export const formRecord = css`
  border: 1px solid var(--bu-gray-300);
  background-color: var(--bu-white);
  padding: 10px 0 0;
  min-height: 46px;
  margin: 12px 15px;
  border-radius: 4px;
  overflow: visible;
  transition: background-color 0.4s, border-color 0.4s;
  box-shadow: 0px 1px 3px 0px #00000026;

  :hover:not(.active) {
    border-color: var(--bu-primary-500);
    background-color: var(--bu-gray-200);
  }

  &.active {
    border-top: 2px solid var(--bu-primary-500);
  }
`;

export const historyItemContainer = css`
  border: 1px solid var(--bu-gray-300);
  background-color: var(--bu-white);
  width: 90%;
  box-shadow: 0px 1px 3px 0px #00000026;
  margin-bottom: 12px;
  border-radius: 4px;

  &.single {
    width: 100%;
    border: none;
    box-shadow: none;
    padding: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const history = css`
  padding: 10px 20px;
  background-color: var(--bu-white);
  border-top: 1px solid var(--bu-gray-400);
`;

export const historyRow = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const historyDealsButton = css`
  cursor: pointer;
  color: var(--bu-primary-700);
  border-radius: 4px;
  font-size: 12px;
  margin-left: 10px;
`;

export const historyDate = css`
  color: var(--bu-gray-700);
  font-family: var(--bu-font-regular);
  font-size: 12px;
  line-height: 17px;
  display: block;
  padding-top: 6px;
`;

export const historyValue = css`
  color: var(--bu-gray-900);
  font-size: 14px;
  font-weight: 700;
  padding-top: 12px;
  padding-bottom: 3px;
`;

export const historyNotes = css`
  color: var(--bu-gray-700);
  font-family: var(--bu-font-regular);
  font-size: 12px;
  line-height: 22px;
  display: block;
  padding-bottom: 12px;
  margin-top: 3px;

  &.empty {
    padding-bottom: 0px;
    margin-bottom: -10px;
  }
`;

export const viewAll = css`
  color: var(--bu-primary-700);
  font-size: 12px;
  padding-top: 0;
  padding-bottom: 10px;
  cursor: pointer;
`;

export const panelBody = css`
  height: 100%;
  overflow: auto;
  background-color: var(--bu-gray-200);

  &.deals {
    padding: 0 0 60px 0;
  }
`;

export const panelBodyHistory = css`
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .sf-history-item {
    padding: 16px;
  }
`;

export const noSubmissions = css`
  text-align: center;
  margin: 40px 20px;
  font-size: 16px;
  color: #9b9b9b;
`;

export const dealDescription = css`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  width: 100%;
`;

export const toggleAllText = css`
  font-weight: bold;
  font-size: 14px;
  font-weight: bold;
  color: #777777;
  padding-left: 10px;
`;

export const dealName = css`
  font-weight: bold;
  font-size: 13px;
  font-weight: bold;
  padding-bottom: 10px;
  cursor: pointer;
`;

export const dealInfo = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #5a5a5a;
  font-size: 13x;
`;

export const dealDate = css`
  color: var(--bu-gray-700);
`;

export const success = css`
  color: var(--bu-color-positive);
  padding-left: 3px;
  padding-bottom: 3px;
  font-size: 13px;
`;

export const failure = css`
  color: var(--bu-color-negative);
  padding-left: 3px;
  padding-bottom: 3px;
`;

export const searchInput = css`
  display: flex;
  height: 50px;
  width: 100%;

  i {
    margin-left: 15px !important;
  }

  input {
    margin: 10px 15px !important;
    border: 1px solid var(--bu-gray-400) !important;
    border-radius: 15px !important;
    background-color: transparent !important;
  }
`;

export const tripActions = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #cbcbcb;
  font-size: 13px;
  margin-top: 10px;
  letter-spacing: 0;
  line-height: 15px;
`;

export const tripActionsFailureIcon = css`
  width: 19px;
  height: 17px;
  margin-top: -3px;
`;
export const tripActionsSuccessIcon = css`
  width: 17px;
  height: 17px;
  margin-top: -3px;
`;

export const centerAlign = css`
  display: flex;
  justify-content: space-between;
  width: 75px;
  color: #777777;
  cursor: pointer;
`;

export const divider = css`
  border: 1px solid #dddddd;
  height: 16px;
`;

export const popUpWrapper = css`
  display: flex;
  flex-flow: row wrap;
`;

export const header = css`
  padding: 10px 20px;
  flex: 1 100%;
  border-bottom: 1px solid #cccc;
  color: #8c8b8b;
`;

export const aside1 = css`
  flex: 0.5 0 0;
  border-right: 1px solid #dddddd;
`;

export const aside2 = css`
  flex: 4 0 0;
  padding: 10px;
  margin-left: -19px;
`;

export const asideContainer = css`
  display: flex;
  margin-bottom: 10px;
`;

export const popupContainer = css`
  padding: 0 !important;
`;

export const tripActionsSuccessIconsPopUp = css`
  margin: 50% 0px;
  background: #fff;
  width: 18px;
  height: 18px;
`;
export const tripActionsFailureIconsPopUp = css`
  margin: 50% 0px;
  background: #fff;
  width: 21px;
  height: 18px;
  margin-left: -2px;
`;

export const rolSection = css`
  color: #cbcbcb;
  font-size: 12px;
`;

export const marginLeft10 = css`
  margin-left: 10px;
`;

export const marginLeft5 = css`
  margin-left: 5px;
`;

export const marginLeft20 = css`
  margin-left: 20px;
`;

export const riskInfo = css`
  margin-top: 10px;
  color: #cbcbcb;
`;

export const displayName = css`
  display: flex;
  margin-top: 10px;
  color: #cbcbcb;
  font-size: 13px;
`;

export const valueColor = css`
  color: #5a5a5a;
  margin-left: 5px;
`;

export const flex = css`
  display: flex;
`;
