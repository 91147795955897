import { DEFAULT_OPPORTUNITIES_ORDER_BY } from '../WidgetCreate/WidgetCreateOptions/constants';

import { AnalysisType } from 'components/dashboard/Metrics/constants';
import { VisualizationType } from 'components/dashboard/Metrics/enums';
import { BIWidget } from 'components/dashboard/Metrics/metrics.types';

export const BASE_PREVIEW_WIDGET: BIWidget = {
  analysis_type: AnalysisType.LIVE,
  chart_type: VisualizationType.Column,
  compute_user_hierarchy_response: true,
  group_by: [],
  name: '',
  metric_list: [],
  order_by: [],
  order_by_column: DEFAULT_OPPORTUNITIES_ORDER_BY,
  point_in_time: '',
  template_filters: [],
  widget_filters: [],
};
