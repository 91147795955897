import React from 'react';

import BuSelect from 'components/UI/BuSelect';
import {
  BOOLEAN_OPERATION_DROPDOWN_OPTIONS,
  BOOLEAN_VALUE_DROPDOWN_OPTIONS,
  NULL_OPERATOR_VALUES,
} from 'components/dashboard/Metrics/Create/Conditions/Condition/constants';
import {
  FixedWidthContainer,
  FlexGrowContainer,
} from 'components/dashboard/Metrics/Create/Conditions/Condition/styles';
import { FlexRow } from 'components/dashboard/Metrics/metrics.styles';
import { BIMetricsFilter } from 'components/dashboard/Metrics/metrics.types';

type Props = {
  disabled: boolean;
  localFilter: Partial<BIMetricsFilter>;
  setLocalFilter: React.Dispatch<
    React.SetStateAction<Partial<BIMetricsFilter>>
  >;
  onOperatorSelect: (operator: string) => void;
};

export const BoolTypeCondition: React.FC<Props> = ({
  disabled,
  localFilter,
  setLocalFilter,
  onOperatorSelect,
}) => {
  const handleBoolValueChange = (value: string): void => {
    setLocalFilter((prev) => ({
      ...prev,
      value: value !== 'false',
    }));
  };

  return (
    <FlexRow cssProps={{ gap: '12px' }}>
      <FixedWidthContainer width={100}>
        <BuSelect
          fullWidth
          isLargePlaceholder
          secondary
          defaults={[localFilter.operator ? localFilter.operator : '']}
          disabled={disabled}
          options={BOOLEAN_OPERATION_DROPDOWN_OPTIONS}
          placeholder=""
          testingLabel="operator"
          onChange={(values: string[]) => {
            onOperatorSelect(values[0]);
          }}
        />
      </FixedWidthContainer>

      {!NULL_OPERATOR_VALUES.includes(localFilter.operator ?? '') && (
        <FlexGrowContainer data-testing="operator-value">
          <BuSelect
            fullWidth
            isLargePlaceholder
            secondary
            defaults={[
              localFilter.value === false
                ? (BOOLEAN_VALUE_DROPDOWN_OPTIONS.find(
                    (boolean) => boolean.value === 'false'
                  )?.value as string)
                : localFilter.value
                ? (BOOLEAN_VALUE_DROPDOWN_OPTIONS.find(
                    (boolean) => boolean.value === 'true'
                  )?.value as string)
                : '',
            ]}
            disabled={disabled}
            options={BOOLEAN_VALUE_DROPDOWN_OPTIONS}
            placeholder=""
            testingLabel="boolean-values"
            onChange={(values: string[]) => {
              handleBoolValueChange(values[0]);
            }}
          />
        </FlexGrowContainer>
      )}
    </FlexRow>
  );
};
