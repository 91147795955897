import iconSearch from '../../../assets/images/icons/search.svg';
import * as s from './styles';
import { IProps } from './types';
import { isNull } from 'lodash';
import React, { useRef, useEffect, useState } from 'react';

const MiniSearchInput = ({
  inputSearchValue,
  placeholder = 'Search for deals',
  onEnterPressSearch,
  resetInputSearch,
  onChangeInputSearchValue,
}: IProps) => {
  const inputEl = useRef(null);
  const [inputEvent, setInputEvent] = useState();

  useEffect(() => {
    if (!isNull(inputEl)) {
      //@ts-ignore
      inputEl.current.focus();
    }
  }, []);

  const resetInputSearchHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (resetInputSearch) {
      resetInputSearch(e);
    }
  };

  const onPressHandler = (e: React.FormEvent<HTMLInputElement>) => {
    if (onChangeInputSearchValue) {
      onChangeInputSearchValue(e);
    }
  };

  const onClickHandler = (e: React.MouseEvent<HTMLInputElement>) => {
    // @ts-ignore
    setInputEvent(e);
  };

  return (
    <form className={s.container}>
      <img className={s.icon_search} src={iconSearch} alt="icon search" />
      <input
        id="search_input"
        value={inputSearchValue}
        onClick={(e) => onClickHandler(e)}
        onChange={(e) => onPressHandler(e)}
        onKeyDown={onEnterPressSearch}
        placeholder={placeholder}
        className={s.input_search}
        type="text"
        ref={inputEl}
        data-testing="txt_field"
      />
      {inputSearchValue !== '' && (
        <button
          id="reset_search_button"
          type="button"
          onClick={(e) => resetInputSearchHandler(e)}
          className={s.reset_btn}
        />
      )}
    </form>
  );
};

export default MiniSearchInput;
