import { css } from 'emotion';

import { fontCaption } from 'assets/css/common';

export const optionsContainer = css`
  display: flex;

  .option-group {
    height: 28px;
    border-radius: 4px;
    border: 1px solid var(--bu-gray-400);
  }
`;

export const itemContainerWhiteBg = css`
  .option-group {
    background: var(--bu-white);
  }
`;

export const itemContainer = css`
  ${fontCaption}
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--bu-control-height-regular);
  text-align: center;
  letter-spacing: 0px;
  color: var(--bu-gray-900);
  margin-right: 5px;
  cursor: pointer;

  &.option-group:last-child {
    margin-right: 0;
  }

  &.not-icon-button {
    min-width: 80px;
    & > span {
      padding: 0 16px;
    }
  }

  & > span {
    width: 100%;
    height: 100%;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .option-group-item {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.disabled {
    background-color: var(--bu-gray-200);
    cursor: default;
    pointer-events: none;
    span {
      opacity: 0.4;
    }
  }
  &:hover {
    background-color: var(--bu-gray-300);
  }
`;

export const selectedOption = css`
  color: var(--bu-white) !important;
  background-color: var(--bu-gray-900) !important;
  border: none !important;
  cursor: default;
`;
