import * as R from 'ramda';
import { toast, ToastOptions } from 'react-toastify';
import {
  ForkEffect,
  call,
  takeEvery,
  put,
  all,
  select,
} from 'redux-saga/effects';
import { Action } from 'typescript-fsa';

import { actions } from 'actions';
import * as t from 'actions/actionTypes';
import { forecastSubmissionsSettingsAvailableSuccessAction } from 'actions/forecastActions';
import { ForecastSubmission } from 'components/settings/ForecastSettings/ForecastSubmissionTypes/types';
import * as selectors from 'selectors';
import { fetchApiWithoutCb } from 'utils/network';

const toastOptions: ToastOptions = { position: 'bottom-left' };

const SETTINGS_SHOULD_UPDATE_ROLL_UPS_FILTERS = [
  'forecast_roll_ups_closing_in_filters_config',
];

function* initFiltersGenerator() {
  // @ts-ignore
  const user = yield select(selectors.getUser);
  yield put(actions.ui.filters.init({ role: user.role, email: user.email }));
}

export type CustomViewRes = Filters.CustomView & {
  id: string;
  isPinned: boolean;
  showToast?: boolean;
  isPinningAction?: boolean;
  fields: Record<string, Filters.CustomViewProps>;
};

function* onAddCustomViewGenerator(action: Action<CustomViewRes>) {
  const {
    id,
    deleteable,
    view_type,
    view_section,
    fields,
    pinned,
    properties,
    user,
    is_shared,
  } = action.payload;

  toast.success(
    properties?.display_name
      ? `${properties?.display_name} added successfully!`
      : 'View added successfully',
    toastOptions
  );

  yield put(
    actions.ui.filters.onAddCustomView({
      id,
      pinned,
      deleteable,
      user,
      tab: view_section,
      name: fields[view_section].display_name,
      viewType: view_type,
      columnToggleState: fields[view_section].columnToggleState,
      tablePersistName: fields[view_section].tablePersistName,
      selectedBusinessType: fields[view_section].selectedBusinessType,
      is_shared,
    } as Filters.AddCustomViewMeta)
  );
}

function* onUpdateCustomViewGenerator() {
  toast.success('View updated successfully');
}

function* showRenameSuccessToast(action: Action<Filters.RenameCustomView>) {
  const { newName } = action.payload;

  toast.success(`${newName} renamed successfully!`, toastOptions);
}

function* showPinSuccessToast() {
  toast.success('Updated Pinned View', toastOptions);
}

function* onRemoveCustomViewGenerator(
  action: Action<Filters.RemoveCustomViewMeta>
) {
  toast.success(
    action.payload.name
      ? `${action.payload.name} removed successfully!`
      : 'View removed successfully',
    toastOptions
  );

  yield put(
    actions.ui.filters.onRemoveCustomView({
      id: action.payload.id,
      tab: action.payload.tab,
      hasPinned: action.payload.hasPinned,
    } as Filters.RemoveCustomViewMeta)
  );
}

function* addForecastDealsCustomFiltersTabs() {
  const submissionsAvailable: ForecastSubmission[] = yield select(
    selectors.getForecastSubmissionSettings
  );

  // @ts-ignore
  const globalDefaults = yield select((state) =>
    selectors.getFiltersState(state, '', 'globalDefaults')
  );

  if (!R.isEmpty(submissionsAvailable) && !R.isNil(globalDefaults)) {
    yield all(
      submissionsAvailable.map((filter) =>
        put(
          actions.ui.filters.addForecastDealsCustomFiltersTab({
            filter,
            filters: globalDefaults,
          })
        )
      )
    );
  }
}

function* reloadRollUpsFilters(action: Action<{ settings: any }>) {
  const shouldReloadRollUpsFilters =
    action.payload.settings &&
    Object.keys(action.payload.settings).some((settingsName) =>
      SETTINGS_SHOULD_UPDATE_ROLL_UPS_FILTERS.includes(settingsName)
    );
  if (!shouldReloadRollUpsFilters) return;

  const { result } = yield call(fetchApiWithoutCb, {
    queryMethod: 'get',
    url: `${process.env.REACT_APP_BACKEND_URL}/api/data/dynamic_tables/filters/forecast_roll_ups`,
  });

  // @ts-ignore
  const rollUpsFilters = yield select((state) =>
    selectors.getFiltersState(state, 'forecast_roll_ups')
  );

  yield put(
    actions.ui.filters.reloadRollUpsFilters({
      rollUps: result.data,
      state: rollUpsFilters,
    })
  );
}

function* filtersGenerator(action: Action<Filters.PersistUserParams>) {
  const { state, filters, rollUps, customViews } = yield all({
    state: call(fetchApiWithoutCb, {
      queryMethod: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/filters/enabled_filters`,
    }),
    filters: call(fetchApiWithoutCb, {
      queryMethod: 'post',
      queryParams: { email: action.payload.email },
      url: `${process.env.REACT_APP_BACKEND_URL}/api/data/filters/v2/deals`,
    }),
    rollUps: call(fetchApiWithoutCb, {
      queryMethod: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/api/data/dynamic_tables/filters/forecast_roll_ups`,
    }),
    customViews: call(fetchApiWithoutCb, {
      queryMethod: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/api/custom_views`,
    }),
  });

  if (state.error) {
    console.error('get filters state', state.error);
  }

  if (filters.error) {
    console.error('get filters list', filters.error);
  }

  if (rollUps.error) {
    console.error('get rollUps list', rollUps.error);
  }

  if (customViews.error) {
    console.error('get customViews list', customViews.error);
  }

  if (state.result && filters.result && rollUps.result && customViews.result) {
    const pages = Object.keys(state.result);

    // @ts-ignore
    const available = yield call(fetchApiWithoutCb, {
      queryMethod: 'post',
      queryParams: { pages },
      url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/filter_page`,
    });

    if (available.error) {
      console.error('get available  filters list', available.error);
    }

    yield put(
      actions.ui.filters.loaded({
        available: available.result,
        filters: filters.result.data,
        customViewsForUser: [
          {
            view_type: 'DEFAULT',
            fields: state.result,
            deleteable: false,
            is_shared: false,
          },
          ...customViews.result.data,
        ],
        user: action.payload,
        rollUps: rollUps.result.data,
      })
    );
  }
}

function* injectFiltersGenerator(
  { payload }: Action<Filters.InjectFiltersPayload>,
  filterName: string
) {
  const { result } = yield call(fetchApiWithoutCb, {
    queryMethod: 'get',
    url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/filters/enabled_filters`,
  });

  const pages = Object.keys(result).filter((page) =>
    result[page].enabled_filters.includes(filterName)
  );

  const visibleFilters = payload.data
    .filter((f) => !f.hidden)
    .map(({ display_name, value }) => ({ display_name, value }));

  yield put(
    actions.ui.filters.updateProfileFilters({
      filters: visibleFilters,
      pages,
      filterName,
    })
  );
}

function* clearCacheSettingsFilters(action: Action<{ toast: boolean }>) {
  const { result } = yield call(fetchApiWithoutCb, {
    queryParams: {},
    url: `${process.env.REACT_APP_BACKEND_URL}/api/data/filters/v2/deals?cache_refresh=1`,
  });

  if (result.error) {
    if (action.payload.toast) {
      toast.error(`Clear cache ${result.error}`, toastOptions);
    }
    console.error('get available  filters list', result.error);
  }

  if (action.payload.toast) {
    toast.success(`Clear cache success`, toastOptions);
  }
  yield call(initFiltersGenerator);
}

// eslint-disable-next-line import/prefer-default-export
export function* filtersSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(actions.ui.filters.init.type, filtersGenerator);

  yield takeEvery(
    t.COMPANY_SETTINGS + t.UPDATE + t.SUCCESS,
    reloadRollUpsFilters
  );

  yield takeEvery(
    t.FIELD_CONFIGURATION + t.DETAIL + t.UPDATE + t.SUCCESS,
    initFiltersGenerator
  );

  /* to keep the filters up to date after updating the map forecast categories on the Onboarding settings */
  yield takeEvery(
    t.COMPANY_SETTINGS + t.CRM_FORECAST_CATEGORY + t.SET + t.SUCCESS,
    (action: Action<Filters.InjectFiltersPayload>) =>
      injectFiltersGenerator(action, 'forecast_category_names')
  );

  yield takeEvery(
    t.CUSTOM_VIEWS + t.CREATE + t.SUCCESS,
    onAddCustomViewGenerator
  );

  yield takeEvery(
    t.FILTER + t.CUSTOM_VIEWS + t.UPDATE + t.SUCCESS,
    onUpdateCustomViewGenerator
  );

  yield takeEvery(
    t.FILTER + t.CUSTOM_VIEWS + t.RENAME + t.SUCCESS,
    showRenameSuccessToast
  );

  yield takeEvery(
    t.FILTER + t.CUSTOM_VIEWS + t.PIN + t.SUCCESS,
    showPinSuccessToast
  );

  yield takeEvery(
    t.CUSTOM_VIEWS + t.REMOVE + t.SUCCESS,
    onRemoveCustomViewGenerator
  );

  yield takeEvery(
    [
      actions.ui.filters.loaded,
      forecastSubmissionsSettingsAvailableSuccessAction.type,
    ],
    addForecastDealsCustomFiltersTabs
  );

  yield takeEvery(t.CACHE + t.CLEAR_FILTERS, clearCacheSettingsFilters);
}
