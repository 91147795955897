import clx from 'classnames';
import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { actions } from 'actions';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import BuPopup from 'components/UI/BuPopup';
import DefaultViewPinIcon from 'components/UI/OpenFiltersPanel/CustomViewsDropdown/CustomViewItem/DefaultViewPinIcon';
import RenameViewInput from 'components/UI/OpenFiltersPanel/CustomViewsDropdown/CustomViewItem/RenameViewInput';
import ViewActionButtons from 'components/UI/OpenFiltersPanel/CustomViewsDropdown/CustomViewItem/ViewActionButtons';
import * as s from 'components/UI/OpenFiltersPanel/CustomViewsDropdown/CustomViewItem/styles';
import { popup } from 'components/UI/OpenFiltersPanel/styles';
import { AnalyticsTracker } from 'components/common/analyticsUtils';

type Props = {
  tab: string;
  canBeModified: boolean;
  id: string | undefined;
  name: string;
  isPinned: boolean;
  isShared?: boolean;
  isInEditMode: boolean;
  isDefaultView: boolean;
  selectedProfileName?: string;
  filterProfilesNames: string[];
  setProfileInEdit: Dispatch<SetStateAction<string>>;
  setIsDropdownOpen: Dispatch<SetStateAction<boolean>>;
  onSelectView: (name: string) => void;
  onRenameView: (name: string, id: string) => void;
  onRemoveView: (id: string, name: string) => void;
  onPinnedChange: (id: string) => void;
};

type RenamedView = {
  initialName: string;
  newName: string;
  isValid: boolean;
  isChanged: boolean;
};

const CustomViewItem: React.FC<Props> = ({
  tab,
  canBeModified,
  id,
  name,
  isPinned,
  isShared = false,
  isInEditMode,
  isDefaultView,
  selectedProfileName,
  filterProfilesNames,
  setProfileInEdit,
  setIsDropdownOpen,
  onSelectView,
  onRenameView,
  onRemoveView,
  onPinnedChange,
}) => {
  const dispatch = useDispatch();

  const RENAMED_VIEW_INITIAL_STATE: RenamedView = {
    initialName: name,
    newName: name,
    isValid: true,
    isChanged: false,
  };
  const [renamedView, setRenamedView] = useState<RenamedView>(
    RENAMED_VIEW_INITIAL_STATE
  );
  const [inputWidth, setInputWidth] = useState<number>(0);
  const [internalIsPinned, setInternalIsPinned] = useState<boolean>(isPinned);

  useEffect(() => {
    if (!isInEditMode) {
      setRenamedView((prev) => ({
        ...prev,
        newName: prev.initialName,
      }));
    }
  }, [isInEditMode]);

  useEffect(() => {
    if (isPinned !== internalIsPinned) {
      setInternalIsPinned(isPinned);
    }
  }, [isPinned]);

  useEffect(() => {
    setRenamedView((prev) => ({
      ...prev,
      initialName: name,
      newName: name,
    }));
  }, [name]);

  const handleSetActiveProfile = (name: string): void => {
    if (!isInEditMode && name !== selectedProfileName) {
      dispatch(
        actions.ui.filters.setActiveProfile({
          tab,
          profile: name,
        })
      );

      AnalyticsTracker.event(
        { tab },
        {
          category: 'Filters Panel',
          action: 'click on view filters',
          value: name,
        }
      );

      setIsDropdownOpen(false);
      onSelectView(name);
    }
  };

  const handleClickDefaultPin = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation();
    if (isDefaultView && isPinned) {
      return;
    }
    const newPinnedStatus = !isPinned;
    setInternalIsPinned(newPinnedStatus);
    onPinnedChange(id as string);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const trimmedName = e.target.value?.trimStart();
    const isNameChanged = trimmedName !== name;
    const isNameValid = !!trimmedName;

    setRenamedView((prev) => ({
      ...prev,
      newName: trimmedName,
      isValid: isNameValid,
      isChanged: isNameChanged,
    }));
  };

  const handleSaveNewName = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();

    const trimmedName = renamedView.newName.trim();
    if (filterProfilesNames.includes(trimmedName)) {
      setRenamedView((prev) => ({
        ...prev,
        newName: trimmedName,
        isValid: false,
      }));
      toast.error(
        'View name cannot be updated as this name is taken. Try again with different name.',
        {
          position: 'bottom-left',
        }
      );

      return;
    }

    onRenameView(trimmedName, id as string);
    setProfileInEdit('');
  };

  const handleCloseNameEdit = (
    e: React.MouseEvent<HTMLButtonElement>
  ): void => {
    e.stopPropagation();
    setProfileInEdit('');
    setRenamedView(RENAMED_VIEW_INITIAL_STATE);
  };

  const handleEditName = (): void => {
    setProfileInEdit(id as string);
  };

  return (
    <>
      <div
        className={clx(s.options_box_element, {
          active: name === selectedProfileName,
        })}
        onClick={() => {
          handleSetActiveProfile(name);
        }}
      >
        <div className={s.element_container}>
          <DefaultViewPinIcon
            isPinned={internalIsPinned}
            isDefaultView={isDefaultView}
            onPinClick={handleClickDefaultPin}
          />

          {isInEditMode ? (
            <RenameViewInput
              inputWidth={inputWidth}
              isNameValid={renamedView.isValid}
              isNameChanged={renamedView.isChanged}
              name={renamedView.newName}
              onNameChange={handleNameChange}
              onSaveNewName={handleSaveNewName}
              onCloseNameEdit={handleCloseNameEdit}
            />
          ) : (
            <>
              <span
                className={clx(s.view_name, {
                  active: name === selectedProfileName,
                })}
                ref={(ref) => {
                  if (ref) {
                    const elementWidth = Number(ref.clientWidth) - 23;
                    setInputWidth((prev) =>
                      prev < elementWidth ? elementWidth : prev
                    );
                  }
                }}
              >
                {name}
              </span>
              {canBeModified && (
                <ViewActionButtons
                  id={id}
                  name={name}
                  onEditName={handleEditName}
                  onRemoveView={onRemoveView}
                />
              )}
            </>
          )}

          {isShared ? (
            <BuPopup
              trigger={(ref) => (
                <span ref={ref} className={s.sharedIcon}>
                  <BuIcon
                    name={BoostUpIcons.UserGroup}
                    color="var(--bu-gray-600)"
                  />
                </span>
              )}
            >
              <div className={popup}>Shared View</div>
            </BuPopup>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default CustomViewItem;
