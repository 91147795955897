import { put, all } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';

import * as t from 'actions/actionTypes';
import {
  CompanyUserAction,
  SalesforceId,
  UserResponse,
} from 'reducers/companyUsersReducer';
import * as genericSagas from 'sagas/generic';

export type CompanyUsersQueryParams = {
  page_number?: number;
  page_size?: number;
  sort?: string;

  search?: string;

  manager?: string; // email
  status?: 'active' | 'disabled' | 'unmapped';
  crm_active?: boolean;
  user?: string; // email
  role?: string;
  profile_names?: string;
  only_managers?: boolean;
  email_sync_failed?: boolean;
  calendar_sync_failed?: boolean;
};

type CompanyUsersResponse = {
  data: {
    users: UserResponse[];
    total: number;
    profile_names: string[];
    disable_fields: string[];
    email_domains: string[];
  };
  errors?: string;
};

type CompanyUsersErrorResponse = {};

export const getCompanyUsers = (data?: CompanyUsersQueryParams) => ({
  type: t.COMPANY_SETTINGS + t.USERS + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/company/users`,
  data,
  saga: genericSagas.get,
  success: (payload: CompanyUsersResponse): CompanyUserAction => ({
    type: t.COMPANY_SETTINGS + t.USERS + t.GET + t.SUCCESS,
    payload: {
      data: payload.data.users,
      total: payload.data.total,
      disable_fields: payload.data.disable_fields,
    },
  }),
  error: (e: CompanyUsersErrorResponse): CompanyUserAction => ({
    type: t.COMPANY_SETTINGS + t.USERS + t.GET + t.ERROR,
    error: 'error',
  }),
  loading: (): CompanyUserAction => ({
    type: t.COMPANY_SETTINGS + t.USERS + t.GET + t.LOADING,
  }),
});

export const getUnmappedUsers = (
  data?: Omit<CompanyUsersQueryParams, 'status'>,
  query?: string
) => ({
  type: t.COMPANY_SETTINGS + t.UNMAPPED_USERS + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/company/users?${query}`,
  data: {
    ...data,
    status: 'unmapped',
  },
  saga: genericSagas.get,
  success: (payload: CompanyUsersResponse): CompanyUserAction => ({
    type: t.COMPANY_SETTINGS + t.UNMAPPED_USERS + t.GET + t.SUCCESS,
    payload: {
      data: payload.data.users,
      profile_names: payload.data.profile_names,
      total: payload.data.total,
    },
  }),
  error: (e: CompanyUsersErrorResponse): CompanyUserAction => ({
    type: t.COMPANY_SETTINGS + t.UNMAPPED_USERS + t.GET + t.ERROR,
    error: 'error',
  }),
  loading: (): CompanyUserAction => ({
    type: t.COMPANY_SETTINGS + t.UNMAPPED_USERS + t.GET + t.LOADING,
  }),
});

export const getCompanyUser = (
  data: Required<Pick<CompanyUsersQueryParams, 'user'>>
) => ({
  type: t.COMPANY_SETTINGS + t.USER + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/company/users`,
  data,
  saga: genericSagas.get,
  success: (payload: CompanyUsersResponse): CompanyUserAction => ({
    type: t.COMPANY_SETTINGS + t.USER + t.GET + t.SUCCESS,
    payload: {
      data: Object.values(payload.data.users),
      total: payload.data.total,
      disable_fields: payload.data.disable_fields,
      email_domains: payload.data.email_domains,
    },
  }),
  error: (e: CompanyUsersErrorResponse): CompanyUserAction => ({
    type: t.COMPANY_SETTINGS + t.USER + t.GET + t.ERROR,
    error: 'error',
  }),
  loading: (): CompanyUserAction => ({
    type: t.COMPANY_SETTINGS + t.USER + t.GET + t.LOADING,
  }),
});

export const getCompanyManagers = () => ({
  type: t.COMPANY_SETTINGS + t.MANAGERS + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/company/users`,
  data: {
    sort: 'name',
    only_manager_roles: true,
    page_size: 1500,
  },
  saga: genericSagas.get,
  success: (payload: CompanyUsersResponse): CompanyUserAction => ({
    type: t.COMPANY_SETTINGS + t.MANAGERS + t.GET + t.SUCCESS,
    payload: {
      data: Object.values(payload.data.users),
      total: payload.data.total,
    },
  }),
  error: (e: CompanyUsersErrorResponse): CompanyUserAction => ({
    type: t.COMPANY_SETTINGS + t.MANAGERS + t.GET + t.ERROR,
    error: 'error',
  }),
  loading: (): CompanyUserAction => ({
    type: t.COMPANY_SETTINGS + t.MANAGERS + t.GET + t.LOADING,
  }),
});

export const getCompanyManagerTeams = () => ({
  type: t.COMPANY_SETTINGS + t.MANAGERS_TEAM + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/company/users`,
  data: {
    sort: 'name',
    only_managers: true,
    page_size: 500,
  },
  saga: genericSagas.get,
  success: (payload: CompanyUsersResponse): CompanyUserAction => ({
    type: t.COMPANY_SETTINGS + t.MANAGERS_TEAM + t.GET + t.SUCCESS,
    payload: {
      data: Object.values(payload.data.users),
      total: payload.data.total,
    },
  }),
  error: (e: CompanyUsersErrorResponse): CompanyUserAction => ({
    type: t.COMPANY_SETTINGS + t.MANAGERS_TEAM + t.GET + t.ERROR,
    error: 'error',
  }),
  loading: (): CompanyUserAction => ({
    type: t.COMPANY_SETTINGS + t.MANAGERS_TEAM + t.GET + t.LOADING,
  }),
});

interface UserUpdate
  extends Pick<
      UserResponse,
      'email' | 'user_settings' | 'allow_salesforce_update'
    >,
    Partial<
      Omit<
        UserResponse,
        'email' | 'user_settings' | 'manager' | 'profile' | 'sfdc_user_id'
      >
    > {
  manager?: string | null;
  profile?: string | null;
  new_email?: string | null;
  sfdc_user_id?: string | SalesforceId[];
}

export type CompanyUserUpdateFields = UserUpdate;

// Sadly the backend handle update and other endpoints differently
// for update it returns the user or a string with an error
type CompanyUserUpdateResponse = {
  data: {
    [key: string]: UserResponse | string;
  };
};
type CompanyUserCreateResponse = {
  data: {
    [key: string]: UserResponse;
  };
};

export const updateCompanyUser = (data: CompanyUserUpdateFields) => ({
  type: t.COMPANY_SETTINGS + t.USER + t.UPDATE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/company/users`,
  data: [data],
  *saga(action: Action<{}>) {
    yield genericSagas.update(action);
    yield all([put(getCompanyManagers()), put(getCompanyManagerTeams())]);
  },
  success: (payload: CompanyUserUpdateResponse): CompanyUserAction => {
    const updatedUsers = Object.values(payload.data);
    // Backend always returns 200, if something breaks its send as a message
    // inside the user that caused it as a string
    const userWithError = updatedUsers.find(
      (u) => typeof u == 'string'
    ) as string;
    if (userWithError) {
      return {
        type: t.COMPANY_SETTINGS + t.USER + t.UPDATE + t.ERROR,
        error: userWithError,
      };
    } else {
      return {
        type: t.COMPANY_SETTINGS + t.USER + t.UPDATE + t.SUCCESS,
        payload: {
          // As we arelady check if some of the user has a string
          // this 'if' branch assure us that all users are UserResponse type
          data: updatedUsers as UserResponse[],
        },
      };
    }
  },
  error: (e: any): CompanyUserAction => ({
    type: t.COMPANY_SETTINGS + t.USER + t.UPDATE + t.ERROR,
    payload: {
      data: [data as UserResponse],
    },
    error: e?.response?.data?.error?.message?.[0] || e,
  }),
  loading: (): CompanyUserAction => ({
    type: t.COMPANY_SETTINGS + t.USER + t.UPDATE + t.LOADING,
    payload: {
      data: [data as UserResponse],
    },
  }),
});

export const updateCompanyUsers = (data: CompanyUserUpdateFields[]) => ({
  type: t.COMPANY_SETTINGS + t.USER + t.UPDATE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/company/users`,
  data,
  *saga(action: Action<{}>) {
    yield genericSagas.update(action);
    yield all([put(getCompanyManagers()), put(getCompanyManagerTeams())]);
  },
  success: (payload: CompanyUserUpdateResponse): CompanyUserAction => {
    const updatedUsers = Object.values(payload.data);
    // Backend always returns 200, if something breaks its send as a message
    // inside the user that caused it as a string
    const userWithError = updatedUsers.find(
      (u) => typeof u == 'string'
    ) as string;
    if (userWithError) {
      return {
        type: t.COMPANY_SETTINGS + t.USER + t.UPDATE + t.ERROR,
        error: userWithError,
      };
    } else {
      return {
        type: t.COMPANY_SETTINGS + t.USER + t.UPDATE + t.SUCCESS,
        payload: {
          // As we arelady check if some of the user has a string
          // this 'if' branch assure us that all users are UserResponse type
          data: updatedUsers as UserResponse[],
        },
      };
    }
  },
  error: (e: CompanyUsersErrorResponse): CompanyUserAction => ({
    type: t.COMPANY_SETTINGS + t.USER + t.UPDATE + t.ERROR,
    payload: {
      data: data as UserResponse[],
    },
    error: 'error',
  }),
  loading: (): CompanyUserAction => ({
    type: t.COMPANY_SETTINGS + t.USER + t.UPDATE + t.LOADING,
    payload: {
      data: data as UserResponse[],
    },
  }),
});

export const createCompanyUsers = (data: CompanyUserUpdateFields[]) => ({
  type: t.COMPANY_SETTINGS + t.UNMAPPED_USERS + t.CREATE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/company/users`,
  data,
  *saga(action: Action<{}>) {
    yield genericSagas.create(action);
    yield all([put(getCompanyManagers()), put(getCompanyManagerTeams())]);
  },
  success: (payload: CompanyUserCreateResponse): CompanyUserAction => ({
    type: t.COMPANY_SETTINGS + t.UNMAPPED_USERS + t.CREATE + t.SUCCESS,
    payload: {
      data: Object.values(payload.data),
    },
  }),
  error: (
    e: CompanyUsersErrorResponse
  ): CompanyUserAction<CompanyUsersErrorResponse> => ({
    type: t.COMPANY_SETTINGS + t.UNMAPPED_USERS + t.CREATE + t.ERROR,
    payload: {
      data: data as UserResponse[],
    },
    error: e,
  }),
  loading: (): CompanyUserAction => ({
    type: t.COMPANY_SETTINGS + t.UNMAPPED_USERS + t.CREATE + t.LOADING,
    payload: {
      data: data as UserResponse[],
    },
  }),
});

export const syncCompanyUser = (id: string, source: 'email' | 'calendar') => ({
  type: t.COMPANY_SETTINGS + t.USER + t.SYNC,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/user/${id}/sync`,
  data: {
    source,
  },
  saga: genericSagas.create,
  success: (payload: CompanyUserCreateResponse): CompanyUserAction => ({
    type: t.COMPANY_SETTINGS + t.USER + t.SYNC + t.SUCCESS,
    payload: {
      data: Object.values(payload.data),
    },
  }),
  error: (e: CompanyUsersErrorResponse) => ({
    type: t.COMPANY_SETTINGS + t.USER + t.SYNC + t.ERROR,
    payload: {
      data: {
        id,
        source,
      },
    },
    error: 'error',
  }),
  loading: () => ({
    type: t.COMPANY_SETTINGS + t.USER + t.SYNC + t.LOADING,
    payload: {
      id,
      source,
    },
  }),
});

export const showEmailCalendarSyncPopup = (
  isCalendarSync: boolean,
  isEmailSync: boolean
) => ({
  type: t.MODAL + t.USER + t.EMAIL + t.SYNC + t.OPEN,
  payload: {
    isCalendarSync,
    isEmailSync,
  },
});

export const hideEmailCalendarSyncPopup = () => ({
  type: t.MODAL + t.USER + t.EMAIL + t.SYNC + t.CLOSE,
});
