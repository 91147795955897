import { userActionType } from '../actions/userActions';

import * as t from 'actions/actionTypes';

// userReducer gets state.user as the input state
// Each action makes an appropriate API call
const initialState = {
  name: '',
  email: '',
  password: '',
  rememberMe: false,
  confirmed_password: '',
  role: '',
  registrationToken: '',
  apiSuccess: null,
  apiError: null,
  emailVerificationDone: null,
  googleOAuthDone: null,
  reset_password_token: null,
  user_id: null,
  read_only: false,
  originalUser: null,
};
export const userReducer = function (state = initialState, action) {
  switch (action.type) {
    case userActionType.UPDATE_STATE:
      if (typeof action.key === 'string') {
        return {
          ...state,
          [action.key]: action.value,
        };
      } else {
        return {
          ...state,
          ...action.key,
        };
      }
    case t.LOGIN + t.OAUTH + t.SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        originalUser: action.payload.data,
      };
    case t.SIGN_UP + t.OAUTH + t.SUCCESS:
      return {
        ...state,
        ...action.payload.data,
      };
    case userActionType.RESET_STATE:
      return initialState;
    case userActionType.SET_ORIGINAL_USER: {
      return {
        ...state,
        originalUser: state.originalUser?.email
          ? state.originalUser
          : action.originalUser,
      };
    }
    default:
      return state;
  }
};
