import * as s from './styles';
import React from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import { BIAdditionalMetric } from 'components/dashboard/Metrics/metrics.types';

interface Props {
  additionalMetrics: BIAdditionalMetric[];
}

const formatValue = (value: string | number, type: string) => {
  if (value === undefined) {
    return ' - ';
  }
  if (type === 'percent') {
    return `${value}%`;
  }
  return value;
};

export const AdditionalMetrics: React.FC<Props> = ({ additionalMetrics }) => {
  if (additionalMetrics.length === 0) {
    return null;
  }

  return (
    <div className={s.additionalMetricsBlock}>
      <BuIcon name={BoostUpIcons.ColumnGraphBox} color="var(--bu-gray-600)" />
      <div className={s.metricsList} data-testing="totals-block-section">
        {additionalMetrics.map((el, index) => (
          <div
            className={s.metric}
            key={`${el.display_name}_${el.type}_${el.value}`}
            data-testing={`total-${index}`}
          >
            <span className="metric-name" data-testing="total-title">
              {el.display_name}
            </span>
            <span className="metric-value" data-testing="total-value">
              {formatValue(el.value, el.type)}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
