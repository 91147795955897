import axios from 'axios';
import classNames from 'classnames';
import hash from 'object-hash';
import React, { Fragment, useEffect, useState } from 'react';
import { Loader } from 'semantic-ui-react';

import { shouldUpdateCache } from 'common/utils';
import AttachmentsTable from 'components/UI/AttachmentsTable';
import BuGroupButton from 'components/UI/BuGroupButton';
import MomentsTable from 'components/UI/MomentsTable';
import * as styles from 'components/UI/MomentsTabs/styles';
import {
  ApiResponse,
  IDataState,
  MomentsTabsProps,
} from 'components/UI/MomentsTabs/types';
import Table from 'components/UI/TopicsDiscussedTabs/Table';
import OptionsSelector from 'components/UI/TopicsDiscussedTabs/OptionsSelector';
import { useBoundary } from 'components/hooks/useBoundary';

const timeInterval = [
  { text: 'Last 7 days', value: 'L7D' },
  { text: 'Last 14 days', value: 'L14D' },
  { text: 'Last 30 days', value: 'L30D' },
  { text: 'Last 3 months', value: 'L3M' },
  { text: 'Last 6 months', value: 'L6M' },
  { text: 'All Time', value: 'ALT' },
];

const MomentsTabs = (props: MomentsTabsProps) => {
  const isFirstLoad = useBoundary(props.position);

  const [active, setActive] = useState('competitors');
  const [attachmentCount, setAttachmentCount] = useState<number>(0);
  const [timespanState, setTimespanState] = useState<string>(props.timespan);
  const [dataState, setDataState] = useState<IDataState>({
    objections: [],
    positiveTopics: [],
    isLoading: false,
    apiError: undefined,
  });

  const fetch = async () => {
    try {
      const path = `/api/data/deals/${props.id}/objections/${props.type}?time_period=${timespanState}`;
      const hashKey = hash(path);

      // @ts-ignore
      if (hashKey in props.cache && !shouldUpdateCache(props.cacheTimestamp!)) {
        setDataState({
          ...dataState,
          // @ts-ignore
          objections: props.cache[hashKey].data.objections,
          // @ts-ignore
          positiveTopics: props.cache[hashKey].data.positive_topics,
          isLoading: false,
          apiError: undefined,
        });
      } else {
        const response: ApiResponse = await axios.get(path);

        setDataState({
          ...dataState,
          isLoading: true,
          objections: [],
          positiveTopics: [],
        });

        if (props.createCache) {
          props.createCache(hashKey, response.data, 'objectionMoment');
        }

        setDataState({
          ...dataState,
          objections: response.data.data.objections,
          positiveTopics: response.data.data.positive_topics,
          isLoading: false,
          apiError: undefined,
        });
      }
    } catch (e) {
      setDataState({ ...dataState, apiError: e as string });
    }
  };

  const { id, fetchDealTopics } = props;

  useEffect(() => {
    if (!isFirstLoad && fetchDealTopics) {
      fetch();
      fetchDealTopics(id, undefined, timespanState);
    }
  }, [id, isFirstLoad, fetchDealTopics, timespanState]);

  // Active button css classes
  const buttonClasses = (name: string) =>
    classNames({
      [styles.btn_tab]: true,
      active: active === name,
    });

  const buildOptionGroupData = () => {
    return [
      {
        id: 'competitors',
        text: `Competitors Discussed (${
          props.competitors && props.competitors.length
        })`,
      },
      {
        id: 'objections',
        text: `Objection Moments (${dataState.objections.length})`,
      },
      {
        id: 'positive',
        text: `Positive Moments (${dataState.positiveTopics.length})`,
      },
      {
        id: 'attachments',
        text: `Attachments (${attachmentCount})`,
      },
    ];
  };

  const showPreloader =
    active === 'competitors' ? !props.competitors : dataState.isLoading;

  return (
    <div className={styles.wrapper}>
      <div className={styles.buttons_box}>
        <div className={classNames(styles.section_header, 'bu-font-heading')}>
          Moments
        </div>
        <div className={styles.buttons_box_content}>
          <BuGroupButton
            options={buildOptionGroupData()}
            selectedOption={active}
            onSelect={(value: string) => {
              setActive(value);
            }}
            useRevampStyle
          />
        </div>
        <div
          id="moments-btn-optionsSelector"
          className={styles.optionsSelector}
        >
          <OptionsSelector
            value={timespanState}
            onChange={(value) => setTimespanState(value)}
            options={timeInterval}
          />
        </div>
      </div>
      <Fragment>
        {showPreloader ? (
          <div className={styles.whiteWrapper}>
            <Loader inline="centered" active size="small" />
          </div>
        ) : dataState.apiError ? (
          <div className={styles.apiError}>Error loading from API</div>
        ) : (
          [
            active === 'competitors' && (
              <Fragment key={active}>
                <div id="moments-table-competitors">
                  <Table
                    loading={false}
                    data={props.competitors || []}
                    timeSpan={timespanState}
                    entityId={props.id}
                  />
                </div>
              </Fragment>
            ),
            active === 'objections' && (
              <div id="moments-table-objections">
                <MomentsTable
                  key={active}
                  id={props.id}
                  dataType="objections"
                  data={dataState.objections}
                />
              </div>
            ),
            active === 'positive' && (
              <div id="moments-table-positive">
                <MomentsTable
                  key={active}
                  id={props.id}
                  dataType="positive_topics"
                  data={dataState.positiveTopics}
                />
              </div>
            ),
            <div
              id="moments-table-attachments"
              key="hidden"
              style={{ display: active === 'attachments' ? '' : 'none' }}
            >
              {/* Hidden but on the dom so it makes the first request on load */}
              <AttachmentsTable
                key={active}
                id={props.id}
                type={props.type}
                timeSpan={timespanState}
                setCount={setAttachmentCount}
              />
            </div>,
          ]
        )}
      </Fragment>
    </div>
  );
};

export default MomentsTabs;
