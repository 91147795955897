import BusinessTypeDetails from './BusinessTypeDetails';
import React from 'react';

import { ModalRouteProps } from 'navigation/types';

const AddBusinessTypeModal: React.FC<ModalRouteProps<{}>> = ({ onClose }) => {
  return <BusinessTypeDetails onClose={onClose} />;
};

export default AddBusinessTypeModal;
