import { IRowProps, IRowPreview } from './types';
import classNames from 'classnames';
import { css } from 'emotion';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { formatMoney } from 'common/numbers';
import * as selectors from 'selectors';

const rowWrapper = css`
  &:not(:last-child) {
    border-bottom: 1px solid #dddddd;
  }
`;

const expandedRow = css`
  background-color: #f4f7f8;
  margin: 0 10px 10px;
  padding: 10px;
  border-radius: 3px;
  word-break: break-word;
`;

const showMoreButton = css`
  color: var(--bu-primary-700);
  cursor: pointer;
`;

const columns_row = (numberColumns: number) => css`
  grid-template-columns: repeat(${numberColumns}, 208px);
`;

const Row = ({ row, columns, styles }: IRowProps) => {
  const companyCurrencyCode = useSelector(selectors.getUserLocalCurrency);

  const [fullRow, setFullRow] = useState<IRowPreview>({
    value: '',
    visible: false,
  });

  const onClickHandler = (e: React.ChangeEvent<HTMLDivElement>) => {
    const { value = '' } = e.target.dataset;
    setFullRow({ value, visible: !fullRow.visible });
  };

  return (
    <>
      <tr
        className={classNames(
          styles.full_rows,
          rowWrapper,
          columns_row(columns.length)
        )}
      >
        {columns.map((c, i) => {
          const { type } = columns[i];
          const value: any = row[columns[i].field] || '';

          switch (type) {
            case 'bool':
              return (
                // eslint-disable-next-line react/no-array-index-key
                <td className={styles.table_body_column} key={i}>
                  <div className={styles.row_text}>
                    {value ? 'True' : 'False'}
                  </div>
                </td>
              );
            case 'date':
              return (
                // eslint-disable-next-line react/no-array-index-key
                <td
                  className={classNames(
                    styles.table_body_column,
                    styles.table_body_rest_column
                  )}
                  key={i}
                >
                  <div className={styles.row_text}>
                    {moment(value).format("DD MMM 'YY")}
                  </div>
                </td>
              );
            case 'amount':
              return (
                // eslint-disable-next-line react/no-array-index-key
                <td className={styles.table_body_rest_column} key={i}>
                  <div className={styles.row_text}>
                    {formatMoney(companyCurrencyCode, Number(value), 0)}
                  </div>
                </td>
              );
            default:
              return (
                // eslint-disable-next-line react/no-array-index-key
                <td className={styles.table_body_column} key={i}>
                  <div
                    className={classNames(
                      'configurable-table',
                      styles.truncate_text
                    )}
                  >
                    {value}
                  </div>
                  {value.length > 150 && (
                    <div
                      data-value={value}
                      className={classNames(
                        'configurable-table',
                        showMoreButton
                      )}
                      // @ts-ignore
                      onClick={onClickHandler}
                    >
                      Show {fullRow.visible ? 'Less' : 'More'}
                    </div>
                  )}
                </td>
              );
          }
        })}
      </tr>
      {fullRow.visible && (
        <div className={expandedRow}>
          <span className={styles.expand_row}>{fullRow.value}</span>
        </div>
      )}
    </>
  );
};

export default Row;
