import styled from '@emotion/styled';

const BuDropdownNonClickableItem = styled.div`
  padding: 15px;
  background-color: transparent;

  border-top: 1px solid var(--bu-gray-400);

  &:first-of-type {
    border-top: none;
  }

  &:not(.no-hover):hover {
    background-color: var(--bu-primary-200);
  }
`;

export default BuDropdownNonClickableItem;
