import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import * as styles from 'components/UI/BuImpersonationPanel/styles';
import { IProps } from 'components/UI/BuImpersonationPanel/types';
import { AccessMode } from 'reducers/companyUsersReducer';
import { IReduxState } from 'reducers/types';
import { isImpersonate } from 'selectors';
import { fetchApiPromise } from 'utils/network';

const COMPONENT_TESTING_NAME = 'ImpersonationPanel';

const accessModeTypes = {
  [AccessMode.EDITOR]: 'Read-Write',
  [AccessMode.VIEWER]: 'Read-Only',
};

const BuImpersonationPanel: React.FC<IProps> = ({ testingLabel }) => {
  const [accessMode, setAccessMode] = useState<AccessMode | ''>('');
  const [impersonatedUser, setImpersonatedUser] =
    useState<{
      email: string;
      id: string;
      name: string;
    } | null>(null);

  const isImpersonationAllowed = useSelector((state: IReduxState) =>
    isImpersonate(state)
  );

  useEffect(() => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/settings/impersonation`;

    fetchApiPromise<object, any>({
      queryMethod: 'get',
      url,
    }).then(({ result }) => {
      setAccessMode(result?.access_mode || '');
      setImpersonatedUser(result?.user || null);
    });
  }, []);

  const componentDataTestingKey = testingLabel
    ? `${testingLabel}-${COMPONENT_TESTING_NAME}`
    : COMPONENT_TESTING_NAME;

  return accessMode && impersonatedUser && isImpersonationAllowed ? (
    <div
      data-testing={componentDataTestingKey}
      className={styles.impersonationContainer}
    >
      You are impersonating{' '}
      <span>{impersonatedUser.name || impersonatedUser.email}</span> with{' '}
      <span>{accessMode && accessModeTypes[accessMode]}</span> permissions.
    </div>
  ) : null;
};

export default BuImpersonationPanel;
