import {
  reportsActionType,
  selectedClassType,
} from '../actions/reportsActions';
import { notNullAndEmpty } from '../common/utils';

function getNewValue(state, key, value) {
  let new_value = null;
  if (key === 'opportunities') {
    if (state['page_number'] !== 0) {
      if (notNullAndEmpty(state['all_opportunities'])) {
        new_value = state['all_opportunities'].concat(value);
      } else {
        new_value = value;
      }
    } else {
      new_value = value;
    }
  }
  return new_value;
}

function appendToState(state, key, value) {
  const new_value = getNewValue(state, key, value);
  if (new_value) {
    const new_key = 'all_' + key;
    return {
      ...state,
      [new_key]: new_value,
    };
  } else {
    return state;
  }
}

export const reportsReducer = function (
  state = {
    all_opportunities: [],
    opportunities: [],
    apiError: null,
    page_number: 0,
    selected_class_type: selectedClassType.ALL_OPPORTUNITIES,
    selected_custom_report_id: null,
    selected_custom_report_name: null,
  },
  action
) {
  switch (action.type) {
    case reportsActionType.UPDATE_STATE:
      var new_state;
      if (typeof action.key === 'string') {
        new_state = {
          ...state,
          [action.key]: action.value,
        };
        return appendToState(new_state, action.key, action.value);
      } else {
        new_state = {
          ...state,
          ...action.key,
        };
        const update = { ...action.key };
        for (var key in update) {
          new_state = appendToState(new_state, key, update[key]);
        }
        return new_state;
      }
    case reportsActionType.RESET_STATE:
      return {
        all_opportunities: [],
        opportunities: [],
        apiError: null,
        page_number: 0,
        selected_class_type: selectedClassType.ALL_OPPORTUNITIES,
        selected_custom_report_id: null,
        selected_custom_report_name: null,
      };
    case reportsActionType.SELECT_CUSTOM_REPORT:
      return {
        ...state,
        page_number: 0,
        selected_class_type: selectedClassType.CUSTOM_REPORT,
        selected_custom_report_id: action.custom_report_id,
        selected_custom_report_name: action.custom_report_name,
      };
    case reportsActionType.CLEAR_CUSTOM_REPORT:
      return {
        ...state,
        selected_custom_report_id: null,
        selected_custom_report_name: null,
      };
    default:
      return state;
  }
};
