import * as t from '../actions/actionTypes';

const initialState = {
  'How did your pipeline change': {
    visibleSeries: [],
  },
};

export default function widgetsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case t.WIDGETS + t.SET + t.VISIBLE_SERIES:
      return {
        ...state,
        [payload.title]: {
          ...(state[payload.title] || {}),
          visibleSeries: payload.series,
        },
      };
    case t.WIDGETS + t.SORTING:
      return {
        ...state,
        [payload.title]: {
          ...(state[payload.title] || {}),
          sort: payload.value,
        },
      };
    default:
      return state;
  }
}
