import Bound from 'bounds.js';
import React, {
  Children,
  cloneElement,
  isValidElement,
  useEffect,
  useRef,
  useState,
} from 'react';

export enum BOUNDARY {
  INSIDE,
  OUTSIDE,
}

export type AddedWithCloneProps = {
  position?: BOUNDARY;
};

type Props = {
  children: React.ReactNode;
  height?: number;
  margin?: number;
  withClone?: boolean;
};

export const VirtualizedItem: React.FC<Props> = ({
  children,
  height,
  margin = 0,
  withClone,
}) => {
  const [position, setPosition] = useState<BOUNDARY>(BOUNDARY.OUTSIDE);
  const root = useRef(null);

  useEffect(() => {
    let timeoutId: NodeJS.Timer | null = null;

    const boundary = Bound({
      margins: {
        top: margin,
        bottom: margin,
      },
    });

    const onEnter = (): void => {
      timeoutId = setTimeout(
        () =>
          setPosition(
            boundary.check(root.current) ? BOUNDARY.INSIDE : BOUNDARY.OUTSIDE
          ),
        300
      );
    };

    const onLeave = (): void => setPosition(BOUNDARY.OUTSIDE);

    boundary.watch(root.current, onEnter, onLeave);

    return () => {
      boundary.clear();

      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
    };
  }, [margin]);

  return withClone ? (
    <div ref={root}>
      {Children.map(children, (el) => {
        if (isValidElement(el)) {
          // @ts-ignore
          return cloneElement(el, { position });
        }
      })}
    </div>
  ) : (
    <div ref={root} style={{ height }}>
      {position === BOUNDARY.INSIDE ? children : null}
    </div>
  );
};
