import { getRevBiNavigationLink } from 'common/helpers';
import { navigationSchemes } from 'navigation/constants';
import {
  INavigationScheme,
  IUserProfileNavigationItem,
} from 'navigation/types';

/* returns default landing scheme for navigation */
export const getDefaultNavigationPath = (
  navigationItem: IUserProfileNavigationItem
) => {
  if (navigationItem.default_landing) {
    const navigationScheme = navigationSchemes[
      navigationItem.name
    ] as INavigationScheme;
    /* if `default_landing` match with defined route schemes, return defined one, if no return revbi dashboard scheme */
    return navigationScheme[navigationItem.default_landing]
      ? navigationScheme[navigationItem.default_landing].scheme
      : getRevBiNavigationLink(
          navigationItem.name,
          navigationItem.default_landing.split('__')[1]
        );
  }
  return '';
};

export const getMainNavLandingsPaths = (
  navigationItems: IUserProfileNavigationItem[]
) => {
  const navigationSchemes: { [key: string]: { path: string } } = {};

  navigationItems.forEach((item) => {
    navigationSchemes[item.name] = {
      path: getDefaultNavigationPath(item),
    };
  });

  return navigationSchemes;
};
