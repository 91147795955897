import classNames from 'classnames';
import get from 'lodash/get';
import moment from 'moment';
import React, { Component } from 'react';

import { emailBreak } from 'components/UI/MeetingAndCallsTable/Row/Row';
import Chart from 'components/UI/TopicsDiscussedTabs/Table/Chart';
import {
  IProps,
  IState,
} from 'components/UI/TopicsDiscussedTabs/Table/Row/types';
import {
  TopicTitle,
  TitlesRow,
} from 'components/UI/TopicsDiscussedTabs/elements';
import * as s from 'components/UI/TopicsDiscussedTabs/styles';
import { LastMentions } from 'components/UI/TopicsDiscussedTabs/types';

const R = require('ramda');
class Row extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { lineWidth: null };
  }

  componentDidMount() {
    const width = get(this, 'timeLineWidth.current.offsetWidth', 499);
    this.setState({
      lineWidth: width,
    });
  }

  render() {
    const { row, minMaxRange, entityId } = this.props;
    const { lineWidth } = this.state;
    const num_mentions = get(row, 'timeline.length', 0);

    const last_mentions_all = get(row, 'last_mentions_customer', []).concat(
      get(row, 'last_mentions_company', [])
    );
    const sort_function = R.comparator(
      (el1: LastMentions, el2: LastMentions) =>
        moment(el1.mentioned_at) > moment(el2.mentioned_at)
    );

    const last_mentions_all_sorted = R.sort(sort_function, last_mentions_all);

    const last_mentioned = last_mentions_all_sorted
      ? last_mentions_all_sorted[0]
      : null;

    const title =
      last_mentioned && last_mentioned.email_type == 'CUSTOMER'
        ? last_mentioned.mentioned_by.title
        : '';

    const titles = last_mentioned ? (
      <div
        style={{ marginBottom: 10, wordBreak: 'break-word' }}
        key={last_mentioned.object_id}
      >
        <TitlesRow>
          <div>
            {last_mentioned.mentioned_by
              ? emailBreak(last_mentioned.mentioned_by.email)
              : ''}
            <br />
            {title}
          </div>
        </TitlesRow>
        <span className={s.time}>
          {moment(last_mentioned.mentioned_at).fromNow()}
        </span>
      </div>
    ) : null;

    const horizontalAlign = classNames({
      [s.body_row_column]: true,
      horizontal: true,
    });

    return (
      <tr className={s.table_body_row}>
        <td className={s.body_row_column}>
          <TopicTitle>{row.topic_name}</TopicTitle>
        </td>
        <td className={horizontalAlign}>{num_mentions}</td>
        <td className={s.body_row_column}>{titles}</td>
        <td className={s.body_row_column}>
          <Chart
            entityId={entityId}
            minMaxRange={minMaxRange}
            timeLineWidth={lineWidth}
            data={get(this.props, ['row', 'timeline'], [])}
            topic={get(this.props, ['row', 'topic_name'], '')}
            timeSpan={get(this.props, ['timeSpan'], '')}
          />
        </td>
      </tr>
    );
  }
}

export default Row;
