import clx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Loader } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import * as styles from 'components/modals/EventModal/styles';
import { IProps } from 'components/modals/EventModal/types';

const EventBody: React.FC<{ event: IProps['event'] }> = ({ event }) => {
  if (!event) {
    return null;
  }

  return (
    <>
      <div className={styles.box_email}>
        <div className={clx(styles.box_email_line, styles.margin_bottom)}>
          <div className={styles.box_email_icon}>
            <span className={clx(styles.circle, { orange: true })}>
              <BuIcon name={BoostUpIcons.Calendar} />
            </span>
          </div>
          <div className={styles.align_left}>
            <div className={clx(styles.subject_name, 'bu-font-heading')}>
              {event.summary}
            </div>
            <div className={styles.date}>
              {moment(event.start).format('DD MMM YYYY, h:mm A')}
            </div>
          </div>
        </div>
        <div className={styles.box_email_line}>
          <div className={styles.box_email_icon}>Creator:</div>
          <div className={styles.text}>{event.creator.email}</div>
        </div>
        {/* {event.location && (
          <div className={clx(styles.box_email_line, styles.margin_bottom)}>
            <div className={styles.box_email_icon}>Location:</div>
            <div className={styles.text}>{event.location}</div>
          </div>
        )} */}
        <div className={clx(styles.box_email_line, styles.margin_bottom)}>
          <div className={styles.box_email_icon}>Attendees:</div>
          <div className={styles.text}>
            {event &&
              event.attendees &&
              event.attendees.map(
                (
                  attendee: {
                    email: string;
                    display_name?: string;
                    response_status: string;
                  },
                  index: number
                ) => (
                  <span key={attendee.email}>
                    {attendee.display_name || attendee.email}
                    {attendee.response_status === 'declined' ? (
                      <span className={styles.attendeeDeclined}>
                        &nbsp;(Declined)
                      </span>
                    ) : null}

                    {index < event.attendees.length - 1 ? ', ' : null}
                  </span>
                )
              )}
          </div>
        </div>
        {event.description && (
          <div className={styles.box_email_line}>
            <div className={styles.box_email_icon}>
              <span className={styles.left_icons}>
                <BuIcon name={BoostUpIcons.Dialogue} />
              </span>
            </div>
            <div className={styles.email_body_box}>
              <p
                className="display-linebreak"
                dangerouslySetInnerHTML={{ __html: event.description }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

EventBody.propTypes = {
  event: PropTypes.shape({
    summary: PropTypes.string,
    start: PropTypes.string,
    creator: PropTypes.shape({
      email: PropTypes.string,
    }),
    attendees: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string,
        display_name: PropTypes.string,
        response_status: PropTypes.string,
      })
    ),
    location: PropTypes.string,
    description: PropTypes.string,
  }),
};
EventBody.defaultProps = {
  event: null,
};

export const EventModal: React.FC<IProps> = ({ event, status }) => (
  <div className={styles.container}>
    {status === 'loading' && <Loader active />}

    {status === 'error' ? (
      <div className={styles.not_found}>Event not found</div>
    ) : (
      <EventBody event={event} />
    )}
  </div>
);

EventModal.defaultProps = {
  event: EventBody.defaultProps.event,
  status: null,
};
