import classNames from 'classnames';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import EventsTable from 'components/UI/EventsTable';
import OpenFiltersPanel from 'components/UI/OpenFiltersPanel';
import { useHeader } from 'components/UI/Wrapper/Header/header.context';
import * as styles from 'components/dashboard/Meetings/styles';
import { IReduxState } from 'reducers/types';
import { getFiltersForAPI } from 'selectors/openFilters';

type Props = {
  tab: 'meetings_completed' | 'meetings_upcoming';
  title: string;
};

const Meetings: React.FC<Props> = ({ tab, title }) => {
  const { setPartition, clearContext } = useHeader();

  const filters = useSelector((state: IReduxState) =>
    getFiltersForAPI(state, tab)
  );

  const isUpcoming = tab === 'meetings_upcoming';
  const filterKey = isUpcoming ? 'start_gt' : 'start_lt';

  useEffect(() => {
    setPartition('meetings');

    return () => {
      clearContext();
    };
  }, []);

  return (
    <div className={classNames('wrapper', styles.wrapper)}>
      <div className={styles.header}>
        <OpenFiltersPanel tab={tab} />
      </div>

      <div className={styles.content}>
        <EventsTable
          embedded
          title={title}
          filters={
            {
              ...filters,
              [filterKey]: moment().format('YYYY-MM-DD'),
              sort: isUpcoming ? 'start' : '-start',
            } as any
          }
        />
      </div>
    </div>
  );
};

export default Meetings;
