import { useEffect, useState } from 'react';

export const useCheckFrameWindow = (): boolean => {
  const [isFrame, setFrame] = useState(false);

  useEffect(() => {
    try {
      return setFrame(window.self !== window.top);
    } catch (e) {
      return setFrame(true);
    }
  });

  return isFrame;
};
