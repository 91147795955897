import React from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import BuPopup from 'components/UI/BuPopup';
import {
  pin_container,
  pin_icon,
} from 'components/UI/OpenFiltersPanel/CustomViewsDropdown/CustomViewItem/DefaultViewPinIcon/styles';
import { popup } from 'components/UI/OpenFiltersPanel/styles';

type IProps = {
  isPinned: boolean;
  isDefaultView: boolean;
  onPinClick: (e: React.MouseEvent<HTMLDivElement>) => void;
};

const DefaultViewPinIcon: React.FC<IProps> = ({
  isPinned,
  isDefaultView,
  onPinClick,
}) => {
  const icon = (
    <BuIcon
      name={BoostUpIcons.Pin}
      className={pin_icon}
      color={isPinned ? 'var(--bu-primary-500)' : 'var(--bu-gray-300)'}
    />
  );

  return isPinned ? (
    <div
      style={{ cursor: isDefaultView ? 'default' : 'pointer' }}
      onClick={onPinClick}
    >
      {icon}
    </div>
  ) : (
    <div className={pin_container} onClick={onPinClick}>
      <BuPopup trigger={(ref) => <div ref={ref}>{icon}</div>}>
        <div className={popup}>
          Pin this as a <br />
          default view
        </div>
      </BuPopup>
    </div>
  );
};

export default DefaultViewPinIcon;
