import { css } from 'emotion';

export const table = css`
  margin-bottom: 20px;
`;

export const table_wrapper = css`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  position: relative;
  border: 1px solid var(--bu-gray-500);
  color: var(--bu-gray-900);
`;

export const table_sticky_header = css`
  border-collapse: separate !important;

  & thead tr:first-child > th {
    position: sticky !important;
    top: 0;
    background-color: var(--bu--table-header);
    border-right: 1px solid var(--bu-table-header-border-color);
    font-family: var(--bu-font-medium);
    font-weight: 500;
    color: var(--bu-gray-900);
    line-height: 1.3em;

    &:last-child {
      border-right: none;
    }
  }
`;

export const table_header_box = css`
  z-index: 1;
  position: relative;
  th {
    border-bottom: 1px solid var(--bu-table-header-border-color);
  }
`;

export const table_header_column = css`
  padding: 0 10px;
  color: var(--bu-gray-700);
  position: relative;
  width: 10%;
`;

export const body_table = css`
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const body_table_row = css`
  td {
    border-bottom: 0.1px solid var(--bu-table-border-color);
    border-right: 1px solid var(--bu-table-border-color);

    &:last-child {
      border-right: none;
    }
  }

  &:hover {
    background: var(--bu-gray-200);
  }
`;

export const body_table_column = css`
  padding: 5px 10px;
  line-height: 22px;
`;

// first column

export const show_text = css`
  cursor: pointer;
  color: var(--bu-primary-500);
  text-decoration: underline;
`;

// second column
export const prospect_position = css`
  font-weight: 600;
`;

// third column

export const date = css`
  font-weight: 600;
  color: #ee724e;
`;

// section btn

export const section_btn = css`
  text-align: center;
  padding: 15px;
`;

export const boldText = css`
  padding: 2px 4px;
  background-color: #e6e60c;
  color: #1a1a1a;
`;

export const table_body_empty = css`
  text-align: center;
  padding: 30px;
  font-weight: 100;
  color: var(--bu-gray-700);
`;
export const timespanSelector = css`
  display: flex;
  justify-content: flex-end;
`;
export const textTruncate = css`
  max-width: 35vw;
  text-overflow: ellipsis;
  overflow: hidden;
`;
