import { QueryStatus, useQuery } from '@tanstack/react-query';
import { getMetricProperties } from 'api/RevBiMetric';
import { useRef } from 'react';
import {
  AGGREGATION_OPTIONS,
  AnalysisType,
  FORECAST_SUBMISSION,
} from '../../constants';

interface UseMetricPropertiesParams {
  analysisType: AnalysisType;
  object: string;
}

interface UseMetricPropertiesResult {
  status: QueryStatus;
  isLoading: boolean;
  isError: boolean;
  options: any[];
  aggOptions: any[];
  operations: any[];
}

export const useMetricPropertiesList = ({
  analysisType,
  object,
}: UseMetricPropertiesParams): UseMetricPropertiesResult => {
  const {
    data = [],
    isError,
    isLoading,
    status,
  } = useQuery({
    queryKey: ['metricPropertiesList', object, analysisType],
    queryFn: () => getMetricProperties(object, analysisType),
    staleTime: 3600,
  });

  /**
   * AGG options for now are hardcoded in FE
   * we don't have an API and only FS will not used it
   */
  const aggOptions = object !== FORECAST_SUBMISSION ? AGGREGATION_OPTIONS : [];

  return {
    status,
    isLoading,
    isError,
    options: data,
    aggOptions,
    operations: [],
  };
};
