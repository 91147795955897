import * as t from 'actions/revbi/actionTypes';
import {
  BIMetricCreated,
  BIWidget,
  DateFilterOptionsResponse,
} from 'components/dashboard/Metrics/metrics.types';
import { get } from 'sagas/generic';
import { actionCreator } from 'utils/factories';

/**
 * Action fetches all metrics from the server
 * @see /rev_bi/metrics API GET
 */
export const fetchAllMetrics = () => ({
  type: t.METRIC + t.GET + t.ALL,
  url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics`,
  saga: get,
  success: (payload: BIMetricCreated[]) => fetchAllMetricsSuccess(payload),
  loading: fetchAllMetricsLoading,
});
export const fetchAllMetricsSuccess = actionCreator<BIMetricCreated[]>(
  t.METRIC + t.GET + t.ALL + t.SUCCESS
);
export const fetchAllMetricsLoading = actionCreator(
  t.METRIC + t.GET + t.ALL + t.LOADING
);

/**
 * Action fetches all metrics from the server
 * @see /rev_bi/metrics/metrics_time_series API GET
 */
export const fetchAllTSMetrics = () => ({
  type: t.METRIC_TIME_SERIES + t.GET + t.ALL,
  url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics_time_series`,
  saga: get,
  success: (payload: BIMetricCreated[]) => fetchAllTSMetricsSuccess(payload),
  loading: fetchAllTSMetricsLoading,
});

export const fetchAllTSMetricsSuccess = actionCreator<BIMetricCreated[]>(
  t.METRIC_TIME_SERIES + t.GET + t.ALL + t.SUCCESS
);
export const fetchAllTSMetricsLoading = actionCreator(
  t.METRIC_TIME_SERIES + t.GET + t.ALL + t.LOADING
);

/**
 * Action removes deleted metric from the metrics list in store.
 */
export const removeMetric = actionCreator<{ ids: string[] }>(
  t.METRIC + t.REMOVE
);

/**
 * Actions add newly created or cloned metric to the list
 */
export const addMetric = actionCreator<BIMetricCreated>(t.METRIC + t.ADD);

/**
 * Actions add newly created or cloned historical metric to the list
 */
export const addTSMetric = actionCreator<BIMetricCreated>(
  t.METRIC_TIME_SERIES + t.ADD
);

/**
 * Action gets objects list from API
 * @see /rev_bi/external/get_object_list
 */
export const fetchObjectList = () => ({
  type: t.OBJECT_LIST + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_object_list`,
  saga: get,
  success: (payload: string[]) => fetchObjectListSuccess(payload),
  loading: fetchObjectListLoading,
});
export const fetchObjectListSuccess = actionCreator<string[]>(
  t.OBJECT_LIST + t.GET + t.SUCCESS
);
export const fetchObjectListLoading = actionCreator(
  t.OBJECT_LIST + t.GET + t.LOADING
);

/**
 * Action gets objects list from API
 * @see /rev_bi/external/get_object_list_history
 */
export const fetchTimeSeriesObjectList = () => ({
  type: t.OBJECT_LIST + t.TIME_SERIES + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_object_list_history`,
  saga: get,
  success: (payload: string[]) => fetchTimeSeriesObjectListSuccess(payload),
  loading: fetchTimeSeriesObjectListLoading,
});
export const fetchTimeSeriesObjectListSuccess = actionCreator<string[]>(
  t.OBJECT_LIST + t.TIME_SERIES + t.GET + t.SUCCESS
);
export const fetchTimeSeriesObjectListLoading = actionCreator(
  t.OBJECT_LIST + t.TIME_SERIES + t.GET + t.LOADING
);

/**
 * Action gets objects list from API
 * @see /rev_bi/external/get_date_filter_settings
 */
export const fetchTimeOptions = () => ({
  type: t.TIME_OPTIONS + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_date_filter_settings`,
  saga: get,
  success: (payload: DateFilterOptionsResponse) =>
    fetchTimeOptionsSuccess(payload),
  loading: fetchTimeOptionsLoading,
});
export const fetchTimeOptionsSuccess = actionCreator<DateFilterOptionsResponse>(
  t.TIME_OPTIONS + t.GET + t.SUCCESS
);
export const fetchTimeOptionsLoading = actionCreator(
  t.TIME_OPTIONS + t.GET + t.LOADING
);

/**
 * Action fetches all widgets from the server
 * @see /rev_bi/widgets API GET
 */
export const fetchAllWidgets = () => ({
  type: t.WIDGET + t.GET + t.ALL,
  url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/widgets`,
  saga: get,
  success: (payload: BIWidget[]) => fetchAllWidgetsSuccess(payload),
  loading: fetchAllWidgetsLoading,
});
export const fetchAllWidgetsSuccess = actionCreator<BIWidget[]>(
  t.WIDGET + t.GET + t.ALL + t.SUCCESS
);
export const fetchAllWidgetsLoading = actionCreator(
  t.WIDGET + t.GET + t.ALL + t.LOADING
);

/**
 * Action removes deleted widget from the widgets list in store.
 */
export const removeWidget = actionCreator<{ id: string }>(t.WIDGET + t.REMOVE);

/**
 * Actions add newly created or cloned widget to the list
 */
export const addWidget = actionCreator<BIWidget>(t.WIDGET + t.ADD);

/**
 * Updates widget in the list
 */
export const updateWidget = actionCreator<BIWidget>(t.WIDGET + t.UPDATE);

export const fetchQuarterForecastPeriodTimeOptions = () => ({
  type: t.FORECAST_PERIOD + t.GET + t.QUARTER,
  url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/forecast_submission/get_time_period_options/quarter`,
  saga: get,
  success: (payload: Filters.DateFilterElement[]) =>
    fetchQuarterForecastPeriodTimeOptionsSuccess(payload),
  loading: fetchQuarterForecastPeriodTimeOptionsLoading,
});
export const fetchQuarterForecastPeriodTimeOptionsSuccess = actionCreator<
  Filters.DateFilterElement[]
>(t.FORECAST_PERIOD + t.GET + t.QUARTER + t.SUCCESS);
export const fetchQuarterForecastPeriodTimeOptionsLoading = actionCreator(
  t.FORECAST_PERIOD + t.GET + t.QUARTER + t.LOADING
);

export const fetchMonthForecastPeriodTimeOptions = () => ({
  type: t.FORECAST_PERIOD + t.GET + t.MONTH,
  url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/forecast_submission/get_time_period_options/month`,
  saga: get,
  success: (payload: Filters.DateFilterElement[]) =>
    fetchMonthForecastPeriodTimeOptionsSuccess(payload),
  loading: fetchMonthForecastPeriodTimeOptionsLoading,
});
export const fetchMonthForecastPeriodTimeOptionsSuccess = actionCreator<
  Filters.DateFilterElement[]
>(t.FORECAST_PERIOD + t.GET + t.MONTH + t.SUCCESS);
export const fetchMonthForecastPeriodTimeOptionsLoading = actionCreator(
  t.FORECAST_PERIOD + t.GET + t.MONTH + t.LOADING
);

export const fetchWeekForecastPeriodTimeOptions = () => ({
  type: t.FORECAST_PERIOD + t.GET + t.WEEK,
  url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/forecast_submission/get_time_period_options/week`,
  saga: get,
  success: (payload: Filters.DateFilterElement[]) =>
    fetchWeekForecastPeriodTimeOptionsSuccess(payload),
  loading: fetchWeekForecastPeriodTimeOptionsLoading,
});
export const fetchWeekForecastPeriodTimeOptionsSuccess = actionCreator<
  Filters.DateFilterElement[]
>(t.FORECAST_PERIOD + t.GET + t.WEEK + t.SUCCESS);
export const fetchWeekForecastPeriodTimeOptionsLoading = actionCreator(
  t.FORECAST_PERIOD + t.GET + t.WEEK + t.LOADING
);
