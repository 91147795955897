import React, { useMemo } from 'react';

import { MetricDescription } from 'components/dashboard/Metrics/common/MetricTag/styles';
import { FORECAST, TARGET } from 'components/dashboard/Metrics/constants';
import { AggregationFunction } from 'components/dashboard/Metrics/enums';

const AGGREGATION_FUNCTION_COLORS = [
  {
    aggregation: AggregationFunction.Sum,
    color: 'indigo',
  },
  {
    aggregation: AggregationFunction.Average,
    color: 'yellow',
  },
  {
    aggregation: AggregationFunction.Min,
    color: 'lime',
  },
  {
    aggregation: AggregationFunction.Max,
    color: 'purple',
  },
  {
    aggregation: AggregationFunction.Count,
    color: 'primary',
  },
  {
    aggregation: TARGET,
    color: 'green',
  },
  {
    aggregation: FORECAST,
    color: 'orange',
  },
];

interface Props {
  readonly metricDescription: string;
  readonly aggregationFunction: string;
}

export const MetricTag: React.FC<Props> = ({
  metricDescription,
  aggregationFunction,
}) => {
  const tagColor = useMemo(
    () =>
      AGGREGATION_FUNCTION_COLORS.find(
        (element) => element.aggregation === aggregationFunction
      )?.color ?? 'gray',
    [aggregationFunction]
  );

  return (
    <MetricDescription color={tagColor}>
      <span>{metricDescription}</span>
    </MetricDescription>
  );
};
