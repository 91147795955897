import { push, LOCATION_CHANGE, getLocation } from 'connected-react-router';
import queryString from 'query-string';
import * as R from 'ramda';
import { put, race, select, take, call } from 'redux-saga/effects';

import * as t from 'actions/actionTypes';
import { createSettingsAction } from 'actions/settingsActions';
import { createUserAction, signUpStepChange } from 'actions/userActions';

function* createAccountStep() {
  // clear the state to start the creation of a new user
  yield put(createUserAction.resetState());
  yield put(createSettingsAction.resetState());

  // if reg_token provided put to state
  const params = queryString.parse(window.location.search);
  const registrationToken = params.reg_token;
  if (registrationToken) {
    yield put(createUserAction.updateState({ registrationToken }));
  }
  // wait for a google or outlook login success action
  const action = yield take(t.SIGN_UP + t.OAUTH + t.SUCCESS);

  // redirect to the salesforce linking section
  yield put(signUpStepChange(0, true));
  yield put(push('/sign-up/salesforce'));
}

function* linkSalesforceStep() {
  // catch the link action
  yield take(t.SALESFORCE + t.OAUTH + t.SUCCESS);

  // move to the next step
  yield put(signUpStepChange(1, true));
  // yield put(push('/sign-up/conference-details'));
  yield put(push('/sign-up/summary'));
}

function* conferenceDetailsStep() {
  // catch update action
  yield take(t.SETTINGS + t.UPDATE + t.SUCCESS);

  // move to the next step
  yield put(signUpStepChange(2, true));
  yield put(push('/sign-up/summary'));
}

function* summaryStep() {
  // nothing to do here, wait for location change
  yield take(LOCATION_CHANGE);
}

const steps = {
  '/sign-up': createAccountStep,
  '/sign-up/it-admin-oauth': createAccountStep,
  '/sign-up/salesforce': linkSalesforceStep,
  // '/sign-up/conference-details': conferenceDetailsStep,
  '/sign-up/summary': summaryStep,
};

function* signUpProcess() {
  while (true) {
    const { pathname } = yield select(getLocation);
    const step = steps[pathname];

    if (R.isNil(step)) {
      // we're on a route outside of the sign-up, so exit the saga
      break;
    }

    yield race([
      call(step), // call the current step
      take(LOCATION_CHANGE), // but it can be skipped at any time
    ]);
  }
}

function* signUpSaga() {
  while (true) {
    // when entering the login section
    yield take(t.SIGN_UP + t.ENTER);

    // start with the sign up process
    yield call(signUpProcess);
  }
}

export default signUpSaga;
