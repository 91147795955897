import { css } from 'emotion';
import React from 'react';

import PlayImage from 'assets/fonts/boostup-icons/play.svg';
import TruncatedText from 'components/UI/TruncatedText/TruncatedText';
import { IDataCellProps } from 'components/UI/common/TypedTable/TypedTable';
import NotAvailableCell from 'components/UI/common/TypedTable/renderers/NotAvailableCell';
import { ModalLinkWithAnalytics } from 'navigation/Link';
import { history } from 'store/configureStore';

const audioCallButtonStyle = css`
  border: none;
  cursor: pointer;
  height: 26px;
  background: transparent;
`;

const blueLinkStyle = css`
  color: #0762ec;
  cursor: pointer;
`;

const nameCellColumnStyle = css`
  display: flex;
  flex-direction: column;
`;

const nameCellContainerStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const EventNameCell: React.FC<IDataCellProps> = ({
  column,
  row,
}: IDataCellProps) => {
  const { event_call, event_id, event_summary } = row;
  const { tableName, truncateTextFrom = 40 } = column.config;
  const isAudioCallPresent = tableName === 'meeting-follow-up' && event_call;
  const text = `${event_summary}`;

  const textLength = isAudioCallPresent
    ? truncateTextFrom - 5
    : truncateTextFrom;

  return (
    <div className={nameCellContainerStyle}>
      {event_summary ? (
        <>
          <div className={nameCellColumnStyle}>
            {event_id ? (
              <ModalLinkWithAnalytics
                action={`Open eventId:${event_id}`}
                category="Reps Recommendation"
                url={{
                  scheme: '/event/:eventId',
                  params: { eventId: `${event_id}` },
                }}
              >
                <TruncatedText
                  inverted
                  length={textLength}
                  text={text}
                  textClassName={blueLinkStyle}
                />
              </ModalLinkWithAnalytics>
            ) : (
              <TruncatedText inverted text={text} length={textLength} />
            )}
          </div>

          {isAudioCallPresent && (
            <button
              className={audioCallButtonStyle}
              key={`audioCall-${event_call}`}
              onClick={() => history.push(`/transcript/${event_call}`)}
            >
              <img src={PlayImage} alt="play" />
            </button>
          )}
        </>
      ) : (
        <NotAvailableCell />
      )}
    </div>
  );
};

export default EventNameCell;
