import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import * as accountsActions from 'actions/accountsActions';
import * as dealsActions from 'actions/dealsActions';
import { goBackOr, goToAllDeals } from 'actions/routeActions';
import Account from 'components/account-insights';
import * as selectors from 'selectors';

class AccountScreen extends React.Component {
  componentDidMount() {
    const { getAccount, id = get(this, ['props', 'match', 'params', 'id']) } =
      this.props;

    getAccount(id);
  }

  componentDidUpdate(prevProps) {
    const { getAccount, id } = this.props;
    const oldAccountId = get(prevProps, 'match.params.id') || prevProps.id;
    const newAccountId = get(this.props, 'match.params.id') || id;

    if (
      !isNil(oldAccountId) &&
      !isNil(newAccountId) &&
      !isEqual(oldAccountId, newAccountId)
    ) {
      getAccount(newAccountId);
    }
  }

  render() {
    return <Account {...this.props} />;
  }
}

const mapStateToProps = (state, props) => ({
  account: selectors.getAccount(
    state,
    get(props, ['match', 'params', 'id']) || props.id
  ),
  previousSection: selectors.getPreviousSection(state, 'All Deals'),
  previousRoute: selectors.getPreviousRoute(state),
  deals: selectors.getAccountDeals(state),
  status: selectors.getAccountsStatus(state),
  calculatedData: selectors.getAccountCalculatedData(state),
  openStages: selectors.getSettingsOpenStages(state),
  defaultTimeFilter: selectors.getDealInsightsDefaultTime(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAccount: (id) => dispatch(accountsActions.getAccountData(id)),
  goBack: (previousRoute) => dispatch(goBackOr(goToAllDeals, previousRoute)),
  changeSentiment: (id, prospect, grade, phrases) =>
    dispatch(
      dealsActions.changeDealProspectSentiment(id, prospect, grade, phrases)
    ),
  removeSentiment: (id, prospect) =>
    dispatch(dealsActions.removeDealProspectSentiment(id, prospect)),
});

AccountScreen.propTypes = {
  getAccount: PropTypes.func,
  id: PropTypes.string,
  isPanel: PropTypes.bool,
  noResave: PropTypes.bool,
  isFullScreen: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountScreen);
