import { css } from 'emotion';

import { fontDefault } from 'assets/css/common';

const BASE_PANEL_DROPDOWN_WIDTH = 241;

export const getHierarchyDropdownStyle = (level: number) => css`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow: overlay;
  width: ${BASE_PANEL_DROPDOWN_WIDTH + 30 * level}px;

  div.ctt:first-child {
    margin-top: 15px !important;
  }

  div.ctt:last-child {
    margin-bottom: 5px !important;
  }
`;

export const list_item = css`
  padding: 7px 15px 7px 15px;
  transition: 0.4s all;

  &:hover {
    background: rgba(48, 176, 209, 0.08);
  }
`;

export const no_result_box = css`
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    ${fontDefault};
    margin-bottom: 10px;
  }

  span {
    padding: 5px 11px;
    color: var(--bu-gray-900);
    ${fontDefault};
    background: var(--bu-white);
    border: 1px solid var(--bu-gray-500);
    border-radius: 3px;
    cursor: pointer;
    transition: 0.4s;

    &:hover {
      border-color: var(--bu-primary-500);
      background: rgba(49, 92, 121, 0.12);
    }
  }
`;

export const panel_dropdown_divider = css`
  margin: 0px !important;
  border-bottom: 0px !important;
`;

export const panel_dropdown_input = css`
  display: flex;
  height: 32px;
  width: 100%;

  input {
    ${fontDefault};
    border: 0px !important;
    border-radius: 2px;
    background-color: var(--bu-white);
    transition: 0.4s all;

    &:focus {
      outline: 1px solid var(--bu-gray-700);
    }
  }
`;
