import styled from '@emotion/styled';
import { css } from 'emotion';

export const wrapper = css`
  min-width: 580px;
  max-height: 600px;
  overflow-y: auto;
`;

export const loaderStyle = css`
  height: 50px;
  margin-bottom: 15px;
  margin-top: 15px;
`;

export const forecastItem = css`
  display: flex;
  border-bottom: 1px solid var(--bu-gray-400);
`;

export const LeftSide = styled.div`
  flex: 1;
  padding: 12px 16px;
  border-right: 1px solid var(--bu-gray-400);
  border-bottom: 1px solid var(--bu-gray-400);
`;

export const RightSide = styled.div`
  flex: 2;
  border-bottom: 1px solid var(--bu-gray-400);
  padding: 12px 24px 12px 16px;
`;

export const forecastName = css`
  font-weight: 600;
`;

export const forecastDate = css`
  margin-top: 5px;
  color: var(--bu-gray-700);
`;

export const rightHeader = css`
  display: flex;
  width: 100%;
  flex-grow: 1;
`;

export const amount = css`
  font-weight: 500;
  font-size: 14px;
  flex: 1;
  display: flex;
`;

export const deltaBadge = css`
  margin: -2px 5px;
`;

export const dealsCount = css`
  flex: 1;
  justify-self: flex-end;
`;

export const CardsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin: 6px 0;
`;

// TODO: remember to change these colors to CSS variables after UI revamp
const grayCardBorder = 'var(--bu-gray-500)';
const greenCardBorder = '#00A674';
const redCardBorder = '#E24444';
const grayCardBackground = 'var(--bu-gray-200)';
const redCardBackground = '#FCEDED';
const greenCardBackground = '#E4FBF3';

export const cardBorderColor = (sentiment: boolean | null) => {
  if (sentiment == null) return grayCardBorder;
  if (sentiment) {
    return greenCardBorder;
  } else {
    return redCardBorder;
  }
};

export const cardBackgroundColor = (sentiment: boolean | null) => {
  if (sentiment == null) return grayCardBackground;
  if (sentiment) {
    return greenCardBackground;
  } else {
    return redCardBackground;
  }
};

export const SummaryDate = styled.div`
  font-size: 12px;
  color: var(--bu-gray-700);
`;

export const DealLink = styled.div`
  color: var(--bu-primary-500);
  display: inline;
  cursor: pointer;
`;

export const NoDataMessage = styled.div`
  max-width: 700px;
  margin: 10px 40px;
  color: var(--bu-gray-700);
  text-align: center;
`;

export const FetchingMessage = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
  color: var(--bu-gray-700);
  font-size: 12px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
`;
