import { css } from 'emotion';

import { fontSubtitle } from 'assets/css/common';
import dealStyles from 'components/deal/styles';

export const styles = {
  table: css`
    display: none;
    margin-bottom: 10px;

    &.open {
      display: block;
    }
  `,
  attendees: css`
    color: #4a4a4a;
    font-size: 15px;
    line-height: 20px;
    font-weight: 800;
    font-family: var(--bu-font-regular);
    padding-right: 20px;
  `,
  attendee: css`
    line-height: 1.2rem;

    & + & {
      margin-top: 0.5rem;
    }
  `,
  subject: css`
    color: #4a4a4a;
    font-size: 15px;
    line-height: 20px;
    font-weight: 800;
    font-family: var(--bu-font-regular);
    padding-right: 20px;
    cursor: pointer;

    :hover {
      color: #327cff;
    }
  `,
  email_subject: css`
    font-size: 24px;
  `,
  no_show: css`
    display: block;
    color: #f74544;
    font-size: 15px;
    font-family: var(--bu-font-regular);
  `,
  position: css`
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    font-family: var(--bu-font-regular);
    color: #4a4a4a;

    &.red {
      color: #f74544;
    }

    &.green {
      color: #0ba5a3;
    }
  `,
  graph: css`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  `,
  tabs: css`
    display: flex;
    margin-bottom: 10px;
  `,
  tab: css`
    display: inline-block;
    padding: 5px 10px;
    margin-right: 15px;
    align-items: center;
    cursor: pointer;
    font-family: var(--bu-font-regular);
    font-size: 13px;
    font-weight: 300;
    color: #777777;
    border-radius: 2px;
    background-color: #f4f7f8;
    outline: 0;
    border: none;
    transition: 0.4s;
    width: 100%;
    height: 30px;
    max-width: 220px;
    min-width: 150px;

    &.active {
      color: var(--bu-white);
      background-color: #4a4a4a;
    }

    &:hover {
      color: var(--bu-white);
      background-color: #4a4a4a;
    }
  `,
  fullScreenIcon: dealStyles.fullScreenIcon,
};

export default styles;

export const debugInfoStyles = {
  container: css`
    position: relative;
    display: flex;
    flex-direction: row;
    border: 1px solid var(--bu-gray-300);
    border-radius: 5px;
    background-color: var(--bu-white);
    margin-bottom: 2px;
    color: var(--bu-gray-900);
    letter-spacing: 0;
    line-height: 20px;
    margin: 12px 30px;
  `,
  content: css`
    padding: 20px 0 10px;
    flex: 1;
  `,
  iconContainer: css`
    background-color: var(--bu-primary-500);
    color: var(--bu-white);
    width: 40px;
    display: flex;
    align-items: center;
    font-size: 1.5em;
    justify-content: center;
  `,
  column: css`
    display: flex;
    flex-direction: column;
  `,
  row: css`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 2px;
  `,
  button: css`
    position: absolute;
    height: 24px;
    width: 24px;
    left: 50%;
    bottom: -12px;
    cursor: pointer;
    outline-style: none;
    background-color: var(--bu-white);
    border-radius: 12px;
    border-style: none;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);

    &:hover {
      background-color: #0762ec;

      img {
        filter: invert(100%) sepia(98%) saturate(0%) hue-rotate(345deg)
          brightness(150%) contrast(150%);
      }
    }
  `,
  icon: css`
    position: relative;
    height: 10px;
    width: 10px;
    transform: rotate(90deg);
    filter: invert(23%) sepia(86%) saturate(2603%) hue-rotate(210deg)
      brightness(97%) contrast(96%);
  `,
  title: css`
    ${fontSubtitle};
    margin-right: 5px;
    color: var(--bu-gray-900);
  `,
  subtitle: css`
    ${fontSubtitle};
    color: var(--bu-gray-900);
  `,
  marginTop15: css`
    margin-top: 15px;
  `,
  marginBottom10: css`
    margin-bottom: 10px;
  `,
  paddingRow: css`
    padding: 0 26px;
  `,
  lineHeight22: css`
    line-height: 22px;
  `,
  toggle_box: css`
    z-index: 989;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 20px;
    cursor: pointer;
    position: absolute;
    left: 50%;
    bottom: -14px;
    transform: translateX(-50%);
    width: 100%;
    height: 28px;
    &.hidden_panel {
      bottom: -17px;
      &:before {
        top: 9px;
      }
    }
  `,

  toggle_box_btn: css`
    display: inline-block;
    border: 1px solid var(--bu-gray-300);
    height: 100%;
    border-radius: 15px;
    padding: 0 15px 0 24px;
    background-color: var(--bu-white);
    transition: 0.4s;
    font-size: 12px;
    box-shadow: rgb(0 0 0 / 30%) 0px 0px 2px 0px;
    color: var(--bu-gray-700);
    position: relative;
    .bu-icon {
      width: 8px;
      margin-right: 5px;
      transform: rotate(90deg);
      display: inline-block;
      height: 10px;
      font-size: 16px;
      position: absolute;
      left: 12px;
      top: 0px;
      color: var(--bu-primary-500);
      &.down {
        transform: rotate(270deg);
        left: 6px;
        top: 9px;
      }
    }
  `,

  back_btn: css`
    box-sizing: border-box;
    height: 32px;
    width: 92px;
    border: 1px solid #aaaaaa;
    border-radius: 4px;
    background: var(--bu-white);
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    font-family: var(--bu-font-regular);
    font-size: 14px;
    color: #4a4a4a;
    transition: 0.5s;

    &:hover {
      background: var(--bu-gray-500);
    }
  `,
};
