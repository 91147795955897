import { css } from 'emotion';
import React, { FC } from 'react';

import BuDropdown, {
  BuDropdownItem,
  BuDropdownItemContainer,
  getDropdownItemProps,
} from 'components/UI/BuDropdown';
import { IProps } from 'components/UI/TopicsDiscussedTabs/OptionsSelector/types';

const customBuDropdown = css`
  &.bu-dropdown.bu-secondary {
    height: 32px;

    .bu-btn-content {
      color: var(--bu-gray-800) !important;
      border-color: var(--bu-gray-400) !important;
      font-weight: 500;
      display: flex;
      align-items: center;

      i.bu-down,
      i.bu-up {
        border-color: var(--bu-gray-800);
      }
    }
  }
`;

const OptionsSelector: FC<IProps> = ({ value, onChange, options = [] }) => {
  const activeOption = options.find((i) => value === i.value);

  return (
    <BuDropdown
      label={activeOption?.text}
      secondary
      className={customBuDropdown}
    >
      {(hide) => (
        <BuDropdownItemContainer
          style={{ maxHeight: 300 }}
          className="bu-font-default"
        >
          {options.map((el) => (
            <BuDropdownItem
              key={el.value}
              {...getDropdownItemProps(el, value === el.value, hide, () =>
                onChange(String(el.value))
              )}
            >
              {el.text}
            </BuDropdownItem>
          ))}
        </BuDropdownItemContainer>
      )}
    </BuDropdown>
  );
};

export default OptionsSelector;
