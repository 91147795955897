import styled from '@emotion/styled';

export const FunnelMetricDefinitionSection = styled.div({
  paddingBottom: '10px',
  position: 'relative',
});

export const SubSection = styled.div({
  marginBottom: '15px',
});

export const SubTitle = styled.div({
  fontSize: '16px',
  fontWeight: 600,
  color: 'var(--bu-gray-900)',
  marginBottom: '4px',
});

export const DefaultMetricOption = styled.div`
  padding: 10px 0;

  &:hover {
    background-color: var(--bu-primary-200);
    cursor: pointer;
  }
`;

export const DefaultMetricOptionTitle = styled.div({
  fontSize: '16px',
  padding: '0 10px',
});

export const DefaultMetricOptionDescription = styled.div({
  fontSize: '12px',
  color: 'var(--bu-gray-600)',
  padding: '0 10px',
});
