import * as selectors from '../../selectors';
import './styles.css';
import React from 'react';
import { connect } from 'react-redux';

const Banner = ({ isActive, title }) => {
  if (!isActive) {
    return (
      <div className="banner">
        {title} is in processing and testing currently and will be activated in
        a few days. Stay tuned!
      </div>
    );
  }
  return null;
};

const mapStateToProps = (state) => ({
  isActive: selectors.getSettingsIsDashboardActivated(state),
});

export default connect(mapStateToProps)(Banner);
