import classNames from 'classnames';
import React, { useRef } from 'react';

import * as styles from 'components/UI/TwoColumnsDashboard/styles';

type Props = {
  panel: React.ReactNode;
  isVisiblePanel: boolean;
  isModal?: boolean;
};

const TwoColumnsDashboard: React.FC<Props> = ({
  panel,
  isVisiblePanel,
  children,
  isModal = false,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const containerDashboardHeight = containerRef?.current?.offsetHeight || 0;

  return (
    <div
      className={classNames('container', {
        [styles.container]: isVisiblePanel,
        [styles.noPadding]: !isModal,
      })}
    >
      <div
        className={classNames('container-dashboard', {
          [styles.children]: isVisiblePanel,
        })}
        ref={containerRef}
      >
        {children}
      </div>

      {isVisiblePanel && (
        <div
          className={classNames(styles.panel, {
            [styles.borderTop]: isModal,
          })}
          style={{
            height: containerDashboardHeight,
          }}
        >
          {panel}
        </div>
      )}
    </div>
  );
};

export default TwoColumnsDashboard;
