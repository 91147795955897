import { css } from 'emotion';
import React from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import { IRow } from 'components/UI/common/TypedTable/TypedTable';
import {
  CustomComponentFunction,
  makeComponent,
} from 'components/UI/common/TypedTable/renderers/custom/common';

const buttonContainer = css`
  justify-content: center;
  display: flex;
  width: 100%;
`;

export type EditRemoveButtonsProps = {
  canEdit?(row: IRow, rows: IRow[]): boolean;
  canRemove?(row: IRow, rows: IRow[]): boolean;

  onEdit?(row: IRow, rows: IRow[]): void;
  onRemove?(row: IRow, rows: IRow[]): void;

  disabled?: ((row: IRow, rows: IRow[]) => boolean) | boolean;
};

const isFunction = (param: any): param is Function =>
  typeof param === 'function';

export const editRemoveButtons: CustomComponentFunction<EditRemoveButtonsProps> =

    ({
      canEdit = () => true,
      canRemove = () => true,

      onEdit = () => {},
      onRemove = () => {},

      disabled = false,
    }) =>
    ({ row, rows }) => {
      const isDisabled = isFunction(disabled) ? disabled(row, rows) : disabled;

      return (
        <>
          <div className={buttonContainer}>
            {canEdit(row, rows) && (
              <BuButton
                icon
                borderless
                disabled={isDisabled}
                size={BuControlSize.REGULAR}
                onClick={() => onEdit(row, rows)}
              >
                <BuIcon name={BoostUpIcons.Pencil} color="var(--bu-gray-700)" />
              </BuButton>
            )}
            {canRemove(row, rows) && (
              <BuButton
                icon
                borderless
                disabled={isDisabled}
                size={BuControlSize.REGULAR}
                onClick={() => onRemove(row, rows)}
              >
                <BuIcon name={BoostUpIcons.Trash} color="var(--bu-gray-700)" />
              </BuButton>
            )}
          </div>
        </>
      );
    };

export const EditRemoveButtons = makeComponent(editRemoveButtons);
