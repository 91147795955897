import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

import { PipelineStagesDataType } from 'actions/pipelineStagesAction';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import InfoIcon from 'assets/fonts/boostup-icons/badge_info_outline.svg';
import BuDropdown, {
  BuDropdownItem,
  BuDropdownItemContainer,
  getDropdownItemProps,
} from 'components/UI/BuDropdown';
import { TimeFilterForTrends } from 'components/UI/TimeFilter';
import TypedTable, {
  BorderType,
} from 'components/UI/common/TypedTable/TypedTable';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import SeriesLabel from 'components/dashboard/ForecastDashboard/TrackingDashboard/SeriesLabel';
import {
  getChartConfig,
  generateDealsProgressionTable,
  sortStageSequence,
  sortForecastCategorySequence,
  mapSequenceToPoints,
} from 'components/dashboard/ForecastPipelineStage/helpers';
import {
  DropdownLabel,
  WidgetInfoIcon,
  WidgetChart,
  WidgetContainer,
  WidgetControlContainer,
  WidgetControls,
  WidgetLegendContainer,
  tableStyle,
} from 'components/dashboard/ForecastPipelineStage/styles';
import {
  IProps,
  isSankeyNode,
} from 'components/dashboard/ForecastPipelineStage/types';
import { openModal } from 'navigation/utils';

const DataTypes: Record<PipelineStagesDataType, string> = {
  stage: 'Pipeline Stages',
  forecast_category: 'Forecast Category',
};

const ForecastPipelineStage: React.FC<IProps> = ({
  persistModalParams,
  stagesConfig,
  forecastConfiguration,
  pipelineStages,
  pipelineStagesStatus,
  savedPrevValuesDate,
  changeWidgetSinceDate,
  companyCurrency,
  filters,
  businessType,
  localStorageKeyPrefix = '',
  getPipelineStages,
  includeDisabledUsers,
}) => {
  const [dataType, setDataType] = useState<PipelineStagesDataType>('stage');

  const isLoading = ['notAsked', 'loading'].includes(pipelineStagesStatus);
  const timeSpan = savedPrevValuesDate?.[0] || 'L7D';

  useEffect(() => {
    getPipelineStages({
      ...filters,
      time_span: timeSpan,
      business_type_name: businessType || '',
      sankey_field: dataType,
      include_disabled: includeDisabledUsers,
    });
  }, [
    timeSpan,
    businessType,
    dataType,
    includeDisabledUsers,
    JSON.stringify(filters),
  ]);

  const handleDealsModal = useCallback(
    (title: string, ids: string[]) => {
      openModal({
        scheme: '/deals',
        params: {
          localStorageKeyPrefix,
        },
        persistParams: {
          title: title,
          isDealsDelta: true,
          filters: JSON.stringify({
            ids,
            opportunity_stages: [],
            forecast_category_names: [],
            time_span: timeSpan,
            change_interval: timeSpan,
            is_forecast: true,
            close_date_interval: ['TQU'],
            business_type_name: businessType,
            skip_business_validation: true,
            include_disabled: includeDisabledUsers,
          }),
        },
        persistor: persistModalParams,
      });
    },
    [persistModalParams, timeSpan, businessType, includeDisabledUsers]
  );

  const sortedSequence = useMemo(
    () =>
      dataType === 'stage'
        ? sortStageSequence(stagesConfig)
        : sortForecastCategorySequence(forecastConfiguration),
    [dataType, stagesConfig, forecastConfiguration]
  );

  const points = useMemo(
    () => mapSequenceToPoints(sortedSequence),
    [sortedSequence]
  );

  const chartOptions = useMemo(
    () =>
      getChartConfig({
        data: pipelineStages,
        points: points,
        companyCurrency,
        onClickLink(point) {
          const ids = point?.custom?.filters?.ids;
          const title = isSankeyNode(point)
            ? point.name
            : `Conversion to ${point.toNode.name}`;

          if (ids.length) {
            handleDealsModal(title, ids);
          }
        },
      }),
    [pipelineStages, handleDealsModal, points]
  );

  const { rows, columns, topHeaders } = useMemo(
    () =>
      generateDealsProgressionTable(
        pipelineStages,
        sortedSequence,
        timeSpan,
        DataTypes[dataType],
        handleDealsModal
      ),
    [pipelineStages, points]
  );

  return (
    <WidgetContainer>
      <Dimmer.Dimmable dimmed={isLoading}>
        <WidgetControlContainer>
          <WidgetControls>
            <span className="bu-font-heading">
              How deals move through the
              <BuDropdown
                label={<DropdownLabel>{DataTypes[dataType]}</DropdownLabel>}
                borderless
              >
                {(hide) => (
                  <BuDropdownItemContainer
                    style={{ maxHeight: 300 }}
                    className="bu-font-default"
                  >
                    {Object.keys(DataTypes).map((type) => (
                      <BuDropdownItem
                        key={type}
                        {...getDropdownItemProps(
                          type as PipelineStagesDataType,
                          type === dataType,
                          hide,
                          setDataType
                        )}
                      >
                        {DataTypes[type as PipelineStagesDataType]}
                      </BuDropdownItem>
                    ))}
                  </BuDropdownItemContainer>
                )}
              </BuDropdown>
            </span>

            <WidgetInfoIcon>
              <TooltipWrapper
                tooltip={
                  <div style={{ width: '200px', textAlign: 'center' }}>
                    This chart displays the volume of deals moving through Deal
                    Stages or Forecast categories in a given period of time.
                    <br />
                    <br />
                    Deals transitioned to hidden stages are not displayed in
                    this chart.
                  </div>
                }
                position="top center"
              >
                <img src={InfoIcon} alt="info" />
              </TooltipWrapper>
            </WidgetInfoIcon>
          </WidgetControls>

          <WidgetControls>
            <TimeFilterForTrends
              tab=""
              value={timeSpan}
              onChange={(_, value) => changeWidgetSinceDate({ sankey: value })}
            />
          </WidgetControls>
        </WidgetControlContainer>

        <WidgetLegendContainer>
          {points.map((item) => (
            <SeriesLabel
              color={item.color}
              icon={BoostUpIcons.ChartLabelDot}
              key={item.id}
            >
              {item.name}
            </SeriesLabel>
          ))}
        </WidgetLegendContainer>

        <WidgetChart>
          {points.length && (
            <HighchartsReact
              highcharts={Highcharts}
              options={chartOptions}
              immutable={true}
            />
          )}
        </WidgetChart>
        <section className={tableStyle}>
          <TypedTable.Border borders={BorderType.TOP} width="fit-content">
            <TypedTable
              columns={columns}
              data={rows}
              extraHeader={topHeaders}
              stickyHeader
              minWidth="100% + 5px"
              widgetSize
            />
          </TypedTable.Border>
        </section>

        <Dimmer active={isLoading} inverted>
          <Loader />
        </Dimmer>
      </Dimmer.Dimmable>
    </WidgetContainer>
  );
};

export default ForecastPipelineStage;
