import React from 'react';

import BuSelect from 'components/UI/BuSelect';
import {
  NULL_OPERATOR_VALUES,
  SET_OPERATION_DROPDOWN_OPTIONS,
} from 'components/dashboard/Metrics/Create/Conditions/Condition/constants';
import { FixedWidthContainer } from 'components/dashboard/Metrics/Create/Conditions/Condition/styles';
import { HierarchicalUserFilter } from 'components/dashboard/Metrics/common/HierarchicalUserFilter/HierarchicalUserFilter';
import { FlexRow } from 'components/dashboard/Metrics/metrics.styles';
import { BIMetricsFilter } from 'components/dashboard/Metrics/metrics.types';

interface Props {
  filter: Partial<BIMetricsFilter>;
  onOperatorSelect: (operator: string) => void;
  onChange: (value: string[]) => void;
}

export const UserTypeCondition: React.FC<Props> = ({
  filter,
  onOperatorSelect,
  onChange,
}) => {
  const handleChangeUserFilter = (filterValues: string[]) => {
    if (!filterValues.length) {
      onChange(['__all__']);
    } else {
      onChange(filterValues);
    }
  };

  return (
    <FlexRow cssProps={{ gap: '12px' }}>
      <FixedWidthContainer width={100}>
        <BuSelect
          fullWidth
          isLargePlaceholder
          secondary
          placeholder=""
          defaults={[
            filter?.operator
              ? filter.operator
              : SET_OPERATION_DROPDOWN_OPTIONS[0].value,
          ]}
          options={SET_OPERATION_DROPDOWN_OPTIONS}
          onChange={(values: string[]) => {
            onOperatorSelect(values[0]);
          }}
        />
      </FixedWidthContainer>

      {!NULL_OPERATOR_VALUES.includes(filter?.operator ?? '') && (
        <HierarchicalUserFilter
          currentValues={(filter?.value as string[]) || []}
          onChangeUserFilter={handleChangeUserFilter}
        />
      )}
    </FlexRow>
  );
};
