import styled from '@emotion/styled';
import React, { useCallback } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { fontDefault } from 'assets/css/common';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';

type IPaginationProps = {
  pageNumber?: number;
  totalPages: number;
  onChange?(pageNumber: number, pageSize: number): void;
  totalCount?: number;
  showTotalResultsNextToPagination?: boolean;

  /** @deprecated component is no longer allowing changing page size */
  rowPerPage?: number;
  /** @deprecated component is no longer allowing changing page size */
  paginationOptions?: number[];
};

const Container = styled.div`
  display: flex;
`;

const Label = styled.div`
  color: var(--bu-gray-900);
  line-height: 32px;
  margin: 0 8px;
`;

const Pagination: React.FC<IPaginationProps> = ({
  pageNumber = 1,
  totalPages,
  rowPerPage = 10,
  totalCount,
  showTotalResultsNextToPagination,
  onChange = () => {},
}) => {
  const handleClickPrev = useCallback(() => {
    if (pageNumber > 1) {
      onChange(pageNumber - 1, rowPerPage);
    }
  }, [pageNumber, rowPerPage, onChange]);

  const handleClickNext = useCallback(() => {
    if (pageNumber < totalPages) {
      onChange(pageNumber + 1, rowPerPage);
    }
  }, [pageNumber, rowPerPage, totalPages, onChange]);

  return (
    <Container className={fontDefault}>
      {showTotalResultsNextToPagination && totalCount !== undefined && (
        <Label>
          {totalCount} {totalCount !== 1 ? 'Results' : 'Result'}.
        </Label>
      )}
      <Label>
        {pageNumber} of {totalPages}
      </Label>

      <BuButton
        icon
        borderless
        size={BuControlSize.BIG}
        disabled={pageNumber === 1}
        onClick={handleClickPrev}
      >
        <BuIcon name={BoostUpIcons.ChevronLeft} />
      </BuButton>

      <BuButton
        icon
        borderless
        size={BuControlSize.BIG}
        disabled={pageNumber === totalPages || totalPages === 0}
        onClick={handleClickNext}
      >
        <BuIcon name={BoostUpIcons.ChevronRight} />
      </BuButton>
    </Container>
  );
};

export default Pagination;
