import ShowMoreButton from '../common/Buttons/ShowMoreButton';
import MomentsRow from './MomentsRow';
import * as styles from './styles';
import { OwnProps, IPaginationState } from './types';
import classNames from 'classnames';
import { orderBy, take, isEmpty } from 'lodash';
import React, { useState } from 'react';

import { tableSizeStyle } from 'components/UI/common/TypedTable/styles';

const MomentsTable: React.FC<OwnProps> = (props: OwnProps) => {
  const [listState, setListState] = useState<IPaginationState>({
    pageCount: 1,
    pageSize: 5,
  });
  const list = take(props.data, listState.pageCount * listState.pageSize);

  const sortedList = orderBy(list, ['mentioned_at'], ['desc']);

  return (
    <section className={styles.table}>
      <table
        className={classNames(
          styles.table_wrapper,
          styles.table_sticky_header,
          tableSizeStyle,
          'table-compact'
        )}
      >
        <thead className={styles.table_header_box}>
          <tr>
            <th
              className={classNames(
                styles.table_header_column,
                'table-header-label'
              )}
            >
              Content
            </th>
            <th
              className={classNames(
                styles.table_header_column,
                'table-header-label'
              )}
            >
              Topic
            </th>
            <th
              className={classNames(
                styles.table_header_column,
                'table-header-label'
              )}
            >
              Who / When
            </th>
          </tr>
        </thead>
        <tbody>
          {isEmpty(props.data) ? (
            <tr>
              <td className={styles.table_body_empty} colSpan={3}>
                Nothing found
              </td>
            </tr>
          ) : null}
          {sortedList.map((data, index) => (
            <MomentsRow
              data={data}
              dataType={props.dataType}
              key={data.object_id + index}
            />
          ))}
          {props.data.length > sortedList.length && (
            <tr>
              <td colSpan={3} className={styles.section_btn}>
                <ShowMoreButton
                  count={props.data.length}
                  showing={sortedList.length}
                  onClick={() =>
                    setListState((state) => ({
                      ...state,
                      pageCount: state.pageCount + 1,
                    }))
                  }
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </section>
  );
};

export default MomentsTable;
