import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { actions } from 'actions';
import { PersistedTableItem } from 'components/UI/DealsFlatTableTS/Table/types';

export type IState = PersistedTableItem[];

const initialState: IState = [];

const reducer = reducerWithInitialState<IState>(initialState)
  .case(actions.ui.table.persist, (state, payload): IState => {
    const newState = state.filter(
      (el) => el.name !== payload.name || el.type !== payload.type
    );

    return [...newState, payload];
  })
  .case(actions.ui.table.reset, () => initialState);

export const table = { initialState, reducer };
