import BuSkeleton from '.';
import BuSkeletonTableTop from './BuSkeletonTableTop';
import * as styles from './styles';
import { IBuSkeletonTable, TopTablePosition } from './types';
import classNames from 'classnames';
import React, { FC } from 'react';

const BuSkeletonTable: FC<IBuSkeletonTable> = ({
  filledHeader = false,
  borderedHeader = false,
  hasTopTable = false,
  topTablePosition,
  rowsCount = 5,
  rowItem,
  headerItems,
}) => {
  return (
    <>
      {hasTopTable && topTablePosition === TopTablePosition.OUTSIDE && (
        <BuSkeletonTableTop />
      )}
      <div
        className={classNames(styles.tableWrapper, {
          [styles.tableWrapperFilled]: filledHeader,
        })}
      >
        {filledHeader ? (
          <BuSkeleton
            containerClassName={styles.tableHeaderSkeleton}
            height={40}
            width="100%"
          />
        ) : (
          <div
            className={classNames(styles.tableHeader, {
              [styles.tableHeaderBordered]: borderedHeader,
            })}
          >
            <div className={styles.tableHeaderContent}>
              {hasTopTable && topTablePosition === TopTablePosition.INSIDE && (
                <BuSkeletonTableTop hasPadding />
              )}
              {headerItems && headerItems}
            </div>
          </div>
        )}
        <div className={styles.tableBody}>
          {new Array(rowsCount).fill(null).map((_item, index) => (
            <div key={index} className={styles.tableRow}>
              {rowItem ? (
                rowItem
              ) : (
                <BuSkeleton
                  containerClassName={styles.tableHeaderSkeleton}
                  height={10}
                  width="100%"
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default BuSkeletonTable;
