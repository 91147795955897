import { omit } from 'ramda';
import { useCallback, useEffect, useReducer } from 'react';
import { matchPath } from 'react-router-dom';
import {
  DefaultModalConfigType,
  ModalCallbacks,
  ModalParams,
  ModalParamsToParamsMap,
  ModalProps,
  ModalSchemeToCallbacksMap,
  ModalSchemeToPropsMap,
} from './modal.context.types';

import { makeModalPath, makeURL } from 'navigation/scheme.utils';
import { MODALS_SCHEMES, ModalsScheme } from 'navigation/types';
import { history } from 'store/configureStore';

const LOCAL_STORAGE_MODAL_PROPS_KEY = 'modalsPropStorage';

export const changeUrlToOpenModal = (
  scheme: ModalsScheme,
  params: ModalParams | undefined
) => {
  const to = makeURL({ scheme, params });

  if (to) {
    const location = window.location;
    const pathname = location.pathname.replace(
      process.env.REACT_APP_SUBDIRECTORY || '',
      ''
    );

    history.push({
      ...location,
      pathname: makeModalPath(pathname, `~${to}`),
    });
  }
};

export const getWhichModalsAreOpen = (fullPathname: string): ModalsScheme[] => {
  const pathname = fullPathname.replace(
    process.env.REACT_APP_SUBDIRECTORY || '',
    ''
  );
  const modalsPath = pathname.split('~').slice(1);

  if (modalsPath.length === 0) {
    return [];
  }

  return MODALS_SCHEMES.filter((scheme) =>
    modalsPath.some((path) => {
      const pathMatchedScheme = matchPath(path, {
        path: scheme,
        exact: true,
      });

      return !!pathMatchedScheme;
    })
  );
};

type ModalState = {
  modalCallbacks: ModalSchemeToCallbacksMap;
  modalProps: ModalSchemeToPropsMap;
  modalParams: ModalParamsToParamsMap;
};

const initialState: ModalState = {
  modalCallbacks: {} as ModalSchemeToCallbacksMap,
  modalProps: {} as ModalSchemeToPropsMap,
  modalParams: {} as ModalParamsToParamsMap,
};

type StoreConfigAction = {
  type: 'STORE_CONFIG';
  payload: {
    scheme: ModalsScheme;
    props?: ModalProps;
    params?: ModalParams;
  };
};

type RemoveConfigAction = {
  type: 'REMOVE_CONFIG';
  payload: {
    scheme: ModalsScheme;
  };
};

type UpdatePropsAction = {
  type: 'UPDATE_PROPS';
  payload: {
    scheme: ModalsScheme;
    newProps: ModalProps;
  };
};

type SetCallbacksAction = {
  type: 'SET_CALLBACKS';
  payload: {
    scheme: ModalsScheme;
    callbacks: ModalCallbacks;
  };
};

type ModalAction =
  | StoreConfigAction
  | RemoveConfigAction
  | UpdatePropsAction
  | SetCallbacksAction;

const modalReducer = (state: ModalState, action: ModalAction): ModalState => {
  switch (action.type) {
    case 'STORE_CONFIG':
      const { scheme, props, params } = action.payload;
      return {
        ...state,
        modalProps: props
          ? { ...state.modalProps, [scheme]: props }
          : state.modalProps,
        modalParams: params
          ? { ...state.modalParams, [scheme]: params }
          : state.modalParams,
      };
    case 'REMOVE_CONFIG':
      return {
        ...state,
        modalCallbacks: omit(
          [action.payload.scheme],
          state.modalCallbacks
        ) as ModalSchemeToCallbacksMap,
        modalProps: omit(
          [action.payload.scheme],
          state.modalProps
        ) as ModalSchemeToPropsMap,
        modalParams: omit(
          [action.payload.scheme],
          state.modalParams
        ) as ModalParamsToParamsMap,
      };
    case 'UPDATE_PROPS':
      return {
        ...state,
        modalProps: {
          ...state.modalProps,
          [action.payload.scheme]: {
            ...state.modalProps[action.payload.scheme],
            ...action.payload.newProps,
          },
        },
      };
    case 'SET_CALLBACKS':
      return {
        ...state,
        modalCallbacks: {
          ...state.modalCallbacks,
          [action.payload.scheme]: action.payload.callbacks,
        },
      };
    default:
      return state;
  }
};

const getInitialStateFromStorage = (): ModalState => {
  const storedProps = localStorage.getItem(LOCAL_STORAGE_MODAL_PROPS_KEY);
  const initialModalProps = storedProps
    ? JSON.parse(storedProps)
    : initialState.modalProps;

  return {
    ...initialState,
    modalProps: initialModalProps,
  };
};

/**
 * useModalStorageReducer is a custom hook that provides functionalities related to modal data management.
 * It uses a reducer pattern to manage the state and provides actions and selectors to:
 *
 * The state is initialized using the `getInitialStateFromStorage` function, which retrieves the initial state from
 * local storage, the state is saved back to local storage whenever it changes, ensuring that modal configurations persist on page reloads.
 *
 * It uses a reducer pattern to manage the state and provides actions and selectors to:
 *
 * 1. Store configuration data for a given modal scheme.
 * 2. Remove configuration data for a given modal scheme.
 * 3. Set callbacks for a given modal scheme.
 * 4. Update properties for a given modal scheme.
 * 5. Retrieve various pieces of stored data like callbacks, properties, and parameters for a specific modal scheme.
 *
 *
 */
const useModalStorageReducer = () => {
  const [state, dispatch] = useReducer(
    modalReducer,
    null,
    getInitialStateFromStorage
  );

  const storeConfigForScheme = useCallback(
    (
      scheme: ModalsScheme,
      config: Omit<DefaultModalConfigType, 'scheme' | 'callbacks'>
    ) => {
      const { props, params } = config;
      dispatch({
        type: 'STORE_CONFIG',
        payload: {
          scheme,
          props,
          params,
        },
      });
    },
    []
  );

  const removeConfigForScheme = useCallback((scheme: ModalsScheme) => {
    dispatch({
      type: 'REMOVE_CONFIG',
      payload: {
        scheme,
      },
    });
  }, []);

  const updatePropsForScheme = useCallback(
    (scheme: ModalsScheme, newProps: ModalProps) => {
      dispatch({
        type: 'UPDATE_PROPS',
        payload: {
          scheme,
          newProps,
        },
      });
    },
    []
  );

  const setCallbacksForScheme = useCallback(
    (scheme: ModalsScheme, callbacks: ModalCallbacks) => {
      dispatch({
        type: 'SET_CALLBACKS',
        payload: {
          scheme,
          callbacks,
        },
      });
    },
    []
  );

  const getPropsForScheme = useCallback(
    (scheme: ModalsScheme) => state.modalProps[scheme],
    [state.modalProps]
  );

  const getOnCloseCallbackForScheme = useCallback(
    (scheme: ModalsScheme) => () =>
      state.modalCallbacks[scheme]?.onClose?.(getPropsForScheme(scheme)),
    [state.modalCallbacks, getPropsForScheme]
  );

  const getParamsForScheme = useCallback(
    (scheme: ModalsScheme) => state.modalParams[scheme],
    [state.modalParams]
  );

  const getCallbacksForScheme = useCallback(
    (scheme: ModalsScheme) => omit(['onClose'], state.modalCallbacks[scheme]),
    [state.modalCallbacks]
  );

  useEffect(() => {
    localStorage.setItem(
      LOCAL_STORAGE_MODAL_PROPS_KEY,
      JSON.stringify(state.modalProps)
    );
  }, [state.modalProps]);

  return {
    state,
    storeConfigForScheme,
    removeConfigForScheme,
    updatePropsForScheme,
    setCallbacksForScheme,
    getOnCloseCallbackForScheme,
    getPropsForScheme,
    getParamsForScheme,
    getCallbacksForScheme,
  };
};

export const clearModalStorage = () =>
  localStorage.removeItem(LOCAL_STORAGE_MODAL_PROPS_KEY);

export default useModalStorageReducer;
