import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  GetAccountsResponse,
  UpdateAccountParams,
  updateAccount,
} from 'api/Accounts';
import { set } from 'lodash';

export const useUpdateAccountMutation = (keyToUpdate: QueryKey) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (update: UpdateAccountParams) => updateAccount(update),
    onMutate: async (update) => {
      await queryClient.cancelQueries(keyToUpdate);

      const previousData =
        queryClient.getQueryData<GetAccountsResponse>(keyToUpdate);

      if (!previousData) {
        return;
      }

      const accountBeingUpdated = previousData?.accounts.find(
        (item) => item._id === update.id
      );

      if (!accountBeingUpdated) {
        return;
      }

      const optimisticUpdate = {
        ...accountBeingUpdated,
      };

      Object.entries(update.editedFields).forEach(([key, value]) => {
        set(optimisticUpdate, key, value);
      });

      const newData = {
        ...previousData,
        accounts: previousData.accounts.map((account) =>
          account._id === optimisticUpdate._id ? optimisticUpdate : account
        ),
      };

      queryClient.setQueryData(keyToUpdate, newData);

      return { previousData };
    },
    onSettled: () => {
      queryClient.invalidateQueries(keyToUpdate);
    },
    onError: (_err, _variables, context) => {
      const previousData = (
        context as {
          previousData?: GetAccountsResponse;
        }
      )?.previousData;

      if (previousData) {
        queryClient.setQueryData(keyToUpdate, previousData);
      }
    },
  });
};
