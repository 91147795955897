import React, { useState, useCallback, useRef } from 'react';

type DispatchDelayed<A> = (value: A, milliseconds: number) => void;

function useStateDelayed<S>(
  initialState: S | (() => S)
): [S, DispatchDelayed<React.SetStateAction<S>>] {
  const [value, setValue] = useState(initialState);
  const timerRef = useRef<number>();

  const setStateDelayed: DispatchDelayed<React.SetStateAction<S>> = useCallback(
    (state, milliseconds) => {
      if (timerRef.current) {
        window.clearTimeout(timerRef.current);
      }
      if (milliseconds <= 0) {
        setValue(state);
      } else {
        timerRef.current = window.setTimeout(
          () => setValue(state),
          milliseconds
        );
      }
    },
    [setValue, timerRef]
  );

  return [value, setStateDelayed];
}

export default useStateDelayed;
