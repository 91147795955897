import { QUARTER_DELIMITER_COLUMN } from './styles';
import classNames from 'classnames';

import { multiplierFormatter } from 'components/UI/TableConfig/column-helper';
import {
  IColumn,
  IRow,
  SortOrder,
  TypedTableCellConfig,
} from 'components/UI/common/TypedTable/TypedTable';
import { currencyFormatter } from 'components/UI/common/TypedTable/formatters';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { Target, Targets } from 'reducers/sellerTargetsReducer/types';
import { calcFiscalMonthName } from 'utils/dates';

type GetColumnsFunction = (
  isQuarterly: boolean,
  targets: Targets,
  fiscalYear: string,
  selectedBusinessType: string,
  currentFiscalYear: number,
  currentFiscalQuarter: number,
  companyCurrency: string,
  isCoverage: boolean
) => {
  columns: IColumn[];
  extraHeader: IColumn[];
};

export const getTargetKey = (
  isQuarterly: boolean,
  period: Target['quarter'],
  fiscalYear: string,
  businessType?: string
) => {
  const label = (isQuarterly ? 'Q' : 'M') + period;

  return businessType
    ? `${label} ${fiscalYear}|${businessType}`
    : `${label} ${fiscalYear}`;
};

const getTargetColumn = (
  isQuarterly: boolean,
  isCoverage: boolean,
  companyCurrency: string,
  period: number | undefined,
  label: string,
  className: string,
  fiscalYear: string,
  currentYear: string,
  businessType: string
): IColumn => ({
  align: 'right',
  config: {
    isCoverage,
    isMoney: true,
    formatter: (isCoverage
      ? multiplierFormatter
      : currencyFormatter(
          companyCurrency,
          0
        )) as TypedTableCellConfig['formatter'],
    className,
  },
  field: getTargetKey(isQuarterly, period, fiscalYear, businessType),
  id: getTargetKey(isQuarterly, period, fiscalYear, businessType),
  [isQuarterly ? 'quarter' : 'month']: period,
  editable: true,
  sort_order: SortOrder.ASCENDING,
  sortable: false,
  type: ColumnTypes.MONEY,
  ...(businessType
    ? {
        business_type: businessType,
        label: `${businessType}`,
      }
    : {
        label: `${label} ${currentYear} ${fiscalYear}`,
      }),
});

export const getColumns: GetColumnsFunction = (
  isQuarterly,
  targets,
  fiscalYear,
  selectedBusinessType,
  currentFiscalYear,
  currentFiscalQuarter,
  companyCurrency,
  isCoverage
) => {
  let columns: IColumn[] = [];
  let extraHeader: IColumn[] = [];
  const currentYear = String(currentFiscalYear) === fiscalYear ? 'CFY' : 'FY';
  const overallTargets = targets.overall_targets || [];

  if (overallTargets.length) {
    columns.push({
      id: 'user',
      label: 'Users',
      field: 'user',
      type: ColumnTypes.TEXT,
      sortable: false,
      sort_order: SortOrder.ASCENDING,
      config: {},
    });

    extraHeader.push({
      id: '',
      label: '',
      field: '',
      type: ColumnTypes.TEXT,
      sortable: false,
      sort_order: SortOrder.ASCENDING,
      config: {},
    });

    overallTargets
      // The next two lines ensure the uniqueness of the columns
      .map((item) => (isQuarterly ? item.quarter : item.month))
      .filter((value, index, self) => self.indexOf(value) === index)
      .forEach((period: number | undefined) => {
        const label = isQuarterly
          ? `Q${period}`
          : calcFiscalMonthName(1, period || 1, 'short');

        const className =
          isQuarterly && currentFiscalQuarter === period ? 'current' : '';

        columns.push(
          getTargetColumn(
            isQuarterly,
            isCoverage,
            companyCurrency,
            period,
            label,
            className,
            fiscalYear,
            currentYear,
            selectedBusinessType
          )
        );

        extraHeader.push({
          colSpan: 1,
          config: { className },
          field: getTargetKey(isQuarterly, period, fiscalYear),
          id: getTargetKey(isQuarterly, period, fiscalYear),
          label: `${label} ${currentYear} ${fiscalYear}`,
          sort_order: SortOrder.ASCENDING,
          sortable: false,
          type: ColumnTypes.MONEY,
          align: 'right',
        });
      });
  }

  // Adding QUARTER_DELIMITER_COLUMN class to separate vertically the quarters
  if (isQuarterly) {
    let currentQuarter: number;
    columns = columns.map((col) => {
      const { config, quarter } = col;
      let delimiterCls;
      if (currentQuarter !== quarter) {
        currentQuarter = quarter;
        delimiterCls = QUARTER_DELIMITER_COLUMN;
      }

      return {
        ...col,
        config: {
          ...config,
          className: classNames(config.className, delimiterCls),
        },
      };
    });

    extraHeader = extraHeader.map((col) => {
      const { config } = col;
      const className = classNames(config.className, QUARTER_DELIMITER_COLUMN);
      return { ...col, config: { ...config, className } };
    });
  }

  return {
    columns,
    extraHeader,
  };
};

const getTargetRows = (
  isQuarterly: boolean,
  targetList: Target[],
  fiscalYear: string,
  selectedBusinessType: string,
  targetType: string
): IRow[] =>
  targetList.map((target: Target, i: number) => ({
    id: `${targetType}-${i}`,
    targetType,
    user: target.user.name || target.user.email,
    email: target.user.email,
    fiscal_year: fiscalYear,
    ...target.targets?.reduce(
      (acc, item: Target) => ({
        ...acc,
        [getTargetKey(
          isQuarterly,
          isQuarterly ? item.quarter : item.month,
          fiscalYear,
          selectedBusinessType
        )]: item.target,
        ...(isQuarterly ? { quarter: item.quarter } : { month: item.month }),
      }),
      {}
    ),
  }));

const getParentRow = (
  isQuarterly: boolean,
  targetList: Target[],
  fiscalYear: string,
  rows: IRow[],
  selectedBusinessType: string,
  targetType: string
): IRow => {
  const calculatedCustomTotals: { [key: string]: number } = {};

  targetList.forEach((target: Target) => {
    if (target.targets?.length) {
      target.targets.forEach((innerTarget: Target) => {
        const key = getTargetKey(
          isQuarterly,
          isQuarterly ? innerTarget.quarter : innerTarget.month,
          fiscalYear,
          selectedBusinessType
        );

        calculatedCustomTotals[key] = rows.reduce((agg, item) => {
          const value = item[key] as number;
          return isNaN(value) ? agg : agg + value;
        }, 0);
      });
    }
  });

  return {
    id: targetType,
    user: targetType,
    children: rows,
    isTopRow: true,
    disableEdit: true,
    ...calculatedCustomTotals,
  };
};

export const getRows = (
  isQuarterly: boolean,
  targets: Targets,
  fiscalYear: string,
  selectedBusinessType: string
): IRow[] => {
  const overallTargets = targets.overall_targets || [];
  const userTargetsByRole = targets.user_targets_by_role || [];
  const rowOverall: IRow = {
    id: 'overall',
    user: 'Overall',
    isTopRow: true,
    fiscal_year: fiscalYear,
    ...overallTargets.reduce(
      (acc, target: Target) => ({
        ...acc,
        [getTargetKey(
          isQuarterly,
          isQuarterly ? target.quarter : target.month,
          fiscalYear,
          selectedBusinessType
        )]: target.target,
      }),
      {}
    ),
  };

  const allTargets: IRow[] = [];

  userTargetsByRole.forEach((target, index) => {
    const rowsUsers = getTargetRows(
      isQuarterly,
      target.user_targets,
      fiscalYear,
      selectedBusinessType,
      'user'
    );

    const rowTopUsers = getParentRow(
      isQuarterly,
      target.user_targets,
      fiscalYear,
      rowsUsers,
      selectedBusinessType,
      target.role_display_name
    );

    allTargets.push(rowTopUsers);

    if (userTargetsByRole.length - 1 !== index) {
      allTargets.push({
        id: `empty-${target.role_display_name}`,
        isEmpty: true,
      });
    }
  });

  return [rowOverall, { id: 'empty-overall', isEmpty: true }, ...allTargets];
};
