import * as R from 'ramda';
import React from 'react';

import {
  IDataCellProps,
  TypedTableCellConfig,
} from 'components/UI/common/TypedTable/TypedTable';
import LinkCell, {
  LinkCellConfig,
} from 'components/UI/common/TypedTable/renderers/LinkCell';

interface CrmLookupCellConfig extends TypedTableCellConfig {
  sfBaseUrl: string;
}

const CrmLookupCell: React.FC<IDataCellProps> = ({
  column: passedColumn,
  row,
  ...passedProps
}) => {
  const { sfBaseUrl, ...passedConfig } =
    (passedColumn.config as CrmLookupCellConfig) || {};

  const cellValue = R.path(passedColumn.field.split('.'), row) as string;

  const config = {
    ...passedConfig,
    link_pattern: cellValue
      ? `${sfBaseUrl}/${encodeURIComponent(cellValue)}`
      : '',
  } as LinkCellConfig;

  const column = {
    ...passedColumn,
    config,
  };

  return <LinkCell {...passedProps} column={column} row={row} />;
};

export default CrmLookupCell;
