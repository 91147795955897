import styled from '@emotion/styled';

const BuDropdownItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: auto;
  position: relative;

  .bu-search {
    width: 100%;
  }

  .bu-search-label {
    border: none;
  }
`;

export default BuDropdownItemContainer;
