import styled from '@emotion/styled';

export const MetricPreviewColumn = styled.div({
  flexGrow: 1,
  background: 'var(--bu-gray-200)',
  alignItems: 'center',
  padding: '24px 48px',
  overflow: 'auto',
});

export const PreviewNoteParagraph = styled.p({
  color: 'black',
  fontWeight: 400,
  fontSize: '12px',
  textAlign: 'center',
  marginTop: '16px',
  marginBottom: '48px !important',
});

export const MetricPreviewContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
});
