/* eslint-disable camelcase */
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';

import { notNullAndEmpty } from 'common/utils';
import * as styles from 'components/UI/InlineSearch/styles';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import * as selectors from 'selectors/search';

const handleClick = (e, type, value, onResultClick) => {
  if (e.button === 0) {
    onResultClick(type, value);
  }
};

function getOrder(type) {
  switch (type) {
    case 'opportunities':
      return 1;
    case 'accounts':
      return 2;
    case 'calls':
      return 3;
    case 'meetings':
      return 4;
    default:
      return 0;
  }
}

class CategorizedSearchResults extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static getRiskScoreString(score) {
    if (score != null && score > 80) {
      return 'High';
    }

    if (score != null && score >= 50 && score <= 80) {
      return 'Medium';
    }

    if (score != null && score < 50) {
      return 'Low';
    }
    return false;
  }

  static getRiskScore(score, withComma) {
    const stringRiskScore = CategorizedSearchResults.getRiskScoreString(score);
    let color = '';

    switch (stringRiskScore) {
      case 'High':
        color = 'var(--bu-red-400)';
        break;
      case 'Medium':
        color = 'var(--bu-orange-500)';
        break;
      case 'Low':
        color = 'var(--bu-green-500)';
        break;
      default:
        color = 'var(--bu-green-500)';
    }
    return (
      <Fragment>
        <span>Risk Score: </span>
        <span style={{ color }}>{`${score} ${stringRiskScore}`}</span>
        <span>{withComma ? '.' : ''}</span>
      </Fragment>
    );
  }

  renderAccRiskScore(typeName, value) {
    const { suggestions } = this.props;

    if (typeName === 'accounts' && !_.isEmpty(suggestions)) {
      const acc = suggestions.accounts.find((el) => el.id === value);
      if (acc) {
        return (
          <div className={styles.descField}>
            {CategorizedSearchResults.getRiskScore(acc.risk_score)}
          </div>
        );
      }
    }
  }

  renderOpportunitiesDesc(typeName, value) {
    const { suggestions } = this.props;

    if (typeName === 'opportunities' && !_.isEmpty(suggestions)) {
      const acc = suggestions.opportunities.find((el) => el.id === value);
      return (
        <div className={styles.descTitle}>
          <span className={styles.descField}>
            {CategorizedSearchResults.getRiskScore(acc.risk_score, true)}
          </span>
          <span className={styles.descField}>{`AE: ${acc.user_name}.`}</span>
          <span
            className={styles.descField}
          >{`Close Date: ${acc.close_date}.`}</span>
          <span
            className={styles.descField}
          >{`Stage: ${acc.opportunity_stage}`}</span>
        </div>
      );
    }
  }

  render() {
    const { results, onResultClick } = this.props;

    return R.compose(
      R.map((el) => el.component),
      R.sortBy(R.prop('order')),
      R.values,
      R.mapObjIndexed((typeResults, typeName) => {
        return {
          order: getOrder(typeName),
          component: (
            <Fragment key={typeName}>
              <div className={styles.resultType}>{typeName}</div>
              {typeResults.map(
                ({
                  value,
                  type,
                  title,
                  description,
                  warning,
                  icon,
                  shouldLink,
                  activity_sync_info,
                }) => (
                  <div
                    key={value}
                    className={classNames({
                      [styles.resultItem]: true,
                      [styles.notLinked]: !shouldLink,
                    })}
                    role="none"
                    onMouseDown={
                      shouldLink
                        ? (e) => handleClick(e, type, value, onResultClick)
                        : (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }
                    }
                  >
                    <div className={styles.resultItemIcon}>
                      <img src={icon} alt="" />
                    </div>
                    <div className={styles.resultItemDescription}>
                      <div className={styles.resultItemTitle} title={title}>
                        {notNullAndEmpty(activity_sync_info) ? (
                          <Icon name="warning" color="red" />
                        ) : null}
                        {`${title}`}
                      </div>

                      <TooltipWrapper
                        tooltip={
                          <div className={styles.resultItemTooltip}>
                            {this.renderAccRiskScore(typeName, value)}
                            {this.renderOpportunitiesDesc(typeName, value)}
                            {description}
                          </div>
                        }
                        position="left center"
                      >
                        <div className={styles.resultItemType}>
                          {this.renderAccRiskScore(typeName, value)}
                          {this.renderOpportunitiesDesc(typeName, value)}
                          {description}
                        </div>
                      </TooltipWrapper>

                      {warning ? (
                        <span className={styles.resultItemWarning}>
                          {warning}
                        </span>
                      ) : null}
                    </div>
                  </div>
                )
              )}
            </Fragment>
          ),
        };
      })
    )(results);
  }
}

const mapStateToProps = (state) => ({
  suggestions: selectors.getSearchSuggestions(state),
});

CategorizedSearchResults.propTypes = {
  results: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any.isRequired,
        type: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string,
        warning: PropTypes.string,
        icon: PropTypes.string.isRequired,
        shouldLink: PropTypes.bool.isRequired,
      })
    )
  ),
  onResultClick: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, null)(CategorizedSearchResults);
