import { DOWN, UP } from '../constants';
import clx from 'classnames';
import React from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import * as s from 'components/settings/UserProfiles/BusinessTypes/UpDownButtons/styles';

const UpDownButtons = ({
  position,
  isFirst,
  isLast,
  onMoveItem,
}: {
  position: string;
  isFirst: boolean;
  isLast: boolean;
  onMoveItem: (position: string, direction: string) => void;
}) => {
  return (
    <div className={s.btn_arrows}>
      <div
        className={clx(s.btn_up, { is_first: isFirst })}
        onClick={() => onMoveItem(position, UP)}
      >
        <BuIcon name={BoostUpIcons.ChevronUp} />
      </div>
      <div
        className={clx(s.btn_down, { is_last: isLast })}
        onClick={() => onMoveItem(position, DOWN)}
      >
        <BuIcon name={BoostUpIcons.ChevronDown} />
      </div>
    </div>
  );
};

export default UpDownButtons;
