import Highcharts from 'highcharts';

import { SankeyColumn } from 'common/highcharts-mixins/highcharts-sankey-node-order';

export type SankeyStackLabelsOptions = {
  enabled?: boolean;
  formatter: Highcharts.FormatterCallbackFunction<SankeyColumn>;
  position?: 'top' | 'bottom';
  style?: Record<string, any>;
};

/**
 * Highcharts mixin for Sankey chart, it is allowing to add extra data label below/above node group
 */
export default function (H: any) {
  H.wrap(
    H.seriesTypes.sankey.prototype,
    'render',
    function (this: any, proceeds: any) {
      proceeds.apply(this, Array.prototype.slice.call(arguments, 1));

      const stacking = this;
      const chart = this.chart;
      const renderer = chart.renderer;
      const stackLabelsOption = this.options
        ?.stackLabels as SankeyStackLabelsOptions;
      const enabled = stackLabelsOption?.enabled ?? false;
      const formatter = stackLabelsOption?.formatter ?? String;
      const position = stackLabelsOption?.position ?? 'top';
      const style = stackLabelsOption?.style;

      if (!enabled) {
        return;
      }

      const stackTotalGroup = (stacking.stackTotalGroup =
        stacking.stackTotalGroup ||
        renderer
          .g('stack-labels')
          .attr({
            visibility: 'visible',
            zIndex: 6,
            opacity: 0,
          })
          .add());

      stackTotalGroup.translate(chart.plotLeft, chart.plotTop);

      if (!stackTotalGroup.sankeyStackLabels) {
        stackTotalGroup.sankeyStackLabels = [];
      }

      stackTotalGroup.sankeyStackLabels.forEach((label: any) => {
        if (label.className === 'sankey-stack-label') {
          label.destroy();
        }
      });

      this.nodeColumns.forEach((column: SankeyColumn) => {
        const bBoxes = column
          .map(
            (item) =>
              (item as any).graphic?.getBBox() as
                | Highcharts.BBoxObject
                | undefined
          )
          .filter((item) => !!item);

        if (!bBoxes.length) {
          return;
        }

        const [minY, maxY] = bBoxes.reduce(
          (acc, nodeBBox) => {
            return [
              Math.min(acc[0], nodeBBox?.y ?? 0),
              Math.max(acc[1], (nodeBBox?.y ?? 0) + (nodeBBox?.height ?? 0)),
            ];
          },
          [Infinity, 0]
        );

        const label = chart.renderer
          .label(
            formatter.call(column),
            null,
            null,
            false,
            null,
            null,
            false,
            false,
            'sankey-stack-label'
          )
          .css(style);

        if (!label.added) {
          label.add(stackTotalGroup); // add to the labels-group

          stackTotalGroup.sankeyStackLabels.push(label);

          const labelBBox = label.getBBox() as Highcharts.BBoxObject;

          const nodeBBox = (
            column.find((c: any) => !!c.graphic) as any
          )?.graphic?.getBBox() as Highcharts.BBoxObject | undefined;

          if (nodeBBox) {
            if (position === 'top') {
              label.attr({
                x: nodeBBox.x + nodeBBox.width / 2,
                y: minY - labelBBox.height,
                'text-anchor': 'middle',
              });
            } else {
              label.attr({
                x: nodeBBox.x + nodeBBox.width / 2,
                y: maxY + 15,
                'text-anchor': 'middle',
              });
            }

            label.show();
          }
        }
      });

      stackTotalGroup.animate(
        {
          opacity: 1,
        },
        {}
      );
    }
  );
}
