import { css } from 'emotion';

export const styles = {
  header: css`
    color: var(--bu-gray-700) !important;
  `,
  header_title: css`
    color: var(--bu-gray-900);
    font-size: 14px;
  `,
  popup_window: css`
    max-height: 300px;
    overflow-y: auto;
    padding-top: 4px;
  `,
  indicator: css`
    border-radius: 15px;
    padding: 4px 10px;
    font-family: var(--bu-font-regular);
    font-size: 14px;
    display: inline-block;
    margin-bottom: 7px;
    margin-right: 7px;
    position: relative;
  `,
  indicator_red: css`
    background: #fce3dc;
    border: 1px solid #ee724e;
  `,
  indicator_green: css`
    background: #cef0ef;
    border: 1px solid #47ccca;
  `,
};

export default styles;
