import classNames from 'classnames';
import { isString, isArray, isNil } from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  formatTo,
  selectTimeRange,
} from 'sharedLogicMainAppAndRevBi/timeSpanOptions';
import { timeSpanOptions } from 'sharedLogicMainAppAndRevBi/timeSpanOptions';

import IconCalendar from 'assets/images/icons/calendar_small.svg';
import CustomRangeTab from 'components/UI/TimeFilter/TabsForFilter/CustomRangeTab';
import PredefinedTab from 'components/UI/TimeFilter/TabsForFilter/PredefinedTab';
import { BetweenDate } from 'components/UI/TimeFilter/TabsForFilter/types';
import * as s from 'components/UI/TimeFilter/styles';
import {
  ITimeFilterProps,
  LocalTab,
  TimeFilterContentProps,
} from 'components/UI/TimeFilter/types';
import { getFiscalYearStartDate } from 'selectors';
import BuIcon from '../BuIcon';
import { BoostUpIcons } from 'assets/css/boostup-icons';

const tabsForFilter = ['Predefined', 'Custom Range'] as const;
const defaultBtnLabel = 'Time Filter';

export const TimeFilterContent: React.FC<TimeFilterContentProps> = ({
  config,
  onChangeLocalTab,
  localTab,
  tab,
  value,
  onChange,
  betweenDate,
  setBetweenDate,
  noFutureTime,
  showOtherOptions,
  onClose,
}) => (
  <>
    {(config?.customStart || config?.customEnd) && (
      <div className={s.time_filter_dropdown_tabs}>
        {tabsForFilter.map((tab) => (
          <div
            key={tab}
            onClick={() => onChangeLocalTab(tab)}
            className={classNames(s.time_filter_dropdown_tab, {
              borderRight: tab === localTab && localTab === 'Predefined',
              boderLeft: tab === localTab && localTab === 'Custom Range',
            })}
          >
            {tab}
          </div>
        ))}
      </div>
    )}
    {localTab === 'Predefined' && (
      <PredefinedTab
        tab={tab}
        config={config}
        value={value}
        noFutureTime={noFutureTime}
        showOtherOptions={showOtherOptions}
        onChange={onChange}
        onClose={onClose}
      />
    )}
    {localTab === 'Custom Range' && (
      <CustomRangeTab
        tab={tab}
        config={config}
        onChange={onChange}
        betweenDate={betweenDate}
        setBetweenDate={setBetweenDate}
        noFutureTime={noFutureTime}
      />
    )}
  </>
);

const TimeFilter: React.FC<ITimeFilterProps> = ({
  label,
  onChange,
  tab,
  value,
  size = 'small',
  noFutureTime,
  showOtherOptions,
  preBtnFilterLabel,
  customTooltipMessage,
}) => {
  const fiscalYearStartDate = useSelector(getFiscalYearStartDate);

  const [activeValue, setActiveValue] = useState<string>('');
  const [isOpenFilter, setOpenFilter] = useState<boolean>(false);
  const [localTab, setLocalTab] = useState<LocalTab>('Predefined');
  const [betweenDate, setBetweenDate] = useState<BetweenDate>({
    startDate: '',
    endDate: '',
  });
  const refPopupFilter = useRef<HTMLDivElement>(null);
  const refBtnFilter = useRef<HTMLButtonElement>(null);

  const isDashboard = tab.includes('_dashboard') || noFutureTime;

  // useEffect for update value
  useEffect(() => {
    const format = (date: string) => formatTo(date, 'YYYY-MM-DD', 'MM/DD/YYYY');

    if (isString(value) && value.length === 21) {
      const dates = value.split(',');

      setLocalTab('Custom Range');
      setBetweenDate({
        startDate: format(dates[0]),
        endDate: format(dates[1]),
      });
      setActiveValue(value);
    } else if (value && value[0] && value[0].length >= 10) {
      const dates = value[0].split(',');

      setBetweenDate({
        startDate: format(dates[0]),
        endDate: format(dates[1]),
      });
    } else {
      const date = selectTimeRange(
        isArray(value) ? value : [value],
        fiscalYearStartDate,
        isDashboard
      );

      setBetweenDate({
        startDate: date ? format(Object.keys(date[0])[0]) : '',
        endDate: date ? format(date[0][Object.keys(date[0])[0]]) : '',
      });
    }

    setActiveValue(isArray(value) ? value[0] : value);
  }, [value]);

  // useEffet for close if click outside popup
  useEffect(() => {
    document.addEventListener('mousedown', (e) => handleClickOutside(e));

    return () => {
      document.removeEventListener('mousedown', (e) => handleClickOutside(e));
    };
  }, [refPopupFilter]);

  const handleClickOutside = (e: Event) => {
    const refPopup = refPopupFilter.current;
    const refButton = refBtnFilter.current;

    if (
      !isNil(refPopup) &&
      !refPopup.contains(e.target as Node) &&
      !isNil(refButton) &&
      !refButton.contains(e.target as Node)
    ) {
      setOpenFilter(false);
    }
  };

  const toggleHandler = () => {
    setOpenFilter(!isOpenFilter);
  };

  const labelButton = () => {
    if (activeValue) {
      const options = [
        ...timeSpanOptions.timeSpanPresent,
        ...timeSpanOptions.timeSpanFuture,
        ...timeSpanOptions.timeSpanPast,
        ...timeSpanOptions.timeSpanOther,
      ];
      const timeSpan = options.find((el) => activeValue.includes(el.value));

      const rangeDate =
        activeValue.length !== undefined && activeValue.length > 10
          ? `${betweenDate.startDate.slice(0, -4)}${betweenDate.startDate.slice(
              -2
            )} -
              ${betweenDate.endDate.slice(0, -4)}${betweenDate.endDate.slice(
              -2
            )}`
          : activeValue[0];

      return timeSpan ? timeSpan.text : rangeDate;
    }

    return defaultBtnLabel;
  };

  const btnFilterLabel = labelButton();

  const tooltipMessage = () => {
    if (isDashboard) {
      return `Changes in ${btnFilterLabel}`;
    }
    if (!isDashboard && btnFilterLabel !== 'Closing in the Future') {
      return `Closing in ${btnFilterLabel}`;
    } else {
      return btnFilterLabel;
    }
  };

  const btnTooltip = customTooltipMessage
    ? customTooltipMessage(btnFilterLabel)
    : tooltipMessage();

  return (
    <div className={s.time_filter_wrapper}>
      <div>
        {label && <span className={s.time_filter_label}>{label}</span>}
        <button
          ref={refBtnFilter}
          type="button"
          className={classNames(s.time_filter_btn(size), {
            open: isOpenFilter,
          })}
          onClick={toggleHandler}
        >
          <img src={IconCalendar} alt="calendar icons" />
          {preBtnFilterLabel}
          {btnFilterLabel}
          <BuIcon
            name={
              isOpenFilter ? BoostUpIcons.TriangleUp : BoostUpIcons.TriangleDown
            }
          />
        </button>
      </div>

      {isOpenFilter && (
        <div ref={refPopupFilter} className={s.time_filter_dropdown}>
          <div className={s.time_filter_dropdown_wrap}>
            <TimeFilterContent
              betweenDate={betweenDate}
              localTab={localTab}
              onChange={(value) => onChange([value])}
              onChangeLocalTab={setLocalTab}
              setBetweenDate={setBetweenDate}
              tab={tab}
              value={value}
              noFutureTime={noFutureTime}
              showOtherOptions={showOtherOptions}
              onClose={setOpenFilter}
            />
          </div>
        </div>
      )}
      {!isOpenFilter && <small className={s.tooltip}>{btnTooltip}</small>}
    </div>
  );
};

export default TimeFilter;
