import { css } from 'emotion';

import { IColumn, SortOrder } from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { CustomCellConfig } from 'components/UI/common/TypedTable/renderers/CustomCell';
import { editRemoveButtons } from 'components/UI/common/TypedTable/renderers/custom';
import UserProfileName from 'components/settings/UserProfiles/UserProfileName';
import { IGetColumnsConfig } from 'components/settings/UserProfiles/types';

const commonCellClassName = css`
  display: table-cell;
  vertical-align: middle;
  height: 42px !important;
  font-size: 14px;
`;

const defaults = {
  id: '',
  delta: false,
  editable: false,
  resize: false,
  sort_field: null,
  sort_order: SortOrder.DESCENDING,
  sortable: true,
  type: ColumnTypes.TEXT,
  config: {
    className: commonCellClassName,
  },
};

export const getColumnsConfig = (actions: IGetColumnsConfig): IColumn[] =>
  [
    {
      ...defaults,
      field: '',
      label: 'Actions',
      sortable: false,
      type: ColumnTypes.CUSTOM,
      width: 80,
      maxWidth: 80,
      minWidth: 80,
      config: {
        renderer: editRemoveButtons({
          onEdit: actions.editProfile,
          onRemove: actions.deleteProfile,
        }),
        className: 'actionColumn',
      } as CustomCellConfig,
    },
    {
      ...defaults,
      field: 'name',
      label: 'Name',
      type: ColumnTypes.CUSTOM,
      config: {
        className: 'user-profile-cell',
        renderer: UserProfileName,
      },
    },
    {
      ...defaults,
      field: 'description',
      label: 'Description',
      type: ColumnTypes.TEXT,
      minWidth: 150,
    },
    {
      ...defaults,
      field: 'last_updated',
      label: 'Last Updated',
      type: ColumnTypes.DATE,
      config: {
        ...defaults.config,
        format: 'MMM D, h:mma',
      },
    },
    {
      ...defaults,
      field: 'total_users',
      label: 'Users associated',
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.TEXT,
      minWidth: 150,
    },
  ].map((col) => ({ ...col, id: col.field }));
