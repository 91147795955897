import { combineReducers } from 'redux';

import { metricsReducer } from 'reducers/revbi/metrics';

// import { widgetsReducer } from 'reducers/revbi/widgets';

export const revbiReducer = combineReducers({
  metrics: metricsReducer,
  // widgets: widgetsReducer,
});
