import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import accountsReducer from 'reducers/accountsReducer';
import activityReducer from 'reducers/activityReducer';
import { adminReducer } from 'reducers/adminReducer';
import analyticsReducer from 'reducers/analyticsReducer';
import app from 'reducers/app';
import { appState } from 'reducers/appState';
import buyerEngagementReducer from 'reducers/buyerEngagementReducer';
import {
  cacheReducer,
  dealsIncluded,
  draftTableRows,
} from 'reducers/cacheReducer';
import { callReducer } from 'reducers/callReducer';
import callsReducer from 'reducers/callsReducer';
import callsStatsReducer from 'reducers/callsStatsReducer';
import { companySettingsReducer } from 'reducers/companyUsersReducer';
import { customReportsReducer } from 'reducers/customReportsReducer';
import emailReducer from 'reducers/emailReducer';
import engagementReducer from 'reducers/engagementReducer';
import eventReducer from 'reducers/eventReducer';
import { externalApiReducer } from 'reducers/externalApiReducer';
import { openFilters } from 'reducers/filters/openFilters';
import forecastReducer from 'reducers/forecastReducer';
import forecastSubmissionReducer from 'reducers/forecastSubmissionReducer';
import { forecastWidgetDate } from 'reducers/forecastWidgetsDate';
import { insightsReducer } from 'reducers/insightsReducer';
import loginReducer from 'reducers/loginReducer';
import { modal } from 'reducers/modal';
import dealsReducer from 'reducers/opportunitiesReducer';
import pipelineReducer from 'reducers/pipelineReducer';
import pipelineStagesReducer from 'reducers/pipelineStagesReducer';
import { reportsReducer } from 'reducers/reportsReducer';
import { revbiReducer } from 'reducers/revbi';
import routeReducer from 'reducers/routeReducer';
import searchReducer from 'reducers/searchReducer';
import { targetsReducer } from 'reducers/sellerTargetsReducer/targets';
import { settingsReducer } from 'reducers/settingsReducer';
import signUpReducer from 'reducers/signUpReducer';
import { isSubmitForecast } from 'reducers/submitForecast';
import { table } from 'reducers/table';
import topicsReducer from 'reducers/topicsReducer';
import transcriptReducer from 'reducers/transcriptReducer';
import { userReducer } from 'reducers/userReducer';
import { widget } from 'reducers/widget';
import widgetsReducer from 'reducers/widgetsReducer';

const rootReducer = (history) => (state, action) => {
  if (action.type === 'LOGOUT_SUCCESS') {
    state = undefined;
  }

  if (action.type === 'ADMIN_LOGOUT_SUCCESS') {
    state = {
      admin: state.admin,
      user: state.user,
    };
  }

  return combineReducers({
    analytics: analyticsReducer,
    accounts: accountsReducer,
    activity: activityReducer,
    admin: adminReducer,
    externalApi: externalApiReducer,
    app,
    buyerEngagement: buyerEngagementReducer,
    cache: cacheReducer,
    dealsIncluded: dealsIncluded,
    draftTableRows,
    call: callReducer,
    calls: callsReducer,
    callsStats: callsStatsReducer,
    pipelineStages: pipelineStagesReducer,
    customReports: customReportsReducer,
    deals: dealsReducer,
    email: emailReducer,
    engagement: engagementReducer,
    event: eventReducer,
    forecast: forecastReducer,
    forecastSubmission: forecastSubmissionReducer,
    insights: insightsReducer,
    openFilters: openFilters.reducer,
    login: loginReducer,
    modal: modal.reducer,
    widget: widget.reducer,
    pipeline: pipelineReducer,
    reports: reportsReducer,
    route: routeReducer,
    router: connectRouter(history),
    search: searchReducer,
    sellerTargets: targetsReducer,
    settings: settingsReducer,
    companySettings: companySettingsReducer,
    signUp: signUpReducer,
    table: table.reducer,
    topics: topicsReducer,
    appState: appState.reducer,
    transcript: transcriptReducer,
    user: userReducer,
    widgets: widgetsReducer,
    forecastWidgetDate: forecastWidgetDate.reducer,
    submitForecast: isSubmitForecast,
    revbi: revbiReducer,
  })(state, action);
};

export default rootReducer;
