import { SkeletonProps } from 'react-loading-skeleton';

export enum TopTablePosition {
  INSIDE = 'inside',
  OUTSIDE = 'outside',
}

export interface IBuSkeletonTable {
  filledHeader?: boolean;
  borderedHeader?: boolean;
  hasTopTable?: boolean;
  topTablePosition?: TopTablePosition;
  rowsCount?: number;
  rowItem?: any;
  headerItems?: any;
}

export interface IBuSkeletonTableTop {
  hasPadding?: boolean;
}

export interface IBuSkeleton extends SkeletonProps, Common.DataTestingType {}
