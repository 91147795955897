import React, { useContext } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import BuToggle from 'components/UI/BuToggle';
import { WidgetActions } from 'components/dashboard/Metrics/Widget/Header/Actions/WidgetActions';
import {
  DEFAULT_TITLE,
  PREVIEW_TITLE,
} from 'components/dashboard/Metrics/Widget/Header/constants';
import {
  WidgetTitleRow,
  ActionsWrapper,
  TitleWrapper,
  MetricsToggleLabel,
} from 'components/dashboard/Metrics/Widget/Header/styles';
import { UNTITLED_DASHBOARD } from 'components/dashboard/Metrics/constants';
import BuButtonRefresh from 'components/UI/BuButtonRefresh';
import { RevBISettingsContext } from '../../contexts/RevBISettingsContext';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import { ExclamationIcon } from 'components/dashboard/Metrics/metrics.styles';

export interface RefreshButtonConfiguration {
  showRefreshButton: boolean;
  refreshButtonLoading: boolean;
  disabled: boolean;
  cacheDate?: string;
  targetDate?: number;
  onRefreshData?: () => void;
}
interface Props {
  id?: string;
  name?: string;
  dashboardName?: string;
  tooltip?: JSX.Element;
  isCreateEditMetric?: boolean;
  isDashboardWidget?: boolean;
  isDashboardModal?: boolean;
  isReportViewWidget?: boolean;
  showMetrics?: boolean;
  optionalMetrics?: boolean;
  alternativeVisibility: boolean;
  onEditWidget?: (metricId: string) => void;
  onCloneWidget?: (metricId: string) => void;
  onRemoveWidget?: (metricId: string) => void;
  onTitleClicked?: () => void;
  onCloseWidgetModal?: () => void;
  setShowMetrics?: (checked: boolean) => void;
  refreshButtonConfig?: RefreshButtonConfiguration;
}

export const WidgetHeader: React.FC<Props> = ({
  id,
  name,
  dashboardName = '',
  tooltip,
  isCreateEditMetric = false,
  isDashboardWidget = false,
  isDashboardModal = false,
  isReportViewWidget = false,
  showMetrics = false,
  optionalMetrics = false,
  refreshButtonConfig = {
    showRefreshButton: false,
    refreshButtonLoading: false,
    disabled: false,
    cacheDate: '',
    targetDate: 0,
    onRefreshData: () => {},
  },
  alternativeVisibility = false,
  onEditWidget,
  onCloneWidget,
  onRemoveWidget,
  onTitleClicked,
  onCloseWidgetModal,
  setShowMetrics = () => {},
}) => {
  const { warningElapsedUpdateTimeSeconds } = useContext(RevBISettingsContext);

  const nameBuilder = () => {
    let currentName;

    if (isCreateEditMetric) {
      currentName = PREVIEW_TITLE;
    } else if (isDashboardModal) {
      currentName = (
        <>
          <span>{`${dashboardName || UNTITLED_DASHBOARD} / `}</span> {name}
        </>
      );
    } else if (name) {
      currentName = name;
    } else {
      currentName = DEFAULT_TITLE;
    }

    return currentName;
  };

  return (
    <WidgetTitleRow
      isDashboardModal={isDashboardModal}
      isDashboardWidget={isDashboardWidget}
    >
      <TitleWrapper>
        {isDashboardModal && (
          <BuButton
            borderless
            secondary
            onClick={onCloseWidgetModal}
            testingLabel="close-modal"
          >
            <BuIcon color="black" name={BoostUpIcons.ClosePopup} />
          </BuButton>
        )}

        {alternativeVisibility && (
          <h5>
            alternative view
            <TooltipWrapper
              tooltip={
                <p>
                  This widget is configured using the alternative team
                  visibility feature. This is not your team hierarchy. Any
                  user-based filters in this page will not work on this widget.
                </p>
              }
              position="right center"
            >
              <ExclamationIcon>
                <BuIcon
                  name={BoostUpIcons.BadgeInfoOutline}
                  color="var(--bu-gray-700)"
                />
              </ExclamationIcon>
            </TooltipWrapper>
          </h5>
        )}
        <section>
          <h4 onClick={onTitleClicked} data-testing="widget-title">
            {nameBuilder()}
          </h4>
          {refreshButtonConfig.showRefreshButton && (
            <BuButtonRefresh
              cacheDate={refreshButtonConfig.cacheDate ?? ''}
              onClick={refreshButtonConfig.onRefreshData ?? (() => {})}
              showLastUpdatedMessage={!!refreshButtonConfig.cacheDate}
              status={refreshButtonConfig.refreshButtonLoading}
              testingLabel="widget"
              warningElapsedUpdateTimeSeconds={warningElapsedUpdateTimeSeconds}
              disabled={refreshButtonConfig.disabled}
              targetDate={refreshButtonConfig.targetDate}
            />
          )}
        </section>
      </TitleWrapper>

      <ActionsWrapper>
        {optionalMetrics && (
          <BuToggle
            checked={showMetrics}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setShowMetrics(e.target.checked)
            }
            leftLabel
          >
            <MetricsToggleLabel>Metrics</MetricsToggleLabel>
          </BuToggle>
        )}

        {isDashboardWidget && !isDashboardModal && (
          <WidgetActions
            isReportViewWidget={isReportViewWidget}
            widgetId={id}
            tooltip={tooltip}
            onEditWidget={onEditWidget}
            onCloneWidget={onCloneWidget}
            onRemoveWidget={onRemoveWidget}
            data-testing="widget-actions"
          />
        )}
      </ActionsWrapper>
    </WidgetTitleRow>
  );
};
