import { css } from 'emotion';

export const customBox = css`
  display: block;
  width: 100%;
  max-width: 500px;
  padding-left: 24px;

  &.fullWidth {
    max-width: 100%;
  }

  &.isFilter {
    padding: 0;
  }
`;

export const customBox_radio_btns = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0 15px 0;
  border-radius: 2px;
`;

export const generateAutomaticallyLabel = css`
  font-size: 14px;
  color: var(--bu-gray-900);
  padding: 10px;
  background-color: var(--bu-gray-200);
  line-height: 20px;
`;

export const customList_title = css`
  color: var(--bu-gray-900);
  font-size: 14px;
  margin: 15px 0 6px 0;
`;

export const customList = css`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const customList_item = css`
  margin: 0;
  padding: 0;
  color: var(--bu-gray-900);
  margin-bottom: 15px;
  display: grid;
  grid-gap: 10px;
  align-items: center;

  &.three_columns {
    grid-template-columns: 20px 1fr 60px;
  }

  &.four_columns {
    grid-template-columns: 12px 190px 1fr 60px;
  }
`;

export const customList_item_count = css`
  color: var(--bu-gray-900);
  font-size: 14px;
`;

export const customList_item_input = css`
  width: 100%;
`;

export const custom_label_box = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const custom_label_bool = css`
  font-size: 11px;
  border-radius: 10px;
  text-align: center;
  padding: 5px 10px;

  &.true {
    background-color: var(--bu-green-200);
    color: var(--bu-green-500);
  }

  &.false {
    background-color: var(--bu-red-100);
    color: var(--bu-red-400);
  }
`;

export const customList_addButton = css`
  margin-left: 20px;
  color: var(--bu-primary-500);
  background-color: var(--bu-white);

  &:enabled {
    &:hover {
      background-color: var(--bu-gray-300);
    }
  }
`;

export const custom_text_box = css`
  border-radius: 4px;
  border: 1px solid var(--bu-gray-400);
  padding: 20px;

  &.isFilter {
    border: none;
  }
`;

export const custom_btns_controlls = css`
  margin-top: 10px;
`;

export const range_box = css`
  margin-bottom: 20px;
`;

export const separator = css`
  font-size: 16px;
  color: var(--bu-gray-500);
  text-align: center;
  margin-top: -20px;
`;

export const price_fields = css`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 70px 1fr;
`;

export const price_field = css`
  span {
    margin-top: 7px;
    display: block;
    font-size: 12px;
    color: var(--bu-gray-600);
  }
`;

export const price_input = css`
  &.enabled {
    &.error {
      border-color: var(--bu-red-400);

      &:focus,
      &:focus-within {
        border-color: var(--bu-red-400);
      }
    }
  }
`;

export const inputBox = css`
  max-width: 195px;
  width: 100%;
`;

export const row_price_separator = css`
  font-size: 16px;
  color: var(--bu-gray-500);
  text-align: center;
`;

export const row_price = css`
  display: grid;
  grid-template-columns: 20px min-content auto;
  grid-gap: 10px;
  align-items: center;
  margin-bottom: 15px;
`;

export const row_price_two = css`
  display: grid;
  grid-template-columns: 20px 1fr 30px 1fr 50px;
  grid-gap: 12px;
  align-items: center;
  margin-bottom: 15px;
`;

export const custom_calendar_box = css`
  border-left: 1px solid var(--bu-gray-300);
  border-right: 1px solid var(--bu-gray-300);
  border-bottom: 1px solid var(--bu-gray-300);
`;

export const custom_calendar_tabs = css`
  border-top: 1px solid var(--bu-gray-300);
  border-left: 1px solid var(--bu-gray-300);
  border-right: 1px solid var(--bu-gray-300);
`;

export const loader_box = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const custom_select = css`
  button {
    &.bu-dropdown {
      max-width: 190px;
    }
  }

  &.error {
    button {
      &.bu-dropdown {
        border-color: var(--bu-red-400);
      }
    }
  }
`;

export const dropdownOptionRange = css`
  width: 54px;
  height: 100%;
  border: 1px solid var(--bu-gray-500);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--bu-gray-900);
  font-size: 17px;
`;

export const box_AddBtn = css`
  margin-left: 12px;
  margin-bottom: 10px;
`;

export const label_checkbox = css`
  font-size: 14px;
  color: var(--bu-gray-900);
  line-height: 32px;
`;

export const title_box_date = css`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--bu-gray-900);
  margin-bottom: 7px;

  p {
    margin: 0 10px 0 7px;
  }
`;

export const error = css`
  margin: -12px 0 10px 25px;
  font-size: 14px;
  color: var(--bu-red-400);
`;
