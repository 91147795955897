import * as t from 'actions/actionTypes';

export const addCachedData = (
  key,
  data,
  entity,
  entity_id,
  cacheTimestamp
) => ({
  type: t.CACHE + t.CREATE,
  key,
  data,
  entity,
  entity_id,
  cacheTimestamp,
});

export const updateDraftTableData = (tableName, draftRows) => ({
  type: t.CACHE + t.TABLE_DRAFT,
  tableName,
  draftRows,
});

export const setIncludedDeals = (value, submissionId) => ({
  type: t.CACHE + t.INCLUDED_DEALS + t.CHANGE,
  value,
  submissionId,
});

export const clearCacheSettingsFilters = (toast) => ({
  type: t.CACHE + t.CLEAR_FILTERS,
  payload: { toast },
});
