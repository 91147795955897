import { IDataCellProps, TypedTableCellConfig } from '../TypedTable';
import * as s from '../styles';
import { css } from 'emotion';
import * as R from 'ramda';
import React from 'react';

export interface ShowMoreCellConfig extends TypedTableCellConfig {
  show: boolean;
}

const showMoreButton = css`
  color: var(--bu-primary-700);
  cursor: pointer;
`;

const ShowMoreCell = ({ column, row, onChange = () => {} }: IDataCellProps) => {
  const config: ShowMoreCellConfig =
    (column.config as ShowMoreCellConfig) || {};
  const text = R.pathOr('', column.field.split('.'), row);
  const isOpen: boolean = config.show || false;

  const handleShow = () => {
    onChange(column, row, !isOpen);
  };

  return (
    <div>
      <div className={s.textOverflow}>{text}</div>
      <div className={showMoreButton} onClick={handleShow}>
        {isOpen ? 'Show Less' : 'Show More'}
      </div>
    </div>
  );
};

export default ShowMoreCell;
