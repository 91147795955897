import { IReduxState } from 'reducers/types';

export const apiError = (state: IReduxState) =>
  state.externalApi.apiTokens.errors?.message;

export const isApiError = (state: IReduxState) =>
  state.externalApi.apiTokens.errors?.status;

export const isApiSuccess = (state: IReduxState) =>
  state.externalApi.apiTokens.success;

export const isDeleted = (state: IReduxState) =>
  state.externalApi.apiTokens.isDeleted;

export const getStateTokens = (state: IReduxState) =>
  state.externalApi.apiTokens.tokens;

export const getStateServices = (state: IReduxState) =>
  state.externalApi.apiServices.services;
