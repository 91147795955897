import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DateOperatorOption } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/types';
import { DropdownCalendar } from 'components/dashboard/Metrics/common/Calendar/Calendar';
import {
  getQuarterForecastPeriodOptions,
  getQuarterForecastPeriodOptionsStatus,
  getMonthForecastPeriodOptions,
  getMonthForecastPeriodOptionsStatus,
  getWeekForecastPeriodOptions,
  getWeekForecastPeriodOptionsStatus,
} from 'selectors/revbi/metrics';

interface Props {
  forecastPeriod?: string;
  selectedValue?: string | string[];
  title: string;
  disabled?: boolean;
  onValueChange: (change: DateOperatorOption) => void;
}

export const ForecastPeriodCalendar: FC<Props> = ({
  forecastPeriod,
  selectedValue,
  title,
  disabled,
  onValueChange,
}) => {
  const quarterOptionsStatus = useSelector(
    getQuarterForecastPeriodOptionsStatus
  );
  const monthOptionsStatus = useSelector(getMonthForecastPeriodOptionsStatus);
  const weekOptionsStatus = useSelector(getWeekForecastPeriodOptionsStatus);

  const quarterOptions = useSelector(getQuarterForecastPeriodOptions);
  const monthOptions = useSelector(getMonthForecastPeriodOptions);
  const weekOptions = useSelector(getWeekForecastPeriodOptions);

  const [status, options] = useMemo(() => {
    switch (forecastPeriod) {
      case 'q':
        return [quarterOptionsStatus, quarterOptions];
      case 'm':
        return [monthOptionsStatus, monthOptions];
      case 'w':
        return [weekOptionsStatus, weekOptions];
      default:
        return ['notAsked', []];
    }
  }, [
    forecastPeriod,
    quarterOptionsStatus,
    monthOptionsStatus,
    weekOptionsStatus,
  ]);

  const value = selectedValue
    ? typeof selectedValue === 'string'
      ? selectedValue
      : selectedValue[0]
    : '';

  const includedBlankValues =
    selectedValue?.length && typeof selectedValue !== 'string'
      ? !!selectedValue[1]
      : false;

  return (
    <DropdownCalendar
      disabled={disabled}
      config={{
        allowFuture: true,
        title: title,
        isLocked: status !== 'success',
        values: options.map((option) => ({
          ...option,
          checked: option.value === value,
        })),
        isSimpleCalendar: true,
        includedBlankValues,
        showIncludeBlanksValues: false,
      }}
      key="Forecast Period Filter"
      name="Forecast Period Filter"
      onChange={onValueChange}
    />
  );
};
