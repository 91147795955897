import * as Highcharts from 'highcharts';

interface AdditionalProps {
  isHidden: boolean;
  showTimer: ReturnType<typeof setTimeout>;
  options: {
    delay: number;
  };
}

/**
 * Highcharts mixin for add functionality to show tooltips after delay
 * Adds tooltip.delay: number prop to specify a delay time before show tooltop
 */
export default function (H: any) {
  H.wrap(
    H.Tooltip.prototype,
    'refresh',
    function (
      this: Highcharts.Tooltip & AdditionalProps,
      proceed: Highcharts.WrapProceedFunction,
      point: Highcharts.Point,
      mouseEvent: MouseEvent
    ) {
      if (this.options.delay && this.isHidden) {
        clearTimeout(this.showTimer);

        this.showTimer = setTimeout(() => {
          if (this.chart.hoverPoints) {
            proceed.call(this, point, mouseEvent);
          }
        }, this.options.delay);
      } else {
        proceed.call(this, point, mouseEvent);
      }
    }
  );
}
