import { AxiosError, AxiosResponse } from 'axios';

import * as t from 'actions/actionTypes';
import {
  OptionsKeys,
  StatMetrics,
  StatMetricValue,
} from 'components/dashboard/ForecastDashboard/TrackingDashboard/types';
import {
  ForecastPaceQuarterlyAction,
  ForecastQuotaAttainmentAction,
} from 'reducers/analyticsReducer';
import * as genericSagas from 'sagas/generic';

export type ForecastPaceQuarterlyData = {
  display_name: string;
  name: StatMetrics;
  forecast: boolean;
  forecasttype?: OptionsKeys;
  forecast_category?: boolean;
  values: StatMetricValue[];
};

export type ForecastPaceQuarterlyPayload = ForecastPaceQuarterlyData[];

type GetForecastPaceQuarterlyArgs = {
  business_type_name?: string;
  managers?: string[];
  users?: string[];
  opportunity_types?: string[];
  opportunity_stages?: string[];
  custom_filters?: Record<string, string[]>;
};

export const getForecastPaceQuarterly = (
  data?: GetForecastPaceQuarterlyArgs,
  refreshCache?: boolean
) => ({
  type: t.FORECAST + t.PACE_QUARTERLY + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/forecast/pace/quarterly${
    refreshCache ? '?cache_refresh=1' : ''
  }`,
  data,
  saga: genericSagas.create,
  success: (
    {
      data,
      periods,
    }: { data: ForecastPaceQuarterlyPayload; periods: string[] },
    response: AxiosResponse
  ): ForecastPaceQuarterlyAction => ({
    type: t.FORECAST + t.PACE_QUARTERLY + t.GET + t.SUCCESS,
    payload: data,
    periods,
    cacheDate: response.headers['cache-created'] || '',
  }),
  error: (e: AxiosError<ForecastPaceQuarterlyPayload>) => ({
    type: t.FORECAST + t.PACE_QUARTERLY + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.FORECAST + t.PACE_QUARTERLY + t.GET + t.LOADING,
  }),
});

export type ForecastQuotaAttainmentData = {
  user: { email: string; name: string };
  target: number;
  booked: number;
  quota_attainment: number;
};
export type ForecastQuotaAttainmentPayload = ForecastQuotaAttainmentData[];

type CloseDateInterval = 'TQU' | 'LQU';
type GetForecastQuotaAttainmentArgs = {
  close_date_interval?: CloseDateInterval[];
  managers?: string[];
  business_type_name?: string;
  opportunity_types?: string[];
  opportunity_stages?: string[];
  custom_filters?: Record<string, string[]>;
};
export const getForecastQuotaAttainments = (
  data?: GetForecastQuotaAttainmentArgs
) => ({
  type: t.FORECAST + t.QUOTA_ATTAINMENT + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/forecast/pace/seller-quota-attainment`,
  data,
  saga: genericSagas.create,
  success: (
    payload: AxiosResponse<ForecastQuotaAttainmentPayload>
  ): ForecastQuotaAttainmentAction => ({
    type: t.FORECAST + t.QUOTA_ATTAINMENT + t.GET + t.SUCCESS,
    payload: payload.data,
  }),
  error: (e: AxiosError<ForecastQuotaAttainmentPayload>) => ({
    type: t.FORECAST + t.QUOTA_ATTAINMENT + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.FORECAST + t.QUOTA_ATTAINMENT + t.GET + t.LOADING,
  }),
});
