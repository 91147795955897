import { BoostUpIcons } from 'assets/css/boostup-icons';

import {
  Attendee,
  CallInsightsResponse,
  CallSummarySentiment,
  ParticipantSentiment,
  Segment,
  SpeakingTimeRange,
} from './types';

const PARTICIPANT_COLOR_OPTIONS = [
  '--bu-color-dynamic-bar-yellow',
  '--bu-color-dynamic-bar-aqua',
  '--bu-color-dynamic-bar-lime',
  '--bu-color-dynamic-bar-portage',
  '--bu-color-dynamic-bar-blue',
  '--bu-color-dynamic-bar-pink',
  '--bu-color-dynamic-bar-violet',
  '--bu-color-dynamic-bar-orange',
  '--bu-color-dynamic-bar-desaturated-red',
  '--bu-color-dynamic-bar-fresh-green',
  '--bu-color-dynamic-bar-green',
  '--bu-color-dynamic-bar-purple',
  '--bu-color-dynamic-bar-strong-pink',
  '--bu-color-dynamic-bar-sky-blue',
  '--bu-color-dynamic-bar-cyan',
  '--bu-color-dynamic-line-lime',
  '--bu-color-dynamic-line-dark-pink',
  '--bu-color-dynamic-line-purple',
  '--bu-color-dynamic-line-yellow',
  '--bu-color-dynamic-line-dark-indigo',
];

export const SENTIMENT_STATUS_COLOR: Record<
  CallSummarySentiment['sentiment'],
  string
> = {
  negative: 'var(--bu-red-400)',
  neutral: 'var(--bu-orange-500)',
  positive: 'var(--bu-green-500)',
  NA: 'var(--bu-gray-500)',
};

export const ATTENDEE_RESPONSE_STATUS: Record<
  Attendee['response_status'],
  string
> = {
  accepted: 'Accepted',
  declined: 'Declined',
  needsAction: 'No response',
  tentative: 'Maybe',
};

export const SPEAKER_SENTIMENT_ICONS: Record<
  ParticipantSentiment['sentiment'],
  BoostUpIcons
> = {
  positive: BoostUpIcons.SentimentFacePositive,
  negative: BoostUpIcons.SentimentFaceNegative,
  neutral: BoostUpIcons.SentimentFaceNeutral,
};

export const extendCallsInsightDataObject = (
  callInsightsData: CallInsightsResponse
) => {
  if (!callInsightsData) return null;

  callInsightsData.attendees = (callInsightsData.attendees || []).map(
    (attendee) => ({
      ...attendee,
      unique_name:
        attendee.display_name || attendee.speaker_name || attendee.name,
    })
  );

  callInsightsData.meeting_participants = (
    callInsightsData.meeting_participants || []
  ).map((participant, index) => ({
    ...participant,
    color: `var(${
      PARTICIPANT_COLOR_OPTIONS[index % PARTICIPANT_COLOR_OPTIONS.length]
    })`,
    organizer: callInsightsData.attendees.some(
      (attendee) =>
        attendee.organizer && participant.name === attendee.unique_name
    ),
  }));

  callInsightsData.segments = (callInsightsData.segments || []).map(
    (segment) => ({
      ...segment,
      formattedStartTime: formatSecondsToFriendlyTimeString(
        segment.start_time,
        callInsightsData.duration
      ),
    })
  );

  if (callInsightsData.call_summary_data) {
    callInsightsData.call_summary_data.sentiment = {
      ...(callInsightsData.call_summary_data.sentiment || {}),
      sentiment:
        callInsightsData.call_summary_data.sentiment?.sentiment || 'NA',
    };
  }

  return callInsightsData;
};

export const formatSecondsToFriendlyTimeString = (
  seconds: number,
  duration: number
) => {
  const pad = (num: number): string => String(num).padStart(2, '0');
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  if (duration < 3600) {
    return `${pad(minutes)}.${pad(secs)}`;
  }

  return `${pad(hours)}.${pad(minutes)}.${pad(secs)}`;
};

export const getSpeakingTimeRanges = (
  segments: Segment[]
): SpeakingTimeRange[] => {
  const timeRangesMap = segments.reduce((acc, segment) => {
    if (!acc[segment.speaker_name]) {
      acc[segment.speaker_name] = [];
    }

    acc[segment.speaker_name].push([segment.start_time, segment.end_time]);

    return acc;
  }, {} as Record<string, [number, number][]>);

  return Object.entries(timeRangesMap).map(([speaker, time_ranges]) => ({
    speaker,
    time_ranges,
  }));
};

export const formatTimeToFriendlyTimeString = (time: number) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);

  const hoursStr = hours > 0 ? `${hours}:` : '';
  const minutesStr = hours > 0 && minutes < 10 ? `0${minutes}` : `${minutes}`;
  const secondsStr = seconds < 10 ? `0${seconds}` : `${seconds}`;

  return `${hoursStr}${minutesStr}:${secondsStr}`;
};

export const formatDurationToFriendlyDurationString = (
  seconds: number,
  onlyMinutes: boolean = false
) => {
  if (seconds === 0) {
    return '0min 0sec';
  }

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.round(seconds % 60);

  if (onlyMinutes) {
    return `${minutes}min${minutes > 1 ? 's' : ''}`;
  }

  const minutesString =
    minutes > 0 ? `${minutes}min${minutes > 1 ? 's' : ''}` : '';
  const secondsString =
    remainingSeconds > 0
      ? `${remainingSeconds}sec${remainingSeconds > 1 ? 's' : ''}`
      : '';

  return [minutesString, secondsString].join(' ');
};

export const findNearestLowerStartTimeIndex = (arr: number[], target: number) =>
  arr.reduce<number | null>((nearestLower, num, index) => {
    if (num === target) {
      return index;
    }

    if (num < target) {
      return index;
    }

    return nearestLower;
  }, null);

export const getVideoTimeAsPercentage = (time: number, duration: number) =>
  (time / duration) * 100;

export const getSectionsToHighlight = (
  startTimes: number[],
  segments: Segment[]
) =>
  startTimes
    .map((startTime): [number, number] | null => {
      const segment = segments.find(
        (segment) => segment.start_time === startTime
      );

      return segment ? [segment.start_time, segment.end_time] : null;
    })
    .filter((section) => section !== null) as [number, number][];

export const replaceLinksWithAnchorTags = (input: string): string => {
  const urlRegex = /(https?:\/\/[^\s<]+)/g;

  // Only add anchor tag if it doesn't have already
  return input
    .split(urlRegex)
    .map((part, index, parts) => {
      if (
        urlRegex.test(part) &&
        !parts[index - 1]?.includes('<a') &&
        !parts[index + 1]?.includes('</a>')
      ) {
        return `<a href="${part}" target="_blank">${part}</a>`;
      }

      return part;
    })
    .join('');
};

export const getUniqueNameOrEmail = (attendee?: Attendee) =>
  attendee?.unique_name || attendee?.email;
