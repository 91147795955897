import * as R from 'ramda';
import { useMemo, useState, Dispatch, SetStateAction } from 'react';

import { ISelectOption } from 'components/UI/BuSelect/types';

export interface OptionWithComparator<T> extends ISelectOption {
  compareTo: MapPaths.RecursivePartial<T>;
}

const useComplexDropdownFilter = <T>(
  Options: OptionWithComparator<T>[],
  initFilterValue: OptionWithComparator<T>['value']
): [
  OptionWithComparator<T>['value'],
  Dispatch<SetStateAction<OptionWithComparator<T>['value']>>,
  (obj: T) => boolean
] => {
  const [callFilterValue, setCallFilterValue] =
    useState<OptionWithComparator<T>['value']>(initFilterValue);

  const callFilter = useMemo(() => {
    const compareTo =
      Options.find((item) => item.value === callFilterValue)?.compareTo ?? {};
    return R.whereEq(compareTo);
  }, [Options, callFilterValue]);

  return [callFilterValue, setCallFilterValue, callFilter];
};

export default useComplexDropdownFilter;
