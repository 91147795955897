import { css } from 'emotion';

export const chart_wrapper = css`
  margin-bottom: 20px;
`;

export const chart_two_line = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 20px;
`;

export const header = css`
  display: flex;
  align-items: center;
  background-color: var(--bu-gray-100);
  border-bottom: 1px solid var(--bu-gray-400);
  color: var(--bu-gray-900);
  font-size: 20px;
  font-weight: 500;
  height: 50px;
  width: 100%;
  padding: 15px 30px;
  margin-bottom: 20px;
  position: sticky;
  top: 0px;
  z-index: 150;
`;

export const body = css`
  padding: 0 30px 20px;
  color: var(--bu-gray-900);
`;

export const keepHeight = css`
  margin-bottom: 20px;
`;

export const dashboardWrapper = css`
  position: relative;
  padding-top: 50px;
`;

export const widget_table = css`
  border: 1px solid var(--bu-gray-500);
  border-radius: 2px;
  max-height: 437px;
  overflow: auto;
`;

export const widget_table_header = css`
  border-bottom: 1px solid var(--bu-gray-500);
  padding: 14px 21px;
  font-size: 16px;
  color: var(--bu-gray-900);
  font-family: var(--bu-font-regular);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const widget_table_body = css`
  height: calc(100% - 59px);
  overflow: auto;
`;

export const dealsNotFound = css`
  background: var(--bu-gray-200);
  width: 100%;
  height: 375px;
  text-align: center;
  padding-top: 175px;
  font-size: 16px;
  font-family: var(--bu-font-regular);
`;
