import classNames from 'classnames';
import { css } from 'emotion';
import React, { useMemo } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import { ITypedRowProps } from 'components/UI/common/TypedTable/TypedRow';

type TypedHierarchyToggleProps = Pick<
  ITypedRowProps,
  'isHierarchyOpen' | 'toggleHierarchy'
>;

export const ICON_WIDTH = 14;
export const HIERARCHICAL_OFFSET = 12;

const buttonClassName = css`
  margin: 0 4px;
  cursor: pointer;
  outline-style: none;
  background-color: transparent;
  border-style: none;
  padding: 0;
  font-size: 0.6em;
`;

const icon = css`
  font-size: 2em;
  color: var(--bu-gray-900);

  &.open {
    color: var(--bu-primary-500);
  }
`;

const toggleHierarchyPlaceholder = css`
  width: 25px;
`;

const hierarchyToggle = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TypedHierarchyToggle: React.FC<TypedHierarchyToggleProps> = ({
  isHierarchyOpen,
  toggleHierarchy,
}) => {
  return (
    <div className={classNames('hierarchy-toggle', hierarchyToggle)}>
      {toggleHierarchy ? (
        <button className={buttonClassName} onClick={toggleHierarchy}>
          <BuIcon
            name={
              isHierarchyOpen
                ? BoostUpIcons.ChevronDown
                : BoostUpIcons.ChevronRight
            }
            className={classNames(icon, {
              open: isHierarchyOpen,
            })}
          />
        </button>
      ) : (
        <div className={toggleHierarchyPlaceholder} />
      )}
    </div>
  );
};

export default TypedHierarchyToggle;
