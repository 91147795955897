import {
  connect,
  MapDispatchToPropsParam,
  MapStateToPropsParam,
} from 'react-redux';

import { getUnmappedUsers } from 'actions/companyUsersActions';
import SFUserList from 'components/settings/ManageUsers/UsersAddView/AddUserSteps/SFUserList/SFUserList';
import {
  IDispatchProps,
  IOwnProps,
  IStateProps,
} from 'components/settings/ManageUsers/UsersAddView/AddUserSteps/SFUserList/types';
import { IReduxState } from 'reducers/types';
import { getUser } from 'selectors';
import {
  getCompanyManagerList,
  getUnmappedUserIsLoading,
  getUnmappedUserList,
  isCompanyUserCreated,
} from 'selectors/companyUsers';

export const mapStateToProps: MapStateToPropsParam<
  IStateProps,
  IOwnProps,
  IReduxState
> = (state) => ({
  usersData: getUnmappedUserList(state),
  isLoading: getUnmappedUserIsLoading(state),
  isCreated: isCompanyUserCreated(state),
  managers: getCompanyManagerList(state),
  currentUserRole: getUser(state).role,
});

export const mapDispatchToProps: MapDispatchToPropsParam<
  IDispatchProps,
  IOwnProps
> = {
  getUsers: getUnmappedUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(SFUserList);
