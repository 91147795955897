import React from 'react';

import { formatMoney, formatNumber } from 'common/numbers';
import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import { COLUMN_DEFAULTS } from 'components/dashboard/Metrics/Widget/Table/constants';
import { BIWidgetColumnV2 } from 'components/dashboard/Metrics/metrics.types';

export const getTableOptions = (
  cols: BIWidgetColumnV2[],
  data: { [key: string]: any },
  companyCurrencyCode: string,
  onTableDataClick: (column: IColumn, row: IRow) => void
): {
  columns: IColumn[];
  rows: IRow[];
} => {
  const columns: IColumn[] = cols.map((el) => {
    return {
      ...COLUMN_DEFAULTS,
      id: el.field_name,
      align: 'right',
      field: el.field_name,
      label: el.display_name,
      sortable: false,
      type: el.type,
      metricId: el.metric_id,
      config: {
        ...(el.field_name === 'stage' && {
          click: (column: IColumn, row: IRow) => {
            onTableDataClick(column, row);
          },
        }),
        formatter: (value: any) => (
          <span>
            {el.type === 'currency' ? (
              formatMoney(companyCurrencyCode, value)
            ) : el.type === 'number' ? (
              formatNumber(value)
            ) : el.type === 'percent' ? (
              `${value}%`
            ) : el.field_name === 'stage' ? (
              <strong> {value} </strong>
            ) : (
              value
            )}
          </span>
        ),
      },
    } as IColumn;
  });

  const rows: IRow[] = data as IRow[];

  return { columns, rows };
};
