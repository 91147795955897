import * as s from './styles';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { Dropdown, Input } from 'semantic-ui-react';

import {
  MappingObject,
  MappingOptions,
  ThirdPartyLink,
} from 'common/types/ThirdPartyIntegration';

const MappingObjectWidget: React.FC<{
  mappings?: MappingOptions;
  selected?: ThirdPartyLink;
  className?: string;
  onChange?: (selected: ThirdPartyLink) => void;
}> = ({ mappings, selected, onChange, className }) => {
  const [selectedObject, setSelectedObject] = useState(
    mappings?.types.find((el) => selected?.type === el.name) ??
      mappings?.types[0]
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedField, setSelectedField] = useState(selected);

  const filteredOptions = useMemo(() => {
    return selectedObject?.objects.filter((el) =>
      el.path.some((str) =>
        str.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, selectedObject]);

  return (
    <Dropdown
      text={
        selectedField?.path[selectedField?.path.length - 1] ?? 'Select field'
      }
      className={classNames(s.linkToWidget, className)}
      fluid
      direction="left"
    >
      <Dropdown.Menu className={s.overrideMenuZIndex}>
        <div className={s.linkToWidgetContainer}>
          <div className={s.linkToWidgetSubjectObjectContainer}>
            <span>SELECT OBJECT</span>
            {mappings?.types.map((el) => (
              <div
                key={el.name}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setSelectedObject(el);
                }}
                className={classNames(s.subjectObjectName, {
                  selected: selectedObject?.name === el.name,
                })}
              >
                {el.name}
              </div>
            ))}
          </div>
          <div className={s.linkToWidgetMainContainer}>
            <Input
              icon="search"
              iconPosition="left"
              placeholder="Search..."
              className={s.widgetSearch}
              onChange={(event) => setSearchTerm(event.target.value)}
              onClick={(event: any) => {
                event.preventDefault();
                event.stopPropagation();
              }}
              data-testing="txt_field"
            ></Input>
            <div className={s.optionsContainer}>
              {filteredOptions?.map((field) => (
                <div
                  key={field.path.join('-')}
                  className={classNames(s.fieldContainer, {
                    selected: selectedField?.path.every(
                      (value, index) => value === field.path[index]
                    ),
                  })}
                  onClick={(event) => {
                    setSelectedField({
                      path: field.path,
                      type: selectedObject?.name ?? '',
                    });
                    if (!!onChange) {
                      onChange({
                        path: field.path,
                        type: selectedObject?.name ?? '',
                      });
                    }
                  }}
                >
                  {selectedObject?.name === 'Salesforce' ? (
                    <>
                      <span className={s.fieldDisplayName}>
                        {field.path[1]}
                      </span>
                      <span className={s.objectName}>{field.path[0]}</span>
                    </>
                  ) : (
                    <>
                      <span className={s.fieldDisplayName}>
                        {field.path[3]}
                      </span>
                      <span className={s.objectName}>
                        {field.path.slice(0, 3).join(', ')}
                      </span>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MappingObjectWidget;
