import styled from '@emotion/styled';

export const Container = styled.label`
  --bu-toggle-width: 32px;
  --bu-toggle-height: 20px;
  --bu-toggle-dot_size: 16px;

  --bu-toggle-dot_offset: calc(
    (var(--bu-toggle-dot_size) - var(--bu-toggle-height)) / 2
  );

  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 2px 0;
  height: 30px;

  &.disabled {
    opacity: var(--bu-control-disable-opacity);
  }

  &.disabled,
  &.readOnly {
    cursor: default !important;
  }
`;

export const Bar = styled.span<{ leftLabel?: boolean }>`
  background-color: var(--bu-gray-500);
  transition: 0.4s;
  border-radius: var(--bu-toggle-height);
  width: var(--bu-toggle-width);
  height: var(--bu-toggle-height);
  display: inline-block;
  margin: var(--bu-toggle-dot_offset);
  margin: ${(props) =>
    props.leftLabel
      ? '0 0 0 calc(var(--bu-toggle-dot_offset) + 8px)'
      : '0 calc(var(--bu-toggle-dot_offset) + 8px) 0 0'};
  text-align: left;

  &.readOnly {
    background-color: var(--bu-white);
    border: 1px solid var(--bu-gray-500);
    box-sizing: content-box;
  }

  &::before {
    content: '';
    height: var(--bu-toggle-dot_size);
    width: var(--bu-toggle-dot_size);
    background-color: var(--bu-white);
    transition: 0.4s;
    border-radius: var(--bu-toggle-dot_size);
    transform: translate(
      calc(-1 * var(--bu-toggle-dot_offset)),
      calc(-1 * var(--bu-toggle-dot_offset))
    );
    display: inline-block;
  }

  &.readOnly {
    &::before {
      background-color: var(--bu-gray-500);
    }
  }
`;

export const Dot = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  :checked {
    + span {
      background-color: var(--bu-primary-500);

      &.readOnly {
        background-color: var(--bu-gray-500);
      }

      :hover:not(.disabled, .readOnly) {
        background-color: var(--bu-primary-700);
      }

      &:before {
        transform: translate(
          calc(
            var(--bu-toggle-width) - var(--bu-toggle-dot_size) +
              var(--bu-toggle-dot_offset)
          ),
          calc(-1 * var(--bu-toggle-dot_offset))
        );
        background-color: var(--bu-white);
      }
    }
  }
`;

Dot.defaultProps = {
  type: 'checkbox',
};
