import classNames from 'classnames';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  formatTo,
  selectTimeRange,
} from 'sharedLogicMainAppAndRevBi/timeSpanOptions';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import BuRadio from 'components/UI/BuRadio';
import { DateOperatorOption } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/types';
import { TimeFilter } from 'components/dashboard/Metrics/common/Calendar/TimeFilter/TimeFilter';
import * as s from 'components/dashboard/Metrics/common/Calendar/styles';
import {
  BetweenDate,
  Checkbox,
  FiltersConfig,
} from 'components/dashboard/Metrics/common/Calendar/types';
import { useClickOutside } from 'components/hooks/useClickOutside';
import { getFiscalYearStartDate } from 'selectors';

type Props = {
  config: FiltersConfig;
  name: string;
  disabled?: boolean;
  onChange: (values: DateOperatorOption) => void;
};

type IN_FILTER_VALUES = 'in' | 'not_in' | 'is_null';

interface DropdownStyles {
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
  maxHeight?: number;
}

export const DropdownCalendar: React.FC<Props> = ({
  config,
  name,
  disabled,
  onChange,
}) => {
  const CUSTOM_DATE_END_VALUES = [
    'days',
    'weeks',
    'months',
    'quarters',
    'years',
  ];

  const fiscalYearStartDate = useSelector(getFiscalYearStartDate);

  const {
    isOpen,
    setIsOpen,
    refElement: buttonContainerRef,
  } = useClickOutside();

  const dropdownRef = useRef<HTMLDivElement>(null);

  const [localTab, setLocalTab] = useState<string>('Predefined');
  const [operator, setOperator] = useState<IN_FILTER_VALUES>('in');
  const [position, setPosition] = useState<DropdownStyles>();
  const [selectedDate, setSelectedDate] = useState<string>('all_time');
  const [includeBlanks, setIncludesBlanks] = useState(false);
  const [betweenDate, setBetweenDate] = useState<BetweenDate>({
    startDate: '',
    endDate: '',
  });

  const {
    isLocked,
    title,
    values,
    fiscalYearValues,
    relativeValues,
    customPeriodValue,
    showInNotInFilter,
    isSimpleCalendar,
    includedBlankValues,
    showIncludeBlanksValues = true,
  } = config;

  const currentValue: Checkbox | undefined = useMemo(
    () =>
      fiscalYearValues?.find((item) => item.checked) ||
      relativeValues?.find((item) => item.checked) ||
      values.find((item) => item.checked),
    [fiscalYearValues, relativeValues, values]
  );

  const label: string = useMemo(() => {
    if (currentValue?.label) {
      return currentValue?.label;
    }

    if (!!customPeriodValue) {
      return customPeriodValue.split('_').join(' ');
    }

    if (!!betweenDate?.startDate && !!betweenDate?.endDate) {
      return `${betweenDate?.startDate} - ${betweenDate?.endDate}`;
    }

    return '';
  }, [currentValue?.label, customPeriodValue, betweenDate]);

  const value: string =
    currentValue?.value ||
    (typeof customPeriodValue === 'string' && customPeriodValue) ||
    (customPeriodValue?.length && customPeriodValue[0]) ||
    '';

  const isHistoricalCalendar: boolean = !!relativeValues?.length;

  useEffect(() => {
    setSelectedDate(value || 'all_time');
    setIncludesBlanks(includedBlankValues);

    if (!isSimpleCalendar) {
      const format = (date: string) =>
        formatTo(date, 'YYYY-MM-DD', 'MM/DD/YYYY');

      if (isString(value) && value.length === 21) {
        const dates = value.split(',');

        setLocalTab('Range');
        setBetweenDate({
          startDate: format(dates[0]),
          endDate: format(dates[1]),
        });
      } else if (value && value[0] && value[0].length >= 10) {
        const dates = value[0].split(',');

        setBetweenDate({
          startDate: format(dates[0]),
          endDate: format(dates[1]),
        });
      } else {
        const date = selectTimeRange(
          isArray(value) ? value : [value],
          fiscalYearStartDate,
          false
        );

        setBetweenDate({
          startDate: date ? format(Object.keys(date[0])[0]) : '',
          endDate: date ? format(date[0][Object.keys(date[0])[0]]) : '',
        });
      }

      const isPredefined = values?.some((el) => el.value === value);
      const isFiscalYear = fiscalYearValues?.some((el) => el.value === value);
      const isRelative = relativeValues?.some((el) => el.value === value);
      const isRange = value.includes(',');
      const isCustom =
        !isHistoricalCalendar &&
        value.includes('_') &&
        value.split('_').length === 3 &&
        parseInt(value.split('_')?.[1] || '') &&
        CUSTOM_DATE_END_VALUES.includes(value.split('_')?.[2]);

      if (isPredefined) {
        setLocalTab('Predefined');
      }
      if (isFiscalYear) {
        setLocalTab('Fiscal Year');
      }
      if (isRelative) {
        setLocalTab('Relative');
      }
      if (isRange) {
        setLocalTab('Range');
      }
      if (isCustom) {
        setLocalTab('Custom');
      }
    }
  }, [value]);

  const adaptDropdownPosition = () => {
    if (!buttonContainerRef.current || !dropdownRef.current) {
      return;
    }

    const screenHeight = window.innerHeight;
    const buttonRect = buttonContainerRef.current?.getBoundingClientRect();
    const spaceAbove = buttonRect.top;
    const spaceBelow = screenHeight - buttonRect.bottom;

    const positionBelow = spaceBelow > spaceAbove;

    if (positionBelow) {
      setPosition({
        top: buttonRect.bottom - buttonRect.height,
        left: buttonRect.left,
        maxHeight: spaceBelow,
      });
    } else {
      setPosition({
        bottom: window.innerHeight - buttonRect.top,
        left: buttonRect.left,
        maxHeight: spaceAbove,
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('scroll', adaptDropdownPosition, true);
      window.addEventListener('resize', adaptDropdownPosition, true);
    }
    return () => {
      window.removeEventListener('scroll', adaptDropdownPosition, true);
      window.removeEventListener('resize', adaptDropdownPosition, true);
    };
  }, [isOpen]);

  const handleCalendarClick = (): void => {
    if (!isLocked && !disabled) {
      setIsOpen(!isOpen);
      adaptDropdownPosition();
    }
  };

  const handleChangeDetected = (id: string): void => {
    setSelectedDate(id);
    const selection = includeBlanks ? [{ id: 'is_null', checked: true }] : [];
    onChange({
      selected: [{ id, checked: true }, ...selection],
      operator: operator,
    });
  };

  const handleInclusionOfBlank = (value: boolean): void => {
    setIncludesBlanks(value);
    const selection = value ? [{ id: 'is_null', checked: true }] : [];
    onChange({
      selected: [{ id: selectedDate, checked: true }, ...selection],
      operator: operator,
    });
  };

  const handleRadioChange = (
    _: React.FormEvent<HTMLInputElement>,
    { value }: { value: IN_FILTER_VALUES }
  ): void => {
    setOperator(value);
    let dateValue = selectedDate;
    /**
     * when the user selects "Is Blank" option we must send 'is_null' as a value
     * and when the user changes to any other option we must send a default 'PAST' value.
     */
    if (value === 'is_null') {
      dateValue = 'is_null';
    } else if (dateValue === 'is_null') {
      dateValue = 'PAST';
    }
    const selection =
      includeBlanks && dateValue !== 'is_null'
        ? [{ id: 'is_null', checked: true }]
        : [];
    onChange({
      selected: [{ id: dateValue, checked: true }, ...selection],
      operator: value,
    });
  };

  const isNULLOperator = useMemo(() => 'is_null' === operator, [operator]);

  return (
    <div
      data-testing="operator-value"
      id={`${name}_dropdown`}
      ref={buttonContainerRef}
      className={classNames(s.panel_wrapper, {
        disabled,
      })}
    >
      <div
        data-testing="calendar_dropdown"
        className={classNames(s.panel_dropdown_button, {
          isLocked,
          isOpen,
          disabled,
        })}
        onClick={handleCalendarClick}
      >
        <span className={s.panel_dropdown_name}>{title}:</span>

        {showInNotInFilter && (
          <span className={s.operator_value}>
            {isNULLOperator ? 'Is Blank' : `${operator.replace('_', ' ')}`}
          </span>
        )}

        <span
          className={classNames(s.panel_dropdown_value, {
            hided: isNULLOperator,
          })}
        >
          {label}
        </span>

        <BuIcon
          name={BoostUpIcons.Calendar}
          color="var(--bu-primary-500)"
          className={s.big_icon}
        />

        <BuIcon
          name={isOpen ? BoostUpIcons.TriangleUp : BoostUpIcons.TriangleDown}
          color="var(--bu-gray-900)"
          className={s.icon}
        />
      </div>

      <div
        style={{
          top: position?.top,
          left: position?.left,
          right: position?.right,
          bottom: position?.bottom,
          maxHeight: position?.maxHeight,
          visibility: isOpen ? 'visible' : 'hidden',
          transition: 'height 0.5s ease',
        }}
        onScroll={adaptDropdownPosition}
        ref={dropdownRef}
        className={classNames(s.panel_dropdown, {
          disabled: isNULLOperator,
        })}
      >
        {showInNotInFilter && (
          <section
            data-testing="operator-selection-section"
            className={s.operator_section}
          >
            <BuRadio
              label="In"
              value="in"
              checked={'in' === operator}
              onChange={handleRadioChange}
            />
            <BuRadio
              label="Not In"
              value="not_in"
              checked={'not_in' === operator}
              onChange={handleRadioChange}
            />
            <BuRadio
              label="Is Blank"
              value="is_null"
              onChange={handleRadioChange}
              checked={isNULLOperator}
            />
          </section>
        )}

        <TimeFilter
          config={config}
          betweenDate={betweenDate}
          localTab={localTab}
          isHistorical={isHistoricalCalendar}
          value={value}
          includeBlanks={includeBlanks}
          onChange={handleChangeDetected}
          onChangeLocalTab={setLocalTab}
          onClose={setIsOpen}
          setBetweenDate={setBetweenDate}
          onIncludeBlank={handleInclusionOfBlank}
          showIncludeBlanks={showIncludeBlanksValues}
        />
      </div>
    </div>
  );
};
