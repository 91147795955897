import styled from '@emotion/styled';

export const AnalysisTypeRadioContainer = styled.div(
  (props: { selected: boolean; disabled: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    border: '1px solid',
    borderRadius: '4px',
    borderColor: props.selected
      ? `var(--bu-primary-500)`
      : `var(--bu-gray-400)`,
    padding: '8px',
    cursor: props.disabled ? '' : 'pointer',
    width: '100%',
    '>div .ui.radio.checkbox label': {
      fontSize: '16px',
      paddingLeft: '26px',
      marginBottom: '5px',
    },
  })
);
