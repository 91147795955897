import MiniSearchInput from '../../MiniSearchInput';
import debounce from 'lodash/debounce';
import React, {
  MouseEvent,
  SyntheticEvent,
  useCallback,
  useState,
} from 'react';

interface ISearchInputProps {
  defaultValue?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
}

const SearchInput = ({
  defaultValue = '',
  placeholder,
  onChange = () => {},
}: ISearchInputProps) => {
  const [value, setValue] = useState(defaultValue);

  const onChangeCallback = useCallback(debounce(onChange, 500), [onChange]);

  const handleChange = (event: SyntheticEvent<HTMLElement, Event>) => {
    const e = event as React.SyntheticEvent<HTMLInputElement>;
    setValue(e.currentTarget.value);
    onChangeCallback(e.currentTarget.value);
  };

  const handleReset = (event: MouseEvent<HTMLButtonElement>) => {
    setValue('');
    onChangeCallback('');
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.defaultPrevented) {
      return;
    }
    if (event.altKey || event.ctrlKey) {
      return;
    }
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        break;
      case 'Esc': // IE/Edge specific value
      case 'Escape':
        setValue('');
        onChangeCallback('');
        break;
    }
  };

  return (
    <MiniSearchInput
      placeholder={placeholder}
      inputSearchValue={value}
      onChangeInputSearchValue={handleChange}
      onEnterPressSearch={handleKeyPress}
      resetInputSearch={handleReset}
    />
  );
};

export default SearchInput;
