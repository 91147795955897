import { SortOrder } from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';

export const COLUMN_DEFAULTS = {
  id: '',
  delta: false,
  editable: false,
  resize: false,
  sort_field: null,
  sort_order: SortOrder.DESCENDING,
  sortable: true,
  type: ColumnTypes.TEXT,
  config: {},
  minWidth: 90,
};

export const SUBTOTAL = 'Subtotal';
export const CELL_EMPTY_VALUE = '   ';
export const ALL = 'All';
