import React from 'react';
import { connect } from 'react-redux';

import * as callsActions from 'actions/callActions';
import * as searchActions from 'actions/searchActions';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import CallsDesktop from 'components/new-library';
import * as selectors from 'selectors';

export class Calls extends React.Component {
  componentDidMount() {
    AnalyticsTracker.event(this.props, {
      category: 'Calls Dashboard',
      action: `Tab: ${this.props.activeTab.key}`,
      label: 'Tab Opened',
    });
    AnalyticsTracker.setUserProperties(this.props);
  }

  componentDidUpdate({ activeTab }) {
    if (activeTab.key !== this.props.activeTab.key) {
      // fire an analytics event when the user changes tabs
      AnalyticsTracker.event(this.props, {
        category: 'Calls Dashboard',
        action: `Tab: ${this.props.activeTab.key}`,
        label: 'Tab Opened',
      });
    }
  }

  render() {
    return <CallsDesktop {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  tabs: selectors.getCallsTabs(state),
  activeTab: selectors.getCallsActiveTab(state),
  user: selectors.getUser(state),
  isAdmin: selectors.isAdmin(state),
  searchResults: selectors.getSearchCallsResults(state),
});

const mapDispatchToProps = {
  filterCalls: callsActions.filterCalls,
  getCalls: callsActions.getCalls,
  pagination: callsActions.pagination,
  clearFilters: callsActions.clearFilter,
  search: searchActions.getSearchResults,
  paginateSearch: searchActions.paginateSearchResults,
};

export default connect(mapStateToProps, mapDispatchToProps)(Calls);
