import classNames from 'classnames';
import { css } from 'emotion';
import React, { ChangeEvent, useCallback } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import closeIcon from 'assets/images/close.svg';
import BuIcon from 'components/UI/BuIcon';
import { useClickOutside } from 'components/hooks/useClickOutside';

export type TSearchVariant = 'collapsible' | 'default' | 'top-header';

export type TProps = {
  name: string;
  onBlur?: () => {};
  onChange: (value: string) => void;
  onFocus?: () => void;
  onKeyDown?: () => void;
  placeholder?: string;
  reset?: () => void;
  type?: TSearchVariant;
  value: string;
  width?: number;
};

const DEFAULT_FULL_WIDTH = 300; // px

const close_icon = css`
  cursor: pointer;
  height: 12px;
  width: 12px;
  margin: 9px;
`;

const search_icon = css`
  font-size: 15px;
`;

const getStyles = (type: TSearchVariant, width: number) => {
  const mapVariantToStyles: {
    [key in TSearchVariant]: { [key: string]: string };
  } = {
    collapsible: {
      container: css`
        width: 32px;
        &.open {
          width: ${width}px;
        }
      `,
      label: css`
        display: flex;
        flex-direction: row;
        position: relative;
        width: 100%;
        border: 1px solid var(--bu-white);
        border-radius: 18px;
        background-color: var(--bu-primary-800);
        height: 32px;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.open {
          cursor: default;
          border-radius: 8px;
          padding: 3px 7px;
          background-color: var(--bu-white);

          i {
            color: var(--bu-primary-700) !important;
          }
        }

        :focus-within {
          border: 1px solid var(--bu-primary-500);
        }
      `,
      close_icon,
      search_icon,
      input: css`
        display: none;
        &.open {
          display: block;
          height: 30px;
          width: 100%;
          border: none;
          border-radius: 100px;
          color: var(--bu-gray-900);
          margin-left: 5px;
          font-size: 12px;
          font-weight: normal;
        }

        &::placeholder {
          color: var(--bu-gray-700);
        }
      `,
    },
    default: {
      container: css``,
      label: css`
        display: flex;
        flex-direction: row;
        align-items: baseline;
        position: relative;
        width: 100%;
        border: 1px solid var(--bu-gray-400);
        border-radius: 100px;
        background: var(--bu-gray-100);

        input {
          background: var(--bu-gray-100);
        }

        :focus-within {
          border: 1px solid var(--bu-gray-700);
        }
      `,
      close_icon,
      search_icon,
      input: css`
        height: 30px;
        width: 100%;
        border: none;
        border-radius: 100px;
        color: var(--bu-gray-900);

        &::placeholder {
          color: var(--bu-gray-700);
        }
      `,
    },
    ['top-header']: {
      container: css`
        width: ${width}px;
      `,
      label: css`
        display: flex;
        flex-direction: row;
        align-items: baseline;
        position: relative;
        width: 100%;
        background-color: var(--bu-gray-100);
        border: 1px solid var(--bu-gray-400);
        border-radius: 100px;

        :focus-within {
          background-color: var(--bu-white);
          border: 1px solid var(--bu-gray-700);
        }
      `,
      close_icon,
      search_icon,
      input: css`
        height: 30px;
        width: 100%;
        border: none;
        border-radius: 100px;
        background-color: var(--bu-gray-100);
        color: var(--bu-gray-900);
        :focus {
          background-color: var(--bu-white);
        }

        &::placeholder {
          color: var(--bu-gray-700);
        }
      `,
    },
  };

  return mapVariantToStyles[type];
};

const TypedSearchInput: React.FC<TProps> = ({
  name,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  placeholder = 'Search...',
  reset,
  type = 'collapsible',
  value,
  width = DEFAULT_FULL_WIDTH,
}) => {
  const { isOpen, setIsOpen, refElement } = useClickOutside();

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  }, []);

  const handleReset = useCallback(() => {
    if (typeof reset === 'function') {
      reset();
    } else {
      onChange('');
    }
  }, []);

  const s = getStyles(type, width);

  return (
    <div
      className={classNames(s.container, { open: isOpen })}
      onClick={handleOpen}
      ref={refElement}
    >
      <label className={classNames(s.label, { open: isOpen })}>
        <BuIcon
          className={s.search_icon}
          name={BoostUpIcons.Search}
          color="var(--bu-white)"
        />
        <input
          className={classNames('bu-font-default', s.input, {
            open: isOpen,
          })}
          name={name}
          onBlur={onBlur && onBlur}
          onChange={handleChange}
          onFocus={onFocus && onFocus}
          onKeyDown={onKeyDown && onKeyDown}
          placeholder={placeholder}
          type="search"
          value={value}
          data-testing="txt_field"
        />
        {value && isOpen && (
          <img
            className={classNames(s.close_icon, 'close_icon')}
            onMouseDown={handleReset}
            src={closeIcon}
            alt="clear search"
          />
        )}
      </label>
    </div>
  );
};

export default TypedSearchInput;
