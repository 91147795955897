import React from 'react';

import BuRadio from 'components/UI/BuRadio';
import { AnalysisTypeRadioContainer } from 'components/dashboard/Metrics/Create/AnalysisTypes/AnalysisTypeSelector/styles';
import { AnalysisTypeSelectDescription } from 'components/dashboard/Metrics/Create/AnalysisTypes/common.styles';
import { AnalysisType } from 'components/dashboard/Metrics/constants';

interface Props {
  analysisType: AnalysisType;
  selectedAnalysisType: AnalysisType;
  description: string;
  disabled: boolean;
  onChangeAnalysisType: (analysisType: AnalysisType) => void;
}

export const AnalysisTypeSelector: React.FC<Props> = ({
  analysisType,
  selectedAnalysisType,
  description,
  disabled,
  onChangeAnalysisType,
}) => (
  <AnalysisTypeRadioContainer
    key={analysisType}
    selected={analysisType === selectedAnalysisType}
    disabled={disabled}
    onClick={() => !disabled && onChangeAnalysisType(analysisType)}
  >
    <BuRadio
      label={analysisType.charAt(0).toUpperCase() + analysisType.slice(1)}
      value={analysisType}
      checked={analysisType === selectedAnalysisType}
      disabled={disabled}
    />
    <AnalysisTypeSelectDescription>{description}</AnalysisTypeSelectDescription>
  </AnalysisTypeRadioContainer>
);
