import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import truncate from 'lodash/truncate';
import React, { useMemo, useState } from 'react';
import { Dropdown, Grid, Popup } from 'semantic-ui-react';

import { getChartConfig } from 'components/UI/ProspectEngagement/EngagementChart/helper';
import * as styles from 'components/UI/ProspectEngagement/EngagementChart/styles';
import { IProps } from 'components/UI/ProspectEngagement/EngagementChart/types';

const EngagementChart = <T,>({
  title,
  icon,
  count,
  series,
  min,
  max,
  color = '',
  lineColor = '#CCD8EE',
  filterValue,
  filterOptions = [],
  onFilterChange = () => {},
  tooltipFormatter,
  onPointClick,
}: IProps<T>) => {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const config = useMemo(
    () =>
      getChartConfig(
        min,
        max,
        color,
        lineColor,
        tooltipFormatter,
        onPointClick,
        series
      ),
    [
      min,
      max,
      color,
      lineColor,
      tooltipFormatter,
      onPointClick,
      JSON.stringify(series),
    ]
  );

  const handleOver = ({ target, type }: React.SyntheticEvent<EventTarget>) => {
    if (target instanceof HTMLSpanElement) {
      const { clientWidth, scrollWidth } = target;

      if (clientWidth < scrollWidth) {
        setPopupOpen(type === 'mouseenter');
      }
    }
  };

  return (
    <Grid.Row className={styles.row}>
      <Grid.Column width={4}>
        <div
          id={`engagement-chart-item-${title.replaceAll(' ', '')}`}
          className={styles.rowItemLabel}
        >
          {icon}

          <Popup
            content={<span>{title}</span>}
            open={isPopupOpen}
            position="bottom center"
            trigger={
              <span
                className={styles.ellipsis}
                onMouseEnter={handleOver}
                onMouseLeave={handleOver}
              >
                <span className="engagement-title">{title}</span>
                {count !== undefined && (
                  <span className="engagement-sub">({count})</span>
                )}
              </span>
            }
          />
        </div>

        {filterOptions?.length > 0 &&
          (filterOptions[0].value !== 'all' || filterOptions?.length > 1) && (
            <div className={styles.dropdownContainer}>
              {'By: '}
              <Dropdown
                id="engagement-chart-item-dropdown"
                className={styles.resetDefaultStyles}
                inline
                value={filterValue}
                text={truncate(filterValue, {
                  length: 16,
                  omission: '...',
                })}
                options={filterOptions}
                onChange={(e, { value }) =>
                  onFilterChange(
                    filterOptions.find((filter) => filter.value === value)!
                  )
                }
              />
            </div>
          )}
      </Grid.Column>
      <Grid.Column
        id={`engagement-chart-graph-${title.replaceAll(' ', '')}`}
        width={12}
      >
        <HighchartsReact
          highcharts={Highcharts}
          options={config}
          immutable={true}
          containerProps={{ className: styles.chartContainer }}
        />
      </Grid.Column>
    </Grid.Row>
  );
};

export default EngagementChart;
