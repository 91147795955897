import { css } from 'emotion';

export const wrapper = css`
  padding: 20px;
`;

export const linksWrapper = css`
  font-size: 20px;
  display: flex;
  flex-direction: column;
`;

export const link = css`
  border: 2px solid #eee;
  padding: 15px;
  margin-top: 15px;
  color: #222;

  &:hover {
    background: #eee;
    color: #222;
  }
`;
