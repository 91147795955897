import React, { useCallback, useState, useRef } from 'react';

export interface ModalProps {
  isOpen: boolean;
  onSave(props: unknown): void;
  onClose(): void;
}

type ModalExtraProps<T extends React.FC<{} & ModalProps>> = Omit<
  React.ComponentProps<T>,
  keyof ModalProps | 'children'
>;

type UseDataModalResult<T extends React.FC<{} & ModalProps>> = {
  open(
    props: ModalExtraProps<T>,
    onSave?: React.ComponentProps<T>['onSave']
  ): void;
  updateProps(props: Partial<ModalExtraProps<T>>): void;
  props: ModalExtraProps<T> & ModalProps;
};

export const useDataModal = <T extends React.FC<any>>(
  initialValue: ModalExtraProps<T>
): UseDataModalResult<T> => {
  const [isOpen, setOpen] = useState<React.ComponentProps<T>['isOpen']>(false);
  const [extraProps, setExtraProps] = useState<ModalExtraProps<T>>(
    initialValue as ModalExtraProps<T>
  );
  const ref = useRef<React.ComponentProps<T>['onSave']>();

  const onClose = useCallback<React.ComponentProps<T>['onClose']>(() => {
    setOpen(false);
  }, [setOpen]);

  const onSave = useCallback(
    (...args) => {
      setOpen(false);
      ref.current?.(...args);
    },
    [setOpen]
  );

  const open = useCallback<UseDataModalResult<T>['open']>(
    (props, onSave) => {
      setOpen(true);
      setExtraProps(props);
      ref.current = onSave;
    },
    [setOpen]
  );

  const updateProps = useCallback<UseDataModalResult<T>['updateProps']>(
    (props) => {
      setExtraProps((prevProps) => ({ ...prevProps, ...props }));
    },
    [setExtraProps]
  );

  return {
    open,
    updateProps,
    props: {
      ...extraProps,
      isOpen,
      onClose,
      onSave,
    },
  };
};
