import { css } from 'emotion';

import { fontDefault, checkedIcon } from 'assets/css/common';

const BORDER_STYLE = '1px solid var(--bu-table-border-color)';
const BUTTONS_MARGIN = '0 10px';

export const chartWrapper = css`
  border: ${BORDER_STYLE};
  border-radius: 4px;
  padding: 0;
  margin-bottom: 15px;
  height: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 1px 3px 0px #00000026;

  /* Style of the bar connector/link */
  .highcharts-graph {
    stroke: var(--bu-primary-700);
    stroke-width: 2;
  }
`;

export const toManyItemsWarning = css`
  position: absolute;
  left: 0;
  right: 0;
  top: 55px;
  line-height: 30px;
  height: 30px;
  color: #666666;
  background-color: #fdf0cc;
  z-index: 1;
`;

export const chartHeader = css`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  min-height: 50px;
  margin-left: 20px;
`;

export const chartHeaderRow = css`
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  justify-content: center;
`;

export const chartTitle = css`
  font-size: 16px;
  font-family: var(--bu-font-medium);
  color: var(--bu-gray-900);
`;

export const chartInfoIcon = css`
  display: inline-block;
  margin-left: 8px;

  img {
    margin-top: -2px;
    width: 22px;
  }
`;

export const chartChangeInterval = css`
  margin-top: 4px;
  font-family: var(--bu-font-regular);
  font-size: 14px;

  .green {
    color: var(--bu-green-500);
  }

  .red {
    color: var(--bu-red-400);
  }
`;

export const segment = css`
  min-height: 250px;
  margin: 0 !important;
  padding: 0 !important;
  background-color: var(--bu-white) !important;
`;

export const legendLabelContainer = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const legendLabelCheckbox = css`
  &.ui.checkbox {
    width: 12px;
    height: 12px;
    min-height: 12px;
    line-height: 12px;
    min-width: 12px;
    margin-right: 7px;
    margin-top: 8px;

    label:before {
      border-radius: 2px;
      width: 14px;
      height: 14px;
    }

    input:checked ~ label:before {
      background-color: var(--bu-primary-500) !important;
      border-color: var(--bu-primary-500) !important;
    }

    input:checked ~ label:after {
      content: '' !important;
      top: 3px;
      left: 3px;
      width: 8px !important;
      height: 8px !important;

      ${checkedIcon}
    }
  }
`;

export const circle_legend = (color?: string) => css`
  background-color: ${color};
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 6px;
`;

export const legendLabelCircle = css`
  margin-left: 4px;
  margin-right: 4px;

  &.green {
    color: #04dab2;
  }

  &.red {
    color: #ff8f8e;
  }

  &.orange_black {
    color: #ee724e;
  }
`;

export const legendLabelText = css`
  height: 16px;
  color: #000000;
  font-family: var(--bu-font-regular);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
`;

export const dropdown = css`
  z-index: 2;
  height: 32px;
  margin: ${BUTTONS_MARGIN};

  .ui.dropdown {
    padding: 4px 10px;
    border-radius: 4px;
    border: ${BORDER_STYLE};
    box-shadow: rgba(27, 31, 35, 0.05) 0px 1px 0px 0px;

    .dropdown {
      padding: 4px 10px;
    }

    .menu {
      width: 100% !important;
    }
  }
`;

export const additionalFunction = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const changesSelector = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #4a4a4a;
  background: var(--bu-white) !important;
  border-radius: 2px;
  border: 1px solid #9b9b9b;
  height: 30px;
  width: 205px;
  font-family: var(--bu-font-regular);
  font-size: 14px;
`;

export const timeSegments = css`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 20px;
`;

export const downloadBtn = css`
  margin: ${BUTTONS_MARGIN};
  width: auto;
  padding: 0;
`;

export const fullScreenBtn = css`
  width: 18px;
  height: 18px;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  margin: ${BUTTONS_MARGIN};

  img {
    width: 18px;
    height: 18px;
  }
  &:focus {
    outline: none;
  }
`;

export const widgetHeader = css`
  border-bottom: ${BORDER_STYLE};
`;

export const filterTabsContainer = css`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const filterTabsItem = css`
  ${fontDefault};
  cursor: pointer;
  color: var(--bu-gray-900);
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
  margin: 0 3px;
  padding: 6px 8px;
  border: 1px solid transparent;
  border-radius: 4px;

  &:hover {
    color: var(--bu-primary-500);
  }

  &.active {ß
    color: var(--bu-primary-500);
    background-color: var(--bu-primary-500);
    border-color: var(--bu-primary-500);
  }
`;

export const buttons = css`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
`;

export const chartContainer = css`
  overflow: visible !important;
  padding: 0 0 15px 15px;
  width: 100%;
  height: 100%;
  max-height: 1000px;

  .highcharts-yaxis-grid .highcharts-grid-line:last-child {
    display: none;
  }
`;

export const emptyData = css`
  width: 100%;
  height: 100%;
  min-height: 415px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bu-white);

  p {
    font-size: 14px;
    font-family: var(--bu-font-regular);
    color: var(--bu-gray-700);
  }
`;

export const segment_modal = css`
  min-height: 250px;
  margin: 0 !important;
  padding: 0 !important;
`;

export const extraFilter = css`
  margin-right: 15px;
`;
