import { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { actions } from 'actions';

type RefData = {
  ref: HTMLTableHeaderCellElement;
  pageX: number;
  width: string;
  id: string;
};

type CurrentRef = RefData & {
  startOffset: number;
};

export type OnSelectColumn = (props: RefData) => void;

export const useTableColumnResize = () => {
  const [column, setColumn] = useState<CurrentRef | undefined>(undefined);

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const mouseMove = useCallback(
    (e: MouseEvent) => {
      if (column) {
        column.ref.style.minWidth = column.startOffset + e.pageX + 'px';
      }
    },
    [column]
  );

  const mouseUp = useCallback(() => {
    if (column) {
      setColumn(undefined);

      dispatch(
        actions.ui.appState.resizeColumn({
          path: pathname,
          column: {
            width: column.ref.style.minWidth,
            id: column.id,
          },
        })
      );
    }
  }, [pathname, column]);

  useEffect(() => {
    window.addEventListener('mouseup', mouseUp);
    window.addEventListener('mousemove', mouseMove);

    return () => {
      window.removeEventListener('mouseup', mouseUp);
      window.removeEventListener('mousemove', mouseMove);
    };
  }, [mouseUp, mouseMove]);

  const onSelectColumn: OnSelectColumn = useCallback((data) => {
    setColumn({
      ...data,
      startOffset: data.ref.offsetWidth - data.pageX,
    });
  }, []);

  return onSelectColumn;
};
