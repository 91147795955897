import { capitalize } from 'lodash';
import moment from 'moment';
import * as R from 'ramda';

export default {
  getTimeSinceLastEngaged: (c) => {
    if (c.is_engaged) {
      const lastEngaged = R.path(['last_engaged', 'contacted_at'], c);
      if (!R.isNil(lastEngaged)) {
        return moment(lastEngaged).fromNow();
      }
    }
    return false;
  },

  getLastEngagedType: (c) => {
    if (c.is_engaged) {
      const lastEngaged = R.path(['last_engaged', 'contacted_at'], c);
      const weeksSinceLastEngaged = moment().diff(lastEngaged, 'weeks');
      if (weeksSinceLastEngaged < 2) {
        return 'Engaged Recently';
      }
      if (weeksSinceLastEngaged < 4) {
        return 'Disengaged Recently';
      }
      return 'Disengaged';
    }
    return 'Not Engaged Yet';
  },

  getContactType: (c) => {
    const contact = capitalize(R.path(['last_engaged', 'ttype'], c));
    if (contact === 'Event') return 'Meeting Accepted';
    return contact;
  },
};
