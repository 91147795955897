import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Tab } from 'semantic-ui-react';

import { ValueProp } from 'components/UI/common/TypedTable/TypedTable';
import PipelineSummary from 'components/dashboard/ForecastRollUps/CustomColumnTooltips/MyOrgCustomTooltip/PipelineSummary';
import ForecastSummary from 'components/dashboard/ForecastRollUps/CustomColumnTooltips/MyOrgCustomTooltip/ForecastSummary';
import styles from 'components/dashboard/ForecastRollUps/CustomColumnTooltips/styles';
import { getMyOrgTooltip } from 'components/dashboard/ForecastRollUps/helpers';
import { IReduxState } from 'reducers/types';
import {
  getFeatureFlags,
  getBusinessTypesList,
  getSelectedBusinessType,
  getCompanySettings,
} from 'selectors';
import { FiltersForAPI } from 'selectors/openFilters';

export type Props = {
  pipelineSummaryFilters?: FiltersForAPI;
  rollUpsFilters: FiltersForAPI;
  managerEmail: string;
  companyCurrency: string;
  includeDisabledUsers: boolean;
  splitType?: string;
  args: { [key: string]: ValueProp };
};

const MyOrgCustomTooltip: React.FC<Props> = (props) => {
  const { enable_pipeline_summary, forecast_summary_enabled } = useSelector(
    (state) => getFeatureFlags(state)
  );
  const businessTypesList = useSelector(getBusinessTypesList);
  const { rollup_overall_view_enabled } = useSelector(getCompanySettings);
  const selectedBusinessType = useSelector((state: IReduxState) =>
    getSelectedBusinessType(
      state,
      'forecast_roll_ups',
      rollup_overall_view_enabled
    )
  );

  const businessTypeConfig = useMemo(
    () =>
      businessTypesList.find(
        (businessType: any) => businessType.name === selectedBusinessType
      ),
    [selectedBusinessType, businessTypesList]
  );

  const tabs: {
    menuItem?: string;
    render?: () => JSX.Element;
  }[] = [
    forecast_summary_enabled
      ? {
          menuItem: 'Forecast Summary',
          render: () => (
            <ForecastSummary
              managerEmail={props.managerEmail}
              rollUpsFilters={props.rollUpsFilters}
              includeDisabledUsers={props.includeDisabledUsers}
              companyCurrency={props.companyCurrency}
            />
          ),
        }
      : {},
    enable_pipeline_summary && businessTypeConfig.enable_pipeline_summary
      ? {
          menuItem: 'Pipeline Summary',
          render: () => (
            <PipelineSummary
              {...props}
              pipelineSummaryFilters={
                businessTypeConfig.pipeline_summary_filters
              }
              splitType={businessTypeConfig.split_type}
            />
          ),
        }
      : {},
  ].filter((tab) => tab.render);

  return (
    <>
      {enable_pipeline_summary || forecast_summary_enabled ? (
        <div className={styles.myOrgTooltipContainer}>
          <Tab menu={{ secondary: true, pointing: true }} panes={tabs} />
        </div>
      ) : (
        getMyOrgTooltip(null, props.args)
      )}
    </>
  );
};

export default MyOrgCustomTooltip;
