import { toast } from 'react-toastify';
import { combineReducers } from 'redux';

import * as t from 'actions/actionTypes';
import { Targets } from 'reducers/sellerTargetsReducer/types';

const targetsDefault = {
  overall_targets: [],
  user_targets_by_role: [],
  targets: [],
  loading: false,
};

export const sellerTargets = (state: Targets = targetsDefault, action: any) => {
  switch (action.type) {
    case t.TARGETS + t.GET + t.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case t.TARGETS + t.GET + t.SUCCESS: {
      return {
        ...state,
        targets: action.targets,
        loading: false,
      };
    }
    case t.TARGETS + t.GET + t.ERROR: {
      return {
        targets: [],
        errors: action.errors,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

const setTargetsDefault = {
  targetChanged: false,
  hiddenColumns: [],
  loading: false,
};

export const setTargetReducer = (
  state: any = setTargetsDefault,
  action: any
) => {
  switch (action.type) {
    case t.TARGETS + t.SET + t.SUCCESS: {
      return {
        ...state,
        targetChanged: true,
        loading: false,
      };
    }
    case t.TARGETS + t.SET + t.REQUEST: {
      return {
        ...state,
        targetChanged: false,
        loading: true,
      };
    }
    case t.TARGETS + t.SET + t.ERROR: {
      // TODO: Reducers are for data. Drop this code and use the sagas!
      toast.error(action.error, { position: 'bottom-left' });
      return {
        ...state,
        targetChanged: false,
        loading: false,
      };
    }
    case t.TARGETS + t.SET + t.HIDDEN_COLUMNS: {
      return {
        ...state,
        hiddenColumns: action.payload.hidden,
      };
    }
    default: {
      return state;
    }
  }
};

export const targetsReducer = combineReducers({
  targets: sellerTargets,
  setTarget: setTargetReducer,
});
