import { css } from 'emotion';

import { fontDefault } from 'assets/css/common';

export const styles = {
  email_empty_modal: css`
    text-align: center;
    font-size: 20px;
    padding: 30px;
  `,
  align_left: css`
    text-align: left;
  `,
  box_email: css`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
    text-align: left;
  `,
  box_email_line: css`
    display: grid;
    grid-template-columns: 1.5fr 11fr;
  `,
  box_email_attachments: css`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: row;
    gap: 10px;
  `,
  box_email_icon: css`
    display: flex;
    justify-content: flex-end;
    font-family: var(--bu-font-regular);
    font-size: 14px;
    color: var(--bu-gray-700);
    padding-right: 13px;
    padding-top: 2px;

    img {
      width: 30px;
      height: 30px;
    }
  `,
  email_body_box: css`
    font-size: 16px;
    color: #4a4a4a;
    max-width: 600px;
    line-height: 24px;
  `,
  email_attachment: css`
    box-sizing: border-box;
    padding: 8px 10px;
    width: fit-content;
    color: var(--bu-primary-500);
    border: 1px solid var(--bu-gray-400);
    border-radius: 2px;
    display: flex;
    align-items: center;
    font-size: 14px;

    i {
      margin-right: 5px;

      :before {
        font-size: 18px;
      }
    }

    span {
      max-width: 11em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `,
  email_attachment_tooltip: css`
    font-size: var(--bu-font-size-small);
  `,
  margin_bottom: css`
    margin-bottom: 27px;
  `,
  subject_name: css`
    margin-bottom: 8px;
    margin-top: 9px;
    font-size: var(--bu-font-size-big);
    color: var(--bu-gray-900);
  `,
  date: css`
    color: var(--bu-gray-700);
    ${fontDefault};
  `,
  text: css`
    color: var(--bu-gray-900);
    ${fontDefault};
    text-align: left;
    line-height: 20px;
  `,
  match: css`
    background-color: var(--bu-yellow-300);
    padding: 0 3px;
  `,
  link_btn: css`
    padding: 7px 14px;
    color: #0762ec;
    font-size: var(--bu-font-size-small);
    font-family: var(--bu-font-medium);
    border: 1px solid #0762ec;
    border-radius: 2px;
    cursor: pointer;

    img {
      width: 25px;
      height: 18px;
      margin-right: 10px;
    }
  `,
  circle: css`
    width: 32px;
    height: 32px;
    display: inline-block;
    position: relative;
    border-radius: 50%;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: var(--bu-font-size-small);
      color: var(--bu-white);
    }

    &.blue {
      background: var(--bu-primary-500);
    }
  `,
  left_icons: css`
    color: var(--bu-gray-900);
    font-size: var(--bu-font-size-big);
  `,
};

export default styles;
