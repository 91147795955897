import styled from '@emotion/styled/macro';
import classNames from 'classnames';
import React, { useState } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import {
  Panel,
  PanelCollapseExpandButton,
  PanelContainer,
  PanelSectionContainer,
} from 'components/UI/NavPanel/styles';
import Tooltip from 'components/UI/common/Tooltip';

const CollapseExpandButton: React.FC<{
  active: boolean;
  visible: boolean;
  onClick(): void;
}> = ({ active, onClick, visible }) => (
  <PanelCollapseExpandButton
    onClick={onClick}
    visible={visible}
    className={classNames({ visible })}
  >
    <Tooltip tooltip={active ? 'collapse' : 'fix opened'} position="top center">
      <BuIcon
        name={active ? BoostUpIcons.ChevronLeft : BoostUpIcons.ChevronRight}
      />
    </Tooltip>
  </PanelCollapseExpandButton>
);

const PanelSectionContainerRevBi = styled(PanelSectionContainer)`
  & > *:last-child {
    margin-bottom: 0px;
  }
`;

export const SubNavPanel: React.FC = ({ children }) => {
  const [active, setActive] = useState(true);
  const handleSwitchOpen = () => setActive((prev) => !prev);
  return (
    <Panel bgColor="var(--bu-white)">
      <PanelContainer
        active={active}
        data-se="settings-sections"
        className={classNames({ active })}
      >
        <PanelSectionContainerRevBi active={active} style={{}}>
          {children}
        </PanelSectionContainerRevBi>

        <CollapseExpandButton
          active={active}
          onClick={handleSwitchOpen}
          visible={active}
        />
      </PanelContainer>

      <CollapseExpandButton
        active={active}
        onClick={handleSwitchOpen}
        visible={!active}
      />
    </Panel>
  );
};
