import classNames from 'classnames';
import Highcharts from 'highcharts';
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { formatAmount } from 'common/helpers';
import { addTargetLine } from 'common/highcharts-helpers';
import * as styles from 'components/dashboard/ForecastDashboard/TrackingDashboard/Chart/styles';
import {
  TooltipWrapper,
  TooltipDefaultText,
  TooltipAccentText,
} from 'components/dashboard/ForecastDashboard/TrackingDashboard/Chart/styles';
import {
  PointCustomType,
  SeriesCustomType,
  StatByColumn,
} from 'components/dashboard/ForecastDashboard/TrackingDashboard/Chart/types';
import {
  MainMetrics,
  Series,
  Stats,
  StatMetrics,
  PointCustomItems,
} from 'components/dashboard/ForecastDashboard/TrackingDashboard/types';
import { STACK_LABELS_STYLES } from 'components/dashboard/ForecastDashboard/Widget3/helper';

interface HoveredPoint {
  graphic?: {
    element: HTMLElement;
  };
  custom?: {
    field: string;
  };
}

const SeriesOrder: Array<StatMetrics> = [
  'pipeline',
  'best_case',
  'commit',
  'booked',
  'total_target',
];

const getOrderedSeries = (columns: Series[]) =>
  SeriesOrder.map((field) => columns.find((column) => column.field === field)!)
    .concat(columns.filter((item) => !SeriesOrder.includes(item.field)))
    .filter((item) => !!item);

type PipelineCoverageFields = Record<MainMetrics, boolean>;
const defaultPipelineCoverageState: PipelineCoverageFields = {
  pipeline: false,
  best_case: false,
  commit: false,
  booked: false,
};

type Props = {
  series: Series[];
  stat?: Stats;
  yAxisWidth: number;
  pointWidth: number;
  fluid: boolean;
  companyCurrencyCode: string;
};
export const getChartOptions = ({
  series,
  stat,
  yAxisWidth,
  pointWidth,
  fluid,
  companyCurrencyCode,
}: Props): Highcharts.Options => {
  const BAR_WIDTH = 40;

  const plotWidth = pointWidth * (stat?.periods.length ?? 0);

  const seriesWithoutTarget = series.filter(
    (item) => item.field !== 'total_target'
  );

  const target = stat?.data.find((item) => item.name === 'total_target');
  const targetSeries = series.find((item) => item.field === 'total_target');
  const targetValue = target?.values[0] ?? 0;

  const firstVisibleColumn = getOrderedSeries(series).find(
    (item) => item.type === 'column' && item.visible
  );

  const pipelineCoverageFields = seriesWithoutTarget
    .filter((item) =>
      Object.keys(defaultPipelineCoverageState).includes(item.field)
    )
    .reduce<PipelineCoverageFields>((acc, item) => {
      acc[item.field as keyof PipelineCoverageFields] = item.visible;
      return acc;
    }, defaultPipelineCoverageState);

  const options = {
    legend: { enabled: false },
    chart: {
      height: 340,
      marginLeft: yAxisWidth,
      marginTop: 0,
      marginBottom: 0,
      marginRight: 0,
      width: fluid || plotWidth === 0 ? undefined : yAxisWidth + plotWidth,
      plotBorderWidth: 0,
      plotBorderColor: '#D6D7DE',
      className: classNames(
        styles.chartLinesStyles,
        styles.chartTargetLabelPill
      ),
    },
    title: { text: '' },
    tooltip: {
      delay: 100,
      hideDelay: 0,
      centerAnchor: true,
      stickOnContact: true,
      borderRadius: 2,
      padding: 0,
      useHTML: true,
      formatter() {
        const { custom } = this.point as PointCustomItems;
        const label = this.series.data.find(
          (item) => item.category === this.x?.toString()
        )?.series.name;
        const value = formatAmount(
          companyCurrencyCode,
          this.y ?? custom?.column[custom?.field] ?? 0
        );
        return renderToStaticMarkup(
          <TooltipWrapper>
            <TooltipDefaultText>{label ?? custom?.field}</TooltipDefaultText>
            <br />
            <TooltipAccentText>{this.x}</TooltipAccentText>
            <br />
            <TooltipAccentText>{value}</TooltipAccentText>
          </TooltipWrapper>
        );
      },
      positioner(this, labelWidth, labelHeight, point) {
        const snapDistance = 10;
        const { hoverPoints, chartHeight, chartWidth } = this.chart;
        const hoveredPoint: unknown = hoverPoints ? hoverPoints[0] : null;

        if (!hoveredPoint) {
          return undefined;
        }

        const { graphic, custom } = hoveredPoint as HoveredPoint;
        const pointArea = graphic?.element.getBoundingClientRect();

        const lines = this.chart.series.reduce(
          (acc: string[], curr: Highcharts.Series) => {
            if (curr.type !== 'line') {
              return acc;
            }

            const field = (curr.userOptions.custom?.series?.field || '').trim();
            return [...acc, field];
          },
          []
        );
        const isLine = custom && lines.includes(custom.field.trim());

        let x = 0;
        let y = 0;

        // Adjusting position for lines tooltips
        if (isLine && custom?.field) {
          x = yAxisWidth + point.plotX - labelWidth / 2;
          y = point.plotY - snapDistance - labelHeight;
        }

        // Fixing LEFT overflow (if necessary)
        const leftOverflowLimit = point.plotX - labelWidth - snapDistance;
        if (leftOverflowLimit < 0) {
          x += labelWidth / 2 + snapDistance;
        }

        // Fixing TOP overflow (if necessary), or adjusting position for columns tooltips
        if (y < 0 || !isLine) {
          x =
            yAxisWidth +
            point.plotX +
            (pointArea?.width ?? 0) / 2 +
            snapDistance;
          y = point.plotY - labelHeight / 2 + (pointArea?.height ?? 0) / 2;
        }

        // Fixing BOTTOM overflow (if necessary)
        const bottomOverflowLimit = labelHeight + y + snapDistance;
        if (bottomOverflowLimit > chartHeight) {
          y -= bottomOverflowLimit - chartHeight;
        }

        // Fixing RIGHT overflow (if necessary)
        const rightOverflowLimit =
          yAxisWidth + point.plotX + labelWidth + snapDistance;
        if (rightOverflowLimit > chartWidth) {
          x -= labelWidth / 2 + snapDistance;
        }

        return {
          x,
          y,
        };
      },
    },
    xAxis: [
      {
        categories: stat?.periods.map((item) => item) ?? [],
        minPadding: 0,
        maxPadding: 0,
        crosshair: false,
      },
    ],
    yAxis: [
      {
        title: { text: '' },
        labels: {
          formatter() {
            return this.isFirst || this.isLast
              ? ''
              : `${formatAmount(
                  companyCurrencyCode,
                  parseFloat(this.value.toString())
                )}`;
          },
        },
        gridLineDashStyle: 'Dash',
      },
    ],
    plotOptions: {
      series: {
        stickyTracking: false,
        states: {
          hover: {
            brightness: 0,
          },
          inactive: {
            opacity: 0.4,
          },
        },
      },
      column: {
        stickyTracking: false,
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          crop: false,
          inside: false,
          overflow: 'justify',
          formatter() {
            // TODO: add the coverage value from the BE side
            return null;
          },
          className: styles.totalValue,
          ...STACK_LABELS_STYLES,
        },
      },
      line: {
        lineWidth: 3,
        stickyTracking: false,
      },
    },
    series: getOrderedSeries(seriesWithoutTarget).map<
      Highcharts.SeriesColumnOptions | Highcharts.SeriesLineOptions
    >((series, index) => {
      const item = stat?.data.find((i) => i.name === series.field);

      const statPoint = stat?.data.reduce<StatByColumn>((acc, s) => {
        acc[s.name] = s.values[index];
        return acc;
      }, {} as StatByColumn);

      const data = item?.values.map<Highcharts.PointOptionsObject>(
        (value, x) => ({
          y: value,
          x: x,
          custom: {
            field: series.field,
            column: statPoint,
          } as PointCustomType,
        })
      );

      const baseSeries:
        | Highcharts.SeriesColumnOptions
        | Highcharts.SeriesLineOptions = {
        type: series.type,
        name: series.name,
        visible: series.visible,
        color: series.color,
        xAxis: 0,
        custom: {
          series: series,
        } as SeriesCustomType,
      };

      if (series.type === 'line') {
        return {
          ...baseSeries,
          marker: {
            lineWidth: 3,
            lineColor: series.color,
            fillColor: 'white',
            symbol: 'circle',
            enabled: !(
              ['total_target', 'ideal_target'] as StatMetrics[]
            ).includes(series.field),
          },
          xAxis: 0,
          dashStyle: series.dashStyle,
          data: data,
        } as Highcharts.SeriesLineOptions;
      }

      return {
        ...baseSeries,
        pointWidth: BAR_WIDTH,
        data: data,
      } as Highcharts.SeriesColumnOptions;
    }),
  } as Highcharts.Options;

  return targetSeries && targetValue
    ? addTargetLine(options, {
        label: `${targetSeries.name}: ${formatAmount(
          companyCurrencyCode,
          targetValue
        )}`,
        name: targetSeries.name,
        value: targetValue,
        visible: targetSeries.visible,
        color: targetSeries.color,
        dashStyle:
          targetSeries.type === 'line' ? targetSeries.dashStyle : 'Dash',
      })
    : options;
};
