import { IReduxState } from 'reducers/types';

export const newPipelineCreated = (state: IReduxState) =>
  state.pipeline.newPipelineCreated;

export const pipelineCreatedByCloseDate = (state: IReduxState) =>
  state.pipeline.pipelineCreatedByCloseDate;

export const newPipelineCreatedLoading = (state: IReduxState) =>
  state.pipeline.newPipelineCreatedLoading;

export const pipelineCreatedByCloseDateLoading = (state: IReduxState) =>
  state.pipeline.pipelineCreatedByCloseDateLoading;

export const getNewPipelineCreatedFilters = (state: IReduxState) =>
  state.pipeline.newPipelineCreatedFilters;

export const getPipelineCreatedByCloseDateFilters = (state: IReduxState) =>
  state.pipeline.pipelineCreatedByCloseDateFilters;
