import * as styles from './styles';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { Fragment } from 'react';
import { Loader } from 'semantic-ui-react';

const handleClick = (e, onSearch) => {
  if (e.button === 0) {
    onSearch();
  }
};

const SearchResults = ({
  query,
  results,
  loading,
  hasMoreResults,
  renderResults,
  onSearch,
  onResultClick,
}) => (
  <div className={styles.results}>
    {loading ? (
      <div className={styles.resultsLoading}>
        <Loader active inline />
      </div>
    ) : R.either(R.isNil, R.isEmpty)(results) ? (
      <div className={styles.resultsEmpty}>
        No results matched the provided query
      </div>
    ) : (
      <Fragment>
        {renderResults(results, onResultClick)}
        {hasMoreResults ? (
          <div
            className={styles.resultsHasMore}
            onMouseDown={(e) => handleClick(e, onSearch)}
          >
            See All Results for '{query}'
          </div>
        ) : null}
      </Fragment>
    )}
  </div>
);

SearchResults.propTypes = {
  query: PropTypes.string.isRequired,
  results: PropTypes.any.isRequired,
  loading: PropTypes.bool,
  hasMoreResults: PropTypes.bool,
  renderResults: PropTypes.func.isRequired,
  onSearch: PropTypes.func,
  onResultClick: PropTypes.func.isRequired,
};

export default SearchResults;
