import omit from 'lodash/omit';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { actions } from 'actions';
import { ModalPersistParams } from 'navigation/types';

export type IState = {
  [scheme: string]: ModalPersistParams;
};

const initialState: IState = {};

const reducer = reducerWithInitialState<IState>(initialState)
  .case(
    actions.ui.modal.persist,
    (state, payload): IState => ({
      ...state,
      [payload.scheme]: {
        props: payload.persistParams,
        options: payload.modalOptions,
      },
    })
  )
  .case(
    actions.ui.modal.remove,
    (state, payload): IState => omit(state, [payload.scheme])
  );

export const modal = { initialState, reducer };
