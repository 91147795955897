import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { actions } from 'actions';
import { WidgetStateRecord } from 'components/chart-dashboards/Widget/types';

export type IState = {
  [id: string]: WidgetStateRecord;
};

const initialState: IState = {};

const reducer = reducerWithInitialState<IState>(initialState).case(
  actions.ui.widget.persist,
  (state, payload): IState => ({
    ...state,
    [payload.id]: {
      series: payload.series,
      serializedQueryParams: payload.serializedQueryParams,
    },
  })
);

export const widget = { initialState, reducer };
