import { css } from 'emotion';

export const errorContainer = css`
  height: 100%;
  display: flex;
  align-items: center;
`;
export const popupInfoContainer = css`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  padding: 7px;
  text-align: center;
  max-width: 194px;
`;
