import get from 'lodash/get';
import { connect, MapDispatchToPropsParam } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { actions } from 'actions';
import * as cacheActions from 'actions/cacheActions';
import * as dealsActions from 'actions/dealsActions';
import DealsTable from 'components/UI/DealsFlatTableConfig/DealsTable';
import {
  StateProps,
  DispatchProps,
  OwnProps,
} from 'components/UI/DealsFlatTableConfig/types';
import { PersistedTableItem } from 'components/UI/DealsFlatTableTS/Table/types';
import { IRow } from 'components/UI/common/TypedTable/TypedTable';
import * as selectors from 'selectors';

const mapStateToProps = (state: StateProps, ownProps: OwnProps) => ({
  user_role: selectors.getUserRole(state),
  columnPreferences: selectors.getCompanyColumnPreferences(state),
  hiddenColumns: selectors.getHiddenColumns(state),
  forceRefresh: selectors.getForceRefresh(state),
  companyDomain: selectors.getCompanyDomain(state),
  persistedState: (
    selectors.getTablePrevState(
      state as any,
      'common',
      ownProps.persistName
    ) as any
  ).filter,
  sortableFields: selectors.getSortableFields(state),
  sortDealsCachedState: selectors.getTablePrevState(
    state as any,
    'deals',
    ownProps.title
  ) as any,
  statuses: selectors.getDealStatuses(state),
  draftData: selectors.getDraftTableRows(`opportunities`, state),
  dealsIncludedCache: selectors.getDealsIncluded(state),
  isReadOnlyUser: selectors.isReadOnlyUser(state),
  isTableConfigurationLoading:
    selectors.isSettingsTableConfigurationLoading(state),
});

const dispatchToProps: MapDispatchToPropsParam<DispatchProps, OwnProps> = (
  dispatch,
  ownProps
) => ({
  describeFields: () => dispatch(dealsActions.describeFields()),
  persistTableParams: (value: PersistedTableItem) =>
    dispatch(actions.ui.table.persist(value)),
  setDealsCount: (title: string, count: number) =>
    dispatch(dealsActions.setDealsCount(title, count)),
  changeDeal: (
    id: string,
    changes: object,
    isARealTimeUpdate = false,
    origin: string
  ) => {
    dispatch(dealsActions.changeDeal(id, changes, isARealTimeUpdate, origin));
  },
  updateDraftData: (draftTableRows: IRow[]) =>
    dispatch(
      cacheActions.updateDraftTableData(`opportunities`, draftTableRows)
    ),
  setDealsIncludedCache: (value: string[], submissionId: string) => {
    if (!ownProps.noResave) {
      dispatch(cacheActions.setIncludedDeals(value, submissionId));
    }
  },
});

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
) => {
  const props = { ...stateProps, ...dispatchProps, ...ownProps };

  if (typeof stateProps.persistedState === 'object') {
    props.initialRequest = {
      ...props.initialRequest,
      page_number: stateProps.persistedState.page_number,
      sort: stateProps.persistedState.sort,
    };
    props.persistedState = props.initialRequest;
    const overWrittenloseDateInterval = get(
      ownProps,
      'initialRequest.close_date_interval',
      false
    );
    if (overWrittenloseDateInterval) {
      props.persistedState.close_date_interval = overWrittenloseDateInterval;
    }
  }

  return props;
};

export default connect(
  mapStateToProps,
  dispatchToProps,
  mergeProps
)(withRouter(DealsTable));
