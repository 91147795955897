import Highcharts from 'highcharts';

export interface SeriesSankeyOrderedNodesOptionsObject
  extends Highcharts.SeriesSankeyNodesOptionsObject {
  order?: number;
}

export type SankeyColumn = Highcharts.SankeyNodeObject[] & {
  sum(): number;
  top(factor: number): number;
  offset(node: Highcharts.SankeyNodeObject, factor: number): number;
  name: number;
};

/**
 * Highcharts mixin for Sankey chart, it is allowing set node order property, it is helpful for keeping correct node order
 */
export default function (H: any) {
  H.wrap(
    H.seriesTypes.sankey.prototype,
    'createNodeColumns',
    function (this: any, proceeds: any) {
      const columns: SankeyColumn[] = proceeds.apply(
        this,
        Array.prototype.slice.call(arguments, 1)
      );

      columns.forEach((column, index) => {
        column.sort((a, b) => {
          const { order: aOrder = 0 } =
            a.options as SeriesSankeyOrderedNodesOptionsObject;
          const { order: bOrder = 0 } =
            b.options as SeriesSankeyOrderedNodesOptionsObject;
          return aOrder - bOrder;
        });
        column.name = this.options?.columns?.[index];
      });

      return columns;
    }
  );
}
