import React, { Dispatch, SetStateAction } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import {
  MetricTitle,
  MetricsButtonsContainer,
  SavedMetricBlock,
  ActionButtonsContainer,
  SavedMetricInfo,
} from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/DefinitionsMetricsList/styles';
import { MetricInfo } from 'components/dashboard/Metrics/common/MetricInfo/MetricInfo';
import { MetricInfoContainer } from 'components/dashboard/Metrics/metrics.styles';
import { BIMetricCreated } from 'components/dashboard/Metrics/metrics.types';

interface Props {
  metricsFromList: BIMetricCreated[];
  setIsSideBarOpen: Dispatch<SetStateAction<boolean>>;
  onRemoveMetric: (metric: BIMetricCreated) => void;
}

export const FunnelMetricsList: React.FC<Props> = ({
  metricsFromList,
  setIsSideBarOpen,
  onRemoveMetric,
}) => {
  return (
    <>
      {metricsFromList?.map((metric: BIMetricCreated, idx: number) => (
        <SavedMetricBlock
          key={metric._id}
          data-testing={`metric-wrapper-${idx}`}
        >
          <SavedMetricInfo>
            <MetricInfoContainer data-testing={`info-metric-${idx}`}>
              <MetricTitle>
                {metric?.name ?? '-metric_name_placeholder-'}
              </MetricTitle>
              <MetricInfo hasPopups metric={metric} />
            </MetricInfoContainer>

            <ActionButtonsContainer>
              <BuButton
                borderless
                icon
                size={BuControlSize.SMALL}
                onClick={() => onRemoveMetric(metric)}
                testingLabel={`trash-metric-${idx}`}
              >
                <BuIcon name={BoostUpIcons.Trash} />
              </BuButton>
            </ActionButtonsContainer>
          </SavedMetricInfo>
        </SavedMetricBlock>
      ))}

      <div>
        <span>Add an existing metric</span>
      </div>

      <MetricsButtonsContainer>
        <BuButton secondary onClick={() => setIsSideBarOpen(true)}>
          + Existing Metric
        </BuButton>
      </MetricsButtonsContainer>
    </>
  );
};
