import styled from '@emotion/styled';
import { css } from 'emotion';

export const DropDownItemsContainer = styled.div`
  width: 350px;
  height: 33vh;
  overflow: hidden;

  @media (max-height: 768px) {
    height: 20vh;
  }

  @media (max-height: 576px) {
    height: 15vh;
  }
`;

export const ButtonContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  height: '48px',
  padding: '0px 10px',
  gap: '5px',
});

export const dropdownLabel = css`
  font-size: 14px;
`;

export const tableColumnsSelectionStyle = css`
  width: 18px;
`;
