import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'actions';
import { ForecastSubmissionSettingHistoryReduxState } from 'common/types/forecastSubmission';
import * as styles from 'components/dashboard/Forecast/Dashboard/SubmitForecast/styles';
import { Formatter } from 'components/dashboard/Forecast/Dashboard/SubmitForecast/types';
import { splitByNewline } from 'helpers/splitByNewline';
import { PersistQueryParams } from 'navigation/types';
import { openModal } from 'navigation/utils';
import { getForecastActiveBusinessType } from 'selectors';

type Props = {
  formatter: Formatter;
  historyItem: ForecastSubmissionSettingHistoryReduxState[0];
  single?: boolean;
  submissionSettingId: string;
};

const HistoryItem: React.FC<Props> = ({
  formatter,
  historyItem,
  single,
  submissionSettingId,
}) => {
  const {
    amount,
    created_at,
    len_included_deals,
    notes,
    included_deals_amount,
  } = historyItem;

  const activeBusinessType = useSelector(getForecastActiveBusinessType);
  const dispatch = useDispatch();

  const openIncludedDealsModal = () => {
    const businessType = activeBusinessType ? `[${activeBusinessType}]` : '';
    const title = `${businessType} ${historyItem.forecast_name} included deals`;
    openModal({
      scheme: '/deals/included/:submissionId',
      params: {
        submissionId: historyItem.id,
        localStorageKeyPrefix: 'DealsIncluded',
      },
      persistParams: {
        apiUrl: `/api/data/forecast/submission/deals_data_history/${historyItem.id}`,
        title: title,
        isHistoricalSubmissionsModal: true,
        filterIncludedTogglerInitialState: 'on',
        submissionSetting: { id: submissionSettingId },
      },
      persistor: (params: PersistQueryParams) =>
        dispatch(actions.ui.modal.persist(params)),
    });
  };

  const createdAt = moment.utc(created_at);

  return (
    <div
      className={classNames(styles.historyItemContainer, 'sf-history-item', {
        single,
      })}
    >
      <div className={styles.historyDate}>{createdAt.fromNow()}</div>

      <div className={styles.historyRow}>
        <div className={styles.historyValue}>{formatter(amount)}</div>
        {len_included_deals !== null && len_included_deals !== undefined && (
          <div
            className={styles.historyDealsButton}
            onClick={openIncludedDealsModal}
          >
            {`Deals Included: ${len_included_deals} (${formatter(
              included_deals_amount
            )})`}
          </div>
        )}
      </div>

      {notes && (
        <div className={classNames(styles.historyNotes, { empty: !notes })}>
          {splitByNewline(notes)}
        </div>
      )}
    </div>
  );
};

export default HistoryItem;
