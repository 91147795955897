import { connect } from 'react-redux';

import ChildWidgetDealsModal from 'components/modals/ChildWidgetDealsModal/ChildWidgetDealsModal';
import {
  OwnProps,
  PersistedProps,
} from 'components/modals/ChildWidgetDealsModal/types';
import { IReduxState } from 'reducers/types';
import { getModalProps, getFiltersForAPI, getModalOptions } from 'selectors';

const stateToProps = (state: IReduxState, props: OwnProps) => ({
  filters:
    props.params.tab === 'accounts-deals'
      ? ({ account_id: null } as any)
      : getFiltersForAPI(
          state,
          props.params.tab === 'accounts'
            ? 'accounts'
            : 'forecast_opportunities'
        ),
  modalProps: getModalProps<PersistedProps>(state, '/deals/:tab'),
  modalOptions: getModalOptions<PersistedProps>(state, '/deals/:tab'),
});

export default connect(stateToProps)(ChildWidgetDealsModal);
