import classNames from 'classnames';
import React, { useRef, useEffect } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import setElementInViewport from 'components/UI/OpenFiltersPanel/Dropdowns/setElementInViewport';
import * as s from 'components/UI/OpenFiltersPanel/styles';
import { Props } from 'components/UI/OpenFiltersPanel/types';
import { useClickOutside } from 'components/hooks/useClickOutside';
import { LayoutTextFilter } from 'components/settings/FieldConfiguration/CustomFieldsConfiguration/TextConfigCustom';

export const DropdownText: React.FC<Props> = ({
  config,
  isProfileSettings,
  name,
  onChange,
  onClose,
  className,
}) => {
  const { isOpen, setIsOpen, refElement } = useClickOutside();

  const { isChanged, isLocked, title, values } = config;

  const panelRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setElementInViewport(panelRef);

    if (!isOpen && onClose) {
      onClose();
    }
  }, [isOpen]);

  return (
    <div
      id={`${name}_dropdown`}
      ref={refElement}
      className={classNames(s.panel_wrapper, className)}
    >
      <div
        ref={labelRef}
        className={classNames(s.panel_dropdown_button, {
          isChanged,
          isLocked: !isProfileSettings && isLocked,
          isOpen,
        })}
        onClick={() => !isLocked && setIsOpen(!isOpen)}
      >
        <span className={s.panel_dropdown_name}>{title}:</span>
        <span className={s.panel_dropdown_value}>{values[0].value}</span>

        <BuIcon
          name={isOpen ? BoostUpIcons.TriangleUp : BoostUpIcons.TriangleDown}
          color="var(--bu-gray-900)"
          className={s.icon}
        />
      </div>

      {isOpen && (
        <div ref={panelRef} className={s.panel_dropdown}>
          <LayoutTextFilter
            isFilter
            options={values}
            onChange={onChange}
            onClose={() => setIsOpen(false)}
          />
        </div>
      )}
    </div>
  );
};
