import styled from '@emotion/styled';
import classNames from 'classnames';
import React, { useCallback, useRef, useState } from 'react';
import { Header, Modal } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import {
  center_content,
  confirmation_modal,
  exclamation_icon,
  footer,
  no_icon_title,
  subtitle,
  title,
} from 'components/UI/BuConfirmationPopup/styles';
import BuIcon from 'components/UI/BuIcon';

type IProps = {
  showControls?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onConfirm(): void;
  headerText?: string;
  children: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  onlyConfirm?: boolean;
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen';
  showIcon?: boolean;
  icon?: React.ReactElement;
  closeOnDimmerClick?: boolean;
  closeOnEscape?: boolean;
  disableConfirm?: boolean;
};

export const BuModalStatusBox = styled.p`
  color: black;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: var(--bu-control-border-radius);

  .bu-icon {
    font-size: 1.5em;
  }

  &.error {
    background-color: var(--bu-red-100);
    .bu-icon {
      color: var(--bu-red-400);
    }
  }

  &.success {
    background-color: var(--bu-green-200);
    .bu-icon {
      color: var(--bu-green-500);
    }
  }
`;

type BuConfirmationHookRefCallbackType = {
  onConfirm?: () => void;
  onClose?: () => void;
};

export const useBuConfirmationModal = (
  onDefaultConfirm: () => void = () => {},
  onDefaultClose: () => void = () => {}
) => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef<BuConfirmationHookRefCallbackType>({
    onConfirm: undefined,
    onClose: undefined,
  });

  const onClose = useCallback(() => {
    setOpen(false);
    (ref.current.onClose || onDefaultClose)();
  }, [setOpen, ref, onDefaultClose]);

  const onConfirm = useCallback(() => {
    setOpen(false);
    (ref.current.onConfirm || onDefaultConfirm)();
  }, [setOpen, ref, onDefaultConfirm]);

  const open = useCallback(
    (onConfirm?: () => void, onClose?: () => void) => {
      setOpen(true);
      ref.current.onConfirm = onConfirm;
      ref.current.onClose = onClose;
    },
    [setOpen, ref]
  );

  return {
    isOpen,
    onClose,
    onConfirm,
    open,
  };
};

const exclamationIcon = (
  <BuIcon
    name={BoostUpIcons.BadgeWarningOutline}
    color="var(--bu-red-400)"
    className={exclamation_icon}
  />
);

const BuConfirmationModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  onConfirm,
  headerText = 'Confirmation Required!',
  children,
  confirmText = 'Yes',
  cancelText = 'No',
  onlyConfirm = false,
  size = 'tiny',
  showIcon = true,
  showControls = true,
  icon = exclamationIcon,
  closeOnDimmerClick = true,
  closeOnEscape = true,
  disableConfirm,
}) => (
  <Modal
    open={isOpen}
    onClose={onClose}
    size={size}
    className={confirmation_modal}
    closeOnDimmerClick={closeOnDimmerClick}
    closeOnEscape={closeOnEscape}
  >
    <Modal.Content className={center_content}>
      {showIcon && icon}
      <Header>
        <div
          className={
            showIcon
              ? title
              : classNames('bu-font-section-title', no_icon_title)
          }
        >
          {headerText}
        </div>
      </Header>
      <div className={subtitle}>{children}</div>
    </Modal.Content>
    {showControls && (
      <Modal.Actions className={footer}>
        {!onlyConfirm && (
          <BuButton secondary size={BuControlSize.REGULAR} onClick={onClose}>
            {cancelText}
          </BuButton>
        )}
        <BuButton
          size={BuControlSize.REGULAR}
          onClick={onConfirm}
          disabled={disableConfirm}
        >
          {confirmText}
        </BuButton>
      </Modal.Actions>
    )}
  </Modal>
);

export default BuConfirmationModal;
