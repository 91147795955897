import { useModal } from '../ModalContext/modal.context';
import { ModalConfig } from '../ModalContext/modal.context.types';
import { GetTrendsPayload } from 'api/Trends';
import { css } from 'emotion';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { FILTER_NAME } from 'common/constants';
import BuButton from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import OpenFiltersPanel from 'components/UI/OpenFiltersPanel';
import { useHeader } from 'components/UI/Wrapper/Header/header.context';
import TrendsWaterfall from 'components/charts/TrendsWaterfall/TrendsWaterfall';
import { useGetTrends } from 'components/charts/TrendsWaterfall/TrendsWaterfall.helper';
import { IReduxState } from 'reducers/types';
import { getFiltersForAPI } from 'selectors';

const titleWrapper = css`
  display: flex;
  align-items: center;
  justify-content: start;
  max-height: 70px;
  padding: 24px 16px 12px 16px;
`;

const title = css`
  font-size: 18px;
  margin: 0px;
  font-weight: 600;
  color: var(--bu-gray-900);
`;
const titleSpan = css`
  font-size: 14px;
  font-weight: ;
  color: var(--bu-gray-700);
  margin-right: 5px;
`;

const trendsWrapper = css`
  margin: 24px 16px 0 16px;
`;
type FullScreenTrendsModalProps = {
  filters: GetTrendsPayload;
  user: string;
};
export type FullScreenTrendsModal = ModalConfig<
  FullScreenTrendsModalProps,
  {},
  {},
  '/fullscreen-trends'
>;

interface FullscreenTrendsProp {
  onClose: () => void;
}
const FullscreenTrends = ({ onClose }: FullscreenTrendsProp) => {
  const { getPropsForScheme } = useModal<FullScreenTrendsModal>();
  const { setShowTabs } = useHeader();

  useEffect(() => {
    setShowTabs(false);

    return () => {
      setShowTabs(true);
    };
  }, []);

  const openPanelFilters = useSelector(
    (state: IReduxState) =>
      getFiltersForAPI(
        state,
        FILTER_NAME.FullscreenTrendsModal
      ) as GetTrendsPayload
  );
  const { user, filters } = getPropsForScheme('/fullscreen-trends') || {};
  const [changeInterval, setChangeInterval] = useState(filters.change_interval);
  const updatedFilters = {
    ...filters,
    ...openPanelFilters,
    change_interval: changeInterval,
    time_span: changeInterval,
  } as GetTrendsPayload;

  const { data, isLoading } = useGetTrends(updatedFilters);

  return (
    <>
      <div className={titleWrapper}>
        <BuButton borderless secondary onClick={onClose}>
          <BuIcon name={BoostUpIcons.ClosePopup} />
        </BuButton>
        <h2 className={title}>
          <span className={titleSpan}>Rollups / </span> {user}: Pipeline Summary
        </h2>
      </div>
      <OpenFiltersPanel tab={FILTER_NAME.FullscreenTrendsModal} isModal />
      <div className={trendsWrapper}>
        {!data || isLoading ? (
          <Dimmer.Dimmable>
            <Dimmer inverted active={isLoading}>
              <Loader content="Loading" />
            </Dimmer>
          </Dimmer.Dimmable>
        ) : (
          <TrendsWaterfall
            filters={updatedFilters}
            trends={data}
            onIntervalChange={setChangeInterval}
          />
        )}
      </div>
    </>
  );
};

export default FullscreenTrends;
