import * as Highcharts from 'highcharts';

/**
 * Highcharts mixin for add functionality to center tooltips anchor
 * Adds tooltip.centerAnchor: boolean value
 */
export default function (H: any) {
  H.wrap(
    H.Tooltip.prototype,
    'updatePosition',
    function (this: any, proceed: Highcharts.WrapProceedFunction, point: any) {
      if (this.chart.hoverPoints) {
        proceed.apply(this, [arguments[1]]);
      }

      if (this.options.centerAnchor) {
        this.label.attr({
          anchorY: point.plotY + point.h / 2 + this.chart.plotTop,
        });
      }
    }
  );
}
