import { ProfileNavItem } from './NavigationProfile';

const isItemVisibleForNonAdmin = (item: ProfileNavItem): boolean =>
  !item.show_admin_only;

export const filterNavigationItemsForNonAdmin = (
  items: ProfileNavItem[]
): ProfileNavItem[] =>
  items.filter(isItemVisibleForNonAdmin).map((item) => {
    const filteredSubNavItems = item.sub_nav?.filter(isItemVisibleForNonAdmin);
    return { ...item, sub_nav: filteredSubNavItems } as ProfileNavItem;
  });
