import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { actions } from 'actions';
import { MANAGER_ROLES } from 'common/constants';
import { ViewBy } from 'common/enums/filters';
import Scorecard from 'components/dashboard/Scorecard/Scorecard';
import {
  DispatchProps,
  StateProps,
} from 'components/dashboard/Scorecard/types';
import { Partition } from 'navigation/tabs';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

const mapStateToProps = (
  state: IReduxState,
  ownProps: RouteComponentProps
): StateProps => {
  const tabPartition: Partition =
    ownProps.location.pathname === '/dashboard/scorecard'
      ? 'dashboards'
      : 'activity';
  return {
    filters: selectors.getFiltersForAPI(state, 'team_dashboard'),
    companyCurrency: selectors.getUserLocalCurrency(state),
    viewBy: selectors.isUserRole(state, Object.values(MANAGER_ROLES))
      ? selectors.filtersViewBy(state)
      : ViewBy.SELLER,
    isManager: selectors.isUserRole(state, Object.values(MANAGER_ROLES)),
    tabPartition,
  };
};

const dispatchToProps: DispatchProps = {
  persistModalParams: actions.ui.modal.persist,
};

export default connect(mapStateToProps, dispatchToProps)(Scorecard);
