import * as t from 'actions/actionTypes';

export const isSubmitForecast = (state = false, action) => {
  switch (action.type) {
    case t.FORECAST + t.SUBMIT:
      return true;
    case t.FORECAST + t.SUBMIT + t.CLEAR:
      return false;
    default:
      return state;
  }
};
