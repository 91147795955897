import { ModalPersistedProps } from 'navigation/types';
import { IReduxState } from 'reducers/types';

export const getModalProps = <T>(
  state: IReduxState,
  scheme: string
): ModalPersistedProps<T> =>
  ((state.modal[scheme] && state.modal[scheme].props) || {}) as any;

export const getModalOptions = <T>(
  state: IReduxState,
  scheme: string
): ModalPersistedProps<T> =>
  ((state.modal[scheme] && state.modal[scheme].options) || {}) as any;
