import * as Highcharts from 'highcharts';

import { formatAmount } from 'common/numbers';
import { Stat } from 'components/dashboard/Pipeline/PipelineDashboard/PipelineCreationWidget/types';

const xAXIS_EDGE_OFFSET = 0.5;

const addNoDataLabel = (chart: Highcharts.Chart, xAxisIndex: number) => {
  const xLeftBound = chart.xAxis[0].toPixels(
    xAxisIndex - xAXIS_EDGE_OFFSET,
    false
  );
  const xRightBound = chart.xAxis[0].toPixels(
    xAxisIndex + xAXIS_EDGE_OFFSET,
    false
  );

  chart.renderer
    .label('No data available for this quarter', 0)
    .attr('text-anchor', 'middle')
    .css({
      color: '#9b9b9b',
      fontSize: '14px',
      fontFamily: 'var(--bu-font-medium)',
    })
    .align({ align: 'center', verticalAlign: 'middle' }, false, {
      x: xLeftBound,
      y: 0,
      width: xRightBound - xLeftBound,
      height: chart.chartHeight,
    })
    .add();
};

const pointToBreakLine = (
  x: number,
  y: number | null,
  stat: Stat
): (Highcharts.PointOptionsObject | null)[] => [
  { x: x - xAXIS_EDGE_OFFSET, y, custom: stat },
  { x: x - xAXIS_EDGE_OFFSET / 2, y, custom: stat },
  { x: x, y },
  { x: x + xAXIS_EDGE_OFFSET / 2, y, custom: stat },
  { x: x + xAXIS_EDGE_OFFSET, y, custom: stat },
  null,
];

const isSeriesColumnOptions = (
  series: Highcharts.SeriesOptions
): series is Highcharts.SeriesColumnOptions => series.type === 'column';

export const getPlotOptions = (
  stats: Stat[],
  currencyCode: string,
  showPipelineCreatedValue: boolean,
  showPipelineCreationTarget: boolean
): Highcharts.Options => ({
  chart: {
    type: 'column',
    height: 380,
    marginLeft: 220,
    marginTop: 0,
    marginBottom: 0,
    marginRight: 0,
    events: {
      render() {
        stats.forEach((stat, i) => {
          if (stat.total_amount === null && stat.target === null) {
            addNoDataLabel(this, i);
          }
        });
      },
    },
  },
  title: {
    text: '',
  },
  xAxis: [
    {
      type: 'category',
      categories: stats.map(({ name: category }) => category),
      minPadding: 0,
      maxPadding: 0,
      labels: {
        enabled: true,
      },
      visible: true,
      gridLineWidth: 1,
    },
    {
      type: 'linear',
      categories: stats.map(({ name: category }) => category),
      minPadding: 0,
      maxPadding: 0,
      min: 0,
      max: stats.length - 1,
      visible: true,
      gridLineWidth: 1,
    },
  ],
  yAxis: {
    allowDecimals: false,
    min: 0,
    title: {
      text: '',
    },
    labels: {
      enabled: true,
      formatter() {
        return this.isFirst || this.isLast
          ? ''
          : formatAmount(this.value as number, currencyCode);
      },
    },
    stackLabels: {
      enabled: true,
      formatter() {
        return formatAmount(this.total, currencyCode);
      },
      y: -10,
      backgroundColor: 'var(--bu-color-constant-pipeline-coverage)',
      borderRadius: 11,
      borderWidth: 0,
      padding: 3,
      style: {
        color: 'var(--bu-white)',
        fontSize: '12px',
        fontWeight: 'normal',
        textOutline: 'none',
      },
    } as Highcharts.YAxisStackLabelsOptions,
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    enabled: true,
    formatter: function () {
      const xSeries = this.point.options.custom?.name || this.x;
      const formatter = (value?: number) =>
        formatAmount(value || 0, currencyCode);

      const tooltipLines = [
        `<b>${xSeries}</b>`,
        `${this.series.name}: ${formatter(this.y as number)}`,
      ];

      if (this.point.options.custom?.hideTooltip === true) {
        return false;
      }

      return tooltipLines.join('<br />');
    },
  },
  plotOptions: {
    series: {
      states: {
        inactive: {
          opacity: 0.75,
        },
      },
    },
    column: {
      stacking: 'normal',
    },
  },
  series: [
    {
      type: 'column',
      color: 'var(--bu-color-default-chart)',
      visible: showPipelineCreatedValue,
      name: 'Total Pipeline Created Value',
      data: stats.map(({ total_amount: y }) => y),
      pointWidth: 20,
      xAxis: 0,
    } as Highcharts.SeriesColumnOptions,
    {
      type: 'line',
      color: 'var(--bu-color-default-chart)',
      visible: showPipelineCreationTarget,
      name: 'Pipeline Creation Target',
      data:
        stats?.length > 0
          ? stats
              .map((stat, x) => pointToBreakLine(x, stat.target, stat))
              .flat()
          : [],
      step: 'center',
      marker: {},
      dashStyle: 'ShortDot',
      xAxis: 1,
    } as Highcharts.SeriesLineOptions,
  ],
});
