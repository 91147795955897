import { useModal } from '../ModalContext/modal.context';
import { ManageForecastModalType } from './types';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { resetModifiedSinceRecalculation } from 'actions/dealsActions';
import { someStatusIsLoading } from 'common/rowStatus.helper';
import {
  getDealStatuses,
  getIfSomeDealWasModifiedSinceRecalculation,
  getIsRealTimeEnabled,
} from 'selectors';

const MODAL_SCHEME = '/deals-status/:tab';

export const useRecalculateManageForecastModal = () => {
  const {
    updateModalProps,
    getCallbacksForScheme,
    getPropsForScheme,
    closeTooltip,
    setCloseTooltip,
    setIsCloseEnabled: setIsCloseModalEnabled,
  } = useModal<ManageForecastModalType>();

  const isRealTimeEnabled = useSelector(getIsRealTimeEnabled);

  const dispatch = useDispatch();

  const { onRecalculate } = getCallbacksForScheme(MODAL_SCHEME);

  const dealsStatuses = useSelector(getDealStatuses);
  const someDealUpdateIsLoading = someStatusIsLoading(dealsStatuses);

  const someDealWasModifiedSinceRecalculation = useSelector(
    getIfSomeDealWasModifiedSinceRecalculation
  );

  useEffect(() => {
    if (
      someDealWasModifiedSinceRecalculation &&
      !closeTooltip &&
      isRealTimeEnabled
    ) {
      setCloseTooltip(
        'Changes were made to opportunities and these may affect column total value and deal count. Rollups data will be refreshed upon closing this popup'
      );
      updateModalProps(MODAL_SCHEME, { hasToRecalculate: true });
    }
    if (
      !someDealWasModifiedSinceRecalculation &&
      closeTooltip &&
      isRealTimeEnabled
    ) {
      setCloseTooltip(undefined);
    }
  }, [someDealWasModifiedSinceRecalculation, closeTooltip, isRealTimeEnabled]);

  useEffect(() => {
    if (isRealTimeEnabled) {
      setIsCloseModalEnabled(!someDealUpdateIsLoading);
    }
  }, [someDealUpdateIsLoading, isRealTimeEnabled]);

  const internalOnRecalculate = useCallback(() => {
    dispatch(resetModifiedSinceRecalculation());
    onRecalculate();
  }, [onRecalculate]);

  useEffect(() => {
    return () => {
      if (isRealTimeEnabled) {
        dispatch(resetModifiedSinceRecalculation());
      }
    };
  }, [isRealTimeEnabled]);
  const modalProps = getPropsForScheme(MODAL_SCHEME);

  return {
    modalProps,
    onRecalculate: internalOnRecalculate,
    hasToRecalculate: someDealWasModifiedSinceRecalculation,
    someDealUpdateIsLoading,
  };
};
