import { connect } from 'react-redux';

import PipelineAttainmentWidget from 'components/dashboard/Pipeline/PipelineDashboard/PipelineAttainmentWidget/PipelineAttainmentWidget';
import { StateProps } from 'components/dashboard/Pipeline/PipelineDashboard/PipelineAttainmentWidget/types';
import { IReduxState } from 'reducers/types';
import { getUserLocalCurrency, getFiltersForAPI } from 'selectors';

const mapStateToProps = (state: IReduxState): StateProps => ({
  companyCurrency: getUserLocalCurrency(state),
  openFilters: getFiltersForAPI(state, 'pipeline_dashboard'),
});

export default connect(mapStateToProps)(PipelineAttainmentWidget);
