import React, { useState } from 'react';

import BuDropdown from 'components/UI/BuDropdown/BuDropdown';
import {
  DefaultMetricOption,
  DefaultMetricOptionDescription,
  DefaultMetricOptionTitle,
  FunnelMetricDefinitionSection,
  SubSection,
  SubTitle,
} from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/FunnelDefaultMetricDefinition/styles';
import { BIWidget } from 'components/dashboard/Metrics/metrics.types';

interface MetricOption {
  value: string;
  text: string;
  description: string;
}

interface Props {
  widget: BIWidget;
  onCompleteOptions: (widget: Partial<BIWidget>) => void;
}

const FunnelDefaultMetricDefinition: React.FC<Props> = ({
  widget,
  onCompleteOptions,
}) => {
  const defaultMetricOptions: MetricOption[] = [
    {
      value: 'obj_count',
      text: 'Deal Count',
      description: 'Number of deals in the stage',
    },
    {
      value: 'sum_amount',
      text: 'Deal Amount',
      description: 'Total amount from deals in the stage',
    },
  ];

  const [selectedDefaultMetric, setSelectedDefaultMetric] =
    useState<MetricOption>(
      defaultMetricOptions[widget.funnel_column == 'sum_amount' ? 1 : 0]
    );

  return (
    <FunnelMetricDefinitionSection>
      <SubSection>
        <SubTitle>Default Funnel Metric</SubTitle>
        <BuDropdown
          testingLabel="default-funnel-metric"
          label={selectedDefaultMetric.text}
          secondary
          fullWidth
        >
          {(hide) =>
            defaultMetricOptions.map(
              (option: typeof defaultMetricOptions[0]) => (
                <DefaultMetricOption
                  key={option.value}
                  data-testing={option.text}
                  onClick={() => {
                    setSelectedDefaultMetric(option);
                    onCompleteOptions({ funnel_column: option.value });
                    hide();
                  }}
                >
                  <DefaultMetricOptionTitle>
                    {selectedDefaultMetric.value === option.value ? (
                      <strong>{option.text}</strong>
                    ) : (
                      option.text
                    )}
                  </DefaultMetricOptionTitle>
                  <DefaultMetricOptionDescription>
                    {option.description}
                  </DefaultMetricOptionDescription>
                </DefaultMetricOption>
              )
            )
          }
        </BuDropdown>
      </SubSection>
    </FunnelMetricDefinitionSection>
  );
};

export default FunnelDefaultMetricDefinition;
