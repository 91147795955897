import classNames from 'classnames';
import moment from 'moment';
import React, { useMemo, useRef } from 'react';
import Calendar, { DateCallback } from 'react-calendar';

import IconCalendar from 'assets/images/icons/calendar_small.svg';
import { dateStyle } from 'components/dashboard/Forecast/MeddicSidePanel/styles';
import { useClickOutside } from 'components/hooks/useClickOutside';

type IProps = {
  selectorClassName?: string;
  popUpClassName?: string;
  value: string;
  emptyValueLabel?: string;
  onChange(value: string | null): void;
  disabled: boolean;
  onClose?(): void;
  onOpen?(): void;
};

const BuDatePicker: React.FC<IProps> = ({
  selectorClassName,
  popUpClassName,
  value,
  emptyValueLabel = 'Select a date',
  onChange,
  disabled,
  onClose,
  onOpen,
}) => {
  const elemRef = useRef<HTMLElement | null>(null);

  const { isOpen, setIsOpen, refElement } = useClickOutside(onClose);

  const date: moment.Moment | null = useMemo(
    () => (value ? moment(value as string) : null),
    [value]
  );

  const handleDayClick: DateCallback = (newDate) => {
    onChange(newDate ? moment(newDate).format('YYYY-MM-DD') : null);
    setIsOpen(false);
    onClose && onClose();
  };

  const handleCalendarVisibility = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const target = e.target as HTMLElement;

    if (
      !refElement?.current?.contains(target) &&
      elemRef?.current?.contains(target)
    ) {
      if (isOpen) {
        onClose?.();
      } else {
        onOpen?.();
      }

      setIsOpen(!isOpen);
    }
  };

  return (
    <div
      ref={(elem) => {
        elemRef.current = elem;
      }}
      className={classNames([dateStyle, selectorClassName, { disabled }])}
      onClick={(e) => !disabled && handleCalendarVisibility(e)}
    >
      <div className="date-button">
        <img
          src={IconCalendar}
          alt="calendar icons"
          className={classNames({ disabled })}
        />
        {date ? date?.format('YYYY-MM-DD') : emptyValueLabel}
      </div>
      {isOpen && (
        <div
          ref={refElement}
          className={classNames('date-popup', popUpClassName)}
        >
          <Calendar
            onClickDay={handleDayClick}
            value={date && date.isValid() ? date.toDate() : undefined}
            locale="en-EN"
          />
        </div>
      )}
    </div>
  );
};

export default BuDatePicker;
