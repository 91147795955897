import { IReduxState } from 'reducers/types';

export const forecastPaceQuarterly = (state: IReduxState) =>
  state.analytics.forecastPaceQuarterly;

export const forecastPaceQuarterlyLoading = (state: IReduxState) =>
  state.analytics.forecastPaceQuarterlyLoading;

export const forecastQuotaAttainments = (state: IReduxState) =>
  state.analytics.forecastQuotaAttainment;

export const forecastQuotaAttainmentsLoading = (state: IReduxState) =>
  state.analytics.forecastQuotaAttainmentLoading;
