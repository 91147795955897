import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { selectedClassType } from 'actions/adminActions';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import { USER_ROLES } from 'common/constants';
import BuIcon, { BuLogo } from 'components/UI/BuIcon';
import { getMainNavLandingsPaths } from 'components/UI/Wrapper/LeftPanel/helpers';
import * as s from 'components/UI/Wrapper/LeftPanel/styles';
import { IProps, MenuItem } from 'components/UI/Wrapper/LeftPanel/types';
import { useEnabledDashboard } from 'components/dashboard/DashboardPage/DashboardPage';
import { partitions } from 'navigation/tabs';
import { Scheme } from 'navigation/types';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';
import { getAppNavigation } from 'selectors/navigation';
import { getHiddenPages } from 'selectors/route';
import { history, persistor } from 'store/configureStore';

const NO_CRM = 'no-crm';
const ADMIN_DASHBOARD_URL = '/admin/dashboard';

const LeftPanel: React.FC<IProps> = ({
  admin,
  crmType,
  isAdmin,
  isAdminEnabled,
  isImpersonate,
  match,
  resetTableParams,
  restoreAdmin,
  updateAdminState,
  userRole,
  isMeetingsDashboardEnabled,
}: IProps) => {
  const { path, url } = match;
  const enabledDashboard = useEnabledDashboard();

  const companySettings = useSelector(selectors.getCompanySettings);
  const isReadOnlyUser = useSelector(selectors.isReadOnlyUser);
  const hiddenPages = useSelector<IReduxState, Scheme[]>(getHiddenPages);
  const appNavigationItems = useSelector(getAppNavigation);
  const rawMenu: MenuItem[] = useSelector((state) =>
    selectors.isPagesAvailableWithFallback(state, [
      {
        name: 'Opportunities',
        path: '/opportunities',
        scheme: '/opportunities',
        partition: 'opportunities',
        active: url.includes('/opportunities'),
        icon: BoostUpIcons.NavOpportunities,
        isVisible: crmType !== NO_CRM,
        default: userRole !== USER_ROLES.ACCOUNT_EXECUTIVE,
      },
      {
        name: 'Forecast',
        path: '/forecast/roll-ups',
        scheme: '/forecast/roll-ups',
        partition: 'forecast',
        active: url.includes('/forecast') && !url.endsWith('/forecast'),
        icon: BoostUpIcons.NavForecast,
        isVisible: crmType !== NO_CRM,
      },
      {
        name: 'Pipeline',
        path: '/pipeline/dashboard',
        scheme: '/pipeline/dashboard',
        partition: 'pipeline',
        active:
          url.includes('/pipeline') &&
          !url.endsWith('/pipeline-creation') &&
          !url.endsWith('/pipeline-coverage'),
        icon: BoostUpIcons.NavPipeline,
        isVisible: crmType !== NO_CRM,
      },
      {
        name: 'Targets',
        path: '/targets/forecast',
        scheme: '/targets/forecast',
        partition: 'targets',
        active: url.includes('/targets'),
        icon: BoostUpIcons.NavTargets,
        isVisible:
          !isReadOnlyUser &&
          [USER_ROLES.SALES_OPERATIONS, USER_ROLES.SALES_MANAGER].includes(
            userRole
          ) &&
          !(
            // check that all targets tabs are hidden in the navigation api
            partitions.targets.every(({ scheme }) =>
              hiddenPages.includes(scheme)
            )
          ),
      },
      {
        name: 'Accounts',
        path: '/accounts/all',
        scheme: '/accounts/all',
        partition: 'accounts',
        active: url.includes('/accounts/'),
        icon: BoostUpIcons.NavAccounts,
      },
      {
        name: 'Calls',
        path: '/calls/upcoming',
        scheme: '/calls/upcoming',
        partition: 'calls',
        active: url.includes('/calls'),
        icon: BoostUpIcons.NavCalls,
        isVisible: crmType !== NO_CRM,
      },
      {
        name: 'Meetings',
        path: '/meetings',
        scheme: '/meetings',
        partition: 'meetings',
        active: url.includes('/meetings'),
        icon: BoostUpIcons.NavMeetings,
        isVisible: crmType !== NO_CRM,
      },
      {
        name: 'Activity',
        path: '/dashboards/0',
        scheme: '/dashboards/0',
        partition: 'activity',
        active:
          url === '/dashboards/0' ||
          url === '/scorecard' ||
          url.includes('/activity'),
        icon: BoostUpIcons.NavActivity,
      },
      {
        name: 'Seller',
        path: '/dashboards/2',
        partition: 'sellers',
        scheme: '/dashboards/2',
        active: url === '/dashboards/2',
        icon: BoostUpIcons.NavSeller,
        isVisible: userRole !== USER_ROLES.ACCOUNT_EXECUTIVE,
      },
      {
        name: "To do's",
        path: '/reps-recommendations',
        partition: 'todos',
        scheme: '/reps-recommendations/:tableName?',
        active: url.includes('/reps-recommendations'),
        icon: BoostUpIcons.NavTodos,
        default: userRole === USER_ROLES.ACCOUNT_EXECUTIVE,
      },
      {
        name: 'Prospect Account',
        path: '/prospect-account-dashboard',
        partition: 'prospect_account',
        scheme: '/prospect-account-dashboard',
        active: match.url === '/prospect-account-dashboard',
        icon: BoostUpIcons.NavProspect,
      },
      {
        name: 'Dashboards',
        path: '/dashboard',
        scheme: '/dashboard',
        partition: 'dashboards',
        active:
          (url === '/dashboard' ||
            (url.includes('/dashboard/') && !path.includes('id')) ||
            // this is needed to prevent this tab to be active if we opened dashboard from revbi panel
            // TODO: fix routes conflicts between dashboards and RevBi tabs and see if customers are still using that tab
            url.includes('dashboards/rev-bi/dashboard')) &&
          path !== '/revbi/dashboard/:dashboardId',
        icon: BoostUpIcons.NavDashboard,
        isVisible: enabledDashboard.length > 0,
      },
      {
        name: 'RevBi',
        path: '/revbi/dashboards/list',
        partition: 'RevBI',
        scheme: '/revbi/dashboards/list',
        active: url.includes('/revbi'),
        icon: BoostUpIcons.NavDashboard,
        isVisible: Boolean(companySettings?.metrics_builder_enabled),
      },
    ])
  );

  const features = {
    isAdminEnabled,
    isForecastDashboardEnabled: !!companySettings?.forecast_dashboard_enabled,
    isRepPaceEnabled: !!companySettings?.rep_pace_table_enabled,
    isScorecardEnabled: !!companySettings?.scorecard_enabled,
    isMeetingsDashboardEnabled: !!companySettings?.meetings_dashboard_enabled,
    isSankeyChartEnabled: !!companySettings?.sankey_chart_enabled,
  };

  const mainNavLandings = useMemo(
    () => getMainNavLandingsPaths(appNavigationItems),
    [appNavigationItems]
  );

  const handleAdminClick = useCallback(() => {
    if (!isAdmin) {
      // If not admin, then update the Admin state and redirect to the admin dashboard.
      updateAdminState({
        email_domain: admin.email_domain,
        selected_class_type: selectedClassType.USERS,
      });

      history.replace(ADMIN_DASHBOARD_URL);
    } else {
      history.replace(ADMIN_DASHBOARD_URL);
    }

    resetTableParams();
    persistor.purge();
  }, [isImpersonate]);

  const mapToMenuWithDefaultLandings = (elem: MenuItem) => {
    const mainNavDefaultLanding =
      mainNavLandings[elem.name.toLowerCase()]?.path;

    if (!mainNavDefaultLanding) {
      return elem;
    }

    // Default Landing page can be set to an user that doesn't have permission for page
    // So we have to check that the current user can access the page
    const partitionForElement =
      (elem.partition && partitions[elem.partition]) || [];

    const defaultLandingPartitionItem = partitionForElement.find(
      (partition) => partition.scheme === mainNavDefaultLanding
    );

    const isDefaultLandingVisibleForUser =
      defaultLandingPartitionItem?.isVisible?.(userRole, features) ?? true;

    return isDefaultLandingVisibleForUser
      ? { ...elem, path: mainNavDefaultLanding }
      : elem;
  };

  const menuItems = useMemo(() => {
    const items: MenuItem[] = [];

    appNavigationItems.forEach((navItem) => {
      const currentScheme = rawMenu.find(
        (menuScheme) => navItem.name === menuScheme.partition
      );
      if (currentScheme?.name) {
        items.push(mapToMenuWithDefaultLandings(currentScheme));
      }
    });

    return items;
  }, [appNavigationItems, url, mainNavLandings, rawMenu]);

  const isHideSettingsLink = useSelector((state) =>
    selectors.isHideSettingsPage(state)
  );

  return (
    <div className={s.container}>
      <ul className={classNames('nav-buttons', s.list_header_buttons)}>
        <li className={s.list_buttons_item_logo}>
          <Link to="/">
            <button type="button" className={classNames(s.button_nav, 'white')}>
              <BuLogo className={s.logoArrowAnimation} />
              <p className="bu-icon-logo-wrapper">
                <BuIcon.Group>
                  <BuIcon
                    name={BoostUpIcons.BoostupPart_1}
                    color="var(--bu-color-left-panel-logo-part-1)"
                  />
                  <BuIcon
                    name={BoostUpIcons.BoostupPart_2}
                    color="var(--bu-color-left-panel-logo-part-2)"
                  />
                </BuIcon.Group>
              </p>
            </button>
          </Link>
        </li>
      </ul>

      <ul className={classNames('nav-buttons', s.list_buttons)}>
        {menuItems.map((elem) => (
          <li key={elem.name} className={s.list_buttons_item}>
            <Link to={elem.path}>
              <button
                type="button"
                className={classNames(s.button_nav, {
                  active: elem.active,
                })}
              >
                <BuIcon name={elem.icon} />
                <p>{elem.name}</p>
              </button>
            </Link>
          </li>
        ))}

        {isAdminEnabled && (
          <li className={s.list_buttons_item}>
            <button
              type="button"
              className={s.button_nav}
              onClick={handleAdminClick}
            >
              <BuIcon name={BoostUpIcons.NavAdmin} />
              <p>Admin</p>
            </button>
          </li>
        )}

        {!isAdminEnabled && admin.is_user_impersonation_allowed && (
          <li className={s.list_buttons_item}>
            <button
              type="button"
              className={s.button_nav}
              onClick={handleAdminClick}
            >
              <BuIcon name={BoostUpIcons.NavAdmin} />
              <p>Impersonate As</p>
            </button>
          </li>
        )}

        <li className={s.list_buttons_spacer} />

        {isHideSettingsLink && (
          <li className={classNames(s.list_buttons_item, 'border')}>
            <Link to="/settings/link-accounts">
              <button
                type="button"
                className={classNames(s.button_nav, {
                  active: url.includes('/settings'),
                })}
              >
                <BuIcon name={BoostUpIcons.NavSettings} />

                <p>Settings</p>
              </button>
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default LeftPanel;
