import axios from 'axios';
import React, { useState, useCallback, useEffect } from 'react';
import {
  connect,
  MapStateToPropsParam,
  MapDispatchToPropsParam,
} from 'react-redux';
import { toast } from 'react-toastify';
import { Dimmer, Loader } from 'semantic-ui-react';

import * as settingsActions from 'actions/settingsActions';
import BuButton from 'components/UI/BuButton';
import BuConfirmationPopup from 'components/UI/BuConfirmationPopup';
import {
  QuickViewActions,
  QuickViewContent,
  QuickViewHeader,
} from 'components/UI/Modal/QuickView/styles';
import TableCondition from 'components/settings/CRMSync/CRMTableFiltersModal/TableCondition/TableCondition';
import {
  ConditionFieldInfo,
  ConditionType,
} from 'components/settings/CRMSync/CRMTableFiltersModal/TableCondition/types';
import {
  section,
  NoteSection,
  FiltersSection,
  FiltersCounter,
  mainContent,
  addConditionButton,
  cancelButton,
  AndLabel,
} from 'components/settings/CRMSync/CRMTableFiltersModal/styles';
import {
  IStateProps,
  IModalRouteProps,
  IProps,
  DispatchProps,
  IOwnProps,
} from 'components/settings/CRMSync/CRMTableFiltersModal/types';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';
import { fetchApi } from 'utils/network';

export const CRMTableFiltersComponent: React.FC<IProps> = ({
  conditions,
  loading,
  companyCurrency,
  params,
  onClose,
  isReadOnly,
  getCRMSyncStatus,
  getCrmSyncTableConditions,
  clearCrmSyncTableConditions,
}) => {
  const [currentConditions, setCurrentConditions] =
    useState<ConditionType[]>(conditions);

  const [options, setOptions] = useState<ConditionFieldInfo[]>([]);
  const [isConfirmationVisible, setConfirmationVisible] = useState(false);
  const [isConditionsSaveDisabled, setConditionsSaveDisabled] = useState(true);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/crm/sync/conditions/${params.tableName}/options`
      )
      .then((res) => setOptions(res.data.data))
      .catch(() => {});
  }, []);

  const handleAddFilter = useCallback(() => {
    setCurrentConditions([...(currentConditions || []), {} as ConditionType]);
  }, [currentConditions, setCurrentConditions]);

  const handleCancel = useCallback(() => {
    onClose!();
  }, [onClose]);

  const onConfirm = useCallback(() => {
    setConfirmationVisible(false);
    fetchApi({
      url: `${process.env.REACT_APP_BACKEND_URL}/api/crm/sync/conditions/${params.tableName}`,
      queryMethod: 'post',
      queryParams: currentConditions.map((cond) => ({
        ...cond,
        display_name: cond.display_name ?? null,
        field_type: cond.field_type ?? null,
      })),
      setData(response) {
        clearCrmSyncTableConditions();
        getCRMSyncStatus();
      },
      setError(error) {
        toast.error(`Saving filters failed`, {
          position: 'bottom-left',
        });
      },
    });
    onClose!();
  }, [onClose, currentConditions]);

  const handleSave = useCallback(() => {
    setConfirmationVisible(true);
  }, [onClose, currentConditions]);

  useEffect(() => {
    getCrmSyncTableConditions(params.tableName);

    return () => {
      clearCrmSyncTableConditions();
    };
  }, [
    getCrmSyncTableConditions,
    clearCrmSyncTableConditions,
    params.tableName,
  ]);

  useEffect(() => {
    for (var i = 0; i < currentConditions.length; ++i) {
      if (
        currentConditions[i].value === null ||
        currentConditions[i].value === undefined ||
        currentConditions[i].value === '' ||
        !currentConditions[i].operator ||
        !currentConditions[i].field
      ) {
        setConditionsSaveDisabled(true);
        break;
      } else {
        setConditionsSaveDisabled(false);
      }
    }
  }, [JSON.stringify(currentConditions)]);

  useEffect(() => {
    setCurrentConditions(conditions);
  }, [JSON.stringify(conditions)]);

  const handleOnChange = (
    condition: ConditionType,
    index: number,
    filters: ConditionType[]
  ) => {
    setCurrentConditions(
      Object.assign([], filters, {
        [index]: condition,
      })
    );
  };

  const handleOnRemove = (index: number, filters: ConditionType[]) => {
    setCurrentConditions(
      filters.slice(0, index).concat(filters.slice(index + 1, filters.length))
    );
  };

  return (
    <>
      <QuickViewHeader>Edit filters for {params.tableName}</QuickViewHeader>
      <QuickViewContent className={mainContent}>
        <Dimmer.Dimmable dimmed={loading}>
          <NoteSection className={section}>
            <b>Note:</b> Define filters to sync only relevant records you wish
            to sync to BoostUp from CRM. It's recommended to define filters if
            possible to sync only relevant data. It will make sync process
            faster.
          </NoteSection>
          <FiltersCounter className={section}>
            <b>Filters applied:</b> {conditions.length} (
            {conditions.length
              ? 'Records matching following filters will be synced'
              : 'By default all records for this table will be synced'}
            )
          </FiltersCounter>
          {currentConditions?.map((filter, index, filters) => (
            <React.Fragment key={`condition-${index}`}>
              {index > 0 && <AndLabel>AND</AndLabel>}
              <TableCondition
                companyCurrency={companyCurrency}
                condition={filter}
                conditionFields={options}
                isReadOnly={isReadOnly}
                onChange={(condition) =>
                  handleOnChange(condition, index, filters)
                }
                onRemove={() => handleOnRemove(index, filters)}
              />
            </React.Fragment>
          ))}
          {!isReadOnly && (
            <FiltersSection className={section}>
              <button
                data-testing="Btn-Add_filter"
                className={addConditionButton}
                onClick={handleAddFilter}
              >
                Add Filter
              </button>
            </FiltersSection>
          )}
          <Dimmer active={loading} inverted>
            <Loader />
          </Dimmer>
        </Dimmer.Dimmable>
      </QuickViewContent>
      <QuickViewActions>
        {isReadOnly ? (
          <BuButton onClick={handleCancel}>Done</BuButton>
        ) : (
          <>
            <BuButton secondary className={cancelButton} onClick={handleCancel}>
              Cancel
            </BuButton>
            <BuButton disabled={isConditionsSaveDisabled} onClick={handleSave}>
              Save
            </BuButton>
          </>
        )}
      </QuickViewActions>
      <BuConfirmationPopup
        cancelText="No"
        confirmText="Yes"
        headerText="Confirmation Required!"
        isOpen={isConfirmationVisible}
        onClose={() => setConfirmationVisible(false)}
        onConfirm={onConfirm}
      >
        <p>
          Are you sure you want to apply new filters while syncing data of{' '}
          {params.tableName} from CRM? If you proceed then these filters will be
          applied on the future CRM data sync and only matching records will be
          synced to the BoostUp.{' '}
        </p>
      </BuConfirmationPopup>
    </>
  );
};

const mapStateToProps: MapStateToPropsParam<
  IStateProps,
  IModalRouteProps,
  IReduxState
> = (state) => {
  return {
    conditions: selectors.getCrmSyncTableConditions(state),
    loading: selectors.areCrmSyncTableConditionsLoading(state),
    companyCurrency: selectors.getUserLocalCurrency(state),
  };
};

const mapDispatchToProps: MapDispatchToPropsParam<DispatchProps, IOwnProps> = (
  dispatch
) => {
  return {
    getCRMSyncStatus: () => dispatch(settingsActions.getCRMSyncStatus()),
    getCrmSyncTableConditions: (tableName) =>
      dispatch(settingsActions.getCrmSyncTableConditions(tableName)),
    clearCrmSyncTableConditions: () =>
      dispatch(settingsActions.clearCrmSyncTableConditions()),
  };
};

export const CRMTableFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(CRMTableFiltersComponent);
