import { BoostUpIcons } from 'assets/css/boostup-icons';
import classNames from 'classnames';
import BuIcon from 'components/UI/BuIcon';
import {
  icon,
  pivotedTotalsContainer,
  pivotedTotalsInnerContainer,
  pivotedTotalsItemContainer,
  pivotedTotalsValueContainer,
  totalCardAThirdOfSpace,
  totalCardHalfSpace,
} from './styles';

interface LayoutPivotedTotalsProps {
  children: React.ReactNode;
  managerActions?: React.ReactNode;
}

export const LayoutPivotedTotals = ({
  children,
  managerActions,
}: LayoutPivotedTotalsProps) => (
  <div className={pivotedTotalsContainer}>
    <div
      className={pivotedTotalsInnerContainer}
      data-testing="totals-container"
    >
      <BuIcon
        className={icon}
        name={BoostUpIcons.ColumnGraphBox}
        color="var(--bu-gray-600)"
      />

      <div
        className={pivotedTotalsValueContainer}
        data-testing="totals-block-section"
      >
        {children}
      </div>
    </div>
    {managerActions}
  </div>
);

interface LayoutPivotedTotalsItemProps {
  children: React.ReactNode;
  isClickable: boolean;
  metricLength: number;
  'data-testing': string;
  handleClick: () => void;
}
export const LayoutPivotedTotalsItem = ({
  children,
  isClickable,
  metricLength,
  'data-testing': dataTesting,
  handleClick,
}: LayoutPivotedTotalsItemProps) => {
  const shouldItemUseAThirdOfSpace: boolean = metricLength % 2 !== 0;
  return (
    <div
      data-testing={dataTesting}
      className={classNames([
        pivotedTotalsItemContainer,
        {
          clickable: isClickable,
        },
        shouldItemUseAThirdOfSpace
          ? totalCardAThirdOfSpace
          : totalCardHalfSpace,
      ])}
      onClick={isClickable ? handleClick : () => {}}
    >
      {children}
    </div>
  );
};
