import { AxiosError } from 'axios';
import { Action } from 'redux';

import * as t from 'actions/actionTypes';
import { SagaActionFunction } from 'actions/index';
import * as genericSagas from 'sagas/generic';

type StageName = string;

export interface StageTransfer
  extends Record<PipelineStagesColumns, StageName> {
  weight: number; //deals
  amount: number;
  ids: string[];
}

export interface PipelineStagesResponse {
  data: StageTransfer[];
  totals: {
    from: Record<StageName, number>;
    to: Record<StageName, number>;
  };
  from: string; // timestamp
  to: string; // timestamp
}

export type PipelineStagesColumns = keyof PipelineStagesResponse['totals'];

export interface PipelineStagesAction extends Action {
  type: string;
  payload?: PipelineStagesResponse;
  error?: string;
}

type PipelineStagesPayload = PipelineStagesResponse;

export type PipelineStagesDataType = 'stage' | 'forecast_category';

type PipelineStagesParams = {
  time_span: string;
  sankey_field: PipelineStagesDataType;
  business_type_name: string;
  include_disabled: boolean;
};

export const getPipelineStages: SagaActionFunction<
  PipelineStagesPayload,
  AxiosError<PipelineStagesPayload>,
  PipelineStagesAction,
  PipelineStagesParams
> = (data) => ({
  type: t.PIPELINE_STAGES + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/forecast/sankey`,
  saga: genericSagas.create,
  data: data,
  success: (payload) => ({
    type: t.PIPELINE_STAGES + t.GET + t.SUCCESS,
    payload: payload,
  }),
  error: (e) => ({
    type: t.PIPELINE_STAGES + t.GET + t.ERROR,
    error: 'error',
  }),
  loading: () => ({
    type: t.PIPELINE_STAGES + t.GET + t.LOADING,
  }),
});
