import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Panel from 'components/UI/FilterPanels/Panel';
import { IPropsNew } from 'components/UI/FilterPanels/types';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

type ReduxStateProps = ReturnType<typeof stateToProps>;
type IProps = ReduxStateProps & IPropsNew;

const PanelColumnList: React.FC<IProps> = ({ users, config, onChange }) => {
  const { withShowAll, searchable, values: rawValues } = config;
  const [values, setValues] = useState<Filters.Checkbox[]>([]);

  useEffect(() => {
    setValues(rawValues);
  }, [rawValues]);

  const checkedValues = values
    .filter((item) => item.checked)
    .map((item) => item.value);

  const isAncestorChecked = (items: Filters.UserFilterElement): boolean =>
    !!items.team?.find(
      (item) => checkedValues.includes(item.email) || isAncestorChecked(item)
    );

  const options = users.map((item) =>
    Array.isArray(item)
      ? { email: item[0], name: item[1], id: item[1], role: '', team: [] }
      : item
  );

  const isAllChecked = checkedValues.includes('__all__');
  const isCurrentItemChecked = (item: Filters.UserFilterElement) =>
    checkedValues.includes(item.email) || isAncestorChecked(item);

  const isCurrentItemAll = (item: Filters.UserFilterElement) =>
    item.email === '__all__';

  const handleCheckItem = (changes: Filters.PersistValue[]) =>
    onChange(changes, true);

  const renderUserLevel = (
    currentLevelValue: string,
    values?: Filters.UserFilterElement[]
  ): React.ReactNode => {
    const currentRootNode = values?.find((item) => isCurrentItemChecked(item));

    const levelOptions: Filters.Checkbox[] =
      values
        ?.map<Filters.Checkbox>((item) => ({
          checked:
            (isCurrentItemAll(item) && isAllChecked) ||
            (!isCurrentItemAll(item) &&
              isCurrentItemChecked(item) &&
              !isAllChecked),
          value: item.email,
          label: item.name,
          group: !!item.team?.length || undefined,
        }))
        .sort(
          (a, b) =>
            (a.label === 'All' && -1) ||
            (b.label === 'All' && 1) ||
            a.label.localeCompare(b.label)
        ) || [];

    return (
      <>
        <Panel
          config={{
            type: 'text_field',
            values: levelOptions,
            checkedTotal: 0,
            withShowAll,
            title: currentLevelValue,
            checkedAll:
              levelOptions.filter((item) => item.checked).length === 0,
            searchable,
          }}
          onChange={handleCheckItem}
          onClose={() => {}}
        />
        {!!currentRootNode?.team?.length &&
          renderUserLevel(currentRootNode.email, currentRootNode?.team)}
      </>
    );
  };

  return <>{renderUserLevel('__all__', options)}</>;
};

const stateToProps = (state: IReduxState) => ({
  users: selectors.getSalesManagersForFilter(state),
});

export default connect(stateToProps)(PanelColumnList);
