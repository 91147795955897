import { css } from 'emotion';

export const infoMeetingsContainer = css`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
`;

export const marginRight = css`
  margin-right: 10px;
`;

export const borderWrapper = css`
  display: flex;
  border: 1px solid var(--bu-gray-300);
  padding: 5px 10px;
  alignitems: center;
  margin: 20px 0 10px 0;
`;

export const flexColumn = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 8px 0 0 20px;
`;

export const marginBottom12 = css`
  margin-bottom: 12px;
`;

export const marginBottom14 = css`
  margin-bottom: 14px;
`;
