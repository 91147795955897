import * as Highcharts from 'highcharts';
import { DropdownItemProps } from 'semantic-ui-react';

import { ForecastQuotaAttainmentData } from 'actions/analyticsActions';
import { formatAmount } from 'common/numbers';

export const percentOf = (value: number, target: number) =>
  target !== 0 ? `${~~((value / target) * 100)}%` : '0%';

export const getPlotOptions = (
  quotaAttainments: ForecastQuotaAttainmentData[],
  currencyCode: string,
  sorting: number
): Highcharts.Options => {
  const sortedData = quotaAttainments.sort(
    ({ quota_attainment: left }, { quota_attainment: right }) =>
      sorting === 1 ? right - left : left - right
  );

  const avgTarget =
    sortedData.length > 0
      ? sortedData.reduce((acc, item) => acc + item.quota_attainment, 0) /
        sortedData.length
      : 0;

  return {
    chart: {
      type: 'column',
      height: 420,
      marginTop: 40,
      scrollablePlotArea: {
        minWidth: 1024,
        opacity: 1,
      },
    },
    title: {
      text: '',
    },
    xAxis: [
      {
        type: 'category',
        categories: sortedData.map((quota) => quota.user.name),
        labels: {
          enabled: true,
          rotation: -30,
        },
        visible: true,
      },
    ],
    yAxis: {
      allowDecimals: false,
      min: 0,
      softMax: 100,
      title: {
        text: 'Quota Attainment',
      },
      labels: {
        enabled: true,
        format: '{value}%',
      },
      gridLineDashStyle: 'Dash',
      stackLabels: {
        enabled: true,
        format: '{total:.0f}%',
      },
      plotLines: [
        {
          value: avgTarget,
          label: {
            text: `Avg: ${~~avgTarget}%`,
            align: 'right',
            verticalAlign: 'middle',
            style: {
              color: '#F05A30',
            },
            x: -5,
          },
          dashStyle: 'ShortDot',
          color: '#F05A30',
          width: 2,
          zIndex: 10,
        },
      ],
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      formatter: function () {
        const quotaAttainment = this.point.options
          .custom as ForecastQuotaAttainmentData;

        return [
          `<b>${this.x}</b>`,
          `Target: ${formatAmount(quotaAttainment.target, currencyCode)}`,
          `Booked: ${formatAmount(quotaAttainment.booked, currencyCode)}`,
        ].join('<br />');
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        grouping: true,
        pointRange: 1,
      },
    },
    series: [
      {
        type: 'column',
        color: 'var(--bu-color-constant-booked)',
        name: 'Booked',
        data: sortedData.map((quota) => ({
          y: quota.quota_attainment,
          custom: quota,
        })),
        pointWidth: 20,
        xAxis: 0,
      } as Highcharts.SeriesColumnOptions,
    ],
  };
};

export const sortOptions: DropdownItemProps[] = [
  {
    key: 'asc',
    value: -1,
    text: 'Ascending',
  },
  {
    key: 'desc',
    value: 1,
    text: 'Descending',
  },
];
