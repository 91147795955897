/* eslint-disable import/prefer-default-export */
import { SortOrder, IColumn } from 'components/UI/common/TypedTable/TypedTable';
import { currencyFormatter } from 'components/UI/common/TypedTable/formatters';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import DealCell from 'components/dashboard/RepsRecommendations/customCells/DealCell';

export const configTable = (companyCurrency: string) =>
  [
    {
      id: 'name-0',
      delta: false,
      editable: false,
      resize: false,
      sort_field: null,
      sort_order: SortOrder.DESCENDING,
      sortable: false,
      config: {
        className: 'primary-cell',
        renderer: DealCell,
        truncateTextFrom: 32,
      },
      field: 'crm_metadata.opportunity_name',
      label: 'Name',
      type: ColumnTypes.CUSTOM,
      align: 'left',
      showTooltip: true,
    },
    {
      field: 'amount',
      editable: false,
      sort_order: 'desc',
      sortable: true,
      id: 'amount-7',
      label: 'Amount',
      type: ColumnTypes.NUMBER,
      delta: true,
      config: {
        formatter: currencyFormatter(companyCurrency, 0),
        isMoney: true,
        isMulticurrency: false,
      },
      align: 'right',
      showTooltip: false,
    },
    {
      field: 'risk_score.value',
      editable: false,
      sort_order: 'desc',
      sortable: true,
      id: 'risk_score-3',
      label: 'Forecast Risk',
      type: ColumnTypes.SCORE,
      config: {
        risk_factors_field: 'risk_factors',
        positive_indicators_field: 'positive_indicators',
        moreButton: {
          label: 'View Deal Insights',
        },
      },
      showTooltip: true,
    },
    {
      field: 'close_date',
      editable: false,
      sort_order: 'desc',
      sortable: true,
      id: 'close_date-9',
      label: 'Close Date',
      type: ColumnTypes.DATE,
      delta: true,
      config: {
        format: false,
      },
      showTooltip: true,
    },
    {
      field: 'forecast_category_name',
      editable: false,
      sort_order: 'desc',
      sortable: true,
      id: 'forecast_category_name-5',
      label: 'Category',
      type: ColumnTypes.DROPDOWN,
      delta: true,
      config: {
        options: [
          {
            key: 'Closed (Lost)',
            value: 'Closed (Lost)',
            text: 'Closed (Lost)',
          },
          {
            key: 'Omitted',
            value: 'Omitted',
            text: 'Omitted',
          },
          {
            key: 'In Play',
            value: 'In Play',
            text: 'In Play',
          },
          {
            key: 'Best Case',
            value: 'Best Case',
            text: 'Best Case',
          },
          {
            key: 'Commit',
            value: 'Commit',
            text: 'Commit',
          },
          {
            key: 'Closed (Won)',
            value: 'Closed (Won)',
            text: 'Closed (Won)',
          },
        ],
      },
      showTooltip: true,
    },
  ] as IColumn[];
