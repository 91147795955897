import { Container } from './Container';
import styled from '@emotion/styled';
import React from 'react';
import { Icon, Loader } from 'semantic-ui-react';

export const Fullscreen = styled(Container)`
  label: fullscreen;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

Fullscreen.Header = styled.div`
  label: fullscreen-header;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
`;

const BackButton = (props) => (
  <button {...props}>
    <Icon name="arrow left" />
    Back
  </button>
);
Fullscreen.BackButton = styled(BackButton)`
  label: fullscreen-back-button;
  box-sizing: border-box;
  height: 32px;
  width: 92px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-family: var(--bu-font-regular);
  font-size: 14px;
  color: #4a4a4a;
  transition: 0.5s;

  &:focus {
    outline: none;
  }

  &:hover {
    background: #0ab3b0;
    color: #ffffff;
  }
`;

Fullscreen.Breadcrumb = styled.div`
  label: fullscreen-breadcrumb;
  padding: 0 20px;
  font-family: var(--bu-font-regular);
  font-size: 15px;
  color: #9b9b9b;
`;

Fullscreen.Breadcrumb.Previous = styled.span`
  label: fullscreen-breadcrumb-previous;
  color: #000000;
  text-transform: capitalize;
`;

Fullscreen.Title = styled.div`
  label: fullscreen-title;
  margin-bottom: 15px;
  color: #4a4a4a;
  font-size: 22px;
  font-weight: bold;
`;

Fullscreen.Details = styled.div`
  label: fullscreen-details;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 15px;
`;

Fullscreen.Details.Item = styled.div`
  label: fullscreen-details-item;
  padding: 3px 20px 3px 0;
  margin-right: 20px;
  color: #9b9b9b;
  font-size: 15px;

  &:not(:last-child) {
    border-right: 2px solid #dddddd;
  }
`;

Fullscreen.Details.Value = styled.span`
  label: fullscreen-details-item;
  color: #000000;
  font-weight: 600;
`;

const Loading = (props) => (
  <div {...props}>
    <Loader active inline="centered" />
  </div>
);
Fullscreen.Loading = styled(Loading)`
  label: fullscreen-loading;
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

Fullscreen.Card = styled.div`
  label: fullscreen-card;
  margin-bottom: 20px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  box-shadow: var(--bu-shadow-basic);
`;

Fullscreen.Card.Header = styled.div`
  label: fullscreen-card-header;
  padding: 10px 25px;
  border-radius: 4px 4px 0 0;
  background-color: #f9f9f9;
  display: flex;
  color: #777777;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
`;

Fullscreen.Card.Body = styled.div`
  label: fullscreen-card-body;
  padding: 10px 25px;
  white-space: pre-line;
`;
