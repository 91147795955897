import React from 'react';

import {
  WidgetPreviewColumn,
  WidgetPreviewContainer,
} from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetPreview/styles';
import { WidgetPreviewPlaceholder } from 'components/dashboard/Metrics/Create/WidgetPreviewPlaceholder/WidgetPreviewPlaceholder';
import { FunnelWidget } from 'components/dashboard/Metrics/Widget/FunnelWidget/FunnelWidget';
import { HistoricalWidgetPreview } from 'components/dashboard/Metrics/Widget/Historical/HistoricalWidgetPreview';
import { MetricsWidgetPreview } from 'components/dashboard/Metrics/Widget/MetricsWidgetPreview';
import { ReportWidget } from 'components/dashboard/Metrics/Widget/Report/ReportWidget';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import { BIWidget } from 'components/dashboard/Metrics/metrics.types';

interface Props {
  readonly widget: BIWidget;
  readonly canPreviewReportWidget: boolean;
  readonly canPreviewFunnelWidget: boolean;
  readonly canPreviewLiveOrHistoricalWidget: boolean;
  readonly isCreateOrEditMetricMode: boolean;
  onUpdateWidget: (updatedWidgetConfig: Partial<BIWidget>) => void;
}

export const WidgetPreview: React.FC<Props> = ({
  widget,
  canPreviewReportWidget = false,
  canPreviewFunnelWidget = false,
  canPreviewLiveOrHistoricalWidget = false,
  isCreateOrEditMetricMode = false,
  onUpdateWidget,
}) => (
  <WidgetPreviewColumn>
    {widget.analysis_type === AnalysisType.REPORT && (
      <>
        {!canPreviewReportWidget && (
          <WidgetPreviewPlaceholder analysisType={AnalysisType.REPORT} />
        )}

        {canPreviewReportWidget && (
          <WidgetPreviewContainer data-testing="widget-preview-section">
            <ReportWidget
              isControlsShown
              widget={widget}
              onChangeWidget={onUpdateWidget}
            />
          </WidgetPreviewContainer>
        )}
      </>
    )}

    {widget.analysis_type === AnalysisType.FUNNEL && (
      <>
        {!canPreviewFunnelWidget && (
          <WidgetPreviewPlaceholder analysisType={AnalysisType.FUNNEL} />
        )}

        {canPreviewFunnelWidget && (
          <FunnelWidget
            isControlsShown
            widget={widget}
            onChangeWidget={onUpdateWidget}
          />
        )}
      </>
    )}

    {widget.analysis_type === AnalysisType.HISTORICAL && (
      <>
        {!canPreviewLiveOrHistoricalWidget && (
          <WidgetPreviewPlaceholder analysisType={AnalysisType.HISTORICAL} />
        )}

        {canPreviewLiveOrHistoricalWidget && (
          <WidgetPreviewContainer data-testing="widget-preview-section">
            <HistoricalWidgetPreview
              widget={widget}
              isCreateEditMetric={isCreateOrEditMetricMode}
              onChangeWidget={onUpdateWidget}
            />
          </WidgetPreviewContainer>
        )}
      </>
    )}

    {widget.analysis_type === AnalysisType.LIVE && (
      <>
        {!canPreviewLiveOrHistoricalWidget && (
          <WidgetPreviewPlaceholder analysisType={AnalysisType.LIVE} />
        )}

        {canPreviewLiveOrHistoricalWidget && (
          <WidgetPreviewContainer data-testing="widget-preview-section">
            <MetricsWidgetPreview
              widget={widget}
              isCreateEditMetric={isCreateOrEditMetricMode}
              onChangeWidget={onUpdateWidget}
            />
          </WidgetPreviewContainer>
        )}
      </>
    )}
  </WidgetPreviewColumn>
);
