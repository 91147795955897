const filterInputType = {
  CHECKBOX: 'checkbox',
  TEXT_FIELD: 'text_field',
};

export default {
  MY_TAGS: {
    key: 'my_tags',
    name: 'My Tags',
    input_type: filterInputType.CHECKBOX,
  },
  OTHER_TAGS: {
    key: 'other_tags',
    name: 'Other Tags',
    input_type: filterInputType.CHECKBOX,
  },
  SEARCH_TEXT: {
    key: 'search_text',
    name: 'Search',
    input_type: filterInputType.TEXT_FIELD,
  },
  OPPORTUNITY_NAME: {
    key: 'opportunity_name',
    name: 'Search',
    input_type: filterInputType.TEXT_FIELD,
  },
  BEST_CALLS: {
    key: 'best_calls',
    name: 'Best Calls',
    input_type: filterInputType.CHECKBOX,
  },
  CALL_DURATION: {
    key: 'call_duration',
    name: 'Call Duration',
    input_type: filterInputType.CHECKBOX,
  },
  SPEAKER_TYPE: {
    key: 'speaker_type',
    name: 'Speaker Track',
    input_type: filterInputType.CHECKBOX,
  },
  TOPICS: {
    key: 'topics',
    name: 'Topics',
    input_type: filterInputType.CHECKBOX,
  },
  QUESTIONS: {
    key: 'questions',
    name: 'Questions',
    input_type: filterInputType.CHECKBOX,
  },
  USERS: {
    key: 'users',
    name: 'Users',
    input_type: filterInputType.CHECKBOX,
  },
  OPPORTUNITY_STAGES: {
    key: 'opportunity_stages',
    name: 'Deal Stages',
    input_type: filterInputType.CHECKBOX,
  },
  UPCOMING_DATES: {
    key: 'upcoming_dates',
    name: 'Dates',
    input_type: filterInputType.CHECKBOX,
  },
  COMPLETED_DATES: {
    key: 'completed_dates',
    name: 'Dates',
    input_type: filterInputType.CHECKBOX,
  },
  OPP_STATUS: {
    key: 'opportunity_status',
    name: 'Opportunity Status',
    input_type: filterInputType.CHECKBOX,
  },
  OPP_FOLLOW_UP_SCHEDULED: {
    key: 'opportunity_follow_up_scheduled',
    name: 'Follow Up Scheduled',
    input_type: filterInputType.CHECKBOX,
  },
  OPP_NUM_COMPLETED_CALLS: {
    key: 'opportunity_num_completed_calls',
    name: 'Num Completed Calls',
    input_type: filterInputType.CHECKBOX,
  },
  OPP_ID: {
    key: 'opportunity_id',
    name: 'Opportunity ID',
    input_type: filterInputType.TEXT_FIELD,
  },
  TITLES_TOUCHED: {
    key: 'titles_touched',
    name: 'Titles Touched',
    input_type: filterInputType.CHECKBOX,
  },
  TITLES_RESPONSIVE: {
    key: 'titles_responsive',
    name: 'Titles Responsive',
    input_type: filterInputType.CHECKBOX,
  },
  NO_SHOW: {
    key: 'no_show',
    name: 'No-Show Calls',
    input_type: filterInputType.CHECKBOX,
  },
  BUYING_SIGNALS: {
    key: 'buying_signals',
    name: 'Buying Signals',
    input_type: filterInputType.CHECKBOX,
  },
  NUMBER_ATTENDEES: {
    key: 'number_attendees',
    name: 'No. of Participants',
    input_type: filterInputType.CHECKBOX,
  },
  FORECAST_CATEGORY_NAMES: {
    key: 'forecast_category_names',
    name: 'Forecast Category',
    input_type: filterInputType.CHECKBOX,
  },
};
