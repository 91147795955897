import { BIPivotDescriptor } from 'components/dashboard/Metrics/metrics.types';

const GROUP_BY_COLUMN_TYPES = new Set(['picklist', 'text']);

// THIS SHOULD BE REMOVED WHEN WE CLEAN THE DATE SUPPORT.
export const groupColumns = (columns: BIPivotDescriptor[] = []) =>
  columns.reduce<BIPivotDescriptor[]>((pre, column) => {
    if (column.type === 'date' && column.table_name !== 'time_interval') {
      pre.push({
        ...column,
        name: `${column.name}_MoM`,
        label: `${column.label}-MoM`,
        type: 'timePeriod',
      });
      pre.push({
        ...column,
        name: `${column.name}_WoW`,
        label: `${column.label}-WoW`,
        type: 'timePeriod',
      });
      pre.push({
        ...column,
        name: `${column.name}_QoQ`,
        label: `${column.label}-QoQ`,
        type: 'timePeriod',
      });
    }

    if (
      (GROUP_BY_COLUMN_TYPES.has(column.type) ||
        column.table_name === 'time_interval') &&
      pre.findIndex((el) => el.name === column.name) === -1
    ) {
      pre.push({
        ...column,
        name: column.name,
        label: column.label,
        type: column.type,
      });
    }

    return pre;
  }, []);
