import { formatMoney } from 'common/numbers';
import {
  BIMetricsFilter,
  DateFilterOptionsResponse,
} from 'components/dashboard/Metrics/metrics.types';

const FILTER_OPERATOR_TO_TEXT_MAP: Record<string, string> = {
  lt: 'is less than',
  lte: 'is less than or equal to',
  eq: 'is equal to',
  gte: 'is greater than or equal to',
  gt: 'is greater than',
  in: 'is in',
  not_in: 'is not in',
  is_null: 'is null',
  is_not_null: 'is not null',
};

const EXCLUDE_VALUE_FIELD_BY_OPERATOR = new Set(['is_null', 'is_not_null']);

const getFilterValue = (
  filter: BIMetricsFilter,
  currencyCode: string,
  timeFilterOptions: DateFilterOptionsResponse,
  forecastPeriodOptions: Filters.DateFilterElement[]
) => {
  const value = filter.value;

  if (Array.isArray(value)) {
    return value.join(', ');
  }

  const type = filter.column?.type;
  if (type === 'money') {
    return formatMoney(currencyCode, Number(filter.value));
  }

  if (type === 'date') {
    const isForecastPeriodFilter =
      filter.column?.name === 'forecast_submission.forecast_period';

    if (isForecastPeriodFilter) {
      for (const option of forecastPeriodOptions) {
        if (option.value === value) {
          return option.label;
        }
      }
    } else {
      for (const key of Object.keys(timeFilterOptions)) {
        const filterOptions =
          timeFilterOptions[key as keyof typeof timeFilterOptions];
        for (const option of filterOptions) {
          if (option.value === value) {
            return option.label;
          }
        }
      }
    }
  }

  return value;
};

export const getFilterHumanReadable = (
  filter: BIMetricsFilter,
  currencyCode: string,
  timeFilterOptions: DateFilterOptionsResponse,
  forecastPeriodOptions: Filters.DateFilterElement[]
): string => {
  const label = filter.column?.label;
  const operatorText = FILTER_OPERATOR_TO_TEXT_MAP[filter.operator];

  let value = getFilterValue(
    filter,
    currencyCode,
    timeFilterOptions,
    forecastPeriodOptions
  );

  // this is a workaround just to not fetch all the option just to replace only this case.
  value =
    typeof value === 'string' ? value.replace('__null__', 'Blank') : value;

  return `${label} ${operatorText} ${
    EXCLUDE_VALUE_FIELD_BY_OPERATOR.has(filter.operator) ? '' : value
  }`;
};
