import React from 'react';
import { connect } from 'react-redux';

import { actions } from 'actions';
import { ViewBy } from 'common/enums/filters';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';
import BuGroupButton from 'components/UI/BuGroupButton';

type ReduxStateProps = ReturnType<typeof stateToProps>;
type ReduxDispatchProps = typeof dispatchToProps;
type Props = ReduxStateProps & ReduxDispatchProps;

const ViewTypeButtons: React.FC<Props> = ({ viewBy, updateViewBy }) => {
  const buildOptionGroupData = () => {
    return [
      {
        id: 'manager',
        text: `Manager View`,
      },
      {
        id: 'seller',
        text: `Seller View`,
      },
    ];
  };

  return (
    <BuGroupButton
      options={buildOptionGroupData()}
      selectedOption={viewBy}
      onSelect={(value: string) => {
        updateViewBy(value as ViewBy);
      }}
      useRevampStyle
    />
  );
};

const stateToProps = (state: IReduxState) => ({
  viewBy: selectors.filtersViewBy(state),
});

const dispatchToProps = {
  updateViewBy: actions.ui.filters.updateViewBy,
};

export default connect(stateToProps, dispatchToProps)(ViewTypeButtons);
