import classNames from 'classnames';
import React from 'react';

import { fontCaption } from 'assets/css/common';
import { Container, Dot, Bar } from 'components/UI/BuToggle/styles';

export type IToggleProps = {
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  disabled?: boolean;
  leftLabel?: boolean;
  readOnly?: boolean;
} & Common.DataTestingType;
const BuToggle: React.FC<IToggleProps> = ({
  checked = false,
  onChange,
  className,
  disabled,
  readOnly,
  children,
  testingLabel = '',
  leftLabel,
  ...rest
}) => {
  const testingId = testingLabel ? `${testingLabel}_BuToggle` : `BuToggle`;
  return (
    <Container
      className={classNames(className, fontCaption, { disabled, readOnly })}
      data-testing={testingId}
      {...rest}
    >
      {leftLabel && children}
      <Dot
        checked={checked}
        aria-checked={checked}
        readOnly
        onChange={!disabled && !readOnly ? onChange : undefined}
      />
      <Bar
        className={classNames({ disabled, readOnly })}
        leftLabel={leftLabel}
      />
      {!leftLabel && children}
    </Container>
  );
};

export default BuToggle;
