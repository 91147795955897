import clx from 'classnames';
import React, { useContext } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import BuToggle from 'components/UI/BuToggle';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import { useClickOutside } from 'components/hooks/useClickOutside';
import {
  ENABLE_MANAGER_HIERARCHY_LABEL,
  REMOVE_MY_REPORTEES_DATA_LABEL,
} from './ManagerActions.constants';
import { container, icon, toggles_container } from './styles';
type IProps = {
  readonly computeUserHierarchyResponse: boolean;
  readonly removeMyReporteesData: boolean | undefined;
  onComputeUserHierarchyResponseChange: (compute: boolean) => void;
  onRemoveMyReporteesDataChange: (show: boolean) => void;
};

export const ManagerActions: React.FC<IProps> = ({
  computeUserHierarchyResponse = false,
  removeMyReporteesData = false,
  onComputeUserHierarchyResponseChange,
  onRemoveMyReporteesDataChange,
}) => {
  const { removeReporteesDataFromManagers: reporteesDataDefaultValue } =
    useContext(RevBISettingsContext);

  const { isOpen: open, refElement, setIsOpen } = useClickOutside();

  const toggle = () => setIsOpen(!open);

  const handleHierarchyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onComputeUserHierarchyResponseChange(e.target.checked);
    toggle();
  };

  const handleRemoveMyReporteesDataChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    onRemoveMyReporteesDataChange(e.target.checked);
    toggle();
  };

  return (
    <div ref={refElement} className={container}>
      <BuButton
        borderless
        secondary
        onClick={toggle}
        size={BuControlSize.REGULAR}
        testingLabel="hierarchy-actions-button"
      >
        <BuIcon
          className={icon}
          color="var(--bu-primary-500)"
          name={BoostUpIcons.Settings}
        />
      </BuButton>

      <div
        className={clx(toggles_container, {
          open,
        })}
      >
        <BuToggle
          checked={computeUserHierarchyResponse}
          onChange={handleHierarchyChange}
          testingLabel="user-hierarchy-change-toggle"
        >
          {ENABLE_MANAGER_HIERARCHY_LABEL}
        </BuToggle>

        <BuToggle
          checked={
            removeMyReporteesData === null
              ? reporteesDataDefaultValue
              : removeMyReporteesData
          }
          onChange={handleRemoveMyReporteesDataChange}
          testingLabel="reportees-data-change-toggle"
        >
          {REMOVE_MY_REPORTEES_DATA_LABEL}
        </BuToggle>
      </div>
    </div>
  );
};
