import { css } from 'emotion';

export const tooltipLabel = css`
  font-family: var(--bu-font-semi-bold);
`;

export const tooltipContainer = css`
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-family: var(--bu-font-regular);
  line-height: 20px;
  font-size: 12px;
`;

export const tooltipCaption = css`
  font-weight: 400;
  line-height: 16.8px;
  color: #6b7275;
`;
