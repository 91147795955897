import { css } from 'emotion';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRouteMatch } from 'react-router-dom';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import { WidgetAnalysisTypes } from 'components/dashboard/Metrics/Create/AnalysisTypes/WidgetAnalysisTypes/WidgetAnalysisTypes';
import { DefinitionsMetricsList } from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/DefinitionsMetricsList/DefinitionsMetricsList';
import FunnelDefaultMetricDefinition from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/FunnelDefaultMetricDefinition';
import { SubTitle } from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/FunnelDefaultMetricDefinition/styles';
import { FunnelDefinition } from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/FunnelDefinition/FunnelDefinition';
import { FunnelMetricsList } from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/FunnelMetricsList/FunnelMetricsList';
import { MetricDefinition } from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/MetricDefinition';
import { ReportDefinition } from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/ReportDefinition/ReportDefinition';
import { WidgetFilters } from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/WidgetFilters/WidgetFilters';
import { DEFAULT_OPPORTUNITIES_ORDER_BY } from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/constants';
import {
  DefinitionsContainer,
  SectionTitle,
  SectionTitleText,
  SectionWrapper,
  WidgetOptionSection,
  WidgetOptionsColumn,
} from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/styles';
import {
  FUNNEL_PREDEFINED_METRICS,
  FUNNEL_PREDEFINED_TOP_METRICS,
} from 'components/dashboard/Metrics/Create/WidgetCreate/constants';
import {
  NOT_SAVED_METRIC,
  OptionSections,
} from 'components/dashboard/Metrics/Create/constants';
import {
  getMetricType,
  isBIMetricFormula,
  isBIMetricSimple,
} from 'components/dashboard/Metrics/Create/utils';
import { parseFormulaToMetricsIdArray } from 'components/dashboard/Metrics/Widget/helper';
import { useFormulaMetricsList } from 'components/dashboard/Metrics/Widget/hooks/useFormulaMetricsList';
import {
  AnalysisType,
  FORECAST_SUBMISSION,
  TARGET,
} from 'components/dashboard/Metrics/constants';
import {
  MetricType,
  VisualizationType,
} from 'components/dashboard/Metrics/enums';
import {
  DataDescriptor,
  BIMetricCreated,
  BIMetricFormula,
  BIMetricSimple,
  BIMetricUnion,
  BIWidget,
  ExternalColumnFieldFilter,
  MetricDisplayInfo,
} from 'components/dashboard/Metrics/metrics.types';
import { fetchApiWithoutCb } from 'utils/network/fetchApiWithoutCb';
import { QueryMethod } from 'utils/network/types';
import { getWidgetObjects } from 'components/dashboard/Metrics/metrics.helpers';

interface Props {
  readonly sectionExpanded: OptionSections;
  readonly widget: BIWidget;
  readonly isValidReportViewInput: boolean;
  readonly isValidFunnelInput: boolean;
  readonly isCreateOrEditMetricMode: boolean;
  setSectionExpanded: Dispatch<SetStateAction<OptionSections>>;
  setIsCreateOrEditMetricMode: Dispatch<SetStateAction<boolean>>;
  setIsSidebarOpen: Dispatch<SetStateAction<boolean>>;
  setIsTopMetricsSidebarOpen: Dispatch<SetStateAction<boolean>>;
  onCompleteOptions: (pWidget: Partial<BIWidget>) => void;
}

export const WidgetCreateOptions: React.FC<Props> = ({
  sectionExpanded,
  widget,
  isValidReportViewInput,
  isValidFunnelInput,
  isCreateOrEditMetricMode,
  setSectionExpanded,
  setIsCreateOrEditMetricMode,
  setIsSidebarOpen,
  setIsTopMetricsSidebarOpen,
  onCompleteOptions,
}) => {
  const match = useRouteMatch<{ widgetId: string }>();

  const [isContinueButtonClicked, setContinueButtonClicked] = useState<boolean>(
    () => (match.params.widgetId ? true : false)
  );

  const [metricToEdit, setMetricToEdit] = useState<BIMetricCreated>();

  const [columnList, setColumnList] = useState<DataDescriptor[]>([]);

  const funnelMetrics: BIMetricCreated[] = useMemo(() => {
    if (!widget.funnel_metric_list) return [];

    const metricMapping: { [metricId: string]: BIMetricCreated } =
      FUNNEL_PREDEFINED_METRICS.reduce((acc, metric) => {
        return { [metric._id]: metric, ...acc };
      }, {});

    return widget.funnel_metric_list?.map(
      (metricId) => metricMapping[metricId]
    );
  }, [widget.funnel_metric_list]);

  const funnelTopMetrics: BIMetricCreated[] = useMemo(() => {
    if (!widget.funnel_top_metrics) return [];

    const metricMapping: { [metricId: string]: BIMetricCreated } =
      FUNNEL_PREDEFINED_TOP_METRICS.reduce((acc, metric) => {
        return { [metric._id]: metric, ...acc };
      }, {});

    return widget.funnel_top_metrics?.map(
      (metricId) => metricMapping[metricId]
    );
  }, [widget.funnel_top_metrics]);

  const widgetMetrics: BIMetricCreated[] = widget.metric_list || [];

  const usedFormulaMetricIds: string[] = useMemo(
    () =>
      widgetMetrics
        .filter((m) => getMetricType(m) === MetricType.Formula)
        .flatMap((m) =>
          parseFormulaToMetricsIdArray((m as BIMetricFormula).synthetic_metric)
        ),
    [widgetMetrics]
  );

  const { data: usedFormulaMetricsMap } =
    useFormulaMetricsList(usedFormulaMetricIds);

  const objsSet = new Set(
    widgetMetrics.map((m) => (m as BIMetricSimple)?.object)
  );

  useEffect(() => {
    const abortController = new AbortController();
    let url = `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_column_fields_filter`;
    let queryMethod: QueryMethod = `post`;

    const tables = getWidgetObjects(widget, usedFormulaMetricsMap);
    let queryParams: ExternalColumnFieldFilter = { tables };

    if (widget.analysis_type === AnalysisType.HISTORICAL) {
      url = `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_column_fields_filter_history`;
      queryMethod = 'get';
      queryParams = { tables: [] };
    }

    if (widget.analysis_type === AnalysisType.HISTORICAL || tables.length) {
      fetchApiWithoutCb<ExternalColumnFieldFilter, any>({
        url,
        queryMethod,
        queryParams,
        signal: abortController.signal,
      }).then(({ result }) => {
        setColumnList(result);
      });
    }

    return () => {
      abortController.abort();
      setColumnList([]);
    };
  }, [
    widget.analysis_type,
    JSON.stringify(Array.from(objsSet)),
    JSON.stringify(Object.keys(usedFormulaMetricsMap)),
  ]);

  /* Handlers */
  const handleChangeAnalysisType = (analysisType: AnalysisType): void => {
    onCompleteOptions({
      analysis_type: analysisType,
      widget_filters: [],
      template_filters: [],
      group_by: [],
      chart_type:
        analysisType === AnalysisType.REPORT
          ? 'table'
          : analysisType === AnalysisType.FUNNEL
          ? 'funnel'
          : 'column',
      order_by_column: DEFAULT_OPPORTUNITIES_ORDER_BY,
      time_period:
        analysisType === AnalysisType.FUNNEL
          ? 'current_quarter-predefined'
          : undefined,
      funnel_object:
        analysisType === AnalysisType.FUNNEL ? 'opportunity' : undefined,
    });
  };

  const handleContinueButtonClick = (nextSection: OptionSections): void => {
    setSectionExpanded(nextSection);
    setContinueButtonClicked(true);
  };

  const handleRemoveMetric = (
    fieldName: 'metric_list' | 'funnel_metric_list' | 'funnel_top_metrics',
    removedMetricId: string
  ): void => {
    if (fieldName === 'metric_list') {
      const metricsList: BIMetricCreated[] = widget.metric_list.filter(
        (metric: BIMetricCreated) => metric._id !== removedMetricId
      );

      // removes the metric also from the metricToChartType
      const metricToChartType =
        widget.properties?.metricToChartType?.filter(
          (metricToChart) => metricToChart.metricId !== removedMetricId
        ) ?? [];

      // we also need to remove metric info from the advanced_configurations
      const { [removedMetricId]: _, ...newMetricsConf } =
        widget.advanced_configurations?.display?.metrics ?? {};

      // if the metric_list is empty we should clean the pivots.
      const newGroupBy = !!metricsList.length ? widget.group_by : [];

      onCompleteOptions({
        metric_list: metricsList,
        template_filters: metricsList.length ? widget.template_filters : [],
        group_by: newGroupBy,
        properties: {
          metricToChartType: metricToChartType,
        },
        advanced_configurations: {
          ...widget.advanced_configurations,
          display: {
            ...(widget.advanced_configurations?.display ?? {}),
            metrics: newMetricsConf,
          },
        },
      });
    } else {
      onCompleteOptions({
        [fieldName]:
          widget[fieldName]?.filter(
            (metricId: string) => metricId !== removedMetricId
          ) ?? [],
      });
    }
  };

  const handleSaveMetric = (pMetric: BIMetricUnion): void => {
    let action: QueryMethod = 'post';
    let url = `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics`;
    const isEdition = pMetric._id !== NOT_SAVED_METRIC;
    if (
      (isBIMetricSimple(pMetric) || isBIMetricFormula(pMetric)) &&
      isEdition
    ) {
      action = 'put';
      url = `${url}/${pMetric._id}`;
    }
    fetchApiWithoutCb<BIMetricUnion, BIMetricCreated>({
      queryMethod: action,
      url,
      queryParams: pMetric,
    }).then(({ result }) => {
      let newList: BIMetricCreated[] = [];
      let newMetricToChartType = widget.properties?.metricToChartType ?? [];
      if (result) {
        // TODO verify what happens with advance configurations
        const replaceMetric = isEdition ? result._id : NOT_SAVED_METRIC;
        // after save the new metric, the id should be updated in
        // the metric list and Metric To Chart Type
        newList = widget.metric_list?.map((metric: BIMetricCreated) =>
          metric._id === replaceMetric ? result : metric
        );
        newMetricToChartType = newMetricToChartType.map((mtct) =>
          mtct.metricId === replaceMetric
            ? { ...mtct, metricId: result._id }
            : { ...mtct }
        );
      }
      onCompleteOptions({
        metric_list: newList,
        properties: {
          metricToChartType: newMetricToChartType,
        },
      });
      setIsCreateOrEditMetricMode(false);
    });
  };

  /**
   * handle any metric change, if the user edit the metric and cancel the action this undo the changes.
   * if the user makes any change should trigger the previsualization.
   * @param action preview | undo |
   * @param metric new metric configuration
   */
  const handleMetricVisualization = (
    action: string,
    metric?: BIMetricCreated
  ): void => {
    const wasAlreadyAdded = widgetMetrics.some(
      (metric: BIMetricCreated) => metric._id === NOT_SAVED_METRIC
    );

    const isTempMetric =
      metric && (metric as BIMetricCreated)._id === NOT_SAVED_METRIC;

    let newList = [...widgetMetrics];
    let newMetricToChartType = [
      ...(widget.properties?.metricToChartType ?? []),
    ];

    const replaceMetric = (list: BIMetricCreated[], metric: BIMetricCreated) =>
      list.map((m: BIMetricCreated) => (m._id === metric._id ? metric : m));

    if (action === 'preview' && metric) {
      if (wasAlreadyAdded) {
        newList[newList.length - 1] = metric;
      } else if (isTempMetric) {
        newList.push(metric);
        newMetricToChartType = [
          ...newMetricToChartType,
          {
            metricId: NOT_SAVED_METRIC,
            chartType: newMetricToChartType.length
              ? newMetricToChartType[0].chartType
              : VisualizationType.Column,
          },
        ];
      } else {
        newList = replaceMetric(newList, metric);
      }
    } else if (action === 'undo' && !wasAlreadyAdded && metricToEdit) {
      newList = replaceMetric(newList, metricToEdit);
    } else if (wasAlreadyAdded) {
      newList.pop();
      newMetricToChartType.pop();
    }
    onCompleteOptions({
      metric_list: newList,
      properties: {
        metricToChartType: newMetricToChartType,
      },
    });
  };

  const handleAdvancedConfigurationsChanges = useCallback(
    (metricId: string, configuration: MetricDisplayInfo): void => {
      const newAdvancedMetricDisplayConf = {
        ...(widget.advanced_configurations?.display?.metrics ?? {}),
      };
      newAdvancedMetricDisplayConf[metricId] = {
        ...(newAdvancedMetricDisplayConf[metricId] ?? {}),
        ...configuration,
      };

      onCompleteOptions({
        advanced_configurations: {
          ...widget.advanced_configurations,
          display: {
            ...widget.advanced_configurations?.display,
            metrics: newAdvancedMetricDisplayConf,
          },
        },
      });
    },
    [widget, widget.properties?.metricToChartType]
  );
  /* Handlers End */

  const isFunnelCompleted: boolean =
    widget.funnel_stage_column !== undefined &&
    widget.funnel_stages?.length !== 0;
  const isSectionTypeExpanded: boolean =
    sectionExpanded === OptionSections.TYPE;
  const isAnalysisTypeEqualFunnel: boolean =
    widget.analysis_type === AnalysisType.FUNNEL;
  const sectionsBasedOnAnalysisType: 4 | 3 = isAnalysisTypeEqualFunnel ? 4 : 3;

  const metricsSectionValid: boolean = Boolean(
    (widgetMetrics.length > 0 &&
      (((widgetMetrics[0] as BIMetricSimple)?.aggregation_function &&
        (widgetMetrics[0] as BIMetricSimple)?.column) ||
        (widgetMetrics[0] as BIMetricSimple)?.object === TARGET ||
        (widgetMetrics[0] as BIMetricSimple)?.object ===
          FORECAST_SUBMISSION)) ||
      (widgetMetrics[0] as BIMetricFormula)?.synthetic_metric ||
      isValidReportViewInput ||
      isValidFunnelInput
  );

  const isForecastSubmissionWidget: boolean | null = useMemo(() => {
    if (
      widget.metric_list?.length === 0 ||
      (widget.metric_list?.length === 1 && isCreateOrEditMetricMode)
    ) {
      return null;
    }

    if (widget.metric_list?.[0]?.object === FORECAST_SUBMISSION) {
      return true;
    }

    return false;
  }, [widget.metric_list]);

  return (
    <WidgetOptionsColumn data-testing="widget-section">
      <WidgetOptionSection
        expanded={isSectionTypeExpanded}
        sections={sectionsBasedOnAnalysisType}
        first
        data-testing="type-of-widget-section"
      >
        <SectionTitle
          onClick={() => {
            setSectionExpanded(OptionSections.TYPE);
          }}
        >
          <BuIcon
            name={BoostUpIcons.BadgeCheckSolid}
            color={
              isContinueButtonClicked
                ? 'var(--bu-green-500)'
                : 'var(--bu-gray-500)'
            }
          />
          <SectionTitleText>Type of widget</SectionTitleText>
          <BuIcon
            name={
              isSectionTypeExpanded
                ? BoostUpIcons.ChevronUp
                : BoostUpIcons.ChevronDown
            }
            className={css`
              font-size: 20px;
            `}
          />
        </SectionTitle>
        <SectionWrapper hidden={sectionExpanded !== OptionSections.TYPE}>
          <WidgetAnalysisTypes
            selectedAnalysisType={
              (widget.analysis_type as AnalysisType) || AnalysisType.LIVE
            }
            disabled={isContinueButtonClicked}
            onChangeAnalysisType={handleChangeAnalysisType}
            onContinueButtonClick={handleContinueButtonClick}
          />
        </SectionWrapper>
      </WidgetOptionSection>

      <WidgetOptionSection
        hidden={widget.analysis_type !== AnalysisType.FUNNEL}
        expanded={sectionExpanded === OptionSections.FUNNEL}
        sections={sectionsBasedOnAnalysisType}
        first={false}
        data-testing="funnel-widget-definition-section"
      >
        <SectionTitle
          onClick={() => {
            if (isAnalysisTypeEqualFunnel) {
              setSectionExpanded(OptionSections.FUNNEL);
            }
          }}
        >
          <BuIcon
            name={BoostUpIcons.BadgeCheckSolid}
            color={
              isFunnelCompleted ? 'var(--bu-green-500)' : 'var(--bu-gray-500)'
            }
          />
          <SectionTitleText>Funnel definition</SectionTitleText>
          <BuIcon
            name={
              sectionExpanded === OptionSections.FUNNEL
                ? BoostUpIcons.ChevronUp
                : BoostUpIcons.ChevronDown
            }
            className={css`
              font-size: 20px;
            `}
          />
        </SectionTitle>
        <SectionWrapper hidden={sectionExpanded !== OptionSections.FUNNEL}>
          <DefinitionsContainer>
            {isAnalysisTypeEqualFunnel && (
              <FunnelDefinition
                widget={widget}
                onCompleteOptions={(changes: Partial<BIWidget>) =>
                  onCompleteOptions(changes)
                }
                onContinueButtonClick={handleContinueButtonClick}
              />
            )}
          </DefinitionsContainer>
        </SectionWrapper>
      </WidgetOptionSection>

      <WidgetOptionSection
        expanded={sectionExpanded === OptionSections.METRIC}
        sections={sectionsBasedOnAnalysisType}
        first={false}
        data-testing="widget-definition-section"
      >
        <SectionTitle
          onClick={() => {
            setSectionExpanded(OptionSections.METRIC);
          }}
        >
          <BuIcon
            name={BoostUpIcons.BadgeCheckSolid}
            color={
              metricsSectionValid ? 'var(--bu-green-500)' : 'var(--bu-gray-500)'
            }
          />
          <SectionTitleText>
            {widget.analysis_type === AnalysisType.REPORT
              ? 'Report '
              : 'Metrics '}
            definition
          </SectionTitleText>
          <BuIcon
            name={
              sectionExpanded === OptionSections.METRIC
                ? BoostUpIcons.ChevronUp
                : BoostUpIcons.ChevronDown
            }
            className={css`
              font-size: 20px;
            `}
          />
        </SectionTitle>
        <SectionWrapper hidden={sectionExpanded !== OptionSections.METRIC}>
          <DefinitionsContainer>
            {isAnalysisTypeEqualFunnel && (
              <>
                <FunnelDefaultMetricDefinition
                  widget={widget}
                  onCompleteOptions={(changes: Partial<BIWidget>) =>
                    onCompleteOptions(changes)
                  }
                />
                <SubTitle>Metrics</SubTitle> {/* Title For the next section */}
              </>
            )}

            {widget.analysis_type !== AnalysisType.REPORT && (
              <>
                {isCreateOrEditMetricMode ? (
                  <MetricDefinition
                    key={(metricToEdit as BIMetricCreated)?._id ?? 'new'}
                    analysisType={widget.analysis_type as AnalysisType}
                    isForecastSubmissionWidget={isForecastSubmissionWidget}
                    metric={metricToEdit}
                    setIsCreateOrEditMetricMode={setIsCreateOrEditMetricMode}
                    onSaveMetric={handleSaveMetric}
                    manageMetricVisualization={handleMetricVisualization}
                  />
                ) : isAnalysisTypeEqualFunnel ? (
                  <div data-testing="existing-metrics-content">
                    <FunnelMetricsList
                      metricsFromList={funnelMetrics}
                      setIsSideBarOpen={setIsSidebarOpen}
                      onRemoveMetric={(metric) =>
                        handleRemoveMetric('funnel_metric_list', metric._id)
                      }
                    />
                  </div>
                ) : (
                  <DefinitionsMetricsList
                    widget={widget}
                    setIsCreateOrEditMetricMode={setIsCreateOrEditMetricMode}
                    setIsSideBarOpen={setIsSidebarOpen}
                    setMetricIdToEdit={(id: string) =>
                      setMetricToEdit(widgetMetrics.find((m) => m._id === id))
                    }
                    onReorder={(newList) => {
                      onCompleteOptions({
                        metric_list: newList,
                      });
                    }}
                    onRemoveMetric={(metric) =>
                      handleRemoveMetric('metric_list', metric._id)
                    }
                    onChangeAdvanceConfig={handleAdvancedConfigurationsChanges}
                  />
                )}
              </>
            )}

            {isAnalysisTypeEqualFunnel && (
              <>
                <SubTitle>Top Metrics</SubTitle>
                <div data-testing="top-metrics-content">
                  <FunnelMetricsList
                    metricsFromList={funnelTopMetrics}
                    setIsSideBarOpen={setIsTopMetricsSidebarOpen}
                    onRemoveMetric={(metric) =>
                      handleRemoveMetric('funnel_top_metrics', metric._id)
                    }
                  />
                </div>
              </>
            )}

            {widget.analysis_type === AnalysisType.REPORT && (
              <ReportDefinition
                key={widget._id || 'new'}
                widget={widget}
                columnList={columnList}
                setWidget={onCompleteOptions}
              />
            )}
          </DefinitionsContainer>
        </SectionWrapper>
      </WidgetOptionSection>

      <WidgetOptionSection
        expanded={sectionExpanded === OptionSections.TEMPLATE_FILTERS}
        sections={widget.analysis_type === AnalysisType.FUNNEL ? 4 : 3}
        first={false}
        data-testing="widget-filters-section"
      >
        <SectionTitle
          onClick={() => {
            setSectionExpanded(OptionSections.TEMPLATE_FILTERS);
          }}
        >
          <BuIcon
            name={BoostUpIcons.BadgeCheckSolid}
            color={
              widget.widget_filters?.length
                ? 'var(--bu-green-500)'
                : 'var(--bu-gray-500)'
            }
          />
          <SectionTitleText>Widget filters (optional)</SectionTitleText>
          <BuIcon
            name={
              sectionExpanded === OptionSections.TEMPLATE_FILTERS
                ? BoostUpIcons.ChevronUp
                : BoostUpIcons.ChevronDown
            }
            className={css`
              font-size: 20px;
            `}
          />
        </SectionTitle>

        <SectionWrapper
          hidden={sectionExpanded !== OptionSections.TEMPLATE_FILTERS}
        >
          <WidgetFilters
            key={widget._id || 'new'}
            analysisType={widget.analysis_type}
            columns={columnList}
            templateFilters={widget.template_filters}
            widgetFilters={widget.widget_filters}
            updateWidget={onCompleteOptions}
          />
        </SectionWrapper>
      </WidgetOptionSection>
    </WidgetOptionsColumn>
  );
};
