import { useElementSize } from 'components/hooks/useElementSize';
import { useMemo } from 'react';
import { singleTotalContainer, singleTotalItemContainer } from './styles';

interface SingleTotalLayoutProps {
  children: React.ReactNode;
}
export const SingleTotalSectionLayout = ({
  children,
}: SingleTotalLayoutProps) => (
  <div data-testing="totals-container" style={{ height: '100%' }}>
    <div className={singleTotalContainer} data-testing="totals-block-section">
      {children}
    </div>
  </div>
);

interface SingleTotalItemLayoutProps {
  children: React.ReactNode;
  isClickable: boolean;
  'data-testing': string;
  handleClick: () => void;
}

export const SingleTotalItemLayout = ({
  children,
  isClickable,
  'data-testing': dataTesting,
  handleClick,
}: SingleTotalItemLayoutProps) => {
  const [containerRef, { height, width }] = useElementSize();
  const fontSize: number = useMemo(() => {
    const baseFontSize = 6;

    const heightFactor = 0.023;
    const widthFactor = 0.018;

    return (
      baseFontSize +
      Math.ceil(height * heightFactor) +
      Math.ceil(width * widthFactor)
    );
  }, [height, width]);

  return (
    <div
      ref={containerRef}
      data-testing={dataTesting}
      className={singleTotalItemContainer({ fontSize, isClickable })}
      onClick={isClickable ? handleClick : () => {}}
    >
      {children}
    </div>
  );
};
