import { push } from 'connected-react-router';

import * as t from 'actions/actionTypes';
import { postAction } from 'actions/baseActions';
import * as genericSagas from 'sagas/generic';

export const adminActionType = {
  UPDATE_STATE: 'ADMIN_UPDATE_STATE',
  RESET_STATE: 'ADMIN_RESET_STATE',
};

export const selectedClassType = {
  USERS: 1,
  USER_JOBS: 2,
  CALL_JOBS: 3,
  COMPANIES: 4,
};

export const createAdminAction = {
  updateState: (key, value) => ({
    type: adminActionType.UPDATE_STATE,
    key,
    value,
  }),
  resetState: () => ({
    type: adminActionType.RESET_STATE,
  }),
  processApiSuccess: (response) =>
    createAdminAction.updateState('apiSuccess', response),
  processApiError: (response) =>
    createAdminAction.updateState('apiError', response),
  processCompanies: (response) =>
    createAdminAction.updateState({
      companies: response.data.companies,
      apiError: null,
    }),
  processUsers: (response) =>
    createAdminAction.updateState({
      users: response.data.users,
      apiError: null,
    }),
  processUserJobs: (response) =>
    createAdminAction.updateState({
      user_jobs: response.data,
      apiError: null,
    }),
  processCallJobs: (response) =>
    createAdminAction.updateState({
      call_jobs: response.data,
      apiError: null,
    }),
};

export const doGetCompanies = (
  email,
  api_access_token,
  successCallback,
  failureCallback
) => {
  const success = successCallback || createAdminAction.processCompanies;
  const failure = failureCallback || createAdminAction.processApiError;
  return postAction(
    `${process.env.REACT_APP_BACKEND_URL}/api/admin/company/list`,
    {
      email,
      api_access_token,
    },
    success,
    failure
  );
};

export const doGetUsers = (
  email,
  api_access_token,
  email_domain,
  successCallback,
  failureCallback
) => {
  const success = successCallback || createAdminAction.processUsers;
  const failure = failureCallback || createAdminAction.processApiError;
  return postAction(
    `${process.env.REACT_APP_BACKEND_URL}/api/admin/users/list`,
    {
      email,
      api_access_token,
      email_domain,
    },
    success,
    failure
  );
};

export const doUpdateUserRole = (email, api_access_token, user_email, role) =>
  postAction(
    `${process.env.REACT_APP_BACKEND_URL}/api/admin/user-role/update`,
    {
      email,
      api_access_token,
      user_email,
      role,
    },
    createAdminAction.processApiSuccess,
    createAdminAction.processApiError
  );

export const impersonateUser = (userId) => ({
  type: t.ADMIN + t.IMPERSONATE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/admin/impersonate`,
  data: {
    user_id: userId,
  },
  saga: genericSagas.create,
  success: () => ({
    type: t.ADMIN + t.IMPERSONATE + t.SUCCESS + t.INIT,
  }),
  error: () => ({
    type: t.ADMIN + t.IMPERSONATE + t.ERROR,
  }),
  loading: () => ({
    type: t.ADMIN + t.IMPERSONATE + t.LOADING,
  }),
});

export const restoreAdmin = () => ({
  type: t.ADMIN + t.RESTORE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/admin/restore`,
  saga: genericSagas.create,
  success: () => push('/admin/dashboard'),
  error: () => ({
    type: t.ADMIN + t.RESTORE + t.ERROR,
  }),
  loading: () => ({
    type: t.ADMIN + t.RESTORE + t.LOADING,
  }),
});
