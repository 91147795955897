import { useQuery } from '@tanstack/react-query';
import {
  ForecastChangeSummaryItemSchema,
  getForecastSummary,
} from 'api/ForecastSummary';
import { formatAmount, formatMoney } from 'common/numbers';
import { SerieRowWrapper } from 'components/dashboard/ForecastRollUps/CustomColumnTooltips/styles';
import * as styles from 'components/dashboard/ForecastRollUps/CustomColumnTooltips/MyOrgCustomTooltip/styles';
import moment from 'moment';
import { FiltersForAPI } from 'selectors';
import { Dimmer, Loader } from 'semantic-ui-react';
import {
  CardsList,
  DealLink,
  FetchingMessage,
  LeftSide,
  NoDataMessage,
  RightSide,
  SummaryDate,
  cardBackgroundColor,
  cardBorderColor,
} from 'components/dashboard/ForecastRollUps/CustomColumnTooltips/MyOrgCustomTooltip/styles';
import Badge from 'components/dashboard/Forecast/Dashboard/Badge';
import { openModal } from 'navigation/utils';
import { useDispatch } from 'react-redux';
import { actions } from 'actions';
import { PersistQueryParams } from 'navigation/types';

type Props = {
  managerEmail: string;
  rollUpsFilters: FiltersForAPI;
  includeDisabledUsers: boolean;
  companyCurrency: string;
};

const ForecastSummary: React.FC<Props> = ({
  managerEmail,
  rollUpsFilters,
  companyCurrency,
}) => {
  const dispatch = useDispatch();

  // Request the data
  const requestPayload = {
    close_date_interval: rollUpsFilters.close_date_interval,
    change_interval: rollUpsFilters.change_interval,
    business_type_name: `${rollUpsFilters.business_type_name}`,
    user: managerEmail,
  };
  const { isFetching, data, isLoading, dataUpdatedAt } = useQuery({
    queryKey: [
      'forecastSummary',
      managerEmail,
      rollUpsFilters.close_date_interval,
    ],
    queryFn: () => getForecastSummary(requestPayload),
  });

  const openIncludedDealsModal = (
    submissionId: string,
    forecast_name: string
  ) => {
    const businessType = `${rollUpsFilters.business_type_name}`;
    const title = `${businessType} ${forecast_name} included deals`;
    openModal({
      scheme: '/deals/included/:submissionId',
      params: {
        submissionId,
        localStorageKeyPrefix: 'DealsIncluded',
      },
      persistParams: {
        apiUrl: `/api/data/forecast/submission/deals_data_history/${submissionId}`,
        title: title,
        isHistoricalSubmissionsModal: true,
        filterIncludedTogglerInitialState: 'on',
        submissionSetting: { id: submissionId },
      },
      persistor: (params: PersistQueryParams) =>
        dispatch(actions.ui.modal.persist(params)),
    });
  };

  const getAmountCard = (item: ForecastChangeSummaryItemSchema) => {
    if (
      !item.forecast_amount_delta ||
      item.forecast_amount_delta?.delta_amount == undefined
    ) {
      return null;
    }

    const amount = formatAmount(
      item.forecast_amount_delta.current_amount,
      companyCurrency
    );
    const prevAmount = formatAmount(
      item.forecast_amount_delta.prev_amount,
      companyCurrency
    );
    const { delta_percentage, sentiment } = item.forecast_amount_delta;
    const amountDiff = formatAmount(
      item.forecast_amount_delta.delta_amount,
      companyCurrency
    );

    const cardBorder = cardBorderColor(sentiment);
    const cardBackground = cardBackgroundColor(sentiment);
    const changed = sentiment === null ? '' : ' has changed';
    return (
      <SerieRowWrapper
        borderColor={cardBorder}
        backgroundColor={cardBackground}
        cursor={''}
      >
        <span>
          Forecast {changed}: {Math.round(delta_percentage)}% ({amountDiff}){' '}
          <br /> {prevAmount} {'>'} {amount}
        </span>
      </SerieRowWrapper>
    );
  };

  const getNoDataMessage = (item: ForecastChangeSummaryItemSchema) => {
    if (!item.basic_summary.id) {
      return (
        <NoDataMessage>
          Forecast summary is not available as this user has not made any
          submission yet on this forecast type. Visit again once there is at
          least one forecast submission made.
        </NoDataMessage>
      );
    }
    if (!item.basic_summary.prev_id) {
      return (
        <NoDataMessage>
          We are unable to generate the forecast summary for this forecast type
          as there is no data about a previous submission based on currently
          selected Changes Since filter value.
        </NoDataMessage>
      );
    }
    return null;
  };

  const getDealsIncludedCard = (item: ForecastChangeSummaryItemSchema) => {
    if (
      !item.forecast_count_delta ||
      item.forecast_count_delta?.prev_count == undefined ||
      item.forecast_count_delta?.current_count == undefined
    ) {
      return null;
    }
    const { current_count, prev_count, sentiment } = item.forecast_count_delta;

    const prevDeals = (
      <DealLink
        onClick={() =>
          openIncludedDealsModal(
            item.basic_summary.prev_id,
            item.basic_summary.display_name
          )
        }
      >
        {prev_count} deals
      </DealLink>
    );

    const currentDeals = (
      <DealLink
        onClick={() =>
          openIncludedDealsModal(
            item.basic_summary.id,
            item.basic_summary.display_name
          )
        }
      >
        {current_count} deals
      </DealLink>
    );

    const cardBorder = cardBorderColor(sentiment);
    const cardBackground = cardBackgroundColor(sentiment);
    return (
      <SerieRowWrapper
        borderColor={cardBorder}
        backgroundColor={cardBackground}
        cursor={''}
      >
        <span>
          Deals included{' '}
          {sentiment === null ? '' : sentiment ? 'went up' : 'gone down'}:{' '}
          {prevDeals} {'>'} {currentDeals}
        </span>
      </SerieRowWrapper>
    );
  };

  const summaryItems =
    data && data?.data.length ? (
      data.data.map((item) => (
        <div className={styles.forecastItem} key={item.basic_summary.id}>
          <LeftSide>
            <div className={styles.forecastName}>
              {item.basic_summary.display_name}
            </div>
            {item.basic_summary.created_at ? (
              <div className={styles.forecastDate}>
                {moment(item.basic_summary.created_at).format(
                  "MMM DD' YY, h.mma"
                )}
              </div>
            ) : null}
          </LeftSide>
          <RightSide>
            {item.basic_summary.id ? (
              <div className={styles.rightHeader}>
                <div className={styles.amount}>
                  <div>
                    {formatMoney(companyCurrency, item.basic_summary.amount)}
                  </div>
                  <div className={styles.deltaBadge}>
                    <Badge value={item.basic_summary.delta_amount} />
                  </div>
                </div>
                <div className={styles.dealsCount}>
                  <DealLink
                    onClick={() =>
                      openIncludedDealsModal(
                        item.basic_summary.id,
                        item.basic_summary.display_name
                      )
                    }
                  >
                    Deals: {item.basic_summary.len_included_deals} of{' '}
                    {item.basic_summary.total_deals} (
                    {formatMoney(
                      companyCurrency,
                      item.basic_summary.included_deals_amount
                    )}
                    )
                  </DealLink>
                </div>
              </div>
            ) : null}
            {item.basic_summary.prev_created_at ? (
              <SummaryDate>
                Delta based on last submission on{' '}
                {moment(item.basic_summary.prev_created_at)
                  .endOf('D')
                  .format("MMM DD' YY, h.mma")}
              </SummaryDate>
            ) : null}
            <CardsList>
              {getNoDataMessage(item)}
              {getAmountCard(item)}
              {getDealsIncludedCard(item)}
            </CardsList>
          </RightSide>
        </div>
      ))
    ) : (
      <NoDataMessage>
        Forecast summaries are not available as this user has not made any
        submission yet. Visit again once there is at least one forecast
        submission made.
      </NoDataMessage>
    );
  return (
    <div className={styles.wrapper}>
      {isLoading ? (
        <Dimmer.Dimmable dimmed className={styles.loaderStyle}>
          <Dimmer inverted active>
            <Loader />
          </Dimmer>
        </Dimmer.Dimmable>
      ) : (
        <>
          <FetchingMessage>
            {isFetching && <Loader active inline size="mini" />}
            <div>
              {dataUpdatedAt && (
                <>Updated {moment(dataUpdatedAt).fromNow() || 'a while ago'}</>
              )}
            </div>
          </FetchingMessage>
          {data && data.user_has_profile ? (
            summaryItems
          ) : (
            <NoDataMessage>
              Forecast summary is not available as you do not have any user
              profile assigned. Contact admin for help.
            </NoDataMessage>
          )}
        </>
      )}
    </div>
  );
};

export default ForecastSummary;
