import React, { useEffect, useState } from 'react';

import NavPanel from 'components/UI/NavPanel';
import { NavPanelGroup, NavPanelLinkItem } from 'components/UI/NavPanel/types';
import RTable from 'components/dashboard/RepsRecommendations/RTable';
import {
  DEFAULT_TABLE,
  tablesToRender,
} from 'components/dashboard/RepsRecommendations/configs';
import { rContainer } from 'components/dashboard/RepsRecommendations/styles';
import {
  RepsRecommendationsProps,
  TableName,
} from 'components/dashboard/RepsRecommendations/types';
import { selectTableTitle } from 'components/dashboard/RepsRecommendations/utils';

const todosGroups: NavPanelGroup<NavPanelLinkItem>[] = [
  {
    key: 'todos',
    label: 'TO DO’S',
    visible: true,
    items: tablesToRender.map((tableName) => ({
      key: tableName,
      label: selectTableTitle[tableName],
      path: `/reps-recommendations/${tableName}`,
      visible: true,
    })),
  },
];

const getTableName = (tableName?: TableName): TableName =>
  tableName && tablesToRender.includes(tableName) ? tableName : DEFAULT_TABLE;

const RepsRecommendations: React.FC<RepsRecommendationsProps> = ({
  match,
}: RepsRecommendationsProps) => {
  const [activeTable, setActiveTable] = useState(
    getTableName(match.params.tableName)
  );

  useEffect(() => {
    setActiveTable(getTableName(match.params.tableName));
  }, [match.params.tableName]);

  return (
    <div className={rContainer} data-cypress="reps-recommendations">
      <NavPanel activeItem={activeTable} groups={todosGroups} />

      <RTable key={activeTable} tableName={activeTable} />
    </div>
  );
};

export default RepsRecommendations;
