import { connect } from 'react-redux';

import * as callActions from 'actions/callActions';
import Tabs from 'components/new-library/Tabs';
import {
  DispatchProps,
  StateProps,
  OwnProps,
} from 'components/new-library/types';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

const mapDispatchToProps = {
  pagination: callActions.pagination,
  clearFilters: callActions.clearFilter,
};

const mapStateToProps = (state: IReduxState) => ({
  loadingStatus: selectors.getCallsStatus(state),
  paginationStatus: selectors.getCallsPaginationStatus(state),
});

export default connect<StateProps, DispatchProps, OwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(Tabs);
