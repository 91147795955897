import styled from '@emotion/styled';

export const WidgetOptionsColumn = styled.div({
  width: '520px',
  minWidth: '520px',
  display: 'flex',
  flexDirection: 'column',
  borderRight: 'solid var(--bu-gray-400) 1px',
});

export const WidgetOptionSection = styled.div(
  (props: { expanded: boolean; first: boolean; sections: number }) =>
    props.expanded
      ? {
          flexGrow: 1,
          overflowX: 'visible',
          overflowY: 'auto',
          borderTop: props.first ? 0 : 'solid var(--bu-gray-400) 1px',
          maxHeight: `calc(100vh - ${(props.sections - 1) * 115}px)`,
        }
      : {
          flexGrow: 0,
          height: '60px',
          minHeight: '60px',
          borderTop: props.first ? 0 : 'solid var(--bu-gray-400) 1px',
        }
);

export const SectionTitle = styled.div({
  fontWeight: 'bolder',
  fontSize: '18px',
  cursor: 'pointer',
  display: 'flex',
  gap: '8px',
  margin: '16px',
});

export const SectionWrapper = styled.div({
  padding: '0 16px 16px 16px',
});

export const SectionTitleText = styled.div({
  flexGrow: 1,
  marginBottom: '24px',
});

export const DefinitionsContainer = styled.div({
  maxHeight: 'calc(100vh - 400px)',
});
