import contactsToFollowUpConfig from 'components/dashboard/RepsRecommendations/configs/contactsToFollowUp';
import meetingsToFollowUpConfig from 'components/dashboard/RepsRecommendations/configs/meetingsToFollowUp';
import upcomingMeetingsWithNoRsvpConfig from 'components/dashboard/RepsRecommendations/configs/upcomingMeetingsWithNoRsvp';
import {
  RTableConfig,
  TableName,
} from 'components/dashboard/RepsRecommendations/types';

export const DEFAULT_TABLE: TableName = 'contact-follow-up';

export const tableConfigs: {
  [key in TableName]: RTableConfig;
} = {
  'contact-follow-up': contactsToFollowUpConfig,
  'meeting-follow-up': meetingsToFollowUpConfig,
  'upcoming-meeting-no-rsvp': upcomingMeetingsWithNoRsvpConfig,
  // todo change to actual
  'email-follow-up': contactsToFollowUpConfig,
  'upcoming-meeting-no-agenda': contactsToFollowUpConfig,
};

export const tablesToRender: TableName[] = [
  'contact-follow-up',
  'upcoming-meeting-no-rsvp',
  'meeting-follow-up',
];
