import * as styles from './styles';
import { IBuSkeleton } from './types';
import classNames from 'classnames';
import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

const BuSkeleton: FC<IBuSkeleton> = (props) => {
  let innerTestingLabel = props.testingLabel
    ? `${props.testingLabel}-Skltn`
    : 'Skltn';

  return (
    <Skeleton
      containerTestId={innerTestingLabel}
      containerClassName={classNames(
        props.containerClassName,
        styles.buSkeleton
      )}
      {...props}
    />
  );
};

export default BuSkeleton;
