import { IColumn, IRow } from '../TypedTable';
import classNames from 'classnames';
import { css } from 'emotion';
import React from 'react';
import { Popup } from 'semantic-ui-react';

import { fontCaption1 } from 'assets/css/fontStyles';
import { getProbabilityPercentage } from 'common/helpers';
import { getOutcomeMessage } from 'components/deal/PredictionPanel/utils';
import { DealOutcomePrediction } from 'components/deal/types';

type IPredictionCell = {
  row: IRow;
  column: IColumn;
};

const predictionStyles = css`
  padding: 0 8px;
  width: fit-content;
  border-radius: 12px;
  text-transform: capitalize;
  font-size: 12px;
  font-family: var(--bu-font-medium);
  cursor: help;
  height: 20px;
  min-width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.na {
    text-transform: uppercase;
    color: var(--bu-white);
    background-color: var(--bu-gray-400);
  }

  &.win {
    color: var(--bu-white);
    background-color: var(--bu-green-500);
  }

  &.lose {
    color: var(--bu-white);
    background-color: var(--bu-red-400);
  }

  &.slip {
    color: var(--bu-white);
    background-color: var(--bu-orange-500);
  }
`;

const predictionInfo = css`
  display: flex;
  flex-direction: column;
  text-align: center;
  ${fontCaption1}

  .prediction-text {
    margin-top: 5px;
  }

  .prediction-probability {
    display: flex;
    justify-content: space-evenly;
    margin: 10px;

    .win {
      color: var(--bu-green-700);
    }

    .lose {
      color: var(--bu-red-600);
    }

    .slip {
      color: var(--bu-orange-700);
    }
  }
`;

const PredictionCell = ({ column, row }: IPredictionCell) => {
  const outcome = row[column.field] as DealOutcomePrediction | undefined;
  const predictionMessage = getOutcomeMessage(outcome?.prediction);
  const prediction = outcome?.prediction.toLowerCase() ?? 'na';

  const tooltipContent = (
    <div className={predictionInfo}>
      <span className="prediction-text">{predictionMessage}</span>
      {outcome && (
        <div className="prediction-probability">
          <span className="win">
            Win: {getProbabilityPercentage(outcome.win_probability)}
          </span>
          <span className="slip">
            Slip: {getProbabilityPercentage(outcome.slip_probability)}
          </span>
          <span className="lose">
            Lose: {getProbabilityPercentage(outcome.lose_probability)}
          </span>
        </div>
      )}
    </div>
  );

  return (
    <Popup
      basic
      content={tooltipContent}
      position="bottom center"
      mouseEnterDelay={0}
      trigger={
        <div className={classNames(predictionStyles, prediction, fontCaption1)}>
          {prediction}
        </div>
      }
    />
  );
};

export default PredictionCell;
