import { MANAGER_ROLES, USER_ROLES } from 'common/constants';
import { ACTIONS_COLUMN_DEFAULT_WIDTH } from 'components/UI/TableConfig/column-helper';
import {
  IColumn,
  IRow,
  SortOrder,
} from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { CustomCellConfig } from 'components/UI/common/TypedTable/renderers/CustomCell';
import { DropDownCellConfig } from 'components/UI/common/TypedTable/renderers/DropDownCell';
import { UserCellConfig } from 'components/UI/common/TypedTable/renderers/UserCell';
import { editRemoveButtons } from 'components/UI/common/TypedTable/renderers/custom';
import { enabledField } from 'components/UI/common/TypedTable/renderers/custom/EnabledDisabled';
import { syncField } from 'components/UI/common/TypedTable/renderers/custom/SyncField';
import { yesNoImpersonationField } from 'components/UI/common/TypedTable/renderers/custom/YesNoImpersonation';
import * as s from 'components/settings/ManageUsers/styles';
import { RowField } from 'components/settings/ManageUsers/types';
import { openModal } from 'navigation/utils';
import { UserResponse } from 'reducers/companyUsersReducer';

export const OPTION_ALL_VALUE = 'all';

type GetColumnsFunction = (params: { currentUserRole?: string }) => {
  [id: string]: IColumn;
};

export const typedRows = <T extends RowField>(rows: IRow[]): T[] =>
  rows as unknown as T[];
export const typedRow = <T extends RowField>(row: IRow): T =>
  row as unknown as T;

const isRow = <T extends RowField>(row?: IRow): row is T => !!row;

export const isUsersMarked =
  (managers: UserResponse[], searchText?: string) => (user: RowField) =>
    searchText
      ? Boolean(
          user.name?.toLocaleLowerCase().includes(searchText) ||
            user.email?.toLocaleLowerCase().includes(searchText) ||
            user.role?.toLocaleLowerCase().includes(searchText) ||
            user.manager?.toLocaleLowerCase().includes(searchText) ||
            user.title?.toLocaleLowerCase().includes(searchText) ||
            managers
              .find((manager) => manager.email === user.manager)
              ?.name?.includes(searchText)
        )
      : true;

const handleEditClick = (row: IRow) => {
  if (isRow<RowField>(row)) {
    openModal({
      scheme: '/user/:id',
      params: {
        id: encodeURIComponent(row.email),
      },
    });
  }
};

export const getColumns: GetColumnsFunction = ({ currentUserRole }) => ({
  action: {
    id: 'actions',
    label: 'Actions',
    field: 'email',
    type: ColumnTypes.CUSTOM,
    sort_order: SortOrder.ASCENDING,
    width: ACTIONS_COLUMN_DEFAULT_WIDTH,
    minWidth: ACTIONS_COLUMN_DEFAULT_WIDTH,
    maxWidth: ACTIONS_COLUMN_DEFAULT_WIDTH,
    disable: 'isDisabled',
    config: {
      renderer: editRemoveButtons({
        onEdit: handleEditClick,
        canRemove: () => false,
      }),
      className: 'actionColumn',
    } as CustomCellConfig,
  },
  name: {
    id: 'name',
    label: 'Name',
    field: 'name',
    type: ColumnTypes.USER,
    sortable: true,
    sort_order: SortOrder.ASCENDING,
    width: 250,
    maxWidth: 250,
    disable: 'isDisabled',
    config: {
      className: 'primary-cell',
      tokenField: 'access_token',
      click: (column, row: IRow) => handleEditClick(row),
    } as UserCellConfig,
    showTooltip: true,
  },
  email: {
    id: 'email',
    label: 'Email',
    field: 'email',
    type: ColumnTypes.TEXT,
    sortable: true,
    sort_order: SortOrder.ASCENDING,
    config: {},
  },
  role: {
    id: 'role',
    label: 'Role',
    field: 'role',
    sortable: true,
    type: ColumnTypes.TEXT,
    sort_order: SortOrder.ASCENDING,
    config: {},
  },
  userProfile: {
    id: 'profile',
    label: 'User Profile',
    field: 'profile.name',
    sortable: true,
    type: ColumnTypes.TEXT,
    sort_order: SortOrder.ASCENDING,
    config: {},
  },
  manager: {
    id: 'manager',
    label: 'Manager',
    field: 'managerName',
    type: ColumnTypes.TEXT,
    hidden: !Object.values(MANAGER_ROLES).includes(currentUserRole || ''),
    sortable: true,
    sort_order: SortOrder.ASCENDING,
    config: {},
  },
  sfStatus: {
    id: 'crm_active',
    label: 'CRM Status',
    field: 'crm_active',
    type: ColumnTypes.DROPDOWN,
    sortable: true,
    sort_order: SortOrder.ASCENDING,
    config: {
      options: [
        {
          text: 'Active',
          value: true,
          key: 'active',
          className: s.cellActiveColor,
        },
        { text: 'Disabled', value: false, key: 'disabled' },
      ],
    } as DropDownCellConfig,
  },
  emailSync: {
    id: 'emailSync',
    label: 'Email Sync',
    field: 'email_watcher_last_processed_at',
    type: ColumnTypes.CUSTOM,
    editable: false,
    sortable: true,
    sort_order: SortOrder.ASCENDING,
    config: {
      renderer: syncField({
        isEmail: true,
        enabledField: 'user_settings.processing.emails',
        lastSyncDateField: 'email_watcher_last_processed_at',
        watcherFailedField: 'email_watcher_refreshed_failed',
        syncFailedField: 'email_watcher_last_processed_failed',
      }),
    } as CustomCellConfig,
  },
  calendarSync: {
    id: 'calendarSync',
    label: 'Calendar Sync',
    field: 'calendar_watcher_last_processed_at',
    type: ColumnTypes.CUSTOM,
    editable: false,
    sortable: true,
    sort_order: SortOrder.ASCENDING,
    config: {
      renderer: syncField({
        isEmail: false,
        enabledField: 'user_settings.processing.calendar',
        lastSyncDateField: 'calendar_watcher_last_processed_at',
        watcherFailedField: 'calendar_watcher_refreshed_failed',
        syncFailedField: 'calendar_watcher_last_processed_failed',
      }),
    } as CustomCellConfig,
  },
  callSync: {
    id: 'callSync',
    label: 'Call Sync',
    field: 'user_settings.processing.calls',
    type: ColumnTypes.CUSTOM,
    editable: false,
    sortable: false,
    sort_order: SortOrder.ASCENDING,
    config: {
      renderer: enabledField({}),
    } as CustomCellConfig,
  },
  impersonation: {
    id: 'impersonation',
    label: 'Impersonation',
    field: 'impersonation_settings',
    type: ColumnTypes.CUSTOM,
    editable: false,
    sortable: true,
    sort_order: SortOrder.ASCENDING,
    config: {
      renderer: yesNoImpersonationField({}),
    } as CustomCellConfig,
  },
  createdTime: {
    id: 'createdTime',
    label: 'Activated On',
    field: 'created_time',
    type: ColumnTypes.DATE,
    editable: false,
    sortable: false,
    sort_order: SortOrder.ASCENDING,
    config: {},
  },
  status: {
    id: 'status',
    label: 'Status',
    field: 'status',
    type: ColumnTypes.DROPDOWN,
    sortable: true,
    sort_order: SortOrder.ASCENDING,
    config: {
      options: [
        {
          text: 'Active',
          value: 'active',
          key: 'active',
          className: s.cellActiveColor,
        },
        { text: 'Disabled', value: 'disabled', key: 'disabled' },
      ],
    } as DropDownCellConfig,
  },
  id: {
    id: 'id',
    label: 'CRM ID',
    field: 'sfdc_user_id',
    type: ColumnTypes.TEXT,
    sortable: true,
    sort_order: SortOrder.ASCENDING,
    config: {},
  },
});

export const statusOptions: Filters.Checkbox[] = [
  {
    value: OPTION_ALL_VALUE,
    label: 'All',
    checked: false,
  },
  {
    value: 'active',
    label: 'Active',
    checked: false,
  },
  {
    value: 'disabled',
    label: 'Disabled',
    checked: false,
  },
];

export const crmActiveOptions: Filters.Checkbox[] = [
  {
    value: OPTION_ALL_VALUE,
    label: 'All',
    checked: false,
  },
  {
    value: 'true',
    label: 'Active',
    checked: false,
  },
  {
    value: 'false',
    label: 'Disabled',
    checked: false,
  },
];

export const roleOptions: Filters.Checkbox[] = [
  {
    value: OPTION_ALL_VALUE,
    label: 'All',
    checked: false,
  },
  ...Object.values(USER_ROLES).map((role) => ({
    value: role,
    label: role,
    checked: false,
  })),
];

export const createManagerOptions = (
  managers: UserResponse[]
): Filters.Checkbox[] => [
  {
    value: OPTION_ALL_VALUE,
    label: 'All',
    checked: false,
  },
  ...managers
    .filter((item) => item.status !== 'disabled')
    .map((item) => ({
      value: item.email,
      label: item.name || item.email,
      checked: false,
    })),
];

export const syncFailedOptions: Filters.Checkbox[] = [
  {
    value: OPTION_ALL_VALUE,
    label: 'All',
    checked: false,
  },
  {
    value: 'false',
    label: 'Success',
    checked: false,
  },
  {
    value: 'true',
    label: 'Failed',
    checked: false,
  },
];
