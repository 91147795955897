import React, { useMemo, useState } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import BuInput from 'components/UI/BuInput';
import QuickView from 'components/UI/Modal/QuickView';
import { QuickViewHeader } from 'components/UI/Modal/QuickView/styles';
import { QuickViewItem } from 'components/dashboard/Metrics/QuickView/QuickViewItem/QuickViewItem';
import {
  AddNewContainer,
  AddNewParagraph,
  ListContainer,
  NumberOfRecordsParagraph,
  QuickViewContent,
  SearchInputContainer,
} from 'components/dashboard/Metrics/QuickView/styles';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import { SidebarType } from 'components/dashboard/Metrics/enums';
import {
  BIMetricCreated,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';
import { FieldConfigurationSidePanel as styles } from 'components/settings/FieldConfiguration/styles';

interface Props {
  readonly isOpen: boolean;
  readonly list: any[];
  readonly sidebarType: SidebarType;
  readonly selectedIds?: string[];
  readonly onClose: () => void;
  readonly onAdd: (item: any) => void;
  readonly onCreateNew: () => void;
  readonly onCloneAddMetric?: (id: string) => void;
  readonly analysisType?: AnalysisType;
}

export const RevBiQuickView: React.FC<Props> = ({
  isOpen,
  list,
  sidebarType,
  selectedIds = [],
  onClose,
  onAdd,
  onCreateNew,
  onCloneAddMetric,
  analysisType,
}) => {
  const [metricSearch, setMetricSearch] = useState<string>('');

  const filteredList = useMemo(
    () =>
      list.filter((listElement: BIMetricCreated | BIWidget) =>
        listElement?.name?.toLowerCase().includes(metricSearch.toLowerCase())
      ),
    [list, metricSearch]
  );

  return (
    <QuickView size="small" enabled={isOpen} onClose={onClose}>
      <QuickViewHeader className={styles.panelTitle}>
        {`Add ${sidebarType}`}
      </QuickViewHeader>
      <QuickViewContent>
        <ListContainer>
          <AddNewContainer>
            <AddNewParagraph>{`Add an existing ${
              sidebarType === SidebarType.WIDGETS ? 'widget' : 'metric'
            } or create a new one`}</AddNewParagraph>

            {analysisType !== AnalysisType.FUNNEL && (
              <BuButton secondary onClick={onCreateNew}>
                {sidebarType === SidebarType.WIDGETS
                  ? 'Create new widget'
                  : '+ New metric'}
              </BuButton>
            )}
          </AddNewContainer>

          <SearchInputContainer>
            <BuInput
              prefix={<BuIcon name={BoostUpIcons.Search} />}
              placeholder="Search..."
              testingLabel="search"
              value={metricSearch}
              onChange={(e) => {
                const value = e.target.value;
                if (value.trim().length === 0) {
                  setMetricSearch('');
                } else {
                  setMetricSearch(value);
                }
              }}
            />
          </SearchInputContainer>

          <NumberOfRecordsParagraph>
            Showing {filteredList.length}{' '}
            {sidebarType === SidebarType.WIDGETS
              ? 'widget'
              : 'available metric'}
            s
          </NumberOfRecordsParagraph>
          {filteredList?.map((item, idx) => (
            <QuickViewItem
              key={item?._id}
              updatedAt={item.updated_at}
              userName={item.metadata?.user_name ?? 'Unknown User'}
              isAdded={selectedIds.includes(item?._id)}
              sidebarType={sidebarType}
              item={item}
              onAdd={onAdd}
              canClone={analysisType !== AnalysisType.FUNNEL}
              onCloneAddMetric={onCloneAddMetric}
              idx={idx}
            />
          ))}
        </ListContainer>
      </QuickViewContent>
    </QuickView>
  );
};
