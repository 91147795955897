import { css } from 'emotion';

export const wrapper = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 54px 0;
`;

export const boostUpLogo = css`
  margin-bottom: 146px;
  width: 175px;
`;

export const title = css`
  color: #1e306e;
  font-family: var(--bu-font-semi-bold);
  font-size: 48px;
  margin: 10px 0 12px;
`;

export const text = css`
  color: #4a4a4a;
  font-family: var(--bu-font-regular);
  font-size: 18px;
  line-height: 28px;
  text-align: center;
`;

export const link = css`
  color: #0762ec;
  text-decoration: underline;
`;
