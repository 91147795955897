import clx from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import * as R from 'ramda';
import React, { Component } from 'react';
import { Loader, Popup } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { replaceNewLinesToHTML } from 'common/helpers';
import BuIcon from 'components/UI/BuIcon';
import EmailBody from 'components/UI/EmailBody';
import styles from 'components/modals/EmailDetailModal/styles';
import {
  IProps,
  TopicMatchDetail,
} from 'components/modals/EmailDetailModal/types';

class EmailDetailModal extends Component<IProps> {
  componentWillMount(): void {
    // this is needed to prevent empty body in modal
    const { fetch, params } = this.props;
    fetch(params.emailId);
  }

  highlightTerms(body: string, terms: string[]): string {
    if (!isEmpty(body) && !isEmpty(terms)) {
      let result = body;

      terms.forEach((term: string) => {
        const pattern = new RegExp(term, 'gi');

        result = result.replace(
          pattern,
          `<span style="background-color: yellow; padding: 3px;">${term}</span>`
        );
      });
      return result;
    }
    return body;
  }

  render() {
    const { status, email, modalProps, isEmailConvertNewlineEnabled } =
      this.props;

    const self = this;

    if (status === 'loading') {
      return <Loader active />;
    }

    if (status === 'error') {
      return (
        <div className={styles.email_empty_modal}>
          Only emails after April 1st 2018 are accessible
        </div>
      );
    }

    if (R.isNil(email)) {
      return <div className={styles.email_empty_modal}>Email not found</div>;
    }

    function getEmailBody(): string {
      let result = email.body;

      function getTopicMatches(): TopicMatchDetail[] {
        const topicName = get(modalProps, 'topic', false);

        if (topicName && email.topic_match_details) {
          return email.topic_match_details.filter(
            (el: TopicMatchDetail) => el.topic_name === topicName
          );
        }

        if (email.topic_match_details) {
          return email.topic_match_details;
        }

        return [];
      }

      if (email.topic_match_details) {
        const matches = getTopicMatches();

        const terms = matches.reduce((acc: string[], el) => {
          let matches = el.matches.map((m) => m.match);
          return acc.concat(matches);
        }, []);

        result = self.highlightTerms(email.body, terms);
      }

      if (isEmailConvertNewlineEnabled) {
        return replaceNewLinesToHTML(result);
      }

      return result;
    }

    const selectedTopicMatchDetails: TopicMatchDetail =
      email.topic_match_details &&
      email.topic_match_details.find(
        (t: TopicMatchDetail) => modalProps.topic === t.topic_name
      );

    const getMatchDetails = (attachmentName: string) => {
      const attachmentDetails = selectedTopicMatchDetails?.matches.find(
        (item) => item.attachment_name === attachmentName
      );

      return {
        match: attachmentDetails?.match,
        phrase: attachmentDetails?.phrase,
      };
    };

    const renderAttachment = (attachment: string) => {
      const { match } = getMatchDetails(attachment);
      const fileType = attachment.split('.').pop();
      const attachmentItems: string[] = [];

      if (match) {
        attachment
          .substring(0, attachment.lastIndexOf('.'))
          .split(match)
          .forEach((item, index, items) => {
            if (item) {
              attachmentItems.push(item);
            }
            if (index !== items.length - 1) {
              attachmentItems.push(match);
            }
          });
      }

      const content =
        match && attachmentItems.includes(match)
          ? [
              ...attachmentItems.map((item, index) => (
                <span
                  style={{ display: 'inline-block' }}
                  key={`${item}-${index}`}
                  className={match === item ? styles.match : ''}
                >
                  {item}
                </span>
              )),
              <span key={fileType}>
                {'.'}
                {fileType}
              </span>,
            ]
          : attachment;

      return (
        <Popup
          position="top center"
          content={() => (
            <span className={styles.email_attachment_tooltip}>
              {attachment}
            </span>
          )}
          trigger={
            <div key={attachment} className={styles.email_attachment}>
              <BuIcon name={BoostUpIcons.Attachment} color="#737376" />
              {content}
            </div>
          }
        />
      );
    };

    return (
      <div className={styles.box_email}>
        <div className={clx(styles.box_email_line, styles.margin_bottom)}>
          <div className={styles.box_email_icon}>
            <span className={clx(styles.circle, { blue: true })}>
              <BuIcon name={BoostUpIcons.Mail} />
            </span>
          </div>
          <div className={styles.align_left}>
            <div className={clx(styles.subject_name, 'bu-font-heading')}>
              {email.subject}
            </div>
            <div className={styles.date}>
              {moment(email.received_at).format(
                'dddd, DD MMM YYYY [at] HH:mm:ss'
              )}
            </div>
          </div>
        </div>
        <div className={styles.box_email_line}>
          <div className={styles.box_email_icon}>From:</div>
          <div className={styles.text}>{email.sender}</div>
        </div>
        <div className={clx(styles.box_email_line, styles.margin_bottom)}>
          <div className={styles.box_email_icon}>To:</div>
          <div className={styles.text}>
            {email.recepients ? email.recepients.join(', ') : ''}
          </div>
        </div>

        {email.attachments && (
          <div className={styles.box_email_line}>
            <div className={styles.box_email_icon}>Attachments:</div>
            <div
              className={clx(
                styles.box_email_line,
                styles.box_email_attachments
              )}
            >
              {email.attachments.map((a: { name: string }) => (
                <div key={a.name}>{renderAttachment(a.name)}</div>
              ))}
            </div>
          </div>
        )}

        {email.body && (
          <div className={styles.box_email_line}>
            <div className={styles.box_email_icon}>
              <span className={styles.left_icons}>
                <BuIcon name={BoostUpIcons.Dialogue} />
              </span>
            </div>
            <div className={styles.email_body_box}>
              {email.body && <EmailBody body={getEmailBody()} />}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default EmailDetailModal;
