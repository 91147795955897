import {
  HierarchicalWidgetNode,
  WidgetPivotConfiguration,
} from 'api/RevBiWidget';
import { useContext, useMemo, useState } from 'react';
import { COLUMN_DEFAULTS } from '../../Table/constants';
import { isSubtotal } from './ExpandHierarchyCell.styles';
import ExpandableHierarchyCell, {
  IDataExpandableCellProps,
} from './ExpandableHierarcyCell';
import {
  OnExpandClick,
  PathAwareHierarchicalWidgetNode,
  getTreeAsExpandableRows,
} from './useExpandableWidgetTable.helper';

import { getIconByEntityName } from 'common/icons';
import { IColumn } from 'components/UI/common/TypedTable/TypedTable';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';

interface UseExpandableHierarchyParams {
  tree: PathAwareHierarchicalWidgetNode[];
  deltasByPath: Record<string, HierarchicalWidgetNode>;
  pivots: WidgetPivotConfiguration[];
  isExpandableColumnSortable: boolean;
  onExpand: (nodePath: string) => void;
}

const useExpandableWidgetTable = ({
  tree,
  deltasByPath,
  pivots,
  isExpandableColumnSortable,
  onExpand,
}: UseExpandableHierarchyParams) => {
  const [expandedNodePaths, setExpandedNodePaths] = useState<string[]>([]);
  const { tableAliasesConfigs } = useContext(RevBISettingsContext);

  const visibleRows = useMemo(
    () =>
      getTreeAsExpandableRows(tree, pivots, deltasByPath, expandedNodePaths),
    [tree, pivots, deltasByPath, expandedNodePaths]
  );

  const onExpandClick: OnExpandClick = (
    nodePath,
    shouldExpand,
    haveToFetchPivotsIfExpanded
  ) => {
    if (shouldExpand) {
      setExpandedNodePaths((expandedNodePaths) => [
        ...expandedNodePaths,
        nodePath,
      ]);
      if (haveToFetchPivotsIfExpanded) {
        onExpand(nodePath);
      }
    } else {
      setExpandedNodePaths((expandedNodePaths) =>
        expandedNodePaths.filter((path) => !path.startsWith(nodePath))
      );
    }
  };

  const [firstPivot] = pivots;

  const getColumnNameAndIconTable = (depthLevel: number) => {
    const pivot = pivots[depthLevel - 1];
    return pivot
      ? {
          columnName: pivot.display_name,
          icon: getIconByEntityName(
            tableAliasesConfigs[pivot.table_name] ?? ''
          ),
        }
      : {
          columnName: '',
          icon: getIconByEntityName(''),
        };
  };

  const hierarchyExpandableColumn = {
    ...COLUMN_DEFAULTS,
    field: 'hierarchy',
    label: firstPivot?.display_name,
    sortable: isExpandableColumnSortable,
    type: 'custom',
    id: 'hierarchy',
    align: 'left',
    config: {
      renderer: (props: Omit<IDataExpandableCellProps, 'onExpandClick'>) =>
        ExpandableHierarchyCell({
          ...props,
          onExpandClick,
          getColumnNameAndIconTable,
        }),

      className: (row) => row.isSubtotal && isSubtotal,
    },
  } as IColumn;

  return {
    visibleRows,
    hierarchyExpandableColumn,
  };
};

export default useExpandableWidgetTable;
