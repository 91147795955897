import styled from '@emotion/styled';

export const ControlsContainer = styled.div(
  ({ isSpaceBetween }: { isSpaceBetween: boolean }) => ({
    justifyContent: isSpaceBetween ? 'space-between' : 'flex-start',
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  })
);

export const FilterBarElement = styled.div({
  '>i': {
    fontSize: '20px',
  },
});

export const PivotContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 8px;
  gap: 8px;

  > .secondary-background {
    margin-right: 8px;
  }

  .bu-dropdown {
    max-width: calc((100vw - 150px) / 6);
    min-width: auto;
  }

  .bu-btn-content {
    div {
      max-width: fit-content;
    }
  }
`;
