import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';

import { USER_ROLES } from 'common/constants';
import { FiltersForAPI } from 'selectors';

const serviceValues = ['__all__', '__none__'];
const specialFilters = ['date', 'datepicker', 'search_bar', 'slider'];

export const updateValues = (
  data: Filters.Checkbox[],
  values: Filters.PersistValue[],
  replace: boolean
): Filters.Checkbox[] => {
  const changedValues = values.reduce<Record<string, boolean>>(
    (res, { id, checked }) => ({ ...res, [id]: checked }),
    {}
  );

  if (changedValues.__none__ !== undefined) {
    const checked = changedValues.__none__ && !changedValues.__all__;

    return data.map((item) => ({
      ...item,
      checked: item.value === '__none__' ? checked : !checked,
    }));
  }

  return data.map((item) => {
    const checked =
      changedValues[item.value] !== undefined
        ? changedValues[item.value]
        : changedValues[item.label];

    return checked !== undefined
      ? { ...item, checked: item.value === '__none__' ? false : checked }
      : replace
      ? { ...item, checked: false }
      : { ...item, checked: item.value === '__none__' ? false : item.checked };
  });
};

const updateCustomValue = (
  type: 'date' | 'datepicker' | 'search_bar' | 'slider',
  data: Filters.Checkbox[],
  values: Filters.PersistValue[]
): Filters.Checkbox[] => {
  if (type === 'search_bar' || type === 'slider') {
    const newData = updateValues(data, values, true);

    const keys = data.map(({ value }) => value);
    const textValue = values.filter(({ id }) => !keys.includes(id))[0];

    if (textValue) {
      newData[0] = {
        ...newData[0],
        checked: textValue.checked,
        value: textValue.id,
      };
    }
    return newData;
  }

  const value = values[0] && values[0].id;
  const isValueExist = data.find((item) => item.value === value);
  const newData = updateValues(data, isValueExist ? [values[0]] : [], true);

  return isValueExist
    ? newData
    : [
        ...newData,
        { checked: true, label: (value || '').replace(',', ' / '), value },
      ];
};

type TotalRes = {
  checkedAll: Filters.Config['checkedAll'];
  checkedTotal: Filters.Config['checkedTotal'];
};

export const calcTotal = (values: Filters.Checkbox[]): TotalRes => {
  const checkedTotal = values.filter(
    (e) => e.checked && !e.group && !serviceValues.includes(e.value)
  ).length;

  const checkedAll =
    checkedTotal ===
    values.filter((e) => !e.group && !serviceValues.includes(e.value)).length;

  return { checkedAll, checkedTotal };
};

const calcTotalRadio = (values: Filters.Checkbox[]): TotalRes => {
  const checkedTotal = values.filter(
    (e) => e.checked && !e.group && !serviceValues.includes(e.value)
  ).length;

  const checkedAll = !!values.find((e) => e.checked && e.value === '__all__');

  return { checkedAll, checkedTotal };
};

const createData = (
  values: Filters.Checkbox[],
  group?: Filters.Config['group'],
  withShowAll?: boolean,
  title = '',
  type: Filters.Config['type'] = 'checkbox',
  searchable?: Filters.Config['searchable']
): Filters.Config => ({
  ...calcTotal(values),
  title,
  type,
  group,
  values,
  withShowAll: withShowAll !== undefined ? withShowAll : values.length > 1,
  searchable,
});

const parseRawOpenedFilter = (
  data: Filters.Default[],
  group: Filters.Config['group'],
  filterTitle: Filters.Config['title'],
  filterType: Filters.Config['type'],
  searchable?: Filters.Config['searchable']
): Filters.Config =>
  createData(
    data.map((item) => ({
      checked: true,
      label: item[1],
      value: item[0],
    })),
    group,
    data.length > 1 && data[0][0] === '__all__',
    filterTitle,
    filterType,
    searchable
  );

export const parseRawDateFilter = (
  data: Filters.DateFilterElement[],
  group: Filters.Config['group'],
  filterTitle: Filters.Config['title'],
  allowFuture: Filters.Config['allowFuture'] | undefined,
  customStart: Filters.Config['customStart'] | undefined,
  customEnd: Filters.Config['customEnd'] | undefined
): Filters.Config => ({
  ...createData(
    data.map((item) => ({
      checked: false,
      label: item.label,
      value: item.value,
      type: item.type,
      is_quarter_field: item.is_quarter_field,
    })),
    group,
    data.length > 1 && data[0].value === '__all__',
    filterTitle,
    'date',
    false
  ),
  allowFuture,
  customStart,
  customEnd,
});

export const parseRawCustomDateFilter = (
  data: [string, string][],
  filterTitle: string,
  group: Filters.Config['group'],
  filterType: Filters.Config['type'],
  extra: Filters.Config['extra']
): Filters.Config => {
  const isCustomRange = extra?.tabs.includes('custom');

  return {
    ...createData(
      data.map((item) => ({
        checked: true,
        label: item[1],
        value: item[0],
      })),
      group,
      data.length > 1 && data[0][0] === '__all__',
      filterTitle,
      filterType,
      false
    ),
    customStart: isCustomRange,
    customEnd: isCustomRange,
    allowFuture: isCustomRange,
  };
};

export const parseRawSearchFilter = (
  data: Filters.Default[],
  filterTitle: Filters.Config['title'],
  group: Filters.Config['group'],
  filterType: Filters.Config['type']
): Filters.Config => ({
  ...createData(
    [['', '__internal_value__'], ...data].map((item, i) => ({
      checked: i === 1,
      label: item[1],
      value: item[0],
    })),
    group,
    false,
    filterTitle,
    filterType,
    false
  ),
});

export const parseRawSliderFilter = (
  data: Filters.Default[],
  filterTitle: Filters.Config['title'],
  group: Filters.Config['group'],
  filterType: Filters.Config['type']
): Filters.Config => {
  const { min = 0, max = 0 } = data.reduce<{ min?: number; max?: number }>(
    (acc, item) => {
      if (['min', 'max'].includes(item[1])) {
        acc[item[1] as 'min' | 'max'] = Number(item[0]);
      }
      return acc;
    },
    {}
  );

  return {
    ...createData(
      [[`[${min}:${max}]`, '__internal_value__'], ...data].map((item, i) => ({
        checked: i === 0,
        label: item[1],
        value: item[0],
      })),
      group,
      data.length > 1 && data[0][0] === '__all__',
      filterTitle,
      filterType,
      false
    ),
  };
};

const parseRawUserRoles = (
  data: Filters.UserRoleFilterElement | null
): Filters.Checkbox[] => {
  const values: Filters.Checkbox[] = [];

  if (!data) {
    return values;
  }

  const parse = (d: Filters.UserRoleFilterElement) => {
    if (d.id && d.name) {
      const checked = !(d.children && d.children.length);

      values.push({
        checked,
        group: !checked || undefined,
        label: d.name,
        value: d.id,
      });
    }

    if (d.children) {
      d.children.forEach(parse);
    }
  };

  parse(data);
  return values;
};

const getCheckedMode = (user?: Filters.PersistUserParams): 'all' | 'current' =>
  user?.role === USER_ROLES.ACCOUNT_EXECUTIVE ? 'current' : 'all';

export const parseRawUsers = (
  options: (Filters.UserFilterElement | Filters.Default)[],
  user?: Filters.PersistUserParams
): Filters.Checkbox[] => {
  const values: Filters.Checkbox[] = [];
  const mode = getCheckedMode(user);

  const parse = (opts: (Filters.UserFilterElement | Filters.Default)[]) =>
    opts.forEach((item) => {
      if (Array.isArray(item)) {
        values.push({
          checked: mode !== 'current',
          label: item[1],
          value: item[0],
          group: undefined,
        });
      } else {
        const { team, email, name } = item;

        values.push({
          checked: mode === 'current' ? !!user && user.email === email : true,
          label: name,
          value: email,
          group: !!(team && team.length) || undefined,
        });

        if (!isNil(team)) {
          parse(team);
        }
      }
    });

  parse(options);
  return values;
};

export const calcNewFilterState = (
  {
    values,
    withReset = false,
    emptyNotAllowed = false,
    ...filterParams
  }: Filters.PersistFiltersParams,
  current: Filters.Config,
  loadingOrCreatingFilter: boolean = false,
  initialData?: Filters.Config
) => {
  const updatedValues: Filters.Checkbox[] = specialFilters.includes(
    current.type
  )
    ? updateCustomValue(current.type as never, current.values || [], values)
    : updateValues(current.values || [], values, withReset);
  const filterIsEmpty = !updatedValues.some((item) => item.checked);

  /**
   * Logic to re-check every option with empty is not allowed to this filter
   */
  const newValues =
    !loadingOrCreatingFilter && emptyNotAllowed && filterIsEmpty
      ? updatedValues.map((item) =>
          item.value !== '__all__' ? { ...item, checked: true } : item
        )
      : updatedValues;

  const { checkedAll, checkedTotal } =
    current.type === 'radio' ? calcTotalRadio(newValues) : calcTotal(newValues);

  const isChanged = initialData?.values
    ? !isEqual(newValues, initialData.values)
    : undefined;
  const hasAllOption = newValues.some((item) => item.value === '__all__');
  const isAllChecked = (filterParams.checkedAll || checkedAll) && hasAllOption;

  return {
    checkedAll: specialFilters.includes(current.type) ? false : isAllChecked,
    checkedTotal,
    isChanged,
    values: isAllChecked
      ? newValues.map((item) =>
          item.value === '__all__' ? { ...item, checked: true } : item
        )
      : newValues,
  };
};

export const getInitialData = (
  filters: Filters.BackendFiltersList,
  user?: Filters.PersistUserParams
) => {
  const customFilters = filters.custom_filters || {};
  const customAccountFilters = filters.custom_account_filters || {};

  const customFiltersKeys = Object.keys(customFilters);
  const customAccountFiltersKeys = Object.keys(customAccountFilters);

  const initialData: Filters.FiltersState = {};
  const usersFilters = ['users', 'users_seller', 'sales_managers'];

  const allFilters = {
    ...customFilters,
    ...filters,
  };

  Object.keys(allFilters)
    .filter(
      (key) =>
        Array.isArray(allFilters[key].fields) && !usersFilters.includes(key)
    )
    .forEach((key) => {
      const item = allFilters[key];

      if (item.type === 'slider') {
        initialData[key] = parseRawSliderFilter(
          item.fields as any,
          item.title,
          customFiltersKeys.includes(key) ? 'custom_filters' : undefined,
          item.type
        );
      }

      if (item.type === 'search_bar') {
        initialData[key] = parseRawSearchFilter(
          item.fields as any,
          item.title,
          customFiltersKeys.includes(key) ? 'custom_filters' : undefined,
          item.type
        );
      }

      if (item.type === 'datepicker') {
        initialData[key] = parseRawCustomDateFilter(
          item.fields as any,
          item.title,
          customFiltersKeys.includes(key) ? 'custom_filters' : undefined,
          item.type,
          item.extra
        );
      }

      if (item.type === 'date') {
        initialData[key] = parseRawDateFilter(
          item.fields,
          customFiltersKeys.includes(key) ? 'custom_filters' : undefined,
          item.title,
          item.allow_future,
          item.custom_start,
          item.custom_end
        );
      }

      if (item.type === 'radio' || item.type === 'checkbox') {
        initialData[key] = parseRawOpenedFilter(
          item.fields as any,
          customFiltersKeys.includes(key) ? 'custom_filters' : undefined,
          item.title,
          item.type,
          item.searchable
        );
      }
    });

  customAccountFiltersKeys
    .filter(
      (key) =>
        Array.isArray(customAccountFilters[key].fields) &&
        !usersFilters.includes(key)
    )
    .forEach((key) => {
      const item = customAccountFilters[key];

      if (item.type === 'slider') {
        initialData[key] = parseRawSliderFilter(
          item.fields as any,
          item.title,
          'custom_account_filters',
          item.type
        );
      }

      if (item.type === 'search_bar') {
        initialData[key] = parseRawSearchFilter(
          item.fields as any,
          item.title,
          'custom_account_filters',
          item.type
        );
      }

      if (item.type === 'date') {
        initialData[key] = parseRawDateFilter(
          item.fields,
          'custom_account_filters',
          item.title,
          item.allow_future,
          item.custom_start,
          item.custom_end
        );
      }

      if (item.type === 'datepicker') {
        initialData[key] = parseRawCustomDateFilter(
          item.fields as any,
          item.title,
          'custom_account_filters',
          item.type,
          item.extra
        );
      }

      if (item.type === 'radio' || item.type === 'checkbox') {
        initialData[key] = parseRawOpenedFilter(
          item.fields,
          'custom_account_filters',
          item.title,
          item.type,
          item.searchable
        );
      }
    });

  const usersData = filters.users.fields;
  const users = parseRawUsers(usersData, user);
  const userRole = parseRawUserRoles(filters.user_role.fields);

  initialData['users'] = {
    isLocked: false,
    searchable: filters.users.searchable,
    title: filters.users.title,
    type: filters.users.type,
    values: users,
    withShowAll: Array.isArray(usersData[0]),
    ...calcTotal(users),
  };

  if (filters.users_seller) {
    const usersSeller = parseRawUsers(filters.users_seller.fields);

    initialData['users_seller'] = {
      isLocked: false,
      searchable: filters.users_seller.searchable,
      title: filters.users_seller.title,
      type: filters.users_seller.type,
      values: usersSeller,
      withShowAll: false,
      ...calcTotal(usersSeller),
    };
  }

  if (filters.sales_managers) {
    const salesManagers = parseRawUsers(filters.sales_managers.fields);

    initialData['sales_managers'] = {
      isLocked: false,
      searchable: filters.sales_managers.searchable,
      title: filters.sales_managers.title,
      type: filters.sales_managers.type,
      values: salesManagers,
      withShowAll: false,
      ...calcTotal(salesManagers),
    };
  }

  initialData['user_role'] = {
    isLocked: false,
    searchable: filters.user_role.searchable,
    title: filters.user_role.title,
    type: filters.user_role.type,
    values: userRole,
    withShowAll: true,
    ...calcTotal(userRole),
  };

  return initialData;
};

const getPreselectedValuesForFilter = (
  filterName: string,
  filtersWithSpecialCases: FiltersForAPI
) => {
  const presetFilterValueForName = filtersWithSpecialCases[filterName] || [];
  const thereIsAlreadyAValueForFilter = presetFilterValueForName.length > 0;

  if (typeof presetFilterValueForName === 'string') {
    return null;
  }

  const mappedValueForDropdown = thereIsAlreadyAValueForFilter
    ? presetFilterValueForName.map((v) => ({
        id: v,
        checked: true,
      }))
    : null;

  if (mappedValueForDropdown === null) {
    return null;
  }

  return {
    name: filterName,
    values: mappedValueForDropdown,
  };
};

export const getInheritedFilterValuesForModal = (
  filtersConfiguredForModal: FiltersForAPI,
  filtersToInheritFrom: FiltersForAPI
) => {
  const changeIntervalFilter = filtersToInheritFrom.time_span
    ? { change_interval: [filtersToInheritFrom.time_span] }
    : {};

  const filtersToInheritFromWithSpecialCases = {
    ...filtersToInheritFrom,
    ...changeIntervalFilter,
  } as FiltersForAPI;

  const { custom_filters, custom_account_filters, ...mainFilters } =
    filtersConfiguredForModal;

  const mainFiltersForModal = Object.keys(mainFilters)
    .map((filterName) =>
      getPreselectedValuesForFilter(
        filterName,
        filtersToInheritFromWithSpecialCases
      )
    )
    .filter((f): f is Filters.FiltersForModal => f !== null);

  const alreadySetCustomFilterValues =
    filtersToInheritFromWithSpecialCases.custom_filters || {};

  const customFiltersForModal = Object.keys(custom_filters || {})
    .map((filterName) =>
      getPreselectedValuesForFilter(filterName, alreadySetCustomFilterValues)
    )
    .filter((f): f is Filters.FiltersForModal => f !== null);

  return [...mainFiltersForModal, ...customFiltersForModal];
};

export const haveFiltersConfigurated = (filterNamesForModal: FiltersForAPI) => {
  const mainFilters = Object.keys(filterNamesForModal || {}).filter(
    (filt) => filt !== 'custom_account_filters' && filt !== 'custom_filters'
  );

  const customFilters = Object.keys(filterNamesForModal?.custom_filters || {});

  return [...mainFilters, ...customFilters].length > 0;
};
