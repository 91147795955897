import classNames from 'classnames';
import React from 'react';
import { timeSpanOptions } from 'sharedLogicMainAppAndRevBi/timeSpanOptions';

import { fontSubtitle, fontDefaultDropdown } from 'assets/css/common';
import * as s from 'components/dashboard/Metrics/common/Calendar/TimeFilter/Tabs/styles';
import { NormalizedData } from 'components/dashboard/Metrics/common/Calendar/TimeFilter/Tabs/types';
import { FiltersConfig } from 'components/dashboard/Metrics/common/Calendar/types';

const dashboardValues = ['L7D', 'L14D', 'L30D', 'LWE', 'TWE'];
const excludedFromDashboard = ['ALT'];
const titlePredefined: Record<string, { title: string; order: number }> = {
  PRESENT: {
    title: 'Present',
    order: 1,
  },
  PAST: {
    title: 'Past',
    order: 2,
  },
  FUTURE: {
    title: 'Future',
    order: 3,
  },
  OTHER: {
    title: 'Other',
    order: 4,
  },
};

const getKeys = (config: FiltersConfig): (Filters.DateElementType | '')[] => {
  const keys: Filters.DateElementType[] = [];

  config.values.forEach((item) => {
    if (item.type && !keys.includes(item.type as any)) {
      keys.push(item.type as any);
    }
  });

  return keys.length ? keys : [''];
};

const getNormalizedData = (
  config: FiltersConfig | undefined,
  isDashboard: boolean,
  showOtherOptions?: boolean
): NormalizedData => {
  if (config) {
    const values = config.fiscalYearValues
      ? config.fiscalYearValues
      : config.values;
    return getKeys(config)
      .map((title) => ({
        title,
        fields: values
          .filter((item) => item.type === (title || undefined))
          .map(({ label, value, is_quarter_field }) => ({
            value,
            text: label,
            boldText: is_quarter_field,
          })),
      }))
      .sort(
        (l, r) =>
          titlePredefined[l.title].order - titlePredefined[r.title].order
      );
  }

  return [
    {
      title: 'PRESENT',
      fields: timeSpanOptions.timeSpanPresent.filter(
        (el) =>
          !(
            (dashboardValues.includes(el.value) && !isDashboard) ||
            (isDashboard && excludedFromDashboard.includes(el.value))
          )
      ),
    },
    {
      title: 'PAST',
      fields: timeSpanOptions.timeSpanPast.filter(
        (el) => !(dashboardValues.includes(el.value) && !isDashboard)
      ),
    },
    {
      title: 'FUTURE',
      fields: isDashboard ? [] : timeSpanOptions.timeSpanFuture,
    },
    {
      title: 'OTHER',
      fields: showOtherOptions ? timeSpanOptions.timeSpanOther : [],
    },
  ];
};

type Props = {
  config?: FiltersConfig;
  value: string[] | string;
  onChange: (value: string) => void;
  onClose: Function;
};

const FiscalYearTab: React.FC<Props> = ({
  config,
  value,
  onChange,
  onClose,
}) => {
  const data = getNormalizedData(config || undefined, false, false).filter(
    (item) => !!item.fields.length
  );

  const isMultiple = data.length > 1;

  return (
    <div
      className={classNames(s.specific_time_periods, {
        [s.specific_time_periods_single]: !isMultiple,
      })}
    >
      {data.map(({ title, fields }) => (
        <div
          key={title}
          className={classNames({
            [s.specific_time_periods_item]: isMultiple,
          })}
        >
          {title && (
            <p className={s.specific_time_period_title}>
              {titlePredefined[title].title}
            </p>
          )}

          {fields.map((el) => (
            <div
              key={el.value}
              onClick={() => {
                onChange(el.value);
                onClose(false);
              }}
              className={classNames(s.specific_time_period_item, {
                selected:
                  value && Array.isArray(value)
                    ? value.includes(el.value)
                    : value === el.value,
                [fontSubtitle]: el.boldText,
                [fontDefaultDropdown]: !el.boldText,
              })}
            >
              {el.text}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default FiscalYearTab;
