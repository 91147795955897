import styled from '@emotion/styled';

const BuDropdownItem = styled.button<{ active?: boolean }>`
  padding: 6px 15px;
  outline: none;
  border: none;
  background-color: transparent;
  text-align: inherit;
  white-space: nowrap;
  height: fit-content;
  font-size: 14px;
  width: 100%;

  ${({ active }) =>
    active &&
    `
    background-color: var(--bu-primary-200);
    font-weight: bold;
  `};

  &.multiselect {
    background-color: transparent;
    font-weight: normal;
  }

  &:hover {
    background-color: var(--bu-primary-200);
  }
`;

export const getDropdownItemProps = <T,>(
  item: T,
  isActive: boolean,
  hide: () => void,
  onChange: (item: T) => void = () => {}
): { active: boolean; onClick(): void } => {
  return {
    active: isActive,
    onClick() {
      hide();
      onChange(item);
    },
  };
};

export default BuDropdownItem;
