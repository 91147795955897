import { css } from 'emotion';

export const styles = {
  table: css`
    border: 1px solid var(--bu-gray-500);
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin-bottom: 20px;
  `,
  table_header: css`
    border-top: 1px solid #dddddd;
    background: #f4f7f8;
    padding: 10px 20px;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #777;
    border-bottom: 1px solid var(--bu-gray-500);
    text-transform: uppercase;
  `,
  attendees: css`
    color: #4a4a4a;
    line-height: 20px;
    font-weight: 800;
    padding-right: 20px;
  `,
  attendee: css`
    line-height: 1.2rem;

    & + & {
      margin-top: 0.5rem;
    }
  `,
  engagements: css`
    display: flex;
    align-items: center;
    color: #4a4a4a;
    margin-bottom: 2px;

    i {
      font-size: 18px;
      margin-right: 4px;
    }
  `,
  subject: css`
    color: #4a4a4a;
    line-height: 20px;
    font-weight: 800;
    padding-right: 20px;
    cursor: pointer;

    :hover {
      color: var(--bu-primary-500);
    }
  `,
  email_subject: css`
    font-size: 24px;
  `,
  no_show: css`
    display: block;
    color: #f74544;
  `,
  not_engaged: css`
    color: #4a4a4a;
    font-weight: 800;
  `,
  last_engaged_green: css`
    color: var(--bu-green-500);
    font-weight: 600;
  `,
  last_engaged_orange: css`
    color: var(--bu-orange-500);
    font-weight: 600;
  `,
  last_engaged_red: css`
    color: var(--bu-red-400);
    font-weight: 600;
  `,
  line_svg: css`
    height: 5px;
    margin-left: 5px;
    margin-top: -2px;
  `,
  position: css`
    line-height: 20px;
    color: #4a4a4a;

    &.red {
      color: var(--bu-red-400);
    }

    &.green {
      color: var(--bu-green-500);
    }
  `,
  graphContainer: css`
    display: flex;
    justify-content: space-around;
  `,
  graph: css`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  `,
  blue_link_more: css`
    color: var(--bu-primary-700);
    cursor: pointer;
    text-decoration: underline;
  `,
  sentiment_title: css`
    text-transform: uppercase;
    color: var(--bu-gray-900);
  `,
  sentiment_text: css`
    margin: 5px 0;
    word-break: break-word;
  `,
  sentiment_color_neutral: css`
    color: var(--bu-primary-500);
    font-weight: 600;
  `,
  sentiment_color_positive: css`
    color: var(--bu-green-500);
    font-weight: 600;
  `,
  sentiment_color_negative: css`
    color: var(--bu-red-400);
    font-weight: 600;
  `,
  errorMessage: css`
    text-align: center;
    color: var(--bu-red-400);
  `,
  filters_container: css`
    display: flex;
    margin-bottom: 10px;
  `,
  buttons_box_content: css`
    display: flex;
    justify-content: flex-end;
    width: 100%;
  `,
  section_header: css`
    color: var(--bu-gray-900);
    display: flex;
    align-items: center;
    margin-top: 10px;
  `,
  btn_tab: css`
    display: inline-block;
    padding: 5px 10px;
    height: 30px;
    margin-right: 15px;
    align-items: center;
    cursor: pointer;
    font-weight: 300;
    color: #777777;
    border-radius: 2px;
    background-color: #f4f7f8;
    outline: 0;
    border: none;
    transition: 0.4s;
    width: 100%;
    max-width: 220px;
    min-width: 150px;

    &.active {
      color: var(--bu-white);
      background-color: #4a4a4a;
    }

    &:hover {
      color: var(--bu-white);
      background-color: #4a4a4a;
    }
  `,
  table_wrapper: css`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid var(--bu-gray-500);
    position: relative;
    background: #fff;
    margin-bottom: 20px;
  `,
  table_engagement: css`
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    position: relative;
    color: var(--bu-gray-900);
  `,
  table_sticky_header: css`
    border-collapse: separate !important;

    & thead tr:first-child > th {
      position: sticky !important;
      top: 0;
      background-color: var(--bu--table-header);
      border-right: 1px solid var(--bu-table-header-border-color);
      font-family: var(--bu-font-medium);
      font-weight: 500;
      color: var(--bu-gray-900);
      line-height: 1.3em;

      &:last-child {
        border-right: none;
      }
    }
  `,
  table_engagement_header: css`
    z-index: 1;
    position: relative;
    th {
      border-bottom: 1px solid var(--bu-table-header-border-color);
    }
  `,
  table_engagement_header_column: css`
    padding: 0 10px;
    color: var(--bu-gray-700);
    position: relative;
    width: 10%;

    .bu-icon {
      display: none;
    }

    &.sortable {
      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        > span {
          flex: 1;
        }
      }

      &:hover > div > .bu-icon {
        display: inline-block;
      }

      &.sorting {
        background-color: var(--bu--table-header-sorting) !important;
      }

      &.sorting > div > {
        span {
          color: var(--bu-gray-900);
        }

        .bu-icon {
          display: inline-block;
          color: var(--bu-primary-500);
        }
      }
    }
  `,
  body_table: css`
    td {
      border-bottom: 1px solid var(--bu-table-border-color);
      border-right: 1px solid var(--bu-table-border-color);

      &:last-child {
        border-right: none;
      }
    }

    &:hover {
      background: var(--bu-gray-200);
    }
  `,
  table_body_column: css`
    padding: 5px 10px;
    line-height: 22px;
  `,
  table_body_btn: css`
    text-align: center;
    padding: 10px;
  `,
  nothing_found: css`
    padding: 30px;
    color: var(--bu-gray-700);
    text-align: center;
  `,
  icon: css`
    height: 18px;
    width: auto;
    margin-left: 10px;
  `,
};

export default styles;
