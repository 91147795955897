import React from 'react';

import { USER_ROLES } from 'common/constants';
import { NavPanelLinkItem } from 'components/UI/NavPanel/types';

export type IStateProps = {
  crmType: string;
  isAdminEnabled: boolean;
  isSalesOps: boolean;
  isSalesAdmin: boolean;
  isSelfServeEnabled: boolean;
  isDeveloper: boolean;
  isCSM: boolean;
  isMobileLoginAllowed: boolean;
  isThirdPartyIntegrationEnabled: boolean;
};

export type IProps = IStateProps;

export interface NavPanelComponentItem extends NavPanelLinkItem {
  component: React.ComponentType<any>;
}

export const USER_MANAGEMENT_ROLES = [USER_ROLES.SALES_OPERATIONS];
export const MY_TEAM_ROLES = [
  USER_ROLES.CLEVEL,
  USER_ROLES.SALES_MANAGER,
  USER_ROLES.SALES_OPERATIONS,
];
export const CALLS_STAT_ROLES = [
  USER_ROLES.CLEVEL,
  USER_ROLES.SALES_MANAGER,
  USER_ROLES.SALES_OPERATIONS,
];
export const VOICE_RECORDS_ROLES = [
  USER_ROLES.CLEVEL,
  USER_ROLES.SALES_MANAGER,
  USER_ROLES.SALES_OPERATIONS,
];
export const COMPETITORS_TOPICS_ROLES = [
  USER_ROLES.CLEVEL,
  USER_ROLES.SALES_MANAGER,
  USER_ROLES.SALES_OPERATIONS,
];
export const CRM_CONFIG_ROLES = [
  USER_ROLES.CLEVEL,
  USER_ROLES.SALES_MANAGER,
  USER_ROLES.SALES_OPERATIONS,
];
export const SALES_ADMIN_ROLES = [
  USER_ROLES.CLEVEL,
  USER_ROLES.SALES_MANAGER,
  USER_ROLES.SALES_OPERATIONS,
];
