import { css } from 'emotion';

export const paginationWrapper = css`
  display: flex;
  margin: 10px 0;
`;

export const paginationTitle = css`
  flex-grow: 1;
  color: var(--bu-gray-900);
  font-family: var(--bu-font-medium);
  text-transform: uppercase;
  font-size: 14px;
  align-self: center;

  span {
    color: var(--bu-gray-700);
  }
`;

export const loader = css`
  label: loader;
  margin: 50px;
  display: flex;
  justify-content: center;
`;

export const table = css`
  height: auto;
  background: #fff;
`;

export const table_header = css`
  display: grid;
  grid-template-columns: 1.2fr 1.3fr 1fr 1fr 1fr;
  text-align: center;
  border-bottom: 1px solid var(--bu-gray-500);
  text-transform: uppercase;
  background-color: var(--bu-gray-200);
  border: 1px solid var(--bu-gray-500);
  border-radius: 4px 4px 0 0;
  border-bottom: none;
`;

export const table_header_embedded = css`
  grid-template-columns: 1.2fr 1.3fr 1fr 1fr 1fr 1fr 1fr;
`;

export const header_column = css`
  padding: 9px 0 9px 10px;
  color: var(--bu-gray-700);
  font-family: var(--bu-font-regular);
  font-size: 12px;
  font-weight: bold;
  line-height: 17px;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;

  &:first-child {
    padding-left: 15px;
    border-right: 1px solid var(--bu-gray-300);
  }

  &:last-child {
    border-right: none;
  }

  &.align {
    text-align: center;
  }
`;

export const rows = css`
  display: grid;
  grid-template-columns: 1.2fr 1.3fr 1fr 1fr 1fr;
  border-bottom: 1px solid var(--bu-gray-500);

  &:last-child {
    border-bottom: none;
  }
`;

export const rows_embedded = css`
  grid-template-columns: 1.2fr 1.3fr 1fr 1fr 1fr 1fr 1fr !important;
`;

export const body_column = css`
  display: flex;
  flex-direction: column;
  padding: 9px 0 9px 10px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;

  &:first-child {
    padding-left: 15px;
    border-right: 1px solid var(--bu-gray-300);
  }
`;

export const table_body = css`
  border: 1px solid var(--bu-gray-500);
  border-radius: 0 0 4px 4px;
`;

export const name_field_name = css`
  color: var(--bu-clickable-cell);
  font-family: var(--bu-font-regular);
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  &:hover {
    color: var(--bu-clickable-cell);
  }
`;

export const name_field_date = css`
  height: 17px;
  color: #777777;
  font-family: var(--bu-font-regular);
  font-size: 12px;
  line-height: 23px;
  font-weight: 300;
  white-space: normal;
`;

export const deal_name = css`
  color: var(--bu-clickable-cell);
  font-family: var(--bu-font-regular);
  font-size: 14px;
  line-height: 19px;
  font-weight: 300;
`;

export const callStatus = css`
  font-family: var(--bu-font-regular);
  font-size: 14px;
  color: #666;
`;

export const callButton = css`
  label: call-button;
  border-radius: 20px;
  background-color: var(--bu-gray-300);
  padding: 0px 0px 0px 10px;
  border: 1px solid var(--bu-gray-500);
  color: var(--bu-gray-900);
  font-size: 14px;
  font-family: var(--bu-font-regular);
  cursor: pointer;

  img {
    width: 30px;
    height: 30px;
    margin-left: 5px;
  }

  &:hover {
    outline: none;
  }
`;

export const dealName = css`
  label: deal-name;
  color: var(--bu-clickable-cell);
  font-size: 14px;
  transition: 0.4s;
`;
