import { GetTrendsPayload } from 'api/Trends';
import { css } from 'emotion';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';

import { setForecastOppSplitView } from 'actions/forecastActions';
import { ViewBy } from 'common/enums/filters';
import ActiveUsersToggle from 'components/UI/ActiveUsersToggle';
import BusinessTypesPanel from 'components/UI/BusinessTypesPanel';
import OpenFiltersPanel from 'components/UI/OpenFiltersPanel';
import { useHeader } from 'components/UI/Wrapper/Header/header.context';
import TrendsWaterfall from 'components/charts/TrendsWaterfall/TrendsWaterfall';
import { useGetTrends } from 'components/charts/TrendsWaterfall/TrendsWaterfall.helper';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import { IProps } from 'components/dashboard/ForecastAnalytics/types';
import * as selectors from 'selectors';

const TAB_KEY = 'forecast_analytics_change';

const stickyTop = css`
  position: sticky;
  background: var(--bu-white);
  z-index: 989;
`;

const btActiveUsers = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bu-white);
  border-bottom: 1px solid var(--bu-gray-400);
  margin: 0 -20px 0px -20px;
  padding: 0 20px 0px 20px;
`;

const contentWrapper = css`
  margin-top: 20px;
  margin-bottom: 100px;
  background-color: var(--bu-white);
`;

const containerWrapper = css`
  height: 100%;
  background-color: var(--bu-primary-100);
`;

const ForecastAnalytics: React.FC<IProps> = ({
  selectedBusinessType,
  filters: panelFilters,
  toggleAllFilters,
  isBusinessTypeEnabled,
  tabPartition = 'forecast',
  isRequiredDataReady,
}) => {
  const { setPartition, clearContext } = useHeader();

  const dispatch = useDispatch();
  const oppSplitViewFlag = useSelector(selectors.getForecastOppSplitViewFlag);
  const isSplitsOnWaterfallEnabled = useSelector(
    selectors.isSplitsOnWaterfallEnabled
  );
  const isOppSplitEnabled = useSelector(selectors.isOppSplitEnabled);

  const includeDisabledUsersDefault = useSelector(
    selectors.getIncludeDisabledUsersByDefaut
  );
  const [includeDisabledUsers, setIncludeDisabledUsers] = useState(
    includeDisabledUsersDefault
  );

  useEffect(() => {
    setPartition(tabPartition);

    dispatch(setForecastOppSplitView(false));
    AnalyticsTracker.event(
      { filters: panelFilters },
      {
        action: 'Open',
        category: 'Forecast',
        label: 'Trends page',
      }
    );

    return () => {
      clearContext();
    };
  }, []);
  const [changeInterval, setChangeInterval] = useState('L7D');
  const filtersForWaterfall = {
    ...panelFilters,
    forecast_category_names: panelFilters.forecast_category_names?.length
      ? panelFilters.forecast_category_names
      : undefined,
    business_type_name: selectedBusinessType,
    split_view: isBusinessTypeEnabled ? oppSplitViewFlag : undefined,
    include_disabled: includeDisabledUsers,
    change_interval: changeInterval,
    time_span: changeInterval,
  } as GetTrendsPayload;

  const { data, isLoading } = useGetTrends(filtersForWaterfall);
  const trends = data ?? [];

  if (isEmpty(panelFilters) || !isRequiredDataReady) {
    return <Loader active />;
  }

  const splitToggleEnabled =
    isBusinessTypeEnabled && isOppSplitEnabled && isSplitsOnWaterfallEnabled;

  return (
    <div className={containerWrapper} onClick={() => toggleAllFilters(true)}>
      <div className="container">
        <div className="container-dashboard">
          <div className={stickyTop}>
            <OpenFiltersPanel tab={TAB_KEY} />
          </div>

          <div className={btActiveUsers}>
            {isBusinessTypeEnabled && <BusinessTypesPanel tab={TAB_KEY} />}
            <ActiveUsersToggle
              checked={!includeDisabledUsers}
              onChange={setIncludeDisabledUsers}
            />
          </div>

          <div className={contentWrapper}>
            <Dimmer.Dimmable>
              <TrendsWaterfall
                trends={trends}
                filters={filtersForWaterfall}
                splitToggleEnabled={splitToggleEnabled}
                onIntervalChange={setChangeInterval}
              />
              <Dimmer inverted active={isLoading}>
                <Loader content="Loading" />
              </Dimmer>
            </Dimmer.Dimmable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForecastAnalytics;
