import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import {
  BIMetricSimple,
  BIMetricUnion,
  DataDescriptor,
} from 'components/dashboard/Metrics/metrics.types';
import { useMetricRelatedDateColumns } from 'components/dashboard/Metrics/Widget/hooks/useMetricRelatedDateColumns';
import BuSelect from 'components/UI/BuSelect';
import { css } from 'emotion';
import { useContext, useMemo } from 'react';

const linkedDateFieldWrapper = css`
  display: flex;
  flex-direction: column;
  gap: 4px;

  label,
  p {
    margin-left: 4px;
  }
`;

const infoParagraph = css`
  font-size: 12px;
  color: var(--bu-gray-600);
`;

export const MetricDateField = ({
  metric,
  dateField,
  onDateFieldChange,
}: {
  metric: BIMetricUnion;
  dateField?: DataDescriptor | null;
  onDateFieldChange: ({
    field,
    complete,
  }: {
    field: DataDescriptor | undefined | null;
    complete: boolean;
  }) => void;
}) => {
  const { defaultDateColumnPerTable } = useContext(RevBISettingsContext);

  const { isLoading, isError, options, pivotColumn } =
    useMetricRelatedDateColumns(metric);

  const disabled = isLoading || isError || !options.length;
  const hidden = !isLoading && !options.length;

  const selectedDateField = useMemo(() => {
    const simpleMetric = metric as BIMetricSimple;

    if (isLoading) {
      return '';
    }

    const columnName =
      defaultDateColumnPerTable[simpleMetric.object]?.name ?? '';

    const pivotColumnData: DataDescriptor | undefined = pivotColumn.find(
      (option) => option.name === columnName
    );

    if (simpleMetric.date_field?.name) {
      return simpleMetric.date_field.name;
    }

    if (pivotColumnData) {
      onDateFieldChange({ field: pivotColumnData, complete: true });
      return pivotColumnData.name;
    }

    return '';
  }, [metric, dateField, defaultDateColumnPerTable, pivotColumn, isLoading]);

  const handleDateFieldChange = (values: string[]) => {
    const pivotColumnData = pivotColumn.find(
      (option) => option.name === values[0]
    );
    pivotColumnData &&
      onDateFieldChange({ field: pivotColumnData, complete: true });
  };

  if (hidden && dateField?.name) {
    onDateFieldChange({ field: null, complete: true });
  }

  return hidden ? null : (
    <div className={linkedDateFieldWrapper}>
      <label>Linked Date Field</label>
      <BuSelect
        placeholder={'Choose a Date Field'}
        searchable
        disabled={disabled}
        fullWidth
        isLargePlaceholder
        secondary
        defaults={[selectedDateField]}
        options={options}
        testingLabel="date-field"
        onChange={handleDateFieldChange}
      />
      <p className={infoParagraph}>
        Select a date field to be used for grouping and displaying data through
        time period and interval based widgets
      </p>
    </div>
  );
};
