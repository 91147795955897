import * as R from 'ramda';
import { select, put, take, all, call, fork, cancel } from 'redux-saga/effects';

import * as t from 'actions/actionTypes';
import { getCallsStats } from 'actions/callsStatsActions';
import { TIME_PERIODS, CALLS_STATS_CHART_IDS } from 'common/constants';
import { getCallsStatsLocalFilter, getCallsStatsGlobalFilter } from 'selectors';

const groupingMap = {
  [TIME_PERIODS.LAST_WEEK]: 'day',
  [TIME_PERIODS.THIS_WEEK]: 'day',
  [TIME_PERIODS.NEXT_WEEK]: 'day',
  [TIME_PERIODS.LAST_MONTH]: 'day',
  [TIME_PERIODS.THIS_MONTH]: 'day',
  [TIME_PERIODS.NEXT_MONTH]: 'day',
  [TIME_PERIODS.LAST_3_MONTHS]: 'week',
  [TIME_PERIODS.LAST_6_MONTHS]: 'week',
  [TIME_PERIODS.LAST_9_MONTHS]: 'week',
  [TIME_PERIODS.LAST_12_MONTHS]: 'week',
};

function* fetchCallsStats(graphId) {
  // put together all the data necessary to make a call to the API to fetch the data for
  // a specific chart
  const data = {};

  // type to call (either events or calls)
  const { type } = yield select(getCallsStatsLocalFilter, graphId);

  // global filters data
  const { period, users } = yield select(getCallsStatsGlobalFilter);

  // group by
  data['group_by'] = groupingMap[period];

  // periods
  if (period.includes('next')) {
    data['upcoming_dates'] = [period];
  } else {
    data['completed_dates'] = [period];
  }

  // users
  if (!R.isEmpty(users)) {
    data['users'] = users;
  }

  // fetch the chart data
  yield put(getCallsStats(graphId, type, data));
}

function* watchFilterChanges() {
  while (true) {
    // do an initial fetch and wait for further filter changes
    yield all(
      R.compose(
        R.map(R.curryN(2, call)(fetchCallsStats)),
        R.values
      )(CALLS_STATS_CHART_IDS)
    );

    yield take(t.CALLS_STATS + t.CHANGE + t.GLOBAL + t.FILTER);
  }
}

function* callsStatsSaga() {
  while (true) {
    // when entering the call stats section, start watching for filter changes
    yield take(t.CALLS_STATS + t.ENTER);
    const watch = yield fork(watchFilterChanges);

    // when exiting, stop listening
    yield take(t.CALLS_STATS + t.EXIT);
    cancel(watch);
  }
}

export default callsStatsSaga;
