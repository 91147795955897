import styled from '@emotion/styled';

export const FiltersContainer = styled.div(
  ({ showControls = false }: { showControls: boolean }) => ({
    backgroundColor: 'var(--bu-gray-200)',
    display: showControls ? 'flex' : 'none',
    padding: '8px 16px',
    alignItems: 'center',
  })
);

export const FilterBarELement = styled.div({
  padding: '4px 8px 4px 0',
  '>i': {
    fontSize: '20px',
  },
});

export const FiltersDropdownsContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
});
