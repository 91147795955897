import { css } from 'emotion';

import { fontDefault } from 'assets/css/common';

export const container = css`
  width: 100%;
`;

export const not_found = css`
  text-align: center;
  font-size: 20px;
`;

export const attendeeDeclined = css`
  label: attendee-declined;
  color: #ee724e;
`;

export const email_empty_modal = css`
  text-align: center;
  font-size: 20px;
  padding: 30px;
`;
export const align_left = css`
  text-align: left;
`;
export const box_email = css`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;
  text-align: left;
`;
export const box_email_line = css`
  display: grid;
  grid-template-columns: 1.5fr 10fr;
`;
export const box_email_icon = css`
  display: flex;
  justify-content: flex-end;
  font-family: var(--bu-font-regular);
  font-size: 14px;
  color: var(--bu-gray-700);
  padding-right: 13px;
  padding-top: 2px;

  img {
    width: 30px;
    height: 30px;
  }
`;
export const email_body_box = css`
  font-size: 16px;
  color: #4a4a4a;
  max-width: 600px;
  line-height: 24px;
`;
export const margin_bottom = css`
  margin-bottom: 20px;
`;
export const subject_name = css`
  margin-bottom: 8px;
  margin-top: 9px;
  font-size: var(--bu-font-size-big);
  color: var(--bu-gray-900);
`;
export const date = css`
  color: var(--bu-gray-700);
  ${fontDefault};
`;
export const text = css`
  color: var(--bu-gray-900);
  ${fontDefault};
  text-align: left;
  line-height: 20px;
`;
export const link_btn = css`
  padding: 7px 14px;
  color: #0762ec;
  font-size: 14px;
  font-family: var(--bu-font-medium);
  border: 1px solid #0762ec;
  border-radius: 2px;
  cursor: pointer;
  img {
    width: 25px;
    height: 18px;
    margin-right: 10px;
  }
`;

export const closeButton = css`
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

export const modal_box = css`
  max-width: 720px;
  width: 100%;
  height: 450px;
  overflow: hidden;
  background: #fff;
`;

export const modal_box_header = css`
  width: 100%;
  height: 50px;
  font-size: 18px;
  color: rgb(74, 74, 74);
  font-family: var(--bu-font-regular);
  background: rgb(244, 247, 248);
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
`;

export const modal_box_body = css`
  padding: 20px 47px;
  overflow-y: hidden;
  height: 400px;
  box-sizing: border-box;
  &:hover {
    overflow-y: auto;
  }
`;

export const circle = css`
  width: 32px;
  height: 32px;
  display: inline-block;
  position: relative;
  border-radius: 50%;

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: var(--bu-white);
  }

  &.blue {
    background: var(--bu-primary-500);
  }

  &.orange {
    background: var(--bu-orange-300);
  }
`;

export const left_icons = css`
  color: var(--bu-gray-900);
  font-size: 24px;
`;
