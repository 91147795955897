import * as t from 'actions/actionTypes';

export const activityRemapRequest = (body) => ({
  type: t.ACTIVITY + t.REMAP + t.REQUEST,
  body,
});

export const activityRemapSuccess = () => ({
  type: t.ACTIVITY + t.REMAP + t.SUCCESS,
});

export const activityRemapFailure = () => ({
  type: t.ACTIVITY + t.REMAP + t.ERROR,
});
