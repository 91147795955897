import classNames from 'classnames';
import * as R from 'ramda';
import React, { Fragment } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import EngagementRow from 'components/UI/EngagementTable/Row';
import {
  Contact,
  IProps,
} from 'components/UI/EngagementTable/TeamEngagement/types';
import styles from 'components/UI/EngagementTable/styles';
import ShowMoreButton from 'components/UI/common/Buttons/ShowMoreButton';
import { tableSizeStyle } from 'components/UI/common/TypedTable/styles';

const Column: React.FC<{
  columnName?: string;
  onClick?: Function;
  sortField?: string;
  width?: string;
}> = ({ columnName, children, onClick = () => {}, sortField, width }) => (
  <th
    className={classNames(styles.table_engagement_header_column, {
      sortable: !!columnName,
      sorting: sortField === columnName || sortField === `-${columnName}`,
    })}
    style={{ width }}
  >
    <div onClick={() => onClick(columnName || '')}>
      <span className="table-header-label">{children}</span>
      <BuIcon
        name={
          sortField === columnName
            ? BoostUpIcons.SortingAscending
            : BoostUpIcons.SortingDescending
        }
      />
    </div>
  </th>
);

const TeamEngagement: React.FC<IProps> = ({
  contacts,
  total,
  status,
  sortingStatus,
  // paginationStatus,
  sortField,
  // pageNumber,
  onSortingChange,
  onLoadMore,
}: IProps) => {
  if (!contacts) return null;
  const customerContacts = contacts;
  const maxEmails = Math.max(
    ...customerContacts.map((contact: Contact) =>
      R.pathOr(
        0,
        ['touchpoint_stats', 'email', 'num_touchpoints_engaged'],
        contact
      )
    )
  );
  const maxEvents = Math.max(
    ...customerContacts.map((contact: Contact) =>
      R.pathOr(
        0,
        ['touchpoint_stats', 'event', 'num_touchpoints_engaged'],
        contact
      )
    )
  );
  const hasMore = contacts.length < total;
  // const nextPage = paginationStatus === 'error' ? pageNumber : pageNumber + 1;

  return (
    <div className={styles.table_wrapper}>
      <table
        className={classNames(
          styles.table_engagement,
          styles.table_sticky_header,
          tableSizeStyle,
          'table-compact'
        )}
      >
        <thead className={styles.table_engagement_header}>
          <tr>
            <Column
              columnName="name"
              onClick={onSortingChange}
              sortField={sortField}
            >
              Name
            </Column>
            <Column
              columnName="last_engaged_at"
              onClick={onSortingChange}
              sortField={sortField}
            >
              Last Engaged
            </Column>
            <Column width="40%">Last Email / Last Sentiment</Column>
            <Column
              columnName="engagement_count"
              onClick={onSortingChange}
              sortField={sortField}
              width="5%"
            >
              # of engagements
            </Column>
          </tr>
        </thead>

        {R.isEmpty(customerContacts) ? (
          <tbody>
            <tr>
              <td className={styles.nothing_found} colSpan={4}>
                Nothing found
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {customerContacts.map((contact: Contact, index: number) => (
              <EngagementRow
                row={contact}
                key={index}
                maxEvents={maxEvents}
                maxEmails={maxEmails}
              />
            ))}
          </tbody>
        )}

        <Fragment>
          {status === 'error' ? (
            <div className={styles.errorMessage}> Error loading page </div>
          ) : null}

          {hasMore && (
            <tbody>
              <tr>
                <td colSpan={4} className={styles.table_body_btn}>
                  <ShowMoreButton
                    count={total}
                    showing={contacts.length}
                    loading={status === 'loading'}
                    onClick={() => onLoadMore()}
                  />
                </td>
              </tr>
            </tbody>
          )}
        </Fragment>
      </table>
    </div>
  );
};

export default TeamEngagement;
