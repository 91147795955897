import { IProps } from './types';
import { css } from 'emotion';
import React, { useState } from 'react';

const linkStyle = css`
  color: #0762ec;
  padding: 3px 0;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
`;

const ExpandibleItems = (props: IProps) => {
  const [expanded, setExpanded] = useState(false);
  const slice = props.items.slice(0, props.max);
  const missingItems = props.items.length - slice.length;

  return (
    <div>
      {expanded ? props.items : slice}
      {!expanded && missingItems > 0 ? (
        <div className={linkStyle} onClick={() => setExpanded(true)}>
          {' '}
          + {missingItems}{' '}
        </div>
      ) : null}
    </div>
  );
};

export default ExpandibleItems;
