import BuSkeleton from '.';
import * as styles from './styles';
import { IBuSkeletonTableTop } from './types';
import classNames from 'classnames';
import React, { FC } from 'react';

const BuSkeletonTableTop: FC<IBuSkeletonTableTop> = ({ hasPadding }) => {
  return (
    <div
      className={classNames(styles.upTable, {
        [styles.upTablePadding]: hasPadding,
      })}
    >
      <BuSkeleton height={35} width={180} />

      <div className={styles.upTableGrid}>
        <div className={styles.upTableGridItems}>
          {new Array(4).fill(null).map((_item, index) => (
            <span key={index} />
          ))}
        </div>
        <BuSkeleton height={35} width={120} />
      </div>
    </div>
  );
};

export default BuSkeletonTableTop;
