import moment from 'moment';
import { range } from 'ramda';

export const HOURS: string[] = range(0, 24).map((hour) =>
  moment({ hour }).format('h A')
);

export const MINUTES: string[] = range(0, 60).map((minute) =>
  moment({ minute }).format('mm')
);

export const defaultTimeFormats = {
  inputFormat: 'HH:mm',
  displayFormat: 'hh:mm A',
};
