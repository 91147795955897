import axiosInstance from './axiosInstance';

import { ISelectOption } from 'components/UI/BuSelect/types';

export interface SubValue {
  display_name: string;
  field_name: string;
  type: string;
  synthetic_metric?: string;
  unit?: string;
}

export interface SubValuesData {
  subValueList: SubValue[];
  subValueOptions: ISelectOption[];
}

export const fetchSubValueList = async (): Promise<SubValuesData> => {
  const res = await axiosInstance.get<SubValue[]>('/rev_bi/subvalue_metrics');

  const optionList: ISelectOption[] = res.data.map((subValue: SubValue) => ({
    key: subValue.field_name,
    text: subValue.display_name,
    value: subValue.field_name,
  }));

  return {
    subValueList: res.data,
    subValueOptions: optionList,
  };
};
