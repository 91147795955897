import styled from '@emotion/styled';
import { css } from 'emotion';

import { fontSubtitle } from 'assets/css/common';

export const MainValueWrapper = styled.p`
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
`;

export const MainValue = styled.span`
  align-items: center;
  color: var(--bu-gray-900);
  display: flex;
  letter-spacing: 0;
  line-height: 20px;
`;

export const FormulaTitle = styled.p`
  color: var(--bu-gray-700);
  font-weight: normal;
  margin-bottom: 0;
  white-space: nowrap;
`;

export const VariableValuesTable = styled.div`
  border: 1px solid var(--bu-gray-400);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-top: 14px;

  .values-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 4px;
    > div {
      margin: 0;
    }
  }
`;

export const TableBody = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  .table-header {
    font-size: 14px;
    background: var(--bu-gray-200);
    color: var(--bu-gray-700);

    :nth-of-type(1) {
      border-top-left-radius: 4px;
    }

    :nth-of-type(2) {
      border-top-right-radius: 4px;
    }
  }

  > div {
    padding: 10px;
    border-bottom: 1px solid var(--bu-gray-400);

    :nth-of-type(odd) {
      border-right: 1px solid var(--bu-gray-400);
    }

    :nth-of-type(even) {
      text-align: right;
    }

    :nth-last-of-type(1),
    :nth-last-of-type(2) {
      border-bottom: none;
    }
  }
`;

export const container = css`
  margin: -15px -15px -10px;
  width: 600px;
  overflow: hidden;
`;

export const myOrgTooltipContainer = css`
  margin: -15px -15px -10px;

  .ui.menu {
    margin: 0;
  }

  .ui.secondary.pointing.menu .item {
    &:hover {
      color: var(--bu-color-buying_team_email);
    }

    &.active {
      border-color: var(--bu-color-buying_team_email) !important;
      color: var(--bu-color-buying_team_email);
    }

    &:focus {
      outline: none;
    }
  }
`;

export const tabContainer = css`
  width: 530px;
  overflow: hidden;
`;

export const row = css`
  display: flex;
  flex-direction: row;
`;

export const header = css`
  width: 100%;
  padding: 13px 20px 8px;
  border-bottom: 1px solid var(--bu-gray-500);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    ${fontSubtitle};
    font-size: 14px;
  }

  .details {
    ${fontSubtitle};
    font-size: 14px;
    color: var(--bu-gray-700);
    display: flex;
    height: 20px;
    line-height: 20px;
    align-items: center;

    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 15px;
      margin-left: 5px;
    }
  }
`;

export const column = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const body = css`
  flex: 1;
  max-height: 400px;
  overflow-y: auto;
  padding: 20px;
`;

export const myOrgCustomBody = css`
  ${body}
  height: 400px;
`;

export const badgeWrapper = css`
  margin-left: 4px;

  div.amount-badge {
    margin-top: 0;
  }
`;

export const SerieRowWrapper = styled.div(
  (prop: {
    borderColor?: string;
    backgroundColor?: string;
    cursor?: string;
  }) => ({
    marginBottom: '5px',
    borderRadius: '6px',
    cursor: prop.cursor ?? 'pointer',
    ...(prop.backgroundColor || prop.borderColor
      ? {
          backgroundColor: `${prop.backgroundColor}`,
          borderColor: `${prop.borderColor} !important`,
          border: '1px solid',
          borderLeft: '6px solid',
          padding: '10px',
        }
      : {
          padding: '5px 0',
        }),
    fontSize: '14px',
    '.deals-total-amount': {
      color: 'var(--bu-primary-500)',
    },
  })
);

export const loaderStyle = css`
  height: 50px;
  margin-bottom: 15px;
  margin-top: 15px;
`;

export const viewWaterfallLink = css`
  color: var(--bu-primary-500);
  cursor: pointer;
  font-size: 14px;
`;

export const waterfallViewWrapper = css`
  border-top: 2px solid rgba(34, 36, 38, 0.15);
  padding: 10px 20px;
  ${fontSubtitle}
`;

export default {
  container,
  tabContainer,
  myOrgTooltipContainer,
  loaderStyle,
  row,
  header,
  column,
  body,
  myOrgCustomBody,
  badgeWrapper,
  viewWaterfallLink,
  waterfallViewWrapper,
};
