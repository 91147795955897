import React, { FC } from 'react';

import TypedRow, {
  ITypedRowProps,
} from 'components/UI/common/TypedTable/TypedRow';
import TypedRowDraggable from 'components/UI/common/TypedTable/TypedRowDraggable';

const TypedRowWrapper: FC<ITypedRowProps> = (props) => {
  const { enableReorderingRows, canDragRow, row, rows } = props;

  const isRowDraggable = enableReorderingRows && canDragRow(row, rows);

  if (isRowDraggable) {
    return <TypedRowDraggable {...props} />;
  }

  return <TypedRow {...props} />;
};

export default TypedRowWrapper;
