import styled from '@emotion/styled';
import { css } from 'emotion';

export const modalHeader = css`
  padding-bottom: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
`;

export const Title = styled.h3`
  display: inline-block;
  padding-left: 21px;
`;

export const AutoIncludeInfoIcon = styled.div`
  display: inline-block;
  margin-left: 8px;

  img {
    margin-top: -3px;
  }
`;

export const modalContent = css`
  padding: 0 !important;
  // Default modal size is 80vh but this is only the content
  height: 70vh;
  overflow: auto;
`;

export const selectDealsTableWrapper = css`
  height: 100%;
  /**
   * Workaround to apply the scroll correctly, table-controls menu have a margin
   * that is oversizing the wrapper. TableControls.tsx:26 (resetMarginTop)
   */
  margin-top: -10px;
  padding-top: 10px;
`;
