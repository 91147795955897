import { useStateWhile } from 'common/helpers';
import { useEffect } from 'react';

interface StatusData {
  status: string;
  date: number;
}

const DELAY = 5000;
export const useHandleCellStatus = (status: StatusData | undefined) => {
  // Only used when status is success or error
  const [showStatusForSomeTime, seShowStatusForSomeTime] = useStateWhile(false);

  useEffect(() => {
    if (status && status.status !== 'loading') {
      if (status.date + DELAY > Date.now()) {
        seShowStatusForSomeTime(true, DELAY);
      }
    }
  }, [status && status.date]);

  const isLoading = status && status.status === 'loading';

  const showStatus = isLoading ? true : showStatusForSomeTime;

  return { showStatus };
};
