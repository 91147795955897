import { css } from 'emotion';

export const styles = {
  headerFixed: css`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  `,
  view_btn: css`
    padding: 5px 12px;
    color: #327cff;
    font-family: var(--bu-font-regular);
    font-weight: 600;
    font-size: 14px;
    border: 1px solid #327cff;
    background: none;
    border-radius: 15px;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  `,
  table_body_empty: css`
    text-align: center;
    padding: 30px;
    font-size: 17px;
    font-family: var(--bu-font-regular);
    font-weight: 100;
    color: #777777;
  `,
  naCell: css`
    color: #777777;
    font-style: italic;
    font-weight: 600;
    font-family: var(--bu-font-regular);
  `,
  columnLabel: css`
    max-width: 100%;
    width: 100%;
  `,
  pointer: css`
    cursor: pointer;
  `,
  deltaValueWrapper: css`
    display: flex;
  `,
  arrowWrapper: css`
    align-self: center;
    width: 20px;
    margin-left: 0;
    padding-left: 5px;
  `,
  dotWrapper: css`
    align-self: center;
    width: 15px;
    margin-left: 0;
    padding-left: 5px;
  `,
  dotted: css`
    border: 5px solid #f26907;
    border-radius: 5px;
  `,
  cellValue: css`
    padding: 9px;
    font-size: 14px;
    font-weight: 800;
    margin-bottom: 3px;

    @media (max-width: 1370px) {
      font-size: 10px;
    }
  `,
  boolDropdown: css`
    label: dropdown-overrides;
    font-family: var(--bu-font-regular);
    font-size: 14px;
    font-weight: 100;
    @media (max-width: 1370px) {
      font-size: 10px;
    }
    &.ui.top.right.pointing.dropdown > .menu {
      margin-top: 0;
    }
    &.ui.dropdown {
      display: flex;
      align-items: center;
      padding: 0 9px;
      i {
        display: flex;
        align-items: center;
      }
    }
  `,
  title: css`
    font-family: var(--bu-font-regular);
    font-size: 20px;
    padding: 5px 0 15px 0;
    color: #4a4a4a;
  `,
  headerRow: css`
    display: grid;
    // grid-template-columns: 1fr 0.7fr 0.7fr 0.7fr 0.7fr 0.6fr 0.6fr 0.6fr 0.6fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

    align-items: center;
    border: 1px solid #dddd;
    border-radius: 2px 2px 0 0;
    margin-bottom: -1px;
    border-bottom: 1px solid #f1a525;
    position: relative;
    z-index: 1;
    font-family: var(--bu-font-semi-bold);
    font-size: 12px;
    text-transform: uppercase;
    color: #666666;
  `,
  container: css`
    height: 100%;
  `,
  aboveHeader: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  `,
  deals_body: css`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    border-top: none;
    border-radius: 0 0 2px 2px;
    position: relative;
    background: #fff;
    margin-bottom: 10px;
  `,
  noBottomMargin: css`
    margin-bottom: 0;
  `,
  pagination_end: css`
    display: flex;
    justify-content: flex-end;
  `,
  body_row: css`
    display: grid;
    // grid-template-columns: 1fr 0.7fr 0.7fr 0.7fr 0.7fr 0.6fr 0.6fr 0.6fr 0.6fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

    align-items: center;
    border-bottom: 1px solid #ddd;

    &:nth-child(2n + 1) {
      background: #fcfcfc;
    }
  `,
  body_row_column: css`
    padding: 11px 15px;
    display: flex;
    align-items: center;
    height: 100%;
    &:last-child {
      border-right: none;
    }
  `,

  // new TABLE

  new_table: css`
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  `,
  new_table_header: css`
    th {
      border-bottom: 1px solid #f1a525;
      border-top: 1px solid #dddddd;
    }
  `,
  new_table_header_column: css`
    padding: 0 7px;
    text-transform: uppercase;
    font-family: var(--bu-font-semi-bold);
    font-size: 12px;
    color: #666;
    position: relative;
    border-right: 1px solid #ddd;
    width: 10%;

    &.hoverContent {
      width: 70px;
    }

    &.firstColumn {
      padding-left: 15px;
      min-width: 250px;

      @media (max-width: 1370px) {
        min-width: 145px;
      }
    }

    &.minWidth {
      min-width: 105px;
    }

    &:last-child {
      border-right: none;
    }

    @media (max-width: 1370px) {
      font-size: 9px;
    }
  `,
  new_table_sticky_header: css`
    border-collapse: separate !important;

    & thead tr:first-child > th {
      position: sticky !important;
      top: 0;
      z-index: 2;
      background-color: #fff !important;
    }
  `,
  fullscreen: css`
    thead,
    tbody,
    tfoot {
      > tr {
        > td,
        > th {
          &:first-child {
            padding-left: 25px;
          }
          &:last-child {
            padding-right: 25px;
          }
        }
      }
    }
  `,
  new_table_sticky_column: css`
    & tbody {
      td:first-child {
        padding-left: 15px;
        position: sticky;
        left: 0;
        z-index: 1;
      }

      tr {
        td:first-child {
          background: white;
          &:hover {
            background: #eef7fe;
          }
        }
      }

      tr:nth-child(2n) {
        td:first-child {
          background-color: var(--bu-gray-100);
          &:hover {
            background: #eef7fe;
          }
        }
        background-color: var(--bu-gray-100);
      }
    }

    & thead tr:first-child > th:first-child {
      position: sticky;
      left: 0;
      z-index: 3;
    }
  `,
  new_table_body: css`
    tr {
      background: #fff;

      td {
        border-bottom: 0.1px solid var(--bu-gray-300);
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }

      &:hover {
        background: #eef7fe;
      }

      &:nth-child(2n) {
        background: var(--bu-gray-100);

        &:hover {
          background: #eef7fe;
        }
      }
    }
  `,
  new_table_body_column: css`
    color: #444444;
    align-items: center;
    padding: 5px;
  `,
  stages_column: css`
    max-width: 100px;
  `,
  tableText: css`
    color: #444444;
    font-family: var(--bu-font-regular);
    font-weight: 100;
    font-size: 14px;
    text-transform: capitalize;
  `,
  align_column: css`
    display: flex;
    flex-direction: column;
    align-items: baseline;
  `,
  titleWrapper: css`
    display: flex;
    align-items: center;
  `,
  deal_name: css`
    font-size: 14px;
    font-weight: 600;
    color: #0762ec;
    font-family: var(--bu-font-regular);
    white-space: nowrap;
    overflow: hidden;
    width: 250px;
    text-overflow: ellipsis;
    cursor: pointer;

    @media (max-width: 1370px) {
      font-size: 10px;
    }
  `,
  account_name: css`
    color: #0053cc;
    font-family: var(--bu-font-regular);
    font-size: 14px;
    cursor: pointer;
    hyphens: auto;
    padding-right: 0px;
    transition: 0.4s;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 130px;

    &:hover {
      color: #37a4e8;
    }

    @media (max-width: 1370px) {
      font-size: 10px;
    }
  `,
  Not_Scored: css`
    font-family: var(--bu-font-regular);
    font-size: 13px;
    padding: 9px;
    font-weight: 800;

    @media (max-width: 1370px) {
      font-size: 10px;
    }
  `,
  allDeals_deal_user: css`
    color: #444444;
    font-family: var(--bu-font-regular);
    font-weight: 100;
    font-size: 14px;
    display: flex;
    align-items: center;

    span {
      margin-left: 5px;
    }

    @media (max-width: 1370px) {
      margin-top: 5px;
      font-size: 10px;
    }
  `,
  next_step: css`
    @media (max-width: 1370px) {
      font-size: 12px;
    }
  `,
  red: css`
    color: #f74544 !important;
  `,
  coral: css`
    color: #f18525 !important;
  `,
  green: css`
    color: #0ba5a3 !important;
  `,
  generic_field_content: css`
    margin: 0 auto;
    height: 28px;
    width: 28px;
    line-height: 28px;
    border-radius: 14px;
    background-color: #edf0f2;
    text-align: center;
    cursor: pointer;

    &:hover {
      background-color: #c9eaff;
    }
  `,
  generic_field_text: css`
    color: #0762ec;
    font-family: var(--bu-font-regular);
    font-size: 14px;
    font-weight: 600;
  `,
  custom_popup: css`
    z-index: 20 !important;
    padding: 0 !important;
  `,
  popupText: css`
    font-family: var(--bu-font-regular);
    font-size: 13px;
    line-height: 20px;
    color: #4a4a4a;
  `,
  popupTextBold: css`
    font-weight: 800;
    color: black;
    text-transform: uppercase;
  `,
  popupTextBoldNoUppercase: css`
    font-weight: 800;
    color: black;
  `,
  dropdownLabel: css`
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 28px;
  `,
};

export default styles;
