import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';
import { call, put, select, all, takeLatest } from 'redux-saga/effects';

import { actions } from 'actions';
import * as t from 'actions/actionTypes';
import * as forecastActions from 'actions/forecastActions';
import { notNullAndEmpty } from 'common/utils';
import * as selectors from 'selectors';

const tab2tab = {
  analytics: 'pipeline_analytics',
  topOpportunities: 'pipeline_recommendations',
};

function* requestDealsAndStats() {
  const currentTab = yield select(selectors.getDealsCurrentTab);

  if (notNullAndEmpty(currentTab)) {
    const updateDealsOnly = currentTab === 'topOpportunities';
    const updateStatsOnly = currentTab === 'analytics';

    const filters = yield select(
      selectors.getFiltersForAPI,
      tab2tab[currentTab] || 'topOpportunities'
    );

    let dealsResponse;
    let statsResponse;

    if (!isEmpty(filters)) {
      const business_type_name = yield select(
        selectors.getBusinessTypeForTab,
        tab2tab[currentTab]
      );

      if (business_type_name) {
        filters.business_type_name = business_type_name;
      }

      if (updateDealsOnly) {
        dealsResponse = yield call(
          axios.post,
          `${process.env.REACT_APP_BACKEND_URL}/api/data/deals/`,
          filters
        );
      }

      if (updateStatsOnly) {
        yield put({
          type: t.DEALS + t.FILTER + t.LOADING,
          tab: currentTab,
        });
        statsResponse = yield call(
          axios.post,
          `${process.env.REACT_APP_BACKEND_URL}/api/data/deals/stats/`,
          filters
        );
      }

      yield put({
        type: t.DEALS + t.FILTER + t.SUCCESS,
        tab: currentTab,
        deals: dealsResponse && dealsResponse.data,
        stats: statsResponse && statsResponse.data,
      });
    }
  }
}

function* enteredTab() {
  const [currentTab, isAppInitialized] = yield all([
    select(selectors.getDealsCurrentTab),
    select(selectors.isAppInitiated),
  ]);

  if (isAppInitialized && notNullAndEmpty(currentTab)) {
    const [dealsStatus, statsStatus] = yield all([
      select(selectors.getDealsStatus, currentTab),
      select(selectors.getStatsStatus, currentTab),
    ]);

    const isUpdateNeeded =
      (currentTab === 'topOpportunities' && dealsStatus === 'notAsked') ||
      (currentTab === 'analytics' && statsStatus === 'notAsked');

    if (isUpdateNeeded) {
      yield call(requestDealsAndStats);
    }
  }
}

function* updateForecast() {
  yield put(forecastActions.updateTotals());
}

function* errorUpdating(action) {
  const isARealTimeUpdate = action.payload.isARealTimeUpdate;
  if (!isARealTimeUpdate) {
    yield put({
      type: t.DEALS + t.REFRESH,
    });
  }
}

function* meddicLoading() {
  toast.warn('Saving sales process...', {
    position: 'bottom-left',
  });
  yield;
}

function* meddicSuccess() {
  toast.success('Changes saved successfully', {
    position: 'bottom-left',
  });
  yield;
}

export function* dealsSaga() {
  yield all([
    takeLatest(t.DEALS + t.SET_ACTIVE_TAB, enteredTab),
    takeLatest(
      [
        actions.ui.filters.loaded,
        actions.ui.filters.persist,
        actions.ui.filters.clearAll,
        t.COMPANY_SETTINGS + t.GET + t.SUCCESS,
        actions.ui.filters.setBusinessType,
      ],
      requestDealsAndStats
    ),
    takeLatest(t.DEALS + t.UPDATE + t.SUCCESS, updateForecast),
    takeLatest(t.DEALS + t.UPDATE + t.ERROR, errorUpdating),
    takeLatest(t.DEALS + t.UPDATE + t.SALES_PROCESS + t.SUCCESS, meddicSuccess),
    takeLatest(t.DEALS + t.UPDATE + t.SALES_PROCESS + t.LOADING, meddicLoading),
  ]);
}

export function* customReportSorting(action) {
  const { reportId, sorting } = action;
  const user = yield select(selectors.getUser);

  const dealsResponse = yield call(
    axios.post,
    `${process.env.REACT_APP_BACKEND_URL}/api/data/deals/`,
    {
      flat: true,
      report_spec_id: reportId,
      email: user.email,
      sort: sorting,
    },
    {
      headers: {
        Authorization: `Bearer ${user.api_access_token}`,
      },
    }
  );

  yield put({
    type: t.DEALS + t.REPORT + t.SORTING + t.SUCCESS,
    reportId,
    sorting,
    deals: dealsResponse.data.data,
  });
}
