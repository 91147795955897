import { MetricSuggesterState } from 'components/UI/BuFormulaTextField/FormulaFieldSuggester/types';

export const OPERATORS_REGEXP = new RegExp(/[\-\+|\/\*\(\)]{1}/gm);

export const METRIC_REGEXP = new RegExp(/\{[a-zA-Z0-9]{24}\}/gm);

export const METRIC_ID_REGEXP = new RegExp(/[0-9a-zA-Z]{24}/g);

export const SIGNS_ARRAY = [' ', '-', '+', '(', ')', '*', '/'];

export const FORMULA_PARSER_REGEXP = new RegExp(
  /\{[a-zA-Z0-9]{24}\}|[\+-\/\*\(\)]{1}|[\d]{1,}/gm
);

export const NUMBERS_REGEXP = new RegExp(/^-?\d+$/);

export const METRICS_SUGGESTER_INITIAL_STATE: MetricSuggesterState = {
  caretPosition: 0,
  collapsed: true,
  listOfSuggestions: [],
};
