import { css } from 'emotion';

import {
  fontHeading3,
  fontCaption1,
  fontCaption2,
  fontSubheading1,
} from 'assets/css/fontStyles';

import { showMore, removeTooltipBefore } from '../styles';

export { removeTooltipBefore };

export const callTimelineWrapper = css`
  .call-timeline {
    position: relative;
    padding-bottom: 10px;
  }

  .call-timeline-progress {
    position: absolute;
    height: 100%;
    /* Using color straight because the opacity  */
    /* Adding opacity property would impact the border also */
    background-color: #3884ff10; // --bu-primary-500
    border-right: 1px solid var(--bu-primary-700);
    z-index: 0;
  }

  .call-timeline-highlight-section {
    position: absolute;
    height: 100%;
    background-color: var(--bu-colol-call-timeline-highlight-section);
    z-index: 999;

    &.disabled {
      cursor: default;
    }

    &:hover:not(.disabled) {
      cursor: pointer;
      box-shadow: var(--bu-shadow-basic);
    }
  }

  .call-timeline-participant {
    margin-top: 30px;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;

    &:last-child {
      margin-bottom: 0;
    }

    .participant {
      display: flex;
      align-items: center;
      gap: var(--bu-control-gap-medium);

      .participant-info {
        ${fontHeading3}
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
        gap: var(--bu-control-gap-medium);

        .participant-info-left {
          display: flex;
          align-items: center;
          gap: var(--bu-control-gap-medium);
        }

        /* TODO: Will have this in the future */
        /* .participant-role-company {
          color: var(--bu-color-call-light-gray);
          font-size: 12px;
          font-family: var(--bu-font-regular);
        } */

        .speaker-sentiment-badge {
          display: flex;
          align-items: center;
          padding: 2px 6px;
          gap: var(--bu-control-gap-small);
          border-radius: var(--bu-control-border-radius);
          text-transform: uppercase;
          cursor: pointer;

          i {
            font-size: 14px;
          }

          .sentiment-badge-text {
            ${fontSubheading1}
            font-weight: 500;
            font-size: 12px;
          }

          &.positive {
            background-color: var(
              --bu-color-call-summary-sentiment-positive-background
            );
            color: var(--bu-green-500);
          }

          &.negative {
            background-color: var(
              --bu-color-call-summary-sentiment-negative-background
            );
            color: var(--bu-red-400);
          }

          &.neutral {
            background-color: var(
              --bu-color-call-summary-sentiment-neutral-background
            );
            color: var(--bu-orange-500);
          }
        }

        .participant-percentage {
          font-size: 14px;
          font-family: var(--bu-font-medium);
          color: var(--bu-color-call-light-gray);
        }
      }
    }

    .video-time {
      margin-top: 6px;
      background-color: var(--bu-color-call-video-time);
      height: 10px;
      border-radius: var(--bu-control-border-radius-large);
      position: relative;

      .video-participant-speaking {
        position: absolute;
        height: 10px;
        border-radius: var(--bu-control-border-radius-large);

        &.disabled {
          cursor: default;
        }

        &:hover:not(.disabled) {
          cursor: pointer;
          box-shadow: var(--bu-shadow-deep);
        }
      }
    }
  }

  .call-timeline-show-more {
    ${showMore}
    margin-top: 14px;
  }
`;

export const participantSentimentTooltipWrapper = css`
  ${fontCaption1}
  max-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .speaker-sentiment-title {
    ${fontCaption2}
    font-size: 14px;
    font-weight: 600;
  }

  .speaker-sentiment-justification {
    display: flex;
    flex-direction: column;
    gap: var(--bu-control-gap-medium);
  }
`;
