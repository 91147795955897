import { css, cx } from 'emotion';

import { checkedIcon } from 'assets/css/common';

export const panelItemDropdown = css`
  // padding: 12px 10px;
`;

export const checkBoxStyles = css`
  position: relative;
  cursor: pointer;
  min-width: 12px;
  width: 12px !important;
  height: 12px;
  border: 1px solid var(--bu-gray-500);
  display: inline-block;
  background: #f7f7f7;
  border-radius: 2px;

  &.half-check {
    background: #999999;
  }

  &.check:after {
    background: var(--bu-primary-500);
    content: '';
    display: block;
    position: absolute;
    left: -1px;
    right: -1px;
    top: -1px;
    bottom: -1px;
    border-radius: 3px;
  }

  &.check:before {
    z-index: 1;
    content: '';
    ${checkedIcon}
    width: 8px;
    height: 8px;
    position: absolute;
    left: 1px;
    top: 1px;
  }
`;

export const panelDividerColor = css`
  &.ui.divided.grid:not([class*='vertically divided']) {
    & > .column:not(.row),
    & > .row > .column {
      box-shadow: -1px 0 0 0 #f4f7f8;
    }
  }
`;

const commonForRadioAndTree = css`
  font-family: var(--bu-font-regular);
  color: #666;
`;

const checkboxContainer = css`
  max-height: 210px;
  overflow-y: auto;
`;

export const panelCheckBoxTree = cx(checkboxContainer, commonForRadioAndTree);

export const addonsForOpenFilters = css`
  .rct-text {
    white-space: nowrap;
  }
`;

export const panelRadioGroup = cx(
  checkboxContainer,
  commonForRadioAndTree,
  css`
    display: flex;
    flex-direction: column;
  `
);

export const panelRadioGroupItem = css`
  margin-bottom: 15px !important;

  &.special {
    margin-top: 5px !important;
    margin-bottom: 24px !important;
  }
`;

export const moreButtonStyles = css`
  &.ui.button.basic {
    padding: 10px;
    border: 1px solid #dddddd;
    background: var(--bu-white);
    box-shadow: rgba(34, 36, 38, 0.15) 0px 1px 2px 0px;
    font-family: var(--bu-font-regular);
    font-weight: 600;
    color: #4a4a4a;
  }
`;
