import * as styles from '../../styles';
import { IProps } from './types';
import React from 'react';
import Highlighter from 'react-highlight-words';

const ObjectionText: React.FC<IProps> = (props) => {
  if (typeof props.phrase !== 'string' || typeof props.match !== 'string') {
    return null;
  }
  return (
    <div className={styles.textTruncate}>
      <Highlighter
        highlightClassName={styles.boldText}
        searchWords={[props.match]}
        autoEscape={true}
        textToHighlight={props.phrase}
      />
    </div>
  );
};

export default ObjectionText;
