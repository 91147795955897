import classNames from 'classnames';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useState, useEffect, useMemo } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import { Dimmer, Loader, DropdownItemProps } from 'semantic-ui-react';

import BuButtonRefresh from 'components/UI/BuButtonRefresh';
import {
  getPlotOptions,
  PIPELINE_ATTAINMENT_TIME_INTERVAL_OPTIONS_DEFAULT,
  PIPELINE_ATTAINMENT_TIME_INTERVAL_OPTIONS,
  PIPELINE_ATTAINMENT_SORT_OPTIONS,
} from 'components/dashboard/Pipeline/PipelineDashboard/PipelineAttainmentWidget/helpers';
import * as styles from 'components/dashboard/Pipeline/PipelineDashboard/PipelineAttainmentWidget/styles';
import {
  IProps,
  apiResponse,
  AttainmentData,
} from 'components/dashboard/Pipeline/PipelineDashboard/PipelineAttainmentWidget/types';
import { useClickOutside } from 'components/hooks/useClickOutside';
import { QueryStatus, fetchApi } from 'utils/network';

const apiPoint = `${process.env.REACT_APP_BACKEND_URL}/api/data/pipeline/new/seller`;
const toastOptions: ToastOptions = { position: 'bottom-left' };

const PipelineAttainmentWidget: React.FC<IProps> = ({
  companyCurrency,
  openFilters,
  selectedBusinessType,
}) => {
  const [timeInterval, setTimeInterval] = useState<string>(
    PIPELINE_ATTAINMENT_TIME_INTERVAL_OPTIONS_DEFAULT
  );
  const [sorting, setSorting] = useState(1);
  const [apiData, setApiData] = useState<AttainmentData[]>([]);
  const [dataStatus, setDataStatus] = useState<QueryStatus>('notAsked');
  const [cacheDate, setCacheDate] = useState('');
  const [refreshCache, setRefreshCache] = useState(false);

  const isLoading = ['notAsked', 'loading'].includes(dataStatus);

  const serializedQueryParams = useMemo(
    () =>
      JSON.stringify({
        business_type_name: selectedBusinessType,
        pace_interval: timeInterval,
        ...openFilters,
      }),
    [JSON.stringify(openFilters), selectedBusinessType, timeInterval]
  );

  useEffect(() => {
    const abortController = new AbortController();

    fetchApi<string, apiResponse>({
      queryParams: serializedQueryParams,
      setData: ({ data }: apiResponse) =>
        setApiData(data.filter((data) => data.user)),
      setError: (error: string | null) =>
        toast.error(`Fetching data failed: ${error}`, toastOptions),
      setStatus: setDataStatus,
      setHeaders: (headers) => {
        setCacheDate(headers.get('cache-created') || '');
      },
      signal: abortController.signal,
      url: apiPoint,
    });

    return () => abortController && abortController.abort();
  }, [serializedQueryParams]);

  useEffect(() => {
    let abortController: AbortController | null = null;

    if (refreshCache) {
      abortController = new AbortController();

      fetchApi<string, apiResponse>({
        queryParams: serializedQueryParams,
        setData: ({ data }: apiResponse) =>
          setApiData(data.filter((data) => data.user)),
        setError: (error: string | null) =>
          toast.error(`Fetching data failed: ${error}`, toastOptions),
        setStatus: setDataStatus,
        setHeaders: (headers) => {
          setCacheDate(headers.get('cache-created') || '');
          setRefreshCache(false);
        },
        signal: abortController.signal,
        url: refreshCache ? `${apiPoint}?cache_refresh=1` : apiPoint,
      });
    }

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [serializedQueryParams, refreshCache]);

  const options = useMemo(
    () => getPlotOptions(apiData, companyCurrency, sorting),
    [apiData, companyCurrency, sorting]
  );

  const hasTargets = useMemo(
    () => apiData.some((data) => data.target),
    [apiData]
  );

  return hasTargets ? (
    <Dimmer.Dimmable dimmed={isLoading}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          How are sellers doing on Pipeline Creation Quota Attainment?
          <BuButtonRefresh
            status={refreshCache}
            cacheDate={cacheDate}
            onClick={() => setRefreshCache(true)}
          />
          <div className={styles.filters}>
            <DropdownCustom
              value={timeInterval}
              options={PIPELINE_ATTAINMENT_TIME_INTERVAL_OPTIONS}
              onClick={(e: React.SyntheticEvent, value: string) =>
                setTimeInterval(value as string)
              }
              label="Created in"
            />
            <DropdownCustom
              value={sorting}
              options={PIPELINE_ATTAINMENT_SORT_OPTIONS}
              onClick={(e: React.SyntheticEvent, value: number) =>
                setSorting(value)
              }
              label="Rank"
            />
          </div>
        </div>

        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          immutable={true}
        />
      </div>

      <Dimmer active={isLoading} inverted>
        <Loader />
      </Dimmer>
    </Dimmer.Dimmable>
  ) : null;
};

export default PipelineAttainmentWidget;

type IpropsDropdownProps = {
  label: string;
  onClick: any;
  value: string | number;
  options: DropdownItemProps[];
};

const DropdownCustom: React.FC<IpropsDropdownProps> = ({
  label,
  onClick,
  value,
  options,
}) => {
  const { isOpen, setIsOpen, refElement } = useClickOutside();

  const toggle = () => setIsOpen(!isOpen);

  const option = options.find((el) => el.value === value);

  const activeValue = option !== undefined ? option.text : '';

  return (
    <div
      ref={refElement}
      onClick={toggle}
      className={classNames(styles.dropdrown, { active: isOpen })}
    >
      <span className={styles.dropdrown_label}>{label}:</span>
      <span className={styles.dropdrown_value}>
        {activeValue}
        <i
          className={classNames(styles.triangle, {
            'bu-up': isOpen,
            'bu-down': !isOpen,
          })}
        />
      </span>
      <ul className={classNames(styles.dropdrown_options, { open: isOpen })}>
        {options.map((item) => (
          <li
            onClick={(e) => onClick(e, item.value)}
            className={classNames(styles.dropdrown_options_item, {
              active: item.value === value,
            })}
          >
            {item.text}
          </li>
        ))}
      </ul>
    </div>
  );
};
