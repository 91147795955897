import Highcharts, { Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

import Modal from 'components/UI/Modal/Modal';
import * as layStyle from 'components/dashboard/styles';
import {
  REVENUE_DROPDOWN_OPTIONS,
  RevenueTypes,
  formatRevenueTitle,
} from 'components/modals/types';

type Props = {
  open: boolean;
  type: RevenueTypes;
  config: Options;
  title: string;
  onClose: () => void;
  onChange: (
    event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => void;
};

const RevenueStackedModal: React.FC<Props> = ({
  open,
  type,
  config,
  title,
  onClose,
  onChange,
}) => {
  const controls = (
    <Dropdown
      className={layStyle.dropdown}
      options={REVENUE_DROPDOWN_OPTIONS}
      onChange={onChange}
      value={type}
    />
  );

  return open ? (
    <Modal
      title={formatRevenueTitle(type, title) || ''}
      onClose={onClose}
      size="fullscreen"
      controls={controls}
      fullHeight
    >
      <HighchartsReact
        containerProps={{ className: 'highcharts-container-full-height' }}
        highcharts={Highcharts}
        options={config}
        immutable
      />
    </Modal>
  ) : null;
};

export default RevenueStackedModal;
