import BuSkeleton from '.';
import * as styles from './styles';
import random from 'lodash/random';
import React, { FC, useMemo } from 'react';

interface IBuLineWithDots {
  lineWidth?: number;
  dotsCount?: number;
}

const generateDots = (count: number, vectorSize: number) => {
  const dotStep = Math.round(vectorSize / count);
  return new Array(count).fill(null).map((_item, index) => {
    return random(index * dotStep, dotStep * (index + 1));
  });
};

const BuLineWithDots: FC<IBuLineWithDots> = ({
  lineWidth = 100,
  dotsCount = 2,
}) => {
  const dotsPositions: number[] = useMemo(
    () => generateDots(dotsCount, lineWidth),
    [dotsCount, lineWidth]
  );

  return (
    <div className={styles.lineWithDotsContainer}>
      <BuSkeleton
        height={3}
        width={lineWidth}
        containerClassName={styles.lineWithDotsLineContainer}
      />

      {dotsPositions.map((item: number, index: number) => {
        return (
          <BuSkeleton
            width={11}
            height={11}
            circle
            containerClassName={styles.linewithDotsDot}
            style={{ left: `${item}px` }}
            key={`${item}-${index}`}
          />
        );
      })}
    </div>
  );
};

export default BuLineWithDots;
