import { all, put, select, takeEvery } from 'redux-saga/effects';

import {
  fetchForecastsSubmissionsByPeriodToCopy,
  forecastSubmissionsCreateSuccessAction,
} from 'actions/forecastActions';
import { ForecastSubmissionSettingItemReduxState } from 'common/types/forecastSubmission';
import {
  getForecastSubmissionSettings,
  getForecastSubmissionSettingsById,
} from 'selectors';

function* refreshForecastSubmissionCopyForRelevantPeriodsOnCreateWorker(
  action: ReturnType<typeof forecastSubmissionsCreateSuccessAction>
) {
  const { submissionSettingId } = action.payload;

  const forecastSubmissionSetting: ForecastSubmissionSettingItemReduxState =
    yield select((state) =>
      getForecastSubmissionSettingsById(state, submissionSettingId)
    );
  const { period_delta: periodDelta, period_type: periodType } =
    forecastSubmissionSetting;

  const allForecastsSubmissionSettings: ForecastSubmissionSettingItemReduxState[] =
    yield select(getForecastSubmissionSettings);

  const periodCopiesToUpdate = allForecastsSubmissionSettings
    .filter(
      (s) =>
        s.period_delta === periodDelta &&
        s.period_type === periodType &&
        s.submissionsToCopyStatus === 'SUCCESS'
    )
    .map((s) =>
      put(
        fetchForecastsSubmissionsByPeriodToCopy({
          submissionSettingId: s.id,
          periodType,
          includedDeals: s?.deals?.deals.map((d) => d._id) || [],
        })
      )
    );
  yield all(periodCopiesToUpdate);
}

export function* watchSubmissionCreation() {
  yield takeEvery(
    forecastSubmissionsCreateSuccessAction.type,
    refreshForecastSubmissionCopyForRelevantPeriodsOnCreateWorker
  );
}
