import { DOWN, UP } from './constants';

import { IRow } from 'components/UI/common/TypedTable/TypedTable';

export const reorderBusinessTypes = (
  businessTypes: IRow[],
  positionOfMovedItem: number,
  direction: string
): IRow[] => {
  const orderedBusinessTypes = [...businessTypes].sort(
    (a, b) => (a.order as number) - (b.order as number)
  );
  const itemOrders = orderedBusinessTypes.map((item) => item.order as number);
  const delta = direction === UP ? -1 : 1;
  const itemToMoveIndex = orderedBusinessTypes.findIndex(
    (item) => item.order === positionOfMovedItem
  )!;
  const itemToMove = orderedBusinessTypes[itemToMoveIndex];
  const orderOfMovedItem = itemToMove.order as number;

  if (
    (direction === UP && orderOfMovedItem + delta < Math.min(...itemOrders)) ||
    (direction === DOWN && orderOfMovedItem + delta > Math.max(...itemOrders))
  ) {
    return orderedBusinessTypes;
  }

  return orderedBusinessTypes.reduce<IRow[]>((acc, curr) => {
    if (curr.order === orderOfMovedItem + delta) {
      if (direction === UP) {
        acc.push({ ...itemToMove, order: curr.order });
        acc.push({ ...curr, order: curr.order + 1 });
      }
      if (direction === DOWN) {
        acc.push({ ...curr, order: curr.order - 1 });
        acc.push({ ...itemToMove, order: curr.order });
      }
    } else if (curr.name !== itemToMove.name) {
      acc.push(curr);
    }
    return acc;
  }, []);
};
