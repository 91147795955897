import axios from 'axios';
import {
  delay,
  put,
  takeEvery,
  takeLatest,
  call,
  select,
} from 'redux-saga/effects';

import { actions } from 'actions';
import * as t from 'actions/actionTypes';
import {
  fetchForecastDataFailure,
  fetchForecastDataLoading,
  fetchForecastDataSuccess,
  updateTotalsSuccess,
  forecastSubmissionsCreateErrorAction,
  forecastSubmissionsCreateSuccessAction,
  setForecastOppSplitView,
} from 'actions/forecastActions';
import { getBusinessTypesList } from 'selectors';

function* clearGenerator(action) {
  yield delay(7 * 1000);

  yield put(
    actions.ui.manageForecast.clear({
      settingsId: action.payload.submissionSettingId,
    })
  );
}

export function* fetchForecastDataSaga({ filters }) {
  try {
    yield put(fetchForecastDataLoading());
    const response = yield call(
      axios.post,
      `${process.env.REACT_APP_BACKEND_URL}/api/data/forecast/v2/summary`,
      filters
    );

    yield put(fetchForecastDataSuccess(response.data.data));
    yield put(updateTotalsSuccess());
  } catch (error) {
    yield put(fetchForecastDataFailure(error));
  }
}

function* _setBusinessTypeDefaultSplit(selectedBusinessType) {
  const businessTypesList = yield select(getBusinessTypesList);
  const businessTypeConfig = businessTypesList.find(
    (businessType) => businessType.name === selectedBusinessType
  );

  const splitsEnabled = businessTypeConfig?.enable_opportunity_splits ?? false;
  const defaultValue = businessTypeConfig?.splits_default_enabled ?? true;
  const splitValue = splitsEnabled ? defaultValue : false;

  yield put(setForecastOppSplitView(splitValue));
}

function* setBusinessTypeDefaultSplit({ payload }) {
  yield _setBusinessTypeDefaultSplit(payload);
}

function* setBusinessTypeDefaultSplitForecast({ payload }) {
  const { businessType } = payload;
  if (businessType !== undefined) {
    yield _setBusinessTypeDefaultSplit(businessType);
  }
}

export function* manageForecastSaga() {
  yield takeLatest([t.FORECAST + t.GET], fetchForecastDataSaga);

  yield takeLatest(
    [t.FORECAST + t.SET + '_ACTIVE_BUSINESS_TYPE'],
    setBusinessTypeDefaultSplit
  );

  yield takeLatest(
    [t.FILTER + t.PERSIST + t.BUSINESS_TYPES],
    setBusinessTypeDefaultSplitForecast
  );

  yield takeEvery(
    [
      forecastSubmissionsCreateErrorAction.type,
      forecastSubmissionsCreateSuccessAction.type,
    ],
    clearGenerator
  );
}
