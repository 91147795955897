import BuSelect from '../BuSelect';
import { ISelectOption } from '../BuSelect/types';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

interface OwnProps {
  value: string;
  multiple?: boolean;
  field: string;
  isBooleanField?: boolean;
  onChange: (value: string) => any;
}

interface StateProps {
  fieldOptions?: ISelectOption[];
}

type IProps = OwnProps & StateProps;

const dropContainer = css`
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;

  .bu-dropdown.bu-dropdown-open,
  .bu-dropdown.bu-dropdown-close {
    .bu-btn-content {
      .bu-dropdown-label {
        color: var(--bu-primary-700);
        font-size: 13px;
      }

      i.bu-down,
      i.bu-up {
        border-color: var(--bu-gray-900);
        margin-top: 5px;
      }
    }
  }
`;

const InlineDropDownOption = ({
  value,
  fieldOptions,
  multiple,
  isBooleanField = false,
  onChange: _handleChange,
}: IProps) => {
  const [selectedValue, setSelectedValue] = useState(value);
  const opts = useMemo(() => {
    return isBooleanField
      ? [
          {
            value: 'true',
            text: 'True',
          },
          {
            value: 'false',
            text: 'False',
          },
        ]
      : fieldOptions || [];
  }, [isBooleanField, fieldOptions]);

  return (
    <section className={dropContainer}>
      <BuSelect
        borderless
        options={opts}
        defaults={[selectedValue]}
        onChange={(values: string[]) => {
          setSelectedValue(values[0]);
          values[0] && values[0] !== value && _handleChange(String(values[0]));
        }}
        multiselect={multiple}
        fullWidth
      />
    </section>
  );
};
InlineDropDownOption.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,

  // used in redux connect
  // eslint-disable-next-line
  field: PropTypes.string.isRequired,
};

const mapStateToProps = (
  state: IReduxState,
  ownProps: OwnProps
): StateProps => ({
  fieldOptions: selectors
    .getFieldOptions(state, ownProps.field)
    .map((value: string) => ({ key: value, text: value, value })),
});

export default connect(mapStateToProps)(InlineDropDownOption);
