import * as style from './styles';
import { TooltipContainer, TooltipHeader } from './styles';
import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { formatAmount, formatMoney, formatNumber } from 'common/numbers';
import NotAvailableCell from 'components/UI/common/TypedTable/renderers/NotAvailableCell';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import {
  ForecastPipelineColumnType,
  ForecastPipelineRowType,
  ForecastPipelineStageCellProps,
  StageTransferSentiment,
} from 'components/dashboard/ForecastPipelineStage/types';
import { getUserLocalCurrency } from 'selectors';

const getTooltipCopies = (
  toField: string,
  fromField: string,
  rowType: ForecastPipelineRowType,
  columnType: ForecastPipelineColumnType
): { title: string; percentageCopy?: string } => {
  const isTotalRow = rowType === 'totalRow';

  const isStartingColumn = columnType === 'startingValue';
  const isEndingColumn = columnType === 'endingValue';

  const isStartingTotalRow = isStartingColumn && isTotalRow;

  const isTotalRowTransfer = !isStartingColumn && isTotalRow;

  const isProgressionRow = rowType === 'progressionRow';
  const isRegressionRow = rowType === 'regressionRow';

  switch (true) {
    case isStartingTotalRow:
      return { title: 'Total Starting Value' };
    case isTotalRowTransfer:
      return {
        title: isEndingColumn
          ? 'Total Ending Value'
          : `Total Conversion to ${toField}`,
      };
    case isStartingColumn:
      return { title: `${fromField}` };
    case isProgressionRow:
      return {
        title: isEndingColumn
          ? 'Total Progression'
          : `Progression into ${toField}`,
        percentageCopy: 'of current total',
      };
    case isRegressionRow:
      return {
        title: isEndingColumn
          ? 'Total Regression'
          : `Regression into ${toField}`,
        percentageCopy: 'of current total',
      };
    default:
      return {
        title: `From ${fromField} to ${toField}`,
        percentageCopy: `of ${fromField}`,
      };
  }
};

const ForecastPipelineStageCell: React.FC<ForecastPipelineStageCellProps> = ({
  column,
  row,
}) => {
  const compayCurrencyCode = useSelector(getUserLocalCurrency);

  const { field: toField, onAmountClick, columnType } = column;

  const { from, rowType } = row;
  const forecastToAmount = (row[toField] as number) || 0;
  const forecastToDealsIds = (row[`${toField}_ids`] as string[]) || [];
  const forecastPercentage = (row[`${toField}_percentage`] as number) || 0;

  const transferSentiment = row[
    `${toField}_sentiment`
  ] as StageTransferSentiment;

  const dealsTransfered = forecastToDealsIds.length;

  // There are cases when the amount for the deals total is 0.
  // In that case we only have to show the transfer if there are deals transfered
  if (
    dealsTransfered === 0 ||
    forecastToAmount === null ||
    forecastToAmount === undefined
  ) {
    return <NotAvailableCell placeholder={'-'} justify="end" />;
  }

  const { title: tooltipAndModalTitle, percentageCopy } = getTooltipCopies(
    toField,
    from,
    rowType,
    columnType
  );

  const displayValue = (
    <>
      <span className={style.clickableAmount}>
        {formatAmount(forecastToAmount, compayCurrencyCode)}
      </span>{' '}
      <span className={style.dealsTransfered}>
        {dealsTransfered} {dealsTransfered != 1 ? 'Deals' : 'Deal'}
      </span>
    </>
  );

  const tooltipContent = (
    <TooltipContainer>
      <TooltipHeader>{tooltipAndModalTitle}</TooltipHeader>
      <div
        className={style.tooltipAmountCell}
        onClick={() =>
          onAmountClick &&
          onAmountClick(tooltipAndModalTitle, forecastToDealsIds)
        }
      >
        {formatMoney(compayCurrencyCode, forecastToAmount)} ({dealsTransfered}{' '}
        {dealsTransfered != 1 ? 'Deals' : 'Deal'})
      </div>
      {!!forecastPercentage && percentageCopy && (
        <div>
          {formatNumber(forecastPercentage, 2)}% {percentageCopy}
        </div>
      )}
    </TooltipContainer>
  );

  return (
    <TooltipWrapper
      tooltip={tooltipContent}
      position={'top center'}
      hoverable={true}
    >
      <div
        className={classNames(style.forecastPipelineStageCell, {
          [style.positiveSentimentCell]: transferSentiment === 'positive',
          [style.negativeSentimentCell]: transferSentiment === 'negative',
        })}
        onClick={() =>
          onAmountClick &&
          onAmountClick(tooltipAndModalTitle, forecastToDealsIds)
        }
      >
        {displayValue}
      </div>
    </TooltipWrapper>
  );
};

export default ForecastPipelineStageCell;
