import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { formatMoney } from 'common/numbers';
import { someStatusIsLoading } from 'common/rowStatus.helper';
import { RowStatusType } from 'components/UI/common/TypedTable/TypedTable';
import {
  DealsRow,
  ForecastDealsModalProps,
} from 'components/dashboard/ForecastRollUps/ForecastDealsTable/types';
import { OptionWithComparator } from 'components/hooks/useComplexDropdownFilter';
import { getIsRealTimeEnabled } from 'selectors';

export const SELECT_ROW_FIELD = '__isChecked__';

const INCLUDED_DEALS_BY_TEAM_API = `${process.env.REACT_APP_BACKEND_URL}/api/data/forecast/submission/deals_included_by_team/:submissionSettingId`;

export enum CallFilter {
  All = '',
  IncludedByTeam = 'IncludedByTeam',
  ExcludedByTeam = 'ExcludedByTeam',
  IncludedByMe = 'IncludedByMe',
  ExcludedByMe = 'ExcludedByMe',
  IncludedByTeamOverride = 'IncludedByTeamOverride',
  ExcludedByTeamOverride = 'ExcludedByTeamOverride',
}

export const ALL_OPTION = {
  value: CallFilter.All,
  order: 0,
  text: 'All',
  compareTo: {},
};

const INCLUDED_BY_TEAM_OVERRIDE = {
  value: CallFilter.IncludedByTeamOverride,
  order: 1,
  text: 'Included by team',
  compareTo: { included_in_submission: true },
};

const EXCLUDED_BY_TEAM_OVERRIDE = {
  value: CallFilter.ExcludedByTeamOverride,
  order: 2,
  text: 'Excluded by team',
  compareTo: { included_in_submission: false },
};

const INCLUDED_BY_ME = {
  value: CallFilter.IncludedByMe,
  order: 3,
  text: 'Included by me',
  compareTo: { [SELECT_ROW_FIELD]: true },
};

const EXCLUDED_BY_ME = {
  value: CallFilter.ExcludedByMe,
  order: 4,
  text: 'Excluded by me',
  compareTo: { [SELECT_ROW_FIELD]: false },
};

const INCLUDED_BY_TEAM = {
  value: CallFilter.IncludedByTeam,
  order: 1,
  text: 'Included by team',
  compareTo: { includedByTeam: true },
};

const EXCLUDED_BY_TEAM = {
  value: CallFilter.ExcludedByTeam,
  order: 2,
  text: 'Excluded by team',
  compareTo: { excludedByTeam: true },
};

export const OVERRIDE_ME_AND_TEAM_OPTIONS: OptionWithComparator<DealsRow>[] = [
  ALL_OPTION,
  INCLUDED_BY_TEAM_OVERRIDE,
  EXCLUDED_BY_TEAM_OVERRIDE,
  INCLUDED_BY_ME,
  EXCLUDED_BY_ME,
];

const SIDE_ME_AND_TEAM_OPTIONS: OptionWithComparator<DealsRow>[] = [
  ALL_OPTION,
  INCLUDED_BY_TEAM,
  EXCLUDED_BY_TEAM,
  INCLUDED_BY_ME,
  EXCLUDED_BY_ME,
];

const ME_CALLS_ONLY_OPTIONS: OptionWithComparator<DealsRow>[] = [
  ALL_OPTION,
  INCLUDED_BY_ME,
  EXCLUDED_BY_ME,
];

type DealWithAmountAndExchangeRate = Pick<
  Deals.Deal,
  '_id' | 'amount' | 'exchange_rate'
>;

export const getSumAmount = (data: DealWithAmountAndExchangeRate[]) =>
  data.reduce(
    (acc, row) => acc + (row.amount.value || 0) / (row.exchange_rate || 1),
    0
  );

export const useTeamSummaryMemo = (
  dealsRows: DealsRow[],
  companyCurrency: string
): [number, string] =>
  useMemo(() => {
    const teamDeals = dealsRows.filter((item) => item.included_in_submission);

    return [
      teamDeals.length,
      formatMoney(companyCurrency, getSumAmount(teamDeals), 0),
    ];
  }, [dealsRows, companyCurrency]);

export const useMySummaryMemo = (
  dealsRows: DealWithAmountAndExchangeRate[],
  companyCurrency: string,
  selectedDealsIds: string[]
): [number, string] =>
  useMemo(() => {
    const myDeals = dealsRows.filter(({ _id }) =>
      selectedDealsIds.includes(_id)
    );

    return [
      myDeals.length,
      formatMoney(companyCurrency, getSumAmount(myDeals), 0),
    ];
  }, [dealsRows, companyCurrency, selectedDealsIds]);

export const getIncludedDealsByTeamApi = (submissionSettingId: string) =>
  INCLUDED_DEALS_BY_TEAM_API.replace(
    ':submissionSettingId',
    submissionSettingId
  );

export const getCallOptionsForType = (
  callOptionType: ForecastDealsModalProps<any>['callOptionType'],
  hasTeam: boolean
): OptionWithComparator<DealsRow>[] => {
  switch (true) {
    case callOptionType === 'OVERRIDE_ME_AND_TEAM_CALLS':
      return OVERRIDE_ME_AND_TEAM_OPTIONS;
    case hasTeam && callOptionType === 'SIDE_PANEL_ME_AND_TEAM_CALLS':
      return SIDE_ME_AND_TEAM_OPTIONS;
    case !hasTeam && callOptionType === 'SIDE_PANEL_ME_AND_TEAM_CALLS':
    case callOptionType === 'ME_CALLS_ONLY':
      return ME_CALLS_ONLY_OPTIONS;
    default:
      return [ALL_OPTION];
  }
};

interface RealTimeForecastDealsModalParams {
  dealsEditionTracker: RowStatusType;
  onRefresh: () => void;
  onClose: () => void;
}

export const useRealTimeForecastDealsModal = ({
  dealsEditionTracker,
  onRefresh,
  onClose,
}: RealTimeForecastDealsModalParams) => {
  const [isRecalculating, setIsRecalculating] = useState(false);
  const isRealTimeEnabled = useSelector(getIsRealTimeEnabled);
  const [
    someWasModifiedSinceRecalculation,
    setSomeWasModifiedSinceRecalculation,
  ] = useState(false);
  const someDealIsLoading = someStatusIsLoading(dealsEditionTracker);

  const actionButtonsDisabled = isRealTimeEnabled && someDealIsLoading;

  const onRecalculate = () => {
    setIsRecalculating(true);
    setSomeWasModifiedSinceRecalculation(false);
    onRefresh();
  };

  const hasToRecalculate =
    isRealTimeEnabled && someWasModifiedSinceRecalculation;

  const internalOnClose = () => {
    if (hasToRecalculate) {
      onRecalculate();
    }
    onClose();
  };

  const realTimeOnDealsRefreshed = () => {
    if (isRecalculating) {
      setIsRecalculating(false);
    }
  };

  const realTimeOnDealUpdated = () => {
    setSomeWasModifiedSinceRecalculation(true);
  };

  return {
    realTimeOnClose: internalOnClose,
    realTimeOnDealsRefreshed,
    realTimeRecalculate: onRecalculate,
    realTimeOnDealUpdated,
    hasToRecalculate,
    actionButtonsDisabled,
    isRecalculating,
    isRealTimeEnabled,
  };
};
