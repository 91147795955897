import { buffers } from 'redux-saga';
import { actionChannel, flush, fork, takeEvery } from 'redux-saga/effects';

import * as t from 'actions/actionTypes';
import { forecastSubmissionsCreateSuccessAction } from 'actions/forecastActions';
import activitySaga from 'sagas/activity';
import callsSaga from 'sagas/calls';
import callsStatsSaga from 'sagas/callsStats';
import { dealsSaga } from 'sagas/deals';
import { filtersSaga } from 'sagas/filters';
import { manageForecastSaga } from 'sagas/forecast';
import { watchSubmissionCreation } from 'sagas/forecastTyped';
import { initSaga } from 'sagas/init';
import { loginSaga } from 'sagas/login';
import { preferencesSaga } from 'sagas/preferences';
import { mappingTakeEveryRevBISaga } from 'sagas/revbi';
import routeSaga from 'sagas/route';
import sellerTargetsSaga from 'sagas/sellerTargets';
import settingsSaga from 'sagas/settings';
import signUpSaga from 'sagas/sign-up';

export const mappingTakeEvery = [
  t.ACCOUNT + t.GET,
  t.ACCOUNT + t.UPDATE,
  t.ACCOUNTS + t.GET,
  t.ADMIN + t.AUTH,
  t.ADMIN + t.IMPERSONATE,
  t.ADMIN + t.RESTORE,
  t.BUSINESS_TYPES + t.GET,
  t.SPLIT_TYPES + t.GET,
  t.BUSINESS_TYPES + t.SET,
  t.BUSINESS_TYPES + t.UPDATE + t.SORTING,
  t.CALLS + t.EVENTS + t.PAGINATION,
  t.CALLS + t.FILTER,
  t.CALLS + t.GET,
  t.CALLS + t.PAGINATION,
  t.CALLS_STATS + t.GET,
  t.COMPANY_SETTINGS + t.CRM_FORECAST_CATEGORY + t.GET,
  t.COMPANY_SETTINGS + t.CRM_FORECAST_CATEGORY + t.SET,
  t.COMPANY_SETTINGS + t.CRM_SYNC + t.GET,
  t.COMPANY_SETTINGS + t.RT + t.CRM_SYNC + t.SET,
  t.COMPANY_SETTINGS + t.CRM_SYNC + t.SET,
  t.COMPANY_SETTINGS + t.CRM_SYNC + t.USERS + t.GET,
  t.COMPANY_SETTINGS + t.CRM_SYNC + t.USER + t.SET,
  t.COMPANY_SETTINGS + t.CRM_SYNC + t.USER + t.TEST,
  t.COMPANY_SETTINGS + t.CRM_SYNC + t.TABLE + t.SET,
  t.COMPANY_SETTINGS + t.CRM_SYNC + t.FREQUENCY_TIME + t.SET,
  t.COMPANY_SETTINGS + t.FORECAST_CATEGORY + t.GET,
  t.COMPANY_SETTINGS + t.GET,
  t.COMPANY_SETTINGS + t.GET + t.ACTIVE,
  t.COMPANY_SETTINGS + t.UPDATE + t.ACTIVE,
  t.COMPANY_SETTINGS + t.MANAGERS + t.GET,
  t.COMPANY_SETTINGS + t.MANAGERS_TEAM + t.GET,
  t.COMPANY_SETTINGS + t.UNMAPPED_USERS + t.CREATE,
  t.COMPANY_SETTINGS + t.UNMAPPED_USERS + t.GET,
  t.COMPANY_SETTINGS + t.UPDATE,
  t.COMPANY_SETTINGS + t.USER + t.GET,
  t.COMPANY_SETTINGS + t.USER + t.SYNC,
  t.COMPANY_SETTINGS + t.USER + t.UPDATE,
  t.COMPANY_SETTINGS + t.USERS + t.GET,
  t.COMPANY_SETTINGS + t.USERS + t.UPDATE,
  t.CUSTOM_VIEWS + t.CREATE,
  t.CUSTOM_VIEWS + t.UPDATE,
  t.CUSTOM_VIEWS + t.REMOVE,
  t.CUSTOM_VIEWS + t.RENAME,
  t.CUSTOM_VIEWS + t.PIN,
  t.DEAL + t.DEALTOPICS + t.GET,
  t.DEAL + t.DETAIL + t.UPDATE,
  t.DEAL + t.ENGAGEMENT + t.GET,
  t.DEAL + t.PROSPECT + t.REMOVE,
  t.DEAL + t.PROSPECT + t.UPDATE,
  t.DEAL_STAGE + t.GET,
  t.DEALS + t.DESCRIBE,
  t.DEALS + t.GET + t.DETAIL,
  t.DEALS + t.UPDATE,
  t.DEALS + t.UPDATE + t.MEDDIC,
  t.DEAL + t.SYNC,
  t.EMAIL + t.DETAIL + t.GET,
  t.EMAIL + t.GET,
  t.EMAIL + t.PAGINATION,
  t.EVENT + t.GET,
  t.EVENTS + t.GET,
  t.FIELD_CONFIGURATION + t.DETAIL + t.ADD,
  t.FIELD_CONFIGURATION + t.DETAIL + t.GET,
  t.FIELD_CONFIGURATION + t.DETAIL + t.REMOVE,
  t.FIELD_CONFIGURATION + t.DETAIL + t.UPDATE,
  t.FIELD_CONFIGURATION + t.GET,
  t.FIELD_CONFIGURATION_TABLES + t.DETAIL + t.GET,
  t.FIELD_CONFIGURATION_TABLES + t.DETAIL + t.ADD,
  t.FIELD_CONFIGURATION_TABLES + t.DETAIL + t.REMOVE,
  t.FIELD_CONFIGURATION_TABLES + t.DETAIL + t.UPDATE,
  t.FORECAST_SUBMISSION_CREATE,
  t.FORECAST_USER_SUBMISSION_HISTORY,
  t.FORECAST + t.PACE_QUARTERLY + t.GET,
  t.FORECAST + t.QUOTA_ATTAINMENT + t.GET,
  t.INIT,
  t.LOGIN + t.OAUTH,
  t.LOGIN + t.SSO,
  t.NEW_PIPELINE_CREATED + t.GET,
  t.ROUTER_CONFIG + t.GET,
  t.PIPELINE_CREATED_BY_CLOSE_DATE + t.GET,
  t.SALESFORCE + t.OAUTH,
  t.SALESFORCE_SCHEMA + t.GET,
  t.SEARCH + t.RESULT + t.GET,
  t.SEARCH + t.RESULT + t.PAGINATION,
  t.SEARCH + t.SUGGESTION + t.GET,
  t.SETTINGS + t.GET,
  t.SETTINGS + t.SALES_PROCESS + t.ADD,
  t.SETTINGS + t.SALES_PROCESS + t.UPDATE,
  t.SETTINGS + t.SALES_PROCESS + t.REMOVE,
  t.SETTINGS + t.SALES_PROCESS + t.GET,
  t.SETTINGS + t.SALES_CONFIG + t.GET,
  t.SETTINGS + t.UPDATE,
  t.SETTINGS_USERS + t.ALL + t.GET,
  t.SETTINGS_USERS + t.GET,
  t.SETTINGS_USERS + t.REMOVE,
  t.SETTINGS_USERS + t.UPDATE,
  t.SETTINGS + t.API + t.TOKENS + t.GET,
  t.SETTINGS + t.API + t.TOKENS + t.UPDATE,
  t.SETTINGS + t.API + t.TOKENS + t.REMOVE,
  t.SETTINGS + t.API + t.SERVICES + t.GET,
  t.SIGN_UP + t.OAUTH,
  t.SUGGESTED_TEAM_MEMBERS + t.GET,
  t.TABLE_CONFIGURATION + t.DETAIL + t.GET,
  t.TABLE_CONFIGURATION + t.DETAIL + t.UPDATE,
  t.TABLE_CONFIGURATION + t.TABLE + t.GET,
  t.TEAM_MEMBERS + t.GET,
  t.TEAM_MEMBERS + t.UPDATE,
  t.TOPICS + t.GET,
  t.TOPICS + t.REMOVE,
  t.TOPICS + t.UPDATE,
  t.TRANSCRIPT + t.GET,
  t.USER + t.PROFILE + t.CREATE,
  t.USER + t.PROFILE + t.DETAIL + t.GET,
  t.USER + t.PROFILE + t.UPDATE,
  t.USER + t.PROFILES + t.ALL + t.GET,
  t.PIPELINE_STAGES + t.GET,
  t.CACHE + t.CLEAR_FILTERS,
  t.COMPANY_SETTINGS + t.CRM_SYNC + t.GET + t.CONDITIONS,
  t.BUSINESS_TYPES_CONFIG + t.GET,
  t.SETTINGS + t.USER + t.PROFILE + t.DISPLAY + t.CONFIGURATION,
  t.SETTINGS + t.ACTIVITY_MATCHING + t.SORTING_KEYS + t.GET,
  t.SETTINGS + t.ACTIVITY_MATCHING + t.SORTING_KEYS + t.SUBMIT,
  t.SETTINGS + t.ACTIVITY_MATCHING + t.FIELDS_INFO + t.GET,
  t.SETTINGS + t.ACTIVITY_MATCHING + t.FILTER_POLICY + t.SUBMIT,
  t.FORECAST_SETTING_HISTORY_GET,
  t.FORECAST_SUBMISSIONS_BY_PERIOD_TO_COPY_GET,
  t.FORECAST_SETTINGS_AVAILABLE_GET,
  t.FORECAST_SETTING_DEALS_GET,
  forecastSubmissionsCreateSuccessAction.type,
  ...mappingTakeEveryRevBISaga,
];

export function* proxySaga(action) {
  if (typeof action.saga === 'function') {
    yield action.saga(action);
  }
}

export default function* rootSaga() {
  const channel = yield actionChannel(mappingTakeEvery, buffers.sliding(50));

  yield takeEvery(mappingTakeEvery, proxySaga);

  const actions = yield flush(channel);
  for (let i = 0; i < actions.length; i++) {
    yield proxySaga(actions[i]);
  }

  yield fork(activitySaga);
  yield fork(callsSaga);
  yield fork(callsStatsSaga);
  yield fork(dealsSaga);
  yield fork(filtersSaga);
  yield fork(initSaga);
  yield fork(loginSaga);
  yield fork(manageForecastSaga);
  yield fork(preferencesSaga);
  yield fork(routeSaga);
  yield fork(sellerTargetsSaga);
  yield fork(settingsSaga);
  yield fork(signUpSaga);
  yield fork(watchSubmissionCreation);
}
