import styled from '@emotion/styled';

import { mediaQueries } from 'assets/css/common';

export const Container = styled.div`
  label: container;

  margin: 0 auto;
  padding: 25px 15px;
  font-family: var(--bu-font-regular);

  ${mediaQueries.mobile} {
    width: 750px;
  }

  ${mediaQueries.tablet} {
    width: 970px;
  }

  ${mediaQueries.desktop} {
    width: 1182px;
  }
`;
