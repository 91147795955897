import React from 'react';
import { connect } from 'react-redux';

import { translateUserRoleToFilter } from 'common/helpers';
import { IPropsNew } from 'components/UI/FilterPanels/types';
import { FilterPanelItem } from 'components/UI/common/Filter/FilterPanel';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

type ReduxStateProps = ReturnType<typeof stateToProps>;
type Props = ReduxStateProps & IPropsNew;

const PanelUserRole: React.FC<Props> = ({ config, onChange, userRoles }) => (
  <FilterPanelItem
    panel={{
      children: translateUserRoleToFilter(userRoles),
      label: 'User Role',
      value: 'user_role',
      search: true,
    }}
    checked={
      config && config.values.filter((e) => e.checked).map((e) => e.value)
    }
    onChange={(values) =>
      onChange(
        config.values.map((item) => ({
          id: item.value,
          checked: values.includes(item.value),
        }))
      )
    }
  />
);

const stateToProps = (state: IReduxState) => ({
  userRoles: selectors.getUserRolesForFilter(state),
});

export default connect(stateToProps)(PanelUserRole);
