import * as styles from './styles';
import React from 'react';

interface BadgeProps {
  value: string;
}

const BadgeTitle: React.FC<BadgeProps> = ({ value }) => (
  <span className={styles.wrapper}>{value}</span>
);

export default BadgeTitle;
