import { BoostUpIcons } from 'assets/css/boostup-icons';
import { fontSubtitle } from 'assets/css/common';
import useVerifySalesforceLinking from 'components/hooks/useVerifySalesforceLinking';
import { SettingsView } from 'components/settings/Settings/styles';
import BuConfirmationPopup from 'components/UI/BuConfirmationPopup';
import BuIcon from 'components/UI/BuIcon';
import { errorContainer } from './styles';
import { impersonationAlert } from './VerifySalesforceConnection.styles';

export const VerifySalesforceConnection = () => {
  const {
    showConnectSalesforceModal,
    linkAccountDisabled,
    isImpersonatingUser,
    username,
    errorMessage,
    skipConnection,
    authSalesforce,
  } = useVerifySalesforceLinking();

  return (
    <BuConfirmationPopup
      headerText="Link your Salesforce account!"
      isOpen={showConnectSalesforceModal}
      onClose={skipConnection}
      onConfirm={authSalesforce}
      cancelText="I Will Link Later"
      confirmText="Link Now"
      closeOnDimmerClick={false}
      closeOnEscape={false}
      disableConfirm={linkAccountDisabled}
    >
      <p>
        Your salesforce account is not linked with BoostUp. Until your account
        is linked you will not be able to update opportunities and other data in
        BoostUp.
      </p>

      {isImpersonatingUser && (
        <SettingsView.ImpersonationDisclaimer className={impersonationAlert}>
          <BuIcon
            name={BoostUpIcons.BadgeInfoSolid}
            color="var(--bu-orange-500)"
          />

          {linkAccountDisabled ? (
            <>
              You are impersonating{' '}
              <span className={fontSubtitle}>{username}</span>. You cannot edit
              these settings when you are in impersonation mode.
            </>
          ) : (
            <>
              Editing these settings will set your own credentials on the user
              you're impersonating
            </>
          )}
        </SettingsView.ImpersonationDisclaimer>
      )}

      {errorMessage && <div className={errorContainer}> {errorMessage} </div>}
    </BuConfirmationPopup>
  );
};

export default VerifySalesforceConnection;
