import { css } from 'emotion';

export const container = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 100%;
  max-height: 512px;
  background: white;
  align-items: center;
  padding: 48px 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.15);
`;

export const titleContainer = css`
  font-weight: bolder;
  font-size: 18px;
`;
