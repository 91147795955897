import className from 'classnames';
import React from 'react';

import BuImpersonationPanel from 'components/UI/BuImpersonationPanel';
import Header from 'components/UI/Wrapper/Header';
import LeftPanel from 'components/UI/Wrapper/LeftPanel';
import * as s from 'components/UI/Wrapper/styles';

const Wrapper: React.FC = ({ children }) => {
  return (
    <div className={className('main-wrapper', s.wrapper)}>
      <div className={s.content}>
        <LeftPanel />

        <Header />

        <BuImpersonationPanel />
        <div id="main_content" className={s.content_box}>
          {children}
        </div>

      </div>
    </div>
  );
};

export default Wrapper;
