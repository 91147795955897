import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import ForecastDashboard from 'components/dashboard/ForecastDashboard/ForecastDashboard';
import { StateProps } from 'components/dashboard/ForecastDashboard/types';
import { IReduxState } from 'reducers/types';
import {
  getFeatureFlags,
  getFiltersForAPI,
  getSelectedBusinessType,
  isBusinessTypesConfigReady,
} from 'selectors';

const mapStateToProps = (
  state: IReduxState,
  ownProps: RouteComponentProps
): StateProps => ({
  selectedBusinessType: getSelectedBusinessType(state, 'forecast_analytics'),
  filters: getFiltersForAPI(state, 'forecast_analytics'),
  tabPartition:
    ownProps.location.pathname === '/dashboard/forecast'
      ? 'dashboards'
      : 'forecast',
  isQuotaAttainmentChartEnabled:
    getFeatureFlags(state)?.quota_attainment_chart_enabled,
  isRequiredDataReady: isBusinessTypesConfigReady(state),
});

export default connect(mapStateToProps)(ForecastDashboard);
