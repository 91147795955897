import styled from '@emotion/styled';
import { css } from 'emotion';

export const FlexColumn = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});

export const FlexGrowContainer = styled.div({
  flexGrow: 1,
});

export const FixedWidthContainer = styled.div((props: { width: number }) => ({
  width: `${props.width}px`,
  flexGrow: 0,
  flexShrink: 0,
}));

export const FixedWidthContainerWithMargin = styled.div(
  (props: { width: number; marginRight: number }) => ({
    width: `${props.width}px`,
    flexGrow: 0,
    flexShrink: 0,
    marginRight: `${props.marginRight}px`,
  })
);

export const ConditionRow = css`
  display: flex;
  align-items: baseline;
`;
