import * as R from 'ramda';
import { combineReducers } from 'redux';

import * as t from 'actions/actionTypes';

const query = (state = '', action) => {
  switch (action.type) {
    case t.SEARCH + t.QUERY + t.CHANGE:
      return action.payload;
    default:
      return state;
  }
};

const loadingSuggestions = (state = false, action) => {
  switch (action.type) {
    case t.SEARCH + t.QUERY + t.CHANGE:
      return false;
    case t.SEARCH + t.SUGGESTION + t.GET + t.LOADING:
      return true;
    case t.SEARCH + t.SUGGESTION + t.GET + t.SUCCESS:
      return false;
    case t.SEARCH + t.SUGGESTION + t.GET + t.ERROR:
      return false;
    default:
      return state;
  }
};

const defaultSuggestions = {};
const suggestions = (state = defaultSuggestions, action) => {
  switch (action.type) {
    case t.SEARCH + t.SUGGESTION + t.GET + t.SUCCESS:
      return action.payload.results;
    case t.SEARCH + t.SUGGESTION + t.GET + t.ERROR:
      return defaultSuggestions;
    default:
      return state;
  }
};

const defaultSuggestionsPagination = {};
const suggestionsPagination = (
  state = defaultSuggestionsPagination,
  action
) => {
  switch (action.type) {
    case t.SEARCH + t.SUGGESTION + t.GET + t.SUCCESS:
      return action.payload.pagination;
    case t.SEARCH + t.RESULT + t.GET + t.ERROR:
      return defaultPagination;
    default:
      return state;
  }
};

const loadingResults = (state = false, action) => {
  switch (action.type) {
    case t.SEARCH + t.RESULT + t.GET:
      return true;
    case t.SEARCH + t.RESULT + t.GET + t.SUCCESS:
    case t.SEARCH + t.RESULT + t.GET + t.ERROR:
      return false;
    default:
      return state;
  }
};

const defaultResults = {};
const results = (state = defaultResults, action) => {
  switch (action.type) {
    case t.SEARCH + t.RESULT + t.GET + t.SUCCESS:
      return action.payload.results;
    case t.SEARCH + t.RESULT + t.PAGINATION + t.SUCCESS:
      return R.mergeWith(R.concat, R.__, action.payload.results)(state);
    case t.SEARCH + t.RESULT + t.GET + t.ERROR:
      return defaultResults;
    default:
      return state;
  }
};

const defaultPagination = {};
const pagination = (state = defaultPagination, action) => {
  switch (action.type) {
    case t.SEARCH + t.RESULT + t.GET + t.SUCCESS:
    case t.SEARCH + t.RESULT + t.PAGINATION + t.SUCCESS:
      return R.mergeDeepLeft(action.payload.pagination)(state);
    case t.SEARCH + t.RESULT + t.GET + t.ERROR:
      return defaultPagination;
    default:
      return state;
  }
};

export default combineReducers({
  query,
  loadingSuggestions,
  suggestions,
  suggestionsPagination,
  loadingResults,
  results,
  pagination,
});
