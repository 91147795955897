import { RowField } from 'components/settings/FieldConfiguration/FieldConfigurationItem';
import { Field, FieldType } from 'components/settings/FieldConfiguration/types';

export enum RangeType {
  'min' = 'min',
  'max' = 'max',
}

export type StateConfiguration = {
  type: FieldType;
  custom_filter_settings: Field['custom_filter_settings'];
};

export type PropsConfiguration = {
  fieldInputs: RowField;
  setFieldInputs: (value: RowField) => void;
  fieldConfigurationTitle: string;
};

export type Props = {
  fieldInputs: RowField;
  setFieldInputs: (value: RowField) => void;
  fieldConfigurationTitle: string;
  stateFilter: {
    type: FieldType;
    custom_filter_settings: Field['custom_filter_settings'];
  };
};

export type StateCustom = {
  value: string | boolean;
  display_name: string;
};

export type PropsControllers = {
  isChecked: boolean;
  onChange: () => void;
  labelGenerate?: string;
  labelCustom?: string;
};

interface OptionTabs {
  label: TypeTabsDate;
  active: boolean;
  checked: boolean;
}

export type OptionsResponse = {
  data: {
    choices: { value: string; display_name: string }[];
    crm_field: string;
    max: number;
    min: number;
  };
};

export type TypeTabs = Record<TypeTabsDate, OptionTabs>;

export type TypeTabsDate = 'Predefined' | 'Custom Range';
