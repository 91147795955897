import classNames from 'classnames';
import React, { useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, Popup, Icon } from 'semantic-ui-react';

import BuButton, { BuControlSize } from 'components/UI/BuButton';
import { DropdownNormal } from 'components/UI/OpenFiltersPanel/Dropdowns/Normal';
import {
  formatFilterProps,
  getUniqueListBy,
  handleCapitalizePhrase,
  custom_filters,
  isDefaultDropdownOption,
} from 'components/settings/ForecastSettings/AddForecastSubmission/helper';
import * as styles from 'components/settings/ForecastSettings/AddForecastSubmission/styles';
import {
  FilterProps,
  Filter,
  DealFilterDropdownOptionsProps,
} from 'components/settings/ForecastSettings/AddForecastSubmission/types';

type IProps = {
  filtersList: FilterProps[];
  filters: Record<string, any>;
  onPersist: (name: string, filters: Record<string, any>) => void;
  onRemoveFilterOptions: (name: string) => void;
  availableFilters?: any; // check this type
  globalDefaults?: any; // check this type
  onDropdownChange: (filters: Record<string, any>) => void;
  onResetToDefaults: () => void;
  hasAddFiltersButton?: boolean;
  hasRemoveFilterButton?: boolean;
  disableRemoveButtonList?: string[];
  dropdownTab: string;
};

export const FiltersFieldSettings: React.FC<IProps> = ({
  filtersList = [],
  filters = {},
  onPersist,
  onRemoveFilterOptions,
  availableFilters: _availableFilters,
  globalDefaults: _globalDefaults,
  onDropdownChange,
  onResetToDefaults,
  hasAddFiltersButton = false,
  hasRemoveFilterButton = false,
  disableRemoveButtonList = [],
  dropdownTab,
}) => {
  const openFiltersSelector = useSelector(
    ({ openFilters }: any) => openFilters
  );

  const [dropdownValue, setDropdownValue] = useState<string[]>([]);

  const availableFilters = useMemo(
    () =>
      _availableFilters ||
      openFiltersSelector?.settings.available?.forecast_opportunities
        ?.available_filters,
    [_availableFilters, openFiltersSelector]
  );
  const globalDefaults = useMemo(
    () => _globalDefaults || openFiltersSelector?.settings.globalDefaults,
    [_globalDefaults, openFiltersSelector]
  );

  const dropDownFiltersOptions = useMemo(() => {
    return availableFilters
      ?.map((availableFilter: string) => ({
        text: handleCapitalizePhrase(availableFilter),
        value: availableFilter,
      }))
      .filter(
        (dealFilterDropdownOption: DealFilterDropdownOptionsProps) =>
          !filtersList.find(
            (dealFilterOption) =>
              dealFilterOption.name == dealFilterDropdownOption.value
          )
      );
  }, [availableFilters, filtersList]);

  const isAddButtonActive = dropDownFiltersOptions?.length > 0;

  /*
   * this function persist the changes into filter dropdown
   * in order to be send when the form is saved.
   */
  const persistFilter = (name: string, changes: Filters.PersistValue[]) => {
    const filter = filters[name] as Filter;
    const { values: oldValues } = filter;

    const newFilterValues = oldValues.map((option) => {
      const changed = changes.find(
        (change) => String(change.id) === String(option.value)
      );

      return changed ? { ...option, checked: changed.checked } : { ...option };
    }) as any;

    const checkedOptions = newFilterValues.filter(
      (value: Filters.Checkbox) =>
        value.checked && !isDefaultDropdownOption(value)
    ).length;

    let areAllChecked = oldValues.length - 1 === checkedOptions;

    // checking if filter has no All option
    areAllChecked = !newFilterValues.some(
      (option: any) => option.value === '__all__'
    )
      ? false
      : areAllChecked;

    const allCheckedOption: Filters.PersistValue[] = newFilterValues.map(
      (value: Filters.Checkbox) => ({
        ...value,
        checked: isDefaultDropdownOption(value)
          ? areAllChecked
          : !areAllChecked && value.checked,
      })
    );

    onPersist(name, {
      ...filter,
      values: allCheckedOption,
      isChange: true,
    });
  };

  const dropDownFilterValues = useMemo(() => {
    return Object.entries(globalDefaults).map(([key, value]) => ({
      filter: value,
      name: key,
    }));
  }, [globalDefaults]);

  const handleDropdownChange = useCallback((e, { value: _value }) => {
    /**
     * When in Forecast Settings we need to load a different filter config
     * from the filters list, but still using the other info from the
     * main filter (e.g. default values, labels)
     */
    const value = _value.includes('close_date_interval')
      ? ['forecast_close_date_interval']
      : _value;
    const selectedFilters = dropDownFilterValues.filter((item) =>
      value.includes(item.name)
    ) as FilterProps[];

    const option: Record<string, any> = formatFilterProps(selectedFilters);

    for (let i = 0; i < value.length; i++) {
      const newOption = {
        ...option[value[i]],
        values: option[value[i]].values.map((el: Filters.Checkbox) =>
          el.checked && el.value === '__all__' ? el : { ...el, checked: false }
        ),
      };
      option[value[i]] = newOption;
    }

    onDropdownChange(option);
  }, []);

  const _onRemoveFilterOptions = (name: string) => {
    setDropdownValue(dropdownValue.filter((item) => item !== name));
    onRemoveFilterOptions(name);
  };

  const _onResetToDefaults = () => {
    setDropdownValue([]);
    onResetToDefaults();
  };

  return (
    <>
      <div className={styles.filterWrapper}>
        {filtersList?.map(({ name, filter }) => {
          if (filter.title === 'Users') {
            filter['values'] = getUniqueListBy(filter.values, 'value');
          }
          return (
            !filter.isHidden && (
              <div key={name} style={{ display: 'flex' }}>
                <DropdownNormal
                  setNormalPanel
                  tab={dropdownTab}
                  name={name}
                  config={filter}
                  onChange={(change: Filters.PersistValue[]) =>
                    persistFilter(name, change)
                  }
                />
                {hasRemoveFilterButton && (
                  <div
                    className={classNames(
                      styles.actionTrashIconContainerStyle,
                      styles.withBorder
                    )}
                  >
                    <Icon
                      className={styles.addPointer}
                      name="trash alternate outline"
                      disabled={disableRemoveButtonList.includes(name)}
                      onClick={() => _onRemoveFilterOptions(name)}
                    />
                  </div>
                )}
              </div>
            )
          );
        })}
      </div>

      <label className={styles.label} />

      <div className={styles.buttons_container}>
        {hasAddFiltersButton && (
          <Popup
            content={
              isAddButtonActive ? null : 'There are no more filters to be added'
            }
            disabled={isAddButtonActive}
            on="hover"
            trigger={
              isAddButtonActive ? (
                <Dropdown
                  basic
                  compact
                  className={classNames(styles.dropdownStyle, {
                    [styles.emptyOptions]:
                      dropDownFiltersOptions.length === dropdownValue.length,
                  })}
                  closeOnChange
                  multiple
                  onChange={handleDropdownChange}
                  options={dropDownFiltersOptions}
                  selection
                  text="Add filters"
                  value={dropdownValue}
                />
              ) : (
                <div
                  className={classNames(
                    styles.dropdownStyle,
                    styles.dropdownStubStyle
                  )}
                >
                  Add filters
                </div>
              )
            }
          />
        )}

        <BuButton
          secondary
          size={BuControlSize.REGULAR}
          className={styles.buttonResetStyle}
          onClick={_onResetToDefaults}
        >
          Reset to defaults
        </BuButton>
      </div>
    </>
  );
};
