import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';

import FullScreen from 'assets/images/new_icon/full_screen_icon.svg';
import * as s from 'components/dashboard/ProspectAccountDashboard/WidgetPie/styles';

type IProps = {
  title: string;
  centerTitle?: string;
  numberAccounts?: number;
  data: {
    data: {
      color: string;
      name: string;
      y: number;
    }[];
    name: string;
  }[];
};

const WidgetPie: React.FC<IProps> = ({
  title,
  data,
  centerTitle,
  numberAccounts,
}) => {
  const [fullScreen, setFullScreen] = useState(false);

  const centerText = numberAccounts
    ? `${numberAccounts} accounts`
    : (centerTitle && centerTitle.split(' ').join('<br />')) ||
      'no data provided';

  function getConfig(viewType?: 'modal' | 'otherType') {
    const viewConfig =
      viewType === 'modal'
        ? {
            titleFontSize: '20px',
            height: 450,
          }
        : {
            titleFontSize: '14px',
            height: 250,
          };

    return {
      chart: {
        type: 'pie',
        height: viewConfig.height,
      },
      title: {
        text: `<div class=${s.customTitle}>${centerText}</div>`,
        style: { fontSize: viewConfig.titleFontSize },
        useHTML: true,
        align: 'center',
        verticalAlign: 'middle',
        y: -17,
        x: -1,
      },
      legend: {
        layout: 'vertical',
        itemMarginBottom: 5,
        enabled: !!numberAccounts,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
      series: data,
    };
  }

  return (
    <>
      <div className={s.header}>
        <div className={s.header_title}>{title}</div>
        <div className={s.header_btns}>
          <button onClick={() => setFullScreen(true)}>
            <img src={FullScreen} alt="full screen" />
          </button>
        </div>
      </div>

      <HighchartsReact
        highcharts={Highcharts}
        options={getConfig()}
        immutable={true}
      />

      <Modal
        open={fullScreen}
        size="large"
        closeIcon
        onClose={() => setFullScreen(false)}
      >
        <HighchartsReact
          highcharts={Highcharts}
          options={getConfig('modal')}
          immutable={true}
        />
      </Modal>
    </>
  );
};

export default WidgetPie;
