import styled from '@emotion/styled';

export const TimePicker = styled.div`
  border: 1px solid var(--bu-gray-500);
  color: var(--bu-gray-900);
  min-height: 30px;
  border-radius: 4px;
  line-height: 30px;

  &:focus {
    border-color: var(--bu-color-buying_team_email);
  }

  width: 100%;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  position: relative;
  display: flex;

  &.disabled {
    background-color: var(--bu-gray-200);
  }

  .date-button {
    display: flex;
    align-items: center;
    align-content: space-between;
    line-height: 27px;

    img {
      margin-right: 5px;
      margin-left: 5px;
      margin-top: -2px;
      height: 18px;
      width: 18px;

      &.disabled {
        filter: contrast(5%);
      }
    }
  }
`;

export const PickerContainer = styled.div`
  padding: 7px 15px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
`;

export const TimePickerPopup = styled.div`
  padding: 5px;
  position: absolute;
  top: 30px;
  display: block;
  max-width: 280px;
  border: 1px solid var(--bu-gray-300);
  z-index: 1;
  background: var(--bu-white);
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  border-bottom: 1px solid var(--bu-gray-400);
  margin-bottom: 5px;

  .arrow {
    cursor: pointer;
    margin: 10px;
    &:hover {
      fill: var(--bu-primary-500);
    }
  }

  .arrow-up {
    transform: rotate(-90deg);
  }

  .arrow-down {
    transform: rotate(90deg);
  }
`;

export const TimeRoll = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  user-select: none;
  max-height: 90px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const RollItem = styled.div`
  color: var(--bu-gray-700);

  &.selected {
    color: var(--bu-gray-900);
    font-weight: bold;
  }
`;
