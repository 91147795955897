import styled from '@emotion/styled';

export const StagesSection = styled.div({
  background: 'var(--bu-gray-100)',
  padding: '10px',
  borderRadius: '5px',
  display: 'flex',
  flexDirection: 'column',
});

export const StagesTitle = styled.div({
  fontWeight: 'bold',
  fontSize: '17px',
  paddingBottom: '5px',
});

export const StagesDescription = styled.div({
  color: 'var(--bu-gray-700)',
  fontSize: '12px',
  marginBottom: '10px',
});

export const StageValues = styled.div({
  marginTop: '15px',
});

export const AddStageButtonWrapper = styled.div({
  width: '130px',
  marginTop: '10px',
});

export const LoaderContainer = styled.div({
  position: 'relative',
  margin: '20px',
});

export const ItemsWrapper = styled.div({
  maxHeight: '200px',
  maxWidth: '400px',
});
