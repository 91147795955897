/* eslint-disable no-debugger */
import { combineReducers } from 'redux';

import * as t from 'actions/actionTypes';

const byId = (state = {}, { type, payload }) => {
  switch (type) {
    case t.DEAL + t.PROSPECT + t.UPDATE + t.SUCCESS: {
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          custom: 'sds',
          contacts: state[payload.id].contacts.map((el) => {
            if (el.email === payload.prospect) {
              el.sentiment = payload.data;
            }
            return el;
          }),
        },
      };
    }
    case t.DEAL + t.PROSPECT + t.REMOVE + t.SUCCESS: {
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          custom: 'sds',
          contacts: state[payload.id].contacts.map((el) => {
            if (el.email === payload.prospect) {
              el.sentiment = null;
            }
            return el;
          }),
        },
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  byId,
});
