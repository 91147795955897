import React from 'react';
import { useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import { formatMoney } from 'common/numbers';
import * as interfaces from 'common/props-interfaces';
import * as selectors from 'selectors';

const KPI = ({ data, status }) => {
  const companyCurrencyCode = useSelector(selectors.getUserLocalCurrency);

  return (
    <div className="statistics__other bu-font-default">
      <div className="statistics__other-box">
        <div className="statistics__other-box-item red">
          <span className="statistics__other-box-item-top">
            Deals At High Risk
          </span>
          <div className="statistics__other-box-item-bottom">
            {status === 'success' ? (
              <span data-cypress="kpi-revenue-amount">
                {data.count_high_risk}
              </span>
            ) : (
              <Loader active inline />
            )}
          </div>
        </div>
        <div className="statistics__other-box-item red">
          <span className="statistics__other-box-item-top">
            Revenue At High Risk
          </span>
          <div className="statistics__other-box-item-bottom">
            {status === 'success' ? (
              <span data-cypress="kpi-revenue-value">
                {`${formatMoney(
                  companyCurrencyCode,
                  data.amount_high_risk,
                  0
                )}`}
              </span>
            ) : (
              <Loader active inline />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

KPI.defaultProps = {
  data: {},
  status: 'notAsked',
};

KPI.propTypes = {
  data: interfaces.statTotal,
  status: interfaces.loadingStatus,
};

export default KPI;
