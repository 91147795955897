import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Loader } from 'semantic-ui-react';

import EventsTable from 'components/UI/EventsTable';
import { IProps } from 'components/modals/EventsModal/types';

const EventsModal: React.FC<IProps> = ({ modalProps }) => {
  if (isEmpty(modalProps)) {
    return <Loader active />;
  }

  return (
    <EventsTable
      embedded
      title={modalProps.title}
      apiUrl={modalProps.apiUrl}
      filters={JSON.parse(modalProps.filters || '{}')}
      persistName={`events_modal_${JSON.stringify(modalProps)}`}
    />
  );
};

export default EventsModal;
