import { css } from 'emotion';

import { fontSubtitle } from 'assets/css/common';

export const panel_wrapper = css`
  display: flex;
  flex-direction: column;
  position: relative;

  &.disabled {
    opacity: 0.4;
  }
`;

export const panel_dropdown_button = css`
  display: flex;
  position: relative;
  box-sizing: border-box;
  height: 32px;
  padding: 5px 8px;
  border: 1px solid var(--bu-gray-500);
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--bu-white);
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  transition: 0.4s all;
  color: var(--bu-gray-900);

  &.isOpen {
    border: 1px solid var(--bu-primary-500) !important;
  }

  &:hover {
    background: var(--bu-gray-400);
  }

  &.disabled {
    cursor: default;
  }
`;

export const panel_dropdown_name = css`
  ${fontSubtitle};
  margin-right: 5px;
`;

export const panel_span = css`
  color: var(--bu-gray-800);
  font-weight: 100;
`;

export const icon = css`
  font-size: 7px;
  margin-left: 5px;
`;

export const panel_dropdown = css`
  position: absolute;
  margin-top: 35px;
  flex-direction: column;
  border: 1px solid var(--bu-gray-400);
  overflow: hidden;
  min-width: 300px;
  width: max-content;
  border-radius: 4px;
  display: flex;
  z-index: 991;
  background: #fff;
  box-shadow: 0px 22px 24px var(--bu-gray-500);

  div {
    width: 100% !important;
  }
`;
