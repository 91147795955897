import React, { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { getUser } from 'selectors';
import { fetchApi } from 'utils/network';

const DEFAULT_USERS_BY_ACTIVITY: Filters.UserFilterElement[] = [];

const UsersByActivityContext = createContext(DEFAULT_USERS_BY_ACTIVITY);

type DealsRequestPayload = {
  email: string;
  include_disabled: boolean;
};

const UsersByActivityProvider = ({
  children,
  includeDisabledUsers,
}: {
  children: React.ReactNode;
  includeDisabledUsers: boolean;
}) => {
  const [usersByActivity, setUsersByActivity] = useState<
    Filters.UserFilterElement[]
  >([]);

  const user = useSelector(getUser);

  useEffect(() => {
    const abortController = new AbortController();

    fetchApi<DealsRequestPayload, any>({
      url: `${process.env.REACT_APP_BACKEND_URL}/api/data/filters/v2/deals`,
      queryMethod: 'post',
      queryParams: {
        email: user.email,
        include_disabled: includeDisabledUsers,
      },
      setData: (result) => {
        setUsersByActivity(result.data.users.fields);
      },
      setError: (error: string | null) => {
        setUsersByActivity(DEFAULT_USERS_BY_ACTIVITY);
        console.error(error);
        toast.error('Failed fetching users by activity');
      },
    });

    return () => {
      abortController.abort();
      setUsersByActivity(DEFAULT_USERS_BY_ACTIVITY);
    };
  }, [includeDisabledUsers]);

  return (
    <UsersByActivityContext.Provider value={usersByActivity}>
      {children}
    </UsersByActivityContext.Provider>
  );
};

export { UsersByActivityContext, UsersByActivityProvider };
