import React from 'react';

import { HierarchicalUserFilter } from 'components/dashboard/Metrics/common/HierarchicalUserFilter/HierarchicalUserFilter';

interface Props {
  values: string[];
  disabled?: boolean;
  updateFilters: (
    emptyFilters: boolean,
    operator: string,
    value: string | string[] | number
  ) => void;
}

export const UserTypeFilter: React.FC<Props> = ({
  values,
  updateFilters,
  disabled,
}) => {
  const handleChangeUserFilter = (
    filterValues: string[],
    checkedAll: boolean = false
  ) => {
    const noneChecked = !filterValues.length;
    updateFilters(checkedAll || noneChecked, 'in', filterValues);
  };

  return (
    <HierarchicalUserFilter
      disabled={disabled}
      currentValues={values}
      onChangeUserFilter={handleChangeUserFilter}
    />
  );
};
