import { actions } from '../../../../actions';
import classNames from 'classnames';
import { Html5Entities } from 'html-entities';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import MindtickleCallIcon from 'assets/fonts/boostup-icons/mindtickle_call_icon.svg';
import PlayImage from 'assets/fonts/boostup-icons/play.svg';
import { RESPONSE_STATUS_MAP } from 'common/constants';
import {
  IProps,
  IState,
  Attendee,
} from 'components/UI/MeetingAndCallsTable/Row/types';
import styles from 'components/UI/MeetingAndCallsTable/styles';
import TruncateText from 'components/UI/TruncatedText';
import Tooltip from 'components/UI/common/Tooltip';
import { Link, ModalLink } from 'navigation/Link';
import { openModal } from 'navigation/utils';
import { fetchApi } from 'utils/network';

const entities = new Html5Entities();

const R = require('ramda');

const responseStatusClassNameMap: { [index: string]: string } = {
  none: styles.attendeeNotResponded,
  notResponded: styles.attendeeNotResponded,
  needsAction: styles.attendeeNotResponded,
  declined: styles.attendeeDeclined,
  accepted: styles.attendeeAccepted,
  organizer: styles.attendeeAccepted,
};

export const emailBreak = (email: string) =>
  email
    .split(/(\w*?[\W])/)
    .filter((str) => Boolean(str))
    .map((breakCharacter: string) => (
      <>
        {breakCharacter}
        <wbr />
      </>
    ));

class MeetingsCallsRow extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      rowsCount: 4,
      pageCustomer: 1,
      pageCompany: 1,
      widthFirstColumn: null,
    };
  }

  componentDidMount() {
    const firstColumn = get(this, 'firstColumn.current.offsetWidth', 0);
    this.setState({
      widthFirstColumn: firstColumn,
    });
  }

  renderAttendees(attendees: Object[], customer: boolean) {
    const { rowsCount, pageCustomer, pageCompany } = this.state;
    const page = customer ? pageCustomer : pageCompany;

    const filterAttendeeType = customer ? R.filter : R.reject;
    const selectedAttendees = R.compose(
      filterAttendeeType(R.prop('is_customer'))
    )(attendees);

    const attendeesForRendering = R.compose(
      R.dropLast(
        selectedAttendees.length - page * rowsCount > 0
          ? selectedAttendees.length - page * rowsCount
          : 0
      )
    )(selectedAttendees);

    return (
      <Fragment>
        {attendeesForRendering.map((attendee: Attendee) => (
          <Fragment key={attendee.email}>
            <div className={styles.attendee_name}>
              <Tooltip tooltip={attendee.email || ''} position="bottom left">
                {attendee.display_name || emailBreak(attendee.email || '')}
              </Tooltip>
              <Fragment>
                &nbsp;
                <span
                  className={
                    responseStatusClassNameMap[attendee.response_status]
                  }
                >
                  {`(${RESPONSE_STATUS_MAP[attendee.response_status]})`}
                </span>
              </Fragment>
            </div>
            {customer && attendee.title && (
              <div className={styles.attendeeTitle}>
                {customer && attendee.title ? attendee.title : null}
              </div>
            )}
          </Fragment>
        ))}

        {rowsCount * page < selectedAttendees.length && (
          <div
            role="none"
            onClick={() => {
              customer
                ? this.setState({ pageCustomer: page + 1 })
                : this.setState({ pageCompany: page + 1 });
            }}
          >
            <button type="button" className={styles.attendee_btn_more}>
              {`+ ${selectedAttendees.length - rowsCount * page} More`}
            </button>
          </div>
        )}
      </Fragment>
    );
  }

  getTruncationCount() {
    const { widthFirstColumn } = this.state;
    if (widthFirstColumn === null) {
      return 0;
    }
    if (widthFirstColumn > 135) {
      return 20;
    }
    if (widthFirstColumn > 102 && widthFirstColumn <= 135) {
      return 17;
    }
    if (widthFirstColumn <= 102) {
      return 25;
    }
  }

  render() {
    const { data, objectId, persistModalParams } = this.props;

    const sortedAttendees = sortBy(data.attendees, ['response_status']);
    const callId = data && data.call_id ? data.call_id : 0;
    const { call_source, call_recording_url } = data;

    const handleRedirectToActivity = () => {
      return fetchApi({
        url: `${process.env.REACT_APP_BACKEND_URL}/api/data/events/call_ai/${callId}`,
        queryMethod: 'get',
        setData(response) {
          if (response) {
            const activity = (response as any).activities[0];

            openModal({
              scheme: '/activity/:activityId',
              params: {
                activityId: `${objectId}_${activity.record_id}`,
              },
              persistParams: {
                activity: response,
              },
              persistor: persistModalParams,
            });
          }
        },
        setError() {
          const win = window.open(call_recording_url, '_blank');
          win?.focus();
          return null;
        },
      });
    };

    const playCell =
      call_source && call_source === 'Mindtickle' && call_recording_url ? (
        <button
          type="button"
          className={styles.play_call_btn}
          onClick={handleRedirectToActivity}
        >
          <img
            className={styles.play_call_icon}
            src={MindtickleCallIcon}
            alt="play"
          />
        </button>
      ) : (
        <Link
          key={callId}
          url={{ scheme: '/transcript/:id', params: { id: callId } }}
        >
          <button type="button" className={styles.play_call_btn}>
            <img className={styles.play_call_icon} src={PlayImage} alt="play" />
          </button>
        </Link>
      );

    return (
      <tr className={styles.table_body_row}>
        <td className={styles.table_row_colum}>
          <ModalLink
            url={{
              scheme: '/event/:eventId',
              params: { eventId: data.id },
            }}
            className={classNames(styles.cursor, styles.primaryCell)}
          >
            <TruncateText
              text={data.summary}
              length={this.getTruncationCount()}
            />
          </ModalLink>
          <br />
          <span className={styles.date_call}>
            {moment(data.start).format('DD MMM YYYY, h:mm A')}
          </span>
        </td>
        <td className={styles.table_row_colum}>
          <div className={styles.attendees}>
            {data && data.attendees
              ? this.renderAttendees(sortedAttendees, false)
              : null}
          </div>
        </td>
        <td className={styles.table_row_colum}>
          <div className={styles.attendees}>
            {data && data.attendees
              ? this.renderAttendees(sortedAttendees, true)
              : null}
          </div>
        </td>
        <td className={styles.table_row_colum}>
          {!data.is_call || data.status === 'skipped' ? (
            <span>Call Not Available</span>
          ) : null}
          {data.is_call && data.status === 'scheduled' ? (
            <span>Call Not Recorded</span>
          ) : null}
          {data.is_call && data.status === 'recorded' ? (
            <span>Call Being Processed</span>
          ) : null}
          {data.is_call && data.no_show ? (
            <span className={styles.no_show}>Call No Show</span>
          ) : null}
          {data.is_call && data.status === 'summarized' && !data.no_show
            ? playCell
            : null}
        </td>
        <td className={styles.table_row_colum}>
          {data.follow_up_email != null ? (
            <>
              <div className={styles.MeetingFollowUpEmail}>
                {entities.decode(data.follow_up_email.summary) + '...'}
              </div>
              <ModalLink
                url={{
                  scheme: '/email/:emailId',
                  params: { emailId: data.follow_up_email!.id },
                }}
                className={styles.blue_link_more}
              >
                More
              </ModalLink>
            </>
          ) : null}
        </td>
      </tr>
    );
  }
}

const mapDispatchToProps = {
  persistModalParams: actions.ui.modal.persist,
};

export default connect(null, mapDispatchToProps)(MeetingsCallsRow);
