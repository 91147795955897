import BuIcon from '../BuIcon';
import classNames from 'classnames';
import { css } from 'emotion';
import React from 'react';

import * as styles_old from 'components/UI/BuGroupButton/styles';
import * as styles_new from 'components/UI/BuGroupButton/styles_new';
import { IProps } from 'components/UI/BuGroupButton/types';
import Tooltip from 'components/UI/common/Tooltip';

const COMPONENT_TESTING_NAME = 'GroupButton';

const customWrapper = css`
  &.ui.inverted.popup {
    padding: 5px 8px;
    font-size: 12px;
  }
`;

const BuGroupButton: React.FC<IProps> = ({
  options,
  selectedOption,
  className,
  onSelect,
  disabled,
  testingLabel = '',
  itemClassName,
  useRevampStyle = false,
}) => {
  const componentDataTestingKey = testingLabel
    ? `${testingLabel}-${COMPONENT_TESTING_NAME}`
    : COMPONENT_TESTING_NAME;

  // TODO: REMOVE AFTER APPLY REVAMP STYLE TO EVERY PLACE USES THIS COMPONENT
  const styles = useRevampStyle ? styles_new : styles_old;

  return (
    <div
      data-testing={componentDataTestingKey}
      className={classNames(styles.optionsContainer, className)}
    >
      {options.map((option, i) => (
        <div
          role="presentation"
          data-testing={`${componentDataTestingKey}-Option-${
            option.text?.replaceAll(' ', '_') ?? i
          }`}
          onClick={() => !disabled && onSelect(option.id)}
          key={option.id}
          className={classNames(
            'option-group',
            itemClassName,
            styles.itemContainer,
            {
              [styles.selectedOption]: selectedOption === option.id,
              disabled: option.disabled || disabled,
              'not-icon-button': !option.iconName,
            }
          )}
        >
          <Tooltip
            className={customWrapper}
            tooltip={option.text}
            position="top center"
            hoverable
          >
            <div className="option-group-item">
              {option.iconName ? (
                <BuIcon name={option.iconName} />
              ) : (
                option.text
              )}
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

export default BuGroupButton;
