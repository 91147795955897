import MappingObjectWidget from '../MappingObjectWidget/MappingObjectWidget';
import * as s from './styles';
import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  MappingOptions,
  ThirdPartyDatabase,
  ThirdPartySchema,
  ThirdPartyTable,
} from 'common/types/ThirdPartyIntegration';
import BuButton from 'components/UI/BuButton';
import BuInput from 'components/UI/BuInput';
import { ModalRouteProps } from 'navigation/types';
import { IReduxState } from 'reducers/types';
import { getModalProps } from 'selectors';

const EditThirdPartyIntegrationModal: React.FC<ModalRouteProps> = ({
  onClose,
}) => {
  const persistedParams = useSelector((state: IReduxState) =>
    getModalProps<{
      table: ThirdPartyTable;
      name: string;
      type: string;
      display_name: string;
      link: string;
      mappings: MappingOptions;
      database: ThirdPartyDatabase;
      schema: ThirdPartySchema;
    }>(state, '/settings/data-integration/edit')
  );
  const { table, name, type, display_name, link, mappings, database, schema } =
    persistedParams;
  const [displayName, setDisplayName] = useState(display_name);
  const [linkedTo, setLinkedTo] = useState(
    table.columns.find((el) => el.name === name)?.linked_to
  );

  const handleSave = useCallback(() => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/third_party_integration/snowflake/table`,
        {
          shared_db_name: database.name,
          schema_name: schema.name,
          table_name: table.name,
          columns: table.columns.map((el) =>
            el.name === name
              ? {
                  ...el,
                  display_name: displayName,
                  linked_to: linkedTo,
                }
              : el
          ),
        }
      )
      .then(() => {
        handleClose();
      });
  }, [linkedTo, displayName]);

  const handleClose = () => {
    if (!!onClose) {
      onClose();
    }
  };
  return (
    <div className={s.container}>
      <div className={s.header}>Edit {table.name} field</div>
      <div className={s.mainContent}>
        <div className="row">
          <span className="lab">Field Name:</span>
          <span className="name">{name}</span>
        </div>
        <div className="row">
          <span className="lab">Type:</span>
          <span>{type}</span>
        </div>
        <div className="row">
          <span className="lab">Display Name:</span>
          <BuInput
            className={s.inputWidth}
            value={displayName}
            onChange={(event) => setDisplayName(event.target.value)}
          ></BuInput>
        </div>
        <div className="row">
          <span className="lab">Link To:</span>
          <MappingObjectWidget
            mappings={mappings}
            className={s.inputWidth}
            selected={linkedTo}
            onChange={(el) => setLinkedTo(el)}
          >
            {link}
          </MappingObjectWidget>
        </div>
      </div>
      <div className={s.footer}>
        <BuButton secondary onClick={handleClose}>
          Cancel
        </BuButton>
        <BuButton onClick={handleSave}>Save</BuButton>
      </div>
    </div>
  );
};

export default EditThirdPartyIntegrationModal;
