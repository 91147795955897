import React, { createContext, useEffect, useState } from 'react';

import { Partition } from 'navigation/tabs';

export interface HeaderContextValue {
  partition: Partition | '';
  setPartition: (partition: Partition | '') => void;
  isSubmitForecastPanelOpen: boolean;
  setIsSubmitForecastPanelOpen: (isSubmitForecastPanelOpen: boolean) => void;
  showSubmitForecastButton: boolean;
  setShowSubmitForecastButton: (showSubmitForecastButton: boolean) => void;
  showTabs: boolean;
  setShowTabs: (showTabs: boolean) => void;
  renderExtraTabs: React.ReactNode;
  setRenderExtraTabs: (renderExtraTabs: React.ReactNode) => void;
  clearContext: () => void;
}

export const HeaderContext = createContext<HeaderContextValue>({
  partition: 'opportunities',
  setPartition: (partition) => {},
  isSubmitForecastPanelOpen: false,
  setIsSubmitForecastPanelOpen: (isSubmitForecastPanelOpen) => {},
  showSubmitForecastButton: false,
  setShowSubmitForecastButton: (showSubmitForecastButton) => {},
  showTabs: true,
  setShowTabs: (showTabs) => {},
  renderExtraTabs: null,
  setRenderExtraTabs: (renderExtraTabs) => {},
  clearContext: () => {},
});

export const HeaderProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [partition, setPartition] = useState<Partition | ''>('');
  const [isSubmitForecastPanelOpen, setIsSubmitForecastPanelOpen] =
    useState(false);
  const [showSubmitForecastButton, setShowSubmitForecastButton] =
    useState(false);
  const [showTabs, setShowTabs] = useState(true);
  const [renderExtraTabs, setRenderExtraTabs] = useState<React.ReactNode>(null);

  const clearContext = () => {
    setIsSubmitForecastPanelOpen(false);
    setShowSubmitForecastButton(false);
    setShowTabs(true);
    setRenderExtraTabs(null);
    setPartition('');
  };

  return (
    <HeaderContext.Provider
      value={{
        partition,
        setPartition,
        isSubmitForecastPanelOpen,
        setIsSubmitForecastPanelOpen,
        showSubmitForecastButton,
        setShowSubmitForecastButton,
        showTabs,
        setShowTabs,
        clearContext,
        renderExtraTabs,
        setRenderExtraTabs,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeader = () =>
  React.useContext<HeaderContextValue>(HeaderContext);
