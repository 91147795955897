import { ModalsScheme, Scheme, URLScheme } from './types';
import { compile } from 'path-to-regexp';

export const makeURL = (options: URLScheme<ModalsScheme | Scheme>) => {
  const { scheme, params } = options;

  try {
    return scheme.includes(':') || params ? compile(scheme)(params) : scheme;
  } catch (error) {
    console.error('makeURL', options, error);
    return null;
  }
};

const addEndSlash = (path: string) =>
  path.substr(-1) === '/' ? path : path + '/';

export const makeModalPath = (pathname: string, next: string) => {
  if (pathname.substr(-next.length) === next) {
    return pathname;
  }

  return addEndSlash(pathname) + next;
};
