import { css } from 'emotion';
import React from 'react';

import TruncatedText from 'components/UI/TruncatedText/TruncatedText';
import { IDataCellProps } from 'components/UI/common/TypedTable/TypedTable';
import NotAvailableCell from 'components/UI/common/TypedTable/renderers/NotAvailableCell';

const contactNameCellContainerStyle = css`
  display: flex;
  flex-direction: column;
  padding-top: 6px;
  padding-bottom: 6px;
`;

const emailStyleStyle = css`
  color: '#4A4A4A';
  font-family: var(--bu-font-regular);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 6px;
`;

const nameStyle = css`
  color: var(--bu-gray-900);
  font-family: var(--bu-font-medium);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 6px;
`;

const titleStyle = css`
  color: #777777;
  font-family: var(--bu-font-regular);
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
`;

const ContactNameCell: React.FC<IDataCellProps> = ({
  column,
  row,
}: IDataCellProps) => {
  const { truncateTextFrom = 25 } = column.config;
  const { contact_email, contact_name, contact_title } = row;

  return contact_email || contact_name || contact_title ? (
    <div className={contactNameCellContainerStyle}>
      {contact_name && (
        <TruncatedText
          inverted
          length={truncateTextFrom}
          text={`${contact_name}`}
          textClassName={nameStyle}
        />
      )}
      {contact_email && (
        <TruncatedText
          inverted
          length={truncateTextFrom}
          text={`${contact_email}`}
          textClassName={emailStyleStyle}
        />
      )}
      {contact_title && (
        <TruncatedText
          inverted
          length={truncateTextFrom}
          text={`${contact_title}`}
          textClassName={titleStyle}
        />
      )}
    </div>
  ) : (
    <NotAvailableCell />
  );
};

export default ContactNameCell;
