import { css } from 'emotion';

export const viewSection = css`
  padding: 10px 20px;
`;

export const subSectionSkeleton = css`
  margin: 6px;
`;

export const accordionHeader = css`
  display: flex;
  align-items: center;
  font-weight: normal;
  color: var(--bu-gray-900);
  flex: 1;
  height: 30px;
  gap: 6px;

  *:hover > & {
    color: var(--bu-primary-500);
  }
`;

export const accordionActive = css`
  color: var(--bu-primary-500);
`;

export const accordionEnabledViews = css`
  color: var(--bu-gray-700);
`;

export const accordionIcon = css`
  font-size: 1.2em;
  margin-right: 5px;
  line-height: 1.2em;
`;

export const accordionTitle = css`
  margin: 0;
`;
