import styled from '@emotion/styled';

const BuDropdownItemGroup = styled.div`
  background-color: transparent;

  border-top: 1px solid var(--bu-gray-400);

  &:first-of-type {
    border-top: none;
  }
`;

export default BuDropdownItemGroup;
