import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { addWidget, removeWidget } from 'actions/revbi/metrics';
import { CommonList } from 'components/dashboard/Metrics/List/Common/CommonList';
import { parseWidget } from 'components/dashboard/Metrics/metrics.helpers';
import {
  BIWidget,
  SelectedItem,
} from 'components/dashboard/Metrics/metrics.types';
import { getWidgetsList, getWidgetsListStatus } from 'selectors/revbi/metrics';
import { fetchApi, QueryStatus } from 'utils/network';

export const WidgetsList: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cloneStatus, setCloneStatus] = useState<QueryStatus>('notAsked');

  const [selectedElement, setSelectedElement] = useState<SelectedItem>();

  const widgetsStatus = useSelector(getWidgetsListStatus);
  const widgetList = useSelector(getWidgetsList);

  const handleDeleteConfirm = () => {
    fetchApi<void, void>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/widgets/${selectedElement?.id}`,
      queryMethod: 'delete',
      setData: () => {
        dispatch(removeWidget({ id: selectedElement?.id || '' }));
        toast.success(`Widget ${selectedElement?.name} has been deleted`);
      },
      setError: (error: string | null) => {
        console.error(
          `Failed to delete widget "${selectedElement?.name}": ${error}`
        );
        toast.error(`Failed to delete widget "${selectedElement?.name}"`);
      },
      setStatus: () => {},
    });
  };

  const handleClone = (id: string, name: string) => {
    if (cloneStatus !== 'loading') {
      fetchApi<void, BIWidget>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/widgets/${id}/clone`,
        queryMethod: 'get',
        setData: (result) => {
          const newWidget = parseWidget(result);
          dispatch(addWidget(newWidget));
          history.push(`/revbi/widgets/edit/${newWidget.id}`);
          toast.success(`Widget "${name}" has been cloned`);
        },
        setError: (error: string | null) => {
          console.error(`Failed to clone widget "${name}": ${error}`);
          toast.error(`Failed to clone widget "${name}": ${error}`);
        },
        setStatus: setCloneStatus,
      });
    }
  };

  return (
    <CommonList
      list={widgetList}
      status={widgetsStatus as QueryStatus}
      selectedElement={selectedElement}
      onDeleteConfirm={handleDeleteConfirm}
      onClone={handleClone}
      setSelectedElement={setSelectedElement}
    />
  );
};
