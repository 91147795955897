import { connect } from 'react-redux';

import { actions } from 'actions';
import EventsTable from 'components/UI/EventsTable/EventsTable';
import { OwnProps } from 'components/UI/EventsTable/types';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

const mapStateToProps = (state: IReduxState, ownProps: OwnProps) => ({
  persistedState: selectors.getTablePrevState(
    state,
    'common',
    ownProps.persistName
  ),
});

const dispatchToProps = {
  persistTableParams: actions.ui.table.persist,
};

export default connect(mapStateToProps, dispatchToProps)(EventsTable);
