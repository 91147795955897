import * as R from 'ramda';

import { getUsersForFilter } from 'selectors/openFilters';

export const getFlatFilterUsers = (state) => {
  let result = [];
  let toExplore = R.clone(getUsersForFilter(state));

  while (toExplore.length > 0) {
    const user = toExplore.pop();
    result = result.concat(user);
    if (user.team) {
      toExplore = toExplore.concat(user.team);
    }
  }
  return result;
};
