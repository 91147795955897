import styled from '@emotion/styled';

export const OptionGroup = styled.div({
  marginBottom: '24px',
});

export const AggregationDropdownContainer = styled.div({
  display: 'flex',
  flexShrink: 0,
  flexGrow: 0,
  width: '100px',
  marginRight: '12px',
  '> div': {
    width: '100px',
  },
});

export const ColumnDropdownContainer = styled.div({
  display: 'flex',
  flexGrow: 1,
  '>.select': {
    width: '100%',
  },
});
