import { IColumn } from 'components/UI/common/TypedTable/TypedTable';
import { useMemo } from 'react';
import useTableSort from '../../Table/useTableSort';
import { ExpandableRow } from './useExpandableWidgetTable.helper';

interface UseExpandableTableSortingParams {
  hierarchyExpandableColumn: IColumn;
  rows: ExpandableRow[];
  sortingConfiguration: {
    firstColumnSortable: boolean;
    metricsSortable: boolean;
  };
  initialSortBy?: string;
}

const FIELD_VALUE_FOR_HIERARCHY = 'expandValue';

const recursiveManagerHierarchySort = (
  sortBy: (list: readonly ExpandableRow[]) => ExpandableRow[],
  rows: ExpandableRow[]
): ExpandableRow[] =>
  rows.map((row) => {
    if (row.children || row.hierarchyDataRows) {
      return {
        ...row,

        // Sort children if they exist
        ...(row.children && {
          children: recursiveManagerHierarchySort(sortBy, sortBy(row.children)),
        }),

        // Sort hierarchyDataRows (pivots) if they exist
        ...(row.hierarchyDataRows && {
          hierarchyDataRows: recursiveManagerHierarchySort(
            sortBy,
            sortBy(row.hierarchyDataRows)
          ),
        }),
      };
    }
    return row;
  });

const getSortField = (
  sortByColumn: string,
  hierarchyColumnSortName: string
) => {
  if (sortByColumn?.includes(hierarchyColumnSortName)) {
    const isDescending = sortByColumn.startsWith('-');
    return `${isDescending ? '-' : ''}${FIELD_VALUE_FOR_HIERARCHY}`;
  }

  return sortByColumn;
};

const useExpandableTableSorting = ({
  rows,
  hierarchyExpandableColumn,
  sortingConfiguration,
  initialSortBy,
}: UseExpandableTableSortingParams) => {
  const hierarchyColumnSortName = hierarchyExpandableColumn.id.toString();

  const [sortByField, setSortByField, sortBy] = useTableSort<ExpandableRow>({
    initSortByField: getSortField(
      initialSortBy || hierarchyColumnSortName,
      hierarchyColumnSortName
    ),
    shouldItemAlwaysBeLast: (row) => !!row.isSubtotal,
  });

  /**
   * We need to handle the sorting of the hierarchy column differently
   * When user clicks on the hierarchy column, we have to map it to the value
   * that is used to display the hierarchy cell
   *
   * But the table needs the column name to handle the sorting
   * So we have to map the sorting value when the user clicks on the hierarchy column
   * To sort by the field and then sort that name to the hierarchy
   */

  const internalSortBy = (columnName?: string) => {
    const sortField = getSortField(columnName || '', hierarchyColumnSortName);
    setSortByField(sortField);
  };

  const isDescending = sortByField && sortByField.startsWith('-');

  const isCurrentlySortingByHierarchy =
    sortByField && sortByField.includes(FIELD_VALUE_FOR_HIERARCHY);

  const sortByMapped = isCurrentlySortingByHierarchy
    ? `${isDescending ? '-' : ''}${hierarchyColumnSortName}`
    : sortByField;

  const sortedRows = useMemo(() => {
    const shouldSort =
      sortingConfiguration.firstColumnSortable ||
      sortingConfiguration.metricsSortable;

    if (sortByField && shouldSort) {
      return recursiveManagerHierarchySort(sortBy, sortBy(rows));
    }
    return rows;
  }, [
    rows,
    sortByField,
    sortingConfiguration.metricsSortable,
    sortingConfiguration.firstColumnSortable,
  ]);

  return {
    sortedRows: sortedRows,
    sortByField: sortByMapped,
    setSortByField: internalSortBy,
  };
};

export default useExpandableTableSorting;
