import moment from 'moment';
import React from 'react';

import MinusCircle from 'assets/images/minus-circle.svg';
import PlusCircle from 'assets/images/plus-circle.svg';
import { IDataCellProps } from 'components/UI/common/TypedTable/TypedTable';
import { IRow } from 'components/UI/common/TypedTable/TypedTable';
import { ClickableCellConfig } from 'components/UI/common/TypedTable/renderers/ClickableCell';
import * as s from 'components/UI/common/TypedTable/styles';

type Props = {
  cellProps: IDataCellProps;
  showOrHideMonths?: string[];
  notAvailableRows?: string[];
  handleShowMonthsRows?: (row: IRow) => void;
  loadingNewRows?: string[];
};

const MonthCustomCell: React.FC<Props> = ({ cellProps }) => {
  const { column, row } = cellProps;
  const config = column.config as ClickableCellConfig;
  const handleClick = config.click;

  const onClick = () => {
    handleClick && handleClick(column, row);
  };

  const IconImage = row.buttonLabelStatus === 'Hide' ? MinusCircle : PlusCircle;

  const renderMonthsValue = (item: IRow) => {
    const [month, year] = String(item?.Months).split('/');
    const date = `${year}-${month}-15`;
    const formatedMonth = moment(date).format('MMM');
    const formatedYear = moment(date).format('YY');
    return (
      <div className={s.monthsWrapper}>
        <p>
          {formatedMonth} '{formatedYear}
        </p>
      </div>
    );
  };

  if (row.Months) {
    return renderMonthsValue(row);
  }

  if (row.buttonLabelStatus === 'Loading') {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <div className="ui tiny active centered inline loader"></div>
      </div>
    );
  }
  if (row.buttonLabelStatus === 'No Data') {
    return <p className={s.noDataWrapper}>{row.buttonLabelStatus}</p>;
  }

  return (
    <button onClick={onClick} className={s.showHideButton}>
      <img className={s.iconImage} src={IconImage} alt={IconImage} />
      <p className={s.buttonLabelStatus}>{row.buttonLabelStatus || 'Show'}</p>
    </button>
  );
};

export default MonthCustomCell;
