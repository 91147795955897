import TypedTable, {
  ColumnsType,
  IDataCellProps,
  IRow,
  TableDetails,
  TypedTableCellConfig,
  ValueProp,
} from '../TypedTable';
import * as s from '../styles';
import NotAvailableCell from './NotAvailableCell';
import classNames from 'classnames';
import { css } from 'emotion';
import * as R from 'ramda';
import React, { useState } from 'react';
import { Popup } from 'semantic-ui-react';

export interface PopupTableCellConfig extends TypedTableCellConfig {
  formatter?: (value: ValueProp) => string;

  table: {
    columns: ColumnsType;
    dataPath: string;
  };
}

const defaultFormatter = (value: ValueProp) => `${value}`;

const circleLabel = css`
  margin: 0 auto;
  height: 28px;
  width: 28px;
  line-height: 28px;
  border-radius: 14px;
  background-color: #edf0f2;
  text-align: center;
  cursor: pointer;

  color: var(--bu-primary-700);
  font-family: var(--bu-font-regular);
  font-size: 13px;
  font-weight: 600;

  &:hover {
    background-color: #c9eaff;
  }
`;

const noPadding = css`
  padding: 0 !important;
`;

const PopupTableCell = ({ column, row, onChange, status }: IDataCellProps) => {
  const [shadowTopOffset, setShadowTopOffset] = useState(0);
  const [shadowLeftOffset, setShadowLeftOffset] = useState(0);

  const text = `${R.path(column.field.split('.'), row)}`;

  const config = (column.config as PopupTableCellConfig) || {};
  const formatter = config.formatter || defaultFormatter;
  const table = config.table || {};

  let data = (R.path(table.dataPath.split('.'), row) as any[]) || [];

  if (column.field === 'extra.Meetings.value') {
    data = R.sortWith([R.descend(R.prop('Meeting_Date__c'))])(data);
  }

  const handleRender = (details: TableDetails) => {
    if (details.columns && details.columns[0]) {
      setShadowLeftOffset(details.columns[0].width);
      setShadowTopOffset(details.columns[0].height);
    }
  };

  return (
    <div
      className={classNames(s.fluid, s.textOverflow)}
      role="button"
      tabIndex={0}
      aria-pressed={false}
    >
      {R.isNil(text) ? (
        <NotAvailableCell />
      ) : (
        <Popup
          className={noPadding}
          disabled={!(data && data.length > 0)}
          trigger={<div className={circleLabel}>{formatter(text)}</div>}
          position="bottom center"
          hoverable
          mouseEnterDelay={500}
          offset={[0, 0]}
          content={
            <div
              style={{
                fontSize: '.85em',
                overflowY: 'auto',
                overflowX: 'hidden',
                maxHeight: 300,
              }}
            >
              <TypedTable
                columns={table.columns || {}}
                data={
                  data.map((item, index) => ({
                    id: index,
                    ...item,
                  })) as IRow[]
                }
                onRender={handleRender}
                stickyHeader
                width={840}
              />
            </div>
          }
        />
      )}
    </div>
  );
};

export default PopupTableCell;
