import { css } from 'emotion';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';

import { teamFlatter } from 'common/helpers';
import CheckboxInput from 'components/UI/Checkbox';
import {
  IProps,
  UserCheckbox,
} from 'components/UI/FilterPanelUsers/TeamCheckbox/types';

const listItemWrap = css`
  padding: 7px 15px 7px 15px;
  transition: 0.4s all;

  &:hover {
    background: rgba(48, 176, 209, 0.08);
  }
`;

const listItem = css`
  display: flex;
  align-items: center;
`;

const TeamCheckbox: React.FC<IProps> = ({
  checkbox,
  defaultExpanded,
  handleChange,
  level = 0,
  maxLevel = 0,
  setMaxLevel,
  isAllChecked,
  isAllMembersChecked,
  isFirstLevel,
}) => {
  const [expandedState, setExpandedState] = useState(defaultExpanded);

  const flatTeamMembers = teamFlatter(checkbox.team);
  const isHierarchyOpen = !(
    isNil(checkbox.team) ||
    isEmpty(checkbox.team) ||
    expandedState
  );

  const isAllTeamActive = () => !flatTeamMembers.some((el) => !el.checked);
  const isSomeTeamActive = () => flatTeamMembers.some((el) => el.checked);

  useEffect(() => {
    if (setMaxLevel && level > maxLevel) {
      setMaxLevel(level);
    }
  }, []);

  function renderCheckbox(checkbox: UserCheckbox) {
    const isUserTeamLeader = !isNil(checkbox.team) && !isEmpty(checkbox.team);

    function isChecked(): boolean {
      if (isAllChecked) {
        return false;
      }

      if (isFirstLevel) {
        if (isUserTeamLeader) {
          return isAllTeamActive();
        }

        return checkbox.checked && !isAllChecked;
      }

      if (isUserTeamLeader) {
        return isAllTeamActive();
      }

      return checkbox.checked;
    }

    return (
      <div className={listItemWrap}>
        <div className={listItem}>
          <div key={checkbox.id} className="filter_tag">
            <CheckboxInput
              id={checkbox.id}
              name={checkbox.name}
              value={checkbox.email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e, isAllMembersChecked, isFirstLevel)
              }
              checked={isChecked()}
              semichecked={
                !isAllTeamActive() && !isAllChecked && isSomeTeamActive()
              }
              label={checkbox.name}
              key={checkbox.id}
            />
          </div>

          {!isNil(checkbox.team) && !isEmpty(checkbox.team) && (
            <Icon
              name={expandedState ? 'angle down' : 'angle up'}
              onClick={() => setExpandedState(!expandedState)}
              style={{ cursor: 'pointer' }}
            />
          )}
        </div>
        {isHierarchyOpen ? renderChildren(checkbox) : null}
      </div>
    );
  }

  function renderChildren(checkbox: UserCheckbox) {
    return (
      <div
        style={{
          paddingLeft: '30px',
          display: expandedState ? 'none' : 'block',
        }}
      >
        {checkbox.team.map((c) => (
          <TeamCheckbox
            checkbox={c}
            defaultExpanded={true}
            handleChange={handleChange}
            isAllChecked={isAllChecked}
            isAllMembersChecked={isAllTeamActive()}
            isFirstLevel={false}
            key={c.id}
            level={level + 1}
            maxLevel={maxLevel}
            setMaxLevel={setMaxLevel}
          />
        ))}
      </div>
    );
  }

  return renderCheckbox(checkbox);
};

export default TeamCheckbox;
