import styled from '@emotion/styled';
import classNames from 'classnames';
import { findCurrency } from 'currency-formatter';
import { css } from 'emotion';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import NumberFormat, {
  NumberFormatPropsBase,
  NumberFormatValues,
} from 'react-number-format';

import { BuControlSize } from 'components/UI/BuButton';

const Container = styled.div`
  height: var(--bu-control-height-regular);
  min-width: 80px;
  background-color: var(--bu-white);
  border: 1px solid var(--bu-gray-300);
  border-radius: var(--bu-control-border-radius);
  overflow: hidden;

  display: flex;
  flex-direction: row;
  align-items: center;

  &.enabled {
    border: 1px solid var(--bu-gray-500);
  }

  &.enabled:focus,
  &.enabled:focus-within {
    border-color: var(--bu-primary-500);
  }

  &.read-only {
    border-color: var(--bu-read-only-border-color);
  }

  &.bu-small {
    height: var(--bu-control-height-small);
    font-size: var(--bu-font-size-small);
  }

  &.bu-big {
    height: var(--bu-control-height-big);
    font-size: var(--bu-font-size-big);
  }
`;

const Loader = styled.i`
  position: relative;
  height: 16px;
  width: 16px;
  display: inline-block;
  animation: around 5.4s infinite;
  margin-right: 5px;

  @keyframes around {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &::after,
  &::before {
    content: '';
    background: white;
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    border-width: 2px;
    border-color: var(--bu-gray-500) var(--bu-gray-500) transparent transparent;
    border-style: solid;
    border-radius: 1em;
    box-sizing: border-box;
    top: 0;
    left: 0;
    animation: around 0.7s ease-in-out infinite;
  }

  &::after {
    animation: around 0.7s ease-in-out 0.1s infinite;
    background: transparent;
  }
`;

const inputStyles = css`
  cursor: pointer;
  flex: 1;
  border: none;
  outline: none;
  height: 100%;
  min-width: 20px;
  padding: 0 8px 0 10px;

  &.alignLeftText:not(:focus) {
    text-align: left;
  }

  &[type='number'],
  &[inputmode='numeric'] {
    text-align: right;
  }

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'],
  &[inputmode='numeric'] {
    -moz-appearance: textfield;
  }

  :disabled {
    background-color: var(--bu-gray-100);
    color: var(--bu-gray-700);
  }

  :read-only {
    background-color: var(--bu-read-only-primary);
    color: var(--bu-gray-900);
    pointer-events: none;

    ::placeholder {
      color: var(--bu-read-only-placeholder-color);
    }
  }

  ::placeholder {
    color: var(--bu-gray-400);
  }
`;

const Tag = styled.div`
  border: none;
  outline: none;
  background-color: var(--bu-gray-300);
  padding: 0 8px;
  display: flex;
  align-items: center;
  height: 100%;

  * ~ & {
    border-left: 1px solid var(--bu-gray-500);
  }

  &.currency {
    display: none;
  }
`;

export type IProps = {
  size?: BuControlSize;
  prefix?: React.ReactNode | string;
  postfix?: React.ReactNode | string;
  code?: string;
  loading?: boolean;
  innerRef?: React.Ref<HTMLInputElement>;
  isAlignLeftText?: boolean;
  handleKeyDown?: (event: any) => void;
} & Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'size' | 'prefix'
> &
  Common.DataTestingType;

const BuInput: React.FC<IProps> = ({
  size,
  prefix,
  postfix,
  code,
  loading = false,
  className,
  style,
  type,
  onFocus,
  onBlur,
  handleKeyDown,
  innerRef,
  isAlignLeftText = false,
  testingLabel = '',
  ...rest
}) => {
  const [prefixState, setPrefixState] = useState<React.ReactNode>();
  const [postfixState, setPostfixState] = useState<React.ReactNode>();
  const [showAffix, setShowAffix] = useState(false);
  const prefixRef = useRef<HTMLDivElement>(null);
  const postfixRef = useRef<HTMLDivElement>(null);

  const dataTestingId = testingLabel
    ? `${testingLabel}_txt_field`
    : 'txt_field';
  const handleCurrencyBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      onBlur?.(event);
      if (prefixRef?.current !== null) {
        prefixRef.current.style.display = 'none';
      }
      if (postfixRef?.current !== null) {
        postfixRef.current.style.display = 'none';
      }

      if (prefixState || postfixState) {
        setShowAffix(true);
      }
    },
    [prefixState, postfixState, onBlur]
  );

  const handleCurrencyFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      event.target.select();
      onFocus?.(event);

      if (prefixRef?.current !== null) {
        prefixRef.current.style.display = 'flex';
      }
      if (postfixRef?.current !== null) {
        postfixRef.current.style.display = 'flex';
      }

      if (prefixState || postfixState) {
        setShowAffix(false);
      }
    },
    [prefixState, postfixState]
  );

  const handleChange = useCallback(
    (values: NumberFormatValues) => {
      if (rest.value !== values.floatValue) {
        rest.onChange?.({
          currentTarget: {
            valueAsNumber: values.floatValue ?? NaN,
            value: values.value,
          },
        } as React.ChangeEvent<HTMLInputElement>);
      }
    },
    [rest.onChange, rest.value]
  );

  const renderType = useMemo(() => {
    switch (type) {
      case 'number':
        return (
          <NumberFormat
            className={inputStyles}
            value={rest.value as NumberFormatPropsBase['value']}
            onFocus={(event) => {
              event.target.select();
              onFocus?.(event);
            }}
            onKeyDown={(event) => {
              handleKeyDown && handleKeyDown(event);
            }}
            thousandSeparator={true}
            disabled={rest.disabled}
            onValueChange={handleChange}
            size={1}
            onBlur={onBlur}
            getInputRef={innerRef}
            data-testing={dataTestingId}
          />
        );
      case 'currency':
        return (
          <NumberFormat
            className={classNames(inputStyles, {
              alignLeftText: isAlignLeftText,
            })}
            value={rest.value as NumberFormatPropsBase['value']}
            onFocus={handleCurrencyFocus}
            onBlur={handleCurrencyBlur}
            onKeyDown={(event) => {
              handleKeyDown && handleKeyDown(event);
            }}
            thousandSeparator={true}
            disabled={rest.disabled}
            onValueChange={handleChange}
            size={1}
            prefix={
              (showAffix ? prefixState : '') as NumberFormatPropsBase['prefix']
            }
            suffix={
              (showAffix ? postfixState : '') as NumberFormatPropsBase['suffix']
            }
            getInputRef={innerRef}
            data-testing={dataTestingId}
          />
        );
      default:
        return (
          <input
            className={inputStyles}
            onFocus={(event) => {
              event.target.select();
              onFocus?.(event);
            }}
            onKeyDown={(event) => {
              handleKeyDown && handleKeyDown(event);
            }}
            {...rest}
            size={1}
            ref={innerRef}
            onBlur={onBlur}
            data-testing={dataTestingId}
          />
        );
    }
  }, [
    type,
    rest.value,
    prefixState,
    postfixState,
    showAffix,
    rest.disabled,
    handleChange,
  ]);

  useEffect(() => {
    //TODO remove currency-formatter library
    if (type === 'currency') {
      let shouldShowAffix = false;
      if (code) {
        const currencyInfo = findCurrency(code);
        if (currencyInfo) {
          currencyInfo.symbolOnLeft
            ? setPrefixState(currencyInfo.symbol)
            : setPostfixState(currencyInfo.symbol);
          shouldShowAffix = true;
        }
      } else {
        if (typeof prefix === 'string') {
          setPrefixState(prefix);
          shouldShowAffix = true;
        }
        if (typeof postfix === 'string') {
          setPostfixState(postfix);
          shouldShowAffix = true;
        }
      }
      setShowAffix(shouldShowAffix);
    } else {
      prefix && setPrefixState(prefix);
      postfix && setPostfixState(postfix);
    }
  }, [type, code, prefix, postfix]);

  return (
    <Container
      className={classNames(size, className, {
        enabled: !rest.disabled && !rest.readOnly,
        'read-only': rest.readOnly,
      })}
      style={style}
    >
      {prefixState && (
        <Tag ref={prefixRef} className={type}>
          {prefixState}
        </Tag>
      )}
      {renderType}
      {loading && <Loader />}
      {postfixState && (
        <Tag ref={postfixRef} className={type}>
          {postfixState}
        </Tag>
      )}
    </Container>
  );
};

export default BuInput;
