import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import SalesforceOAuth from 'components/login/salesforce-oauth';

export const SALESFORCE_CLIENT_ID = process.env.REACT_APP_SALESFORCE_CLIENT_ID;
export const SALESFORCE_LOGIN_URL = process.env.REACT_APP_SALESFORCE_LOGIN_URL;
export const SALESFORCE_CALLBACK_URL =
  window.location.origin + '/salesforce-oauth-callback.html';

const SalesforceButton = ({
  children,
  onSuccess,
  onFailure,
  salesforceLoginUrl,
  ...props
}) => {
  const handleSalesforceOAuth = (onSuccess, onFailure) => {
    let oauth = new SalesforceOAuth(
      SALESFORCE_CLIENT_ID,
      salesforceLoginUrl || SALESFORCE_LOGIN_URL,
      SALESFORCE_CALLBACK_URL
    );
    oauth.login().then(onSuccess, onFailure);
  };

  return (
    <button
      onClick={() => handleSalesforceOAuth(onSuccess, onFailure)}
      {...props}
    >
      {children}
    </button>
  );
};

SalesforceButton.propTypes = {
  children: PropTypes.node,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
};

export default styled(SalesforceButton)`
  label: salesforce-button;
  padding: 7px 22px;
  border: 0;
  border-radius: 4px;
  background-color: var(--bu-primary-500);
  color: var(--bu-white);
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }
`;
