import { FC } from 'react';

import BuPopup from 'components/UI/BuPopup';
import {
  errorContainer,
  popupInfoContainer,
} from 'components/dashboard/Metrics/Widget/Visualization/MetricsTotals/Error/styles';

interface Props {
  readonly name: string | undefined;
  readonly errorMessage: string;
  readonly containerStyle: string;
  readonly titleStyle: string;
}

export const ErrorTotal: FC<Props> = ({
  name,
  errorMessage,
  containerStyle,
  titleStyle,
}) => (
  <div className={containerStyle}>
    <p data-testing="total-title" className={titleStyle}>
      {name || '-'}
    </p>

    <BuPopup
      placement="top"
      trigger={(ref) => (
        <span data-testing="total-value" className={errorContainer} ref={ref}>
          {<>-</>}
        </span>
      )}
    >
      <div className={popupInfoContainer}>{errorMessage}</div>
    </BuPopup>
  </div>
);
