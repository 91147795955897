import { css } from 'emotion';

export const container = css`
  height: 100%;

  display: flex;
  flex-direction: column;

  color: #4a4a4a;
  font-size: 14px;
  line-height: 16px;
`;

export const header = css`
  background-color: var(--bu-white);

  height: 49px;
  padding: 14px;

  color: var(--bu-gray-900);

  font-family: var(--bu-font-medium);
  font-size: 18px;
  line-height: 21px;

  border-bottom: 1px solid var(--bu-gray-400);
`;

export const notFound = css`
  font-style: italic;
`;

export const mainContent = css`
  flex: 1;
`;

export const actionButtons = css`
  border-top: 1px solid var(--bu-gray-300);

  height: 60px;
  padding: 13px 22px;

  text-align: right;

  & .ui.button {
    margin: 8px;
  }
`;

export const userHeaderContainer = css`
  display: flex;
  width: fit-content;
  flex-direction: row;
  &.hasTooltip:hover {
    background-color: var(--bu-gray-200);
  }

  margin: 10px 20px;

  & > .avatar {
    width: 50px;
    margin: 10px;
    text-transform: uppercase;

    color: #fff;
  }

  & > .details {
    margin: 0 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    & > .header {
      font-family: var(--bu-font-medium);
      color: var(--bu-gray-900);
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
    }
  }
`;

export const userDialContainer = css`
  margin: 10px;
  padding-left: 90px;

  & > .header {
    font-family: var(--bu-font-medium);
  }
`;

export const userDetailsContainer = css`
  border-top: 1px solid var(--bu-gray-300);
  padding: 10px 22px;

  & > .item {
    margin: 10px 0;

    display: flex;
    flex-direction: row;

    & > .lab {
      width: 155px;
      line-height: 38px;
    }

    & > .control {
      flex: 1;
      display: flex;
      align-items: center;

      .ui.dropdown,
      .ui.input {
        width: 100%;
      }
    }

    & > .half-size-control {
      flex: 1;
      display: flex;
      align-items: center;

      .ui.dropdown {
        width: 200px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      .ui.input input {
        width: 200px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
`;

export const icon = css`
  margin-left: 11px;
  font-size: 15px;
`;

export const dangerStype = css`
  color: var(--bu-red-400);
`;

export const successStyle = css`
  color: var(--bu-green-500);
`;

export const tooltipContent = css`
  padding: 8px 14px;
  > .tooltipHeader {
    margin-bottom: 0;
  }
  > .tooltipText {
    color: var(--bu-gray-700);
  }
`;

export const impersonationContent = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  & > span {
    line-height: 38px;
  }
`;

export const dropdownWidth120 = css`
  width: 125px;
  min-width: 125px !important;
  margin-right: 5px;

  @media (max-width: 1200px) {
    width: 47%;
    margin-right: 0;
  }
`;

export const dropdownWidth75 = css`
  width: 75px;
  min-width: 75px !important;
  margin-right: 5px;

  @media (max-width: 1200px) {
    width: 47%;
  }
`;

export const dropdownWidth240 = css`
  display: flex !important;
  flex: 1;
  width: 1px;
  min-width: 100px !important;
  margin-right: 5px;

  .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 1200px) {
    width: 80%;
    order: 1;
    margin-top: 5px;
  }
`;

export const impersonationControls = css`
  display: flex;
  width: 100%;
  height: 38px;

  & > span {
    display: flex;
    width: 100%;
    margin-right: 5px;

    .dropdown {
      width: 100%;
    }

    @media (max-width: 1200px) {
      width: 80%;
      order: 1;
      margin-top: 5px;

      & > div {
        width: 100%;
      }
    }
  }

  @media (max-width: 1200px) {
    height: 80px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const addButton = css`
  height: 100% !important;

  @media (max-width: 1200px) {
    height: 38px !important;
    order: 1;
    margin-top: 5px;
  }
`;

export const tableWrapper = css`
  margin-top: 10px;
  z-index: 1;
  width: 100%;
`;

export const tableTitleWrapper = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0 4px 0;
`;

export const tableTitle = css`
  font-size: 14px;
  font-family: var(--bu-font-medium);
  color: var(--bu-gray-900);
`;

export const tooltipWrapper = css`
  &.ui.inverted.popup {
    width: 200px;
    background: #fff;
    color: #000;
    font-size: 14px;
    text-align: center;
    border: 1px solid #c2ccd0;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    padding: 7px;
  }
`;

export const tableCustomWrapper = css`
  tbody {
    tr {
      td:last-child {
        text-transform: capitalize;
      }
    }
  }
`;
