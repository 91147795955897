import * as t from 'actions/actionTypes';
import { postAction } from 'actions/baseActions';
import * as genericSagas from 'sagas/generic';

export const getCalls = (data, tab) => ({
  type: t.CALLS + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/calls/list`,
  data,
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.CALLS + t.GET + t.SUCCESS,
    payload,
    tab,
  }),
  error: (e) => ({
    type: t.CALLS + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.CALLS + t.GET + t.LOADING,
    tab,
  }),
});

export const filterCalls = (data) => ({
  type: t.CALLS + t.FILTER,
  data,
  success: (payload, tab) => ({
    type: t.CALLS + t.FILTER + t.SUCCESS,
    payload,
    tab,
  }),
  error: (e) => ({
    type: t.CALLS + t.FILTER + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.CALLS + t.FILTER + t.LOADING,
  }),
});

export const clearFilter = () => ({
  type: t.CALLS + t.CLEAR_FILTERS,
  success: (payload, tab) => ({
    type: t.CALLS + t.FILTER + t.SUCCESS,
    payload,
    tab,
  }),
  error: (e) => ({
    type: t.CALLS + t.FILTER + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.CALLS + t.FILTER + t.LOADING,
  }),
});

export const pagination = (data, tab) => ({
  type: t.CALLS + t.PAGINATION,
  data,
  tab,
  success: (payload, key) => ({
    type: t.CALLS + t.PAGINATION + t.SUCCESS,
    payload,
    tab: key || tab,
  }),
  error: (e) => ({
    type: t.CALLS + t.PAGINATION + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.CALLS + t.PAGINATION + t.LOADING,
    tab,
  }),
});

export const callActionType = {
  UPDATE_STATE: 'CALL_UPDATE_STATE',
  RESET_STATE: 'CALL_RESET_STATE',
};

export const selectedClassType = {
  PAST_EVENTS: 1,
  UPCOMING_EVENTS: 2,
  COMPLETED_CALLS: 3,
  UPCOMING_CALLS: 4,
  ALL_COMPLETED_CALLS: 5,
  ALL_UPCOMING_CALLS: 6,
  CALL_LIBRARY: 7,
};

export const createCallAction = {
  updateState: (key, value) => {
    return {
      type: callActionType.UPDATE_STATE,
      key,
      value,
    };
  },
  resetState: () => {
    return {
      type: callActionType.RESET_STATE,
    };
  },
  processApiSuccess: (response) => {
    return createCallAction.updateState('apiSuccess', response);
  },
  processApiError: (response) => {
    return createCallAction.updateState('apiError', response);
  },
  processEvents: (page_number) => (response) => {
    return createCallAction.updateState({
      events: response.data,
      apiError: null,
      page_number,
    });
  },
  processCalls: (page_number) => (response) => {
    return createCallAction.updateState({
      calls: response.data,
      apiError: null,
      page_number,
    });
  },
};
