import { createContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { fetchApi } from 'utils/network';
import { DataDescriptor } from '../metrics.types';
import { useSelector } from 'react-redux';
import { getFeatureFlags, getUser } from 'selectors';

export type DrilldownTableSettings = {
  table_display_name: string;
  url: string;
};

export type DrilldownTableSettingsDictionary = Record<
  string,
  DrilldownTableSettings
>;

export type DefaultDateColumnPerTable = Record<string, DataDescriptor>;

type RevBISettings = {
  // currency signal for this company, it can possible removed from here because this is duplicated.
  currency: string;
  // is max number of pivot available, in some case it could be less than this max value.
  maxAvailablePivots: number;
  // is the list of fields than can represent the hierarchy for this company.
  hierarchyAlias: string[];
  // value for default toggle on the widget managers actions
  removeReporteesDataFromManagers: boolean;

  /**
   * This is the configuration for the drilldown tables it will be used to know
   * the url to fetch the drilldown data
   *
   * (for now only used on custom objects - every metric should be slowly migrated to use this)
   */
  drilldownTableSettings: DrilldownTableSettingsDictionary;
  /**
   * this is the configuration of the icons
   * if the table appears here means that is an alias of the specific table and should use the icon that represent.
   */
  tableAliasesConfigs: Record<string, string>;
  /**
   * To hide or not the exploratory view when clicking on widget title
   * on published dashboards
   */
  hideWidgetExploratoryView: boolean;
  /**
   * seconds that should be valid the cache before to show the text
   * in red color.
   */
  warningElapsedUpdateTimeSeconds: number;
  /**
   * seconds that should wait before activate de refresh button.
   */
  waitingTimeToRefreshWidgetSeconds: number;
  /**
   * this object contains a table name as a key and its value is the default date column.
   */
  defaultDateColumnPerTable: DefaultDateColumnPerTable;

  /**
   * This is the mapping of the change interval filters values to the valuessed by core.
   */
  changeIntervalMapToCore: Record<string, string>;
};

const REV_BI_SETTINGS: RevBISettings = {
  currency: 'USD',
  maxAvailablePivots: 2,
  hierarchyAlias: ['user.name', 'user$name'],
  removeReporteesDataFromManagers: false,
  drilldownTableSettings: {},
  tableAliasesConfigs: {},
  hideWidgetExploratoryView: false,
  warningElapsedUpdateTimeSeconds: 3600,
  waitingTimeToRefreshWidgetSeconds: 3,
  defaultDateColumnPerTable: {},
  changeIntervalMapToCore: {},
};

const RevBISettingsContext = createContext(REV_BI_SETTINGS);

const RevBISettingsProvider = ({ children }: { children: any }) => {
  const user = useSelector(getUser);
  const thereIsAUserLoggedIn = !!user?.email;

  const { metrics_builder_enabled, revbi_show_dashboard_nav_items_enabled } =
    useSelector(getFeatureFlags);

  const isRevBIEnabled =
    metrics_builder_enabled && revbi_show_dashboard_nav_items_enabled;

  const [revBISettings, setRevBISettings] =
    useState<RevBISettings>(REV_BI_SETTINGS);

  useEffect(() => {
    if (!isRevBIEnabled || !thereIsAUserLoggedIn) {
      return;
    }

    const abortController = new AbortController();

    fetchApi<string, any>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/settings`,
      queryMethod: 'get',
      setData: (result) => {
        const {
          manager_hierarchy_column_names,
          max_number_of_pivots,
          drilldown_table_settings,
          remove_reportees_data_from_managers,
          table_aliases_configs,
          hide_widget_exploratory_view,
          warning_elapsed_update_time_seconds,
          waiting_time_to_refresh_data_seconds,
          default_date_column_per_table,
          changes_since_to_core,
          ...rest
        } = result;

        setRevBISettings({
          ...REV_BI_SETTINGS,
          ...rest,
          maxAvailablePivots: max_number_of_pivots,
          // for some reason we still using this alias
          // and be is not returning it as an option.
          hierarchyAlias: ['user$name', ...manager_hierarchy_column_names],
          drilldownTableSettings: drilldown_table_settings,
          removeReporteesDataFromManagers: remove_reportees_data_from_managers,
          tableAliasesConfigs: table_aliases_configs,
          hideWidgetExploratoryView: hide_widget_exploratory_view,
          warningElapsedUpdateTimeSeconds:
            warning_elapsed_update_time_seconds ?? 3600,
          waitingTimeToRefreshWidgetSeconds:
            waiting_time_to_refresh_data_seconds ?? 3,
          defaultDateColumnPerTable: default_date_column_per_table ?? {},
          changeIntervalMapToCore: changes_since_to_core ?? {},
        });
      },
      setError: (error: string | null) => {
        setRevBISettings(REV_BI_SETTINGS);
        console.error(error);
        toast.error('Failed fetching rev bi settings');
      },
      signal: abortController.signal,
    });

    return () => {
      abortController.abort();
      setRevBISettings(REV_BI_SETTINGS);
    };
  }, [isRevBIEnabled, thereIsAUserLoggedIn, user?.email]);

  return (
    <RevBISettingsContext.Provider value={revBISettings}>
      {children}
    </RevBISettingsContext.Provider>
  );
};

export { RevBISettingsContext, RevBISettingsProvider };
