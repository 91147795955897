import styled from '@emotion/styled';
import { css } from 'emotion';
import React from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import { IDataCellProps } from 'components/UI/common/TypedTable/TypedTable';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import { getFieldValue } from 'components/UI/common/TypedTable/renderers/custom/common';

const UserProfileNameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const UserProfileNameStyle = css`
  flex: 1 1 auto;
`;

const ReadOnlyTooltipStyle = css`
  width: auto !important;
`;

const ReadOnlyIconStyle = css`
  margin-left: 5px;
  font-size: 16px;
  opacity: 0.6;
  cursor: pointer;

  &:hover,
  &:focus {
    opacity: 1;
  }
`;

const UserProfileName: React.FC<IDataCellProps> = ({ row }) => {
  const name = getFieldValue<string>('name', row);
  const read_only = getFieldValue<boolean>('read_only', row);

  return (
    <UserProfileNameWrapper>
      <span className={UserProfileNameStyle}>{name}</span>
      {read_only && (
        <TooltipWrapper
          tooltip="Read only profile"
          tooltipClassName={ReadOnlyTooltipStyle}
          wrap
        >
          <BuIcon name={BoostUpIcons.ReadOnly} className={ReadOnlyIconStyle} />
        </TooltipWrapper>
      )}
    </UserProfileNameWrapper>
  );
};

export default UserProfileName;
