import { css } from 'emotion';
import React from 'react';

const container = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 32px;
`;

export const DataFetchErrorMessage: React.FC = () => (
  <div className={container} data-testing="data-fetch-error-message">
    <h1>ERROR!</h1>
    <p>Failed to fetch the data for the widget!</p>
  </div>
);
