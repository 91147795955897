import styled from '@emotion/styled';

export const AnalysisTypeSelectContainer = styled.div({
  display: 'flex',
  gap: '8px',
});

export const AnalysisTypeSelectDescription = styled.div({
  color: 'var(--bu-gray-700)',
  padding: '8px 0 0 24px',
});
