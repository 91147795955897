import * as R from 'ramda';
import { createSelector } from 'reselect';

import {
  DASHBOARD_ROUTE,
  ALL_DEALS_ROUTE,
  WIN_AND_LOSS_ROUTE,
  SEARCH_ROUTE,
  CALLS_ROUTE,
  LIBRARY_ROUTE,
} from 'actions/routeActions';
import { partitions } from 'navigation/tabs';
import { isAdminEnabled } from 'selectors/admin';
import { getCompanySettings } from 'selectors/settings';
import { getUserRole } from 'selectors/user';

export const getPreviousRoute = R.path(['route', 'previous']);
export const didGoBack = R.path(['route', 'wentBack']);

const PATH_TO_SECTION = {
  [DASHBOARD_ROUTE]: 'Dashboard',
  [ALL_DEALS_ROUTE]: 'All Deals',
  [WIN_AND_LOSS_ROUTE]: 'Win and Loss',
  [SEARCH_ROUTE]: 'Search',
  [CALLS_ROUTE]: 'Calls',
  [LIBRARY_ROUTE]: 'Library',
};
export const getPreviousSection = createSelector(
  [
    R.nthArg(1), // default value
    getPreviousRoute, // previous route
  ],
  (defaultValue, previousRoute) =>
    R.compose(
      R.propOr(defaultValue, R.__, PATH_TO_SECTION),
      R.when(R.is(String), R.compose(R.head(), R.split('?')))
    )(previousRoute)
);

export const getDefaultLanding = (state) =>
  state.route.config.isInitialized
    ? state.route.config.defaultLanding
    : undefined;

export const isPagesAvailable = (state, schemes) => {
  const { isInitialized, hiddenSchemes, allSchemes } = state.route.config;

  if (!isInitialized || !schemes) {
    return [];
  }

  // why do we do this?
  return schemes
    .map((item) => {
      return {
        ...item,
        isVisible: hiddenSchemes.includes(item.scheme) ? false : item.isVisible,
      };
    })
    .sort((a, b) =>
      !allSchemes.includes(a.scheme) || !allSchemes.includes(b.scheme)
        ? 0
        : allSchemes.indexOf(a.scheme) - allSchemes.indexOf(b.scheme)
    );
};

export const isHideSettingsPage = (state) => {
  const { hiddenSchemes } = state.route.config;
  const isHideSettingsPage = !hiddenSchemes.includes('/settings');

  return isHideSettingsPage;
};

export const isPagesAvailableWithFallback = (state, schemes) => {
  const links = isPagesAvailable(state, schemes);

  const userRole = getUserRole(state);
  const companySettings = getCompanySettings(state);

  const features = {
    isAdminEnabled: isAdminEnabled(state),
    isForecastDashboardEnabled: companySettings?.forecast_dashboard_enabled,
    isRepPaceEnabled: companySettings?.rep_pace_table_enabled,
    isMeetingsDashboardEnabled:
      companySettings?.meetings_dashboard_enabled ?? false,
    isSankeyChartEnabled: companySettings?.sankey_chart_enabled,
  };

  return links.map((item) => {
    if (item.isVisible !== false || !item.partition) {
      return item;
    }

    const firstAvailableTab = isPagesAvailable(
      state,
      partitions[item.partition]
    ).filter(
      (elem) =>
        elem.scheme !== item.scheme &&
        // TODO: simplify or remove this check
        (R.isNil(elem.isVisible) ||
          (typeof elem.isVisible === 'boolean'
            ? elem.isVisible
            : elem.isVisible(userRole, features)))
    )[0];

    if (!firstAvailableTab) {
      return item;
    }

    return {
      ...item,
      // TODO: do not use `scheme` as `path`
      path: firstAvailableTab.scheme,
      scheme: firstAvailableTab.scheme,
    };
  });
};

export const getHiddenPages = createSelector(
  (state) => state.route.config,
  ({ hiddenSchemes }) => hiddenSchemes || []
);
