export const DEFAULT_MAX_ITEMS_PER_ROW = 6;

export const getMetricListItemsPerRow = (
  itemsLength: number,
  metricListWidth: number
): number => {
  let maxItemsAllowed = DEFAULT_MAX_ITEMS_PER_ROW;
  if (metricListWidth < 650) {
    maxItemsAllowed = 2;
  } else if (metricListWidth < 800) {
    maxItemsAllowed = 3;
  } else if (metricListWidth < 1000) {
    maxItemsAllowed = 4;
  }

  return Math.min(maxItemsAllowed, itemsLength);
};
