import classNames from 'classnames';
import { css } from 'emotion';
import React from 'react';

import Modal from 'components/UI/Modal/Modal';
import { chartContainer } from 'components/chart-dashboards/Widget/styles';
import { IProps } from 'components/modals/WidgetModal/types';

const widgetModalOwnContentClass = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 32px;

  & > ${chartContainer} {
    width: 80%;
    margin: auto;
    height: calc(100% - 32px);

    & > div {
      height: 600px;
    }
  }
`;

const WidgetModal: React.FC<IProps> = ({
  chart,
  contentClassName,
  open,
  onClose,
  title,
}) => {
  if (typeof onClose !== 'function' || !open) {
    return null;
  }

  return (
    <Modal
      size="fullscreen"
      onClose={() => onClose(false)}
      title={title}
      fullHeight
    >
      <div className={classNames(widgetModalOwnContentClass, contentClassName)}>
        {chart}
      </div>
    </Modal>
  );
};

export default WidgetModal;
