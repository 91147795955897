import { isEmpty } from 'lodash';
import React from 'react';
import { Popup } from 'semantic-ui-react';

import UserIcon from 'assets/images/user_default_grey.svg';
import { RESPONSE_STATUS_MAP } from 'common/constants';
import * as styles from 'components/UI/AttendeeItem/styles';
import { IProps } from 'components/UI/AttendeeItem/types';

export const responseStatusClassNameMap: { [index: string]: string } = {
  none: styles.attendeeDeclined,
  notResponded: styles.attendeeDeclined,
  needsAction: styles.attendeeDeclined,
  declined: styles.attendeeDeclined,
  accepted: styles.attendeeAccepted,
  organizer: styles.attendeeAccepted,
};

const AttendeeItem = (props: IProps) => {
  const responseStatusHandler = (status: string): string => {
    if (
      props.attendee.email === props.creator.email ||
      props.attendee.email === props.organizer.email
    ) {
      return 'Accepted';
    }

    return RESPONSE_STATUS_MAP[status];
  };

  return (
    <div
      className={styles.attendees_field}
      key={props.attendee.display_name || props.attendee.email}
    >
      <img
        className={styles.attendees_icon}
        src={UserIcon}
        alt="attendees icon"
      />
      <div className={styles.attendees_name}>
        {!isEmpty(props.attendee) && (
          <Popup
            hoverable
            trigger={
              <span>
                {props.attendee.display_name || props.attendee.email}{' '}
                {props.attendee.response_status && (
                  <span
                    className={
                      responseStatusClassNameMap[props.attendee.response_status]
                    }
                  >
                    {`(${responseStatusHandler(
                      props.attendee.response_status
                    )})`}
                  </span>
                )}
              </span>
            }
            content={
              props.attendee.display_name
                ? `${props.attendee.display_name} (${props.attendee.email})`
                : props.attendee.email
            }
          />
        )}
      </div>
    </div>
  );
};

export default AttendeeItem;
