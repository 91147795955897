import { css } from 'emotion';

export const dropdownContent = css`
  min-width: 300px;
  padding-top: 4px;
`;

export const option = css`
  height: 70px;
  padding: 0 20px 4px 20px;
  cursor: pointer;
  margin-bottom: 4px;

  :hover {
    background-color: var(--bu-primary-200);
  }
`;

export const title = css`
  font-weight: 600;
  letter-spacing: 0;
  color: var(--bu-gray-900);
  line-height: 30px;
  margin-bottom: 0px;

  &.disabled {
    color: var(--bu-gray-500);
  }
`;

export const subheading = css`
  height: 40px;
  color: var(--bu-gray-800);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;

  &.disabled {
    color: var(--bu-gray-400);
  }
`;

export const downloadIcon = css`
  font-size: 18px !important;
`;
