import { css } from 'emotion';
import React from 'react';
import { Popup } from 'semantic-ui-react';

import { RESPONSE_STATUS_MAP } from 'common/constants';
import { responseStatusClassNameMap } from 'components/UI/AttendeeItem/AttendeeItem';
import TruncatedText from 'components/UI/TruncatedText/TruncatedText';
import { IDataCellProps } from 'components/UI/common/TypedTable/TypedTable';
import NotAvailableCell from 'components/UI/common/TypedTable/renderers/NotAvailableCell';
import {
  EventAttender,
  ResponseStatus,
} from 'components/dashboard/RepsRecommendations/types';

const attendeeStyle = css`
  display: flex;
  flex-direction: row;
  padding-bottom: 4px;
`;

const blueMoreStyle = css`
  color: #0762ec;
  font-size: 15px;
  text-decoration: underline;
  cursor: pointer;
`;

const popupStyle = css`
  max-height: 400px;
  width: 300px;
  overflow: auto;
`;

const getStatus = (responseStatus: ResponseStatus) =>
  RESPONSE_STATUS_MAP[responseStatus];

const getAttendee = ({
  display_name,
  email,
  response_status,
}: EventAttender) => {
  const text = display_name || email;

  return text ? (
    <div className={attendeeStyle} key={text}>
      <TruncatedText inverted text={display_name || email} length={28} />
      &nbsp;(
      <span className={responseStatusClassNameMap[response_status]}>
        {getStatus(response_status.toString())}
      </span>
      )
    </div>
  ) : null;
};

const AttendeesCell: React.FC<IDataCellProps> = ({
  column,
  row,
}: IDataCellProps) => {
  const { event_company_attendees, event_customer_attendees } =
    row as unknown as {
      event_company_attendees: EventAttender[];
      event_customer_attendees: EventAttender[];
    };

  if (
    !Array.isArray(event_company_attendees) ||
    !Array.isArray(event_customer_attendees)
  ) {
    return <NotAvailableCell />;
  }

  const attendees = [...event_customer_attendees, ...event_company_attendees];
  const attendeesToShow = attendees.slice(0, 2);
  const attendeesToPopUp = attendees.slice(2, attendees.length);

  if (!attendeesToShow.length) {
    return <NotAvailableCell />;
  }

  return (
    <>
      {attendeesToShow.map(getAttendee)}

      {attendeesToPopUp.length ? (
        <Popup
          content={
            <div className={popupStyle}>
              {attendeesToPopUp.map(getAttendee)}
            </div>
          }
          trigger={
            <span className={blueMoreStyle}>
              {attendeesToPopUp.length}&nbsp;More
            </span>
          }
          on="click"
          position="bottom right"
        />
      ) : null}
    </>
  );
};

export default AttendeesCell;
