import {
  AggregationDropdownContainer,
  ColumnDropdownContainer,
  OptionGroup,
} from './styles';
import React, { useEffect, useMemo, useState } from 'react';

import { sortAlphabetically } from 'common/helpers';
import BuSelect from 'components/UI/BuSelect';
import { ISelectOption } from 'components/UI/BuSelect/types';
import {
  AnalysisType,
  FORECAST_SUBMISSION,
} from 'components/dashboard/Metrics/constants';
import { AggregationFunction } from 'components/dashboard/Metrics/enums';
import {
  MetricCreateSubTitle,
  FlexRow,
} from 'components/dashboard/Metrics/metrics.styles';
import {
  BIMetricSimple,
  BIMetricSimpleNewborn,
  DataDescriptor,
} from 'components/dashboard/Metrics/metrics.types';
import { useMetricPropertiesList } from 'components/dashboard/Metrics/Widget/hooks/useMetricPropertiesList';

interface Props {
  readonly aggregationFunction: AggregationFunction;
  readonly analysisType: AnalysisType;
  readonly metricColumn: DataDescriptor | undefined | null;
  readonly metricObject: string;
  readonly updatePropertyMetric: (
    complete: boolean,
    metric: Partial<BIMetricSimple | BIMetricSimpleNewborn>
  ) => void;
}

export const MetricCreateSelectField: React.FC<Props> = ({
  aggregationFunction,
  analysisType,
  metricColumn,
  metricObject,
  updatePropertyMetric,
}) => {
  const [aggregation, setAggregation] = useState<AggregationFunction>();
  const [column, setColumn] = useState<DataDescriptor>();

  /**
   * initialize states
   */
  useEffect(() => {
    if (metricObject === FORECAST_SUBMISSION) {
      setAggregation(undefined);
    } else if (aggregationFunction) {
      setAggregation(aggregationFunction);
    }
  }, [aggregationFunction, metricObject]);

  useEffect(() => {
    metricColumn && setColumn(metricColumn);
  }, [JSON.stringify(metricColumn)]);

  /**
   * fetches columns list
   */
  const { options, aggOptions, status } = useMetricPropertiesList({
    object: metricObject,
    analysisType,
  });

  const columnOptions: ISelectOption[] = useMemo(() => {
    const types =
      aggregation === 'count' ? ['text'] : ['money', 'number', 'percentage'];

    if (status === 'success') {
      return sortAlphabetically(
        options
          .filter((column) => types.includes(column.type))
          .map((column) => ({
            text: column.label,
            value: column.name,
          }))
      );
    }

    return [];
  }, [aggregation, options, status]);

  const handleChangeAggregation = (values: string[]): void => {
    setAggregation(values[0] as AggregationFunction);
    setColumn(undefined);
  };

  const handleChangeColumn = (values: string[]): void => {
    const selectedColumn = options.find(
      (oppColumn) => values[0] === oppColumn.name
    );

    setColumn(selectedColumn);
  };

  /**
   * when the section is complete and valid it triggers the change to its container.
   */
  useEffect(() => {
    const complete = !!(column?.name && (!aggOptions.length || aggregation));

    // Standard metric use aggregation and a column to calculate the value
    // but FS metric needs a custom configuration
    if (metricObject === FORECAST_SUBMISSION && column?.name && !aggregation) {
      updatePropertyMetric(complete, {
        aggregation_function: undefined,
        forecast_submission_properties: {
          metric_type: column.name,
          column_type: column.type,
        },
        column: undefined,
      });
    } else if (column?.name && !!aggregation) {
      updatePropertyMetric(complete, {
        aggregation_function: aggregation,
        column,
        forecast_submission_properties: undefined,
      });
    }
  }, [aggregation, column]);

  const defaultValue = column?.name ?? '';

  return (
    <div data-testing="property-section">
      <MetricCreateSubTitle>Property</MetricCreateSubTitle>
      <OptionGroup>
        <FlexRow>
          {!!aggOptions.length && (
            <AggregationDropdownContainer>
              <BuSelect
                options={aggOptions}
                onChange={handleChangeAggregation}
                secondary
                defaults={[aggregation ?? '']}
                fullWidth
                testingLabel="operator"
              />
            </AggregationDropdownContainer>
          )}
          <ColumnDropdownContainer>
            <BuSelect
              fullWidth
              isLargePlaceholder
              searchable
              secondary
              options={columnOptions}
              onChange={handleChangeColumn}
              placeholder="Select a column"
              defaults={[defaultValue]}
              className="select"
              testingLabel="operator-column"
              disabled={!columnOptions.length || status !== 'success'}
            />
          </ColumnDropdownContainer>
        </FlexRow>
      </OptionGroup>
    </div>
  );
};
