import { connect } from 'react-redux';

import PipelineCreationWidget from 'components/dashboard/Pipeline/PipelineDashboard/PipelineCreationWidget/PipelineCreationWidget';
import { StateProps } from 'components/dashboard/Pipeline/PipelineDashboard/PipelineCreationWidget/types';
import { IReduxState } from 'reducers/types';
import {
  getUserLocalCurrency,
  getDayInQuarter,
  getFiltersForAPI,
} from 'selectors';

const mapStateToProps = (state: IReduxState): StateProps => ({
  companyCurrency: getUserLocalCurrency(state),
  dayInQuarter: getDayInQuarter(state),
  openFilters: getFiltersForAPI(state, 'pipeline_dashboard'),
});

export default connect(mapStateToProps)(PipelineCreationWidget);
