import axiosInstance from 'api/axiosInstance';

// ==== Popup ====

export type ForecastSummaryRequestData = {
  close_date_interval?: string | string[];
  change_interval?: string | string[];
  user: string;
  include_disabled?: boolean;
  business_type?: string;
};

type ForecastBasicSummaryData = {
  id: string;
  display_name: string;
  created_at: string;
  amount: number;
  included_deals_amount: number;
  len_included_deals: number;
  total_deals: number;
  prev_id: string;
  prev_amount: number;
  delta_amount: number;
  prev_created_at: string;
};

type ForecastSummaryAmountDeltaData = {
  current_amount: number;
  prev_amount: number;
  delta_amount: number;
  delta_percentage: number;
  sentiment: boolean;
};

type ForecastSummaryCountDeltaData = {
  current_count: number;
  prev_count: number;
  current_fs_id: string;
  prev_fs_id: string;
  sentiment: boolean;
};

export type ForecastChangeSummaryItemSchema = {
  basic_summary: ForecastBasicSummaryData;
  forecast_amount_delta: ForecastSummaryAmountDeltaData;
  forecast_count_delta: ForecastSummaryCountDeltaData;
};

export type GetForecastSummaryApiResponse = {
  data: ForecastChangeSummaryItemSchema[];
  user_has_profile: boolean;
};

export const getForecastSummary = async (
  payload: ForecastSummaryRequestData
): Promise<GetForecastSummaryApiResponse> => {
  const res = await axiosInstance.post<GetForecastSummaryApiResponse>(
    '/api/data/forecast/summary',
    payload
  );
  return res.data;
};

// ==== Profile ====

export type ForecastProfileData = {
  show_forecasted_amount?: boolean;
  show_forecasted_deals_list?: boolean;
  show_forecasted_deals_amount?: boolean;
  show_forecasted_amount_delta?: boolean;
  show_forecasted_count_delta?: boolean;
};

export type GetForecastSummaryProfileApiResponse = {
  data: ForecastProfileData;
};

export type ForecastDashboardMetricId =
  | 'show_forecasted_amount'
  | 'show_forecasted_deals_list'
  | 'show_forecasted_deals_amount'
  | 'show_forecasted_amount_delta'
  | 'show_forecasted_count_delta';

export interface ForecastDashboardMetric {
  id: ForecastDashboardMetricId;
  text: string;
  disable?: boolean;
}

export const getForecastSummaryProfile = async (
  profileId: string
): Promise<GetForecastSummaryProfileApiResponse> => {
  const res = await axiosInstance.get<GetForecastSummaryProfileApiResponse>(
    `/api/profile/profile/${profileId}/forecast_summary`
  );
  return res.data;
};

export const setForecastSummaryProfile = async (
  profileId: string,
  payload: ForecastProfileData
): Promise<GetForecastSummaryProfileApiResponse> => {
  const res = await axiosInstance.put<GetForecastSummaryProfileApiResponse>(
    `/api/profile/profile/${profileId}/forecast_summary`,
    payload
  );
  return res.data;
};
