import MappingObjectWidget from './MappingObjectWidget/MappingObjectWidget';
import * as s from './styles';
import React from 'react';
import { Checkbox, Dropdown } from 'semantic-ui-react';

import { actions } from 'actions';
import {
  MappingOptions,
  ThirdPartyColumn,
  ThirdPartyLink,
} from 'common/types/ThirdPartyIntegration';
import { DropdownNormal } from 'components/UI/OpenFiltersPanel/Dropdowns/Normal';
import { ACTIONS_COLUMN_DEFAULT_WIDTH } from 'components/UI/TableConfig/column-helper';
import {
  IColumn,
  IDataCellProps,
  IRow,
  SortOrder,
} from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { CustomCellConfig } from 'components/UI/common/TypedTable/renderers/CustomCell';
import { editRemoveButtons } from 'components/UI/common/TypedTable/renderers/custom';
import { openModal } from 'navigation/utils';

export const getColumns = (
  editable = false,
  mappings?: MappingOptions,
  showEditColumn: boolean = true,
  onEdit?: (row: any, rows: any) => void
) => {
  let columns: IColumn[] = [
    {
      id: 'fieldName',
      sort_order: SortOrder.DESCENDING,
      field: 'name',
      type: ColumnTypes.TEXT,
      label: 'Field Name',
      config: {},
      maxWidth: 200,
    },
    {
      id: 'displayName',
      sort_order: SortOrder.DESCENDING,
      field: 'display_name',
      type: ColumnTypes.TEXT,
      label: 'Display Name',
      config: {},
      editable,
      maxWidth: 200,
    },
    {
      id: 'type',
      sort_order: SortOrder.DESCENDING,
      field: 'type',
      type: ColumnTypes.TEXT,
      label: 'Type',
      config: {},
      maxWidth: 200,
    },
    {
      id: 'link',
      sort_order: SortOrder.DESCENDING,
      field: 'link',
      type: ColumnTypes.CUSTOM,
      label: 'Link To',
      width: 400,
      config: {
        renderer: ({ column, row, onChange }) => {
          const linkTo = row[column.field] as ThirdPartyLink;
          return (
            <>
              {editable ? (
                <MappingObjectWidget
                  selected={linkTo}
                  onChange={(newValue) =>
                    !!onChange && onChange(column, row, newValue)
                  }
                  mappings={mappings}
                ></MappingObjectWidget>
              ) : (
                <span>{linkTo?.path[linkTo?.path.length - 1] ?? '-'}</span>
              )}
            </>
          );
        },
      } as CustomCellConfig,
      editable,
    },
  ];
  if (showEditColumn) {
    columns.unshift({
      id: 'actions',
      field: 'protected',
      align: 'center',
      label: 'actions',
      type: ColumnTypes.CUSTOM,
      sort_order: SortOrder.DESCENDING,
      width: 75,
      config: {
        renderer: editRemoveButtons({
          canRemove: () => false,
          onEdit: onEdit,
        }),
      } as CustomCellConfig,
    });
  }
  return columns;
};

export const getRows = (fieldMappings: ThirdPartyColumn[]): IRow[] => {
  return fieldMappings.map((mapping) => ({
    id: mapping.name,
    name: mapping.name,
    display_name: mapping.display_name,
    type: mapping.type,
    link: mapping.linked_to,
  }));
};
