import * as t from 'actions/actionTypes';
import { Targets } from 'reducers/sellerTargetsReducer/types';

export interface ITargetsPayload {
  byUserType?: string;
  year?: number;
  targetsBy?: string;
  interval: string;
  attribute?: string;
  selectedBusinessType?: string;
  body?: {
    user: string;
    fiscal_year: number;
    target: number;
    month?: number;
    quarter?: number;
  };
}

export const getTargetsRequest = (
  interval: string,
  year: string,
  attribute: string,
  selectedBusinessType: string
) => ({
  type: t.TARGETS + t.GET + t.REQUEST,
  interval,
  year,
  attribute,
  selectedBusinessType,
});

export const getTargetsSuccess = (targets: Targets) => ({
  type: t.TARGETS + t.GET + t.SUCCESS,
  targets,
});

export const getTargetsFailure = (error: string) => ({
  type: t.TARGETS + t.GET + t.ERROR,
  error,
});

export const setTargetRequest = ({
  interval,
  targetsBy,
  body,
}: ITargetsPayload) => ({
  type: t.TARGETS + t.SET + t.REQUEST,
  interval,
  targetsBy,
  body,
});

export const setTargetSuccess = () => ({
  type: t.TARGETS + t.SET + t.SUCCESS,
});

export const setTargetFailure = (error: string) => ({
  type: t.TARGETS + t.SET + t.ERROR,
  error,
});
