import { BreadcrumbOptions } from 'highcharts';
import { ManagerActions } from './Actions/ManagerActions';
import { OnVisualizationChange } from './Dropdown/VisualizationDropdown';
import TotalSectionItem from './TotalSectionItem';
import {
  LayoutMultipleTotalItem,
  LayoutMultipleTotals,
} from './TotalSectionLayout/MultipleTotals';
import {
  LayoutPivotedTotals,
  LayoutPivotedTotalsItem,
} from './TotalSectionLayout/PivotedTotals';
import {
  SingleTotalItemLayout,
  SingleTotalSectionLayout,
} from './TotalSectionLayout/SingleTotal';
import {
  ManagerActionsConfig,
  TotalSectionConfig,
  TotalSectionLayoutType,
} from './helper';
import {
  multipleTotalValueContainer,
  multipleTotalsTitleStyle,
  multipleWithChartSelectionTotalValueContainer,
  pivotedTitleStyle,
  singleTitleStyle,
  singleTotalValueContainer,
} from './styles';

interface SectionWrapperProps {
  children: React.ReactNode;
  /**
   * Manager actions to be displayed in the section
   * for now only accepted by the pivoted layout
   */
  managerActions?: React.ReactNode;
  metricLength: number;
  layoutType: TotalSectionLayoutType;
}

const SectionWrapper = ({
  children,
  layoutType,
  metricLength,
  managerActions,
}: SectionWrapperProps) => {
  switch (layoutType) {
    case 'pivoted':
      return (
        <LayoutPivotedTotals managerActions={managerActions}>
          {children}
        </LayoutPivotedTotals>
      );
    case 'multiple':
      return (
        <LayoutMultipleTotals metricLength={metricLength}>
          {children}
        </LayoutMultipleTotals>
      );
    case 'single':
      return <SingleTotalSectionLayout>{children}</SingleTotalSectionLayout>;
  }
};

interface ItemWrapperProps {
  layoutType: TotalSectionLayoutType;
  children: React.ReactNode;
  metricLength: number;
  isClickable: boolean;
  'data-testing': string;
  onClick: () => void;
}

const ItemWrapper = ({
  children,
  layoutType,
  metricLength,
  onClick,
  'data-testing': dataTesting,
  isClickable,
}: ItemWrapperProps) => {
  switch (layoutType) {
    case 'pivoted':
      return (
        <LayoutPivotedTotalsItem
          data-testing={dataTesting}
          metricLength={metricLength}
          handleClick={onClick}
          isClickable={isClickable}
        >
          {children}
        </LayoutPivotedTotalsItem>
      );
    case 'multiple':
      return (
        <LayoutMultipleTotalItem
          data-testing={dataTesting}
          metricLength={metricLength}
          handleClick={onClick}
          isClickable={isClickable}
        >
          {children}
        </LayoutMultipleTotalItem>
      );
    case 'single':
      return (
        <SingleTotalItemLayout
          data-testing={dataTesting}
          handleClick={onClick}
          isClickable={isClickable}
        >
          {children}
        </SingleTotalItemLayout>
      );
  }
};

export type OnTotalClick = (metricId: string, chartLevel?: any) => void;

interface TotalSectionProps {
  totalPanelConfig: TotalSectionConfig;
  chartLevels: BreadcrumbOptions[];
  managerActionsConfig: ManagerActionsConfig;
  onVisualizationChange: OnVisualizationChange;
  onTotalClick: OnTotalClick;
  onComputeUserHierarchyResponseChange: (compute: boolean) => void;
  onRemoveMyReporteesDataChange: (show: boolean) => void;
}

const layoutToValueContainerStyle: Record<TotalSectionLayoutType, string> = {
  pivoted: multipleWithChartSelectionTotalValueContainer,
  multiple: multipleTotalValueContainer,
  single: singleTotalValueContainer,
};

const layoutToTitleStyle: Record<TotalSectionLayoutType, string> = {
  pivoted: pivotedTitleStyle,
  multiple: multipleTotalsTitleStyle,
  single: singleTitleStyle,
};

const layoutToPopupPosition: Record<
  TotalSectionLayoutType,
  'bottom left' | 'bottom center'
> = {
  pivoted: 'bottom left',
  multiple: 'bottom left',
  single: 'bottom center',
};

export const TotalsSection = ({
  totalPanelConfig,
  chartLevels,
  managerActionsConfig,
  onVisualizationChange,
  onTotalClick,
  onComputeUserHierarchyResponseChange,
  onRemoveMyReporteesDataChange,
}: TotalSectionProps) => {
  const layoutType = totalPanelConfig.layoutType;
  const totals = totalPanelConfig.totals;

  const managerActionsComponent = managerActionsConfig.enabled ? (
    <ManagerActions
      computeUserHierarchyResponse={
        managerActionsConfig.computeUserHierarchyResponse
      }
      removeMyReporteesData={managerActionsConfig.removeMyReporteesData}
      onComputeUserHierarchyResponseChange={
        onComputeUserHierarchyResponseChange
      }
      onRemoveMyReporteesDataChange={onRemoveMyReporteesDataChange}
      data-testing="widget-manager-actions"
    />
  ) : null;

  return (
    <SectionWrapper
      layoutType={layoutType}
      metricLength={totals.length}
      managerActions={managerActionsComponent}
    >
      {totals.map((total, index) => {
        const titleStyle = layoutToTitleStyle[layoutType];
        const containerStyle = layoutToValueContainerStyle[layoutType];
        const popupPosition = layoutToPopupPosition[layoutType];
        return (
          <ItemWrapper
            key={total.id}
            data-testing={`total-${index}`}
            layoutType={layoutType}
            metricLength={totals.length}
            isClickable={total.isClickable}
            onClick={() => {
              if (chartLevels.length) {
                onTotalClick(total.id, chartLevels[chartLevels.length - 1]);
              }
              onTotalClick(total.id);
            }}
          >
            <TotalSectionItem
              key={total.id}
              total={total}
              popupPosition={popupPosition}
              containerStyle={containerStyle}
              titleStyle={titleStyle}
              onVisualizationChange={onVisualizationChange}
            />
          </ItemWrapper>
        );
      })}
    </SectionWrapper>
  );
};
