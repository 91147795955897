import { css } from 'emotion';

import { fontDefault } from 'assets/css/common';

export const root = css`
  margin-left: -20px;
  margin-right: -20px;
`;

export const tabContainer = css`
  display: flex;
  border-bottom: 1px solid #dddddd;
  margin-top: 15px;
`;

export const tab = (isActive: boolean) => css`
  cursor: pointer;
  font: ${fontDefault};
  text-transform: uppercase;
  color: var(--bu-gray-900);
  letter-spacing: 0;
  line-height: 16px;
  margin: 0 10px -2px;
  padding-bottom: 10px;
  color: ${isActive ? 'var(--bu-primary-500)' : 'var(--bu-gray-900)'};
  border-bottom: ${isActive ? `3px solid var(--bu-primary-500)` : 'none'};
  &:hover {
    color: var(--bu-primary-500);
  }
`;

export const badgeContainer = css`
  margin-left: 10px;

  > div {
    margin-top: -2px;
  }
`;

export const amountFieldHighlightIcon = css`
  width: 20px;
  margin-left: 10px;
  margin-top: -2px;
`;

export const timeIntervalContainer = css`
  color: var(--bu-gray-700);
  margin: -2px 0 0 10px;
  font-size: 12px;
  line-height: 14px;
  font-family: var(--bu-font-regular);
`;

export const hiddenTableContainer = css`
  display: none;
`;

export const tabTooltip = css`
  text-align: center;
`;
