import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Funnel from 'components/dashboard/Charts/Funnel';
import Select from 'components/modals/PersonalActivity/Select';
import * as styles from 'components/modals/PersonalActivity/styles';
import * as selectors from 'selectors';
import { fetchApi, QueryStatus } from 'utils/network';

type Props = {
  filters: { [key: string]: any };
  email: string;
  isFiltersLoaded: boolean;
};

type StatsData = {
  stages: any;
  risk_factors: any;
};

type ApiResponse = {
  data: StatsData;
};

const initialData: StatsData = {
  stages: [],
  risk_factors: [],
};

const PipelineRiskAnalytics: React.FC<Props> = ({
  email,
  filters,
  isFiltersLoaded,
}) => {
  const stagesFunnel = useSelector(selectors.getSettingsStagesFunnel);

  const [data, setData] = useState<StatsData>(initialData);
  const [status, setStatus] = useState<QueryStatus>('notAsked');
  const [closeDateInterval, setCloseDateInterval] = useState<string[]>(
    filters.close_date_interval
  );

  const allFilters = {
    ...filters,
    close_date_interval: closeDateInterval,
  };

  const queryParams = JSON.stringify(allFilters);

  useEffect(() => {
    if (isFiltersLoaded) {
      fetchApi<string, ApiResponse>({
        queryParams,
        setData: (res) => setData(res.data),
        setStatus,
        url: `${process.env.REACT_APP_BACKEND_URL}/api/data/deals/stats/`,
      });
    }
  }, [email, isFiltersLoaded, queryParams]);

  const isEmpty =
    !R.isEmpty(data.stages) &&
    data.stages.some((obj: any) =>
      Object.keys(obj).some(
        (key) => obj[key] !== 'opportunity_stage' && obj[key] !== 0
      )
    );

  if (!isEmpty) {
    return null;
  }

  return (
    <div className={styles.chart_wrapper}>
      <div className={styles.keepHeight}>
        <Funnel
          isModal
          data={data && data.stages}
          filters={allFilters}
          funnel={stagesFunnel}
          status={status}
          title="Stage"
          extraFilter={
            <Select onChange={setCloseDateInterval} value={closeDateInterval} />
          }
        />
      </div>
    </div>
  );
};

export default PipelineRiskAnalytics;
