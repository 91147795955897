import { css } from 'emotion';

export const btn_arrows = css`
  width: 50px;
  height: 26px;
  border: 1px solid var(--bu-gray-500);
  border-radius: var(--bu-control-border-radius);
`;
export const btn_up = css`
  width: 24px;
  height: 24px;
  display: inline-block;
  border-right: 1px solid #ccc;
  cursor: pointer;

  &.is_first {
    cursor: default;
    opacity: 0.4;
  }

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-size: 22px;
  }
`;
export const btn_down = css`
  width: 24px;
  height: 24px;
  display: inline-block;
  cursor: pointer;

  &.is_last {
    cursor: default;
    opacity: 0.4;
  }

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-size: 22px;
  }
`;
