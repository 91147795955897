import React from 'react';

export const splitByNewline = (text: string) => (
  <>
    {text.split(/(?:\r\n|\r|\n)/g).map((line, i) => (
      <span key={i}>
        {line}
        <br />
      </span>
    ))}
  </>
);
