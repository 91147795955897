import * as styles from './styles';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { Component, PureComponent } from 'react';
import { connect } from 'react-redux';

import { clearSearchQuery } from 'actions/searchActions';
import CategorizedSearchResults from 'components/UI/InlineSearch/CategorizedSearchResults';
import SearchResultsContainer from 'components/UI/InlineSearch/SearchResultsContainer';
import SearchBox from 'components/UI/SearchBox';

class InlineSearch extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  toggleSearchResults(open) {
    this.setState({ open });
  }

  handleChange(e) {
    const { onQueryChange } = this.props;

    const query = typeof e === 'string' ? e : e.target.value;

    // indicate the query changed
    onQueryChange(query);

    // open the search results if necessary
    this.toggleSearchResults(R.not(R.isEmpty(query)));
  }

  handleKeyDown(e) {
    const { query, onSearch } = this.props;

    if (onSearch && e.key === 'Enter') {
      onSearch(query);
      this.toggleSearchResults(false);
    }
  }

  handleFocus() {
    const { query } = this.props;
    this.toggleSearchResults(R.not(R.isEmpty(query)));
  }

  handleBlur() {
    this.toggleSearchResults(false);
  }

  render() {
    const {
      query,
      results,
      loading,
      hasMoreResults,
      className,
      renderResults,
      onQueryChange,
      onSearch,
      clearSearch,
      onResultClick,
      searchBoxComponent: SearchBoxComponent,
      ...props
    } = this.props;
    const { open } = this.state;
    return (
      <div className={`${styles.container} ${className}`}>
        <SearchBoxComponent
          className={styles.searchBox}
          scale={1.086}
          reset={() => clearSearch()}
          value={query}
          open={open}
          onChange={(e) => this.handleChange(e)}
          onKeyDown={(e) => this.handleKeyDown(e)}
          onFocus={() => this.handleFocus()}
          onBlur={() => this.handleBlur()}
          {...props}
        />
        {open ? (
          <SearchResultsContainer
            query={query}
            results={results}
            loading={loading}
            hasMoreResults={hasMoreResults}
            renderResults={renderResults}
            onSearch={onSearch}
            onResultClick={onResultClick}
          />
        ) : null}
      </div>
    );
  }
}

InlineSearch.propTypes = {
  query: PropTypes.string.isRequired,
  results: PropTypes.any,
  loading: PropTypes.bool,
  hasMoreResults: PropTypes.bool,
  className: PropTypes.string,
  searchBoxComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.instanceOf(Component),
    PropTypes.object,
  ]),
  renderResults: PropTypes.func.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  onResultClick: PropTypes.func.isRequired,
  onSearch: PropTypes.func,
};

InlineSearch.defaultProps = {
  searchBoxComponent: SearchBox,
  renderResults: (results, onResultClick) => (
    <CategorizedSearchResults results={results} onResultClick={onResultClick} />
  ),
};

const mapDispatchToProps = {
  clearSearch: clearSearchQuery,
};

export default connect(null, mapDispatchToProps)(InlineSearch);
