import styled from '@emotion/styled';

export const HeaderContainer = styled.div({
  padding: '16px 24px 16px 8px',
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  '.bu-dropdown div': {
    color: 'var(--bu-gray-900)',
  },
  borderBottom: 'solid var(--bu-gray-400) 1px',
});

export const NameInputContainer = styled.div({
  flexGrow: 1,
  cursor: 'pointer',
});

export const NameNotEditing = styled.div({
  display: 'flex',
  alignItems: 'center',
  p: {
    margin: '0px 8px 0px 0px',
    fontWeight: 315,
    fontSize: '18px',
    lineHeight: '21.6px',
  },
  '.icon-button': {
    visibility: 'hidden',
    cursor: 'pointer',
    i: {
      fontSize: '20px',
    },
  },
  '&:hover': {
    '>.icon-button': {
      visibility: 'unset',
    },
  },
});

export const ActionDropdownText = styled.div({ minWidth: '60px' });
