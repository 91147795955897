import classNames from 'classnames';
import React from 'react';

import IconCalendar from 'assets/images/icons/calendar_small.svg';
import BetweenTab from 'components/UI/TimeFilter/TabsForFilter/BetweenTab';
import { timeOptions } from 'components/UI/TimeFilter/TabsForFilter/timeSpanOptions';
import { ChangesSinceTabProps } from 'components/UI/TimeFilter/TabsForFilter/types';
import * as s from 'components/UI/TimeFilter/styles';
import { useClickOutside } from 'components/hooks/useClickOutside';

const excludedFromDashboard = ['ALT'];

const ChangesSinceTab: React.FC<ChangesSinceTabProps> = ({
  value,
  onChange,
  betweenDate,
  isCalendarActive,
  setIsCalendarActive,
  setBetweenDate,
  tab,
}) => {
  const { isOpen, setIsOpen, refElement } = useClickOutside();
  const isDashboard = tab.includes('_dashboard');

  const toggleHandler = () => {
    setIsCalendarActive(isOpen);
    setIsOpen(!isOpen);
  };

  const renderOption = (option: { text: string; value: string }) => {
    const { text, value: oValue } = option;

    return isDashboard && excludedFromDashboard.includes(oValue) ? null : (
      <div
        key={oValue}
        onClick={() => onChange('change_interval', oValue)}
        className={classNames(s.specific_time_period_item, {
          selected: isCalendarActive && value && value.includes(oValue),
        })}
      >
        {text}
      </div>
    );
  };

  const isSelected = betweenDate.startDate !== '';

  return (
    <div className={s.specific_time_periods}>
      <div className={s.specific_time_period}>
        {timeOptions.firstCol.map(renderOption)}
        <button
          type="button"
          className={classNames(s.time_filter_btn('smaller'), {
            active: isSelected,
            margin: true,
            open: isOpen,
          })}
          onClick={toggleHandler}
        >
          <img src={IconCalendar} alt="calendar icons" />
          <div className={s.time_filter_btn_label}>
            {isSelected ? betweenDate.startDate : 'MM/DD/YYYY'}
          </div>
        </button>

        {isOpen && (
          <div ref={refElement} className={s.time_filter_change_since_dropdown}>
            <BetweenTab
              onChange={onChange}
              betweenDate={betweenDate}
              setBetweenDate={setBetweenDate}
              isSelected
            />
          </div>
        )}
      </div>
      <div className={s.specific_time_period}>
        {timeOptions.secondCol.map(renderOption)}
      </div>
      <div className={s.specific_time_period}>
        {timeOptions.thirdCol.map(renderOption)}
      </div>
    </div>
  );
};

export default ChangesSinceTab;
