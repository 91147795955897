import * as R from 'ramda';
import createCachedSelector from 're-reselect';

const getCallsStatsGraphStateById = (state) => state.callsStats.callsGraphState;
export const getCallsStatsGraphState = createCachedSelector(
  [
    R.nthArg(1), // id of the graph
    getCallsStatsGraphStateById,
  ],
  R.prop
)(R.nthArg(1)); // cache by id
const getCallsStatsGraphDataById = (state) => state.callsStats.callsGraphData;
export const getCallsStatsGraphData = createCachedSelector(
  [
    R.nthArg(1), // id of the graph
    getCallsStatsGraphDataById,
  ],
  R.propOr([]) // return either the graph data or an empty array
)(R.nthArg(1)); // cache by id
const getCallsStatsLocalFiltersById = (state) =>
  state.callsStats.callsGraphLocalFilters;
export const getCallsStatsLocalFilter = createCachedSelector(
  [
    R.nthArg(1), // id of the graph
    getCallsStatsLocalFiltersById,
  ],
  R.prop
)(R.nthArg(1)); // cache by id
export const getCallsStatsGlobalFilter = (state) =>
  state.callsStats.callsGraphGlobalFilters;

export const getCallsStatsGraphSummarizedData = createCachedSelector(
  [getCallsStatsGraphData],
  R.compose(
    R.reduce(
      R.mergeWith(R.add), // sum all datapoints values together
      {}
    ),
    R.map(R.omit(['period'])) // remove the period from the datapoints
  )
)(R.nthArg(1)); // cache by id

// call mentions helpers

// totals and graphs selectors
export const getCallsMentionsStats = (state) =>
  state.callsStats.callsMentionsStats;
