import { css } from 'emotion';
import { isNil } from 'ramda';
import React from 'react';

import {
  IColumn,
  SortOrder,
  ValueProp,
  IRow,
  TypedTableCellConfig,
} from 'components/UI/common/TypedTable/TypedTable';
import { currencyFormatter } from 'components/UI/common/TypedTable/formatters';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import {
  IDropdownOption,
  Pace,
  Stat,
} from 'components/dashboard/Progression/types';

type GetColumnsFunction = (
  paces: Pace[],
  checkedColumns: IDropdownOption[],
  companyCurrency: string
) => {
  columns: IColumn[];
  extraHeader: IColumn[];
};

const percentGreen = css`
  color: var(--bu-green-500);
`;
const percentRed = css`
  color: var(--bu-red-400);
`;

const tooltip_line = css`
  padding-bottom: 5px;
  color: var(--bu-gray-900);

  span {
    color: var(--bu-gray-700);
  }
`;

const getClassNameFor = (border: number) => (value: ValueProp) =>
  isFinite(value as number) && !isNil(value)
    ? value >= border
      ? percentGreen
      : percentRed
    : '';

const COLUMNS_TO_CONFIG: {
  [key: string]: {
    type: ColumnTypes;
    minWidth?: number;
    sort_order?: SortOrder;
    align: 'right' | 'left';
    config?: TypedTableCellConfig;
  };
} = {
  overall_target: {
    type: ColumnTypes.MONEY,
    align: 'right',
  },
  target: {
    type: ColumnTypes.MONEY,
    align: 'right',
  },
  booked: {
    type: ColumnTypes.MONEY,
    align: 'right',
    config: {
      progressBar: {
        relativeField: 'target',
      },
    },
  },
  pace: {
    type: ColumnTypes.PERCENT,
    align: 'left',
    config: {
      formatter: (value?: ValueProp) => {
        const number = value as number;
        if (isFinite(number) && !isNil(number)) {
          if (number === -100) {
            return 'Not Started';
          }

          if (number > -1 && number < 1) {
            return 'At pace';
          }

          const displayValue = Math.abs(Math.round(number));
          return `${number >= 1 ? 'Ahead' : 'Behind'} by ${displayValue}%`;
        }

        return '-';
      },
      getClassName: getClassNameFor(0),
    },
  },
  total_pipe: {
    type: ColumnTypes.MONEY,
    align: 'right',
  },
  pipeline_gap: {
    type: ColumnTypes.PERCENT,
    sort_order: SortOrder.DESCENDING,
    align: 'right',
    config: {
      formatter: (value?: ValueProp) => {
        const valueAsNumber = value as number;
        return !isFinite(valueAsNumber) || isNil(value)
          ? '-'
          : `${Math.round(valueAsNumber)}%`;
      },
      getClassName: getClassNameFor(100),
    },
  },
  default: {
    type: ColumnTypes.MONEY,
    align: 'right',
  },
};

export const getColumns: GetColumnsFunction = (
  paces: Pace[],
  checkedColumns: IDropdownOption[],
  companyCurrency: string
) => {
  const columns: IColumn[] = [];
  const extraHeader: IColumn[] = [];
  const periods = new Set<string>();

  const formatter = currencyFormatter(
    companyCurrency,
    0
  ) as TypedTableCellConfig['formatter'];

  if (paces.length) {
    paces.forEach((pace: Pace) =>
      pace.stats.forEach((stat: Stat) => periods.add(stat.period))
    );

    columns.push({
      id: 'seller',
      label: 'Seller',
      field: 'seller',
      type: ColumnTypes.TEXT,
      sortable: true,
      sort_order: SortOrder.ASCENDING,
      config: {},
    });

    extraHeader.push({
      id: '',
      label: '',
      field: '',
      type: ColumnTypes.TEXT,
      sortable: false,
      sort_order: SortOrder.ASCENDING,
      config: {},
    });

    periods.forEach((period: string) => {
      columns.push(
        ...checkedColumns.map(({ text, value }) => {
          const columnConfig =
            COLUMNS_TO_CONFIG[value] ?? COLUMNS_TO_CONFIG.default;
          const {
            config = {},
            minWidth,
            sort_order,
            type,
            align,
          } = columnConfig;

          const id = `${period}|${value}`;

          if (value === 'pace') {
            config.tooltip = {
              relativeFields: [`${period}|target`, `${period}|booked`],
              getTooltip: (
                value: ValueProp,
                relativeFields: { [key: string]: ValueProp }
              ) => {
                if (isNil(value)) {
                  return '-';
                }

                const target = relativeFields[`${period}|target`] as number;
                const className = value >= 0 ? percentGreen : percentRed;
                const diff =
                  (relativeFields[`${period}|booked`] as number) - target;
                const displayValue = Math.abs(Math.round(value as number));
                return (
                  <>
                    <div className={tooltip_line}>
                      Expected Booked: <span>{formatter!(target)}</span>
                    </div>
                    <div className={className}>
                      {`${diff >= 0 ? 'Ahead' : 'Behind'} by ${formatter!(
                        Math.abs(diff)
                      )}`}{' '}
                      {value < -1 || value > 1 ? `(${displayValue}%)` : null}
                    </div>
                  </>
                );
              },
            };
          }

          if (value === 'pipeline_gap') {
            config.tooltip = {
              position: 'bottom right',
              relativeFields: [`${period}|ideal_pipe`, `${period}|total_pipe`],
              getTooltip: (
                value: ValueProp,
                relativeFields: { [key: string]: ValueProp }
              ) => {
                if (isNil(value)) {
                  return '-';
                }

                const expectedPipeline = relativeFields[
                    `${period}|ideal_pipe`
                  ] as number,
                  totalPipeline = relativeFields[
                    `${period}|total_pipe`
                  ] as number,
                  displayValue = isFinite(+value)
                    ? Math.round(value as number)
                    : '0';
                return (
                  <>
                    <div className={tooltip_line}>
                      Expected Pipeline:
                      <span>{formatter!(expectedPipeline)}</span>
                    </div>
                    <div className={tooltip_line}>
                      Current Pipeline: <span>{formatter!(totalPipeline)}</span>
                    </div>
                    <div>({displayValue}% of expected) </div>
                  </>
                );
              },
            };
          }

          return {
            align,
            field: id,
            id,
            label: text,
            minWidth,
            extraHeader: period,
            sort_order: sort_order || SortOrder.ASCENDING,
            sortable: true,
            type,
            config: {
              ...config,
              ...(type === ColumnTypes.MONEY && {
                formatter,
              }),
            },
          };
        })
      );

      extraHeader.push({
        id: period,
        label: period,
        field: period,
        type: ColumnTypes.TEXT,
        sortable: false,
        sort_order: SortOrder.ASCENDING,
        config: {},
        colSpan: checkedColumns.length,
      });
    });
  }
  return {
    columns,
    extraHeader,
  };
};

export const getRows = (paces: Pace[]): IRow[] =>
  paces.map((pace: Pace, i: number) => {
    const row: IRow = {
      id: i,
      seller: pace.user.name || pace.user.email,
      target: pace.target,
    };

    pace.stats.forEach((stat: Stat) => {
      row[`${stat.period}|overall_target`] = pace.target;
      row[`${stat.period}|target`] = stat.target;
      row[`${stat.period}|booked`] = stat.booked;
      row[`${stat.period}|expected_booked`] = stat.expected_booked;
      row[`${stat.period}|pace`] = stat.pace;
      row[`${stat.period}|total_pipe`] = stat.total_pipe;
      row[`${stat.period}|pipeline_gap`] = stat.pipeline_gap;
      row[`${stat.period}|ideal_pipe`] = stat.ideal_pipe;

      Object.entries(stat.forecast_categories).forEach(
        ([categoryName, categoryValue]) => {
          row[`${stat.period}|${categoryName}`] = categoryValue;
        }
      );
    });

    return row;
  });
