import {
  ForecastsSubmissionsByPeriodToCopyReduxState,
  ForecastSubmissionSettingsDealsItemReduxState,
  ForecastSubmissionSettingsDealsReduxState,
} from 'common/types/forecastSubmission';

export const NUMBER_OF_SETTINGS_TO_PRELOAD = 1;

export const getIncludedDeals = (
  deals: ForecastSubmissionSettingsDealsItemReduxState[],
  include_exclude_supported: boolean
) =>
  deals
    .filter(({ auto_included = false }) =>
      include_exclude_supported ? auto_included : true
    )
    .map(({ _id }) => _id);

export const getExcludedDeals = (
  deals: ForecastSubmissionSettingsDealsItemReduxState[],
  include_exclude_supported: boolean
) =>
  deals
    .filter(
      ({ auto_included = false }) => include_exclude_supported && !auto_included
    )
    .map(({ _id }) => _id);

export const getSubmissionsToCopy = (
  submissionsToCopy: ForecastsSubmissionsByPeriodToCopyReduxState,
  deals: ForecastSubmissionSettingsDealsReduxState
) =>
  submissionsToCopy.map((submission) => {
    const totalAmountForInteresectedDeals = submission.intersected_deals.reduce(
      (accumulatedAmount, intersectedDealId) =>
        accumulatedAmount +
        (deals?.deals.find?.((d) => d._id === intersectedDealId)?.amount || 0),
      0
    );

    return {
      id: submission.id,
      intersectedDealsAmount: totalAmountForInteresectedDeals,
      intersectedDeals: submission.intersected_deals,
      displayName: submission.name,
      intersectedDealsCount: submission.intersected_deals.length,
      totalDeals: deals?.count,
      createdAt: submission.created_at,
    };
  });
