import * as t from 'actions/actionTypes';
import { get } from 'sagas/generic';

export const DEFAULT_PAGE_SIZE = 20;

export const fetchEmails = (dealId) => ({
  type: t.EMAIL + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/emails/`,
  data: {
    deal: dealId,
    page_size: DEFAULT_PAGE_SIZE,
    page_number: 0,
  },
  saga: get,
  success: (payload) => ({
    type: t.EMAIL + t.GET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.EMAIL + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.EMAIL + t.GET + t.LOADING,
  }),
});

export const paginateEmails = (dealId, page) => ({
  type: t.EMAIL + t.PAGINATION,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/emails/`,
  data: {
    deal: dealId,
    page_size: DEFAULT_PAGE_SIZE,
    page_number: page,
  },
  saga: get,
  success: (payload) => ({
    type: t.EMAIL + t.PAGINATION + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.EMAIL + t.PAGINATION + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.EMAIL + t.PAGINATION + t.LOADING,
  }),
});

export const fetchEmail = (messageId) => ({
  type: t.EMAIL + t.DETAIL + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/emails/${messageId}/`,
  saga: get,
  success: (payload) => ({
    type: t.EMAIL + t.DETAIL + t.GET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.EMAIL + t.DETAIL + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.EMAIL + t.DETAIL + t.GET + t.LOADING,
  }),
});
