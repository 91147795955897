import { css } from 'emotion';

export const header = css`
  padding: 10px;
  display: grid;
  grid-template-columns: 85% 15%;
  margin-bottom: 10px;
`;

export const header_title = css`
  font-size: 16px;
  color: #4a4a4a;
  font-family: var(--bu-font-semi-bold);
`;

export const header_title_count = css`
  font-size: 16px;
  color: #0762ec;
  font-family: var(--bu-font-semi-bold);
`;

export const header_btns = css`
  display: flex;
  justify-content: space-between;
  justify-content: flex-end;
  a {
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0;
    margin: 0 4px;
    img {
      width: 18px;
      height: 18px;
    }
    &:focus {
      outline: none;
    }
  }
`;
