import * as t from 'actions/actionTypes';
import * as genericSagas from 'sagas/generic';

export const changeDealActiveTab = (tab) => ({
  type: t.DEALS + t.SET_ACTIVE_TAB,
  tab,
});

export const getDealDetailes = (id) => ({
  type: t.DEALS + t.GET + t.DETAIL,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/deals/${id}/`,
  saga: genericSagas.createCached,
  id,
  success: (payload) => ({
    type: t.DEALS + t.GET + t.DETAIL + t.SUCCESS,
    id,
    payload,
  }),
  error: (e) => ({
    type: t.DEALS + t.GET + t.DETAIL + t.ERROR,
    id,
    error: e,
  }),
  loading: () => ({
    type: t.DEALS + t.GET + t.DETAIL + t.LOADING,
    id,
  }),
});

export const toogleFiltersBlock = (tab, shouldClose) => ({
  type: t.DEALS + t.FILTER + t.TOGGLE,
  tab,
  shouldClose,
});

export const toggleAllFilters = (shouldClose) => ({
  type: t.DEALS + t.FILTER + t.TOGGLE,
  tab: 'all',
  shouldClose,
});

export const getDealStages = () => ({
  type: t.DEAL_STAGE + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/deals/stages/`,
  saga: genericSagas.get,
  success: (payload) => ({
    type: t.DEAL_STAGE + t.GET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.DEAL_STAGE + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.DEAL_STAGE + t.GET + t.LOADING,
  }),
});

export const updateDealDetail = (id, changes) => ({
  type: t.DEAL + t.DETAIL + t.UPDATE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/deals/update/${id}/`,
  saga: genericSagas.create,
  data: changes,
  success: (payload) => ({
    type: t.DEAL + t.DETAIL + t.UPDATE + t.SUCCESS,
    id,
    payload,
  }),
  error: (e) => ({
    type: t.DEAL + t.DETAIL + t.UPDATE + t.ERROR,
    id,
    error: e?.response?.data?.error?.message?.[0] || e,
  }),
  loading: () => ({
    type: t.DEAL + t.DETAIL + t.UPDATE + t.LOADING,
    id,
  }),
});

export const changeDealProspectSentiment = (id, prospect, grade, phrases) => ({
  type: t.DEAL + t.PROSPECT + t.UPDATE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/deals/${id}/prospect-sentiment/${prospect}`,
  saga: genericSagas.update,
  data: {
    grade,
    phrases,
  },
  success: (payload) => ({
    type: t.DEAL + t.PROSPECT + t.UPDATE + t.SUCCESS,
    payload: {
      prospect,
      data: payload.data,
      id,
    },
  }),
  error: (e) => ({
    type: t.DEAL + t.PROSPECT + t.UPDATE + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.DEAL + t.PROSPECT + t.UPDATE + t.LOADING,
  }),
});

export const removeDealProspectSentiment = (id, prospect) => ({
  type: t.DEAL + t.PROSPECT + t.REMOVE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/deals/${id}/prospect-sentiment/${prospect}`,
  saga: genericSagas.remove,
  success: (payload) => ({
    type: t.DEAL + t.PROSPECT + t.REMOVE + t.SUCCESS,
    payload: {
      prospect,
      data: payload.data,
      id,
    },
  }),
  error: (e) => ({
    type: t.DEAL + t.PROSPECT + t.REMOVE + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.DEAL + t.PROSPECT + t.REMOVE + t.LOADING,
  }),
});

export const fetchEngagement = (timespan, onlyImportant, dealId, accountId) => {
  const objId = dealId || accountId;
  const objType = dealId ? 'deal' : 'account';
  const dataTransformer = (payload) => ({ ...payload, objId });
  return {
    type: t.DEAL + t.ENGAGEMENT + t.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/api/data/deals/${objId}/engagement/${objType}`,
    saga: genericSagas.getCached,
    dataTransformer,
    data: {
      time_period: timespan,
      only_important: onlyImportant,
    },
    success: (payload) => ({
      type: t.DEAL + t.ENGAGEMENT + t.GET + t.SUCCESS,
      payload: dataTransformer(payload),
    }),
    error: (e) => ({
      type: t.DEAL + t.ENGAGEMENT + t.GET + t.ERROR,
      error: e,
    }),
    loading: () => ({
      type: t.DEAL + t.ENGAGEMENT + t.GET + t.LOADING,
    }),
  };
};

export const changeDeal = (id, changes, isARealTimeUpdate = false, origin) => ({
  type: t.DEALS + t.UPDATE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/deals/update/${id}/?origin=${origin}`,
  saga: genericSagas.create,
  data: changes,
  options: {
    toast: {
      error: true,
      defaultErrorMessage:
        "Sorry, we couldn't update the CRM. Please try again.",
    },
  },
  success: (payload) => ({
    type: t.DEALS + t.UPDATE + t.SUCCESS,
    payload: {
      id,
      changes,
      data: {
        ...payload.data,
      },
      isARealTimeUpdate,
    },
  }),
  error: (e) => ({
    type: t.DEALS + t.UPDATE + t.ERROR,
    error: e,
    payload: {
      id,
      changes,
      isARealTimeUpdate,
    },
  }),
  loading: () => ({
    type: t.DEALS + t.UPDATE + t.LOADING,
    payload: {
      id,
      changes,
    },
  }),
});

export const resetModifiedSinceRecalculation = () => ({
  type: t.DEALS_MODIFIED_SINCE_RECALCULATE_RESET,
});

export const updateSalesProcess = (
  id,
  salesProcessName,
  stepsPayload,
  changes,
  isARealTimeUpdate = false
) => ({
  type: t.DEALS + t.UPDATE + t.MEDDIC,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/sales_process/update_fields`,
  saga: genericSagas.update,
  data: {
    opportunity_id: id,
    sales_process: salesProcessName,
    steps: stepsPayload,
  },
  options: {
    toast: {
      error: true,
    },
  },
  success: ({ data }) => ({
    type: t.DEALS + t.UPDATE + t.SALES_PROCESS + t.SUCCESS,
    payload: {
      id,
      changes,
      response: data,
      isARealTimeUpdate,
    },
  }),
  error: (e) => ({
    type: t.DEALS + t.UPDATE + t.SALES_PROCESS + t.ERROR,
    error: e,
    payload: {
      id,
      changes,
    },
  }),
  loading: () => ({
    type: t.DEALS + t.UPDATE + t.SALES_PROCESS + t.LOADING,
    payload: {
      id,
      changes,
    },
  }),
});

export const syncDeal = (id) => ({
  type: t.DEAL + t.SYNC,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/deals/sync/${id}/`,
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.DEAL + t.SYNC + t.SUCCESS,
    id,
    payload,
  }),
  error: (e) => ({
    type: t.DEAL + t.SYNC + t.ERROR,
    error: e,
    id,
  }),
  loading: () => ({
    type: t.DEAL + t.SYNC + t.LOADING,
    id,
  }),
});

export const refreshDeals = () => ({
  type: t.DEALS + t.REFRESH,
});

export const setDealsCount = (key, count) => ({
  type: t.DEALS + t.SET + t.COUNT,
  key,
  count,
});

export const describeFields = () => ({
  type: t.DEALS + t.DESCRIBE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/deals/describe/`,
  saga: genericSagas.get,
  success: (payload) => ({
    type: t.DEALS + t.DESCRIBE + t.SUCCESS,
    payload: {
      data: {
        ...payload.data,
      },
    },
  }),
  error: (e) => ({
    type: t.DEALS + t.DESCRIBE + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.DEALS + t.DESCRIBE + t.LOADING,
  }),
});
