import { css } from 'emotion';

export const tooltipContainer = css`
  padding: 0;
  max-width: 480px;
`;

export const labelParagraph = css`
  color: var(--bu-gray-700);
  font-size: 12px;
  line-height: 130%;
  margin-top: 8px !important;
  margin-bottom: 4px !important;
`;

export const descriptionParagraph = css`
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 130%;
`;
