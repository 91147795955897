import styled from '@emotion/styled';

export const ControlsContainer = styled.div(
  ({
    isSpaceBetween,
    showControls,
  }: {
    isSpaceBetween: boolean;
    showControls: boolean;
  }) => ({
    justifyContent: isSpaceBetween ? 'space-between' : 'flex-start',
    padding: '8px 16px',
    alignItems: 'center',
    display: showControls ? 'flex' : 'none',
  })
);

export const FilterBarElement = styled.div({
  paddingRight: '6px',
  '>i': {
    fontSize: '20px',
  },
});

export const PivotFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 8px;
  > .secondary-background {
    margin-right: 8px;
  }
  &.notExpanded {
    .bu-dropdown {
      max-width: calc((100vw - 150px) / 6);
      min-width: auto;
    }
  }
  .bu-btn-content {
    div {
      max-width: fit-content;
    }
  }
`;
