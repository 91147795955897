import { IColumn, SortOrder } from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import AttendeesCell from 'components/dashboard/RepsRecommendations/customCells/AttendeesCell';
import DealCell from 'components/dashboard/RepsRecommendations/customCells/DealCell';
import EmailCell from 'components/dashboard/RepsRecommendations/customCells/EmailCell';
import EventNameCell from 'components/dashboard/RepsRecommendations/customCells/EventNameCell';
import {
  IGetColumnsConfig,
  RTableData,
  TableName,
} from 'components/dashboard/RepsRecommendations/types';
import { getComposeDismissButtonsConfig } from 'components/dashboard/RepsRecommendations/utils';

const tableName: TableName = 'upcoming-meeting-no-rsvp';
const apiPoint = `${process.env.REACT_APP_BACKEND_URL}/api/data/recommendations/type/upcoming_meeting_no_rsvp`;
const tableMinWidth = 1320;

const defaultQueryParams = {
  page_number: 1,
  page_size: 50,
  sort: 'account_name',
};

const initialState: RTableData = {
  total_count: 0,
  recommendations: [
    {
      account_id: '',
      account_name: '',
      created_at: '',
      description: null,
      event_company_attendees: [{ email: '', response_status: 'none' }],
      event_customer_attendees: [{ email: '', response_status: 'none' }],
      event_id: '',
      event_start: '',
      event_summary: '',
      id: '',
      last_email_message_id: null,
      last_email_received_at: null,
      last_email_subject: null,
      opportunity_id: null,
      opportunity_name: null,
      type: 'UPCOMING_MEETING_NO_RSVP',
    },
  ],
};

const defaults = {
  id: '',
  delta: false,
  editable: false,
  resize: false,
  sort_field: null,
  sort_order: SortOrder.DESCENDING,
  sortable: true,
  type: ColumnTypes.TEXT,
  width: Math.floor(tableMinWidth / 6),
  config: {},
};

const getColumnsConfig = ({ compose, dismiss }: IGetColumnsConfig): IColumn[] =>
  [
    {
      ...defaults,
      field: 'event_summary',
      label: 'Name',
      type: ColumnTypes.CUSTOM,
      sortable: false,
      width: 330,
      config: {
        renderer: EventNameCell,
        tableName,
        truncateTextFrom: 46,
      },
    },
    {
      ...defaults,
      field: 'event_start',
      label: 'When',
      type: ColumnTypes.DATE,
      width: 120,
      config: {
        format: 'to',
        withoutTooltip: true,
      },
    },
    {
      ...defaults,
      field: 'opportunity_name',
      label: 'Deal',
      type: ColumnTypes.CUSTOM,
      width: 230,
      config: {
        renderer: DealCell,
        tableName,
        truncateTextFrom: 32,
      },
    },
    {
      ...defaults,
      field: 'opportunity_close_date',
      label: 'Close date',
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.DATE,
      width: 120,
      config: {
        withoutTooltip: true,
      },
    },
    {
      ...defaults,
      field: '',
      label: 'Prospect Attendees',
      type: ColumnTypes.CUSTOM,
      sortable: false,
      width: 310,
      config: {
        renderer: AttendeesCell,
      },
    },
    {
      ...defaults,
      field: 'last_email_subject',
      label: 'Last Email',
      sortable: false,
      type: ColumnTypes.CUSTOM,
      width: 470,
      config: {
        renderer: EmailCell,
        truncateTextFrom: 80,
      },
    },
    {
      ...defaults,
      field: '',
      label: 'Actions',
      sortable: false,
      type: ColumnTypes.ACTION,
      width: 120,
      config: {
        buttons: getComposeDismissButtonsConfig({ compose, dismiss }),
      },
    },
  ].map((col) => ({ ...col, id: col.label }));

export default {
  apiPoint,
  defaultQueryParams,
  getColumnsConfig,
  initialState,
  tableMinWidth,
  tableName,
};
