import { filterNavigationItemsForNonAdmin } from './helper';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import clx from 'classnames';
import classNames from 'classnames';
import { css } from 'emotion';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast, ToastOptions } from 'react-toastify';
import {
  Dropdown,
  Icon,
  Input,
  Loader,
  DropdownItemProps,
  InputOnChangeData,
  Popup,
} from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuConfirmationPopup from 'components/UI/BuConfirmationPopup';
import BuIcon from 'components/UI/BuIcon';
import BuToggle from 'components/UI/BuToggle';
import Tooltip from 'components/UI/common/Tooltip';
import { BIDashboard } from 'components/dashboard/Metrics/metrics.types';
import { apiUserProfile } from 'components/settings/UserProfiles/UserProfiles';
import { isAdminEnabled as isAdminEnabledSelector } from 'selectors';
import { getCompanySettings } from 'selectors/settings';
import { QueryStatus, fetchApi } from 'utils/network';

export type ProfileNavItem = {
  enabled: boolean;
  name: string;
  title: string;
  dashboard_id?: string;
  sub_nav?: ProfileNavItem[];
  default_landing?: string;
  locked?: boolean;
  type: 'default' | 'revbi_dashboard';
  revbi_dashboards_enabled?: boolean;
  show_admin_only?: boolean;
};

export type TProfileNavData = {
  default_landing: string;
  items: ProfileNavItem[];
};

type TProfileNavApiResponse = {
  data: TProfileNavData;
};

type OwnProps = {
  profileId: string;
};

type IConfirmationModal = {
  isOpen: boolean;
  id?: string;
  partition?: string;
};

type Props = OwnProps;

const toastOptions: ToastOptions = { position: 'bottom-left' };

const parseDataId = (
  dataId: string
): { action: string; name: string; parent: string | null } => {
  const [field, action] = dataId.split(':');
  const isNested = field.includes('.');

  return {
    action,
    name: isNested ? field.slice(field.lastIndexOf('.') + 1) : field,
    parent: isNested ? field.slice(0, field.lastIndexOf('.')) : null,
  };
};

const getItems = (
  navDataItems: ProfileNavItem[],
  name: string,
  parent: string | null
): {
  itemToChange: ProfileNavItem;
  itemToChangeIndex: number;
  items: ProfileNavItem[];
  parentIndex: number;
} => {
  const parentIndex = parent
    ? navDataItems.findIndex((navItem) => navItem.name === parent)
    : -1;

  const items =
    parentIndex > -1 ? navDataItems[parentIndex].sub_nav || [] : navDataItems;

  const itemToChangeIndex = items.findIndex(
    ({ name: itemName }) => itemName === name
  );
  const itemToChange = items[itemToChangeIndex];

  return { itemToChange, itemToChangeIndex, items, parentIndex };
};

const getNextDefault = (
  navDataItems: ProfileNavItem[],
  itemToChange: ProfileNavItem,
  itemToChangeIndex: number,
  items: ProfileNavItem[],
  parentIndex: number
): string | undefined => {
  let name;

  if (parentIndex > -1) {
    const sameParent = items.find(
      ({ enabled, name: n }) => enabled && n !== itemToChange.name
    );
    name = sameParent && sameParent.name;
  }

  if (parentIndex < 0 || !name) {
    navDataItems.some((navItem, index) => {
      if (index !== itemToChangeIndex && navItem.enabled) {
        if (navItem.sub_nav) {
          return navItem.sub_nav.some((subNavItem) => {
            if (subNavItem.enabled) {
              name = subNavItem.name;
              return true;
            }
          });
        } else {
          name = navItem.name;
          return true;
        }
      }
    });
  }

  return name;
};

const AddDashboardDropdown = ({
  name,
  dashboardDropdownOptions,
  addDashboardToProfile,
}: {
  name: string;
  dashboardDropdownOptions: DropdownItemProps[];
  addDashboardToProfile: (option: string, name: string) => void;
}) => {
  const [dashboardSearchQuery, setDashboardSearchQuery] = useState('');

  const onSearchDashboard = (
    e: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    e.stopPropagation();
    const value = String(data.value.toLowerCase());

    setDashboardSearchQuery(value);
  };

  const filterOptionsBySearchQuery = useCallback(
    (option: DropdownItemProps) => {
      if (dashboardSearchQuery) {
        return String(option.text).toLowerCase().includes(dashboardSearchQuery);
      } else {
        return true;
      }
    },
    [dashboardSearchQuery]
  );

  return (
    <Dropdown
      disabled={
        !dashboardDropdownOptions || dashboardDropdownOptions.length === 0
      }
      searchQuery={dashboardSearchQuery}
      closeOnChange
      className={classNames(dropdownStyle)}
      text="+ Dashboard"
    >
      <Dropdown.Menu>
        <Input
          icon="search"
          iconPosition="left"
          className="search"
          onClick={(e: Event) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          // semantic ui bug
          onKeyDown={(e: any) =>
            e.keyCode === 32 ? e.stopPropagation() : null
          }
          onChange={onSearchDashboard}
        />
        <Dropdown.Menu scrolling>
          {dashboardDropdownOptions
            .filter(filterOptionsBySearchQuery)
            .map((option: any) => (
              <Dropdown.Item
                onClick={() => {
                  addDashboardToProfile(option, name);
                }}
                key={option.id}
                {...option}
              />
            ))}
        </Dropdown.Menu>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const NavigationProfile: React.FC<Props> = ({ profileId }) => {
  const features = useSelector(getCompanySettings);

  const [navData, setNavData] = useState<TProfileNavData | null>(null);
  const [status, setStatus] = useState<QueryStatus>('notAsked');
  const [updateStatus, setUpdateStatus] = useState<QueryStatus>('success');
  const [dashboardsOptions, setDashboardsOptions] = useState<
    DropdownItemProps[]
  >([]);
  const [dashboardSearchQuery, setDashboardSearchQuery] = useState('');
  const [confirmationModalProps, setConfirmationModalProps] =
    useState<IConfirmationModal>({
      isOpen: false,
      id: '',
      partition: '',
    });

  const isAdminEnabled = useSelector(isAdminEnabledSelector);

  const url = `${apiUserProfile}/${profileId}/navigation`;
  const isDataLoading = status === 'loading';
  let abortController: AbortController | null = null;

  useEffect(() => {
    fetchApi<void, BIDashboard[]>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/dashboards`,
      queryMethod: 'get',
      setData: (result) => {
        if (result && result.length > 0) {
          setDashboardsOptions(
            result.map((dashboard: BIDashboard) => ({
              id: dashboard._id,
              text: dashboard.name,
            }))
          );
        }
      },
      setError: (error: string | null) => {
        toast.error(`Failed to load dashboard: ${error}`);
      },
    });
    return () => {
      setDashboardsOptions([]);
    };
  }, []);

  useEffect(() => {
    if (status === 'notAsked') {
      abortController = new AbortController();

      const setData = ({ data }: TProfileNavApiResponse) => {
        setNavData(data);
      };

      const setError = (error: string | null) => {
        toast.error(`Fetching profile data failed: ${error}`, toastOptions);
      };

      fetchApi<void, TProfileNavApiResponse>({
        queryMethod: 'get',
        setData,
        setError,
        setStatus,
        url,
      });
    }
  }, [status]);

  function updateNavigation(newNavigation: TProfileNavData) {
    const setData = ({ data }: TProfileNavApiResponse) => {
      setNavData(data);
      setDashboardSearchQuery('');
    };

    const setError = (error: string | null) => {
      toast.error(`Updating failed: ${error}`, toastOptions);
    };

    fetchApi<string, TProfileNavApiResponse>({
      queryParams: JSON.stringify(newNavigation),
      queryMethod: 'put',
      setData,
      setError,
      setStatus: setUpdateStatus,
      url,
    });
  }

  const handleCloseModal = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    setConfirmationModalProps({ isOpen: false });
  };

  const handleConfirmModal = useCallback(
    (e: Event) => {
      e.preventDefault();
      e.stopPropagation();

      if (confirmationModalProps.id && confirmationModalProps.partition) {
        deleteDashboardFromProfile(
          confirmationModalProps.id,
          confirmationModalProps?.partition
        );
      }

      setConfirmationModalProps({ isOpen: false });
      setUpdateStatus('notAsked');
    },
    [confirmationModalProps.id, confirmationModalProps.partition]
  );

  const addDashboardToProfile = useCallback(
    (item, partition) => {
      if (dashboardSearchQuery) {
        setDashboardSearchQuery('');
      }
      if (navData) {
        const updatedNavigation: TProfileNavData = {
          ...navData,
          items: navData.items.map((navigationItem) => {
            return navigationItem.name === partition
              ? {
                  ...navigationItem,
                  sub_nav: [
                    ...navigationItem.sub_nav!,
                    {
                      name: `${partition}__${item.id}`,
                      title: item.text,
                      enabled: false,
                      type: 'revbi_dashboard',
                    },
                  ],
                }
              : navigationItem;
          }),
        };

        updateNavigation(updatedNavigation);
      }
    },
    [navData, dashboardSearchQuery]
  );

  const deleteDashboardFromProfile = (id: string, partition: string) => {
    if (navData) {
      const updatedNavigation: TProfileNavData = {
        ...navData,
        items: navData.items.map((navigationItem) => {
          return navigationItem.name === partition
            ? {
                ...navigationItem,
                sub_nav:
                  navigationItem.sub_nav &&
                  navigationItem.sub_nav.filter((subNav) => subNav.name !== id),
              }
            : navigationItem;
        }),
      };
      updateNavigation(updatedNavigation);
    }
  };

  const reorderHandler = useCallback(
    (e: React.SyntheticEvent<EventTarget>) => {
      if (
        updateStatus !== 'loading' &&
        e.target instanceof HTMLElement &&
        e.target.dataset.id
      ) {
        const { id } = e.target.dataset;
        const { action, name, parent } = parseDataId(id);

        if (navData) {
          const { itemToChange, itemToChangeIndex, items, parentIndex } =
            getItems(navData.items, name, parent);

          if (
            (action === 'up' && itemToChangeIndex > 0) ||
            (action === 'down' && itemToChangeIndex < items.length - 1)
          ) {
            const delta = action === 'up' ? -1 : 1;

            const updatedItems = items.reduce<ProfileNavItem[]>(
              (acc, curr, index) => {
                if (index === itemToChangeIndex + delta) {
                  if (action === 'up') {
                    acc.push(itemToChange);
                    acc.push(curr);
                  }
                  if (action === 'down') {
                    acc.push(curr);
                    acc.push(itemToChange);
                  }
                } else if (curr.name !== itemToChange.name) {
                  acc.push(curr);
                }
                return acc;
              },
              []
            );

            const updatedNavData = { ...navData };

            if (parentIndex > -1) {
              updatedNavData.items[parentIndex].sub_nav = updatedItems;
            } else {
              updatedNavData.items = updatedItems;
            }

            updateNavigation(updatedNavData);
          }
        }
      }
    },
    [JSON.stringify(navData), updateStatus]
  );

  const navItemEnabledHandler = (id: string) => () => {
    if (id && navData) {
      const { name, parent } = parseDataId(id);
      const updatedNavData = { ...navData };
      const { itemToChange, itemToChangeIndex, items, parentIndex } = getItems(
        navData.items,
        name,
        parent
      );

      items[itemToChangeIndex].enabled = !itemToChange.enabled;

      if (parentIndex > -1) {
        updatedNavData.items[parentIndex].sub_nav = items;
      } else {
        (items[itemToChangeIndex].sub_nav || []).forEach((subNavItem) => {
          subNavItem.enabled = itemToChange.enabled;
        });

        updatedNavData.items = items;
      }

      updateNavigation(updatedNavData);
    }
  };

  const defaultLandingHandler = (id: string) => () => {
    if (updateStatus !== 'loading' && id) {
      const { name } = parseDataId(id);

      updateNavigation({ ...navData!, default_landing: name });
    }
  };

  const mainNavLandingHandler = useCallback(
    (id: string) => () => {
      if (updateStatus !== 'loading' && id) {
        const { name, parent } = parseDataId(id);

        if (navData) {
          const { parentIndex } = getItems(navData!.items, name, parent);

          const updatedNavigation = {
            ...navData,
            items: navData.items.map((item, index) =>
              index === parentIndex ? { ...item, default_landing: name } : item
            ),
          };

          updateNavigation(updatedNavigation);
        }
      }
    },
    [updateStatus, setNavData, setUpdateStatus, navData]
  );

  const renderUpOrDownBtn = (
    id: string,
    is_first: boolean,
    is_last: boolean
  ) => (
    <div className={btn_arrows}>
      <div className={clx(btn_up, { is_first })}>
        <Icon data-id={`${id}:up`} name="angle up" onClick={reorderHandler} />
      </div>
      <div className={clx(btn_down, { is_last })}>
        <Icon
          data-id={`${id}:down`}
          name="angle down"
          onClick={reorderHandler}
        />
      </div>
    </div>
  );

  const renderCustomCheckbox = (
    id: string,
    checked: boolean,
    handleChange: (
      id: string
    ) => (e: React.ChangeEvent<HTMLInputElement>) => void,
    isToggleEnabled: boolean,
    isChildren: boolean
  ) => {
    const disabled = !isToggleEnabled || isChildren;
    return (
      <label className={clx(custom_checkbox, { disabled, hidden: isChildren })}>
        <input
          checked={checked}
          data-default={checked}
          disabled={disabled}
          onChange={handleChange(id)}
          type="checkbox"
        />
        <span />
      </label>
    );
  };

  const renderCustomToggle = (
    id: string,
    checked: boolean,
    locked: boolean = false
  ) => (
    <BuToggle
      disabled={locked}
      checked={checked}
      data-enable={checked}
      onChange={navItemEnabledHandler(`${id}:toggle`)}
    />
  );

  const renderRow = (
    navItem: ProfileNavItem,
    isFirst: boolean,
    isLast: boolean,
    parent?: ProfileNavItem
  ): React.ReactNode => {
    const { enabled, name, sub_nav, title, type } = navItem;
    const { default_landing: parentDefaultLanding, name: parentName } =
      parent || {};
    const id = parentName ? `${parentName}.${name}` : `${name}`;
    const rowClassName = parentName ? table_body_row_collapse : table_body_row;
    const cellClassName = parentName
      ? table_body_row_collapse_col
      : table_body_col;
    const isOnlyChild = !parent?.sub_nav;
    const locked: boolean =
      (!features['forecast_dashboard_enabled'] &&
        name === 'forecast__dashboard') ||
      Boolean(parent && !parent.enabled);
    // get revbi ids and filter dropdown options
    const revbiDashboardIds =
      sub_nav &&
      sub_nav
        .filter((d) => d.type === 'revbi_dashboard')
        .map((d) => d.name.split('__')[1]);

    const dashboardDropdownOptions = dashboardsOptions.filter(
      (option) => !revbiDashboardIds?.includes(option.id!)
    );

    return navData === null ? null : (
      <>
        <div key={`${name}-${title}`} className={rowClassName}>
          <div className={cellClassName}>
            {renderUpOrDownBtn(id, isFirst, isLast)}
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            className={clx(cellClassName, { disabled: locked })}
          >
            <div className={nameClassName}>
              <Tooltip position="bottom left" tooltip={title || ''}>
                {title}
              </Tooltip>
            </div>
            {type === 'revbi_dashboard' &&
              parent &&
              features['metrics_builder_enabled'] && (
                <span>
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      setConfirmationModalProps({
                        id: name,
                        partition: parent!.name,
                        isOpen: true,
                      })
                    }
                  >
                    <BuIcon name={BoostUpIcons.Trash} />
                  </span>
                </span>
              )}
          </div>

          {!parentName && (
            <div
              className={classNames(cellClassName, { vertical_align: true })}
            >
              {' '}
              -{' '}
            </div>
          )}

          <div
            className={clx(cellClassName, {
              vertical_align: true,
              align_center: true,
            })}
          >
            {renderCustomToggle(id, enabled, locked)}
          </div>

          <div className={clx(cellClassName, { align_center: true })}>
            {!isOnlyChild &&
              renderCustomCheckbox(
                id,
                name === parentDefaultLanding,
                mainNavLandingHandler,
                enabled,
                !!sub_nav
              )}
          </div>

          <div className={clx(cellClassName, { align_center: true })}>
            {renderCustomCheckbox(
              id,
              name === navData.default_landing,
              defaultLandingHandler,
              enabled,
              !!sub_nav
            )}
          </div>
        </div>
        {Array.isArray(sub_nav) && sub_nav.length > 0 ? (
          <>
            {sub_nav.map((childNavItem, index) =>
              renderRow(
                childNavItem,
                index === 0,
                index === sub_nav.length - 1,
                navItem
              )
            )}
            {navItem.revbi_dashboards_enabled && dashboardDropdownOptions && (
              <div className={dropdownWrapper}>
                <div style={{ width: '100%' }} />
                <AddDashboardDropdown
                  name={name}
                  addDashboardToProfile={addDashboardToProfile}
                  dashboardDropdownOptions={dashboardDropdownOptions}
                />
                <div style={{ width: '100%' }} />
                <div style={{ width: '100%' }} />
                <div style={{ width: '100%' }} />
              </div>
            )}
          </>
        ) : null}
      </>
    );
  };

  const rowsToDisplay = isAdminEnabled
    ? navData?.items
    : filterNavigationItemsForNonAdmin(navData?.items || []);

  return isDataLoading || navData === null ? (
    <Loader active inline="centered" />
  ) : (
    <div className={container}>
      <div className={table}>
        <div className={table_header}>
          <div className={table_header_row}>
            <div className={table_header_col}>Order</div>
            <div className={table_header_col}>Main Nav</div>
            <div className={table_header_col}>Sub Nav</div>
            <div className={classNames(table_header_col, centeredHeaders)}>
              Enable
            </div>
            <div className={classNames(table_header_col, centeredHeaders)}>
              <Popup
                basic
                content={
                  'Select by default on which Sub-Nav this user profile should land on when clicked on a specific Main navigation tab. By default first Sub-Nav is selected if no change is made.'
                }
                position="bottom center"
                mouseEnterDelay={0}
                trigger={
                  <div>
                    <span className={headerMargin}>Default Sub-Nav</span>
                    <BuIcon
                      color="var(--bu-gray-600)"
                      name={BoostUpIcons.BadgeInfoOutline}
                    />
                  </div>
                }
              />
            </div>

            <div className={classNames(table_header_col, centeredHeaders)}>
              <Popup
                basic
                content={
                  'Select by default on which main navigation tab this user profile should land on after logged in. By default first main navigation tab is selected if no change is made.'
                }
                position="bottom left"
                mouseEnterDelay={0}
                trigger={
                  <div>
                    <span className={headerMargin}>Default Landing</span>
                    <BuIcon
                      color="var(--bu-gray-600)"
                      name={BoostUpIcons.BadgeInfoOutline}
                    />
                  </div>
                }
              />
            </div>
          </div>
        </div>
        <div className={table_body}>
          {rowsToDisplay?.map((item, index) =>
            renderRow(item, index === 0, index === navData.items.length - 1)
          )}
          <BuConfirmationPopup
            cancelText="No"
            confirmText="Yes"
            headerText="Confirmation Required!"
            isOpen={confirmationModalProps.isOpen}
            // type `() => void` because BuConfirmationPopup callbacks doesn't use event as an argument
            onClose={handleCloseModal as () => void}
            onConfirm={handleConfirmModal as () => void}
            size={'tiny'}
          >
            <p>{'Do you really want to remove this dashboard?'}</p>
            <br />
            <p>
              {
                'This will remove the dashboard for this user profile. All the users associated with this user profile will no longer able to view/access this dashboard.'
              }
            </p>
          </BuConfirmationPopup>
        </div>
      </div>
    </div>
  );
};

export default NavigationProfile;

const container = css`
  width: 100%;
  height: 100%;
`;
const table = css`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
`;
const table_header = css`
  position: sticky;
  top: 0;
  z-index: 1;
`;
const table_header_row = css`
  display: flex;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid #ddd;
  background: #f4f7f8;
  color: #666;
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 800;
`;
const table_header_col = css`
  padding: 12px 14px;
  width: 100%;
  border-right: 1px solid #ddd;

  &:first-child {
    min-width: 80px;
    max-width: 80px;
    display: inline-table;
    text-align: center;
  }

  &:last-child {
    border-right: none;
  }
`;
const table_body = css`
  color: #4a4a4a;
`;
const table_body_row = css`
  display: flex;
  border-bottom: 1px solid #ddd;

  &:last-child {
    border-bottom: none;
  }
`;
const table_body_row_collapse = css`
  display: flex;
`;
const table_body_row_collapse_col = css`
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 7px;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  &:first-child {
    margin-left: 79px;
    border-left: 1px solid #ddd;
  }

  &:last-child {
    border-right: none;
  }

  &.align_center {
    align-items: center;
    justify-content: center;
    display: flex;
  }

  &.vertical_align {
    align-items: center;
    display: flex;
  }
  &.disabled {
    color: var(--bu-gray-500);
  }
`;
const table_body_col = css`
  height: 40px;
  width: 100%;
  padding: 7px;
  border-right: 1px solid #ddd;

  &:first-child {
    min-width: 80px;
    max-width: 80px;
    display: flex;
    justify-content: center;
    margin-top: -1px;
    border-top: 1px solid #ddd;
  }

  &:last-child {
    border-right: none;
  }

  &.align_center {
    align-items: center;
    justify-content: center;
    display: flex;
  }

  &.vertical_align {
    display: flex;
    align-items: center;
  }
`;
const btn_arrows = css`
  display: inline-block;
  width: 50px;
  border: 1px solid var(--bu-gray-500);
  border-radius: var(--bu-control-border-radius);
`;
const btn_up = css`
  width: 24px;
  height: 24px;
  display: inline-block;
  border-right: 1px solid #ccc;
  cursor: pointer;

  &.is_first {
    cursor: default;
    opacity: 0.4;
  }

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
`;
const btn_down = css`
  width: 24px;
  height: 24px;
  display: inline-block;
  cursor: pointer;

  &.is_last {
    cursor: default;
    opacity: 0.4;
  }

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
`;
const centeredHeaders = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const custom_checkbox = css`
  padding-left: 20px;
  width: 20px;
  height: 20px;
  position: relative;

  &.disabled {
    opacity: 0.3;

    & > span {
      cursor: default;
    }
  }

  &.hidden {
    display: none;
  }

  input {
    position: absolute;
    appearance: none;

    &:checked {
      ~ span {
        border-color: var(--bu-primary-500);

        &:after {
          content: '';
          position: absolute;
          right: 3px;
          top: 3px;
          border-radius: 50%;
          background-color: var(--bu-primary-500);
          width: 10px;
          height: 10px;
        }
      }
    }
  }

  span {
    cursor: pointer;
    margin-left: -20px;
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: #fff;
    border: 1px solid var(--bu-gray-500);
    border-radius: 50%;
  }
`;
const dropdownStyle = css`
  width: 100%;
  margin-left: 79px;
  padding: 10px;
  border-radius: 0;
  color: var(--bu-primary-500);
  cursor: pointer;
  transition: 0.4s;

  i.dropdown.icon {
    display: none;
  }
`;
const dropdownWrapper = css`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  border-left: none;
  border-bottom: 1px solid var(--bu-gray-400);

  .search {
    input {
      background: var(--bu-gray-200);
    }
  }

  .ui.dropdown > .animating.menu .scrolling.menu,
  .ui.dropdown > .visible.menu .scrolling.menu {
    border-top: none;
    position: static;
    max-height: 14vh;
  }
`;
const nameClassName = css`
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  max-width: 9vw;
  height: 100%;
  padding: 5px 0;
  white-space: nowrap;

  @media (max-width: 1500px) {
    max-width: 8vw;
  }
  @media (max-width: 1200px) {
    max-width: 7vw;
  }
`;
const headerMargin = css`
  margin-right: 5px;
`;
