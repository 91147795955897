import {
  IColumn,
  IDataCellProps,
  IRow,
  TypedTableCellConfig,
} from '../TypedTable';
import { highlightText } from '../helper';
import * as s from '../styles';
import classNames from 'classnames';
import { css } from 'emotion';
import moment from 'moment';
import React from 'react';
import UserAvatar from 'react-user-avatar';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import {
  getCellValue,
  getFieldValue,
} from 'components/UI/common/TypedTable/renderers/custom/common';
import { avatarColors } from 'components/settings/styles';

export interface UserCellConfig extends TypedTableCellConfig {
  click(column?: IColumn, row?: IRow): void;
  tokenField?: string;
}

const pointerCursor = css`
  cursor: pointer;

  color: var(--bu-clickable-cell);

  tr:hover & {
    color: var(--bu-clickable-cell__hover);
  }
`;

export const userAvatar = css`
  color: white;
  cursor: pointer;
  font-size: 11px;
  text-transform: uppercase;

  width: 26px;
  margin-right: 12px;
  margin-bottom: -8px;
  display: inline-block;
`;

const flex = css`
  display: flex;
  flex-direction: row;

  & > div:first-child {
    flex: 1;
  }
`;

const iconStyle = css`
  line-height: 26px;
  font-size: 20px;
  margin-left: 4px;
`;

const dangerStype = css`
  color: var(--bu-red-400);
`;

const successStyle = css`
  color: var(--bu-green-500);
`;

type Token = {
  status: 'missing' | 'expired' | 'valid';
  expiry: null | string;
};

const tokenStatusToConfig: Record<
  Token['status'],
  { text: string; className: string }
> = {
  expired: { text: 'User Token is expired', className: dangerStype },
  missing: { text: 'User Token is missing', className: dangerStype },
  valid: { text: 'User Token is valid', className: successStyle },
};

const UserCell = ({ column, row }: IDataCellProps) => {
  const config = (column.config as UserCellConfig) || {};
  const text = getCellValue<string>({ column, row });
  const token = config.tokenField
    ? getFieldValue<Token>(config.tokenField, row)
    : null;

  const handleClick = () => {
    if (config.click) {
      config.click(column, row);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.altKey || event.ctrlKey) {
      return;
    }

    switch (event.key) {
      case 'Enter':
      case 'Space':
      case ' ':
        handleClick();
        break;
      default:
    }
  };

  let tooltip: React.ReactNode;

  if (column.showTooltip) {
    if (token) {
      tooltip = (
        <div>
          <div className={tokenStatusToConfig[token.status]?.className}>
            {tokenStatusToConfig[token.status]?.text}
          </div>

          {token.expiry && (
            <div style={{ color: 'var(--bu-gray-500)' }}>
              Since {moment(token.expiry).format('DD MMM, h:mm A')}
            </div>
          )}
        </div>
      );
    } else {
      tooltip = text;
    }
  }

  return (
    <TooltipWrapper
      tooltip={tooltip}
      position={token ? 'bottom center' : 'bottom left'}
    >
      <div className={flex}>
        <div className={s.textOverflow}>
          <div
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            className={classNames(
              { [pointerCursor]: !!config.click },
              config.className
            )}
          >
            <div className={userAvatar}>
              <UserAvatar size={26} name={text || 'NA'} colors={avatarColors} />
            </div>

            {config.highlightText
              ? highlightText(text, config.highlightText)
              : text}
          </div>
        </div>

        {token && (
          <BuIcon
            name={
              token.status === 'valid'
                ? BoostUpIcons.CheckCircle
                : BoostUpIcons.DangerCircle
            }
            className={classNames(
              iconStyle,
              tokenStatusToConfig[token.status]?.className
            )}
          />
        )}
      </div>
    </TooltipWrapper>
  );
};

export default UserCell;
