import { produce } from 'immer';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import { combineReducers } from 'redux';

import * as t from 'actions/actionTypes';

const defaultByName = {};
const byName = produce((draft, action) => {
  switch (action.type) {
    case t.TOPICS + t.GET + t.SUCCESS:
      return R.indexBy(R.prop('name'))(action.payload.data);
    case t.TOPICS + t.UPDATE + t.SUCCESS:
      draft[action.payload.data.name] = action.payload.data;
      toast.success(
        action.is_competitor
          ? 'Competitor save successful.'
          : 'Topic save successful.',
        {
          position: 'bottom-left',
        }
      );
      break;
    case t.TOPICS + t.REMOVE + t.SUCCESS:
      delete draft[action.payload.name];
      toast.success(
        action.is_competitor
          ? 'Competitor remove successful.'
          : 'Topic remove successful.',
        {
          position: 'bottom-left',
        }
      );
      break;
    case t.TOPICS + t.UPDATE + t.ERROR:
      if (action.error?.response?.data?.error?.message) {
        action.error.response.data.error.message.forEach((message) => {
          toast.error(message.name || message, {
            position: 'bottom-left',
          });
        });
      }
      break;
    default:
  }
}, defaultByName);

const loading = (state = false, action) => {
  switch (action.type) {
    case t.TOPICS + t.GET + t.LOADING:
    case t.DEAL + t.DEALTOPICS + t.GET + t.LOADING:
      return true;
    case t.TOPICS + t.GET + t.SUCCESS:
    case t.TOPICS + t.GET + t.ERROR:
    case t.DEAL + t.DEALTOPICS + t.GET + t.SUCCESS:
    case t.DEAL + t.DEALTOPICS + t.GET + t.ERROR:
      return false;
    default:
      return state;
  }
};

const byObjId = (state = {}, action) => {
  switch (action.type) {
    case t.DEAL + t.DEALTOPICS + t.GET + t.SUCCESS:
      return {
        ...state,
        [action.payload.objId]: action.payload.data,
      };
    default:
      return state;
  }
};

export default combineReducers({
  byObjId,
  byName,
  loading,
});
