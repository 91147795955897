import classNames from 'classnames';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Popup } from 'semantic-ui-react';

import Search from 'assets/images/search.svg';
import BuCheckbox from 'components/UI/BuCheckbox';
import BuDropdown, {
  DropdownItemContainer,
  BuDropdownItem,
} from 'components/UI/BuDropdown';
import {
  Field,
  Input,
  SearchIcon,
  SearchError,
  DropdownLabel,
  pivotTitleSeparator,
  groupLabelWrapper,
} from 'components/UI/BuSelect/styles';
import { IBuSelect, ISelectOption } from 'components/UI/BuSelect/types';
import { getIconByEntityName } from 'common/icons';

const ALL_TEXT = 'All';

const ALL_ITEM: ISelectOption = {
  value: ALL_TEXT,
  text: ALL_TEXT,
  order: 0,
};

const BuSelect: React.FC<IBuSelect> = ({
  borderless,
  options,
  secondary = false,
  defaults = [],
  multiselect = false,
  inlineLabel,
  addAllOption = false,
  disabled = false,
  readOnly = false,
  className,
  onChange,
  placeholder = '',
  returnFullList = false,
  searchable = false,
  testingLabel = '',
  fullWidth = false,
  isLargePlaceholder = false,
  isRevBIMultiPicklist = false,
}) => {
  const [localSelection, setLocalSelection] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState('');

  const onClose = useCallback(() => {
    if (multiselect && localSelection) {
      onChange(localSelection);
    }
  }, [localSelection]);

  useEffect(() => {
    if (Array.isArray(defaults)) {
      setLocalSelection(defaults);
    }
  }, [JSON.stringify(defaults)]);

  /*
   * when one period option is clicked should be add it on the list to
   */
  const onChangeMultiple = (val: ISelectOption, checked: boolean) => {
    let currentSelection: string[] = [];

    const isAll = val.value === ALL_TEXT || val.value === '__all__';

    if (isRevBIMultiPicklist) {
      const hasAllSelected = localSelection.some((e) => e === '__all__');

      const newSelection: string[] = checked
        ? hasAllSelected || isAll
          ? [val.value]
          : [...localSelection, val.value]
        : [...localSelection.filter((item) => item !== val.value)];

      const areAllIncluded = newSelection.length >= options.length;

      currentSelection = areAllIncluded && !returnFullList ? [] : newSelection;
    } else {
      const newSelection: string[] = checked
        ? [...localSelection, val.value]
        : [...localSelection.filter((item) => item !== val.value)];

      const areAllIncluded = newSelection.length >= options.length;

      currentSelection =
        isAll || (areAllIncluded && !returnFullList) ? [] : newSelection;
    }

    setLocalSelection(currentSelection);
  };

  const onChangeItem = (val: ISelectOption) => {
    const isAll = val.value === ALL_TEXT;
    const currentSelection = isAll ? [] : [val.value];
    onChange(currentSelection);
  };

  const optionMaker = (option: any, checked: boolean, hide: Function) => {
    const { value, text } = option;
    const dropdownItemProps = {
      key: value,
      value,
      active: checked,
      className: classNames({ multiselect: multiselect }),
    };

    let itemInner = text;

    if (multiselect) {
      itemInner = (
        <BuCheckbox
          label={text}
          checked={checked}
          onChange={(checked) => onChangeMultiple(option, checked)}
        />
      );
    }

    /*
     * if the Select Component is a simple list, when the item is selected,
     * it will close the list and notify
     */
    const dropdownItemClick = !multiselect && {
      onClick: () => {
        hide();
        onChangeItem(option);
        setSearchValue('');
      },
    };

    const ariaActiveAttribute = multiselect ? 'aria-checked' : 'aria-selected';

    const selectedOptionProps = {
      role: multiselect ? 'menuitemcheckbox' : 'option',
      [ariaActiveAttribute]: dropdownItemProps.active ? true : false,
    };

    return (
      <BuDropdownItem
        {...selectedOptionProps}
        {...dropdownItemProps}
        {...dropdownItemClick}
      >
        {itemInner}
      </BuDropdownItem>
    );
  };

  const availableOptions = (hide: Function) => {
    const sortByOrder =
      options.length && options.find((option: ISelectOption) => !!option.order);
    const optionsItems = !searchable
      ? options
      : options.filter((option) => {
          const valueIncludes = option.value
            ?.toLowerCase()
            .includes(searchValue.toLowerCase());
          const textIncludes = option.text
            ?.toLowerCase()
            .includes(searchValue.toLowerCase());
          return valueIncludes || textIncludes;
        });

    let lastGroup = '';
    const newOptionsItems: ReactNode[] = optionsItems
      .sort((pItem: ISelectOption, nItem: ISelectOption) =>
        sortByOrder
          ? pItem.order! > nItem.order!
            ? 1
            : pItem.order! < nItem.order!
            ? -1
            : 0
          : 0
      )
      .map((item: ISelectOption) => {
        let groupTitle = null;
        if (item.group && item.group !== lastGroup) {
          const IconPath = item.entityName
            ? getIconByEntityName(item.entityName)
            : null;

          groupTitle = (
            <div className={groupLabelWrapper}>
              {IconPath && <img src={IconPath} />}
              <div className={pivotTitleSeparator}>{item.group}</div>
            </div>
          );
          lastGroup = item.group;
        }
        return (
          <>
            {groupTitle}
            {optionMaker(item, localSelection.includes(item.value), hide)}
          </>
        );
      });

    if (addAllOption) {
      newOptionsItems.unshift(
        optionMaker(ALL_ITEM, !localSelection.length, hide)
      );
    }

    return newOptionsItems;
  };

  const optionsMaker = (hide: Function) => (
    <>
      {searchable && (
        <>
          <SearchIcon src={Search} alt={'Search Icon'} />
          <Input
            value={searchValue}
            type="text"
            placeholder="Search..."
            onChange={(e) => setSearchValue(e.target.value)}
            data-testing="txt_field"
          />
        </>
      )}
      <DropdownItemContainer>
        {availableOptions(hide) || (
          <SearchError>No items match the search</SearchError>
        )}
      </DropdownItemContainer>
    </>
  );

  const selectedElement = options.find(
    (item: ISelectOption) => item.value === localSelection[0]
  );
  const hasMoreThanOne = localSelection.length > 1;
  const selectedText =
    (localSelection.length && selectedElement?.text) || placeholder;
  const selectLabel = localSelection.length
    ? hasMoreThanOne
      ? `${selectedText} (+${localSelection.length - 1})`
      : `${selectedText}`
    : placeholder;

  return (
    <Field
      className={classNames(className, {
        'secondary-background': secondary,
        'borderless-background': borderless,
        'read-only-background': readOnly,
      })}
    >
      <BuDropdown
        borderless={borderless}
        key="select-ma"
        secondary={secondary}
        inlineLabel={inlineLabel}
        label={
          <Popup
            hoverable
            content={selectLabel}
            trigger={
              <DropdownLabel
                isPlaceholder={!selectedElement}
                isLarge={isLargePlaceholder}
                className="bu-dropdown-label"
              >
                {selectLabel}
              </DropdownLabel>
            }
          />
        }
        fullWidth={fullWidth}
        disabled={disabled}
        readOnly={readOnly}
        onClose={onClose}
        testingLabel={testingLabel}
      >
        {optionsMaker}
      </BuDropdown>
    </Field>
  );
};

export default BuSelect;
