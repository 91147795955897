import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Loader } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import RowComponent from 'components/UI/TopicsDiscussedTabs/Table/Row';
import { Row } from 'components/UI/TopicsDiscussedTabs/Table/Row/types';
import {
  IProps,
  TopicTimeline,
} from 'components/UI/TopicsDiscussedTabs/Table/types';
import * as s from 'components/UI/TopicsDiscussedTabs/styles';
import ShowMoreButton from 'components/UI/common/Buttons/ShowMoreButton';
import { tableSizeStyle } from 'components/UI/common/TypedTable/styles';

const R = require('ramda');

const Column: React.FC<{
  columnName?: string;
  onClick?(column: string): void;
  sortState?: { sortField: Array<string>; sortOrder: string };
}> = ({ columnName, children, onClick = () => {}, sortState }) => (
  <th
    className={classNames(s.table_header_column, {
      sortable: !!sortState,
      sorting: sortState?.sortField.includes(columnName || ''),
    })}
  >
    <div onClick={() => onClick(columnName || '')}>
      <span className="table-header-label">{children}</span>
      <BuIcon
        name={
          sortState?.sortField.includes(columnName || '') &&
          sortState?.sortOrder === 'desc'
            ? BoostUpIcons.SortingDescending
            : BoostUpIcons.SortingAscending
        }
      />
    </div>
  </th>
);

function Table(props: IProps) {
  const { data, timeSpan, loading, entityId = '' } = props;
  const [pageState, setPageState] = useState({ rowsCount: 10, page: 1 });
  const [sortState, setSortState] = useState({
    sortField: [''],
    sortOrder: '',
  });

  /* "mentionAt" property added to the "data" object to allow sorting by time */
  const addMentionProperty = (): Object => {
    const getTime = (time: string): number => new Date(time).getTime();

    return data.map((item) => ({
      ...item,
      mentionedAt:
        Array.isArray(item.last_mentions_customer) &&
        item.last_mentions_customer.length
          ? item.last_mentions_customer.map(({ mentioned_at }) =>
              getTime(mentioned_at)
            )
          : item.last_mentions_company.map(({ mentioned_at }) =>
              getTime(mentioned_at)
            ),
    }));
  };

  const handleSortState = (field: string) => {
    const { sortOrder } = sortState;

    setSortState({
      sortField: [`${field}`],
      sortOrder: sortOrder === 'asc' ? 'desc' : 'asc',
    });
  };

  function getSortedRows() {
    const { rowsCount, page } = pageState;
    const { sortField, sortOrder } = sortState;

    const unsortedData = sortField.includes('mentionedAt')
      ? addMentionProperty()
      : data;

    return R.compose(
      R.sort(
        sortOrder === 'asc'
          ? R.ascend(R.path(sortField))
          : R.descend(R.path(sortField))
      ),
      R.map((el: TopicTimeline) => ({ ...el, name: el.topic_name })),
      R.dropLast(data.length - page * rowsCount)
    )(unsortedData);
  }

  const topicsForRendering = getSortedRows();

  const getRange = () => {
    const dates: number[] = [];
    data.forEach((el) =>
      el.timeline.map((item) => dates.push(moment(item.mentioned_at).valueOf()))
    );

    return {
      min: dates.length
        ? moment(Math.min(...dates))
            .startOf('day')
            .subtract(2, 'day')
            .valueOf()
        : undefined,
      max: dates.length
        ? moment(Math.max(...dates))
            .startOf('day')
            .add(2, 'day')
            .valueOf()
        : undefined,
    };
  };

  return (
    <table
      className={classNames(
        s.table,
        s.table_sticky_header,
        'bu-font-default',
        tableSizeStyle,
        'table-compact'
      )}
    >
      <thead className={s.table_header}>
        <tr>
          <Column
            columnName="topic_name"
            onClick={handleSortState}
            sortState={sortState}
          >
            Topic
          </Column>
          <Column
            columnName="timeline"
            onClick={handleSortState}
            sortState={sortState}
          >
            # of Mentions
          </Column>
          <Column
            columnName="mentionedAt"
            onClick={handleSortState}
            sortState={sortState}
          >
            Last Mentioned
          </Column>
          <Column>Timeline</Column>
        </tr>
      </thead>

      <tbody>
        {loading ? (
          <tr style={{ padding: '30px' }}>
            <td colSpan={4}>
              <Loader active inline="centered" />
            </td>
          </tr>
        ) : (
          <>
            {_.isEmpty(topicsForRendering) ? (
              <tr>
                <td className={s.nothing_found} colSpan={4}>
                  Nothing found
                </td>
              </tr>
            ) : (
              topicsForRendering.map((el: Row) => (
                <RowComponent
                  row={el}
                  key={el.topic_name}
                  minMaxRange={getRange()}
                  timeSpan={timeSpan}
                  entityId={entityId}
                />
              ))
            )}

            {pageState.rowsCount * pageState.page < data.length && (
              <tr>
                <td className={s.box_btn_show} colSpan={4}>
                  <ShowMoreButton
                    showing={pageState.rowsCount * pageState.page}
                    count={data.length}
                    max={10}
                    loading={loading}
                    onClick={() =>
                      setPageState({ ...pageState, page: pageState.page + 1 })
                    }
                  />
                </td>
              </tr>
            )}
          </>
        )}
      </tbody>
    </table>
  );
}

export default Table;
