import classNames from 'classnames';
import { css } from 'emotion';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Dropdown,
  DropdownItemProps,
  DropdownProps,
  Label,
} from 'semantic-ui-react';

import {
  IDataCellProps,
  isEditable,
  TypedTableCellConfig,
  ValueType,
  ValueProp,
} from 'components/UI/common/TypedTable/TypedTable';
import DeltaValueCell from 'components/UI/common/TypedTable/renderers/common/DeltaValueCell';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import { getCellValue } from 'components/UI/common/TypedTable/renderers/custom/common';

export interface MultiDropDownCellConfig extends TypedTableCellConfig {
  options?: DropdownItemProps[];
  allowAdditions?: boolean;
}

const removePadding = css`
  &.dropdown {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const editableCellBorder = css`
  border: 1px solid transparent;
  box-sizing: border-box;

  padding: 1px;

  tr:hover & {
    border: 1px solid var(--bu-gray-400);
    box-sizing: border-box;
    background-color: var(--bu-white);

    &:hover {
      border-color: var(--bu-gray-500);
    }
  }

  &.ui.multiple.dropdown > .label {
    font-size: 12px !important;
  }

  &.ui.dropdown.active {
    border-color: var(--bu-primary-500);
  }

  &.loading {
    background-color: #fffaca !important;
  }

  &.success {
    background-color: #cff6d7 !important;
  }

  &.error {
    background-color: #fce3dc !important;
  }
`;

const fluid = css`
  width: 100%;
`;

const dropdownLabelOverflow = css`
  &.ui.dropdown > .text {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const tagContainer = css`
  white-space: pre-line;

  .ui.image.label {
    margin: 2px 2px;
  }
`;

const MultiDropDownCell = ({
  column,
  row,
  rows,
  onChange,
  status,
}: IDataCellProps) => {
  const [isOpen, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const oldValue = getCellValue<string[]>({ column, row }) || [];

  const config = (column.config as MultiDropDownCellConfig) || {};
  const options = config.options;
  const allowAdditions = config.allowAdditions;

  useEffect(() => {
    setSelectedOptions(oldValue);
  }, [JSON.stringify(oldValue)]);

  const getOptionItem = (value: ValueProp) =>
    options && options.find((item) => item.value === value);

  const formatter = (value: ValueProp) => {
    const printOption = getOptionItem(value);

    return `${printOption ? printOption.text : oldValue ? oldValue : ''}`;
  };

  const handleChange = (
    event: SyntheticEvent<HTMLElement>,
    { value }: DropdownProps
  ) => {
    if (onChange instanceof Function) {
      setSelectedOptions(value as string[]);
      onChange(column, row, value as ValueType);
    }
  };

  if (!isEditable(column, row, rows)) {
    return (
      <DeltaValueCell
        column={column}
        row={row}
        rows={rows}
        formatter={formatter}
      >
        <div className={tagContainer}>
          {selectedOptions.map((value) => (
            <Label key={`${row.id}-${value}`} image>
              {value}
            </Label>
          ))}
        </div>
      </DeltaValueCell>
    );
  }

  const newValues = allowAdditions
    ? selectedOptions
        .filter((value) => !options?.some((option) => option.value === value))
        .map((value) => ({ value: value, text: value }))
    : [];

  return (
    <DeltaValueCell column={column} row={row} rows={rows} formatter={formatter}>
      <TooltipWrapper
        tooltip={column.showTooltip && formatter(oldValue)}
        wrap
        disable={isOpen}
      >
        <Dropdown
          className={classNames(
            removePadding,
            editableCellBorder,
            dropdownLabelOverflow,
            fluid,
            getOptionItem(oldValue)?.className
          )}
          fluid
          multiple
          search
          selection
          options={
            options &&
            options.filter((option) => option.value !== null).concat(newValues)
          }
          clearable={
            options && !!options.find((option) => option.value === null)
          }
          placeholder={
            (options &&
              options
                .find((option) => option.value === null)
                ?.text?.toLocaleString()) ||
            'Not Selected'
          }
          icon={null}
          onChange={handleChange}
          open={isOpen}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          allowAdditions={allowAdditions}
          value={selectedOptions as string[]}
          floating
          inline
          scrolling
        />
      </TooltipWrapper>
    </DeltaValueCell>
  );
};

export default MultiDropDownCell;
