import clx from 'classnames';
import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { actions } from 'actions';
import {
  deleteCustomView,
  pinCustomView,
  renameCustomView,
} from 'actions/customViews';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import { allTabs, BusinessTypeTab } from 'common/constants';
import BuButton from 'components/UI/BuButton';
import BuConfirmationPopup from 'components/UI/BuConfirmationPopup';
import BuIcon from 'components/UI/BuIcon';
import CustomViewItem from 'components/UI/OpenFiltersPanel/CustomViewsDropdown/CustomViewItem/CustomViewItem';
import * as s from 'components/UI/OpenFiltersPanel/CustomViewsDropdown/styles';
import { PinnedView } from 'components/UI/OpenFiltersPanel/types';
import { useClickOutside } from 'components/hooks/useClickOutside';
import { USER_MANAGEMENT_ROLES } from 'components/settings/Settings/types';
import { ProfileDataWithName } from 'reducers/filters/openFilters';
import { IReduxState } from 'reducers/types';
import {
  getPersistName,
  getFeatureFlags,
  getBusinessTypes,
  getShowOrHideColumns,
  getSelectedBusinessTypeForPanel,
  getForecastActiveBusinessType,
  isUserRole,
} from 'selectors';
import {
  getActiveFiltersProfile,
  getBestProfileNameByTab,
  getFiltersForCustomViews,
  getViewsForTab,
  getViewNames,
} from 'selectors/openFilters';

type Props = {
  tab: string;
  pinnedView: PinnedView;
  setPinnedView: Dispatch<SetStateAction<PinnedView>>;
  onSelectView: (name: string) => void;
  openCreateNewViewModal: () => void;
};
interface ClosedModal {
  isVisible: false;
}

type OpenModal = {
  isVisible: true;
  confirmationText: string;
  cancelationText: string;
  contentText: string;
} & (DeletingViewModal | RenamingSharedViewModal);

type ConfirmationModal = ClosedModal | OpenModal;
interface DeletingViewModal {
  type: 'DELETING_VIEW';
  id: string;
  name: string;
}

interface RenamingSharedViewModal {
  type: 'SALES_OPS_RENAMING_SHARED_VIEW';
  id: string;
  newName: string;
}

const getModalForDeletingView = (id: string, name: string): OpenModal => ({
  type: 'DELETING_VIEW',
  id,
  name,
  contentText: `Are you sure you want to delete ‘${name}’ view?  It will be permanently removed.`,
  confirmationText: 'Delete',
  cancelationText: 'Cancel',
  isVisible: true,
});

const getModalForRenamingSharedView = (
  newName: string,
  id: string
): OpenModal => ({
  type: 'SALES_OPS_RENAMING_SHARED_VIEW',
  id,
  newName,
  contentText:
    'You are renaming a shared view. Changes made will impact users who have access to this view',
  confirmationText: 'Accept',
  cancelationText: 'Cancel',
  isVisible: true,
});

const getModalForDeletingSharedView = (
  id: string,
  name: string
): OpenModal => ({
  type: 'DELETING_VIEW',
  id,
  name,
  contentText:
    'You are deleting a shared view. Changes made will impact users who have access to this view',
  confirmationText: 'Delete',
  cancelationText: 'Cancel',
  isVisible: true,
});

const CustomViewsDropdown: React.FC<Props> = ({
  tab,
  pinnedView,
  setPinnedView,
  onSelectView,
  openCreateNewViewModal,
}) => {
  const dispatch = useDispatch();
  const { isOpen, setIsOpen, refElement } = useClickOutside();

  const viewsForTab = useSelector((state: IReduxState) =>
    getViewsForTab(state, tab)
  );

  const {
    bestProfileName,
    businessTypes,
    filterProfilesNames,
    persistName,
    selectedView,
  } = useSelector((state: IReduxState) => {
    const { deals_overall_view_enabled } = getFeatureFlags(state);

    return {
      bestProfileName: getBestProfileNameByTab(state, tab),
      businessTypes: getBusinessTypes(state, deals_overall_view_enabled),
      filterProfilesNames: getViewNames(state, tab),
      persistName: getPersistName(state, false),
      properties: getFiltersForCustomViews(state, tab),
      selectedBusinessType: allTabs.includes(tab as BusinessTypeTab)
        ? getSelectedBusinessTypeForPanel(state, tab as BusinessTypeTab)
        : getForecastActiveBusinessType(state),
      selectedView: getActiveFiltersProfile(state, tab),
      showOrHideColumns: getShowOrHideColumns(state, false),
    };
  });

  const isSalesOps = useSelector((state) =>
    isUserRole(state, USER_MANAGEMENT_ROLES)
  );

  const [confirmationModal, setConfirmationModal] = useState<ConfirmationModal>(
    { isVisible: false }
  );

  const [viewInEditId, setViewInEditId] = useState<string>('');

  useEffect(() => {
    // Set pinned view (or default if any) every time CustomViewsDropdown is rendered
    // Basically every page change or refresh
    const allViews = Object.values(viewsForTab).flat();
    const pinnedView: ProfileDataWithName | undefined = allViews.find(
      (p) => p.pinned
    );

    dispatch(
      actions.ui.filters.setActiveProfile({
        tab,
        profile: pinnedView ? pinnedView.name : viewsForTab['DEFAULT'][0].name,
      })
    );
  }, []);

  // When selectedProfile (saved views) change, set the showOrHideColumns
  // and business type according to the saved view
  useEffect(() => {
    if (
      selectedView?.profile.tablePersistName &&
      selectedView?.profile.columnToggleState
    ) {
      dispatch(
        actions.ui.appState.replaceShowOrHideColumns({
          path: selectedView?.profile.tablePersistName,
          columns: selectedView?.profile.columnToggleState,
        })
      );
    } else {
      // backward compatible with older views that does not contain column toggles
      dispatch(
        actions.ui.appState.replaceShowOrHideColumns({
          path: persistName,
          columns: [],
        })
      );
    }
    // dispatch to set business type
    dispatch(
      actions.ui.manageForecast.setActiveBusinessType(
        selectedView?.profile.selectedBusinessType ?? businessTypes[0]
      )
    );
    dispatch(
      actions.ui.filters.setBusinessType({
        businessType:
          selectedView?.profile.selectedBusinessType ?? businessTypes[0],
        tab,
      })
    );
  }, [selectedView?.name]);

  useEffect(() => {
    if (
      selectedView?.name &&
      bestProfileName &&
      selectedView?.name !== bestProfileName
    ) {
      dispatch(
        actions.ui.filters.setActiveProfile({
          tab,
          profile: bestProfileName,
        })
      );
    }
  }, [dispatch, tab, selectedView?.name, bestProfileName]);

  const handleSetRemoveView = (id: string, name: string): void => {
    if (isSalesOps) {
      setConfirmationModal(getModalForDeletingSharedView(id, name));
    } else {
      setConfirmationModal(getModalForDeletingView(id, name));
    }
  };

  const closeConfirmationModal = () => {
    setConfirmationModal({ isVisible: false });
  };

  const handleOnConfirm = (confirmationModal: OpenModal) => {
    switch (confirmationModal.type) {
      case 'DELETING_VIEW':
        removeView(confirmationModal.id, confirmationModal.name);
        break;
      case 'SALES_OPS_RENAMING_SHARED_VIEW':
        renameView(confirmationModal.newName, confirmationModal.id);
        break;
    }

    closeConfirmationModal();
  };

  const removeView = (id: string, name: string) => {
    // Find pinned and set it if different than deleted
    const pinnedProfile: ProfileDataWithName | undefined = Object.values(
      viewsForTab
    )
      .flat()
      .find((p) => p.pinned);

    const hasOtherPinnedProfile = pinnedProfile && pinnedProfile.id !== id;

    dispatch(deleteCustomView(id, tab, name, hasOtherPinnedProfile));

    if (hasOtherPinnedProfile) {
      dispatch(
        actions.ui.filters.setActiveProfile({
          tab,
          profile: pinnedProfile
            ? pinnedProfile.name
            : viewsForTab['DEFAULT'][0].name,
        })
      );
    }
  };

  const viewsSorted = (views: ProfileDataWithName[]) =>
    views.sort((a: ProfileDataWithName, b: ProfileDataWithName) =>
      a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: 'base',
      })
    );

  const defaultViews = viewsForTab.DEFAULT || [];

  const personalViews = viewsSorted(viewsForTab.PERSONAL || []);

  const myViews = [...defaultViews, ...personalViews];

  const sharedViews = viewsSorted(viewsForTab.GLOBAL || []);
  const allViews = [...myViews, ...sharedViews];

  const allViewsPinnedView = allViews.find((view) => view.pinned);
  const pinnedViewId = allViewsPinnedView?.id || '';
  const pinnedViewName = allViewsPinnedView?.name || '';

  useEffect(() => {
    setPinnedView({
      id: pinnedViewId,
      name: pinnedViewName,
    });
  }, [pinnedViewId, pinnedViewName]);

  const renameView = (newName: string, id: string): void => {
    dispatch(renameCustomView({ id, newName }));
  };

  const handleChangePin = (viewIdToPin?: string): void => {
    const isViewAlreadyPinned = pinnedView.id === viewIdToPin;
    const pinningDefaultView = !viewIdToPin;
    let pinDispatchPayload;

    if (pinningDefaultView) {
      pinDispatchPayload = { id: pinnedView.id, newPinnedStatus: false };
    } else {
      pinDispatchPayload = {
        id: viewIdToPin!,
        newPinnedStatus: !isViewAlreadyPinned,
      };
    }

    dispatch(pinCustomView(pinDispatchPayload));
  };

  const handleRenameView = (newName: string, id: string) => {
    if (isSalesOps) {
      setConfirmationModal(getModalForRenamingSharedView(newName, id));
    } else {
      renameView(newName, id);
    }
  };

  const canEditView = (view_type: ProfileDataWithName['view_type']): boolean =>
    view_type !== 'DEFAULT' && (view_type === 'PERSONAL' || isSalesOps);

  return (
    <div className={s.container}>
      <div className={s.view_filters_box} ref={refElement}>
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className={clx(s.view_filters_label, { open: isOpen })}
        >
          <div>
            <BuIcon name={BoostUpIcons.MenuDrawer} color="var(--bu-gray-600)" />
            <span title={selectedView?.name} className="ellipse">
              {selectedView?.name}
            </span>
          </div>

          <BuIcon
            className={s.arrow_up_down}
            name={isOpen ? BoostUpIcons.TriangleUp : BoostUpIcons.TriangleDown}
          />
        </button>

        <div className={clx(s.view_filters_options, { open: isOpen })}>
          <div className={s.saved_views_label_container}>
            <span className={s.saved_views_label}>
              <strong>My Saved Views</strong>
            </span>
          </div>
          {myViews.map(({ id, name, view_type, pinned, is_shared }) => (
            <CustomViewItem
              key={`${view_type}_${id}`}
              tab={tab}
              id={id}
              name={name}
              isPinned={
                Boolean(pinned) ||
                (view_type === 'DEFAULT' && pinnedView.id === '')
              }
              isShared={is_shared}
              isDefaultView={view_type === 'DEFAULT'}
              selectedProfileName={selectedView?.name}
              filterProfilesNames={filterProfilesNames}
              canBeModified={canEditView(view_type)}
              isInEditMode={id === viewInEditId}
              setProfileInEdit={setViewInEditId}
              setIsDropdownOpen={setIsOpen}
              onSelectView={onSelectView}
              onRenameView={handleRenameView}
              onRemoveView={handleSetRemoveView}
              onPinnedChange={handleChangePin}
            />
          ))}
          {sharedViews.length > 0 && (
            <>
              <div className={s.saved_views_label_container}>
                <span className={s.saved_views_label}>
                  <strong>Shared Views</strong>
                </span>
              </div>
              {sharedViews.map(({ id, name, view_type, pinned }) => (
                <CustomViewItem
                  key={`${view_type}_${id}`}
                  tab={tab}
                  id={id}
                  name={name}
                  isPinned={
                    Boolean(pinned) ||
                    (view_type === 'DEFAULT' && pinnedView.id === '')
                  }
                  isDefaultView={false}
                  selectedProfileName={selectedView?.name}
                  filterProfilesNames={filterProfilesNames}
                  canBeModified={canEditView(view_type)}
                  isInEditMode={id === viewInEditId}
                  setProfileInEdit={setViewInEditId}
                  setIsDropdownOpen={setIsOpen}
                  onSelectView={onSelectView}
                  onRenameView={handleRenameView}
                  onRemoveView={handleSetRemoveView}
                  onPinnedChange={handleChangePin}
                />
              ))}
            </>
          )}
          {allViews.length === 1 && (
            <div className={s.create_new_view_container}>
              <p className={s.create_saved_view_p}>
                Create your first Saved view. It will save current state of
                filters, columns and business types.
              </p>
              <BuButton onClick={openCreateNewViewModal}>Create View</BuButton>
            </div>
          )}
        </div>
      </div>

      {confirmationModal.isVisible && (
        <BuConfirmationPopup
          cancelText={confirmationModal.cancelationText}
          confirmText={confirmationModal.confirmationText}
          isOpen={true}
          onClose={closeConfirmationModal}
          onConfirm={() => handleOnConfirm(confirmationModal)}
        >
          <div className={s.confirmationModalContent}>
            {confirmationModal.contentText}
          </div>
        </BuConfirmationPopup>
      )}
    </div>
  );
};

export default CustomViewsDropdown;
