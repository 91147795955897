import { getForecastSubmissionSettings } from './forecastSubmission';
import { isNull } from 'lodash';
import * as R from 'ramda';
import { createSelector } from 'reselect';

import {
  getFiscalPeriod,
  getHiddenFilters,
  getBusinessTypes,
} from 'selectors/settings';

export const getSubmitForecast = (state) => state.submitForecast;

export const getForecastTotals = (state) => state.forecast.totals;

export const getForecastOveride = (state) => state.forecast.overide;

export const getSelectedItem = (state) => state.forecast.totals.selectedItem;

export const getTotalsStatus = (state) => state.forecast.isTotalsUpToDate;

export const forecastOverrideStatus = (state) =>
  state.forecast.setForecastOverride;

const getForecastFiltersCloseDateInterval = (state) =>
  state.deals.forecast.filters.close_date_interval;

const getForecastFiltersCategoryNames = (state) =>
  state.deals.forecast.filters.forecast_category_names;

const getForecastDealsFilter = (state) => state.deals.forecast.filters;

export const isForecastLoading = (state) => state.forecast.isLoading;

export const getCustomFieldsTotals = (state) =>
  state.forecast.customFieldsTotals;

export const getForecastFilters = createSelector(
  getForecastFiltersCloseDateInterval,
  getFiscalPeriod,
  getHiddenFilters,
  getForecastFiltersCategoryNames,
  getForecastDealsFilter,
  (
    closeDateIntervalRaw,
    fiscalPeriod,
    hiddenFilters,
    forecastCategoryNamesRaw,
    dealsFilterForecast
  ) => {
    function getCloseDateInterval() {
      if (isNull(closeDateIntervalRaw)) {
        return fiscalPeriod === 'monthly' ? ['TMO'] : ['TQU'];
      }
      return closeDateIntervalRaw;
    }

    const closeDateInterval = getCloseDateInterval();
    const forecastCategoryNames = hiddenFilters.includes('forecast categories')
      ? []
      : forecastCategoryNamesRaw;

    return {
      ...dealsFilterForecast,
      close_date_interval: closeDateInterval,
      forecast_category_names: forecastCategoryNames,
    };
  }
);

export const getForecastMapping = (state) => {
  return R.pathOr(
    {},
    ['settings', 'companySettings', 'forecast_category_mapping'],
    state
  );
};

export const getManageForecastSettings = (state) => {
  return R.pathOr(
    {},
    ['settings', 'companySettings', 'manage_forecast_settings'],
    state
  );
};

export const getForecastActiveSettingsId = (state) =>
  state.forecastSubmission.activeSettingsId;

export const getForecastActiveBusinessType = (state) =>
  state.forecastSubmission.activeBusinessType;

export const getForecastHistoryPanel = (state) =>
  state.forecastSubmission.historyPanel;

export const getForecastOpenedSettings = (state) =>
  state.forecastSubmission.openedSettings;

export const getForecastSubmissionSettingsLoading = (state) =>
  state.forecastSubmission.isLoading;

export const getForecastPersisted = (state) =>
  state.forecastSubmission.persisted;

export const forecastSubmissionDeadlineConfig = (state) =>
  state.forecastSubmission.profilesDeadlines.find(
    (profile) => profile.is_user_profile
  ) || {};

export const forecastLastSubmissionDate = (state) =>
  state.forecastSubmission.lastSubmissionDate;

export const isForecastSubmissionAvailable = (state) => {
  const companyBusinessType = getBusinessTypes(state);
  const businessType = getForecastActiveBusinessType(state);
  const isOverall = businessType === 'Overall';
  const submissionSettings = getForecastSubmissionSettings(state).map(
    (submission) => submission.business_type_name
  );

  const hasSubmissionAvailables =
    isOverall &&
    submissionSettings.filter((submission) =>
      companyBusinessType.includes(submission)
    );

  const hasSelectedBusinessTypeSubmissions = R.isEmpty(companyBusinessType)
    ? submissionSettings.length
    : submissionSettings.find(
        (submittionName) => submittionName === businessType
      );
  return (
    !!hasSelectedBusinessTypeSubmissions || !!hasSubmissionAvailables?.length
  );
};

export const getRecommendationsFilter = (state, name) =>
  state.deals.recommendationFilters[name];

export const getForecastProgressionFilters = (state) =>
  state.forecast.progressionFilters;

export const getForecastAllChangesDate = (tab) => (state) =>
  state.forecastWidgetDate[tab] ? state.forecastWidgetDate[tab] : [];

export const getForecastDashboardFilters = (state) =>
  state.forecast.dashboardFilters;

export const getForecastOppSplitViewFlag = (state) =>
  state.forecast.oppSplitView;
