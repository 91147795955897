import styled from '@emotion/styled';
import { css } from 'emotion';

export const accPanel = css`
  padding: 0 !important;
`;

export const accPanelTableHeader = css`
  color: #9b9b9b !important;
  background-color: #f4f7f8 !important;
`;

export const accTableRowEditButton = css`
  background-color: transparent !important;
  border: none;
  box-shadow: unset;
`;

export const accPanelRowEditModeButtons = css`
  min-width: 50px;
  padding: 4px;

  &.cancelButton {
    background: #f1a525 !important;
  }

  &.saveButton {
    background: #61e37d !important;
  }
`;

export const accTitle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;

  &.titleActive {
    background-color: #f9f9f9 !important;
  }
`;

export const accTitleActiveColor = css`
  color: #0762ec !important;
`;

export const accTitleMeta = css`
  color: #9b9b9b;
`;

export const accTitleRow = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const accTitleText = css`
  color: #4a4a4a;

  &.titleActive {
    color: #0762ec !important;
  }
`;

export const accTitleTextWithMeta = css`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const hidden = css`
  visibility: hidden;
`;

export const FieldConfigurationSidePanel = {
  mainContent: css`
    display: flex;
    flex-direction: column;
  `,
  fieldWrapper: css`
    display: flex;
    align-items: flex-start;
    margin-bottom: 14px;

    .bu-btn-content {
      div {
        max-width: 100%;
      }
    }
  `,
  label: css`
    color: var(--bu-gray-900);
    flex: 1;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    margin-right: 10px;
    padding-top: 8px;
  `,
  label_checkbox: css`
    color: var(--bu-gray-900);
    flex: 1;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    margin-right: 10px;
    padding-top: 8px;
    margin-left: 10px;
  `,
  legend: css`
    color: var(--bu-gray-900);
    flex: 1;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    margin-right: 10px;
    padding-top: 8px;
    border: 0;
  `,
  fieldNameText: css`
    flex: 3;
    color: var(--bu-gray-900);
    padding-top: 8px;
    font-size: 12px;
    line-height: 18px;
    margin-right: 10px;
    margin-left: 3px;
  `,
  inputWrapper: css`
    flex: 3;
    color: var(--bu-gray-900);

    button {
      width: 100%;
    }
  `,
  checkboxesWrapper: css`
    display: flex;
    flex: 3;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 14px;
  `,
  inputNoteWrapper: css`
    margin-top: 8px;
    display: flex;
    align-items: baseline;
  `,
  inputNote: css`
    color: var(--bu-gray-900);
    font-size: 12px;
    margin-right: 8px;
    margin-left: 3px;
    font-weight: 600;
    line-height: 18px;
  `,
  panelTitle: css`
    height: 49px !important;
    font-size: 18px;
    background-color: white !important;
    flex-shrink: 0;
  `,
  crmTypeLabel: css`
  font-size: 14px;
  font-family: var(--bu-font-medium);
  > span {
    color: var(--bu-gray-700);
    font-family: var(--bu-font-regular);
  },`,
};

export const FieldNameColumnStyle = css`
  .typed-table-cell {
    display: flex;
  }
`;

export const FieldNameTruncated = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
  vertical-align: middle;
`;

export const MissingFieldIcon = css`
  color: var(--bu-red-400);
  margin-left: 4px;
`;

export const backgroundOnHover = css`
  &.ui.accordion.styled {
    .title {
      &:hover,
      &.active {
        background-color: var(--bu-gray-100);
      }
    }

    .content.active {
      padding: 0;

      border-top: 1px solid #dddddd;
    }
  }
`;

export const contentWithoutPadding = css`
  &.ui.accordion.styled {
    .content.active {
      padding: 0;

      border-top: 1px solid var(--bu-gray-400);
    }
  }
`;

export const fixHeadersInAccordion = css`
  &.ui.styled.accordion .ui.header > .content {
    padding: 0 !important;
    padding-left: 0.75em !important;
  }
`;

export const container = css`
  display: flex;
`;
export const box = css`
  display: inline-block;
  min-width: 200px;
  margin-right: 10px;
`;
export const box_btn = css`
  margin-left: auto;
`;
export const select_new_field = css`
  width: 100%;

  button {
    width: 100%;
  }
`;

export const loaderSkeleton = css`
  padding: 10px;
  height: auto;
  width: 100%;
  display: block;
`;

export const question_circle = css`
  border-radius: 50%;
  background: var(--bu-white);
  display: inline-block;
  text-align: center;
  border: 1px solid var(--bu-gray-500);
  width: 20px;
  height: 20px;
  position: relative;
  margin-left: 10px;

  .bu-icon.bu-icon-quick_help {
    margin: 0;
    padding: 0;
    font-size: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const btn_sync = css`
  .bu-btn-content {
    .bu-icon {
      font-size: 16px;
    }
  }
`;

export const formula_field = css`
  padding-right: 5px;
`;

export const formula_icon = css`
  cursor: pointer;
`;

export const field_configuration_item_table = css`
  margin-bottom: 0 !important;
`;
