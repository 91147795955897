import {
  MutableRefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

export interface ElementSize {
  width: number;
  height: number;
}

/**
 * This is a custom React Hook for determining the size of a DOM element.
 *
 * It returns a ref and the current size of the element the ref is attached to.
 * This is particularly useful when you need to get or track the width and height
 * of a DOM element in a functional component.
 *
 * The returned ref should be attached to the element whose size is to be monitored.
 * This hook sets up event listeners to update the size state when the window is resized,
 * and it also re-checks the size of the element whenever the element's offsetWidth or offsetHeight changes.
 *
 * Note: The ref is a MutableRefObject, meaning it can persist for the full lifetime of the component.
 * As a result, the function does not create a new ref each time it is called, but reuses the existing one.
 *
 * @return {Array} An array containing two items:
 *   - The first item is a ref to attach to the element.
 *   - The second item is the current size of the element.
 */
export const useElementSize = <T extends HTMLElement = HTMLDivElement>(): [
  MutableRefObject<T | null>,
  ElementSize
] => {
  // Mutable values like 'ref.current' aren't valid dependencies
  // because mutating them doesn't re-render the component.
  // Instead, we use a state as a ref to be reactive.
  const ref = useRef<T | null>(null);
  const [size, setSize] = useState<ElementSize>({
    width: 0,
    height: 0,
  });

  const handleSize = useCallback(() => {
    if (ref.current) {
      setSize({
        width: ref.current.offsetWidth || 0,
        height: ref.current.offsetHeight || 0,
      });
    }
  }, [ref?.current?.offsetHeight, ref?.current?.offsetWidth]);

  useEffect(() => {
    window.addEventListener('resize', handleSize);
    return () => window.removeEventListener('resize', handleSize);
  }, []);

  useLayoutEffect(() => {
    handleSize();
  }, [ref?.current?.offsetHeight, ref?.current?.offsetWidth]);

  return [ref, size];
};
