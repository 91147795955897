import { FilterProps, FilterList, Values } from './types';

export const CHOOSE_AN_OPTION = 'Choose an option';

export const BULK_CREATE_URL =
  '/api/data/forecast/submission/submission-settings/bulk_create';

export const AVAILABLE_FILTERS_LIST = [
  'opportunity_stages',
  'opportunity_types',
  'forecast_category_names',
  'roles',
];

export const DEFAULT_DROPDOWN_OPTION = {
  checked: true,
  label: 'All',
  value: '__all__',
};

export const isDefaultDropdownOption = (option: Filters.Checkbox) =>
  option.value === DEFAULT_DROPDOWN_OPTION.value;

export const optionMaker = (optionList: string[]) => {
  return optionList.map((text, index) => ({
    key: index,
    text,
    value: text,
  }));
};

export const toTitleCase = (text: string) =>
  text.charAt(0).toUpperCase() + text.substring(1);

export const handleCapitalizePhrase = (sentence: string) => {
  const words = sentence.split(/_+/);
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }
  return words.join(' ');
};

export const getUniqueListBy = (arr: Filters.Checkbox[], key: string) => {
  return [
    ...new Map(arr.map((item: any) => [item[key], item])).values(),
  ] as Filters.Checkbox[];
};

export const formatFilterProps = (arr: FilterProps[]) => {
  return arr.reduce((acc, curr) => {
    const data = curr.filter;
    return { ...acc, [curr.name]: { ...data, checkedAll: false } };
  }, {});
};

export const formatNewFilterList = (filterList: FilterList) => {
  return Object.entries(filterList).reduce((acc, curr) => {
    let item = {};
    if (curr[0] === 'custom_filters') {
      item = { ...curr[1] };
    } else {
      item = { [curr[0]]: curr[1] };
    }
    return { ...acc, ...item };
  }, {}) as FilterList;
};

export const custom_filters = ['Key Deal', 'Health', 'Key Deal'];

export const isAllValueChecked = (values: any[]): boolean =>
  values[0] && values[0].value === '__all__' && values[0].checked;

export const getNewFiltersList = (filters: Record<string, any>) =>
  Object.keys(filters).map((name: string) => ({
    filter: filters[name] as Filters.Config,
    name: name,
  }));

export const extractSelectedOptionsReduce = (
  filters: any,
  filterName: FilterProps
) => {
  const { name: _name, filter } = filterName;
  /**
   * Sending the correct name of the filter to the BE when saving options
   */
  const name =
    _name !== 'forecast_close_date_interval' ? _name : 'close_date_interval';
  const allItemOption = filter.values.find((item) => item.value === '__all__');

  const isAllItemOptionChecked = allItemOption?.checked;
  const filterValueSelected =
    allItemOption && isAllItemOptionChecked
      ? [allItemOption.value]
      : filter.values.filter((el) => el.checked).map((el) => el.value);

  return {
    ...filters,
    [name]: filterValueSelected,
  };
};

export const generateAvailableFilters = (
  filters: string[],
  filterList: FilterList,
  default_filter_values?: { [key: string]: string[] },
  ignorePresetValues?: boolean,
  forceFilterValues?: { [key: string]: string[] }
) =>
  filters.reduce((accFilters, filterName: string) => {
    const newFilterList = formatNewFilterList(filterList);
    /**
     * When in Forecast Settings we need to load a different filter config
     * from the filters list, but still using the other info from the
     * main filter (e.g. default values, labels)
     */
    let configs =
      filterName !== 'close_date_interval'
        ? newFilterList[filterName]
        : newFilterList['forecast_close_date_interval'];
    let fields: any[] = [];

    const flatUserTeamsTree = (
      items: Filters.UserDealFilterProps[]
    ): Filters.UserDealFilterProps[] =>
      items.flatMap((item: any) =>
        item.team?.length > 0 ? flatUserTeamsTree(item.team) : [item]
      );

    const flatFilterChildrenTree = (items: any) =>
      items.flatMap((item: any) =>
        item.children?.length > 0
          ? [item, ...flatFilterChildrenTree(item.children)]
          : [item]
      );

    const configFields: any = configs?.fields;

    if (configFields.children) {
      fields = flatFilterChildrenTree(configFields.children).map(
        (item: any) => {
          return { label: item.name, value: item.id };
        }
      );
    } else if (configs.title === 'Users') {
      fields = flatUserTeamsTree(configFields).map((item: any, index: number) =>
        index !== 0 ? { label: item.name, value: item.email } : item
      );
    } else {
      fields = configFields;
    }

    configs = {
      ...configs,
      fields,
    };

    const defaultValues =
      default_filter_values && default_filter_values[filterName];

    // if defaultValues is empty means all option is selected.

    const allSelected = !defaultValues?.length;
    // @ts-ignore
    let newValues = configs.fields.map((field: any) => {
      const isArray = Array.isArray(field);

      const checked = isArray
        ? defaultValues?.includes(field[0])
        : defaultValues?.includes(field.value);
      const label = isArray ? field[1] : field.label;
      const value = isArray ? field[0] : field.value;

      return {
        label,
        value,
        checked:
          filterName === 'opportunity_stages' &&
          configs.preset_values &&
          configs.preset_values.length &&
          ignorePresetValues
            ? configs.preset_values.includes(field[0])
            : allSelected
            ? field[0] === '__all__'
            : checked,
      };
    });

    if (forceFilterValues && forceFilterValues[filterName]) {
      newValues = newValues.filter((v: any) =>
        forceFilterValues[filterName].includes(v.label)
      );
    }
    // We have to handle this special close_date_interval case
    // For now this is a workaround, a proper solution will be added in the future
    // So this should be temporal
    // close_date_interval can have some value set, that we don't have on the possible dropdown values
    // as we get the values from the same endpoint as the opportunities filters, there are more possible values
    // but the endpoint only returns a subset of those values as not all are necesary
    // BUT in this case, forecast setting can have a different value that is not on the subset
    // Check https://vocalo.atlassian.net/browse/VPD-8506 for more context
    let isHidden = false;
    if (filterName === 'close_date_interval') {
      const valuesNotInDropdown = defaultValues?.filter(
        (valueSelected) =>
          !configs.fields?.some(
            (valuesOnDropdown: any) =>
              (valuesOnDropdown as Values).value === valueSelected
          )
      );
      const mappedValuesToDropdown =
        valuesNotInDropdown?.map((value) => ({
          checked: true,
          label: value,
          value,
        })) || [];
      newValues = [...newValues, ...mappedValuesToDropdown];
      isHidden = !!valuesNotInDropdown?.length;
    }

    const newConfig = {
      ...configs,
      checkedAll: false,
      checkedTotal: newValues.length - 1,
      isChange: true,
      values: newValues,
      group: undefined,
      isLocked: false,
      isHidden,
      withShowAll: true,
    };
    return { ...accFilters, [filterName]: newConfig };
  }, {});
