import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Calendar, { CalendarTileProperties } from 'react-calendar';
import { formatTo } from 'sharedLogicMainAppAndRevBi/timeSpanOptions';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import 'components/UI/TimeFilter/TabsForFilter/StylesForCalendar.css';
import * as s from 'components/UI/TimeFilter/styles';
import {
  BetweenDate,
  FiltersConfig,
} from 'components/dashboard/Metrics/common/Calendar/types';

const formatDate = (date: number): string =>
  moment.unix(date / 1000).format('MM/DD/YYYY');

const warningMessages = [
  { key: 1, value: 'End Date can not be before the Start Date' }, //END < START
  { key: 2, value: 'End date can not be in the future' }, // END > now
  { key: 3, value: 'Start date can not be in the future' }, // START > now
  { key: 4, value: 'Start date can not be after End date' }, // START > END
];

type Props = {
  config?: FiltersConfig;
  betweenDate: BetweenDate;
  setBetweenDate: (value: BetweenDate) => void;
  onChange: (value: string) => void;
};

const CustomRangeTab: React.FC<Props> = ({
  config,
  betweenDate,
  setBetweenDate,
  onChange,
}) => {
  const [isWarningMessage, setWarningMessage] = useState({
    isOpen: false,
    message: '',
  });
  const [date, setDate] = useState({
    startDate: betweenDate.startDate,
    endDate: betweenDate.endDate,
  });
  const refStartDate = useRef<HTMLInputElement>(null);
  const allowFuture = config ? config.allowFuture || false : true;

  useEffect(() => {
    if (refStartDate.current !== null) {
      refStartDate.current.focus();
    }
  }, [refStartDate]);

  const isValidInput = (date: string, type: string) => {
    const isDateValid = moment(date, 'MM/DD/YYYY', true).isValid();
    let isEndDateAppropriate = true;
    let isStartDateAppropriate = true;
    if (type === 'startDate') {
      if (!allowFuture) {
        isStartDateAppropriate = moment(date, 'MM/DD/YYYY', true).isBefore(
          moment()
        );
      } else {
        isStartDateAppropriate = moment(date, 'MM/DD/YYYY', true).isBefore(
          moment(betweenDate.endDate, 'MM/DD/YYYY')
        );
      }
    }
    if (type === 'endDate') {
      isEndDateAppropriate = moment(date, 'MM/DD/YYYY', true).isAfter(
        moment(betweenDate.startDate, 'MM/DD/YYYY')
      );
    }
    return isDateValid && isEndDateAppropriate && isStartDateAppropriate;
  };

  const handleChangeInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    if (name === 'start_date') {
      setDate({ ...date, startDate: value });
      if (isValidInput(value, 'startDate')) {
        setBetweenDate({ ...betweenDate, startDate: value });
      }
      return;
    }

    if (name === 'end_date') {
      setDate({ ...date, endDate: value });

      if (isValidInput(value, 'endDate')) {
        setBetweenDate({ ...betweenDate, endDate: value });
      }
      return;
    }
  };

  const handleSendDate = (e: React.KeyboardEvent) => {
    const dateStart = formatTo(
      betweenDate.startDate,
      'MM/DD/YYYY',
      'YYYY-MM-DD'
    );
    const dateEnd = formatTo(betweenDate.endDate, 'MM/DD/YYYY', 'YYYY-MM-DD');

    if (e.key === 'Enter') {
      onChange(`${dateStart},${dateEnd}`);
    }
  };

  const onClickStartDate = (data: Date) => {
    const date = formatDate(data.getTime());
    const startForApi = formatTo(date, 'MM/DD/YYYY', 'YYYY-MM-DD');
    const endForApi = formatTo(betweenDate.endDate, 'MM/DD/YYYY', 'YYYY-MM-DD');
    const isBeforeDate = moment(date).isBefore(
      moment(betweenDate.endDate, 'MM/DD/YYYY').toDate()
    );
    const isBeforeDateNow = !moment(moment.now()).isBefore(
      moment(date, 'MM/DD/YYYY').toDate()
    );

    if (betweenDate.startDate === '' || betweenDate.startDate !== '') {
      if (betweenDate.endDate !== '') {
        if (isBeforeDate) {
          if (!allowFuture) {
            setBetweenDate({ ...betweenDate, startDate: date });
            setDate({ ...betweenDate, startDate: date });
            if (!isBeforeDateNow) {
              setWarningMessage({
                isOpen: true,
                message: warningMessage(data, 'start'),
              });
            } else {
              onChange(`${startForApi},${endForApi}`);
            }
            return;
          }

          setBetweenDate({ ...betweenDate, startDate: date });
          setDate({ ...betweenDate, startDate: date });
          onChange(`${startForApi},${endForApi}`);
          return;
        } else {
          setWarningMessage({
            isOpen: true,
            message: warningMessage(data, 'start'),
          });
          setBetweenDate({ ...betweenDate, startDate: date });
          setDate({ ...betweenDate, startDate: date });
          return;
        }
      } else {
        if (isBeforeDate) {
          setBetweenDate({ ...betweenDate, startDate: date });
          setDate({ ...betweenDate, startDate: date });
          if (!isBeforeDateNow) {
            setWarningMessage({
              isOpen: true,
              message: warningMessage(data, 'start'),
            });
          } else {
            onChange(`${startForApi},${endForApi}`);
          }
          return;
        }
        setBetweenDate({ ...betweenDate, startDate: date });
        setDate({ ...betweenDate, startDate: date });
      }
    }
  };

  const onClickEndDate = (data: Date) => {
    const date = formatDate(data.getTime());
    const startForApi = formatTo(
      betweenDate.startDate,
      'MM/DD/YYYY',
      'YYYY-MM-DD'
    );
    const endForApi = formatTo(date, 'MM/DD/YYYY', 'YYYY-MM-DD');
    const isBeforeDate = !moment(date).isBefore(
      moment(betweenDate.startDate, 'MM/DD/YYYY').toDate()
    );
    const isBeforeDateNow = !moment(moment.now()).isBefore(
      moment(date, 'MM/DD/YYYY').toDate()
    );

    if (betweenDate.endDate === '' || betweenDate.endDate !== '') {
      if (betweenDate.startDate !== '') {
        if (isBeforeDate) {
          if (!allowFuture) {
            setBetweenDate({ ...betweenDate, endDate: date });
            setDate({ ...betweenDate, endDate: date });
            if (!isBeforeDateNow) {
              setWarningMessage({
                isOpen: true,
                message: warningMessage(data, 'end'),
              });
            } else {
              onChange(`${startForApi},${endForApi}`);
            }
            return;
          }

          setBetweenDate({ ...betweenDate, endDate: date });
          setDate({ ...betweenDate, endDate: date });
          onChange(`${startForApi},${endForApi}`);
          return;
        } else {
          setWarningMessage({
            isOpen: true,
            message: warningMessage(data, 'end'),
          });
          setBetweenDate({ ...betweenDate, endDate: date });
          setDate({ ...betweenDate, endDate: date });
          return;
        }
      } else {
        if (!allowFuture) {
          setBetweenDate({ ...betweenDate, endDate: date });
          setDate({ ...betweenDate, endDate: date });
          if (!isBeforeDateNow) {
            setWarningMessage({
              isOpen: true,
              message: warningMessage(data, 'end'),
            });
          } else {
            onChange(`${startForApi},${endForApi}`);
          }
          return;
        }
        setBetweenDate({ ...betweenDate, endDate: date });
        setDate({ ...betweenDate, endDate: date });
      }
    }
  };

  const tileClassNameStart = (data: CalendarTileProperties) => {
    const { date } = data;
    if (moment(date).format('MM/DD/YYYY') === betweenDate.startDate) {
      return ['react-calendar__tile--rangeStart'];
    }
    if (betweenDate.startDate === '') {
      return ['react-calendar-b-disabled'];
    }
    return [];
  };

  const tileClassNameEnd = (data: CalendarTileProperties) => {
    const { date } = data;
    if (moment(date).format('MM/DD/YYYY') === betweenDate.endDate) {
      return ['react-calendar__tile--rangeEnd'];
    }
    if (betweenDate.endDate === '') {
      return ['react-calendar-b-disabled'];
    }
    return [];
  };

  const warningMessage = (date_e: Date, interval: 'start' | 'end') => {
    const dateNow = moment().toNow();
    const dateIsAfterNow = !moment(date_e).isAfter(dateNow);
    const isEndBeforeStart =
      interval === 'end' &&
      moment(date_e).isBefore(
        moment(betweenDate.startDate, 'MM/DD/YYYY').toDate()
      );

    if (!allowFuture) {
      if (interval === 'end' && isEndBeforeStart) {
        return warningMessages[0].value;
      }

      if (interval === 'start' && dateIsAfterNow) {
        // Start > now
        return warningMessages[2].value;
      }

      if (interval === 'end' && dateIsAfterNow) {
        // End > now
        return warningMessages[1].value;
      }
    }
    return warningMessages[0].value;
  };

  return (
    <div className={s.customWrapper}>
      <div className={s.custom_calendar_header}>
        <div className={s.custom_calendar_header__first_column}>
          <div>
            <span className={s.custom_title_range}>Start date</span>
            <input
              ref={refStartDate}
              onChange={(e) => handleChangeInputValue(e)}
              onKeyDown={(e) => handleSendDate(e)}
              className={classNames(s.custom_input, {
                isValid: !isValidInput(date.startDate, 'startDate'),
              })}
              placeholder="MM/DD/YYYY"
              value={date.startDate}
              maxLength={10}
              name="start_date"
              type="text"
              data-testing="txt_field"
            />
          </div>
          <div>
            <span className={s.custom_title_range}>End date</span>
            <input
              onChange={(e) => handleChangeInputValue(e)}
              onKeyDown={(e) => handleSendDate(e)}
              className={classNames(s.custom_input, {
                isValid: !isValidInput(date.endDate, 'endDate'),
              })}
              placeholder="MM/DD/YYYY"
              value={date.endDate}
              maxLength={10}
              name="end_date"
              type="text"
              data-testing="txt_field"
            />
          </div>
        </div>
      </div>

      <div className={s.double_calendar}>
        <Calendar
          onClickDay={onClickStartDate}
          tileClassName={tileClassNameStart}
          value={
            moment(betweenDate.startDate).isValid()
              ? moment(betweenDate.startDate).toDate()
              : undefined
          }
          locale="en-EN"
          formatShortWeekday={(locale, value) =>
            ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'][value.getDay()]
          }
          prevLabel={<BuIcon name={BoostUpIcons.ChevronLeft} />}
          nextLabel={<BuIcon name={BoostUpIcons.ChevronRight} />}
        />
        <Calendar
          onClickDay={onClickEndDate}
          tileClassName={tileClassNameEnd}
          value={
            moment(betweenDate.endDate).isValid()
              ? moment(betweenDate.endDate).toDate()
              : undefined
          }
          locale="en-EN"
          formatShortWeekday={(locale, value) =>
            ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'][value.getDay()]
          }
          prevLabel={<BuIcon name={BoostUpIcons.ChevronLeft} />}
          nextLabel={<BuIcon name={BoostUpIcons.ChevronRight} />}
        />

        {isWarningMessage.isOpen && (
          <div className={s.message_warning}>
            <div className={s.message_warning_title}>Warning!</div>
            <p>{isWarningMessage.message}</p>
            <BuButton
              size={BuControlSize.REGULAR}
              onClick={() => setWarningMessage({ isOpen: false, message: '' })}
            >
              Ok
            </BuButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomRangeTab;
