import styled from '@emotion/styled/macro';
import { css } from 'emotion';

import { fontDefaultDropdown, fontDefault } from 'assets/css/common';
import * as styles from 'components/UI/TwoColumnsDashboard/styles';

// BEWARE THIS FILE IS ALSO USED ON NEW FORCAST, NOT LIMITED TO ROLLUPS

export const rollupsWrapper = css`
  .container-dashboard {
    overflow-y: hidden;
  }

  .${styles.panel} {
    width: 400px;
  }
`;

export const rollupsContent = css`
  flex: 1;
  overflow: hidden;
  z-index: 9;

  .ui.table.structured.compact > tbody > tr {
    td {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    &.topRow {
      background-color: var(--bu-white);

      &:nth-child(2n) {
        background-color: var(--bu-white);

        td:first-child {
          background-color: var(--bu-white);
        }

        &:hover td {
          background: var(--bu-gray-200);
        }
      }

      td:first-child {
        background-color: var(--bu-white);
      }

      &:hover td {
        background: var(--bu-gray-200);
      }
    }

    &.empty {
      .typed-table-cell {
        opacity: 0;
        pointer-events: none;

        .hoverable {
          color: white;
        }
      }
    }

    &.inactive {
      background: var(--bu-gray-200);

      &:nth-child(2n) {
        background: var(--bu-gray-200);

        td:first-child {
          background: var(--bu-gray-200);
        }

        &:hover td {
          background: var(--bu-gray-200);
        }
      }

      td:first-child {
        background: var(--bu-gray-200);
      }

      &:hover td {
        background: var(--bu-gray-200);
      }

      .typed-table-cell > div,
      .typed-table-cell .hoverable {
        color: #9b9b9b;
      }
    }

    &:nth-child(2n) {
      background-color: var(--bu-white);

      td:first-child {
        background-color: var(--bu-white);
      }

      &:hover td {
        background: var(--bu-gray-200);
      }
    }
  }

  .primaryField {
    font-weight: 600;
  }

  .topRow {
    .typed-table-cell:not(.typed-table-cell-type-score) {
      font-weight: normal;
    }
  }
`;

export const wrapper = css`
  height: 100%;
  flex-direction: column;

  .container,
  .container-dashboard {
    height: 100%;
    overflow-x: hidden;
  }

  .container {
    padding: 0;
  }

  .container-dashboard {
    display: flex;
    flex-direction: column;
  }
`;

export const buttons_container = css`
  display: flex;
  align-items: center;
  margin-left: 5px;
`;

export const includeDisabledUsersButtons = css`
  margin-right: 10px;
`;

export const refreshButtonWrapper = css`
  > div {
    margin: 0;
  }
`;

export const dropdown_container = css`
  margin-left: auto;
  margin-right: 10px;
`;

export const header = css`
  margin: 0 20px;
  z-index: 10;
`;

export const exportBtn = css`
  border: none;
  padding: 0 !important;

  i {
    font-size: 18px !important;
  }

  &:hover {
    background-color: var(--bu-white) !important;
  }

  .bu-down,
  .bu-up {
    display: none;
  }
`;

export const removeClickStyles = css`
  color: var(--bu-gray-900) !important;
  cursor: text !important;
  text-decoration: none !important;
`;

export const content = css`
  flex: 1;
  overflow: hidden;
  z-index: 9;

  .ui.table.structured.compact > tbody > tr {
    &.topRow {
      background-color: var(--bu-white);

      &:nth-child(2n) {
        background-color: var(--bu-white);

        td:first-child {
          background-color: var(--bu-white);
        }

        &:hover td {
          background: var(--bu-gray-200);
        }
      }

      td:first-child {
        background-color: var(--bu-white);
      }

      &:hover td {
        background: var(--bu-gray-200);
      }
    }

    &.empty {
      .typed-table-cell {
        opacity: 0;
        pointer-events: none;

        .hoverable {
          color: white;
        }
      }
    }

    &.inactive {
      background: var(--bu-gray-200);

      &:nth-child(2n) {
        background: var(--bu-gray-200);

        td:first-child {
          background: var(--bu-gray-200);
        }

        &:hover td {
          background: var(--bu-gray-200);
        }
      }

      td:first-child {
        background: var(--bu-gray-200);
      }

      &:hover td {
        background: var(--bu-gray-200);
      }

      .typed-table-cell > div,
      .typed-table-cell .hoverable {
        color: #9b9b9b;
      }
    }

    &:nth-child(2n) {
      background-color: var(--bu-white);

      td:first-child {
        background-color: var(--bu-white);
      }

      &:hover td {
        background: var(--bu-gray-200);
      }
    }
  }

  .primaryField {
    font-weight: 600;
  }

  .topRow {
    .typed-table-cell:not(.typed-table-cell-type-score) {
      font-weight: normal;
    }
  }
`;

export const primaryFieldSubValue = css`
  font-size: 0.9em;
  font-weight: normal;
  margin-top: 2px;
`;

export const tooltipMyOrg_role = css`
  color: var(--bu-gray-700);
`;

export const tooltipMyOrg_email = css`
  color: var(--bu-primary-500);
  padding-top: 5px;
`;

export const tooltip_line = css`
  padding-bottom: 5px;
  color: var(--bu-gray-900);

  span {
    color: var(--bu-gray-700);
  }
`;

export const htmlTooltip = css`
  padding: 10px 20px 0 3px;
  min-width: 350px;
  max-width: 400px;
  width: 100%;
  font-size: 14px;
  max-height: 400px;
  overflow-y: scroll;

  p {
    margin-bottom: 0.5em;
  }

  > ul {
    margin-top: 15px;
    padding: 0;
    list-style: none;

    > li {
      border: 1px solid var(--bu-gray-500);
      border-radius: 4px;
      color: #4c4c4c;
      margin-bottom: 15px;
      padding: 7px 20px 10px 20px;
      position: relative;
      ${fontDefault};

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background: #30b0d1;
        display: inline-block;
        width: 6px;
      }
    }
  }
`;

export const percentGreen = css`
  color: var(--bu-green-500);
  ${fontDefaultDropdown};
  padding: 2px 5px;
  border-radius: 4px;

  &:hover {
    background: rgba(41, 188, 98, 0.1);
  }
`;

export const percentRed = css`
  color: #f05a30;
`;

export const toggleContainer = css`
  margin: 10px 20px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DeltasTag = styled.span`
  margin: 0 10px;
  border-radius: var(--bu-control-border-radius);
  padding: 4px 9px;
  color: var(--bu-orange-500);
  background-color: var(--bu-orange-300);
  font-size: 0.85em;
`;

export const fullSize = css`
  width: 100%;
  height: 100%;
`;

export const hidden = css`
  display: none;
`;

export const extraMargin = css`
  margin-top: 12px;
`;

export const rightActionsContent = css`
  display: flex;
  margin-top: 10px;
`;

export const subtitle = css`
  ${fontDefault};
  font-size: 14px;
`;

export const dropdownItem = css`
  ${fontDefault};
`;

export const ExclamationIconWrapper = styled.div`
  display: flex;
  position: relative;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 16px;

  i {
    color: var(--bu-red-400);
    font-size: 14px;
  }
`;

export const btActiveUsers = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  > div {
    margin: 0;
  }
`;

export const exportBtnWrapper = css`
  margin-left: 5px;
`;

export const activeUsersMetricsWrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const activeUsersMetricsDivider = css`
  width: 1px;
  height: 16px;
  border-right: 1px solid var(--bu-gray-300);
  margin: 0 10px;
`;
