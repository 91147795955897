import styled from '@emotion/styled';

export const MessageSpan = styled.span((props: { isValid?: boolean }) => ({
  marginLeft: '8px',
  fontSize: '12px',
  color: props.isValid ? 'var(--bu-gray-700)' : 'var(--bu-red-600)',
}));

export const InputContainer = styled.div({
  marginBottom: '24px',
  marginRight: '4px',
});

export const RadiosContainer = styled.div({
  marginTop: '8px',
});
