import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { TABLE_ID } from 'common/constants';
import { DownloadButtonProps } from 'components/UI/DownloadButton/types';
import Table from 'components/UI/TableConfig/Table';
import {
  ColumnTypesCallback,
  columnTypes,
  styleFirstVisibleColumn,
  typeToScheme,
} from 'components/UI/TableConfig/column-helper';
import {
  TableConfigurationData,
  isColumnConfigClickable,
} from 'components/UI/TableConfig/types';
import {
  IColumn,
  IRow,
  SortOrder,
} from 'components/UI/common/TypedTable/TypedTable';
import { currencyFormatter } from 'components/UI/common/TypedTable/formatters';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { getDrilldownUrlQueryParams } from 'components/dashboard/Metrics/Widget/metrics.widget.helpers';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import { usePageSize } from 'components/hooks/usePageSize';
import {
  CsvColumns,
  DrilldownDataResponse,
  DrilldownQueryParams,
} from 'components/modals/types';
import { css } from 'emotion';
import { ModalPersistParams } from 'navigation/types';
import { openModal } from 'navigation/utils';
import { IReduxState } from 'reducers/types';
import { getModalProps, isMulticurrencyEnabled } from 'selectors';
import { QueryStatus, fetchApi } from 'utils/network';

const modalHeight = css`
  height: 80vh;
`;

export const OpportunitySplitModal: React.FC = () => {
  const { currency: companyCurrencyCode } = useContext(RevBISettingsContext);

  const isMultiCurrencyEnabled = useSelector(isMulticurrencyEnabled);
  const params: ModalPersistParams = useSelector((state: IReduxState) =>
    getModalProps(state, '/opportunity-split')
  );

  const [tableColumns, setTableColumns] = useState<IColumn[]>([]);
  const [csvColumns, setCsvColumns] = useState<CsvColumns[]>([]);
  const [rows, setRows] = useState<IRow[]>([]);
  const [count, setCount] = useState<number>(0);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [offsetIndex, setOffsetIndex] = useState<number>(0);
  const [oppSplitPageSize] = usePageSize('RevbiOpportunitySplit', 15);
  const [paginationIndex, setPaginationIndex] = useState<number>(1);
  const [orderByExpression, setOrderByExpression] = useState<
    string | undefined
  >('id');
  const [dataStatus, setDataStatus] = useState<QueryStatus>('notAsked');

  const handleOpportunitiesTableSpecialClick: ColumnTypesCallback = (props) => {
    if (
      isColumnConfigClickable(props.columnConfig) &&
      props.columnConfig.meta.object_id &&
      props.row?.opportunity
    ) {
      const scheme = typeToScheme[props.columnConfig.meta.object_id];
      const opp: IRow = props.row.opportunity as IRow;

      if (scheme) {
        openModal({
          scheme,
          params: { id: opp[props.columnConfig.meta.object_id] as string },
        });
      } else {
        console.warn('Action not supported');
      }
    }
  };

  const handlePageChange = (pageNumber: number, _: number) => {
    setOffsetIndex(pageNumber - 1);
    setPaginationIndex(pageNumber);
  };

  const getColumnType = columnTypes(
    {
      currencyFormatter: currencyFormatter(
        companyCurrencyCode,
        0,
        isMultiCurrencyEnabled
      ),
      sequence: [],
    },
    handleOpportunitiesTableSpecialClick,
    '',
    isMultiCurrencyEnabled
  );

  useEffect(() => {
    fetchApi<void, { data: TableConfigurationData }>({
      url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/tables/opportunity_split/RevbiOpportunitySplit`,
      queryMethod: 'get',
      setData: (result) => {
        const data = result?.data ?? {};
        const { columns, order } = data;

        setOrderByExpression(order.object_field);

        setCsvColumns(
          columns.map((c) => ({
            display_name: c.display_name,
            object_field:
              c.object_field === 'crm_metadata.opportunity_name'
                ? 'opportunity_name'
                : c.object_field,
          }))
        );

        const parsedColumns = (columns || [])
          .map<IColumn>((column, index) => ({
            field: column.object_field,
            sort_order: SortOrder.DESCENDING,
            sortable: column.sortable,
            id: `${column.field_name}-${index}`,
            label: column.display_name,
            ...getColumnType(column, columns),
            editable: column.editable,
          }))
          .map((column) => ({
            ...column,
            showTooltip: ![
              ColumnTypes.NUMBER,
              ColumnTypes.PERCENT,
              ColumnTypes.MONEY,
            ].includes(column.type),
          }));

        styleFirstVisibleColumn(parsedColumns, 400);

        setTableColumns(parsedColumns);
      },
      setError: () => {
        toast.error('Failed to load drilldown columns');
      },
    });
  }, []);

  const drilldownUrlQueryParams = getDrilldownUrlQueryParams(
    params.drilldownParams
  );

  useEffect(() => {
    if (params?.metricObject && tableColumns.length) {
      const columns = tableColumns.map(({ field }) => field);
      const queryParams: DrilldownQueryParams = {
        ...params.drilldownParams,
        columns: columns,
        limit: oppSplitPageSize,
        offset: offsetIndex,
        order_by_expression: orderByExpression || '',
      };

      fetchApi<DrilldownQueryParams, DrilldownDataResponse>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/drill_down/${params.metricObject}/data${drilldownUrlQueryParams}`,
        queryMethod: 'post',
        queryParams: queryParams,
        setData: (result) => {
          const data = result ?? [];
          data.count && setCount(data.count);
          data.total_amount && setTotalAmount(data.total_amount);

          const parsedDeals: IRow[] = data?.rows?.map((deal: Deals.Deal) => ({
            ...deal,
            id: `${deal._id}`,
            crm_metadata:
              deal.crm_metadata && deal.crm_metadata.next_step === null
                ? { ...deal.crm_metadata, next_step: '' }
                : deal.crm_metadata,
          }));

          setRows(parsedDeals);
        },
        setStatus: setDataStatus,
        setError: () => {
          toast.error('Failed to load drilldown data');
          setRows([]);
        },
      });
    }
  }, [
    params?.metricObject,
    params.drilldownParams,
    tableColumns,
    offsetIndex,
    orderByExpression,
  ]);

  const downloadButton: DownloadButtonProps = useMemo(
    () => ({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/drill_down/${params?.metricObject}/download${drilldownUrlQueryParams}`,
      queryMethod: 'post',
      serializedQueryParams: JSON.stringify({
        drill_down: {
          ...params?.drilldownParams,
          limit: 100000,
          offset: 0,
          order_by_expression: orderByExpression || '',
        },
        columns: csvColumns,
      }),
    }),
    [params, csvColumns, orderByExpression]
  );

  const tableTitle: string = useMemo(() => {
    if (params) {
      if (params?.pivotValue) {
        return `${params?.metricName}`;
      } else {
        return `${params?.metricName}`;
      }
    }

    return 'Opportunity Split';
  }, [params]);

  return (
    <div className={modalHeight}>
      <Table
        tableId={TABLE_ID.SPLITS_MODAL}
        pinnableColumns
        hidePaginationEnd
        hideSearch
        fullscreen
        isModal
        showColumnsVisibilityToggle
        totalAmount={totalAmount}
        loading={dataStatus !== 'success' && dataStatus !== 'error'}
        title={tableTitle}
        totalCount={count}
        currentPage={paginationIndex}
        rowsPerPage={oppSplitPageSize}
        downloadButton={downloadButton}
        data={rows}
        columns={tableColumns}
        sortOrder={orderByExpression}
        showTotalAmount={params.showTotalAmount}
        onSort={setOrderByExpression}
        onPaginationChange={handlePageChange}
      />
    </div>
  );
};
