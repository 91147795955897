import { css } from 'emotion';

export const impersonationContainer = css`
  margin-left: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  background: #fdf6e7;
  color: var(--bu-red-400);

  & > span {
    color: var(--bu-gray-900);
    margin: 0 4px;
  }
`;
