import get from 'lodash/get';

export const getAccount = (state, id) => {
  const account = get(state, ['accounts', 'account']);
  if (id && account.id === id) {
    return account;
  }

  if (id && account.salesforce_url && account.salesforce_url.includes(id)) {
    return account;
  }
  return {};
};

export const getAccountsStatus = (state) =>
  get(state, ['accounts', 'list', 'status']);
export const getAccountDeals = (state) => get(state, ['accounts', 'deals']);
export const getAccountCalculatedData = (state) =>
  get(state, ['accounts', 'calculatedData']);

export const getAccounts = (state) => get(state, ['accounts', 'list']);
