import { css } from 'emotion';

import { fontBody } from 'assets/css/fontStyles';

export const additionalMetricsBlock = css`
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
  align-items: center;
`;

export const metricsList = css`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  height: 100%;
`;

export const metric = css`
  min-height: 46px;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  border: solid var(--bu-gray-400) 1px;
  border-radius: 4px;
  margin: 2px;
  -webkit-box-flex: 1;
  width: fit-content;
  max-width: 100%;
  flex: 1 1 200px;
  padding: 4px 8px;
  ${fontBody}
  .metric-name {
    color: var(--bu-gray-700);
    font-size: 12px;
    margin: 0px;
  }
  .metric-value {
    color: var(--bu-gray-900);
  }
`;
