import * as s from './styles';
import styled from '@emotion/styled';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Input from 'components/UI/Input';
import SearchIcon from 'assets/images/icons/search.svg';
import CloseTagFilterIcon from 'assets/images/new_icon/close_tag_filter.svg';

class SearchBox extends Component {
  render() {
    const { className, reset, ...otherProps } = this.props;

    return (
      <div
        className={classnames('search-container', className, s.searchContainer)}
      >
        <img className={s.search_icon} src={SearchIcon} alt="" />
        <Input placeholder="Search" {...otherProps} data-testing="txt_field" />
        {this.props.value !== '' && (
          <div className={s.clear_icon__container} onClick={reset}>
            <img className={s.clear_icon} src={CloseTagFilterIcon} alt="" />
          </div>
        )}
      </div>
    );
  }
}

SearchBox.propTypes = {
  scale: PropTypes.number,
};

SearchBox.defaultProps = {
  scale: 1,
};

export default styled(SearchBox)`
  label: search-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  input {
    height: ${(props) => Math.round(35 * props.scale)}px;
    padding-left: ${(props) => Math.round(40 * props.scale)}px;
    padding-right: 35px;

    &::placeholder {
      color: #777;
    }
  }
`;
