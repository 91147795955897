import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'actions';
import closeIcon from 'assets/images/close.svg';
import backIcon from 'assets/images/new_icon/back_arrow.svg';
import HistoryItem from 'components/dashboard/Forecast/Dashboard/SubmitForecast/HistoryItem';
import * as styles from 'components/dashboard/Forecast/Dashboard/SubmitForecast/styles';
import { Formatter } from 'components/dashboard/Forecast/Dashboard/SubmitForecast/types';
import { IReduxState } from 'reducers/types';
import { getForecastSubmissionSettingsById } from 'selectors';

type Props = {
  formatter: Formatter;
  onBack?: () => void;
  onClose?: () => void;
  panel?: boolean;
  setActiveBusinessType?: (settingId: string) => void;
  settingId: string;
};

const History: React.FC<Props> = ({
  formatter,
  onBack = () => {},
  onClose = () => {},
  panel,
  setActiveBusinessType,
  settingId: settingsId,
}) => {
  const dispatch = useDispatch();

  const setting = useSelector((state) =>
    getForecastSubmissionSettingsById(state as IReduxState, settingsId)
  );

  const { history, period_info } = setting || {};

  if (!setting || !history || !history.length) {
    return null;
  }

  if (panel) {
    return (
      <div className={styles.panelContainer}>
        <div className={styles.panelHeader}>
          <div className="row">
            <div className="back" onClick={onBack}>
              <img
                alt="chevron left"
                className={styles.backIcon}
                src={backIcon}
              />
            </div>

            <div className="main-text-extended">
              <span>Previous Submissions</span>
              <br /> {period_info}
            </div>

            <img
              alt="close"
              className={styles.closeButton}
              onClick={onClose}
              src={closeIcon}
            />
          </div>
        </div>

        <div className={classNames(styles.panelBody, styles.panelBodyHistory)}>
          {history.map((historyItem) => (
            <HistoryItem
              key={`submissionHistoryItem-${historyItem.id}`}
              submissionSettingId={settingsId}
              formatter={formatter}
              historyItem={historyItem}
            />
          ))}
        </div>
      </div>
    );
  }

  const onClickViewAll = () => {
    setActiveBusinessType && setActiveBusinessType(setting.id);

    dispatch(
      actions.ui.manageForecast.setHistoryPanel({ settingsId: setting.id })
    );
  };

  return (
    <div className={classNames(styles.history, { single: true })}>
      <HistoryItem
        formatter={formatter}
        historyItem={history[0]}
        single
        submissionSettingId={settingsId}
      />

      {history.length > 1 && (
        <div className={styles.viewAll} onClick={onClickViewAll}>
          {`View All Submissions >`}
        </div>
      )}
    </div>
  );
};

export default History;
