import styled from '@emotion/styled';
import { css } from 'emotion';

export const MetricTitle = styled.span({
  fontSize: '16px',
  fontWeight: 315,
  lineHeight: '22px',
  color: 'var(--bu-gray-900)',
  marginBottom: '4px',
});

export const MetricsButtonsContainer = styled.div({
  padding: '16px 0 24px 0',
  display: 'flex',
  gap: '16px',
});

export const SavedMetricBlock = styled.div({
  backgroundColor: 'white',
  border: '1px solid var(--bu-gray-400)',
  borderRadius: '4px',
  margin: '10px 0 10px 0',
  display: 'flex',
  cursor: 'pointer',
  position: 'relative',
});

export const SavedMetricContent = styled.div({
  flex: 1,
  width: '90%',
});

export const DragItem = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: '0 3px',
  color: 'var(--bu-gray-500)',
  fontSize: '1.4em',
  cursor: 'move',
});

export const SavedMetricInfo = styled.div({
  alignItems: 'flex-start',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px 12px 12px 27px',
  width: '100%',
});
export const SavedMetricProperties = styled.div({
  alignItems: 'flex-start',
  borderTop: '1px solid var(--bu-gray-400)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '12px 12px 12px 15px',
  width: '100%',
});

export const ActionButtonsContainer = styled.div({
  display: 'flex',
});

export const PropertiesTitle = styled.label({
  cursor: 'pointer',
  fontSize: '14px',
  color: 'var(--bu-primary-500)',
});

// TODO we shold find a way to avoid using a harcoded max-height for the animation
export const CollapseAnimationContainer = styled.div`
  transition: max-height 0.1s;
  max-height: 2000px;
  width: 100%;
  padding: 5px 0;

  &.closed {
    max-height: 0px;
    overflow: hidden;
    display: none;
  }
`;

export const SavedMetricProperty = styled.div`
  margin: 5px 0;
  & .bu-dropdown-content-wrapper {
    max-width: 100%;
  }
`;

export const MetricPropertyWrapper = styled.div`
  display: flex;
`;

export const SubValueConfiguration = styled.div`
  display: none;

  &.active {
    display: flex;
    gap: 5px;
    flex-direction: column;
    margin-left: 15px;
  }
`;

export const SubValueWrapper = styled.div`
  width: 80%;

  & .bu-dropdown {
    max-width: 100%;
  }
`;

export const subValueSelect = css`
  max-width: 320px;
`;

export const MetricSubValue = css`
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-left: 20px;
`;

export const InfoIcon = css`
  cursor: pointer;
  display: inline;
  margin-left: 4px;
`;

export const InfoIconWrapper = css`
  display: flex;
  align-items: center;
  gap: 5px;
`;
