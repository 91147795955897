import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import BuInput from 'components/UI/BuInput';
import {
  container,
  input_error,
  text_input,
} from 'components/UI/OpenFiltersPanel/CustomViewsDropdown/CustomViewItem/RenameViewInput/styles';

type Props = {
  inputWidth: number;
  isNameValid: boolean;
  isNameChanged: boolean;
  name: string;
  onNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSaveNewName: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onCloseNameEdit: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const RenameViewInput: React.FC<Props> = ({
  inputWidth,
  isNameValid,
  isNameChanged,
  name,
  onNameChange,
  onSaveNewName,
  onCloseNameEdit,
}) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref?.current?.focus();
  }, []);

  return (
    <div className={container(inputWidth)}>
      <BuInput
        className={classNames(
          text_input,
          !isNameValid && isNameChanged && input_error
        )}
        type="text"
        placeholder="Set Name"
        value={name}
        innerRef={ref}
        onChange={onNameChange}
      />
      <BuButton
        icon
        borderless
        onClick={onSaveNewName}
        disabled={!isNameValid || !isNameChanged}
      >
        <BuIcon
          name={BoostUpIcons.CheckCircle}
          color={
            isNameValid && isNameChanged
              ? 'var(--bu-green-500)'
              : 'var(--bu-gray-500)'
          }
        />
      </BuButton>
      <BuButton icon borderless onClick={onCloseNameEdit}>
        <BuIcon name={BoostUpIcons.CrossCircle} color="var(--bu-red-400)" />
      </BuButton>
    </div>
  );
};

export default RenameViewInput;
