import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import orderBy from 'lodash/orderBy';
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast, ToastOptions } from 'react-toastify';

import { actions } from 'actions';
import { Chart } from 'components/chart-dashboards/Dashboard/types';
import { DataItemType, IData } from 'components/chart-dashboards/Widget/types';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';
import { QueryStatus, fetchApi } from 'utils/network';

type ApiResponse = { data: { series: IData[] } };

type Props = {
  apis: Chart['apis'];
  changeInterval?: string;
  chartType: string;
  dashboardId?: string;
  mode: 'default' | 'lazy';
  onGetDefaultData: (changeInterval?: string) => IData[];
  pathInitialValue: string;
  serializedQueryParams: string;
  setVisibleSeries: Function;
  title: string;
  valuePath: string;
  onSetData?: (data: Array<IData>) => void;
};

const toastOptions: ToastOptions = { position: 'bottom-left' };
const { AbortController } = window;

const negativeValues: string[] = ['booked', 'lost'];
const negativeValuesForWaterfall: string[] = [
  'Pushed',
  '-Value',
  'Won',
  'Lost',
];

const parseApiData = (el: IData) => {
  if (el.internal_name && negativeValues.includes(el.internal_name)) {
    el.data.forEach(
      (d) =>
        (d.total_amount =
          d.total_amount === undefined ? 0 : d.total_amount * -1)
    );
  }

  return el.name;
};

type SummarizeUserDataType = Required<
  Pick<DataItemType, 'count' | 'total_amount' | 'filters'>
>;
const summarizeUser = (
  user: Filters.UserFilterElement,
  dataItemCollection: IData
): SummarizeUserDataType => {
  const dataItem = dataItemCollection.data.find(
    (item) => item.user?.email === user.email && !user.team?.length
  );

  const team = (user.team || []).reduce<SummarizeUserDataType>(
    (acc, item) => {
      const f = summarizeUser(item, dataItemCollection);

      acc.count += f.count!;
      acc.total_amount += f.total_amount!;
      acc.filters = {
        ...f.filters,
        ...acc.filters,
        users: acc.filters.users?.concat(f.filters.users || []),
      };

      return acc;
    },
    {
      count: dataItem?.count || 0,
      total_amount: dataItem?.total_amount || 0,
      filters: {
        users: [],
        ...dataItem?.filters,
      },
    }
  );

  return {
    count: team.count,
    total_amount: team.total_amount,
    filters: {
      users: [],
      ...team.filters,
    },
  };
};

export const useWidgetApi = ({
  apis,
  changeInterval,
  chartType,
  dashboardId,
  mode,
  onGetDefaultData = () => [],
  pathInitialValue,
  serializedQueryParams,
  setVisibleSeries,
  title,
  valuePath,
  onSetData,
}: Props) => {
  const widgetId = dashboardId + title;
  const dispatch = useDispatch();

  const { savedData, sorting, viewBy } = useSelector((state: IReduxState) => ({
    savedData: selectors.getWidgetState(state, widgetId),
    sorting: selectors.getInternalActivitySortByFilter(state),
    viewBy: selectors.filtersViewBy(state),
  }));

  const [data, setData] = useState<IData[]>([]);
  const [dataStatus, setDataStatus] = useState<QueryStatus>('notAsked');
  const [isRestored, setIsRestored] = useState(false);
  const [prevFiltersState, setPrevFiltersState] = useState(
    JSON.stringify({
      path: pathInitialValue,
      sorting,
    })
  );

  const isNoData = data.length === 0;

  const handleSetData = useCallback(
    (series: IData[]) => {
      setData(series);
      if (onSetData) {
        onSetData(series);
      }
    },
    [onSetData]
  );

  const setWidgetData = ({ data: { series } }: ApiResponse) => {
    setIsRestored(true);

    if (chartType !== 'waterfall') {
      const activeCheckboxes = series.map(parseApiData);

      if (isNoData) {
        setVisibleSeries('How did your pipeline change', activeCheckboxes);
      }
    }

    if (series.length && series[0].data.length && chartType === 'column') {
      if (dashboardId === '0') {
        const { field, order } = sorting;

        series[0].data.sort((a, b) => {
          if (field === 'username') {
            return a.user?.name?.localeCompare(b.user?.name ?? '') ?? 0;
          }

          const countLeft = a.count ?? 0;
          const countRight = b.count ?? 0;

          return order === 'asc'
            ? countLeft - countRight
            : countRight - countLeft;
        });
      } else if (title === 'Pipeline Created') {
        series[0].data.sort((a: any, b: any) => b.count - a.count);
      }
    }

    if (mode === 'lazy') {
      dispatch(
        actions.ui.widget.persist({
          id: widgetId,
          series: series,
          serializedQueryParams,
        })
      );
    }

    handleSetData(series);
  };

  const setError = (error: string | null) => {
    toast.error(`Fetching data failed: ${error}`, toastOptions);
    handleSetData(onGetDefaultData(changeInterval));
  };

  useEffect(() => {
    const abortController = new AbortController();

    if (
      !savedData ||
      savedData.serializedQueryParams !== serializedQueryParams
    ) {
      fetchApi<string, ApiResponse>({
        queryParams: serializedQueryParams,
        setData: setWidgetData,
        setError,
        setStatus: setDataStatus,
        signal: abortController.signal,
        url: apis[0].api_url,
      });
    }

    return () => abortController.abort();
  }, [
    apis[0].api_url,
    serializedQueryParams,
    sorting,
    !!savedData,
    viewBy,
    handleSetData,
  ]);

  useEffect(() => {
    if (savedData && !isRestored) {
      setWidgetData({ data: { series: savedData.series } });
      setDataStatus('success');
    }
  }, [!!savedData, isRestored, handleSetData]);

  const currentFiltersState = JSON.stringify({ path: valuePath, sorting });

  useEffect(() => {
    if (prevFiltersState !== currentFiltersState) {
      const oldData = data[0] && data[0].data;

      const newData =
        (dashboardId === '0' && sorting.field !== 'count') ||
        chartType !== 'column'
          ? orderBy(oldData, 'user.name', ['asc'])
          : orderBy(oldData, valuePath, ['desc']);

      setPrevFiltersState(currentFiltersState);

      handleSetData([{ ...data[0], data: newData }]);
    }
  }, [
    JSON.stringify(data[0]),
    prevFiltersState,
    currentFiltersState,
    valuePath,
    sorting.field,
    handleSetData,
  ]);

  return { data, dataStatus };
};
