import { useCallback, useState } from 'react';

import { AnalyticsTracker } from 'components/common/analyticsUtils';
import {
  DEFAULT_FILE_NAME,
  DEFAULT_FILE_TYPE,
  DEFAULT_QUERY_METHOD,
  POSITIVE_HTTP_STATUSES,
} from 'utils/network/constants';
import { getBody } from 'utils/network/getBody';
import { mapFileTypeToMime, mapHttpStatusToMessage } from 'utils/network/maps';
import {
  IDownloadFile,
  QueryStatus,
  UseDownloadFileProps,
} from 'utils/network/types';

export const downloadFile = async <TReqParams>({
  fileType = DEFAULT_FILE_TYPE,
  queryMethod = DEFAULT_QUERY_METHOD,
  queryParams,
  setError = () => {},
  setStatus = () => {},
  url,
}: IDownloadFile<TReqParams>): Promise<void> => {
  try {
    const body = getBody<TReqParams>(queryMethod, queryParams);

    const response = await fetch(url, {
      body,
      credentials: 'include',
      headers: {
        Accept: mapFileTypeToMime[fileType],
        'Content-Type': 'application/json',
      },
      method: queryMethod,
    });

    if (POSITIVE_HTTP_STATUSES.includes(response.status)) {
      const [blob, contentDisposition] = await Promise.all([
        response.blob(),
        response.headers.get('content-disposition'),
      ]);

      let fileName = `${DEFAULT_FILE_NAME}.${fileType}`;
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename=(.+)/);

        if (fileNameMatch && fileNameMatch.length === 2)
          fileName = fileNameMatch[1];
      }

      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = fileName;
      a.click();

      setTimeout(() => {
        window.URL.revokeObjectURL(a.href);
      }, 250);

      AnalyticsTracker.event(
        {
          body,
          url,
        },
        {
          action: `Download ${fileName}`,
          category: `File download from ${url}`,
          label: `${fileType.toUpperCase()} file downloaded`,
        }
      );

      setStatus('success');
    } else {
      setError(mapHttpStatusToMessage[response.status] || 'network error');
      setStatus('error');
    }
  } catch (err) {
    setError((err as Error).message);
    setStatus('error');
  }
};

export const useDownloadFile = <TReqParams>({
  fileType,
  queryMethod,
  queryParams,
  url,
}: UseDownloadFileProps<TReqParams>) => {
  const [error, setError] = useState<null | string>(null);
  const [status, setStatus] = useState<QueryStatus>('notAsked');

  const isPending = status === 'loading';

  const handleDownload = useCallback(() => {
    if (!isPending) {
      setError(null);
      setStatus('loading');

      downloadFile<TReqParams>({
        fileType,
        queryMethod,
        queryParams,
        setError,
        setStatus,
        url,
      });
    }
  }, [fileType, queryMethod, queryParams, setError, setStatus, url]);

  return {
    isPending,
    status,
    error,
    triggerDownload: handleDownload,
  };
};
