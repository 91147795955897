import { TableData } from 'components/dashboard/ProspectAccountDashboard/WidgetTableAccount/types';

const tablesDataInitialState: TableData = {
  accounts: [
    {
      _id: '',
      account_name: '',
      email_domain: '',
      user: {
        name: '',
        email: '',
      },
      risk_score: 0,
      risk_status: '',
      risk_status_display: '',
      risk_factors: null,
      positive_indicators: null,
      open_opportunities: 0,
      closed_opportunities: 0,
      created_at: '',
      last_event: {
        event: '',
        occurred_at: '',
        is_call: false,
      },
      last_touched: '',
      last_engaged: '',
      contacts_touched: 0,
      contacts_engaged: 0,
      account_value: 0,
      events_count: 0,
      account_owner_emails_count: 0,
      buyer_emails_count: 0,
      additional_fields: {},
    },
  ],
  total_count: 0,
};

export default tablesDataInitialState;
