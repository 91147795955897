import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { Action } from 'typescript-fsa';

import { actions } from 'actions';
import { getAppState } from 'selectors';
import { fetchApiWithoutCb } from 'utils/network';

export type UserPreferences = {
  user: { key_name: string; value: any }[];
};

const userLevelPreferencesUrl = `${process.env.REACT_APP_BACKEND_URL}/api/profile/preferences/user`;

function* userPreferencesInitGenerator() {
  const { error, result } = yield call(fetchApiWithoutCb, {
    queryMethod: 'get',
    url: userLevelPreferencesUrl,
  });

  if (error) {
    console.error(`get user's preferences ${error}`);
  }

  let user: { key_name: string; value: any }[] = [];
  if (result && result.data && Array.isArray(result.data.user)) {
    try {
      (result.data.user as { key_name: string; value: string }[]).forEach(
        ({ key_name, value }) => {
          if (key_name && value) {
            user.push({ key_name, value: JSON.parse(value) });
          }
        }
      );
    } catch (err) {
      console.error(`response parsing error ${err}`);
    }
  }

  if (user.length) {
    yield put(actions.ui.preferences.user.loaded({ user }));
  }
}

function* persistAppStateGenerator(action: Action<{ path: string }>) {
  if (
    [
      '/accounts/all',
      '/opportunities',
      '/forecast/roll-ups/~/deals-status/forecast',
    ].includes(action.payload.path)
  ) {
    // @ts-ignore
    const appState = yield select(getAppState);
    const { error } = yield call(fetchApiWithoutCb, {
      queryMethod: 'post',
      queryParams: {
        user: [{ key_name: 'appState', value: JSON.stringify(appState) }],
      },
      url: userLevelPreferencesUrl,
    });

    if (error) {
      console.error('post appState', error);
    }
  }
}

export function* preferencesSaga() {
  yield takeEvery(
    actions.ui.preferences.user.get.type,
    userPreferencesInitGenerator
  );

  yield all([
    takeLatest(
      actions.ui.appState.persistMetricsStatus.type,
      persistAppStateGenerator
    ),
    takeLatest(actions.ui.appState.resizeColumn.type, persistAppStateGenerator),
    takeLatest(
      actions.ui.appState.setSequenceColumns.type,
      persistAppStateGenerator
    ),
    takeLatest(
      actions.ui.appState.replaceShowOrHideColumns.type,
      persistAppStateGenerator
    ),
    takeLatest(
      actions.ui.appState.setShowOrHideColumns.type,
      persistAppStateGenerator
    ),
  ]);
}
