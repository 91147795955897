import * as R from 'ramda';
import { createSelector } from 'reselect';

import AccountSearchIcon from 'assets/images/account_search_icon_new.svg';
import OpportunitySearchIcon from 'assets/images/opportunity_search_icon_new.svg';
import CallSearchIcon from 'assets/images/call_search_icon_new.svg';
import MeetingSearchIcon from 'assets/images/meeting_search_icon_new.svg';

// helpers
const ICON_FOR_TYPE = {
  account: AccountSearchIcon,
  opportunity: OpportunitySearchIcon,
  call: CallSearchIcon,
  meeting: MeetingSearchIcon,
};

const formatSearchResults = R.compose(
  R.reject(R.isEmpty),
  R.merge({
    // the merge forces a re-order on the object keys
    opportunities: [],
    calls: [],
    meetings: [],
  }),
  R.map(
    R.map(
      ({
        id,
        type,
        title,
        description,
        warning,
        shouldLink,
        activity_sync_info,
      }) => ({
        // map the object to the format required by the component
        type,
        title,
        description,
        warning,
        value: id,
        icon: ICON_FOR_TYPE[type],
        shouldLink,
        activity_sync_info,
      })
    )
  )
);

const flattenCount = R.compose(R.length, R.flatten, R.values);

// selectors
export const getSearchQuery = R.path(['search', 'query']);
export const isLoadingSearchSuggestions = R.path([
  'search',
  'loadingSuggestions',
]);
export const getSearchSuggestions = R.path(['search', 'suggestions']);
export const getSearchSuggestionsForInline = createSelector(
  [getSearchSuggestions],
  formatSearchResults
);
const getSearchSuggestionsCount = createSelector(
  [getSearchSuggestions],
  flattenCount
);
const getSearchSuggestionsPagination = R.path([
  'search',
  'suggestionsPagination',
]);
const getSearchSuggestionsPaginationCount = createSelector(
  [getSearchSuggestionsPagination],
  R.compose(R.sum, R.values)
);
export const hasMoreSearchResults = createSelector(
  [getSearchSuggestionsPaginationCount, getSearchSuggestionsCount],
  R.compose(R.lt(0), R.subtract) // return true if not all elements have been retrieved
);

export const isLoadingSearchResults = R.path(['search', 'loadingResults']);
export const getSearchResults = R.path(['search', 'results']);
export const getSearchCallsResults = R.path(['search', 'results', 'calls']);

export const getSearchResultsCount = createSelector(
  [getSearchResults],
  flattenCount
);
export const getCategorizedSearchResults = createSelector(
  [getSearchResults],
  formatSearchResults
);
export const getSearchPagination = R.path(['search', 'pagination']);
