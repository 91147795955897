import { BoostUpIcons } from 'assets/css/boostup-icons';
import classNames from 'classnames';
import { useClickOutside } from 'components/hooks/useClickOutside';
import { BuControlSize } from 'components/UI/BuButton';
import BuDropdown, { BuDropdownItemContainer } from 'components/UI/BuDropdown';
import BuIcon from 'components/UI/BuIcon';
import {
  iconStyles,
  itemStyles,
  menuColor,
  removeButtonStyles,
  wrapperStyles,
} from './HeaderOptions.styles';
import { SortOrder } from './TypedTable';

export interface HeaderOptionsProps {
  isSortable: boolean;
  sortDirection?: SortOrder;
  onSort?: (order: SortOrder) => void;
  isPinned?: boolean;
  isPinnable?: boolean;
  onPin?: () => void;
  tableRef?: React.RefObject<HTMLTableSectionElement>;
}

type ActionTypes = 'ASCENDING_SORT' | 'DESCENDING_SORT' | 'PIN_COLUMN';

interface PinOptionProps {
  isPinned: boolean;
  onClick: () => void;
}

const PinOption = ({ isPinned, onClick }: PinOptionProps) => {
  const icon = isPinned ? BoostUpIcons.RotatedPinned : BoostUpIcons.RotatedPin;
  const text = isPinned ? 'Unpin to this Column' : 'Pin to this Column';

  return (
    <BuDropdownItemContainer onClick={onClick}>
      <div className={itemStyles}>
        <BuIcon className={iconStyles} name={icon} />
        <span>{text}</span>
      </div>
    </BuDropdownItemContainer>
  );
};

interface SortOptionsProps {
  sortDirection: SortOrder | undefined;
  onSort: (action: 'ASCENDING_SORT' | 'DESCENDING_SORT') => void;
}

const SortOptions = ({ sortDirection, onSort }: SortOptionsProps) => {
  return (
    <>
      {sortDirection !== SortOrder.ASCENDING && (
        <BuDropdownItemContainer
          onClick={() => {
            onSort('ASCENDING_SORT');
          }}
        >
          <div className={itemStyles}>
            <BuIcon
              className={iconStyles}
              name={BoostUpIcons.SortingAscending}
            />
            <span>Sort Ascending</span>
          </div>
        </BuDropdownItemContainer>
      )}
      {sortDirection !== SortOrder.DESCENDING && (
        <BuDropdownItemContainer
          onClick={() => {
            onSort('DESCENDING_SORT');
          }}
        >
          <div className={itemStyles}>
            <BuIcon
              className={iconStyles}
              name={BoostUpIcons.SortingDescending}
            />
            <span>Sort Descending</span>
          </div>
        </BuDropdownItemContainer>
      )}
    </>
  );
};

const HeaderOptions = ({
  isSortable,
  sortDirection,
  onSort,
  isPinned,
  isPinnable,
  onPin,
  tableRef,
}: HeaderOptionsProps) => {
  const { setIsOpen } = useClickOutside();

  const onClick = (hide: () => void, action: ActionTypes) => {
    hide();
    setIsOpen(false);
    switch (action) {
      case 'ASCENDING_SORT':
        onSort?.(SortOrder.ASCENDING);
        break;
      case 'DESCENDING_SORT':
        onSort?.(SortOrder.DESCENDING);
        break;
      case 'PIN_COLUMN':
        onPin?.();
        break;
    }
  };

  const someOptionAvailable = isSortable || isPinnable;
  // Attach to body to avoid z-index issues
  const attachTo = tableRef?.current as HTMLElement;

  return (
    <>
      {someOptionAvailable && (
        <BuDropdown
          secondary
          label={<BuIcon name={BoostUpIcons.MenuVert} />}
          size={BuControlSize.REGULAR}
          noDropdownIcon
          borderless
          className={classNames(removeButtonStyles, menuColor)}
          attachTo={attachTo}
        >
          {(hide) => (
            <div className={wrapperStyles}>
              {onSort && isSortable && (
                <SortOptions
                  sortDirection={sortDirection}
                  onSort={(action: ActionTypes) => onClick(hide, action)}
                />
              )}

              {onPin && isPinnable && (
                <PinOption
                  isPinned={!!isPinned}
                  onClick={() => {
                    onClick(hide, 'PIN_COLUMN');
                  }}
                />
              )}
            </div>
          )}
        </BuDropdown>
      )}
    </>
  );
};

export default HeaderOptions;
