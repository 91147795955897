import classNames from 'classnames';
import { css } from 'emotion';
import React from 'react';

import OpenFiltersPanel from 'components/UI/OpenFiltersPanel';
import { SortBy } from 'components/chart-dashboards/Dashboard/SortBy';
import { Users } from 'components/chart-dashboards/Dashboard/Users';
import ViewTypeButtons from 'components/chart-dashboards/Dashboard/ViewTypeButtons';
import { indexToDashboard } from 'navigation/routes';

const stickyStyles = css`
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 991;
  border-bottom: 1px solid transparent;

  &.scrolled {
    z-index: 905;
    border-bottom: 1px solid #dddddd;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    margin-left: -20px;
    margin-right: -20px;
    padding: 10px 20px 10px 20px;
  }
`;

const titleStyle = css`
  min-width: 180px;
  font-family: var(--bu-font-semi-bold);
  font-size: 18px;
  color: #4a4a4a;
  margin: auto 0 !important;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const titlePanelStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid var(--bu-gray-400);
`;

const titleSellerPanelStyle = css`
  display: flex;
  flex-direction: row;
  background-color: var(--bu-white);
  margin: 0 -20px 0px -20px;
  padding: 0 20px 0px 20px;
  border-bottom: 1px solid var(--bu-gray-400);
`;

const customFilterStyle = css`
  padding: 10px 0;
`;

export const businessTypesPanelWrapper = css`
  background-color: var(--bu-white);
  border-bottom: 1px solid var(--bu-gray-400);
  margin: 0px -20px 0px -20px;
  padding: 0px 20px 0px 20px;
`;

const DashboardFilters: React.FC<{
  dashboardId?: string;
  tab?: string;
  title: string;
  showViewBySwitch?: boolean;
  showSortBy?: boolean;
}> = ({
  dashboardId = '',
  tab,
  title,
  showViewBySwitch = false,
  showSortBy = true,
}) => (
  <div className={stickyStyles}>
    <div
      className={dashboardId === '2' ? titleSellerPanelStyle : titlePanelStyle}
    >
      <h1 className={classNames('dashboard-title', titleStyle)}>{title}</h1>

      {dashboardId === '0' && (
        <div className={customFilterStyle}>
          {showViewBySwitch && <ViewTypeButtons />}

          {showSortBy && <SortBy />}
        </div>
      )}
      {dashboardId === '2' && (
        <div className={customFilterStyle}>
          <Users />
        </div>
      )}
    </div>
    {/* <div className={businessTypesPanelWrapper}> */}
    <OpenFiltersPanel
      tab={dashboardId ? indexToDashboard[dashboardId].tab || tab! : tab!}
    />
    {/* </div> */}
  </div>
);

export default DashboardFilters;
