import { IReduxState } from '../MiniSearchInput/types';
import GlobalAppLoader from './GlobalAppLoader';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as selectors from 'selectors';

const mapStateToProps = (state: IReduxState) => ({
  isAppInitiated: selectors.isAppInitiated(state),
});

export default connect(mapStateToProps)(withRouter(GlobalAppLoader));
