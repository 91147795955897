import React, { useEffect, useState } from 'react';

import { FormulaMetricInputs } from 'components/dashboard/Metrics/Create/MetricDefinitionInputs/FormulaMetricInputs';
import { MetricTypeSelection } from 'components/dashboard/Metrics/Create/MetricDefinitionInputs/MetricTypeSelection';
import { SimpleMetricsInputs } from 'components/dashboard/Metrics/Create/MetricDefinitionInputs/SimpleMetricsInputs';
import {
  FORMULA_HISTORICAL_METRIC_NEWBORN_MOCK,
  FORMULA_METRIC_NEWBORN_MOCK,
  NOT_SAVED_METRIC,
  SIMPLE_HISTORICAL_METRIC_NEWBORN_MOCK,
  SIMPLE_METRIC_NEWBORN_MOCK,
} from 'components/dashboard/Metrics/Create/constants';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import { MetricType } from 'components/dashboard/Metrics/enums';
import { BIMetricUnion } from 'components/dashboard/Metrics/metrics.types';
import { isBIMetricFormula, isBIMetricSimple } from '../utils';
import { omit } from 'ramda';

type IProps = {
  readonly metric: BIMetricUnion;
  onChangeMetricDefinitionInputs: (
    complete: boolean,
    metricInputs: Partial<BIMetricUnion>
  ) => void;
};

export const MetricDefinitionInputs: React.FC<IProps> = ({
  metric,
  onChangeMetricDefinitionInputs,
}) => {
  const [metricType, setMetricType] = useState<MetricType>(
    metric.metadata.type || MetricType.Simple
  );

  const handleChangeMetricType = (type: MetricType): void => {
    setMetricType(type);
    onChangeMetricDefinitionInputs(false, {
      ...(type === MetricType.Simple && {
        ...(metric.analysis_type === AnalysisType.LIVE
          ? omit(['name'], SIMPLE_METRIC_NEWBORN_MOCK)
          : omit(['name'], SIMPLE_HISTORICAL_METRIC_NEWBORN_MOCK)),
      }),
      ...(type === MetricType.Formula && {
        ...(metric.analysis_type === AnalysisType.LIVE
          ? omit(['name'], FORMULA_METRIC_NEWBORN_MOCK)
          : omit(['name'], FORMULA_HISTORICAL_METRIC_NEWBORN_MOCK)),
      }),
    });
  };

  const handleCompleteMetricInputs = (
    complete: boolean,
    metricInputs: Partial<BIMetricUnion>
  ): void => {
    onChangeMetricDefinitionInputs(complete, metricInputs);
  };

  return (
    <>
      <MetricTypeSelection
        metricType={metricType}
        updateMetricType={handleChangeMetricType}
        editing={metric._id !== NOT_SAVED_METRIC}
      />

      {isBIMetricSimple(metric) && (
        <SimpleMetricsInputs
          metric={metric}
          onCompleteMetricInputs={handleCompleteMetricInputs}
        />
      )}

      {isBIMetricFormula(metric) && (
        <FormulaMetricInputs
          metric={metric}
          onCompleteMetricInputs={handleCompleteMetricInputs}
        />
      )}
    </>
  );
};
