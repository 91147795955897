import { adminActionType, selectedClassType } from '../actions/adminActions';

import * as t from 'actions/actionTypes';

const initState = {
  companies: [],
  users: [],
  user_jobs: [],
  call_jobs: [],
  email: '',
  password: '',
  token: '',
  user_email: null,
  selected_class_type: selectedClassType.COMPANIES,
  page_number: 0,
  apiSuccess: null,
  apiError: null,
  isEnabled: false,
  email_impersonation: '',
  email_domain: null,
  is_admin: false,
  is_user_impersonation_allowed: false,
  isDeveloper: false,
};

export const adminReducer = function (state = initState, action) {
  switch (action.type) {
    case adminActionType.UPDATE_STATE:
      if (typeof action.key === 'string') {
        return {
          ...state,
          [action.key]: action.value,
        };
      } else {
        return {
          ...state,
          ...action.key,
        };
      }
    case adminActionType.RESET_STATE:
      return initState;
    case t.LOGIN + t.OAUTH + t.SUCCESS:
      return {
        ...state,
        email_impersonation: action.payload.data.email,
        email_domain: action.payload.data.email_domain,
        is_admin: action.payload.data.is_admin,
        isDeveloper: action.payload.data.is_developer,
        is_user_impersonation_allowed:
          action.payload.data.is_user_impersonation_allowed,
      };
    default:
      return state;
  }
};
