import {
  insightsActionType,
  selectedClassType,
} from '../actions/insightsActions';
import { notNullAndEmpty } from '../common/utils';

export const insightsReducer = function (
  state = {
    all_calls: [],
    calls: [],
    apiError: null,
    page_number: 0,
    time_min: null,
    time_max: null,
    selected_class_type: selectedClassType.COMPANY_INSIGHTS,
    call_state: {},
  },
  action
) {
  switch (action.type) {
    case insightsActionType.UPDATE_STATE:
      if (typeof action.key === 'string') {
        return {
          ...state,
          [action.key]: action.value,
        };
      } else {
        return {
          ...state,
          ...action.key,
        };
      }
    case insightsActionType.RESET_STATE:
      return {
        all_calls: [],
        calls: [],
        apiError: null,
        page_number: 0,
        time_min: null,
        time_max: null,
        selected_class_type: selectedClassType.COMPANY_INSIGHTS,
        call_state: {},
      };
    case insightsActionType.UPDATE_CALLS: {
      const { calls, page_number } = action.value;
      let call_state = {};
      for (var i = 0; i < calls.length; i++) {
        call_state[calls[i].id] = {
          searchIndex: 0,
          isPlaying: false,
        };
      }
      let all_calls = calls;
      if (page_number !== 0) {
        // append calls to all_calls
        // merge in call_state
        if (notNullAndEmpty(state.all_calls)) {
          all_calls = state.all_calls.concat(calls);
        }
        call_state = {
          ...state.call_state,
          ...call_state,
        };
      }
      return {
        ...state,
        calls,
        all_calls,
        call_state,
        page_number,
      };
    }
    case insightsActionType.UPDATE_CALL_STATE:
      const call_state = state.call_state;
      const state_for_given_call = call_state[action.call_id];
      const new_state_for_given_call = {
        ...state_for_given_call,
        [action.key]: action.value,
      };
      const new_call_state = {
        ...call_state,
        [action.call_id]: new_state_for_given_call,
      };
      return {
        ...state,
        call_state: new_call_state,
      };
    default:
      return state;
  }
};
