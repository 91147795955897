/* eslint-disable no-restricted-syntax */
import { getLocation } from 'connected-react-router';
import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getFiltersForAPI } from 'selectors/openFilters';

export const getCallsTabs = (state) => state.calls.tabs;

const pathToTab = {
  '/calls': {
    internalTab: 'completedCalls',
    filtersTab: 'calls',
  },
  '/calls/upcoming': {
    internalTab: 'upcomingCalls',
    filtersTab: 'upcoming_calls',
  },
};

const returnDataByTab = (state, tab, filtersTab) => {
  const filtersState = getFiltersForAPI(state, filtersTab);

  return {
    ...tab,
    filtersTab,
    filters: tab.filters.map((filter) => ({
      ...filter,
      options: filtersState[filter.data.key] || [],
    })),
  };
};

export const getCallsActiveTab = (state) => {
  const { pathname } = getLocation(state);

  const { tabs } = state.calls;
  const key =
    pathToTab[
      pathname.includes('/calls/upcoming') ? '/calls/upcoming' : '/calls'
    ];

  if (key && tabs[key.internalTab]) {
    return returnDataByTab(state, tabs[key.internalTab], key.filtersTab);
  }

  for (const tab in tabs) {
    if (tabs[tab].active) {
      return returnDataByTab(state, tabs[tab], 'calls');
    }
  }
};

export const getCallActiveTabPure = (state) => {
  const activeTab = getCallsActiveTab(state);

  return {
    ...state.calls.tabs[activeTab.key],
    filtersTab: activeTab.filtersTab,
  };
};

export function getCalls(state) {
  const activeTab = getCallsActiveTab(state);
  return activeTab && activeTab.calls;
}

export const getCallsStatus = (state) => state.calls.getCallsStatus;
export const getCallsPaginationStatus = (state) => state.calls.paginationStatus;
export const getCallOpenedDetails = createSelector(
  (state, id) => ({
    id,
    activeTab: getCallsActiveTab(state),
  }),
  ({ id, activeTab }) =>
    id == null ? null : R.find((c) => c.id === id)(activeTab.calls)
);
