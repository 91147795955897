import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { createAdminAction } from 'actions/adminActions';
import { appInitiated, doLogoutUser } from 'actions/userActions';
import Header from 'components/UI/Wrapper/Header/Header';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

const mapStateToProps = (state: IReduxState) => ({
  isAdmin: selectors.isAdmin(state),
  isAdminEnabled: selectors.isAdminEnabled(state),
  isImpersonate: selectors.isImpersonate(state),
  user: selectors.getUser(state),
});

const mapDispatchToProps = {
  doLogoutUser,
  appInitiated,
  updateAdminState: createAdminAction.updateState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
