import * as t from '../actions/actionTypes';
import produce from 'immer';

const initialState = {
  isAppInitiated: false,
  displayMode: '',
};

export default function appReducer(state = initialState, action) {
  const { type, payload } = action;

  const reducer = {
    [t.APP_INITIATED]: (draft) => {
      draft.isAppInitiated = true;
    },
    [t.APP_DISPLAY_MODE]: (draft) => {
      draft.displayMode = payload.displayMode;
    },
  };

  return reducer.hasOwnProperty(type) ? produce(state, reducer[type]) : state;
}
