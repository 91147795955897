import * as t from 'actions/actionTypes';
import { profileBase } from 'components/settings/UserProfiles/UserProfiles';
import * as genericSagas from 'sagas/generic';

export const getUserProfilesList = () => ({
  type: t.USER + t.PROFILES + t.ALL + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}${profileBase}`,
  saga: genericSagas.get,
  success: (payload) => ({
    type: t.USER + t.PROFILES + t.ALL + t.GET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.USER + t.PROFILES + t.ALL + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.USER + t.PROFILES + t.ALL + t.GET + t.LOADING,
  }),
});

export const createUserProfile = ({
  name,
  description,
  read_only,
  is_draft,
}) => ({
  type: t.USER + t.PROFILE + t.CREATE,
  url: `${process.env.REACT_APP_BACKEND_URL}${profileBase}`,
  saga: genericSagas.create,
  data: {
    name,
    description,
    read_only,
    is_draft,
  },
  success: (payload) => ({
    type: t.USER + t.PROFILE + t.CREATE + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.USER + t.PROFILE + t.CREATE + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.USER + t.PROFILE + t.CREATE + t.LOADING,
  }),
});

export const updateUserProfile = ({
  id,
  name,
  description,
  read_only,
  is_draft,
}) => ({
  type: t.USER + t.PROFILE + t.UPDATE,
  url: `${process.env.REACT_APP_BACKEND_URL}${profileBase}/${id}`,
  saga: genericSagas.update,
  data: {
    name,
    description,
    read_only,
    is_draft,
  },
  success: (payload) => ({
    type: t.USER + t.PROFILE + t.UPDATE + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.USER + t.PROFILE + t.UPDATE + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.USER + t.PROFILE + t.UPDATE + t.LOADING,
  }),
});
