import { connect } from 'react-redux';

import EmailsModal from 'components/modals/EmailsModal/EmailsModal';
import { PersistedProps } from 'components/modals/EventsModal/types';
import { IReduxState } from 'reducers/types';
import { getModalProps } from 'selectors';

const mapStateToProps = (state: IReduxState) => ({
  modalProps: getModalProps<PersistedProps>(state, '/emails'),
});

export type ReduxProps = ReturnType<typeof mapStateToProps>;

export default connect<ReduxProps, {}, {}, IReduxState>(mapStateToProps)(
  EmailsModal
);
