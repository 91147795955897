import React from 'react';

import BuButton from 'components/UI/BuButton';
import { FunnelConditions } from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/FunnelDefinition/FunnelConditions/FunnelConditions';
import { FunnelObjectSelector } from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/FunnelDefinition/FunnelObjectSelector/FunnelObjectSelector';
import { StagesSelector } from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/FunnelDefinition/StagesSelector/StagesSelector';
import { ContinueButtonWrapper } from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/FunnelDefinition/styles';
import { OptionSections } from 'components/dashboard/Metrics/Create/constants';
import { BIWidget } from 'components/dashboard/Metrics/metrics.types';

interface Props {
  widget: BIWidget;
  onCompleteOptions: (changes: Partial<BIWidget>) => void;
  onContinueButtonClick: (nextSection: OptionSections) => void;
}

export const FunnelDefinition: React.FC<Props> = ({
  widget,
  onCompleteOptions,
  onContinueButtonClick,
}) => (
  <>
    <FunnelObjectSelector />
    <FunnelConditions widget={widget} onCompleteOptions={onCompleteOptions} />
    <StagesSelector widget={widget} onCompleteOptions={onCompleteOptions} />
    <ContinueButtonWrapper>
      <BuButton
        secondary
        onClick={() => {
          onContinueButtonClick(OptionSections.METRIC);
        }}
        testingLabel="funnel-definition-continue"
      >
        Continue
      </BuButton>
    </ContinueButtonWrapper>
  </>
);
