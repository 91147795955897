import { css } from 'emotion';

export const suggesterWrapper = css`
  display: block;
  width: 360px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16), 0px 22px 24px rgba(0, 0, 0, 0.2);
  padding: 12px;
  border-radius: 8px;
  position: absolute;
  z-index: 20;
  background-color: #fff;
`;

export const header = css`
  font-size: 10px;
  color: var(--bu-gray-700);
  font-family: var(--bu-font-regular);
  text-transform: uppercase;
`;

export const metricsSuggestionsList = css`
  list-style-type: none;
  list-style-position: outside;
  padding-inline-start: 0;
  overflow-x: hidden;
  max-height: 200px;
  li {
    font-size: 14px;
    font-family: var(--bu-font-medium);
    color: var(--bu-gray-900);
    padding: 5px 3px 5px 8px;

    i {
      padding-right: 8px;
    }
  }

  li:hover {
    background-color: var(--bu-gray-300);
    cursor: pointer;
  }
`;

export const activeMetric = css`
  background-color: var(--bu-gray-300);
`;
