import { css } from 'emotion';

export const container = css`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  align-content: stretch;
`;

export const noPadding = css`
  padding: 0 !important;
`;

export const borderTop = css`
  border-top: 2px solid var(--bu-gray-400);
`;

export const children = css`
  overflow: hidden;
  flex: 1;
`;

export const panel = css`
  color: #000000;
  width: 100%;
  width: 380px;
  height: calc(100vh - 50px);
  overflow: auto;
  background-color: var(--bu-white);
  border-left: 1px solid var(--bu-gray-400);
  z-index: 990;
`;
