import { matchPath } from 'react-router-dom';
import { makeModalPath, makeURL } from './scheme.utils';

import { MODALS } from 'navigation/modals';
import {
  MODALS_SCHEMES,
  ModalsScheme,
  OpenModalProps,
  Scheme,
  URLScheme,
} from 'navigation/types';
import { useSelector } from 'react-redux';
import { IReduxState } from 'reducers/types';
import { getHiddenPages } from 'selectors';
import { getAppNavigation } from 'selectors/navigation';
import { history } from 'store/configureStore';
import { ROUTES, SCHEMES } from './routes';

export const isUrlMatchScheme = (url: string, options: URLScheme<Scheme>) =>
  makeURL(options) === url;

export const redirect = (
  scheme: URLScheme<Scheme>['scheme'],
  params?: URLScheme<Scheme>['params']
) => {
  const url = makeURL({ scheme, params });

  if (url) {
    history.push(url);
  } else if (scheme && !params) {
    history.push(scheme);
  }
};

export const getSchemeFromSection = (section: string) => {
  if (MODALS[section as ModalsScheme]) {
    return {
      scheme: section,
    };
  }

  const match = MODALS_SCHEMES.map((scheme) =>
    matchPath(section, {
      path: scheme,
      exact: true,
    })
  ).find((item) => item);

  if (!match) {
    return undefined;
  }

  const { path, params } = match;

  return {
    params: JSON.stringify(params),
    scheme: path,
  };
};

/**
 *
 * @deprecated use `useModal` from src/components/modals/ModalContext/modal.context.tsx
 *
 * Opens a table modal, the table will depend on the scheme. How this works:
 * - Check that the desired scheme exists in the modals constant.
 * - Call this function with the proper arguments.
 * - It will replace the url in order to trigger the modals router switch.
 *
 * The arguments for modal schemes that need url replacing such as `/activity/:activityId` have to be supplied via
 * `props` object with the same key. Whereas the arguments for the modal itself (apiUrl, title, id) should be put inside the
 * `persistParams` object and will reach the `DealsModal` component via the persistor (which should be also in the props).
 * In order for this to work all of the steps mentioned above should be carefully completed.
 *
 * @see modals
 * @see DealsModal
 *
 */
export const openModal = (props: OpenModalProps) => {
  const { scheme, params } = props;

  const to = makeURL({ scheme, params });

  if (to) {
    const location = window.location;
    const pathname = location.pathname.replace(
      process.env.REACT_APP_SUBDIRECTORY || '',
      ''
    );

    history.push({
      ...location,
      pathname: makeModalPath(pathname, `~${to}`),
    });

    if ('persistParams' in props) {
      props.persistor({
        scheme,
        persistParams: props.persistParams,
        modalOptions: {
          subtab: params ? params.subtab : '',
          localStorageKeyPrefix: params ? params.localStorageKeyPrefix : '',
        },
      });
    }
  }
};

export const useSchemesForRouting = () => {
  const hiddenPages = useSelector<IReduxState, Scheme[]>(getHiddenPages);

  const appNavigationItems = useSelector(getAppNavigation);

  const pagesAvailableForUser = appNavigationItems.flatMap((item) => [
    item.name,
    ...(item.sub_nav?.map((sub) =>
      // If type is revbi_dashboard, we want to enable revbi dashboard pages as a whole
      // As the name is dynamic, we can't use it as a key for the scheme
      sub.type === 'revbi_dashboard' ? 'revbi_dashboard' : sub.name
    ) ?? []),
  ]);

  return SCHEMES.filter((path) => {
    const route = ROUTES[path];
    // Confusing but when the ROUTE doesn't have api key
    // Then is a public route (not really but there are other controls for those cases)
    if (!route.apiKey) {
      return true;
    }

    return (
      !hiddenPages.includes(path) &&
      route &&
      pagesAvailableForUser.includes(route.apiKey)
    );
  });
};
