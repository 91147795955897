import { connect } from 'react-redux';

import { FILTER_NAME } from 'common/constants';
import DealsModal from 'components/modals/DealsModal/DealsModal';
import { PersistedProps, StateProps } from 'components/modals/DealsModal/types';
import { IReduxState } from 'reducers/types';
import { getFiltersForAPI, getModalProps, getModalOptions } from 'selectors';

const mapStateToProps = (state: IReduxState) => {
  const modalProps = getModalProps<PersistedProps>(state, '/deals');
  const modalOptions = getModalOptions<PersistedProps>(state, '/deals');
  const filterKeyForModal =
    modalProps.filterKeyForModal || FILTER_NAME.ForecastOpportunitiesModal;
  return {
    modalProps,
    modalOptions,
    modalFilters: getFiltersForAPI(state, filterKeyForModal),
  };
};

export default connect<StateProps, {}, {}, IReduxState>(mapStateToProps)(
  DealsModal
);
