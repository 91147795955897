import React from 'react';
import { useSelector } from 'react-redux';

import Widget3 from 'components/dashboard/ForecastDashboard/Widget3';
import { NewPipelineCreatedWidget } from 'components/dashboard/Pipeline/PipelineDashboard/PipelineWidget';
import PipelineRiskAnalytics from 'components/modals/PersonalActivity/PipelineRiskAnalytics';
import Table from 'components/modals/PersonalActivity/Table';
import * as styles from 'components/modals/PersonalActivity/styles';
import { ModalRouteProps, ModalPersistedProps } from 'navigation/types';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

type Props = ModalRouteProps<{ email: string }>;

const PersonalActivity: React.FC<Props> = ({ params }) => {
  const businessTypes = useSelector(selectors.getBusinessTypes);
  const isFiltersLoaded = useSelector(selectors.isFiltersLoaded);

  const companyCurrency = useSelector(selectors.getUserLocalCurrency);

  const selectedBusinessType = businessTypes[0];

  const { filters, user } = useSelector<
    IReduxState,
    ModalPersistedProps<{ filters: { [key: string]: any }; user: string }>
  >((state) => selectors.getModalProps(state, '/activity/personal/:email'));

  const { email } = params;

  if (!isFiltersLoaded) {
    return null;
  }

  return (
    <>
      <div className={styles.header}>
        Dashboard for {user} ({email})
      </div>
      <div className={styles.body}>
        <PipelineRiskAnalytics
          filters={filters}
          email={email}
          isFiltersLoaded={isFiltersLoaded}
        />

        <div className={styles.chart_two_line}>
          <Table
            filters={filters}
            title="Deals at Risk"
            order="high"
            companyCurrency={companyCurrency}
          />
          <Table
            filters={filters}
            title="Low Risk Deals"
            order="low"
            companyCurrency={companyCurrency}
          />
        </div>

        <div className={styles.chart_wrapper}>
          <Widget3 isModal selectedBusinessType={selectedBusinessType} />
        </div>

        <div className={styles.chart_wrapper}>
          <NewPipelineCreatedWidget
            isModal
            selectedBusinessType={selectedBusinessType}
          />
        </div>
      </div>
    </>
  );
};

export default PersonalActivity;
