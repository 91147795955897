import { FileType } from 'utils/network/types';

export const mapFileTypeToMime: { [ext in FileType]: string } = {
  csv: 'text/csv',
  json: 'application/json',
  pdf: 'application/pdf',
};

export const mapHttpStatusToMessage: { [key: number]: string } = {
  400: 'bad query',
  401: 'unauthorized',
  403: 'forbidden',
  404: 'not found',
  405: 'wrong method',
  500: 'server error',
};
