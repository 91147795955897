import { forecastTabs, BusinessTypeTab } from 'common/constants';
import { BIMetricsFilter } from 'components/dashboard/Metrics/metrics.types';
import { ProfileDataWithName } from 'reducers/filters/openFilters';
import { IReduxState } from 'reducers/types';
import { getForecastActiveBusinessType } from 'selectors/forecast';
import { getCompanySettings, getBusinessTypes } from 'selectors/settings';

type FilterByName = {
  name: string;
  filter: Filters.Config;
} | null;

export const isFiltersLoaded = (state: IReduxState): boolean =>
  state.openFilters.status.loaded;

const getFilterByName = (
  filters: Filters.FiltersState,
  name: string
): FilterByName => (filters[name] ? { filter: filters[name], name } : null);

export const filtersViewBy = (state: IReduxState) =>
  state.openFilters.status.viewBy;

export const getBestProfileNameByTab = (state: IReduxState, tab: string) => {
  const profiles = state?.openFilters?.data?.[tab]?.profiles;

  if (!profiles) {
    return null;
  }

  const selectedProfileName = state?.openFilters?.selectedProfiles?.[tab];

  if (!selectedProfileName) {
    return null;
  }

  const [selectedProfileKey] =
    Object.entries(profiles).find(([key]) => key === selectedProfileName) || [];

  return selectedProfileKey;
};

export const getFiltersState = (
  state: IReduxState,
  tab: string | null,
  partition?: Filters.FiltersPartition,
  profileId?: string
): Filters.FiltersState | null => {
  if (
    !isFiltersLoaded(state) ||
    (profileId && !state.openFilters.settings.profiles[profileId])
  ) {
    return null;
  }

  let filters: Filters.FiltersState = {};

  if (tab) {
    const selectedProfile = getBestProfileNameByTab(state, tab);

    if (!selectedProfile) {
      return null;
    }

    if (!partition || partition === 'data') {
      filters = profileId
        ? state.openFilters.settings.profiles[profileId].data[tab]
        : state.openFilters.data[tab]?.profiles[selectedProfile]?.state || null;
    }

    if (partition === 'initialState') {
      filters = profileId
        ? state.openFilters.settings.profiles[profileId].initialState[tab]
        : state.openFilters.initialState[tab] || null;
    }
  }

  if (partition === 'globalDefaults') {
    filters = state.openFilters.settings.globalDefaults;
  }

  if (!filters) {
    return null;
  }

  const allFilters = Object.keys(filters);
  const res: Filters.FiltersState = {};

  allFilters.forEach((rawName) => {
    const filter = getFilterByName(filters, rawName);

    if (!filter) {
      return;
    }

    res[filter.name] = filters[filter.name];
  });

  return res;
};

export type FiltersForAPI = {
  custom_filters?: {
    [customFilterName: string]: any;
  };
  custom_account_filters?: {
    [customFilterName: string]: string[];
  };
} & {
  [filterName: string]: string[];
};

/**
 * Utility type that extends the base `FiltersForAPI` type and allows for defining more strict objects.
 * It provides the ability to specify additional custom filters and custom account filters,
 * while also enforcing the presence of specific keys or allowing them to be of a different type.
 *
 * @template T - The base type to extend.
 *
 * @example
 * // Suppose we have a base type `BaseFilters`:
 * interface BaseFilters {
 *   category: string;
 *   price: number;
 * }
 *
 * // We can use `GetSpecificFiltersForAPI` to define a more strict object:
 * type SpecificFilters = GetSpecificFiltersForAPI<BaseFilters>;
 *
 * // In this example, `SpecificFilters` will have all the properties of `BaseFilters`, but it also allows for additional custom filters and custom account filters.
 * // For example:
 * const filters: SpecificFilters = {
 *   category: 'electronics',
 *   price: 100,
 *   custom_filters: {
 *     color: 'red',
 *     brand: 'Apple',
 *   },
 *   custom_account_filters: {
 *     size: ['small', 'medium'],
 *   },
 *   some_other_property: ['some_other_value'],
 * };
 *
 * // The `filters` object can have any number of custom filters and custom account filters,
 * // while still enforcing the presence of `category` and `price` properties.
 */

export type GetSpecificFiltersForAPI<T> = {
  custom_filters?: {
    [customFilterName: string]: any;
  };
  custom_account_filters?: {
    [customFilterName: string]: string[];
  };
} & T &
  Omit<
    {
      [filterName: string]: string[];
    },
    keyof T
  >;

export const getFiltersForAPI = (
  state: IReduxState,
  tab: string,
  partition?: Filters.FiltersPartition,
  profileId?: string
): FiltersForAPI => {
  const filters = getFiltersState(state, tab, partition, profileId);

  if (!filters) {
    return {};
  }

  let result: FiltersForAPI = {};
  result.custom_filters = {};
  result.custom_account_filters = {};

  Object.keys(filters).forEach((name) => {
    const filter = getFilterByName(filters, name);

    if (filter) {
      let item: string[] | boolean[] = filter.filter.checkedAll
        ? []
        : filter.filter.values
            .filter((i) => i.checked && !i.group)
            .map((i) => i.value);

      if (filter.filter.group) {
        // hack for boolean custom filters
        if (['true', 'false'].includes(item[0])) {
          item = [item[0] === 'true'];
        }

        (result[filter.filter.group] as any)[filter.name] = item;
      } else {
        result[filter.name] = item;
      }
    }
  });

  if (result.close_date_interval && result.close_date_interval[0] === '') {
    result.close_date_interval = [];
  }

  return result;
};

export const getBusinessTypeForTab = (
  state: IReduxState,
  tab: BusinessTypeTab
): string | null => {
  if (tab && isFiltersLoaded(state)) {
    const dataByTab = state.openFilters.data[tab];
    const selectedProfile = state.openFilters.selectedProfiles[tab];

    if (dataByTab && selectedProfile) {
      return dataByTab.profiles[selectedProfile]?.business_type_name || null;
    }
  }

  return null;
};

export const getSelectedBusinessTypeForPanel = (
  state: IReduxState,
  tab: BusinessTypeTab
): string | null =>
  forecastTabs.includes(tab)
    ? getForecastActiveBusinessType(state)
    : getBusinessTypeForTab(state, tab);

export const getSelectedBusinessType = (
  state: IReduxState,
  tab: BusinessTypeTab,
  displayOverall = false
): string => {
  const selectedBusinessTypeForPanel = getSelectedBusinessTypeForPanel(
    state,
    tab
  );
  const availablesBusinesTypes = getBusinessTypes(state, displayOverall);
  return selectedBusinessTypeForPanel &&
    availablesBusinesTypes.includes(selectedBusinessTypeForPanel)
    ? selectedBusinessTypeForPanel
    : availablesBusinesTypes[0];
};

export const getFiltersForCustomViews = (
  state: IReduxState,
  tab: string
): Filters.BackendFiltersStateItem | null => {
  const filters = getFiltersState(state, tab);

  if (!filters) {
    return null;
  }

  const result: Filters.BackendFiltersStateItem = {
    display_name: state.openFilters.selectedProfiles[tab] || '',
    enabled_filters: [],
    locked_filters: [],
    preset_values: {},
    section: '',
  };

  Object.keys(filters).forEach((name) => {
    const filter = getFilterByName(filters, name);

    if (filter) {
      let item: string[] | boolean[] = filter.filter.checkedAll
        ? []
        : filter.filter.values
            .filter((i) => i.checked && !i.group)
            .map((i) => i.value);

      if (item && item.length) {
        if (filter.filter.group) {
          // hack for boolean custom filters
          if (['true', 'false'].includes(item[0])) {
            item = [item[0] === 'true'];
          }
        }

        result.preset_values[filter.name] = item as string[];
      }

      result.enabled_filters.push(filter.name);

      if (filter.filter.isLocked) {
        result.locked_filters.push(filter.name);
      }
    }
  });

  if (
    result.preset_values.close_date_interval &&
    result.preset_values.close_date_interval[0] === ''
  ) {
    result.preset_values.close_date_interval = [];
  }

  return result;
};

export const getActiveFiltersProfile = (state: IReduxState, tab: string) => {
  const name = state.openFilters.selectedProfiles[tab] || undefined;

  if (name) {
    const profile = state.openFilters.data?.[tab]?.profiles[name];

    if (profile) {
      return { name, profile };
    }
  }
};

export const getViewNames = (state: IReduxState, tab: string): string[] => {
  return Object.keys(state.openFilters.data[tab]?.profiles || {});
};

export const getViewsForTab = (state: IReduxState, tab: string) => {
  const profiles = state.openFilters.data[tab]?.profiles || {};
  const user = state.user;

  return Object.entries(profiles).reduce<{
    [view_type: string]: ProfileDataWithName[];
  }>((acc, [key, profile]) => {
    const viewType = profile.view_type;

    const viewCreatedByUser = profile.user?.id === user.user_id;

    // If view is shared is set as global, but we need to show it to
    // the original creator as personal
    const correctViewType = viewCreatedByUser ? 'PERSONAL' : viewType;

    return {
      ...acc,
      [correctViewType]: [
        ...(acc[correctViewType] || []),
        {
          ...profile,
          name: key,
        },
      ],
    };
  }, {});
};

export const getFiltersInitialState = (state: IReduxState) =>
  state.openFilters.initialState;

export const getSettingsFiltersTabsState = (state: IReduxState) =>
  state.openFilters.settings.tabs;

export const getSettingsFiltersAvailable = (state: IReduxState, tab: string) =>
  state.openFilters.settings.available[tab];

export const getSettingsFiltersAvailableByProfile = (
  state: IReduxState,
  tab: string,
  profileId: string
) =>
  state.openFilters.settings.profiles[profileId]
    ? state.openFilters.settings.profiles[profileId].available[tab]
    : { available_filters: [], enabled_filters: [] };

export const getFiltersForProfile = (state: IReduxState, profileId: string) =>
  (state.openFilters.settings.profiles[profileId] || {}).data || {};

export const getUsersForFilter = (
  state: IReduxState
): Filters.UserFilterElement[] => state.openFilters.rawFragments.users || [];

export const getUserRolesForFilter = (
  state: IReduxState
): Filters.UserRoleFilterElement[] => {
  const role = state.openFilters.rawFragments.user_role;

  return role ? [role] : [];
};

export const getForecastCategoryNamesForFilter = (
  state: IReduxState
): Filters.Default[] =>
  state.openFilters.rawFragments.forecast_category_names || [];

export const getSalesManagersForFilter = (
  state: IReduxState
): Filters.UserFilterElement[] =>
  state.openFilters.rawFragments.sales_managers || [];

export const getInternalActivitySortByFilter = (state: IReduxState) =>
  state.openFilters.internal.activitySortBy;

export const getShowCustomViews = (state: IReduxState) => {
  const companySettings = getCompanySettings(state) || {};
  return companySettings?.custom_views?.enabled || false;
};
