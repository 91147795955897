import React from 'react';

import { DateOperatorOption } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/types';
import { DropdownCalendar } from 'components/dashboard/Metrics/common/Calendar/Calendar';
import { useTargetPeriodOptions } from 'components/dashboard/Metrics/common/TargetTimePeriodCalendar/hooks';

interface Props {
  isDateFirstPivot?: boolean;
  selectedValue?: string | string[];
  targetPeriod?: string;
  title: string;
  disabled?: boolean;
  onValueChange: (change: DateOperatorOption) => void;
}

export const TargetTimePeriodCalendar: React.FC<Props> = ({
  isDateFirstPivot = false,
  selectedValue,
  targetPeriod,
  title,
  disabled,
  onValueChange,
}) => {
  const { options: targetPeriodOptions } = useTargetPeriodOptions(
    targetPeriod || ''
  );

  const value = selectedValue
    ? typeof selectedValue === 'string'
      ? selectedValue
      : selectedValue[0]
    : '';

  const includedBlankValues =
    selectedValue?.length && typeof selectedValue !== 'string'
      ? !!selectedValue[1]
      : false;

  return (
    <DropdownCalendar
      disabled={disabled}
      config={{
        allowFuture: true,
        title: title,
        isLocked: isDateFirstPivot,
        customEnd: false,
        customStart: false,
        values: targetPeriodOptions.map((option) => ({
          ...option,
          checked: option.value === value,
        })),
        isSimpleCalendar: true,
        includedBlankValues,
        showIncludeBlanksValues: false,
      }}
      key="Target Time Period Filter"
      name="Target Time Period Filter"
      onChange={onValueChange}
    />
  );
};
