import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useHideBlockingScreen } from 'components/hooks/useHideBlockingScreen';
import AccountInsights from 'screens/Account';

const FullScreenAccount: FC = () => {
  const { id } = useParams<{ id: string }>();

  useHideBlockingScreen();

  if (!id) {
    return null;
  }

  return <AccountInsights id={id} isPanel={false} isFullScreen />;
};

export default FullScreenAccount;
