import { css } from 'emotion';

export const pin_icon = css`
  font-size: 18px;
`;

export const pin_container = css`
  :hover {
    i {
      color: var(--bu-gray-500) !important;
    }
  }
`;
