import { css } from 'emotion';

export const container = css`
  margin-bottom: 24px;
`;

export const optionContainer = css`
  margin-bottom: 12px;
`;

export const infoParagraph = css`
  font-size: 12px;
  color: var(--bu-gray-600);
  padding-left: 46px;
  padding-bottom: 16px;
`;

export const infoParagraphRadio = css`
  font-size: 12px;
  color: var(--bu-gray-600);
  padding-left: 26px;
`;
