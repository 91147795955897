import styled from '@emotion/styled';
import React from 'react';

import EmptyDashboard from 'assets/images/empty_dashboard.svg';
import BuButton from 'components/UI/BuButton';

const PlaceholderContainer = styled.div({
  padding: '48px',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  alignContent: 'center',
  alignItems: 'center',
  width: '100%',
});

const PlaceholderTextMain = styled.div({
  fontSize: '18px',
  fontWeight: 400,
});

interface Props {
  readonly buttonDisabled: boolean;
  readonly onAddWidgetClick: () => void;
}

export const DashboardPlaceholder: React.FC<Props> = ({
  buttonDisabled,
  onAddWidgetClick,
}) => (
  <PlaceholderContainer>
    <img src={EmptyDashboard} />
    <PlaceholderTextMain>What do you want to analyse?</PlaceholderTextMain>
    <div>Add the widgets that you want to see in this dashboard</div>
    <div>
      <BuButton disabled={buttonDisabled} onClick={onAddWidgetClick}>
        + Add widget
      </BuButton>
    </div>
  </PlaceholderContainer>
);
