import React from 'react';
import {
  Dropdown,
  Grid,
  Header,
  Modal,
  DropdownProps,
} from 'semantic-ui-react';

type Props = {
  open: boolean;
  type: 'revenue' | 'deals';
  title: string;
  onClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onChange: (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => void;
};

const FunnelModal: React.FC<Props> = ({
  open,
  type,
  title,
  onClose,
  onChange,
}) => (
  <Modal open={open} size="large" closeIcon onClose={onClose}>
    <Grid container>
      <Grid.Row centered verticalAlign="middle">
        <Grid.Column width={4}>
          <Dropdown
            options={[
              {
                text: 'Revenue',
                value: 'revenue',
              },
              {
                text: '# of Deals',
                value: 'count',
              },
            ]}
            onChange={onChange}
            value={type}
          />
        </Grid.Column>
        <Grid.Column width={8} textAlign="center">
          <Header as="h4">
            {type === 'revenue' ? 'Revenue' : '# of Deals'} by {title}
          </Header>
        </Grid.Column>
        <Grid.Column width={4} textAlign="right" />
      </Grid.Row>
    </Grid>
  </Modal>
);

export default FunnelModal;
