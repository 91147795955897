import { useQuery } from '@tanstack/react-query';
import { getDateColumnsFor } from 'api/RevBiMetric';
import { ISelectOption } from 'components/UI/BuSelect/types';
import { AnalysisType } from '../../constants';
import { formatMetricObject } from '../../metrics.helpers';
import {
  BIMetricSimple,
  BIMetricUnion,
  DataDescriptor,
} from '../../metrics.types';

interface MetricRelatedDateColumns {
  status: string;
  isLoading: boolean;
  isError: boolean;
  isOptional: boolean;
  options: ISelectOption[];
  pivotColumn: DataDescriptor[];
}

export const useMetricRelatedDateColumns = (
  metric: BIMetricUnion
): MetricRelatedDateColumns => {
  const object = (metric as BIMetricSimple).object ?? '';

  const {
    data = [],
    isError,
    isLoading,
    status,
  } = useQuery({
    queryKey: ['metricRelatedDateColumns', object],
    queryFn: () => getDateColumnsFor(object),
    staleTime: 300000,
    enabled: !!object && metric.analysis_type !== AnalysisType.HISTORICAL,
  });

  if (!object || metric.analysis_type === AnalysisType.HISTORICAL) {
    return {
      status: '',
      isLoading: false,
      isError: false,
      isOptional: true,
      options: [],
      pivotColumn: [],
    };
  }

  /**
   * when the object selected has not option or the table doesn't have
   * its own date field column, this prop is optional. So the user can
   * save the metric without a linked date field.
   */
  const isOptional = !data.some((el: any) => el.table_name === object);

  const options: ISelectOption[] = data.map((el: any) => ({
    ...el,
    text: el.label,
    value: el.name,
    group: formatMetricObject(el.table_name),
    entityName: el.table_name,
  }));

  return {
    status,
    isLoading,
    isError,
    isOptional,
    options,
    pivotColumn: data,
  };
};
